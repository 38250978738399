import { ManagementMethodComponent } from './../components/admin/admin-pages/management-method/management-method.component';
import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class ManagementMethodService extends BaseService {
  getModalityAll(): any {
    return this.get(UrlConstant.Modality + '/GetAll');
  }

  saveModality(data: any) {
    return this.post(UrlConstant.Modality, data);
  }

  // getSystemCodeById(id: string) {
  //   return this.get(UrlConstant.SYSTEM_CODE + '/' + id);
  // }

  updateModality(id, data) {
    return this.put(UrlConstant.Modality + '/' + data.id, data);
  }
}
