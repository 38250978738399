<div
  class="form-input form-elm"
  *ngIf="data.viewType == 'input' || data.viewType == 'checkbox'">
  <label
    nz-checkbox
    [(ngModel)]="data.valueBoolean"
    [nzDisabled]="data.editType == 0 || data.editType == 3"
    (ngModelChange)="onModelChange($event)"></label>
</div>
<div class="form-input form-elm" *ngIf="data.viewType == 'radio'">
  <nz-radio-group
    [(ngModel)]="data.valueBoolean"
    (ngModelChange)="onModelChange($event)"
    [nzDisabled]="data.editType == 0 || data.editType == 3">
    <label nz-radio [nzValue]="true">Có</label>
    <label nz-radio [nzValue]="false">Không</label>
  </nz-radio-group>
</div>
