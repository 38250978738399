import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Visits } from 'src/app/models/listVisit.class';
import { Reminder } from 'src/app/models/Reminder.class';
import { ListReminderService } from 'src/app/services/ListReminder.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PatientService } from 'src/app/services/patient.service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-patient-reminder-form',
  templateUrl: './patient-reminder-form.component.html',
  styleUrls: ['./patient-reminder-form.component.scss'],
})
export class PatientReminderFormComponent implements OnInit {
  @Input() isVisible = false;
  @Input() visit: Visits = null;
  @Input() doctorInGroup = [];

  _data: Reminder;
  @Input() set data(value) {
    this._data = value;
    if (value != null) {
      this.dataForm = {
        id: value.id,
        doctorId: value.doctorId,
        doctorName: value.doctorName,
        activeDate: value.activeDate,
        title: value.title,
        detail: value.detail,
        status: value.status,
        isForMe: value.isForMe,
        minutesBeforeReminder: value.minutesBeforeReminder,
      };
    } else {
      this.resetDataForm();
    }
  }
  get data() {
    return this._data;
  }
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() refreshData: EventEmitter<any> = new EventEmitter();

  dataForm: Reminder = {
    id: '',
    doctorId: '',
    doctorName: '',
    activeDate: null,
    title: '',
    detail: '',
    status: 0,
    isForMe: true,
    minutesBeforeReminder: 0,
  };
  lstMinutesBeforeRmd = [
    {
      label: '15 phút',
      value: 15,
    },
    {
      label: '30 phút',
      value: 30,
    },
    {
      label: '1 giờ',
      value: 60,
    },
    {
      label: '2 giờ',
      value: 120,
    },
    {
      label: '1 ngày',
      value: 1440,
    },
  ];
  dateFormat = 'dd/MM/yyyy';
  disabledDateBeforeToday = this.patientService.disabledDateBeforeToday;
  isInvalidActiveDate = false;
  isShowListSample = false;

  constructor(
    private patientService: PatientService,
    private listReminderService: ListReminderService,
    private notificationService: NotificationService,
    private shareService: ShareService
  ) {}

  ngOnInit(): void {}

  handleCancel() {
    this.isVisible = false;
    this.resetDataForm();
    this.closeModal.emit();
  }

  resetDataForm() {
    this.dataForm = {
      id: '',
      doctorId: '',
      doctorName: '',
      activeDate: null,
      title: '',
      detail: '',
      status: 0,
      isForMe: true,
      minutesBeforeReminder: 0,
    };
  }

  onChangeActiveDate(date) {
    this.isInvalidActiveDate =
      Date.parse(date) < Date.parse(new Date().toString());
  }

  saveReminder() {
    if (
      this.shareService.checkEmptyStr(this.dataForm.title) ||
      (!this.dataForm.isForMe && this.dataForm.doctorId == '') ||
      this.shareService.checkEmptyObj(this.dataForm.activeDate)
    ) {
      this.notificationService.showNotification(
        Constant.ERROR,
        'Bạn cần nhập các trường bắt buộc!'
      );
      return;
    }
    if (this.isInvalidActiveDate) {
      this.notificationService.showNotification(
        Constant.ERROR,
        'Thời gian không hợp lệ!'
      );
      return;
    }
    const doctorName =
      this.dataForm.doctorId == ''
        ? ''
        : this.doctorInGroup.filter((en) => en.id == this.dataForm.doctorId)[0]
            .fullName;
    const payload: Reminder = {
      id: this.dataForm.id,
      groupId: this.visit.groupId,
      patientId: this.visit.patientId,
      patientName: this.visit.patientName,
      patientCode: this.visit.patientCode,
      patientPhoneNo: this.visit.phoneNo,
      patientAddress: this.visit.address,
      doctorId: this.dataForm.doctorId,
      doctorName,
      activeDate: this.dataForm.activeDate,
      title: this.dataForm.title,
      detail: this.dataForm.detail,
      status: this.dataForm.status,
      isForMe: this.dataForm.isForMe,
      minutesBeforeReminder: this.dataForm.minutesBeforeReminder,
    };
    if (this.dataForm.id == '') {
      this.listReminderService.addReminder(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(
            Constant.SUCCESS,
            'Thêm nhắc nhở thành công!'
          );
          this.isVisible = false;
          this.closeModal.emit();
          this.refreshData.emit();
        }
      });
    } else {
      this.listReminderService.updateReminder(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(
            Constant.SUCCESS,
            'Cập nhật thành công!'
          );
          this.isVisible = false;
          this.closeModal.emit();
          this.refreshData.emit();
        }
      });
    }
  }
  showListSample() {
    this.isShowListSample = true;
    //this.reloadDataSample();
  }
  usingSample(data) {
    this.dataForm.title = data.title;
    this.dataForm.detail = data.detail;
    this.dataForm.minutesBeforeReminder = data.minutesBeforeReminder;
  }
}
