import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { ApiTokenService } from 'src/app/services/api-token.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-api-token-modal',
  templateUrl: './api-token-modal.component.html',
  styleUrls: ['./api-token-modal.component.scss'],
})
export class ApiTokenModalComponent implements OnInit {
  _isVisible = false;
  _currentApiToken: any = {};
  @Input() set isVisible(val: boolean) {
    if (val) {
    }
    this._isVisible = val;
  }
  @Input() set currentApiToken(val: any) {
    this._currentApiToken = val;
    if (
      this.shareService.checkEmpty(this._currentApiToken) ||
      this.shareService.checkEmpty(this._currentApiToken.id)
    ) {
      this.genSecretKey();
    } else {
      this.formData.patchValue({
        name: this._currentApiToken.name,
        token: this._currentApiToken.token,
        groupId: this.groupId,
      });
      this.token = this._currentApiToken.token;
      this.events.forEach((item) => {
        if (
          this._currentApiToken.events.filter(
            (t) => t.eventName == item.eventName && t.eventActive
          ).length > 0
        ) {
          item.eventActive = true;
        }
      });
    }
  }

  get currentApiToken() {
    return this._currentApiToken;
  }

  _groupId = '';
  @Input() set groupId(val: string) {
    console.log(val);
    this._groupId = val;
  }

  get groupId() {
    return this._groupId;
  }
  @Output() isVisibleChange: EventEmitter<any> = new EventEmitter();
  formData: FormGroup;
  events: any[] = [];
  get isVisible(): boolean {
    return this._isVisible;
  }
  token = '';

  constructor(
    private fb: FormBuilder,
    private shareService: ShareService,
    private modalService: NzModalService,
    private notificationService: NotificationService,
    private apiTokenService: ApiTokenService
  ) {
    this.formData = this.fb.group({
      name: ['', [Validators.required], [this.emptyAsyncValidator]],
      token: ['', [Validators.required]],
      groupId: [''],
    });
  }

  ngOnInit() {
    this.formData.get('token').disable();
    this.formData.get('groupId').disable();
  }

  handleCancel() {
    this.formData.reset();
    this.isVisibleChange.emit(false);
  }

  genSecretKey() {
    this.apiTokenService.genKeyApiToken().subscribe((res) => {
      this.formData.patchValue({
        token: res.token,
        groupId: this.groupId,
      });
      this.token = res.token;
    });
  }

  emptyAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.shareService.checkEmptyStr(control.value)) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, emptyName: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  nonUnicodeAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (
        !this.shareService.NON_UNICODE_REGEX.test(control.value) ||
        this.shareService.NON_WHITESPACE_REGEX.test(control.value)
      ) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, nonUnicodeWhitespace: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  saveApiToken() {
    this.validateForm();
    if (this.formData.valid) {
      let data = this.formData.value;
      const activeEvents = this.events.map((t) => ({
        eventName: t.eventName,
        eventActive: t.eventActive,
      }));
      data = { ...data, ...{ groupId: this.groupId, token: this.token } };
      if (this.currentApiToken) {
        data = { ...data, ...{ id: this.currentApiToken.id } };
      }
      this.apiTokenService.saveApiToken(data).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Lưu thành công'
          );
          this.formData.reset();
          this.isVisibleChange.emit(false);
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            res.error[0].errorMessage
          );
        }
      });
    }
  }

  validateForm() {
    for (const i in this.formData.controls) {
      this.formData.controls[i].markAsDirty();
      this.formData.controls[i].updateValueAndValidity();
    }
  }
}
