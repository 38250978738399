import { NONE_TYPE } from '@angular/compiler';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { AppConfigService } from 'src/app-config.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { UrlConstant } from 'src/app/share/constants/url.class';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class UploadImageComponent implements OnInit {
  //url = "http://192.168.18.27:8899/api/Upload/uploadFile?TypeUpload=upload_visit_image";
  _isVisible: boolean;
  _uploadMaxSize: any;
  boundClass: string;
  maxWidth: string;
  maxHeight: string;

  @Input() set isVisible(value: boolean) {
    this._isVisible = value;
    this.avatarUrl = '';
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  @Input() set uploadMaxSize(value: any) {
    this._uploadMaxSize = value;
    this.maxWidth = `max-width: ${this._uploadMaxSize.width}px;`;
    if (this._uploadMaxSize.height > 400) {
      this._uploadMaxSize.height = 400;
    }
    this.maxHeight = `max-height: ${this._uploadMaxSize.height}px;`;
    // if (this._uploadMaxSize.height > this._uploadMaxSize.width) {
    //   this.boundClass = "fit-width";
    // } else {
    //   this.boundClass = "fit-height";
    // }
  }

  @Input() imageType: boolean;
  @Output() isVisibleChange = new EventEmitter();
  @Output() addImage = new EventEmitter();
  @ViewChild('image') container: ElementRef;

  loading = false;
  avatarUrl?: string;
  header?: any;

  get uploadMaxSize(): any {
    return this._uploadMaxSize;
  }

  constructor(
    private notificationService: NotificationService,
    private configService: AppConfigService
  ) {
    this.header = {
      Authorization: 'Bearer ' + localStorage.getItem(Constant.TOKEN),
    };
  }

  mediaUrl = `${
    this.configService.getConfig().api.baseUrl
  }/api/Upload/uploadFile?TypeUpload=upload_visit_image`;
  ngOnInit() {}

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) => new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        // this.msg.error('You can only upload JPG file!');
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng);
      observer.complete();
    });

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        // this.avatarUrl = info.file.thumbUrl;
        this.avatarUrl =
          this.configService.getConfig().media.baseUrl +
          '/VisitImage/' +
          info.file.response.fileName;
        // this.formAddPatient.controls['avatar'].setValue(info.file.response["fileName"]);
        // this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Success');
        break;
      case 'error':
        // this.msg.error('Network error');
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Error'
        );
        this.loading = false;
        break;
    }
  }

  handleOk(): void {
    const height = this.container.nativeElement.offsetHeight;
    const naturalHeight = this.container.nativeElement.naturalHeight;
    const naturalWidth = this.container.nativeElement.naturalWidth;
    const width = (height * naturalWidth) / naturalHeight;

    this.addImage.emit({
      type: this.imageType,
      src: this.avatarUrl,
      height,
      width,
    });
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }
}
