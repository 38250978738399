<div *ngIf="result.state == 1">
  <span>
    <i style="color: green" nz-icon nzType="check-square" nzTheme="outline"></i>
  </span>
  <span> Kết quả: {{ result.result }} </span>
</div>

<div *ngIf="result.state == 2">
  <span>
    <i style="color: red" nz-icon nzType="close-square" nzTheme="outline"></i>
  </span>
  <span>
    {{ result.content }}
  </span>
</div>
