import { ActivatedRoute } from '@angular/router';
import { DoctorInGroup } from './../../../models/doctor-in-group.model';
import { string } from '@amcharts/amcharts4/core';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { WaitingPageService } from 'src/app/services/waitingPage.service';
import { WaitingPageConfig } from '../../../models/waiting-page.class';
import { NotificationService } from 'src/app/services/notification.service';
import { Subscription } from 'rxjs';
import { DoctorService } from 'src/app/services/doctor.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { Constant } from 'src/app/share/constants/constant.class';
import { ShareService } from 'src/app/services/share.service';
import { GroupTreeService } from 'src/app/services/groupTree.service';
import { PatientRoomService } from 'src/app/services/patient-room.service';
import { fas } from '@fortawesome/free-solid-svg-icons';

interface Payload {
  fromDate: any;
  roomId?: any;
  listDoctorIds?: any;
  page: number;
  pageSize: number;
  isReceived: boolean;
}

@Component({
  selector: 'app-waiting-page',
  templateUrl: './waiting-page.component.html',
  styleUrls: ['./waiting-page.component.scss'],
})
export class WaitingPageComponent implements OnInit, OnDestroy {
  isShowConfig = true;
  isShowScreen = false;
  isAddConfig = false;
  isIncludeField = new Array(4);
  isShow2Doctors = false;
  isShow2Room = false;

  scrollY: string = window.innerHeight - 365 + 'px';
  groupSub: Subscription;

  lstColumn = [
    { text: 'STT', width: '70px' },
    { text: 'Tên BN', width: '150px' },
    { text: 'Tuổi', width: '70px' },
    { text: 'Dịch vụ', width: '200px' },
  ];
  listColumnDisplay = [];
  lstConfigDisplay = [];
  doctorSelected = [];
  roomSelected = [];

  dataConfig: WaitingPageConfig = {
    id: '',
    name: '',
    numberDoctor: 1,
    numberRoom: 1,
    listDoctorIds: string[''],
    listColumns: string[''],
    headerHtml: '',
    description: '',
    fontSizeGrid: '40',
  };
  selectConfig: WaitingPageConfig = {
    id: '',
    name: '',
    numberDoctor: 1,
    listDoctorIds: string[''],
    listColumns: string[''],
    headerHtml: '',
    description: '',
    fontSizeGrid: '',
  };
  selectConfigId: string;
  selectedDoctor1: any;
  selectedDoctor2: string;
  lstVisitWaiting1 = [];
  lstVisitWaiting2 = [];
  selectNumberOfDoctor = '1';
  headerHtml: string;
  fontSizeGrid: string;
  nameConfig: string;
  doctorInGroup: DoctorInGroup[] = [];
  groupId: string;
  useRoomOrDoctorForVisit: boolean;
  isShowdoctorInRoom = false;
  rooms;
  intervalId1: any;
  intervalId2: any;
  listDoctorInRoom1;
  listDoctorInRoom2;
  listNameDoctorInRoom1;
  listNameDoctorInRoom2;

  constructor(
    private WaitingPageService: WaitingPageService,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    private doctorService: DoctorService,
    private route: ActivatedRoute,
    private shareService: ShareService,
    private groupTreeService: GroupTreeService,
    private roomService: PatientRoomService
  ) {}

  ngOnInit(): void {
    this.groupId = this.route.snapshot.paramMap.get('groupId');
    this.getVisitByRoomOrDoctor(this.groupId);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId1);
    clearInterval(this.intervalId2);
  }

  getVisitByRoomOrDoctor(groupId) {
    this.groupTreeService.getGroupInfo(groupId).subscribe((res) => {
      if (res) {
        this.useRoomOrDoctorForVisit = res.getVisitByRoomOrDoctor;
        // true--room;false--doctor;
        if (!this.useRoomOrDoctorForVisit) {
          this.getDoctorInGroup(this.groupId);
        } else {
          this.getRoomInGroup(this.groupId);
        }
      }
    });
  }
  getRoomInGroup(groupId) {
    this.roomService.getAllRoomInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;
        this.getConfig();
      }
    });
  }

  showDoctorInRoom(data) {
    this.listDoctorInRoom1 = data.find((el) => el.id == this.selectedDoctor1)?.listDoctor;
    this.listDoctorInRoom2 = data.find((el) => el.id == this.selectedDoctor2)?.listDoctor;
    if (this.useRoomOrDoctorForVisit) {
      if (this.listDoctorInRoom1) {
        this.listNameDoctorInRoom1 = this.listDoctorInRoom1
          .map((el) => {
            return el.fullName;
          })
          .join(',');
      }
      if (this.listDoctorInRoom2) {
        this.listNameDoctorInRoom2 = this.listDoctorInRoom2
          .map((el) => {
            return el.fullName;
          })
          .join(',');
      }
    }
  }

  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;
        this.getConfig();
      }
    });
  }

  showScreen() {
    if (this.checkDataValid()) {
      this.showDoctorInRoom(this.doctorInGroup);
      this.getDoctorNames();
      this.showField();
      this.isShowConfig = false;
      this.isShowScreen = true;
      this.getListVisitWaiting1(this.selectedDoctor1);

      this.getListVisitWaiting2(this.selectedDoctor2);
      clearInterval(this.intervalId1);
      clearInterval(this.intervalId2);
      this.intervalId1 = setInterval(() => {
        this.getListVisitWaiting1(this.selectedDoctor1);
      }, 3000);
      this.intervalId2 = setInterval(() => this.getListVisitWaiting2(this.selectedDoctor2), 3000);
    }
  }

  getConfig() {
    this.WaitingPageService.getConfigByUser(null).subscribe(
      (res) => {
        this.lstConfigDisplay = res.filter((value) => {
          return this.doctorInGroup.some((item) => {
            return item.id == value.listDoctorIds[0];
          });
        });
        // res.filter((item) => {
        //   this.doctorInGroup.forEach((el) => {
        //     if(el.id == item.listDoctorIds[0])
        //     if(!this.lstConfigDisplay.includes(item)){
        //     this.lstConfigDisplay.push(item)}
        //   });
        // });
      },
      (error) => {
        this.notificationService.showNotification('error', 'Lỗi không lấy được dữ liệu');
      }
    );
  }

  saveConfig() {
    this.dataConfig.listDoctorIds = [];
    if (this.dataConfig.numberDoctor == 1) {
      this.dataConfig.listDoctorIds.push(this.selectedDoctor1);
    } else if (this.dataConfig.numberDoctor == 2) {
      this.dataConfig.listDoctorIds.push(this.selectedDoctor1, this.selectedDoctor2);
    }
    if (!this.doctorSelected) {
      this.getDoctorNames();
    }

    if (this.checkDataValid()) {
      const payload = {
        id: this.dataConfig.id,
        name: this.dataConfig.name,
        numberDoctor: this.dataConfig.numberDoctor,
        listDoctorIds: this.dataConfig.listDoctorIds,
        listColumns: this.dataConfig.listColumns,
        headerHtml: this.dataConfig.headerHtml,
        description: this.dataConfig.description,
        fontSizeGrid: this.dataConfig.fontSizeGrid,
      };
      this.WaitingPageService.updateConfig(payload).subscribe(
        (res) => {
          this.notificationService.showNotification('success', 'Cập nhật thành công');
          this.getConfig();
          this.getListVisitWaiting1(this.selectedDoctor1);
          this.getListVisitWaiting2(this.selectedDoctor2);
        },
        (error) => {
          this.notificationService.showNotification('error', 'Chưa nhập hết dữ liệu');
        }
      );

      this.showField();
    }
  }

  showField() {
    this.isIncludeField = this.lstColumn.map((e) => this.dataConfig.listColumns.includes(e.text));
    this.listColumnDisplay = this.lstColumn.filter((e) => this.dataConfig.listColumns.includes(e.text));
  }

  addConfig() {
    this.isAddConfig = true;
  }

  handleCancel(): void {
    this.isShowConfig = false;
  }

  handleCancelAdd(): void {
    this.isAddConfig = false;
    this.nameConfig = '';
  }

  handleAddOk(datasend) {
    if (this.checkDataValid()) {
      this.dataConfig.listDoctorIds = [];
      this.dataConfig.listDoctorIds.push(this.selectedDoctor1, this.selectedDoctor2);
      const payload = {
        id: this.dataConfig.id,
        name: this.nameConfig,
        numberDoctor: this.dataConfig.numberDoctor,
        listDoctorIds: this.dataConfig.listDoctorIds,
        listColumns: this.dataConfig.listColumns,
        headerHtml: this.dataConfig.headerHtml,
        description: this.dataConfig.description,
        fontSizeGrid: this.dataConfig.fontSizeGrid,
      };
      this.WaitingPageService.addConfig(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Tạo mới thành công');
          this.getConfig();
          this.selectConfigId = res.jsonData.id;

          this.isAddConfig = false;
          this.dataConfig.name = this.nameConfig;
          this.dataConfig.id = this.selectConfigId;
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Bạn chưa nhập đủ dữ liệu');
        }
      });
    }
  }

  getDoctorNames() {
    this.doctorSelected = [];

    if (this.dataConfig.numberDoctor == 0) {
      return 0;
    } else if (this.dataConfig.numberDoctor == 1) {
      this.isShow2Doctors = false;
      var doctorSelected1 = this.doctorInGroup.find((doctor) => doctor.id == this.selectedDoctor1);
      if (this.useRoomOrDoctorForVisit) {
        this.doctorSelected.push(doctorSelected1.name);
      }
      this.doctorSelected.push(doctorSelected1.fullName);
    } else if (this.dataConfig.numberDoctor == 2) {
      this.isShow2Doctors = true;
      var doctorSelected1 = this.doctorInGroup.find((doctor) => doctor.id == this.selectedDoctor1);
      const doctorSelected2 = this.doctorInGroup.find((doctor) => doctor.id == this.selectedDoctor2);

      if (doctorSelected1.hasOwnProperty('name')) {
        this.doctorSelected.push(doctorSelected1.name, doctorSelected2.name);
      }
      if (doctorSelected1.hasOwnProperty('fullName')) {
        this.doctorSelected.push(doctorSelected1.fullName, doctorSelected2.fullName);
      }
    }
  }

  selectConfigChange() {
    this.selectConfig = this.lstConfigDisplay.find((config) => config.id == this.selectConfigId);
    this.selectedDoctor1 = this.selectConfig.listDoctorIds[0];
    if (this.selectConfig.numberDoctor == 2) {
      this.selectedDoctor2 = this.selectConfig.listDoctorIds[1];
    } else {
      this.selectedDoctor2 = '';
    }

    this.dataConfig = {
      id: this.selectConfig.id,
      name: this.selectConfig.name,
      numberDoctor: this.selectConfig.numberDoctor,
      listDoctorIds: this.selectConfig.listDoctorIds,
      listColumns: this.selectConfig.listColumns,
      headerHtml: this.selectConfig.headerHtml,
      description: this.selectConfig.description,
      fontSizeGrid: this.selectConfig.fontSizeGrid,
    };
    this.getDoctorNames();
    this.showField();
  }

  openModalConfig() {
    this.isShowConfig = true;
  }

  getListVisitWaiting1(data) {
    let payload: Payload = {
      fromDate: this.shareService.getToday(),
      listDoctorIds: [`${data}`],
      page: 1,
      pageSize: 10000,
      isReceived: null,
    };
    if (this.useRoomOrDoctorForVisit) {
      payload = {
        fromDate: this.shareService.getToday(),
        roomId: data,
        listDoctorIds: [],
        page: 1,
        pageSize: 10000,
        isReceived: null,
      };
    }

    this.WaitingPageService.getListVisitWaiting(payload).subscribe(
      (res) => {
        this.lstVisitWaiting1 = res.source;
      },
      (error) => {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Lỗi không tải được dữ liệu');
      }
    );
  }

  getListVisitWaiting2(data) {
    let payload: Payload = {
      fromDate: this.shareService.getToday(),
      listDoctorIds: [`${data}`],
      page: 1,
      pageSize: 10000,
      isReceived: null,
    };
    if (this.useRoomOrDoctorForVisit) {
      payload = {
        fromDate: this.shareService.getToday(),
        roomId: data,
        listDoctorIds: [],
        page: 1,
        pageSize: 10000,
        isReceived: null,
      };
    }
    this.WaitingPageService.getListVisitWaiting(payload).subscribe(
      (res) => {
        this.lstVisitWaiting2 = res.source;
      },
      (error) => {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Lỗi không tải được dữ liệu');
      }
    );
  }

  syncDefaultFontSize() {
    if (
      this.shareService.checkEmptyStr(this.dataConfig.fontSizeGrid) ||
      this.shareService.checkEmptyStr(this.dataConfig.id)
    ) {
      this.dataConfig.fontSizeGrid = this.dataConfig.numberDoctor == 1 ? '40' : '36';
    }
  }

  isNumber(val) {
    return parseInt(val) == val;
  }

  calcAge(dob) {
    return this.shareService.calcAge(dob);
  }
  checkDataValid() {
    if (this.selectedDoctor1 == undefined || this.selectedDoctor1 == null) {
      return this.notificationService.showNotification('error', 'Chưa chọn dữ liệu bắt buộc (*)');
    } else if (this.dataConfig.listColumns == undefined) {
      return this.notificationService.showNotification('error', 'Chưa chọn các cột hiển thị');
    } else if (
      (this.selectedDoctor2 == undefined || this.selectedDoctor2 == null) &&
      this.dataConfig.numberDoctor == 2
    ) {
      return this.notificationService.showNotification('error', 'Chưa chọn dữ liệu bắt buộc (*)');
    } else if (this.selectedDoctor1 == this.selectedDoctor2) {
      return this.notificationService.showNotification('error', 'Chọn dữ liệu trùng lặp');
    } else return true;
  }
}
