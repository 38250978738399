import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';
import { Constant } from '../share/constants/constant.class';
import { DoctorInGroup } from '../models/doctor-in-group.model';

@Injectable({
  providedIn: 'root',
})
export class DoctorService extends BaseService {
  getDoctorInGroup(payload): Observable<DoctorInGroup[]> {
    return this.post(UrlConstant.DOCTOR_IN_GROUP + payload, null);
  }
  getListGroup(): Observable<any> {
    return this.get(UrlConstant.GROUP_OF_DOCTOR);
  }
  getMyCovidGroups(): Observable<any> {
    return this.get(UrlConstant.COVID_GROUP_OF_DOCTOR);
  }
  saveGroupCategory(payload): Observable<any> {
    return this.post(UrlConstant.SAVE_GROUP_CATEGORY, payload);
  }
  deleteGroupCategory(payload): Observable<any> {
    return this.post(
      UrlConstant.DELETE_GROUP_CATEGORY + '/' + payload,
      payload
    );
  }
  moveGrouptoCategory(payload): Observable<any> {
    return this.post(UrlConstant.MOVE_GROUP_TO_CATEGORY, payload);
  }
  editAliasGroupName(payload): Observable<any> {
    return this.post(UrlConstant.EDIT_ALIAS_GROUP_NAME, payload);
  }
  createUserAcc(payload): Observable<any> {
    return this.post(UrlConstant.CREATE_USER_ACCOUNT, payload);
  }
  quickCreateUserByPatient(payload): Observable<any> {
    return this.post(UrlConstant.QUICK_CREATE_USER_ACCOUNT, payload);
  }
  // saveCurrentGroupId(groupId): Observable<any> {
  //   return this.post(UrlConstant.SAVE_CURRENT_GROUP_ID, "", {groupId});
  // }
  getListDoctorRank(): Observable<any> {
    return this.get(UrlConstant.GET_LIST_DOCTORRANK);
  }
}
