import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ListAppointmentComponent } from '../../../worklist/list-appointment/list-appointment.component';
import { InviteMemberGroupComponent } from '../../../modal/invite-member-group/invite-member-group.component';
import { ConfigGroupComponent } from '../../../modal/config-group-module/config-group/config-group.component';
import { VisitDetailComponent } from '../../../worklist/visit-detail/visit-detail.component';
import { NotificationsComponent } from '../../../modal/notifications/notifications.component';
import { Observable, Subscription } from 'rxjs';
import { Menu } from '../../../../models/menu.class';
import { Patient } from '../../../../models/patient.class';
import { MenuItemTree } from '../../../../models/grouptree.model';
import { UserService } from '../../../../services/user-service';
import { AppConfigService } from '../../../../../app-config.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state/app-state';
import { AuthService } from '../../../../services/auth.service';
import { GroupTreeService } from '../../../../services/groupTree.service';
import { TabDataService } from '../../../../share/base-service/data-worklist.service';
import { MessageService } from '../../../../services/message.service';
import { GroupMemberService } from '../../../../services/group-member.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { VideoConferenceService } from '../../../../services/video-conference.service';
import { NotificationService } from '../../../../services/notification.service';
import { ShareService } from '../../../../services/share.service';
import { ChatService } from '../../../../services/chat.service';
import { Constant } from '../../../../share/constants/constant.class';
import firebase from 'firebase';
import { AuthActionTypes, Logout, ResetLockScreen, SSOLogout } from '../../../auth/redux/auth.action';
import { Join } from '../../../auth/redux/group-auth.action';
import { OpenDuplicatePatient } from '../../../modal/redux/modal/modal.action';

@Component({
  selector: 'app-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
})
export class MobileLayoutComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  static readonly ROUTE_DATA_PAGENAME = 'pagename';

  @ViewChild(RouterOutlet) refresh;
  @ViewChild(ListAppointmentComponent) appointment;
  @ViewChild(InviteMemberGroupComponent)
  inviteMember: InviteMemberGroupComponent;
  @ViewChild(ConfigGroupComponent) configGroup: ConfigGroupComponent;
  @ViewChild(VisitDetailComponent) visitDetail: VisitDetailComponent;
  @ViewChild(NotificationsComponent) notiComponent: NotificationsComponent;

  isCollapsed = false;
  isVisibleSelectGroup = false;
  reload = false;
  username = 'User';
  sub: Subscription;
  menus: Menu[] = [];
  selectedMenu: Menu;
  pageName: string;
  tabs: Array<any> = [];

  isShowDDLGroup = false;
  isShowMemberRoom: boolean;
  public indexTab = 0;
  listDuplicatePatient: [];
  patient: Patient = {};
  sendEditedPatient: Patient;
  listGroups: Array<MenuItemTree> = [];
  listMenuTemp: Array<MenuItemTree> = []; //Lưu để dùng khi reset search
  selectedGroupId = '000000000000000000000000';
  selectedGroupName = 'Chọn nhóm làm việc';
  selectedGroupAvatar = '';
  //newGroupId: string = '000000000000000000000000';
  isVisibleAddGroup = false;
  //isVisibleSettingGroup: boolean = false;
  isVisibleManagerGroup = false;
  isVisibleAddMemberGroup = false;
  windowWidth: number;
  currentTabId: string;
  myIsAdminGroup = false;
  isVisibleMemberRoom = false;
  @Input() updateListVisit = this.selectedGroupId;
  @ViewChild('mainContent') mainContent: ElementRef;
  @ViewChild('customHtml') customHtml: TemplateRef<any>;
  windowHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  message: any;
  titleMessage: any;
  bodyMessage: any;
  typeMessage: any;
  msg: any;
  numOfNewNotifi = 0;
  numOfUnreadNotifi = 0;
  showAllNotifications = false;
  listNotification: any;
  PathAvatar: string = this.configService.getConfig().media.baseUrl;
  groupSub: Subscription;
  isCovidGroup = false;
  isSystemAdmin = false;
  firebaseInstance: any;
  source$: Observable<Event>;
  lastToken = '';
  curToken = '';
  curLock = '1';
  isMobile: boolean;
  activetab: string;

  sideBarLeftShow: boolean;
  sideBarRightShow: boolean;
  notificationSideBarRightShow: boolean;
  unReadHtml: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    if (this.windowWidth <= 1200) {
      this.isCollapsed = true;
    }
    this.setSidebarWidth(this.isCollapsed);
  }
  constructor(
    private userService: UserService,
    private configService: AppConfigService,
    private route: Router,
    private activeRoute: ActivatedRoute,
    private store: Store<AppState>,
    private authService: AuthService,
    private groupTreeService: GroupTreeService,
    private tabDataService: TabDataService,
    private cdr: ChangeDetectorRef,
    public messagingService: MessageService,
    private groupMemberService: GroupMemberService,
    private notification: NzNotificationService,
    private videoConferenceService: VideoConferenceService,
    private notificationService: NotificationService,
    private shareService: ShareService,
    private chatService: ChatService //private doctorService: DoctorService,
  ) {
    this.tabDataService.currentTabId.subscribe((id) => {
      this.currentTabId = id;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    this.isMobile = window.innerWidth < 540;
    const roots = window.location.pathname.split('/');
    this.activetab = roots.length === 0 ? 'group-dashboard' : roots[roots.length - 1];

    this.getTreeMenu();
    this.getCurrentGroupFromDB();
    //update info if group is edited in page group-dashboard or tree-manager-group
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.selectedGroupId = data.groupId;
        this.selectedGroupName = data.groupName;
        this.selectedGroupAvatar = data.groupAvatar;
        this.isCovidGroup = data.isCovidGroup;
        if (this.isCovidGroup) {
          this.tabDataService.reInitTab('ListPatient', 'Danh sách bệnh nhân');
        } else {
          this.tabDataService.reInitTab('ListVisit', 'Danh sách ca khám');
        }
      }
    });

    this.countNewNotifi();
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.messagingService.messageSource.subscribe((mess) => {
      this.msg = mess;
      if (this.msg.notification !== undefined) {
        let duration = 25000;
        if (this.msg.data.type === 'InviteRoom') {
          duration = 0;
        }
        this.notification.remove('');
        this.notification.template(this.customHtml, {
          nzDuration: duration,
          nzPlacement: 'bottomRight',
        });
        this.updateNewNotifi(true);
      }
    });

    let userInfo;
    this.store
      .select((store) => store.auth)
      .subscribe((res) => {
        userInfo = res;
      });
    //Check nếu là userGuest thì remove token + bắt login
    if (userInfo.userName === 'shareable') {
      localStorage.removeItem(Constant.TOKEN);
      localStorage.removeItem(Constant.FIREBASE_TOKEN);
      localStorage.removeItem(Constant.USER_INFO);
      this.route.navigate(['/login']);
    }

    this.getTabs();

    this.username = userInfo.userName;
    if (!this.shareService.checkEmpty(userInfo.userId)) {
      setTimeout(() => {
        this.firebaseInstance = firebase.database().ref('notify-count/' + userInfo.userId);
        this.firebaseInstance.on('child_changed', (resp2: any) => {
          const item = resp2.val();
          this.numOfNewNotifi = item;
        });
        this.firebaseInstance.on('child_added', (resp2: any) => {
          const item = resp2.val();
          this.numOfNewNotifi = item;
        });
      }, 2000);
    }
    this.lastToken = localStorage.getItem(Constant.TOKEN);
    this.store.select('auth').subscribe((res) => {
      if (res) {
        this.lastToken = res.value;
        this.isSystemAdmin =
          res.userType.includes(Constant.USERROLE.SystemAdmin) || res.userType.includes(Constant.USERROLE.Admin);
      } else {
        this.lastToken = '';
      }
    });
  }

  updateNewNotifi(msg) {
    if (msg != null) {
      this.numOfNewNotifi = this.numOfNewNotifi + 1;
    }
  }
  countNewNotifi() {
    this.userService.countNewNotifi().subscribe((res) => {
      if (res !== null) {
        if (res.isValid) {
          this.numOfNewNotifi = parseInt(res.jsonData);
        }
      }
    });
  }
  getTreeMenu() {
    this.groupTreeService.resolveIems(false).subscribe(
      (res) => {
        if (res !== null) {
          this.isShowDDLGroup = false;
          const list = res;
          for (let i = 0; i < list.length; i++) {
            list[i].name = res[i].text;
            list[i].expanded = false;
            if (list[i].children != null && list[i].children.length > 0) {
              list[i].children = this.recuisiveList(list[i].children);
            }
            if (list[i].isLeaf == true) {
              if (list[i].icon == '' || list[i].icon == null) {
                list[i].icon = 'assets/image/group.svg';
              } // Add "total": 2 to all objects in array
            } else {
              list[i].icon = 'assets/image/folder.svg';
            } // Add "total": 2 to all objects in array
          }
          this.listGroups = list;
          this.listMenuTemp = list;
        }
      },
      (error) => {
        console.log('getTreeMenu err', error);
      }
    );
  }

  getFirstItem() {
    for (let i = 0; i < this.listGroups.length; i++) {
      if (this.listGroups[i].isLeaf == true) {
        return this.listGroups[i];
      }
    }
  }

  getCurrentGroupFromDB() {
    this.groupTreeService.getCurrentGroup().subscribe((res) => {
      if (res != null) {
        this.changeSelectedGroupName(res);
      } else {
        const firstGroup = this.getFirstItem();
        if (firstGroup != null) {
          const item = {
            groupId: firstGroup.id,
            groupAvatar: firstGroup.icon,
            groupName: firstGroup.name,
          };
          this.changeSelectedGroupName(item);
        }
      }
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isCollapsed = true;
    }, 100);
    const $this = this;

    $('.ant-tabs-top > .ant-tabs-nav').css({
      margin: '0px',
      border: 'none',
    });
    $(document).mouseup(function (e) {
      if (
        $(e.target).closest('.menu-doctor-group').length === 0 &&
        $(e.target).closest('.ant-menu-submenu-popup').length === 0
      ) {
        //
        $('.menu-doctor-group').hide();
        $this.listGroups = $this.listMenuTemp;
      }
    });
    window.onclick = function (event) {
      if (
        document.getElementsByClassName('dropdown-content')[0] &&
        !document.getElementsByClassName('dropdown-content')[0].contains(event.target) &&
        !document.getElementsByClassName('dropdown')[0].contains(event.target)
      ) {
        $('#myDropdown').hide(300);
      }
    };
    $('.group-name').click(function (event) {
      // stops the bubbling of an event to parent elements, preventing any parent event handlers from being executed.
      event.stopPropagation();
      $this.isShowDDLGroup = true;
    });
  }

  clickPage() {
    if (localStorage.getItem(Constant.LOCKSTATE) == '1') {
      localStorage.setItem(Constant.LOCKSTATE, '2');
    } else {
      localStorage.setItem(Constant.LOCKSTATE, '1');
    }
    this.store.dispatch(new ResetLockScreen());
  }

  recuisiveList(lst) {
    lst.forEach((res) => {
      res.name = res.text;
      res.expanded = false;
      if (res.children != null && res.children.length > 0) {
        res.children = this.recuisiveList(res.children);
      }
      if (res.isLeaf == true) {
        if (res.icon == '' || res.icon == null) {
          res.icon = 'assets/image/group.svg';
        } // Add "total": 2 to all objects in array
      } else {
        res.icon = 'assets/image/folder.svg';
      }
    });
    return lst;
  }

  toggleMenu(menu: string) {
    this.sideBarLeftShow = !this.sideBarLeftShow;
    this.sideBarRightShow = false;
    this.notificationSideBarRightShow = false;
  }

  logout() {
    if (this.configService.getConfig().isUseSSOLogin) {
      const returnURL = window.location.origin + '/sso-login';
      this.store.dispatch(new SSOLogout(returnURL));
    } else {
      this.sub = this.authService.logout({ returnUrl: '' }).subscribe((res) => {
        this.store.dispatch(new Logout(''));
        this.route.navigate(['/login']);
      });
    }
  }

  openUserProfile() {
    this.route.navigate(['/user-profile']);
  }
  private getPageInfo() {
    let child = this.activeRoute.firstChild;
    while (child.firstChild) {
      child = child.firstChild;
    }
    if (child.snapshot.data[MobileLayoutComponent.ROUTE_DATA_PAGENAME]) {
      return child.snapshot.data[MobileLayoutComponent.ROUTE_DATA_PAGENAME];
    }
    return '';
  }

  getTabs() {
    this.tabDataService.share.subscribe((tabss) => {
      //this.tabs = this.tabDataService.tabs(this.isCovidGroup);
      this.tabs = this.tabDataService.tabs();
      this.indexTab = this.tabDataService.indextab;
      this.cdr.detectChanges();
    });
  }
  closeTab(tab: any): void {
    this.tabDataService.closeTab(tab);
  }
  reloadTab(tab: any): void {
    tab.reload = true;
    setTimeout(() => {
      tab.reload = false;
    }, 200);
  }

  openNewTab(id: string, tab: string, type: string) {
    this.tabDataService.updateTab(id, tab, type, '');
  }
  openListVist(id: string) {
    this.tabDataService.changeTab(id);
    this.indexTab = 0;
  }
  showTreeGroup() {
    this.isShowDDLGroup = !this.isShowDDLGroup;

    this.groupMemberService.getMyGroupMember(this.selectedGroupId).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.myIsAdminGroup = res.jsonData.isAdmin || res.jsonData.isOwner;
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  hidemenu() {
    if (this.isCollapsed) {
      $('.mytree').hide();
    }
  }
  showmenu() {
    if (this.isCollapsed) {
      this.getUnReadMessage();
      $('.mytree').show();
    }
  }

  getUnReadMessage() {
    this.unReadHtml = '';
    this.chatService.getUnReadMessage().subscribe((res) => {
      let unReadCount = res.jsonData;
      if (+unReadCount > 0) {
        if (+unReadCount > 5) {
          unReadCount = '5+';
        }
        this.unReadHtml = `${unReadCount}`;
      }
    });
  }

  selectGroup(obj) {
    this.isShowDDLGroup = false;
    if (this.selectedGroupId != obj.id) {
      this.groupMemberService.setCurrentGroup(obj.id).subscribe((res) => {
        window.location.href = 'common-mobile/worklist/group-dashboard';
      });
    }
  }
  // changeSelectedGroupName(_id, _groupName, _groupAvatar)
  changeSelectedGroupName(groupObj) {
    this.selectedGroupId = groupObj.groupId; //_id;
    this.selectedGroupName = groupObj.groupName; //_groupName;
    this.selectedGroupAvatar = groupObj.groupAvatar; //_groupAvatar;

    // console.log("call this.store.dispatch(new Join.. here in changeSelectedGroupName, id: " + groupObj.id);
    this.store.dispatch(new Join(groupObj)); //_id
  }
  hoverParent(parentId) {
    alert('hell');
  }

  emptyFunction(): void {}

  addPatientEvent(value): void {
    this.patient = value;
  }
  addGroupEvent(newMenu): void {
    this.listGroups.push(newMenu);
    this.listMenuTemp = this.listGroups;
  }
  emitEditTreeGroupEvent(): void {
    this.getTreeMenu();
  }

  duplicatePatientEvent(data): void {
    if (data.res.isSuccess) {
    } else {
      this.store.dispatch(new OpenDuplicatePatient());
      this.listDuplicatePatient = data.res.duplicatePatients;
      this.sendEditedPatient = data.form;
    }
  }

  selectPatientEvent(value): void {
    this.patient = value;
  }
  openModelAddGroup() {
    //alert(1);
    this.isVisibleAddGroup = true;
    this.isShowDDLGroup = false;
  }
  openModelManagerGroup() {
    this.isVisibleManagerGroup = true;
    this.isShowDDLGroup = false;
  }

  closeAddGroup(isRefresh) {
    this.isVisibleAddGroup = false;
  }
  // Mở tạo phòng họp/ trao đổi
  openModelMemberRoom() {
    this.isVisibleMemberRoom = true;
    this.isShowMemberRoom = false;
  }

  closeModalRoom() {
    this.isVisibleMemberRoom = false;
  }
  closeManagerGroup(isRefresh) {
    this.isVisibleManagerGroup = false;
    this.getTreeMenu();
  }

  refreshDataListVisit() {
    if (this.refresh && this.refresh.component) {
      this.refresh.component.searchListVisit();
    }
  }
  refreshDataAppointment() {
    if (this.appointment) {
      this.appointment.getListAppointment();
    }
  }

  onTabClick(tabId: any) {
    this.tabDataService.changeTab(tabId);
  }

  showPage() {
    $('#loader').fadeOut(500);
  }
  logoCollap() {
    if (!window.matchMedia('(max-width: 1200px)').matches) {
      this.isCollapsed = !this.isCollapsed;
    }
    this.setSidebarWidth(this.isCollapsed);
  }

  setSidebarWidth(isCollapsed) {
    if (!isCollapsed) {
      document.documentElement.style.setProperty('--w-sidebar-width', '240px');
    } else {
      document.documentElement.style.setProperty('--w-sidebar-width', '60px');
    }
  }

  onScroll(e) {
    const scrollTop = $('#mainContent').scrollTop();
    const top = Math.max(350 - scrollTop, 100);
    const mgTop = Math.max(450 - scrollTop, 200);
    const height = this.windowHeight - mgTop;
    this.visitDetail.onScroll({ top, height });
  }

  getPatient(p) {
    this.patient = p;
  }

  collapsedChange($event) {
    this.setSidebarWidth($event);
  }

  acceptJoin(_notiId: string, isOk: boolean, temp: any) {
    //let objMsg=JSON.parse(msg);
    let notiId = '';
    if (_notiId != null) {
      notiId = _notiId;
    } else {
      notiId = this.msg.data.info;
    }

    this.videoConferenceService.joinRoom(notiId, isOk).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            if (isOk) {
              if (temp !== null) {
                temp.close();
              } else {
                this.listNotification = this.listNotification.map((e) =>
                  e.id == notiId
                    ? {
                        ...e,
                        viewed: true,
                        hasButton: 0,
                        actionHtml:
                          'Bạn đã đồng ý join room. Nhấn vào <a target="_blank" href="' +
                          e.link +
                          '" >đây</a> để tham gia',
                      }
                    : e
                );
              }

              window.open(
                window.location.origin + '/video-conference/' + res.jsonData.roomId,
                '_blank',
                'toolbar=0,location=0,menubar=0'
              );
            } else {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.INFO,
                'Bạn đã từ chối tham gia nhóm hội chẩn!'
              );
              if (temp !== null) {
                temp.close();
              } else {
                this.listNotification = this.listNotification.map((e) =>
                  e.id == notiId
                    ? {
                        ...e,
                        viewed: true,
                        hasButton: 0,
                        actionHtml: 'Bạn đã từ chối tham gia hội chẩn',
                      }
                    : e
                );
              }
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }

  readNotifi(notiId) {
    this.userService.viewNotifi(notiId).subscribe((res) => {
      if (res !== null) {
        if (res.isValid) {
          const viewItem = this.listNotification.filter((en) => en.id === notiId)[0];
          viewItem.viewed = true;
        }
      }
    });
  }
  acceptJoinGroup(_notiId, isOK, temp) {
    let codeInviteId = '';
    let notiId = '';
    if (_notiId === null) {
      notiId = this.msg.data.info;
    } else {
      notiId = _notiId;
    }
    const payload = {
      notiId,
      isOK,
    };
    this.userService.confirmInviteGroup(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            codeInviteId = res.jsonData.invitaionId;
            if (temp !== null) {
              temp.close();
            } else {
              this.listNotification = this.listNotification.map((e) =>
                e.id == notiId ? { ...e, hasButton: 0, viewed: true } : e
              );
            }
            if (isOK) {
              this.route.navigateByUrl('/confirm-invite?Code=' + codeInviteId + '&Type=1'); //http://localhost:4200/confirm-invite?Code=60b8c0ecb029ff1ef5d86b44&Type=1
            } else {
              this.route.navigateByUrl('/confirm-invite?Code=' + codeInviteId + '&Type=-1'); //http://localhost:4200/confirm-invite?Code=60b8c0ecb029ff1ef5d86b44&Type=1
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  setLinkHoiChan(link) {
    return 'Ấn vào <a target="_blank" href="' + link + '">đây</a> để tham gia hội chẩn';
  }
  changeNotification(event) {
    if (event) {
      this.userService.getTopNotifi().subscribe((res) => {
        if (res !== null && res.isValid) {
          this.listNotification = res.jsonData.topNoti;
          this.numOfUnreadNotifi = res.jsonData.totalNoti;
          this.numOfNewNotifi = 0; //reset new notification count
        }
      });
    }
  }
  toggleNotification() {
    this.route.navigate(['/common-mobile/worklist/notify']);
  }
  viewAllNoti() {
    this.showAllNotifications = true;
    this.notiComponent.callPager(1);
  }
  closeViewAllNoti(event) {
    this.showAllNotifications = false;
  }

  async searchGroup(event) {
    this.listGroups = JSON.parse(JSON.stringify(this.listMenuTemp));
    let keyword = event.target.value.trim();
    if (keyword.length <= 1) {
      return;
    }
    keyword = keyword.toLowerCase();
    let isOpen = false;
    const listSearch = [];
    for (let i = 0; i < this.listGroups.length; i++) {
      let children = [];
      let item = null;
      if (this.listGroups[i].children != null && this.listGroups[i].children.length > 0) {
        children = await this.searchGroupRecuisive(this.listGroups[i].children, keyword);
      }
      if (this.listGroups[i].name.toLowerCase().indexOf(keyword) !== -1 || children.length > 0) {
        item = this.listGroups[i];
        if (!isOpen && children.length > 0) {
          isOpen = true;
          item.expanded = true;
        }
        item.children = children;
        listSearch.push(item);
      }

      children = [];
    }
    this.listGroups = listSearch;
  }
  async searchGroupRecuisive(lst, kw) {
    const listSearch = [];
    for (let i = 0; i < lst.length; i++) {
      let children = [];
      let item = null;
      if (lst[i].children != null && lst[i].children.length > 0) {
        children = await this.searchGroupRecuisive(lst[i].children, kw);
      }
      if (lst[i].name.toLowerCase().indexOf(kw) !== -1 || children.length > 0) {
        item = lst[i];
        item.children = children;
        listSearch.push(item);
      }

      children = [];
    }
    return listSearch;
  }
  ngOnDestroy() {
    this.groupSub.unsubscribe();
  }
  goFromNotify(item) {
    if (item.referenceInfo == null || item.referenceInfo === '') {
      return;
    }
    if (item.type == Constant.NotificationType.Visit) {
      var arr = item.referenceInfo.split('#');
      if (arr.length != 3) {
        return;
      }
      this.tabDataService.updateTab(arr[0], arr[1], 'VisitDetail', arr[2]);
    } else if (item.type == Constant.NotificationType.ChatInVisit) {
      var arr = item.referenceInfo.split('#');
      if (arr.length != 2) {
        return;
      }
      //TODO: show tab chat when open visit
      this.tabDataService.updateTab(arr[0], arr[1], 'VisitDetail', '');
    } else if (item.type == Constant.NotificationType.Conversation) {
      //userId + "#" + userFullName
      var arr = item.referenceInfo.split('#');
      if (arr.length != 2) {
        return;
      }
      this.tabDataService.updateTab('chat', 'Tin nhắn', arr[1], arr[0]);
    }
    this.readNotifi(item.id);
    this.notificationSideBarRightShow = false;
  }

  onClickedOutside($event) {}

  menuClick(groupDashboard: string) {
    //common-mobile/worklist/patient-list
    location.href = `/common-mobile/worklist/${groupDashboard}`;
  }

  toggleUserInfo(s: string) {
    this.sideBarRightShow = !this.sideBarRightShow;
    this.sideBarLeftShow = false;
    this.notificationSideBarRightShow = false;
  }

  closeAllSideBar() {
    this.sideBarRightShow = false;
    this.sideBarLeftShow = false;
    this.notificationSideBarRightShow = false;
  }

  toggleNotificationSideBar() {
    this.notificationSideBarRightShow = !this.notificationSideBarRightShow;
    this.sideBarLeftShow = false;
    this.sideBarRightShow = false;
  }

  showModalGroup() {
    this.isVisibleSelectGroup = true;
  }

  closeModalGroup() {
    this.isVisibleSelectGroup = false;
  }
}
