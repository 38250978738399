import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterItem } from 'src/app/models/filter-model/filter-item.class';
import { SearchListPatient } from 'src/app/models/list-patient.class';

@Component({
  selector: 'app-input-number-filter',
  templateUrl: './input-number-filter.component.html',
  styleUrls: ['./input-number-filter.component.scss'],
})
export class InputNumberFilterComponent implements OnInit {
  _searchItem: FilterItem;
  _searchParam: SearchListPatient;
  _value: number;
  @Input() set searchItem(val: FilterItem) {
    this._searchItem = val;
    if (this._searchParam) {
      this._value = this._searchParam[this._searchItem.field];
    }
  }

  get searchItem(): FilterItem {
    return this._searchItem;
  }

  @Input() set searchParam(val: SearchListPatient) {
    this._value = val[this.searchItem.field];
    this._searchParam = val;
  }

  get searchParam() {
    return this._searchParam;
  }

  @Output() seachListPatient: EventEmitter<any> = new EventEmitter();
  @Output() mFilterValueChange: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  seachNow() {
    this.seachListPatient.emit();
  }

  onModelChange(val) {
    if (this.searchItem.isOT) {
      let curOt = this._searchParam.oTSearchFields.find(
        (t) =>
          t.oTCode == this._searchItem.field &&
          t.operator == this._searchItem.operator
      );
      if (curOt) {
        curOt.valueInteger = this._value;
      } else {
        curOt = {};
        curOt.oTCode = this._searchItem.field;
        curOt.dataType = this._searchItem.otDatatype;
        curOt.valueInteger = this._value;
        curOt.operator = this._searchItem.operator;
        this._searchParam.oTSearchFields.push(curOt);
      }
    } else {
      this._searchParam[this.searchItem.field] = this._value;
    }

    this.mFilterValueChange.emit(this._searchParam);
  }
}
