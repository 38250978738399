<div class="msc-m-vist-info">
  <div class="group-box">
    <div class="box-title" (click)="toggleCollapse(0)">
      <strong>LÝ DO KHÁM/ĐIỀU TRỊ</strong>
      <i nz-icon [nzType]="arrCollapse[0] ? 'caret-up' : 'caret-down'"></i>
    </div>
    <div [ngClass]="arrCollapse[0] ? 'box-content show' : 'box-content hide'">
      <textarea
        class="textarea-box"
        nz-input
        placeholder="Đây là lí do khám của bệnh nhân"
        [(ngModel)]="visit.visitReason"
        (ngModelChange)="onTypingReason($event)"></textarea>
    </div>
  </div>
  <div class="group-box">
    <div class="box-title" (click)="toggleCollapse(1)">
      <strong>TRIỆU CHỨNG LÂM SÀNG</strong>
      <!-- <i *ngIf="visit.clinicalInfomation && visit.clinicalInfomation != '' && !onlyView" class="icon-md" nz-icon nzType="copy" title="Thêm vào master note" nzTheme="outline"
    (click)="sendConclusionToMasterNoteAndClinicalInfomation()"></i> -->
      <i nz-icon [nzType]="arrCollapse[1] ? 'caret-up' : 'caret-down'"></i>
    </div>
    <div [ngClass]="arrCollapse[1] ? 'box-content show' : 'box-content hide'">
      <textarea
        class="textarea-box"
        nz-input
        placeholder="Nhập triệu chứng lâm sàng"
        [(ngModel)]="visit.clinicalInfomation"
        (ngModelChange)="onTypingClinicalInfo($event)"></textarea>
    </div>
  </div>
  <div class="group-box">
    <div class="box-title" (click)="toggleCollapse(2)">
      <strong>CHẨN ĐOÁN THEO MÃ ICD 10</strong>
      <i nz-icon [nzType]="arrCollapse[2] ? 'caret-up' : 'caret-down'"></i>
    </div>
    <div [ngClass]="arrCollapse[2] ? 'box-content show' : 'box-content hide'">
      <div class="disease-box">
        <div class="input-diseases {{ hideButton }}">
          <nz-select
            [nzShowSearch]="true"
            [nzShowArrow]="true"
            [nzServerSearch]="true"
            [(ngModel)]="suggestDiseases"
            nzPlaceHolder="Tìm kiếm bệnh theo mã ICD10"
            (nzOnSearch)="onTypingICD($event)"
            (ngModelChange)="fieldSelectedICD10($event)">
            <nz-option
              *ngFor="let item of diseasesData"
              nzCustomContent
              [nzLabel]="item.code"
              [nzValue]="item">
              <div>{{ item.code }} - {{ item.name }}</div>
            </nz-option>
          </nz-select>
        </div>
        <ul id="ul-disease">
          <li *ngFor="let disease of lstDisease" style="width: 100%">
            {{ disease.code }} - {{ disease.name }}
            <i
              class="{{ hideButton }}"
              nz-icon
              nzType="close"
              nzTheme="outline"
              style="margin-left: auto"
              (click)="removeDiseases($event, disease.id)"></i>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="group-box">
    <div class="box-title" (click)="toggleCollapse(3)">
      <strong>KẾT LUẬN CỦA BÁC SĨ</strong>
      <!-- <i *ngIf="visit.conclusion && visit.conclusion != '' && !onlyView" class="icon-md" nz-icon nzType="copy" title="Thêm vào master note" nzTheme="outline"
    (click)="sendConclusionToMasterNoteAndClinicalInfomation()"></i> -->
      <i nz-icon [nzType]="arrCollapse[3] ? 'caret-up' : 'caret-down'"></i>
    </div>
    <div [ngClass]="arrCollapse[3] ? 'box-content show' : 'box-content hide'">
      <textarea
        class="textarea-box"
        nz-input
        placeholder="Nhập kết luận của bác sĩ"
        [(ngModel)]="visit.conclusion"
        (ngModelChange)="onTypingConclusion($event)"
        [disabled]="onlyView"></textarea>
    </div>
  </div>
  <div class="group-box">
    <app-ros-and-subclinic
      [hideButton]="hideButton"
      [visitId]="visit.id"
      (openPhieudl)="openPhieudl($event)"></app-ros-and-subclinic>
  </div>
</div>
