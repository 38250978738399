import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageElement } from 'src/app/models/form-model/form.class';

@Component({
  selector: 'app-image-element',
  templateUrl: './image-element.component.html',
  styleUrls: ['./image-element.component.scss'],
})
export class ImageElementComponent implements OnInit {
  @Input() data: any;
  @Output() selectElement: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {
    // console.log(this.data);
  }
}
