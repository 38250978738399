import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { AppState } from 'src/app/components/app-state/app-state';
import { GroupTreeService } from 'src/app/services/groupTree.service';
import { MedicalInstructionService } from 'src/app/services/medical-instruction.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-medical-instruction',
  templateUrl: './medical-instruction.component.html',
  styleUrls: ['./medical-instruction.component.scss'],
})
export class MedicalInstructionComponent implements OnInit {
  textSearch = '';
  pageIndex = 1;
  pageSize = 10;
  total = 0;
  isVisible = false;
  listData: any;

  itemEdit = {
    id: '',
    name: '',
    description: '',
    type: 1,
    privacy: 1,
  };
  lstGroupName = [];
  lstGroupId = [];
  groupShare = '';
  searchDataGroupFilter = [];
  curUserId = '';
  isVisibleView = false;
  isLoading = false;

  constructor(
    private groupTreeService: GroupTreeService,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private medicalInstructionService: MedicalInstructionService,
    private shareService: ShareService,
    private modalService: NzModalService
  ) {
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
  }

  ngOnInit(): void {}
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.getByFilter();
  }
  getByFilter() {
    this.isLoading = true;
    const payload = {
      keyword: this.textSearch,
      page: this.pageIndex,
      pageSize: this.pageSize,
    };
    this.medicalInstructionService.getByFilter(payload).subscribe((res) => {
      this.total = res.total;
      this.listData = res.data;
      this.isLoading = false;
      //console.log(this.listData);
    });
  }
  showModalForm(type, data) {
    if (type == 'add') {
      this.itemEdit = {
        id: '',
        name: '',
        description: '',
        type: 1,
        privacy: 1,
      };
      this.lstGroupName = [];
      this.lstGroupId = [];
      this.isVisible = true;
    } else if (type == 'update') {
      this.itemEdit = {
        id: data.id,
        name: data.name,
        description: data.description,
        type: data.type,
        privacy: data.privacy,
      };
      this.lstGroupName = data.groupNames;
      this.lstGroupId = data.groupIds;
      this.isVisible = true;
    } else if (type == 'view') {
      this.itemEdit = {
        id: data.id,
        name: data.name,
        description: data.description,
        type: data.type,
        privacy: data.privacy,
      };
      this.lstGroupName = data.groupNames;
      this.lstGroupId = data.groupIds;
      this.isVisibleView = true;
    }
  }
  handleCancel() {
    this.isVisible = false;
  }
  submit() {
    const data = this.itemEdit;
    if (
      this.shareService.checkEmptyStr(data.name) ||
      this.shareService.checkEmpty(data.privacy) ||
      this.shareService.checkEmptyStr(data.description) ||
      (data.privacy == 2 && this.lstGroupId.length == 0)
    ) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn cần nhập các trường thông tin bắt buộc'
      );
      return;
    }
    //console.log("submit", this.itemEdit)
    const payload = { ...this.itemEdit, groupIds: this.lstGroupId.toString() };
    if (this.itemEdit.id == '') {
      this.medicalInstructionService.add(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
          this.getByFilter();
          this.isVisible = false;
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    } else {
      this.medicalInstructionService.update(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
          this.getByFilter();
          this.isVisible = false;
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    }
  }
  remove(id: string) {
    //console.log("remove", id);
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Bạn có muốn xóa hay không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Bỏ qua',
      nzOnOk: () => {
        this.medicalInstructionService.remove(id).subscribe((res) => {
          if (res.isValid) {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
            this.getByFilter();
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
          }
        });
      },
    });
  }
  onSearchGroup(keyword) {
    if (keyword.trim().length > 1) {
      this.groupTreeService.getListGroup(keyword).subscribe(
        (response) => {
          if (response !== null) {
            this.searchDataGroupFilter = [...response];
          }
        },
        (error) => {
          console.error(error);
          this.notificationService.showNotification('', 'Lỗi data');
        }
      );
    }
  }
  selectGroup(group) {
    if (this.lstGroupId.filter((en) => en == group.id).length == 0) {
      this.lstGroupId.push(group.id);
      this.lstGroupName.push(group.name);
    }
    this.groupShare = '';
  }
  unSelectGroup(id) {
    this.lstGroupId = this.lstGroupId.filter((en) => en.id !== id);
  }
  toTextPrivacy(privacy: number) {
    return this.medicalInstructionService.toTextPrivacy(privacy);
  }
  toTextType(type: number) {
    return this.medicalInstructionService.toTextType(type);
  }
  cancelView() {
    this.isVisibleView = false;
  }
}
