import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ShareService } from 'src/app/services/share.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { SearchVisitSmall } from 'src/app/models/listVisit.class';
import { Subscription } from 'rxjs';
import { ListVisitService } from 'src/app/services/listVisit-service';
import { NotificationService } from 'src/app/services/notification.service';
import { NzResizeEvent } from 'ng-zorro-antd/resizable';
import { Constant } from 'src/app/share/constants/constant.class';
import { GenderToTextPipe } from 'src/app/share/pipe/gender-to-text.pipe';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { UpdateRoom } from '../../auth/redux/auth.action';
import { PatientRoomService } from 'src/app/services/patient-room.service';
import { PatientRoom } from 'src/app/models/patient-room';
import { VisitService } from 'src/app/services/visit.service';

interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}
const TABLE_HEIGHT_SHORT = 'calc(100vh - 380px)';
const TABLE_HEIGHT_LONG = 'calc(100vh - 200px)';
@Component({
  selector: 'app-list-visit-small',
  templateUrl: './list-visit-small.component.html',
  styleUrls: ['./list-visit-small.component.scss'],
})
export class ListVisitSmallComponent implements OnInit, AfterViewInit, OnDestroy {
  _listVisitSize: number;
  @Input()
  set listVisitSize(listVisitSize: number) {
    this._listVisitSize = listVisitSize;
    setTimeout(() => {
      this.setLastColumnWidth();
    }, 50);
  }
  get listVisitSize() {
    return this._listVisitSize;
  }

  _currentVisit: any = {};
  @Input()
  set currentVisit(data) {
    if (data !== null) {
      for (let i = 0; i < this.listVisits.length; ++i) {
        if (this.listVisits[i].id == data.id) {
          if (this._currentVisit.visitStatus != data.status) this.listVisits[i].visitStatus = data.status;
          this.listVisits[i].isReceived = data.isReceived;
          break;
        }
      }
    }
    this._currentVisit = data;
  }
  get currentVisit() {
    return this._currentVisit;
  }

  @Output() selectVisitEvent: EventEmitter<any> = new EventEmitter();
  @Output() currentVisitChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('tableContainer') private tableContainer: ElementRef;

  timer: any;
  pageIndex = 1;
  pageSize = 30;
  listVisits = [];

  loading = false;
  scrollY: string = window.innerHeight - 300 + 'px';
  selectedGroupId = '';
  search: SearchVisitSmall = {
    patientName: '',
    patientCode: '',
    visitDate: '',
    visitStatus: null,
    isReceived:null,
  };
  searchDataIsReceived = false;
  cols = [
    {
      title: 'STT',
      width: 30,
    },
    {
      title: 'TÊN BỆNH NHÂN',
      width: 150,
    },
    {
      title: 'TUỔI, GIỚI TÍNH',
      width: 80,
    },
    {
      title: 'TRẠNG THÁI',
      width: 50,
    },
  ];
  VISIT_STATUS = Constant.VISIT_STATUS;
  isExpandSearch = false;
  maxTableHeight = TABLE_HEIGHT_LONG;
  totalVisit = 0;
  groupSub: Subscription;
  userRoomSub: Subscription;
  isVisibleSelectRoom = false;
  rooms: PatientRoom[] = [];
  currentUserRoomId = '';
  currentUserRoomName = '';
  currentUser = null;

  isDoctorSelectRoom = false;
  isUseDividePatientRoom = false;
  isUpdateUserRoom = false;
  useRoomOrDoctorVisit = false;

  curUserId = '';
  emptyId = '000000000000000000000000';

  intervalReloadGrid: any;
  reloadTime = 30000;
  isVisibleReceiveVisit = false;
  visitReceiveInfo = null;

  constructor(
    private shareService: ShareService,
    private store: Store<AppState>,
    private listVisitService: ListVisitService,
    private notificationService: NotificationService,
    private genderConvert: GenderToTextPipe,
    private roomService: PatientRoomService,
    private userprofileService: UserProfileService,
    private visitService: VisitService
  ) {
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
  }

  ngOnInit(): void {
    this.groupSub = this.store
      .select((store) => store.groupAuth)
      .subscribe((data) => {
        if (data.isAuthenticated) {
          if (!this.shareService.checkEmpty(data)) {
            this.selectedGroupId = data.groupId;
            this.isUseDividePatientRoom = data.isUseDividePatientRoom;
            this.isDoctorSelectRoom = data.isDoctorSelectRoom;
            this.useRoomOrDoctorVisit = data.getVisitByRoomOrDoctor;
          }
        }
      });
    this.userRoomSub = this.store
      .select((store) => store.auth.roomName)
      .subscribe((data) => {
        this.currentUserRoomName = data;
      });
    this.getRoomInGroup(this.selectedGroupId);

    this.search.visitDate = this.shareService.getToday();
    if (this.useRoomOrDoctorVisit) {
      if (this.shareService.checkEmpty(this.currentUserRoomId) || this.currentUserRoomId == this.emptyId) {
        this.getUserInfo();
      } else this.searchListVisit();
    } else this.searchListVisit();
    console.log(this.useRoomOrDoctorVisit);
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setLastColumnWidth();
    }, 50);
  }

  getUserInfo() {
    this.userprofileService.GetInfo().subscribe((res) => {
      if (res.d?.isValid) {
        this.currentUser = res.d.jsonData;
        this.currentUserRoomId = res.d.jsonData.roomId;
        this.currentUserRoomName = res.d.jsonData.roomName;

        if (
          this.isUseDividePatientRoom &&
          this.isDoctorSelectRoom &&
          (this.shareService.checkEmpty(this.currentUserRoomId) || this.currentUserRoomId == this.emptyId)
        ) {
          this.openSelectRoom(false);
        }
        if (this.useRoomOrDoctorVisit) {
          this.getListVisit();
        }
        this.store.dispatch(new UpdateRoom(res.d.jsonData.roomId, res.d.jsonData.roomName));
      }
    });
  }
  openSelectRoom(isUpdate) {
    this.isVisibleSelectRoom = true;
    this.isUpdateUserRoom = isUpdate;
  }
  getRoomInGroup(groupId) {
    this.roomService.getAllRoomInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.rooms = res;
        //console.log(res);
      }
    });
  }
  updateUserRoom() {
    if (!this.currentUserRoomId) {
      return this.notificationService.showNotification('warning', 'Bạn chưa chọn phòng khám');
    }
    this.userprofileService.UpdateUserRoom(this.currentUserRoomId).subscribe((res) => {
      if (res.d.isValid) {
        this.notificationService.showNotification('success', 'Cập nhật thành công');
        this.isVisibleSelectRoom = false;
        this.currentUserRoomName = this.rooms.find((r) => r.id == this.currentUserRoomId).name;

        this.store.dispatch(new UpdateRoom(this.currentUserRoomId, this.currentUserRoomName));
        if (this.useRoomOrDoctorVisit) {
          this.searchListVisit();
          this.currentVisit = {};
          this.selectVisitEvent.emit();
        }
        this.moveDoctorRoom();
      } else {
        this.notificationService.showNotification('error', 'Cập nhật không thành công');
      }
    });
  }
  moveDoctorRoom() {
    const oldRoomId = this.currentUser.roomId;
    if (oldRoomId) {
      const payloadRemove = {
        userId: this.curUserId,
        roomId: oldRoomId,
      };
      this.roomService.removeDoctor(payloadRemove).subscribe((res) => {
        if (!res?.isValid) {
          console.log('Lỗi cập nhật room removeDoctor', res.errors[0].errorMessage);
        }
      });
    }
    this.currentUser.roomId = this.currentUserRoomId;
    const payloadAdd = {
      userId: this.curUserId,
      roomId: this.currentUserRoomId,
    };

    this.roomService.addDoctor(payloadAdd).subscribe((res) => {
      if (!res?.isValid) {
        console.log('Lỗi cập nhật room addDoctor', res.errors[0].errorMessage);
      }
    });
  }
  onTableScroll(e) {
    const params: NzTableQueryParams = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      sort: null,
      filter: null,
    };
    this.onQueryParamsChange(params);
  }
  toggleExpandSearch() {
    this.isExpandSearch = !this.isExpandSearch;
    this.maxTableHeight = this.isExpandSearch ? TABLE_HEIGHT_SHORT : TABLE_HEIGHT_LONG;
  }
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.getListVisit();
  }
  onResizeColumn({ width }: NzResizeEvent, col: string): void {
    this.cols = this.cols.map((e) => (e.title === col ? { ...e, width } : e));
    this.setLastColumnWidth();
  }
  setLastColumnWidth() {
    this.cols[3].width =
      this.tableContainer.nativeElement.clientWidth - this.cols[0].width - this.cols[1].width - this.cols[2].width - 70;
  }
  getListVisit() {
    if (this.intervalReloadGrid) {
      clearTimeout(this.intervalReloadGrid);
    }
    const $this = this;
    this.intervalReloadGrid = setTimeout(function () {
      $this.searchListVisit();
    }, this.reloadTime);

    this.loading = true;
    const data = {
      ...this.search,
      inGroup: true,
      pageSize: this.pageSize,
      page: this.pageIndex,
      groupId: this.selectedGroupId,
      roomId: this.currentUserRoomId,
      isGetByRoomOrDoctor: this.useRoomOrDoctorVisit,
    };
    this.listVisitService.getListVisitSmall(data).subscribe(
      (resdata) => {
        if (resdata !== null) {
          if (resdata.source && resdata.itemCount > this.listVisits.length) {
            this.pageIndex += 1;
            this.listVisits = this.listVisits.concat(resdata.source);
            this.listVisits.forEach((el) => {
              if (
                this.useRoomOrDoctorVisit &&
                el.roomId == this.currentUserRoomId &&
                (el.doctorId == this.emptyId || el.doctorId == this.curUserId) &&
                !el.isReceived
              ) {
                el.canReceive = true;
              } else {
                el.canReceive = false;
              }
            });
          }
          if (!this.totalVisit) {
            this.totalVisit = resdata.itemCount;
          }
        }
        this.loading = false;
      },
      (error) => {
        console.error(error);
        this.notificationService.showNotification('', error.statusText);
        this.loading = false;
      }
    );
  }
  onSearchPatientName(e) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.totalVisit = 0;
      this.listVisits = [];
      this.pageIndex = 1;
      this.getListVisit();
    }, 500);
  }
  resetSearch() {
    this.totalVisit = 0;
    this.listVisits = [];
    this.search.patientName = '';
    this.search.patientCode = '';
    this.search.visitDate = this.shareService.getToday();
    this.search.visitStatus = null;
    this.search.isReceived  = null;
    this.pageIndex = 1;
    this.getListVisit();
  }

  searchListVisit() {
    this.totalVisit = 0;
    this.listVisits = [];
    this.pageIndex = 1;
    this.getListVisit();
  }

  getDate(date) {
    if (date) {
      const time = date.setHours(0, 0, 0, 0);
      const date0 = new Date(time);
      this.search.visitDate = date0.toString();
    } else {
      this.search.visitDate = '';
    }
  }

  selectVisit(item) {
    this.currentVisit = item;
    this.resetVisitHeaderHeight();
    this.selectVisitEvent.emit(item.id);
  }

  resetVisitHeaderHeight() {
    document.documentElement.style.setProperty('--h-visit-header', '25px');
  }

  getCurrentUserFullTitle() {
    let title = this.currentUser?.doctorRankName
      ? this.currentUser?.doctorRankName + ' ' + this.currentUser?.fullName
      : this.currentUser?.fullName;
    if (this.currentUserRoomName) {
      title += ' - ' + this.currentUserRoomName;
    }
    return title;
  }

  cancelUpdateUserRoom() {
    this.isUpdateUserRoom = false;
    this.isVisibleSelectRoom = false;
  }

  ngOnDestroy() {
    this.groupSub.unsubscribe();
    this.userRoomSub.unsubscribe();
    if (this.intervalReloadGrid) {
      clearTimeout(this.intervalReloadGrid);
    }
  }
  showReceivePopup(visitData) {
    console.log(this.currentVisit);
    this.visitReceiveInfo = visitData;
    this.isVisibleReceiveVisit = true;
  }
  handleCancel(): void {
    this.isVisibleReceiveVisit = false;
  }
  receiveVisit() {
    this.visitService.receiveVisit(this.visitReceiveInfo.id).subscribe((res) => {
      if (res.isValid) {
        this.selectVisit(this.visitReceiveInfo);
        this.getListVisit();
        this.isVisibleReceiveVisit = false;
        this.notificationService.showNotification(Constant.SUCCESS, Constant.MESSAGE_UPDATE_SUCCESS);
      } else {
        this.notificationService.showNotification(Constant.ERROR, res.errors[0].errorMessage);
      }
    });
  }
  receiveNextVisit() {
    let index = this.listVisits.findIndex((el) => {
      return el.id == this.visitReceiveInfo.id;
    });
    let nextReceiveVisit = this.listVisits.find((el, i) => {
      return el.canReceive && i > index;
    });
    if (nextReceiveVisit) {
      this.visitReceiveInfo = nextReceiveVisit;
    } else {
      this.notificationService.showNotification(Constant.ERROR, 'Không tồn tại bệnh nhân');
    }
  }
}
