<div class="custom-modal">
  <!-- <form  (ngSubmit)="handleOk()"> -->
  <!-- [formGroup]="formInviteMemberGroup" -->
  <!-- <nz-modal class="custom-modal" [(nzVisible)]="isVisible" [nzWidth]="1000" nzTitle="Mời thành viên vào nhóm làm việc"
        (nzOnCancel)="handleCancel()" (nzOnOk)="handleCancel()"> -->
  <app-m-modal
    class="custom-modal"
    [modalId]="'invite-member-group'"
    [isVisible]="isVisible"
    (closeModal)="handleCancel()"
    [mMask]="true"
    [width]="1000"
    [minWidth]="1000"
    [height]="700"
    [minHeight]="600"
    [isResiable]="true">
    <ng-template #mModalTitle>
      <div class="header-title">Mời thành viên vào nhóm làm việc</div>
    </ng-template>
    <ng-template #mModalContent>
      <div class="row invite-modal">
        <div class="col-sm-6 col-sm-left">
          <div class="row mg-t-30 elm-title">Danh sách thành viên</div>
          <div class="row mg-t-30">
            <div class="col-sm-8 pd-r-15 input-row">
              <input
                #txtSearchMember
                (keyup)="loadMember(userType.value, txtSearchMember.value)"
                placeholder="Tìm theo tên hoặc email" />
            </div>
            <div class="col-sm-4 input-row">
              <select
                #userType
                (change)="loadMember(userType.value, txtSearchMember.value)">
                <option value="0">Chọn trạng thái</option>
                <option selected value="1">Đã tham gia</option>
                <option value="-1">Chưa tham gia</option>
              </select>
            </div>
          </div>
          <div class="row mg-t-30">
            <ul id="listMember">
              <li *ngFor="let item of listMember" class="media">
                <div class="media-status">
                  <div class="btn-group">
                    <!-- <img src="../Styles/Images/check-circle-blue-128.png" width="20px" height="20px" title="Đã là thành viên"> -->

                    <!-- <i  nz-icon nzType="bulb" nzTheme="fill" title="Đã là thành viên"></i> -->
                    <a
                      *ngIf="item.isInvited == true"
                      (click)="copytoClipBoard(item)"
                      class="mg-r-10"
                      >
                      <i
                        nz-icon
                        nzType="copy"
                        nzTheme="twotone"
                        title="Copy to clipboard"

                        ></i
                    >
                  </a>
                    <a
                      class="mg-r-10"
                      *ngIf="item.isInvited == true"
                      (click)="reSendInivitaion(item.userId, item.email)"
                      >
                      <i
                        nz-icon
                        nzType="star"
                        nzTheme="twotone"
                        title="Gửi lại lời mời"></i
                    >
                  </a>
                    <a
                      class="mg-r-10"
                      *ngIf="
                        item.isInvited == false &&
                        (myIsAdminGroup || myIsOwnerGroup)
                      "
                      nz-popover
                      nzPopoverPlacement="bottom"
                      nzPopoverTitle="Chỉ định quyền"
                      [nzPopoverTrigger]="'click'"
                      [nzPopoverContent]="menuRoleTemplate"
                      (nzPopoverVisibleChange)="
                        isVisibleChange(
                          $event,
                          item.doctorPermissions,
                          item.userId
                        )
                      ">
                      <i nz-icon nzType="user-switch" nzTheme="outline"></i>
                    </a>
                    <a
                      *ngIf="
                        item.isInvited == false &&
                        !item.isOwner &&
                        ((myIsOwnerGroup && item.userId !== curUserId) ||
                          (myIsAdminGroup && !item.isAdmin))
                      "
                      nz-dropdown
                      [nzDropdownMenu]="menu">
                      <i nz-icon nzType="setting" nzTheme="outline"></i>
                    </a>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <ul *ngIf="item.isInvited == false" nz-menu>
                        <li
                          *ngIf="
                            item.isOwner == false &&
                            item.isAdmin == false &&
                            myIsOwnerGroup
                          "
                          nz-menu-item>
                          <a (click)="setGroupAdmin(item.userId)">
                            <i class="fa fa-pencil"></i>Chỉ định làm Quản trị
                            viên
                          </a>
                        </li>
                        <li
                          *ngIf="item.isAdmin == true && myIsOwnerGroup"
                          nz-menu-item>
                          <a (click)="removeGroupAdmin(item.userId)">
                            <i class="fa fa-pencil"></i>Xóa tư cách Quản trị
                            viên
                          </a>
                        </li>
                        <li *ngIf="item.isMember == true" nz-menu-item>
                          <a (click)="removeUser(item.userId)">
                            <i class="fa fa-trash-o"></i>Xóa khỏi nhóm</a
                          >
                        </li>
                        <li
                          *ngIf="myIsOwnerGroup && item.userId !== curUserId"
                          nz-menu-item>
                          <a (click)="changeOwnerGroup(item.userId)">
                            <i
                              nz-icon
                              nzType="vertical-align-top"
                              nzTheme="outline"></i
                            >Chuyển quyền Owner</a
                          >
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                  </div>
                </div>
                <img class="media-object" src="{{ item.imageUrl }}" />
                <div class="media-body">
                  <h4 *ngIf="item.userName.length > 0" class="media-heading">
                    {{ item.fullName }}
                    <span *ngIf="item.fullName.length > 0">-</span>
                    {{ item.userName }}
                  </h4>
                  <div class="media-heading-sub">{{ item.email }}</div>
                  <i *ngIf="item.isOwner" class="media-role">Chủ nhóm</i>
                  <i *ngIf="item.isAdmin" class="media-role">Quản trị viên</i>
                  <i *ngIf="item.isMember" class="media-role"
                    >Đã là thành viên</i
                  >
                  <i *ngIf="item.isInvited" class="media-role">Đã được mời</i>
                  <span>
                    <ng-container *ngFor="let th of item.doctorPermissions">
                      <i *ngIf="th == 1" class="media-role">, Điều phối viên</i>
                      <i *ngIf="th == 2" class="media-role">, Bác sĩ</i>
                      <i *ngIf="th == 3" class="media-role"
                        >, Dược sĩ lâm sàng</i
                      >
                      <i *ngIf="th == 4" class="media-role"
                        >, Chuyên viên tư vấn</i
                      >
                      <i *ngIf="th == 5" class="media-role"
                        >, Điều dưỡng phụ trách</i
                      >
                      <i *ngIf="th == 6" class="media-role">, Kê đơn thuốc</i>
                      <i *ngIf="th == 7" class="media-role"
                        >, Duyệt đơn thuốc</i
                      >
                      <i *ngIf="th == 8" class="media-role"
                        >, Bỏ duyệt đơn thuốc của người khác</i
                      >
                      <i *ngIf="th == 9" class="media-role"
                        >, Quản trị room BN</i
                      >
                      <i *ngIf="th == 10" class="media-role"
                        >, Tình nguyện viên</i
                      >
                      <i *ngIf="th == 11" class="media-role"
                        >, Thống kê báo cáo</i
                      >
                    </ng-container>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-6 col-right">
          <div class="row mg-t-30 elm-title">
            Mời bác sỹ tham gia nhóm làm việc
          </div>
          <div class="row mg-t-30">
            <input
              (keyup)="onKeyPress($event)"
              class="col-sm-12"
              placeholder="Tìm kiếm theo account hoặc email để mời"
              [(ngModel)]="searchEmailOrUsername" />
          </div>
          <div class="row mg-t-30">
            <ul id="listInvite">
              <ng-container
                *ngFor="let item of listUser; let indexOfelement = index">
                <li
                  [ngClass]="{
                    'media-odd': indexOfelement % 2 != 0,
                    'media-even': indexOfelement % 2 == 0
                  }"
                  class="media">
                  <div class="media-status">
                    <div class="btn-group">
                      <a
                        *ngIf="!item.isMember && !item.isInvited"
                        title="Mời thành viên"
                        (click)="
                          selectToInvite(item.userId, item.userName, item.email)
                        ">
                        <i
                          nz-icon
                          nzType="plus"
                          nzTheme="outline"
                          title="Chọn để mời"></i
                      ></a>
                      <a *ngIf="!item.isMember && item.isInvited">
                        <i nz-icon nzType="plus-circle" nzTheme="outline"></i
                        >Mời lại</a
                      >
                      <a
                        *ngIf="
                          !item.isMember && !item.isInvited && item.isAddUser
                        "
                        title="Thêm thành viên"
                        (click)="openAddUser()"
                        class="mg-l-4">
                        <i
                          nz-icon
                          nzType="user-add"
                          nzTheme="outline"
                          title="Chọn để thêm"></i
                      ></a>
                    </div>
                  </div>
                  <img
                    class="media-object"
                    src="http://pmrmedia.stc.vn/Media/Avatar/ava.jpg" />
                  <div class="media-body">
                    <h4
                      *ngIf="item.userName != null && item.userName.length > 0"
                      class="media-heading">
                      {{ item.fullName }}
                    </h4>
                    <div class="media-heading-sub">
                      {{ item.userName
                      }}<br
                        *ngIf="
                          item.userName != null && item.userName.length > 0
                        " />{{ item.email }}
                    </div>
                    <i *ngIf="item.isOwner" class="media-role">Chủ nhóm</i>
                    <i *ngIf="item.isAdmin" class="media-role">Quản trị viên</i>
                    <i *ngIf="item.isMember" class="media-role"
                      >Đã là thành viên</i
                    >
                    <i *ngIf="item.isInvited" class="media-role">Đã được mời</i>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
          <div>
            <div class="elm-title">
              Danh sách khách mời - <span>{{ listInvited.length }}</span> người
            </div>
            <ul id="listEmail">
              <li *ngFor="let item of listInvited">
                {{
                  item.email === '' || item.email === null
                    ? item.userName
                    : item.email
                }}<a
                  (click)="
                    unSelectToInvite(item.userId, item.userName, item.email)
                  "
                  title="Bỏ chọn"
                  >X</a
                >
              </li>
            </ul>
          </div>
          <div class="k-info-wrapper">
            <div class="elm-title">Lời nhắn</div>
            <textarea [(ngModel)]="message" #Message style="width: 100%">
            </textarea>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- <div class="row" *nzModalFooter>
            <div class="col-sm-6 input-row">
                <div class="row">
                    <div class="col-sm-6 input-row" style="text-align: left;">Tổng số thành viên: {{ totalMemberCount }}</div>
                    <div class="col-sm-6 input-row">Tổng số thành viên đang mời: {{ totalInvitedCount }}</div>
                </div>
            </div>
            <div class="col-sm-6 input-row">
                <div class="row" style="float: right;">
                    <button nz-button (click)="handleCancel()" class="btn-cancel-medium">Đóng</button>
                    <button nz-button nzType="primary" (click)="handleOk()" class="btn-primary-medium">Mời</button>
                </div>
            </div>
        </div> -->
    <ng-template #mModalFooter>
      <div class="row">
        <div class="col-sm-6 input-row">
          <div class="row">
            <div class="col-sm-6 input-row" style="text-align: left">
              Tổng số thành viên: {{ totalMemberCount }}
            </div>
            <div class="col-sm-6 input-row">
              Tổng số thành viên đang mời: {{ totalInvitedCount }}
            </div>
          </div>
        </div>
        <div class="col-sm-6 input-row">
          <div class="row" style="float: right">
            <button
              nz-button
              (click)="handleCancel()"
              class="btn-cancel-medium">
              Đóng
            </button>
            <button
              nz-button
              nzType="primary"
              (click)="handleOk()"
              class="btn-primary-medium">
              Mời
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </app-m-modal>
  <!-- </nz-modal> -->
  <!-- </form> -->
</div>
<ng-template #menuRoleTemplate>
  <li *ngFor="let per of groupPermissions">
    <label
      style="margin-bottom: 5px"
      nz-checkbox
      [(ngModel)]="per.checked"
      (ngModelChange)="addUserPermission(per.type, per.checked)"
      >{{ per.name }}</label
    >
  </li>
  <div class="mg-t-10" style="text-align: center">
    <button
      nz-button
      class="btn-primary-small"
      (click)="setUserPermission(currMemberId)">
      OK
    </button>
  </div>
</ng-template>

<nz-modal
  [(nzVisible)]="isVisibleAddUser"
  nzTitle="Thêm user thành viên"
  nzMaskClosable="false"
  (nzClosable)="(true)"
  (nzOnCancel)="handleCancelAddUser()">
  <div *nzModalContent>
    <form nz-form [formGroup]="formData">
      <nz-form-item>
        <nz-form-label [nzSpan]="8" nzRequired nzFor="username"
          >Tên đăng nhập</nz-form-label
        >
        <nz-form-control nzHasFeedback [nzErrorTip]="userNameErrorTpl">
          <input formControlName="username" nz-input />
          <ng-template #userNameErrorTpl let-control>
            <ng-container *ngIf="control.hasError('emptyName')">
              Tên đăng nhập không được để trống!
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">
              Hãy nhập Tên đăng nhập!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="8" nzRequired nzFor="password"
          >Mật khẩu</nz-form-label
        >
        <nz-form-control nzHasFeedback [nzErrorTip]="passErrorTpl">
          <input
            formControlName="password"
            type="password"
            nz-input
            (ngModelChange)="updateConfirmValidator()" />
          <ng-template #passErrorTpl let-control>
            <ng-container *ngIf="control.hasError('emptyName')">
              Mật khẩu không được để trống!
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">
              Hãy nhập Mật khẩu!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="8" nzRequired nzFor="confirmPassword"
          >Xác nhận mật khẩu</nz-form-label
        >
        <nz-form-control nzHasFeedback [nzErrorTip]="confirmPasswordErrorTpl">
          <input formControlName="confirmPassword" type="password" nz-input />
          <ng-template #confirmPasswordErrorTpl let-control>
            <ng-container *ngIf="control.hasError('emptyName')">
              Xác nhận lại mật khẩu!
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">
              Xác nhận lại mật khẩu!
            </ng-container>
            <ng-container *ngIf="control.hasError('confirm')">
              Xác nhận lại mật khẩu!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="8" nzRequired nzFor="fullName"
          >Họ tên</nz-form-label
        >
        <nz-form-control nzHasFeedback [nzErrorTip]="nameErrorTpl">
          <input formControlName="fullName" nz-input />
          <ng-template #nameErrorTpl let-control>
            <ng-container *ngIf="control.hasError('emptyName')">
              Tên không được để trống!
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">
              Tên không được để trống!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="8" nzFor="doctorRankId">Chức danh</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="doctorRank" />
          <!-- <input formControlName="doctorRankId" nz-input placeholder="Chức danh" /> -->
          <!--<nz-select formControlName="doctorRankId" nzAllowClear>
            <nz-option
              *ngFor="let option of doctorRanks"
              [nzValue]="option.id"
              [nzLabel]="option.name"></nz-option>
          </nz-select>-->
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="8" nzRequired nzFor="email">Email</nz-form-label>
        <nz-form-control nzHasFeedback [nzErrorTip]="emailErrorTpl">
          <input formControlName="email" nz-input />
          <ng-template #emailErrorTpl let-control>
            <ng-container *ngIf="control.hasError('emptyName')">
              Email không được để trống!
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">
              Hãy nhập email!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
  <div *nzModalFooter>
    <button
      nz-button
      nzType="default"
      class="btn-cancel-medium"
      (click)="handleCancelAddUser()"
      nzDanger>
      Huỷ
    </button>
    <button
      nz-button
      nzType="primary"
      class="btn-primary-medium"
      (click)="saveAddUser()">
      Thêm
    </button>
  </div>
</nz-modal>
