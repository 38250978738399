<div id="print-div-prescription" *ngIf="prescription">
  <div class="paper-size" *ngIf="medicines.length>0">
    <div [innerHTML]="printHeader | safeHtml"> </div>
    <div class="title-barcode medicine">
      <div class="title">
        ĐƠN THUỐC
      </div>
      <div class="barcode-wrap">
        <ngx-barcode [bc-font-size]="15" [bc-margin]="1" [bc-width]="1" [bc-height]="40" [bc-font]=""
          [bc-value]="visit.patientCode" bc-element-type="svg" [bc-display-value]="true" [bc-margin-top]="1"
          [bc-margin-bottom]="1" [bc-element-type]="'svg'" [bc-margin-left]="380" [bc-margin-right]="1"></ngx-barcode>
      </div>
    </div>
    <div style="padding: 20px">
      <div style="width: 100%">
        <div class="row info-row">
          <div class="col-6">
            <span class="label">Họ và tên: </span>
            <span>{{ visit.patientName }}</span>
          </div>
          <div class="col-3">
            <span class="label">Năm sinh: </span>
            <span>{{ visit.dob | date: 'yyy' }}</span>
          </div>
          <div class="col-3">
            <span class="label">Giới tính: </span>
            <span>{{ visit.patientSexText }}</span>
          </div>
        </div>
        <div class="row info-row">
          <div class="col-9">
            <span class="label">Địa chỉ: </span><span>{{ visit.address }}</span>
          </div>
          <div class="col-3">
            <span class="label">SĐT: </span><span>{{ visit.phoneNo }}</span>
          </div>
        </div>
        <!-- <div class="row info-row">
          <div class="col-12">
            <span class="label">Bác sĩ: </span><span>{{ visit.doctor }}</span>
          </div>
        </div> -->
        <div class="row info-row">
          <div class="col-12">
            <span class="label">Chẩn đoán: </span><span>{{ visit.conclusion }}</span>
          </div>
        </div>

      </div>
      <br />
      <!-- đơn thuốc dạng bảng -->
      <div *ngIf="config == templateConstant.Table.value">
        <table style="width: 100%" cellpadding="0" cellspacing="0" border="1" class="table-detail">
          <thead>
            <tr>
              <th class="center" style="width: 20px">STT</th>
              <th>Tên thuốc (hàm lượng)</th>
              <th class="text-nowrap">Đơn vị</th>
              <th class="text-nowrap">SL</th>
              <!-- <th class="text-nowrap">Thực xuất</th> -->
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of medicines; let i = index">
              <tr>
                <td class="text-nowrap center" style="text-align: center; width: 20px" rowspan="2">
                  {{ i + 1 }}
                </td>
                <td class="">
                  {{ item.Title }}
                </td>
                <td>
                  {{ item.UnitName }}
                </td>
                <td class="center">
                  {{ item.Amount }}
                </td>
                <!-- <td class="center">
                  {{ getAmount(item.Amount, item.ExistingAmount) }}
                </td> -->

              </tr>
              <tr>
                <td colspan="4">
                  <i>{{ item.Text }}</i>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <!-- đơn thuốc dạng danh sách -->
      <div *ngIf="config == templateConstant.List.value" class="prescription-list">
        <div class="bold title">THÔNG TIN ĐƠN THUỐC</div>
        <div *ngFor="let item of medicines; let i = index" class="pres-item">
          <div style="width: 75%">
            <div>
              <span class="">{{ i + 1 }}.&nbsp;</span>
              <span>{{ item.Title }}</span>
            </div>
            <div style="margin-left: 10px">
              <i>{{ item.Text }}</i>
            </div>
          </div>
          <div class="center" style="width: 25%">
            {{ item.Amount }} {{ item.UnitName }}
          </div>
        </div>
      </div>
      <br />
      <div>
        <div class="d-flex">
          <label class="bold">LỜI DẶN:&nbsp;</label>
          <div [innerHTML]="convertTextbox2Html(prescription.DoctorComment)"></div>
        </div>
        <div class="d-flex">
          <label class="bold">LƯU Ý:&nbsp;</label>
          <div>{{ PRESCRIPTION_NOTE }}</div>
        </div>
        <div class="d-flex">
          <label class="bold">HẸN TÁI KHÁM:&nbsp;</label>
          <div>{{ prescription.ReExaminationDate | date: dateFormat }}</div>
        </div>
      </div>
      <table style="width: 100%; margin-bottom: 20px">
        <ng-container *ngIf="isUseDeliveryInfo">
          <tr>
            <td colspan="2">
              <label style="font-weight: bold" class="label">THÔNG TIN GIAO THUỐC:</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Họ tên người nhận:</label>
            </td>
            <td>
              <label>{{ prescription.RecipientName }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Số điện thoại:</label>
            </td>
            <td>
              <label>{{ prescription.RecipientPhoneNo }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Địa chỉ:</label>
            </td>
            <td>
              <label>{{ prescription.RecipientAddress }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Thời gian có thể nhận:</label>
            </td>
            <td>
              <label>{{ prescription.RecipientTimePlan }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Hình thức thanh toán:</label>
            </td>
            <td>
              <label>{{ prescription.PaymentMethodString }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Ghi chú:</label>
            </td>
            <td>
              <label>{{ prescription.RecipientNote }}</label>
            </td>
          </tr>
        </ng-container>
      </table>
      <br />
      <table style="width: 100%; text-align: center;">
        <tr>
          <td colspan="4" style="text-align: right;">
            <label class="label">
              {{ prescription.CreatedDate | prescrriptionDay }}
            </label>
          </td>
        </tr>

        <ng-container *ngIf="prescription.CreatorRole === 'Doctor'">
          <tr style="text-transform: uppercase; font-weight: bold;">
            <td colspan="2">
              <div>Bệnh nhân xác nhận</div>
            </td>
            <td colspan="2">
              <div>BS khám bệnh</div>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="padding-top: 40px">
              {{ visit.patientName }}
              </td>
              <td colspan="2" style="padding-top: 40px">
              {{ prescription.CreatorName }}
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
  <div style="page-break-after: always;" *ngIf="medicines.length >0 && functionalFoods.length>0"></div>
  <div class="paper-size" *ngIf="functionalFoods.length>0">
    <div [innerHTML]="printHeader | safeHtml"> </div>
    <div>
    </div>
    <div class="title-barcode">
      <div class="title">
        PHIẾU TƯ VẤN SỬ DỤNG THỰC PHẨM CHỨC NĂNG
      </div>
      <div class="barcode-wrap">
        <ngx-barcode [bc-font-size]="15" [bc-margin]="1" [bc-width]="1" [bc-height]="40" [bc-font]=""
          [bc-value]="visit.patientCode" bc-element-type="svg" [bc-display-value]="true" [bc-margin-top]="1"
          [bc-margin-bottom]="1" [bc-element-type]="'svg'" [bc-margin-left]="380" [bc-margin-right]="1"></ngx-barcode>
      </div>
    </div>
    <div style="padding: 20px">
      <div style="width: 100%">
        <div class="row info-row">
          <div class="col-6">
            <span class="label">Họ và tên: </span>
            <span>{{ visit.patientName }}</span>
          </div>
          <div class="col-3">
            <span class="label">Năm sinh: </span>
            <span>{{ visit.dob | date: 'yyy' }}</span>
          </div>
          <div class="col-3">
            <span class="label">Giới tính: </span>
            <span>{{ visit.patientSexText }}</span>
          </div>
        </div>
        <div class="row info-row">
          <div class="col-9">
            <span class="label">Địa chỉ: </span><span>{{ visit.address }}</span>
          </div>
          <div class="col-3">
            <span class="label">SĐT: </span><span>{{ visit.phoneNo }}</span>
          </div>
        </div>
        <!-- <div class="row info-row">
          <div class="col-12">
            <span class="label">Bác sĩ: </span><span>{{ visit.doctor }}</span>
          </div>
        </div> -->
        <div class="row info-row">
          <div class="col-12">
            <span class="label">Chẩn đoán: </span><span>{{ visit.conclusion }}</span>
          </div>
        </div>
      </div>
      <br />
      <!-- đơn thuốc dạng bảng -->
      <div *ngIf="config == templateConstant.Table.value">
        <table style="width: 100%" cellpadding="0" cellspacing="0" border="1" class="table-detail">
          <thead>
            <tr>
              <th class="center" style="width: 20px">STT</th>
              <th>Tên thuốc (hàm lượng)</th>
              <th class="text-nowrap">Đơn vị</th>
              <th class="text-nowrap">SL</th>
              <!-- <th class="text-nowrap">Thực xuất</th> -->
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of functionalFoods; let i = index">
              <tr>
                <td class="text-nowrap center" style="text-align: center; width: 20px" rowspan="2">
                  {{ i + 1 }}
                </td>
                <td class="">
                  {{ item.Title }}
                </td>
                <td>
                  {{ item.UnitName }}
                </td>
                <td class="center">
                  {{ item.Amount }}
                </td>
                <!-- <td class="center">
                  {{ getAmount(item.Amount, item.ExistingAmount) }}
                </td> -->

              </tr>
              <tr>
                <td colspan="4">
                  <i>{{ item.Text }}</i>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <!-- đơn thuốc dạng danh sách -->
      <div *ngIf="config == templateConstant.List.value" class="prescription-list">
        <div class="bold title">THÔNG TIN ĐƠN THUỐC</div>
        <div *ngFor="let item of functionalFoods; let i = index" class="pres-item">
          <div style="width: 75%">
            <div>
              <span class="">{{ i + 1 }}.&nbsp;</span>
              <span>{{ item.Title }}</span>
            </div>
            <div style="margin-left: 10px">
              <i>{{ item.Text }}</i>
            </div>
          </div>
          <div class="center" style="width: 25%">
            {{ item.Amount }} {{ item.UnitName }}
          </div>
        </div>
      </div>
      <br />
      <div>
        <div class="d-flex">
          <label class="bold">LỜI DẶN:&nbsp;</label>
          <div [innerHTML]="convertTextbox2Html(prescription.DoctorComment)"></div>
        </div>
        <div class="d-flex">
          <label class="bold">LƯU Ý:&nbsp;</label>
          <div>{{ PRESCRIPTION_NOTE }}</div>
        </div>
        <div class="d-flex">
          <label class="bold">HẸN TÁI KHÁM:&nbsp;</label>
          <div>{{ prescription.ReExaminationDate | date: dateFormat }}</div>
        </div>
      </div>
      <table style="width: 100%; margin-bottom:10px" *ngIf="isUseDeliveryInfo">
        <ng-container *ngIf="isUseDeliveryInfo">
          <tr>
            <td colspan="2">
              <label style="font-weight: bold" class="label">THÔNG TIN GIAO THUỐC:</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Họ tên người nhận:</label>
            </td>
            <td>
              <label>{{ prescription.RecipientName }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Số điện thoại:</label>
            </td>
            <td>
              <label>{{ prescription.RecipientPhoneNo }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Địa chỉ:</label>
            </td>
            <td>
              <label>{{ prescription.RecipientAddress }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Thời gian có thể nhận:</label>
            </td>
            <td>
              <label>{{ prescription.RecipientTimePlan }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Hình thức thanh toán:</label>
            </td>
            <td>
              <label>{{ prescription.PaymentMethodString }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Ghi chú:</label>
            </td>
            <td>
              <label>{{ prescription.RecipientNote }}</label>
            </td>
          </tr>
        </ng-container>
      </table>
      <br />
      <table style="width: 100%; text-align: center;">
        <tr>
          <td colspan="4" style="text-align: right;">
            <label class="label">
              {{ prescription.CreatedDate | prescrriptionDay }}
            </label>
          </td>
        </tr>
        <ng-container *ngIf="prescription.CreatorRole === 'Doctor'">
          <tr style="text-transform: uppercase; font-weight: bold;">
            <td colspan="2 bold">
              <div>Bệnh nhân xác nhận</div>
            </td>
            <td colspan="2 bold">
              <div>BS khám bệnh</div>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="padding-top: 40px">
              {{ visit.patientName }}
            </td>
            <td colspan="2" style="padding-top: 40px">
              {{ prescription.CreatorName }}
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
</div>
