import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService extends BaseService {
  getProvince(): Observable<any> {
    return this.get('/Province');
  }
  getDistrictByProvinceId(provinceId: string): Observable<any> {
    return this.get('/District/' + provinceId);
  }
  getWardByProvinceId(districtId: string): Observable<any> {
    return this.get('/Ward/' + districtId);
  }
}
