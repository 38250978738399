import { ShareService } from './../../../services/share.service';
import { AppState } from 'src/app/components/app-state/app-state';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { ListFormService } from 'src/app/services/listForm-service';
import { NotificationService } from 'src/app/services/notification.service';
import { FormData } from 'src/app/models/form.interface';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-form-management-component',
  templateUrl: './form-management-component.component.html',
  styleUrls: ['./form-management-component.component.scss'],
})
export class FormManagementComponent implements OnInit {
  public listForms: FormData[] = [];
  constructor(
    private notify: NotificationService,
    private dataService: ListFormService,
    private store: Store<AppState>,
    private shareService: ShareService,
    private modalService: NzModalService,
    private tabDataService: TabDataService
  ) {}
  public requestData = {
    search: '',
    groupId: '',
    pageSize: 1000,
    page: 1,
  };
  public totaldata = 25;
  currentForm: any = {};
  currentName = '';
  public inputValue?: string;
  public filterOption: string[] = [];
  public options: string[] = [];
  async ngOnInit() {
    // this.requestData.groupId = localStorage.getItem(Constant.CURRENT_GROUPID);
    this.store.select('groupAuth').subscribe((res) => {
      this.requestData.groupId = res.groupId;
    });
    this.getListDPForms(true);
  }

  getListDPForms(isOninit: boolean): void {
    this.dataService.getListEditableDPFrom(this.requestData).subscribe(
      (response) => {
        if (response !== null && response.source != null) {
          this.totaldata = response.total;
          this.listForms = [...response.source];
          if (isOninit === true) {
            this.getOptionsSearch();
          }
        }
      },
      (error) => {
        this.notify.showNotification('', 'Có lỗi xảy ra');
      }
    );
  }
  getOptionsSearch() {
    this.listForms.forEach((data) => {
      if (!this.options.includes(data.name)) {this.options.push(data.name);}
    });
    this.filterOption = this.options;
  }

  ontyping(value: string): void {
    this.filterOption = this.options.filter(
      (option) => option.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  search(): void {
    this.requestData.search = this.inputValue;
    this.requestData.page = 1;
    this.getListDPForms(false);
  }

  reset(): void {
    this.filterOption = this.options;
    this.inputValue = null;
    this.search();
  }

  selectForm(value, name): void {
    this.currentForm = value;
    this.currentName = name;
  }

  newRecordTab(idForm, nameForm) {
    if (idForm == 'new') {
      this.tabDataService.updateTab(idForm, nameForm, 'Record', '');
    } else if (
      idForm != 'new' &&
      this.currentForm != '' &&
      this.currentName != ''
    ) {
      this.tabDataService.updateTab(idForm, nameForm, 'Record', ''); //id form
    } else {
      this.notify.showNotification('warning', 'Chưa chọn biểu mẫu');
    }
  }
  deleteItem() {
    if (this.shareService.checkEmpty(this.currentForm.id)) {
      this.notify.showNotification(
        Constant.NOTIFY_TYPE.WARRING,
        'Hãy chọn 1 form!'
      );
    } else {
      if (this.currentForm.isUsed) {
        this.notify.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Form đã được sử dụng!'
        );
      } else {
        if (!this.currentForm.isUsed)
          {this.modalService.confirm({
            nzTitle: 'Confirm',
            nzContent: 'Bạn có muốn xóa hay không?',
            nzOkText: 'Đồng ý',
            nzCancelText: 'Huỷ',
            nzOnOk: () => this.deleteForm(),
          });}
      }
    }
  }

  deleteForm() {
    this.dataService.deleteForm(this.currentForm.id).subscribe((response) => {
      if (response && response.isValid) {
        this.notify.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          Constant.MESSAGE_DELETE_SUCCESS
        );
        this.getListDPForms(true);
      } else {
        this.notify.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          response.errors[0].errorMessage
        );
      }
    });
  }
}
