<div
  *ngIf="isVisible"
  class="variable-context"
  [ngStyle]="{
    'top': coordinate.top + 18 + 'px',
    'left': coordinate.left + 'px'
  }">
  <ul>
    <li *ngFor="let variable of variables" (click)="selectVariable(variable)">
      <a>{{ variable.name }}</a>
    </li>
  </ul>
</div>
