import { select } from '@ngrx/store';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MenuItemTree } from 'src/app/models/grouptree.model';

@Component({
  selector: 'app-mobile-select-group',
  templateUrl: './mobile-select-group.component.html',
  styleUrls: ['./mobile-select-group.component.scss'],
})
export class MobileSelectGroupComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() menus: Array<MenuItemTree> = [];
  @Output() selectItem: EventEmitter<any> = new EventEmitter();
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() searchGroupEvent: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  onCancel() {
    this.closeModal.emit(false);
  }

  selectGroup(val) {
    this.selectItem.emit(val);
    this.onCancel();
  }

  searchGroup(val) {
    this.searchGroupEvent.emit(val);
  }
}
