import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { Observable } from 'rxjs';
import { UrlConstant } from '../share/constants/url.class';
import { Medication } from '../models/medication.class';
@Injectable({
  providedIn: 'root',
})
export class MedicationService extends BaseService {
  getMedicationAll(payload): Observable<Medication[]> {
    return this.post(UrlConstant.MEDICATION + 'GetAll', payload);
  }

  deleteMedication(id: string) {
    return this.delete(UrlConstant.MEDICATION,id);
  }

  addMedication(data: any) {
    return this.post(UrlConstant.MEDICATION, data);
  }

  updateMedication(data: any) {
    return this.put(UrlConstant.MEDICATION + data.id, data);
  }

  searchMedication(data: any): Observable<any> {
    return this.post(UrlConstant.MEDICATION + 'GetByFilter', data);
  }

  checkMedication(id: string, groupId: string) {
    return this.get(UrlConstant.CHECK_MEDICATION + id + '/' + groupId);
  }

  importMedication(groupIds, privacyUse, formData: FormData) {
    return this.post(UrlConstant.IMPORT_MEDICATION, formData, {
      groupIds,
      privacyUse,
    });
  }

  exportMedication(payload): Observable<any> {
    return this.post(
      UrlConstant.EXPORT_MEDICATION,
      payload,
      '',
      'blob'
    );
  }
}
