import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitHistoryComponent } from './visit-history.component';
import { SharedModule } from 'src/app/share/share.module';

@NgModule({
  declarations: [VisitHistoryComponent],
  imports: [CommonModule, SharedModule],
  exports: [VisitHistoryComponent],
})
export class VisitHistoryModule {}
