import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Visit } from 'src/app/models/visit.model';
import { ListVisitService } from '../../../../services/listVisit-service';
import { TableSelectionAbstract } from '../../../../share/component/table/table-selection.abstract';
import { NotificationService } from '../../../../services/notification.service';
import { Constant } from '../../../../share/constants/constant.class';
import { ObservationTypeService } from 'src/app/services/obserType-service';
import { AppState } from 'src/app/components/app-state/app-state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-vital-signs-tab',
  templateUrl: './vital-signs-tab.component.html',
  styleUrls: ['./vital-signs-tab.component.scss'],
})
export class VitalSignsTabComponent extends TableSelectionAbstract implements OnInit, OnDestroy {
  _reloadNow = false;
  @Input() visit: Visit;
  @Input() hideButton: string;
  @Input() disabledClass: string;

  get reloadNow(): boolean {
    return this._reloadNow;
  }

  @Input() set reloadNow(value: boolean) {
    this._reloadNow = value;
    if (value) {
      this.cakhams = [];
      this.lstVitalSign = [];
      this.vitalSignOnChart = [];

      this.getObservationOfPatient();
      setTimeout(() => {
        this.reloadNowChange.emit(false);
      }, 500);
    }
  }

  @Output() reloadNowChange = new EventEmitter<any>();
  @ViewChild('tableSurvival') tableSurvival: ElementRef;

  scrollY: string = window.innerHeight - 510 + 'px';
  groupId = '';
  groupSub: Subscription;
  cakhams: any[];
  lstVitalSign: any[];
  vitalSignFilter: any[];
  vitalSignOnChart: any[];
  isVisibleChart: boolean;
  searchText: string;
  filterObservationIds: string[];
  arrSort: string[];
  initData: any[] = [
    {
      id: '5f6d9bbab85fb8352c276242',
      observationTypeId: '5f61d622be9d6263c029d770',
      name: 'Height',
      value: 80,
      visitId: '5f9795ac13759dcbca8c689e',
      visitDate: '27/10/2020',
      visitDateTime: '2020-10-27T10:36:00+07:00',
      dataType: 1,
      unit: 'kg',
      minValue: 100,
      maxValue: 200,
      rangeType: -1,
    },
  ];
  constructor(
    private listVisitService: ListVisitService,
    private notificationService: NotificationService,
    private observationTypeService: ObservationTypeService,
    private store: Store<AppState>
  ) {
    super('id');
  }

  ngOnInit(): void {
    this.cakhams = [];
    this.lstVitalSign = [];
    this.vitalSignOnChart = [];
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
      }
    });
    this.getOTOrder();
  }

  getOTOrder() {
    this.observationTypeService.GetListOTOrderByGroupId(this.groupId).subscribe((arrorder) => {
      this.arrSort = arrorder;
      this.getObservationOfPatient();
    });
  }
  getObservationOfPatient() {
    const dataFilter = {
      visitId: this.visit.id,
      patientId: this.visit.patientId,
      observationCategory: 2,
    };
    this.listVisitService.getObservationOfPatient(dataFilter).subscribe((res) => {
      let lstObs = res.observations;
      if (lstObs !== []) {
        this.initData = [];
        let lst = [];
        if (this.arrSort != null && this.arrSort.length > 0) {
          this.arrSort.forEach((element) => {
            lst = lstObs.filter((en) => en.observationTypeId === element);
            lstObs = lstObs.filter((en) => en.observationTypeId !== element);
            this.initData = [...this.initData, ...lst];
          });
        }

        this.initData = [...this.initData, ...lstObs];

        this.getData();
      }
    });
  }

  getData() {
    let coldata = this.initData;
    if (coldata) {
      coldata = coldata.sort(function (a, b) {
        return new Date(a.visitDateTime).getTime() - new Date(b.visitDateTime).getTime();
      });
      for (var i = 0; i < coldata.length; i++) {
        const item = coldata[i];
        const len = this.cakhams.filter((en) => en.visitId == item.visitId).length;
        if (len === 0) {
          this.cakhams.push({
            visitId: item.visitId,
            visitDate: item.visitDateTime,
          });
        }
      }
    }

    for (var i = 0; i < this.initData.length; i++) {
      // set each rows in list chisosinhton
      const item = this.initData[i];
      const l = this.lstVitalSign.filter((en) => en.observationTypeId === item.observationTypeId).length;
      if (l === 0) {
        const newItem = {
          id: item.observationTypeId,
          observationTypeId: item.observationTypeId,
          name: item.name,
          unit: item.unit,
          lstValueSingleChoice: '',

          visible: true,
          range: '',
          dataType: item.dataType,
          value: null,
          typeDisableChecked: false,
        };

        let unit = '';
        if (item.unit !== '' && item.unit !== null) {
          unit = '<i> (' + item.unit + ')</i>';
        }
        newItem.name = newItem.name + unit;
        //lấy giá trị text của type singlechoice
        if (item.dataType === Constant.DATATYPENUMBER.SINGLECHOICE) {
          item.dataSource = item.dataSource.sort((a, b) => a.valueNumber - b.valueNumber);
          let str = '';
          for (const single of item.dataSource) {
            str += single.valueNumber + ': ' + single.text + ', ';
          }
          newItem.lstValueSingleChoice = str.substring(0, str.length - 2);
        }
        // nếu các chỉ số có type number, boolean, singlechoice -> disable check k vẽ biểu đồ
        if (
          item.dataType !== Constant.DATATYPENUMBER.NUMBER &&
          item.dataType !== Constant.DATATYPENUMBER.BOOLEAN &&
          item.dataType !== Constant.DATATYPENUMBER.SINGLECHOICE
        ) {
          newItem.typeDisableChecked = true;
        }
        if (
          item.dataType === Constant.DATATYPENUMBER.NUMBER ||
          item.dataType === Constant.DATATYPENUMBER.POSITIVENEGATIVE ||
          item.dataType === Constant.DATATYPENUMBER.SINGLECHOICE ||
          item.dataType === Constant.DATATYPENUMBER.BOOLEAN
        ) {
          const ranges = item.lstRange;
          if (ranges != null && ranges.length > 0) {
            let sRange = '';
            ranges.forEach((element) => {
              if (item.dataType === Constant.DATATYPENUMBER.BOOLEAN && element.normalValue) {
                element.text = 'Normal: Có';
              } else if (item.dataType === Constant.DATATYPENUMBER.BOOLEAN && !element.normalValue) {
                element.text = 'Normal: Không';
              }
              if (sRange === '') {
                sRange += element.text;
              } else {
                sRange += ';<br/>' + element.text;
              }
            });
            newItem.range = sRange;
          }
        } else {
          newItem.range = item.unit;
        }
        newItem.name = newItem.name + '<br/>' + newItem.range;
        for (let j = 0; j < this.cakhams.length; j++) {
          // set each visit
          var jVisitId = this.cakhams[j].visitId;
          const val = this.initData.find(
            (en) => en.visitId == jVisitId && en.observationTypeId == item.observationTypeId
          ); // get visit which has observation of row[i]
          if (val) {
            var rangeTypeText = '';
            newItem['s' + jVisitId] = '';
            newItem['c' + jVisitId] = '';
            if (val.dataType === Constant.DATATYPENUMBER.BOOLEAN) {
              let valPN = '';
              if (val.value === true) {
                valPN = 'Có';
              } else if (val.value === false) {
                valPN = 'Không';
              }
              if (val.rangeType == 0) {
                valPN = '<b>' + valPN + '</b>';
              }
              newItem['c' + jVisitId] = valPN;
            } else if (
              val.dataType === Constant.DATATYPENUMBER.SINGLECHOICE ||
              val.dataType === Constant.DATATYPENUMBER.MUILTICHOICE
            ) {
              let sValue = '';
              if (
                item.value !== null &&
                Array.isArray(item.value) &&
                item.value.length > 0 &&
                item.dataSource != null
              ) {
                item.value.forEach((element) => {
                  if (element !== null && element !== '') {
                    const ds = item.dataSource.filter((en) => en.id === element);
                    if (ds != null && ds.length > 0 && ds[0].valueNumber != null && ds[0].valueNumber != '') {
                      if (sValue.length > 0) {
                        sValue += ', ';
                      }
                      sValue += ds[0].valueNumber;
                    }
                  }
                });

                if (sValue.length > 0) {
                  sValue = '(' + sValue + ') ';
                }
                // if(ds!=null && ds.length>0)
                //   data.push({date: d, value: ds[0].valueNumber, }
              }
              if (val.rangeType === 0) {
                newItem['s' + jVisitId] = sValue + val.value2Show;
              } else {
                newItem['c' + jVisitId] = sValue + val.value2Show;
              }
            } else {
              newItem['c' + jVisitId] = val.value;
            }
            // newItem['c2' + jVisitId] = val.value;
            if (val.dataType === Constant.DATATYPENUMBER.NUMBER) {
              var rangeTypeText = '';
              switch (val.rangeType) {
                case 1:
                  rangeTypeText = 'right';
                  break;
                case 0:
                  rangeTypeText = 'mid';
                  break;
                case -1:
                  rangeTypeText = 'left';
                  break;
              }
              newItem['r' + jVisitId] = rangeTypeText;
            } else {
              newItem['r' + jVisitId] = '';
            }
          } else {
            newItem['c' + jVisitId] = null;
            newItem['r' + jVisitId] = '';
          }
        }
        this.lstVitalSign.push(newItem);
      }
    }
    this.vitalSignFilter = this.lstVitalSign.filter((en) => 1 === 1);
    this.scrollToRight();
  }

  searchVitalSign() {
    if (this.searchText) {
      this.vitalSignFilter = this.vitalSignFilter.filter((en) =>
        en.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    } else {
      this.vitalSignFilter = this.lstVitalSign.filter((en) => 1 === 1);
    }
  }

  viewChart() {
    this.filterObservationIds = this.getCheckedIdList();
    if (this.filterObservationIds.length === 0) {
      this.notificationService.showNotification('error', 'Chưa chọn thông tin lâm sàng');
      return;
    }
    this.isVisibleChart = true;
    this.vitalSignOnChart = this.lstVitalSign.filter(
      (en) =>
        this.filterObservationIds.includes(en.observationTypeId) &&
        (en.dataType == Constant.DATATYPENUMBER.NUMBER ||
          en.dataType == Constant.DATATYPENUMBER.BOOLEAN ||
          en.dataType == Constant.DATATYPENUMBER.SINGLECHOICE)
    );
  }

  closeChart(isRefresh) {
    this.isVisibleChart = false;
  }
  ngOnDestroy(): void {
    this.groupSub.unsubscribe();
  }
  scrollToRight() {
    $('#tableSurvival .ant-table-body').animate({ scrollLeft: 10000 });
  }
}
