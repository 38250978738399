<div *ngIf="id != '' || isSliderImagePopup" class="visit-image-action {{ mHeader ? 'frame-big' : 'frame-small' }}">
  <div class="icon-action flx">
    <i *ngIf="imageFrom == ImageFrom.BoxGeneral" class="icon-check-cdha">
      <label
        nz-checkbox
        [nzDisabled]="slide.privacyPolicy != GeneralBoxPrivacy.EditInGroup && slide.creatorId != userIdOnline"
        [(ngModel)]="slide.isKeyImage"
        (ngModelChange)="saveKeyImage($event)">
        Dữ liệu CĐHA
      </label>
    </i>
    <i
      title="xoay trái"
      nz-icon
      (click)="rotateImage(visitId, slide.id, -1)"
      nzType="rotate-left"
      nzTheme="outline"></i>
    <i
      title="xoay phải"
      nz-icon
      (click)="rotateImage(visitId, slide.id, 1)"
      nzType="rotate-right"
      nzTheme="outline"></i>
    <i title="zoom to" nz-icon (click)="zoomImage(1)" nzType="zoom-in" nzTheme="outline"></i>
    <i
      title="zoom nhỏ"
      nz-icon
      [ngClass]="isZoomOut == true ? '' : 'disabled'"
      (click)="zoomImage(-1)"
      nzType="zoom-out"
      nzTheme="outline"></i>
    <i
      *ngIf="imageFrom == ImageFrom.BoxGeneral && slide.creatorId == userIdOnline"
      class="icon-del-image"
      title="xóa ảnh"
      nz-icon
      (click)="deleteImage(visitId, slide.id, slide.index)"
      nzType="delete"
      nzTheme="outline"></i>
  </div>
  <div class="main-body window-{{ id }}">
    <div class="circle-shadow flx circle-left" (click)="preImage(slide.index)">
      <i nz-icon nzType="left" nzTheme="outline"></i>
    </div>
    <div class="circle-shadow flx circle-right" (click)="nextImage(slide.index)">
      <i nz-icon nzType="right" nzTheme="outline"></i>
    </div>
    <div id="parent" #parent>
      <div *ngIf="slide.imageURL" class="image--big flx" id="mouse-wheel">
        <img
          #toTarget
          class="toTarget-{{ id }} i{{ slide.id }}"
          rtvalue="0"
          scaleval="1"
          [src]="convertImageUrl(slide.imageURL)" />
      </div>
    </div>
    <div class="comment-style">{{ slide.comment }}</div>
  </div>
  <div class="carousel carousel-{{ id }}">
    <ul class="customTableCrollbar">
      <div class="icon-roll" style="left: 10px" (click)="gotoHead(1)">
        <i nz-icon nzType="left" nzTheme="outline"></i>
      </div>
      <div class="icon-roll" style="right: 10px" (click)="gotoHead(-1)">
        <i nz-icon nzType="right" nzTheme="outline"></i>
      </div>
      <li
        [ngClass]="i == slide.index ? 'active-image' : 'carousel-img'"
        *ngFor="let li of lstImages; index as i"
        (click)="currentImage(i)"
        title="{{ li.comment }}">
        <img [src]="li.imageURL | thumbnailSrc: baseImageurl" />
      </li>
    </ul>
  </div>
</div>
