<my-date-picker
  #myDatePicker
  [options]="myDatePickerNormalOptions"
  [selector]="selector"
  (dateChanged)="onDateChanged($event)"
  (inputFieldChanged)="onInputFieldChanged($event)"
  (calendarViewChanged)="onCalendarViewChanged($event)"
  (calendarToggle)="onCalendarToggle($event)"
  (inputFocusBlur)="onInputFocusBlur($event)"
  [selDate]="selectedDateNormal"
  [placeholder]="placeholder"
  [disabled]="disabled"
  locale="vi"
  [formDate]="formDate"></my-date-picker>
