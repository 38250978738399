<!-- <nz-modal [nzVisible]="isVisible" nzTitle="Lịch sử hoạt động ca khám" (nzOnCancel)="closePopUp()"  [nzWidth]="650"> -->
<app-m-modal
  [modalId]="'visit-log'"
  [isVisible]="isVisible"
  (closeModal)="closePopUp()"
  [mMask]="true"
  [width]="650"
  [minWidth]="400"
  [minHeight]="300"
  [isResiable]="true">
  <ng-template #mModalTitle>
    <div class="header-title">Lịch sử hoạt động ca khám</div>
  </ng-template>
  <ng-template #mModalContent>
    <table *ngIf="!isMobile" class="table">
      <thead>
        <tr>
          <th style="width: 150px">Thời gian</th>
          <th style="width: 180px">Người thực hiện</th>
          <th style="min-width: 180px">Nội dung</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of lstLogs">
          <td style="width: 150px">
            {{ item.dateModified | date: 'dd/MM/yyyy HH:mm' }}
          </td>
          <td class="username" style="width: 180px">{{ item.modifiedBy }}</td>
          <td>{{ item.content }}</td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="isMobile" class="list-log">
      <div *ngFor="let item of lstLogs" class="mg-bt-10">
        <div class="font-weight-bold">
          {{ item.dateModified | date: 'dd/MM/yyyy HH:mm' }} -
          {{ item.modifiedBy }}
        </div>
        <div>{{ item.content }}</div>
      </div>
    </div>
    <!-- <div *nzModalFooter>
    <button class="button-submit btn-cancel-large" (click)="closePopUp()">Đóng</button>
  </div> -->
  </ng-template>
  <ng-template #mModalFooter>
    <button class="btn-cancel-large" (click)="closePopUp()">Đóng</button>
  </ng-template>
</app-m-modal>
<!-- </nz-modal> -->
