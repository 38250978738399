import { FormatStyle } from './formatStyle.model';
import { FormObject } from './formObject.model';

export class Formula extends FormObject {
  formulaId: string;
  width = 200;
  height = 30;
  name: string;
  value: any;
  formatStyle: FormatStyle = new FormatStyle();
}
