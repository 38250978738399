import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/share/share.module';
import { VitalSignsTabComponent } from './vital-signs-tab.component';
import { ChartComponent } from './chart/chart.component';
import { VitalSignsTabMobileComponent } from '../../mobile-layout/vital-signs-tab-mobile/vital-signs-tab-mobile.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    VitalSignsTabComponent,
    ChartComponent,
    VitalSignsTabMobileComponent,
  ],
  exports: [
    VitalSignsTabComponent,
    ChartComponent,
    VitalSignsTabMobileComponent,
  ],
})
export class VitalSignsTabModule {}
