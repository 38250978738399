import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReminderSampleComponent } from './reminder-sample/reminder-sample.component';
import { ListReminderComponent } from './list-reminder.component';
import { SharedModule } from 'src/app/share/share.module';
import { ReminderFormComponent } from './reminder-form/reminder-form.component';

@NgModule({
  declarations: [
    ListReminderComponent,
    ReminderSampleComponent,
    ReminderFormComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    ListReminderComponent,
    ReminderSampleComponent,
    ReminderFormComponent,
  ],
})
export class ListReminderModule {}
