import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/components/app-state/app-state';
import { Visits } from 'src/app/models/listVisit.class';
import { SubClinicalVisitService } from 'src/app/services/subcliniccal-service';
import { Constant } from 'src/app/share/constants/constant.class';
@Component({
  selector: 'app-tab-CDHA',
  templateUrl: './tab-CDHA.component.html',
  styleUrls: ['./tab-CDHA.component.scss'],
})
export class TabCDHAComponent implements OnInit {
  _visit: Visits;
  @Input() set visit(val: Visits) {
    this._visit = val;
    if (val && val.id) {
      console.log(val.id, 'áda');
      this.getSubClinicalResult();
    }
  }
  get visit() {
    return this._visit;
  }
  @Input() hideButton: string;
  @Input() disabledClass: string;

  isVisablePdf = false;
  linkPdf = '';
  index: number;
  subclinicalResult = [];
  subclinicalResultOfVisit = [];
  otherSubclinicalResultOfPatient = [];

  resultLink = '';
  groupSub: Subscription;

  imageFrom = Constant.ImageForm;
  sourceConstant = Constant.SubclinicalResultSource;

  constructor(private subClinicalVisitService: SubClinicalVisitService, private store: Store<AppState>) {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.resultLink = data.subclinicalResultLink;
      }
    });
  }

  ngOnInit() {}

  getSubClinicalResult() {
    this.subClinicalVisitService.getSubClinicalResult(this.visit.id, this.visit.patientId).subscribe((data) => {
      let lstOfVisit = [];
      let lstOfPatient = [];
      new Promise((resolve, reject) => {
        let locationProtocol = location.protocol;
        for (var i = 0; i < data.length; i++) {
          let data_i = data[i];
          if (data_i != null && data_i.reports != null && data_i.reports.length > 0) {
            for (var j = 0; j < data_i.reports.length; j++) {
              let element = data_i.reports[j];
              let oldProtocol = new URL(element).protocol;
              data_i.reports[j] = data_i.reports[j].replace(oldProtocol, locationProtocol);
            }
          }
          // if (data_i != null && data_i.keyImages != null && data_i.keyImages.length > 0) {
          //   for (var j = 0; j < data_i.keyImages.length; j++) {
          //     let element = data_i.keyImages[j];
          //     //let oldProtocol = new URL(element).protocol;
          //     //data_i.keyImages[j] = data_i.keyImages[j].replace(oldProtocol, locationProtocol);
          //   }
          // }
          let visitId = this.visit.id;
          if (data_i.visitId == visitId || data_i.refVisitId == visitId || data_i.visitId == this.visit.refVisitId) {
            lstOfVisit.push(data_i);
          } else lstOfPatient.push(data_i);
        }
        resolve(true);
      }).then((res) => {
        this.subclinicalResultOfVisit = this.formatData(lstOfVisit);
        this.otherSubclinicalResultOfPatient = this.formatData(lstOfPatient);
        //console.log('subclinicalResultOfVisit', this.subclinicalResultOfVisit, this.otherSubclinicalResultOfPatient);
      });
    });
  }

  formatData(data) {
    let subclinicalResult = [];
    let subIds = data.map((en) => en.subClinicalVisitId).filter((value, index, self) => self.indexOf(value) === index);

    subIds.forEach((id) => {
      let lstSubNotHasResult = data.filter(
        (en) => en.subClinicalVisitId == id && en.source == Constant.SubclinicalResultSource.NotResult
      );
      let lstReportPacs = data.filter(
        (en) => en.subClinicalVisitId == id && en.source == Constant.SubclinicalResultSource.ResultFromPacs
      );
      // let lstImagePacs = data.filter(
      //   (en) => en.subClinicalVisitId == id && en.source == Constant.SubclinicalResultSource.ResultFromPacs
      // );
      let dataGeneral = data.find(
        (en) => en.subClinicalVisitId == id && en.source == Constant.SubclinicalResultSource.General
      );

      if (lstSubNotHasResult.length != 0) {
        lstSubNotHasResult.forEach((element) => {
          const item = {
            source: element.source,
            subClinicalVisitId: element.subClinicalVisitId,
            name: element.name,
            visitDate: element.visitDate,
            description: element.description,
            isExpand: false,
            visitId: id,
            refVisitId: element.refVisitId,
            referenceId : element.referenceId,
          };
          subclinicalResult.push(item);
        });
      }
      if (dataGeneral != null) {
        const item = {
          source: dataGeneral.source,
          subClinicalVisitId: dataGeneral.subClinicalVisitId,
          name: dataGeneral.name,
          visitDate: dataGeneral.visitDate,
          pdfLinks: dataGeneral.reports,
          pacsUrl: dataGeneral.pacsUrl,
          keyImages: dataGeneral.keyImages,
          isExpand: true,
          visitId: id,
          refVisitId: dataGeneral.refVisitId,
        };
        subclinicalResult.push(item);
      }
      if (lstReportPacs.length != 0) {
        const item = {
          source: lstReportPacs[0].source,
          subClinicalVisitId: lstReportPacs[0].subClinicalVisitId,
          name: lstReportPacs[0].name,
          visitDate: lstReportPacs[0].visitDate,
          pdfLinks: lstReportPacs[0].reports,
          keyImages: lstReportPacs[0].keyImages,
          pacsUrl: lstReportPacs[0].pacsUrl,
          reports: lstReportPacs,
          readDoctor: lstReportPacs[0].readDoctor,
          appprovedDoctor: lstReportPacs[0].appprovedDoctor,
          technician: lstReportPacs[0].technician,
          isExpand: true,
          visitId: id,
          refVisitId: lstReportPacs[0].refVisitId,
        };
        subclinicalResult.push(item);
      }
      // if (lstImagePacs.length != 0) {
      //   const item = {
      //     source: Constant.SubclinicalResultSource.ResultFromPacs,
      //     subClinicalVisitId: lstReportPacs[0].subClinicalVisitId,
      //     name: lstReportPacs[0].name,
      //     visitDate: lstReportPacs[0].visitDate,
      //     pdfLinks: lstReportPacs[0].reports,
      //     pacsUrl: lstReportPacs[0].pacsUrl,
      //     reports: lstReportPacs,
      //     readDoctor: lstReportPacs[0].readDoctor,
      //     appprovedDoctor: lstReportPacs[0].appprovedDoctor,
      //     technician: lstReportPacs[0].technician,
      //     isExpand: true,
      //     visitId: id,
      //     refVisitId: lstReportPacs[0].refVisitId,
      //   };
      //   subclinicalResult.push(item);
      // }
      // if (lstImagePacs.length != 0) {
      //   const item = {
      //     source: Constant.SubclinicalResultSource.ResultFromPacs,
      //     subClinicalVisitId: lstImagePacs[0].subClinicalVisitId,

      //     keyImages: lstImagePacs.keyImages,
      //     isExpand: true,
      //     visitId: id,
      //     //refVisitId: dataGeneral.refVisitId,
      //   };
      //   subclinicalResult.push(item);
      // }
    });
    return subclinicalResult;
  }

  openPdf(src) {
    this.isVisablePdf = true;
    this.linkPdf = src;
  }

  closePdf() {
    this.isVisablePdf = false;
  }

  expandBox(data) {
    data.isExpand = !data.isExpand;
  }
}
