<li *ngIf="imageFrom == 2" class="addImage {{ hideButton }}">
  <a (click)="showModalAddImg()">
    <img src="assets/image/DPicon/icon-add-green.svg" />
    <div class="thumbnail-title">Thêm ảnh</div>
  </a>
</li>
<app-image-mode-view
  #view1
  [userIdOnline]="userIdOnline"
  [lstImages]="lstImages"
  [visitId]="visitId"
  [selectedIndex]="selectedIndex"
  [imageFrom]="imageFrom"
  (closeShowImage)="closeModal()"
  (editImage)="showModalEditImg($event)"
  (refresh)="refresh($event)"></app-image-mode-view>

<!-- popup thêm ảnh -->
<app-m-modal
  class="modal-add-img"
  [modalId]="'list-image-general_add'"
  [isVisible]="isVisibleAddImg"
  (closeModal)="cancelAddImg()"
  [mMask]="true"
  [width]="550"
  [minWidth]="550"
  [height]="400"
  [minHeight]="400"
  [isResiable]="true">
  <ng-template #mModalTitle>
    <div class="header-title">Hình ảnh</div>
  </ng-template>
  <ng-template #mModalContent>
    <form [formGroup]="formAdd">
      <div id="paste-image" (paste)="onPaste($event)">
        <div class="row">
          <label class="col-4">Ca khám ngày</label>
          <span class="col-8">{{ visitDate | date: 'dd/MM/yyyy' }}</span>
        </div>
        <div class="row mg-bt-8">
          <label class="col-4">Phạm vi bảo mật </label>
          <div class="col-8">
            <nz-radio-group formControlName="policy">
              <label nz-radio [nzValue]="4" title="BN được xem dữ liệu và các BS trong group BS được xem/sửa dữ liệu">
                Trả cho BN
              </label>
              <label nz-radio [nzValue]="3" title="Các BS trong group BS được xem/sửa dữ liệu">Sửa trong nhóm</label>
              <label nz-radio [nzValue]="2" title="Các BS trong group BS được xem, chỉ tôi được sửa dữ liệu">
                Xem trong nhóm
              </label>
              <label nz-radio [nzValue]="1" title="Chỉ tôi được xem/sửa dữ liệu">Riêng tôi</label>
            </nz-radio-group>
          </div>
        </div>
        <div class="row mg-bt-8">
          <label class="col-4">Comment</label>
          <div class="col-8">
            <input
              nz-input
              [readonly]="!isUserCreateImage"
              nz-tooltip
              [nzTooltipTitle]="editCommentTooltip"
              type="text"
              formControlName="comment" />
            <ng-template #editCommentTooltip>
              <span id="edit-comment-tooltip">Chỉ người tạo được thêm/sửa comment</span>
            </ng-template>
          </div>
        </div>
        <div class="row mg-bt-8">
          <label class="col-4">Là dữ liệu CĐHA</label>
          <div class="col-8">
            <label nz-checkbox formControlName="isKeyImage"></label>
          </div>
        </div>
        <div class="row mg-bt-8">
          <label class="col-4">Hình ảnh</label>
          <div class="col-8">
            <img *ngIf="imageId !== ''" [src]="baseImageurl + fileName" class="edit-image" />
            <input *ngIf="imageId == ''" nz-input type="text" placeholder="(Bấm phím Ctrl+V để dán ảnh)" />
          </div>
        </div>

        <div *ngIf="imageId === ''" class="upload-image">
          <nz-upload
            class="upload-list-inline"
            nzType="drag"
            nzListType="picture-card"
            [nzShowUploadList]="{
              showRemoveIcon: true,
              showDownloadIcon: false
            }"
            [nzMultiple]="true"
            [nzAction]="mediaUrl"
            [nzHeaders]="header"
            nzName="FormFile"
            [(nzFileList)]="fileList"
            [nzBeforeUpload]="beforeUpload"
            (nzChange)="handleChange($event)">
            <p>
              <i class="icon-baseline" nz-icon nzType="inbox" style="margin-right: 10px"></i>Chọn hoặc thả ảnh vào đây
            </p>
          </nz-upload>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #mModalFooter>
    <button nz-button class="btn-primary-small" (click)="addVisitImage()">Lưu</button>
    <button nz-button class="btn-cancel-small" (click)="cancelAddImg()">Đóng</button>
  </ng-template>
</app-m-modal>
