import { Component, OnDestroy, Output, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromAuth from '../redux/auth.reducer';
import { AuthModel } from '../../../models/auth.model';
import { Observable, Subscription } from 'rxjs';
import { Constant } from '../../../share/constants/constant.class';
import { AuthService } from '../../../services/auth.service';
//import * as firebase from 'firebase';
// import { User } from '../../../models/user.class';
import { HttpParams } from '@angular/common/http';
import { UserService } from '../../../services/user-service';
import { error } from 'selenium-webdriver';
import { Cookie } from 'ng2-cookies';
import { AppState } from '../../app-state/app-state';
import { Login, LoginSuccess, ResetLockScreen, SSOLogout } from '../redux/auth.action';
import { NotificationService } from 'src/app/services/notification.service';
import { GroupMemberService } from 'src/app/services/group-member.service';
import { AppConfigService } from 'src/app-config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  validateForm: FormGroup;
  returnUrl: string;
  queryParam: any;
  messageError: string;
  sub: Subscription;
  //user: User;
  auth: AuthModel;
  isLoading = false;
  currentToken: any;
  _vapidKey = 'BAUZwDavMQm99S7pXfiW82rAQTYJS7FKeF39CT2h3mRDdKX01eiysN43n_B7H1z8SrdytM3rn7jYiYTsFgmhVG4';
  isMobile: boolean;
  isUpdateProfile: boolean;
  isNormal = true;
  code = '';
  isRegist = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private groupMemberService: GroupMemberService,
    private configService: AppConfigService
  ) {}

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    const newUserLogined = localStorage.getItem(Constant.SESSION_INVITE_FIRST_USER_PMR);
    console.log('zzzz', this.code, this.isRegist, newUserLogined);
    if (this.configService.getConfig().isUseSSOLogin && (newUserLogined === null || newUserLogined === '')) {
      console.log('zzzz isUseSSOLogin', this.code, this.isRegist, newUserLogined);
      const returnURL = window.location.origin + '/sso-login';
      this.store.dispatch(new SSOLogout(returnURL));
    }

    this.isMobile = window.innerWidth < 540;
    const currentUser = localStorage.getItem(Constant.TOKEN);
    if (currentUser) {
      this.router.navigate([Constant.WELCOME]);
    }

    this.validateForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || Constant.WELCOME;
    this.queryParam = this.route.snapshot.queryParams;
    if (this.isMobile) {
      this.returnUrl = Constant.WELCOME_MOBILE;
    }

    if (newUserLogined !== null && newUserLogined !== '') {
      this.isNormal = false;
      this.code = this.route.snapshot.queryParamMap.get('Code');
      this.isRegist = this.route.snapshot.queryParamMap.get('isRegist');
      console.log('eeee', this.code, this.isRegist, newUserLogined);
    }
  }

  submitForm(): void {
    if (this.validateForm.valid) {
      this.isLoading = true;
      const payload = {
        userName: this.validateForm.value.username,
        password: this.validateForm.value.password,
      };

      this.sub = this.authService.login(payload).subscribe(
        (res) => {
          if (res !== null) {
            const newUserLogined = localStorage.getItem(Constant.SESSION_INVITE_FIRST_USER_PMR);

            localStorage.setItem(Constant.TOKEN, res.value);
            localStorage.setItem(Constant.USER_INFO, JSON.stringify(res));
            this.store.dispatch(new LoginSuccess(res));
            this.store.dispatch(new ResetLockScreen());
            localStorage.setItem(Constant.LOCKSTATE, '1');

            console.log('jjjj', this.code, this.isRegist, newUserLogined);
            if (newUserLogined !== null && newUserLogined !== '' && this.code != null && this.code != '') {
              this.acceptJoin(this.code, res.userId, this.isRegist);
            } else {
              // else
              // {
              //   this.acceptJoin(1,code,res.userId);
              // }
              if (this.isMobile) {
                location.href = Constant.WELCOME_MOBILE;
              } else {
                this.router.navigate(['common/worklist'], { queryParams: this.queryParam });
              }
            }
            this.isLoading = false;
          } else {
            this.messageError = Constant.LOGIN_FAIL;
            this.isLoading = false;
          }
        },
        (error) => {
          this.messageError = Constant.LOGIN_FAIL;
          this.isLoading = false;
        }
      );
    } else {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
  }
  acceptJoin(code, userId, isRegist) {
    const payload = {
      code,
      status: 1,
      userid: userId,
    };
    this.groupMemberService.acceptJoinGroup(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            //le.huathi - rem: don't go to the new group for now
            // this.store.dispatch(new Join(this.joinGID));

            //if (isNewPmr == 2)
            localStorage.removeItem(Constant.SESSION_INVITE_FIRST_USER_PMR);
            if (isRegist === 'OK') {
              this.router.navigateByUrl('/update-user-profile?username=' + this.validateForm.value.username);
            } else {
              this.router.navigate(['/common/worklist']);
            }
            //neu là thành viên thì login
            //if (isNewPmr != 1)
            //this.router.navigate(['/common/worklist']);
          } else {
            if (res.errors && res.errors.length > 0) {
              if (res.errors.filter((en) => en.key === 'Accepted')) {
                //Accepted
                this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
              } else {
                this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
              }
              //Check nếu đã là thành viên rồi thì báo
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
}
