import { LockScreen } from './auth.action';
import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import * as authAction from '../redux/auth.action';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AppState } from '../../app-state/app-state';
import { Join } from './group-auth.action';

@Injectable()
export class AuthEffects {
  timer: any;
  constructor(private actions$: Actions, private authService: AuthService, private store: Store<AppState>) {}

  @Effect()
  login$: Observable<Action> = this.actions$.pipe(
    ofType<authAction.Login>(authAction.AuthActionTypes.Login),
    mergeMap((action: authAction.Login) =>
      this.authService.login(action.payload).pipe(
        map((data: any) => new authAction.LoginSuccess(data)),
        catchError((err) => of(new authAction.LoginFail(err)))
      )
    )
  );

  @Effect()
  ssoLogin$: Observable<any> = this.actions$.pipe(
    ofType<authAction.SSOLogin>(authAction.AuthActionTypes.SSOLogin),
    mergeMap((action: authAction.SSOLogin) =>
      this.authService.ssoLogin({ returnUrl: action.returnURL }).pipe(
        map((data: any) => {
          // return new authAction.LoginSuccess(data);
          window.location.replace(data.returnUrl);
        }),
        catchError((err) => of(new authAction.LoginFail(err)))
      )
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<authAction.Logout>(authAction.AuthActionTypes.Logout),
        tap((action) =>
          // this.authService.ssologout({ returnUrl: action.returnURL }).pipe(
          //   map((data: any) => {
          //     console.log('logout$');
          //     localStorage.removeItem(Constant.TOKEN);
          //     localStorage.removeItem(Constant.USER_INFO);
          //     localStorage.removeItem(Constant.FIREBASE_TOKEN);
          //     //window.location.href = '/login';
          //     window.location.replace(data.returnUrl);
          //   }),
          //   catchError((err) => of(new authAction.LogoutFail()))
          // )
          {
            console.log('logout$');
            localStorage.removeItem(Constant.TOKEN);
            localStorage.removeItem(Constant.USER_INFO);
            localStorage.removeItem(Constant.FIREBASE_TOKEN);
            if (action.queryParam && action.queryParam.patientId) {
              let queryParamString = new URLSearchParams(action.queryParam);
              window.location.href = '/login' + '?' + queryParamString;
            } else {
              if (action.returnURL !== '') {
                window.location.href = '/login?returnUrl=' + action.returnURL;
              } else {
                window.location.href = '/login';
              }
            }

            //window.location.replace(action.returnURL);
          }
        )
      ),
    { dispatch: false }
  );

  @Effect()
  ssoLogout$: Observable<any> = this.actions$.pipe(
    ofType<authAction.SSOLogout>(authAction.AuthActionTypes.SSOLogout),
    mergeMap((action: authAction.SSOLogout) =>
      this.authService.ssologout({ returnUrl: action.returnURL }).pipe(
        map((data: any) => {
          window.location.replace(data.returnUrl);
        }),
        catchError((err) => of(new authAction.LogoutFail()))
      )
    )
  );

  screenLock$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<authAction.ResetLockScreen>(authAction.AuthActionTypes.ResetLockScreen),
        tap((action) => {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.store.dispatch(new authAction.LockScreen());
            localStorage.setItem(Constant.LOCKSTATE, '-1');
            localStorage.removeItem(Constant.TOKEN);
            localStorage.removeItem(Constant.FIREBASE_TOKEN);
          }, 1800000);
        })
      ),
    { dispatch: false }
  );

  clearTimer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<authAction.ClearTime>(authAction.AuthActionTypes.ClearTime),
        tap((action) => {
          if (this.timer) {
            clearTimeout(this.timer);
          }
        })
      ),
    { dispatch: false }
  );
}
