<nz-modal
  nzClassName="about-pmr-modal"
  [(nzVisible)]="isVisible"
  [nzWidth]="870"
  [nzFooter]="null"
  (nzOnCancel)="handleCancel()">
  <div class="d-flex">
    <div class="about-content">
      <div class="text-title">
        <span class="text-green">PMR</span><span class="text-black"> - </span>
        <span class="text-green">P</span
        ><span class="text-black">atient </span>
        <span class="text-green">M</span
        ><span class="text-black">edical </span>
        <span class="text-green">R</span><span class="text-black">ecords</span>
      </div>
      <div class="p-text-grey">
        <div class="mg-bt-10">
          PMR (Patient Medical Records) là hệ thống quản lý dữ liệu bệnh nhân
          thống nhất với user chính là bác sỹ và bệnh nhân, kết nối linh hoạt
          với các hệ thống HIS, LIS, PACS để cập nhật dữ liệu tự động.
        </div>
        <div class="mg-bt-20">
          PMR có chức năng tương tự như một hệ thống bệnh án điện tử (EMR -
          Electronic Medical Record).
        </div>
        <div class="border-dashed"></div>
      </div>
      <div class="support-container">
        <div class="text-title-blue">Liên hệ hỗ trợ</div>
        <div class="support-content">
          <div class="mg-bt-10">
            <img class="mg-r-15" src="assets/image/phone.svg" />
            <span class="text-black">0919 175 115</span>
          </div>
          <div class="mg-bt-10">
            <img class="mg-r-15" src="assets/image/email.svg" />
            <span class="text-black">cskh@mesoco.vn</span>
          </div>
          <div class="mg-bt-10">
            <img class="mg-r-15" src="assets/image/website.svg" />
            <a href="https://mesoco.vn/pmr" target="_blank" class="text-info"
              >https://mesoco.vn/pmr</a
            >
          </div>
        </div>
      </div>
      <div class="copy-right">© Copyright 2019 - Mesoco.vn</div>
    </div>
    <div class="">
      <img src="assets/image/image-about-pmr.png" />
    </div>
  </div>
</nz-modal>
