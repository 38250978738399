import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-patient-family-history',
  templateUrl: './patient-family-history.component.html',
  styleUrls: ['./patient-family-history.component.scss'],
})
export class PatientFamilyHistoryComponent implements OnInit {
  @Input() patient: any;
  isVisibleModal: boolean;
  age: any;
  relationShips = [
    { name: 'Bố', id: 0 },
    { name: 'Mẹ', id: 1 },
    { name: 'Anh', id: 2 },
    { name: 'Chị', id: 3 },
    { name: 'Con', id: 4 },
    { name: 'Ông', id: 5 },
    { name: 'Bà', id: 6 },
  ];
  selectedItem = {
    id: '',
    patientId: '',
    relationShip: 0,
    disease: '',
  };
  mockArray = [];
  constructor(
    private patientService: PatientService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.selectedItem.patientId = this.patient.id;
    this.getList();
  }
  getList(): void {
    this.patientService.getPatientFamilyHistory(this.patient.id).subscribe(
      (res) => {
        if (res.d !== null && res.d.errors.length === 0) {
          this.mockArray = res.d.jsonData;
        }
      },
      (error) => {
        this.notificationService.showNotification('error', 'Error');
      }
    );
  }
  showModal(): void {
    this.isVisibleModal = true;
    this.selectedItem = {
      id: '',
      patientId: this.patient.id,
      relationShip: 0,
      disease: '',
    };
  }
  handleCancel(): void {
    this.isVisibleModal = false;
  }
  handleOk(): void {
    this.selectedItem.relationShip = +this.selectedItem.relationShip;
    //this.mockArray.push(this.selectedItem);
    //console.log(this.mockArray);
    this.isVisibleModal = false;

    this.patientService.updateFamilyHistory(this.selectedItem).subscribe(
      (res) => {
        if (res !== null && res.isValid) {
          this.getList();
          this.notificationService.showNotification(
            'success',
            'Cập nhật bệnh sử thành công'
          );
        }
      },
      (error) => {
        this.notificationService.showNotification('error', 'Error');
      }
    );
  }
  getRelationShipName(id): any {
    const r = this.relationShips.find((en) => en.id === id);
    if (r) {
      return r.name;
    }
  }
}
