import { Component, Input, OnInit } from '@angular/core';
import { FormElement } from 'src/app/models/form-model/form.class';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-datatime-element',
  templateUrl: './datatime-element.component.html',
  styleUrls: ['./datatime-element.component.scss'],
})
export class DatatimeElementComponent implements OnInit {
  _data: FormElement;
  value: Date;

  @Input() set data(val: FormElement) {
    if (val) {
      this._data = val;
      this.value = this._data.valueDateTime;
    }
  }

  get data() {
    return this._data;
  }

  constant: Constant;
  constructor() {}

  ngOnInit() {}
  onModelChange(val) {
    this.data.isDirty = true;
    this._data.valueDateTime = val;
  }
}
