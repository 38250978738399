<div class="title-wrapper">
  <div class="d-flex justify-content-between mg-bt-15">
    <div class="d-flex">
      <div style="width: 200px" class="mg-r-10 msc-group-control width-obser">
        <nz-select
          [nzPlaceHolder]="'Người thực hiện'"
          nzAllowClear
          [(ngModel)]="dataSearchMedicalOrder.executorId"
          [nzShowSearch]="true"
          >>
          <nz-option
            *ngFor="let user of userInGroup"
            [nzValue]="user.id"
            [nzLabel]="user.fullName">
          </nz-option>
        </nz-select>
      </div>
      <div style="width: 200px" class="mg-r-10 msc-group-control width-obser">
        <nz-select
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'Trạng thái'"
          [(ngModel)]="dataSearchMedicalOrder.status">
          <nz-option
            *ngFor="let data of list_status; let i = index"
            [nzValue]="i + 1"
            [nzLabel]="data">
          </nz-option>
        </nz-select>
      </div>
      <div style="width: 200px" class="mg-r-10 msc-group-control width-obser">
        <nz-date-picker
          (ngModelChange)="convertExecuteDate($event)"
          style="width: inherit"
          class="date"
          [nzPlaceHolder]="'Thời gian thực hiện'"
          [(ngModel)]="dataSearchMedicalOrder.executeDate">
        </nz-date-picker>
      </div>
      <div style="width: 200px" class="mg-r-10 msc-group-control width-obser">
        <nz-date-picker
          (ngModelChange)="convertCreatedDate($event)"
          style="width: inherit"
          class="date"
          [nzPlaceHolder]="'Thời gian tạo'"
          [(ngModel)]="dataSearchMedicalOrder.createdDate">
        </nz-date-picker>
      </div>
      <div style="width: 200px" class="mg-r-10 msc-group-control width-obser">
        <nz-select
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'Loại y lệnh'"
          [(ngModel)]="dataSearchMedicalOrder.type">
          <nz-option
            *ngFor="let data of type; let i = index"
            [nzValue]="i + 1"
            [nzLabel]="data">
          </nz-option>
        </nz-select>
      </div>

      <button
        nz-button
        class="pointer mg-r-10"
        (click)="searchMedicalOrder(dataSearchMedicalOrder)">
        <i nz-icon nzType="search" nzTheme="outline"></i>
      </button>
      <button nz-button class="pointer mg-r-10" (click)="reloadDataTable()">
        <i>
          <img src="assets/image/DPicon/icon-close.svg" />
        </i>
      </button>
    </div>
  </div>

  <nz-table
    #medicalVisitTable
    class="main-table-grid"
    [nzFrontPagination]="false"
    [(nzPageSize)]="pageSize"
    [(nzPageIndex)]="pageIndex"
    [nzShowPagination]="true"
    (nzQueryParams)="onQueryParamsChange($event)"
    [(nzData)]="lstDataOfVisit"
    [nzTotal]="total"
    nzShowPagination="false"
    [nzPageSizeOptions]="[15, 50, 100]"
    [nzScroll]="{ y: scrollY }"
    nzShowSizeChanger>
    <thead>
      <tr>
        <th nzWidth="50px">STT</th>
        <th>Tên bệnh nhân</th>
        <th>Y lệnh</th>
        <th>Mô tả</th>
        <th>Người tạo</th>
        <th>Thời gian tạo</th>
        <th>Thời gian dự kiến</th>
        <th>Người thực hiện</th>
        <th>Trạng thái</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of medicalVisitTable.data; index as i">
        <td>
          {{
            medicalVisitTable.nzPageSize * (medicalVisitTable.nzPageIndex - 1) +
              i +
              1
          }}
        </td>
        <td>{{ order.patientName }}</td>
        <td>{{ order.name }}</td>
        <td>{{ order.desc }}</td>
        <td>{{ order.creatorName }}</td>
        <td>{{ order.createdDate | date: 'dd/MM/yyyy HH:mm' }}</td>
        <td>{{ order.executeDate | date: 'dd/MM/yyyy HH:mm' }}</td>
        <td>{{ order.executorName }}</td>
        <td *ngIf="order.status == 1">
          <button class="status new">Chưa thực hiện</button>
        </td>
        <td *ngIf="order.status == 2">
          <button class="status reject">Đã từ chối</button>
        </td>
        <td *ngIf="order.status == 3">
          <button class="status cancel">Đã huỷ</button>
        </td>
        <td *ngIf="order.status == 4">
          <button class="status done">Đã thực hiện</button>
        </td>
        <td>
          <i
            nz-icon
            nzType="edit"
            class="mg-r-10"
            title="Chỉnh sửa"
            (click)="showModalForm('edit', order)"
            nzTheme="outline"></i>
          <i
            nz-icon
            nzType="info-circle"
            class="mg-r-10"
            title="Chi tiết"
            (click)="showModalForm('view', order)"
            nzTheme="outline"></i>
          <i
            nz-icon
            nzType="node-expand"
            title="Cập nhật trạng thái"
            (click)="showModalForm('updateStatus', order)"
            nzTheme="outline"></i>
        </td>
      </tr>
    </tbody>
    <ng-template #rangeTemplate let-range="range" let-total>
      {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }}
    </ng-template>
  </nz-table>
</div>

<nz-modal
  [(nzVisible)]="isVisibleForm"
  [nzTitle]="
    isAddOder
      ? 'Thêm'
      : 'Cập nhật' + ' y lệnh cho BN ' + currentOrder.patientName
  "
  nzWidth="700px"
  nzMaskClosable="false"
  (nzOnCancel)="cancelAdd()">
  <div>
    <div class="row mg-bt-10">
      <div class="col-3">Danh mục y lệnh <span class="red-dot">*</span></div>
      <div class="col-9">
        <nz-select
          class="w-100"
          [(ngModel)]="currentOrder.medicalOrderId"
          (ngModelChange)="selectOrderChange($event)"
          nzAllowClear
          nzPlaceHolder="Chọn loại y lệnh">
          <nz-option
            nzCustomContent
            *ngFor="let medical of lstOrderSource"
            [nzValue]="medical.id"
            [nzLabel]="medical.name">
            <div>{{ medical.name }}</div>
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Tên y lệnh <span class="red-dot">*</span></div>
      <div class="col-9">
        <input
          nz-input
          type="text"
          [(ngModel)]="currentOrder.name"
          placeholder="Nhập tên y lệnh" />
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Mô tả</div>
      <div class="col-9">
        <input
          nz-input
          type="text"
          [(ngModel)]="currentOrder.desc"
          placeholder="Nhập mô tả nội dung" />
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Người thực hiện</div>
      <div class="col-9">
        <nz-select
          class="w-100"
          [(ngModel)]="currentOrder.executorId"
          nzAllowClear
          nzPlaceHolder="Chọn người thực hiện">
          <nz-option
            nzCustomContent
            *ngFor="let user of userInGroup"
            [nzValue]="user.id"
            [nzLabel]="user.fullName">
            <div>{{ user.fullName }}</div>
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Ngày thực hiện</div>
      <div class="col-9">
        <nz-date-picker
          style="width: 200px"
          nzPlaceHolder="DD/MM/YYYY"
          nzFormat="dd/MM/yyyy"
          [(ngModel)]="currentOrder.executeDate">
        </nz-date-picker>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-small" (click)="cancelAdd()">
      Đóng
    </button>
    <button nz-button class="btn-primary-small" (click)="saveMedicalOrder()">
      Lưu
    </button>
  </div>
</nz-modal>

<nz-modal
  [(nzVisible)]="showUpdateStatus"
  nzTitle="Cập nhật trạng thái y lệnh"
  nzWidth="700px"
  nzMaskClosable="false"
  (nzOnCancel)="cancelUpdateStatus()">
  <div>
    <div class="row mg-bt-10">
      <div class="col-3">Thông tin y lệnh</div>
      <div class="col-9">
        <div>
          BN {{ currentVisit.patientName }} -
          {{ getPatientAge(currentVisit.dob) }} -
          {{ currentVisit.patientSexText }}
        </div>
        <div>Y lệnh: {{ currentOrder.name }}</div>
        <div>
          BS ra y lệnh: {{ currentOrder.creatorName }} ({{
            currentOrder.executeDate | date: 'dd/MM/yyyy HH:mm'
          }})
        </div>
        <div>Trạng thái: {{ currentOrder.statusText }}</div>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Trạng thái</div>
      <div class="col-9">
        <nz-select
          class="w-100"
          [(ngModel)]="formUpdateStatus.status"
          nzAllowClear
          nzPlaceHolder="Chọn trạng thái">
          <nz-option
            *ngFor="let data of list_status; let i = index"
            [nzValue]="i + 1"
            [nzLabel]="data">
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Thời gian thực hiện</div>
      <div class="col-9">
        <nz-date-picker
          style="width: 200px"
          nzPlaceHolder="DD/MM/YYYY"
          nzFormat="dd/MM/yyyy"
          [(ngModel)]="formUpdateStatus.executeDate">
        </nz-date-picker>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Nội dung thêm</div>
      <div class="col-9">
        <textarea nz-input [(ngModel)]="formUpdateStatus.otherInfo"></textarea>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-small" (click)="cancelUpdateStatus()">
      Đóng
    </button>
    <button nz-button class="btn-primary-small" (click)="updateStatus()">
      Cập nhật
    </button>
  </div>
</nz-modal>
<nz-modal
  [(nzVisible)]="isVisibleView"
  nzTitle="Chi tiết y lệnh"
  nzMaskClosable="false"
  (nzOnCancel)="cancelView()">
  <div class="row_info d-flex">
    <div class="title">Thông tin BN:&nbsp;</div>
    <div>
      {{ currentVisit.patientName }} - {{ getPatientAge(currentVisit.dob) }} -
      {{ currentVisit.patientSexText }}<br />
      Địa chỉ: {{ currentVisit.address }}<br />
      SĐT: {{ currentVisit.phoneNo }}<br />
    </div>
  </div>
  <div class="row_info">
    <span class="title">Y lệnh: </span>{{ currentOrder.name }}
  </div>
  <div class="row_info">
    <span class="title">BS ra y lệnh: </span>{{ currentOrder.creatorName }}
  </div>
  <div class="row_info">
    <span class="title">Ca khám: </span
    >{{ currentVisit.visitDate | date: 'dd/MM/yyyy HH:mm' }}
    <i
      (click)="
        addTab(
          currentOrder.visitId,
          currentVisit.patientName,
          currentVisit.patientId
        )
      "
      style="margin-left: 12px; font-size: 20px"
      nz-icon
      nzType="arrow-right"
      nzTheme="outline"></i>
  </div>
  <div class="row_info">
    <span class="title">Trạng thái: </span>{{ currentOrder.statusText }}
  </div>
  <div class="row_info">
    <span class="title">Thời gian dự kiến: </span
    >{{ currentOrder.executeDate | date: 'dd/MM/yyyy HH:mm' }}
  </div>
  <div class="row_info">
    <span class="title">Người thực hiện: </span>{{ currentOrder.creatorName }}
  </div>
  <div *ngIf="currentOrder.orderResponse.length > 0" class="row_info">
    <span class="title"
      >Danh sách cập nhật trạng thái ({{
        currentOrder.orderResponse.length
      }}):</span
    >
    <ul class="log-content">
      <li *ngFor="let log of currentOrder.orderResponse" class="d-flex">
        <div class="title">
          - {{ log.date | date: 'dd/MM/yyyy HH:mm' }}:&nbsp;
        </div>
        <div>
          {{ log.who }} - {{ log.orderStatus }} <br />
          {{ log.description }}
        </div>
      </li>
    </ul>
  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-primary-small" (click)="viewToUpdateStatus()">
      Cập nhật trạng thái
    </button>
    <button
      *ngIf="curUserId == currentOrder.creatorId && currentOrder.status != 3"
      nz-button
      class="btn-cancel-small"
      (click)="cancelOrder()">
      Hủy y lệnh
    </button>
    <button nz-button class="btn-cancel-small" (click)="cancelView()">
      Đóng
    </button>
  </div>
</nz-modal>
