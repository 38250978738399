import { SubclinicalorderGroup } from './../../../models/subclinicalorder-group.class';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { Subscription } from 'rxjs';
import { SubclinicalorderGroupService } from 'src/app/services/subclinicalorder-group.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Constant } from 'src/app/share/constants/constant.class';
import { SubclinicalorderService } from 'src/app/services/subclinicalorder.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-subclinicalorder-group',
  templateUrl: './subclinicalorder-group.component.html',
  styleUrls: ['./subclinicalorder-group.component.scss'],
})
export class SubclinicalorderGroupComponent implements OnInit {
  groupId: string;
  groupSub: Subscription;
  lstSubclinicalorder = [];
  lstOrderSource = [];
  isVisible = false;

  emptyId = '000000000000000000000000';
  selectedRowIndex = this.emptyId;
  pageIndex = 1;
  // pageSize = 20;
  total: number;
  scrollY: string = window.innerHeight - 365 + 'px';
  subClinicalOrderGroupModalTitle = '';
  isViewOrderGroupModal = false;
  dataSearch: any = {
    groupId: '',
    pageSize: 10,
    page: 1,
    orderGroupName: '',
  };
  isAdminGroup = false;
  curUserId = '';

  dataForm: SubclinicalorderGroup = {
    groupId: '',
    listOrderId: [],
    orderGroupName: '',
    description: '',
    privacy: 1,
  };
 
  PRIVACY_SUBCLINICALORDER = Constant.PRIVACY_SUBCLINICALORDER;
  constructor(
    private notificationService: NotificationService,
    private store: Store<AppState>,
    private subclinicalorderGroupService: SubclinicalorderGroupService,
    private subclinicalorderService: SubclinicalorderService,
    private modalService: NzModalService,
    private shareService: ShareService
  ) {
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
  }

  ngOnInit(): void {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        if (
          data.role == Constant.UserRoleType.Admin ||
          data.role == Constant.UserRoleType.Owner
        ) {
          this.isAdminGroup = true;
        }
      }
    });
    this.getSubclinicalOrderGroupAll();
    this.getSubClinicalOrder();
  }

  getSubclinicalOrderGroupAll() {
    const payload = {
      groupId: this.groupId,
      // pageSize: this.pageSize,
      page: this.pageIndex,
      orderGroupName: this.dataSearch.orderGroupName,
    };
    this.subclinicalorderGroupService
      .searchSubclinicalOrderGroup(payload)
      .subscribe((res) => {
        this.lstSubclinicalorder = res.source;
        if (this.isAdminGroup) {
          this.lstSubclinicalorder = this.lstSubclinicalorder.map((obj) => ({
            ...obj,
            canEdit: true,
          }));
        }  else {
          const myOwner = this.lstSubclinicalorder
            .filter(
              (en) =>
                en.privacy == this.PRIVACY_SUBCLINICALORDER.OnlyMe.value &&
                en.creatorId == this.curUserId
            )
            .map((obj) => ({ ...obj, canEdit: true }));
          const group = this.lstSubclinicalorder
            .filter(
              (en) => en.privacy == this.PRIVACY_SUBCLINICALORDER.InGroup.value
            )
            .map((obj) => ({ ...obj, canEdit: false }));
          this.lstSubclinicalorder = [...myOwner, ...group];
          // this.total = res.jsonData.total;
        }
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    // this.pageSize = pageSize;
    // (this.dataSearch.page = params.pageIndex),
    //   (this.dataSearch.pageSize = params.pageSize),
    this.getSubclinicalOrderGroupAll();
  }

  searchSubClinicalOrder(datasend) {
    this.dataSearch = {
      groupId: this.groupId,
      // pageSize: this.pageSize,
      page: this.pageIndex,
      orderGroupName: datasend.orderGroupName,
    };
    this.getSubclinicalOrderGroupAll();
  }

  reloadDataTable() {
    this.pageIndex = 1;
    this.dataSearch = {
      groupId: '',
      pageSize: 20,
      page: 1,
      orderGroupName: '',
    };
    this.getSubclinicalOrderGroupAll();
    this.getSubClinicalOrder();
  }

  // lấy ra danh sách chỉ định
  getSubClinicalOrder() {
    this.subclinicalorderService.getSubclinicalOrderAll().subscribe((res) => {
      console.log('h1', res )
      this.lstOrderSource = res;
    });
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  showModal(type: string): void {
    this.isVisible = true;
    this.isViewOrderGroupModal = false;
    if (type == 'add') {
      this.subClinicalOrderGroupModalTitle = 'Thêm nhóm chỉ định';
      this.selectedRowIndex = this.emptyId;
      this.dataForm = {
        groupId: '',
        listOrderId: [],
        orderGroupName: '',
        description: '',
        privacy: 1,
      };
    } else if (type == 'view') {
      this.isViewOrderGroupModal = true;
      this.subClinicalOrderGroupModalTitle = 'Chi tiết nhóm chỉ định';
    } else {
      this.subClinicalOrderGroupModalTitle = 'Sửa nhóm chỉ định';
    }
  }

  onClickRow(data) {
    this.rowSelected(data);
    if (data.canEdit) {
      this.showModal('edit');
    } else {
      this.showModal('view');
    }
  }

  rowSelected(data) {
    this.selectedRowIndex = data.id;
    this.dataForm = {
      groupId: data.groupId,
      listOrderId: data.listOrderId,
      orderGroupName: data.orderGroupName,
      description: data.description,
      privacy: data.privacy,
    };
  }

  addOrSave(datasend) {
    if (
      this.shareService.checkEmptyArray(datasend.listOrderId) ||
      this.shareService.checkEmptyStr(datasend.orderGroupName)
    ) {
      return this.notificationService.showNotification(
        'warning',
        'Bạn chưa nhập đủ dữ liệu!'
      );
    }

    this.dataForm = {
      groupId: this.groupId,
      listOrderId: datasend.listOrderId,
      orderGroupName: datasend.orderGroupName,
      description: datasend.description,
      privacy: datasend.privacy,
    };
    this.subclinicalorderGroupService
      .addSubclinicalOrderGroup(this.dataForm)
      .subscribe(
        (data) => {
          this.notificationService.showNotification('success', 'Thành công');
          this.isVisible = false;
          this.reloadDataTable();
        },
        (error) => {
          let errorMessage = 'Có lỗi xảy ra!';
          if (error.message) {
            errorMessage = error.message;
          }
          this.notificationService.showNotification('error', errorMessage);
        }
      );
  }

  modifyOrderGroup(datasend) {
    if (
      this.shareService.checkEmptyArray(datasend.listOrderId) ||
      this.shareService.checkEmptyStr(datasend.orderGroupName)
    ) {
      return this.notificationService.showNotification(
        'warning',
        'Bạn chưa nhập đủ dữ liệu!'
      );
    }
    const payload = {
      id: this.selectedRowIndex,
      groupId: this.groupId,
      listOrderId: datasend.listOrderId,
      orderGroupName: datasend.orderGroupName,
      description: datasend.description,
      privacy: datasend.privacy,
    };
    this.subclinicalorderGroupService
      .updateSubclinicalOrderGroup(payload)
      .subscribe(
        (data) => {
          this.notificationService.showNotification('success', 'Thành công');
          this.isVisible = false;
          this.reloadDataTable();
        },
        (error) => {
          this.notificationService.showNotification('error', 'Có lỗi xảy ra!');
        }
      );
    this.selectedRowIndex = this.emptyId;
  }

  deleteSubclinicalOrder(data) {
    const id = data;
    if (id == this.emptyId) {
      this.notificationService.showNotification(
        'warning',
        'Bạn chưa chọn dòng để xoá!'
      );
    } else {
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn xóa hay không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Huỷ',
        nzOnOk: () => this.deleteItem(id),
      });
    }
  }

  deleteItem(id) {
    this.subclinicalorderGroupService
      .deleteSubclinicalOrderGroup(id)
      .subscribe((data) => {
        this.notificationService.showNotification('success', 'Xoá thành công');
        this.reloadDataTable();
      }).unsubscribe;
    this.selectedRowIndex = this.emptyId;
  }
}
