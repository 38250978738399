<nz-modal
  [nzVisible]="isVisible"
  nzTitle="Chọn nhóm làm việc"
  (nzOnCancel)="onCancel()">
  <div class="msc-m-modal-content">
    <!-- <div>
      <input nz-input placeholder="Tìm nhóm/danh mục nhóm" (keyup)="searchGroup($event)">
    </div> -->
    <app-mobile-recursive-group
      [menus]="menus"
      (selectItem)="selectGroup($event)"></app-mobile-recursive-group>
  </div>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="onCancel()">Đóng</button>
  </div>
</nz-modal>
