export class AddSubClinicalVisit {
  visitId: string;
  subClinicalOrders: any[];
}

export class SearchSuborderAndForm {
  keyword: string;
  rosCategoryId: string;
  type: number;
  rosId: string;
  rosName: string;
}

export class SubItem {
  isExpand: boolean;
  name: string;
  id: string;
  data: any[];
  rosCategoryId?: string;
}
