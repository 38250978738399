import { filter } from 'rxjs/operators';
import { event } from 'jquery';
import { error } from 'selenium-webdriver';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { WebhookService } from 'src/app/services/webhook.service';
import { Constant } from 'src/app/share/constants/constant.class';

const defaultEvents = [
  {
    id: '1',
    eventName: 'PacsOrderCreated',
    eventNameVN: 'Chỉ định chẩn đoán hình ảnh',
    eventActive: false,
  },
  {
    id: '2',
    eventName: 'LisOrderCreated',
    eventNameVN: 'Chỉ định xét nghiệm',
    eventActive: false,
  },
  {
    id: '3',
    eventName: 'MedicationCreated',
    eventNameVN: 'Tạo đơn thuốc cho bệnh nhân',
    eventActive: false,
  },
  {
    id: '4',
    eventName: 'PacsOrderCancelled',
    eventNameVN: 'Xóa chỉ định chẩn đoán hình ảnh',
    eventActive: false,
  },
  {
    id: '15',
    eventName: 'LisOrderCancelled',
    eventNameVN: 'Xóa chỉ định xét nghiệm',
    eventActive: false,
  },
  {
    id: '5',
    eventName: 'UpdateVisitConclusion',
    eventNameVN: 'Cập nhật kết luận sang HIS',
    eventActive: false,
  },
  {
    id: '6',
    eventName: 'SyncLog',
    eventNameVN: 'Đồng bộ log sang HIS',
    eventActive: false,
  },
  {
    id: '7',
    eventName: 'HISOrderCancelled',
    eventNameVN: 'Đồng bộ Xóa chỉ định chẩn đoán hình ảnh ở HIS',
    eventActive: false,
  },
  {
    id: '8',
    eventName: 'HISOrderInfo',
    eventNameVN: 'Cập nhật trạng thái chỉ định khi nhận KQ từ PACS',
    eventActive: false,
  },
  {
    id: '9',
    eventName: 'UpdateVisit',
    eventNameVN: 'Cập nhật ca khám',
    eventActive: false,
  },
  {
    id: '10',
    eventName: 'UpdatePatient',
    eventNameVN: 'Cập nhật bệnh nhân',
    eventActive: false,
  },
  {
    id: '11',
    eventName: 'UpdateSubclinicalStatus',
    eventNameVN: 'Cập nhật trạng thái chỉ định',
    eventActive: false,
  },
  {
    id: '12',
    eventName: 'UpdatePatientVisitHL7',
    eventNameVN: 'Cập nhật thông tin bệnh nhân HL7',
    eventActive: false,
  },
  {
    id: '14',
    eventName: 'CreatePrescriptionHL7',
    eventNameVN: 'Thêm đơn thuốc HL7',
    eventActive: false,
  },
  {
    id: '15',
    eventName: 'UpdateVisitClinicalInfo',
    eventNameVN: 'Cập nhật thông tin lâm sàng sang lý do khám HIS',
    eventActive: false,
  },
  {
    id: '16',
    eventName: 'UpdateVisitDisease',
    eventNameVN: 'Cập nhật chẩn đoán theo mã icd 10 sang HIS',
    eventActive: false,
  },
  {
    id: '17',
    eventName: 'UpdateVisitDiagnoseInit',
    eventNameVN: 'Cập nhật chẩn đoán ban đầu sang HIS',
    eventActive: false,
  },
  {
    id: '18',
    eventName: 'UpdateVisitDiagnoseInit2Pacs',
    eventNameVN: 'Cập nhật chẩn đoán ban đầu sang PACS',
    eventActive: false,
  },
  {
    id: '19',
    eventName: 'UpdatePatientToLIS',
    eventNameVN: 'Cập nhật thông tin BN sang LIS',
    eventActive: false,
  },
  {
    id: '20',
    eventName: 'UpdateVisitVitalSign',
    eventNameVN: 'Cập nhật thông tin sinh tồn sang HIS',
    eventActive: false,
  },
  {
    id: '21',
    eventName: 'UpdateDoctorInfoHIS',
    eventNameVN: 'Cập nhật thông tin bác sĩ sang HIS',
    eventActive: false,
  },
];

@Component({
  selector: 'app-webhook-modal',
  templateUrl: './webhook-modal.component.html',
  styleUrls: ['./webhook-modal.component.scss'],
})
export class WebhookModalComponent implements OnInit {
  _isVisible = false;
  _currentWebhook: any = {};
  @Input() set isVisible(val: boolean) {
    if (val) {
    }
    this._isVisible = val;
  }
  @Input() set currentWebhook(val: any) {
    this._currentWebhook = val;
    if (this.shareService.checkEmpty(this._currentWebhook) || this.shareService.checkEmpty(this._currentWebhook.id)) {
      this.genSecretKey();
      this.events = JSON.parse(JSON.stringify(defaultEvents));
    } else {
      this.formData.patchValue({
        name: this._currentWebhook.name,
        url: this._currentWebhook.url,
        secretKey: this._currentWebhook.secretKey,
      });
      this.secretKey = this._currentWebhook.secretKey;
      this.events = JSON.parse(JSON.stringify(defaultEvents));
      this.events.forEach((item) => {
        if (this._currentWebhook.events.filter((t) => t.eventName == item.eventName && t.eventActive).length > 0) {
          item.eventActive = true;
        }
      });
    }
  }

  get currentWebhook() {
    return this._currentWebhook;
  }
  @Input() groupId: string;
  @Output() isVisibleChange: EventEmitter<any> = new EventEmitter();
  formData: FormGroup;
  events: any[] = [];
  get isVisible(): boolean {
    return this._isVisible;
  }
  secretKey = '';

  constructor(
    private fb: FormBuilder,
    private shareService: ShareService,
    private modalService: NzModalService,
    private notificationService: NotificationService,
    private webhookService: WebhookService
  ) {
    this.formData = this.fb.group({
      name: ['', [Validators.required], [this.emptyAsyncValidator]],
      url: ['', [Validators.required]],
      secretKey: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.formData.get('secretKey').disable();
  }

  handleCancel() {
    this.formData.reset();
    this.isVisibleChange.emit(false);
  }

  genSecretKey() {
    this.webhookService.genKeyWebhook().subscribe((res) => {
      this.formData.patchValue({
        secretKey: res.key,
      });
      this.secretKey = res.key;
    });
  }

  emptyAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.shareService.checkEmptyStr(control.value)) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, emptyName: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  urlAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (!this.shareService.isUrlValid(control.value)) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, invalidUrl: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  nonUnicodeAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (
        !this.shareService.NON_UNICODE_REGEX.test(control.value) ||
        this.shareService.NON_WHITESPACE_REGEX.test(control.value)
      ) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, nonUnicodeWhitespace: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  saveWebhook() {
    this.validateForm();
    if (this.formData.valid) {
      let data = this.formData.value;
      const activeEvents = this.events.map((t) => ({
        eventName: t.eventName,
        eventActive: t.eventActive,
      }));
      data = {
        ...data,
        ...{
          events: activeEvents,
          groupId: this.groupId,
          secretKey: this.secretKey,
        },
      };
      if (this.currentWebhook) {
        data = { ...data, ...{ id: this.currentWebhook.id } };
      }
      this.webhookService.saveWebhook(data).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Lưu thành công');
          this.formData.reset();
          this.isVisibleChange.emit(false);
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    }
  }

  validateForm() {
    for (const i in this.formData.controls) {
      this.formData.controls[i].markAsDirty();
      this.formData.controls[i].updateValueAndValidity();
    }
  }
}
