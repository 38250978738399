import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Password } from 'src/app/models/userprofile.class';
import { AppConfigService } from 'src/app-config.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { NotificationService } from '../../../services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  user: any = {
    fullName: '',
    dob: '',
    sex: '',
    phone: '',
    email: '',
    address: '',
    doctorRankName: '',
    /*doctorRankId: '',*/
    doctorRank: '',
  };
  isValid = false;
  isVisible = false;
  isVisible_pass = false;
  isVisible_avatar = false;
  lstLogs: any[] = [];
  isLoading = false;
  currentPassword: '';
  newPassword: '';
  repeatPassword: '';
  isShown = false;
  listOfDisplayData: any;
  listworklist: any[] = [];
  fileName: string;
  group: any = {
    groupName: '',
    joinedDate: '',
  };
  log: any = {
    dateCreated: '',
    displayLog: '',
    action: '',
  };
  listDoctorRank: any[] = [];
  DoctorRank: any = {
    id: '',
    name: '',
  };
  avatar: any;
  page: number;
  pageSize: number;
  url = '';
  dataForm: Password = new Password();
  baseAvatarurl = '';
  errorMessage: string;
  uploadFileEvent = '';
  listExportData: {
    'Họ và tên': '';
    'Giới tính': '';
    'Ngày sinh': '';
    'Email': '';
    'SĐT': '';
    'Địa chỉ': '';
    'Chức danh': '';
  };
  isMobile: boolean;
  formEditInfo: FormGroup;
  submitted: boolean;

  @Input() enableEditUser = false;

  constructor(
    private userprofileService: UserProfileService,
    private configService: AppConfigService,
    private notificationService: NotificationService,
    private notification: NotificationService,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {
    this.formEditInfo = this.formBuilder.group({
      address: [''],
      fullName: ['', Validators.required],
      //doctorRankId: ['', Validators.required],
      doctorRank: [''],
      sex: [1, Validators.required],
      email: ['', Validators.required],
      dob: [null, Validators.required],
      phone: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.isMobile = window.innerWidth < 540;
    this.baseAvatarurl =
      this.configService.getConfig().media.baseUrl + '/Avatar/';
    this.getInfo();
    this.getUserGroupMember();
    this.getMedicalLog();
    this.getListDoctorRank();
  }

  get f() {
    return this.formEditInfo.controls;
  }

  getInfo() {
    this.userprofileService.GetInfo().subscribe((res) => {
      this.user = res.d.jsonData;
    });
  }

  getUserGroupMember() {
    this.userprofileService.GetUserGroupMember().subscribe((res) => {
      this.group = res.d.jsonData;
      this.listworklist = res.d.jsonData;
    });
  }

  getMedicalLog() {
    this.userprofileService
      .GetMedicalLog({ page: '1', pageSize: '19' })
      .subscribe((res) => {
        this.log = res.d.jsonData.data;
        this.lstLogs = res.d.jsonData.data;
      });
  }

  getListDoctorRank() {
    this.userprofileService.GetListDoctorRank().subscribe((res) => {
      this.listDoctorRank = res;
    });
  }

  changePassUser(datasend): void {
    this.isVisible_pass = true;
    if (datasend == '') {
      this.enableEditUser = true;
      this.dataForm = new Password();
    } else {
      this.dataForm = {
        currentPassword: datasend.currentPassword,
        newPassword: datasend.newPassword,
        repeatPassword: datasend.repeatPassword,
      };
    }
  }

  checkValidationpassword(datasend) {
    if (
      datasend.newPassword == '' ||
      datasend.repeatPassword == '' ||
      datasend.currentPassword == ''
    ) {
      this.notification.showNotification(
        'error',
        'Chưa nhập đủ giá trị bắt buộc!'
      );
      return false;
    }

    if (datasend.repeatPassword != datasend.newPassword) {
      this.notification.showNotification(
        'error',
        'Mật khẩu nhập lại không khớp '
      );
      return false;
    }
    return true;
  }

  checkValidationinfo(datasend) {
    if (
      datasend.fullName == '' ||
      datasend.dob == '' ||
      datasend.sex == '' ||
      datasend.phone == '' ||
      datasend.email == '' ||
      datasend.address == ''
    ) {
      this.notification.showNotification(
        'error',
        'Chưa nhập đủ giá trị bắt buộc!'
      );
      return false;
    }
    return true;
  }

  savePass(dataForm) {
    const payload = {
      currentPassword: dataForm.currentPassword,
      newPassword: dataForm.newPassword,
      repeatPassword: dataForm.repeatPassword,
    };
    if (!this.checkValidationpassword(dataForm)) {
      return;
    } else {
      this.authService.changepassword(payload).subscribe((res) => {
        if (res.isValid == true) {
          this.notificationService.showNotification(
            'success',
            'Đổi thành công'
          );
          this.isVisible_pass = false;
        } else {
          this.notificationService.showNotification(
            'error',
            'Mật khẩu hiện tại không đúng!'
          );
        }
      });
    }
  }

  handCancel(): void {
    this.isVisible_pass = false;
  }

  editUserInfo() {
    this.isShown = true;
    this.formEditInfo.patchValue({
      fullName: this.user.fullName,
      dob: this.user.dob,
      sex: this.user.sex,
      phone: this.user.phone,
      email: this.user.email,
      address: this.user.address,
      /*doctorRankId: this.user.doctorRankId,*/
      doctorRank: this.user.doctorRank
    });
  }

  saveInfo() {
    const convertAge = new Date(this.user.dob);

    this.submitted = true;
    for (const i in this.formEditInfo.controls) {
      this.formEditInfo.controls[i].markAsDirty();
      this.formEditInfo.controls[i].updateValueAndValidity();
    }

    if (!this.formEditInfo.valid) {
      return;
    } else {
      this.submitted = false;
      this.userprofileService
        .UpdateInfo(this.formEditInfo.value)
        .subscribe((res) => {
          if (res.d.isValid) {
            this.notificationService.showNotification(
              'success',
              'Sửa thành công'
            );
            this.isShown = !this.isShown;
            this.enableEditUser = true;
            this.isVisible = false;
            this.getInfo();
          } else {
            this.notificationService.showNotification(
              'error',
              res.ret[0].errorMessage
            );
          }
        });
    }
  }

  handelCancel() {
    this.isShown = !this.isShown;
  }

  // Phần change avatar

  handlCancel(): void {
    this.isVisible_avatar = false;
  }

  changeAvatar() {
    this.isVisible_avatar = true;
  }

  handleOk() {
    this.url = this.user.avatar;
    this.userprofileService.ChangeAvatar(this.url).subscribe((res) => {
      if (res.d.isValid) {
        this.notification.showNotification('success', 'Đổi thành công');
        this.isVisible_avatar = false;
      } else {
        this.notification.showNotification('error', 'Đổi không thành công');
      }
    });
  }

  uploadFileImage(url) {
    this.user.avatar = url;
    this.handleOk();
  }

  toStringType(t): any {
    return this.userprofileService.toStringType(t);
  }
}
