import { AppState } from 'src/app/components/app-state/app-state';
import { ChatService } from 'src/app/services/chat.service';
import { ShareService } from 'src/app/services/share.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Constant } from 'src/app/share/constants/constant.class';
import { NotificationService } from 'src/app/services/notification.service';
import { PatientService } from 'src/app/services/patient.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Visits } from 'src/app/models/listVisit.class';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-family-member',
  templateUrl: './family-member.component.html',
  styleUrls: ['./family-member.component.scss'],
})
export class FamilyMemberComponent implements OnInit, OnDestroy {
  _visit: Visits;
  formData: FormGroup;
  @Input() set visit(val: Visits) {
    this._visit = val;
    if (val) {
      this.getListMember();
    }
  }

  get visit(): Visits {
    return this._visit;
  }

  isVisibleAdd = false;
  isAdd = true;
  members: any[] = [];
  searchName = '';
  relationship = '';
  curMemberId = '';
  seletedPatient: any = {
    id: '',
  };
  searchvalue: any[] = [];
  disableBtn = false;
  groupSub: Subscription;
  selectedGroupId = '';
  isCollapsed = true;

  constructor(
    private patientService: PatientService,
    private fb: FormBuilder,
    private notifyService: NotificationService,
    private shareService: ShareService,
    private chatService: ChatService,
    private tabDataService: TabDataService,
    private store: Store<AppState>,
    private modalService: NzModalService
  ) {
    this.formData = this.fb.group({
      relationship: ['', [Validators.required]],
    });
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.selectedGroupId = data.groupId;
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    if (this.groupSub) {
      this.groupSub.unsubscribe();
    }
  }
  getListMember() {
    this.members = [];
    this.patientService
      .getPatientFamilyMember(this.visit.patientId)
      .subscribe((res) => {
        this.members = res;
        this.members.sort((a, b) =>
          a.sosRange < b.sosRange ? 1 : b.sosRange < a.sosRange ? -1 : 0
        );
        if (this.members.length > 0) {
          this.isCollapsed = false;
        }
      });
  }

  getNewestVisitMember(patient) {
    this.chatService.getNewestVisit(patient.patientId).subscribe((res) => {
      if (res.isValid) {
        const visitId = res.jsonData;
        this.tabDataService.updateTab(
          visitId,
          patient.fullName,
          'VisitDetail',
          patient.patientId
        );
      }
    });
  }

  onSearchPatient(keyword: string) {
    if (keyword != null && keyword != '') {
      keyword = keyword.trim();
      const payload = {
        textSearch: keyword,
        groupId: this.selectedGroupId,
      };
      this.patientService.searchPatientInGroup(payload).subscribe((res) => {
        this.searchvalue = res ? res : null;
      });
    }
  }

  addMember() {
    this.validateForm();
    if (this.formData.invalid) {
      return;
    }

    if (this.shareService.checkEmptyStr(this.curMemberId)) {
      this.notifyService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn hãy chọn bệnh nhân'
      );
      return;
    }

    if (this.visit.patientId == this.curMemberId) {
      this.notifyService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Không thể thêm bệnh nhân hiện tại'
      );
      return;
    }
    this.disableBtn = true;
    const payload = {
      p1: this.visit.patientId,
      p2: this.curMemberId,
      relationShip: this.formData.value.relationship,
    };
    this.patientService.createPatientFamilyMember(payload).subscribe((res) => {
      if (res.isValid) {
        this.closeAddMember();
        this.getListMember();
        this.notifyService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Thêm thành viên thành công'
        );
        this.disableBtn = false;
      } else {
        this.notifyService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          res.errors[0].errorMessage
        );
        this.disableBtn = false;
      }
    });
  }

  closeAddMember() {
    this.isVisibleAdd = false;
    this.curMemberId = '';
    this.searchName = '';
    this.isAdd = true;
    this.formData.reset();
  }

  confirmDelete(relationId) {
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Bạn có muốn xóa hay không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Bỏ qua',
      nzOnOk: () => this.deleteMember(relationId),
    });
  }

  deleteMember(relationId) {
    this.patientService
      .deletePatientFamilyMember(relationId)
      .subscribe((res) => {
        if (res.isValid) {
          this.getListMember();
        } else {
          this.notifyService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            res.errors[0].errorMessage
          );
        }
      });
  }

  openAddMember() {
    this.isVisibleAdd = true;
    this.isAdd = true;
  }

  selectSearchPatient(patient) {
    if (patient.id == this.visit.patientId) {
      this.notifyService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn không được chọn bệnh nhân hiện tại'
      );
    }
    this.seletedPatient = patient;
    this.curMemberId = patient.id;
  }

  validateForm() {
    for (const i in this.formData.controls) {
      this.formData.controls[i].markAsDirty();
      this.formData.controls[i].updateValueAndValidity();
    }
  }

  editRelationship(item) {
    this.patientService.selectPatient(item.patientId).subscribe((response) => {
      if (response && response.isValid) {
        this.isAdd = false;
        this.isVisibleAdd = true;
        this.seletedPatient = response.jsonData;
        this.curMemberId = item.patientId;
        this.formData.patchValue({
          relationship: item.relationShip,
        });
      }
    });
  }
  collapseBox() {
    this.isCollapsed = !this.isCollapsed;
  }
}
