import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-log-management',
  templateUrl: './log-management.component.html',
  styleUrls: ['./log-management.component.scss'],
})
export class LogManagementComponent implements OnInit {
  public listLogs: any[] = [
    {
      id: '000000000000000000000000',
      creator: 'DanhNd',
      createddate: '2021-02-22 14:00:03',
      type: 'error',
      code: '0x199889',
      event: 'exception: Null Exception',
    },
  ];
  url = 'https://192.168.18.27:9005/';
  urlSafe: SafeResourceUrl;

  public currentLog: any = '000000000';
  public inputValue = '';

  public requestData = {
    search: '',
    pageSize: 10,
    page: 1,
  };
  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  search(): void {}

  selectForm(): void {}

  ontyping(e): void {}

  reset(): void {
    this.inputValue = '';
  }
}
