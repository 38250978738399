import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonInfo } from 'src/app/models/form-model/form.class';
import * as moment from 'moment';

@Component({
  selector: 'app-common-info',
  templateUrl: './common-info.component.html',
  styleUrls: ['./common-info.component.scss'],
})
export class CommonInfoComponent implements OnInit {
  _data: CommonInfo;
  _value: any;

  @Input() set data(val: CommonInfo) {
    this._data = val;
    if (this.value && val && this.data.dataType && this.data.referId) {
      this.updateValue.referId = this.data.referId;
      if (this.data.dataType == 5) {
        this.updateValue.valueString = this.value[this.data.defaultField];
      }
      if (this.data.dataType == 7) {
        this.updateValue.valueDateTime = this.value[this.data.defaultField];
      }
      if (this.data.dataType == 99) {
        var _val = this.value[this.data.defaultField].toString();
        var _date = new Date(_val);
        var thisY = new Date().getFullYear();
        this.updateValue.valueNumber = thisY - _date.getFullYear();
      }
    } else if (this.data && val && this.data.dataType) {
      if (this.data.dataType == 99) {
        var _val = this.value[this.data.defaultField].toString();
        var _date = new Date(_val);
        var thisY = new Date().getFullYear();
        this.updateValue.valueNumber = thisY - _date.getFullYear();
      } else if (this.data.dataType == 8) {
        if (this.data.dataSource != null) {
          let item = this.data.dataSource.filter((it) => it.valueNumber == this.value[this.data.defaultField]);
          this.updateValue.valueString = item[0].text;
        }
      }
    }
  }

  get data() {
    return this._data;
  }

  @Input() set value(val: any) {
    this._value = val;
    if (this.data && val && this.data.dataType && this.data.referId) {
      this.updateValue.referId = this.data.referId;
      if (this.data.dataType == 5) {
        this.updateValue.valueString = this.value[this.data.defaultField];
      }
      if (this.data.dataType == 7) {
        this.updateValue.valueDateTime = this.value[this.data.defaultField];
      }
    }
  }

  @Output() referValueChanged: EventEmitter<any> = new EventEmitter();

  get value() {
    return this._value;
  }

  updateValue: any = {
    referId: '',
    valueString: '',
    valueDateTime: null,
  };
  constructor() {}

  ngOnInit() {}

  ngOnModelChange() {
    this.referValueChanged.emit(this.updateValue);
    console.log(this.updateValue);
  }
}
