<div class="form-input form-elm">
  <div
    click-stop-propagation
    class="text-elm"
    [attr.contenteditable]="true"
    [innerHTML]="html"
    (input)="onContentChange($event.target)"
    [ngClass]="[
      data.formatStyle.isBold == true ? 'bold' : '',
      data.formatStyle.isItalic == true ? 'italic' : '',
      data.formatStyle.isUnderline == true ? 'underline' : '',
      data.formatStyle.align
    ]"
    [ngStyle]="{
      'color': data.formatStyle.color,
      'background': data.formatStyle.bgColor,
      'font-size': data.formatStyle.fontSize + 'px',
      'font-family': data.formatStyle.font
    }"></div>
  <div
    click-stop-propagation
    class="active-elm"
    (click)="textareaClickEvent($event, data)"
    (dblclick)="textareaDbClickEvent($event, data)"
    [ngClass]="
      isFocus && selectedElement.viewId == data.viewId ? 'focus' : ''
    "></div>
</div>
