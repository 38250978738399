<div *ngIf="isLoaded" style="text-align: center;max-width: 100vw;">
  <div style="background-color: rgb(224, 224, 224);padding: 10px;">
    <button
      title="Last Page"
      nz-button
      nzType="default"
      (click)="prevPage()"
      [disabled]="page === 1 || !isLoaded">
      <span nz-icon nzType="caret-left" nzTheme="outline"></span>
    </button>
    <span style="margin: 0px 4px">{{ page }} / {{ totalPage }}</span>
    <button
    title="Next Page"
      class="mg-l-10"
      nz-button
      nzType="default"
      (click)="nextPage()"
      [disabled]="page === totalPage || !isLoaded">
      <span nz-icon nzType="caret-right" nzTheme="outline"></span>
    </button>
    <button
    title="Phóng to"
      class="mg-l-40"
      nz-button
      nzType="default"
      (click)="zoomIn()"
      >
      <span nz-icon nzType="zoom-in" nzTheme="outline"></span>
    </button>
    <!-- <input type="text" [(ngModel)]="zoom" nz-input> -->
    <button
    title="Thu nhỏ"
      class="mg-l-10"
      nz-button
      nzType="default"
      (click)="zoomOut()"
      [disabled]="zoom <= 0.4 || !isLoaded">
      <span nz-icon nzType="zoom-out" nzTheme="outline"></span>
    </button>
    <button
    title="Reset zoom"
      class="mg-l-10"
      nz-button
      nzType="default"
      (click)="resetZoom()"
      >
      <span nz-icon nzType="undo" nzTheme="outline"></span>
    </button>
  </div>
</div>

<pdf-viewer
  [src]="pdfDocumentSrc"
  [rotation]="0"
  [(page)]="page"
  [original-size]="true"
  [show-all]="true"
  [fit-to-page]="false"
  [zoom]="zoom"
  [zoom-scale]="'page-heigh'"

  [render-text]="true"
  [external-link-target]="'blank'"
  [autoresize]="false"
  [show-borders]="false"
  (after-load-complete)="afterLoadComplete($event)"></pdf-viewer>
