import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppConfigService } from 'src/app-config.service';
import { FormService } from 'src/app/services/form.serivce';
import { ListFormService } from 'src/app/services/listForm-service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { FormData } from '../../../../models/form.interface';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'app-formMngt',
  templateUrl: './formMngt.component.html',
  styleUrls: ['./formMngt.component.scss'],
})
export class FormMngtComponent implements OnInit {
  public listForms: FormData[] = [];
  isVisibleImport = false;
  showError = false;
  panels: any[] = [];
  @ViewChild('fileInput') fileInput;

  constructor(
    private notify: NotificationService,
    private dataService: ListFormService,
    private formService: FormService,
    private shareService: ShareService,
    private modalService: NzModalService
  ) {}
  public requestData = {
    search: '',
    groupId: '',
    pageSize: 1000,
    page: 1,
  };
  public totaldata = 25;
  currentForm: any = {};
  public inputValue: string;
  public filterOption: string[] = [];
  public options: string[] = [];
  public isLoading = false;
  callbackFn: any;

  async ngOnInit() {
    this.requestData.groupId = '';
    this.getListDPForms(true);
    this.callbackFn = this.shareService.debounce(this.handleTyping, 1000);
  }

  copy(): void {
    this.formService.copyForm(this.currentForm.id).subscribe((res) => {
      console.log(res);
      const filename = this.currentForm.name + '.json';
      const dataType = res.type;
      const binaryData = [];
      binaryData.push(res);
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('target', '_blank');
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      if (filename) {downloadLink.setAttribute('download', filename);}
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }

  openImportModal() {
    this.isVisibleImport = true;
  }

  cancelImportModal() {
    this.isVisibleImport = false;
    this.fileInput.nativeElement.value = '';
    this.panels = [];
    this.showError = false;
  }

  importForm(type): void {
    if (this.shareService.checkEmpty(this.fileInput.nativeElement.files[0])) {
      this.notify.showNotification('error', 'Vui lòng upload file!');
      return;
    }
    const formData = new FormData();
    formData.append('file', this.fileInput.nativeElement.files[0]);
    this.formService.importForm(type, formData).subscribe((res) => {
      ``;
      // console.log(res)
      if (res.isValid) {
        this.notify.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          Constant.SUCCESS
        );
        this.getListDPForms(true);
        this.cancelImportModal();
      } else {
        this.showError = true;
        this.panels = [];
        const errors = res.jsonData;
        if (errors.commonInfos.length > 0) {
          this.panels.push({
            name: 'Common Info',
            active: true,
            items: errors.commonInfos,
          });
        }
        if (errors.formElms.length > 0) {
          this.panels.push({
            name: 'Observation Type',
            active: true,
            items: errors.formElms,
          });
        }
        if (errors.formRecentElms.length > 0) {
          this.panels.push({
            name: 'Observation Type gần đây',
            active: true,
            items: errors.formRecentElms,
          });
        }
        if (errors.formulaElms.length > 0) {
          this.panels.push({
            name: 'Công thức',
            active: true,
            items: errors.formulaElms,
          });
        }
      }
    });
  }

  deleteItem() {
    if (this.shareService.checkEmpty(this.currentForm.id)) {
      this.notify.showNotification(
        Constant.NOTIFY_TYPE.WARRING,
        'Hãy chọn 1 form!'
      );
    } else {
      if (this.currentForm.isUsed) {
        this.notify.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Form đã được sử dụng!'
        );
      } else {
        if (!this.currentForm.isUsed)
          {this.modalService.confirm({
            nzTitle: 'Confirm',
            nzContent: 'Bạn có muốn xóa hay không?',
            nzOkText: 'Đồng ý',
            nzCancelText: 'Huỷ',
            nzOnOk: () => this.deleteForm(),
          });}
      }
    }
  }

  deleteForm() {
    this.dataService.deleteForm(this.currentForm.id).subscribe((response) => {
      if (response && response.isValid) {
        this.notify.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          Constant.MESSAGE_DELETE_SUCCESS
        );
        this.getListDPForms(true);
      } else {
        this.notify.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          response.errors[0].errorMessage
        );
      }
    });
  }

  getListDPForms(isOninit: boolean): void {
    this.isLoading = true;
    this.dataService.getListDPFrom(this.requestData).subscribe(
      (response) => {
        if (response !== null && response.source != null) {
          this.totaldata = response.total;
          this.listForms = [...response.source];
          if (isOninit === true) {
            this.getOptionsSearch();
          }
          this.isLoading = false;
        }
      },
      (error) => {
        this.notify.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Lỗi data');
      }
    );
  }

  getOptionsSearch() {
    this.options = [];
    this.listForms.forEach((data) => {
      if (!this.options.includes(data.name)) {this.options.push(data.name);}
    });
    this.filterOption = this.options;
    this.isLoading = false;
  }

  handleTyping() {
    const value = this.inputValue;
    this.filterOption = this.options.filter(
      (option) => option.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  emptyId(): boolean {
    return (
      this.shareService.checkEmpty(this.currentForm.id) ||
      this.currentForm.id == this.shareService.EMPTY_ID
    );
  }
  ontyping(value: string): void {
    this.callbackFn();
  }

  search(): void {
    this.requestData.search = this.inputValue;
    this.requestData.page = 1;
    this.getListDPForms(false);
  }

  selectForm(value): void {
    this.currentForm = value;
  }

  reset(): void {
    this.filterOption = this.options;
    this.inputValue = null;
    this.search();
  }
}
