import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/services/auth.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AppState } from '../app-state/app-state';
import { LoginSuccess, ResetLockScreen, SSOLogout } from '../auth/redux/auth.action';
import { HttpUrlEncodingCodec } from '@angular/common/http';
declare let $: any;

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss'],
})
export class SsoLoginComponent implements OnInit {
  token: string;
  returnUrl: string;

  constructor(
    private actionRoute: ActivatedRoute,
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  ngOnInit() {
    const info1 = this.actionRoute.snapshot.queryParams.info1;
    const info2 = this.actionRoute.snapshot.queryParams.info2;
    this.returnUrl = this.actionRoute.snapshot.queryParams.returnUrl;

    this.authService.ssoToken({ info1, info2 }).subscribe((res) => {
      if (res) {
        new Promise((resolve) => {
          if (res) {
            localStorage.setItem(Constant.TOKEN, res.value);
            localStorage.setItem(Constant.USER_INFO, JSON.stringify(res));
            this.store.dispatch(new LoginSuccess(res));
            localStorage.setItem(Constant.LOCKSTATE, '1');
            resolve(true);
          } else {
            resolve(false);
          }
        }).then((data) => {
          console.log(
            'sso-login api ssoToken',
            info1,
            info2,
            this.actionRoute.snapshot.queryParams,
            this.returnUrl,
            decodeURIComponent(this.returnUrl)
          );
          if (data) {
            const redirectUrl = this.returnUrl ? decodeURIComponent(this.returnUrl) : Constant.WELCOME;
            this.router.navigateByUrl(redirectUrl);
          } else {
            this.router.navigate(['/unauthorized']);
          }
        });
      } else {
        const returnURL = window.location.origin + '/sso-login';
        this.store.dispatch(new SSOLogout(returnURL));
      }
    });
  }
}
