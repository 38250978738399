import { Component, OnInit, Input } from '@angular/core';
import { data } from 'jquery';

@Component({
  selector: 'app-formula-view',
  templateUrl: './formula-view.component.html',
  styleUrls: ['./formula-view.component.scss'],
})
export class FormulaViewComponent implements OnInit {
  _data: any = {};
  value = '';
  @Input() set data(val: any) {
    this._data = val;
    if (val) {
      if (typeof val.value === 'number') {
        this.value = val.value.toFixed(1);
      } else {
        this.value = val.value;
      }
    }
  }

  get data() {
    return this._data;
  }

  constructor() {}

  ngOnInit() {
    console.log(this.data);
  }
}
