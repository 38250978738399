<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Tải ảnh lên"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  nzOkText="Thêm"
  nzCancelText="Hủy"
  [nzWidth]="700">
  <div class="text-align-body">
    <nz-upload
      nzAction="{{ mediaUrl }}"
      nzName="FormFile"
      [nzShowUploadList]="false"
      [nzBeforeUpload]="beforeUpload"
      [nzHeaders]="header"
      (nzChange)="handleChange($event)">
      <button nz-button><i nz-icon nzType="upload"></i>Click to Upload</button>
      <!-- <img class="contain" src="assets/image/avt-default.png" style="width: 224px; height:240px;"> -->
    </nz-upload>
    <br />
    <div style="padding-top: 20px">
      <img
        #image
        *ngIf="avatarUrl"
        [src]="avatarUrl"
        class="fit-content"
        [style]="maxWidth + maxHeight" />
      <!-- <img #image *ngIf="avatarUrl" [src]="avatarUrl" class="fit-content"/> -->
    </div>
  </div>
  <!-- <app-image-cropper></app-image-cropper> -->
</nz-modal>
