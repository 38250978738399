import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItemTree } from 'src/app/models/grouptree.model';

@Component({
  selector: 'app-recursive-menu',
  styleUrls: ['./recursive-menu.component.scss'],
  templateUrl: './recursive-menu.component.html',
})
export class RecursiveMenuComponent implements OnInit {
  @Input() menus: Array<MenuItemTree> = [];
  @Input() selectedId: any;
  @Input() isSearch = false;
  // @Input() isExpanAll:boolean;
  @Output() newItemEvent = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {}

  selectGroup(obj) {
    this.newItemEvent.emit(obj);
    //   alert('ok');

    //   // this.selectedGroup.groupName
    //  // alert(groupId);
    //  this.selectedId=groupId;
    //  //this.selectedGroupName=groupName;
    //  //this.tabDataService.groupID=this.selectedGroupId;//set groupId thông qua service
    //   // localStorage.setItem(Constant.CURRENT_GROUPID, this.selectedGroupId);
    //   localStorage.setItem(Constant.CURRENT_GROUPNAME,groupName);
    //   this.IsSelectedId=false;
    //this.isShowDDLGroup =false;
    //this.showTreeGroup()
    //this.resolve!(false);
    //this.observable.subscribe(value => this.isShowDDLGroup = false);
    //this.isShowDDLGroup.next(true);
    // window.location.reload();
    //this.treeCom.getTreeNodeByKey(groupId).isSelected=true;
  }
  test() {
    console.log('ộ');
  }
}
