import { JSONParser } from '@amcharts/amcharts4/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DoctorService } from 'src/app/services/doctor.service';
import { Constant } from '../constants/constant.class';
@Injectable({
  providedIn: 'root',
})
export class TabDataService {
  listVisitComponentName = 'ListVisit';
  public indextab = 0; // select the last tab to display
  private content = new BehaviorSubject<Array<any>>([
    {
      id: this.listVisitComponentName,
      name: 'Danh sách ca khám',
      patientId: '',
      tabType: '',
      reload: false,
    },
  ]);
  public contentVal = this.content;
  public share = this.content.asObservable();
  //private _groupId = '';
  //public sharegroup = new BehaviorSubject("sharegroup");
  private currIdSource = new BehaviorSubject(this.listVisitComponentName);
  public currentTabId = this.currIdSource.asObservable();
  constructor(private doctorService: DoctorService) {}

  updateTab(id, patientName, tabType, patientId): void {
    let checkid = true;
    this.changeTab(id);
    const tabs = this.content.getValue();
    const len = tabs.length;
    for (let i = 0; i < len; i++) {
      if (tabs[i].id === id) {
        this.indextab = i;
        console.log('h',patientName)
        tabs.splice(i, 1, {
          id,
          name: patientName,
          tabType,
          patientId,
          reload: false,
        });
        this.content.next(tabs);

        // this.indextab = Number(i);
        // this.content.next(this.content.getValue().concat([{id : id, name : patientName, tabType : tabType, patientId: patientId, reload: false}]));
        // this.content.getValue().splice(this.content.getValue().length - 1, 1);
        checkid = false;

        return;
      }
      if (
        tabs[i].tabType === 'VisitDetail' &&
        tabType == 'VisitDetail' &&
        tabs[i].patientId == patientId &&
        tabs[i].id !== id
      ) {
        this.replaceTab(tabs[i].id, id);
        checkid = false;
        return;
      }
      else if(
        tabs[i].tabType === 'VisitDetail' &&
        tabType == 'PatientInfoEdit' &&
        tabs[i].patientId == patientId &&
        tabs[i].patientName != patientName &&
        tabs[i].id !== id
      ) {
          
        this.indextab = i;
        console.log('le',patientName)
        tabs.splice(i, 0, {
          id : tabs[i].id,
          name: patientName,
          tabType: tabs[i].tabType,
          patientId,
          reload: false,
        });
        tabs.splice(i+1, 1);  
        this.content.next(tabs);

        // this.indextab = Number(i);
        // this.content.next(this.content.getValue().concat([{id : id, name : patientName, tabType : tabType, patientId: patientId, reload: false}]));
        // this.content.getValue().splice(this.content.getValue().length - 1, 1);
        checkid = false;

       // return;
        // const curTab = this.content.getValue().filter((t) => t.id == tabs[i].id);
        // const index = this.content.getValue().indexOf(curTab);
        // const nextTab = { ...curTab[0], ...{ id: tabs[i].id, name: patientName,
        //   tabType: 'VisitDetail',
        //   patientId,
        //   reload: false, } };

        // this.content.getValue().splice(index, 1, nextTab);
        // this.content.next(this.content.getValue());
      }
    }
    if (checkid) {
      this.indextab = tabs.length;
      this.content.next(
        tabs.concat([
          {
            id,
            name: patientName,
            tabType,
            patientId,
            reload: false,
          },
        ])
      );
    }
    // console.log("", tabs);
  }

  closeTab(data) {
    this.content.getValue().splice(this.content.getValue().indexOf(data), 1);
    if (data.id == this.currIdSource.getValue()) {
      const arr = this.content.getValue();
      const currId = arr[arr.length - 1].id;
      this.changeTab(currId);
    }
  }

  closeTabById(curId) {
    const data = this.content.getValue().find((t) => t.id == curId);
    this.content.getValue().splice(this.content.getValue().indexOf(data), 1);
    if (data.id == this.currIdSource.getValue()) {
      const arr = this.content.getValue();
      const currId = arr[arr.length - 1].id;
      this.changeTab(currId);
    }
  }

  changeGroup() {
    this.currIdSource.next(this.listVisitComponentName); //reload sidebar menu khi thay đổi nhóm
    this.content.getValue().splice(1, this.content.getValue().length); //xoa het tab tru tab danh sach
  }
  tabs() {
    return this.content.getValue();
  }

  getUserProfile() {
    return this.share.subscribe(
      (res) => {
        this.content.getValue();
      },
      (err) => {}
    );
  }

  // set groupID(info:any){
  //   localStorage.setItem(Constant.CURRENT_GROUP_INFO, info);
  //   console.log('setinfo',info);
  //   this.sharegroup.next(info.id);
  //   this.doctorService.saveCurrentGroupId(info.id)
  //   // this._groupId = id;
  // }

  // get groupID(){
  //   console.log('info',localStorage.getItem(Constant.CURRENT_GROUP_INFO));
  //   let info= JSON.parse(localStorage.getItem(Constant.CURRENT_GROUP_INFO));

  //   if(info!=null)
  //     return info.id;
  //   else return '';
  // }

  changeTab(id: string) {
    this.currIdSource.next(id);
  }

  replaceTab(curId: string, nextId: string) {
    const curTab = this.content.getValue().filter((t) => t.id == curId);
    const index = this.content.getValue().indexOf(curTab);
    const nextTab = { ...curTab[0], ...{ id: nextId } };
    this.content.getValue().splice(index, 1, nextTab);
    this.content.next(this.content.getValue());
  }

  reInitTab(type, name) {
    this.content.next([{ id: type, name, patientId: '', tabType: '', reload: false }]);
    this.changeTab(type);
  }
}
