import { SharedModule } from './../../share/share.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportComponent } from './report.component';
import { ReportSummaryComponent } from './report-summary/report-summary.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [ReportComponent, ReportSummaryComponent],
  declarations: [ReportComponent, ReportSummaryComponent],
})
export class ReportModule {}
