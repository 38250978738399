<div class="group-dashboard">
  <h1>
    <span style="color: #555; font-size: 18px">Tên nhóm: </span> <strong style="color: var(--dp-header-text-color-2); font-size: 24px">{{
      groupName }}</strong> <strong *ngIf="!isShowEditGroupName" style="color: var(--dp-header-text-color-2); font-size: 24px"> ({{ aliasName
      }})</strong>
    <input *ngIf="isShowEditGroupName" class="mg-r-15" nz-input placeholder="Nhập alias name" [(ngModel)]="aliasName"
      style="width: 250px; margin-left: 10px; top: -2px;" />
    <i style="color: var(--dp-header-text-color-2); font-size: 17px; margin-left: 5px;" *ngIf="!isShowEditGroupName" (click)="showEditor()"
      nz-icon class="btn-edit" title="Chỉnh sửa" nzType="edit" nzTheme="outline"></i>
    <i style="color: var(--dp-header-text-color-2); font-size: 17px; margin-left: -6px;" *ngIf="isShowEditGroupName" (click)="saveGroupName()"
      nz-icon class="btn-edit" title="Lưu" nzType="save" nzTheme="outline"></i>
    <i style="color: var(--dp-header-text-color-2); font-size: 17px; margin-left: 13px;" *ngIf="isShowEditGroupName"
      (click)="closeGroupName()" nz-icon class="btn-edit" title="Sửa tên hiện thị của nhóm" nzType="close"
      nzTheme="outline"></i>
  </h1>
  <div class="mg-bt-10">
    Id nhóm: <b>{{ groupId }}</b> - Mã nhóm: <b>{{ group?.shortCode }}</b>
  </div>
  <div class="row" *ngIf="cur">
    <div class="col-md-3">
      <div class="box-shadow box-flex">
        <div class="img">
          <img src="assets/image/svg/BacSi.svg" class="avatar-doctor" />
        </div>
        <div>
          <div class="content">Bác sỹ</div>
          <strong>{{ cur.totalDoctor }}</strong>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="box-shadow box-flex">
        <div class="img">
          <img src="assets/image/svg/BenhNhan.svg" class="avatar-doctor" />
        </div>
        <div class="content">
          <div class="content">Bệnh nhân</div>
          <strong>{{ cur.totalPatient }}</strong>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="box-shadow box-flex">
        <div class="img">
          <img src="assets/image/svg/CaKham.svg" class="avatar-doctor" />
        </div>
        <div>
          <div class="content">Ca khám</div>
          <strong>{{ cur.totalVisit }}</strong>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="right" style="float: right">
        <a *ngIf="isAdminGroup" style="margin-left: 10px" (click)="openModelSettingGroup()">
          <i class="icon-dashbord success" style="color: var(--dp-primary-1)" nz-icon size="last" nzType="setting"
            nzIconfont="20" nzTheme="outline"></i></a>
      </div>
      <div class="right" style="float: right">
        <a *ngIf="isAdminGroup" style="margin-left: 10px" (click)="openInviteMember()">
          <i class="icon-dashbord success" style="color: var(--dp-primary-1)" nz-icon size="last" nzType="info-circle"
            nzIconfont="20" nzTheme="outline"></i></a>
      </div>
      <div class="right" style="float: right">
        <a *ngIf="isAdminGroup" style="margin-left: 10px" (click)="exportExcelMember()"
          title="Xuất Excel danh sách thành viên" class="{{ isExport ? 'disabled' : '' }}">
          <i class="icon-dashbord success" style="color: var(--dp-primary-1)" nz-icon size="last" nzType="export"
            nzIconfont="20" nzTheme="outline"></i>
        </a>
      </div>
      <div class="right" style="float: right">
        <a *ngIf="isOwer && !isClosedGroup" style="margin-left: 10px" (click)="confirmCloseGroup()" title="Đóng Group">
          <i class="icon-dashbord danger" style="color: var(--dp-error-color)" nz-icon size="last" nzType="lock"
            nzIconfont="20" nzTheme="outline"></i>
        </a>
      </div>
      <div class="right" style="float: right">
        <a *ngIf="isOwer && isClosedGroup" style="margin-left: 10px" (click)="confirmReopenGroup()" title="Mở nhóm">
          <i class="icon-dashbord success" style="color: var(--dp-primary-1)" nz-icon size="last" nzType="unlock"
            nzIconfont="20" nzTheme="outline"></i>
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="isCovidGroup" class="row">
    <div class="col-md-12 box-filter">
      <strong style="margin-right: 15px">Link đăng ký gửi cho BN:</strong>
      <a>{{ linkCovid }}</a>
      <a style="margin-left: 10px" (click)="copyLinkCovid()" title="Copy link">
        <i nz-icon nzType="link" nzTheme="outline" nzIconfont="24" style="color: var(--dp-primary-1)" nz-icon
          size="last"></i>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 box-filter">
      <strong style="margin-right: 15px">Hiển thị</strong>
      <select class="ft-day" (change)="dayTypeChange($event)" [(ngModel)]="type">
        <option [value]="2">Tuần này</option>
        <option [value]="3">Tháng này</option>
        <option [value]="1">Hôm nay</option>
      </select>
    </div>
  </div>
  <hr style="background: var(--dp-primary-1); margin-bottom: 30px" />
  <div class="row">
    <div class="col-md-12" style="margin-bottom: 30px">
      <strong>CN, ngày {{ now.getDate() }} tháng {{ now.getMonth() + 1 }} năm
        {{ now.getFullYear() }}</strong>
    </div>
  </div>
  <div class="row" *ngIf="last">
    <div class="col-md-3">
      <div class="box-shadow padding-0">
        <div class="box-header">
          <strong>Bác sỹ mới</strong>
        </div>
        <div class="box-content">
          <div class="left">
            <strong>{{ last.totalDoctor }}</strong>
            <sub>BS</sub>
          </div>
          <div class="right">
            <img src="assets/image/svg/BacSiMoi.svg" class="avatar-doctor" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="box-shadow padding-0">
        <div class="box-header">
          <strong>Ca khám mới</strong>
        </div>
        <div class="box-content">
          <div class="left">
            <strong>{{ last.totalVisit }}</strong>
            <sub>CK</sub>
          </div>
          <div class="right">
            <img src="assets/image/svg/CaKhamMoi.svg" class="avatar-doctor" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="box-shadow padding-0">
        <div class="box-header">
          <strong>Bệnh nhân mới</strong>
        </div>
        <div class="box-content">
          <div class="left">
            <strong>{{ last.totalPatient }}</strong>
            <sub>BN</sub>
          </div>
          <div class="right">
            <img src="assets/image/svg/BenhNhanMoi.svg" class="avatar-doctor" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="box-shadow padding-0">
        <div class="box-header">
          <strong>Số log hoạt động</strong>
        </div>
        <div class="box-content">
          <div class="left">
            <strong>{{ last.totalLog }}</strong>
            <sub>Logs</sub>
          </div>
          <div class="right">
            <img src="assets/image/svg/Logs.svg" class="avatar-doctor" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" style="margin-bottom: 30px">
      <div class="log_title">Hoạt động gần đây</div>
    </div>
  </div>
  <div class="box_log">
    <div class="row log_item" *ngFor="let option of logs">
      <div class="col-md-3">{{ option.createTime }}</div>
      <div class="col-md-9">{{ option.displayLog }}</div>
    </div>
  </div>
</div>

<app-add-group (closeModal)="closeAddGroup($event)" [isVisible]="isVisibleAddGroup" (addGroup)="addGroupEvent($event)">
</app-add-group>
<app-config-group (closeModal)="closeSettingGroup($event)" [isVisible]="isVisibleSettingGroup"
  (addGroup)="settingGroupEvent($event)"></app-config-group>
<app-invite-member-group (closeModal)="closeAddMemberGroup($event)" [isCovidGroup]="isCovidGroup" [newGroupId]="groupId"
  [isVisible]="isVisibleAddMemberGroup"></app-invite-member-group>
