import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  IActionMapping,
  ITreeOptions,
  ITreeState,
  TreeComponent,
  TreeModel,
  TreeNode,
  TREE_ACTIONS,
} from '@circlon/angular-tree-component';
import { Observable, Observer } from 'rxjs';
import { NewsService } from 'src/app/services/news.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit, AfterViewInit {
  isAdd = true;
  state: ITreeState;
  currentNode: any = null;
  nodes = [];
  root = {
    id: '000000000000000000000000',
    name: 'root',
    childs: [],
  };
  options: ITreeOptions = {
    childrenField: 'childs',
    levelPadding: 20,
    useVirtualScroll: true,
    animateExpand: true,
    scrollOnActivate: true,
    animateSpeed: 30,
    animateAcceleration: 1.2,
  };

  validateForm!: FormGroup;

  @ViewChild('categoryTree') treeComponent: TreeComponent;
  constructor(
    private newsService: NewsService,
    private notificationService: NotificationService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.validateForm = this.fb.group({
      name: ['', [Validators.required], [this.emptyAsyncValidator]],
      id: [''],
      parentId: ['000000000000000000000000'],
      parentName: [{ disabled: true, value: 'root' }],
    });
    this.getCategoryTree();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.treeComponent.treeModel.expandAll();
    }, 1000);
  }

  addNew() {
    this.isAdd = true;
    this.validateForm.reset();
    const firstRoot = this.treeComponent.treeModel.getFirstRoot();
    firstRoot.setActiveAndVisible();
  }

  cancel() {
    this.addNew();
  }

  checkNull(value) {
    try {
      value = value.trim();
      if (value == null || value == '' || value == 'undefined') {
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  collapseAll() {
    this.state = {
      ...this.state,
      expandedNodeIds: {},
    };
  }

  getCategoryTree() {
    this.newsService.getCategoryTree().subscribe((res) => {
      if (res.isValid) {
        this.root.childs = res.jsonData;
        this.nodes = [];
        this.nodes.push(this.root);
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          res.errors
        );
      }
    });
  }

  hideFolders() {
    const hiddenNodeIds = {};

    this.nodes.forEach((node) => {
      if (node.isFolder) {
        hiddenNodeIds[node.id] = true;
      }
    });

    this.state = {
      ...this.state,
      hiddenNodeIds,
    };
  }

  emptyAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.checkNull(control.value)) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, duplicated: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  selectNode(e) {
    this.currentNode = e.node;
    if (this.currentNode != null) {
      this.validateForm.patchValue({
        parentId: this.currentNode.data.id,
        parentName: this.currentNode.data.name,
      });
    }
  }

  submitForm() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.saveCategory();
    }
  }

  saveCategory() {
    const data = this.validateForm.value;
    this.newsService.saveCategory(data).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          Constant.MESSAGE_SAVE_SUCCESS
        );
        this.addNew();
        this.getCategoryTree();
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }

  updateCategory() {
    if (this.currentNode.id == this.root.id) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'I am Root, I am Inevitable'
      );
    } else {
      this.isAdd = false;
      this.validateForm.patchValue({
        id: this.currentNode.data.id,
        name: this.currentNode.data.name,
        parentId: this.currentNode.parent.data.id,
        parentName: this.currentNode.parent.data.name,
      });
    }
  }
}
