import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';
import { Userprofile } from 'src/app/models/userprofile.class';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService extends BaseService {
  UpdateInfo(payload): Observable<any> {
    return this.post(UrlConstant.UPDATEINFO, payload);
  }
  GetInfo(): Observable<any> {
    return this.get(UrlConstant.USER_PROFILE);
  }
  ChangeAvatar(payload): Observable<any> {
    return this.post(UrlConstant.CHANGEAVATAR + payload, {});
  }
  UpdateUserRoom(roomId): Observable<any> {
    return this.post(UrlConstant.UPDATE_USER_ROOM + roomId, {});
  }
  GetUserGroupMember(): Observable<any> {
    return this.get(UrlConstant.USER_GROUP);
  }
  GetMedicalLog(payload): Observable<any> {
    return this.post(UrlConstant.MEDICAL_LOG, payload);
  }
  GetListDoctorRank(): Observable<any> {
    return this.get(UrlConstant.DOCTOR_RANK);
  }

  toStringType(t) {
    switch (t) {
      case 1:
        return 'Nam';
      case 2:
        return 'Nữ';
      case 3:
        return 'Không xác định';
    }
  }
}
