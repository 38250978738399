import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { Observable } from 'rxjs';
import { UrlConstant } from '../share/constants/url.class';
import { PostObservation, Observation } from '../models/observationType.class';
import { Constant } from '../share/constants/constant.class';
@Injectable()
export class ObservationService extends BaseService {
  getObservationVS(data: PostObservation): Observable<Observation[]> {
    return this.post(UrlConstant.OBSERVATION_VS, data);
  }
  getObservation(data: PostObservation): Observable<Observation> {
    return this.post(UrlConstant.OBSERVATION, data);
  }
  SaveListObservationVS(data: any) {
    return this.post(UrlConstant.SAVE_LIST_OBSERVATION_VS, data);
  }
  saveListObservationAndOrderStatus(data: any) {
    return this.post(UrlConstant.SAVE_LIST_OBSERVATION_VS_STATUS, data);
  }
  SaveListObservation(data: any) {
    return this.post(UrlConstant.SAVE_LIST_OBSERVATION, data);
  }
  saveObservation(data: any) {
    return this.post(UrlConstant.SAVE_OBSERVATION, data);
  }
  convertData(item: Observation, value, dataType) {
    switch (dataType) {
      case Constant.DATATYPENUMBER.NUMBER:
        item.valueInteger = value;
        break;
      case Constant.DATATYPENUMBER.STRING:
        item.valueString = value;
        break;
      case Constant.DATATYPENUMBER.BOOLEAN:
        item.valueBoolean = value;
        break;
      case Constant.DATATYPENUMBER.DATE:
        item.valueDateTime = value;
        break;
      case Constant.DATATYPENUMBER.DATETIME:
        item.valueDateTime = value;
        break;
      case Constant.DATATYPENUMBER.TIME:
        item.valueTime = '';
        break;
      case Constant.DATATYPENUMBER.SINGLECHOICE:
        item.valueSelections = value;
        break;
      case Constant.DATATYPENUMBER.MUILTICHOICE:
        item.valueSelections = value;
        break;
      default:
        break;
    }
    return item;
  }
}
