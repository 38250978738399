import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/components/app-state/app-state';
import { AuthModel } from 'src/app/models/auth.model';
import { CommonInfo } from 'src/app/models/form-model/commonInfo.model';
import { GroupStateModel } from 'src/app/models/group-state.model';
import { CommonInfoService } from 'src/app/services/commonInfo.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ObservationTypeService } from 'src/app/services/obserType-service';
import { RosService } from 'src/app/services/ros-service';
import { RosCategoryService } from 'src/app/services/rosCategory.service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-dpform-info',
  templateUrl: './dpform-info.component.html',
  styleUrls: ['./dpform-info.component.scss'],
})
export class DpformInfoComponent implements OnInit, AfterContentInit {
  _adminPage = true;
  @Input() set adminPage(value: boolean) {
    this._adminPage = value;
    this.getFormScope();
  }

  get adminPage(): boolean {
    return this._adminPage;
  }
  _form: any = {};
  @Input() set form(value: any) {
    this._form = value;
    if (value.id) {
      this.formInfoForm.patchValue({
        id: value.id,
        name: value.name,
        ros: value.ros,
        rosCategory: value.rosCategoryId,
        desc: value.description,
        formScope: value.rangeSharing == 1 ? 'onlyMe' : value.rangeSharing == 3 ? 'system' : 'group',
        groupId: value.groupId,
      });

      this.rosService.getRosCategory(value.ros).subscribe((data) => {
        this.rosCategorys = data;
        this.formInfoForm.patchValue({
          rosCategory: value.rosCategoryId,
        });
      });
    }
  }

  get form(): any {
    return this._form;
  }

  @Input() isChange = false;

  formInfoForm: FormGroup;
  rosCategorys: any[];
  ross: any[];

  formMinScope = 99;
  lstCommonInfo: any = [];
  lstObservationType: any = [];
  lstObservationTypeRecent: any = [];
  lstFilterOT: any = [];
  search_KW_OT = '';
  lstFilterOTRecent: any = [];
  search_KW_OT_Recent = '';
  lstFilterCI: any = [];
  search_KW_CI = '';
  isSpinningSearchOT = true;
  isSpinningSearchRecentOT = true;
  callbackGetlistOTFn: any;
  callbackGetlistOTRecentFn: any;
  groupControl: Observable<GroupStateModel>;
  userRole: Observable<AuthModel>;

  currentGroup: GroupStateModel = {
    isAuthenticated: false,
    groupId: '',
    groupName: '',
    isCovidGroup: false,
    isAutoAddTreatmentPatient: false,
    isLoadByPermission: false,
    role: 0,
  };
  lstFormScope = [
    { value: 'system', name: 'Toàn hệ thống', scope: 3 },
    { value: 'onlyMe', name: 'Chỉ mình tôi', scope: 1 },
    { value: 'group', name: 'Nhóm', scope: 2 },
  ];
  lstFormScopeCurrent = [];
  @Output() addOTItemEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() addOTRecentEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() addCommonInfoEvent: EventEmitter<CommonInfo> = new EventEmitter<CommonInfo>();

  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private rosCategoryService: RosCategoryService,
    private rosService: RosService,
    private OTService: ObservationTypeService,
    private commonInfoService: CommonInfoService,
    private shareService: ShareService,
    private store: Store<AppState>
  ) {
    this.callbackGetlistOTFn = this.shareService.debounce(this.getListObservationType, 500);
    this.callbackGetlistOTRecentFn = this.shareService.debounce(this.getListObservationTypeRecent, 500);
  }

  ngOnInit() {
    this.formInfoForm = this.fb.group({
      id: [null],
      name: ['', [Validators.required]],
      formScope: ['onlyMe', [Validators.required]],
      ros: [null, [Validators.required]],
      rosCategory: [null, [Validators.required]],
      desc: [null],
      groupId: ['000000000000000000000000'],
    });
    this.getListObservationTypeFirstTime();
    this.getListCommonInfo();
    this.getRosCommonInfo();
    this.onChanges();
  }

  getFormScope() {
    this.lstFormScopeCurrent = [];
    if (this.adminPage) {
      this.lstFormScopeCurrent.push(this.lstFormScope[0]);
    } else {
      this.lstFormScopeCurrent = this.lstFormScope;
      this.groupControl = this.store.select((store) => store.groupAuth);
      this.userRole = this.store.select((store) => store.auth);

      this.groupControl.subscribe((value) => {
        this.currentGroup = value;
        if (value.role != Constant.GROUPROLE.Admin && value.role != Constant.GROUPROLE.Owner) {
          this.lstFormScopeCurrent = this.lstFormScopeCurrent.filter((t) => t.scope != 2);
        }
      });
      this.userRole.subscribe((value) => {
        if (!value.userType.includes(Constant.USERROLE.Admin)) {
          this.lstFormScopeCurrent = this.lstFormScopeCurrent.filter((t) => t.scope != 3);
        }
      });
    }

    console.log(this.lstFormScopeCurrent);
  }

  getRosCommonInfo(): void {
    this.rosService.getRos().subscribe((response) => {
      this.ross = response;
    });
    this.rosCategoryService.getRosCategoryAll().subscribe((response) => {
      this.rosCategorys = response;
    });
  }

  chooseRos() {
    const rosId = this.formInfoForm.value.ros;
    if (rosId) {
      this.formInfoForm.patchValue({
        rosCategory: null,
      });
      this.rosService.getRosCategory(rosId).subscribe((data) => {
        this.rosCategorys = data;
      });
    }
  }

  clearSearch() {
    this.search_KW_CI = '';
    this.search_KW_OT = '';
    this.search_KW_OT_Recent = '';

    this.lstFilterCI = this.lstCommonInfo;
    this.getListObservationType();
    this.getListObservationTypeRecent();
  }

  getListObservationType(): void {
    let payload = {
      search: this.search_KW_OT,
      category: -1,
      dataType: 0,
      groupId: '',
    };
    if (this.adminPage) {
    } else {
      payload = {
        search: this.search_KW_OT,
        category: -1,
        dataType: 0,
        groupId: this.currentGroup.groupId,
      };
    }
    this.OTService.getObservationTypeForForm(payload).subscribe((response) => {
      this.lstObservationType = response;
      this.isSpinningSearchOT = false;
    });
  }

  getListObservationTypeFirstTime(): void {
    let payload = {
      search: this.search_KW_OT,
      category: -1,
      dataType: 0,
      groupId: '',
    };
    if (this.adminPage) {
    } else {
      payload = {
        search: this.search_KW_OT,
        category: -1,
        dataType: 0,
        groupId: this.currentGroup.groupId,
      };
    }
    this.OTService.getObservationTypeForForm(payload).subscribe((response) => {
      this.lstObservationType = response;
      this.lstObservationTypeRecent = response;
      this.isSpinningSearchOT = false;
      this.isSpinningSearchRecentOT = false;
    });
  }

  getListObservationTypeRecent(): void {
    let payload = {
      search: this.search_KW_OT_Recent,
      category: -1,
      dataType: 0,
      groupId: '',
    };
    if (this.adminPage) {
    } else {
      payload = {
        search: this.search_KW_OT_Recent,
        category: -1,
        dataType: 0,
        groupId: this.currentGroup.groupId,
      };
    }
    this.OTService.getObservationTypeForForm(payload).subscribe((response) => {
      this.lstObservationTypeRecent = response;
      this.isSpinningSearchRecentOT = false;
    });
  }

  getListCommonInfo(): void {
    this.commonInfoService.getAllCommonInfo().subscribe((response) => {
      this.lstCommonInfo = response;
      this.lstFilterCI = this.lstCommonInfo;
    });
  }

  submitForm(): boolean {
    let error = false;
    for (const i in this.formInfoForm.controls) {
      this.formInfoForm.controls[i].markAsDirty();
      this.formInfoForm.controls[i].updateValueAndValidity();
      if (this.formInfoForm.controls[i].errors != null) {
        error = true;
      }
    }
    return error;
  }

  search_KW_OTChange(): void {
    this.isSpinningSearchOT = true;
    this.callbackGetlistOTFn();
  }

  search_KW_OT_RecentChange(): void {
    this.isSpinningSearchRecentOT = true;
    this.callbackGetlistOTRecentFn();
  }

  search_KW_CIChange(): void {
    const keyword = this.search_KW_CI.toLowerCase();
    if (keyword) {
      this.lstFilterCI = this.lstCommonInfo.filter((item) => item.title.toLowerCase().includes(keyword));
    }
  }
  onChanges(): void {
    this.formInfoForm.valueChanges.subscribe((val) => {
      this.isChange = true;
    });
  }

  add_OT_Item(value) {
    this.addOTItemEvent.emit(value);
  }

  addCommonInfo(commonInfo: CommonInfo) {
    this.addCommonInfoEvent.emit(commonInfo);
  }

  add_OT_Recent(value) {
    this.addOTRecentEvent.emit(value);
  }

  ngAfterContentInit(): void {
    if (this.adminPage) {
      this.formInfoForm.patchValue({
        formScope: 'system',
      });
    }
  }
}
