import { Component, Input, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app-config.service';

@Component({
  selector: 'app-chat-video',
  templateUrl: './chat-video.component.html',
  styleUrls: ['./chat-video.component.scss'],
})
export class ChatVideoComponent implements OnInit {
  configBaseUrl = '';
  _data = {
    src: '',
  };
  @Input() set data(val: any) {
    if (val) {
      const src = this.configBaseUrl + val.src;
      this._data = { ...val, ...{ src } };
    }
  }

  get data() {
    const val = this._data;
    return val;
  }

  constructor(protected configService: AppConfigService) {
    this.configBaseUrl = this.configService.getConfig().media.baseUrlOnly;
  }

  ngOnInit() {}
}
