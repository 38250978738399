<nz-card *ngIf="isVisible" style="width: 100%">
  <div class="btn-group" style="display: flex">
    <div
      title="Align Left"
      class="{{ formatStyle.align == LEFT ? ALIGN_LEFT_ACTIVE : '' }}"
      (click)="selectAlign(LEFT)">
      <svg
        width="40"
        height="40"
        viewBox="0 0 170 170"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <rect x="17" y="14" width="136" height="136" rx="8" fill="white" />
        </g>
        <rect
          x="17"
          y="14"
          width="136"
          height="136"
          rx="8"
          fill="url(#paint0_linear)"
          fill-opacity="0.5"
          style="mix-blend-mode: multiply" />
        <rect
          x="18"
          y="15"
          width="134"
          height="134"
          rx="7"
          stroke="black"
          stroke-opacity="0.37"
          stroke-width="2" />
        <line
          x1="52"
          y1="50"
          x2="117.76"
          y2="50"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="52"
          y1="62"
          x2="102.197"
          y2="62"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="52"
          y1="74"
          x2="117.76"
          y2="74"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="52"
          y1="86"
          x2="102.197"
          y2="86"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="52"
          y1="98"
          x2="102.197"
          y2="98"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="52"
          y1="110"
          x2="117.76"
          y2="110"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="170"
            height="170"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="8.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="85"
            y1="14"
            x2="85"
            y2="150"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="0.333333" stop-color="white" stop-opacity="0.96209" />
            <stop
              offset="0.723958"
              stop-color="#E1E1E1"
              stop-opacity="0.945486" />
            <stop offset="1" stop-color="#9E9E9E" stop-opacity="0.908955" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div
      title="Align Center"
      class="{{ formatStyle.align == CENTER ? ALIGN_CENTER_ACTIVE : '' }}"
      (click)="selectAlign(CENTER)">
      <svg
        width="40"
        height="40"
        viewBox="0 0 170 170"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <rect x="17" y="14" width="136" height="136" rx="8" fill="white" />
        </g>
        <rect
          x="17"
          y="14"
          width="136"
          height="136"
          rx="8"
          fill="url(#paint0_linear)"
          fill-opacity="0.5"
          style="mix-blend-mode: multiply" />
        <rect
          x="18"
          y="15"
          width="134"
          height="134"
          rx="7"
          stroke="black"
          stroke-opacity="0.37"
          stroke-width="2" />
        <line
          x1="52"
          y1="50"
          x2="117.76"
          y2="50"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="62"
          y1="63"
          x2="108"
          y2="63"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="52"
          y1="74"
          x2="117.76"
          y2="74"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="62"
          y1="86"
          x2="108"
          y2="86"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="52"
          y1="98"
          x2="118"
          y2="98"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="62"
          y1="110"
          x2="108"
          y2="110"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="170"
            height="170"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="8.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="85"
            y1="14"
            x2="85"
            y2="150"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="0.333333" stop-color="white" stop-opacity="0.96209" />
            <stop
              offset="0.723958"
              stop-color="#E1E1E1"
              stop-opacity="0.945486" />
            <stop offset="1" stop-color="#9E9E9E" stop-opacity="0.908955" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div
      title="Align Right"
      class="{{ formatStyle.align == RIGHT ? ALIGN_RIGHT_ACTIVE : '' }}"
      (click)="selectAlign(RIGHT)">
      <svg
        width="40"
        height="40"
        viewBox="0 0 170 170"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <rect x="17" y="14" width="136" height="136" rx="8" fill="white" />
        </g>
        <rect
          x="17"
          y="14"
          width="136"
          height="136"
          rx="8"
          fill="url(#paint0_linear)"
          fill-opacity="0.5"
          style="mix-blend-mode: multiply" />
        <rect
          x="18"
          y="15"
          width="134"
          height="134"
          rx="7"
          stroke="black"
          stroke-opacity="0.37"
          stroke-width="2" />
        <line
          x1="52"
          y1="50"
          x2="117.76"
          y2="50"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="68"
          y1="62"
          x2="118.197"
          y2="62"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="52"
          y1="74"
          x2="117.76"
          y2="74"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="68"
          y1="86"
          x2="118.197"
          y2="86"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="68"
          y1="98"
          x2="118.197"
          y2="98"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <line
          x1="52"
          y1="110"
          x2="117.76"
          y2="110"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round" />
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="170"
            height="170"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="8.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="85"
            y1="14"
            x2="85"
            y2="150"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="0.333333" stop-color="white" stop-opacity="0.96209" />
            <stop
              offset="0.723958"
              stop-color="#E1E1E1"
              stop-opacity="0.945486" />
            <stop offset="1" stop-color="#9E9E9E" stop-opacity="0.908955" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
</nz-card>
