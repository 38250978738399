import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class ChatService extends BaseService {
  saveVisitChat(data: any): Observable<any> {
    return this.post(UrlConstant.SAVE_VISIT_CHAT, data);
  }

  savePrivateChat(data: any, receivedUserId: string): Observable<any> {
    return this.post(
      UrlConstant.SAVE_PRIVATE_CHAT + '/' + receivedUserId,
      data
    );
  }

  loadChat(data: any): Observable<any> {
    return this.post(UrlConstant.LOAD_CHAT, data);
  }

  getConversationByVisitId(visitId: string): Observable<any> {
    return this.get(UrlConstant.GET_VISIT_CONVERSATION + '?visitId=' + visitId);
  }

  getPrivateConversationById(convId: string): Observable<any> {
    return this.get(UrlConstant.GET_PRIVATE_CONVERSATION + '?convId=' + convId);
  }

  /*Truongnx add*/

  getConversations(): Observable<any> {
    return this.get('/api/Conversation/GetUserCoversations');
  }
  getConversationById(id: any): Observable<any> {
    return this.get(`/api/Conversation/GetCoversationInfo/${id}`);
  }
  getChats(
    channelId: any,
    skip: number,
    take: number,
    lastMessage: any,
    sortBy: any
  ): Observable<any> {
    const fromDate = new Date();
    const payload = {
      channelId,
      skip,
      take,
      fromDate,
      lastMessage: lastMessage ? lastMessage : '',
      sortBy: sortBy ? sortBy : 'asc',
    };
    return this.post(`/api/Conversation/GetCoversationContent`, payload);
  }
  createPrivateChat(patientId) {
    return this.get(`/api/Conversation/GetConversationPrivate/${patientId}`);
  }
  getNewestVisit(patientId) {
    return this.get(`/api/Visit/GetNewestVisit/${patientId}`);
  }
  leaveGroup(convId) {
    return this.get(`/api/Conversation/LeaveGroup/${convId}`);
  }
  sendChat(payload): Observable<any> {
    return this.post(`/api/Conversation/SendMessage`, payload);
  }
  addMember(channelId: any, userIds: any[]): Observable<any> {
    const payload = {
      channelId,
      userIds,
    };
    return this.post(`/api/Conversation/AddMember`, payload);
  }
  removeMember(channelId: any, userId: any[]): Observable<any> {
    const payload = {
      channelId,
      userId,
    };
    return this.post(`/api/Conversation/RemoveChatMember`, payload);
  }
  promoteAdmin(channelId: any, userId: any[]): Observable<any> {
    const payload = {
      channelId,
      userId,
    };
    return this.post(`/api/Conversation/ChatAdmin`, payload);
  }

  checkHasConversation(visitId) {
    return this.get(`/api/Conversation/GetConversationGroup/${visitId}`);
  }
  getCoversationContentByType(
    channelId: any,
    skip: number,
    take: number,
    lastMessage: any,
    sortBy: any,
    type: any
  ) {
    const fromDate = new Date();
    const payload = {
      channelId,
      skip,
      take,
      fromDate,
      lastMessage: lastMessage ? lastMessage : '',
      sortBy: sortBy ? sortBy : 'asc',
      type,
    };
    return this.post(`/api/Conversation/GetCoversationContentByType`, payload);
  }
  joinGroup(convId) {
    return this.get(`/api/Conversation/JoinConversationGroup/${convId}`);
  }
  getUnReadMessage(): Observable<any> {
    return this.get(`/api/Conversation/UnReadMessage`);
  }
  seenMessage(channelId: any): Observable<any> {
    const payload = {
      channelId,
    };
    return this.post('/api/Conversation/SeenMessage', payload);
  }
}
