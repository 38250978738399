import { ShareService } from 'src/app/services/share.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import firebase from 'firebase';
import { Visits } from '../../../models/listVisit.class';
import { Constant } from '../../../share/constants/constant.class';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AppConfigService } from '../../../../app-config.service';
import { UrlConstant } from 'src/app/share/constants/url.class';
import { UploadService } from 'src/app/services/upload.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ChatService } from 'src/app/services/chat.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { DoctorInGroup } from '../../../models/doctor-in-group.model';
import { stringify } from '@angular/compiler/src/util';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subscription } from 'rxjs';
//import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { AppState } from '../../app-state/app-state';
import { Store } from '@ngrx/store';
export const snapshotToArray = (snapshot: any) => {
  const returnArr = [];

  snapshot.forEach((childSnapshot: any) => {
    const item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });
  return returnArr;
};
declare let $: any;
declare let XRegExp: any;
@Component({
  selector: 'app-chat-tab',
  templateUrl: './chat-tab.component.html',
  styleUrls: ['./chat-tab.component.scss'],
})
export class ChatTabComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() hideButton: string;
  @Input() disabledClass: string;

  @Output() getMessage: EventEmitter<any> = new EventEmitter();
  @ViewChild('chateditor') chateditor: ElementRef;
  userInfo: any;
  chats = [];
  scrolltop: number = null;
  chatForm: FormGroup;
  selectedGroupId: any;
  doctorInGroup: DoctorInGroup[] = [];
  isFocus: boolean;
  isUpload = false;
  labelHint: any;
  @Input() visit: Visits;
  baseImageurl: string =
    this.configService.getConfig().media.baseUrl + '/VisitImage/';
  lstImages: any[] = [];
  isVisibleListImage = false;
  slide: any = { index: 0, id: '', url: '' };
  loaded = false;
  startTime: any = new Date();
  conversation: string;
  UPLOAD_URL: string;
  header?: any;
  groupSub: Subscription;

  curTaggedPeople: any[] = [];

  @ViewChild('chatcontent') chatcontent: ElementRef;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    protected configService: AppConfigService,
    private uploadService: UploadService,
    private notificationService: NotificationService,
    private chatService: ChatService,
    private doctorService: DoctorService,
    private shareService: ShareService,
    // private tabDataService: TabDataService,
    private store: Store<AppState>
  ) {
    this.isFocus = false;
    this.labelHint = 'Nhập nội dung...';
    this.UPLOAD_URL =
      this.configService.getConfig().api.baseUrl +
      UrlConstant.UPLOAD_VISIT_IMAGE +
      '&FileName=11464.jpg';
    this.header = {
      Authorization: 'Bearer ' + localStorage.getItem(Constant.TOKEN),
    };
    this.store
      .select((store) => store.auth)
      .subscribe((res) => {
        this.userInfo = res;
      });
  }

  snapshotToArray = (snapshot: any) => {
    const returnArr = [];

    snapshot.forEach((childSnapshot: any) => {
      const item = childSnapshot.val();
      item.key = childSnapshot.key;
      returnArr.push(item);
    });
    return returnArr;
  };

  convertMessageArray = (items: any[]) => {
    const returnArr = [];
    items = items.reverse();
    items.forEach((item: any) => {
      item.key = item.id;
      item.userId = item.userId;
      item.userName = item.userName;
      item.type = item.type;
      item.src = item.src;
      item.date = this.datepipe.transform(
        new Date(item.date),
        'dd/MM/yyyy HH:mm:ss'
      );
      item.message = item.message;
      returnArr.push(item);
    });
    return returnArr;
  };

  ngOnInit(): void {
    // this.userInfo = JSON.parse(localStorage.getItem(Constant.USER_INFO));
    this.chatForm = this.formBuilder.group({
      message: [null, Validators.required],
    });

    this.chatService
      .getConversationByVisitId(this.visit.id)
      .subscribe((response) => {
        this.conversation = response;
        const data = {
          channelId: this.conversation,
          skip: 0,
          take: 1000,
          fromDate: new Date(),
        };
        this.chatService.loadChat(data).subscribe((response) => {
          this.chats = this.convertMessageArray(response.data);
          this.getMessage.emit(this.chats.length);
          setTimeout(() => this.scrollToElement(), 500);
        });

        firebase
          .database()
          .ref('chats/' + this.conversation)
          .on('child_added', (resp) => {
            const item = resp.val();
            if (new Date(item.timestamp) > this.startTime) {
              this.chats.push(item);
              if (this.chats.length <= 6) {
                this.getMessage.emit(this.chats.length);
              }
              setTimeout(() => this.scrollToElement(), 100);
            }
          });
      });
  }

  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;
        const $this = this;
        const arrNames = this.doctorInGroup.map((a) => a.fullName);
        // $('.inputor').attr('contenteditable', true);
        $('.inputor').atwho({
          at: '@',
          data: arrNames,
          callbacks: {
            beforeInsert(value, $li) {
              $this.curTaggedPeople.push(value);
              return value;
            },
          },
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.selectedGroupId = data.groupId;
        this.getDoctorInGroup(this.selectedGroupId);
      }
    });
    const $this = this;
    function getCookie(cname) {
      try {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
      } catch (err) {
        console.log(
          'getCookie error. cname: ' +
            cname +
            ', document.cookie: ' +
            document.cookie +
            ', error:' +
            err.message
        );
      }
      return '';
    }
    function setCookieSTC(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + d.toUTCString();
      document.cookie =
        cname + '=' + cvalue + ';' + expires + ';domain=.stc.vn;path=/';
    }

    $(document).ready(function () {
      setCookieSTC('abc', 'def', 45);
      $('#txtComment').html($this.labelHint);
      //alert(getCookie("abc"));
    });
    $('.inputor').attr('contenteditable', true);
    // $('.inputor').atwho({
    //   at: '@',
    //   data: $this.doctorInGroup,
    //   displayTpl: '<li><span>${fullName}</span></li>',
    //   insertTpl: '<a href="${id}" data-type="mentionable" data-id="${id}" data-name="${fullName}">${fullName}</a>',
    //   callbacks:{
    //       beforeInsert: function(value, $li) {
    //         console.log('atwho value: ', value);
    //         $this.curTaggedPeople.push(value.id);
    //       }
    //     }
    // });
    // $('.inputor').atwho({
    //   at: '@',
    //   // search: 3,
    //   limit: 20,
    //   searchKey: 'fullName',
    //   // spaceSelectsMatch: true,
    //   acceptSpaceBar: true,
    //   displayTimeout: 400,
    //   insertTpl: '${fullName}',
    //   displayTpl: '<li class="li_result_search"><div> ${fullName}</span> </li>',
    //   callbacks: {
    //     matcher: function (flag, subtext, acceptSpaceBar) {
    //       var match, matched, regexp;
    //       // console.log(XRegExp);
    //       regexp = new XRegExp(
    //         '(\\s+|^)' + flag + '(\\p{Any}+)$|' + flag + '([^\\x00-\\xff]*)$',
    //         'gi'
    //       );
    //       match = regexp.exec(subtext);
    //       //console.log(match, subtext, flag);
    //       // ... get matched result
    //       if (match) {
    //         return match[2] || match[1];
    //       } else {
    //         return null;
    //       }
    //     },
    //     remoteFilter: function (query, callback) {
    //       //console.log('query', query);
    //       if (query.length > 0) {
    //         $.ajax({
    //           url:
    //             $this.configService.getConfig().api.baseUrl +
    //             '/api/Group/GetListDoctor/' +
    //             $this.selectedGroupId,
    //           type: 'POST',
    //           contentType: 'application/json; charset=utf-8',
    //           data: JSON.stringify({ groupId: query }),
    //           // data: JSON.stringify({ groupId: $this.selectedGroupId }),
    //           dataType: 'json',
    //           headers: {
    //             Authorization: 'Bearer ' + localStorage.getItem(Constant.TOKEN),
    //           },
    //           success: function (data) {
    //             const result = data;
    //             callback(result);
    //           },
    //           error: function (XMLHttpRequest, textStatus, errorThrown) {
    //             // ctl.hideModalLoading();
    //             //console.log(XMLHttpRequest, 'url:' + this.url, errorThrown, textStatus);
    //           },
    //         });
    //       }
    //     },
    //   },
    // });
  }
  beforeUploadImage = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        observer.complete();
        return;
      }
      const isLt10M = (file.size ?? 0) / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Kích thước ảnh phải nhỏ hơn 10MB!'
        );
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng && isLt10M);
      observer.complete();
    });

  beforeUploadFile = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isValid =
        file.type === 'application/msword' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/pdf';
      if (!isValid) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'File hỗ trợ: pdf, doc, docx, xls, xlsx'
        );
        observer.complete();
        return;
      }
      const isLt50M = (file.size ?? 0) / 1024 / 1024 < 50;
      if (!isLt50M) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Kích thước ảnh phải nhỏ hơn 50MB!'
        );
        observer.complete();
        return;
      }
      observer.next(isValid && isLt50M);
      observer.complete();
    });

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        break;
      case 'done':
        // Get this url from response in real world.
        // this.getBase64(info.file!.originFileObj!, (img: string) => {
        //   this.loading = false;
        //   this.avatarUrl = img;
        // });
        this.createImageEle(info.file.response);
        break;
      case 'error':
        // this.msg.error('Network error');
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Error'
        );
        break;
    }
  }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        // this.msg.error('You can only upload JPG file!');
        observer.complete();
        return;
      }
      const isLt2M = (file.size ?? 0) / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Image must smaller than 2MB!'
        );
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng && isLt2M);
      observer.complete();
    });

  commentKeyPress(event): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.sendChatOnly();
    }
  }

  commentFocus(f): void {
    this.isFocus = f;
    const $chatContent = $('#txtComment').html();
    if (this.isFocus && $chatContent === this.labelHint) {
      $('#txtComment').focus();
      $('#txtComment').html('');
    } else if ($chatContent === this.labelHint || $chatContent.trim() === '') {
      $('#txtComment').html(this.labelHint);
      $('#txtComment').blur();
    }
  }

  shortName(name): any {
    return this.getInitials(name, ' ');
  }

  getInitials(name, delimeter): string {
    if (name) {
      const array = name.split(delimeter);
      switch (array.length) {
        case 1:
          return array[0].charAt(0).toUpperCase();
          break;
        default:
          return (
            array[0].charAt(0).toUpperCase() +
            array[array.length - 1].charAt(0).toUpperCase()
          );
      }
    }
    return '';
  }

  scrollToElement(): void {
    $(this.chatcontent.nativeElement).animate(
      { scrollTop: this.chatcontent.nativeElement.scrollHeight },
      500
    );
  }

  scrollTop(): void {
    this.scrolltop = this.chatcontent.nativeElement.scrollHeight;
  }
  sendChatOnly(): void {
    const chatContent = $('#txtComment').html();
    this.sendChat(chatContent, 'message', '');
  }

  sendChat(chatContent, type, src): void {
    if (chatContent === '' || chatContent === this.labelHint) {
      alert('Chưa nhập nội dung chat');
      return;
    }

    //send notification to tagged people
    const ids = [];
    if (this.curTaggedPeople.length > 0) {
      this.curTaggedPeople.forEach((item) => {
        const obj = this.doctorInGroup.find(
          (e) => e.fullName === item.substring(1)
        );
        if (obj != null) {
          ids.push(obj.id);
        }
      });
    }

    // console.log(chatContent);
    const chat = {
      userId: this.userInfo.userId,
      userName: this.userInfo.userName,
      date: new Date().toISOString(),
      type,
      src,
      message: chatContent,
      timestamp: new Date().getTime(),
    };
    const chatServer = {
      channelId: this.conversation,
      type,
      message: chatContent,
      src,
      patientName: this.visit.patientName,
      visitId: this.visit.id,
      taggedUserIds: ids.join(','),
    };
    const newMessage = firebase
      .database()
      .ref('chats/' + this.conversation)
      .push();

    newMessage.set(chat);
    this.sendMessageToWebServer(chatServer);
    // var ids = this.getTagIds(chatContent);

    $('#txtComment').html('').focus();
  }

  sendMessageToWebServer(content): void {
    this.chatService.saveVisitChat(content).subscribe((response) => {
      this.curTaggedPeople = [];
    });
  }

  sendImage(content, src): void {
    const chat = {
      userId: this.userInfo.userId,
      userName: this.userInfo.userName,
      date: this.datepipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'),
      type: 'image',
      src,
      message: content,
      timestamp: new Date().getTime(),
    };
    const newMessage = firebase
      .database()
      .ref('chats/' + this.conversation)
      .push();

    newMessage.set(chat);
    const chatServer = {
      channelId: this.conversation,
      type: 'image',
      src,
      message: content,
    };
    this.sendMessageToWebServer(chatServer);
    $('#txtComment').html('').focus();
  }

  getMatches(str, regex, index) {
    index || (index = 1); // default to the first capturing group
    const matches = [];
    let match;
    while ((match = regex.exec(str))) {
      matches.push(match[index]);
    }
    return matches;
  }

  getTagIds(content): any {
    const html = content;
    const myRegEx =
      /<span class="atwho-inserted"(.*?)data-id="(.*?)">(.*?)<\/span>(.*?)/g;
    //// Get an array containing the first capturing group for every match
    const matches = this.getMatches(html, myRegEx, 2);
    const matches2 = this.getMatches(html, myRegEx, 3);

    const ids = [];
    matches.forEach(function (item, index) {
      const id = matches[index];
      const name = matches2[index];
      ids.push(id);
    });
    return ids;
  }

  onPaste(event: ClipboardEvent) {
    const item = event.clipboardData.items[0];

    if (item.type.indexOf('image') === 0) {
      this.isUpload = true;
      const blob = item.getAsFile();
      this.uploadFileDirective(blob);

      event.preventDefault();
    }
  }

  createImageEle(res) {
    const $this = this;
    const img = new Image();
    const node = document.createElement('IMG');
    const src =
      this.configService.getConfig().media.baseUrl +
      '/VisitImage/' +
      res.fileName;

    img.addEventListener('load', function () {
      if (this.naturalWidth > this.naturalHeight) {
        node.classList.add('note-img-hor');
      } else {
        node.classList.add('note-img-ver');
      }
      node.setAttribute('src', src);
      node.setAttribute('is', 'show-image');

      $this.isUpload = false;
      $this.sendImage(node.outerHTML, src);
    });
    img.src = src;
  }

  dropFileToEdit(data): void {
    const $this = this;
    try {
      const node = document.createElement('IMG');

      this.uploadService.saveFile(data).subscribe(
        (resdata) => {
          $this.createImageEle(resdata);
        },
        (error) => {
          this.notificationService.showNotification('', error.statusText);
          this.isUpload = false;
        }
      );
    } catch (error) {
      console.error(error);
      this.isUpload = false;
    }
  }

  uploadFileDirective(data): void {
    const $this = this;

    try {
      const node = document.createElement('IMG');

      this.uploadService.saveFile(data).subscribe(
        (resdata) => {
          const img = new Image();
          const src =
            this.configService.getConfig().media.baseUrl +
            '/VisitImage/' +
            resdata.fileName;
          img.addEventListener('load', function () {
            if (this.naturalWidth > this.naturalHeight) {
              node.classList.add('note-img-hor');
            } else {
              node.classList.add('note-img-ver');
            }

            node.setAttribute('src', src);
            node.setAttribute('is', 'show-image');

            const selection = window.getSelection();
            if (!selection.rangeCount) {
              return false;
            }
            selection.deleteFromDocument();
            selection.getRangeAt(0).insertNode(node);
            $this.isUpload = false;
            $this.sendImage(node.outerHTML, src);
          });
          img.src = src;
        },
        (error) => {
          this.notificationService.showNotification('', error.statusText);
          this.isUpload = false;
        }
      );
    } catch (error) {
      console.error(error);
      this.isUpload = false;
    }
  }

  showSlideShow(e): void {
    this.slide = { id: 1, url: e.src };
    this.isVisibleListImage = true;
  }

  cancelSlideShow(): void {
    this.isVisibleListImage = false;
  }

  nextImage(index: number): void {
    this.currentImage(index + 1);
  }

  preImage(index: number): void {
    this.currentImage(index - 1);
  }

  currentImage(n: number): void {
    let index = this.slide.index;
    if (n > this.lstImages.length - 1) {
      index = 0;
    } else if (n < 0) {
      index = this.lstImages.length - 1;
    } else {
      index = n;
    }
    this.slide = {
      index,
      id: this.lstImages[index].id,
      url: this.lstImages[index].url,
    };
  }

  onFileDropped($event) {
    for (let i = 0; i < $event.length; i++) {
      this.isUpload = true;
      this.dropFileToEdit($event[i]);
    }
  }

  handleUploadFile = (item: any) => {
    const formData = new FormData();
    formData.append('FormFile', item.file as any);
    formData.append('folder', 'chat-' + this.visit.id);

    this.uploadService.uploadMediaFile(formData).subscribe(
      (res) => {
        if (res.uploaded) {
          this.initFile(res.oldFileName, res.fileName);
        }
      },
      (error) => {
        console.log(error);
        // this.notificationService.showNotification(Constant.ERROR, error.message.toString());
      }
    );
  };

  handleUploadImage = (item: any) => {
    const formData = new FormData();
    formData.append('FormFile', item.file as any);
    formData.append('folder', 'chat-' + this.visit.id);

    this.uploadService.uploadMediaFile(formData).subscribe(
      (res) => {
        if (res.uploaded) {
          setTimeout(() => {
            this.initImage(res.oldFileName, res.fileName);
          }, 0);
        }
      },
      (error) => {
        console.log(error);
        // this.notificationService.showNotification(Constant.ERROR, error.message.toString());
      }
    );
  };

  initImage(fileName, filePath) {
    if (fileName) {
      this.sendChat(fileName, 'image', filePath);
    }
  }

  initFile(fileName, filePath) {
    if (fileName) {
      this.sendChat(fileName, 'file', filePath);
    }
  }

  setSize(height) {
    $('app-chat-tab .chat-wrapper').height(height);
  }
  ngOnDestroy(): void {
    this.groupSub.unsubscribe();
  }

  isUrlValid(userInput) {
    const res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) {
      return false;
    } else {
      return true;
    }
  }
}
