import { VisitService } from 'src/app/services/visit.service';
import { ObservationService } from 'src/app/services/observation.service';
import { PatientService } from 'src/app/services/patient.service';
import { ShareService } from 'src/app/services/share.service';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { XetnghiemAddComponent } from '../xetnghiem-add/xetnghiem-add.component';
import { ObservationTypeService } from 'src/app/services/obserType-service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { Constant } from 'src/app/share/constants/constant.class';
import { Visits } from 'src/app/models/listVisit.class';
import { ListVisitService } from 'src/app/services/listVisit-service';
import { NotificationService } from 'src/app/services/notification.service';
import { AppState } from 'src/app/components/app-state/app-state';
import { Observation } from 'src/app/models/observationType.class';
import { XnListComponent } from '../xn-list/xn-list.component';
import { XnResultComponent } from '../xn-result/xn-result.component';

@Component({
  selector: 'app-xetnghiem-tab',
  templateUrl: './xetnghiem-tab.component.html',
  styleUrls: ['./xetnghiem-tab.component.scss'],
})
export class XetnghiemTabComponent implements OnInit, AfterViewInit {
  //@Input() visit: Visits;

  _visit: any;
  @Input() set visit(value: any) {
    this._visit = value;
    console.log(value);
  }
  get visit(): any {
    return this._visit;
  }
  @Input() hideButton: string;
  @Input() disabledClass: string;

  @Input() disabledAction = false;

  _reloadNow: boolean;
  get reloadNow(): boolean {
    return this._reloadNow;
  }
  @Input() set reloadNow(value: boolean) {
    this._reloadNow = value;
    if (value) {
      console.log('reloadNow', value);
      this.getObservationOfPatient();
      this.getSubclinicalVisit();
      setTimeout(() => {
        this.reloadNowChange.emit(false);
      }, 500);
    }
  }

  @Output() reloadNowChange = new EventEmitter<any>();
  @Output() saveXN = new EventEmitter<any>();
  @Output() titleTabRefresh = new EventEmitter();

  @ViewChild(XetnghiemAddComponent) XetnghiemAdd: XetnghiemAddComponent;
  @ViewChild(XnListComponent) xnList: XnListComponent;
  @ViewChild(XnResultComponent) xnResult: XnResultComponent;
  @ViewChild('customTpl', { static: false }) customTpl?: TemplateRef<any>;

  RAW_VAL = 'raw_value_';
  contants = Constant;
  VISIT_STATUS = Constant.VISIT_STATUS;
  groupSub: Subscription;
  newVisitDate: Date;
  newOT: any;

  isVisibleChart: boolean;
  isVisibleAddXN: boolean;
  isVisibleAddVisit: boolean;
  isVisibleAddOT: boolean;
  isVisibleVisitError: boolean;

  addVistitError: string;
  isVisibleOTError: boolean;
  isEditable: boolean;
  OTError: string;
  groupId: string;
  Infinity = Infinity;

  chisoxns: any[];
  chisoxnsView: any[];
  cakhams: any[];
  mockData: any[];
  loading: false;
  searchText: string;
  chisoXNFilter: any[];
  filterIds: string[];
  chisoXNCharts: any[];
  chisoXNOfVS: any[];
  responsive: any;

  subClinicalXNs = [];
  lstObservationType: any;
  subclinicalResultOfVisit = [];

  disabledDateAfter = this.patientService.disabledDateAfterToday;

  constructor(
    private listVisitService: ListVisitService,
    public shareService: ShareService,
    private patientService: PatientService,
    private visitService: VisitService,
    private notificationService: NotificationService,
    private OTService: ObservationTypeService,
    private observationService: ObservationService,
    private store: Store<AppState>,
    private nzConfigService: NzConfigService
  ) {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
      }
    });
  }
  ngOnInit(): void {
    this.isVisibleChart = false;
    this.chisoXNCharts = [];
    this.chisoxns = [];
    this.cakhams = [];
    this.mockData = [
      {
        id: '5f6d9bbab85fb8352c276242',
        observationTypeId: '5f61d622be9d6263c029d770',
        name: 'Height',
        value: 80,
        visitId: '5f9795ac13759dcbca8c689e',
        visitDate: '27/10/2020',
        visitDateTime: '2020-10-27T10:36:00+07:00',
        dataType: 1,
        unit: 'kg',
        minValue: 100,
        maxValue: 200,
        rangeType: -1,
      },
    ];
    this.getObservationOfPatient();
    this.getSubclinicalVisit();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.nzConfigService.set('empty', {
      nzDefaultEmptyContent: this.customTpl,
    });
  }

  // getObservationOfPatient() {
  //   const filterVisit = {
  //     visitId: this.visit.id,
  //     patientId: this.visit.patientId,
  //     observationCategory: 0,
  //   };
  //   this.listVisitService.getObservationOfPatient(filterVisit).subscribe(
  //     (resdata) => {
  //       if (resdata !== null) {
  //         this.mockData = resdata;
  //         this.chisoxns = [];
  //         this.getListData();
  //       }
  //     },
  //     (error) => {
  //       this.notificationService.showNotification('', error.statusText);
  //     }
  //   );
  // }

  getObservationOfPatient() {
    console.log('vao day k');
    const filterVisit = {
      visitId: this.visit.id,
      patientId: this.visit.patientId,
      observationCategory: 0,
    };
    this.listVisitService.getObservationOfPatient(filterVisit).subscribe(
      (resdata) => {
        if (resdata !== null) {
          console.log('getObservationOfPatient', resdata);
          console.log('FIle', resdata.reports);
          this.mockData = resdata.observations;
          this.responsive = resdata.reports
          this.chisoxns = [];
          this.getListData();
        }
      },
      (error) => {
        this.notificationService.showNotification('', error.statusText);
      }
    );
  }

  getListData() {
    for (var i = 0; i < this.mockData.length; i++) {
      const item = this.mockData[i];
      console.log('this.mockData' + i, item);
      const l = this.cakhams.filter((en) => en.visitId === item.visitId).length;
      if (l === 0) {
        this.cakhams.push({
          visitId: item.visitId,
          visitDate: item.visitDate,
          visitDateObj: new Date(item.visitDateTime),
          isNew: false,
        });
      }
    }

    if (this.cakhams.filter((t) => t.visitId == this.visit.id).length == 0) {
      this.cakhams.push({
        visitId: this.visit.id,
        visitDate: this.shareService.toDDMMYY(new Date(this.visit.visitDate)),
        visitDateObj: new Date(this.visit.visitDate),
        isNew: false,
      });
    }

    this.cakhams.sort((a, b) => a.visitDateObj - b.visitDateObj);
    for (var i = 0; i < this.mockData.length; i++) {
      const item = this.mockData[i];
      item.valueText = item.value;
      const l = this.chisoxns.filter((en) => en.observationTypeId === item.observationTypeId).length;
      if (l === 0) {
        const newItem = {
          id: item.observationTypeId,
          observationTypeId: item.observationTypeId,
          dataSource: item.dataSource,
          name: item.name,
          visible: true,
          range: '',
          dataType: 1,
          value: null,
          valueText: item.value,
          validMinValue: item.validMinValue,
          validMaxValue: item.validMaxValue,
        };
        let unit = '';
        if (item.unit !== '' && item.unit !== null) {
          unit = '<i> (' + item.unit + ')</i>';
        }
        newItem.name = newItem.name + unit;
        if (
          !this.shareService.checkEmptyObj(item.validMinValue) ||
          !this.shareService.checkEmptyObj(item.validMaxValue)
        ) {
          let validValue = '';
          if (this.shareService.checkEmptyObj(item.validMinValue)) {
            validValue = '[&leq;' + item.validMaxValue + ']';
          } else if (this.shareService.checkEmptyObj(item.validMaxValue)) {
            validValue = '[&GreaterEqual;' + item.validMinValue + ']';
          } else {
            validValue = '[' + item.validMinValue + ' - ' + item.validMaxValue + ']';
          }
          newItem.name += ' <b>' + validValue + '</b>';
        }
        if (
          item.dataType === Constant.DATATYPENUMBER.NUMBER ||
          item.dataType === Constant.DATATYPENUMBER.POSITIVENEGATIVE ||
          item.dataType === Constant.DATATYPENUMBER.SINGLECHOICE
        ) {
          const ranges = item.lstRange;
          if (ranges != null && ranges.length > 0) {
            let sRange = '';

            ranges.forEach((element) => {
              if (sRange === '') {
                sRange += element.text;
              } else {
                sRange += ';<br/>' + element.text;
              }
            });
            newItem.range = sRange;
          }
        } else {
          newItem.range = item.unit;
        }
        newItem.name = newItem.name + '<br/>' + newItem.range;
        newItem.dataType = item.dataType;

        for (let j = 0; j < this.cakhams.length; j++) {
          const jVisit = this.cakhams[j];
          const val = this.mockData.find(
            (en) => en.visitId === jVisit.visitId && en.observationTypeId === item.observationTypeId
          );
          if (val) {
            if (val.visitId === this.visit.id) {
              newItem.value = val.value;
            }
            newItem[this.RAW_VAL + jVisit.visitId] = val.value;

            if (val.dataType === Constant.DATATYPENUMBER.NUMBER || val.dataType === Constant.DATATYPENUMBER.STRING) {
              newItem['c' + jVisit.visitId] = val.value;
            }

            if (val.dataType === Constant.DATATYPENUMBER.POSITIVENEGATIVE) {
              let valPN = '';
              if (val.value === true) {
                valPN = 'Positive';
              } else if (val.value === false) {
                valPN = 'Negative';
              }
              if (val.rangeType == 0) {
                valPN = '<b>' + valPN + '</b>';
              }
              newItem['c' + jVisit.visitId] = valPN;
              newItem.valueText = valPN;
              item.valueText = valPN;
            }

            if (val.dataType === Constant.DATATYPENUMBER.SINGLECHOICE) {
              if (val.value) {
                const value = item.dataSource.filter((t) => val.value.includes(t.id)).map((t) => t.text);

                let showVal = '';
                for (let k = 0; k < value.length; k++) {
                  showVal += value[k] + '; ';
                }
                let valSlice = showVal.slice(0, showVal.length - 2);
                newItem['c' + jVisit.visitId] = valSlice;
                newItem.valueText = valSlice;
                item.valueText = valSlice;
              }
            }

            newItem['c2' + jVisit.visitId] = val.value;
            if (val.dataType === Constant.DATATYPENUMBER.NUMBER) {
              let rangeTypeText = '';
              switch (val.rangeType) {
                case 1:
                  rangeTypeText = 'right';
                  break;
                case 0:
                  rangeTypeText = 'mid';
                  break;
                case -1:
                  rangeTypeText = 'left';
                  break;
              }
              newItem['r' + jVisit.visitId] = rangeTypeText;
            } else {
              newItem['r' + jVisit.visitId] = '';
            }
          } else {
            newItem['c' + jVisit.visitId] = null;
            newItem['r' + jVisit.visitId] = '';
          }
        }
        this.chisoxns.push(newItem);
      }
    }
    this.chisoXNFilter = this.chisoxns.filter((en) => 1 === 1);
    this.chisoxnsView = JSON.parse(JSON.stringify(this.chisoxns));
    //console.log('chisoXNFilter', this.chisoXNFilter);
  }

  searchResult() {
    if (this.searchText) {
      this.chisoXNFilter = this.chisoxns.filter((en) => en.name.includes(this.searchText));
    } else {
      this.chisoXNFilter = this.chisoxns.filter((en) => 1 === 1);
    }
    this.chisoxnsView = JSON.parse(JSON.stringify(this.chisoxns));
  }

  viewChart() {
    this.filterIds = this.xnResult.getCheckedIdList();
    this.chisoXNCharts = this.chisoxns.filter(
      (en) =>
        this.filterIds.includes(en.observationTypeId) &&
        (en.dataType == Constant.DATATYPENUMBER.NUMBER ||
          en.dataType == Constant.DATATYPENUMBER.BOOLEAN ||
          en.dataType == Constant.DATATYPENUMBER.SINGLECHOICE)
    );
    if (this.filterIds.length === 0 || this.chisoXNCharts.length == 0) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Chưa chọn chỉ số xét nghiệm');
      return;
    }
    this.mockData = [...this.mockData];
    this.isVisibleChart = true;
  }
  closeChart(isRefresh) {
    this.isVisibleChart = false;
  }
  addObserEmitEvent(): void {
    this.isVisibleAddXN = false;
    this.getObservationOfPatient();
    this.saveXN.emit('reload now');
  }
  editXetNghiem(obj) {
    this.isVisibleAddXN = true;
    this.XetnghiemAdd.getListObservationType(obj);
  }
  addXetNghiem() {
    this.isVisibleAddXN = true;
    this.XetnghiemAdd.getListObservationType(null);
  }
  closeAddXetNghiem(isRefresh) {
    this.isVisibleAddXN = false;
  }
  openAddVisitDate() {
    this.isVisibleAddVisit = true;
    this.isVisibleVisitError = true;
    this.addVistitError = '';
    this.newVisitDate = new Date();
  }

  closeAddVisitDate() {
    this.isVisibleAddVisit = false;
  }

  openAddVisitOT() {
    this.isVisibleAddOT = true;
    this.isVisibleOTError = false;
    this.OTError = '';
  }

  closeAddVisitOt() {
    this.isVisibleAddOT = false;
    this.newOT = null;
  }

  addNewVisit() {
    if (this.shareService.checkEmpty(this.newVisitDate)) {
      this.isVisibleAddVisit = true;
      this.addVistitError = 'Bạn chưa chọn ngày khám';
    } else {
      const payload = {
        appointmentI: '',
        dateOfDiagnosis: this.visit.dateOfDiagnosis,
        dateOfSymptom: this.visit.dateOfSymptom,
        doctorId: this.visit.doctorId,
        doctorInCareDoct: this.visit.doctorInCareDoct,
        groupId: this.visit.groupId,
        nursingInCare: this.visit.nursingInCare,
        personId: this.visit.patientId,
        reason: 'Khám bệnh',
        type: 1,
        visitDate: this.newVisitDate,
        visitId: '000000000000000000000000',
      };
      this.visitService.addVisitOnly(payload).subscribe((res) => {
        if (res.isValid) {
          const newVisitDate = new Date(this.newVisitDate);
          this.cakhams.push({
            visitId: res.jsonData.id,
            visitDate: this.shareService.toDDMMYY(newVisitDate),
            visitDateObj: newVisitDate,
            isNew: true,
          });
          this.cakhams.sort((a, b) => a.visitDateObj - b.visitDateObj);
          this.isVisibleAddVisit = false;
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Có lỗi xảy ra!');
        }
      });
    }
  }

  addNewOT() {
    if (this.shareService.checkEmpty(this.newOT)) {
      this.isVisibleOTError = true;
      this.OTError = 'Bạn chưa chọn chỉ số xét nghiệm';
    } else {
      const newItem = {
        id: this.newOT.id,
        observationTypeId: this.newOT.id,
        name: this.newOT.name,
        visible: true,
        range: '',
        dataType: 1,
        value: null,
      };

      this.chisoxns.push(newItem);
      this.chisoXNFilter = this.chisoxns.filter((en) => 1 === 1);
      this.chisoxnsView = JSON.parse(JSON.stringify(this.chisoxns));
      this.closeAddVisitOt();
    }
  }

  onInputOT(keyword: string): void {
    keyword = keyword.trim();
    if (keyword.length > 1) {
      const payload = {
        search: keyword,
        category: 0,
        dataType: 0,
        groupId: this.groupId,
      };
      this.OTService.getObservationTypeByRole(payload).subscribe((response) => {
        this.lstObservationType = response;
        this.lstObservationType = this.lstObservationType.filter(
          (en) =>
            (en.dataType === Constant.DATATYPENUMBER.NUMBER ||
              en.dataType === Constant.DATATYPENUMBER.STRING ||
              en.dataType === Constant.DATATYPENUMBER.POSITIVENEGATIVE) &&
            this.chisoxns.filter((t) => t.id == en.id).length < 1
        );
      });
    }
  }

  toggleQuickEdit() {
    this.isEditable = !this.isEditable;
    if (!this.isEditable) {
      this.getObservationOfPatient();
      this.titleTabRefresh.emit(Constant.TabTitleInsideVisitDetail.Test);
    }
  }

  onOTChange(item, visitId) {
    const visitValue = item[this.RAW_VAL + visitId];
    this.saveObservation(item, visitId, visitValue);
  }

  saveObservation(item, visitId, value) {
    let observation = new Observation();
    this.convertData(observation, value, item.dataType);
    const payload = {
      visitId,
      patientId: this.visit.patientId,
      observationTypeId: item.observationTypeId,
      dataType: item.dataType,
      valueBoolean: false,
      valueSelections: [],

      ...observation,
    };
    this.observationService.saveObservation(payload).subscribe((res) => {
      if (res.isValid) {
        //this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật chỉ số sinh tồn thành công');
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }
  getSubclinicalVisit() {
    console.log('vao day 12');
    if (!this.visit.id) return;
    this.visitService.getSubclinicalAndObservationType(this.visit.id).subscribe((res) => {
      this.subClinicalXNs = res;
      console.log('getSubclinicalVisit', res);
      this.subClinicalXNs.forEach((en) => {
        if (en.status == Constant.SubclinicalVisitHL7Status.CM && en.isMap == true) {
          en.resultType = 1;
        } else {
          en.resultType = 2;
        }
      });
    });
  }
  convertData(item: Observation, value, dataType) {
    let result = this.observationService.convertData(item, value, dataType);
    return result;
  }
  printResult() {
    this.xnList.printResult();
  }
  resultSaved() {
    this.getObservationOfPatient();
    this.getSubclinicalVisit();
    this.titleTabRefresh.emit(Constant.TabTitleInsideVisitDetail.Test);
  }
}
