import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constant } from 'src/app/share/constants/constant.class';
import { ShareService } from 'src/app/services/share.service';
import { PrescriptionService } from 'src/app/services/prescription-service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-modal-create-sample',
  templateUrl: './modal-create-sample.component.html',
  styleUrls: ['./modal-create-sample.component.scss'],
})
export class ModalCreateSampleComponent implements OnInit {
  @Input() isVisible = false;
  @Output() isVisibleChange = new EventEmitter();

  @Input() prescription = null;
  @Input() groupId = '';

  newSampleName = '';
  errorEmptyName = '';
  constructor(
    private shareService: ShareService,
    private prescriptionService: PrescriptionService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {}

  closeCreateSample() {
    this.isVisible = false;
    this.isVisibleChange.emit(false);
  }
  createSample() {
    if (this.shareService.checkEmptyStr(this.newSampleName)) {
      this.errorEmptyName = 'Bạn không được để trống tên!';
      return;
    }
    const medicationRequests = [];
    let listMedicationTable = this.prescription.MedicationRequests;
    for (let i = 0; i < listMedicationTable.length; i++) {
      const item = listMedicationTable[i];
      const newItem = {
        medicationId: item.MedicationId,
        medicationName: item.MedicationName,
        activeIngredient: item.ActiveIngredient,
        amount: item.Amount,
        unitName: item.UnitName,
        text: item.Text,
        content: item.Content,
        isFunctionalFoods: item.IsFunctionalFoods,
      };
      medicationRequests.push(newItem);
    }
    const payload = {
      title: this.newSampleName,
      doctorComment: this.prescription.DoctorComment,
      medicationRequests,
    };
    this.prescriptionService
      .createPrescriptionSample(this.groupId, payload)
      .subscribe((res) => {
        if (res.d.isValid) {
          this.isVisible = false;
          this.notificationService.showNotification(
            Constant.SUCCESS,
            'Tạo đơn thuốc mẫu thành công'
          );
          //this.reloadGetSample = true;
        } else {
          this.notificationService.showNotification(
            Constant.ERROR,
            res.d.errors[0].errorMessage
          );
        }
      });
  }
  checkNameEmpty() {
    if (this.shareService.checkEmptyStr(this.newSampleName)) {
      this.errorEmptyName = 'Bạn không được để trống tên!';
    } else {
      this.errorEmptyName = '';
    }
  }
}
