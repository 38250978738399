import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppState } from 'src/app/components/app-state/app-state';
import { Visits } from 'src/app/models/listVisit.class';
import { DoctorService } from 'src/app/services/doctor.service';
import { MedicalInstructionService } from 'src/app/services/medical-instruction.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-medical-inst-list',
  templateUrl: './medical-inst-list.component.html',
  styleUrls: ['./medical-inst-list.component.scss'],
})
export class MedicalInstListComponent implements OnInit {
  _visit: Visits;
  @Input() set visit(data) {
    this._visit = data;
    if (!this.shareService.checkEmptyObj(data)) {
      // this.getUserInGroup(data.groupId);
      this.getByVisitAndPatient();
      // this.getMedicalOrderSource(data.groupId);
    }
  }
  get visit() {
    return this._visit;
  }

  total = 0;
  lstDataOfVisit: any[] = [];
  lstDataOfPatient: any[] = [];
  lstOrderSource: any[] = [];
  isVisibleForm = false;
  currentOrder = {
    medicalOrderId: '',
    id: '',
    name: '',
    desc: '',
    executorId: '',
    executorName: '',
    executeDate: null,
    otherInfo: '',
    status: 1,
    statusText: '',
    creatorName: '',
    creatorId: '',
    orderResponse: [],
  };
  formUpdateStatus = {
    id: '',
    status: 0,
    executeDate: null,
    otherInfo: '',
  };
  userInGroup: any[] = [];
  lstMedicalOrder: any[] = [];
  showUpdateStatus = false;
  isAddOder = false;
  isVisibleView = false;
  curUserId = '';

  constructor(
    private medicalInstructionService: MedicalInstructionService,
    private doctorService: DoctorService,
    private shareService: ShareService,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    private modalService: NzModalService
  ) {
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
  }

  ngOnInit(): void {}

  showModalForm(type, data) {
    if (type == 'add') {
      this.isAddOder = true;
      this.isVisibleForm = true;
      this.currentOrder = {
        medicalOrderId: '',
        id: '',
        name: '',
        desc: '',
        executorId: '',
        executorName: '',
        executeDate: null,
        otherInfo: '',
        status: 1,
        statusText: '',
        creatorName: '',
        creatorId: '',
        orderResponse: [],
      };
    } else if (type == 'updateStatus' || type == 'edit' || type == 'view') {
      this.isAddOder = false;
      this.currentOrder = {
        medicalOrderId: data.medicalOrderId,
        id: data.id,
        name: data.name,
        desc: data.desc,
        executorId: data.executorId,
        executorName: data.executorName,
        executeDate: data.executeDate,
        otherInfo: data.otherInfo,
        status: data.status,
        statusText: data.statusText,
        creatorName: data.creatorName,
        creatorId: data.creatorId,
        orderResponse: data.orderResponse,
      };
      // console.log("currentOrder", this.currentOrder);
      if (type == 'updateStatus') {
        this.showUpdateStatus = true;
        this.formUpdateStatus = {
          id: data.id,
          status: 0,
          executeDate: null,
          otherInfo: '',
        };
      } else if (type == 'edit') {
        this.isVisibleForm = true;
      } else if (type == 'view') {
        this.isVisibleView = true;
      }
    }
  }

  getByVisitAndPatient() {
    this.medicalInstructionService
      .getByVisitId(this.visit.id)
      .subscribe((res) => {
        this.lstDataOfVisit = res;
        this.getMedicalOrderByPatientId();
      });
  }
  getMedicalOrderByVisitId() {
    this.medicalInstructionService
      .getByVisitId(this.visit.id)
      .subscribe((res) => {
        this.lstDataOfVisit = res;
      });
  }
  getMedicalOrderByPatientId() {
    this.medicalInstructionService
      .getByPatientId(this.visit.patientId)
      .subscribe((res) => {
        this.lstDataOfPatient = res;
        // filter order of current visit
        this.lstDataOfVisit.forEach((item) => {
          this.lstDataOfPatient = this.lstDataOfPatient.filter(
            (en) => en.id !== item.id
          );
        });
      });
  }

  selectOrderChange(event) {
    const item = this.lstOrderSource.filter((en) => en.id == event)[0];
    this.currentOrder.name = item.name;
    this.currentOrder.desc = item.description;
  }

  getPatientAge(dob): string {
    if (dob != null) {
      const age = new Date().getFullYear() - new Date(dob).getFullYear();
      return age.toString() + ' tuổi';
    } else {return '';}
  }

  reloadData() {
    this.getMedicalOrderByVisitId();
  }

  opeUpdateStatus() {
    this.showUpdateStatus = true;
  }
}
