<div style="padding: 10px">
  <h1 class="page-title" style="margin-bottom: 10px">
    Thông tin lâm sàng ({{ visit.visitDate | date: 'dd/MM/yyyy HH:mm' }})
  </h1>
  <div class="survival-top-bar" style="margin-bottom: 10px">
    <div class="btn-right">
      <input
        class="mgr15"
        nz-input
        [(ngModel)]="searchText"
        (ngModelChange)="searchVitalSign()"
        placeholder="Tên thông tin lâm sàng" />
      <!--<button class="btn-primary-small mgr15" (click)="viewChart()" nz-button>Lập biểu đồ</button>-->
      <!--<button class="btn-printer {{hideButton}}">
        <i><img src="assets/image/DPicon/white-print.svg" /></i>
      </button>-->
    </div>
  </div>
  <nz-table
    id="tableSurvival"
    #tableSurvival
    nzPageSize="100"
    [nzData]="vitalSignFilter"
    class="table-survival"
    [nzShowPagination]="false"
    (nzCurrentPageDataChange)="currentPageDataChange($event)"
    nzShowSizeChanger
    [nzPageSizeOptions]="[50, 100]">
    <tbody>
      <tr *ngFor="let item of tableSurvival.data">
        <td nzLeft class="xn-col th-index" [innerHtml]="item.name"></td>
        <td class="action" style="width: 90px">
          <div
            *ngIf="item['c' + visit.id] !== ''"
            class="value"
            [innerHtml]="item['c' + visit.id]"></div>
          <div
            *ngIf="item['s' + visit.id] !== ''"
            class="value-special"
            [innerHtml]="item['s' + visit.id]"></div>
          <div class="range" *ngIf="item['r' + visit.id]">
            <div
              *ngIf="item['r' + visit.id] !== ''"
              class="border {{ item['r' + visit.id] }}"></div>
            <div *ngIf="item['r' + visit.id] === ''"></div>
            <ng-container *ngIf="item['r' + visit.id] !== ''">
              <div class="inner"></div>
            </ng-container>
          </div>
        </td>
        <td
          class="action"
          style="width: 90px"
          style="padding: 16px 0; text-align: right">
          <a (click)="viewChart(item)">
            <i
              title="Xem nhanh"
              style="font-size: 16px"
              nz-icon
              nzType="area-chart"
              nzTheme="outline"></i>
          </a>
          <a (click)="viewDetail(item)">
            <i
              title="Xem nhanh"
              style="font-size: 16px"
              nz-icon
              nzType="eye"
              nzTheme="outline"></i>
          </a>
        </td>
        <!--<ng-container *ngFor="let td of cakhams">
        <td [ngClass]="{ XNSelected: td.visitId===visit.id }" class="xn-col">
          <div *ngIf="item['c' + td.visitId]!==''" class="value" [innerHtml]="item['c' + td.visitId]">
          </div>
          <div *ngIf="item['s' + td.visitId]!==''" class="value-special" [innerHtml]="item['s' + td.visitId]">
          </div>
          <div class="range">
            <div *ngIf="item['r' + td.visitId]!==''" class="border {{item['r' + td.visitId]}}"></div>
            <div *ngIf="item['r' + td.visitId]===''" ></div>
            <ng-container *ngIf="item['r' + td.visitId]!==''">
              <div class="inner"></div>
            </ng-container>
          </div>
        </td>
      </ng-container>-->
      </tr>
    </tbody>
  </nz-table>
</div>
<app-chart
  (closeChart)="closeChart($event)"
  [isVisibleChart]="isVisibleChart"
  [datas]="vitalSignOnChart"
  [rawData]="initData"></app-chart>
<nz-modal
  [nzVisible]="isVisibleDetail"
  [nzTitle]="selectedOb.name"
  (nzOnCancel)="onCancelDetail()">
  <div class="msc-m-modal-content">
    <ul *ngIf="selectedOb">
      <li *ngFor="let td of cakhams" style="display: flex">
        <div style="margin-right: 10px">
          <strong
            >{{ td.visitDate | date: 'dd/MM/yyyy ' }}
            {{ td.visitDate | date: 'HH:mm' }}</strong
          >
        </div>
        <div>
          <div
            *ngIf="selectedOb['c' + td.visitId] !== ''"
            class="value"
            [innerHtml]="selectedOb['c' + td.visitId]"></div>
          <div
            *ngIf="selectedOb['s' + td.visitId] !== ''"
            class="value-special"
            [innerHtml]="selectedOb['s' + td.visitId]"></div>
          <div class="range">
            <div
              *ngIf="selectedOb['r' + td.visitId] !== ''"
              class="border {{ selectedOb['r' + td.visitId] }}"></div>
            <div *ngIf="selectedOb['r' + td.visitId] === ''"></div>
            <ng-container *ngIf="selectedOb['r' + td.visitId] !== ''">
              <div class="inner"></div>
            </ng-container>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="onCancelDetail()">Đóng</button>
  </div>
</nz-modal>
