<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Chọn nhóm chỉ định tạo sẵn"
  nzWidth="700px"
  nzMaskClosable="false"
  (nzOnCancel)="handleCancel()"
  [nzFooter]="null">
  <nz-spin nzTip="Đang cập nhật..." [nzSpinning]="isSaving">
    <div class="d-flex align-items-center mg-bt-10">
      <span class="mg-r-15">Tìm theo tên</span>
      <input
        style="width: 200px"
        nz-input
        class="mg-r-15"
        [(ngModel)]="searchName"
        (keydown.enter)="getByFilter()" />
      <button nz-button (click)="getByFilter()">
        <i nz-icon title="Tìm kiếm" nzType="search" nzTheme="outline"></i>
      </button>
    </div>
    <nz-table
      class="main-table-grid group-sub-table"
      #basicTable
      [nzData]="lstData"
      nzSize="small"
      nzShowPagination="false"
      nzPageSize="100"
      [nzLoading]="isLoading">
      <thead>
        <tr>
          <th>Tên nhóm</th>
          <th>Mô tả</th>
          <th>Phạm vi</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of basicTable.data; let i = index"
          (click)="save(data)">
          <td>{{ data.orderGroupName }}</td>
          <td>{{ data.description }}</td>
          <td>{{ toTextPrivacy(data.privacy) }}</td>
        </tr>
      </tbody>
    </nz-table>
    <i class="fs-12">*Click vào dòng để thêm</i>
  </nz-spin>
</nz-modal>
