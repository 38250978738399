import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Contact } from 'src/app/models/designated-list.class';
import { CodeMapSystem } from '../../../../models/designated-list.class';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NotificationService } from '../../../../services/notification.service';
import { SystemListsService } from '../../../../services/systemLists.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-system-lists',
  templateUrl: './system-lists.component.html',
  styleUrls: ['./system-lists.component.scss'],
})
export class SystemListsComponent implements OnInit {
  [x: string]: any;
  inputValue;
  dataForm: any = {};
  emptyId = '000000000000000000000000';
  selectedRowIndex = this.emptyId;
  isVisible = false;
  listOfDisplayData = [];
  systemDataSource = [];
  systemCodes = {
    sysName: '',
    sysCode: '',
    descriptions: '',
  };
  public filterSysList = new Map();
  filterSysName = [];
  importContacts: Contact[] = [];
  exportContacts: Contact[] = [];
  statusData: string[] = [];
  checkVaid = true;
  listDataExport: {
    'Tên hệ thống': '';
    'Mã hệ thống': '';
    'Mô tả': '';
  }[] = [];
  observationType: any;
  listOfOption: any[] = [];
  codeMap = 'LOINC';
  listCodeMapSystem: CodeMapSystem[] = [];
  pageSize = 20;
  searchKey = '';

  constructor(
    private modalService: NzModalService,
    private notificationService: NotificationService,
    private systemListsService: SystemListsService,
    private ShareService: ShareService
  ) {}

  ngOnInit() {
    this.loadSystemLists();
  }
  // Hiển thị thông tin mã hệ thống
  showModal(dataForm): void {
    this.isVisible = true;
    if (dataForm == 'them') {
      this.selectedRowIndex = this.emptyId;
      this.dataForm = {
        id: '000000000000000000000000',
        sysName: '',
        sysCode: '',
        descriptions: '',
      };
    }
  }
  // Khai báo lệnh hủy
  handleCancel(): void {
    this.isVisible = false;
  }
  // Xóa danh sách bất kỳ: ko thấy yêu cầu xóa

  // Thêm hệ thống mã vào bảng
  addSystemLists(datasend) {
    if (datasend.sysName == '' || datasend.sysCode == '') {
      return this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Chưa nhập đủ dữ liệu yêu cầu!'
      );
    }
    datasend.sysName = datasend.sysName.trim();
    datasend.sysCode = datasend.sysCode.trim();
    // datasend.description = datasend.description.trim();
    this.systemListsService.saveSystemCode(datasend).subscribe((data) => {
      if (data.isValid) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Thành công'
        );
        this.loadSystemLists();
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Trùng mã hệ thống!'
        );
      }
    });
    this.selectedRowIndex = this.emptyId;
  }

  // Tải dữ liệu vào hệ thống
  loadSystemLists() {
    this.systemListsService.getSystemCodeAll().subscribe((data) => {
      if (data.isValid) {
        this.isVisible = false;
        this.systemDataSource = data.jsonData;
        this.listOfDisplayData = data.jsonData;
        console.log('h1', data.jsonData)

      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          data.errors[0].errorMessage
        );
      }
    });
  }
  // Sửa hệ thống mã đã chọn
  editSystemLists(datasend) {
    datasend.sysName = datasend.sysName.trim();
    datasend.sysCode = datasend.sysCode.trim();
    if (datasend.sysName == '' || datasend.sysCode == '') {
      return this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Chưa nhập đủ dữ liệu, không thể thay đổi.'
      );
    }
    if (datasend.turn == false) {
      datasend.status = 0;
    } else {
      datasend.status = 1;
    }

    this.systemCodes = {
      sysName: datasend.sysName,
      sysCode: datasend.sysCode,
      descriptions: datasend.description,
    };
    this.systemListsService
      .updateSystemCode(this.selectedRowIndex, this.dataForm)
      .subscribe((data) => {
        if (data.isValid) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Thay đổi thành công'
          );
          this.isVisible = false;
          this.loadSystemLists();
          this.selectedRowIndex = this.emptyId;
        } else {
          for (const error of data.errors) {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.ERROR,
              'Không thể thay đổi.'
            );
          }
        }
      });
  }

  RowSelected(data) {
    this.curData = data;
    this.selectedRowIndex = data.id;
    this.dataForm = {
      id: this.selectedRowIndex,
      sysName: data.sysName,
      sysCode: data.sysCode,
      description: data.description,
    };
  }

  loadSystemListsGroup(id) {
    this.systemListsService.getSystemCodeById(id).subscribe((thisSysLists) => {
      this.ros_rosCateData = thisSysLists;
    });
  }
  searchName() {
    const keyword = this.searchKey.trim();
    if (keyword != '') {
      this.listOfDisplayData = this.systemDataSource.filter((en) =>
        en.sysName.toLowerCase().includes(keyword.toLowerCase())
      );
    } else {this.listOfDisplayData = this.systemDataSource;}
  }
}
