<div class="form-input form-elm">
  <div
    class="label-elm"
    [innerHTML]="data.value"
    [ngClass]="[
      data.formatStyle.isBold == true ? 'bold' : '',
      data.formatStyle.isItalic == true ? 'italic' : '',
      data.formatStyle.isUnderline == true ? 'underline' : '',
      data.formatStyle.align
    ]"
    [ngStyle]="{
      'color': data.formatStyle.color,
      'background': data.formatStyle.bgColor,
      'font-size': data.formatStyle.fontSize + 'px',
      'font-family': data.formatStyle.font
    }"></div>
</div>
