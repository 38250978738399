import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NzTabsCanDeactivateFn } from 'ng-zorro-antd/tabs';
import { AppConfigService } from 'src/app-config.service';
import { Notifications } from 'src/app/models/item-notifi';
import { UserService } from 'src/app/services/user-service';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from './../../../share/constants/constant.class';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  //@Input() ShowTopNotifi: boolean = false;
  //@Output() eventShowTopNotifi = new EventEmitter<any>();
  @Input() isVisible: boolean;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  listNotification: Notifications[] = [];
  tabs = [-1, 0, 3];
  tabTitle = '';
  currTab: number;
  pageIndex = 1;
  total = 0;
  search: any = {
    fromDate: null,
    toDate: null,
    type: -1,
    skip: 0,
    take: 10,
  };
  PathAvatar: string =
    this.configService.getConfig().media.baseUrl + '/Avatar/';
  constructor(
    private userService: UserService,
    private route: Router,
    private configService: AppConfigService,
    private notificationService: NotificationService
  ) {}
  canDeactivate: NzTabsCanDeactivateFn = (
    fromIndex: number,
    toIndex: number
  ) => {
    this.currTab = this.tabs[toIndex];
    // switch (fromIndex) {
    //   case -1:
    //   case 0:
    //   case 3:
    //     this.currTab=fromIndex;

    //     break;
    //   // case 1:
    //   //   return Promise.resolve(toIndex === 2);
    //   // case 2:
    //   //   return this.confirm();
    //   default:
    //     return true;
    // }
    this.search.type = this.currTab;
    this.pageIndex = 1;
    this.callPager(this.pageIndex);
    return true;
  };
  callPager(pageIndex) {
    const skip = (pageIndex - 1) * 10;

    this.search.skip = skip;
    this.searchListNoti();
  }
  ngOnInit(): void {
    // if(this.ShowTopNotifi)
    //   this.bellNotifiClick();
  }
  handleCancel() {
    this.isVisible = false;
    this.closeModal.emit(false);
  }
  getTabTitle(_tab) {
    // this.currTab=_tab;
    switch (_tab) {
      case -1:
        return 'Tất cả';
      case 0:
        return 'Ca khám';
      case 3:
        return 'Nhóm';
    }
  }
  searchListNoti() {
    //getTopNotifi
    this.userService.getAllNotifiByType(this.search).subscribe((res) => {
      if (res !== null) {
        this.listNotification = res.data;
        this.total = res.total;
      } else {this.listNotification = [];}
    });
  }
  acceptJoinGroup(notiId, isOK, temp) {
    let codeInviteId = '';
    const payload = {
      notiId,
      isOK,
    };
    this.userService.confirmInviteGroup(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            codeInviteId = res.jsonData.invitaionId;
            if (temp !== null) {temp.close();}
            if (isOK) {
              this.route.navigateByUrl(
                '/confirm-invite?Code=' + codeInviteId + '&Type=1'
              ); //http://localhost:4200/confirm-invite?Code=60b8c0ecb029ff1ef5d86b44&Type=1
            } else {
              this.route.navigateByUrl(
                '/confirm-invite?Code=' + codeInviteId + '&Type=-1'
              ); //http://localhost:4200/confirm-invite?Code=60b8c0ecb029ff1ef5d86b44&Type=1
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  readNotifi(notiId) {
    this.userService.viewNotifi(notiId).subscribe((res) => {
      if (res !== null) {
        if (res.isValid) {
          const viewItem = this.listNotification.filter(
            (en) => en.id === notiId
          )[0];
          viewItem.viewed = true;
        }
      }
    });
  }
  markAllAsRead() {
    this.userService.readAllNotifi().subscribe((res) => {
      if (res != null && res.isValid == true) {
        this.notificationService.showNotification(
          Constant.SUCCESS,
          'Cập nhật thành công!'
        );
        this.searchListNoti();
      } else {
        this.notificationService.showNotification(
          Constant.ERROR,
          'Cập nhật không thành công! Xin liên hệ quản trị hệ thống.'
        );
      }
    });
  }
}
