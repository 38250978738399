import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GroupStateModel } from 'src/app/models/group-state.model';
import { Constant } from '../../../share/constants/constant.class';
import { AppState } from '../../app-state/app-state';
import { GroupAuthActions, GroupAuthActionTypes } from './group-auth.action';

export const authAdapter: EntityAdapter<GroupStateModel> =
  createEntityAdapter<GroupStateModel>();
export const defaultAuth: GroupStateModel = {
  isAuthenticated: false,
  groupId: '',
  groupName: '',
  role: 0,
  doctorPermission: [],
};

const newState = (state, ...newData) => Object.assign({}, state, newData);

export function groupAuthReducer(
  state = defaultAuth,
  action: GroupAuthActions
): GroupStateModel {
  // console.log("groupAuthReducer, action: " + action.type);
  switch (action.type) {
    // case GroupAuthActionTypes.JoinSuccess: {
    //   return {
    //         ...state,
    //         isAuthenticated: true,
    //         ...action.payload,
    //     };
    // }
    // case GroupAuthActionTypes.JoinError: {
    //   return {
    //     ...state,
    //     isAuthenticated: false,
    //   };
    // }
    // case GroupAuthActionTypes.Join: {
    //   return {
    //     ...state,
    //     ...{ groupId: action.payload },
    //   };
    // }
    // case GroupAuthActionTypes.Leave: {
    //   return {
    //     ...state,
    //     isAuthenticated: false,
    //     groupId: '',
    //     groupName: '',
    //     role: 0,
    //   };
    // }

    //le.huathi - optimize FE
    case GroupAuthActionTypes.Join: {
      return {
        ...state,
        isAuthenticated: true,
        ...action.payload,
      };
    }

    default: {
      // console.log('state:', state);
      return state;
    }
  }
}

export const selectGroupAuthFeature = (state: AppState) => state.groupAuth;
export const getGroupAuth = createSelector(
  selectGroupAuthFeature,
  (groupAuth: GroupStateModel) => groupAuth
);
