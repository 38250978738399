import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { identity, Observable } from 'rxjs';
import { AppConfigService } from 'src/app-config.service';
import { BaseService } from '../share/base-service/base-service.service';
import { Constant } from '../share/constants/constant.class';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class GroupMemberService extends BaseService {
  getInvitation(id): Observable<any> {
    return this.get(UrlConstant.INVITE_GET + '/' + id);
  }
  getMyGroupMember(groupId): Observable<any> {
    return this.get(UrlConstant.GET_MY_GROUP_MEMBER + '?groupId=' + groupId);
  }
  getListMember(payload: any): Observable<any> {
    return this.post(UrlConstant.GET_GROUP_MEMBER, payload);
  }
  getGroupMemberCount(groupId): Observable<any> {
    return this.get(UrlConstant.GET_GROUP_MEMBER_COUNT + '/' + groupId);
  }

  inviteUser(payload): Observable<any> {
    return this.post(UrlConstant.INVITE_USER, payload);
    // let token = 'Bearer ' + localStorage.getItem(Constant.TOKEN);
    // const headers = {
    //   Authorization: 'Bearer ' + localStorage.getItem(Constant.TOKEN),
    // };
    // return this.httpClient.post(
    //   this.configService.getConfig().api.baseUrl + UrlConstant.INVITE_USER,
    //   token,
    //   { headers: headers, params: payload }
    // );
  }
  inviteMultiUser(payload): Observable<any> {
    return this.post(UrlConstant.INVITE_MULTI_USER, payload);
  }
  acceptJoinGroup(payload): Observable<any> {
    return this.post(UrlConstant.JOIN_GROUP, payload);
  }
  rejectJoinGroup(payload): Observable<any> {
    return this.post(UrlConstant.REJECT_JOIN_GROUP, payload);
  }

  RemoveMember(payload): Observable<any> {
    return this.post(UrlConstant.REMOVE_MEMBER_GROUP, payload);
  }
  ChangeOwnerGroup(payload): Observable<any> {
    return this.post(UrlConstant.CHANGE_OWNER_GROUP, payload);
  }
  RemoveGroupAdmin(payload): Observable<any> {
    return this.post(UrlConstant.REMOVE_ADMIN_GROUP, payload);
  }
  SetGroupAdmin(payload): Observable<any> {
    return this.post(UrlConstant.SET_ADMIN_GROUP, payload);
  }

  getAllGroup() {
    //system admin only
    return this.post(UrlConstant.LIST_TREE_MENU + '/' + false, null);
  }

  getControllableGroup(): Observable<any> {
    return this.get(UrlConstant.GET_CONTROLLABLE_GROUP);
  }

  checkGroupAuthen(groupId: string): Observable<any> {
    return this.get(UrlConstant.CHECK_GROUP_AUTHEN + groupId);
  }

  setCurrentGroup(groupId: string): Observable<any> {
    return this.get(UrlConstant.SET_CUR_GROUP + '/' + groupId);
  }

  setDoctorPermission(payload): Observable<any> {
    return this.post(UrlConstant.DOCTOR_PERMISSION, payload);
  }

  exportMember(groupId): Observable<any> {
    return this.httpClient.get(
      this.configService.getConfig().api.baseUrl +
        UrlConstant.EXPORT_LIST_DOCTOR +
        groupId,
      {
        headers: this.createHeaders() || {},
        responseType: 'blob' as 'json',
      }
    );
  }
  addUser(payload): Observable<any> {
    return this.post(UrlConstant.ADD_USER_TO_GROUP_MEMBER, payload);
  }
}
