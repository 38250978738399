import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonInfo } from '../models/form-model/form.class';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class CommonInfoService extends BaseService {
  getAllCommonInfo(): Observable<CommonInfo[]> {
    return this.get(UrlConstant.COMMON_INFO);
  }
}
