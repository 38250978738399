import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { fromEvent } from 'rxjs';
import { AppConfigService } from 'src/app-config.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { VisitService } from 'src/app/services/visit.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-slider-image',
  templateUrl: './slider-image.component.html',
  styleUrls: ['./slider-image.component.scss'],
})
export class SliderImageComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() visitId = '';
  @Input() lstImages = [];
  @Input() imageFrom = 0;
  @Input() userIdOnline = '';
  @Input() useFullImageUrl = false;
  @Input() mHeader = true;

  private _curIndex = 0;
  @Input()
  get curIndex() {
    return this._curIndex;
  }
  set curIndex(val) {
    this.onIndexChange(val);
  }

  private _id = '';
  @Input()
  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
    this.setImageSize();
    this.setDefaultValue();
    this.setTransform();
  }

  private _isResetSize = 0;
  @Input()
  get isResetSize() {
    return this._isResetSize;
  }
  set isResetSize(value) {
    this._isResetSize = value;
    if (value) this.setImageSize();
  }

  @Output() listImageChange = new EventEmitter();
  @Output() curIndexChange = new EventEmitter();

  @ViewChild('toTarget') toTarget: ElementRef;
  @ViewChild('parent') boundElm: ElementRef;

  slide = {
    index: 0,
    id: '',
    creatorId: '',
    imageURL: 'assets/image/logo.png',
    comment: '',
    isKeyImage: false,
    privacyPolicy: 4,
  };
  isZoomOut = false;
  isShowArrowIcon = false;
  rotateDeg = 0;

  scale = 1;
  panning = false;
  pointX = 0;
  pointY = 0;
  start = { x: 0, y: 0 };
  clientX = 0;
  clientY = 0;
  bound: any;
  isSliderImagePopup = false;

  ImageFrom = Constant.ImageForm;
  GeneralBoxPrivacy = Constant.GeneralBoxPrivacy;
  baseImageurl = this.configService.getConfig().media.baseUrl + '/VisitImage/';

  constructor(
    private modalService: NzModalService,
    private visitService: VisitService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private configService: AppConfigService,
    private shareService: ShareService
  ) {
    //this.visitId = this.route.snapshot.paramMap.get('visitId');
  }

  ngOnInit(): void {
    const param = this.route.snapshot.paramMap.get('visitId');
    if (!this.shareService.checkEmpty(param)) {
      this.getVisitImage(param);
    }
    let pathname = window.location.pathname;
    if(pathname.includes('silder-image')){
      this.isSliderImagePopup = true;
    }

  }

  getVisitImage(visitId) {
    this.visitService.getVisitImage(visitId).subscribe((res) => {
      if (res.isValid) {
        this.useFullImageUrl = true;
        this.lstImages = res.jsonData;
        // let ind = this.route.snapshot.paramMap.get('index');
        // this.curIndex = (!this.shareService.checkEmpty(ind)) ? parseInt(ind) : 0;
        // console.log("this.curIndex", this.curIndex);
        let image = this.lstImages[0];
        this.slide = {
          index: 0,
          id: image.id,
          creatorId: image.creatorId,
          imageURL: image.imageURL,
          comment: image.comment,
          isKeyImage: image.isKeyImage,
          privacyPolicy: image.privacyPolicy,
        };
      }
    });
  }
  onIndexChange(index) {
    if (this.lstImages != null && this.lstImages.length > 0 && this.lstImages != undefined) {
      let image = this.lstImages[index];
      this.slide = {
        index: index,
        id: image.id,
        creatorId: image.creatorId,
        imageURL: image.imageURL,
        comment: image.comment,
        isKeyImage: image.isKeyImage,
        privacyPolicy: image.privacyPolicy,
      };
      if (this.imageFrom == this.ImageFrom.BoxGeneral) {
        this.isShowArrowIcon = this.lstImages.length * 90 > $('.visit-image-action').width() - 60 ? true : false;
      }
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.isVisibleFullScreen != undefined && change.isVisibleFullScreen.currentValue == true) {
      let image = this.lstImages[0];
      this.slide = {
        index: 0,
        id: image.id,
        creatorId: image.creatorId,
        imageURL: image.imageURL,
        comment: image.comment,
        isKeyImage: image.isKeyImage,
        privacyPolicy: image.privacyPolicy,
      };
    }
    this.setDefaultValue();
  }

  ngAfterViewInit(): void {
    this.setImageSize();

    if (this.mHeader) {
      fromEvent(this.toTarget.nativeElement, 'mousewheel').subscribe((e: WheelEvent) => {
        e.preventDefault();
        if ((this.scale > 10 && e.deltaY < 0) || (this.scale == 1 && e.deltaY > 0)) {
          return;
        }
        this.setBoundElm();
        this.clientX = e.clientX - this.bound.x;
        this.clientY = e.clientY - this.bound.y;

        let delta = e.deltaY ? -e.deltaY : e.deltaY;
        this.setCoordinate(delta);

        if (this.scale <= 1) {
          this.scale = 1;
          this.pointX = 0;
          this.pointY = 0;
        }
        this.isZoomOut = this.scale > 1 ? true : false;
        this.setTransform();
      });

      fromEvent(this.toTarget.nativeElement, 'mousemove').subscribe((e: any) => {
        e.preventDefault();
        if (!this.panning) {
          return;
        }
        if (this.scale > 5) {
          return;
        }
        this.setBoundElm();
        this.clientX = e.clientX - this.bound.x;
        this.clientY = e.clientY - this.bound.y;

        this.pointX = this.clientX - this.start.x;
        this.pointY = this.clientY - this.start.y;
        this.setTransform();
      });

      fromEvent(this.toTarget.nativeElement, 'mousedown').subscribe((e: any) => {
        e.preventDefault();
        this.setBoundElm();
        this.clientX = e.clientX - this.bound.x;
        this.clientY = e.clientY - this.bound.y;

        this.start = {
          x: this.clientX - this.pointX,
          y: this.clientY - this.pointY,
        };
        this.panning = true;
        this.setTransform();
      });

      fromEvent(this.toTarget.nativeElement, 'mouseup').subscribe((e: any) => {
        this.panning = false;
      });
    }
  }
  rotateImage(visitId: string, imageId: string, i: number) {
    const payload = {
      visitId,
      imageId,
      direction: 'right',
    };
    // if (visitId != "0") {//TH xoay cả ở fe & be
    //   this.visitService.rotateImage(payload).subscribe(data => {
    //     this.rotate(imageId, i);
    //   });
    // }
    // else {//only rotate fe
    //   this.rotate(imageId, i);
    // }
    this.rotate(imageId, i);
  }

  rotate(imageId, dir) {
    let image = $('.i' + imageId);
    this.rotateDeg = +image.attr('rtvalue'); //get attr as int
    this.rotateDeg += dir == 1 ? 90 : -90;

    image.attr('rtvalue', this.rotateDeg); //luu value vao attribute
    image.css('transform', 'rotate(' + this.rotateDeg + 'deg)'); //xoay ảnh lớn

    //reset width
    let window = $('.window-' + this.id);
    const windowHeight = window.height();
    const windowWidth = window.width();

    const imageWidth = image.width();
    const imageHeight = image.height();
    const rtvalue = +image.attr('rtvalue');

    //nếu quay ngang và imageWidth > imageHeight thì thu nhỏ ảnh
    let deg = (rtvalue / 90) % 2;

    if (imageWidth > imageHeight && deg != 0) {
      image.css('max-width', windowHeight);
      image.css('max-height', '');
    } else if (imageWidth > imageHeight && deg == 0) {
      image.css('max-height', windowHeight);
      image.css('max-width', windowWidth);
    }
  }

  zoomImage(number) {
    if (number == 1) {
      this.isZoomOut = true;
      this.setCoordinate(number);
    } else {
      if (this.scale - 1 < 0) {
        this.isZoomOut = false;
        return;
      } else if (this.scale - 1 < 1) {
        this.scale = 1;
        this.pointX = 0;
        this.pointY = 0;
      } else {
        this.setCoordinate(number);
      }
    }
    if (this.scale == 1) {
      this.isZoomOut = false;
    }
    this.setTransform();
  }

  deleteImage(visitId: string, imageId: string, index?: number): void {
    const payload = { visitId, imageId };
    this.modalService.confirm({
      nzTitle: 'Thông báo',
      nzContent: 'Bạn có chắc muốn xóa hình ảnh này?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () =>
        this.visitService.deleteVisitImage(payload).subscribe((res) => {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xóa ảnh thành công');
          this.lstImages = this.lstImages.filter((en) => en.id !== imageId);
          this.currentImage(index);
          this.listImageChange.emit(this.lstImages);
        }),
    });
  }

  nextImage(index: number): void {
    this.currentImage(index + 1);
  }

  preImage(index: number): void {
    this.currentImage(index - 1);
  }

  gotoHead(x) {
    const curPos = $('.carousel ul').scrollLeft();
    const width = x == 1 ? -window.innerWidth : window.innerWidth;
    $('.carousel ul').animate({ scrollLeft: curPos + width });
  }

  currentImage(currIndex: number): void {
    const curPos = $('.carousel ul').scrollLeft();
    const rotate = currIndex > this.slide.index ? 90 : -90;
    $('.carousel ul').animate({ scrollLeft: curPos + rotate });

    let index = this.slide.index;
    if (currIndex > this.lstImages.length - 1) {
      index = 0;
    } else if (currIndex < 0) {
      index = this.lstImages.length - 1;
    } else {
      index = currIndex;
    }

    this.curIndexChange.emit(index);
    let image = this.lstImages[index];
    this.slide = {
      index: index,
      id: image.id,
      comment: image.comment,
      imageURL: image.imageURL,
      creatorId: image.creatorId,
      isKeyImage: image.isKeyImage,
      privacyPolicy: image.privacyPolicy,
    };
    this.setDefaultValue();
  }

  saveKeyImage($event) {
    const payload = {
      visitId: this.visitId,
      comment: this.slide.comment,
      policy: this.slide.privacyPolicy,
      fileNames: this.slide.imageURL,
      imageId: this.slide.id,
      isKeyImage: this.slide.isKeyImage,
    };
    this.visitService.editVisitImages(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
        for (let i = 0; i < this.lstImages.length; i++) {
          const image = this.lstImages[i];
          if (this.slide.id == image.id) {
            this.lstImages[i].isKeyImage = this.slide.isKeyImage;
            break;
          }
        }
        this.listImageChange.emit(this.lstImages);
      }
    });
  }
  /**************
   * helper
   ***************/
  setDefaultValue() {
    this.start = { x: 0, y: 0 };
    this.scale = 1;
    this.pointX = 0;
    this.pointY = 0;
    this.panning = false;
    this.rotateDeg = 0;
    this.clientX = 0;
    this.clientY = 0;
    this.setTransform();
  }
  setTransform() {
    $('#mouse-wheel').css(
      'transform',
      'translate(' + this.pointX + 'px, ' + this.pointY + 'px) scale(' + this.scale + ')'
    );
    $('.toTarget-' + this.id).css('transform', 'rotate(0deg)');
  }
  setCoordinate(delta) {
    const xs = (this.clientX - this.pointX) / this.scale;
    const ys = (this.clientY - this.pointY) / this.scale;
    if (delta != 0) {
      this.scale = delta > 0 ? this.scale * 1.2 : this.scale / 1.2;
    }
    this.pointX = this.clientX - xs * this.scale;
    this.pointY = this.clientY - ys * this.scale;
  }
  setBoundElm() {
    if (this.bound) return;
    this.bound = this.boundElm.nativeElement.getBoundingClientRect();
  }
  setImageSize() {
    if (this.id == '') return;

    setTimeout(() => {
      let slide = $('.window-' + this.id);

      const windowHeight = slide.height();
      const windowWidth = slide.width();
      let target = $('.toTarget-' + this.id);
      target.css('max-width', windowWidth ?? '');
      target.css('max-height', windowHeight ?? '');
    }, 0);
  }
  convertImageUrl(imageURL) {
    let result = imageURL;
    if (imageURL.indexOf('http') == -1) {
      result = this.baseImageurl + imageURL;
    }
    return result;
  }
}
