export class AppConsts {
  static api: {
    baseUrl: string;
  };
  static media: {
    baseUrl: string;
    baseUrlOnly: string;
  };
  static inviteDoctorLink: {
    baseUrl: string;
  };
  static apisso: {
    baseUrl: string;
  };
  static pageSize: {
    size: any;
  };
  static page: { basePage: number };
  static isSecure: number;
  static reloadTimePatient: number;
  static reloadTimeCovid: number;
  static defaultPage: { defaultBasePage: number };
  static patientPortal: {
    baseUrl: string;
  };
  static jitsi: {
    baseUrl: string;
  };
  static provinceCode: [];
  static subclinicalResultLink: string;
  static isClinic: boolean;
  static isUseSSOLogin: boolean;
  static ROSId: {
    Laboratory: string;
    Imaging: string;
    Procedure: string;
    Other: string;
  };
  static pmrHis: string;
  static title: string;
  static hienGiaDichVuChiDinh: boolean;
  static printReportUrl: string;
}
