import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DoctorService } from 'src/app/services/doctor.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PatientService } from 'src/app/services/patient.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AppState } from '../../app-state/app-state';

@Component({
  templateUrl: './update-user-profile.component.html',
  styleUrls: ['./update-user-profile.component.scss'],
})
export class UpdateUserProfileComponent implements OnInit {
  email: string;
  userName: string;
  updateForm: FormGroup;
  submitted = false;
  doctorRanks: any[] = [];

  formValue = JSON.parse(localStorage.getItem('MemberRegistForm'));
  constructor(
    private fb: FormBuilder,
    private patientService: PatientService,
    private doctorService: DoctorService,
    private userService: UserProfileService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private notification: NotificationService,
    private router: Router
  ) {
    this.updateForm = this.fb.group({
      fullName: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ],
      ],
      phoneNo: [null, [Validators.required]],
      dob: [null],
      address: [null],
      doctorRank: [null],
    });
  }

  disabledDateAfterToday = this.patientService.disabledDateAfterToday;
  get f() {
    return this.updateForm.controls;
  }
  ngOnInit(): void {
    this.getListDoctorRank();
  }
  getListDoctorRank() {
    this.doctorService.getListDoctorRank().subscribe((res) => {
      this.doctorRanks = res;
      this.updateForm.controls.doctorRank.setValue(
        this.formValue.doctorRankId
      );
    });
    this.userName = this.route.snapshot.queryParamMap.get('username');

    this.userService.GetInfo().subscribe((res) => {
      const info = res.d.jsonData;

      if (this.userName !== info.userName) {
        this.notification.showNotification(
          'error',
          'User online không phải là user mà bạn muốn cập nhật thông tin'
        );
        return;
      } else {
        this.email = info.email;
        // this.updateForm.value.address=res.address;
        // this.updateForm.value.fullname=res.fullname;
        // this.updateForm.value.dob=res.dob;
        // this.updateForm.value.phoneNo=res.phoneNo;
        // this.updateForm.controls['address'].setValue(info.address);
        // this.updateForm.controls['fullName'].setValue(info.fullname);
        // this.updateForm.controls['dob'].setValue(info.dob);
        // this.updateForm.controls['phoneNo'].setValue(info.phoneNo);
        // console.log('formValue',localStorage.getItem("MemberRegistForm"))
        // // let formValue = JSON.parse(localStorage.getItem("MemberRegistForm"));
        // console.log('formValue',this.formValue, this.formValue.fullname)
        //this.updateForm.value
        this.updateForm.patchValue({
          fullName: this.formValue.fullname,
          phoneNo: this.formValue.phoneNo,
          dob: this.formValue.dob,
          address: this.formValue.address,
          doctorRank: this.formValue.doctorRankId,
        });
        localStorage.removeItem('MemberRegistForm');
      }
    });
  }
  onSubmit() {
    this.submitted = true;
    this.userService.GetInfo().subscribe((res) => {
      if (this.userName !== res.d.jsonData.userName) {
        this.notification.showNotification(
          'error',
          'User online không phải là user mà bạn muốn cập nhật thông tin'
        );
        return;
      }
    });

    const formValue = this.updateForm.value;
    if (this.updateForm.valid) {
      const payload = {
        fullName: formValue.fullName,
        phone: formValue.phoneNo,
        dob: formValue.dob,
        address: formValue.address,
        email: this.email,
        repeatPassword: formValue.repeatPassword,
        doctorRankId:
          formValue.doctorRank != null ? formValue.doctorRank.id : '',
        sex: -1,
      };
      this.userService.UpdateInfo(payload).subscribe((res) => {
        if (res.d.isValid) {
          this.notification.showNotification(
            Constant.NOTIFY_TYPE.INFO,
            'Cập nhật thông tin tài khoản thành công!'
          );
          this.router.navigate(['/common/worklist']);
          // this.notificationService.showNotification("success", "Sửa thành công");
          // this.isShown = !this.isShown;
          // this.enableEditUser = true;
          // this.isVisible = false;
          // this.getInfo();
        } else {
          this.notification.showNotification('error', res.ret[0].errorMessage);
        }
      });
    }
  }
}
