import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../app-config.service';
import { Constant } from '../share/constants/constant.class';
import { UrlConstant } from '../share/constants/url.class';
import { BaseService } from '../share/base-service/base-service.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { DoctorInGroup } from '../models/doctor-in-group.model';
import { AddDataDocument, RotateDataImage } from '../models/visit-media';

@Injectable({
  providedIn: 'root',
})
export class VisitService extends BaseService {
  private indexSource = new Subject<any>();

  private visitSourceSubject = new BehaviorSubject<any>(null);
  dataVisit$: Observable<any> = this.visitSourceSubject.asObservable();

  public indexTab = this.indexSource.asObservable();

  storeVisit(source: any[]) {
    this.visitSourceSubject.next(source);
  }
  changeTab(index) {
    this.indexSource.next(index);
  }
  getVisit(visitId, currentGroupId): Observable<any> {
    return this.get(UrlConstant.VISIT + '/' + visitId + '/' + currentGroupId);
  }
  getVisitPushHis(visitId): Observable<any> {
    return this.post(UrlConstant.VISIT + '/PushHIS' + '/' + visitId, null);
  }
  getVisitCalendar(payload): Observable<any> {
    return this.post(UrlConstant.VISIT + '/GetListVisitCalendar', payload);
  }
  getVisitInShareLink(visitId, currentGroupId, shareId): Observable<any> {
    return this.get(UrlConstant.GET_VISIT_SHARE_LINK + '/' + visitId + '/' + currentGroupId + '/' + shareId);
  }
  lstVisitHis(dataHis): Observable<any> {
    return this.post(UrlConstant.LIST_VISIT_HIS, dataHis);
  }
  //le.huathi: optimization
  lstVisitHisFast(dataHis): Observable<any> {
    return this.post(UrlConstant.LIST_VISIT_HIS_FAST, dataHis);
  }
  getDiseasesData(keyword): Observable<any> {
    return this.get(UrlConstant.DISEASE_DATA + keyword);
  }
  saveDiseases(payload): Observable<any> {
    return this.post(UrlConstant.VISIT_SAVE_DISEASE, '', payload);
  }
  removeDiseases(payload): Observable<any> {
    return this.post(UrlConstant.VISIT_REMOVE_DISEASE, '', payload);
  }

  addVisitContainPatient(payload): Observable<any> {
    return this.post(UrlConstant.PATIENT, payload);
  }

  addVisitOnly(payload): Observable<any> {
    return this.post(UrlConstant.VISIT, payload);
  }

  getDoctorInGroup(payload): Observable<DoctorInGroup> {
    return this.post(UrlConstant.DOCTOR_IN_GROUP, payload);
  }

  addVisitImage(payload): Observable<any> {
    return this.post(UrlConstant.ADD_IMAGES_VISIT, '', payload);
  }

  addVisitPdf(payload): Observable<any> {
    return this.post(UrlConstant.ADD_PDFS_VISIT, '', payload);
  }

  editVisitPdf(payload): Observable<any> {
    return this.post(UrlConstant.EDIT_VISIT_PDF, '', payload);
  }

  editVisitImages(payload): Observable<any> {
    return this.post(UrlConstant.EDIT_IMAGES_VISIT, payload);
  }

  deleteVisitImage(payload): Observable<any> {
    return this.post(UrlConstant.REMOVE_IMAGES_VISIT, payload);
  }

  getVisitImage(visitId: string) {
    return this.get(UrlConstant.GET_VISIT_IMAGE + '/' + visitId);
  }

  rotateImage(data: RotateDataImage) {
    return this.post(UrlConstant.ROTATE_VISIT_IMAGE, data);
  }

  addVisitDocument(data: AddDataDocument) {
    return this.post(UrlConstant.ADD_DOCUMENTS_VISIT, data);
  }

  deleteVisitDocument(data: AddDataDocument) {
    return this.post(UrlConstant.DELETE_DOCUMENTS_VISIT, data);
  }

  deleteVisitPdf(payload) {
    return this.post(UrlConstant.DELETE_PDF_VISIT, payload);
  }

  getVisitMasterNote(patientId): Observable<any> {
    return this.post('/api/Visit/GetVisitMasterNote?patientId=' + patientId, null);
  }
  getMasterNoteById(masternoteId): Observable<any> {
    return this.get('/api/Visit/GetMasterNoteById/' + masternoteId);
  }
  deleteMasterNoteById(masternoteId): Observable<any> {
    return this.delete('/api/Visit/DeleteMasterNote/', masternoteId);
  }
  getMasterNoteByVisitId(vistiId): Observable<any> {
    return this.get('/api/Visit/GetMasterNoteByVisitId/' + vistiId);
  }
  addVisitMasterNote(payload): any {
    return this.post('/api/Visit/AddVisitMasterNote', payload);
  }
  updateVisitMasterNote(payload): any {
    return this.put('/api/Visit/UpdateVisitMasterNote', payload);
  }
  saveVisitReason(payload): Observable<any> {
    return this.post(UrlConstant.REASON_VISIT, payload);
  }
  updateVisitRoom(payload): Observable<any> {
    return this.post(UrlConstant.UPDATE_VISIT_ROOM, payload);
  }
  saveVisitStatus(payload): Observable<any> {
    return this.post(UrlConstant.VISIT_STATUS, payload);
  }
  updateClinicalInfo(payload): Observable<any> {
    return this.post(UrlConstant.CLINICAL_INFO, payload);
  }
  updateConclusion(payload): Observable<any> {
    return this.post(UrlConstant.CONCLUSION, payload);
  }
  updateVisitDiagnoseInit(payload): Observable<any> {
    return this.post(UrlConstant.UpdateVisitDiagnoseInit, payload);
  }
  updateTags(payload): Observable<any> {
    return this.post(UrlConstant.TAGS, payload);
  }
  updateVisitInfoCore(payload): Observable<any> {
    return this.post(UrlConstant.VISITINFO_CORE, payload);
  }
  finishVist(vistiId): Observable<any> {
    return this.post(UrlConstant.FINISH_VISIT + '/' + vistiId, '');
  }
  setPatientIsTest(payload): Observable<any> {
    return this.post(UrlConstant.IS_TEST, payload);
  }
  deleteVisit(visitId): Observable<any> {
    return this.delete(UrlConstant.DELETE_VISIT , visitId);
  }
  deleteVisitRelated(visitId): Observable<any> {
    return this.delete(UrlConstant.DELETE_VISIT_RELATED , visitId);
  }
  getTotalElementOfTab(payload): Observable<any> {
    return this.post(UrlConstant.TOTAL_TAB, payload);
  }
  pushVisitStatus2HIS(payload): Observable<any> {
    return this.post(UrlConstant.VISIT_STATUS_2HIS, payload);
  }
  pushVisitRoom2HIS(payload): Observable<any> {
    return this.post(UrlConstant.VISIT_ROOM_2HIS, payload);
  }
  getPrintTemplate(visitId, printType): Observable<any> {
    return this.post(UrlConstant.SUMMARY_REPORT, '', {
      visitId,
      printType,
    });
  }
  getPrintTemplate2(visitId, printType, ids, hienGiaDichVuChiDinh, visitIds = []): Observable<any> {
    return this.post('/api/FormTemplate/SummaryReportCustomSubClinicalIds', {
      visitId,
      printType,
      ids,
      visitIds,
      hienGiaDichVuChiDinh,
    });
  }
  receiveVisit(visitId): Observable<any> {
    return this.get(UrlConstant.VISIT_RECEIVE + '/Receive' + '/' + visitId);
  }
  returnVisit(visitId): Observable<any> {
    return this.get(UrlConstant.VISIT_RECEIVE + '/Return' + '/' + visitId);
  }
  addExtraVisit(payload): Observable<any> {
    return this.post(UrlConstant.VISIT_RECEIVE + '/AddExtraVisit', payload);
  }
  getRelatedVisit(payload): Observable<any> {
    return this.post(UrlConstant.VISIT_RECEIVE + '/GetRelatedVisit', payload);
  }
  searchExaminationService(payload): Observable<any> {
    return this.post('/api/ExaminationService/Search', payload);
  }
  getSubclinicalAndObservationType(id): Observable<any> {
    return this.get(UrlConstant.SUB_CLINICAL_VISIT + '/GetSubclinicalAndObservationType?id=' + id);
  }
  getLaboratoryResultReport(payload): Observable<any> {
    return this.post(UrlConstant.TEMPLATE_LAB_RESULT, payload);
  }
  UpdateMedicalReportInfo(payload): Observable<any> {
    return this.post(UrlConstant.VISIT+'/UpdateMedicalReportInfo', payload);
  }
  GetInfo2(id): Observable<any> {
    return this.get(UrlConstant.VISIT+'/GetInfo2/'+id);
  }
}
