import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { News } from '../models/news.class';
import { Result } from '../models/result.class';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class NewsService extends BaseService {
  saveCategory(category: any): Observable<Result> {
    return this.post(UrlConstant.SAVE_CATEGORY, category);
  }

  getCategoryTree(): Observable<Result> {
    return this.get(UrlConstant.GET_CATEGORY_TREE);
  }

  saveNews(data: News): Observable<Result> {
    return this.post(UrlConstant.SAVE_NEWS, data);
  }

  getNewsByUrl(id: string): Observable<any> {
    return this.get(UrlConstant.GET_NEWS_BY_URL + '/' + id);
  }

  getNews(data: any): Observable<any> {
    return this.post(UrlConstant.GET_NEWS, data);
  }
}
