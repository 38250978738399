import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Visits } from 'src/app/models/listVisit.class';
import { MedicalInstructionService } from 'src/app/services/medical-instruction.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-command-state-modal',
  templateUrl: './command-state-modal.component.html',
  styleUrls: ['./command-state-modal.component.scss'],
})
export class CommandStateModalComponent implements OnInit, OnChanges {
  @Input() isVisible = false;
  @Input() visit: Visits;
  @Input() currentOrder: any;

  @Output() isVisibleChange: EventEmitter<any> = new EventEmitter();
  @Output() reloadData: EventEmitter<any> = new EventEmitter();
  formUpdateStatus = {
    id: '',
    status: 0,
    executeDate: null,
    otherInfo: '',
  };
  constructor(
    private medicalInstructionService: MedicalInstructionService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentOrder) {
      const item = changes.currentOrder.currentValue;
      if (item != null) {
        this.formUpdateStatus = {
          id: item.id,
          status: item.status,
          executeDate: item.executeDate,
          otherInfo: item.otherInfo,
        };
      }
    }
  }

  cancelUpdateStatus() {
    this.isVisibleChange.emit(false);
  }

  getPatientAge(dob): string {
    if (dob != null) {
      const age = new Date().getFullYear() - new Date(dob).getFullYear();
      return age.toString() + ' tuổi';
    } else {return '';}
  }

  updateStatus() {
    const payload = {
      id: this.currentOrder.id,
      status: this.formUpdateStatus.status,
      executeDate: this.formUpdateStatus.executeDate,
      otherInfo: this.formUpdateStatus.otherInfo,
    };
    this.medicalInstructionService.update2Visit(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(
          Constant.SUCCESS,
          'Cập nhật thành công!'
        );
        this.isVisibleChange.emit(false);
        this.reloadData.emit();
      } else {
        this.notificationService.showNotification(
          Constant.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }
}
