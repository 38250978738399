<nz-result
  nzStatus="404"
  nzTitle="404"
  nzSubTitle="Sorry, the page you visited does not exist.">
  <div nz-result-extra>
    <button nz-button nzType="primary" (click)="wayBackHome()">
      Back Home
    </button>
  </div>
</nz-result>
