<div style="padding: 10px">
  <div style="display: block">
    <h1 class="page-title">Danh sách bệnh nhân</h1>
    <a
      style="margin-left: 20px; float: right; font-size: 12px"
      (click)="toggleFilter()"
      >Bộ lọc</a
    >
  </div>
  <nz-table
    class="main-table-grid listvs-only"
    #fixedTable
    [nzFrontPagination]="false"
    [nzTotal]="total"
    nzShowSizeChanger
    [nzPageSize]="pageSize"
    [(nzPageIndex)]="pageIndex"
    [nzData]="resVisit"
    [nzLoading]="loading"
    (nzQueryParams)="onQueryParamsChange($event)"
    [nzShowTotal]="rangeTemplate"
    [nzPageSizeOptions]="[15, 30, 50]">
    <thead>
      <tr></tr>
    </thead>
    <tbody>
      <tr class="pointer" *ngFor="let data of fixedTable.data; index as i">
        <td>
          <div class="row-item sos-{{ data.sosRange }}">
            <div
              style="float: left; text-align: left; display: flex"
              (click)="
                visitDetail(data.visitId, data.patientName, data.patientId)
              ">
              <div
                class="customer-photo"
                [ngStyle]="{ 'background-color': 'white' }">
                {{ calcDay(data) }}
              </div>
              <!-- <div *ngIf="(!data.color ||
                          !data.shortName) &&
                          (!data.avatar || data.avatar == '')
                          " class="customer-photo" style="
                          background-image: url('/assets/image/avt-default2.png');
                          background-repeat: round;
                          "></div>
              <div *ngIf="data.avatar && data.avatar != ''" class="customer-photo" [ngStyle]="{
                                      'background-image': 'url(' + (baseAvatarurl + data.avatar) + ')'
                                      }"></div> -->
              <div style="font-size: 14px; margin-top: 2px">
                <strong>{{ data.patientName }}</strong> -
                {{ getGenderText(data.genderText) }} -
                {{ data.dob | date: 'yyyy' }}
              </div>
            </div>
            <div style="float: right; text-align: right; width: 48px">
              <a
                style="width: 48px; height: 100%; display: block"
                (click)="showDetailOfPatient(data)">
                <i
                  title="Xem nhanh"
                  style="font-size: 16px"
                  nz-icon
                  nzType="eye"
                  nzTheme="outline"></i>
              </a>
            </div>
            <div style="clear: both"></div>
          </div>
          <div
            class="row-inline"
            (click)="
              visitDetail(data.visitId, data.patientName, data.patientId)
            ">
            <div class="col-md-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                style="margin-right: 8px; fill: var(--dp-primary-1)">
                <path
                  id="Path_2258"
                  data-name="Path 2258"
                  d="M6.218,9.924a13.465,13.465,0,0,0,5.858,5.858l1.956-1.956a.884.884,0,0,1,.907-.213,10.14,10.14,0,0,0,3.173.507.892.892,0,0,1,.889.889v3.1a.892.892,0,0,1-.889.889A15.11,15.11,0,0,1,3,3.889.892.892,0,0,1,3.889,3H7a.892.892,0,0,1,.889.889A10.1,10.1,0,0,0,8.4,7.062a.892.892,0,0,1-.222.907Z"
                  transform="translate(-3 -3)"
                  fill="#48ae95" />
              </svg>
              <a [href]="'tel:' + data.phoneNo">{{ data.phoneNo }}</a>
            </div>
            <div class="col-md-6">
              <nz-alert
                *ngIf="data.totalNewPrescription > 0"
                [nzType]="'success'"
                nzMessage="{{ data.totalNewPrescription + ' đơn thuốc' }}"
                class="alert-error msc-alert-success"
                style="width: 100px; float: right"></nz-alert>
            </div>
          </div>
          <div
            class="row-inline"
            (click)="
              visitDetail(data.visitId, data.patientName, data.patientId)
            ">
            <div class="col-md-12">
              <svg
                id="home-52"
                xmlns="http://www.w3.org/2000/svg"
                width="17.137"
                height="16.857"
                viewBox="0 0 17.137 16.857"
                style="margin-right: 8px; fill: var(--dp-primary-1)">
                <g
                  id="Group_2922"
                  data-name="Group 2922"
                  transform="translate(0 0)">
                  <path
                    id="Path_2256"
                    data-name="Path 2256"
                    d="M10.671,5.951,4,11.67v7.652a.556.556,0,0,0,.556.556H9V15.43H12.34v4.448h4.448a.556.556,0,0,0,.556-.556V11.671Zm1.669,7.255H9v-2.78H12.34Z"
                    transform="translate(-2.104 -3.022)"
                    fill="#48ae95" />
                  <path
                    id="Path_2257"
                    data-name="Path 2257"
                    d="M9.157.683,5.266,4.019V1.971H3.042V5.925L.59,8.027l.724.844L9.157,2.148,17,8.871l.724-.844Z"
                    transform="translate(-0.59 -0.683)"
                    fill="#48ae95" />
                </g>
              </svg>
              <span>{{ data.address }}</span>
            </div>
          </div>
          <div
            class="row-inline"
            (click)="
              visitDetail(data.visitId, data.patientName, data.patientId)
            ">
            <div class="col-md-6">
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style="enable-background: new 0 0 512 512"
                xml:space="preserve"
                style="
                  margin-right: 8px;
                  fill: var(--dp-primary-1);
                  stroke: var(--dp-primary-1);
                  width: 16px;
                ">
                <g>
                  <g>
                    <g>
                      <circle cx="386" cy="210" r="20" />
                      <path
                        d="M432,40h-26V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v20h-91V20c0-11.046-8.954-20-20-20
                        c-11.046,0-20,8.954-20,20v20h-90V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v20H80C35.888,40,0,75.888,0,120v312
                        c0,44.112,35.888,80,80,80h153c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20H80c-22.056,0-40-17.944-40-40V120
                        c0-22.056,17.944-40,40-40h25v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h90v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h91
                        v20c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80h26c22.056,0,40,17.944,40,40v114c0,11.046,8.954,20,20,20
                        c11.046,0,20-8.954,20-20V120C512,75.888,476.112,40,432,40z" />
                      <path
                        d="M391,270c-66.72,0-121,54.28-121,121s54.28,121,121,121s121-54.28,121-121S457.72,270,391,270z M391,472
                        c-44.663,0-81-36.336-81-81s36.337-81,81-81c44.663,0,81,36.336,81,81S435.663,472,391,472z" />
                      <path
                        d="M420,371h-9v-21c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v41c0,11.046,8.954,20,20,20h29
                        c11.046,0,20-8.954,20-20C440,379.954,431.046,371,420,371z" />
                      <circle cx="299" cy="210" r="20" />
                      <circle cx="212" cy="297" r="20" />
                      <circle cx="125" cy="210" r="20" />
                      <circle cx="125" cy="297" r="20" />
                      <circle cx="125" cy="384" r="20" />
                      <circle cx="212" cy="384" r="20" />
                      <circle cx="212" cy="210" r="20" />
                    </g>
                  </g>
                </g>
              </svg>
              {{ data.visitDate | date: 'dd/MM/yyyy HH:mm' }}
            </div>
            <div class="col-md-6">
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style="
                  margin-right: 8px;
                  fill: var(--dp-primary-1);
                  stroke: var(--dp-primary-1);
                  width: 16px;
                "
                xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M408.028,292.972c-24.795-24.795-54.585-42.774-87.096-53.027c5.691-3.911,11.075-8.356,16.082-13.326
                      C358.93,204.865,371,175.879,371,145V45c0-24.813-20.187-45-45-45H186c-24.813,0-45,20.187-45,45v98.745
                      c0,39.8,20.028,75.107,50.428,96.086c-32.651,10.234-62.569,28.254-87.456,53.141C63.364,333.58,41,387.571,41,445v67h430v-67
                      C471,387.571,448.636,333.58,408.028,292.972z M171,45c0-8.271,6.729-15,15-15h70h70c8.271,0,15,6.729,15,15v15h-42.58
                      c-6.192-17.458-22.865-30-42.42-30s-36.228,12.542-42.42,30H171V45z M271,75c0,8.271-6.729,15-15,15s-15-6.729-15-15
                      s6.729-15,15-15S271,66.729,271,75z M171,143.745V90h42.58c6.192,17.458,22.865,30,42.42,30s36.228-12.542,42.42-30H341v55
                      c0,22.823-8.921,44.248-25.121,60.328c-16.198,16.077-37.711,24.794-60.526,24.669C208.84,229.653,171,190.96,171,143.745z
                      M241,482H71v-37c0-73.758,43.394-137.564,105.988-167.254L241,341.759V482z M207.997,266.329C223.312,262.21,239.402,260,256,260
                      c16.572,0,32.638,2.203,47.932,6.31l-47.977,47.977L207.997,266.329z M441,482H271V341.668l63.95-63.951
                      C397.577,307.394,441,371.217,441,445V482z" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      points="371,392 371,362 341,362 341,392 311,392 311,422 341,422 341,452 371,452 371,422 401,422 401,392 		" />
                  </g>
                </g>
              </svg>
              {{ data.doctorName }}
            </div>
          </div>
          <div class="row-inline">
            <div class="col-md-6">
              <i
                nz-icon
                nzType="book"
                nzTheme="outline"
                class="mg-r-8"
                style="color: var(--dp-primary-1)"></i>
              <span *ngIf="!data.userReported" style="color: red">{{
                userReportedToText(data.userReported)
              }}</span>
              <span *ngIf="data.userReported" style="color: green">{{
                userReportedToText(data.userReported)
              }}</span>
            </div>
          </div>
          <!-- <div class="row-inline">
            <div class="col-md-6">
              <svg id="Group_2811" data-name="Group 2811" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="margin-right: 8px;fill:var(--dp-primary-1);stroke:var(--dp-primary-1);">
                <path id="Path_2210" data-name="Path 2210" d="M554.435,195.694h0a1.674,1.674,0,0,0-1.6-1.706l-.078,0a1.287,1.287,0,0,1-2.3-.02c-.036,0-.07,0-.105,0a1.671,1.671,0,0,0-1.591,1.678,3.4,3.4,0,0,0,.873,2.2,9.534,9.534,0,0,0,1.848,1.736l.087.06.087-.058a9.48,9.48,0,0,0,1.87-1.7A3.381,3.381,0,0,0,554.435,195.694Zm-1.154,1.9a9.177,9.177,0,0,1-1.7,1.571,9.23,9.23,0,0,1-1.681-1.6c-.1-.128-.208-.274-.312-.435h1.1a.054.054,0,0,0,.045-.025l.06-.093.075.4a.054.054,0,0,0,.107,0l.071-.371.088.548a.054.054,0,0,0,.108,0l.271-1.534.224,2.271a.056.056,0,0,0,.054.051.055.055,0,0,0,.054-.049l.193-1.352.079.578a.054.054,0,0,0,.107.008l.135-.5.053.281a.054.054,0,0,0,.1.008l.07-.207.062.1a.055.055,0,0,0,.045.026.053.053,0,0,0,.044-.022l.094-.124h.792C553.508,197.306,553.389,197.462,553.281,197.6Zm.412-.578H552.8a.053.053,0,0,0-.043.022l-.062.082-.081-.133a.053.053,0,0,0-.051-.026.055.055,0,0,0-.047.038l-.041.121-.058-.311a.055.055,0,0,0-.052-.046h0a.055.055,0,0,0-.052.041l-.127.468-.1-.7a.055.055,0,0,0-.108,0l-.185,1.292-.219-2.216a.054.054,0,0,0-.108,0l-.284,1.607-.086-.534a.056.056,0,0,0-.054-.048.054.054,0,0,0-.053.046l-.076.392-.047-.253a.055.055,0,0,0-.045-.045.054.054,0,0,0-.054.024l-.118.182h-1.137a2.39,2.39,0,0,1-.4-1.347,1.29,1.29,0,0,1,1.244-1.3l.086,0c.6.039,1.013.737,1.017.744l.142.246.145-.244a1.532,1.532,0,0,1,1.026-.726,1.3,1.3,0,0,1,1.313,1.319h0A2.35,2.35,0,0,1,553.693,197.019Z" transform="translate(-546.438 -190.967)" fill="#707070"/>
                <path id="Path_2211" data-name="Path 2211" d="M559.937,193.766a1.862,1.862,0,0,0-3.689,0,1.673,1.673,0,0,0,1.438,1.566v2.7a4.109,4.109,0,0,1-8.141,0v-1.1h1.794a1.9,1.9,0,0,0,1.958-1.506l1.027-7.211a1.576,1.576,0,0,0-.48-1.349,2.111,2.111,0,0,0-1.478-.58h-.744a.358.358,0,1,0,0,.708h.744a1.241,1.241,0,0,1,.869.341.926.926,0,0,1,.282.793l-1.027,7.211a1.118,1.118,0,0,1-1.151.885h-4.4a1.118,1.118,0,0,1-1.151-.885l-1.027-7.211a.925.925,0,0,1,.282-.793,1.241,1.241,0,0,1,.869-.341h1.05a.358.358,0,1,0,0-.708h-1.05a2.112,2.112,0,0,0-1.478.58,1.576,1.576,0,0,0-.48,1.349l1.027,7.211a1.9,1.9,0,0,0,1.958,1.506h1.794v1.1c0,2.345,2.191,4.253,4.884,4.253s4.884-1.908,4.884-4.253v-2.7A1.673,1.673,0,0,0,559.937,193.766Z" transform="translate(-543.937 -186.285)" fill="#707070"/>
              </svg>
              {{data.visitReason}}
            </div>
            <div class="col-md-6">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	              viewBox="0 0 496 496" style="margin-right: 8px;fill:var(--dp-primary-1);stroke:var(--dp-primary-1);width: 16px;" xml:space="preserve">
                <g>
                  <g>
                    <g>
                      <path d="M447.812,390.192c-5.368-32.232-27.968-58.68-58.976-69.016l-68.832-22.944V288h-16v15.008l-11.944,47.768L259.748,312
                        h-23.496l-32.312,38.776l-11.936-47.768V288h-16v10.232l-68.832,22.944c-31,10.336-53.6,36.784-58.976,69.016L30.556,496h434.888
                        L447.812,390.192z M209.068,480h-88.224l7.12-71.2l-15.92-1.592l-7.28,72.8H49.452l14.528-87.176
                        c4.392-26.376,22.888-48.008,48.256-56.472l66.104-22.04l17.728,70.904l18.04-21.648l9.544,14.32L209.068,480z M270.78,480
                        h-45.552l15.128-105.896l-15.568-23.352L243.748,328h8.504l18.96,22.752l-15.568,23.352L270.78,480z M391.244,480l-7.28-72.8
                        l-15.92,1.592l7.12,71.2H286.94l-14.584-102.104l9.544-14.32l18.04,21.648l17.728-70.904l66.104,22.04
                        c25.368,8.456,43.856,30.096,48.256,56.472L446.556,480H391.244z"/>
                      <path d="M147.54,215.552c4.08,14.04,11.616,26.912,22.24,37.536l31.2,31.2C208.532,291.84,218.572,296,229.26,296h37.496
                        c10.68,0,20.728-4.16,28.28-11.712l31.2-31.2c10.624-10.624,18.16-23.504,22.24-37.536c19.928-2.256,35.528-19.024,35.528-39.552
                        c0-13.128-6.448-24.704-16.248-32h0.248V96c0-20.024-14.8-36.664-34.032-39.552C321.932,22.944,289.716,0,253.764,0h-5.76
                        c-66.168,0-120,53.832-120,120v24h0.248c-9.8,7.296-16.248,18.872-16.248,32C112.004,196.528,127.604,213.296,147.54,215.552z
                        M336.004,190.856c0,19.24-7.488,37.32-21.088,50.92l-31.2,31.2c-4.464,4.464-10.648,7.024-16.968,7.024h-37.496
                        c-6.32,0-12.496-2.56-16.968-7.024l-31.2-31.2c-13.6-13.6-21.088-31.68-21.088-50.912V152h31.432
                        c35.208,0,68.496-12.504,94.8-35.408l2.448,4.08c10.208,17.016,27.872,28.328,47.328,30.792V190.856z M368.004,176
                        c0,10.552-6.896,19.44-16.376,22.64c0.224-2.568,0.376-5.16,0.376-7.776v-37.392C361.292,156.784,368.004,165.584,368.004,176z
                        M144.004,120c0-57.344,46.656-104,104-104h5.76c30.736,0,58.104,20.64,66.552,50.2l1.656,5.8h6.032c13.232,0,24,10.768,24,24
                        v40.808c-2.584-0.528-5.264-0.808-8-0.808c-16.944,0-32.888-9.024-41.608-23.56l-12.808-21.344l-7.248,7.248
                        C258.06,122.624,225.78,136,191.436,136h-39.432c-2.736,0-5.416,0.28-8,0.808V120z M144.004,153.472v37.392
                        c0,2.616,0.152,5.208,0.376,7.784c-9.48-3.208-16.376-12.096-16.376-22.648C128.004,165.584,134.716,156.784,144.004,153.472z"/>
                    </g>
                  </g>
                </g>
              </svg>
              {{data.coordinatorName}}
            </div>
          </div> -->
          <!-- <div class="row-item">
            <a class="btn btn-primary" (click)="visitDetail(data.visitId, data.patientName, data.patientId)" style="margin-right: 10px;padding: 2px 5px">
              Xem chi tiết
            </a>
          </div> -->
        </td>
      </tr>
    </tbody>
    <ng-template #rangeTemplate let-range="range" let-total>
      {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }} {{ 'bệnh nhân' }}
    </ng-template>
  </nz-table>
</div>
<div class="sidebar sidebar-right" *ngIf="isVisibleFilter">
  <div class="sidebar-header" style="margin: auto">
    <div style="width: 80px; display: flex">
      <strong>Bộ lọc</strong>
      <a style="margin-left: 10px" (click)="toggleFilter()">
        <img _ngcontent-kjb-c497="" src="assets/image/DPicon/icon-close.svg" />
      </a>
    </div>
  </div>
  <div style="padding: 10px">
    <div class="filter-row">
      <app-filter-item
        #filter1
        [index]="0"
        [filterItems]="currentFilters"
        [searchParam]="search"
        [resetFilter]="resetFilter"
        (endTreatmentEvent)="endTreatmentEvent($event)"
        (seachListPatientEvent)="searchListPatient()"></app-filter-item>
    </div>
    <div class="filter-row">
      <app-filter-item
        #filter2
        [index]="1"
        [filterItems]="currentFilters"
        [searchParam]="search"
        [resetFilter]="resetFilter"
        (endTreatmentEvent)="endTreatmentEvent($event)"
        (seachListPatientEvent)="searchListPatient()"></app-filter-item>
    </div>
    <div class="filter-row">
      <app-filter-item
        #filter3
        [index]="2"
        [filterItems]="currentFilters"
        [searchParam]="search"
        [resetFilter]="resetFilter"
        (endTreatmentEvent)="endTreatmentEvent($event)"
        (seachListPatientEvent)="searchListPatient()"></app-filter-item>
    </div>
    <div class="filter-row">
      <app-filter-item
        #filter4
        [index]="3"
        [filterItems]="currentFilters"
        [searchParam]="search"
        [resetFilter]="resetFilter"
        (endTreatmentEvent)="endTreatmentEvent($event)"
        (seachEvent)="searchListPatient()"></app-filter-item>
    </div>
    <div class="filter-row">
      <app-filter-item
        #filter5
        [index]="4"
        [filterItems]="currentFilters"
        [searchParam]="search"
        [resetFilter]="resetFilter"
        (endTreatmentEvent)="endTreatmentEvent($event)"
        (seachListPatientEvent)="searchListPatient()"></app-filter-item>
    </div>
    <div style="display: flex">
      <a
        class="btn btn-primary"
        (click)="searchListPatient()"
        style="margin-right: 10px">
        <i title="Tìm kiếm">
          <img src="assets/image/DPicon/icon-search.svg" />
        </i>
        Tìm kiếm
      </a>
      <a class="btn btn-primary" (click)="resetSearch()">
        <i title="Clear dữ liệu">
          <img src="assets/image/DPicon/icon-close.svg" />
        </i>
        Làm mới
      </a>
    </div>
  </div>
</div>
<div class="sidebar-patient sidebar-right" *ngIf="isVisibleDetail">
  <div class="sidebar-header" style="margin: auto">
    <div style="width: 100%; display: flex">
      <div style="float: left; width: 90%">
        Xem nhanh: <strong>{{ currentDetail.patientName }}</strong>
      </div>
      <div style="float: right; width: 10%">
        <a style="margin-left: 10px" (click)="toggleDetailPatient()">
          <svg
            id="Group_14"
            data-name="Group 14"
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            style="fill: #fff; stroke: #fff">
            <path
              id="Path_54"
              data-name="Path 54"
              d="M7.932,6.516l4.861-4.861a.712.712,0,0,0,0-1.006L12.366.224a.713.713,0,0,0-1.006,0L6.5,5.084,1.639.224a.712.712,0,0,0-1.005,0L.208.649a.712.712,0,0,0,0,1.006L5.069,6.516.208,11.377a.713.713,0,0,0,0,1.006l.426.426a.712.712,0,0,0,1.005,0L6.5,7.948l4.861,4.861a.705.705,0,0,0,.5.208h0a.705.705,0,0,0,.5-.208l.426-.426a.713.713,0,0,0,0-1.006Z"
              transform="translate(0 -0.016)" />
          </svg>
        </a>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
  <div style="padding: 10px">
    <div class="row-inline">Họ tên: {{ currentDetail.patientName }}</div>
    <div class="row-inline">SĐT: {{ currentDetail.phoneNo }}</div>
    <div class="row-inline">Địa chỉ: {{ currentDetail.address }}</div>
    <div class="row-inline">
      Cập nhật thông tin: {{ currentDetail.userReportedToText }}
    </div>
    <div class="row-inline">
      Cập nhật gần nhất:
      {{ currentDetail.visitDate | date: 'dd/MM/yyyy HH:mm' }}
    </div>
    <div class="row-inline">
      Triệu chứng lâm sàng: {{ currentDetail.clinicalInfomation }}
    </div>
    <div class="row-inline">Chẩn đoán: {{ currentDetail.conclusion }}</div>
    <div class="row-inline">
      BS phụ trách: {{ currentDetail.doctorInCareDoctName }}
    </div>
    <div class="row-inline">
      Điều phối viên: {{ currentDetail.coordinatorName }}
    </div>
    <div class="row-inline">
      <strong>Thông tin lâm sàng:</strong>
    </div>
    <div
      style="padding: 0 10px"
      [innerHtml]="getPatientVitalSignsDetail(currentDetail)"></div>
    <div class="row-item" style="text-align: center; padding: 8px">
      <a
        class="btn btn-primary"
        (click)="
          visitDetail(
            currentDetail.visitId,
            currentDetail.patientName,
            currentDetail.patientId
          )
        ">
        Xem chi tiết
      </a>
    </div>
  </div>
</div>
