import { NgModule } from '@angular/core';
import { SharedModule } from '../../share/share.module';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { AuthorizedComponent } from './authorized/authorized.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user-service';
import { GroupMemberService } from '../../services/group-member.service';
import { ConfirmInviteComponent } from './confirm-invite/confirm-invite.component';
import { NotificationService } from 'src/app/services/notification.service';
import { MemberRegistrationComponent } from './member-registration/member-registration.component';
import { LossPasswordComponent } from './loss-password/loss-password.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
@NgModule({
  declarations: [
    LoginComponent,
    LossPasswordComponent,
    AuthorizedComponent,
    PageNotFoundComponent,
    ServerErrorComponent,
    ConfirmInviteComponent,
    MemberRegistrationComponent,
    AuthLayoutComponent,
  ],
  imports: [AuthRoutingModule, SharedModule],
  providers: [
    AuthService,
    UserService,
    GroupMemberService,
    NotificationService,
  ],
})
export class AuthModule {}
