<!-- <nz-modal class="custom-modal" [(nzVisible)]="isVisible" [nzWidth]="1200" [nzFooter]="null" nzTitle="Chia sẻ ca khám"
  (nzOnCancel)="handleCancel()" nzClassName="share-visit"> -->
<app-m-modal
  class="share-visit"
  [modalId]="'share-visit'"
  [isVisible]="isVisible"
  (closeModal)="handleCancel()"
  [mMask]="true"
  [width]="1200"
  [minWidth]="700"
  [height]="500"
  [minHeight]="400"
  [isResiable]="true"
  [mFooter]="false">
  <ng-template #mModalTitle>
    <div class="header-title">Chia sẻ ca khám</div>
  </ng-template>
  <ng-template #mModalContent>
    <div class="row share-content">
      <div class="col-md-7 left-panel">
        <div class="share-title">Chia sẻ theo link</div>
        <div class="row share-time-div">
          <div class="share-info mg-bt-10">Thời gian chia sẻ</div>
          <div class="slt-time">
            <nz-select
              nzAllowClear
              nzPlaceHolder="Thời gian chia sẻ"
              [(ngModel)]="model.expireDate"
              class="share-time w-100">
              <nz-option nzLabel="4 giờ" nzValue="4"></nz-option>
              <nz-option nzLabel="8 giờ" nzValue="8"></nz-option>
              <nz-option nzLabel="12 giờ" nzValue="12"></nz-option>
              <nz-option nzLabel="24 giờ" nzValue="24"></nz-option>
              <nz-option nzLabel="7 ngày" nzValue="168"></nz-option>
              <nz-option nzLabel="30 ngày" nzValue="720"></nz-option>
              <nz-option nzLabel="Vô hạn" nzValue="9999"></nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row mg-bt-12">
          <div class="share-info mg-bt-10">Cấp độ chia sẻ</div>
          <div class="">
            <nz-radio-group [(ngModel)]="model.shareLevel">
              <!-- (ngModelChange)="updateShareLink()" -->
              <label
                nz-radio
                [nzValue]="4"
                class="mg-bt-12 d-block"
                title="BN được xem dữ liệu và các BS trong group BS được xem/sửa dữ liệu">
                <span class="patient-icon"></span> Thông tin trả cho bệnh
                nhân</label
              >
              <label
                nz-radio
                [nzValue]="3"
                class="mg-bt-12"
                title="Các BS trong group được xem/sửa dữ liệu">
                <span class="group-icon"></span> Thông tin trong nhóm bác
                sĩ</label
              >
            </nz-radio-group>
          </div>
        </div>
        <div class="row mg-bt-20 div-btn-share">
          <div>
            <button
              nz-button
              nzType="primary"
              class="share-btn"
              (click)="shareVisit()">
              Chia sẻ
            </button>
          </div>
        </div>
        <div>
          <div class="share-info mg-bt-8">Link ca khám</div>
          <div>
            <nz-table
              #basicTable
              class="msc-border-table"
              [nzBordered]="true"
              [nzData]="linkShares"
              [nzShowPagination]="false"
              [nzScroll]="{ x: '580px', y: '110px' }">
              <thead>
                <tr>
                  <th nzWidth="300px">Link</th>
                  <th nzWidth="120px">Thời gian</th>
                  <th nzWidth="80px">Cấp độ</th>
                  <th nzWidth="100px"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of basicTable.data">
                  <td class="txt-ellipsis share-link">
                    <a href="{{ data.url }}">{{ data.url }}</a>
                  </td>
                  <td>{{ convertHourToText(data.expireTime) }}</td>
                  <td class="text-align-center">
                    <span
                      [ngClass]="
                        data.shareLevel == 4 ? 'patient-icon' : 'group-icon'
                      "></span>
                  </td>
                  <td class="text-align-center">
                    <span
                      class="copy-icon action-icon"
                      title="Copy"
                      (click)="copyInputUrl(data.url)"></span>
                    <span
                      class="qr-icon action-icon mg-l-10"
                      title="QR code"
                      (click)="openQRCode(data.url)"></span>
                    <span
                      class="close-icon action-icon mg-l-10"
                      title="Xóa"
                      (click)="detele(data.id)"></span>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
      <div class="col-md-5 right-panel">
        <div class="share-title">Chia sẻ theo nhóm</div>
        <div class="row mg-bt-20">
          <div class="share-info">Nhóm chia sẻ</div>
        </div>
        <div class="row mg-bt-15">
          <div>
            <div class="search-group mg-bt-12 row">
              <div class="col-9" style="padding: 0 !important">
                <input
                  class="width-100 height-35"
                  #keywordPatient
                  placeholder="Nhập tên nhóm muốn chia sẻ"
                  minLength="2"
                  nz-input
                  (input)="onSearchGroup(keywordPatient.value)"
                  [nzAutocomplete]="auto"
                  [(ngModel)]="searchGroupInput" />
                <nz-autocomplete #auto>
                  <nz-auto-option
                    *ngFor="let item of searchDataFilter"
                    [nzValue]="item.name"
                    (click)="selectShareGroup(item)">
                    <span>
                      <p>{{ item.name }}</p>
                    </span>
                  </nz-auto-option>
                </nz-autocomplete>
              </div>
              <div class="col-3">
                <button
                  nz-button
                  nzType="primary"
                  class="share-btn-group"
                  (click)="shareGroup()">
                  Chia sẻ
                </button>
              </div>
            </div>
            <div class="mr-bt-8">
              <label nz-checkbox [(ngModel)]="shareAllVisit"
                >Chia sẻ toàn bộ ca khám trong nhóm</label
              >
            </div>
            <label class="note italic"
              >* Lưu ý: Chia sẻ theo nhóm sẽ được chia sẻ thông tin ở cấp 2 -
              trong nhóm bác sỹ</label
            >
          </div>
        </div>
        <div class="row mg-bt-20">
          <div class="share-info">Nhóm được chia sẻ</div>
        </div>
        <div class="row mg-bt-12">
          <div
            *ngIf="data != null && data.length > 0"
            id="list-group"
            class="col-12">
            <nz-list nzItemLayout="horizontal" [nzLoading]="loading">
              <nz-list-item *ngFor="let item of data">
                <span
                  ><img src="assets/image/group.svg" width="30px" height="30px"
                /></span>
                <span class="txt-ellipsis share-group-item">
                  {{ item.name }}
                </span>
                <!-- <nz-list-item-meta nzAvatar="assets/image/group.svg" nzDescription="{{item.name}}"></nz-list-item-meta> -->
              </nz-list-item>
            </nz-list>
            <nz-list-empty *ngIf="data.length === 0"></nz-list-empty>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</app-m-modal>
<!-- </nz-modal> -->

<app-qr-share-link
  [isVisible]="isVisibleQR"
  [link]="currentLinkShare"
  (closeModal)="closeQR($event)"></app-qr-share-link>
