import { TabCDHAComponent } from './tab-CDHA.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/share/share.module';
import { ListImageGeneralModule } from '../list-image-general/list-image-general.module';
import { ModalModule } from 'src/app/components/modal/modal.module';

@NgModule({
  imports: [CommonModule, SharedModule, ListImageGeneralModule, ModalModule],
  declarations: [TabCDHAComponent],
  exports: [TabCDHAComponent],
})
export class TabCDHAModule {}
