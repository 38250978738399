import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'protocalFormat',
})
export class ProtocalFormatPipe implements PipeTransform {
  transform(value: string): any {
    if (value) {
      return this.format(value);
    }
    return value;
  }

  format(url: string) {
    let result = url;
    let protocol = location.protocol;
    let newUrl = new URL(url);
    result = url.replace(newUrl.protocol, protocol);
    return result;
  }
}
