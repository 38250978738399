<nz-layout style="overflow: hidden" class="layout-admin">
  <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzBreakpoint]="'md'" nzWidth="240px" nzTheme="dark"
    nzCollapsedWidth="60" [nzTrigger]="null">
    <div style="display: flex; height: 64px" appTargetBlank [routerLink]="'/common/worklist'">
      <div class="pmr-logo">
        <img src="assets/image/logo.png" class="image" style="width: 35px; height: 35px" />
      </div>
      <div [ngStyle]="{ display: isCollapsed ? 'none' : 'flex' }" class="pmr-app-name">
        PMR
      </div>
    </div>
    <div style="border-style: dashed; border-width: 0 0 1px 0; border-color: white"
      [ngStyle]="{ margin: isCollapsed ? '0px' : '0px 13px' }"></div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-submenu nzTitle="Trang chủ" nzIcon="home">
        <ul>
          <li nz-menu-item routerLink="/admin/default">Thông tin chung</li>
        </ul>
      </li>
      <li nz-submenu [nzTitle]="menu.name" [nzIcon]="menu.icon" *ngFor="let menu of menus" [nzOpen]="menu.expand">
        <ul>
          <ng-container *ngFor="let item of menu.childrens">
            <li class="menu-item menu-item-flex" nz-menu-item [nzSelected]="item.expand">
              <span><i nz-icon nzType="tag" nzTheme="outline"></i> </span>
              <span><a routerLink="/admin/{{ item.url }}" (click)="changePageTitle(item.name)">{{ item.name }}</a>
              </span>
            </li>
          </ng-container>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="col-md-12">
        <div class="row">
          <div class="col-6 col-md-10">
            <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
              (click)="isCollapsed = !isCollapsed"></i>
            <span class="title-bar">{{ pageName }}</span>
          </div>
          <div class="col-6 col-md-2 text-right">
            <div class="dropdown-toggle header-icon" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
              <nz-avatar nzIcon="user" nzSize="small">
              </nz-avatar>
              <span class="d-none d-sm-inline-block" style="width: 5px"></span>
              <span class="d-none d-sm-inline-block user-info">{{
                username
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <div id="myDropdown" class="dropdown-content">
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item>
              <a target="_blank" href="/">Trang chủ</a>
            </li>
            <li nz-menu-item (click)="logout()">
              <span class="fa fa-sign-out"></span>Đăng xuất
            </li>
            <li nz-menu-item>Profile</li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </nz-header>
    <nz-content>
      <!-- <div class="inner-content"> -->
      <router-outlet></router-outlet>
      <!-- </div> -->
    </nz-content>
  </nz-layout>
</nz-layout>