import { CustomColumnModule } from './../worklist/custom-column-module/custom-column.module';
import { ChatItemModule } from './../worklist/chat-item/chat-item.module';
import { MasternoteEnlargeComponent } from './masternote-enlarge/masternote-enlarge.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { SharedModule } from '../../share/share.module';
import { GroupRoutingModule } from './modal-routing.module';
import { modalReducer } from './redux/modal/modal.reducer';
import { StoreModule } from '@ngrx/store';
import { AddPatientComponent } from './add-patient/add-patient.component';
import { AddVisitComponent } from './add-visit/add-visit.component';
import { DuplicatePatientComponent } from './duplicate-patient/duplicate-patient.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { InviteMemberGroupComponent } from './invite-member-group/invite-member-group.component';
import { ChatTabComponent } from './chat-tab/chat-tab.component';
import { MasternotesTabComponent } from './masternotes-tab/masternotes-tab.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DataFormComponent } from './data-form/data-form.component';
import { FormModule } from '../form/form.module';
import { PatientInfoTableComponent } from './patient-info-table/patient-info-table.component';
//import { PatientFamilyHistoryComponent } from './patient-family-history/patient-family-history.component';
import { ThumbnailImageComponent } from './thumbnail-image/thumbnail-image.component';
import { ImageElementComponent } from './image-element/image-element.component';
import { XetnghiemAddComponent } from './xet-nghiem/xetnghiem-add/xetnghiem-add.component';
import { NgxPrintModule } from 'ngx-print';
import { AppointmentAddComponent } from './appointment-add/appointment-add.component';
import { AppointmentEditComponent } from './appointment-edit/appointment-edit.component';
import { PatientProfileComponent } from './patient-profile/patient-profile.component';
import { ConferenceRoomComponent } from './conference-room/conference-room.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ConferenceAddUserComponent } from './conference-add-user/conference-add-user.component';
import { EditVisitinfoCoreComponent } from './edit-visitinfo-core/edit-visitinfo-core.component';
import { AboutPmrComponent } from './about-pmr/about-pmr.component';
import { SliderImageComponent } from './slider-image/slider-image.component';
import { ImageModeViewComponent } from './image-mode-view/image-mode-view.component';
import { TreatmentPatientComponent } from './treatment-patient/treatment-patient.component';
import { CovidAddPatientComponent } from './covid-add-patient/covid-add-patient.component';
import { DataFormMobileComponent } from './data-form-mobile/data-form-mobile.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { AddVisitUpdateComponent } from './add-visit-update/add-visit-update.component';

@NgModule({
  imports: [
    CommonModule,
    GroupRoutingModule,
    SharedModule,
    StoreModule,
    // StoreModule.forFeature('modals', modalReducer),
    CKEditorModule,
    AngularEditorModule,
    FormModule,
    ChatItemModule,
    NzButtonModule,
    NgxPrintModule,
  ],
  declarations: [
    AddPatientComponent,
    AddVisitComponent,
    DuplicatePatientComponent,
    AddGroupComponent,
    InviteMemberGroupComponent,
    DataFormComponent,
    ChatTabComponent,
    MasternotesTabComponent,
    PatientInfoTableComponent,
    //PatientFamilyHistoryComponent,
    // DropFileDirective,
    ThumbnailImageComponent,
    ImageElementComponent,
    AppointmentAddComponent,
    AppointmentEditComponent,
    PatientProfileComponent,
    ConferenceRoomComponent,
    NotificationsComponent,
    ConferenceAddUserComponent,
    MasternoteEnlargeComponent,
    EditVisitinfoCoreComponent,
    AboutPmrComponent,
    SliderImageComponent,
    ImageModeViewComponent,
    TreatmentPatientComponent,
    CovidAddPatientComponent,
    DataFormMobileComponent,
    AddVisitUpdateComponent,
  ],
  exports: [
    AddPatientComponent,
    AddVisitComponent,
    DuplicatePatientComponent,
    AddGroupComponent,
    InviteMemberGroupComponent,
    DataFormComponent,
    DataFormMobileComponent,
    ChatTabComponent,
    MasternotesTabComponent,
    PatientInfoTableComponent,
    //PatientFamilyHistoryComponent,
    AngularEditorModule,
    FormModule,
    AppointmentAddComponent,
    AppointmentEditComponent,
    PatientProfileComponent,
    ConferenceRoomComponent,
    NotificationsComponent,
    ConferenceAddUserComponent,
    EditVisitinfoCoreComponent,
    AboutPmrComponent,
    SliderImageComponent,
    ImageModeViewComponent,
    TreatmentPatientComponent,
    CovidAddPatientComponent,
    AddVisitUpdateComponent,
  ],
  providers: [DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalModule {}
