import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/share/share.module';
import { RulesManagermentComponent } from './rules-managerment.component';
import { ReminderRulesComponent } from './reminder-rules/reminder-rules.component';

@NgModule({
  declarations: [RulesManagermentComponent, ReminderRulesComponent ],
  imports: [CommonModule, SharedModule],
  exports: [RulesManagermentComponent, ReminderRulesComponent],
})
export class RulesManagermentModule { }
