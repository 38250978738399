<div class="tab-list-room">
  <div class="search-bar" style="display: flex">
    <div class="search-bar-left">
      <!-- <input [(ngModel)]="search.name" class="txt_Search mgr15" name="patientName" id="txtFullName" type="text"
        autocomplete="off" placeholder="Tên nhóm" (keydown.enter)="searchRoom()" />
      <div (click)="resetSearch()" class="pointer mg-r-10">
        <i>
          <img src="assets/image/DPicon/icon-close.svg" />
        </i>
      </div>
      <div (click)="searchRoom()" class="pointer mg-r-10">
        <i>
          <img src="assets/image/DPicon/icon-search.svg" />
        </i>
      </div> -->
    </div>
    <div class="search-bar-right">
      <!-- <button *ngIf="selectedRoomId && isRoomManager" class="normal-btn btn-primary-medium mg-r-8" type="button" (click)="showAddModal()">
        Danh sách BN
      </button> -->
      <button
        *ngIf="selectedRoomId"
        class="normal-btn btn-primary-medium mg-r-8"
        type="button"
        (click)="showViewDoctorModal()">
        Danh sách BS
      </button>
      <button
        *ngIf="selectedRoomId"
        class="normal-btn btn-primary-medium mg-r-8"
        type="button"
        (click)="showAddModal(false)">
        Sửa phòng
      </button>
      <button
        class="normal-btn btn-primary-medium"
        type="button"
        (click)="showAddModal(true)">
        Thêm phòng
      </button>
    </div>
  </div>
  <div>
    <nz-table
      class="main-table-grid listvs-only patient-room-table"
      #fixedTable
      nzShowSizeChanger
      [nzPageSize]="pageSize"
      [(nzPageIndex)]="pageIndex"
      [nzData]="rooms"
      [nzLoading]="loading"
      [nzScroll]="{ x: '1000px', y: 'calc(100vh - 300px)' }"
      [nzShowTotal]="rangeTemplate"
      [nzPageSizeOptions]="[15, 30, 50]">
      <thead>
        <tr>
          <th nzWidth="60px" class="center">STT</th>
          <th nzWidth="350px">Tên phòng</th>
          <th nzWidth="300px" class="center">Người tạo</th>
          <th class="center">Số bác sĩ</th>
          <th class="center">Số bệnh nhân</th>
          <th class="center">Mã phòng</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let room of fixedTable.data; let i = index"
          [ngClass]="{ 'highlight': selectedRoomId == room.id }"
          (click)="selectRoom(room)">
          <td class="center">
            {{ fixedTable.nzPageSize * (fixedTable.nzPageIndex - 1) + i + 1 }}
          </td>
          <td>{{ room.name }}</td>
          <td class="center">{{ room.createdByUserName }}</td>
          <td class="center">{{ room.listDoctor.length }}</td>
          <td class="center">{{ room.listPatient.length }}</td>
          <td class="center">{{ room.id }}</td>
        </tr>
      </tbody>
      <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }} {{ 'room' }}
      </ng-template>
    </nz-table>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isVisibleAddGroup"
  nzMaskClosable="false"
  (nzOnCancel)="handleCancelRoom()">
  <div *nzModalTitle>
    {{ (selectedRoomId == '' ? 'Thêm' : 'Sửa') + ' phòng' }}
  </div>
  <div *nzModalContent>
    <form nz-form [formGroup]="formRoom">
      <nz-form-item>
        <nz-form-label [nzSpan]="5" nzRequired nzFor="name"
          >Tên phòng</nz-form-label
        >
        <nz-form-control nzHasFeedback [nzErrorTip]="nameErrorTpl">
          <input formControlName="name" nz-input placeholder="Tên phòng" />
          <ng-template #nameErrorTpl let-control>
            <ng-container *ngIf="control.hasError('emptyName')">
              Tên phòng không được để trống!
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">
              Hãy nhập tên phòng!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="saveRoom()">
      {{ selectedRoomId == '' ? 'Thêm' : 'Sửa' }}
    </button>
    <button nz-button nzType="default" (click)="handleCancelRoom()" nzDanger>
      Huỷ
    </button>
  </div>
</nz-modal>

<app-doctor-in-room
  [isVisible]="isVisibleViewDoctor"
  [groupId]="selectedGroupId"
  [roomId]="selectedRoomId"
  (closeModal)="closeViewDoctorModal()"
  (changeDoctor)="resetSearch()"></app-doctor-in-room>
