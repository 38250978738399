<div class="custom-modal">
  <!-- <nz-modal [nzFooter]=null class="custom-modal" [nzWidth]="900" [(nzVisible)]="isVisible" nzTitle="Thông báo"(nzOnCancel)="handleCancel()" (nzOnOk)="handleCancel()"> -->
  <app-m-modal
    class="custom-modal"
    [modalId]="'notifications'"
    [isVisible]="isVisible"
    (closeModal)="handleCancel()"
    [mMask]="true"
    [width]="900"
    [minWidth]="900"
    [height]="750"
    [minHeight]="600"
    [isResiable]="true"
    [mFooter]="false">
    <ng-template #mModalTitle>
      <div class="header-title">Thông báo</div>
    </ng-template>
    <ng-template #mModalContent>
      <nz-tabset [nzCanDeactivate]="canDeactivate" class="tabset-default">
        <nz-tab class="tab-content" *ngFor="let tab of tabs" [nzTitle]="getTabTitle(tab)">
          <!-- Content of tab {{ tab }} -->
          <div class="row list-noti">
            <div class="col-3">
              <nz-date-picker
                [(ngModel)]="search.fromDate"
                nzPlaceHolder="Từ ngày"
                nzFormat="dd/MM/yyyy"
                style="margin-top: 7px"></nz-date-picker>
            </div>
            <div class="col-3">
              <nz-date-picker
                [(ngModel)]="search.toDate"
                nzPlaceHolder="Đến ngày"
                nzFormat="dd/MM/yyyy"
                style="margin-top: 7px"></nz-date-picker>
              <!-- <input
                              [(ngModel)]="search.fromDate"
                              class="txt_Search mgr15"
                              name="fromDate"
                              id="txtFullName"
                              type="text"
                              autocomplete="off"
                              placeholder="Từ ngày"
                              (keydown.enter)="searchListNoti()"
                            />
                            <input
                              [(ngModel)]="search.toDate"
                              class="txt_Search mgr15"
                              name="patientCode"
                              id="txtCode"
                              type="text"
                              autocomplete="off"
                              placeholder="đến ngày"
                              (keydown.enter)="searchListNoti()"
                            /> -->
            </div>
            <div class="col-2" style="text-align: left">
              <button
                class="response-btn btn-primary-medium button-css"
                type="button"
                title="Tìm"
                value="Tìm"
                (click)="callPager(1)">
                Tìm kiếm
              </button>
            </div>
            <div class="col-1"></div>
            <div class="col-3" style="text-align: right">
              <a (click)="markAllAsRead()" style="color: #007bff !important; padding-top: 4px; display: block">
                Đánh dấu tất cả là đã đọc
              </a>
            </div>
          </div>
          <div class="row list-noti">
            <ul class="list-align customTableCrollbar">
              <li class="notifi-item" *ngFor="let item of listNotification">
                <div class="user-avatar">
                  <img
                    *ngIf="item.avatar === '' || item.avatar === null"
                    style="height: 40px"
                    src="assets/image/group.svg"
                    alt="" />
                  <img *ngIf="item.avatar !== ''" style="height: 40px" src="PathAvatar+item.avatar" alt="" />
                </div>
                <div class="item-content">
                  <div class="line-clamp" [innerHtml]="item.contentHtml"></div>
                  <div class="time-style">{{ item.createdDate }}</div>
                  <div *ngIf="item.hasButton === 1">
                    <button
                      style="margin-right: 10px"
                      *ngIf="item.type === 3"
                      (click)="acceptJoinGroup(item.id, true, null)"
                      nz-button
                      nzSize="small">
                      Đồng ý
                    </button>
                    <button
                      *ngIf="item.type === 3"
                      (click)="acceptJoinGroup(item.id, false, null)"
                      nz-button
                      nzSize="small">
                      Từ chối
                    </button>
                  </div>
                  <div *ngIf="item.hasButton === 0" [innerHtml]="item.actionHtml">
                    <!-- <span>{{item.actionHtml}}</span> -->
                  </div>
                </div>
                <div class="item-status">
                  <div *ngIf="!item.viewed" (click)="readNotifi(item.id)" class="view unViewed"></div>
                  <div *ngIf="item.viewed" class="view viewed"></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="row list-noti">
            <nz-pagination
              [nzPageSize]="10"
              [nzPageIndex]="pageIndex"
              [nzTotal]="total"
              (nzPageIndexChange)="callPager($event)"></nz-pagination>
          </div>
        </nz-tab>
      </nz-tabset>
    </ng-template>
  </app-m-modal>
  <!-- </nz-modal> -->
</div>
