<nz-modal
  [(nzVisible)]="isVisible"
  nzWidth="500px"
  nzTitle="Nhập số thuốc BN đã có ở nhà"
  nzMaskClosable="false"
  (nzOnCancel)="closeAmountExistOfPatient()">
  <div class="d-flex mg-bt-10" *ngFor="let medicine of listMedicationTable">
    <label class="mg-r-10" style="min-width: 30%; max-width: 70%">
      {{ medicine.Title }}
    </label>
    <div style="width: 30%">
      <input
        nz-input
        [(ngModel)]="medicine.ExistingAmount"
        type="number"
        min="0" />
    </div>
  </div>
  <div *nzModalFooter>
    <button
      class="btn-cancel-small"
      nz-button
      (click)="closeAmountExistOfPatient()">
      Hủy
    </button>
    <button class="btn-primary-small" nz-button (click)="saveGeneralInfo()">
      Lưu
    </button>
  </div>
</nz-modal>
