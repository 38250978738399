import { Constant } from './../../share/constants/constant.class';
import { NotificationService } from './../../services/notification.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { PatientRoom } from './../../models/patient-room';
import { DoctorService } from './../../services/doctor.service';
import { ShareService } from './../../services/share.service';
import { Store, select } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppState } from '../app-state/app-state';
import { Observable, Observer, Subscription } from 'rxjs';
import { PatientRoomService } from 'src/app/services/patient-room.service';

@Component({
  selector: 'app-patient-room',
  templateUrl: './patient-room.component.html',
  styleUrls: ['./patient-room.component.scss'],
})
export class PatientRoomComponent implements OnInit, OnDestroy {
  selectedGroupId = '';
  groupSub: Subscription;
  doctorInGroup = [];
  rooms: PatientRoom[] = [];
  search = {
    name: '',
  };
  pageIndex = 1;
  pageSize = 15;
  total = 100;
  loading = false;
  isVisibleAddGroup = false;
  isVisibleViewDoctor = false;
  isRoomManager = false;
  formRoom: FormGroup;
  selectedRoomId = '';
  selectedRoom: any = {};

  constructor(
    private store: Store<AppState>,
    private shareService: ShareService,
    private roomService: PatientRoomService,
    private fbRoom: FormBuilder,
    private notificationService: NotificationService,
    private doctorService: DoctorService
  ) {
    this.formRoom = this.fbRoom.group({
      name: ['', [Validators.required], [this.emptyAsyncValidator]],
    });
  }

  ngOnInit() {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        if (!this.shareService.checkEmpty(data)) {
          this.selectedGroupId = data.groupId;
          //this.isRoomManager = data.doctorPermission.includes(9);
          // this.getDoctorInGroup(this.selectedGroupId);
          this.getRoomInGroup(this.selectedGroupId);
        }
      }
    });
  }

  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;
      }
    });
  }

  getRoomInGroup(groupId) {
    this.roomService.getAllRoomInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.rooms = res;
      }
    });
  }

  getRoom(roomId) {
    this.roomService.getRoom(roomId).subscribe((res) => {
      if (res) {
        this.selectedRoom = res;
        this.formRoom.patchValue({
          name: res.name,
        });
      }
    });
  }

  handleCancelRoom() {
    this.selectedRoomId = '';
    this.formRoom.reset();
    this.isVisibleAddGroup = false;
    this.getRoomInGroup(this.selectedGroupId);
  }

  saveRoom() {
    this.validateFormRoom();
    if (this.formRoom.valid) {
      const payload = {
        ...this.formRoom.value,
        ...{ id: this.selectedRoomId, groupId: this.selectedGroupId },
      };
      this.roomService.createRoom(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Lưu thành công'
          );
          this.handleCancelRoom();
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            res.errors[0].errorMessage
          );
          // this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Tên nhóm đã tồn tại!');
        }
      });
    }
  }

  searchRoom() {
    this.getRoomInGroup(this.selectedGroupId);
  }

  selectRoom(item) {
    this.selectedRoomId = item.id;
  }

  showAddModal(isAdd) {
    if (isAdd) {
      this.selectedRoomId = '';
    } else {
      this.getRoom(this.selectedRoomId);
    }
    this.isVisibleAddGroup = true;
  }

  showViewDoctorModal() {
    this.isVisibleViewDoctor = true;
  }

  closeViewDoctorModal() {
    this.isVisibleViewDoctor = false;
  }

  resetSearch() {
    this.search = {
      name: '',
    };
    this.getRoomInGroup(this.selectedGroupId);
  }

  emptyAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.shareService.checkEmptyStr(control.value)) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, emptyName: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  validateFormRoom() {
    for (const i in this.formRoom.controls) {
      this.formRoom.controls[i].markAsDirty();
      this.formRoom.controls[i].updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    if (this.groupSub) {
      this.groupSub.unsubscribe();
    }
  }
}
