import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ContentChild,
  TemplateRef,
  Output,
  EventEmitter,
  Renderer2,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';

const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2,
}

@Component({
  selector: 'app-m-modal',
  templateUrl: './m-modal.component.html',
  styleUrls: ['./m-modal.component.scss'],
})
export class MModalComponent implements OnInit, AfterViewInit, OnDestroy {
  _isVisible: boolean;
  @Input() modalId = 'draggable';
  @Input() isResiable = true;
  @Input() mMask = false;
  @Input() mFooter = true;
  @Input() width = 700;
  @Input('height')
  get height() {
    return this._height;
  }
  set height(value) {
    const maxHeight = window.innerHeight - 180;
    if (value > maxHeight) {
      this._height = maxHeight;
    } else {
      this._height = 'auto';
    }
  }
  private _height: number | string = 'auto';
  @Input() minWidth = 100;
  @Input() minHeight = 100;
  @Input() set isVisible(val: boolean) {
    this._isVisible = val;
    if (val) {
      const keyDowns = fromEvent(document, 'keydown').pipe(
        filter((e: KeyboardEvent) => e.keyCode === 27),
        distinctUntilChanged()
      );
      this.escSubscription = keyDowns.subscribe((escpress) => {
        if (escpress.type === 'keydown') {
          // Do your thing
          this.handleCancel();
        }
      });
    }
    // if(val){
    //   this.dragAndResizeModal.nativeElement.style.width = this.width + 'px';
    // }
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  public left = 100;
  public top = 100;
  public DELTA = 4;
  public style: any = { left: 'left' };
  escSubscription: Subscription;

  @ViewChild('dragAndResizeModal') public dragAndResizeModal: ElementRef;
  @ContentChild('mModalContent', { static: false })
  modalContent: TemplateRef<any>;
  @ContentChild('mModalFooter', { static: false })
  modalFooter: TemplateRef<any>;
  @ContentChild('mModalTitle', { static: false }) modalTitle: TemplateRef<any>;

  @Output() closeModal = new EventEmitter();
  private boxPosition: { left: number; top: number };
  private lastSize: { width: number; height: number } = { width: 0, height: 0 };
  private containerPos: {
    left: number;
    top: number;
    right: number;
    bottom: number;
  };
  public mouse: { x: number; y: number };
  public status: Status = Status.OFF;
  private mouseClick: { x: number; y: number; left: number; top: number };

  constructor(private elementRef: ElementRef, private render: Renderer2) {}
  ngOnInit() {}

  getModalElement() {
    // return document.querySelector(`#${this.id}`);
    return this.elementRef.nativeElement;
  }

  ngAfterViewInit() {
    const modalElement = this.getModalElement();
    const $this = this;
    this.render.listen(
      modalElement,
      'mousemove',
      function (event) {
        $this.mouse = { x: event.clientX, y: event.clientY };

        if ($this.status === Status.RESIZE) {
          $this.resize();
        }
        // else if(this.status === Status.MOVE) this.move();
      }.bind(this)
    );

    // const left = (window.innerWidth - this.width) / 2;
    // this.style.left = left + 'px';
  }

  private loadBox() {
    const { left, top } = this.dragAndResizeModal.nativeElement.getBoundingClientRect();
    this.boxPosition = { left, top };
  }

  setStatus(event: MouseEvent, status: number) {
    if (status === 1) {
      event.stopPropagation();
    } else if (status === 2) {
      this.mouseClick = {
        x: event.clientX,
        y: event.clientY,
        left: this.left,
        top: this.top,
      };
    }
    this.loadBox();
    this.status = status;
  }

  private resize() {
    const width = Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0;
    const height = Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0;
    const heightMin = height > this.minHeight ? height : this.minHeight;
    const widthMin = width > this.minWidth ? width : this.minWidth;
    if (
      Math.abs(heightMin - this.lastSize.height) > this.DELTA ||
      Math.abs(widthMin - this.lastSize.width) > this.DELTA
    ) {
      this.width = widthMin;
      this.height = heightMin;
      // this.dragAndResizeModal.nativeElement.style.height = this.height + 'px';
      // this.dragAndResizeModal.nativeElement.style.width = this.width + 'px';
      this.lastSize.width = widthMin;
      this.lastSize.height = heightMin;
    }
    // this.width = widthMin;
    // this.height = heightMin;
    // // this.dragAndResizeModal.nativeElement.style.height = this.height + 'px';
    // // this.dragAndResizeModal.nativeElement.style.width = this.width + 'px';
    // this.lastSize.width = widthMin;
    // this.lastSize.height = heightMin;
  }

  // private resizeCondMeet(){
  //   return (this.mouse.x < this.containerPos.right && this.mouse.y < this.containerPos.bottom);
  // }

  // private move(){
  //   if(this.moveCondMeet()){
  //     this.left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
  //     this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
  //   }
  // }

  // private moveCondMeet(){
  //   const offsetLeft = this.mouseClick.x - this.boxPosition.left;
  //   const offsetRight = this.width - offsetLeft;
  //   const offsetTop = this.mouseClick.y - this.boxPosition.top;
  //   const offsetBottom = this.height - offsetTop;
  //   return (
  //     this.mouse.x > this.containerPos.left + offsetLeft &&
  //     this.mouse.x < this.containerPos.right - offsetRight &&
  //     this.mouse.y > this.containerPos.top + offsetTop &&
  //     this.mouse.y < this.containerPos.bottom - offsetBottom
  //     );
  // }

  handleCancel() {
    this.isVisible = false;
    this.closeModal.emit(false);
    if (this.escSubscription) {
      // remove listener
      this.escSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.escSubscription) {
      // remove listener
      this.escSubscription.unsubscribe();
    }
  }
}
