import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AuthModel } from '../../../models/auth.model';
import * as fromRoot from '../../app-state/app-state';
import * as actionAuth from '../redux/auth.action';
import { AuthActionTypes } from '../redux/auth.action';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Constant } from '../../../share/constants/constant.class';
import { AppState } from '../../app-state/app-state';

// interface AuthState extends EntityState<AuthModel> {
//   userId: string | null;
//   lastname: string;
//   timestamp: string;
//   userName: string;
//   userType: number[];
//   isLoading: boolean;
//   isAuthenticated: boolean;
// }
// export interface AppState extends fromRoot.AppState {
//   auth: AuthModel;
// }
export const authAdapter: EntityAdapter<AuthModel> =
  createEntityAdapter<AuthModel>();
export const defaultAuth: AuthModel = {
  userId: null,
  lastname: '',
  timestamp: '',
  userName: '',
  userType: [],
  loaded: false,
  isAuthenticated: false,
  isLock: false,
  value: '',
  roomId: '',
  roomName: '',
};
/// Helper function to create new state object
const newState = (state, newData) => Object.assign({}, state, newData);

// export const initialState = authAdapter.getInitialState(defaultAuth);
export function authReducer(
  state = defaultAuth,
  action: actionAuth.AuthActions
): AuthModel {
  switch (action.type) {
    case AuthActionTypes.LoginSuccess: {
      return newState(state, {
        ...action.payload,
        isAuthenticated: true,
        isLock: false,
        loaded: true,
      });
    }
    case AuthActionTypes.LoginFail: {
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    case AuthActionTypes.LockScreen: {
      return {
        ...state,
        isLock: true,
      };
    }
    case AuthActionTypes.ResetLockScreen: {
      return {
        ...state,
        isLock: false,
        isAuthenticated: true,
      };
    }
    case AuthActionTypes.UnLockScreen: {
      return {
        ...state,
        isLock: false,
      };
    }
    case AuthActionTypes.ClearTime: {
      return {
        ...state,
      };
    }
    case AuthActionTypes.Logout: {
      return {
        ...state,
        lastname: '',
        timestamp: '',
        userName: '',
        userType: [],
        loaded: false,
        isAuthenticated: false,
      };
    }
    case AuthActionTypes.SSOLogout: {
      return {
        ...state,
        lastname: '',
        timestamp: '',
        userName: '',
        userType: [],
        loaded: false,
        isAuthenticated: false,
      };
    }

    case AuthActionTypes.LogoutFail: {
      return {
        ...state,
        lastname: '',
        timestamp: '',
        userName: '',
        userType: [],
        loaded: false,
        isAuthenticated: false,
      };
    }
    case AuthActionTypes.UpdateRoom: {
      return {
        ...state,
        roomId: action.roomId,
        roomName: action.roomName,
      };
    }
    default: {
      return state;
    }
  }
}

export const selectAuthFeature = (state: AppState) => state.auth;
export const getAuth = createSelector(
  selectAuthFeature,
  (auth: AuthModel) => auth
);
