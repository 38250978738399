import { Component, Input, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app-config.service';
import { Visits } from 'src/app/models/listVisit.class';
import { PatientService } from 'src/app/services/patient.service';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { ShareService } from 'src/app/services/share.service';
import { NotificationService } from '../../../services/notification.service';
import { DoctorInGroup } from '../../../models/doctor-in-group.model'; //api
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { DoctorService } from 'src/app/services/doctor.service';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ValidationErrors } from '@angular/forms';
import { Observer } from 'rxjs';
import { Observable } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-patient-info-table',
  templateUrl: './patient-info-table.component.html',
  styleUrls: ['./patient-info-table.component.scss'],
})
export class PatientInfoTableComponent implements OnInit {
  @Input() visitId = '';
  @Input() patient: any;
  @Input() visit: Visits;
  @Input() patientId: any;
  @Input() enableEditPatientInfo = false;
  //@Input() isEdit: boolean=false;
  listPatientImage: Array<string>;
  medical: string;
  vaccine: string;
  //ageString: string;
  baseAvatarurl: string;
  mainDoctor: any = {};
  presenterDoctor: any = {};
  formAddPatient: FormGroup;
  openMore = false;
  isVisible = false;
  profileStatus = 'Gửi hồ sơ cho BN';
  curUserId = '';
  initialValuePatient: any;
  doctorInGroup: DoctorInGroup[] = [];
  submitted = false;
  formAddVisit: FormGroup;
  groupSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private appConfigService: AppConfigService,
    private tabDataService: TabDataService,
    private patientService: PatientService,
    private notificationService: NotificationService,
    private shareService: ShareService,
    private store: Store<AppState>,
    private doctorService: DoctorService,
    private modalService: NzModalService
  ) {
    //   this.formAddPatient = this.formBuilder.group({
    //    phoneNo: [null,[Validators.required, Validators.pattern(/\b\d{10}\b|\b\d{11}\b/)],],
    // })
    try {
      this.store
        .select((store) => store.auth.userId)
        .subscribe((res) => {
          this.curUserId = res;
        });
    } catch (error) {
      console.log(error);
    }

    this.patient = this.formBuilder.group({
      doctorId: [this.curUserId, [Validators.required]],
      reason: ['', [Validators.required], [this.emptyAsyncValidator]],
    });
  }
  async ngOnInit() {
    this.baseAvatarurl =
      this.appConfigService.getConfig().media.baseUrl + '/Avatar/';
    await this.patientService.getPatient(this.patientId).subscribe((res) => {
      if (res !== null && res.errors.length === 0) {
        this.patient = res.jsonData;
        this.medical = this.patient.medicalHistory;
        this.vaccine = this.patient.vaccineHistory;
        this.listPatientImage = this.patient.additionImages;
      }
      this.mainDoctor.id = this.patient.mainDoctorId;
      this.mainDoctor.fullName = this.patient.mainDoctorName;
      this.presenterDoctor.id = this.patient.presenterDoctorId;
      this.presenterDoctor.fullName = this.patient.presenterDoctorName;
    });
    if (this.patient && this.patient.dob) {
      //this.ageString = this.shareService.calcAgeString(this.patient.dob);
    }
    const p = this.patient;
    switch (p.activeStatus) {
      case 2:
        this.profileStatus = 'Đã gửi thông tin cho BN';
        if (p.timeActiveRemaining == 'hết hạn') {
          this.profileStatus = 'Gửi lại thông tin cho BN';
        }
        break;
      case 0:
        this.profileStatus = 'Đã active';
        break;
    }

    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.getDoctorInGroup(data.groupId);
      }
    });
  }

  emptyAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.checkNull(control.value)) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, duplicated: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  checkNull(value) {
    try {
      value = value.trim();
      if (value == null || value == '' || value == 'undefined') {
        return true;
      }
      return false;
    } catch (e) {
      return true;
    }
  }

  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;
      }
    });
  }

  shortName(name): any {
    return this.getInitials(name, ' ');
  }

  getInitials(name, delimeter): string {
    if (name) {
      const array = name.split(delimeter);
      switch (array.length) {
        case 1:
          return array[0].charAt(0).toUpperCase();
          break;
        default:
          return (
            array[0].charAt(0).toUpperCase() +
            array[array.length - 1].charAt(0).toUpperCase()
          );
      }
    }
    return '';
  }

  moreInfo() {
    this.openMore = !this.openMore;
  }

  getStatus(s) {
    this.profileStatus = s;
    this.isVisible = false;
  }

  changeEmail(email) {
    this.patient.email = email;
  }

  showModal() {
    const p = this.patient;
    // if (p.activeStatus == -1 || p.timeActiveRemaining == "hết hạn") {
    //   this.isVisible = true;
    // }
    if (this.patient.activeStatus == 0) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.WARRING,
        'BN đã nhận được email và kích hoạt tài khoản. Bạn không cần gửi lại nữa!'
      );
    } else if (this.patient.activeStatus == 2) {
      this.modalService.confirm({
        nzTitle: 'Thông báo',
        nzContent: 'Bạn đã gửi thông tin cho BN, bạn có muốn gửi lại không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Bỏ qua',
        nzOnOk: () => (this.isVisible = true),
      });
    } else {
      this.isVisible = true;
    }
  }
  closeProflie() {
    this.isVisible = false;
  }

  showTabPatientInfo(id, PatientName): void {
    this.tabDataService.updateTab(id, PatientName, 'PatientInfo', '');
  }

  editPatientInfo() {
    this.enableEditPatientInfo = true;
  }

  cancelEditPatientInfo() {
    this.enableEditPatientInfo = false;
  }

  get f() {
    return this.formAddVisit.controls;
  }

  saveEditPatientInfo() {
    //this.ageString = this.shareService.calcAgeString(this.patient.dob);
    const request = { note: this.patient, patientId: this.patientId };
    const payload = {
      id: this.patient.id,
      fullName: this.patient.fullName,
      idNumber: this.patient.idNumber,
      dob: this.patient.dob,
      dobString: this.shareService.toDDMMYY(new Date(this.patient.dob)),
      sex: this.patient.sex,
      phoneNo: this.patient.phoneNo,
      mainDoctor: this.mainDoctor.id,
      presenterDoctor: this.presenterDoctor.id,
      email: this.patient.email,
      address: this.patient.address,
    };
    this.patientService.updatePatient(payload).subscribe((res) => {
      if (res.isValid) {
        this.enableEditPatientInfo = false;
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Cập nhật thành công'
        );
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }
}
