import { ShareService } from 'src/app/services/share.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'filterMessageName',
})
export class FilterMessageNamePipe implements PipeTransform {
  //pipe search by 'toUserName'
  constructor(
    protected sanitizer: DomSanitizer,
    private shareServiceL: ShareService
  ) {}
  transform(items: any, searchText: string) {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = this.shareServiceL.toUnsign(searchText).toUpperCase();
    return items.filter((it) => it.unsignName.includes(searchText));
  }
}
