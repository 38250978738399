import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrescriptionDetailComponent } from './prescription-detail/prescription-detail.component';
import { PrescriptionComponent } from './prescription-tab/prescription-tab.component';
import { SharedModule } from 'src/app/share/share.module';
import { PrescriptionSampleComponent } from './prescription-sample/prescription-sample.component';
import { PrintTemplateComponent } from './print-template/print-template.component';
import { SharePrescriptionComponent } from './share-prescription/share-prescription.component';
import { ModalCreateSampleComponent } from './modal-create-sample/modal-create-sample.component';
import { ModalQueryAprrovingComponent } from './modal-query-aprroving/modal-query-aprroving.component';
import { ModalEditAmountComponent } from './modal-edit-amount/modal-edit-amount.component';
import { DeliveryInfoComponent } from './delivery-info/delivery-info.component';
import { PrescriptionListComponent } from './prescription-list/prescription-list.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    PrescriptionDetailComponent,
    PrescriptionSampleComponent,
    PrescriptionComponent,
    PrintTemplateComponent,
    SharePrescriptionComponent,
    ModalCreateSampleComponent,
    ModalQueryAprrovingComponent,
    ModalEditAmountComponent,
    DeliveryInfoComponent,
    PrescriptionListComponent,
  ],
  exports: [
    PrescriptionDetailComponent,
    PrescriptionSampleComponent,
    PrescriptionComponent,
    PrintTemplateComponent,
    SharePrescriptionComponent,
  ],
})
export class PrescriptionModule {}
