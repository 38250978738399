import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'dayViewPipe',
  pure: true,
})
export class DayViewPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  transform(arr: any, from, to): any {
    if (!from || !to) return;
    let isoTimeFr = from.toISOString();
    let isoTimeTo = to.toISOString();

    let result = arr.filter((el) => {
      let date = new Date(el.visitDate).toISOString();
      return date >= isoTimeFr && date < isoTimeTo;
    });

    let html = '';
    if (result.length > 0) {
      result.forEach((el) => {
        html += `<div style="color:#007bff;max-width: 180px;
        width: 200px;
        background: #ffecb4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;;cursor: pointer;border: 1px solid #c5baba; margin-top:10px; white-space: nowrap;   border-radius: 10px;
        padding: 5px;
        margin-right: 10px;" class="visit-item"  data-visit='${JSON.stringify(el)}' >
        <div style="margin-left: 4px;">
          ${new DatePipe('en-Us').transform(el.visitDate, 'HH:mm')}
          ${el.patientName}
        </div>
        </div>`;
      });
    }

    return this.sanitized.bypassSecurityTrustHtml(html);
  }
}
