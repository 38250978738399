import { ShareService } from './../../../services/share.service';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
  HostListener,
  ElementRef,
  TemplateRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { Constant } from '../../../share/constants/constant.class';
import { Router, ActivatedRoute } from '@angular/router';
import { Menu } from '../../../models/menu.class';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/auth.service';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
declare let $: any;
import { AppConfigService } from '../../../../app-config.service';
import { GroupTreeService } from '../../../services/groupTree.service';
import { MenuItemTree } from 'src/app/models/grouptree.model';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { Patient } from '../../../models/patient.class';
import { AppState } from '../../app-state/app-state';
import { OpenDuplicatePatient } from '../../modal/redux/modal/modal.action';
import { InviteMemberGroupComponent } from '../../modal/invite-member-group/invite-member-group.component';
import { MessageService } from 'src/app/services/message.service';
import { VisitDetailComponent } from '../../worklist/visit-detail/visit-detail.component';
import { ListAppointmentComponent } from '../../worklist/list-appointment/list-appointment.component';
import { GroupMemberService } from 'src/app/services/group-member.service';
import { AuthActionTypes, Logout, ResetLockScreen, SSOLogout } from '../../auth/redux/auth.action';
import { ConfigGroupComponent } from '../../modal/config-group-module/config-group/config-group.component';
import { Join } from '../../auth/redux/group-auth.action';
import { VideoConferenceService } from '../../../services/video-conference.service';
import { UserService } from 'src/app/services/user-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationsComponent } from '../../modal/notifications/notifications.component';
import firebase from 'firebase';
import { ChatService } from '../../../services/chat.service';
import { ConferenceRoomComponent } from '../../modal/conference-room/conference-room.component';
import { ListVisitComponent } from '../../worklist/list-visit/list-visit.component';
import { ListReminderService } from 'src/app/services/ListReminder.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReloadCancelOrder } from '../../app-state/control-state/control.action';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RulesManagermentService } from 'src/app/services/rules-managerment.service';

@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html',
  styleUrls: ['./common-layout.component.scss'],
  encapsulation: ViewEncapsulation.None, // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GroupTreeService, TabDataService, NzDropDownModule],
})
export class CommonLayoutComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  static readonly ROUTE_DATA_PAGENAME = 'pagename';

  @ViewChild(ListVisitComponent) visitComponent;
  @ViewChild(ListAppointmentComponent) appointment;
  @ViewChild(InviteMemberGroupComponent)
  inviteMember: InviteMemberGroupComponent;
  @ViewChild(ConfigGroupComponent) configGroup: ConfigGroupComponent;
  @ViewChild(ConferenceRoomComponent) CreateVideoCall: ConferenceRoomComponent;

  @ViewChild(VisitDetailComponent) visitDetail: VisitDetailComponent;
  @ViewChild(NotificationsComponent) notiComponent: NotificationsComponent;
  @Input() isAdminGroup: any;

  //Config có phải là phòng khám hay k
  // isClinic = true;
  listVisitComponentName = 'ListVisit';

  isCollapsed = false;
  reload = false;
  username = 'User';
  sub: Subscription;
  menus: Menu[] = [];
  selectedMenu: Menu;
  pageName: string;
  tabs: Array<any> = [];

  isShowDDLGroup = false;
  isShowMemberRoom: boolean;
  isShowAddTime: boolean;
  timeSelectOption = 15;
  timeOptions: any[] = [
    { key: 15, value: 15 },
    { key: 30, value: 30 },
    { key: 60, value: 60 },
  ];
  timeUnitOption = 'minute';
  public indexTab = 0;
  listDuplicatePatient: [];
  patient: Patient = {};
  sendEditedPatient: Patient;
  list: Array<MenuItemTree> = [];
  listMenuTemp: Array<MenuItemTree> = []; //Lưu để dùng khi reset search
  selectedGroupId = '000000000000000000000000';
  selectedGroupName = 'Chọn nhóm làm việc';
  selectedGroupAvatar = '';
  //newGroupId: string = '000000000000000000000000';
  isVisibleAddGroup = false;
  //isVisibleSettingGroup: boolean = false;
  // isAdminGroup = false;
  isVisibleManagerGroup = false;
  isVisibleAddMemberGroup = false;
  windowWidth: number;
  currentTabId: string;
  myIsAdminGroup = false;
  isVisibleMemberRoom = false;
  @Input() updateListVisit = this.selectedGroupId;
  @ViewChild('mainContent') mainContent: ElementRef;
  @ViewChild('customHtml') customHtml: TemplateRef<any>;
  // @ViewChild('cdsRuleTemplate') cdsRuleTemplate: TemplateRef<any>;
  @ViewChild('reminderTemplate') reminderTemplate: TemplateRef<any>;
  windowHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  message: any;
  titleMessage: any;
  bodyMessage: any;
  typeMessage: any;
  msg: any = {
    data: {
      type: 'DoctorReminder',
      info: '619c84a901180b300f5ede41',
    },
    notification: {
      title: 'Nhập phiếu theo dõi covid',
      subTitle: 'Thời gian: 20/01/2022 - 17:24 - BN: COVID 1',
      body: 'Nhập phiếu theo dõi covid',
    },
  };
  reminderId = '';
  numOfNewNotifi = 0;
  numOfUnreadNotifi = 0;
  isVisibleNotifications = false;
  showAllNotifications = false;
  listNotification: any;
  PathAvatar: string = this.configService.getConfig().media.baseUrl;
  groupSub: Subscription;
  isCovidGroup = false;
  isUseListPatient = false;
  isUseReminder = false;
  isUseMedicalOrder = false;
  isUseWaitingPage = false;
  isUseChat = false;
  isUseVideoCall = false;
  isUseGroupStatistic = false;
  isUsePatientGroup = false;
  isUserUpdateIdCategory = false;
  isUserUpdateForm = false;
  isUserUpdateMedicine = false;
  isUseTreatmentProcess = false;
  isUseRulesManagerment = false;
  isUseDividePatientRoom = false;
  listPatientIsDefaultTab = false;
  isSystemAdmin = false;
  firebaseInstance: any;
  source$: Observable<Event>;
  lastToken = '';
  curToken = '';
  curLock = '1';
  isMobile: boolean;
  isVisibleAboutPmr = false;
  isChangeGroup = false;
  reloadListVisit = false;
  isUserCoordinator = false;
  isUserVolunteer = false;
  userCanViewStatistic = false;
  timeForm!: FormGroup;
  oldData: any = {};
  curUserId = '';
  unReadHtml: any;
  errorsMessage = [];
  isVisibleSettingGroup: boolean;
  broadcast = new BroadcastChannel(Constant.BROADCAST_CHANNEL.auth.value);
  urlHis = '';
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    if (this.windowWidth <= 1200) {
      this.isCollapsed = true;
    }
    this.setSidebarWidth(this.isCollapsed);
  }
  constructor(
    private userService: UserService,
    private configService: AppConfigService,
    private route: Router,
    private activeRoute: ActivatedRoute,
    private store: Store<AppState>,
    private authService: AuthService,
    private translate: TranslateService,
    private groupTreeService: GroupTreeService,
    private tabDataService: TabDataService,
    private cdr: ChangeDetectorRef,
    public messagingService: MessageService,
    private groupMemberService: GroupMemberService,
    private notification: NzNotificationService,
    private videoConferenceService: VideoConferenceService,
    private notificationService: NotificationService,
    private shareService: ShareService,
    private chatService: ChatService,
    private reminderService: ListReminderService,
    private fb: FormBuilder,
    private modalService: NzModalService,
    private rulesManagermentService: RulesManagermentService,
    private router: ActivatedRoute
  ) {
    this.tabDataService.currentTabId.subscribe((id) => {
      this.currentTabId = id;
    });

    this.timeForm = this.fb.group({
      time: [15, [Validators.required]],
      unit: ['minute', [Validators.required]],
    });

    // if(this.isClinic){
    //   // this.listVisitComponentName = 'ListVisitContainer';
    // }
    // this.isClinic = this.configService.getConfig().isClinic;
    // console.log(this.isClinic);
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
    this.store
      .select((store) => store.auth.userName)
      .subscribe((res) => {
        this.username = res;
      });
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    this.isMobile = window.innerWidth < 540;
    if (this.isMobile) {
      location.href = '/common-mobile/worklist/group-dashboard';
    }
    // this.showPage();
    this.pageName = this.translate.instant(this.getPageInfo());
    this.getTreeMenu();

    this.getCurrentGroupFromDB();
    //update info if group is edited in page group-dashboard or tree-manager-group
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        const oldData = this.oldData;
        this.oldData = Object.assign({}, data);
        const isCovidGroupOld = this.isCovidGroup;
        this.isUseListPatient = data.isUseListPatient;
        this.isUseReminder = data.isUseReminder;
        this.isUseMedicalOrder = data.isUseMedicalOrder;
        this.isUseWaitingPage = data.isUseWaitingPage;
        this.isUseChat = data.isUseChat;
        this.isUseVideoCall = data.isUseVideoCall;
        this.isUseGroupStatistic = data.isUseGroupStatistic;
        this.isUsePatientGroup = data.isUsePatientGroup;
        this.isUserUpdateIdCategory = data.isUserUpdateIdCategory;
        this.isUserUpdateForm = data.isUserUpdateForm;
        this.isUserUpdateMedicine = data.isUserUpdateMedicine;
        this.isUseTreatmentProcess = data.isUseTreatmentProcess;
        this.isUseRulesManagerment = data.isUseRulesManagerment;
        this.isUseDividePatientRoom = data.isUseDividePatientRoom;
        this.selectedGroupId = data.groupId;
        this.selectedGroupName = data.groupName;
        this.selectedGroupAvatar = data.groupAvatar;
        this.isCovidGroup = data.isCovidGroup;
        this.isAdminGroup = data.role == Constant.GROUPROLE.Admin;
        // this.isAdminGroup = data.role === 1 || data.role === 2;

        if (data.doctorPermission) {
          this.isUserCoordinator = data.doctorPermission.includes(Constant.DOCTORPERMISSION.Coordinator);
          this.isUserVolunteer = data.doctorPermission.includes(Constant.DOCTORPERMISSION.Volunteer);
        }
        const newData = Object.assign({}, data);
        if (this.isChangeGroup) {
          if (data.aliasName) {
            newData.aliasName = null;
          }
          if (data.isSaveConfigGroup) {
            newData.isSaveConfigGroup = false;
          }
          this.store.dispatch(new Join(newData));
        }
        if (newData.isSaveConfigGroup) {
          this.openNewTab('dashboard', 'GroupDashboard', 'GroupDashboard');
          this.reloadTabById('dashboard');
          this.reloadTabById('ListPatient');
          this.selectedGroupName = data.aliasName;
          this.getTreeMenu();
        }
        this.listPatientIsDefaultTab = data.isUseListPatient && data.defaultUseListPatient;
        if (
          isCovidGroupOld != data.isCovidGroup ||
          this.isChangeGroup ||
          oldData.isUseListPatient != newData.isUseListPatient ||
          oldData.defaultUseListPatient != newData.defaultUseListPatient
        ) {
          this.isChangeGroup = false;
          if (this.isCovidGroup || (data.isUseListPatient && data.defaultUseListPatient)) {
            this.tabDataService.reInitTab('ListPatient', 'Danh sách bệnh nhân');
          } else {
            this.tabDataService.reInitTab(this.listVisitComponentName, 'Danh sách ca khám');
          }
        }
      }
    });

    this.countNewNotifi();
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.messagingService.messageSource.subscribe((mess) => {
      this.msg = mess;
      if (this.msg.notification !== undefined) {
        let duration = 25000;
        const type = this.msg.data.type;
        console.log('msg0', this.msg);
        if (type !== 'DoctorReminder') {
          if (type == 'CancelOrder') {
            //dispatch reload tab
            this.store.dispatch(new ReloadCancelOrder(true, ''));
            setTimeout(() => {
              this.store.dispatch(new ReloadCancelOrder(false, ''));
            }, 50);
          }
          if (type === 'InviteRoom') {
            duration = 0;
          }

          if (type === 'SubclinicalVisitWarning' || type === 'CancelOrder') {
            console.log('msg', this.msg);
            this.errorsMessage.push({
              id: this.msg.data.info,
              title: this.msg.notification.title,
              body: this.msg.notification.body,
            });
            this.warning(this.errorsMessage[0]);
          } else if (type === 'CDSRule') {
            console.log('msg', this.msg);
            this.errorsMessage.push({
              id: this.msg.data.info,
              title: this.msg.notification.title,
              body: this.msg.notification.body,
            });
            this.warningCDSRule(this.errorsMessage[0]);
          } else {
            this.notification.remove('');
            this.notification.template(this.customHtml, {
              nzDuration: duration,
              nzPlacement: 'bottomRight',
            });
          }
        }
        // else if (type === 'CDSRule') {
        //   duration = 0;warningCDSRule
        //   //this.reminderId = this.msg.data.info;
        //   this.notification.remove('');
        //   this.notification.template(this.cdsRuleTemplate, {
        //     nzDuration: duration,
        //     nzPlacement: 'bottomRight',
        //   });
        //}
        else {
          duration = 0;
          this.reminderId = this.msg.data.info;
          this.notification.remove('');
          this.notification.template(this.reminderTemplate, {
            nzDuration: duration,
            nzPlacement: 'bottomRight',
          });
        }

        this.updateNewNotifi(true);
      }
    });

    //Check nếu là userGuest thì remove token + bắt login
    if (this.username === 'shareable') {
      localStorage.removeItem(Constant.TOKEN);
      localStorage.removeItem(Constant.FIREBASE_TOKEN);
      localStorage.removeItem(Constant.USER_INFO);
      this.route.navigate(['/login']);
    }

    this.getTabs();

    //this.username = userInfo.userName;
    if (!this.shareService.checkEmpty(this.curUserId)) {
      setTimeout(() => {
        this.firebaseInstance = firebase.database().ref('notify-count/' + this.curUserId);
        this.firebaseInstance.on('child_changed', (resp2: any) => {
          const item = resp2.val();
          this.numOfNewNotifi = item;
        });
        this.firebaseInstance.on('child_added', (resp2: any) => {
          const item = resp2.val();
          this.numOfNewNotifi = item;
        });
      }, 2000);
    }
    this.lastToken = localStorage.getItem(Constant.TOKEN);
    this.store.select('auth').subscribe((res) => {
      if (res) {
        this.lastToken = res.value;
        this.isSystemAdmin =
          res.userType.includes(Constant.USERROLE.SystemAdmin) || res.userType.includes(Constant.USERROLE.Admin);
        this.userCanViewStatistic = res.userType.includes(Constant.USERROLE.ViewStatistic);
      } else {
        this.lastToken = '';
      }
    });
    this.urlHis = this.configService.getConfig().pmrHis;
    // this.groupMemberService.getMyGroupMember(this.selectedGroupId).subscribe(
    //   (res) => {
    //     if (res !== null) {
    //       if (res.isValid) {
    //         this.isOwner=res.jsonData.isOwner;
    //         console.log(this.isOwner);

    //       }
    //     }
    //   })
    // this.source$ = fromEvent(window, 'storage');
    // this.source$.pipe(delay(500)).subscribe((ldata) => {
    //   if (!localStorage.getItem(Constant.LOCKSTATE)) {
    //     localStorage.setItem(Constant.LOCKSTATE, '1');
    //     return;
    //   }

    //   this.curLock = localStorage.getItem(Constant.LOCKSTATE)
    //     ? localStorage.getItem(Constant.LOCKSTATE)
    //     : '1';
    //   // console.log('this.curLock', this.curLock);
    //   if (this.curLock == '-1') {
    //     this.store.dispatch(new LockScreen());
    //   } else {
    //     this.store.dispatch(new ResetLockScreen());
    //     this.curToken = localStorage.getItem(Constant.TOKEN)
    //       ? localStorage.getItem(Constant.TOKEN)
    //       : '';
    //     if (this.lastToken != this.curToken) {
    //       window.location.reload();
    //     } else {
    //       // console.log('Nothing added yet');
    //     }
    //   }
    // });

    // localStorage.setItem(Constant.LOCKSTATE, '1');
    // this.store.dispatch(new ResetLockScreen());
  }

  // testNotify(){
  //   if (this.msg.notification !== undefined) {
  //     let duration = 25000;
  //     if(this.msg.data.type !== 'DoctorReminder'){
  //       if (this.msg.data.type === 'InviteRoom') duration = 0;
  //       this.notification.remove('');
  //       this.notification.template(this.customHtml, {
  //         nzDuration: duration,
  //         nzPlacement: 'bottomRight',
  //       });
  //     }else{
  //       duration = 0;
  //       this.reminderId = this.msg.data.info;
  //       this.notification.remove('');
  //       this.notification.template(this.reminderTemplate, {
  //         nzDuration: duration,
  //         nzPlacement: 'bottomRight'
  //       });
  //     }

  //     this.updateNewNotifi(true);
  //   }
  // }

  updateNewNotifi(msg) {
    if (msg != null) {
      this.numOfNewNotifi = this.numOfNewNotifi + 1;
    }
    //return this.numOfNewNotifi;
  }
  countNewNotifi() {
    this.userService.countNewNotifi().subscribe((res) => {
      if (res !== null) {
        if (res.isValid) {
          this.numOfNewNotifi = parseInt(res.jsonData);
        }
      }
    });
  }
  getTreeMenu() {
    this.groupTreeService.resolveIems(false).subscribe(
      (res) => {
        if (res !== null) {
          this.isShowDDLGroup = false;
          const list = res;
          for (let i = 0; i < list.length; i++) {
            list[i].name = res[i].text;
            list[i].expanded = false;
            if (list[i].children != null && list[i].children.length > 0) {
              list[i].children = this.recuisiveList(list[i].children);
            }
            if (list[i].isLeaf == true) {
              if (list[i].icon == '' || list[i].icon == null) {
                list[i].icon = 'assets/image/group.svg';
              } // Add "total": 2 to all objects in array
            } else {
              list[i].icon = 'assets/image/folder.svg';
            } // Add "total": 2 to all objects in array
          }
          this.list = list;
          this.listMenuTemp = list;
        }
      },
      (error) => {
        console.log('getTreeMenu err', error);
      }
    );
  }

  getFirstItem() {
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].isLeaf == true) {
        return this.list[i];
      }
    }
  }

  getCurrentGroupFromDB() {
    this.groupTreeService.getCurrentGroup().subscribe((res) => {
      if (res != null) {
        this.changeSelectedGroupName(res);
      } else {
        const firstGroup = this.getFirstItem();
        if (firstGroup != null) {
          const item = {
            groupId: firstGroup.id,
            groupAvatar: firstGroup.icon,
            groupName: firstGroup.name,
          };
          this.changeSelectedGroupName(item);
        }
      }
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isCollapsed = true;
    }, 100);
    const $this = this;

    $('.ant-tabs-top > .ant-tabs-nav').css({ margin: '0px', border: 'none' });
    $(document).mouseup(function (e) {
      if (
        $(e.target).closest('.menu-doctor-group').length === 0 &&
        $(e.target).closest('.ant-menu-submenu-popup').length === 0
      ) {
        //
        $('.menu-doctor-group').hide();
        $this.list = $this.listMenuTemp;
      }
    });
    window.onclick = function (event) {
      //console.log('trong',mouse_is_inside);
      if (
        document.getElementsByClassName('dropdown-content')[0] &&
        !document.getElementsByClassName('dropdown-content')[0].contains(event.target) &&
        !document.getElementsByClassName('dropdown')[0].contains(event.target)
      ) {
        $('#myDropdown').hide(300);
      }
    };
    $('.group-name').click(function (event) {
      // stops the bubbling of an event to parent elements, preventing any parent event handlers from being executed.
      event.stopPropagation();
      $this.isShowDDLGroup = true;
    });

    // this.testNotify();
  }

  clickPage() {
    if (localStorage.getItem(Constant.LOCKSTATE) == '1') {
      localStorage.setItem(Constant.LOCKSTATE, '2');
    } else {
      localStorage.setItem(Constant.LOCKSTATE, '1');
    }
    this.store.dispatch(new ResetLockScreen());
  }

  recuisiveList(lst) {
    lst.forEach((res) => {
      res.name = res.text;
      res.expanded = false;
      if (res.children != null && res.children.length > 0) {
        res.children = this.recuisiveList(res.children);
      }
      if (res.isLeaf == true) {
        if (res.icon == '' || res.icon == null) {
          res.icon = 'assets/image/group.svg';
        } // Add "total": 2 to all objects in array
      } else {
        res.icon = 'assets/image/folder.svg';
      }
    });
    return lst;
  }

  toggleMenu(menu: string) {
    $(menu).slideToggle(300);
  }

  logout() {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isSaveConfigGroup) {
        const newData = Object.assign({}, data);
        if (data.aliasName) {
          newData.aliasName = null;
        }
        newData.isSaveConfigGroup = false;
        this.store.dispatch(new Join(newData));
      }
      this.broadcast.postMessage({ action: Constant.BROADCAST_CHANNEL.auth.action.logout.value });
    });
    if (this.configService.getConfig().isUseSSOLogin) {
      const returnURL = window.location.origin + '/sso-login';
      this.store.dispatch(new SSOLogout(returnURL));
      this.broadcast.postMessage({ action: Constant.BROADCAST_CHANNEL.auth.action.logout.value });
    } else {
      this.sub = this.authService.logout({ returnUrl: '' }).subscribe((res) => {
        this.store.dispatch(new Logout(''));
        //this.route.navigate(['/login']);
        this.broadcast.postMessage({ action: Constant.BROADCAST_CHANNEL.auth.action.logout.value });
      });
    }
  }

  openUserProfile() {
    this.route.navigate(['/user-profile']);
  }
  private getPageInfo() {
    let child = this.activeRoute.firstChild;
    while (child.firstChild) {
      child = child.firstChild;
    }
    if (child.snapshot.data[CommonLayoutComponent.ROUTE_DATA_PAGENAME]) {
      return child.snapshot.data[CommonLayoutComponent.ROUTE_DATA_PAGENAME];
    }
    return '';
  }

  getTabs() {
    this.tabDataService.share.subscribe((tabss) => {
      this.tabs = this.tabDataService.tabs();
      this.indexTab = this.tabDataService.indextab;
      this.cdr.detectChanges();
    });
  }
  closeTab(tab: any): void {
    this.tabDataService.closeTab(tab);
  }
  reloadTab(tab: any): void {
    tab.reload = true;
    setTimeout(() => {
      tab.reload = false;
    }, 200);
  }
  reloadTabById(id: any): void {
    const nTabs = this.tabs.length;
    for (let i = 0; i < nTabs; ++i) {
      if (this.tabs[i].id == id) {
        this.reloadTab(this.tabs[i]);
        break;
      }
    }
  }
  openNewTab(id: string, tab: string, type: string, otherInfo?: string) {
    this.tabDataService.updateTab(id, tab, type, otherInfo ?? '');
  }
  openListVist(id: string) {
    this.tabDataService.changeTab(id);
    this.indexTab = 0;
  }
  showTreeGroup() {
    this.isShowDDLGroup = !this.isShowDDLGroup;

    this.groupMemberService.getMyGroupMember(this.selectedGroupId).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.myIsAdminGroup = res.jsonData.isAdmin || res.jsonData.isOwner;
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  hidemenu() {
    if (this.isCollapsed) {
      $('.mytree').hide();
    }
  }
  showmenu() {
    if (this.isCollapsed) {
      // this.getUnReadMessage();
      $('.mytree').show();
    }
  }

  getUnReadMessage() {
    this.unReadHtml = '';
    this.chatService.getUnReadMessage().subscribe((res) => {
      let unReadCount = res.jsonData;
      if (+unReadCount > 0) {
        if (+unReadCount > 5) {
          unReadCount = '5+';
        }
        this.unReadHtml = `${unReadCount}`;
      }
    });
  }

  selectGroup(obj) {
    this.isShowDDLGroup = false;
    this.isChangeGroup = true;
    if (this.selectedGroupId != obj.id) {
      this.groupMemberService.setCurrentGroup(obj.id).subscribe((res) => {
        this.changeSelectedGroupName({
          groupId: obj.id,
          groupName: obj.name,
          groupAvatar: obj.icon,
          isCovidGroup: res.jsonData.isCovidGroup,
          isAutoAddTreatmentPatient: res.jsonData.isAutoAddTreatmentPatient,
          isLoadByPermission: res.jsonData.isLoadByPermission,
          is: res.jsonData.isa,
          role: res.jsonData.role != undefined ? res.jsonData.role : 3,
          isAuthenticated: true,
          doctorPermission: res.jsonData.doctorPermission,
          isCreatePatientAccountQuickly: res.jsonData.isCreatePatientAccountQuickly,
        });
      });
    }
  }
  // changeSelectedGroupName(_id, _groupName, _groupAvatar)
  changeSelectedGroupName(groupObj) {
    // console.log('changeSelectedGroupName ', groupObj);
    this.selectedGroupId = groupObj.groupId; //_id;
    this.selectedGroupName = groupObj.groupName; //_groupName;
    this.selectedGroupAvatar = groupObj.groupAvatar; //_groupAvatar;
    let groupInfo;
    if (!this.shareService.checkEmpty(this.selectedGroupId)) {
      this.groupTreeService.getGroupInfo(this.selectedGroupId).subscribe(
        (data) => {
          if (data !== null) {
            groupInfo = { ...groupObj, ...data };
            this.store.dispatch(new Join(groupInfo)); //_id
            // mở tab mới từ query param
            this.activeRoute.queryParams.subscribe((params) => {
              if (params.id && params.name && params.type) {
                this.openNewTab(params.id, params.name, params.type, params.other);
              }
            });
          }
        },
        (error) => {
          console.log(error);
          this.notificationService.showNotification('', 'Có lỗi xảy ra');
        }
      );
    }
    // console.log("call this.store.dispatch(new Join.. here in changeSelectedGroupName, id: " + groupObj.id);
  }
  hoverParent(parentId) {
    alert('hell');
  }

  emptyFunction(): void {}

  addPatientEvent(value): void {
    this.patient = value;
  }
  addGroupEvent(newMenu): void {
    //this.newGroupId = newMenu.id;
    //this.isVisibleAddMemberGroup = true;
    //(this.inviteMember).callService(1,'',this.newGroupId);
    this.list.push(newMenu);
    this.listMenuTemp = this.list;
    this.getTreeMenu();
  }
  // settingGroupEvent(urlAva): void {
  //    this.isVisibleSettingGroup = false;
  //    this.changeSelectedGroupName('', urlAva);
  //   // this.newGroupId = newMenu.id;
  //   // this.isVisibleAddMemberGroup = true;
  //   // (this.inviteMember).callService(1,'',this.newGroupId);
  //   // this.list.push(newMenu);
  // }
  emitEditTreeGroupEvent(): void {
    this.getTreeMenu();
  }
  // openInviteMember(){
  //   this.newGroupId = this.selectedGroupId;
  //   this.isVisibleAddMemberGroup=true;
  //   (this.inviteMember).callService((this.inviteMember).cursltValue,(this.inviteMember).curKeyword,this.newGroupId);
  //   (this.inviteMember).getGroupMemeber(this.newGroupId );
  // }

  duplicatePatientEvent(data): void {
    if (data.res.isSuccess) {
    } else {
      this.store.dispatch(new OpenDuplicatePatient());
      this.listDuplicatePatient = data.res.duplicatePatients;
      this.sendEditedPatient = data.form;
    }
  }

  selectPatientEvent(value): void {
    this.patient = value;
  }
  openModelAddGroup() {
    // console.log('openModelAddGroup');
    this.isVisibleAddGroup = true;
    this.isShowDDLGroup = false;
  }
  openModelManagerGroup() {
    this.isVisibleManagerGroup = true;
    this.isShowDDLGroup = false;
  }

  closeAddGroup(isRefresh) {
    this.isVisibleAddGroup = false;
  }
  // Mở tạo phòng họp/ trao đổi
  openModelMemberRoom() {
    this.isVisibleMemberRoom = true;
    this.isShowMemberRoom = false;
    this.CreateVideoCall.initPopup();
  }

  // Đóng phòng họp/ trao đổi
  closeModalRoom() {
    this.isVisibleMemberRoom = false;
    // console.log("currentMessage-", this.currentMessage);
  }
  closeManagerGroup(isRefresh) {
    //reset lại tên: vì có thể sửa tên trong pop mamager group
    ///localStorage.removeItem(Constant.CURRENT_GROUPNAME);

    this.isVisibleManagerGroup = false;
    this.getTreeMenu();
  }

  refreshDataListVisit() {
    this.reloadListVisit = true;
    setTimeout(() => {
      this.reloadListVisit = false;
    }, 200);
  }

  refreshDataAppointment() {
    if (this.appointment) {
      this.appointment.getListAppointment();
    }
  }

  onTabClick(tabId: any) {
    this.tabDataService.changeTab(tabId);
  }

  // showPage() {
  //   $('#loader').fadeOut(500);
  // }
  logoCollap() {
    if (!window.matchMedia('(max-width: 1200px)').matches) {
      this.isCollapsed = !this.isCollapsed;
    }
    this.setSidebarWidth(this.isCollapsed);
  }

  setSidebarWidth(isCollapsed) {
    if (!isCollapsed) {
      document.documentElement.style.setProperty('--w-sidebar-width', '240px');
    } else {
      document.documentElement.style.setProperty('--w-sidebar-width', '60px');
    }
  }

  onScroll(e) {
    const scrollTop = $('#mainContent').scrollTop();
    const top = Math.max(350 - scrollTop, 100);
    const mgTop = Math.max(450 - scrollTop, 200);
    const height = this.windowHeight - mgTop;
    this.visitDetail.onScroll({ top, height });
  }

  getPatient(p) {
    this.patient = p;
  }

  collapsedChange($event) {
    this.setSidebarWidth($event);
  }

  acceptJoin(_notiId: string, isOk: boolean, temp: any) {
    //let objMsg=JSON.parse(msg);
    let notiId = '';
    if (_notiId != null) {
      notiId = _notiId;
    } else {
      notiId = this.msg.data.info;
    }

    this.videoConferenceService.joinRoom(notiId, isOk).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            if (isOk) {
              if (temp !== null) {
                temp.close();
              } else {
                this.listNotification = this.listNotification.map((e) =>
                  e.id == notiId
                    ? {
                        ...e,
                        viewed: true,
                        hasButton: 0,
                        actionHtml:
                          'Bạn đã đồng ý join room. Nhấn vào <a target="_blank" href="' +
                          e.link +
                          '" >đây</a> để vào lại',
                      }
                    : e
                );
              }

              window.open(
                window.location.origin + '/video-conference?notiId=' + res.jsonData.roomId,
                '_blank',
                'toolbar=0,location=0,menubar=0'
              );
            } else {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.INFO,
                'Bạn đã từ chối tham gia nhóm hội chẩn!'
              );
              if (temp !== null) {
                temp.close();
              } else {
                this.listNotification = this.listNotification.map((e) =>
                  e.id == notiId
                    ? {
                        ...e,
                        viewed: true,
                        hasButton: 0,
                        actionHtml: 'Bạn đã từ chối tham gia hội chẩn',
                      }
                    : e
                );
              }
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  readNotifi(notiId) {
    this.userService.viewNotifi(notiId).subscribe((res) => {
      if (res !== null) {
        if (res.isValid) {
          const viewItem = this.listNotification.filter((en) => en.id === notiId)[0];
          viewItem.viewed = true;
        }
      }
    });
  }
  acceptJoinGroup(_notiId, isOK, temp) {
    let codeInviteId = '';
    let notiId = '';
    if (_notiId === null) {
      notiId = this.msg.data.info;
    } else {
      notiId = _notiId;
    }
    const payload = {
      notiId,
      isOK,
    };
    this.userService.confirmInviteGroup(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            codeInviteId = res.jsonData.invitaionId;
            if (temp !== null) {
              temp.close();
            } else {
              this.listNotification = this.listNotification.map((e) =>
                e.id == notiId ? { ...e, hasButton: 0, viewed: true } : e
              );
            }
            if (isOK) {
              this.route.navigateByUrl('/confirm-invite?Code=' + codeInviteId + '&Type=1'); //http://localhost:4200/confirm-invite?Code=60b8c0ecb029ff1ef5d86b44&Type=1
            } else {
              this.route.navigateByUrl('/confirm-invite?Code=' + codeInviteId + '&Type=-1'); //http://localhost:4200/confirm-invite?Code=60b8c0ecb029ff1ef5d86b44&Type=1
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }

  setLinkHoiChan(link) {
    return ' Ấn vào <a target="_blank" href="' + link + '">đây</a> để tham gia hội chẩn';
  }
  changeNotification(event) {
    // console.log(event);
    // this.isVisibleNotifications = event;
    if (event) {
      //getTopNotifi
      this.userService.getTopNotifi().subscribe((res) => {
        if (res !== null && res.isValid) {
          this.listNotification = res.jsonData.topNoti;
          this.numOfUnreadNotifi = res.jsonData.totalNoti;
          this.numOfNewNotifi = 0; //reset new notification count
          // console.log(this.listNotification);
        }
      });
    }
  }
  toggleNotification() {
    this.isVisibleNotifications = !this.isVisibleNotifications;
    if (this.isVisibleNotifications == true) {
      this.changeNotification(true);
    }
  }
  viewAllNoti() {
    this.showAllNotifications = true;
    this.notiComponent.callPager(1);
  }
  closeViewAllNoti(event) {
    this.showAllNotifications = false;
  }

  async searchGroup(event) {
    this.list = JSON.parse(JSON.stringify(this.listMenuTemp));
    let keyword = event.target.value.trim();
    if (keyword.length <= 1) {
      //Reset show full  list
      // this.list = this.listMenuTemp;
      return;
    }
    keyword = keyword.toLowerCase();
    let isOpen = false;
    const listSearch = [];
    for (let i = 0; i < this.list.length; i++) {
      let children = [];
      let item = null;
      if (this.list[i].children != null && this.list[i].children.length > 0) {
        children = await this.searchGroupRecuisive(this.list[i].children, keyword);
      }
      if (this.list[i].name.toLowerCase().indexOf(keyword) !== -1 || children.length > 0) {
        item = this.list[i];
        if (!isOpen && children.length > 0) {
          isOpen = true;
          item.expanded = true;
        }
        item.children = children;
        listSearch.push(item);
      }

      children = [];
    }
    this.list = listSearch;
  }
  async searchGroupRecuisive(lst, kw) {
    const listSearch = [];
    for (let i = 0; i < lst.length; i++) {
      let children = [];
      let item = null;
      if (lst[i].children != null && lst[i].children.length > 0) {
        children = await this.searchGroupRecuisive(lst[i].children, kw);
      }
      if (lst[i].name.toLowerCase().indexOf(kw) !== -1 || children.length > 0) {
        item = lst[i];
        item.children = children;
        listSearch.push(item);
      }

      children = [];
    }
    return listSearch;
  }
  ngOnDestroy() {
    this.groupSub.unsubscribe();
    this.broadcast.close();
  }
  goFromNotify(item) {
    if (item.referenceInfo == null || item.referenceInfo === '') {
      return;
    }
    if (item.type == Constant.NotificationType.Visit) {
      var arr = item.referenceInfo.split('#');
      if (arr.length != 3) {
        return;
      }
      this.tabDataService.updateTab(arr[0], arr[1], 'VisitDetail', arr[2]);
    } else if (item.type == Constant.NotificationType.ChatInVisit) {
      var arr = item.referenceInfo.split('#');
      if (arr.length != 2) {
        return;
      }
      //TODO: show tab chat when open visit
      this.tabDataService.updateTab(arr[0], arr[1], 'VisitDetail', '');
    } else if (item.type == Constant.NotificationType.Conversation) {
      //userId + "#" + userFullName
      var arr = item.referenceInfo.split('#');
      if (arr.length != 2) {
        return;
      }
      this.tabDataService.updateTab('chat', 'Tin nhắn', arr[1], arr[0]);
    }
    this.readNotifi(item.id);
    this.isVisibleNotifications = false;
  }
  openModalAboutPmr() {
    this.isVisibleAboutPmr = true;
  }
  closeAboutPmr(event) {
    this.isVisibleAboutPmr = false;
  }

  openStatistic() {
    window.open('/thong-ke', '_blank');
  }

  openRemindLater() {
    this.isShowAddTime = true;
    this.timeForm.reset();
    this.timeForm.patchValue({
      time: 15,
      unit: 'minute',
    });
  }

  onChange(value: string): void {
    if (value) {
      const rs = parseInt(value);
      if (rs.toString() == 'NaN') {
        this.timeForm.patchValue({
          time: 15,
        });
      }
    }
  }

  closeNotify(val) {
    const payload = { reminderId: this.reminderId };
    this.reminderService.reminded(payload).subscribe((res) => {
      if (res.isValid) {
        this.notification.remove('');
      } else {
        this.notificationService.showNotification(Constant.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  reminderLater() {
    for (const i in this.timeForm.controls) {
      this.timeForm.controls[i].markAsDirty();
      this.timeForm.controls[i].updateValueAndValidity();
    }

    if (this.timeForm.valid) {
      let payload = this.timeForm.value;
      payload = { ...{ reminderId: this.reminderId }, ...payload };
      this.reminderService.reminderLater(payload).subscribe((res) => {
        if (res.isValid) {
          this.notification.remove('');
        } else {
          this.notificationService.showNotification(Constant.ERROR, res.errors[0].errorMessage);
        }
      });
    }
  }

  timeUnitChange(val) {
    switch (val) {
      case 'minute':
        (this.timeOptions = [
          { key: 15, value: 15 },
          { key: 30, value: 30 },
          { key: 60, value: 60 },
        ]),
          this.timeForm.patchValue({
            time: 15,
          });
        break;
      case 'hour':
        (this.timeOptions = [
          { key: 3, value: 3 },
          { key: 24, value: 24 },
          { key: 72, value: 72 },
        ]),
          // this.timeSelectOption = 3;
          this.timeForm.patchValue({
            time: 3,
          });
        break;
      case 'day':
        (this.timeOptions = [
          { key: 7, value: 7 },
          { key: 30, value: 30 },
          { key: 90, value: 90 },
        ]),
          // this.timeSelectOption = 7;
          this.timeForm.patchValue({
            time: 7,
          });
        break;
      default:
        break;
    }
    this.isShowAddTime = true;
  }

  sendToWaitingPage() {
    window.open(window.location.origin + '/waiting-page/' + this.selectedGroupId, '_blank');
  }

  confirmWarning(id) {
    this.errorsMessage = this.errorsMessage.filter((t) => t.id != id);
    if (this.errorsMessage.length) {
      this.warning(this.errorsMessage[0]);
    }
  }

  warning(msg): void {
    this.modalService.warning({
      nzTitle: msg.title,
      nzContent: msg.body,
      nzOnOk: () => this.confirmWarning(msg.id),
    });
  }
  confirmWarningCDSRule(data) {
    console.log('le', data);
    console.log('le2', JSON.parse(data));
    // this.errorsMessage = this.errorsMessage.filter((t) => t.id != id);
    // if (this.errorsMessage.length) {
    //   this.warning(this.errorsMessage[0]);
    // }
    this.rulesManagermentService.createReminder2CDSRule(JSON.parse(data)).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  warningCDSRule(msg): void {
    this.modalService.warning({
      nzTitle: msg.title,
      nzContent: msg.body,
      nzOkText: 'Đồng ý',
      nzCancelText: 'Đóng',
      nzOnOk: () => this.confirmWarningCDSRule(msg.id),
      //nzOnCancel: () => this.warningCDSRuleClose(),
    });
  }
  openModelSettingGroup(): void {
    console.log('h');
    this.isVisibleSettingGroup = true;
    if (this.isAdminGroup) {
    }
  }
  closeSettingGroup(data) {
    this.isVisibleSettingGroup = false;
  }
  settingGroupEvent(urlAva): void {
    this.isVisibleSettingGroup = false;
  }
}
