<!-- <div [ngSwitch]="type">
    <div *ngSwitchCase="'multiple'" style="height: 100%;">
        <nz-checkbox-wrapper [(ngModel)]="data.observationType.dataSource" (ngModelChange)="updateSingleChecked()">
            <label nz-checkbox *ngFor="let item of data.observationType.dataSource" [nzValue]="item.text"
                [ngClass]="[data.formatStyle.display == 'block' ? 'vertical-display' : '']">{{item.text}}</label>
        </nz-checkbox-wrapper>
    </div>
    <div *ngSwitchCase="'single'" style="height: 100%;">
        <nz-radio-group [(ngModel)]="data.value" nzName="radiogroup">
            <label nz-radio *ngFor="let item of data.observationType.dataSource" [nzValue]="item.text"
                [ngClass]="[data.formatStyle.display == 'block' ? 'vertical-display' : '']">{{item.text}}</label>
        </nz-radio-group>
    </div>
</div> -->

<div [ngSwitch]="type" style="height: 100%">
  <div *ngSwitchCase="'multiple'" style="height: 100%">
    <nz-checkbox-wrapper
      (ngModelChange)="updateSingleChecked()"
      [ngClass]="[
        data.formatStyle.display == 'block' ? 'vertical-display' : '',
        data.formatStyle.isBold == true ? 'bold' : '',
        data.formatStyle.isItalic == true ? 'italic' : '',
        data.formatStyle.isUnderline == true ? 'underline' : '',
        alginFormat.get(data.formatStyle.align)
      ]"
      [ngStyle]="{
        background: data.formatStyle.bgColor,
        'font-size': data.formatStyle.fontSize + 'px',
        'height': '100%',
        'font-family': data.formatStyle.font
      }">
      <label
        nz-checkbox
        *ngFor="let item of data.observationType.dataSource"
        [nzValue]="item.text"
        [ngStyle]="{
          'margin-left': '8px',
          color: data.formatStyle.color
        }"
        >{{ item.text }}</label
      >
    </nz-checkbox-wrapper>
  </div>
  <div *ngSwitchCase="'single'" style="height: 100%">
    <nz-radio-group
      [(ngModel)]="data.value"
      nzName="radiogroup"
      [ngClass]="[
        data.formatStyle.display == 'block' ? 'vertical-display' : '',
        data.formatStyle.isBold == true ? 'bold' : '',
        data.formatStyle.isItalic == true ? 'italic' : '',
        data.formatStyle.isUnderline == true ? 'underline' : '',
        alginFormat.get(data.formatStyle.align)
      ]"
      [ngStyle]="{
        background: data.formatStyle.bgColor,
        'font-size': data.formatStyle.fontSize + 'px',
        'height': '100%',
        'font-family': data.formatStyle.font
      }">
      <label
        nz-radio
        *ngFor="let item of data.observationType.dataSource"
        [nzValue]="item.text"
        [ngStyle]="{
          'margin-left': '8px',
          color: data.formatStyle.color
        }"
        >{{ item.text }}</label
      >
    </nz-radio-group>
  </div>
</div>
<!-- <div class="active-elm"></div> -->
