import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class SystemListsService extends BaseService {
  getSystemCodeAll(): any {
    return this.get(UrlConstant.SYSTEM_CODE);
  }

  saveSystemCode(data: any) {
    return this.post(UrlConstant.SYSTEM_CODE, data);
  }

  getSystemCodeById(id: string) {
    return this.get(UrlConstant.SYSTEM_CODE + '/' + id);
  }

  updateSystemCode(id, data) {
    return this.put(UrlConstant.SYSTEM_CODE + '/' + data.id, data);
  }
}
