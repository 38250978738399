<div id="regist-form">
  <div class="header">
    <img src="assets/image/logo.svg" />
    <p>Patient Medical Record</p>
  </div>

  <div id="regist-message" *ngIf="notExistedPMR">
    <!-- Nếu bạn đã có tài khoản MSA mà chưa là thành viên tại PMR, hãy login tại <a (click)="toLoginFirstPmr()"><strong>đây</strong></a><br/>
            Nếu chưa có tài khoản MSA hãy điền vào form đăng ký dưới đây -->
    <div class="" *ngIf="ExistedMSA">
      <h3>MSA đã tồn tại tài khoản với email hoặc username bạn vừa đăng ký!</h3>
      <p *ngIf="caseText === 'USERINVITED-ISNULL'" style="color: red">
        Click vào đây để đăng nhập và tham gia nhóm
        <a href="{{ UrlLogin }}">Đăng nhập</a>
      </p>
      <p *ngIf="caseText === 'USERINVITED-ISNULL-BUT-ONLINEOTHER'" style="color: red">
        Đồng thời do bạn đang online bằng tài khoản {{ currentUserName }} nếu
        muốn về trang chủ <a href="{{ curUrl }}">click vào đây</a>
      </p>
      <p *ngIf="caseText === 'USERINVITED-ISNULL-BUT-ONLINEOTHER'">
        Nếu vẫn muốn tham gia nhóm bạn hãy click vào
        <a style="color: red" (click)="logoutToJoin(false)">đây</a> để logout
        tài khoản đang online và đăng nhập lại để tham gia nhóm
      </p>
      <p *ngIf="caseText === 'EXIST-MSA-BUT-ONLINEOTHER'">
        Nếu vẫn muốn tham gia nhóm bạn hãy click vào
        <a style="color: red" (click)="logoutToJoin2()">đây</a> để logout
        tài khoản đang online và đăng nhập lại để tham gia nhóm
      </p>
    </div>
    <div class="col-md-10 col-lg-8 invite-message"
      *ngIf="!ExistedMSA && caseText === 'USERINVITED-ISNULL-BUT-ONLINEOTHER'">
      <img src="assets/image/reject-invitation.svg" />
      <h3>
        Bạn đang online với tài khoản
        {{ currentUserName }}!
      </h3>
      <p>
        Click vào đây để về trang chủ <a href="{{ curUrl }}">Về trang chủ</a>
      </p>
      <p>
        Nếu vẫn muốn tham gia nhóm bạn hãy click vào
        <a style="color: red" (click)="logoutToJoin(true)">đây</a> để logout tài
        khoản đang online và đăng ký tài khoản mới
      </p>
    </div>

  </div>
  <div *ngIf="isRegist">
    <app-member-registration [email]="inviteEmail" (eventRegist)="addUser($event)"></app-member-registration>
  </div>

  <div class="col-md-10 col-lg-8 invite-message" *ngIf="isRejectOk">
    <img src="assets/image/reject-invitation.svg" />
    <h3>Bạn đã từ chối tham gia nhóm thành công.</h3>
    <p>Click vào đây để về trang chủ <a href="{{ curUrl }}">Trang chủ</a></p>
  </div>
  <div class="col-md-10 col-lg-8 invite-message" *ngIf="notExisted">
    <img src="assets/image/reject-invitation.svg" />
    <h3>Không tồn tại lời mời hoặc đã hết hạn/từ chối!</h3>
    <p>Click vào đây để về trang chủ <a href="{{ curUrl }}">Trang chủ</a></p>
  </div>
  <div class="col-md-10 col-lg-8 invite-message" *ngIf="caseText === 'USERLOGINED-REJECT'">
    <img src="assets/image/reject-invitation.svg" />
    <h3>
      Bạn đang online với tài khoản
      {{ currentUserName }}!
    </h3>
    <p>Click vào đây để về trang chủ <a href="{{ curUrl }}">Về trang chủ</a></p>
    <p>
      Nếu vẫn muốn tham gia nhóm bạn hãy click vào
      <a style="color: red" (click)="logoutToJoin(false)">đây</a> để logout tài
      khoản đang online và đăng nhập lại để tham gia nhóm
    </p>
  </div>
</div>