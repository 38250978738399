import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ElementRef,
  Renderer2,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';

@Component({
  selector: 'app-day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['./day-view.component.scss'],
})
export class DayViewComponent implements OnInit {
  _timeSlotsDisplay;
  get timeSlotsDisplay() {
    return this._timeSlotsDisplay;
  }
  @Input() set timeSlotsDisplay(value) {
    this._timeSlotsDisplay = value;
  }

  _listVisitGroupByDoctorId = [];
  get listVisitGroupByDoctorId() {
    return this._listVisitGroupByDoctorId;
  }
  @Input() set listVisitGroupByDoctorId(value) {
    this._listVisitGroupByDoctorId = value;
  }
  @Output() visitItemHover = new EventEmitter<any>();
  @Output() visitItemLeave = new EventEmitter<any>();

  constructor(private renderer: Renderer2, private tabDataService: TabDataService) {}

  ngOnInit(): void {}

  onVisitItemClick(event) {
    // console.log(event);
    let dataVisit = JSON.parse(event.target.getAttribute('data-visit'));
    let popups = document.querySelectorAll('.popup-patient-info');

    // event.target.style.color = 'var(--dp-primary-2)';

    if (popups) {
      popups.forEach((el) => {
        el.remove();
      });
    }
    if (dataVisit.id && dataVisit.patientName) {
      this.tabDataService.updateTab(dataVisit?.id, dataVisit?.patientName, 'VisitDetail', dataVisit?.id);
    }
  }
  onVisitItemHover(event) {
    this.visitItemHover.emit(event);
  }

  onVisitItemLeave(event) {
    this.visitItemLeave.emit();
  }
}
