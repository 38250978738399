<div title="Trở về" (click)="backHome()">
  <i nz-icon nzType="rollback" nzTheme="outline" class="back-btn"></i>
</div>
<div class="report-tab">
  <h2>THỐNG KÊ CHUNG</h2>
  <div nz-row>
    <div nz-col [nzSpan]="4">
      <div class="text-align-center info-tab">
        <div class="doctor-icon"></div>
        <div class="mg-l-15">
          <div>Bác sĩ</div>
          <div>
            <span>
              {{ commonData.totalDoctor }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div nz-col [nzSpan]="4">
      <div class="text-align-center info-tab">
        <div class="patient-icon"></div>
        <div class="mg-l-15">
          <div>Bệnh nhân</div>
          <div>
            <span>
              {{ commonData.totalPatient }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div nz-col [nzSpan]="4">
      <div class="text-align-center info-tab">
        <div class="being-treat-icon"></div>
        <div class="mg-l-15">
          <div>Đang điều trị</div>
          <div>
            <span>
              {{ commonData.totalBeingTreatedPatient }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div nz-col [nzSpan]="4">
      <div class="text-align-center info-tab">
        <div class="nurse-icon"></div>
        <div class="mg-l-15">
          <div>Điều dưỡng</div>
          <div>
            <span>
              {{ commonData.totalNurse }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div nz-col [nzSpan]="4">
      <div class="text-align-center info-tab">
        <div class="coordinator-icon"></div>
        <div class="mg-l-15">
          <div>Điều phối viên</div>
          <div>
            <span>
              {{ commonData.totalCoordinator }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div nz-col [nzSpan]="4">
      <div style="padding: 16px; float: right;">
        <button nz-button nzType="primary" class="mg-bt-10 d-block" [nzLoading]="isExporting" (click)="exportListPatient()">
          <i nz-icon nzType="download"></i>
          Xuất DSBN
        </button>
        <button *ngIf="isAdminGroup" nz-button class="btn-success d-block" [nzLoading]="isExportPrescription" (click)="exportPrescription()">
          <i nz-icon nzType="download"></i>
          Xuất thuốc
        </button>
      </div>
    </div> -->
  </div>
  <div nz-row>
    <div nz-col [nzSpan]="15">
      <div nz-row>
        <div nz-col [nzSpan]="4">
          <h3>Chọn nhóm:</h3>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-select
            [(ngModel)]="selectedGroupId"
            (ngModelChange)="getAllData()"
            style="width: 90%">
            <nz-option
              *ngFor="let item of covidGroups"
              [nzLabel]="item.name"
              [nzValue]="item.id"></nz-option>
          </nz-select>
        </div>
        <div nz-col [nzSpan]="6">
          <nz-select [(ngModel)]="range" (ngModelChange)="getCountinuousInfo()">
            <nz-option [nzValue]="7" nzLabel="7 ngày"></nz-option>
            <nz-option [nzValue]="30" nzLabel="30 ngày"></nz-option>
            <nz-option [nzValue]="90" nzLabel="90 ngày"></nz-option>
          </nz-select>
        </div>
      </div>

      <div id="xychartdiv" style="height: 600px"></div>
    </div>
    <nz-divider [nzType]="'vertical'" style="height: 600px"></nz-divider>
    <div nz-col [nzSpan]="8">
      <div id="soschartdiv" style="height: 600px"></div>
    </div>
  </div>
</div>
