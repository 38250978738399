<div class="thuoc-container m-tab-container">
  <div class="thuoc-header mg-bt-10">
    <div>
      <span class="m-tab-title">Đơn thuốc</span>
      <span *ngIf="prescription != null" class="m-tab-title">
        - {{ prescription.StatusString }}
      </span>
    </div>
    <div *ngIf="!isExporting" class="d-flex" [ngClass]="{ 'list-btn-mobile': isMobile }">
      <div class="d-flex mg-r-15">
        <ng-container *ngIf="!isEmptyPrescription">
          <button *ngIf="canApprovePrescription" class="top-btn" nz-button [disabled]="disabledActiton"
            (click)="approvePrescription()">
            Duyệt
          </button>
          <div class="d-flex" *ngIf="isOtherCreated">
            <button *ngIf="canApprovePrescription" class="top-btn" nz-button [disabled]="disabledActiton"
              (click)="queryPrescription()">
              Yêu cầu kiểm tra
            </button>
            <button *ngIf="canDenyPrescription" class="top-btn" nz-button [disabled]="disabledActiton"
              (click)="denyPrescription()">
              Từ chối <span *ngIf="!isMobile">&nbsp;toa</span>
            </button>
          </div>
          <button *ngIf="canUnapprovePrescription" class="top-btn" nz-button [disabled]="disabledActiton"
            (click)="unapprovePrescription()">
            Bỏ duyệt
          </button>
        </ng-container>
      </div>
      <div *ngIf="canEditPrescription" class="mg-r-15">
        <button nz-button [disabled]="disabledActiton" *ngIf="!isShowFormMedication" (click)="showFormMedication(true)"
          class="mg-l-15">
          +
        </button>
        <button nz-button [disabled]="disabledActiton" *ngIf="isShowFormMedication" (click)="showFormMedication(false)">
          x
        </button>
        <button *ngIf="canEditPrescription && prescription != null" class="top-btn" nz-button
          [disabled]="disabledActiton" (click)="removePrescription()">
          Xóa đơn
        </button>
        <button nz-button [disabled]="disabledActiton" *ngIf="canEditPrescription" (click)="openModalSample()"
          class="mg-l-15">
          Đơn thuốc <span *ngIf="!isMobile">&nbsp;mẫu</span>
        </button>
      </div>
      <button nz-button class="mg-r-15" (click)="copyLink()" [disabled]="disabledActiton" title="Chia sẻ link đơn thuốc"
        *ngIf="isShowBtnAction">
        <i nz-icon nzType="link" nzTheme="outline"></i>
        <span *ngIf="!isMobile">Chia sẻ</span>
      </button>
      <!--<button nz-button class="mg-r-15" (click)="exportPdf()" title="Xuất pdf"
        [disabled]="isExporting || disabledActiton" *ngIf="isShowBtnAction">
        <i nz-icon nzType="file-pdf" nzTheme="outline"></i>
        <span *ngIf="!isMobile">Xuất PDF</span>
      </button>-->
      <button nz-button class="mg-r-15" (click)="printPrescription2()" title="In" *ngIf="isShowBtnAction">
        <i nz-icon nzType="printer" nzTheme="outline"></i>
        <span *ngIf="!isMobile">In</span>
      </button>
      <!-- <button nz-button (click)="printHDSD()" title="In HDSD"  *ngIf="isShowBtnAction">
        <i nz-icon nzType="printer" nzTheme="outline"></i>
        <span *ngIf="!isMobile">In HDSD</span>
      </button> -->
      <button nz-button *ngIf="isShowBtnExport" class="mg-l-15" (click)="export()"
        [disabled]="isExporting || disabledActiton" title="Xuất thuốc">
        <i nz-icon nzType="export" nzTheme="outline" style="font-size: 16px"></i>
        <span *ngIf="!isMobile">Xuất thuốc</span>
      </button>
      <div class="mg-l-15 btn-more is-open-{{ isOpenMenu }}" nz-dropdown [nzDisabled]="disabledActiton"
        [nzDropdownMenu]="menu_action" [nzTrigger]="'click'" [(nzVisible)]="isOpenMenu">
        <i nz-icon nzType="more" nzTheme="outline"></i>
      </div>
    </div>
  </div>

  <div *ngIf="prescription != null" style="margin-bottom: 16px">
    <span *ngIf="prescription.CreatorName">
      Bác sỹ kê toa: <strong>{{ prescription.CreatorName }}</strong>
    </span>
    <span *ngIf="prescription.ApproverName">
      - Dược sỹ: <strong>{{ prescription.ApproverName }}</strong>
    </span>
  </div>

  <div *ngIf="isShowFormMedication && isEditMode" class="thuoc-modal">
    <form [formGroup]="formMedication" class="form-edit" enter-for>
      <div class="row">
        <div class="col-md-8 col-12 mg-bt-10">
          <input #nextTab nz-input class="focus-cus" formControlName="medicationName"
            placeholder="Nhập tên thuốc để lựa chọn" minLength="3" (input)="callbackSearchFn()" [nzAutocomplete]="auto"
            (keydown.enter)="selectSearchMedication(auto.activeItem?.nzValue)" style="margin-right: 16px" />
          <nz-autocomplete #auto>
            <nz-auto-option *ngFor="let item of listMedicationSource" [nzValue]="item" [nzLabel]="item.title"
              (click)="selectSearchMedication(item)">
              <p style="white-space: normal">{{ item.title }}</p>
            </nz-auto-option>
          </nz-autocomplete>
          <div class="valid-error" *ngIf="validError && formMedicationData.medicationName.errors">
            <span>Tên thuốc không hợp lệ</span>
          </div>
          <div class="valid-error" *ngIf="
              validError &&
              !formMedicationData.medicationName.errors &&
              formMedicationData.id.errors
            ">
            <span>Phải chọn thuốc từ danh sách</span>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 mg-bt-10">
          <div class="row">
            <div class="col-6">
              <nz-input-number #nextTab nz-input formControlName="amount" nzPlaceHolder="Số lượng"
                style="padding-right: 0px; border-radius: 5px; width: 100%" [nzStep]="1" [nzMin]="1" [nzPrecision]="0">
              </nz-input-number>
              <div class="valid-error" *ngIf="validError && formMedicationData.amount.errors">
                <span>SL không hợp lệ</span>
              </div>
            </div>
            <div class="col-6">
              <input nz-input formControlName="unit" placeholder="Đơn vị tính" type="text" readonly />
              <input formControlName="content" type="hidden" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mg-bt-10">
        <div class="col-md-8 col-8">
          <input #nextTab nz-input formControlName="text" placeholder="Cách sử dụng" type="text" style="width: 100%"
            autocomplete="off" />
          <div class="valid-error" *ngIf="validError && formMedicationData.text.errors">
            <span>Cách sử dụng không hợp lệ</span>
          </div>
        </div>
        <div class="col-md-2 col-4">
          <input nz-input formControlName="price" placeholder="Đơn giá" type="text" readonly />
        </div>
        <div *ngIf="!isMobile" class="col-md-2 d-flex">
          <!-- <div *ngIf="selectedMedication && !selectedMedication?.isFunctionalFoods" style="width: calc(100% - 30px);">
            <input type="checkbox" checked disabled /> Thuốc
          </div>
          <div *ngIf="selectedMedication && selectedMedication?.isFunctionalFoods" style="width: calc(100% - 30px);">
            <input type="checkbox" checked disabled /> TPCN
          </div> -->
          <div *ngIf="selectedMedication" style="width: calc(100% - 30px);">
            <input type="checkbox" checked disabled />
            {{ selectedMedication.isFunctionalFoods ? 'TPCN' : 'Thuốc' }}
          </div>
          <button #nextTab type="submit" nz-button id="blue" class="mgr15" (click)="updateMedication()"
            *ngIf="!addElseUpdate">
            <img style="width: 17px" src="assets/image/DPicon/icon-save-white.svg" />
          </button>
          <button #nextTab type="submit" nz-button id="green" class="mgr15" (click)="addMedication()"
            *ngIf="addElseUpdate">
            <img style="width: 17px" src="assets/image/DPicon/icon-add-white.svg" />
          </button>
          <button nz-button id="red" (click)="resetPopup()">
            <img style="width: 13px" src="assets/image/DPicon/icon-close-white.svg" />
          </button>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-8">
          <span style="color: #333" class="">BN đã có &nbsp;</span>
          <nz-input-number nz-input formControlName="existingAmount"
            style="padding-right: 0px; border-radius: 5px; width: 70px" [nzStep]="1" [nzMin]="0" [nzPrecision]="0">
          </nz-input-number>
        </div>
        <div *ngIf="isMobile" class="d-flex col-4">
          <button nz-button id="red" class="mg-r-10" (click)="resetPopup()">
            <i><img src="assets/image/DPicon/icon-close-white.svg" /></i>
          </button>
          <button type="submit" nz-button id="blue" class="mgr15" (click)="updateMedication()" *ngIf="!addElseUpdate">
            <img style="width: 14px" src="assets/image/DPicon/icon-save-white.svg" />
          </button>
          <button type="submit" nz-button id="green" (click)="addMedication()" *ngIf="addElseUpdate">
            <img style="width: 14px" src="assets/image/DPicon/icon-add-white.svg" />
          </button>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="!isMobile" class="thuoc-table main-table-grid alternating-tables" style="margin-right: -6px">
    <nz-table #tablethuoc class="main-table-grid-wrap-text" nzSize="small" [nzData]="listMedicationTable"
      nzShowPagination="false" [nzScroll]="{ y: '30vh' }" [nzPageSize]="100">
      <thead>
        <tr>
          <th nzWidth="50px">STT</th>
          <th *ngIf="isEditMode" nzWidth="80px"></th>
          <th nzWidth="400px">Tên thuốc/hàm lượng/hướng dẫn sử dụng</th>
          <th class="text-nowrap" nzWidth="50px">SL</th>
          <th class="text-nowrap" nzWidth="80px">Thực xuất</th>
          <th class="text-nowrap" nzWidth="80px">ĐVT</th>
          <th class="text-nowrap" nzWidth="150px">Thuốc hay TPCN</th>
          <th class="text-nowrap" nzWidth="100px">
            Thành tiền<br />(tham khảo)
          </th>
          <!-- <th class="text-nowrap" nzWidth="150px">Tổng</th> -->
          <th *ngIf="isEditMode" nzWidth="80px"></th>
          <!-- <th></th> -->
        </tr>
      </thead>
      <tbody cdkDropList class="example-list" (cdkDropListDropped)="dropMedicationItem($event)">
        <tr cdkDrag *ngFor="let data of tablethuoc.data; index as i" [ngClass]="{
            'highlight': selectedRow == i,
            'cursor-move': isEditMode
          }" >
          <td class="text-center">{{ i + 1 }}</td>
          <td *ngIf="isEditMode" class="text-center">
            <i class="icon-primary-2" nz-icon nzType="vertical-align-top" nzTheme="outline"
              (click)="changeOrder(data, 1)"></i>
            <i class="icon-primary-2" nz-icon nzType="vertical-align-bottom" nzTheme="outline"
              (click)="changeOrder(data, -1)"></i>
          </td>
          <td class="text-left">
            <div>
              <div class="text-title">
                {{ data.Title }}
              </div>
              <span class="text-note-small">{{ data.Text }}</span>
            </div>
          </td>
          <td class="text-nowrap">{{ data.Amount }}</td>
          <td>{{ getAmount(data.Amount, data.ExistingAmount) }}</td>
          <td>{{ data.UnitName }}</td>
          <td>{{ convertTextTPCN(data.IsFunctionalFoods)}}</td>
          <!-- <td>{{ data.statusClinical }}</td> -->
          <td class="text-right">
            {{ getMedicationDetailPrice(data) | currencyFormat }}
          </td>
          <td *ngIf="isEditMode" style="cursor: pointer" class="text-center">
            <i title="Sửa chi tiết thuốc" class="icon-general-info" nz-icon nzType="edit" nzTheme="outline"
              (click)="selectMedication(i)"></i>
            <i title="Xóa chi tiết thuốc" class="icon-general-info" nz-icon nzType="delete" nzTheme="outline"
              (click)="deleteMedication(i)"></i>
          </td>
        </tr>
        <tr *ngIf="getTotalPrice() > 0">
          <td colspan="6" style="text-align: right; font-weight: bold">Tổng</td>
          <td style="text-align: right; font-weight: bold; display: block">
              {{ getTotalPrice() | currencyFormat }} VNĐ
          </td>
          <td *ngIf="isEditMode"></td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div *ngIf="isMobile">
    <div class="m-tab-title mg-bt-15">Danh sách thuốc</div>
    <div *ngFor="let data of listMedicationTable; index as i" class="mg-bt-10 pres-item">
      <div class="d-flex justify-content-between">
        <div>
          <span class="font-weight-bold">{{ i + 1 }} - {{ data.Name }}</span>
          <span *ngIf="
              data.ActiveIngredient != null && data.ActiveIngredient != ''
            ">
            ({{ data.ActiveIngredient }})
          </span>
        </div>
        <div *ngIf="isEditMode" class="d-flex" style="cursor: pointer">
          <i nz-icon nzType="edit" nzTheme="outline" (click)="selectMedication(i)" class="icon-general-info"></i>
          <i nz-icon nzType="delete" nzTheme="outline" (click)="deleteMedication(i)" class="icon-general-info"></i>
        </div>
      </div>
      <div class="italic fs-12">{{ data.Text }}</div>
      <div class="d-flex">
        <div class="w-50 fs-12">Số lượng: {{ data.Amount }}</div>
        <div class="w-50 fs-12">
          Thực xuất: {{ getAmount(data.Amount, data.ExistingAmount) }}
        </div>
      </div>
      <div *ngIf="getMedicationDetailPrice(data) != ''" class="d-flex">
        <div class="w-50 fs-12">Đơn vị: {{ data.UnitName }}</div>
        <div class="w-50 fs-12">
          Thành tiền: {{ getMedicationDetailPrice(data) | currencyFormat }}
        </div>
      </div>
    </div>
    <div *ngIf="getTotalPrice() > 0" class="d-flex justify-content-between">
      <span class="font-weight-bold">Tổng:</span>
      <span style="font-size: 14px">{{ getTotalPrice() | currencyFormat }} VNĐ</span>
    </div>
  </div>
  <!-- Lời dặn của BS -->
  <div class="row">
    <div class="thuoc-general-info col-6">
      <div class="m-tab-title mg-bt-10">Lời dặn của BS:</div>
      <div *ngIf="!isEditMode" class="item-info">
        <div [innerHTML]="convertTextbox2Html(doctorComment)"></div>
      </div>
      <div class="item-info w-100" *ngIf="isEditMode">
        <div class="input-doctor-advice">
          <textarea nz-input type="text" [(ngModel)]="doctorComment" (ngModelChange)="doctorCommentChange()"
            placeholder="Nhập lời khuyên BS" style="min-height: 80px" autocomplete="off"></textarea>
        </div>
      </div>
    </div>
    <div class="thuoc-general-info col-6">
      <div class="m-tab-title mg-bt-10">Lưu ý:</div>
      <div>{{ PRESCRIPTION_NOTE }}</div>
      <div *ngIf="!isEditMode && prescriptionNoteFormData.reExaminationDate.value" class="item-info">
        <!-- <div [innerHTML]="getTextOfPrescriptionNote()"></div> -->
        <label class="mg-r-10">Hẹn tái khám: </label>
        <span>ReExaminationDate
          {{
          prescriptionNoteFormData.reExaminationDate.value | date: dateFormat
          }}
        </span>
      </div>
      <div class="item-info w-100" *ngIf="isEditMode">
        <div [formGroup]="prescriptionNoteFG">
          <label class="mg-r-10">Hẹn tái khám: </label>
          <nz-date-picker class="mg-r-10" formControlName="reExaminationDate" [nzPlaceHolder]="'DD/MM/YYYY'"
            [nzFormat]="dateFormat" (ngModelChange)="reExaminationDateChange($event)"></nz-date-picker>
          <span> Tái khám sau: </span>
          <nz-select class="w-20" nzPlaceHolder="Chọn thời gian" formControlName="visitAgainAfter"
            (ngModelChange)="visitAgainAfterChange($event)">
            <nz-option *ngFor="let item of options" [nzLabel]="item.label" [nzValue]="item.value">
            </nz-option>
          </nz-select>
        </div>
      </div>
    </div>
  </div>

  <app-delivery-info [canEditPrescription]="isEditMode" [prescription]="prescription" [currentUserId]="currentUserId"
    [listMedicationTable]="listMedicationTable"
    [isUsePrescriptionDeliveryInfo]="configGroup.isUsePrescriptionDeliveryInfo"
    [generalInfo]="generalInfo"></app-delivery-info>

  <div class="thuoc-list-all" *ngIf="
      listPrescriptionsOfVisit.length > 1 ||
      (listPrescriptionsOfVisit.length == 1 && prescription == null)
    ">
    <div class="m-tab-title mg-bt-10">Các toa thuốc khác trong ca khám</div>
    <div style="width: 75%; margin: 0 auto">
      <app-prescription-list [visit]="visit" [listPrescription]="listPrescriptionsOfVisit"
        [isUseApprovePrescription]="configGroup.isUseApprovePrescription"
        [isUseDeliveryInfo]="configGroup.isUsePrescriptionDeliveryInfo" [isCanActionCurPrescription]="isEditMode"
        (copyData)="copyPrescription($event)" (prescriptionChange)="
          getPrescriptionOther($event)
        "></app-prescription-list>
    </div>
  </div>

  <div class="thuoc-list-all">
    <div class="m-tab-title mg-bt-10">Các toa thuốc khác của bệnh nhân</div>
    <app-prescription-list [visit]="visit" [listPrescription]="listPrescriptionOfPatient"
      [isUseApprovePrescription]="configGroup.isUseApprovePrescription"
      [isUseDeliveryInfo]="configGroup.isUsePrescriptionDeliveryInfo" [isCanActionCurPrescription]="isEditMode"
      (copyData)="copyPrescription($event)" (prescriptionChange)="
        gotoPrescriptionTab($event)
      "></app-prescription-list>
  </div>
</div>

<div style="display: none" *ngIf="prescription">
  <app-print-template [data]="listMedicationTable" [config]="templateDrugList" [prescription]="prescription"
    [visit]="visit" [printHeader]="printHeader"
    [isUseDeliveryInfo]="isUsePrescriptionDeliveryInfo"></app-print-template>
</div>

<!-- Menu tạo đơn thuốc mẫu từ đơn thuốc hiện tại -->
<nz-dropdown-menu #menu_action="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="openCreateSample()">
      Tạo toa thuốc mẫu từ toa hiện tại
    </li>
    <li nz-menu-item (click)="openCreateMorePrescription()" *ngIf="isCanCreateMore">
      Tạo toa thuốc mới
    </li>
  </ul>
</nz-dropdown-menu>
<div *ngIf="showSharing">
  <nz-modal [(nzVisible)]="showSharing" [nzFooter]="null" nzWidth="700px" nzTitle="Chia sẻ toa thuốc"
    (nzOnCancel)="handleCloseSharing()">
    <span style="font-weight: bold">Link chia sẻ toa thuốc:</span><br /><br />
    <a style="text-decoration: underline" target="_blank" href="{{ prescriptionSharingUrl }}">
      {{ prescriptionSharingUrl }}
    </a>
    <br />
    <div style="width: 100%; text-align: center; margin: 32px 16px">
      <button nz-button (click)="copyMessage()" title="Copy link">
        <i nz-icon nzType="link" nzTheme="outline"></i>
        Copy link
      </button>
    </div>
  </nz-modal>
</div>

<!-- Đơn thuốc mẫu -->
<app-prescription-sample [isVisible]="showSample" (closeModal)="closeModalSample()" [reload]="reloadGetSample"
  (sampleEmit)="getSampleData($event)"></app-prescription-sample>

<app-modal-create-sample [(isVisible)]="isVisibleCreateSample" [prescription]="prescription"
  [groupId]="groupId"></app-modal-create-sample>

<app-modal-query-aprroving [(isVisible)]="isVisiblePrescriptionApproving" [prescription]="prescription"
  [queryingAction]="queryingPrescriptionAction" (querySuccess)="queryAprrovingEvent()"></app-modal-query-aprroving>
