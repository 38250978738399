<div class="form-dropdown form-elm">
  <nz-select
    [nzMode]="_type"
    [nzPlaceHolder]="data.observationType.name"
    [(ngModel)]="data.valueSelections"
    [nzDisabled]="data.editType == 0 || data.editType == 3"
    (ngModelChange)="onModelChange($event)">
    <nz-option
      *ngFor="let item of data.observationType.dataSource"
      [nzLabel]="item.text"
      [nzValue]="item.id">
    </nz-option>
  </nz-select>
</div>
