import { Constant } from 'src/app/share/constants/constant.class';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import firebase from 'firebase';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ChatService } from 'src/app/services/chat.service';
import { TabDataService } from '../../../share/base-service/data-worklist.service';
import { UploadService } from 'src/app/services/upload.service';
import { AppConfigService } from 'src/app-config.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observer, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { DoctorService } from '../../../services/doctor.service';
import { ShareService } from '../../../services/share.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import {
  NzContextMenuService,
  NzDropdownMenuComponent,
} from 'ng-zorro-antd/dropdown';
import { VisitService } from 'src/app/services/visit.service';
declare var $: any;
export const snapshotToArray = (snapshot: any) => {
  const returnArr = [];

  snapshot.forEach((childSnapshot: any) => {
    const item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};
declare var $: any;

@Component({
  selector: 'app-chat-component',
  templateUrl: './chat-component.component.html',
  styleUrls: ['./chat-component.component.scss'],
})
export class ChatComponentComponent implements OnInit, OnDestroy {
  formSendMessage: FormGroup;
  curUser: any;
  chats: any[];
  cons: any = [];
  selectedConv: any;
  scrolltop: any;
  filterName = '';
  MAX_VAL = 100000000000;
  startTime: any = new Date();
  @ViewChild('chatWrapper') chatWrapper: ElementRef;
  currentConversationId = '';
  patientUserId = '';
  patientFullname = '';
  isUpload = false;
  isShowUpload = false;
  loading = false;
  isVisibleListImage = false;
  uploadUrl = '';
  uploadFileUrl = '';
  curImage: any = {
    src: '',
  };
  header?: any = {
    Authorization: 'Bearer ' + localStorage.getItem(Constant.TOKEN),
  };
  isVisibleMoreDoctor: boolean;
  doctorInGroup: any[];
  doctorInGroupFull: any[];
  groupSub: Subscription;
  selectedGroupId: any;

  lastChatId: any;
  firstChatId: any;

  firstImageId: any;
  lastImageId: any;

  firstFileId: any;
  lastFileId: any;

  modalScrollDistance = 2;
  modalScrollThrottle = 50;
  pageIndex = 1;
  selectedGroupName: any;
  textSearchDoctor: any;
  invitedDoctors: any[];

  arrCollapse: any[];
  configBaseUrl: any;
  isMobile: boolean;
  isUploadingFile: boolean;
  images: any[];
  files: any[];
  arrTab: any[];

  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;
  @Output() sendFileToMasterNoteEvent: EventEmitter<any> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private chatService: ChatService,
    public datepipe: DatePipe,
    private tabDataService: TabDataService,
    protected configService: AppConfigService,
    private uploadService: UploadService,
    private notificationService: NotificationService,
    private doctorService: DoctorService,
    private shareService: ShareService,
    private store: Store<AppState>,
    private nzContextMenuService: NzContextMenuService,
    private visitService: VisitService
  ) {
    this.configBaseUrl = this.configService.getConfig().media.baseUrlOnly;
    this.formSendMessage = this.fb.group({
      message: [null, [Validators.required]],
    });

    this.uploadUrl =
      this.configService.getConfig().api.baseUrl +
      '/api/Upload/uploadFile?TypeUpload=upload_visit_image&FileName=1145564.jpg';

    // "/Media/VisitDocument"
    this.uploadFileUrl =
      this.configService.getConfig().api.baseUrl +
      '/api/Upload/uploadFile?TypeUpload=upload_visit_document';
    this.store
      .select((store) => store.auth)
      .subscribe((res) => {
        this.curUser = res;
      });
  }
  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent, member): void {
    if (member.isMe || !(member.type === 'Patient')) {
      this.nzContextMenuService.create($event, null);
    } else {
      this.nzContextMenuService.create($event, menu);
    }
  }

  fileContextMenu(
    $event: MouseEvent,
    menu: NzDropdownMenuComponent,
    file
  ): void {
    this.nzContextMenuService.create($event, menu);
  }
  createImgFromSrc(src) {
    const node = document.createElement('IMG');
    node.classList.add('note-img');
    node.setAttribute('src', src);
    node.setAttribute('is', 'show-image');
    if (node.clientWidth > node.clientHeight) {
      node.classList.add('note-img-hor');
    } else {
      node.classList.add('note-img-ver');
    }
    return node;
  }
  createFileElement(file) {
    const link = document.createElement('a');
    link.download = file.message;
    link.target = '_blank';
    link.href = this.configService.getConfig().media.baseUrlOnly + file.src;
    link.innerHTML = file.message;
    link.style.textDecoration = 'underline';
    return link;
  }
  sendFileToMasterNote(file) {
    // console.log('sendFileToMasterNote', file);
    // console.log(this.selectedConv.info.member);
    const patient = this.selectedConv.info.member.find(
      (m) => m.type === 'Patient'
    );
    if (patient) {
      this.chatService.getNewestVisit(patient.patientId).subscribe((res) => {
        if (res.isValid) {
          const chat = {
            content: '',
            visitId: res.jsonData,
          };
          if (file.type == 'image') {
            chat.content += this.createImgFromSrc(file.src).outerHTML;
          } else {
            chat.content += this.createFileElement(file).outerHTML;
          }
          this.visitService.addVisitMasterNote(chat).subscribe(
            (resdata) => {
              this.sendFileToMasterNoteEvent.emit(chat.visitId);
              this.notificationService.showNotification(
                Constant.SUCCESS,
                'Copy vào Master Note thành công!'
              );
            },
            (error) => {
              this.notificationService.showNotification('', error.statusText);
            }
          );
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            res.errors[0].errorMessage
          );
        }
      });
    } else {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.WARRING,
        'Nhóm không có bệnh nhân!'
      );
    }
  }

  closeMenu(): void {
    this.nzContextMenuService.close();
  }
  convertMessageArray = (items: any[]) => {
    const returnArr = [];
    items = items.reverse();
    items.forEach((item: any) => {
      item.key = item.id;
      item.userId = item.userId;
      item.userName = item.userName;
      item.type = item.type;
      item.src = item.src;
      item.date = item.date; //this.datepipe.transform(new Date(item.date ), 'dd/MM/yyyy HH:mm:ss');
      item.message = item.message;
      returnArr.push(item);
    });
    return returnArr;
  };
  ngOnDestroy(): void {
    const ref = firebase.database().ref('new-conversations');
    ref.off();
  }

  ngOnInit(): void {
    // @ts-ignore
    this.isMobile = window.innerWidth < 540;
    if (this.isMobile) {
      firebase.initializeApp({
        apiKey: 'AIzaSyA4bw4dlkjRJXnMOEFJ2-thIjVRJqfdPoA',
        authDomain: 'doctor-pmr.firebaseapp.com',
        databaseURL: 'https://doctor-pmr.firebaseio.com',
        projectId: 'doctor-pmr',
        storageBucket: 'doctor-pmr.appspot.com',
        messagingSenderId: '934906157901',
        appId: '1:934906157901:web:c30decc1a7e2e5641ab504',
      });
      this.arrTab = [true, false, false];
    } else {
      this.arrTab = [true, true, true];
    }
    this.arrCollapse = [true, true, true];
    this.selectedGroupName = '';
    this.selectedConv = { id: '' };
    //this.curUser = JSON.parse(localStorage.getItem(Constant.USER_INFO));
    this.tabDataService.share.subscribe((tabss) => {
      const tabs = this.tabDataService.tabs();
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i];
        if (tab.id == 'chat') {
          this.patientUserId = tab.patientId;
          this.patientFullname = tab.tabType; //trick: map fullname into tabType
          break;
        }
      }
    });
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        if (!this.shareService.checkEmpty(data)) {
          this.selectedGroupId = data.groupId;
          //this.reloadDataTable();
          this.getDoctorInGroup(data.groupId);
        }
      }
    });

    firebase
      .database()
      .ref(`user-conversations/${this.curUser.userId}`)
      .on('value', (resp2: any) => {
        const arr = snapshotToArray(resp2);
        this.getMyConversations();
        /*if (arr.length > 0){
        this.getMyConversations();
      }*/
      });
    //this.getMyConversations();
  }
  getDoctorInGroup(groupId) {
    this.doctorInGroup = [];
    this.doctorInGroupFull = [];
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        res.forEach((en) => {
          this.doctorInGroupFull.push({
            label: en.fullName,
            value: en.id,
            checked: false,
            disabled: false,
            unsignLabel: this.shareService.toUnsign(en.fullName),
          });
        });
      }
    });
  }
  getMyConversations() {
    // alert(this.patientUserId);
    this.currentConversationId = null;
    this.chatService.getConversations().subscribe((res) => {
      // console.log(res.jsonData);
      new Promise((resolve, reject) => {
        res.jsonData.forEach((element) => {
          element.unsignName = this.shareService.toUnsign(element.name);
          this.cons.push(element);
        });
        resolve(true);
      }).then((res) => {
        if (this.cons.length) {
          this.selectedConv = this.cons[0];
          if (this.patientUserId != '') {
            const cons2 = this.cons.find(
              (x) => x.patientUserId === this.patientUserId
            );
            if (cons2) {
              this.selectedConv = cons2;
            }
          }
          this.openConv(this.selectedConv, false);
        }
      });
    });
  }

  openConv(conv: any, userClicked: boolean): void {
    if (this.isMobile) {
      if (userClicked) {
        this.openTab(1);
      }
    }
    if (!userClicked && this.currentConversationId) {
      return;
    }
    this.patientUserId = null;
    this.selectedGroupName = conv.name;
    this.lastChatId = null;
    this.firstChatId = null;
    this.lastImageId = null;

    this.selectedConv = conv;
    this.startTime = new Date();
    if (conv) {
      conv.newMessage = 0;
      this.chatService
        .seenMessage(this.currentConversationId)
        .subscribe((res) => {
          // console.log(res);
        });
      this.chatService
        .getChats(this.currentConversationId, 0, 10, null, null)
        .subscribe((res) => {
          // console.log(res);
          this.lastChatId = res.jsonData.data[0].id;
          this.firstChatId = res.jsonData.data[res.jsonData.data.length - 1].id;
          this.chats = res.jsonData.data.reverse();
          setTimeout(() => this.scrollToElement(), 300);
        });
      // firebase.database().ref('chats/' + this.currentConversationId).off();
      this.currentConversationId = conv.id;
      /**/
      this.bindConversationInfo();
      firebase
        .database()
        .ref('new-conversations')
        .orderByChild('convId')
        .equalTo(conv.id)
        .once('value', (snapshot: any) => {
          if (!snapshot.exists()) {
            const newRoom = firebase.database().ref('new-conversations').push();
            const obj = {
              convId: conv.id,
              date: this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ssZ'),
              time: 100000000000 - new Date().getTime(),
              lastMessage: 'Xin chào!',
            };
            newRoom.set(obj);
          }
        });
      if (!this.selectedConv.hasRef) {
        const ref = firebase.database().ref('new-conversations');
        //ref.off();
        this.selectedConv.hasRef = true;
        firebase
          .database()
          .ref('new-conversations/')
          .orderByChild('convId')
          .equalTo(conv.id)
          .on('value', (resp2: any) => {
            const arr = snapshotToArray(resp2);
            if (arr.length > 0) {
              const newMessage = arr[0];
              const convUpdate = this.cons.find(
                (en) => en.id === newMessage.convId
              );
              if (convUpdate) {
                convUpdate.lastSenderId = newMessage.senderId;
                convUpdate.lastSendAt = newMessage.date;
                convUpdate.lastMessageType = newMessage.chatType;
                convUpdate.lastMessage = newMessage.lastMessage;
              }

              // //List đang được filter
              // const convUpdateFilter = this.conversationFilter.find(en => en.id === newMessage.convId);
              // if (convUpdateFilter) {
              //   convUpdateFilter.lastSenderId = newMessage.senderId;
              //   convUpdateFilter.lastSendAt = newMessage.date;
              //   convUpdateFilter.lastMessageType = newMessage.chatType;
              //   convUpdateFilter.lastMessage = newMessage.lastMessage;
              // }
              if (
                newMessage.chatType === 'image' ||
                newMessage.chatType === 'file'
              ) {
                //reload image list
                this.bindImageFile(newMessage.chatType);
              }
              this.getChats();
            }
          });
      } else {
        this.chatService
          .getChats(this.currentConversationId, 0, 10, null, null)
          .subscribe((res) => {
            // console.log(res);
            this.lastChatId = res.jsonData.data[0].id;
            this.firstChatId =
              res.jsonData.data[res.jsonData.data.length - 1].id;
            this.chats = res.jsonData.data.reverse();
            setTimeout(() => this.scrollToElement(), 300);
          });
      }
    }
  }
  bindConversationInfo() {
    this.selectedConv.info = null;
    this.chatService
      .getConversationById(this.currentConversationId)
      .subscribe((res) => {
        if (res.isValid) {
          this.selectedConv.info = res.jsonData;
          const adminIds = this.selectedConv.info.admin;
          this.selectedConv.info.member.forEach((mem) => {
            mem.isAdmin = adminIds.includes(mem.id);
            if (mem.id === this.curUser.userId) {
              //Bạn là admin
              //TH set admin cho nguoi khac
              if (mem.isAdmin) {
                this.selectedConv.hasRoleAdmin = true;
              } else {
                this.selectedConv.hasRoleAdmin = false;
              }
            }
            mem.isMe = this.curUser.userId === mem.id;
            mem.otherAdmin = !mem.isMe && mem.isAdmin;
          });
        }
      });
    this.bindImageFile('image');
    this.bindImageFile('file');
  }

  bindImageFile(type: string) {
    if (!this.lastImageId) {
      this.chatService
        .getCoversationContentByType(
          this.currentConversationId,
          0,
          100,
          null,
          null,
          type
        )
        .subscribe((res) => {
          if (res.isValid) {
            if (type === 'image') {
              this.lastImageId = res.jsonData.data.length
                ? res.jsonData.data[0].id
                : null;
              this.images = res.jsonData.data;
            } else if (type === 'file') {
              this.lastFileId = res.jsonData.data.length
                ? res.jsonData.data[0].id
                : null;
              this.files = res.jsonData.data;
            }
          }
        });
    } else {
      this.chatService
        .getCoversationContentByType(
          this.currentConversationId,
          0,
          10,
          type === 'image' ? this.lastImageId : this.lastFileId,
          'asc',
          type
        )
        .subscribe((res) => {
          if (res.isValid && res.jsonData.data.length) {
            if (type === 'image') {
              this.lastImageId = res.jsonData.data[0].id;
              this.images = res.jsonData.data.concat(this.images);
            } else if (type === 'file') {
              this.lastFileId = res.jsonData.data[0].id;
              this.files = res.jsonData.data.concat(this.files);
            }
          }
        });
    }
  }

  sendChatOnly(): void {
    const chatContent = this.formSendMessage.value.message;
    this.sendChat(chatContent, 'message', '');
  }

  sendChat(chatContent, type, src): void {
    if (!this.selectedConv) {
      return;
    }
    // const chatContent = this.formSendMessage.value.message;
    if (chatContent === '') {
      alert('Chưa nhập nội dung chat');
      return;
    }
    const chatServer = {
      channelId: this.selectedConv.id,
      type,
      message: chatContent,
      src,
      taggedUserIds: '',
    };
    if (type === 'message') {
      //display temp
      this.appendPreviewMessage(chatContent);
    }
    this.chatService.sendChat(chatServer).subscribe((res) => {
      // this.openConv(this.selectedConv);
      // this.getChats();
      // ref to firebase
      firebase
        .database()
        .ref('new-conversations')
        .orderByChild('convId')
        .equalTo(this.selectedConv.id)
        .once('value', (snapshot: any) => {
          if (snapshot.exists()) {
            const key = Object.keys(snapshot.val())[0];
            const conRef = firebase
              .database()
              .ref('new-conversations')
              .child(key);
            conRef.once('value', (resp: any) => {
              resp.ref.update({
                time: 100000000000 - new Date().getTime(),
                date: this.datepipe.transform(
                  new Date(),
                  'yyyy-MM-ddTHH:mm:ssZ'
                ),
                lastMessage: chatContent,
                senderId: this.curUser.userId,
                senderName: this.curUser.fullName,
                chatType: type,
              });
            });
          }
        });
    });

    // clear chat box
    this.formSendMessage.patchValue({
      message: '',
    });

    /*const newMessage = firebase.database().ref('chats/' + this.selectedConv.id).push();
    newMessage.set(chat);
    this.formSendMessage.patchValue({
      message : ''
    });
    const key = this.selectedConv.key;
    const conRef = firebase.database().ref('conversations').child(key);

    let lastMessage = '';
    switch(type){
      case 'message':
        lastMessage = chatContent;
        break;
      case 'image':
        lastMessage = 'Đã gửi 1 ảnh';
        break;
      case 'file':
        lastMessage = 'Đã gửi 1 file';
        break;
      default:
        ''
    }

    conRef.once('value', (resp: any) => {
      resp.ref.update({
        time: this.MAX_VAL - new Date().getTime(),
        date: this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ssZ'),
        chatFromUserId: this.curUser.userId,
        lastMessage: lastMessage,
        read: 0
      });
    });*/
  }
  getChats() {
    if (!this.lastChatId) {
      //lấy 10 bản ghi đầu tiên
      this.chatService
        .getChats(this.currentConversationId, 0, 10, null, null)
        .subscribe((res) => {
          this.lastChatId = res.jsonData.data[0].id;
          this.firstChatId = res.jsonData.data[res.jsonData.data.length - 1].id;
          this.chats = res.jsonData.data.reverse();
          setTimeout(() => this.scrollToElement(), 300);
        });
    } else {
      //chỉ lấy bản ghi mới nhất
      this.chatService
        .getChats(this.currentConversationId, 0, 20, this.lastChatId, null)
        .subscribe((res) => {
          if (res.jsonData.data.length) {
            this.lastChatId = res.jsonData.data[0].id;
            const tempLength = this.chats.filter((en) => en.isTemp).length;
            if (res.jsonData.data.length > tempLength) {
              // alert('vao day ko');
              this.chats = this.chats.filter((en) => !en.isTemp);
              res.jsonData.data.reverse().forEach((chat) => {
                this.chats.push(chat);
              });
              setTimeout(() => this.scrollToElement(), 300);
            } else {
              this.chats
                .filter((en) => en.isTemp)
                .forEach((en) => {
                  delete en.isTemp;
                });
            }
          }
        });
    }
  }
  readConv() {
    const key = this.selectedConv.key;
    const conRef = firebase.database().ref('conversations').child(key);
    conRef.once('value', (resp: any) => {
      const snapshot = resp.val();
      if (snapshot.read === 0 && snapshot.chatToUserId === this.curUser.id) {
        resp.ref.update({
          read: 1,
        });
      }
    });
  }

  shortName(name): any {
    return this.getInitials(name, ' ');
  }
  getInitials(name, delimeter): string {
    if (name) {
      const array = name.split(delimeter);
      switch (array.length) {
        case 1:
          return array[0].charAt(0).toUpperCase();
          break;
        default:
          return (
            array[0].charAt(0).toUpperCase() +
            array[array.length - 1].charAt(0).toUpperCase()
          );
      }
    }
    return '';
  }
  scrollToElement(): void {
    $(this.chatWrapper.nativeElement).animate(
      { scrollTop: this.chatWrapper.nativeElement.scrollHeight },
      0
    );
  }
  scrollTop(): void {
    this.scrolltop = this.chatWrapper.nativeElement.scrollHeight;
  }

  toConversationId(id1: any, id2: any): any {
    return 'c' + (id1 < id2 ? id1 + '_' + id2 : id2 + '_' + id1);
  }

  getFriendName(conv: any) {
    if (conv.fromUserId !== this.curUser.userId) {
      return conv.fromUserName;
    } else {
      return conv.toUserName;
    }
  }

  getMyName() {
    if (this.curUser.fullName != null && this.curUser.fullName != '') {
      return this.curUser.fullName;
    } else if (
      this.curUser.firstName != null &&
      this.curUser.lastName != null
    ) {
      return this.curUser.firstName + ' ' + this.curUser.lastName;
    } else {
      return this.curUser.userName;
    }
  }

  createImageEle(res) {
    const $this = this;
    const img = new Image();
    const node = document.createElement('IMG');
    const src =
      this.configService.getConfig().media.baseUrl +
      'Media/VisitImage/' +
      res.fileName;

    img.addEventListener('load', function () {
      if (this.naturalWidth > this.naturalHeight) {
        node.classList.add('note-img-hor');
      } else {
        node.classList.add('note-img-ver');
      }
      node.setAttribute('src', src);
      node.setAttribute('is', 'show-image');

      $this.isUpload = false;
      $this.sendImage(node.outerHTML, src);
    });
    img.src = src;
  }

  dropFileToEdit(data): void {
    const $this = this;
    try {
      const node = document.createElement('IMG');

      this.uploadService.saveFile(data).subscribe(
        (resdata) => {
          $this.createImageEle(resdata);
        },
        (error) => {
          this.notificationService.showNotification('', error.statusText);
          this.isUpload = false;
        }
      );
    } catch (error) {
      console.error(error);
      this.isUpload = false;
    }
  }

  onFileDropped($event) {
    for (let i = 0; i < $event.length; i++) {
      this.isUpload = true;
      this.dropFileToEdit($event[i]);
    }
  }

  onPaste(event: ClipboardEvent) {
    const clipboardItems = event.clipboardData.items;
    const items = [].slice.call(clipboardItems).filter(function (item) {
      // Filter the image items only
      return item.type.indexOf('image') !== -1;
    });
    if (items.length === 0) {
      return;
    }

    const item = items[0];

    if (item.type.indexOf('image') === 0) {
      this.isUpload = true;
      const blob = item.getAsFile();
      this.uploadFileDirective(blob);

      event.preventDefault();
    }
  }

  uploadFileDirective(data): void {
    try {
      const fileName = 'clipboard.png';
      const formData: FormData = new FormData();
      formData.append('FormFile', data, fileName);
      formData.append('folder', this.selectedConv.id);

      this.uploadService.uploadMediaFile(formData).subscribe(
        (resdata) => {
          this.initImage(fileName, resdata.fileName);
        },
        (error) => {
          this.notificationService.showNotification('', error.statusText);
          this.isUpload = false;
        }
      );
    } catch (error) {
      console.error(error);
      this.isUpload = false;
    }
  }

  sendImage(content, src): void {
    new Promise((resolve, reject) => {
      this.sendChat(content, 'image', src);
    }).finally(() => {
      $('#hide-image-div').html('');
    });
  }

  handleOk(): void {
    // console.log('Button ok clicked!');
    this.isShowUpload = false;
  }

  handleCancel(): void {
    // console.log('Button cancel clicked!');
    this.isShowUpload = false;
  }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        observer.complete();
        return;
      }
      const isLt10M = (file.size ?? 0) / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Kích thước ảnh phải nhỏ hơn 10MB!'
        );
        observer.complete();
        return;
      }
      this.appendPreviewImage(file);
      observer.next(isJpgOrPng && isLt10M);
      observer.complete();
    });
  appendPreviewMessage(message) {
    const chatPreview = {
      date: new Date(),
      type: 'image',
      userAvatar: null,
      userId: this.curUser.userId,
      isTemp: true,
      guid: new Date().getUTCMilliseconds(),
      message,
    };
    this.chats.push(chatPreview);
    setTimeout(() => this.scrollToElement(), 0);
  }
  appendPreviewImage(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      const source = '' + reader.result;
      const image = new Image();
      image.src = source;
      const $this = this;
      image.onload = function () {
        // access image size here
        //alert(image.width + '_' + image.height);
        let height = image.height;
        let width = image.width;
        if (height > 250) {
          width = (250 * width) / height;
          height = 250;
        }
        const chatPreview = {
          date: new Date(),
          id: file.uid,
          src: source,
          type: 'image',
          userAvatar: null,
          userId: $this.curUser.userId,
          isTemp: true,
          width,
          height,
          guid: file.uid,
        };
        $this.chats.push(chatPreview);
        setTimeout(() => $this.scrollToElement(), 0);
      };
    };
  }
  beforeUploadFile = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isValid =
        file.type === 'application/msword' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/pdf' ||
        this.isVideo(file.name);
      if (!isValid) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'File hỗ trợ: pdf, doc, docx, xls, xlsx, video'
        );
        observer.complete();
        return;
      }
      const isLt10M = (file.size ?? 0) / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Kích thước file phải nhỏ hơn 10MB!'
        );
        observer.complete();
        return;
      }
      observer.next(isValid && isLt10M);
      observer.complete();
    });

  cancelSlideShow(): void {
    this.isVisibleListImage = false;
  }

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        // this.avatarUrl = info.file.thumbUrl;
        // console.log(info.file.response['fileName']);
        // this.initImage(info.file.response["fileName"]);

        break;
      case 'error':
        this.loading = false;
        break;
    }
  }

  fileChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.isUploadingFile = true;
        break;
      case 'done':
        // this.listImgs.push(info.file);
        // this.listImgsUrl.push(info.file.response['fileName']);
        // this.formAddPatient.controls['imageUrls'].setValue(this.listImgsUrl);
        // this.initFile(info.file.response["fileNameOnly"], info.file.response["fileName"]);
        this.isUploadingFile = false;
        break;
      case 'error':
        this.isUploadingFile = false;
        break;
    }
  }

  initImage(fileName, filePath) {
    if (fileName) {
      this.sendChat(fileName, 'image', filePath);
    }
  }

  initFile(fileName, filePath, isVideo) {
    if (fileName) {
      let type = 'file';
      if (isVideo) {
        type = 'video';
      }
      this.sendChat(fileName, type, filePath);
    }
  }

  showImageEvent(data) {
    this.curImage = data;
    this.isVisibleListImage = true;
  }

  handleUploadFile = async (item: any) => {
    if (this.selectedConv.id == '') {
      return;
    }
    const formData = new FormData();
    formData.append('FormFile', item.file as any);
    formData.append('folder', this.selectedConv.id);
    const isVideo = await this.isVideo(item.file.name);
    this.uploadService.uploadMediaFile(formData).subscribe(
      (res) => {
        if (res.uploaded) {
          this.initFile(res.oldFileName, res.fileName, isVideo);
        }
        this.isUploadingFile = false;
      },
      (error) => {
        this.isUploadingFile = false;
        console.log(error);
        // this.notificationService.showNotification(Constant.ERROR, error.message.toString());
      }
    );
  };

  handleUploadImage = (item: any) => {
    if (this.selectedConv.id == '') {
      return;
    }
    const formData = new FormData();
    formData.append('FormFile', item.file as any);
    formData.append('folder', this.selectedConv.id);

    this.uploadService.uploadMediaFile(formData).subscribe(
      (res) => {
        if (res.uploaded) {
          setTimeout(() => {
            this.initImage(res.oldFileName, res.fileName);
            const chatTemp = this.chats.find((en) => en.id === item.file.uid);
            if (chatTemp) {
              // delete chatTemp.isTemp;
              chatTemp.src = this.configBaseUrl + res.fileName;
            }
          }, 0);
        }
      },
      (error) => {
        console.log(error);
        // this.notificationService.showNotification(Constant.ERROR, error.message.toString());
      }
    );
  };

  addMoreDoctor() {
    this.isVisibleMoreDoctor = true;
    const doctorInGroupIds = [];
    this.invitedDoctors = [];
    this.selectedConv.info.member.forEach((mem) => {
      doctorInGroupIds.push(mem.id);
    });
    this.doctorInGroupFull.forEach((en) => {
      if (doctorInGroupIds.includes(en.value)) {
        en.disabled = true;
        en.checked = true;
      } else {
        en.checked = false;
      }
    });
    this.doctorInGroup = this.doctorInGroupFull.filter((en) => 1 === 1);
  }
  cancelAddDoctor() {
    this.isVisibleMoreDoctor = false;
  }

  doctorChecked($event: any[]) {
    this.inputElement.nativeElement.focus();
    this.textSearchDoctor = '';
    this.searchDoctor('');
    const uncheckIds = [];
    $event.forEach((en) => {
      if (!en.disabled && en.checked) {
        if (!this.invitedDoctors.includes(en)) {
          this.invitedDoctors.push(en);
        }
      } else if (!en.checked) {
        uncheckIds.push(en.value);
      }
    });
    this.invitedDoctors = this.invitedDoctors.filter(
      (en) => !uncheckIds.includes(en.value)
    );
    //this.invitedDoctors();
  }

  inviteDoctor() {
    const doctorIds = [];
    this.invitedDoctors.forEach((en) => {
      if (en.checked && !en.disabled) {
        doctorIds.push(en.value);
      }
    });
    this.chatService
      .addMember(this.currentConversationId, doctorIds)
      .subscribe((res) => {
        if (res.isValid) {
          doctorIds.forEach((id) => {
            this.refDoctorConversation(id, this.currentConversationId);
          });
          this.isVisibleMoreDoctor = false;
          this.notificationService.showNotification(
            Constant.SUCCESS,
            'Thêm thành viên thành công'
          );
          this.bindConversationInfo();
        } else if (res.errors) {
          this.notificationService.showNotification(
            Constant.ERROR,
            res.errors[0].errorMessage
          );
        }
      });
  }

  removeMember(memberId: any, memberName: any) {
    const c = confirm(
      `Bạn có chắc muốn mời thành viên ${memberName} ra khỏi nhóm không?`
    );
    if (!c) {
      return;
    }
    this.chatService
      .removeMember(this.currentConversationId, memberId)
      .subscribe((res) => {
        if (res.isValid) {
          this.isVisibleMoreDoctor = false;
          this.notificationService.showNotification(
            Constant.SUCCESS,
            'Mời thành viên khỏi nhóm thành công'
          );
        }
        this.bindConversationInfo();
        this.refDoctorConversation(memberId, this.currentConversationId);
      });
  }
  leaveGroup(memberId) {
    const c = confirm(`Bạn có chắc muốn rời khỏi nhóm không?`);
    if (!c) {
      return;
    }
    this.chatService.leaveGroup(this.currentConversationId).subscribe((res) => {
      if (res.isValid) {
        this.isVisibleMoreDoctor = false;
        this.notificationService.showNotification(
          Constant.SUCCESS,
          'Bạn đã rời khỏi nhóm thành công'
        );
        //this.refDoctorConversation(this.curUser.userId, this.currentConversationId);
        this.getMyConversations();
      }
      //this.bindConversationInfo();
    });
  }

  promoteAdmin(memberId: any, memberName: any) {
    const c = confirm(
      `Bạn có chắc muốn mời thành viên ${memberName} làm quản trị nhóm không?`
    );
    if (!c) {
      return;
    }
    this.chatService
      .promoteAdmin(this.currentConversationId, memberId)
      .subscribe((res) => {
        if (res.isValid) {
          this.isVisibleMoreDoctor = false;
          this.notificationService.showNotification(
            Constant.SUCCESS,
            'Mời thành viên làm admin thành công'
          );
        }
        this.bindConversationInfo();
      });
  }

  onScrollUp() {
    this.chatService
      .getChats(this.currentConversationId, 0, 10, this.firstChatId, 'desc')
      .subscribe((res) => {
        if (res.jsonData.data.length) {
          this.firstChatId = res.jsonData.data[res.jsonData.data.length - 1].id;

          const oldChats = res.jsonData.data.reverse();
          this.chats.forEach((chat) => {
            oldChats.push(chat);
          });
          this.chats = oldChats;
        }
      });
  }
  isVisibleUserMenu(member: any) {
    if (!member.isMe && !this.selectedConv.hasRoleAdmin) {
      return false;
    }
    // !member.otherAdmin && !(member.isAdmin && member.isMe && selectedConv.info.admin.length === 1)
    const adminRole =
      !member.otherAdmin &&
      !(
        member.isAdmin &&
        member.isMe &&
        this.selectedConv.info.admin.length === 1
      );
    return adminRole;
  }
  refDoctorConversation(doctorId, convId) {
    firebase
      .database()
      .ref(`user-conversations/${doctorId}`)
      .orderByChild('convId')
      .equalTo(convId)
      .once('value', (snapshot: any) => {
        if (!snapshot.exists()) {
          const newRoom = firebase
            .database()
            .ref(`user-conversations/${doctorId}`)
            .push();
          const obj = {
            convId,
            date: this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ssZ'),
            time: 100000000000 - new Date().getTime(),
            lastMessage: 'Just created',
          };
          newRoom.set(obj);
        } else {
          const key = Object.keys(snapshot.val())[0];
          const conRef = firebase
            .database()
            .ref(`user-conversations/${doctorId}`)
            .child(key);
          conRef.once('value', (resp: any) => {
            resp.ref.update({
              convId,
              date: this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ssZ'),
              time: 100000000000 - new Date().getTime(),
              lastMessage: 'Just updated',
            });
          });
        }
      });
  }

  chatPrivate(member: any) {
    if (member && member.id) {
      this.chatService.createPrivateChat(member.patientId).subscribe((res) => {
        this.patientUserId = member.id;
        this.refDoctorConversation(this.curUser.userId, res.jsonData.id);
      });
    } else {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.WARRING,
        'Bệnh nhân chưa kích hoạt tài khoản nên chưa thể chat với BN!'
      );
    }
  }
  openVisit(member: any) {
    this.chatService.getNewestVisit(member.patientId).subscribe((res) => {
      if (res.isValid) {
        const visitId = res.jsonData;
        this.tabDataService.updateTab(
          visitId,
          member.fullname,
          'VisitDetail',
          member.patientId
        );
      }
    });
  }

  searchDoctor($event: any) {
    const val = $event.toUpperCase();
    this.doctorInGroup = this.doctorInGroupFull.filter((en) =>
      en.unsignLabel.includes(val)
    );
  }

  handleClose(removedTag: any): void {
    this.doctorInGroup = this.doctorInGroup.filter(
      (en) => en.value !== removedTag.value
    );
    // this.tags = this.tags.filter(tag => tag !== removedTag);
  }

  toggleCollapse(index: number) {
    this.arrCollapse[index] = !this.arrCollapse[index];
  }
  compareDate(date1: any, date2: any) {
    date1 = new Date(date1);
    date2 = new Date(date2);
    if (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    ) {
      return true;
    }
  }

  addEmoji($event: any) {
    // console.log($event);
  }
  openTab(index: number): void {
    for (let i = 0; i < this.arrTab.length; i++) {
      this.arrTab[i] = false;
    }
    this.arrTab[index] = true;
  }

  back() {
    if (this.arrTab[1]) {
      this.openTab(0);
    } else {
      this.openTab(1);
    }
  }

  showMedia() {
    this.openTab(2);
  }

  isUrlValid(userInput) {
    const res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) {
      return false;
    } else {
      return true;
    }
  }
  isVideo(userInput) {
    // console.log(userInput);
    const res = userInput.match(
      '(?:(?!.(?:mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg))[^/])*.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)'
    );
    if (res == null) {
      return false;
    } else {
      return true;
    }
  }
}
