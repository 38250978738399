import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FilterItem } from '../../../../models/filter-model/filter-item.class';
import { WorklistColumn } from '../../../../models/worklist-column';
import { SearchListPatient } from '../../../../models/list-patient.class';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TabDataService } from '../../../../share/base-service/data-worklist.service';
import { AppConfigService } from '../../../../../app-config.service';
import { ListVisitService } from '../../../../services/listVisit-service';
import { NotificationService } from '../../../../services/notification.service';
import { ShareService } from '../../../../services/share.service';
import { CustomColumnService } from '../../../../services/custom-cloumn.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state/app-state';
import { PatientService } from '../../../../services/patient.service';
import { DoctorService } from '../../../../services/doctor.service';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { Constant } from '../../../../share/constants/constant.class';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzResizeEvent } from 'ng-zorro-antd/resizable';
import { Router } from '@angular/router';
const defaultFilters: FilterItem[] = [
  {
    field: 'patientName',
    label: 'Tên bệnh nhân',
    type: Constant.FILTER_TYPE.INPUT,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: true,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'coordinatorId',
    label: 'Điều phối viên',
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: '',
    searchable: true,
    clearable: true,
    isUsed: true,
    keyField: 'fullName',
    valueField: 'id',
    options: [],
  },
  {
    field: 'doctorInCareDoctId',
    label: 'BS phụ trách',
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: true,
    keyField: 'fullName',
    valueField: 'id',
    options: [],
  },
  {
    field: 'pharmacistId',
    label: 'Dược sĩ lâm sàng',
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: 'fullName',
    valueField: 'id',
    options: [],
  },
  {
    field: 'psychiatristId',
    label: 'Chuyên viên tâm lý',
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: 'fullName',
    valueField: 'id',
    options: [],
  },
  {
    field: 'nursingInCareId',
    label: 'Điều dưỡng phụ trách',
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: 'fullName',
    valueField: 'id',
    options: [],
  },
  {
    field: 'volunteerId',
    label: 'Tình nguyện viên',
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: true,
    keyField: 'fullName',
    valueField: 'id',
    options: [],
  },
  {
    field: 'endTreatment',
    label: 'Trạng thái điều trị',
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: 1,
    searchable: false,
    clearable: false,
    isUsed: true,
    keyField: 'label',
    valueField: 'value',
    options: [
      {
        value: 10,
        label: 'Tất cả',
      },
      {
        value: 1,
        label: 'Đang điều trị',
      },
      {
        value: 2,
        label: 'Kết thúc điều trị',
      },
    ],
  },
  {
    field: 'newPrescription',
    label: 'Đơn thuốc mới',
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: 10,
    searchable: false,
    clearable: false,
    isUsed: false,
    keyField: 'label',
    valueField: 'value',
    options: [
      {
        value: 10,
        label: 'Tất cả',
      },
      {
        value: 1,
        label: 'Có đơn mới',
      },
      {
        value: 2,
        label: 'Không có đơn mới',
      },
    ],
  },
  {
    field: 'reported',
    label: 'Trạng thái report',
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: 10,
    searchable: false,
    clearable: false,
    isUsed: false,
    keyField: 'label',
    valueField: 'value',
    options: [
      {
        value: 10,
        label: 'Tất cả',
      },
      {
        value: 1,
        label: 'Đã report',
      },
      {
        value: 2,
        label: 'Chưa report',
      },
    ],
  },
  {
    field: 'isVisited',
    label: 'Đã được khám',
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: 10,
    searchable: false,
    clearable: false,
    isUsed: false,
    keyField: 'label',
    valueField: 'value',
    options: [
      {
        value: 10,
        label: 'Tất cả',
      },
      {
        value: 1,
        label: 'Đã khám',
      },
      {
        value: 2,
        label: 'Chưa khám',
      },
    ],
  },
];
@Component({
  selector: 'app-patient-mobile-list',
  templateUrl: './patient-mobile-list.component.html',
  styleUrls: ['./patient-mobile-list.component.scss'],
})
export class PatientMobileListComponent implements OnInit, OnDestroy, AfterViewInit {
  currentFilters: FilterItem[] = [];
  defaultConfig: WorklistColumn[] = [];
  otCols = [];
  lstPatient;
  resVisit;
  selectedGroupId = '';
  localStorageName = 'worklist-column';
  search: SearchListPatient = {
    keyword: '',
    patientName: '',
    address: '',
    phone: '',
    patientCode: '',
    doctorName: '',
    from: '',
    to: '',
    inGroup: true,
    pageSize: 50,
    page: 1,
    newId: '',
    groupId: '',
    roomId: '',
    doctorInCareDoctId: null,
    nursingInCareId: null,
    sosRange: 0,
    psychiatristId: null,
    pharmacistId: null,
    coordinatorId: null,
    volunteerId: null,
    endTreatment: 1,
    newPrescription: 10,
    reported: 10,
    isVisited: 10,
    districtName: '',
    wardName: '',
    gender: null,
    minAge: null,
    maxAge: null,
    dateNoMin: null,
    dateNoMax: null,
    vaccinated: 99,
    hasDiseases: 99,
    receptionDate: null,
    oTSearchFields: [],
  };
  isVisibleDuplicatePatient;
  dataTooltip = {
    id: null,
    patientName: null,
    genderText: null,
    dob: null,
    age: null,
    email: null,
    phoneNo: null,
    address: null,
    doctorName: null,
    patientCode: null,
    visitdate: null,
    visitTypeText: null,
    color: null,
    shortName: null,
    avatar: null,
  };
  baseAvatarurl = '';
  scrollX = '1100px';
  scrollY: string = window.innerHeight - 365 + 'px';
  pageIndex = 1;
  pageSize = 15;
  total = 100;
  loading = false;
  resetFilter = false;
  doctorInGroup: any[] = [];

  listDoctorInCase: any[] = [];
  listCoordinator: any[] = [];
  listPharmacist: any[] = [];
  listPsychiatrist: any[] = [];
  listNursingInCase: any[] = [];

  checkColumnWidth = '60px';
  resizing = false;
  isCustomColumnVisible = false;

  showCols: WorklistColumn[] = [];
  hideCols: WorklistColumn[] = [];
  groupSub: Subscription;

  currentUserId = '';
  userPermissionInGroup: number[] = [];
  intervalReloadGrid: any;
  reloadTime = 120000;
  isCoordinator = false;
  isFilterEndTreatment = false;

  isVisibleEditStatus = false;
  formEditStatus: FormGroup;
  initialEditStatus: any;
  currentPatientId = '';
  titlePopup = '';
  //viewEndTreatment: number = 1;
  isVisibleFilter: boolean;
  isVisibleDetail = false;
  currentDetail: any;
  today: Date = new Date();

  constructor(
    private tabDataService: TabDataService,
    private configService: AppConfigService,
    private listVisitService: ListVisitService,
    private notificationService: NotificationService,
    private shareService: ShareService,
    private customColumnService: CustomColumnService,
    private store: Store<AppState>,
    private patientService: PatientService,
    private doctorService: DoctorService,
    private nzContextMenuService: NzContextMenuService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.currentUserId = JSON.parse(localStorage.getItem(Constant.USER_INFO)).userId;
    this.formEditStatus = this.fb.group({
      isEndTreatmented: [true],
      typeEndTreatmented: [0],
      dateEndTreatmented: [null],
    });
    this.initialEditStatus = this.formEditStatus.value;
    this.reloadTime = this.configService.getConfig().reloadTimePatient
      ? this.configService.getConfig().reloadTimePatient
      : 120000;
    this.currentFilters = JSON.parse(JSON.stringify(defaultFilters));
  }
  get f() {
    return this.formEditStatus.controls;
  }
  ngOnInit(): void {
    this.baseAvatarurl = this.configService.getConfig().media.baseUrl + '/Avatar/';

    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        if (!this.shareService.checkEmpty(data)) {
          this.selectedGroupId = data.groupId;
          this.getDoctorInGroup(this.selectedGroupId);
        }
        this.userPermissionInGroup =
          data.doctorPermission == null ? [] : data.doctorPermission.slice().sort((a, b) => a - b);
        //this.userPermissionInGroup = this.userPermissionInGroup.slice().sort((a, b)=> (a-b));
        this.isCoordinator = this.userPermissionInGroup.some((en) => en == Constant.DOCTORPERMISSION.Coordinator);

        if (data.isLoadByPermission) {
          //lấy role đầu tiên để search
          const permis = Math.min(...this.userPermissionInGroup);
          if (permis == Constant.DOCTORPERMISSION.Doctor) {
            this.search.doctorInCareDoctId = this.currentUserId;
          } else if (permis == Constant.DOCTORPERMISSION.Coordinator) {
            this.search.coordinatorId = this.currentUserId;
          } else if (permis == Constant.DOCTORPERMISSION.Nursing) {
            this.search.nursingInCareId = this.currentUserId;
          } else if (permis == Constant.DOCTORPERMISSION.Pharmacist) {
            this.search.pharmacistId = this.currentUserId;
          } else if (permis == Constant.DOCTORPERMISSION.Psychiatrist) {
            this.search.psychiatristId = this.currentUserId;
          } else if (permis == Constant.DOCTORPERMISSION.Volunteer) {
            this.search.volunteerId = this.currentUserId;
          }
        }
        this.getListPatientTreatment();
      }
    });
    // this.getColumns();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.intervalReloadGrid) {
      clearTimeout(this.intervalReloadGrid);
    }
  }

  // getColumns() {
  //   this.customColumnService.getPatientGridDefaultColumn().subscribe((res) => {
  //     if (res.isValid) {
  //       this.defaultConfig = res.jsonData;
  //       // console.log(res.jsonData);
  //       this.otCols = this.defaultConfig
  //         .filter((t) => t.type == 'OT')
  //         .map((t) => t.field);

  //       this.customColumnService.getPatientGridColumn().subscribe((res) => {
  //         if (res.isValid) {
  //           let curColumn = [];
  //           if (res.jsonData.length == 0) {
  //             curColumn = JSON.parse(JSON.stringify(this.defaultConfig));
  //           } else {
  //             res.jsonData.forEach((element) => {
  //               let elm = this.defaultConfig.filter(
  //                 (t) => t.field == element.field
  //               )[0];
  //               elm = { ...elm, ...element };
  //               curColumn.push(elm);
  //             });
  //           }

  //           this.showCols = curColumn;
  //           this.hideCols = this.defaultConfig.filter(
  //             (x) => !this.showCols.map((t) => t.field).includes(x.field)
  //           );
  //         }
  //       });
  //     }
  //   });
  // }

  getOTTypeValues(patient, colFieldsOnly: any[]) {
    // let colFieldsOnly =fieldNames.map(t => t.field);
    const inValid = false;
    patient.lstVitalSigns = patient.lstVitalSigns.filter((t) => colFieldsOnly.includes(t.otCode));
    // console.log(patient.lstVitalSigns)
    for (let i = 0; i < patient.lstVitalSigns.length; i++) {
      const elm = patient.lstVitalSigns[i];
      if (elm.dataType == Constant.DATATYPENUMBER.BOOLEAN) {
        patient[elm.otCode] = this.convertBooleanValue(elm.value2Show);
      } else {
        patient[elm.otCode] = elm.value2Show;
      }
    }
  }

  convertBooleanValue(val) {
    if (val == undefined || val == null) {
      return null;
    }

    if (val) {
      return 'Có';
    } else {
      ('Không');
    }
  }

  // saveColumns() {
  //   const columns = this.showCols.map((t) => ({
  //     field: t.field,
  //     width: t.width,
  //   }));
  //   const payload = { columns };
  //   this.customColumnService.savetPatientGridColumn(payload, this.groupId).subscribe((res) => {
  //     if (res.isValid) {
  //       this.hideCols = this.defaultConfig.filter((x) => !this.showCols.map((t) => t.field).includes(x.field));
  //     }
  //   });
  // }

  // changeColumns(cols: WorklistColumn[]) {
  //   this.showCols = cols;
  //   this.saveColumns();
  // }

  reloadDataTable() {
    this.pageIndex = 1;
    //run when choose different groupId
    if (!this.shareService.checkEmpty(this.selectedGroupId)) {
      // this.tabDataService.changeGroup();
      this.search = {
        keyword: '',
        patientName: '',
        address: '',
        phone: '',
        patientCode: '',
        doctorName: '',
        from: '',
        to: '',
        inGroup: true,
        pageSize: this.pageSize,
        page: this.pageIndex,
        newId: '',
        roomId: '',
        groupId: this.selectedGroupId,
        doctorInCareDoctId: '',
        nursingInCareId: '',
        sosRange: 0,
        psychiatristId: '',
        pharmacistId: '',
        coordinatorId: '',
        volunteerId: '',
        endTreatment: 1,
        newPrescription: 10,
        isVisited: 10,
        reported: 10,
        districtName: '',
        wardName: '',
        gender: null,
        minAge: null,
        maxAge: null,
        dateNoMin: null,
        dateNoMax: null,
        vaccinated: 99,
        hasDiseases: 99,
        receptionDate: null,
        oTSearchFields: [],
      };
      this.apiListPatient(this.search);
    }
  }

  apiListPatient(data) {
    const date = new Date();
    this.today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const $this = this;
    this.loading = true;
    if (this.intervalReloadGrid) {
      clearTimeout(this.intervalReloadGrid);
    }

    this.intervalReloadGrid = setTimeout(function () {
      $this.getListPatientTreatment();
    }, this.reloadTime);

    this.patientService.getListTreatmentPatientGroup(data).subscribe(
      (resdata) => {
        if (resdata !== null) {
          this.lstPatient = resdata;
          this.total = this.lstPatient.itemCount;
          this.resVisit = this.lstPatient.source;
          if (this.resVisit != null) {
            for (let i = 0; i < this.resVisit.length; i++) {
              const element = this.resVisit[i];
              this.getOTTypeValues(element, this.otCols);
            }
          }
        }

        this.loading = false;
      },
      (error) => {
        console.error(error);
        this.notificationService.showNotification('', error.statusText);
        this.loading = false;
      }
    );
  }

  resetSearch() {
    this.isVisibleFilter = false;
    for (let i = 0; i < defaultFilters.length; i++) {
      this.currentFilters[i].isUsed = defaultFilters[i].isUsed;
      this.search[this.currentFilters[i].field] = defaultFilters[i].defaultValue;
    }
    this.resetFilter = true;
    setTimeout(() => {
      this.resetFilter = false;
    }, 100);
    // this.search.patientName = '';
    // this.search.doctorInCareDoctId = '';
    // this.search.nursingInCareId = '';
    // this.search.coordinatorId = '';
    // this.search.pharmacistId = '';
    // this.search.psychiatristId = '';
    this.pageIndex = 1;
    this.getListPatientTreatment();
  }

  searchListPatient() {
    this.isVisibleFilter = false;
    this.pageIndex = 1;
    this.getListPatientTreatment();
  }

  getListPatientTreatment(): void {
    // alert(this.selectedGroupId);
    if (!this.shareService.checkEmpty(this.selectedGroupId)) {
      const data = {
        patientName: this.search.patientName ? this.search.patientName.trim() : '',
        patientCode: this.search.patientCode,
        phone: '',
        keyword: '',
        address: this.search.address,
        doctorName: '',
        from: '',
        to: '',
        inGroup: true,
        pageSize: this.pageSize,
        page: this.pageIndex,
        newId: '',
        groupId: this.selectedGroupId,
        doctorInCareDoctId: this.search.doctorInCareDoctId,
        nursingInCareId: this.search.nursingInCareId,
        psychiatristId: this.search.psychiatristId,
        pharmacistId: this.search.pharmacistId,
        coordinatorId: this.search.coordinatorId,
        endTreatment: this.search.endTreatment,
        newPrescription: this.search.newPrescription,
        isVisited: this.search.isVisited,
        reported: this.search.reported,
        districtName: this.search.districtName,
        wardName: this.search.wardName,
        gender: this.search.gender,
        minAge: this.search.minAge,
        maxAge: this.search.maxAge,
        dateNoMin: this.search.dateNoMin,
        dateNoMax: this.search.dateNoMax,
        vaccinated: this.search.vaccinated,
        hasDiseases: this.search.hasDiseases,
        receptionDate: this.search.receptionDate,
        oTSearchFields: this.search.oTSearchFields,
      };
      this.apiListPatient(data);
      //this.hideToolTip();
    }
  }

  showModal(): void {
    //this.store.dispatch(new OpenAddVisit('ListVisit', {}, ''));
    //this.store.dispatch(new OpenAddVisit('ListVisitContainer', {}, ''));
  }

  showCustomColumn(): void {
    this.isCustomColumnVisible = true;
  }

  addTab(id, PatientName, patientId) {
    //clearTimeout(settime);
    //$('#tooltip').hide();
    //this.tabDataService.updateTab(id, PatientName, 'VisitDetail', patientId);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.getListPatientTreatment();
  }

  ngAfterViewInit() {
    const $this = this;
    setTimeout(() => {
      $(document).ready(function () {
        $('.main-table-grid .ant-table-body').addClass('customTableCrollbar');
        $('.ant-table-header::-webkit-scrollbar').css({
          'background-color': 'white !important',
        });

        //Disable load more
        // $('.main-table-grid .ant-table-body').scroll(function (e) {
        //   if (e.currentTarget.offsetHeight + e.currentTarget.scrollTop == e.currentTarget.scrollHeight) {
        //     if ($this.pageSize > $this.total) {
        //       return;
        //     }
        //     let maxIndex = Math.round($this.total / $this.pageSize / 2);
        //     if (maxIndex < $this.pageIndex) {
        //       $this.pageIndex = maxIndex;
        //     }
        //     $this.pageSize += $this.pageSize;
        //   }
        // });
      });
    }, 500);
  }

  // onResizeCloumn({ width }: NzResizeEvent, col: string): void {
  //   this.showCols = this.showCols.map((e) => (e.title === col ? { ...e, width: `${width}px` } : e));
  //   this.saveColumns();
  // }

  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;
        this.currentFilters = JSON.parse(JSON.stringify(defaultFilters));
        // this.listDoctorInCase = this.doctorInGroup.filter(en => (en.doctorPermissions != null
        //   && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Doctor)));

        // this.listCoordinator = this.doctorInGroup.filter(en => (en.doctorPermissions != null
        //   && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Coordinator)));

        // this.listPharmacist = this.doctorInGroup.filter(en => (en.doctorPermissions != null
        //   && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Pharmacist)));

        // this.listPsychiatrist = this.doctorInGroup.filter(en => (en.doctorPermissions != null
        //   && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Psychiatrist)));

        // this.listNursingInCase = this.doctorInGroup.filter(en => (en.doctorPermissions != null
        //   && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Nursing)));

        const doctorInCaseField = this.currentFilters.find((t) => t.field == 'doctorInCareDoctId');
        doctorInCaseField.options = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Doctor)
        );

        const coordinatorField = this.currentFilters.find((t) => t.field == 'coordinatorId');
        coordinatorField.options = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Coordinator)
        );

        const pharmacistField = this.currentFilters.find((t) => t.field == 'pharmacistId');
        pharmacistField.options = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Pharmacist)
        );

        const psychiatristField = this.currentFilters.find((t) => t.field == 'psychiatristId');
        psychiatristField.options = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Psychiatrist)
        );

        const nursingInCareField = this.currentFilters.find((t) => t.field == 'nursingInCareId');
        nursingInCareField.options = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Nursing)
        );

        const volunteerField = this.currentFilters.find((t) => t.field == 'volunteerId');
        volunteerField.options = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Volunteer)
        );
      }
    });
  }
  calcDay(item) {
    //tính theo ngày phát hiện bệnh
    // console.log(item)
    if (item.dateOfDiagnosis) {
      return 'N' + (this.shareService.calcDateFromString(this.today, item.dateOfDiagnosis) + 1);
    }

    //tính theo ngày khởi phát triệu chứng
    if (!item.dateOfDiagnosis && item.dateOfSymptom) {
      return 'N' + (this.shareService.calcDateFromString(this.today, item.dateOfSymptom) + 1);
    }
  }

  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent, data): void {
    if (!this.isCoordinator) {
      //$event.preventDefault();
      return;
    }
    this.nzContextMenuService.create($event, menu);
    this.currentPatientId = data.patientId;
    if (this.search.endTreatment == 1) {
      this.titlePopup = 'Dừng điều trị cho BN ' + data.patientName;
    } else if (this.search.endTreatment == 2) {
      this.titlePopup = 'Cập nhật trạng thái cho BN ' + data.patientName;
      this.formEditStatus.patchValue({
        isEndTreatmented: data.isEndTreatmented,
        typeEndTreatmented: data.typeEndTreatmented,
        dateEndTreatmented: data.dateEndTreatmented,
      });
    }
  }

  closeMenu(): void {
    this.nzContextMenuService.close();
  }

  openModalEditStatus() {
    this.isVisibleEditStatus = true;
  }

  updateStatus() {
    this.isVisibleEditStatus = false;
    const payload = {
      patientId: this.currentPatientId,
      isEnd: this.formEditStatus.value.isEndTreatmented,
      typeEnd: this.formEditStatus.value.typeEndTreatmented,
      dateEnd: this.formEditStatus.value.dateEndTreatmented,
    };
    this.patientService.editStatusTreatmentPatient(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(Constant.SUCCESS, 'Cập nhật thành công!');
        // nếu đang ở danh sách dừng điều trị và trạng thái là vẫn là dừng -> k remove khỏi ds
        if (this.search.endTreatment == 2 && this.formEditStatus.value.isEndTreatmented) {
          this.getListPatientTreatment();
        } else {
          this.resVisit = this.resVisit.filter((en) => en.patientId != this.currentPatientId);
          this.formEditStatus.reset(this.initialEditStatus);
        }
      } else {
        this.notificationService.showNotification(
          Constant.ERROR,
          'Cập nhật không thành công! Xin liên hệ quản trị hệ thống.'
        );
      }
    });
  }

  cancelEditStatus() {
    this.isVisibleEditStatus = false;
    this.formEditStatus.reset(this.initialEditStatus);
  }

  userReportedToText(val) {
    if (this.shareService.checkEmpty(val)) {
      return 'Chưa report';
    }

    if (val) {
      return 'Đã report';
    }

    return '';
  }

  typeEndTreatmentedToText(val) {
    switch (val) {
      case 0:
        return 'Khỏi bệnh';
      case 1:
        return 'Tử vong';
      case 2:
        return 'Chuyển viện';
      case 3:
        return 'Khác';
      default:
        return '';
    }
  }

  userReportedToStatus(item) {
    if (this.shareService.checkEmpty(item) || !item) {
      return 'error';
    } else {
      return 'success';
    }
  }

  endTreatmentEvent(val) {
    this.isFilterEndTreatment = val;
  }

  get filterByEndtreatment() {
    if (this.isFilterEndTreatment) {
      return this.showCols;
    } else {
      return this.showCols.filter((x) => x.field != 'typeEndTreatmented');
    }
  }

  visitDetail(id, PatientName, patientId) {
    this.tabDataService.updateTab(id, PatientName, 'VisitDetail', patientId);
    this.router.navigateByUrl(`common-mobile/worklist/visit/${id}`);
  }

  toggleFilter() {
    this.isVisibleFilter = !this.isVisibleFilter;
  }

  showDetailOfPatient(patient) {
    this.currentDetail = patient;
    this.isVisibleDetail = true;
  }
  toggleDetailPatient() {
    this.isVisibleDetail = false;
  }
  getGenderText(genderText) {
    if (genderText === 'Không xác định') {
      return 'Không XĐ';
    } else {
      return genderText;
    }
  }
  getPatientVitalSignsDetail(patient) {
    let sRet = '';
    const lstVitalSigns = patient.lstVitalSigns.filter((t) => this.otCols.includes(t.otCode));
    // console.log(patient.lstVitalSigns)
    for (let i = 0; i < lstVitalSigns.length; i++) {
      const elm = lstVitalSigns[i];
      if (patient[elm.otCode] != null) {
        sRet += "<div class='row-inline'>" + elm.name + ': ' + patient[elm.otCode] + '</div>';
      }
      // if (elm.dataType == Constant.DATATYPENUMBER.BOOLEAN) {
      //   patient[elm.otCode] = this.convertBooleanValue(elm.value2Show);
      // } else {
      //   patient[elm.otCode] = elm.value2Show;
      // }
    }
    // console.log(sRet);
    return sRet;
  }
}
