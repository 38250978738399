import { CustomColumnModule } from '../worklist/custom-column-module/custom-column.module';
import { ConfigGroupModule } from './../modal/config-group-module/config-group.module';
import { ReportModule } from './../report/report.module';
import { PatientRoomModule } from './../patient-room/patient-room.module';
import { MobileRecursiveGroupComponent } from './common-layout/mobile-recursive-group/mobile-recursive-group.component';
import { MobileSelectGroupComponent } from './common-layout/mobile-select-group/mobile-select-group.component';
import { EditFormStaticModule } from './../admin/admin-pages/edit-form-static/edit-form-static.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { CommonLayoutComponent } from './common-layout/common-layout.component';
import { LayoutRoutingModule } from './layout-routing.module';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  NavigationStart,
} from '@angular/router';
// import {ActionsSubject, select, Store} from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { from, Subscription } from 'rxjs';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { RouterModule } from '@angular/router';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { SharedModule } from '../../share/share.module';
import { AddPatientComponent } from '../modal/add-patient/add-patient.component';
// import { AddPatientComponent } from '../worklist/add-patient/add-patient.component';
import { VisitDetailComponent } from '../worklist/visit-detail/visit-detail.component';
// import { SearchResultComponent } from '../worklist/search-result/search-result.component';
import { GroupTreeService } from '../../services/groupTree.service';
import { ObservationTypeService } from '../../services/obserType-service';
// import { ObservationService } from '../../services/observation.service';
import { ModalModule } from '../modal/modal.module';
import { WorklistModule } from '../worklist/worklist.module';
import { RecursiveMenuComponent } from './common-layout/recursive-menu/recursive-menu.component';
import { PatientInfoComponent } from '../worklist/patient-info/patient-info.component';
import { FormModule } from '../form/form.module';

import { TreeManagerGroupComponent } from './common-layout/tree-manager-group/tree-manager-group.component';
import { TreeModule } from '@circlon/angular-tree-component';
//import { AddGroupComponent } from '../modal/add-group/add-group.component';
import { AngularSplitModule } from 'angular-split';
import { ObservationTypeModule } from '../admin/admin-pages/observation-type/observation-type.module';
import { LockScreenComponent } from './lock-screen/lock-screen.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { GroupDashboardComponent } from './group-dashboard/group-dashboard.component';
import { GroupDashboardService } from '../../services/group-dashboard.service';
import { ConferenceRoomComponent } from '../modal/conference-room/conference-room.component';
import { CommonModule } from '@angular/common';
import { ViewVisitComponent } from './view-visit/view-visit.component';
import { CovidReceiveListComponent } from './covid-receive-list/covid-receive-list.component';
import { CovidService } from '../../services/covid.service';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { ConferenceRoomCallComponent } from './conference-room-call/conference-room-call.component';
import { NzResizableModule } from 'ng-zorro-antd/resizable';
import { MobileLayoutComponent } from './common-layout/mobile-layout/mobile-layout.component';
import { MedicineManagementModule } from '../admin/admin-pages/medicine-management/medicine-management.module';
import { TreatmentProcessComponent } from './treatment-process/treatment-process.component';
import { UpdateUserProfileComponent } from './update-user-profile/update-user-profile.component';
import { PatientReminderModule } from '../worklist/patient-reminder/patient-reminder.module';
import { ListNDetailVisitModule } from '../list-n-detail-visit/list-n-detail-visit.module';
import { WaitingPageComponent } from './waiting-page/waiting-page.component';
import { MedicalInstVsModule } from '../worklist/medical-inst-vs/medical-inst-vs.module';
import { XetNghiemModule } from '../modal/xet-nghiem/xet-nghiem.module';
import { TabCDHAModule } from '../worklist/visit-detail/tab-CDHA/tab-CDHA.module';
import { VitalSignsTabModule } from '../worklist/visit-detail/vital-signs-tab/vital-signs-tab.module';
import { PrescriptionModule } from '../prescription/prescription.module';
import { ListReminderModule } from '../worklist/list-reminder/list-reminder.module';
import { ExaminationServiceModule } from '../worklist/examination-service/examination-service.module';
import { RulesManagermentModule } from '../admin/admin-pages/rules-managerment/rules-managerment.module';
// import { ListVisitContainerModule } from '../list-visit-container/list-visit-container.module';

// No provider for InjectionToken NzIsInDropDownMenuToken!
// at NullInjector.get (http://localhost:4200/vendor.js:143099:27)

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);

@NgModule({
  declarations: [
    CommonLayoutComponent,
    // AddPatientComponent,
    ViewVisitComponent,
    VisitDetailComponent,
    RecursiveMenuComponent,
    ConferenceRoomCallComponent,
    TreeManagerGroupComponent,
    LockScreenComponent,
    GroupDashboardComponent,
    UserProfileComponent,
    CovidReceiveListComponent,
    MobileLayoutComponent,
    MobileSelectGroupComponent,
    MobileRecursiveGroupComponent,
    TreatmentProcessComponent,
    UpdateUserProfileComponent,
    WaitingPageComponent,
    // AddGroupComponent
    // SearchResultComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    LayoutRoutingModule,
    RouterModule.forChild([]),
    NzDropDownModule,
    NzBreadCrumbModule,
    ModalModule,
    WorklistModule,
    FormModule,
    ObservationTypeModule,
    RulesManagermentModule,
    MedicineManagementModule,
    ExaminationServiceModule,
    TreeModule,
    // NewEditFormModule,
    EditFormStaticModule,
    AngularSplitModule,
    NzAlertModule,
    NzResizableModule,
    PatientRoomModule,
    ConfigGroupModule,
    ReportModule,
    MedicalInstVsModule,
    PatientReminderModule,
    CustomColumnModule,
    ListNDetailVisitModule,
    XetNghiemModule,
    TabCDHAModule,
    VitalSignsTabModule,
    PrescriptionModule,
    ListReminderModule,
    // ListVisitContainerModule
  ],
  exports: [
    TreeManagerGroupComponent,
    //  NotificationsComponent,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons },
    GroupTreeService,
    ObservationTypeService,
    GroupDashboardService,
    CovidService,
  ],
  bootstrap: [
    RecursiveMenuComponent,
    //AddGroupComponent
  ],
})
export class LayoutModule {}
