import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/share/share.module';
import { PatientReminderListComponent } from './patient-reminder-list/patient-reminder-list.component';
import { PatientReminderFormComponent } from './patient-reminder-form/patient-reminder-form.component';
import { ListReminderModule } from '../list-reminder/list-reminder.module';

@NgModule({
  declarations: [PatientReminderListComponent, PatientReminderFormComponent],
  imports: [CommonModule, SharedModule, ListReminderModule],
  exports: [PatientReminderListComponent, PatientReminderFormComponent],
})
export class PatientReminderModule {}
