<form [formGroup]="formEditVisit" (ngSubmit)="handleOk()">
  <!-- <nz-modal nzTitle="Sửa thông tin ca khám"
        [nzVisible]="isVisible" [nzWidth]="800" [nzMaskClosable]="false" (nzOnCancel)='handleCancel()'> -->
  <app-m-modal
    [modalId]="'edit-visitinfo-core'"
    [isVisible]="isVisible"
    (closeModal)="handleCancel()"
    [mMask]="true"
    [width]="700"
    [minWidth]="500"
    [height]="400"
    [minHeight]="300"
    [isResiable]="true">
    <ng-template #mModalTitle>
      <div class="header-title">Sửa thông tin ca khám</div>
    </ng-template>
    <ng-template #mModalContent>
      <div class="row show-item" style="margin-bottom: 15px !important">
        <div class="col-md-4 col-12">
          <div class="mg-r-30 k-info-wrapper">
            <div class="elm-title">
              NGÀY KHÁM (<span class="d-note"> * </span>)
            </div>
            <nz-date-picker
              class="w-input"
              formControlName="visitDate"
              [nzPlaceHolder]="'DD/MM/YYYY hh:MM'"
              [nzFormat]="datetimeFormat"
              nzShowTime="true"></nz-date-picker>
            <!-- <br /> -->
          </div>
          <div class="message-required" *ngIf="submitted && f.visitDate.errors">
            <div *ngIf="f.visitDate.errors.required">
              <i
                nz-icon
                nzType="close-circle"
                [nzTheme]="'twotone'"
                [nzTwotoneColor]="'#FF0000'"
                style="vertical-align: baseline"></i>
              Bạn chưa chọn ngày khám
            </div>
          </div>
          <div class="message-required" *ngIf="submitted && checkDate">
            <div *ngIf="checkDate">
              <i
                nz-icon
                nzType="close-circle"
                [nzTheme]="'twotone'"
                [nzTwotoneColor]="'#FF0000'"
                style="vertical-align: baseline"></i>
              Ngày khám không hợp lệ
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="mg-r-30 k-info-wrapper">
            <div class="elm-title">NGÀY PHÁT HIỆN BỆNH</div>
            <nz-date-picker
              class="w-input"
              formControlName="dateOfDiagnosis"
              [nzPlaceHolder]="'DD/MM/YYYY'"
              [nzFormat]="dateFormat"></nz-date-picker>
            <!-- <br /> -->
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="mg-r-30 k-info-wrapper">
            <div class="elm-title">NGÀY KHỞI PHÁT TRIỆU CHỨNG</div>
            <nz-date-picker
              class="w-input"
              formControlName="dateOfSymptom"
              [nzPlaceHolder]="'DD/MM/YYYY'"
              [nzFormat]="dateFormat"></nz-date-picker>
            <!-- <br /> -->
          </div>
        </div>
        <!-- </div> -->

        <!-- <div class="row" style="margin-bottom: 15px !important;"> -->
        <div class="col-md-4 col-12">
          <div class="mg-r-30 k-info-wrapper">
            <div class="elm-title">
              BÁC SĨ KHÁM (<span class="d-note"> * </span>)
            </div>
            <nz-select
              class="w-input"
              [nzPlaceHolder]="'Chọn bác sĩ khám'"
              formControlName="doctorId"
              [ngClass]="{ 'is-invalid': submitted && f.doctorId.errors }">
              <nz-option
                *ngFor="let item of listDoctorInCase"
                [nzLabel]="item.fullName"
                [nzValue]="item.id">
              </nz-option>
            </nz-select>
          </div>
          <div class="message-required" *ngIf="submitted && f.doctorId.errors">
            <div *ngIf="f.doctorId.errors.required">
              <i
                nz-icon
                nzType="close-circle"
                [nzTheme]="'twotone'"
                [nzTwotoneColor]="'#FF0000'"
                style="vertical-align: baseline"></i>
              Bạn chưa chọn Bác sĩ khám
            </div>
          </div>
        </div>
        <div *ngIf="lstPermissionDefault[1].checked" class="col-md-4 col-12">
          <div class="mg-r-30 k-info-wrapper">
            <div class="elm-title">BÁC SĨ PHỤ TRÁCH</div>
            <nz-select
              class="w-input"
              [nzPlaceHolder]="'Chọn bác sĩ phụ trách'"
              formControlName="doctorInCareDoct"
              [ngClass]="{ 'is-invalid': submitted && f.doctorId.errors }">
              <nz-option
                *ngFor="let item of listDoctorInCase"
                [nzLabel]="item.fullName"
                [nzValue]="item.id">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div *ngIf="lstPermissionDefault[4].checked" class="col-md-4 col-12">
          <div class="mg-r-30 k-info-wrapper">
            <div class="elm-title">ĐIỀU DƯỠNG PHỤ TRÁCH</div>
            <nz-select
              class="w-input"
              [nzPlaceHolder]="'Chọn điều dưỡng phụ trách'"
              formControlName="nursingInCare"
              [ngClass]="{ 'is-invalid': submitted && f.doctorId.errors }">
              <nz-option
                *ngFor="let item of listNursingInCase"
                [nzLabel]="item.fullName"
                [nzValue]="item.id">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <!-- </div> -->

        <!-- <div class="row"> -->
        <div *ngIf="lstPermissionDefault[0].checked" class="col-md-4 col-12">
          <div class="mg-r-30 k-info-wrapper">
            <div class="elm-title">ĐIỀU PHỐI VIÊN</div>
            <nz-select
              class="w-input"
              [nzPlaceHolder]="'Chọn điều điều phối viên'"
              formControlName="coordinator"
              [ngClass]="{ 'is-invalid': submitted && f.doctorId.errors }">
              <nz-option
                *ngFor="let item of listCoordinator"
                [nzLabel]="item.fullName"
                [nzValue]="item.id">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div *ngIf="lstPermissionDefault[2].checked" class="col-md-4 col-12">
          <div class="mg-r-30 k-info-wrapper">
            <div class="elm-title">DƯỢC SĨ LÂM SÀNG</div>
            <nz-select
              class="w-input"
              [nzPlaceHolder]="'Chọn dược sĩ lâm sàng'"
              formControlName="pharmacist"
              [ngClass]="{ 'is-invalid': submitted && f.doctorId.errors }">
              <nz-option
                *ngFor="let item of listPharmacist"
                [nzLabel]="item.fullName"
                [nzValue]="item.id">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div *ngIf="lstPermissionDefault[3].checked" class="col-md-4 col-12">
          <div class="mg-r-30 k-info-wrapper">
            <div class="elm-title">CHUYÊN VIÊN TÂM LÝ</div>
            <nz-select
              class="w-input"
              [nzPlaceHolder]="'Chọn chuyên viên tâm lý'"
              formControlName="psychiatrist"
              [ngClass]="{ 'is-invalid': submitted && f.doctorId.errors }">
              <nz-option
                *ngFor="let item of listPsychiatrist"
                [nzLabel]="item.fullName"
                [nzValue]="item.id">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div *ngIf="lstPermissionDefault[5].checked" class="col-md-4 col-12">
          <div class="mg-r-30 k-info-wrapper">
            <div class="elm-title">TÌNH NGUYỆN VIÊN</div>
            <nz-select
              class="w-input"
              [nzPlaceHolder]="'Chọn tình nguyện viên'"
              formControlName="volunteer"
              [ngClass]="{ 'is-invalid': submitted && f.doctorId.errors }">
              <nz-option
                *ngFor="let item of listVolunteer"
                [nzLabel]="item.fullName"
                [nzValue]="item.id">
              </nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #mModalFooter>
      <button class="btn-cancel-medium" (click)="handleCancel()">
        Quay lại
      </button>
      <button
        nz-button
        class="btn-primary-medium"
        (click)="handleOk()"
        [nzLoading]="isLoading">
        Sửa
      </button>
    </ng-template>
    <!-- <div *nzModalFooter>
            <button class="button-submit btn-cancel-medium" (click)="handleCancel()">Quay lại</button>
            <button nz-button class="btn-primary-medium" (click)="handleOk()" [nzLoading]="isLoading">Sửa</button>
        </div> -->
  </app-m-modal>
  <!-- </nz-modal> -->
</form>
