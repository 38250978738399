import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class FormService extends BaseService {
  saveForm(payload): Observable<any> {
    return this.post(UrlConstant.CREATE_FORM, payload);
  }

  copyForm(formId): Observable<any> {
    return this.httpClient.get(
      this.configService.getConfig().api.baseUrl +
        UrlConstant.EXPORT_FORM +
        formId,
      {
        headers: this.createHeaders() || {},
        responseType: 'blob' as 'json',
      }
    );
  }

  importForm(isAddAnyway, formData: FormData): Observable<any> {
    return this.post(UrlConstant.IMPORT_FORM, formData, {
      groupId: '',
      isAddAnyway,
    });
  }

  getFormToEdit(payload): Observable<any> {
    return this.post('/api/DPForm/getForm/', payload);
  }

  getFormToUpdate(id: string): Observable<any> {
    return this.get('/api/DPForm/getFormToEdit/' + id);
  }

  checkFormula(payload): Observable<any> {
    return this.post('/api/DPForm/CheckFormula', payload);
  }

  saveFormula(payload): Observable<any> {
    return this.post('/api/DPForm/SaveFormula', payload);
  }

  getFormula(id): Observable<any> {
    return this.get('/api/DPForm/Formula/' + id);
  }
}
