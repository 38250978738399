<nz-collapse>
  <ng-container *ngFor="let menu of menus">
    <nz-collapse-panel
      class="msc-m-group-name"
      *ngIf="!menu.isLeaf"
      [nzHeader]="menu.name"
      [nzActive]="isActive"
      [nzExpandedIcon]="expandedIcon">
      <ng-template #expandedIcon let-active>
        {{ active }}
        <i nz-icon>
          <img
            src="{{ menu.icon }}"
            class="image"
            style="width: 38px; height: 38px" />
        </i>
      </ng-template>
      <app-mobile-recursive-group
        *ngIf="menu.children.length > 0"
        [menus]="menu.children"
        (selectItem)="selectGroup($event)"></app-mobile-recursive-group>
    </nz-collapse-panel>
    <div
      *ngIf="menu.isLeaf"
      class="msc-m-group-name"
      (click)="selectGroup(menu)">
      <i nz-icon>
        <img src="{{ menu.icon }}" class="image" />
      </i>
      <span title>{{ menu.name }}</span>
    </div>
  </ng-container>
</nz-collapse>
