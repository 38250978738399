import { ShareService } from './../../services/share.service';
import { NgxPrinterService } from './../../../../projects/ngx-printer/src/lib/ngx-printer.service';
import { ChangeDetectorRef, ElementRef, EventEmitter, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CanvasSave, ObservationSave } from 'src/app/models/form-model/form.class';
import { Patient } from 'src/app/models/patient.class';
import { NotificationService } from 'src/app/services/notification.service';
import { RecordVisitService } from 'src/app/services/record-visit-service';
import { Constant } from 'src/app/share/constants/constant.class';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
declare let $: any;
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormComponent implements OnInit {
  _formId: string;
  _visitId: string;
  _recordId: string;
  _data: any;
  isVisible = false;
  isLoading = false;
  @Input() set data(value: any) {
    if (value && value.formId && value.visitId && value.formId != this.formId) {
      //có value, formId, VisitId, nếu formId = giữ nguyên VÀ k đổi ca khám
      // this.container.remove();
      this._formId = value && value.formId ? value.formId : '';
      this._visitId = value && value.visitId ? value.visitId : '';
      this._recordId = value && value.recordId ? value.recordId : '';
      this._data = value;
      this.isLoading = true;
      this.reload();
      if (this._recordId != '') {
        this.loadFormdata();
      } else {
        this.loadVisitSummary();
      }
    } else {
      // this.container.remove();
      this._formId = '';
      this._visitId = '';
      this._recordId = '';
    }
  }

  @Output() completeDownload: EventEmitter<any> = new EventEmitter();

  @ViewChild('formContentPrint') formElm: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  get formId(): string {
    return this._formId;
  }

  get visitId(): string {
    return this._visitId;
  }

  get recordId(): string {
    return this._recordId;
  }

  get data(): any {
    return this._data;
  }

  patient: Patient;
  commonInfo: any;
  htmlText: any;
  canvasElms = [];
  commonInfos = [];
  formulas = [];
  labels = [];
  images = [];
  observationTypes = [];
  referObservationTypes = [];
  observationTypeRecents = [];
  textareas = [];
  splitDatas = [];
  pages = [];
  constant = Constant;
  container: ViewContainerRef;

  options = {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    allowTaint: true,
    useCORS: true,
    logging: true,
  };

  constructor(
    private shareService: ShareService,
    private notificationService: NotificationService,
    private recordVisitService: RecordVisitService,
    private changeDetector: ChangeDetectorRef,
    private printerService: NgxPrinterService
  ) {}

  ngOnInit() {
    // console.log('form component ngOnInit');
  }

  async downloadImage(name) {
    // await $(".wrapper-elm").removeClass("wrapper-elm");
    html2canvas(this.formElm.nativeElement, this.options).then((canvas) => {
      const fileWidth = 208;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;

      const FILEURI = canvas.toDataURL('image/png');
      const PDF = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);

      PDF.save(name + '.pdf');
      this.completeDownload.emit(true);
    });
  }

  loadFormdata(): any {
    this.recordVisitService.getRecordToEdit({ recordId: this.recordId }).subscribe((response) => {
      if (response.isValid) {
        this.setFormData(response);
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Invalid Id');
      }
      this.isLoading = false;
    });
  }

  loadVisitSummary(): any {
    this.recordVisitService.getVisitSummary({ formId: this.formId, visitId: this.visitId }).subscribe((response) => {
      if (response.isValid) {
        this.setFormData(response);
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Invalid Id');
      }
      this.isLoading = false;
    });
  }

  save() {
    const observationDatas = [];
    const observationRecentDatas = [];
    const canvasDatas = [];
    let isError = false;
    this.observationTypes.forEach((values, keys) => {
      const observation = new ObservationSave(values);
      if (values.editType == 0) {
        observation.isDirty = false;
      } else {
        observation.isDirty = true;
      }

      switch (values.observationType.dataType) {
        case Constant.DATATYPENUMBER.NUMBER:
          if (values.isRequired && this.shareService.checkEmptyObj(values.valueInteger)) {
            isError = true;
          }
          observation.valueInteger = values.valueInteger;
          break;
        case Constant.DATATYPENUMBER.STRING:
          if (values.isRequired && this.shareService.checkEmptyStr(values.valueString)) {
            isError = true;
          }
          observation.valueString = values.valueString;
          break;
        case Constant.DATATYPENUMBER.BOOLEAN:
          if (values.isRequired && this.shareService.checkEmptyObj(values.valueBoolean)) {
            isError = true;
          }
          observation.valueBoolean = values.valueBoolean;
          break;
        case Constant.DATATYPENUMBER.POSITIVENEGATIVE:
          if (values.isRequired && this.shareService.checkEmptyObj(values.valueBoolean)) {
            isError = true;
          }
          observation.valueBoolean = values.valueBoolean;
          break;
        case Constant.DATATYPENUMBER.DATE:
          if (values.isRequired && this.shareService.checkEmptyObj(values.valueDateTime)) {
            isError = true;
          }
          observation.valueDateTime = values.valueDateTime;
          break;
        case Constant.DATATYPENUMBER.TIME:
          if (values.isRequired && this.shareService.checkEmptyObj(values.valueTime)) {
            isError = true;
          }
          observation.valueTime = values.valueDateTime;
          break;
        case Constant.DATATYPENUMBER.DATETIME:
          if (values.isRequired && this.shareService.checkEmptyObj(values.valueDateTime)) {
            isError = true;
          }
          observation.valueDateTime = values.valueDateTime;
          break;
        case Constant.DATATYPENUMBER.SINGLECHOICE:
          if (values.isRequired && this.shareService.checkEmptyObj(values.valueSelections)) {
            isError = true;
          }
          observation.valueSelections =
            typeof values.valueSelections == 'object' ? values.valueSelections : [values.valueSelections];
          break;
        case Constant.DATATYPENUMBER.MUILTICHOICE:
          if (values.isRequired && this.shareService.checkEmptyObj(values.valueSelections)) {
            isError = true;
          }
          observation.valueSelections =
            typeof values.valueSelections == 'object' ? values.valueSelections : [values.valueSelections];
          break;
      }

      observationDatas.push(observation);
    });

    this.observationTypeRecents.forEach((values, keys) => {
      const observation = new ObservationSave(values);
      if (values.editType == 4 && observation.isDirty == true) {
        switch (values.observationType.dataType) {
          case Constant.DATATYPENUMBER.NUMBER:
            if (values.isRequired && this.shareService.checkEmptyObj(values.valueInteger)) {
              isError = true;
            }
            observation.valueInteger = values.valueInteger;
            break;
          case Constant.DATATYPENUMBER.STRING:
            if (values.isRequired && this.shareService.checkEmptyStr(values.valueString)) {
              isError = true;
            }
            observation.valueString = values.valueString;
            break;
          case Constant.DATATYPENUMBER.BOOLEAN:
            if (values.isRequired && this.shareService.checkEmptyObj(values.valueBoolean)) {
              isError = true;
            }
            observation.valueBoolean = values.valueBoolean;
            break;
          case Constant.DATATYPENUMBER.POSITIVENEGATIVE:
            if (values.isRequired && this.shareService.checkEmptyObj(values.valueBoolean)) {
              isError = true;
            }
            observation.valueBoolean = values.valueBoolean;
            break;
          case Constant.DATATYPENUMBER.DATE:
            if (values.isRequired && this.shareService.checkEmptyObj(values.valueDateTime)) {
              isError = true;
            }
            observation.valueDateTime = values.valueDateTime;
            break;
          case Constant.DATATYPENUMBER.TIME:
            if (values.isRequired && this.shareService.checkEmptyObj(values.valueTime)) {
              isError = true;
            }
            observation.valueTime = values.valueDateTime;
            break;
          case Constant.DATATYPENUMBER.DATETIME:
            if (values.isRequired && this.shareService.checkEmptyObj(values.valueDateTime)) {
              isError = true;
            }
            observation.valueDateTime = values.valueDateTime;
            break;
          case Constant.DATATYPENUMBER.SINGLECHOICE:
            if (values.isRequired && this.shareService.checkEmptyObj(values.valueSelections)) {
              isError = true;
            }
            observation.valueSelections =
              typeof values.valueSelections == 'object' ? values.valueSelections : [values.valueSelections];
            break;
          case Constant.DATATYPENUMBER.MUILTICHOICE:
            if (values.isRequired && this.shareService.checkEmptyObj(values.valueSelections)) {
              isError = true;
            }
            observation.valueSelections =
              typeof values.valueSelections == 'object' ? values.valueSelections : [values.valueSelections];
            break;
        }

        observationRecentDatas.push(observation);
      }
    });

    if (isError) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Bạn chưa nhập đủ thông tin');
      return null;
    }
    this.canvasElms.forEach((values, keys) => {
      const canvasSave = new CanvasSave();
      canvasSave.canvasId = values._ids;
      canvasSave.value = values.value;
      canvasDatas.push(canvasSave);
    });

    return {
      observationDatas,
      observationRecentDatas,
      canvasDatas,
      referObservationTypes: this.referObservationTypes,
    };
  }

  setFormData(response): void {
    const form = response.jsonData.form;
    const canvasData = response.jsonData.canvasDatas;
    // let contentHtml = decodeURI(response.jsonData.form.htmlDesign);
    this.commonInfo = null;
    this.canvasElms = [];
    this.commonInfos = [];
    this.formulas = [];
    this.labels = [];
    this.images = [];
    this.observationTypes = [];
    this.observationTypeRecents = [];
    this.textareas = [];
    this.pages = form.pages;

    for (let i = 0; i < form.images.length; i++) {
      this.images.push(form.images[i]);
    }
    for (let i = 0; i < form.labels.length; i++) {
      this.labels.push(form.labels[i]);
    }
    for (let i = 0; i < form.canvasImages.length; i++) {
      let data;
      let item = form.canvasImages[i];
      if (canvasData) {
        data = canvasData.find((t) => t.canvasId == form.canvasImages[i]._ids);
        console.log(data);
        if (data) {
          item = { ...item, ...{ value: data.value } };
        }
      }

      this.canvasElms.push(item);
    }
    for (let i = 0; i < response.jsonData.formElms.length; i++) {
      this.observationTypes.push({
        ...response.jsonData.formElms[i],
        ...{ showError: false },
      });
    }
    for (let i = 0; i < response.jsonData.formRecentElms.length; i++) {
      this.observationTypeRecents.push({
        ...response.jsonData.formRecentElms[i],
        ...{ showError: false, isDirty: false },
      });
    }
    for (let i = 0; i < form.commonInfos.length; i++) {
      this.commonInfos.push(form.commonInfos[i]);
    }
    for (let i = 0; i < form.formulas.length; i++) {
      this.formulas.push(form.formulas[i]);
    }
    for (let i = 0; i < form.textareas.length; i++) {
      this.textareas.push(form.textareas[i]);
    }

    this.splitDatas = form.splitDatas;
    this.commonInfo = response.jsonData.commonInfo;
    // this.createDynamicForm(contentHtml, this);
  }

  referValueChanged(value) {
    console.log('referValueChanged', value);
    const existValue = this.referObservationTypes.find((t) => t.referId == value.referId);
    if (existValue) {
      existValue.valueString = value.valueString;
      existValue.valueDateTime = value.valueDateTime;
      existValue.valueNumber = value.valueNumber;
    } else {
      this.referObservationTypes.push(value);
    }
    console.log(this.referObservationTypes);
  }
  print() {
    this.printerService.printOpenWindow = false;
    this.printerService.printHTMLElement(this.formElm.nativeElement.querySelectorAll('.pageForm')[0]);
    this.printerService.printOpenWindow = true;
  }

  reload() {
    this.isVisible = false;
    this.changeDetector.detectChanges();
    this.isVisible = true;
  }
}
