import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AppConfigService } from 'src/app-config.service';
import { GroupMemberService } from 'src/app/services/group-member.service';
import { NotificationService } from 'src/app/services/notification.service';
import { VideoConferenceService } from 'src/app/services/video-conference.service';
import { UserReturn } from 'src/app/models/userInvite.model';
import { Constant } from 'src/app/share/constants/constant.class';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-conference-add-user',
  templateUrl: './conference-add-user.component.html',
  styleUrls: ['./conference-add-user.component.scss'],
})
export class ConferenceAddUserComponent implements OnInit, OnDestroy {
  @Input() isVisible: any;
  @Input() visitId: string;
  @Input() patient: any;
  @Input() groupId = '';
  @Input() roomId = '';
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  listMember: Array<any> = [];
  listUser: Array<UserReturn> = [];
  listInvited: Array<any> = [];
  ListOfMember;
  participantIds: string;
  baseAvatarurl = '';
  item: string[] = [];
  hasPatient = false;
  groupSub: Subscription;
  myIsAdminGroup: string;
  myIsOwnerGroup: string;
  curKeyword: any;
  cursltValue: any;
  PathAvatar: string;
  newGroupId: string;
  isEmail: any;
  constructor(
    private notificationService: NotificationService,
    private groupMemberService: GroupMemberService,
    private videoConferenceService: VideoConferenceService,
    private configService: AppConfigService
  ) {}

  ngOnInit() {
    this.baseAvatarurl =
      this.configService.getConfig().media.baseUrl + '/Avatar/';
  }

  handleCall(): void {
    if (this.listInvited.length == 0) {
      this.notificationService.showNotification(
        'error',
        'Không có thành viên để mời!'
      );
      return;
    }
    const payload = {
      roomId: this.roomId,
      patientId: null,
      participantIds: this.listInvited.map((en) => en.userId),
      videoConferenceLink: window.location.origin + '/video-conference/{0}',
    };
    this.videoConferenceService.inviteMore(payload).subscribe((data) => {
      if (data.isValid) {
        this.isVisible = false;
        this.closeModal.emit();
        this.listInvited.length = 0;
        this.notificationService.showNotification('success', 'Mời thành công.');
      } else {
        this.notificationService.showNotification(
          'error',
          data.errors[0].errorMessage
        );
      }
    });
  }

  handleCancel(): void {
    this.isVisible = false;
    this.closeModal.emit(false);
  }

  getGroupMemeber(groupId) {
    this.groupMemberService.getMyGroupMember(groupId).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.myIsAdminGroup = res.jsonData.isAdmin;
            this.myIsOwnerGroup = res.jsonData.isOwner;
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }

  loadMember(sltValue, keyword) {
    if (keyword.length >= 1) {
      this.callService(parseInt(sltValue), keyword, this.groupId);
    }
  }

  fieldSelectedMember(event): void {
    if (event.userId !== 'patient') {
      if (
        this.listInvited.filter((en) => en.userId === event.userId).length == 0
      ) {
        this.listInvited.push(event);
      } else {
        this.notificationService.showNotification(
          Constant.ERROR,
          'Người dùng đã được mời'
        );
      }
    } else {
      this.hasPatient = true;
    }
    this.ListOfMember = {};
  }

  callService(sltValue, keyword, groupId) {
    this.curKeyword = keyword;
    this.cursltValue = sltValue;
    const payload = {
      groupId, //this.newGroupId,
      keyword,
      status: sltValue,
    };
    this.groupMemberService.getListMember(payload).subscribe(
      (res) => {
        if (res !== null) {
          this.listMember = res.jsonData;
          for (let i = 0; i < this.listMember.length; i++) {
            this.listMember[i].fullName = this.listMember[i].fullName.trim();
            if (
              this.listMember[i].imageUrl == null ||
              this.listMember[i].imageUrl == ''
            ) {
              this.listMember[i].imageUrl = 'ava.jpg';
            } else {
              this.listMember[i].imageUrl =
                this.PathAvatar + this.listMember[i].imageUrl;
            }
          }
        } else {
          if (res.errors && res.errors.length > 0) {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.WARRING,
              res.errors[0].message
            );
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }

  unSelectToInvite(userId, userName, email) {
    this.listInvited = this.listInvited.filter(
      (en) => en.email !== email && en.userId !== userId
    );
  }

  onKeyPress(x) {
    const keyword = x.target.value;
    const payload = {
      groupId: this.newGroupId,
      keyword,
      status: 1000,
    };

    this.groupMemberService.getListMember(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.listUser = res.jsonData;
            if (this.listUser == null || this.listUser.length == 0) {
              const validEmail = this.isEmail(keyword);
              if (validEmail) {
                const u = new UserReturn();
                u.userId = '';
                u.userName = '';
                u.email = keyword;
                this.listUser = [u];
              }
            }
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.WARRING,
                res.errors[0].message
              );
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }

  ngOnDestroy(): void {
    this.groupSub.unsubscribe();
  }
}
