import { ReminderGroup } from './../models/ReminderGroup.class';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Reminder } from '../models/Reminder.class';
import { SearchReminder } from '../models/Reminder.class';
import { ReminderCurDoctor } from '../models/ReminderCurDoctor.class';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class WorkReminderSampleService extends BaseService {
  searchReminderSample(data) {
    return this.post(UrlConstant.REMINDER_SAMPLE_SEARCH, data);
  }

  addReminderSample(data): Observable<any> {
    return this.post(UrlConstant.REMINDER_SAMPLE_ADD, data);
  }

  updateReminderSample(data): Observable<any> {
    return this.put(UrlConstant.REMINDER_SAMPLE_UPDATE, data);
  }

  deleteReminderSample(id: string) {
    return this.delete(UrlConstant.REMINDER_SAMPLE_DELETE + id, '');
  }
}
