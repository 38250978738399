import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { Observable } from 'rxjs';
import { UrlConstant } from '../share/constants/url.class';
import { RosCategory } from '../models/rosCategory.class';

@Injectable({
  providedIn: 'root',
})
export class RosCategoryService extends BaseService {
  getRosCategoryAll(): Observable<RosCategory[]> {
    return this.get(UrlConstant.ROS_CATEGORY + 'GetAll');
  }

  getRosCate(id: string): Observable<RosCategory> {
    return this.get(UrlConstant.ROS_CATEGORY + id);
  }

  addRosCategory(data: RosCategory) {
    return this.post(UrlConstant.ROS_CATEGORY, data);
  }

  updateRosCate(data: RosCategory) {
    return this.put(UrlConstant.ROS_CATEGORY + data.id, data);
  }

  deleteRosCate(id: string) {
    return this.delete(UrlConstant.ROS_CATEGORY + id, '');
  }

  getRosCateGroup(id: string) {
    return this.get(UrlConstant.ROS_CATEGORY + 'ros/' + id);
  }

  getRosCateById(id: string) {
    return this.get(UrlConstant.ROS_CATEGORY + id);
  }

  updateStatus(id): Observable<any> {
    return this.post(UrlConstant.ROS_CATEGORY + 'UpdateStatus/' + id, '');
  }
}
