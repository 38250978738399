import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Visits } from 'src/app/models/listVisit.class';
import { NotificationService } from 'src/app/services/notification.service';
import { RecordVisitService } from 'src/app/services/record-visit-service';
import { Constant } from 'src/app/share/constants/constant.class';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-list-record',
  templateUrl: './list-record.component.html',
  styleUrls: ['./list-record.component.scss'],
})
export class ListRecordComponent implements OnInit {
  @Input() visit: Visits;
  @Input() listRecord;
  @Input() hideButton: string;
  @Input() disabledClass: string;
  @Output() openPhieudl = new EventEmitter<any>();
  @Output() saveDeleteRecord = new EventEmitter<string>();
  selectedRowSubVisit;
  constructor(
    private recordVisitService: RecordVisitService,
    private notificationService: NotificationService,
    private modalService: NzModalService
  ) {}

  ngOnInit() {
    this.getListRecordVisit(this.visit.id);
  }

  getListRecordVisit(vsId: string) {
    this.recordVisitService.getRecordVisit(vsId).subscribe((data) => {
      this.listRecord = data;
    });
  }

  chooseSubVisit(idSubVisit) {
    //chọn row
    this.selectedRowSubVisit = idSubVisit;
  }

  openTabPhieudl(record) {
    //mở phiếu dl emit sang visitdetail
    this.openPhieudl.emit({
      name: record.name,
      phieudlid: record.dpFormId,
      recordId: record.id,
    });
  }
  deleteRecordVisit(id: string) {
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Bạn có muốn xóa hay không ?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () =>
        this.recordVisitService.deleteRecordVisit(id).subscribe((data) => {
          this.getListRecordVisit(this.visit.id);
          if (data.code == 1) {
            this.saveDeleteRecord.emit('reload now');
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xóa Thành công');
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'data.errors.errorMessage');
          }
        }),
    });
  }
}
