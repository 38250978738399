import { importType } from '@angular/compiler/src/output/output_ast';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthEffects } from '../auth/redux/auth.effects';
import { authReducer } from '../auth/redux/auth.reducer';
import { GroupAuthEffects } from '../auth/redux/group-auth.effects';
import { groupAuthReducer } from '../auth/redux/group-auth.reducer';

import { modalReducer } from '../modal/redux/modal/modal.reducer';
import { controlReducer } from './control-state/control.reducer';

@NgModule({
  imports: [
    EffectsModule.forRoot([AuthEffects, GroupAuthEffects]),

    // Signature matches AppState interface
    StoreModule.forRoot({
      modals: modalReducer,
      auth: authReducer,
      groupAuth: groupAuthReducer,
      control: controlReducer,
    }),

    StoreDevtoolsModule.instrument({ maxAge: 25 }),
  ],
})
export class AppStateModule {}
