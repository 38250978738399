import { filter } from 'rxjs/operators';
import { ClearTime, SSOLogout } from './../../auth/redux/auth.action';
import { AfterContentInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuWrapper } from '../../../models/menu.class';
import { Constant } from '../../../share/constants/constant.class';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../services/auth.service';
import { AppState } from '../../app-state/app-state';
import { Logout } from '../../auth/redux/auth.action';
import { AuthModel } from 'src/app/models/auth.model';
import { AppConfigService } from 'src/app-config.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminLayoutComponent implements OnInit, OnDestroy, AfterContentInit {
  isCollapsed = false;
  username: string;
  sub: Subscription;
  authSub: Subscription;
  userInfo: AuthModel;
  curUrl: string;
  broadcast = new BroadcastChannel(Constant.BROADCAST_CHANNEL.auth.value);
  menusRole: MenuWrapper[] = [
    {
      name: 'Từ điển dữ liệu',
      icon: 'setting',
      searhUrl: 'setting',
      expand: false,
      childrens: [
        // {
        //   url: 'roscategory',
        //   searhUrl: 'roscategory',
        //   name: 'Quản trị Ros',
        //   roleId: Constant.USERROLE.SystemAdmin,
        //   expand: false,
        // },
        {
          url: 'ros-category',
          searhUrl: 'ros-category',
          name: 'Quản trị Ros Category',
          roleId: Constant.USERROLE.LibraryMangament,
          expand: false,
        },
        {
          url: 'subclinicalorder',
          searhUrl: 'subclinicalorder',
          name: 'Quản trị danh mục chỉ định',
          roleId: Constant.USERROLE.SubclinicalOrderMangament,
          expand: false,
        },
        {
          url: 'observation-type',
          searhUrl: 'observation-type',
          name: 'Quản trị Observation type',
          roleId: Constant.USERROLE.ObservationType,
          expand: false,
        },
        {
          url: 'common-info-management',
          searhUrl: 'common-info-management',
          name: 'Quản trị Common-info',
          roleId: Constant.USERROLE.CommonInfoManagement,
          expand: false,
        },
        {
          url: 'form-mngt',
          searhUrl: 'form-mngt',
          name: 'Quản trị biểu mẫu',
          roleId: Constant.USERROLE.FormMangament,
          expand: false,
        },
        {
          url: 'medicine-management',
          searhUrl: 'medicine-management',
          name: 'Quản trị danh mục thuốc ',
          roleId: Constant.USERROLE.MedicationMangament,
          expand: false,
        },
        {
          url: 'system-lists',
          searhUrl: 'system-lists',
          name: 'Quản trị hệ thống mã',
          roleId: Constant.USERROLE.SystemAdmin,
          expand: false,
        },
        {
          url: 'management-method',
          searhUrl: 'management-method',
          name: 'Quản trị Modality',
          roleId: Constant.USERROLE.SystemAdmin,
          expand: false,
        },
        {
          url: 'treatment-process',
          searhUrl: 'treatment-process',
          name: 'DM quy trình điều trị',
          roleId: Constant.USERROLE.TreatmentProcessManagement,
          expand: false,
        },
        {
          url: 'medical-instruction',
          searhUrl: 'medical-instruction',
          name: 'Quản trị danh mục y lệnh',
          roleId: Constant.USERROLE.MedicalInstructionMngt,
          expand: false,
        },
        {
          url: 'rules-managerment',
          searhUrl: 'rules-managerment',
          name: 'Quản trị danh sách CDS Rule',
          roleId: Constant.USERROLE.RulesManagermentMngt,
          expand: false,
        },
      ],
    },
    {
      name: 'Quản lý người dùng',
      icon: 'user',
      childrens: [
        {
          url: 'user/detail',
          searhUrl: 'detail',
          name: 'Quản trị Tài khoản',
          roleId: Constant.USERROLE.SystemAdmin,
          expand: false,
        },
        {
          url: 'user/group',
          searhUrl: 'group',
          name: 'Quản trị Nhóm tài khoản',
          roleId: Constant.USERROLE.SystemAdmin,
          expand: false,
        },
      ],
    },
    {
      name: 'Thư viện bài viết y khoa',
      icon: 'file-done',
      childrens: [
        {
          url: 'category',
          searhUrl: 'category',
          name: 'Quản trị danh mục',
          roleId: Constant.USERROLE.SystemAdmin,
          expand: false,
        },
        {
          url: 'quan-ly-ds-bai-viet',
          searhUrl: 'quan-ly-ds-bai-viet',
          name: 'Quản trị bài viết',
          roleId: Constant.USERROLE.SystemAdmin,
          expand: false,
        },
      ],
    },
    {
      name: 'Quản trị hệ thống',
      icon: 'apartment',
      childrens: [
        {
          url: 'log-management',
          searhUrl: 'log-management',
          name: 'Xem Log hệ thống',
          roleId: Constant.USERROLE.SystemAdmin,
          expand: false,
        },
        // {
        //   url: 'config-management',
        //   searhUrl: 'config-management',
        //   name: 'Cấu hình hệ thống',
        //   roleId: Constant.USERROLE.SystemAdmin,
        //   expand: false,
        // },
      ],
    },
  ];

  menus: MenuWrapper[] = [];
  pageName = '';

  constructor(
    private route: Router,
    private authService: AuthService,
    private store: Store<AppState>,
    private configService: AppConfigService
  ) {
    this.route.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.curUrl = event.urlAfterRedirects;
    });
  }

  ngOnInit(): void {
    this.authSub = this.store.select('auth').subscribe(async (res) => {
      console.log(res);
      if (res.isAuthenticated) {
        this.menus = [];
        this.userInfo = res;
        this.username = this.userInfo.userName;
        this.getMenu();
      }
    });
  }

  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    this.store.dispatch(new ClearTime());
  }

  logout(): void {
    if (this.configService.getConfig().isUseSSOLogin) {
      const returnURL = window.location.origin + '/sso-login';
      this.store.dispatch(new SSOLogout(returnURL));
      this.broadcast.postMessage({action:Constant.BROADCAST_CHANNEL.auth.action.logout.value})
    } else {
      this.sub = this.authService.logout({ returnUrl: '' }).subscribe((res) => {
        this.store.dispatch(new Logout(''));
        this.route.navigate(['/login']);
        this.broadcast.postMessage({action:Constant.BROADCAST_CHANNEL.auth.action.logout.value})
      });
    }
  }

  ngOnDestroy(): void {
    this.authSub.unsubscribe();
    this.broadcast.close()
  }

  async getMenu() {
    for (let i = 0; i < this.menusRole.length; i++) {
      const childrens = await this.menusRole[i].childrens.filter((t) => this.userInfo.userType.indexOf(t.roleId) >= 0);
      if (childrens.length > 0) {
        const menuWrapperItem = {
          ...this.menusRole[i],
          ...{ childrens },
        };
        if (!this.menus.find((t) => t.name == menuWrapperItem.name)) {
          this.menus.push(menuWrapperItem);
        }
      }
    }

    this.activeMenu();
  }

  activeMenu() {
    this.menus.forEach((menu) => {
      if (menu.childrens && menu.childrens.length > 0) {
        menu.expand = false;
        menu.childrens.forEach((child) => {
          const routes = location.pathname.split('/');
          const pathname = routes.splice(2, routes.length - 1).join('/');

          if (pathname == child.url) {
            this.pageName = child.name;
            child.expand = true;
            menu.expand = true;
          } else {
            child.expand = false;
          }
        });
      }
    });
  }

  changePageTitle(name) {
    this.pageName = name;
  }
}
