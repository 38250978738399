<div *ngIf="!isLoading">
  <div class="message-info" *ngIf="!isVisible">
    <div class="header">
      <img src="assets/image/logo.svg" />
      <p>Patient Medical Record</p>
    </div>
    <div class="col-md-10 col-lg-8 message-hide">
      <img src="assets/image/reject-invitation.svg" />
      <h3>Thông tin ca khám được chia sẻ</h3>
      <p style="color: red; font-weight: 500">{{ message }}</p>
    </div>
  </div>
  <div class="page-share-visit" *ngIf="isVisible">
    <div class="vs-share-top">
      <img src="/assets/image/auth/colored-logo.svg" />
      <div class="text-title">
        <h2>Bedohub</h2>
        <p>Thông tin ca khám được chia sẻ</p>
      </div>
    </div>
    <div class="vs-share-body">
      <app-visit-detail
        [shareId]="code"
        [visitId]="_visitId"
        [visitPrivacy]="_visitPrivacy"></app-visit-detail>
    </div>
    <!-- <a (click)="testfirebase()">Test</a> -->
  </div>
</div>
