<nz-modal
  [nzVisible]="!isPrint && showPrinter"
  [nzWidth]="900"
  (nzOnCancel)="closeModal()">
  <div id="print-clinicalForm-header" [ngClass]="printClass" #printClinicalForm>
    <div style="padding: 20px">
      <div [innerHTML]="printHeader | safeHtml"></div>
      <div>
        <div style="width: 100%">
          <div class="row">
            <div class="col-5">
              <label class="info-label">Tên BN: </label>
              <label class="label">{{ visit.patientName }}</label>
            </div>
            <div class="col-3">
              <label class="info-label">Giới tính: </label>
              <label class="label">{{ visit.patientSexText }}</label>
            </div>
            <div class="col-4">
              <label class="info-label-small">Tuổi: </label>
              <label class="label">
                {{ visit.dob | ageStringFromDOB: false }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <label class="info-label">Địa chỉ: </label>
              <label class="label">{{ visit.address }}</label>
            </div>
            <div class="col-4">
              <label class="info-label-small">SĐT: </label>
              <label class="label">{{ visit.phoneNo }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <label class="info-label">Ngày khám: </label>
              <label class="label">{{
                visit.visitDate | date: 'dd/MM/yyyy'
              }}</label>
            </div>
            <div class="col-7">
              <label class="info-label">Lý do khám: </label>
              <label class="label">{{ visit.visitReason }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="info-label">Chẩn đoán: </label>
              <label class="label">{{ visit.conclusion }}</label>
            </div>
          </div>
        </div>
        <br />
        <ng-container *ngIf="showMainTable">
          <ng-container
            *ngTemplateOutlet="
              tableTemplate;
              context: { datas: lstService }
            "></ng-container>
        </ng-container>
        <ng-container *ngIf="showCdhaTable">
          <ng-container
            *ngTemplateOutlet="
              tableTemplate;
              context: { datas: subClinicalCDHAs }
            "></ng-container>
        </ng-container>
        <ng-container *ngIf="showXnTable">
          <ng-container
            *ngTemplateOutlet="
              tableTemplate;
              context: { datas: subClinicalXNs }
            "></ng-container>
        </ng-container>
        <br />
        <br />
        <div class="row">
          <div class="col-8"></div>
          <div class="col-4 center">
            <div>
              <i>{{ undefined | dayMonthYearString }}</i>
            </div>
            <label class="info-label-small bold">BÁC SĨ CHỈ ĐỊNH </label>
            <div><i>(Ký và ghi rõ họ tên)</i></div>
          </div>
        </div>
        <br /><br /><br /><br />
        <div class="tr-sign">
          <i
            >*Sau khi làm xong hết dịch vụ, mời bệnh nhân về phòng khám ban đầu
            để BS đọc kết quả</i
          >
        </div>
      </div>
    </div>
  </div>

  <div *nzModalFooter>
    <button nz-button nzType="danger" (click)="closeModal()">Đóng</button>
    <button
      *ngIf="!showMainTable"
      nz-button
      nzType="primary"
      (click)="toggleCDHA_XN()">
      {{ toggleCDHA_XN_text }}
    </button>
    <button nz-button nzType="primary" (click)="toPrint()">In</button>
  </div>
</nz-modal>

<ng-template #tableTemplate let-datas="datas">
  <table
    style="width: 100%"
    cellpadding="0"
    cellspacing="0"
    border="1"
    class="table-detail">
    <thead>
      <tr>
        <th style="text-align: center; width: 20px">STT</th>
        <th>Tên dịch vụ</th>
        <th style="white-space: nowrap">Đơn giá</th>
        <th style="white-space: nowrap">SL</th>
        <th style="white-space: nowrap">Giảm giá</th>
        <th style="white-space: nowrap">Thành tiền</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of datas; let i = index">
        <td style="text-align: center; width: 20px">
          {{ i + 1 }}
        </td>
        <td>{{ data.name }}</td>
        <td style="text-align: right">{{ data.price | currencyFormat }}</td>
        <td style="text-align: center">{{ data.amount }}</td>
        <td style="text-align: right">{{ data.discount | currencyFormat }}</td>
        <td style="text-align: right">
          {{
            getDetailPrice(data.amount, data.price, data.discount)
              | currencyFormat
          }}
        </td>
      </tr>
      <tr>
        <td></td>
        <td class="bold" colspan="3">Tổng cộng (VNĐ)</td>
        <td class="bold right">
          {{ getTotalDiscount(datas) | currencyFormat }}
        </td>
        <td class="bold right">{{ getTotalPrice(datas) | currencyFormat }}</td>
      </tr>
    </tbody>
  </table>
</ng-template>
