import { Component, Input, OnInit } from '@angular/core';
import { FormElement } from 'src/app/models/form-model/form.class';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-selection-element',
  templateUrl: './selection-element.component.html',
  styleUrls: ['./selection-element.component.scss'],
})
export class SelectionElementComponent implements OnInit {
  _data: FormElement;
  type: string;

  @Input() set data(value: FormElement) {
    this._data = value;
    this.type =
      this._data.observationType.datatypeText ==
        Constant.DATATYPE.MUILTICHOICE ||
      this._data.observationType.dataType ==
        Constant.DATATYPENUMBER.MUILTICHOICE
        ? 'multiple'
        : 'single';
    // console.log(this.type)
  }

  get data(): FormElement {
    return this._data;
  }

  allChecked = false;
  indeterminate = true;
  alginFormat = new Map([
    ['textalign-left', 'textalign-left-flex'],
    ['textalign-center', 'textalign-center-flex'],
    ['textalign-right', 'textalign-right-flex'],
  ]);

  constructor() {}

  ngOnInit() {}

  updateAllChecked(): void {
    this.indeterminate = false;
  }

  updateSingleChecked(): void {}
}
