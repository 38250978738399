import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitDetailSmallComponent } from './visit-detail-small.component';
import { SharedModule } from 'src/app/share/share.module';
import { VisitInfoComponent } from './visit-info/visit-info.component';
import { XetNghiemModule } from '../../modal/xet-nghiem/xet-nghiem.module';
import { TabCDHAModule } from '../../worklist/visit-detail/tab-CDHA/tab-CDHA.module';
import { VitalSignsTabModule } from '../../worklist/visit-detail/vital-signs-tab/vital-signs-tab.module';
import { PrescriptionModule } from '../../prescription/prescription.module';
import { MedicalInstVsModule } from '../../worklist/medical-inst-vs/medical-inst-vs.module';
import { SubclinicalVisitModule } from '../../subclinical-visit/subclinical-visit.module';
import { VisitHistoryModule } from '../../worklist/visit-history/visit-history.module';
import { VisitLogModule } from '../../worklist/visit-detail/visit-log/visit-log.module';
import { PrintConclusionComponent } from './print-conclusion/print-conclusion.component';
import { VisitOtherInfoComponent } from './visit-other-info/visit-other-info.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AddVitalASignModule } from '../../worklist/visit-detail/add-vital-asign/add-vital-asign.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    XetNghiemModule,
    TabCDHAModule,
    VitalSignsTabModule,
    PrescriptionModule,
    MedicalInstVsModule,
    SubclinicalVisitModule,
    VisitHistoryModule,
    VisitLogModule,
    AngularEditorModule,
    AddVitalASignModule,
  ],
  declarations: [VisitDetailSmallComponent, VisitInfoComponent,PrintConclusionComponent, VisitOtherInfoComponent],
  exports: [VisitDetailSmallComponent,PrintConclusionComponent],
})
export class VisitDetailSmallModule {}
