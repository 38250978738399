import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-edit-amount',
  templateUrl: './modal-edit-amount.component.html',
  styleUrls: ['./modal-edit-amount.component.scss'],
})
export class ModalEditAmountComponent implements OnInit {
  @Input() isVisible = false;
  @Output() isVisibleChange = new EventEmitter();

  @Input() listMedicationTable = [];
  @Output() saveGeneralInfoEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  closeAmountExistOfPatient() {
    this.isVisible = false;
    this.isVisibleChange.emit(false);
  }
  saveGeneralInfo() {
    // nút lưu general info
    this.saveGeneralInfoEvent.emit();
  }
}
