<div class="login-form">
  <div *ngIf="step === 1">
    <div class="title-header">Quên mật khẩu</div>
    <div>
      <span>Nhập&nbsp;</span>
      <span style="color: red">địa chỉ email</span>
      <span>&nbsp;của bạn để tiến hành khôi phục mật khẩu</span>
    </div>
    <div class="mg-bt-20">
      <input
        nz-input
        class="mg-t-10"
        type="text"
        [(ngModel)]="email"
        placeholder="Nhập email"
        (ngModelChange)="checkEmailValid()" />
      <div *ngIf="errorMessageEmail != ''" class="message-required">
        <div>*{{ errorMessageEmail }}</div>
      </div>
    </div>
    <div class="text-center">
      <button nz-button class="btn-primary-medium" (click)="sendEmail()">
        Gửi
      </button>
    </div>
  </div>
  <div id="lossPass2" *ngIf="step === 2">
    <div class="col-md-12 title-header">Kiểm tra email</div>
    <div>
      <span>Chúng tôi đã gửi yêu cầu khôi phục mật khẩu tới email </span>
      <span style="font-style: italic; margin-bottom: 10px">{{ email }}.</span>
    </div>
    <div class="mg-bt-10">
      Hãy ấn vào link trong email để thiết lập mật khẩu mới.
    </div>
    <div>
      Không nhận được email?
      <a style="color: red" (click)="reSendEmail()">Gửi lại</a>
    </div>
  </div>
  <div id="lossPass3" *ngIf="step === 3" [formGroup]="updatePasswordForm">
    <div class="col-md-12 title-header">Thay đổi mật khẩu</div>
    <div class="italic mg-bt-10 fs-12">
      (mật khẩu tối thiểu 8 ký tự, không nhập khoảng trắng ở đầu và cuối)
    </div>
    <div class="mg-bt-20">
      <nz-input-group [nzSuffix]="suffixTemplate">
        <input
          nz-input
          [type]="passwordVisible ? 'text' : 'password'"
          formControlName="password"
          placeholder="Mật khẩu"
          [ngClass]="{ 'is-invalid': submitted && formData.password.errors }" />
      </nz-input-group>
      <ng-template #suffixTemplate>
        <i
          nz-icon
          [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
          (click)="passwordVisible = !passwordVisible"></i>
      </ng-template>
      <div
        class="message-required"
        *ngIf="submittedPassword && formData.password.errors">
        <div *ngIf="formData.password.errors.required">*Hãy nhập mật khẩu</div>
        <div *ngIf="formData.password.errors.pattern">
          *Mật khẩu chưa đúng với điều kiện trên
        </div>
      </div>
    </div>
    <div class="mg-bt-20">
      <nz-input-group [nzSuffix]="suffixTemplate1">
        <input
          nz-input
          [type]="repeatpasswordVisible ? 'text' : 'password'"
          formControlName="repassword"
          placeholder="Nhập lại mật khẩu"
          [ngClass]="{ 'is-invalid': submitted && f.repeatPassword.errors }" />
      </nz-input-group>
      <ng-template #suffixTemplate1>
        <i
          nz-icon
          [nzType]="repeatpasswordVisible ? 'eye' : 'eye-invisible'"
          (click)="repeatpasswordVisible = !repeatpasswordVisible"></i>
      </ng-template>
      <div
        class="message-required"
        *ngIf="submittedPassword && formData.repassword.errors">
        <div *ngIf="formData.repassword.errors.required">
          *Hãy nhập lại mật khẩu
        </div>
        <div *ngIf="formData.repassword.errors.pattern">
          *Mật khẩu chưa đúng với điều kiện trên
        </div>
      </div>
      <div
        class="message-required"
        *ngIf="submittedPassword && !formData.repassword.errors">
        <div *ngIf="updatePasswordForm.hasError('confirm')">
          *Mật khẩu không khớp
        </div>
      </div>
    </div>
    <div class="text-center">
      <button nz-button class="btn-primary-medium" (click)="changePass()">
        Lưu
      </button>
    </div>
  </div>
  <div *ngIf="messageError">
    <div class="alert alert-danger" role="alert">
      {{ messageError }}
    </div>
  </div>
</div>
