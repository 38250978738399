import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Prescription } from 'src/app/models/prescription.class';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-prescription-detail',
  templateUrl: './prescription-detail.component.html',
  styleUrls: ['./prescription-detail.component.scss'],
})
export class PrescriptionDetailComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() prescription: any;
  @Input() isUsePrescriptionDeliveryInfo = false;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  dateTimeFormat = 'dd/MM/yyyy HH:mm';
  constructor(private shareService: ShareService) {}

  ngOnInit(): void {}

  handleCancel() {
    this.isVisible = false;
    this.closeModal.emit();
  }
  getAmount(amount, existingAmount) {
    if (!this.shareService.checkEmpty(existingAmount)) {
      const numb = amount - existingAmount;
      if (numb > 0) {
        return numb;
      } else {
        return 0;
      }
    } else {
      return amount;
    }
  }
}
