import { ShareService } from 'src/app/services/share.service';
import { PrescriptionService } from 'src/app/services/prescription-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { Subscription } from 'rxjs/internal/Subscription';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';

@Component({
  selector: 'app-share-prescription',
  templateUrl: './share-prescription.component.html',
  styleUrls: ['./share-prescription.component.scss'],
})
export class SharePrescriptionComponent implements OnInit {
  token = '';
  visit: any = {};
  listMedicationTable: Array<any> = [];

  prescription: any = {};
  groupSub: Subscription;
  isUsePrescriptionDeliveryInfo = false;
  PRESCRIPTION_NOTE = Constant.PRESCRIPTION_NOTE;
  templateDrugList = 0;

  constructor(
    private actionRoute: ActivatedRoute,
    private router: Router,
    private shareService: ShareService,
    private notificationService: NotificationService,
    private prescriptionService: PrescriptionService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.actionRoute.paramMap.subscribe((params) => {
      this.token = params.get('token');
      this.getPrescription();
    });
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      this.isUsePrescriptionDeliveryInfo = data.isUsePrescriptionDeliveryInfo;
    });
  }

  //currentUser = JSON.parse(localStorage.getItem(Constant.USER_INFO));

  getPrescription() {
    if (!this.shareService.checkEmpty(this.token)) {
      this.prescriptionService
        .getSharePrescription(this.token)
        .subscribe((res) => {
          if (res.isValid) {
            const data = JSON.parse(res.jsonData);
            console.log('getPrescription', data);

            if (data.isValid) {
              this.visit = data.jsonData.visit;
              let rawData = data.jsonData.prescription;

              this.templateDrugList = this.visit.templateDrugList
                ? this.visit.templateDrugList
                : Constant.DEFAULT_TEMPLATE_DRUG_LIST;

              /// patch data
              const medications = data.jsonData.prescription.medicationRequests;
              if (!this.shareService.checkEmptyArray(medications)) {
                medications.forEach((en) => {
                  const item = {
                    MedicationName: en.medicationName,
                    Content: en.content,
                    ActiveIngredient: en.activeIngredient,
                    Amount: en.amount,
                    ExistingAmount: en.existingAmount,
                    UnitName: en.unitName,
                    Text: en.text,
                    Title: this.getMedicationName(
                      en.medicationName,
                      en.content,
                      en.activeIngredient
                    ),
                  };
                  this.listMedicationTable.push(item);
                });
              }
              ///patch data prescription
              this.prescription = {
                DoctorComment: rawData.doctorComment,
                RecipientName: rawData.recipientName,
                RecipientPhoneNo: rawData.recipientPhoneNo,
                RecipientAddress: rawData.recipientAddress,
                RecipientTimePlan: rawData.recipientTimePlan,
                PaymentMethodString: this.toPaymentMethodText(
                  rawData.paymentMethod
                ),
                RecipientNote: rawData.recipientNote,
                CreatedDate: rawData.createdDate,
                VisitAgainAfter: rawData.visitAgainAfter,
                ReExaminationDate: rawData.reExaminationDate,
                CreatorName: rawData.creatorName,
                CreatorRole: rawData.CreatorRole,
              };
            } else {
              this.router.navigateByUrl('404');
            }
          } else {
            this.router.navigateByUrl('404');
          }
        });
    }
  }
  toPaymentMethodText(status: number): string {
    return this.prescriptionService.toPaymentMethodText(status);
  }
  getMedicationName(name, content, activeIngredient) {
    let rs = '';
    if (!this.shareService.checkEmpty(name)) {
      rs = name;
    }
    if (!this.shareService.checkEmpty(content)) {
      rs += ' ' + content;
    }
    if (!this.shareService.checkEmpty(activeIngredient)) {
      rs += ' (' + activeIngredient + ')';
    }
    return rs;
  }
  convertTextbox2Html(val: string) {
    if (val == undefined || val == null) {
      return '';
    }
    return val.split('\n').join('<br/>');
  }
  getTextOfPrescriptionNote() {
    const visitAgainAfter = this.prescription.visitAgainAfter;
    if (!visitAgainAfter) {
      return '';
    }
    const visitAgainUnit = this.prescription.visitAgainUnit;
    return (
      'Hẹn khám lại sau: ' +
      visitAgainAfter +
      ' ' +
      visitAgainUnit.toLowerCase() +
      '.'
    );
  }

  // getDayMonthYear(dateStr) {
  //   // console.log("getDayMonthYear:", dateStr);

  //   let dateObj;
  //   if (dateStr == undefined) {
  //     dateObj = new Date();
  //   } else {
  //     dateObj = new Date(dateStr);
  //   }

  //   let str = dateObj.getHours() + 'h ';
  //   str += dateObj.getMinutes() + 'p, ngày ';
  //   str += dateObj.getDate() + ' tháng ';
  //   str += dateObj.getMonth() + 1 + ' năm ';
  //   str += dateObj.getFullYear();
  //   return str;
  // }

  getPrintMedicationName(name, content, ingredient) {
    let rs = name;
    if (content != null) {
      rs += ' ' + content;
    }
    if (ingredient != null) {
      rs += ' (' + ingredient + ')';
    }
    return rs;
  }

  // getAmount(amount, existingAmount) {
  //   if (!this.shareService.checkEmpty(existingAmount)) {
  //     const numb = amount - existingAmount;
  //     if (numb > 0) {
  //       return numb;
  //     } else {
  //       return 0;
  //     }
  //   } else {
  //     return amount;
  //   }
  // }
}
