import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class WaitingPageService extends BaseService {
  addConfig(data): Observable<any> {
    return this.post(UrlConstant.WAITING_PAGE_ADD_CONFIG, data);
  }
  getConfigByUser(data): Observable<any> {
    return this.post(UrlConstant.WAITING_PAGE_GET_CONFIG, data);
  }
  updateConfig(data): Observable<any> {
    return this.post(UrlConstant.WAITING_PAGE_UPDATE_CONFIG, data);
  }
  getListVisitWaiting(data): Observable<any> {
    return this.post(UrlConstant.WAITING_PAGE_GET_VISIT, data);
  }
}
