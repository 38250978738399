import { Injectable } from '@angular/core';
import { UrlConstant } from '../share/constants/url.class';
import { BaseService } from '../share/base-service/base-service.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ShareVisitService extends BaseService {
  deleteShareVisit(id): Observable<any> {
    return this.delete(UrlConstant.SHARE_VISIT_DELETE, id);
  }
  getShareVisit(visitId): Observable<any> {
    return this.get(UrlConstant.SHARE_VISIT_INFO + visitId);
  }
  shareVisit(payload): Observable<any> {
    return this.post(UrlConstant.SHARE_VISIT, payload);
  }
  OnOffShareVisit(id): Observable<any> {
    return this.post(UrlConstant.SHARE_VISIT_ON_OFF + id, id);
  }

  getListGroupShared(visitId): Observable<any> {
    return this.get(UrlConstant.GET_SHARE_VISIT_GROUP + visitId);
  }
  addGroupShared(payload): Observable<any> {
    return this.post(UrlConstant.SHARE_VISIT_GROUP, payload);
  }
}
