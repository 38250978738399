import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { SubclinicalorderGroupService } from 'src/app/services/subclinicalorder-group.service';
import { SubclinicalorderService } from 'src/app/services/subclinicalorder.service';
import { SubClinicalVisitService } from 'src/app/services/subcliniccal-service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-subclinical-group-add',
  templateUrl: './subclinical-group-add.component.html',
  styleUrls: ['./subclinical-group-add.component.scss'],
})
export class SubclinicalGroupAddComponent implements OnInit {
  _isVisible = false;
  @Input() set isVisible(value: boolean) {
    this._isVisible = value;
    if (value) {
      this.getByFilter();
    }
  }
  get isVisible() {
    return this._isVisible;
  }
  @Input() groupId = '';
  @Input() visitId = '';
  @Input() getHISServicePrice = false;

  @Output() refresh: EventEmitter<any> = new EventEmitter();
  @Output() isVisibleChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private subclinicalorderGroupService: SubclinicalorderGroupService,
    private subClinicalVisitService: SubClinicalVisitService,
    private subclinicalorderService: SubclinicalorderService,
    private notificationService: NotificationService
  ) {}

  lstData = [];
  searchName = '';
  isSaving = false;
  isLoading = false;

  ngOnInit(): void {}

  getByFilter() {
    const payload = {
      groupId: this.groupId,
      pageSize: 0,
      page: 0,
      orderGroupName: this.searchName,
    };
    this.isLoading = true;
    this.subclinicalorderGroupService
      .searchSubclinicalOrderGroup(payload)
      .subscribe((res) => {
        this.lstData = res.source;
        this.isLoading = false;
        console.log('lstData', res)
      });
  }

  handleCancel() {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }

  toTextPrivacy(privacy: number): string {
    return this.subclinicalorderGroupService.toTextPrivacy(privacy);
  }

  save(data) {
    this.isSaving = true;
    const lstReq = [];
    const listOrderId = data.listOrderId;
    console.log('listOrderId', listOrderId)
    this.subclinicalorderService
      .checkServicesPrice(listOrderId, this.groupId, this.getHISServicePrice)
      .subscribe((res) => {
        if (res.isValid) {
          const response = res.jsonData;
          for (var id of listOrderId) {
            const item = response.find((en) => en.id == id);
            const request = {
              subClinicalOrderId: id,
              amount: 1,
              price: item?.price,
              executionPlace: item?.executionPlace,
            };
            lstReq.push(request);
          }
          this.callService(lstReq);
        }
      });
  }
  callService(subClinicalOrders) {
    const payload = {
      visitId: this.visitId,
      subClinicalOrders: subClinicalOrders,
    };
    this.subClinicalVisitService
      .addSubClinicalVisitGroup(payload)
      .subscribe((res) => {
        if (res.code == -1) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            res.message
          );
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Cập nhật chỉ định thành công!'
          );
          this.isVisible = false;
          this.isVisibleChange.emit(false);
          this.refresh.emit();
        }
        this.isSaving = false;
      });
  }
}
