import { AppConfigService } from 'src/app-config.service';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { Constant } from 'src/app/share/constants/constant.class';
import { VisitService } from '../../../../services/visit.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UploadService } from 'src/app/services/upload.service';
import { ImageModeViewComponent } from 'src/app/components/modal/image-mode-view/image-mode-view.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/components/app-state/app-state';

@Component({
  selector: 'app-list-image-general',
  templateUrl: './list-image-general.component.html',
  styleUrls: ['./list-image-general.component.scss'],
})
export class ListImageGeneralComponent implements OnInit {
  @Input() visitId: string;
  @Input() lstImages;
  @Input() visitDate: Date;
  @Input() hideButton: string;
  @Input() imageFrom = 0;
  @Input()
  get viewImageVisible() {
    return this._viewImageVisible;
  }
  set viewImageVisible(value) {
    this._viewImageVisible = value;
    if (value) {
      this.modeView.visibleSmallSize = true;
    }
  }
  private _viewImageVisible = false;

  @Output() closeShowImage = new EventEmitter();
  @Output() refreshData = new EventEmitter();

  @ViewChild(ImageModeViewComponent) modeView;

  formAdd: FormGroup;
  fileName = '';
  fileList: NzUploadFile[] = [];
  imageId = '';
  loading: boolean;
  isVisibleAddImg: boolean;
  baseImageurl: string;
  header?: any = {
    Authorization: 'Bearer ' + localStorage.getItem(Constant.TOKEN),
  };
  isSpinning = false;
  selectedIndex = 0;
  showAddImage = false;
  currentUserOnline = '';
  isUserCreateImage = false;
  mediaUrl = '';
  userIdOnline = JSON.parse(localStorage.getItem(Constant.USER_INFO)).userId;

  @HostListener('paste') onPastes(event: ClipboardEvent) {
    this.elementRef.nativeElement
      .querySelector('#paste-image')
      .addEventListener('paste', this.onPaste);
  }

  constructor(
    private notificationService: NotificationService,
    private visitService: VisitService,
    private fb: FormBuilder,
    private configService: AppConfigService,
    private uploadService: UploadService,
    private store: Store<AppState>,
    private elementRef: ElementRef
  ) {
    this.formAdd = this.fb.group({
      visitId: [null],
      comment: [null],
      policy: [null],
      fileNames: [null],
      imageId: [null],
      isKeyImage: [false],
    });
    this.baseImageurl =
      this.configService.getConfig().media.baseUrl + '/VisitImage/';
    this.mediaUrl = `${
      this.configService.getConfig().api.baseUrl
    }/api/Upload/uploadFile?TypeUpload=upload_visit_image`;
  }

  ngOnInit() {
    const userIdSub = this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.currentUserOnline = res;
      });
  }

  showModalAddImg(): void {
    this.formAdd.patchValue({
      visitId: this.visitId,
      comment: '',
      policy: 4,
      fileNames: [],
      isKeyImage: false,
    });
    this.fileList = [];
    this.isVisibleAddImg = true;
    this.isUserCreateImage = true;
  }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng);
      observer.complete();
    });

  handleRemoveImg = (file: any) =>
    new Observable<boolean>((obs) => {
      const del = file.response.fileName;
      //this.fileName = this.fileName.filter((en) => { return en !== del });
      obs.next(true);
    });

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        break;
      case 'error':
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Error'
        );
        this.loading = false;
        break;
    }
  }

  cancelAddImg(): void {
    this.isVisibleAddImg = false;
    this.imageId = '';
  }

  addVisitImage(): void {
    const file = this.fileList.map((en) => en.response.fileName);
    this.formAdd.patchValue({
      visitId: this.visitId,
      fileNames: file,
    });
    const formValue = this.formAdd.value;
    if (this.imageId == '') {
      if (file.length == 0) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Vui lòng chọn ảnh'
        );
      } else {
        this.visitService.addVisitImage(formValue).subscribe(
          (res) => {
            if (res.isValid == false) {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.ERROR,
                res.errors.message
              );
            } else {
              const array = res.jsonData;
              array.forEach((element) => {
                this.lstImages.unshift(element);
              });
              this.isVisibleAddImg = false;
              this.refreshData.emit(this.lstImages);
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.SUCCESS,
                'Thêm ảnh thành công'
              );
            }
          },
          (error) => {}
        );
      }
    } else {
      this.visitService.editVisitImages(formValue).subscribe((res) => {
        this.isVisibleAddImg = false;
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Sửa ảnh thành công'
        );
        for (let i = 0; i < this.lstImages.length; i++) {
          const image = this.lstImages[i];
          if (formValue.imageId == image.id) {
            this.lstImages[i].privacyPolicy = formValue.policy;
            this.lstImages[i].title = formValue.title;
            this.lstImages[i].comment = formValue.comment;
            this.lstImages[i].isKeyImage = formValue.isKeyImage;
            break;
          }
        }
        this.imageId = '';
      });
    }
  }

  onPaste(event: ClipboardEvent) {
    const clipboardItems = event.clipboardData.items;
    const items = [].slice.call(clipboardItems).filter(function (item) {
      // Filter the image items only
      return item.type.indexOf('image') !== -1;
    });
    if (items.length === 0) {
      return;
    }

    const item = items[0];
    const blob = item.getAsFile();
    this.isSpinning = true;

    this.uploadService.saveFile(blob).subscribe((res) => {
      //console.log(res);
      const file: NzUploadFile = {
        uid: res.fileNameOnly,
        name: '',
        status: 'done',
        url: this.baseImageurl + res.fileName,
        response: res,
      };
      // should not use push or splice to change the data passed to nzFileList
      this.fileList = this.fileList.concat(file);
      this.isSpinning = false;
    });
  }

  //popup edit anh
  showModalEditImg(image): void {
    this.isVisibleAddImg = true;
    this.imageId = image.id;
    this.fileName = image.imageURL;
    this.formAdd.patchValue({
      visitId: this.visitId,
      //Title: image.title,
      comment: image.comment,
      policy: image.privacyPolicy,
      fileNames: image.imageURL,
      imageId: image.id,
      isKeyImage: image.isKeyImage,
    });
    this.isUserCreateImage = this.currentUserOnline == image.creatorId;
  }

  cancelEditImg(): void {
    this.isVisibleAddImg = false;
    this.imageId = '';
  }

  closeModal() {
    this.closeShowImage.emit();
  }

  refresh(data) {
    this.refreshData.emit(data);
  }
}
