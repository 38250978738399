<div class="box-header">
  <div class="box-title" (click)="collapseBox()">
    <i nz-icon [nzType]="!isCollapsed ? 'down' : 'right'" nzTheme="outline"></i>
    Quan hệ gia đình
  </div>
  <div class="box-tools" *ngIf="!isCollapsed">
    <i title="Thêm thành viên" (click)="openAddMember()">
      <img class="icon-add" src="assets/image/DPicon/icon-add-green.svg" />
    </i>
  </div>
</div>
<div class="box-body" *ngIf="!isCollapsed">
  <nz-table
    #basicTable
    [nzData]="members"
    class="main-table-grid family-grid"
    [nzShowPagination]="false">
    <thead>
      <tr>
        <th class="center">STT</th>
        <th class="center">Tên</th>
        <th class="center">Quan hệ với chủ hộ</th>
        <th class="center"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let data of basicTable.data; let i = index"
        title="Mở ca khám của bệnh nhân"
        [ngClass]="'sos-' + data.sosRange">
        <td class="center" (click)="getNewestVisitMember(data)">
          {{ basicTable.nzPageSize * (basicTable.nzPageIndex - 1) + i + 1 }}
        </td>
        <td (click)="getNewestVisitMember(data)">{{ data.fullName }}</td>
        <td class="center" (click)="getNewestVisitMember(data)">
          {{ data.relationShip }}
        </td>
        <td class="center">
          <i
            *ngIf="data.relationShip != 'CHỦ HỘ'"
            class="mg-r-4"
            title="Sửa thành viên"
            nz-icon
            nzType="edit"
            nzTheme="outline"
            (click)="editRelationship(data)"></i>
          <i
            *ngIf="
              visit.patientId != data.patientId && data.relationShip != 'CHỦ HỘ'
            "
            title="Xóa thành viên"
            nz-icon
            nzType="delete"
            nzTheme="outline"
            (click)="confirmDelete(data.id)"></i>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<!-- <label class="title-boldtext-color">Quan hệ gia đình</label>
<a (click)="openAddMember()">
  <i title="Thêm thành viên" >
    <img class="icon-add" src="assets/image/DPicon/icon-add-green.svg" />
  </i>
</a> -->

<!-- <nz-modal [(nzVisible)]="isVisibleAdd" [nzWidth]="500" nzTitle="Thêm thành viên gia đình" (nzOnCancel)="closeAddMember()"> -->
<app-m-modal
  [modalId]="'family-member'"
  [isVisible]="isVisibleAdd"
  (closeModal)="closeAddMember()"
  [mMask]="true"
  [width]="500"
  [minWidth]="400"
  [height]="400"
  [minHeight]="300"
  [isResiable]="true">
  <ng-template #mModalTitle>
    <div class="header-title">Thêm thành viên gia đình</div>
  </ng-template>
  <ng-template #mModalContent>
    <ng-container *ngIf="isAdd">
      <div class="title">Tìm kiếm bệnh nhân</div>
      <input
        #keywordPatient
        minLength="2"
        nz-input
        (input)="onSearchPatient(keywordPatient.value)"
        [nzAutocomplete]="auto"
        [(ngModel)]="searchName" />
      <nz-autocomplete #auto>
        <nz-auto-option
          *ngFor="let item of searchvalue"
          [nzValue]="item.fullName"
          (click)="selectSearchPatient(item)">
          <span>
            <p>{{ item.fullName }}</p>
            <i>{{ item.address }}</i>
          </span>
        </nz-auto-option>
      </nz-autocomplete>
      <br />
    </ng-container>

    <div *ngIf="curMemberId" style="margin-top: 20px">
      <div class="title">Thông tin bệnh nhân</div>
      <div class="card" style="width: 100%">
        <div class="row col-12">
          <div class="col-4 label">Tên bệnh nhân</div>
          <div class="col-8">
            <strong>{{ seletedPatient.fullName }}</strong>
          </div>
        </div>
        <div class="row col-12">
          <div class="col-4 label">Ngày sinh</div>
          <div class="col-8">
            <strong>{{ seletedPatient.dobString }}</strong>
          </div>
        </div>
        <div class="row col-12">
          <div class="col-4 label">Địa chỉ</div>
          <div class="col-8">
            <strong>{{ seletedPatient.address }}</strong>
          </div>
        </div>
      </div>
    </div>
    <br />
    <form nz-form [formGroup]="formData">
      <nz-form-item>
        <nz-form-label [nzSpan]="8" nzRequired nzFor="name"
          >Quan hệ với chủ hộ</nz-form-label
        >
        <nz-form-control nzHasFeedback [nzErrorTip]="nameErrorTpl">
          <input
            formControlName="relationship"
            nz-input
            placeholder="Quan hệ" />
          <ng-template #nameErrorTpl let-control>
            <ng-container *ngIf="control.hasError('emptyName')">
              Quan hệ với chủ hộ
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">
              Hãy nhập quan hệ!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>
  <ng-template #mModalFooter>
    <button
      nz-button
      class="btn-primary-medium"
      [disabled]="disableBtn"
      (click)="addMember()">
      {{ isAdd ? 'Thêm' : 'Sửa' }}
    </button>
    <button nz-button class="btn-cancel-medium" (click)="closeAddMember()">
      Đóng
    </button>
  </ng-template>
  <!-- <div *nzModalFooter>
    <button nz-button nzType="primary" class="btn-add-color" [disabled]="disableBtn" (click)="addMember()">{{isAdd ? 'Thêm' : 'Sửa'}}</button>
    <button nz-button (click)="closeAddMember()">Đóng</button>
  </div> -->
</app-m-modal>
<!-- </nz-modal> -->
