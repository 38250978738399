import { Action } from '@ngrx/store';
import { User } from '../../../models/user.class';
import { AuthModel } from '../../../models/auth.model';

export enum AuthActionTypes {
  Login = '[User] Login',
  SSOLogin = '[User] SSO Login',
  LoginSuccess = '[User] Login Success',
  LoginFail = '[User] Login Fail',
  Logout = '[User] Logout',
  SSOLogout = '[User] SSO Logout',
  LogoutSuccess = '[User] Logout Success',
  LogoutFail = '[User] Logout Fail',
  LockScreen = '[User] Lock Screen',
  UnLockScreen = '[User] UnLock Screen',
  UpdateRoom = '[User] Update Room',
  ClearTime = '[User] Clear Time',
  ResetLockScreen = '[User] Reset Lock Screen',
}
export class Login implements Action {
  public readonly type = AuthActionTypes.Login;
  constructor(public payload: any) {}
}

export class SSOLogin implements Action {
  public readonly type = AuthActionTypes.SSOLogin;
  constructor(public returnURL: string) {}
}

export class LoginSuccess implements Action {
  public readonly type = AuthActionTypes.LoginSuccess;
  constructor(public payload: AuthModel) {}
}
export class LoginFail implements Action {
  public readonly type = AuthActionTypes.LoginFail;
  constructor(public err: string) {}
}
export class Logout implements Action {
  constructor(public returnURL: string,public queryParam?:any) {}
  public readonly type = AuthActionTypes.Logout;
}
export class SSOLogout implements Action {
  constructor(public returnURL: string) {}
  public readonly type = AuthActionTypes.SSOLogout;
}
export class LogoutSuccess implements Action {
  public readonly type = AuthActionTypes.LogoutSuccess;
  // constructor(public payload: any) {}
}
export class LogoutFail implements Action {
  public readonly type = AuthActionTypes.LogoutFail;
  // constructor(public err: string) {}
}

export class ResetLockScreen implements Action {
  public readonly type = AuthActionTypes.ResetLockScreen;
}
export class LockScreen implements Action {
  public readonly type = AuthActionTypes.LockScreen;
}
export class UnLockScreen implements Action {
  public readonly type = AuthActionTypes.UnLockScreen;
}
export class UpdateRoom implements Action {
  public readonly type = AuthActionTypes.UpdateRoom;
  constructor(public roomId: string, public roomName: string) {}
}
export class ClearTime implements Action {
  public readonly type = AuthActionTypes.ClearTime;
}
export type AuthActions =
  | Login
  | SSOLogin
  | LoginSuccess
  | LoginFail
  | SSOLogout
  | Logout
  | LogoutSuccess
  | LockScreen
  | UnLockScreen
  | ResetLockScreen
  | UpdateRoom
  | ClearTime
  | LogoutFail;
