import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConfigService } from 'src/app-config.service';
import { AddressService } from 'src/app/services/address.service';
import { CovidService } from 'src/app/services/covid.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-covid-add-patient',
  templateUrl: './covid-add-patient.component.html',
  styleUrls: ['./covid-add-patient.component.scss'],
})
export class CovidAddPatientComponent implements OnInit {
  @Input()
  get isVisible() {
    return this._isVisible;
  }
  set isVisible(value) {
    this._isVisible = value;
    if (value) {
      this.formAdd = {
        fullName: '',
        address: '',
        dateOfBirth: null,
        phone: '',
        emergencyPhone: '',
        height: null,
        weight: null,
        dateOfDiagnosis: '',
        dateOfTestCovid: null,
        vaccinated: '',
        trieuChungNang: [],
        groupId: '',
        myDiseases: '',
        provinceId: '',
        provinceName: '',
        districtId: '',
        districtName: '',
        wardId: '',
        wardName: '',
        listVaccinated: [],
      };
      this.listOfTagDiseases = [];
      this.refreshCheckboxGroup(this.trieuChungNangSource);
      //this.trieuChungNangSource.forEach(en => en.checked = false);
      //this.getProvince();
      if (this.listProvince.length == 1) {
        this.formAdd.provinceId = this.listProvince[0].id;
        this.getDistrictByProvinceId(this.listProvince[0].id);
      }
    }
  }
  private _isVisible = false;
  @Input() groupId = '';

  @Output() closeModal = new EventEmitter<any>();
  @Output() refreshData = new EventEmitter<any>();
  formAdd = {
    fullName: '',
    //sex: 0,
    address: '',
    dateOfBirth: null,
    phone: '',
    emergencyPhone: '',
    height: null,
    weight: null,
    dateOfDiagnosis: '',
    dateOfTestCovid: null,
    vaccinated: '',
    trieuChungNang: [],
    groupId: '',
    myDiseases: '',
    provinceId: '',
    provinceName: '',
    districtId: '',
    districtName: '',
    wardId: '',
    wardName: '',
    listVaccinated: [],
  };
  listOfTagDiseases = [];
  listProvince = [];
  listDistrict = [];
  listWard = [];
  provinceCodeDefault = this.configService.getConfig().provinceCode;

  currentDiseasesChecked = [];
  myDiseasesSource = [
    { label: 'Không', value: 'Không', disabled: false },
    { disabled: false, label: 'Đái tháo đường', value: 'Đái tháo đường' },
    {
      disabled: false,
      label: 'Bệnh phổi tắc nghẽn mãn tính và các bệnh phổi khác',
      value: 'Bệnh phổi tắc nghẽn mãn tính và các bệnh phổi khác',
    },
    {
      disabled: false,
      label:
        'Ung thư (đặc biệt là các khối u ác tính về huyết học, ung thư phổi và bệnh ung thư di căn khác)',
      value:
        'Ung thư (đặc biệt là các khối u ác tính về huyết học, ung thư phổi và bệnh ung thư di căn khác)',
    },
    {
      disabled: false,
      label: 'Bệnh thận mạn tính',
      value: 'Bệnh thận mạn tính',
    },
    {
      disabled: false,
      label: 'Ghép tạng hoặc cấy ghép tế bào gốc tạo máu',
      value: 'Ghép tạng hoặc cấy ghép tế bào gốc tạo máu',
    },
    {
      disabled: false,
      label: 'Bệnh tim mạch (suy tim, bệnh động mạch vành hoặc bệnh cơ tim)',
      value: 'Bệnh tim mạch (suy tim, bệnh động mạch vành hoặc bệnh cơ tim)',
    },
    {
      disabled: false,
      label: 'Bệnh lý mạch máu não (đột quỵ, cơn thoáng thiếu máu não)',
      value: 'Bệnh lý mạch máu não (đột quỵ, cơn thoáng thiếu máu não)',
    },
    { disabled: false, label: 'Hội chứng Down', value: 'Hội chứng Down' },
    { disabled: false, label: 'HIV/AIDS', value: 'HIV/AIDS' },
    {
      disabled: false,
      label: 'Bệnh lý thần kinh, bao gồm cả chứng sa sút trí tuệ',
      value: 'Bệnh lý thần kinh, bao gồm cả chứng sa sút trí tuệ',
    },
    {
      disabled: false,
      label: 'Bệnh hồng cầu hình liềm',
      value: 'Bệnh hồng cầu hình liềm',
    },
    {
      disabled: false,
      label: 'Rối loạn sử dụng chất gây nghiện',
      value: 'Rối loạn sử dụng chất gây nghiện',
    },
    {
      disabled: false,
      label: 'Sử dụng corticosteroid hoặc các thuốc ức chế miễn dịch khác',
      value: 'Sử dụng corticosteroid hoặc các thuốc ức chế miễn dịch khác',
    },
    {
      disabled: false,
      label: 'Các loại bệnh hệ thống',
      value: 'Các loại bệnh hệ thống',
    },
    {
      disabled: false,
      label: 'Bệnh tăng huyết áp',
      value: 'Bệnh tăng huyết áp',
    },
    { disabled: false, label: 'Hen phế quản', value: 'Hen phế quản' },
    {
      disabled: false,
      label: 'Thiếu hụt miễn dịch',
      value: 'Thiếu hụt miễn dịch',
    },
    { disabled: false, label: 'Bệnh gan', value: 'Bệnh gan' },
    { disabled: false, label: 'Béo phì', value: 'Béo phì' },
    { disabled: false, label: 'Có thai', value: 'Có thai' },
  ];
  trieuChungNangSource = [
    { label: 'Không', value: 'Không', checked: false, otherValue: 'other' },
    { checked: false, label: 'Khó thở', value: 'Khó thở' },
    { checked: false, label: 'Thở rít', value: 'Thở rít' },
    { checked: false, label: 'Nặng ngực', value: 'Nặng ngực' },
    {
      checked: false,
      label: 'Rất mệt mỏi, chỉ muốn ngủ',
      value: 'Rất mệt mỏi, chỉ muốn ngủ',
    },
    { checked: false, label: 'Chóng mặt', value: 'Chóng mặt' },
    { checked: false, label: 'Môi xanh tím', value: 'Môi xanh tím' },
    { checked: false, label: 'Ho ra máu', value: 'Ho ra máu' },
  ];

  visibleAccountInfo = false;
  accountInfo = {
    username: '',
    password: '',
    patientName: '',
  };
  isLoading = false;
  listVaccinatedSource = [
    { label: 'Mũi 1', value: '', used: 1 },
    { label: 'Mũi 2', value: '', used: 2 },
    { label: 'Mũi 3', value: '', used: 3 },
  ];
  listVaccinated = [];

  constructor(
    private covidService: CovidService,
    private shareService: ShareService,
    private notificationService: NotificationService,
    private addressService: AddressService,
    private configService: AppConfigService
  ) {}

  ngOnInit(): void {
    this.getProvince();
  }
  handleCancel() {
    this.isVisible = false;
    this.closeModal.emit();
  }
  BMIValue() {
    const h = this.formAdd.height;
    const w = this.formAdd.weight;
    if (h != null && h != 0 && w != null && w != 0) {
      return (w / ((h / 100) * (h / 100))).toFixed(1);
    }
    return 0;
  }
  onChangeDiseases() {
    const khongco = this.myDiseasesSource[0].value;
    if (this.listOfTagDiseases.some((en) => en == khongco)) {
      this.currentDiseasesChecked = this.listOfTagDiseases.filter(
        (en) => en != khongco
      );
      this.listOfTagDiseases = [khongco];
      this.myDiseasesSource.forEach((en) => {
        if (en.value != khongco) {
          en.disabled = true;
        }
      });
    } else {
      if (this.currentDiseasesChecked.length != 0) {
        this.listOfTagDiseases = this.currentDiseasesChecked;
      }
      this.myDiseasesSource.forEach((en) => {
        en.disabled = false;
      });
    }
  }
  save() {
    const form = this.formAdd;
    const trieuChungNang = [];
    this.trieuChungNangSource.forEach((en) => {
      if (en.checked) {
        trieuChungNang.push(en.value);
      }
    });
    const dose = this.toIntDose(form.vaccinated);
    if (
      this.shareService.checkEmptyStr(form.fullName) ||
      this.shareService.checkEmptyStr(form.address) ||
      this.shareService.checkEmpty(form.dateOfBirth) ||
      this.shareService.checkEmptyStr(form.phone) ||
      this.shareService.checkEmptyStr(form.emergencyPhone) ||
      this.shareService.checkEmpty(form.height) ||
      this.shareService.checkEmpty(form.weight) ||
      this.shareService.checkEmpty(form.dateOfDiagnosis) ||
      this.shareService.checkEmpty(form.vaccinated) ||
      trieuChungNang.length == 0 ||
      this.listOfTagDiseases.length == 0 ||
      this.shareService.checkEmptyStr(form.provinceId) ||
      this.shareService.checkEmptyStr(form.districtId) ||
      this.shareService.checkEmptyStr(form.wardId) ||
      (dose > 0 && this.listVaccinated.length != dose)
    ) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn chưa nhập đủ thông tin bắt buộc!'
      );
    } else {
      this.formAdd.trieuChungNang = trieuChungNang;
      this.formAdd.groupId = this.groupId;
      this.formAdd.myDiseases = this.listOfTagDiseases.join('#');
      //tỉnh/huyện/xã
      this.formAdd.provinceName = this.listProvince.filter(
        (en) => en.id == this.formAdd.provinceId
      )[0].fullname;
      this.formAdd.districtName = this.listDistrict.filter(
        (en) => en.id == this.formAdd.districtId
      )[0].name;
      this.formAdd.wardName = this.listWard.filter(
        (en) => en.id == this.formAdd.wardId
      )[0].name;
      const listVaccinated = [];
      this.listVaccinated.forEach((en) => {
        listVaccinated.push(en.value);
      });
      this.formAdd.listVaccinated = listVaccinated;
      //console.log("formAdd", this.formAdd);
      this.isLoading = true;
      this.covidService
        .addPatientCovid19ForDoctor(this.formAdd)
        .subscribe((res) => {
          if (res.isValid) {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.SUCCESS,
              'Thêm bệnh nhân và tiếp nhận thành công!'
            );
            this.refreshData.emit();
            this.isVisible = false;
            // show thông tin tài khoản BN
            const data = res.jsonData;
            this.accountInfo = {
              username: data.autoGenUsername,
              password: data.autoGenPassword,
              patientName: data.patientName,
            };
            this.visibleAccountInfo = true;
            this.isLoading = false;
          } else {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.ERROR,
              res.errors[0].errorMessage
            );
          }
        });
    }
  }
  getProvince() {
    this.addressService.getProvince().subscribe((res) => {
      if (res.isValid && res.jsonData.length > 0) {
        // nếu có config provinceCode
        if (this.provinceCodeDefault.length > 0) {
          this.provinceCodeDefault.forEach((element) => {
            const province = res.jsonData.filter((en) => en.code == element)[0];
            this.listProvince.push(province);
          });
        } else {
          this.listProvince = res.jsonData;
        }
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }
  getDistrictByProvinceId(id) {
    this.formAdd.districtId = '';
    this.formAdd.wardId = '';
    this.addressService.getDistrictByProvinceId(id).subscribe((res) => {
      if (res.isValid) {
        this.listDistrict = res.jsonData;
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }
  getWardByDistrictId(id) {
    this.formAdd.wardId = '';
    this.addressService.getWardByProvinceId(id).subscribe((res) => {
      if (res.isValid) {
        this.listWard = res.jsonData;
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }
  cancelAccountInfo() {
    this.visibleAccountInfo = false;
  }
  vaccinatedChange(vaccinated) {
    const result = this.toIntDose(vaccinated);
    this.listVaccinated = this.listVaccinatedSource.filter(
      (en) => en.used <= result
    );
  }
  toIntDose(vaccinated): number {
    switch (vaccinated) {
      case '1 mũi':
        return 1;
      case '2 mũi':
        return 2;
      case '3 mũi':
        return 3;
      case 'Chưa':
      case 'null':
        return 0;
      default:
        return 0;
    }
  }
  checkboxGroupChange(source, option, type?) {
    const khong = source
      .filter((en) => en.checked)
      .some((en) => en.otherValue == option);
    if (khong) {
      source.forEach((en) => {
        if (en.otherValue != option) {
          en.disabled = true;
          en.checked = false;
        }
      });
    } else {
      source.forEach((en) => {
        en.disabled = false;
      });
    }
    console.log('checkboxGroupChange', option, source);
  }
  refreshCheckboxGroup(source) {
    source.forEach((en) => {
      en.checked = false;
      en.disabled = false;
    });
  }
}
