//import { SharePrescriptionComponent } from './components/common-layout/share-prescription/share-prescription.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChartComponent } from './components/worklist/visit-detail/vital-signs-tab/chart/chart.component';
import { AuthGuard } from './share/guards/guards.class';
import { SsoLoginComponent } from './components/sso-login/sso-login.component';
import { ConferenceRoomCallComponent } from './components/common-layout/conference-room-call/conference-room-call.component';
import { SharePrescriptionComponent } from './components/prescription/share-prescription/share-prescription.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./components/common-layout/layout.module').then(
        (m) => m.LayoutModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./components/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'waiting-page',
    loadChildren: () =>
      import('./components/common-layout/layout.module').then(
        (m) => m.LayoutModule
      ),
  },
  { path: 'chart', component: ChartComponent },
  { path: 'share-prescription/:token', component: SharePrescriptionComponent },
  { path: 'sso-login', component: SsoLoginComponent },
  {
    path: 'video-conference/:notiId',
    component: ConferenceRoomCallComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
