import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constant } from 'src/app/share/constants/constant.class';
import { GroupMemberService } from '../../../services/group-member.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Invitation } from 'src/app/models/invitaion.model';
import { AuthService } from '../../../services/auth.service';
import { AppConfigService } from 'src/app-config.service';
import { LoginSuccess, Logout } from '../redux/auth.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: './confirm-invite.component.html',
  styleUrls: ['./confirm-invite.component.scss'],
  providers: [GroupMemberService],
})
export class ConfirmInviteComponent implements OnInit, OnDestroy {
  code: string;
  status: number;
  invivation: Invitation;
  notExistedPMR = false;
  isRegist = false;
  inviteEmail: string;
  isRejectOk = false;
  notExisted = false;
  newUID: string;
  joinGID: string;
  curUrl: string;
  PathAvatar: string = this.configService.getConfig().media.baseUrl;
  ExistedMSA = false;
  UrlLogin: string;
  caseText: string;
  currentUserOnline: string;
  currentUserName: string;
  sub: Subscription;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private groupMemberService: GroupMemberService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private configService: AppConfigService,
    private store: Store<AppState>
  ) {
    this.curUrl = window.location.origin + '/common/worklist';
    //this.router.navigate(['/login'], { queryParams: { returnUrl: '/common/worklist'} });
  }
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
  ngOnInit(): void {
    // if đồng ý
    // Nếu đồng ý mà chưa là thành viên thì hiện form đang ký thành viên==> submit call api đky==> call api join
    // nếu đã là thành viên ==> call api join
    // check info ok
    //localStorage.removeItem(Constant.SESSION_INVITE_FIRST_USER_PMR);
    const newUserLogined = localStorage.getItem(Constant.SESSION_INVITE_FIRST_USER_PMR);
    this.code = this.route.snapshot.queryParamMap.get('Code');
    this.status = this.route.snapshot.queryParamMap.get('Type') == '1' ? 1 : -1;

    this.store
      .select((store) => store.auth.userName)
      .subscribe((res) => {
        this.currentUserName = res;
      });
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.currentUserOnline = res;
      });
    console.log('currentUserOnline : ', this.currentUserOnline);
    //const currentUser = localStorage.getItem(Constant.USER_INFO);
    const currentToken = localStorage.getItem(Constant.TOKEN);
    // if (this.currentUserName) {
    //   //const userInfo = JSON.parse(currentUser);

    //   // if (currentToken && currentUser) {
    //   //   this.authService.checkToken().subscribe(
    //   //     (response) => {
    //   //       this.store.dispatch(new LoginSuccess(userInfo));
    //   //     },
    //   //     (err) => {
    //   //       this.store.dispatch(new Logout(''));
    //   //     }
    //   //   );
    //   // }
    //   this.currentUserOnline = userInfo.userId;
    // }
    // const currentUser = localStorage.getItem(Constant.TOKEN);
    // if (currentUser) {
    //   this.router.navigate([Constant.WELCOME]);
    // }

    // const userIdSub = this.store
    //   .select((store) => store.auth.userId)
    //   .subscribe((auth) => {
    //     if (auth) {
    //this.currentUserOnline = auth;
    if (newUserLogined !== null && newUserLogined !== '') {
      localStorage.removeItem(Constant.SESSION_INVITE_FIRST_USER_PMR);
    }

    this.groupMemberService.getInvitation(this.code).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.invivation = res.jsonData;
            if (this.status != 1) {
              this.rejectJoin();
              return;
            }
            this.joinGID = this.invivation.groupId;
            if (
              this.invivation.invitedUserId === null ||
              this.invivation.invitedUserId === '' ||
              this.invivation.invitedUserId === '000000000000000000000000'
            ) {
              if (this.invivation.status === 'NOT_EXIST_IN_MSA') {
                this.notExistedPMR = true;
                if (this.currentUserOnline == null) {
                  this.caseText = 'USERINVITED-ISNULL';
                  this.inviteEmail = this.invivation.email;
                  this.isRegist = true;
                } else {
                  this.caseText = 'USERINVITED-ISNULL-BUT-ONLINEOTHER';
                  this.inviteEmail = this.invivation.email;

                  //             const userNameSub = this.store.select((store) => store.auth.userName);
                  //             userNameSub.subscribe((un) => {
                  //               this.currentUserName = un;
                  //             });

                  //             this.store
                  // .select((store) => store.auth.userName)
                  // .subscribe((res) => {
                  //   this.username = res;
                  // });
                  this.isRegist = false;
                }
              } else if (this.invivation.status === 'EXIST_IN_MSA_ONLY') {
                this.notExistedPMR = true;
                this.ExistedMSA = true;

                if (this.currentUserOnline == null) {
                  this.caseText = 'USERINVITED-ISNULL';
                  localStorage.setItem(Constant.SESSION_INVITE_FIRST_USER_PMR, this.joinGID);
                  // if (this.configService.getConfig().isUseSSOLogin)
                  //   this.UrlLogin =
                  //     window.location.origin +
                  //     '/sso-login?returnUrl=/common/worklist&Code=' +
                  //     this.code +
                  //     '&isRegist=OK';
                  // else
                  this.UrlLogin =
                    window.location.origin + '/login?returnUrl=/common/worklist&Code=' + this.code + '&isRegist=OK';
                } else {
                  this.caseText = 'EXIST-MSA-BUT-ONLINEOTHER';

                  localStorage.setItem(Constant.SESSION_INVITE_FIRST_USER_PMR, this.joinGID);

                  // const userNameSub = this.store.select((store) => store.auth.userName);
                  // userNameSub.subscribe((un) => {
                  //   this.currentUserName = un;
                  // });
                }
              }
            } else {
              if (this.status == 1) {
                console.log('ne', this.currentUserOnline);
                // return;
                //const userLogined = JSON.parse(localStorage.getItem(Constant.USER_INFO));
                //console.log('userLogined',userLogined);
                if (this.currentUserOnline == null) {
                  this.toLoginFirstPmr(true);
                }
                //note: case with user đang đồng ý join nhưng đang online với tài khoản khác
                else if (this.currentUserOnline != null && this.invivation.invitedUserId !== this.currentUserOnline) {
                  this.caseText = 'USERLOGINED-REJECT';
                  // const userNameSub = this.store.select((store) => store.auth.userName);
                  // userNameSub.subscribe((res) => {
                  //   this.currentUserName = res;
                  // });
                  return;
                } else if (this.currentUserOnline != null && this.invivation.invitedUserId === this.currentUserOnline) {
                  this.acceptJoin(2);
                } else {
                  this.acceptJoin(3);
                } //join with user logined
              }
            }
          } else {
            if (res.errors && res.errors.length > 0) {
              if (res.errors.filter((en) => en.key === 'NotExisted')) {
                //Accepted
                this.notExisted = true;
                //this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
              } else {
                this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
              } //Check nếu đã là thành viên rồi thì báo
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
    //   } else console.log('ne', auth);
    // });
  }
  toLoginFirstPmr(isFisrtLogin) {
    const url = this.router.routerState.snapshot.url;
    localStorage.setItem(Constant.SESSION_INVITE_FIRST_USER_PMR, this.joinGID);
    if (isFisrtLogin) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: '/common/worklist?', Code: this.code },
      });
    }
    // else
    //   this.router.navigate(['/login'], { queryParams: { returnUrl: url } });
  }

  logoutToJoin(toRegist) {
    localStorage.setItem(Constant.SESSION_INVITE_FIRST_USER_PMR, this.joinGID);
    //this.router.navigate(['/login']);
    if (toRegist) {
      this.authService.logout({ returnUrl: '' }).subscribe((res) => {
        this.store.dispatch(new Logout(''));
        this.isRegist = true;
        this.caseText = 'USERINVITED-ISNULL';
      });
      //}
    } else {
      var returnUrl = '/common/worklist&Code=' + this.code;
      this.authService.logout({ returnUrl: returnUrl }).subscribe((res) => {
        this.store.dispatch(new Logout(returnUrl));
        //this.toLoginFirstPmr(true);
      });
    }
  }
  logoutToJoin2() {
    var returnUrl = '/common/worklist&Code=' + this.code + '&isRegist=OK';
    var sub = this.authService.logout({ returnUrl: returnUrl }).subscribe((res) => {
      this.store.dispatch(new Logout(returnUrl));

      //this.router.navigate(['/login']);
      // if (toRegist) {
      //   this.isRegist = true;
      //   this.caseText = 'USERINVITED-ISNULL';
      //   //}
      // } else {
      //   this.toLoginFirstPmr(true);
      // }
    });
  }

  acceptJoin(isNewPmr) {
    const payload = {
      code: this.code,
      status: this.status,
      userid: this.newUID,
    };
    this.groupMemberService.acceptJoinGroup(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            //le.huathi - rem: don't go to the new group for now
            // this.store.dispatch(new Join(this.joinGID));
            if (isNewPmr === 1) {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.INFO,
                'Bạn đã đăng ký và tham gia nhóm làm việc thành công. Hãy đăng nhập để vào trang làm việc!'
              );
            } else {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.INFO,
                'Bạn đã tham gia nhóm làm việc thành công!'
              );
            }
            if (isNewPmr == 2) {
              localStorage.removeItem(Constant.SESSION_INVITE_FIRST_USER_PMR);
            }

            //neu là thành viên thì login
            if (isNewPmr != 1) {
              this.router.navigate(['/common/worklist']);
            }
          } else {
            if (res.errors && res.errors.length > 0) {
              if (res.errors.filter((en) => en.key === 'Accepted')) {
                //Accepted
                this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
              } else {
                this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
              }
              //Check nếu đã là thành viên rồi thì báo
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }

  addUser(formValue): void {
    //Obj value of form regist
    //call api regist user here
    this.authService.regist(formValue).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.newUID = res.jsonData;
            this.isRegist = false;
            this.acceptJoin(1);

            this.router.navigate(['/login'], {
              queryParams: { returnUrl: '/common/worklist' },
            });

            //call api accept join
          } else if (res.errors.length > 0 && (res.errors[0].key === '10' || res.errors[0].key === '5')) {
            //this.caseText = 'USERNOTLOGIN-EXISTMSA';
            this.ExistedMSA = true;
            localStorage.setItem(Constant.SESSION_INVITE_FIRST_USER_PMR, this.joinGID);
            localStorage.setItem('MemberRegistForm', JSON.stringify(formValue));
            this.UrlLogin =
              window.location.origin + '/login?returnUrl=/common/worklist&Code=' + this.code + '&isRegist=OK';
            if (res.errors[0].key === '5') {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.WARRING,
                'MSA đã tồn tại tài khoản với email bạn vừa đăng ký!'
              );
            } else {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.WARRING,
                'MSA đã tồn tại tài khoản với email hoặc username bạn vừa đăng ký!'
              );
            }
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  logout() {
    this.authService.logout({ returnUrl: '' }).subscribe((res) => {
      this.store.dispatch(new Logout(''));
      //this.router.navigate(['/login']);
      const url = this.router.routerState.snapshot.url;
      this.router.navigate(['/login'], { queryParams: { returnUrl: url } });
    });
  }
  rejectJoin() {
    const payload = {
      code: this.code,
      status: this.status,
    };
    this.groupMemberService.rejectJoinGroup(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.isRejectOk = true;
            //neu là thành viên thì login
            //this.router.navigate(['/login']);
          } else {
            if (res.errors && res.errors.length > 0) {
              if (res.errors.filter((en) => en.key === 'Accepted')) {
                //Accepted
                this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
              } else {
                this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
              }
              //Check nếu đã là thành viên rồi thì báo
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
}
