import { Injectable } from '@angular/core';
import { Constant } from '../share/constants/constant.class';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  EMPTY_ID = '000000000000000000000000';
  NON_UNICODE_REGEX = new RegExp('^(?:[\u0000-\u007F]+|[\u0370-\u03FF]+)$');
  NON_WHITESPACE_REGEX = /^$|\s+/;
  constructor() {}

  checkEmpty(value) {
    if (value == null || value == '' || value == 'undefined') {
      return true;
    }
    return false;
  }

  isMobileAndTabletCheck(): boolean {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor);
    return check;
  }

  checkEmptyObj(value) {
    if (value == null || value == undefined || value == '') {
      return true;
    }
    return false;
  }

  checkEmptyObjContainKeys(value) {
    if (value == null || value == undefined || value == '' || Object.keys(value).length === 0) {
      return true;
    }
    return false;
  }
  checkEmptyArray(value) {
    return !Array.isArray(value) || value.length == 0;
  }

  checkEmptyStr(value) {
    if (value == null || value == '' || value == 'undefined' || value.trim() == '') {
      return true;
    }
    return false;
  }

  hasWhiteSpace(s) {
    if (s == null || s == '' || s == 'undefined') {
      return false;
    }
    return s.indexOf(' ') >= 0;
  }

  debounce = (func, delay) => {
    let timerId;
    return function () {
      clearTimeout(timerId);
      timerId = setTimeout(() => func.apply(this, arguments), delay);
    };
  };

  throttle = (func, delay) => {
    let toThrottle = false;
    return function () {
      if (!toThrottle) {
        toThrottle = true;
        func.apply(this, arguments);
        setTimeout(() => {
          toThrottle = false;
        }, delay);
      }
    };
  };

  toNomalize(value) {
    if (value) {
      return value
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, 'd')
        .replace(/Đ/g, 'D');
    }
    return '';
  }

  toDDMMYY(date) {
    try {
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      const year = date.getFullYear();
      return day + '/' + month + '/' + year;
    } catch (ex) {
      console.error(ex);
    }
  }

  covertDDMMYYtoYear(value) {
    try {
      const splitDate = value.trim().split('/');
      const date = new Date(splitDate[2], splitDate[1], splitDate[0]);
      return date;
    } catch (ex) {
      console.error(ex);
    }
  }

  datatimeToDate(datetime: Date) {
    if (datetime == null) {
      return null;
    }
    const day = datetime.getDate();
    const month = datetime.getMonth();
    const year = datetime.getFullYear();

    return new Date(year, month, day);
  }

  datatimeToDateTimeZero(datetime: Date) {
    if (datetime == null) {
      return null;
    }
    const timezone = -datetime.getTimezoneOffset() / 60;
    const day = datetime.getDate();
    const month = datetime.getMonth();
    const year = datetime.getFullYear();

    return new Date(year, month, day, timezone, 0);
  }

  calcDateFromString(start, endString) {
    const date = new Date(endString);
    const nonTimeZoneDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const diff = start.getTime() - nonTimeZoneDate.getTime();
    return Math.floor(diff / Constant.DAY_MILISECOND);
  }

  calcAge(dob) {
    if (isNaN(Date.parse(dob))) {
      return '';
    }
    const now = new Date();
    const dobDate = new Date(dob);

    if (dobDate > now) {
      return '';
    }

    return now.getFullYear() - dobDate.getFullYear();
  }

  calcAgeString(dob, isShowTextAge = true) {
    let result = '';
    if (isNaN(Date.parse(dob))) {
      return result;
    }
    const now = new Date();
    const dobDate = new Date(dob);

    if (dobDate > now) {
      return result;
    }

    if (now.getFullYear() == dobDate.getFullYear() && now.getMonth() == dobDate.getMonth()) {
      result = now.getDate() - dobDate.getDate() + ' ngày';
    } else if (now.getFullYear() == dobDate.getFullYear()) {
      result = now.getMonth() - dobDate.getMonth() + ' tháng';
    } else if (now.getFullYear() == dobDate.getFullYear() + 1 && now.getMonth() < dobDate.getMonth()) {
      result = now.getMonth() + 12 - dobDate.getMonth() + ' tháng';
    } else {
      result = now.getFullYear() - dobDate.getFullYear() + '';
    }
    if (isShowTextAge) {
      result += ' tuổi';
    }
    return result;
  }

  toUnsign(val) {
    if (this.checkEmpty(val)) {
      return '';
    }
    return val
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D');
  }

  toCamelCase(val) {
    if (this.checkEmpty(val)) {
      return '';
    }

    return val
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
      .replace(/[^0-9a-zA-Z ]/g, '')
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })
      .replace(/\s/g, '')
      .replace(/^(.)/, function ($1) {
        return $1.toLowerCase();
      });
  }

  isUrlValid(userInput) {
    // var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    // if(res == null)
    //     return false;
    // else
    //     return true;
    return true;
  }

  splitLastWord(words) {
    const n = words.split(' ');
    return n[n.length - 1];
  }

  toFileName(words) {
    if (!words) {
      return '';
    }

    return words.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  }

  urlify(text) {
    if (text.includes('img')) {
      return text;
    }

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      // let urlOnly = $(url).text();
      // console.log(urlOnly);
      return '<a href="' + url + '" style="text-decoration: underline;" target="_blank">' + url + '</a>';
    });
  }

  getToday() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today.toISOString();
  }

  removeDuplicate(arr) {
    if (arr.length <= 1) {
      return arr;
    }
    let j = 0;
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] != arr[j]) {
        j++;
        arr[j] = arr[i];
      }
    }
    return arr.slice(0, j + 1);
  }
  saveLocalFile(url, filename) {
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  getStyleCommonPrint() {
    let style = `strong{color:#000;}
    table{
      width: 100%;
      page-break-inside:auto;
      border-collapse: collapse;
    }
    .label{
      display: inline-block;
      margin-right: 5px;
      font-weight: bold;
    }
    .bold{
      font-weight: bold;
    }
    .col-item{
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 10px;
    }
    @page{
      margin: 3mm;
    }
    td{
      page-break-inside:avoid;
      page-break-after:auto;
      padding:1mm;
    }
    .prescription-list .pres-item {
      display: flex;
      align-items: start;
      margin-bottom: 5px;
      margin-left: 10px;
    }
    .paper-size-A4, .paper-size-A4 table{
      font-size: 12px!important
    }
    .paper-size-A5, .paper-size-A5 table{
      font-size: 11px!important
    }
    .d-flex {
      display: flex;
    }
    label {
      margin-bottom: 0.5rem
    }
    .row{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4px;
    }
    .col-2{
      width: 16.66667%
    }
    .col-3{
      width: 25%
    }
    .col-4{
      width: 33.33333%
    }
    .col-5{
      width: 41.66667%
    }
    .col-6 {
      width: 50%;
    }
    .col-8{
      width: 66.66667%
    }
    .col-9 {
      width: 75%;
    }
    .col-12 {
      width: 100%;
    }
    .label {
      display: inline-block;
      margin-right: 5px;
      font-weight: bold;
      min-width: 65px;
    }
    .title-barcode{
      font-size: 22px;
      padding:20px;
      position: relative;
    }
    .title{
      position: relative;
      z-index:100;
      font-weight: bold
    }
    .medicine{
      text-align:center
    }
    @media print and (max-width: 148mm) {
      table{
        border-collapse: collapse;
      }
      .medicine{
        text-align:left;
        padding-left: 100px
      }
      body {
        font-size: 12px;
      }
      .title-barcode{
        font-size: 15px;
        padding:20px;
        position: relative;
      }
    }
    @media screen and (max-width: 148mm) {
      .medicine{
        text-align:left;
        padding-left: 100px
      }
      body {
        font-size: 13px;
      }
      .title-barcode{
        font-size: 15px;
        padding:20px;
        position: relative;
      }
    }
    .barcode-wrap{
      position: absolute;
      right: 0;
      top:0
    }
    `;

    return style;
  }
  printHtmlContent(htmlContent: string, title: string = null, additionCSS: string = null, useboostrapLib = false) {
    let lib = '';
    if (useboostrapLib) {
      lib = `<link rel="stylesheet" href="${window.location.origin}/assets/libs/bootstrap-4.5.3-dist/css/bootstrap.css" media='all' />      `;
    }
    let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <head>
    <title>${title}</title>
    <style>
    ${additionCSS}
    </style>
    ${lib}
    </head>
    <body onload='window.print();window.close();'>${htmlContent}</body>
    </html>`);
    popupWin.document.close();
  }
}
