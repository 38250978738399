import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthorizedComponent } from './authorized/authorized.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { ConfirmInviteComponent } from './confirm-invite/confirm-invite.component';
import { LossPasswordComponent } from './loss-password/loss-password.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { AuthGuard } from 'src/app/share/guards/guards.class';

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      { path: 'forgot_password', component: LossPasswordComponent },
      { path: 'login', component: LoginComponent },
    ],
  },
  // { path: 'login', component: LoginComponent },
  //{ path: 'loss-password', component: LossPasswordComponent },
  { path: 'unauthorized', component: AuthorizedComponent },
  { path: 'server-error', component: ServerErrorComponent },
  { path: 'confirm-invite', component: ConfirmInviteComponent },
  { path: '404', component: PageNotFoundComponent }, //URL nay dang ky cuoi cung
  { path: '**', redirectTo: '/404' }, //URL nay dang ky cuoi cung
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
