<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Thông tin quy trình điều trị"
  nzMaskClosable="false"
  nzWidth="700px"
  (nzOnCancel)="handleCancel()">
  <form *ngIf="isEditProcess" [formGroup]="treatmentForm">
    <div class="row mg-bt-10">
      <div class="col-4">Quy trình điều trị</div>
      <div class="col-8">
        <nz-select
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="Chọn quy trình điều trị"
          formControlName="treatmentProcessId">
          <nz-option
            nzCustomContent
            *ngFor="let data of lstTreatmentProcess"
            [nzLabel]="data.name"
            [nzValue]="data.id">
            {{ data.name }}
          </nz-option>
        </nz-select>
        <div
          class="message-required mg-t-5"
          *ngIf="submitted && formData.treatmentProcessId.errors">
          <span class="fs-12">*Quy trình không được để trống</span>
        </div>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-4">Ngày bắt đầu (N0)</div>
      <div class="col-8">
        <nz-date-picker
          formControlName="activeDate"
          [nzPlaceHolder]="'DD/MM/YYYY'"
          [nzFormat]="dateFormat">
        </nz-date-picker>
        <div
          class="message-required mg-t-5"
          *ngIf="submitted && formData.activeDate.errors">
          <span class="fs-12">*Ngày bắt đầu không được để trống</span>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="!isEditProcess && rawData" class="treatment-view">
    <div class="row mg-bt-10">
      <div class="col-4">Quy trình điều trị</div>
      <div class="col-8 d-flex justify-content-between">
        <div>{{ rawData.treatmentProcessName }}</div>
        <i
          class="mg-r-10"
          nz-icon
          nzType="edit"
          nzTheme="outline"
          (click)="editProcess()"></i>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-4">Ngày bắt đầu (N0)</div>
      <div class="col-8">
        <div>{{ rawData.activeDate | date: 'dd/MM/yyy' }}</div>
      </div>
    </div>
  </div>
  <div *ngIf="rawData && rawData.logs != null" class="row">
    <div class="col-4">Thông tin log quá trình điều trị</div>
    <div class="col-8 lst-log">
      <div *ngFor="let log of rawData.logs" class="mg-bt-10">
        <div class="mock-time">
          [{{ log.dateModified | date: 'dd/MM/yyy' }} - BS {{ log.modifiedBy }}]
        </div>
        <div class="log-content" [innerHtml]="log.content"></div>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button
      *ngIf="!isEditProcess"
      class="btn-cancel-medium"
      (click)="handleCancel()">
      Đóng
    </button>
    <button
      *ngIf="isEditProcess"
      class="btn-cancel-medium mg-r-10"
      (click)="returnView()">
      Quay lại
    </button>
    <button
      *ngIf="isEditProcess"
      class="btn-primary-medium"
      (click)="saveProcess()">
      Lưu
    </button>
  </div>
</nz-modal>
