export class MenuItemTree {
  name: string;
  id: string;
  icon: string;
  children: Array<MenuItemTree>;
  expanded?: boolean;
  isLeaf?: boolean;
  selectable?: boolean;
  status?: number;
}
