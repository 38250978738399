import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubclinicalPrintComponent } from './subclinical-print/subclinical-print.component';
import { SharedModule } from 'src/app/share/share.module';
import { SubclinicalGroupAddComponent } from './subclinical-group-add/subclinical-group-add.component';
import { SubclinicalTreeAddComponent } from './subclinical-tree-add/subclinical-tree-add.component';
import { DiscountComponent } from './discount/discount.component';

@NgModule({
  declarations: [
    SubclinicalPrintComponent,
    SubclinicalGroupAddComponent,
    SubclinicalTreeAddComponent,
    DiscountComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    SubclinicalPrintComponent,
    SubclinicalGroupAddComponent,
    SubclinicalTreeAddComponent,
    DiscountComponent,
  ],
})
export class SubclinicalVisitModule {}
