import { TextAreaElement } from 'src/app/models/form-model/textareaElement.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-textarea-element',
  templateUrl: './textarea-element.component.html',
  styleUrls: ['./textarea-element.component.scss'],
})
export class TextareaElementComponent implements OnInit {
  @Input() data: TextAreaElement;

  constructor() {}

  ngOnInit() {
    // console.log(this.data);
  }
}
