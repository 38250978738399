<app-m-modal
  [modalId]="'prescription-sample_list-detail'"
  [isVisible]="isVisible"
  (closeModal)="handleCancel()"
  [width]="770"
  [minWidth]="770"
  [minHeight]="500"
  [isResiable]="true">
  <ng-template #mModalTitle>
    <div class="header-title">Chi tiết đơn thuốc mẫu</div>
  </ng-template>
  <ng-template #mModalContent>
    <div class="txt-align-r">
      <button (click)="openModalCreate()" class="btn-primary-small mg-bt-15">
        Tạo mẫu mới
      </button>
    </div>
    <nz-table
      class="table-style"
      nzSize="small"
      #sampleTable
      [nzData]="lstSample"
      [nzScroll]="{ y: '250px' }">
      <thead>
        <tr>
          <th nzWidth="40px">STT</th>
          <th nzWidth="120px">Tên đơn thuốc mẫu</th>
          <th nzWidth="100px">Người tạo</th>
          <th nzWidth="85px">Thời gian cập nhật</th>
          <th nzWidth="50px">Thao tác</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of sampleTable.data; let i = index"
          (click)="showSampleDetail(data)"
          [ngClass]="{ 'tr-selected': data.Id == prescription.Id }">
          <td>{{ i + 1 }}</td>
          <td>{{ data.Title }}</td>
          <td>{{ data.CreatorName }}</td>
          <td>{{ data.ModifiedDate | date: 'dd/MM/yyyy' }}</td>
          <td>
            <i
              title="Sử dụng"
              class="mg-r-10"
              (click)="usingSample(data)"
              nz-icon
              nzType="arrow-right"
              nzTheme="outline"></i>
            <i
              *ngIf="data.CreatorId == currentUserId"
              title="Xóa"
              (click)="removeSample(data.Id)"
              nz-icon
              nzType="delete"
              nzTheme="outline"></i>
          </td>
        </tr>
      </tbody>
    </nz-table>


    <div class="sample-detail" *ngIf="showDetail">
      <div class="dpl-flex mg-bt-15">
        <div class="title-bold-14 mg-bt-5">{{ prescription.Title }}</div>
        <div class="dpl-flex">
          <button
            (click)="openModalSampleInfo(prescription)"
            class="btn-primary-small mg-r-15">
            Cập nhật
          </button>
          <button (click)="usingSample(prescription)" class="btn-primary-small">
            Sử dụng
          </button>
        </div>
      </div>
      <nz-table
        class="table-style mg-bt-10"
        nzSize="small"
        #detailTable
        [nzData]="prescription.MedicationRequests"
        [nzShowPagination]="false"
        [nzScroll]="{ y: '250px' }">
        <thead>
          <tr>
            <th nzWidth="40px">STT</th>
            <th nzWidth="120px">Tên thuốc</th>
            <th nzWidth="80px">Số lượng</th>
            <th nzWidth="85px">Đơn vị</th>
            <th nzWidth="100px">Thành tiền</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let data of prescription.MedicationRequests; let i = index">
            <td>{{ i + 1 }}</td>
            <td>
              {{getMedicationName(
              data.MedicationName,
              data.Content,
              data.ActiveIngredient
              )}}
            </td>
            <td>{{ data.Amount }}</td>
            <td>{{ data.UnitName }}</td>
            <td>{{ getMedicationDetailPrice(data) | currencyFormat }}</td>
          </tr>
          <tr *ngIf="getTotalPrice(prescription.MedicationRequests) > 0">
            <td colspan="5" style="text-align: right; font-weight: bold">
              Tổng
            </td>
            <td style="text-align: left; font-weight: bold; display: block">
              {{
                getTotalPrice(prescription.MedicationRequests) | currencyFormat
              }}
              VNĐ
            </td>
          </tr>
        </tbody>
      </nz-table>
      <div class="d-flex">
        <div class="title-bold-14 mg-bt-5">Lời dặn của BS:&nbsp;</div>
        <div [innerHtml]="prescription.DoctorComment"></div>
      </div>
    </div>
  </ng-template>
  <ng-template #mModalFooter>
    <button class="btn-cancel-small" nz-button (click)="handleCancel()">
      Đóng
    </button>
  </ng-template>
</app-m-modal>

<!-- Cập nhật tạo mới đơn thuốc mẫu -->
<app-m-modal
  [modalId]="'prescription-sample_add-new-sample'"
  [isVisible]="showSampleInfo"
  (closeModal)="closeSampleInfo()"
  [width]="770"
  [minWidth]="770"
  [minHeight]="500"
  [isResiable]="true">
  <ng-template #mModalTitle>
    <div class="header-title">Thông tin đơn thuốc mẫu</div>
  </ng-template>
  <ng-template #mModalContent>
    <div *ngIf="presSampleEdit" class="mg-bt-15">
      <div class="title-bold-14 mg-bt-5">Tên đơn thuốc mẫu:</div>
      <div class="input-info">
        <input
          nz-input
          type="text"
          [(ngModel)]="presSampleEdit.Title"
          placeholder="Nhập tên đơn thuốc mẫu" />
      </div>
    </div>
    <div class="mg-bt-15">
      <div class="title-bold-14 mg-bt-5">Thêm, sửa thuốc trong đơn:</div>
      <form class="medication-form" [formGroup]="formMedication">
        <div class="row mg-r-15 mg-bt-10">
          <div class="col-7">
            <input
              nz-input
              class="mg-r-15"
              formControlName="MedicationName"
              #input
              placeholder="Nhập tên thuốc để lựa chọn"
              minLength="3"
              (input)="callbackSearchFn()"
              [nzAutocomplete]="auto"
              (keydown.enter)="selectMedication(auto.activeItem.nzValue)" />
            <nz-autocomplete #auto>
              <nz-auto-option
                *ngFor="let item of listMedicationSource"
                [nzValue]="item"
                [nzLabel]="item.title"
                (click)="selectMedication(item)">
                <span>{{ item.title }}</span>
              </nz-auto-option>
            </nz-autocomplete>
            <div
              class="message-required"
              *ngIf="submitted && formMedicationData.MedicationName.errors">
              <span>Tên thuốc không hợp lệ</span>
            </div>
          </div>
          <div class="col-2">
            <input
              nz-input
              formControlName="Amount"
              placeholder="Số lượng"
              type="number"
              style="padding-right: 0px; border-radius: 5px"
              min="1" />
            <div
              class="message-required"
              *ngIf="submitted && formMedicationData.Amount.errors">
              <span>Số không hợp lệ</span>
            </div>
          </div>
          <div class="col-3">
            <input
              nz-input
              formControlName="UnitName"
              placeholder="Đơn vị tính"
              type="text"
              readonly />
          </div>
        </div>
        <div class="row">
          <div class="col-7">
            <input
              nz-input
              formControlName="Text"
              placeholder="Cách sử dụng"
              type="text"
              style="width: 100%" />
              <input formControlName="Content" type="hidden" />
            <div
              class="message-required"
              *ngIf="submitted && formMedicationData.Text.errors">
              <span>Cách sử dụng không hợp lệ</span>
            </div>
          </div>
          <div class="col-2">
            <input
              nz-input
              formControlName="Price"
              placeholder="Đơn giá"
              type="number"
              readonly />
          </div>
          <div class="col-3" style="display: flex">
            <button
              nz-button
              id="red"
              class="mg-r-15"
              (click)="resetFormMedication()">
              <img
                style="width: 13px"
                src="assets/image/DPicon/icon-close-white.svg" />
            </button>
            <button
              *ngIf="isEditMedication"
              type="submit"
              nz-button
              id="blue"
              class="mg-r-15"
              (click)="updateMedication()">
              <img
                style="width: 17px"
                src="assets/image/DPicon/icon-save-white.svg" />
            </button>
            <button
              *ngIf="!isEditMedication"
              type="submit"
              nz-button
              id="green"
              (click)="addMedication()">
              <img
                style="width: 17px"
                src="assets/image/DPicon/icon-add-white.svg" />
            </button>
          </div>
        </div>
      </form>
    </div>


    <nz-table
      class="table-style"
      nzSize="small"
      *ngIf="showSampleInfo"
      #createSampleTable
      [nzData]="lstNewMedication"
      [nzScroll]="{ y: '250px' }">
      <thead>
        <tr>
          <th nzWidth="40px">STT</th>
          <th nzWidth="120px">Tên thuốc</th>
          <th nzWidth="80px">Số lượng</th>
          <th nzWidth="85px">Đơn vị</th>
          <th nzWidth="100px">Thành tiền</th>
          <th nzWidth="40px"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of createSampleTable.data; let i = index"
          [ngClass]="{
            'tr-selected':
              data.MedicationId == formMedicationData['MedicationId'].value
          }">
          <td>{{ i + 1 }}</td>
          <td>
            {{
              getMedicationName(
                data.MedicationName,
                data.Content,
                data.ActiveIngredient
              )
            }}
          </td>
          <td>{{ data.Amount }}</td>
          <td>{{ data.UnitName }}</td>
          <td>{{ getMedicationDetailPrice(data) | currencyFormat }}</td>
          <td>
            <div class="dpl-flex">
              <i
                title="Sửa chi tiết thuốc"
                nz-icon
                nzType="edit"
                nzTheme="outline"
                (click)="editMedication(data)"></i>
              <i
                title="Xóa chi tiết thuốc"
                nz-icon
                nzType="delete"
                nzTheme="outline"
                (click)="deleteMedication(data)"></i>
            </div>
          </td>
        </tr>
        <tr *ngIf="getTotalPrice(lstNewMedication) > 0">
          <td colspan="4" style="text-align: right; font-weight: bold">Tổng</td>
          <td style="text-align: left; font-weight: bold; display: block">
            {{ getTotalPrice(lstNewMedication) | currencyFormat }} VNĐ
          </td>
          <td></td>
        </tr>
      </tbody>
    </nz-table>

    <div *ngIf="presSampleEdit">
      <div class="title-bold-14 mg-bt-5">Lời dặn của BS:</div>
      <div class="input-info">
        <textarea
          nz-input
          type="text"
          [(ngModel)]="presSampleEdit.DoctorComment"
          placeholder="Nhập lời khuyên BS"
          style="min-height: 50px"></textarea>
      </div>
    </div>
  </ng-template>
  <ng-template #mModalFooter>
    <button class="btn-cancel-small" nz-button (click)="closeSampleInfo()">
      Hủy
    </button>
    <button
      *ngIf="isAddSample"
      class="btn-primary-small"
      nz-button
      (click)="createSample()">
      Tạo mẫu
    </button>
    <button
      *ngIf="!isAddSample"
      class="btn-primary-small"
      nz-button
      (click)="updateSample()">
      Cập nhật
    </button>
  </ng-template>
</app-m-modal>
