import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TabDataService } from '../../../../share/base-service/data-worklist.service';
import { AppConfigService } from 'src/app-config.service';
import { Visits } from '../../../../models/listVisit.class';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/components/app-state/app-state';
import { Subscription } from 'rxjs';
import { ShareService } from 'src/app/services/share.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() visit: Visits;
  @Input() hideButton: string;
  @Input() patient: any;
  @Input() isCovidGroup: boolean;

  baseAvatarurl = '';
  age: number;
  formAddVisit: FormGroup;
  mainDoctor: any = {};
  presenterDoctor: any = {};
  isMobile: boolean;
  isExpandInfo: boolean;
  groupSub: Subscription;

  lstPermission = [
    {
      name: 'Điều phối viên',
      type: 1,
      checked: false,
    },
    {
      name: 'Bác sĩ phụ trách',
      type: 2,
      checked: false,
    },
    {
      name: 'Dược sĩ lâm sàng',
      type: 3,
      checked: false,
    },
    {
      name: 'Chuyên viên tư vấn',
      type: 4,
      checked: false,
    },
    {
      name: 'Điều dưỡng phụ trách',
      type: 5,
      checked: false,
    },
    {
      name: 'Tình nguyện viên',
      type: 10,
      checked: false,
    },
  ];

  constructor(
    private tabDataService: TabDataService,
    private configService: AppConfigService,
    private store: Store<AppState>,
    private shareService: ShareService
  ) {}

  ngOnInit() {
    this.isMobile = window.innerWidth < 540;
    this.baseAvatarurl =
      this.configService.getConfig().media.baseUrl + '/Avatar/';
    this.age =
      new Date().getFullYear() - new Date(this.visit.dob).getFullYear();
    this.groupSub = this.store
      .select((store) => store.groupAuth.doctorPermissions)
      .subscribe((data) => {
        this.convertToPermissionItem(data);
      });
  }
  convertToPermissionItem(values) {
    if (this.shareService.checkEmpty(values)) {
      return;
    }
    this.lstPermission.forEach((en) => {
      if (values.some((x) => x == en.type)) {
        en.checked = true;
      }
    });
  }

  showTabPatientInfo(id, PatientName, visitId): void {
    this.tabDataService.updateTab(id, PatientName, 'PatientInfo', visitId);
  }

  editPatientInfo(id, PatientName, visitId): void {
    //truyền vào id, tên...
    this.tabDataService.updateTab(id, PatientName, 'PatientInfoEdit', visitId);
    // //kiểm tra xem có tab chưa, chưa có  mở tab
    // this.enableEditPatientInfo = true;
    // //sau khi mở tab thì show giao diện sửa
  }

  toggleExpand(): void {
    this.isExpandInfo = !this.isExpandInfo;
  }

  get f() {
    return this.formAddVisit.controls;
  }
}
