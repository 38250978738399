<nz-result
  nzStatus="403"
  nzTitle="403"
  nzSubTitle="Xin lỗi, bạn không có quyền truy cập trang này.">
  <div nz-result-extra>
    <button nz-button nzType="primary" (click)="backHome()">
      Trở về trang chủ
    </button>
  </div>
</nz-result>
