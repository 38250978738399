export class FormatStyle {
  isBold: boolean;
  isItalic: boolean;
  isUnderline: boolean;
  fontSize: any;
  font: string;
  align: string;
  color: string;
  bgColor: string;
  display: string;

  constructor() {
    this.isBold = false;
    this.isItalic = false;
    this.isUnderline = false;
    this.fontSize = 14;
    this.font = 'Roboto';
    this.align = 'textalign-left';
    this.color = '#000000';
    this.bgColor = '#ffffff';
    this.display = 'block';
  }
}
