<div class="box-left" *ngIf="!isMobile">
  <div class="box-header">
    <div class="box-title" (click)="collapseBox()">
      <i nz-icon [nzType]="!isCollapsed ? 'down' : 'right'" nzTheme="outline"></i>
      Danh sách chỉ định/phiếu dữ liệu
      <span *ngIf="totalOfSubclinical > 0">({{ totalOfSubclinical }})</span>
    </div>
    <div class="box-tools">
      <i title="In chỉ định" (click)="printOrder()">
        <span class="icon-md" nz-icon nzType="printer" nzTheme="outline"></span>
      </i>
    </div>
  </div>
  <div *ngIf="isMobile" class="label-collapse-box mobile-box" (click)="collapseBox()">
    <strong class="title-boldtext-color">THÊM CHỈ ĐỊNH/PHIẾU DỮ LIỆU
      <span *ngIf="totalOfSubclinical > 0">({{ totalOfSubclinical }})</span>
    </strong>
    <i *ngIf="!isCollapsed" nz-icon style="float: right" nzType="caret-up" nzTheme="outline"></i>
    <i *ngIf="isCollapsed" nz-icon style="float: right" nzType="caret-down" nzTheme="outline"></i>
  </div>
  <div class="main-box box-body" *ngIf="!isCollapsed">
    <div class="box-choose-ros {{ disabledClass }}">
      <nz-select nzShowSearch [nzServerSearch]="true" nzAllowClear nzPlaceHolder="Tìm kiếm để thêm..."
                 [(ngModel)]="filterRosItem" (nzOnSearch)="ontyping($event)" (ngModelChange)="addRosItem($event)"
                 style="width: calc(100% - 340px); margin-right: 82px;">
        <nz-option nzCustomContent *ngFor="let sub of subcliorder" [nzValue]="sub">
          <div>
            <i class="icon-suborder" style="margin-right: 15px">
              <img src="assets/image/DPicon/clinical-order.svg"/>
            </i>
            {{ sub.name }}
          </div>
        </nz-option>
        <nz-option nzCustomContent *ngFor="let phieudl of listPhieudl" [nzValue]="phieudl">
          <div>
            <i class="icon-suborder" style="margin-right: 15px">
              <img src="assets/image/DPicon/clinical-form.svg"/>
            </i>
            {{ phieudl.name }}
          </div>
        </nz-option>
        <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
          <i style="background-color: white;" nz-icon nzType="loading" class="loading-icon"></i>
          Loading Data...
        </nz-option>
      </nz-select>
      <div class="btn-add" style="margin-top: -32px; margin-left: 378px; display: flex; justify-content: flex-end;">
        <button nz-button class="mg-l-10" (click)="showModalAddSubOrder(rosConstant.Imaging)">
          <i nz-icon nzType="plus" nzTheme="outline"></i>CĐHA
        </button>
        <button nz-button class="mg-l-10" (click)="showModalAddSubOrder(rosConstant.Laboratory)">
          <i nz-icon nzType="plus" nzTheme="outline"></i>Xét nghiệm
        </button>
        <button style="width: 94px;" nz-button [disabled]="disabledEditInfo" class="mg-l-10"
                (click)="showModalSubOrderGroup()">
          <i nz-icon nzType="plus" nzTheme="outline"></i>Nhóm
        </button>
      </div>
    </div>
    <div class="box-subvisit">
      <ul class="customTableCrollbar">
        <li *ngFor="let datasubvisit of subClinicalVisit" title="{{ datasubvisit.name }}" [ngClass]="{
            'highlight-select': selectedRowSubVisit == datasubvisit.id
          }" (click)="chooseSubVisit(datasubvisit.id)">
          <div class="div-row">
            <i style="margin-right: 15px">
              <img src="assets/image/DPicon/clinical-order.svg"/>
            </i>
            {{ datasubvisit.name }}
          </div>
          <div style="width: 230px">
            <span style="font-size: 11px">{{datasubvisit.statusString}}</span>
          </div>
          <div style="width: 90px">
            <div *ngIf="!datasubvisit.isEditing" id="sub-{{ datasubvisit.id }}" class="btn-amount"
                 (click)="switchStatus(datasubvisit)">
              {{ datasubvisit.amount }}
            </div>
            <div *ngIf="datasubvisit.isEditing" class="btn-amount">
              <input nz-input type="number" min="1" style="width: 60px" [(ngModel)]="datasubvisit.amount"
                     (blur)="updateAmount($event, datasubvisit)"/>
            </div>
          </div>

          <div style="width: 40px">
            <label *ngIf="!datasubvisit.isDisabled" nz-checkbox [(ngModel)]="datasubvisit.isPrint"
                   (ngModelChange)="printChildChange($event)"></label>
          </div>
          <div style="width: 40px">
            <i *ngIf="selectedRowSubVisit == datasubvisit.id" class="icon-suborder {{ hideButton }}" title="Hủy chỉ định"
               (click)="deleteSubClinicalVisit(datasubvisit)">
              <img src="assets/image/DPicon/icon-sub-green.svg"/>
            </i>
          </div>
        </li>
        <!-- List phiếu dữ liệu của ca khám -->
        <li *ngFor="let record of listRecord" title="{{ record.name }}"
            [ngClass]="{ 'highlight-select': selectedRowSubVisit == record.id }" (click)="chooseSubVisit(record.id)">
          <div (dblclick)="openTabPhieudl(record)">
            <i style="margin-right: 15px">
              <img src="assets/image/DPicon/clinical-form.svg"/>
            </i>
            {{ record.name }}
            <!-- <span class="source-info" *ngIf="record.source">({{ record.source }})</span> -->
          </div>
          <span>
            <i *ngIf="selectedRowSubVisit == record.id" class="icon-suborder" (click)="openTabPhieudl(record)">
              <img src="assets/image/DPicon/edit-pencil.svg"/>
            </i>
          </span>
          <span style="padding-left: 15px">
            <i *ngIf="selectedRowSubVisit == record.id" class="icon-suborder" (click)="deleteRecordVisit(record.id)">
              <img src="assets/image/DPicon/icon-sub-green.svg"/>
            </i>
          </span>
        </li>
      </ul>
      <div *ngIf="subclivisit === []">
        <div class="emptyData">Không có dữ liệu</div>
      </div>
    </div>
  </div>
</div>

<!-- <app-indication-cdha-add [isVisible]="showAddCdha" [visitId]="visitId" (closeModal)="cancelModalCdha()" (refreshData)="loadData(visitId)">
</app-indication-cdha-add>
<app-indication-xn-add [isVisible]="showAddXn" [visitId]="visitId" (closeModal)="cancelModalXn()" (refreshData)="loadData(visitId)">
</app-indication-xn-add> -->
<app-subclinical-tree-add [(isVisible)]="showAddSubOrder" [visitId]="visitId" [rosIdSelected]="rosIdSelected"
                          [subClinicalVisit]="subClinicalVisit" (cancelModal)="cancelModalAddSubOrder()"
                          (refreshData)="getSubClinicalVisit(this.visitId)"></app-subclinical-tree-add>

<app-subclinical-group-add [(isVisible)]="visibleSubOrderGroupAdd" [groupId]="groupId" [visitId]="visitId"
                           (refresh)="getSubClinicalVisit(this.visitId); titleTabChange()">
</app-subclinical-group-add>

<!-- <app-subclinical-group-add [(isVisible)]="visibleSubOrderGroupAdd">
</app-subclinical-group-add> -->
<nz-modal nzClassName="m-print-order" [nzWidth]="1000" [(nzVisible)]="showOrderPrinterPreview" nzMaskClosable="false">
  <div class="print-content" [innerHTML]="printContents | safe"></div>
  <div *nzModalFooter>
    <button nz-button nzType="danger" (click)="closePrintPreview()">Đóng</button>
    <button nz-button nzType="primary" (click)="printNow()">In</button>
  </div>
</nz-modal>
