import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import { FormatStyle } from 'src/app/models/form-model/form.class';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-format-box',
  templateUrl: './format-box.component.html',
  styleUrls: ['./format-box.component.scss'],
})
export class FormatBoxComponent implements OnInit {
  _formatStyle: any;
  _elementType: string;
  _element: any;
  isFormatEachElement = false;

  @Input() isVisible: boolean;
  @Input() set elementType(value: string) {
    if (
      value === Constant.FORMELEMENT.TEXTAREA ||
      value === Constant.FORMELEMENT.LABEL
    ) {
      this.isFormatEachElement = true;
    } else {
      this.isFormatEachElement = false;
    }
  }

  get elementType(): string {
    return this._elementType;
  }

  @Input() set element(value: any) {
    this._formatStyle =
      value && value.formatStyle ? value.formatStyle : new FormatStyle();
    this._element = value;
  }

  get element(): any {
    return this._element;
  }

  get formatStyle(): any {
    return this._formatStyle;
  }

  @Output() toggleValue: EventEmitter<any> = new EventEmitter();
  @Output() excFormatHtml: EventEmitter<any> = new EventEmitter();
  @Output() selectValue: EventEmitter<any> = new EventEmitter();

  LEFT = 'textalign-left';
  RIGHT = 'textalign-right';
  CENTER = 'textalign-center';
  BOLD_ACTIVE = 'bold-active';
  ITALIC_ACTIVE = 'italic-active';
  UNDERLINE_ACTIVE = 'underline-active';
  ALIGN_RIGHT_ACTIVE = 'align-right-active';
  ALIGN_CENTER_ACTIVE = 'align-center-active';
  ALIGN_LEFT_ACTIVE = 'align-left-active';

  filteredFontOptions: string[] = [];
  fontOptions = [
    'Times new roman',
    'Arial',
    'Roboto',
    'Segoe UI',
    'Helvetica Neue',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'sans-serif',
  ];
  filteredFontSizeOptions: number[] = [];
  fontSizeOptions = [];

  constructor() {
    for (let i = 8; i < 50; i += 2) {
      this.fontSizeOptions.push(i);
    }
  }

  ngOnInit() {
    // console.log(this.element);
  }

  onColorPickerClose(data: any): void {
    this.element.formatStyle.color = data;
    this.selectValue.emit({ key: 'color', attr: 'color', value: data });
  }

  onBgColorPickerClose(data: any): void {
    this.element.formatStyle.bgColor = data;
    this.selectValue.emit({
      key: 'background-color',
      attr: 'bgColor',
      value: data,
    });
  }

  onFontChange(value: string): void {
    this.filteredFontOptions = this.fontOptions.filter(
      (option) => option.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    if (this.fontOptions.includes(value)) {
      this.selectValue.emit({
        key: 'font-family',
        attr: 'font',
        value,
      });
    }
  }

  onFontSizeChange(value: any): void {
    this.filteredFontSizeOptions = this.fontSizeOptions.filter(
      (option) => option.toString().toLowerCase().indexOf(value) !== -1
    );
    this.selectValue.emit({
      key: 'font-size',
      attr: 'fontSize',
      value: parseInt(value),
    });
  }

  toggleBold(): void {
    this.element.formatStyle.isBold = !this.element.formatStyle.isBold;
    this.toggleValue.emit('bold');
  }

  toggleItalic(): void {
    this.element.formatStyle.isItalic = !this.element.formatStyle.isItalic;
    this.toggleValue.emit('italic');
  }

  toggleUnderline(): void {
    this.element.formatStyle.isUnderline =
      !this.element.formatStyle.isUnderline;
    this.toggleValue.emit('underline');
  }

  excFormat(attribute): void {
    this.excFormatHtml.emit(attribute);
  }

  selectAlign(value): void {
    this.element.formatStyle.align = value;
    this.selectValue.emit({
      key: 'text-align',
      attr: 'align',
      value,
    });
  }

  selectFont(value): void {
    this.element.formatStyle.font = value;
    this.selectValue.emit({ key: 'font', attr: 'font', value });
  }
}
