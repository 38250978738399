<nz-modal nzClassName="modal-service" [(nzVisible)]="isVisible" nzTitle="Chỉ định CLS" nzWidth="70vw"
  nzMaskClosable="false" (nzOnCancel)="handleCancel()">
  <div class="cd-table">
    <div class="content-left">
      <app-tree-select [tree]="treeSource" [selectedNodeId]="rosIdSelected"
        (selectdNodeChange)="selectdNodeEvent($event)">
      </app-tree-select>
    </div>
    <div class="content-right">
      <div class="content-item content-v1 mg-bt-10">
        <label class="label"> Danh sách dịch vụ đã chọn ({{ totalSubclinical }}) </label>
        <div class="content-v2">
          <div class="w-50 mgr-10">
            <div *ngFor="let item of lstServiceChecked.slice(0, lstServiceChecked.length / 2 + 1)">
              <ng-container *ngTemplateOutlet="subclinicalItem; context: { item: item }"></ng-container>
            </div>
          </div>
          <div class="w-50 mgl-10">
            <div
              *ngFor="let item of lstServiceChecked.slice(lstServiceChecked.length / 2 + 1, lstServiceChecked.length)">
              <ng-container *ngTemplateOutlet="subclinicalItem; context: { item: item }"></ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="d-flex justify-content-between mg-bt-4">
          <label class="label">Danh sách dịch vụ</label>
          <input style="width: 200px" nz-input type="text" [(ngModel)]="textSearch" placeholder="Enter để tìm kiếm..."
            (keydown.enter)="onSearch()" />
        </div>
        <div class="content-v3 row">
          <div class="col-6">
            <div *ngFor="let item of serviceSearch.slice(0, serviceSearch.length / 2 + 1)" class="cursor-pointer selected-{{item.selected}}"
              (click)="addNewItem(item)">
              <i nz-icon nzType="plus-square" [nzTheme]="'twotone'"></i>
              <span *ngIf="hienGiaDichVuChiDinh" class="pdl8">{{ item.name }} - {{item.price|currencyFormat}}</span>
              <span *ngIf="!hienGiaDichVuChiDinh" class="pdl8">{{ item.name }}</span>
            </div>
          </div>
          <div class="col-6">
            <div *ngFor="let item of serviceSearch.slice(serviceSearch.length / 2 + 1, serviceSearch.length)"
              class="cursor-pointer selected-{{item.selected}} " (click)="addNewItem(item)">
              <i nz-icon nzType="plus-square" [nzTheme]="'twotone'"></i>
              <span *ngIf="hienGiaDichVuChiDinh" class="pdl8">{{ item.name }} - {{item.price |currencyFormat}}</span>
              <span *ngIf="!hienGiaDichVuChiDinh" class="pdl8">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-medium" (click)="handleCancel()">Huỷ</button>
    <button nz-button class="btn-primary-medium" [nzLoading]="isSaving" (click)="updateOrder()">Cập nhật</button>
  </div>
</nz-modal>

<ng-template #subclinicalItem let-item="item">
  <span *ngIf="hienGiaDichVuChiDinh">{{ item.name }} - {{item.price |currencyFormat}} - {{ item.statusString }}</span>
  <span *ngIf="!hienGiaDichVuChiDinh">{{ item.name }} - {{ item.statusString }}</span>
  <span *ngIf="item.amount > 1"> - SL: {{ item.amount }}</span>
  <span *ngIf="!item.canEdit" class="italic"> - {{ item.creatorName }}</span>
  <i *ngIf="item.canEdit" style="font-size: 20px" nz-icon nzType="minus-square" [nzTheme]="'twotone'"
    [nzTwotoneColor]="'#eb2f96'" (click)="removeData(item)"></i>
</ng-template>
