<div style="padding: 13px;">
  <div class="title-wrapper">
    <!-- <p *ngIf="isFromManager" class="page-title">
      Quản trị Danh mục
    </p> -->
    <div class="head-obsertype">
      <div class="box-search">
        <input nz-input type="text" placeholder="Tìm kiếm theo tên..." [(ngModel)]="searchValue"
          (ngModelChange)="handleButtonClick()" />
      </div>
      <div class="edit-element">
        <button class="btn-import mg-r-10" nz-button (click)="openImportModal()">
          <i nz-icon nzType="import" nzTheme="outline"></i>Import
        </button>
        <button class="btn-export mg-r-10" nz-button (click)="exportFile()">
          <i nz-icon nzType="export" nzTheme="outline"></i>Export
        </button>
        <button class="mg-r-10 btn-green" nz-button (click)="showModal('add')">
          <i nz-icon nzType="plus"></i>Thêm mới
        </button>
        <button [disabled]="selectedRowIndex == emptyId" class="mg-r-10" nz-button nzType="primary"
          (click)="showModal(medicine)">
          <i nz-icon nzType="form"></i>Sửa
        </button>
        <button [disabled]="selectedRowIndex == emptyId" nz-button nzType="danger" (click)="deleteMedication()">
          <i nz-icon nzType="close"></i>Xoá
        </button>
      </div>
    </div>
    <div *ngIf="!isFromManager" style="padding-top: 30px"></div>
    <nz-table #fixedTable class="main-table-grid" nzSize="small" [(nzData)]="listOfDisplayData" [nzLoading]="isLoading"
      [nzFrontPagination]="false" [nzTotal]="total" (nzQueryParams)="onQueryParamsChange($event)"
      [nzShowTotal]="rangeTemplate" nzShowSizeChanger [nzPageSize]="pageSize" [(nzPageIndex)]="pageIndex"
      nzShowSizeChanger="" [nzPageSizeOptions]="[10, 20, 50, 100]"
      [nzScroll]="{ x: '1000px', y: 'calc(100vh - 260px)' }">
      <thead>
        <tr>
          <th nzWidth="60px">STT</th>
          <th nzWidth="200px">Mã</th>
          <th>Tên loại thuốc</th>
          <th>Tên hoạt chất</th>
          <th>Hàm lượng</th>
          <th nzWidth="120px">Đơn vị</th>
          <th>Liều dùng</th>
          <th>Đường dùng</th>
          <th>Cách dùng</th>
          <th nzWidth="120px">Thuốc hay TPCN</th>
          <th nzWidth="120px">Trạng thái</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let medicine of fixedTable.data; let i = index"
          [ngClass]="{ 'highlight': selectedRowIndex == medicine.id }" (click)="rowSelected(medicine)">
          <td>
            {{ fixedTable.nzPageSize * (fixedTable.nzPageIndex - 1) + i + 1 }}
          </td>
          <td>{{ medicine.code }}</td>
          <td>{{ medicine.name }}</td>
          <td>{{ medicine.activeIngredient }}</td>
          <td>{{ medicine.content }}</td>
          <td>{{ medicine.unit }}</td>
          <td>{{ medicine.dosages }}</td>
          <td>{{ medicine.route }}</td>
          <td>{{ medicine.usage }}</td>
          <td>{{ medicine.isFunctionalFoodsLabel }}</td>
          <td>
            <i *ngIf="medicine.status  === 'On'" nz-icon nzType="check-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></i>
            <i *ngIf="medicine.status  === 'Off'" nz-icon nzType="stop" [nzTheme]="'twotone'" [nzTwotoneColor]="'#ff0000'"></i>
          </td>
        </tr>
      </tbody>
      <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }}
      </ng-template>
    </nz-table>
  </div>
  <!-- popup thêm, xóa -->
  <nz-modal [(nzVisible)]="isVisible" [nzTitle]="selectedRowIndex != emptyId ? 'Sửa thuốc' : 'Thêm thuốc'"
    [nzWidth]="700" (nzOnCancel)="handleCancle()">
    <div class="msc-admin-row">
      <div class="msc-group-control width-obser">
        <label>Tên loại thuốc <span class="red-dot">*</span></label>
        <input type="text" placeholder="Tên loại thuốc" [(ngModel)]="medicine.name" required />
      </div>
    </div>
    <div class="msc-admin-row">
      <div class="msc-group-control width-obser">
        <label>Tên hoạt chất </label>
        <input type="text" placeholder="Tên hoạt chất" [(ngModel)]="medicine.activeIngredient"  />
      </div>
    </div>
    <div class="row">
      <div class="msc-admin-row col-6">
        <div class="msc-group-control width-obser">
          <label>Hàm lượng <span class="red-dot">*</span></label>
          <input type="text" placeholder="Hàm lượng" [(ngModel)]="medicine.content" required />
        </div>
      </div>
      <div class="msc-admin-row col-6">
        <div class="msc-group-control width-obser">
          <label>Đơn vị <span class="red-dot">*</span></label>
          <input type="text" placeholder="Đơn vị" [(ngModel)]="medicine.unit" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="msc-admin-row col-6">
        <div class="msc-group-control width-obser">
          <label>Đường dùng <span class="red-dot">*</span></label>
          <input type="text" placeholder="Đường dùng" [(ngModel)]="medicine.route" />
        </div>
      </div>
      <div class="msc-admin-row col-6">
        <div class="msc-group-control width-obser">
          <label>Liều dùng</label>
          <input type="text" placeholder="Liều dùng" [(ngModel)]="medicine.dosages" />
        </div>
      </div>
    </div>
    <div class="msc-admin-row">
      <div class="msc-group-control width-obser">
        <label>Cách dùng</label>
        <textarea nz-input placeholder="Cách dùng" [(ngModel)]="medicine.usage"></textarea>
      </div>
    </div>
    <div class="row msc-admin-row">
      <div class="col-3 msc-group-control width-obser">
        <label>Thuốc hay TPCN</label>
      </div>
      <div class="col-9">
        <!-- <nz-switch [(ngModel)]="medicine.isFunctionalFoods"></nz-switch> -->
        <nz-radio-group [(ngModel)]="medicine.isFunctionalFoods">
          <label [nzValue]="false" nz-radio>Thuốc</label>
          <label [nzValue]="true" nz-radio>TPCN</label>
        </nz-radio-group>
      </div>
    </div>
    <div class="row msc-admin-row">
      <div class="col-3 msc-group-control width-obser">
        <label>Trạng thái </label>
      </div>
      <div class="col-9">
        <nz-switch [(ngModel)]="medicine.turn"></nz-switch>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3 msc-group-control"><label>Phạm vi sử dụng</label></div>
      <div class="col-9">
        <nz-radio-group [(ngModel)]="medicine.privacyUse" (ngModelChange)="changeSelectedPrivacy($event)"
          fxLayout="row">
          <label nz-radio [nzDisabled]="ishidePrivacy1" [nzValue]="1">Toàn hệ thống</label>
          <label nz-radio [nzDisabled]="ishidePrivacy2" [nzValue]="2">Trong nhóm</label>
          <label nz-radio [nzDisabled]="ishidePrivacy3" [nzValue]="3">Chỉ mình tôi</label>
        </nz-radio-group>
      </div>
    </div>
    <div *ngIf="isShowChooseGroup" class="row mg-bt-10">
      <div class="col-3" style="padding-left: 30px"><label>Chọn nhóm</label></div>
      <div class="col-9">
        <input style="width: 315px" #keywordPatient placeholder="Nhập tên nhóm muốn chia sẻ" minLength="2" nz-input
          (input)="onSearchForm(keywordPatient.value)" [nzAutocomplete]="auto" />
        <nz-autocomplete #auto>
          <nz-auto-option *ngFor="let item of searchDataFilter" [nzValue]="item.name"
            (selectionChange)="selectFormEvent($event, item)">
            <span>{{ item.name }}</span>
          </nz-auto-option>
        </nz-autocomplete>
      </div>
    </div>
    <div *ngIf="isShowChooseGroup" class="row">
      <div class="col-3" style="padding-left: 30px">
        <label>Danh sách nhóm</label>
      </div>
      <div class="col-9">
        <div class="list-group-share">
          <li *ngFor="let item of groupShare" class="mg-r-10 d-inline-block">
            {{ item.name }}
            <i nz-icon nzType="close" nzTheme="outline" style="font-size: 10px" (click)="unSelectForm(item.id)"></i>
          </li>
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <button *ngIf="selectedRowIndex != emptyId" nz-button class="btn-primary-medium"
        (click)="editMedication(medicine)">
        Lưu
      </button>
      <button *ngIf="selectedRowIndex == emptyId" nz-button class="btn-primary-medium"
        (click)="addMedication(medicine)">
        Thêm
      </button>
      <button nz-button class="btn-cancel-medium" (click)="handleCancle()">
        Đóng
      </button>
    </div>
  </nz-modal>

  <!-- import excel -->
  <nz-modal [(nzVisible)]="visibleImport" nzTitle="Import thuốc" [nzWidth]="700" (nzOnCancel)="cancelImportModal()">
    <div>
      <div class="row mg-bt-10">
        <div class="col-3">Upload file</div>
        <div class="col-9">
          <input #fileInput id="fileOpen" type="file" multiple="false" accept=".xlsx, .xls" />
          <div>
            <i>(Vui lòng tải file mẫu
              <a style="color: #007bff" (click)="
                  downloadFileSample(
                    '/assets/download/medicine_sample.xlsx',
                    'medicine_sample.xlsx'
                  )
                ">tại đây</a>)</i>
          </div>
        </div>
      </div>
      <div class="row mg-bt-10">
        <div class="col-3"><label>Phạm vi sử dụng</label></div>
        <div class="col-9">
          <nz-radio-group [(ngModel)]="importMedicine.privacyUse" (ngModelChange)="changeSelectedPrivacy($event)"
            fxLayout="row">
            <label nz-radio [nzDisabled]="ishidePrivacy1" [nzValue]="1">Toàn hệ thống</label>
            <label nz-radio [nzDisabled]="ishidePrivacy2" [nzValue]="2">Trong nhóm</label>
            <label nz-radio [nzDisabled]="ishidePrivacy3" [nzValue]="3">Chỉ mình tôi</label>
          </nz-radio-group>
        </div>
      </div>
      <div *ngIf="importMedicine.privacyUse == 2" class="row">
        <div class="col-3" style="padding-left: 30px">
          <label>Chọn nhóm</label>
        </div>
        <div class="col-9">
          <input style="width: 315px" #keywordGroup placeholder="Nhập tên nhóm muốn chia sẻ" minLength="2" nz-input
            (input)="onSearchForm(keywordGroup.value)" [nzAutocomplete]="auto" />
          <nz-autocomplete #auto>
            <nz-auto-option *ngFor="let item of searchDataFilter" [nzValue]="item.name"
              (click)="selectImportGroup(item)">
              <span>{{ item.name }}</span>
            </nz-auto-option>
          </nz-autocomplete>
        </div>
      </div>
      <div *ngIf="importGroupShare.length > 0" class="row">
        <div class="col-3" style="padding-left: 30px">
          <label>Danh sách nhóm</label>
        </div>
        <div class="col-9">
          <div class="list-group-share">
            <li *ngFor="let item of importGroupShare" class="mg-r-10 d-inline-block">
              {{ item.name }}
              <i nz-icon nzType="close" nzTheme="outline" style="font-size: 10px"
                (click)="unSelectImportGroup(item.id)"></i>
            </li>
          </div>
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <button nz-button class="btn-primary-medium" (click)="import()">Lưu</button>
      <button nz-button class="btn-cancel-medium" (click)="cancelImportModal()">
        Đóng
      </button>
    </div>
  </nz-modal>

  <nz-modal [(nzVisible)]="visibleImportFail" nzTitle="Import thuốc" (nzOnCancel)="cancelImportFailModal()">
    <div>
      <div *ngIf="insertedImport > 0" class="success-txt">
        Thêm {{ insertedImport }} thuốc thành công!
      </div>
      <div class="fail-txt">
        Có
        <span style="color: red">{{ listMedicationImportExist.length }}</span>
        thuốc trùng với thuốc trong hệ thống:
      </div>
      <li *ngFor="let name of listMedicationImportExist" class="mg-l-15">
        {{ name }}
      </li>
    </div>
    <div *nzModalFooter>
      <button nz-button class="btn-cancel-medium" (click)="cancelImportFailModal()">
        Đóng
      </button>
    </div>
  </nz-modal>

</div>
