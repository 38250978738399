import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Constant } from '../../../share/constants/constant.class';
import { GroupDashboardService } from '../../../services/group-dashboard.service';
import { GroupMemberService } from '../../../services/group-member.service';
import { GroupTreeService } from '../../../services/groupTree.service';
import { MenuItemTree } from 'src/app/models/grouptree.model';
import { InviteMemberGroupComponent } from '../../modal/invite-member-group/invite-member-group.component';
import { UserReturn } from 'src/app/models/userInvite.model';
import { NotificationService } from 'src/app/services/notification.service';
import { AppConfigService } from 'src/app-config.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ShareService } from 'src/app/services/share.service';
import { Join } from '../../auth/redux/group-auth.action';

@Component({
  selector: 'app-group-dashboard',
  templateUrl: './group-dashboard.component.html',
  styleUrls: ['./group-dashboard.component.scss'],
})
export class GroupDashboardComponent implements OnInit, OnDestroy {
  @ViewChild(InviteMemberGroupComponent)
  inviteMember: InviteMemberGroupComponent;
  @Input() isAdminGroup: any;
  isOwer = false;
  isClosedGroup = false;
  now: Date;
  cur: any;
  last: any;
  type: any;
  groupId: any;
  groupCategoryId: any;
  groupName: any;
  group: any;
  aliasName: any;
  logs: any[];
  isVisibleAddGroup: boolean;
  isVisibleSettingGroup: boolean;

  isVisibleAddMemberGroup = false;
  list: Array<MenuItemTree> = [];
  selectedGroupId = '000000000000000000000000';

  cursltValue = 1;
  curKeyword = '';
  linkCovid = '';
  listMember: Array<UserReturn> = [];
  PathAvatar: string =
    this.configService.getConfig().media.baseUrl + '/Avatar/';
  listUser: Array<UserReturn> = [];
  groupSub: Subscription;
  isCovidGroup = false;
  isExport = false;
  isShowEditGroupName = false;
  groupState: any;
  constructor(
    private groupDashboardService: GroupDashboardService,
    private groupMemberService: GroupMemberService,
    private groupTreeService: GroupTreeService,
    private notificationService: NotificationService,
    private configService: AppConfigService,
    private modalService: NzModalService,
    private shareService: ShareService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.type = 2;
    this.now = new Date();
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      this.groupState = data;
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        this.isAdminGroup = data.role === 1 || data.role === 2;
        this.isOwer = data.role === 1;
        this.isClosedGroup = data.status === -1;
        this.isCovidGroup = data.isCovidGroup;
        this.aliasName =  data.groupName;
        console.log('g',data);
        this.groupDashboardService
          .groupStatistic(this.groupId)
          .subscribe((res) => {
            this.cur = res;
          });
        this.groupDashboardService
          .latestStatistic(this.groupId, this.type)
          .subscribe((res) => {
            this.last = res;
          });
        if (this.isCovidGroup) {
          this.linkCovid =
            this.configService.getConfig().patientPortal.baseUrl +
            'covid/' +
            this.groupId;
        }
        
        this.getListLog();
        this.getGroup();
      }
    });
  }

  copyLinkCovid() {
    this.copyMessage(
      this.linkCovid,
      'Đã copy link đăng ký theo dõi F0 tại nhà'
    );
  }

  copyMessage(val: string, message: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationService.showNotification(Constant.SUCCESS, message);
  }

  getGroup(): void {
    this.groupTreeService.getGroup(this.groupId).subscribe(
      (response) => {
        if (response !== null) {
          this.groupName = response.name;
          this.group = response;
          this.groupCategoryId = response.groupCategoryId;
        }
      },
      (error) => {
        console.log('lỗi');
      }
    );
  }
  checkAdminGroup(): void {
    this.groupMemberService.getMyGroupMember(this.groupId).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.isAdminGroup = res.jsonData.isAdmin || res.jsonData.isOwner;
          }
        }
      },
      (error) => {}
    );
  }
  dayTypeChange($event: Event): void {
    this.groupDashboardService
      .latestStatistic(this.groupId, this.type)
      .subscribe((res) => {
        this.last = res;
      });
    this.getListLog();
  }
  getListLog(): void {
    this.groupDashboardService
      .logStatistict(this.groupId, this.type)
      .subscribe((res) => {
        this.logs = res;
      });
  }
  getMonday(d): Date {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  closeAddGroup(isRefresh): void {
    this.isVisibleAddGroup = false;
  }
  closeSettingGroup(isRefresh): void {
    this.isVisibleSettingGroup = false;
  }
  settingGroupEvent(urlAva): void {
    this.isVisibleSettingGroup = false;
  }
  openModelSettingGroup(): void {
    if (this.isAdminGroup) {
      this.isVisibleSettingGroup = true;
    }
  }

  closeAddMemberGroup(isRefresh): void {
    this.isVisibleAddMemberGroup = false;
  }
  addGroupEvent(newMenu): void {
    this.isVisibleAddMemberGroup = true;
    this.inviteMember.callService(1, '', this.groupId);
    this.list.push(newMenu);
  }

  openInviteMember() {
    this.isVisibleAddMemberGroup = true;
    this.inviteMember.onShow();
  }

  exportExcelMember() {
    this.isExport = true;
    this.groupMemberService.exportMember(this.groupId).subscribe((res) => {
      if (res && res.size > 0) {
        const filename =
          'Danh sách BS nhóm ' + this.shareService.toFileName(this.groupName);
        const dataType = res.type;
        const binaryData = [];
        binaryData.push(res);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        if (filename) {
          downloadLink.setAttribute('download', filename);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Không thể xuất file, vui lòng liên hệ KTV'
        );
      }
      this.isExport = false;
    });
  }

  confirmCloseGroup() {
    this.modalService.confirm({
      nzTitle: 'Xác nhận',
      nzContent:
        'Bạn đã suy nghĩ kỹ và chắc chắn muốn đóng lại hoạt động của group này chưa?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () => this.closeGroup(),
    });
  }

  closeGroup() {
    this.groupDashboardService.closeGroup(this.groupId).subscribe(
      (res) => {
        if (res.isValid == true) {
          window.location.reload();
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            res.errors[0].message
          );
        }
      },
      (error) => {}
    );
  }

  confirmReopenGroup() {
    this.modalService.confirm({
      nzTitle: 'Xác nhận',
      nzContent:
        'Bạn đã suy nghĩ kỹ và chắc chắn muốn mở lại hoạt động của group này chưa?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () => this.reopenGroup(),
    });
  }

  reopenGroup() {
    this.groupDashboardService.reopenGroup(this.groupId).subscribe(
      (res) => {
        if (res.isValid == true) {
          window.location.reload();
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            res.errors[0].message
          );
        }
      },
      (error) => {}
    );
  }

  callService(sltValue, keyword, groupId) {
    this.curKeyword = keyword;
    this.cursltValue = sltValue;
    const payload = {
      groupId, //this.newGroupId,
      keyword,
      status: sltValue,
    };
    this.groupMemberService.getListMember(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.listMember = res.jsonData;
            for (let i = 0; i < this.listMember.length; i++) {
              this.listMember[i].fullName = this.listMember[i].fullName.trim();
              if (
                this.listMember[i].imageUrl == null ||
                this.listMember[i].imageUrl == ''
              ) {
                this.listMember[i].imageUrl = 'assets/image/group.svg';
              } else {
                this.listMember[i].imageUrl =
                  this.PathAvatar + this.listMember[i].imageUrl;
              }
            }
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.WARRING,
                res.errors[0].message
              );
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  loadMember(sltValue, keyword) {
    this.callService(parseInt(sltValue), keyword, this.groupId);
  }

  onKeyPress(x) {
    // appending the updated value to the variable
    const keyword = x.target.value;
    const payload = {
      groupId: this.groupId,
      keyword,
      status: 1000,
    };

    this.groupMemberService.getListMember(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.listUser = res.jsonData;
            if (this.listUser == null || this.listUser.length == 0) {
              const validEmail = this.isEmail(keyword);
              if (validEmail) {
                const u = new UserReturn();
                u.userId = '';
                u.userName = '';
                u.email = keyword;
                u.isAdmin = false;
                u.isInvited = false;
                u.isMember = false;
                u.isOwner = false;

                this.listUser = [u];
              }
            }
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.WARRING,
                res.errors[0].message
              );
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  isEmail(search: string): boolean {
    let serchfind: boolean;

    const regexp = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    serchfind = regexp.test(search);
    return serchfind;
  }

  ngOnDestroy() {
    this.groupSub.unsubscribe();
  }
  showEditor(): void {
    // chat.showEditor = true;
    this.isShowEditGroupName = true;
  }
  saveGroupName(): void{
    this.isShowEditGroupName = false;
    const payload = {groupId: this.groupId, groupCategoryId: this.groupCategoryId,  aliasGroupName: this.aliasName  };
    this.groupTreeService.saveAliasGroupName(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Sửa nhóm thành công');
            this.getGroup();
            this.store.dispatch(
              new Join({
                ...this.groupState,
                ...{groupName: this.aliasName },
              })
            );
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
            }
          }
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  closeGroupName() {
    this.isShowEditGroupName = false;
  }
}
