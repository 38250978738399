<div class="form-input form-elm">
  <input
    nz-input
    [(ngModel)]="data.observationType.name"
    [ngClass]="[
      data.formatStyle.isBold == true ? 'bold' : '',
      data.formatStyle.isItalic == true ? 'italic' : '',
      data.formatStyle.isUnderline == true ? 'underline' : '',
      data.formatStyle.align
    ]"
    [ngStyle]="{
      'color': data.formatStyle.color,
      'background': data.formatStyle.bgColor,
      'font-size': data.formatStyle.fontSize + 'px',
      'font-family': data.formatStyle.font
    }"
    placeholder="{{ data.observationType.name }}"
    [disabled]="data.editType == 0" />
</div>
