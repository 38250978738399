import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observation } from 'src/app/models/observationType.class';
import { NotificationService } from 'src/app/services/notification.service';
import { ObservationService } from 'src/app/services/observation.service';
import { Constant } from 'src/app/share/constants/constant.class';
@Component({
  selector: 'app-add-vital-asign',
  templateUrl: './add-vital-asign.component.html',
  styleUrls: ['./add-vital-asign.component.scss'],
})
export class AddVitalAsignComponent implements OnInit {
  readonly DataType: typeof Constant.DATATYPENUMBER = Constant.DATATYPENUMBER;

  @Input() isVisible = false;

  @Output() addObserVSEmit = new EventEmitter<any>();
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  lstObservationType: any = [];
  selectedItem;
  numRow = 0;
  visitId: string;
  patientId: string;
  isfocus = true;

  constructor(private OTService: ObservationService, private notificationService: NotificationService) {}
  ngOnInit(): void {}
  setListObservation(parentValue, observationSrc, _selectedItem, patientId) {
    this.selectedItem = _selectedItem;
    this.visitId = parentValue;
    this.patientId = patientId;
    this.lstObservationType = observationSrc;
    if (this.lstObservationType != null && this.lstObservationType.length > 0) {
      const du = this.lstObservationType.length % 2;
      if (du > 0) {
        this.numRow = (this.lstObservationType.length - 1) / 2 + 1;
      } else {
        this.numRow = this.lstObservationType.length / 2;
      }
    }
  }

  handleOk(): void {
    const lstPr = [];
    const lstPrDel = [];
    let isValid = false;
    this.lstObservationType.forEach((it) => {
      if (it.value != null && it.value !== '') {
        if (isValid == false) {
          isValid = true;
        }
        const item = new Observation();
        switch (it.dataType) {
          case Constant.DATATYPENUMBER.NUMBER:
            item.valueInteger = it.value;
            break;
          case Constant.DATATYPENUMBER.STRING:
            item.valueString = it.value;
            break;
          case Constant.DATATYPENUMBER.BOOLEAN:
            item.valueBoolean = it.value;
            break;
          case Constant.DATATYPENUMBER.DATE:
            item.valueDateTime = it.value;
            break;
          case Constant.DATATYPENUMBER.DATETIME:
            item.valueDateTime = it.value;
            break;
          case Constant.DATATYPENUMBER.TIME:
            item.valueTime = '';
            break;
          case Constant.DATATYPENUMBER.SINGLECHOICE:
            item.valueSelections = it.value; //(it.value && it.value> 0) ?it.value:[it.value];
            break;
          case Constant.DATATYPENUMBER.MUILTICHOICE:
            item.valueSelections = it.value;
            break;
          default:
            break;
        }
        item.id = it.id;
        item.observationTypeId = it.observationTypeId;
        lstPr.push(item);
      } else {
        if (it.id != '' && (it.value == null || it.value === '')) {
          const item = new Observation();
          item.id = it.id;
          item.observationTypeId = it.observationTypeId;
          lstPrDel.push(item);
        }
      }
    });
    if (!isValid) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, 'Hãy nhập giá trị');
    }

    const sendObser: any = {
      visitId: this.visitId,
      patientId: this.patientId,
      listObservation: lstPr,
      listObservationDel: lstPrDel,
    };
    this.OTService.SaveListObservationVS(sendObser).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.isVisible = false;
            //reload List CHa
            this.addObserVSEmit.emit();
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.SUCCESS,
              'Cập nhật chỉ số sinh tồn thành công'
            );
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  handleCancel(): void {
    this.isVisible = false;
    this.closeModal.emit();
  }
  counter(i: number) {
    return new Array(i);
  }
}
