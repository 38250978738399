import { SharedModule } from './../../../../share/share.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitLogComponent } from './visit-log.component';

@NgModule({
  declarations: [VisitLogComponent],
  imports: [CommonModule, SharedModule],
  exports: [VisitLogComponent],
})
export class VisitLogModule {}
