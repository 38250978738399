import { ReminderGroup } from './../models/ReminderGroup.class';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { News } from '../models/news.class';
import { Result } from '../models/result.class';
import { Reminder } from '../models/Reminder.class';
import { SearchReminder } from '../models/Reminder.class';
import { ReminderCurDoctor } from '../models/ReminderCurDoctor.class';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class ListReminderService extends BaseService {
  getRemindersAll(): Observable<any> {
    return this.get(UrlConstant.REMINDER);
  }
  getRemindersFromGroup(data: ReminderGroup) {
    return this.post(UrlConstant.REMINDER_GROUP, data);
  }
  getReminder(id: string) {
    return this.get(UrlConstant.REMINDER + '/' + id);
  }
  deleteReminder(id: string) {
    return this.delete(UrlConstant.REMINDER_DELETE + id, '');
  }
  searchReminder(data: SearchReminder) {
    return this.post(UrlConstant.REMINDER_SEARCH, data);
  }
  addReminder(data: Reminder) {
    return this.post(UrlConstant.REMINDER, data);
  }
  updateReminder(data: Reminder) {
    return this.put(UrlConstant.REMINDER, data);
  }
  getPatientReminder(payload: any): Observable<any> {
    return this.post(UrlConstant.REMINDER + '/PatientReminder', payload);
  }

  reminderLater(payload: any): Observable<any> {
    return this.post(UrlConstant.REMINDER + '/ReminderLater', payload);
  }

  reminded(payload: any): Observable<any> {
    return this.post(UrlConstant.REMINDER + '/Reminded', payload);
  }

  getReminderLog(id: string) {
    return this.get(UrlConstant.REMINDER + '/Log/' + id);
  }
  toTextStatus(status) {
    switch (status) {
      case 0:
        return 'New';
      case 1:
        return 'Doing';
      case 2:
        return 'Done';
      case 3:
        return 'Cancel';
      default:
        return '';
    }
  }
}
