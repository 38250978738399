<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Lịch công việc đối với BN {{ visit.patientName }}"
  nzWidth="1000px"
  nzMaskClosable="false"
  (nzOnCancel)="handleCancel()">
  <div class="d-flex justify-content-between mg-bt-10">
    <div class="title-rmd">Danh sách lịch công việc</div>
    <button
      nz-button
      class="btn-primary-small"
      nzType="success"
      (click)="showModalForm('add', '')">
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      Thêm mới
    </button>
  </div>
  <div *ngIf="isNoData">Chưa có lịch công việc nào</div>
  <nz-table
    *ngIf="lstDataOfPatient.length > 0"
    #reminderTable
    class="main-table-grid reminder-patient-grid"
    [(nzData)]="lstDataOfPatient"
    nzShowPagination="false"
    [nzPageSize]="100">
    <thead>
      <tr>
        <th nzWidth="50px">STT</th>
        <th nzWidth="165px">Thời gian thực hiện</th>
        <th nzWidth="200px">Công việc</th>
        <th nzWidth="200px">Mô tả</th>
        <th nzWidth="165px">Người thực hiện</th>
        <th nzWidth="100px">Trạng thái</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let reminder of reminderTable.data; index as i">
        <td>{{ i + 1 }}</td>
        <td>{{ reminder.activeDate | date: 'dd/MM/yyy hh:mm a' }}</td>
        <td>{{ reminder.title }}</td>
        <td>{{ reminder.detail }}</td>
        <td>{{ reminder.doctorName }}</td>
        <td>
          <button
            *ngIf="!reminder.isEditStatus"
            id="r-{{ reminder.id }}"
            class="status status-{{ reminder.status }}"
            (click)="editStatus(reminder)">
            {{ toTextStatus(reminder.status) }}
          </button>
          <nz-select
            *ngIf="reminder.isEditStatus"
            (clickOutside)="log($event, reminder)"
            [(ngModel)]="reminder.status"
            (ngModelChange)="saveStatus(reminder)">
            <nz-option
              style="text-align: center"
              *ngFor="let data of listStatus; let i = index"
              [nzValue]="i"
              [nzLabel]="data">
            </nz-option>
          </nz-select>
        </td>
        <td>
          <i
            nz-icon
            nzType="edit"
            class="mg-r-10"
            title="Chỉnh sửa"
            (click)="showModalForm('edit', reminder)"
            nzTheme="outline"></i>
          <i
            nz-icon
            nzType="delete"
            class="mg-r-10"
            title="Xóa"
            (click)="showModalForm('delete', reminder)"
            nzTheme="outline"></i>
        </td>
      </tr>
    </tbody>
    <ng-template #rangeTemplate let-range="range" let-total>
      {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }}
    </ng-template>
  </nz-table>
  <div *nzModalFooter>
    <button class="btn-cancel-medium" nz-button (click)="handleCancel()">
      Đóng
    </button>
  </div>
</nz-modal>
<!-- <app-patient-reminder-form
  [isVisible]="isVisibleForm"
  [visit]="visit"
  [doctorInGroup]="doctorInGroup"
  [data]="selectedRmd"
  (closeModal)="closeForm()"
  (refreshData)="getPatientReminder()"></app-patient-reminder-form> -->

<app-reminder-form
  [visit]="visit"
  [(isVisible)]="isVisibleForm"
  [curReminder]="selectedRmd"
  (refreshData)="getPatientReminder()"></app-reminder-form>
