import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObservationTypeComponent } from './observation-type.component';
import { SharedModule } from 'src/app/share/share.module';

@NgModule({
  declarations: [ObservationTypeComponent],
  imports: [CommonModule, SharedModule],
  exports: [ObservationTypeComponent],
})
export class ObservationTypeModule {}
