import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { Observable } from 'rxjs';
import { UrlConstant } from '../share/constants/url.class';
import { RequestFormByRosCate } from '../models/form.interface';

@Injectable()
export class ListFormService extends BaseService {
  deleteForm(formId: string): Observable<any> {
    return this.delete(UrlConstant.DPFFORM, formId);
  }
  getListDPFrom(requestData): Observable<any> {
    return this.post(UrlConstant.LIST_FORM, requestData);
  }
  getListDPFromForGroup(requestData): Observable<any> {
    return this.post(UrlConstant.LIST_FORM_FOR_GROUP, requestData);
  }
  getListEditableDPFrom(requestData): Observable<any> {
    return this.post(UrlConstant.LIST_EDITABLE_FORM, requestData);
  }
  getListFormForConfigGroup(requestData): Observable<any> {
    return this.post(UrlConstant.LIST_FORM_CONFIG_GROUP, requestData);
  }

  getListDPFormByRoscat(requestData: RequestFormByRosCate): Observable<any> {
    if (requestData.groupid == undefined) {
      return this.get(
        UrlConstant.LIST_FORM_BY_ROSCATE +
          '?id=' +
          requestData.id +
          '&skip=' +
          requestData.skip +
          '&take=' +
          requestData.take
      );
    } else {
      return this.get(
        UrlConstant.LIST_FORM_BY_ROSCATE +
          '?id=' +
          requestData.id +
          '&groupid=' +
          requestData.groupid +
          '&skip=' +
          requestData.skip +
          '&take=' +
          requestData.take
      );
    }
  }
}
