<div class="d-flex w-100">
  <div class="day-table">
    <table class="w-100">
      <thead>
        <tr>
          <th style="min-width: 120px; max-width: 120px"></th>
          <th style="width: 400px" class="text-center" *ngFor="let visit of listVisitGroupByDoctorId">
            <img style="margin-bottom: 3px; margin-right: 10px" src="assets/image/DPicon/doctor-fill-white.svg" />
            {{ visit[0].doctorName }} <span style="margin-left: 10px">-</span
            ><span style="margin-left: 10px; font-weight: bold">{{ visit.length }} CK</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let timeSlot of timeSlotsDisplay; index as i">
          <td class="time-line">{{ timeSlot | date: 'HH:mm' }}</td>
          <td *ngFor="let visit of listVisitGroupByDoctorId">
            <div
              class="d-flex flex-wrap"
              [innerHTML]="visit | dayViewPipe: timeSlot:timeSlotsDisplay[i + 1]"
              (mouseover)="onVisitItemHover($event)"
              (click)="onVisitItemClick($event)"
              (mouseleave)="onVisitItemLeave($event)"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
