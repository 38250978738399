import {
  EventEmitter,
  Injectable,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  NzNotificationService,
  NzNotificationRef,
} from 'ng-zorro-antd/notification';
//import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { AppConfigService } from 'src/app-config.service';
//import { UrlConstant } from '../share/constants/url.class';
import { Constant } from '../share/constants/constant.class';

import { GroupTreeService } from './groupTree.service';
import { options } from '@amcharts/amcharts4/core';
@Injectable()
export class MessageService {
  messageSource = new BehaviorSubject({});
  //public currentmessage = this.messageSource.asObservable();
  // titleSource = new BehaviorSubject({});
  // bodySource = new BehaviorSubject({});
  // typeSource = new BehaviorSubject({});
  // updateNumNotifiEmit = new EventEmitter<any>();
  message: any = {
    notification: {
      title: '',
      body: '',
      type: 'default',
      notiId: '',
    },
  };
  private value: TemplateRef<any>;
  public get Value(): TemplateRef<any> {
    return this.value;
  }
  public set Value(input) {
    this.value = input;
  }
  // eventNotifi:NzNotificationRef={
  //   messageId:'001',
  //   onClick:{
  //     alert(1)
  //   }
  // }

  // currentMessage = new BehaviorSubject(null);
  // messageSource = this.currentMessage.asObservable()
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private notification: NzNotificationService,
    private userService: GroupTreeService //private http: HttpClient,private configService: AppConfigService
  ) {
    // this.angularFireMessaging.messaging((_messaging) => {
    //   _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //   _messaging.onTokenRefresh = _messaging.onTokenRefreh.bind(_messaging);
    // })
    this.angularFireMessaging.onMessage((_messaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging) => {
    //     console.log('onMessage',_messaging);
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // )
  }
  showNotification(type: string, message: string, body: string) {
    this.notification.create(type, message, body, {
      nzStyle: {
        fontWeight: 'lighter',
        fontSize: 'larger',
      },
      nzDuration: 5000,
      nzPlacement: 'bottomRight',
    });
  }
  requestPermission() {
    const curToken = localStorage.getItem(Constant.FIREBASE_TOKEN);
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        if (token !== curToken) {
          this.userService.saveToken(token).subscribe((res) => {
            localStorage.setItem(Constant.FIREBASE_TOKEN, token);
          });
        }
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  // saveToken(token): Observable<any> {
  //   console.log('llllllltoken-In',token, 'http-', this.configService.getConfig().api.baseUrl + UrlConstant.SAVE_FCM_TOKEN);
  //     return this.http.post('http://localhost:53856/api/Doctor/SaveFcmToken', {
  //       headers: this.createHeaders() || {},
  //       //responseType: 'text',
  //       token
  //   });
  // }
  // public createHeaders(): HttpHeaders {
  //   // Why "authorization": see CustomLogoutSuccessHandler on server
  //   return new HttpHeaders().set('Authorization', 'Bearer ' + this.getToken());
  // }
  private getToken(): string {
    return localStorage.getItem(Constant.TOKEN);
  }
  // save the permission token in firestore

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      //alert('Nhận message');
      // // var messageSources = this.currentMessage.asObservable()

      this.message = payload;
      // console.log("new message received. ", this.message);

      //this.showNotification('success','helllo'+this.message.notification.title,this.message.notification.body);
      //  let type=this.message.data.type;
      //  console.log("new message received type. ", type);
      //  if(type==='confirm')
      //  {
      //   this.showNotification('info','helllo'+this.message.notification.title,this.message.notification.body);
      //  }
      //  this.updateNumNotifiEmit.emit();
      // let title=this.message.notification.title;
      // let body=this.message.notification.body;
      // let type=this.message.data.type;
      this.messageSource.next(this.message);
      // this.titleSource.next(title)
      // this.bodySource.next(body)
      // this.typeSource.next(type)
      //console.log("day" ,this.messageSource.getValue());
      //this.el.nativeElement.innerHTML = "Hi";
      //   this.notification.template(this.Value,{
      //     nzDuration:5000,
      //     nzPlacement:"bottomRight"
      //   });
    });
  }
}

// import { Injectable } from '@angular/core';

// import { Subject } from 'rxjs';
// //inport {AngularFireStore}
// //import { AngularFirestore } from '@angular/fire/firestore';
// //import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';

// import * as firebase from 'firebase';

// @Injectable()
// export class MessageService {
//   private messaging = firebase.messaging()

//   private messageSource = new Subject()
//   currentMessage = this.messageSource.asObservable()

//   constructor() {

//   }

//   // get permission to send messages
//   getPermission(user) {
//     this.messaging.usePublicVapidKey('BAUZwDavMQm99S7pXfiW82rAQTYJS7FKeF39CT2h3mRDdKX01eiysN43n_B7H1z8SrdytM3rn7jYiYTsFgmhVG4');
//     //this.messaging.getToken({vapidKey: 'BAUZwDavMQm99S7pXfiW82rAQTYJS7FKeF39CT2h3mRDdKX01eiysN43n_B7H1z8SrdytM3rn7jYiYTsFgmhVG4'}).then((currentToken) => {
//     this.messaging.requestPermission()
//     .then(() => {
//       console.log('Notification permission granted.');
//       return this.messaging.getToken()
//     })
//     .then(token => {
//       console.log(token)
//      // this.saveToken(user, token)
//     })
//     .catch((err) => {
//       console.log('Unable to get permission to notify.', err);
//     });
//   }

//   // Listen for token refresh
//   monitorRefresh(user) {
//     this.messaging.onTokenRefresh(() => {
//       this.messaging.getToken()
//       .then(refreshedToken => {
//         console.log('Token refreshed.',refreshedToken);
//      //   this.saveToken(user, refreshedToken)
//       })
//       .catch( err => console.log(err, 'Unable to retrieve new token') )
//     });
//   }

//   // used to show message when app is open
//   receiveMessages() {
//     console.log('Message init ');
//     this.messaging.onMessage(payload => {
//      console.log('Message received. ', payload);
//      this.messageSource.next(payload)
//    });

//   }

//   // save the permission token in firestore
//   // private saveToken(user, token): void {

//   //     const currentTokens = user.fcmTokens || { }
//   //     console.log(currentTokens, token)

//   //     // If token does not exist in firestore, update db
//   //     if (!currentTokens[token]) {
//   //       const userRef = this.afs.collection('users').doc(user.uid)
//   //       const tokens = { ...currentTokens, [token]: true }
//   //       userRef.update({ fcmTokens: tokens })
//   //     }
//   // }
// }
