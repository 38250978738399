<nz-modal nzClassName="rmd-modal sample-modal" nzTitle="Danh sách công việc mẫu" [nzWidth]="780"
  [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()">
  <div class="wrapper">
    <div class="sample-select">
      <input id="inputSample" style="width: 170px" placeholder="Chọn công việc mẫu" [(ngModel)]="keywordsearch"
        minLength="2" nz-input nzAllowClear (keydown.enter)="searchListSample()" />
      <i style="margin: 0; margin-left: 8px" class="i-medium" nz-icon nzType="search" nzTheme="outline"
        (click)="searchListSample()"></i>
      <div class="sample-container">
        <span class="sample-item" *ngFor="let item of lstWorkReminderSample" (click)="selectSampleChange(item)">
          <p [ngClass]="{
              'sample-highlight': selectedSample.id == item.id
            }" class="sample-item-detail">
            {{ item.title }}
          </p>
        </span>
      </div>
    </div>
    <div class="sample-detail">
      <div *ngIf="!isEditing && curUserId == selectedSample.creatorId" class="i-action">
        <i class="i-medium" nz-icon nzType="edit" title="Chỉnh sửa" nzTheme="outline" (click)="editSample()"></i>
        <i class="i-medium" nz-icon nzType="delete" title="Xóa" nzTheme="outline" (click)="deleteSample()"></i>
      </div>
      <div *ngIf="isEditing" class="i-action">
        <i class="i-medium" nz-icon nzType="save" nzTheme="outline" title="Lưu" (click)="save()"></i>
        <i class="i-medium" nz-icon nzType="close" nzTheme="outline" title="Huỷ" (click)="handleCancelEditSample()"></i>
      </div>
      <div [formGroup]="sampleForm">
        <div class="row">
          <div class="col-3">
            <label>Công việc<span class="red-dot"> *</span></label>
          </div>
          <div class="col-9">
            <input formControlName="title" type="text" nz-input />
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <label>Mô tả chi tiết</label>
          </div>
          <div class="col-9">
            <textarea nz-input formControlName="detail"> </textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <label>Thông báo trước</label>
          </div>
          <div class="col-9">
            <nz-select class="w-200px" nzAllowClear formControlName="minutesBeforeReminder">
              <nz-option *ngFor="let item of listMinutesBeforeReminder" [nzLabel]="item.label" [nzValue]="item.value">
              </nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative; height: 34px" *nzModalFooter>
      <button style="position: absolute; left: 6%" class="btn-primary-small 
        {{ selectedSample.id != '' ? 'button-disable' : '' }}" nz-button [disabled]="selectedSample.id != ''"
        (click)="save()">
        <i nz-icon nzType="plus" nzTheme="outline"></i>Thêm mới
      </button>
      <button *ngIf="!isEditing" class="btn-primary-small" nz-button (click)="useSample()">
        Sử dụng
      </button>
      <button style="margin-right: 33%" *ngIf="!isEditing" class="btn-cancel-medium" nz-button (click)="handleCancel()">
        Huỷ
      </button>
    </div>
  </div>
</nz-modal>