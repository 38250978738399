import { Component, Input, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Visits } from 'src/app/models/listVisit.class';
import { DoctorService } from 'src/app/services/doctor.service';
import { MedicalInstructionService } from 'src/app/services/medical-instruction.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from '../../app-state/app-state';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { VisitService } from '../../../services/visit.service';
import { truncateWithEllipsis } from '@amcharts/amcharts4/.internal/core/utils/Utils';

@Component({
  selector: 'app-medical-order',
  templateUrl: './medical-order.component.html',
  styleUrls: ['./medical-order.component.scss'],
})
export class MedicalOrderComponent implements OnInit {
  // _visit: Visits
  // @Input() set visit(data) {
  //     this._visit = data;
  //     if (!this.shareService.checkEmptyObj(data)) {
  //         this.getUserInGroup(data.groupId);
  //         console.log(data);
  //         this.getByVisitAndPatient();
  //         this.getMedicalOrderSource(data.groupId);
  //     }
  // }
  // get visit() {
  //     return this._visit;
  // }
  pageIndex = 1;
  pageSize = 15;
  scrollY: string = window.innerHeight - 365 + 'px';

  groupId: string;
  groupSub: Subscription;
  total = 0;
  list_status = [
    'Mới tạo/Chưa thực hiện',
    'Đã từ chối',
    'Đã huỷ',
    'Đã thực hiện',
  ];
  type = ['Đơn thuốc', 'Chẩn đoán hình ảnh', 'Xét nghiệm', 'Khác'];
  lstDataOfVisit: any[] = [];
  // lstDataOfPatient: any[] = [];
  lstOrderSource: any[] = [];
  isVisibleForm = false;
  dataSearchMedicalOrder = {
    groupId: '',
    status: 1,
    type: null,
    executorId: '',
    keyword: '',
    executeDate: null,
    createdDate: null,
    page: 1,
    pageSize: 20,
  };
  currentOrder = {
    medicalOrderId: '',
    id: '',
    name: '',
    desc: '',
    executorId: '',
    executorName: '',
    executeDate: null,
    otherInfo: '',
    status: 1,
    statusText: '',
    creatorName: '',
    creatorId: '',
    orderResponse: [],
    patientId: '',
    patientName: '',
    patientCode: '',
    visitId: '',
  };
  currentVisit: Visits = {
    stt: '',
    sttsearchresult: '',
    id: '',
    patientId: '',
    patientName: '',
    patientCode: '',
    groupId: '',
    doctorId: '',
    creatorId: '',
    patientSex: '',
    patientSexText: '',
    yob: 0,
    dob: new Date().toJSON(),
    dobString: '',
    phoneNo: '',
    address: '',
    visitDate: new Date(),
    visitReason: '',
    visitTypeText: '',
    visitEndDate: new Date(),
    color: '',
    shortName: '',
    age: 0,
    imageUrl: '',
    diseases: [],
    doctorName: '',
    groupName: '',
    images: [],
    documents: [],
    pdfDocuments: [],
    avatar: '',
    clinicalInfomation: '',
    conclusion: '',
    tags: [],
    dateOfDiagnosis: new Date(),
    dateOfSymptom: new Date(),
    doctorInCareDoct: '',
    nursingInCare: '',
    examinedStatus: false,
    examinedByDoctorId: '',
    examinedByDoctorName: '',
  };
  formUpdateStatus = {
    id: '',
    status: 0,
    executeDate: null,
    otherInfo: '',
  };
  userInGroup: any[] = [];
  lstMedicalOrder: any[] = [];
  showUpdateStatus = false;
  isAddOder = false;
  isVisibleView = false;
  curUserId = '';

  constructor(
    private medicalInstructionService: MedicalInstructionService,
    private visitService: VisitService,
    private doctorService: DoctorService,
    private store: Store<AppState>,
    private shareService: ShareService,
    private notificationService: NotificationService,
    private modalService: NzModalService,
    private tabDataService: TabDataService
  ) {
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
  }

  ngOnInit(): void {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        console.log(this.groupId);
      }
    });
    this.getAllMedicalOrderByGrId();
    this.getMedicalOrderSource(this.groupId);
    this.getUserInGroup(this.groupId);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.getAllMedicalOrderByGrId();
  }

  showModalForm(type, data) {
    if (type == 'add') {
      this.isAddOder = true;
      this.isVisibleForm = true;
      this.currentOrder = {
        medicalOrderId: '',
        id: '',
        name: '',
        desc: '',
        executorId: '',
        executorName: '',
        executeDate: null,
        otherInfo: '',
        status: 1,
        statusText: '',
        creatorName: '',
        creatorId: '',
        orderResponse: [],
        patientId: '',
        patientName: '',
        patientCode: '',
        visitId: null,
      };
    } else if (type == 'updateStatus' || type == 'edit' || type == 'view') {
      this.currentVisit = {
        stt: '',
        sttsearchresult: '',
        id: '',
        patientId: '',
        patientName: '',
        patientCode: '',
        groupId: '',
        doctorId: '',
        creatorId: '',
        patientSex: '',
        patientSexText: '',
        yob: 0,
        dob: new Date().toJSON(),
        dobString: '',
        phoneNo: '',
        address: '',
        visitDate: new Date(),
        visitReason: '',
        visitTypeText: '',
        visitEndDate: new Date(),
        color: '',
        shortName: '',
        age: 0,
        imageUrl: '',
        diseases: [],
        doctorName: '',
        groupName: '',
        images: [],
        documents: [],
        pdfDocuments: [],
        avatar: '',
        clinicalInfomation: '',
        conclusion: '',
        tags: [],
        dateOfDiagnosis: new Date(),
        dateOfSymptom: new Date(),
        doctorInCareDoct: '',
        nursingInCare: '',
        examinedStatus: false,
        examinedByDoctorId: '',
        examinedByDoctorName: '',
      };
      this.currentOrder = {
        medicalOrderId: data.medicalOrderId,
        id: data.id,
        name: data.name,
        desc: data.desc,
        executorId: data.executorId,
        executorName: data.executorName,
        executeDate: data.executeDate,
        otherInfo: data.otherInfo,
        status: data.status,
        statusText: data.statusText,
        creatorName: data.creatorName,
        creatorId: data.creatorId,
        orderResponse: data.orderResponse,
        patientId: data.patientId,
        patientName: data.patientName,
        patientCode: data.patientCode,
        visitId: data.visitId,
      };
      this.getVisitById(this.currentOrder.visitId);
      if (type == 'updateStatus') {
        this.showUpdateStatus = true;
        this.formUpdateStatus = {
          id: data.id,
          status: data.status,
          executeDate: data.executeDate,
          otherInfo: data.otherInfor,
        };
      } else if (type == 'edit') {
        this.isVisibleForm = true;
      } else if (type == 'view') {
        this.isVisibleView = true;
      }
    }
  }
  cancelAdd() {
    this.isVisibleForm = false;
    this.isAddOder = false;
  }

  getVisitById(visitId) {
    this.visitService.getVisit(visitId, this.groupId).subscribe((data) => {
      this.currentVisit = data;
      console.log(this.currentVisit);
    });
  }

  getMedicalOrderSource(groupId) {
    this.medicalInstructionService.getByGroupId(groupId).subscribe((res) => {
      console.log('lstOrderSource', res);
      this.total = res.total;
      this.lstOrderSource = res.data;
      //console.log("lstOrderSource", this.lstOrderSource);
    });
  }
  getAllMedicalOrderByGrId() {
    this.dataSearchMedicalOrder = {
      groupId: this.groupId,
      status: null,
      type: null,
      executorId: null,
      keyword: null,
      executeDate: null,
      createdDate: null,
      page: this.pageIndex,
      pageSize: this.pageSize,
    };
    this.medicalInstructionService
      .getAllByGroupId(this.dataSearchMedicalOrder)
      .subscribe((res) => {
        console.log('lstDataOfVisit', res);
        this.total = res.total;
        this.lstDataOfVisit = res.data;
      });
  }

  getUserInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.userInGroup = res;
        console.log(this.userInGroup);
      }
    });
  }

  cancelUpdateStatus() {
    this.showUpdateStatus = false;
  }

  selectOrderChange(event) {
    const item = this.lstOrderSource.filter((en) => en.id == event)[0];
    this.currentOrder.name = item.name;
    this.currentOrder.desc = item.description;
  }

  saveMedicalOrder() {
    const data = this.currentOrder;
    if (
      this.shareService.checkEmptyStr(data.medicalOrderId) ||
      this.shareService.checkEmptyStr(data.name)
    ) {
      this.notificationService.showNotification(
        Constant.ERROR,
        'Bạn cần nhập các trường bắt buộc!'
      );
      return;
    }
    const payload = {
      id: this.currentOrder.id,
      medicalOrderId: this.currentOrder.medicalOrderId,
      visitId: this.currentOrder.visitId,
      name: this.currentOrder.name,
      desc: this.currentOrder.desc,
      executorId: this.currentOrder.executorId,
      executeDate: this.currentOrder.executeDate,
    };
    if (this.currentOrder.id == '') {
      this.medicalInstructionService.add2Visit(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(
            Constant.SUCCESS,
            'Thêm y lệnh thành công!'
          );
          // this.getMedicalOrderByVisitId();
          this.isVisibleForm = false;
        } else {
          this.notificationService.showNotification(
            Constant.ERROR,
            res.errors[0].errorMessage
          );
        }
      });
    } else {
      this.medicalInstructionService
        .updateInfo2Visit(payload)
        .subscribe((res) => {
          if (res.isValid) {
            this.reloadDataTable();
            this.notificationService.showNotification(
              Constant.SUCCESS,
              'Cập nhật thành công!'
            );
            // this.getMedicalOrderByVisitId();
            this.isVisibleForm = false;
          } else {
            this.notificationService.showNotification(
              Constant.ERROR,
              res.errors[0].errorMessage
            );
          }
        });
    }
  }

  updateStatus() {
    const payload = {
      id: this.currentOrder.id,
      status: this.formUpdateStatus.status,
      executeDate: this.formUpdateStatus.executeDate,
      otherInfo: this.formUpdateStatus.otherInfo,
    };
    this.medicalInstructionService.update2Visit(payload).subscribe((res) => {
      if (res.isValid) {
        this.reloadDataTable();
        this.notificationService.showNotification(
          Constant.SUCCESS,
          'Cập nhật thành công!'
        );
        // this.getMedicalOrderByVisitId();
        this.showUpdateStatus = false;
      } else {
        this.notificationService.showNotification(
          Constant.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }

  cancelView() {
    this.isVisibleView = false;
  }

  viewToUpdateStatus() {
    this.isVisibleView = false;
    this.showUpdateStatus = true;
  }
  cancelOrder() {
    const payload = {
      id: this.currentOrder.id,
      status: 3, // đã hủy
      executeDate: new Date(),
    };
    this.medicalInstructionService.update2Visit(payload).subscribe((res) => {
      if (res.isValid) {
        this.reloadDataTable();
        this.notificationService.showNotification(
          Constant.SUCCESS,
          'Cập nhật thành công!'
        );
        // this.getMedicalOrderByVisitId();
        this.isVisibleView = false;
      } else {
        this.notificationService.showNotification(
          Constant.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }

  getPatientAge(dob): string {
    if (dob != null) {
      const age = new Date().getFullYear() - new Date(dob).getFullYear();
      return age.toString() + ' tuổi';
    } else {return '';}
  }

  searchMedicalOrder(datasend) {
    this.dataSearchMedicalOrder = {
      groupId: this.groupId,
      status: datasend.status,
      type: datasend.type,
      executorId: datasend.executorId,
      keyword: '',
      executeDate: datasend.executeDate,
      createdDate: datasend.createdDate,
      page: this.pageIndex,
      pageSize: this.pageSize,
    };
    this.medicalInstructionService
      .getAllByGroupId(this.dataSearchMedicalOrder)
      .subscribe((res) => {
        console.log('lstDataOfVisit', res);
        this.total = res.total;
        this.lstDataOfVisit = res.data;
      });
  }

  reloadDataTable() {
    this.pageIndex = 1;
    this.dataSearchMedicalOrder = {
      groupId: this.groupId,
      status: null,
      type: null,
      executorId: null,
      keyword: null,
      executeDate: null,
      createdDate: null,
      page: this.pageIndex,
      pageSize: this.pageSize,
    };
    this.getAllMedicalOrderByGrId();
  }

  convertExecuteDate(date) {
    const time = date.setHours(0, 0, 0, 0);
    const date2 = new Date(time);
    this.dataSearchMedicalOrder.executeDate = date2.toString();
  }

  convertCreatedDate(date) {
    const time = date.setHours(0, 0, 0, 0);
    const date2 = new Date(time);
    this.dataSearchMedicalOrder.createdDate = date2.toString();
  }

  addTab(id, PatientName, patientId) {
    //clearTimeout(settime);
    //$('#tooltip').hide();
    this.isVisibleView = false;
    this.tabDataService.updateTab(id, PatientName, 'VisitDetail', patientId);
  }
}
