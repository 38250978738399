import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subscription } from 'rxjs';
import { AppConfigService } from 'src/app-config.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PatientService } from 'src/app/services/patient.service';
import { ShareService } from 'src/app/services/share.service';
import { VisitService } from 'src/app/services/visit.service';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AppState } from '../../app-state/app-state';
import { CloseAddVisitUpdate } from '../redux/modal/modal.action';

@Component({
  selector: 'app-add-visit-update',
  templateUrl: './add-visit-update.component.html',
  styleUrls: ['./add-visit-update.component.scss'],
})
export class AddVisitUpdateComponent implements OnInit {
  isVisible: Observable<boolean>;
  _patient: any;
  @Input() set patient(data) {
    this._patient = data;
    if (!this.shareService.checkEmptyObjContainKeys(data)) {
      if (!data.isCreateNew) {
        this.patientForm.patchValue({
          id: data.id,
          address: data.address,
          avatar: data.avatar,
          bhytCode: data.bhytCode,
          code: data.code,
          idNumber: data.idNumber,
          dob: new Date(data.dob),
          dobString: data.dobString,
          fullName: data.fullName,
          email: data.email,
          sex: data.sex,
          phoneNo: data.phoneNo,
          validDate: data.validDate,
          expireDate: data.expireDate,
        });
        //console.log('set patient');
        if (!this.shareService.checkEmpty(data.avatar)) this.avatarUrl = this.subUrl + data.avatar;
        this.patientForm.disable();
        this.isSelectOldPatient = true;
        //this.autoPatientName = data.fullName;
      }
      this.isCreateNewDupplicate = data.isCreateNew;
    }
  }
  get patient() {
    return this._patient;
  }
  @Output() refreshListVisit: EventEmitter<any> = new EventEmitter();
  @Output() duplicatePatient: EventEmitter<any> = new EventEmitter();

  dateFormat = 'dd/MM/yyyy HH:mm';
  listDoctorInCase: any[] = [];
  doctorInGroup: any[] = [];
  uploadUrl = '';
  uploadFileUrl = '';
  header?: any = {
    Authorization: 'Bearer ' + localStorage.getItem(Constant.TOKEN),
  };
  avatarUrl = '';
  loading = false;
  listImgs: any[] = [];
  listImgsUrl: any[] = [];
  visibleMoreInfo = false;
  isLoading = false;
  searchPatientFilter = [];
  delayTime: any;
  isSelectOldPatient = false;
  patientForm: FormGroup;
  visitForm: FormGroup;
  curUserId = '';
  invalidExpireDate = false;
  groupSub: Subscription;
  groupId = '';
  subUrl = '';
  isMobile: boolean = window.innerWidth < 540;
  submitted = false;
  doctorPermissions: any[] = [];
  //autoPatientName: string = "";
  isCreateNewDupplicate = false;

  showSelectPatient = true;

  constructor(
    private doctorService: DoctorService,
    private configService: AppConfigService,
    private notificationService: NotificationService,
    private patientService: PatientService,
    private formBuilder: FormBuilder,
    private shareService: ShareService,
    private store: Store<AppState>,
    private tabDataService: TabDataService,
    private visitService: VisitService
  ) {
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
    this.uploadUrl =
      this.configService.getConfig().api.baseUrl +
      '/api/Upload/uploadFile?TypeUpload=upload_avatar&FileName=1145564.jpg';
    this.uploadFileUrl =
      this.configService.getConfig().api.baseUrl + '/api/Upload/uploadFile?TypeUpload=upload_visit_document';
    this.patientForm = this.formBuilder.group({
      id: [''],
      address: [''],
      avatar: '',
      bhytCode: ['', [], [this.validBHYTAsyncValidator]],
      code: '',
      idNumber: ['', [Validators.minLength(9), Validators.maxLength(12)]],
      dob: [null, [Validators.required]],
      fullName: [
        null,
        [Validators.required, Validators.minLength(1), Validators.maxLength(100)],
        [this.emptyAsyncValidator],
      ],
      email: ['', [Validators.email]],
      expireDate: [null, [], [this.expireDateAsyncValidator]],
      visitId: '',
      sex: ['', [Validators.required]],
      phoneNo: [null, [Validators.required, Validators.pattern(/\b^([0])\d{9}\b/)]],
      validDate: [null, [], [this.validDateAsyncValidator]],
      //imageUrls: [''],
    });
    this.visitForm = this.formBuilder.group({
      doctorId: [this.curUserId, [Validators.required, Validators.minLength(24), Validators.maxLength(24)]],
      visitDate: [new Date(), [Validators.required]],
      reason: ['', [Validators.required], [this.emptyAsyncValidator]],
      type: ['1', [Validators.required]],
      dateOfDiagnosis: [null],
      dateOfSymptom: [null],
      doctorInCareDoct: [null],
      nursingInCare: [null],
      volunteer: [null],
    });
    this.subUrl = this.configService.getConfig().media.baseUrl + '/Avatar/';
    this.isVisible = this.store.select((store) => store.modals.isVisitUpdateOpen);
    this.store
      .select((store) => store.modals.tabId)
      .subscribe((data) => {
        if (data == 'ListPatient') {
          this.showSelectPatient = false;
        } else {
          this.showSelectPatient = true;
        }
      });
  }
  disabledDateAfterToday = this.patientService.disabledDateAfterToday;
  disabledDateBeforeToday = this.patientService.disabledDateBeforeToday;

  ngOnInit(): void {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        this.doctorPermissions = data.doctorPermissions;
        this.getDoctorInGroup(this.groupId);
      }
    });
  }
  get patientFormData() {
    return this.patientForm.controls;
  }
  validDateAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.shareService.checkEmpty(control.value)) {
        this.invalidExpireDate = false;
        observer.next(null);
      } else {
        if (control.value > new Date()) {
          observer.next({ error: true, validDate: true });
        } else {
          const validExpire =
            this.patientForm &&
            (this.shareService.checkEmptyObj(this.patientForm.value.expireDate) ||
              control.value < this.patientForm.value.expireDate);
          if (!validExpire) {
            this.invalidExpireDate = true;
            observer.next(null);
          } else {
            this.invalidExpireDate = false;
            observer.next(null);
          }
        }
      }
      observer.complete();
    });
  validBHYTAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.shareService.checkEmpty(control.value) && this.patientForm && this.patientForm.value.expireDate) {
        observer.next({ error: true, empty: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });
  expireDateAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.shareService.checkEmptyObj(control.value)) {
        observer.next(null);
      } else {
        const validExpire =
          this.patientForm &&
          (this.shareService.checkEmptyObj(this.patientForm.value.validDate) ||
            control.value > this.patientForm.value.validDate);
        if (!validExpire) {
          observer.next({ error: true, duplicated: true });
          //this.invalidExpireDate = true;
          // observer.next({ error: true, validDate: true });
          observer.next(null);
        } else {
          //this.invalidExpireDate = false;
          observer.next(null);
        }
      }
      observer.complete();
    });
  emptyAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.shareService.checkEmptyStr(control.value)) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, duplicated: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });
  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        observer.complete();
        return;
      }
      const isLt2M = (file.size ?? 0) / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, 'Image must smaller than 2MB!');
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng && isLt2M);
      observer.complete();
    });
  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.avatarUrl = info.file.thumbUrl;
        this.patientForm.controls.avatar.setValue(info.file.response.fileName);
        break;
      case 'error':
        this.loading = false;
        break;
    }
  }
  beforeUploadFile = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isLt2M = (file.size ?? 0) / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, 'Image must smaller than 10MB!');
        observer.complete();
        return;
      }
      observer.next(isLt2M);
      observer.complete();
    });
  handleRemoveImg = (file: any) =>
    new Observable<boolean>((obs) => {
      this.listImgs = this.listImgs.filter((en) => en.response.fileName !== file.response.fileName);
      this.listImgsUrl = this.listImgsUrl.filter((en) => en !== file.response.fileName);
      obs.next(true);
    });
  fileChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.listImgs.push(info.file);
        this.listImgsUrl.push(info.file.response.fileName);
        break;
      case 'error':
        this.loading = false;
        break;
    }
  }
  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;
        if (
          !this.shareService.checkEmptyArray(this.doctorPermissions) &&
          this.doctorPermissions.includes(Constant.DOCTORPERMISSION.Doctor)
        ) {
          this.listDoctorInCase = this.doctorInGroup.filter(
            (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Doctor)
          );
        } else {
          this.listDoctorInCase = res;
        }
      }
    });
  }
  openMoreInfo() {
    this.visibleMoreInfo = !this.visibleMoreInfo;
  }
  handleCancel() {
    this.resetForm();
    this.visibleMoreInfo = false;
    this.store.dispatch(new CloseAddVisitUpdate());
  }
  onSearchPatient(keyword: string) {
    clearTimeout(this.delayTime);
    this.delayTime = setTimeout(() => {
      if (keyword != null && keyword != '') {
        keyword = keyword.trim();
        this.patientService.searchPatient(keyword).subscribe((res) => {
          this.searchPatientFilter = res.length > 0 ? res.slice(0, 20) : null;
          console.log(this.searchPatientFilter)
        });
      }
    }, 200);
  }
  selectSearchPatient(data) {
    let dob = !data.dob && data.yob ? new Date('01/01/' + data.yob) : new Date(data.dob);
    this.patientForm.patchValue({
      id: data.id,
      address: data.address,
      avatar: data.avatar,
      bhytCode: data.bhytCode,
      code: data.code,
      idNumber: data.idNumber,
      dob: dob,
      dobString: data.dobString,
      fullName: data.fullName,
      email: data.email,
      sex: data.sex,
      phoneNo: data.phoneNo,
      validDate: data.validDate,
      expireDate: data.expireDate,
    });
    if (this.shareService.checkEmpty(data.imageUrl)) {
      this.avatarUrl = '';
    } else {
      this.avatarUrl = this.subUrl + data.imageUrl;
    }
    this.isSelectOldPatient = true;
    this.patientForm.disable();
  }
  switchStatus() {
    this.isSelectOldPatient = false;
    this.patientForm.enable();
    this.patientForm.reset();
    this.avatarUrl = '';
  }
  outsidePatientName(value) {
    if (!this.shareService.checkEmpty(this.patientForm.value.id) && value.trim() != this.patientForm.value.fullName) {
      value = '';
      this.patientForm.reset();
      this.patientForm.enable();
    }
  }
  handleOk(): void {
    this.submitted = true;
    console.log(this.patientForm.value);
    if (this.isSelectOldPatient && this.shareService.checkEmptyStr(this.patientForm.value.id)) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Bạn cần chọn bệnh nhân');
      return;
    }
    if (!this.isSelectOldPatient && !this.patientForm.valid) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Kiểm tra lại các trường bắt buộc và lỗi');
      this.visibleMoreInfo = true;
      return;
    }
    if (this.visitForm.valid) {
      if (this.isCreateNewDupplicate) {
        this.addPatientAndVisit();
      } else if (this.isSelectOldPatient) {
        this.addVisitOldPatient();
      } else {
        const formValue = this.patientForm.value;
        this.isLoading = true;

        const payload = {
          fullName: formValue.fullName,
          idNumber: formValue.idNumber,
          dob: formValue.dob,
          dobString: this.shareService.toDDMMYY(formValue.dob),
          sex: formValue.sex,
          phoneNo: formValue.phoneNo,
          groupId: this.groupId,
        };
        this.patientService.checkDuplicate(payload).subscribe((res) => {
          if (res && res.errors && res.errors.length > 0) {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
            this.isLoading = false;
          } else {
            if (res.isSuccess) {
              this.addPatientAndVisit();
            } else {
              const form = {
                ...this.patientForm.value,
                ...{
                  dobString: this.shareService.toDDMMYY(this.patientForm.value.dob),
                },
              };
              this.duplicatePatient.emit({ form, res });
              this.isLoading = false;
            }
          }
        });
      }
    } else {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Bạn cần nhập các trường bắt buộc');
    }
  }
  addVisitOldPatient() {
    const payload = {
      ...this.visitForm.value,
      groupId: this.groupId,
      personId: this.patientForm.value.id,
      visitId: '000000000000000000000000',
    };
    this.visitService.addVisitOnly(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, Constant.MESSAGE_ADD_SUCCESS);
        this.isLoading = false;
        this.visibleMoreInfo = false;
        this.store.dispatch(new CloseAddVisitUpdate());

        if (this.isMobile) {
          location.href = 'common-mobile/worklist/visit/' + res.jsonData.id;
        } else {
          this.tabDataService.updateTab(
            res.jsonData.id,
            res.jsonData.patientName,
            'VisitDetail',
            this.patientForm.value.id
          );
          this.resetForm();
          this.refreshListVisit.emit(); //gửi trigger lên load lại listvisit
        }
      } else {
        this.isLoading = false;
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }
  resetDefaultVisit() {
    this.visitForm.patchValue({
      type: '1',
      visitDate: new Date(),
      doctorId: this.curUserId,
    });

    this.patientForm.patchValue({
      dob: null,
    });
  }
  addPatientAndVisit() {
    //this.patientForm.value.fullName = this.autoPatientName;
    const payload = {
      ...this.visitForm.value,
      ...this.patientForm.value,
      groupId: this.groupId,
      visitId: '000000000000000000000000',
      dobString: this.shareService.toDDMMYY(this.patientForm.value.dob),
      additionImages: this.listImgsUrl ? this.listImgsUrl : null,
    };
    this.visitService.addVisitContainPatient(payload).subscribe((res) => {
      if (res.isValid) {
        const patientName = this.patientForm.value.fullName;
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, Constant.MESSAGE_ADD_SUCCESS);
        this.isLoading = false;
        this.visibleMoreInfo = false;
        this.store.dispatch(new CloseAddVisitUpdate());
        this.resetForm();

        //callback update Appoitment
        // if(!this.shareService.checkEmpty(this.appointmentId)){
        //   this.addVisitToAppointment(res.jsonData.id);
        // }
        //this.store.dispatch(new CloseAddVisit());
        if (this.isMobile) {
          location.href = 'common-mobile/worklist/visit/' + res.jsonData;
        } else {
          this.tabDataService.updateTab(res.jsonData, patientName, 'VisitDetail', res.jsonData);
          this.refreshListVisit.emit(); //gửi trigger lên load lại listvisit
        }
      } else {
        this.isLoading = false;
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }
  resetForm() {
    this.patientForm.reset();
    this.patientForm.enable();
    this.visitForm.reset();
    this.resetDefaultVisit();
    this.submitted = true;
    this.listImgs = [];
    this.listImgsUrl = [];
    this.avatarUrl = '';
    this.isSelectOldPatient = false;
    //this.autoPatientName = "";
    this.isCreateNewDupplicate = false;
  }
}
