import { element } from 'protractor';
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  HostListener,
  Input,
} from '@angular/core';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { AppConfigService } from '../../../../app-config.service';
import { HttpClient } from '@angular/common/http';
import { Constant } from 'src/app/share/constants/constant.class';
import { ListVisitService } from '../../../services/listVisit-service';
import { NotificationService } from '../../../services/notification.service';
import { SearchVisit } from 'src/app/models/listVisit.class';
import {
  OpenAddVisit,
  OpenAddVisitUpdate,
} from '../../modal/redux/modal/modal.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { ShareService } from 'src/app/services/share.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzResizeEvent } from 'ng-zorro-antd/resizable';
import { WorklistColumn } from 'src/app/models/worklist-column';
import { Subscription } from 'rxjs';
import { CustomColumnService } from 'src/app/services/custom-cloumn.service';
declare let $: any;

const defaultConfig: WorklistColumn[] = [
  {
    title: 'STT',
    field: 'STT',
    width: '60px',
    minWidth: 50,
  },
  {
    title: 'Tên bệnh nhân',
    field: 'patientName',
    width: '300px',
    minWidth: 50,
  },
  {
    title: 'Thời gian khám',
    field: 'visitDate',
    width: '180px',
    minWidth: 50,
  },
  {
    title: 'Lý do khám',
    field: 'visitReason',
    width: '250px',
    minWidth: 50,
  },
  {
    title: 'Mã bệnh nhân',
    field: 'patientCode',
    width: '250px',
    minWidth: 50,
  },
  {
    title: 'Giới tính',
    field: 'genderText',
    width: '120px',
    minWidth: 50,
  },
  {
    title: 'Ngày sinh',
    field: 'dobString',
    width: '120px',
    minWidth: 50,
  },
  {
    title: 'Số điện thoại',
    field: 'phoneNo',
    width: '120px',
    minWidth: 50,
  },
  {
    title: 'Địa chỉ',
    field: 'address',
    width: '300px',
    minWidth: 50,
  },
  {
    title: 'Ngày phát hiện bệnh',
    field: 'dateOfDiagnosis',
    width: '125px',
    minWidth: 50,
  },
  {
    title: 'Ngày khởi phát triệu chứng',
    field: 'dateOfSymptom',
    width: '140px',
    minWidth: 50,
  },
  {
    title: 'BS phụ trách',
    field: 'doctorInCareDoctName',
    width: '120px',
    minWidth: 50,
  },
  {
    title: 'Điều dưỡng phụ trách',
    field: 'nursingInCareName',
    width: '185px',
    minWidth: 50,
  },
];

@Component({
  selector: 'app-listVisit',
  templateUrl: './list-visit.component.html',
  styleUrls: ['./list-visit.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class ListVisitComponent implements OnInit, OnDestroy, AfterViewInit {
  _reloadListVisit = false;
  @Input() set reloadListVisit(val: boolean) {
    if (val) {
      this._reloadListVisit = val;
      this.searchListVisit();
    }
  }

  get reloadListVisit(): boolean {
    return this._reloadListVisit;
  }

  lstVisit;
  resVisit;
  selectedGroupId = '';
  localStorageName = 'worklist-column';
  search: SearchVisit = {
    keyword: '',
    patientName: '',
    address: '',
    phone: '',
    patientCode: '',
    doctorName: '',
    from: '',
    to: '',
    inGroup: true,
    pageSize: 50,
    page: 1,
    newId: '',
    groupId: '',
  };
  isVisibleDuplicatePatient;
  dataTooltip = {
    id: null,
    patientName: null,
    genderText: null,
    dob: null,
    age: null,
    email: null,
    phoneNo: null,
    address: null,
    doctorName: null,
    patientCode: null,
    visitdate: null,
    visitTypeText: null,
    color: null,
    shortName: null,
    avatar: null,
  };
  baseAvatarurl = '';
  // scrollX: string = '1100px';
  // scrollY: string = window.innerHeight - 350 + 'px';
  pageIndex = 1;
  pageSize = 15;
  total = 100;
  loading = false;

  checkColumnWidth = '60px';
  resizing = false;
  isCustomColumnVisible = false;
  listVisitComponentName = 'ListVisit';

  showCols: WorklistColumn[] = [];
  hideCols: WorklistColumn[] = [];
  groupSub: Subscription;
  isCovidGroup = false;
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   if (window.innerWidth <= 1200) {
  //     this.scrollX = '1100px';
  //   }
  // }
  constructor(
    private tabDataService: TabDataService,
    private httpClient: HttpClient,
    private configService: AppConfigService,
    private listVisitService: ListVisitService,
    private notificationService: NotificationService,
    private shareService: ShareService,
    private customColumnService: CustomColumnService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.baseAvatarurl =
      this.configService.getConfig().media.baseUrl + '/Avatar/';

    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        if (!this.shareService.checkEmpty(data)) {
          this.selectedGroupId = data.groupId;
          this.reloadDataTable();
          if (data.isCovidGroup) {
            this.isCovidGroup = true;
          }
        }
      }
    });

    // if (localStorage.getItem(this.localStorageName)) {
    //   try {
    //     this.showCols = JSON.parse(localStorage.getItem(this.localStorageName));
    //     let colsTitle = this.showCols.map(t => t.title);
    //     var listColsName = defaultConfig.map(en => en.title);
    //     if (this.showCols == [] || JSON.stringify(colsTitle) != JSON.stringify(listColsName)) {
    //       throw "error";
    //     }
    //   } catch (ex) {
    //     this.showCols = cloneDeep(defaultConfig);
    //     localStorage.removeItem(this.localStorageName);
    //   }

    // } else {
    //   this.showCols = cloneDeep(defaultConfig);
    // }

    this.getColumns();
  }

  ngOnDestroy() {
    // this.hideToolTip();
    this.groupSub.unsubscribe();
  }

  getColumns() {
    this.customColumnService.getMainGridColumn().subscribe((res) => {
      if (res.isValid) {
        let curColumn = [];
        if (res.jsonData.length == 0) {
          curColumn = JSON.parse(JSON.stringify(defaultConfig));
        } else {
          res.jsonData.forEach((element) => {
            let elm = defaultConfig.filter((t) => t.field == element.field)[0];
            if (!this.shareService.checkEmptyObj(elm)) {
              elm = { ...elm, ...element };
              curColumn.push(elm);
            }
          });
        }

        this.showCols = curColumn;
        this.hideCols = defaultConfig.filter(
          (x) => !this.showCols.map((t) => t.field).includes(x.field)
        );
      }
    });
  }

  saveColumns() {
    const columns = this.showCols.map((t) => ({
      field: t.field,
      width: t.width,
    }));
    const payload = { columns };
    this.customColumnService.savetMainGridColumn(payload).subscribe((res) => {
      if (res.isValid) {
        this.hideCols = defaultConfig.filter(
          (x) => !this.showCols.map((t) => t.field).includes(x.field)
        );
      }
    });
  }

  changeColumns(cols: WorklistColumn[]) {
    this.showCols = cols;
    this.saveColumns();
  }

  reloadDataTable() {
    this.pageIndex = 1;
    //run when choose different groupId
    if (!this.shareService.checkEmpty(this.selectedGroupId)) {
      // this.tabDataService.changeGroup();
      this.search = {
        keyword: '',
        patientName: '',
        address: '',
        phone: '',
        patientCode: '',
        doctorName: '',
        from: '',
        to: '',
        inGroup: true,
        pageSize: this.pageSize,
        page: this.pageIndex,
        newId: '',
        groupId: this.selectedGroupId,
      };
      this.apiListVisit(this.search);
      // this.hideToolTip();
    }
  }

  apiListVisit(data) {
    this.loading = true;
    this.listVisitService.getListVisit(data).subscribe(
      (resdata) => {
        if (resdata !== null) {
          this.lstVisit = resdata;
          this.total = this.lstVisit.itemCount;
          this.resVisit = this.lstVisit.source;
        }
        this.loading = false;
      },
      (error) => {
        console.error(error);
        this.notificationService.showNotification('', error.statusText);
        this.loading = false;
      }
    );
  }

  resetSearch() {
    this.search.patientName = '';
    this.search.patientCode = '';
    this.search.phone = '';
    this.pageIndex = 1;
    this.getListVisit();
  }

  searchListVisit() {
    this.pageIndex = 1;
    this.getListVisit();
  }

  getListVisit() {
    if (!this.shareService.checkEmpty(this.selectedGroupId)) {
      const data = {
        patientName: '',
        patientCode: this.search.patientCode,
        phone: this.search.phone,
        keyword: this.search.patientName,
        address: '',
        doctorName: '',
        from: '',
        to: '',
        inGroup: true,
        pageSize: this.pageSize,
        page: this.pageIndex,
        newId: '',
        groupId: this.selectedGroupId, //"5d7a0f011a46690d24cb10f6"
      };
      this.apiListVisit(data);
      // this.hideToolTip();
    }
  }

  showModal(): void {
    //this.store.dispatch(new OpenAddVisit('ListVisit', {}, ''));
    if ((this.listVisitComponentName = 'ListVisit')) {
      this.store.dispatch(new OpenAddVisitUpdate('ListVisit', {}, ''));
    } else {
      this.store.dispatch(new OpenAddVisit('ListVisitContainer', {}, ''));
    }
  }

  showCustomColumn(): void {
    this.isCustomColumnVisible = true;
  }

  addTab(id, PatientName, patientId) {
    this.tabDataService.updateTab(id, PatientName, 'VisitDetail', patientId);
  }

  showToolTip(data) {
    if (data.id != this.dataTooltip.id) {
      this.dataTooltip = {
        id: data.id,
        patientName: data.patientName,
        genderText: data.genderText,
        dob: data.dobString == '' ? 'Không dữ liệu' : data.dobString,
        age:
          data.age == '' || data.age == undefined
            ? new Date().getFullYear() - new Date(data.dob).getFullYear() + 'T'
            : data.age + ' T',
        email: data.email == '' || !data.email ? '(Không dữ liệu)' : data.email,
        phoneNo: data.phoneNo == '' ? '(Không dữ liệu)' : data.phoneNo,
        address: data.address == '' ? '(Không dữ liệu)' : data.address,
        doctorName: data.doctorName,
        patientCode: data.patientCode,
        visitdate: data.visitDate,
        visitTypeText: 'Khám bệnh',
        color: data.color,
        shortName: data.shortName,
        avatar: data.avatar,
      };
    }
    // console.log("dataTooltip", this.dataTooltip);
    settime = setTimeout(function () {
      //x:1500 y:800
      if (mouseX < 1500 && mouseY < 800) {
        $('#tooltip').css({ top: mouseY - 70, left: mouseX + 30 });
      } else if (mouseX < 1500 && mouseY > 800) {
        $('#tooltip').css({ top: mouseY - 150, left: mouseX + 30 });
      } else if (mouseX > 1500 && mouseY < 800) {
        $('#tooltip').css({ top: mouseY - 70, left: mouseX - 370 });
      } else {
        $('#tooltip').css({ top: mouseY - 150, left: mouseX - 370 });
      }
      $('#tooltip').show();
    }, 2000);
  }

  hideToolTip() {
    clearTimeout(settime);
    $('#tooltip').hide();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.getListVisit();
  }

  ngAfterViewInit() {
    const $this = this;
    setTimeout(() => {
      $(document).ready(function () {
        $('.main-table-grid .ant-table-body').addClass('customTableCrollbar');
        $('.ant-table-header::-webkit-scrollbar').css({
          'background-color': 'white !important',
        });

        $('.main-table-grid .ant-table-body').scroll(function (e) {
          if (
            e.currentTarget.offsetHeight + e.currentTarget.scrollTop ==
            e.currentTarget.scrollHeight
          ) {
            if ($this.pageSize > $this.total) {
              return;
            }
            const maxIndex = Math.round($this.total / $this.pageSize / 2);
            if (maxIndex < $this.pageIndex) {
              $this.pageIndex = maxIndex;
            }
            $this.pageSize += $this.pageSize;
          }
        });
      });
    }, 500);
  }

  onResizeCloumn({ width }: NzResizeEvent, col: string): void {
    this.showCols = this.showCols.map((e) =>
      e.title === col ? { ...e, width: `${width}px` } : e
    );
    this.saveColumns();
  }
}

let settime;
let mouseX;
let mouseY;
$(document).mousemove(function (e) {
  // mouse coordinates
  mouseX = e.clientX;
  mouseY = e.clientY;
});
