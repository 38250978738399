import { ShareService } from './../../../../services/share.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user-service';
import { Constant } from 'src/app/share/constants/constant.class';
import firebase from 'firebase';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/components/app-state/app-state';
import { MessageService } from 'src/app/services/message.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AppConfigService } from 'src/app-config.service';
import { NotificationService } from 'src/app/services/notification.service';
import { VideoConferenceService } from 'src/app/services/video-conference.service';

@Component({
  selector: 'app-mobile-notify',
  templateUrl: './mobile-notify.component.html',
  styleUrls: ['./mobile-notify.component.scss'],
})
export class MobileNotifyComponent implements OnInit {
  numOfUnreadNotifi = 0;
  listNotification: any[] = [];
  firebaseInstance: any;
  numOfNewNotifi = 0;
  lastToken = '';
  isSystemAdmin = false;
  msg: any;
  PathAvatar: string = this.configService.getConfig().media.baseUrl;
  skip = 0;
  take = 10;

  constructor(
    private userService: UserService,
    private shareService: ShareService,
    private store: Store<AppState>,
    public messagingService: MessageService,
    private notification: NzNotificationService,
    private notificationService: NotificationService,
    private configService: AppConfigService,
    private videoConferenceService: VideoConferenceService,
    private route: Router
  ) {}

  @ViewChild('customHtml') customHtml: TemplateRef<any>;

  ngOnInit() {
    let userInfo;
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        userInfo = res;
      });
    //Check nếu là userGuest thì remove token + bắt login
    if (userInfo.userName === 'shareable') {
      localStorage.removeItem(Constant.TOKEN);
      localStorage.removeItem(Constant.FIREBASE_TOKEN);
      localStorage.removeItem(Constant.USER_INFO);
      this.route.navigate(['/login']);
    }

    this.countNewNotifi();
    if (!this.shareService.checkEmpty(userInfo.userId)) {
      setTimeout(() => {
        this.firebaseInstance = firebase
          .database()
          .ref('notify-count/' + userInfo.userId);
        this.firebaseInstance.on('child_changed', (resp2: any) => {
          const item = resp2.val();
          this.numOfNewNotifi = item;
          this.getNewNotify();
        });
        this.firebaseInstance.on('child_added', (resp2: any) => {
          const item = resp2.val();
          this.numOfNewNotifi = item;
          this.getNewNotify();
        });
      }, 2000);
    }

    this.lastToken = localStorage.getItem(Constant.TOKEN);
    this.store.select('auth').subscribe((res) => {
      if (res) {
        this.lastToken = res.value;
        this.isSystemAdmin =
          res.userType.includes(Constant.USERROLE.SystemAdmin) ||
          res.userType.includes(Constant.USERROLE.Admin);
      } else {
        this.lastToken = '';
      }
    });

    this.getNotify();
  }

  //Chỉ lấy 10 cái đầu
  getNewNotify() {
    const payload = {
      take: 10,
      skip: 0,
      fromDate: null,
      toDate: null,
      type: -1,
    };
    this.userService.getTopNotifi().subscribe((res) => {
      if (res !== null && res.isValid) {
        this.numOfUnreadNotifi = res.jsonData.totalNoti;
      }
    });

    this.userService.getAllNotifiByType(payload).subscribe((res) => {
      if (res !== null) {
        res.data.forEach((element) => {
          if (
            this.listNotification.filter((t) => t.id == element.id).length == 0
          )
            {this.listNotification.unshift(element);}
        });
      }
    });
  }

  getNotify() {
    const payload = {
      take: this.take,
      skip: this.skip,
      fromDate: null,
      toDate: null,
      type: -1,
    };
    this.userService.getTopNotifi().subscribe((res) => {
      if (res !== null && res.isValid) {
        this.numOfUnreadNotifi = res.jsonData.totalNoti;
        this.numOfNewNotifi = 0; //reset new notification count
      }
    });

    this.userService.getAllNotifiByType(payload).subscribe((res) => {
      if (res !== null) {
        this.listNotification.push(...res.data);
      }
    });
  }

  acceptJoinGroup(_notiId, isOK, temp) {
    let codeInviteId = '';
    let notiId = '';
    if (_notiId === null) {notiId = this.msg.data.info;}
    else {
      notiId = _notiId;
    }
    const payload = {
      notiId,
      isOK,
    };
    this.userService.confirmInviteGroup(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            codeInviteId = res.jsonData.invitaionId;
            if (temp !== null) {temp.close();}
            else
              {this.listNotification = this.listNotification.map((e) =>
                e.id == notiId ? { ...e, hasButton: 0, viewed: true } : e
              );}
            if (isOK) {
              this.route.navigateByUrl(
                '/confirm-invite?Code=' + codeInviteId + '&Type=1'
              ); //http://localhost:4200/confirm-invite?Code=60b8c0ecb029ff1ef5d86b44&Type=1
            } else {
              this.route.navigateByUrl(
                '/confirm-invite?Code=' + codeInviteId + '&Type=-1'
              ); //http://localhost:4200/confirm-invite?Code=60b8c0ecb029ff1ef5d86b44&Type=1
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }

  acceptJoin(_notiId: string, isOk: boolean, temp: any) {
    let notiId = '';
    if (_notiId != null) {notiId = _notiId;}
    else {notiId = this.msg.data.info;}

    this.videoConferenceService.joinRoom(notiId, isOk).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            if (isOk) {
              if (temp !== null) {temp.close();}
              else
                {this.listNotification = this.listNotification.map((e) =>
                  e.id == notiId
                    ? {
                        ...e,
                        viewed: true,
                        hasButton: 0,
                        actionHtml:
                          'Bạn đã đồng ý join room. Nhấn vào <a target="_blank" href="' +
                          e.link +
                          '" >đây</a> để tham gia',
                      }
                    : e
                );}

              window.open(
                window.location.origin +
                  '/video-conference/' +
                  res.jsonData.roomId,
                '_blank',
                'toolbar=0,location=0,menubar=0'
              );
            } else {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.INFO,
                'Bạn đã từ chối tham gia nhóm hội chẩn!'
              );
              if (temp !== null) {temp.close();}
              else
                {this.listNotification = this.listNotification.map((e) =>
                  e.id == notiId
                    ? {
                        ...e,
                        viewed: true,
                        hasButton: 0,
                        actionHtml: 'Bạn đã từ chối tham gia hội chẩn',
                      }
                    : e
                );}
            }
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  countNewNotifi() {
    this.userService.countNewNotifi().subscribe((res) => {
      if (res !== null) {
        if (res.isValid) {
          this.numOfNewNotifi = parseInt(res.jsonData);
        }
      }
    });
  }

  updateNewNotifi(msg) {
    if (msg != null) {this.numOfNewNotifi = this.numOfNewNotifi + 1;}
  }

  readNotifi(notiId) {
    this.userService.viewNotifi(notiId).subscribe((res) => {
      if (res !== null) {
        if (res.isValid) {
          const viewItem = this.listNotification.filter(
            (en) => en.id === notiId
          )[0];
          viewItem.viewed = true;
        }
      }
    });
  }

  goFromNotify(item) {
    this.readNotifi(item.id);
    if (item.referenceInfo == null || item.referenceInfo === '') {return;}
    if (item.type == Constant.NotificationType.Visit) {
      var arr = item.referenceInfo.split('#');
      if (arr.length != 3) {return;}
      // this.tabDataService.updateTab(arr[0], arr[1], 'VisitDetail', arr[2]);
      location.href = `/common-mobile/worklist/visit/${arr[1]}`;
    } else if (item.type == Constant.NotificationType.ChatInVisit) {
      var arr = item.referenceInfo.split('#');
      if (arr.length != 2) {return;}
      //TODO: show tab chat when open visit
      // this.tabDataService.updateTab(arr[0], arr[1], 'VisitDetail', '');
      location.href = `/common-mobile/worklist/visit/${arr[0]}`;
    } else if (item.type == Constant.NotificationType.Conversation) {
      //userId + "#" + userFullName
      var arr = item.referenceInfo.split('#');
      if (arr.length != 2) {return;}
      location.href = `/common-mobile/worklist/chat`;
    }
  }

  setLinkHoiChan(link) {
    return (
      'Ấn vào <a target="_blank" href="' +
      link +
      '">đây</a> để tham gia hội chẩn'
    );
  }

  onScrollDown() {
    this.skip += this.take;
    this.take;
    this.getNotify();
  }
}
