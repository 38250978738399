<div id="warp">
  <div class="row mt-3">
    <div class="col-2" [class]="{ 'text-right': isVisibleCalendar }">
      <span *ngIf="isVisibleCalendar" nz-icon nzType="left" (click)="toggleCalendar()" title="Ẩn"
        class="span-hide"></span>
      <span *ngIf="!isVisibleCalendar" nz-icon nzType="right" (click)="toggleCalendar()" title="Hiện"
        class="span-presently"></span>
    </div>
    <div class="col-10 d-flex justify-content-between">
      <div class="flex-grow-1" style="color: var(--dp-primary-2);  display: flex;
      line-height: 31px;" title="Thời gian đã chọn">
        <!-- <button class="normal-btn" type="button" nz-button (click)="chooseTimeToday()" class="mr-2">Hôm nay</button> -->
        <button nz-button nzType="default" nzShape="circle" class="mr-2" (click)="prevDay()" title="Ngày trước">
          <span nz-icon nzType="caret-left"></span>
        </button>
        <span class="mr-2 time">{{ time | date: 'EEEE, MMMM d, y':'vi' }}</span>
        <button nz-button nzType="default" nzShape="circle" class="mr-2" (click)="nextDay()" title="Ngày sau">
          <span nz-icon nzType="caret-right"></span>
        </button>
        <!-- <span nz-  nzType="clock-circle" nzTheme="outline" style="font-size: 26px; margin-left: 30px"
          class="mr-2"></span>
        <span class="mr-2 time">{{ time | date: 'EEEE, MMMM d, y':'vi' }}</span> -->
      </div>
      <div>
        <button nz-button class="ml-2" type="button" (click)="dayView()"
          [class]="{ 'btn-primary-medium': viewMode == 'day' }">
          Ngày
        </button>
        <button nz-button class="ml-2" type="button" (click)="weekView()"
          [class]="{ 'btn-primary-medium': viewMode == 'week' }">
          Tuần
        </button>
        <button nz-button class="ml-2" type="button" (click)="monthView()"
          [class]="{ 'btn-primary-medium': viewMode == 'month' }">
          Tháng
        </button>
      </div>
    </div>
  </div>

  <div class="mt-2 d-flex justify-content-between">
    <div class="time-picker" *ngIf="isVisibleCalendar" style="flex-basis: 13%; margin-top: -52px; margin-left: -10px">
      <div class="" style="position: relative;">
        <button class="normal-btn" type="button" nz-button (click)="chooseTimeToday()" class="mr-2"
          class="button-today">Hôm nay</button>
        <nz-calendar [nzFullscreen]="false" (nzSelectChange)="onValueChange($event)"
          (nzPanelChange)="onPanelChange($event)"></nz-calendar>
      </div>
      <div class="mt-4 text-center">
        <h1 class="h1">
          <img title="Chọn bác sĩ" class="img" src="assets/image/DPicon/doctor-fill.svg" />Chọn bác sĩ
        </h1>
        <nz-select class="" style="width: 100%" nzShowSearch nzPlaceHolder="Chọn BS" [(ngModel)]="selectDoctorId"
          (ngModelChange)="selectDoctor($event)">
          <nz-option nzLabel="Tất cả bác sĩ" nzValue=""></nz-option>
          <nz-option *ngFor="let el of doctorInGroup" [nzLabel]="el.fullName" [nzValue]="el.id"></nz-option>
        </nz-select>
      </div>
    </div>

    <div class="content" *ngIf="itemCount > 0" [ngStyle]="{ 'flex-basis': isVisibleCalendar ? '85%' : '100%' }">
      <div *ngIf="viewMode == 'day'">
        <app-day-view [timeSlotsDisplay]="timeSlotsDisplay" (visitItemHover)="onVisitItemHover($event)"
          (visitItemLeave)="onVisitItemLeave($event)"
          [listVisitGroupByDoctorId]="listVisitGroupByDoctorIdDisplay"></app-day-view>
      </div>
      <div *ngIf="viewMode == 'week'">
        <app-week-view [time]="time" [timeSlotsDisplay]="timeSlotsDisplay" (visitItemHover)="onVisitItemHover($event)"
          (visitItemLeave)="onVisitItemLeave($event)" (clickDateofWeek)="onClickDateofWeek($event)"
          [listVisitGroupByDoctorId]="listVisitGroupByDoctorIdDisplay"></app-week-view>
      </div>
      <div *ngIf="viewMode == 'month'">
        <app-month-view [time]="time" [timeSlotsDisplay]="timeSlotsDisplay" (visitItemHover)="onVisitItemHover($event)"
          (visitItemLeave)="onVisitItemLeave($event)"
          [listVisitGroupByDoctorId]="listVisitGroupByDoctorIdDisplay"></app-month-view>
      </div>
      <div class="w-100 text-right mt-2">
        <nz-pagination [nzPageIndex]="pageIndex" [nzTotal]="total" [nzPageSize]="pageSize"
          (nzPageIndexChange)="pageIndexChange($event)"></nz-pagination>
      </div>
    </div>
    <div *ngIf="itemCount == 0" class="content col-10"><nz-empty style="margin-top: 150px"></nz-empty></div>
  </div>
</div>