import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfigService } from 'src/app-config.service';
declare let JitsiMeetExternalAPI: any;
import { VideoConferenceService } from 'src/app/services/video-conference.service';
import { ShareService } from 'src/app/services/share.service';
import { Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';

@Component({
  selector: 'app-conference-room-call',
  templateUrl: './conference-room-call.component.html',
  styleUrls: ['./conference-room-call.component.scss'],
})
export class ConferenceRoomCallComponent implements OnInit {
  @Input() isInviteUser: any;
  PathAvatar: string =
    this.configService.getConfig().media.baseUrl + '/Avatar/';
  api: any;
  roomName = '';
  baseAvatarurl: string;
  callOk = true;
  html: string;
  isVisibleInvite: boolean;
  roomId = '';
  curKeyword: any;
  cursltValue: any;
  groupId: string;
  notiId: string;
  constructor(
    private route: ActivatedRoute,
    private shareService: ShareService,
    private videoConferenceService: VideoConferenceService,
    private configService: AppConfigService,
    private store: Store<AppState>
  ) {
    this.route.paramMap.subscribe((params) => {
      this.notiId = params.get('notiId');
      if (this.notiId) {
        this.initCall(this.notiId);
      }
    });
  }

  ngOnInit() {}

  initCall(code) {
    if (code === null || code === '' || code === undefined) {
      this.callOk = false;
      this.html = 'Thông tin của cuộc gọi chưa chính xác!';
      return;
    }
    this.roomId = code;
    const joinStatus = this.route.snapshot.queryParamMap.get('joinStatus');
    if (joinStatus === '-1') {
      this.callOk = false;
      this.html = 'Bạn đã từ chối tham gia cuộc gọi.';
      return;
    }
    if (!this.shareService.checkEmpty(code)) {
      this.videoConferenceService.getRoomInfo(code).subscribe((res) => {
        if (res.isValid) {
          let pwd = '0123456';
          const data = res.jsonData;
          this.roomName = data.name;
          this.groupId = data.groupId;
          let userInfo;
          this.store
            .select((store) => store.auth.userId)
            .subscribe((res) => {
              userInfo = res;
            });

          if (data.creatorId !== userInfo.userId && !data.isStarted) {
            this.callOk = false;
            this.html =
              'Room hội chẩn đang chờ người chủ trì bắt đầu hội chẩn. Bạn hãy chờ trong giây lát…';
            return;
          } else {
            console.log('int jitsi');
            pwd = data.password;

            const members = data.participants;

            const joinName = userInfo.userName;
            const roomName = data.roomId;
            const options = {
              roomName,
              parentNode: document.querySelector('#meet'),
              configOverwrite: {
                disableSimulcast: false,
              },
              interfaceConfigOverwrite: {
                filmStripOnly: false,
                SHOW_JITSI_WATERMARK: false,
                SHOW_WATERMARK_FOR_GUESTS: false,
              },
            };
            console.log(options);
            const url = this.configService.getConfig().jitsi.baseUrl;
            const domain = new URL(url);
            this.api = new JitsiMeetExternalAPI(domain.hostname, options);
            this.api.executeCommand('subject', roomName); //Tên phòng
            this.api.addEventListener('videoConferenceJoined', () => {
              this.api.executeCommand('displayName', joinName); // tên cua ng join
              let avatar = 'assets/image/group.svg';
              if (
                userInfo.avatar !== null &&
                userInfo.avatar !== '' &&
                userInfo.avatar != undefined
              ) {
                avatar = this.PathAvatar + userInfo.avatar;
              }
              this.api.executeCommand('avatarUrl', avatar);
            });

            this.api.addEventListeners({
              readyToClose: this.handleClose,
              participantLeft: this.handleParticipantLeft,
              participantJoined: this.handleParticipantJoined,
              videoConferenceJoined: this.handleVideoConferenceJoined,
              videoConferenceLeft: this.handleVideoConferenceLeft,
              audioMuteStatusChanged: this.handleMuteStatus,
              videoMuteStatusChanged: this.handleVideoStatus,
            });
          }
        }
      });
    }
  }
  handleClose = () => {
    this.api.dispose();
    window.close();
  };

  handleParticipantLeft = async (participant) => {
    const data = await this.getParticipants();
  };

  handleParticipantJoined = async (participant) => {
    const data = await this.getParticipants();
  };

  handleVideoConferenceJoined = async (participant) => {
    const data = await this.getParticipants();
  };

  handleVideoConferenceLeft = () => {
    // event.stopPropagation();
  };

  handleMuteStatus = (audio) => {
    console.log('handleMuteStatus', audio); // { muted: true }
  };

  handleVideoStatus = (video) => {
    console.log('handleVideoStatus', video); // { muted: true }
  };

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }

  openInviterUser(): void {
    this.isVisibleInvite = true;
  }

  closeInviteUser(isRefresh): void {
    this.isVisibleInvite = false;
  }

  callService(sltValue, keyword, groupId) {
    this.curKeyword = keyword;
    this.cursltValue = sltValue;
    const payload = {
      groupId, //this.newGroupId,
      keyword,
      status: sltValue,
    };
  }
}
