import { Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UserReturn } from 'src/app/models/userInvite.model';
import { Constant } from 'src/app/share/constants/constant.class';
import { NotificationService } from '../../../services/notification.service';
import { GroupMemberService } from '../../../services/group-member.service';
import { VideoConferenceService } from '../../../services/video-conference.service';
import * as _ from 'underscore';
import { AppConfigService } from 'src/app-config.service';
import { AppState } from '../../app-state/app-state';
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-conference-room',
  templateUrl: './conference-room.component.html',
  styleUrls: ['./conference-room.component.scss'],
})
export class ConferenceRoomComponent implements OnInit, OnDestroy {
  @Input() isVisible: any;
  @Input() visitId: string;
  @Input() patient: any;
  @Input() groupId = '';

  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  listMember: Array<any> = [];
  listUser: Array<UserReturn> = [];
  listInvited: Array<any> = [];
  ListOfMember;
  userName: string;
  participantIds: string;
  baseAvatarurl = '';
  timeToRoom: any;
  timeCall: any;
  dateCall: Date;
  datetime: any;
  groupSub: any;
  radioValue = 'A';
  curKeyword: any;
  cursltValue: any;
  newGroupId: any;
  hasPatient = false;

  currentUserId = '';
  groupName: string;
  constructor(
    private notificationService: NotificationService,
    private groupMemberService: GroupMemberService,
    private videoConferenceService: VideoConferenceService,
    private configService: AppConfigService,
    private store: Store<AppState>,
    public datepipe: DatePipe
  ) {
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.currentUserId = res;
      });
  }

  ngOnInit() {
    this.baseAvatarurl =
      this.configService.getConfig().media.baseUrl + '/Avatar/';
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        this.groupName = data.groupName;
      }
    });
  }
  initPopup() {
    this.radioValue = 'A';
    this.timeCall = new Date(Date.now() + 1000 * 30 * 60);
    this.datetime = new Date();
  }
  // Bắt đầu cuộc gọi
  handleCall(): void {
    if (this.listInvited.length == 0 && this.hasPatient == false) {
      this.notificationService.showNotification(
        'error',
        'Không có thành viên để tạo phòng!'
      );
      return;
    }

    let datetimeFe = null;
    if (this.radioValue === 'A') {
      this.timeToRoom = null; // this.datatimeToDateTimeZone(new Date());
    } else {
      this.timeToRoom = this.datatimeToDateTimeZone(
        this.datetime,
        this.timeCall
      );
      datetimeFe = new Date(
        this.datetime.getFullYear(),
        this.datetime.getMonth(),
        this.datetime.getDate(),
        this.timeCall.getHours(),
        this.timeCall.getMinutes()
      );

      if (datetimeFe <= new Date()) {
        this.notificationService.showNotification(
          'error',
          'Thời gian không thể ở quá khứ!'
        );
        return;
      }
    }
    let titleRoom = '';
    if (this.patient === undefined) {
      titleRoom = 'Hội chẩn của nhóm ' + this.groupName;
    } else {
      titleRoom = 'Hội chẩn BN ' + this.patient.fullName;
    }
    const payload = {
      name: titleRoom,
      visitId: this.visitId,
      participantIds: this.listInvited.map((en) => en.userId),
      patientId: this.hasPatient ? this.patient.id : '',
      // videoConferenceLink: window.location.origin + '/video-conference?notiId={0}&joinStatus={1}',  //  &groupId={2}
      videoConferenceLink: window.location.origin + '/video-conference/{0}', //  &groupId={2}
      startDate: this.timeToRoom,
      groupId: this.groupId,
    };

    this.videoConferenceService.videoCall(payload).subscribe((data) => {
      if (data.isValid) {
        this.isVisible = false;
        this.closeModal.emit();
        this.listInvited.length = 0;
        if (this.radioValue === 'B') {
          const time = this.datepipe.transform(datetimeFe, 'dd/MM/yyyy HH:mm');
          this.notificationService.showNotification(
            'success',
            'Hệ thống đã tạo room hội chẩn và đã gửi thông báo đến các user được mời tham gia. Chúng tôi sẽ gửi cho bạn thông báo và link tham gia hội chẩn trước 5 phút khi đến thời điểm hội chẩn ' +
              time
          );
          return;
        } else {
          this.hasPatient = false;
          //chuyển sang cuộc gọi video
          const h = window.innerHeight;
          const w = window.innerWidth; //+ '&groupId=' + this.groupId
          window.open(
            window.location.origin +
              '/video-conference/' +
              data.jsonData.roomId,
            '_blank',
            'toolbar=0,location=0,menubar=0,width=900,height=900'
          );
        }
      } else {
        this.notificationService.showNotification(
          'error',
          data.errors[0].errorMessage
        );
      }
    });
  }

  datatimeToDateTimeZone(datetime: Date, timeCall?: Date) {
    if (datetime == null) {
      return null;
    }
    //this.timeCall = 2*60000;
    const timezone = -datetime.getTimezoneOffset() / 60;
    let hours = datetime.getHours() + timezone;
    let minutes = datetime.getMinutes();

    if (timeCall) {
      hours = timeCall.getHours() + timezone;
      minutes = timeCall.getMinutes();
    }

    const day = datetime.getDate();
    const month = datetime.getMonth();
    const year = datetime.getFullYear();

    return new Date(year, month, day, hours, minutes);
  }

  handleCancel(): void {
    this.isVisible = false;
    this.closeModal.emit(false);
  }

  loadMember(sltValue, keyword) {
    this.callService(parseInt(sltValue), keyword, this.groupId);
  }

  // Box chưa danh sách người tham gia cuộc gọi
  fieldSelectedMember(event): void {
    if (event.userId !== 'patient') {
      if (
        this.listInvited.filter((en) => en.userId === event.userId).length == 0
      ) {
        this.listInvited.push(event);
      } else {
        this.notificationService.showNotification(
          Constant.ERROR,
          'Người dùng đã được mời'
        );
      }
    } else {
      this.hasPatient = true;
    }
    this.ListOfMember = {};
  }

  callService(sltValue, keyword, groupId) {
    this.curKeyword = keyword;
    this.cursltValue = sltValue;
    const payload = {
      groupId, //this.newGroupId,
      keyword,
      status: sltValue,
    };
    this.groupMemberService.getListMember(payload).subscribe(
      (res) => {
        if (res !== null) {
          this.listMember = res.jsonData;
          for (let i = 0; i < this.listMember.length; i++) {
            this.listMember[i].fullName = this.listMember[i].fullName.trim();
            if (
              this.listMember[i].imageUrl == null ||
              this.listMember[i].imageUrl == ''
            ) {
              this.listMember[i].imageUrl = 'ava.jpg';
            } else {
              this.listMember[i].imageUrl = this.listMember[i].imageUrl;
            }
          }
          if (this.patient !== undefined) {
            this.patient.avatar =
              this.patient.avatar == null ? 'ava.jpg' : this.patient.avatar;
            const patient = {
              userId: 'patient',
              userName: 'Bệnh nhân: ' + this.patient.fullName,
              imageUrl:
                this.patient.avatar == null ? 'ava.jpg' : this.patient.avatar,
              email: this.patient.email,
            };
            if (this.patient.activeStatus == 0) {
              //activeStatus=0 : BN đã active, activeStatus=2 : BN chưa active
              this.listMember.unshift(patient);
            }
          }
          this.listMember = this.listMember.filter(
            (en) => en.userId != this.currentUserId
          );
        } else {
          if (res.errors && res.errors.length > 0) {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.WARRING,
              res.errors[0].message
            );
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }

  unSelectToInvite(userId, email) {
    this.listInvited = this.listInvited.filter(
      (en) => en.email !== email && en.userId !== userId
    );
    if (userId == 'patient') {
      this.hasPatient = false;
    }
  }

  OnChanges(item: string[]): void {}
  onKeyPress(x) {
    const keyword = x.target.value;
    alert('text');
    const payload = {
      groupId: this.newGroupId,
      keyword,
      status: 1000,
    };

    this.groupMemberService.getListMember(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.listUser = res.jsonData;
            if (this.listUser == null || this.listUser.length == 0) {
            }
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.WARRING,
                res.errors[0].message
              );
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  ngOnDestroy(): void {
    this.groupSub.unsubscribe();
  }
  datepickerChange() {
    this.radioValue = 'B';
  }
}
