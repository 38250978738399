import { Component, OnInit, ViewChild } from '@angular/core';
import { MedicationService } from '../../../../services/medication.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/components/app-state/app-state';
import { Router } from '@angular/router';
import { GroupTreeService } from 'src/app/services/groupTree.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ExcelService } from 'src/app/services/excel.service';
@Component({
  selector: 'app-medicine-management',
  templateUrl: './medicine-management.component.html',
  styleUrls: ['./medicine-management.component.scss'],
})
export class MedicineManagementComponent implements OnInit {
  emptyId = '000000000000000000000000';
  selectedRowIndex = this.emptyId;
  listMedicine: any[] = [];
  isVisible: boolean;
  isLoading = false;
  isFromManager = false;
  ishidePrivacy1 = false;
  ishidePrivacy2 = false;
  ishidePrivacy3 = false;
  myIsAdminGroup = false;
  medicine:any = {
    id: '',
    name: '',
    activeIngredient: '',
    unit: '',
    dosages: '',
    route: '',
    usage: '',
    // turn1: true,
    status: '',
    turn: false,
    privacyUse: 3,
    groupIds: [],
    content: '',
    isFunctionalFoods: '',
  };
  pageIndex = 1;
  pageSize = 20;
  searchValue = '';
  groupIds = [];
  groupShare: {
    id: string;
    name: string;
  }[] = [];
  isShowChooseGroup = false;
  public listOfDisplayData;
  authSub: Subscription;
  groupSub: Subscription;
  groupIdObser = '';
  groupNameObser = '';
  searchDataFilter = [];
  total = 0;

  visibleImport = false;
  importMedicine = {
    privacyUse: 1,
  };
  importGroupShare: {
    id: string;
    name: string;
  }[] = [];
  fileChangedEvent: any;
  visibleImportFail = false;
  listMedicationImportExist: string[] = [];
  insertedImport = 0;
  callbackFn: any;
  // isValid = true;
  isExporting:boolean = true;
  @ViewChild('fileInput') fileInput;

  constructor(
    private medicationService: MedicationService,
    private notificationService: NotificationService,
    private modalService: NzModalService,
    private shareService: ShareService,
    private store: Store<AppState>,
    private router: Router,
    private group: GroupTreeService,
    private excelService: ExcelService
  ) {
    this.callbackFn = this.shareService.debounce(this.getListByFilter, 1000);
  }

  ngOnInit(): void {
    const url = this.router.routerState.snapshot.url;
    this.authSub = this.store
      .select((store) => store.auth.userType)
      .subscribe((res) => {
        const userType = res;
        if (userType.indexOf(Constant.USERROLE.MedicationMangament) >= 0) {
          this.ishidePrivacy1 = false;
          if (url.indexOf('medicine') > 0) {
            this.ishidePrivacy2 = true;
            this.ishidePrivacy3 = true;
            this.isFromManager = true;
          } else {
            this.ishidePrivacy2 = false;
            this.ishidePrivacy3 = false;
          }
        } else {
          this.ishidePrivacy1 = true;
        }
        this.groupSub = this.store.select('groupAuth').subscribe((data) => {
          if (data.isAuthenticated) {
            this.groupIdObser = data.groupId;
            this.groupNameObser = data.groupName;

            if (data.role === 3) {
              this.ishidePrivacy2 = true;
            }
          }
          this.getListByFilter();
        });
      });
  }

  changeSelectedPrivacy(value) {
    if (value === 2 && !this.ishidePrivacy2) {
      this.isShowChooseGroup = true;
    } else {
      this.isShowChooseGroup = false;
    }
  }
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;

    this.getListByFilter();
  }
  onSearchForm(keyword) {
    if (keyword.trim().length > 1) {
      this.group.getListGroup(keyword).subscribe(
        (response) => {
          if (response !== null) {
            this.searchDataFilter = [...response];
          }
        },
        (error) => {
          console.error(error);
          this.notificationService.showNotification('', 'Lỗi data');
        }
      );
    }
  }
  selectForm(selectGroup) {
    this.groupShare.push({ id: selectGroup.id, name: selectGroup.name });
  }
  selectFormEvent(event: any, data: any) {
    if (event.isUserInput) {
      this.selectForm(data);
    }
  }
  unSelectForm(id) {
    this.groupShare = this.groupShare.filter((en) => en.id !== id);
  }
  showModal(datasend): void {
    this.isVisible = true;
    if (datasend == 'add') {
      this.selectedRowIndex = this.emptyId;
      this.medicine = {
        id: '000000000000000000000000',
        name: '',
        activeIngredient: '',
        unit: '',
        dosages: '',
        route: '',
        usage: '',
        // turn1: true,
        status: false,
        turn: true,
        privacyUse: 1,
        groupIds: [],
        content: '',
        isFunctionalFoods: false,
      };
      this.groupShare = [];
      this.isShowChooseGroup = false;
      const url = this.router.routerState.snapshot.url;
      if (!this.isFromManager) {
        const currGroup = {
          id: this.groupIdObser,
          name: this.groupNameObser,
        };
        this.groupShare[0] = currGroup;
      }
    }
  }

  handleCancle(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    this.isVisible = false;
    this.listMedicine.push(this.medicine);
  }

  addMedication(datasend) {
    datasend.name = datasend.name.trim();
    datasend.activeIngredient = datasend.activeIngredient.trim();
    datasend.content = datasend.content.trim();
    datasend.unit = datasend.unit.trim();
    datasend.route = datasend.route.trim();
    //datasend.usage = datasend.usage.trim();

    if (
      datasend.name == '' ||
      datasend.content == '' ||
      datasend.unit == '' ||
      datasend.route == ''
    ) {
      return this.notificationService.showNotification(
        'error',
        'Chưa nhập dữ liệu, không thể thêm'
      );
    }
    if (datasend.turn == false) {
      datasend.status = 0;
    } else {
      datasend.status = 1;
    }
    // if (datasend.turn1 == false) {
    //   datasend.isFunctionalFoods = 0;
    // } else {
    //   datasend.isFunctionalFoods = 1;
    // }
    if (datasend.privacyUse === 2) {
      this.groupIds = this.groupShare.map(function (a) {
        return a.id;
      });
    } else {
      this.groupIds = [];
    }
    this.medicine = {
      id: this.selectedRowIndex,
      name: datasend.name,
      activeIngredient: datasend.activeIngredient,
      unit: datasend.unit,
      dosages: datasend.dosages,
      route: datasend.route,
      usage: datasend.usage,
      // turn1: datasend.turn1,
      status: datasend.status,
      turn: datasend.turn,
      privacyUse: datasend.privacyUse,
      groupIds: this.groupIds,
      content: datasend.content,
      isFunctionalFoods: datasend.isFunctionalFoods,
    };
    this.medicationService.addMedication(this.medicine).subscribe((data) => {
      if (data.errors.length > 0) {
        for (const error of data.errors) {
          this.notificationService.showNotification(
            'error',
            error.errorMessage
          );
        }
      } else {
        this.notificationService.showNotification('success', 'Thêm thành công');
        this.isVisible = false;
        this.getListByFilter();
      }
    });

    this.selectedRowIndex = this.emptyId;
  }

  rowSelected(datasend) {
    this.selectedRowIndex = datasend.id;
    this.medicine = {
      id: this.selectedRowIndex,
      name: datasend.name,
      activeIngredient: datasend.activeIngredient,
      unit: datasend.unit,
      dosages: datasend.dosages,
      route: datasend.route,
      usage: datasend.usage,
      // turn1: datasend.turn1,
      status: datasend.status,
      turn: datasend.turn,
      privacyUse: datasend.privacyUse,
      groupIds: this.groupIds,
      content: datasend.content,
      isFunctionalFoods: datasend.isFunctionalFoods,
    };
    this.groupShare = datasend.groupShare;
  }

  editMedication(datasend) {
    datasend.name = datasend.name.trim();
    datasend.activeIngredient = datasend.activeIngredient.trim();
    datasend.unit = datasend.unit.trim();
    datasend.route = datasend.route.trim();
    if (
      datasend.name == '' ||
      datasend.unit == '' ||
      datasend.route == ''
    ) {
      return this.notificationService.showNotification(
        'error',
        'Chưa nhập dữ liệu, không thể sửa.'
      );
    }
    if (datasend.turn == false) {
      datasend.status = 0;
    } else {
      datasend.status = 1;
    }

    this.groupIds = this.groupShare.map(function (a) {
      return a.id;
    });
    this.medicine = {
      id: this.selectedRowIndex,
      name: datasend.name,
      activeIngredient: datasend.activeIngredient,
      unit: datasend.unit,
      dosages: datasend.dosages,
      route: datasend.route,
      usage: datasend.usage,
      // turn1: datasend.turn1,
      status: datasend.status,
      turn: datasend.turn,
      privacyUse: datasend.privacyUse,
      groupIds: this.groupIds,
      content: datasend.content,
      isFunctionalFoods: datasend.isFunctionalFoods,
    };

    this.medicationService.updateMedication(this.medicine).subscribe((data) => {
      if (data.isValid) {
        this.notificationService.showNotification('success', 'Sửa thành công');
        this.isVisible = false;
        this.getListByFilter();
        this.selectedRowIndex = this.emptyId;
      } else {
        for (const error of data.errors) {
          this.notificationService.showNotification(
            'error',
            error.errorMessage
          );
        }
      }
    });
  }

  deleteMedication() {
    const id = this.selectedRowIndex;
    if (id == this.emptyId) {
      this.notificationService.showNotification(
        'warning',
        'Bạn chưa chọn dòng để xoá!'
      );
    } else {
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn xóa hay không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Huỷ',
        nzOnOk: () => this.deleteItem(id),
      });
    }
  }

  deleteItem(id) {
    this.medicationService.deleteMedication(id).subscribe(
      (data) => {
        if(data.isValid == true) {
          this.notificationService.showNotification('success', 'Xoá thành công');
        this.getListByFilter();
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            data.errors[0].errorMessage
          );
        }
      },
      // (error) => {
      //   this.notificationService.showNotification(
      //     'error',
      //     'Xóa không thành công'
      //   );
      // }
    );
    this.selectedRowIndex = this.emptyId;

    // if (.isValid) {
    //   this.medicationService.deleteMedication(id).subscribe(
    //     (data) => {
    //       this.notificationService.showNotification('success', 'Xoá thành công');
    //       this.getListByFilter();
    //     },
    //     (error) => {
    //       this.notificationService.showNotification('error', 'Xóa không thành công');
    //     }
    //   );
    //   this.selectedRowIndex = this.emptyId;
    // } else {
    //   this.notificationService.showNotification('warning', 'Không thể xóa với giá trị không hợp lệ');
    // }
  }

  handleButtonClick() {
    this.isLoading = true;
    this.shareService.debounce(this.getListByFilter(1), 1000);
  }

  getListByFilter(page: any = null) {
    this.isLoading = true;
    const searchName = this.searchValue;
    if(page !== null){
      this.pageIndex = page;
    }
    const payload = {
      keyword: searchName,
      groupId: this.groupIdObser,
      fromManagerPage: false,
      PageSize: this.pageSize,
      Page: this.pageIndex,
    };
    this.medicationService.searchMedication(payload).subscribe((data) => {
      this.listMedicine = data.source;
      this.total = data.total;
      for (const c of this.listMedicine) {
        if (c.status == 1) {
          c.status = 'On';
          c.turn = true;
        }
        if (c.status == 0) {
          c.turn = false;
          c.status = 'Off';
        }
        if (c.isFunctionalFoods == true) {
          c.isFunctionalFoodsLabel = 'TPCN';

        }
        if (c.isFunctionalFoods == false) {
          c.isFunctionalFoodsLabel = 'Thuốc';
        }
      }


      this.listOfDisplayData = [...this.listMedicine];
      this.isLoading = false;
    });
  }
  openImportModal() {
    this.visibleImport = true;
    this.importGroupShare = [];
    //$("#fileOpen").val("");
    this.importMedicine.privacyUse = 1;
  }
  cancelImportModal() {
    this.visibleImport = false;
    $('#fileOpen').val('');
  }
  downloadFileSample(url: string, filename: string) {
    this.excelService.downloadFile(url).subscribe(
      (response) => {
        const blob: any = new Blob([response], {
          type: 'text/json; charset=utf-8',
        });
        const url = window.URL.createObjectURL(blob);
        this.shareService.saveLocalFile(url, filename);
      },
      (error) => {
        //console.log('Error downloading the file');
        this.notificationService.showNotification(
          'error',
          'Error downloading the file'
        );
      }
    );
  }
  import() {
    if (this.shareService.checkEmpty(this.fileInput.nativeElement.files[0])) {
      this.notificationService.showNotification(
        'error',
        'Vui lòng upload file!'
      );
      return;
    }
    const formData = new FormData();
    formData.append('postedFile', this.fileInput.nativeElement.files[0]);
    const groupIds =
      this.importGroupShare.length > 0
        ? this.importGroupShare.map((en) => en.id).join('#')
        : '';
    const privacyUse = this.importMedicine.privacyUse;

    this.medicationService
      .importMedication(groupIds, privacyUse, formData)
      .subscribe((res) => {
        if (res.isValid) {
          const data = res.jsonData;
          if (data.listExist.length > 0) {
            this.visibleImportFail = true;
            this.listMedicationImportExist = data.listExist;
            this.insertedImport = data.inserted;
          } else {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.SUCCESS,
              'Import ' + res.jsonData.inserted + ' thuốc thành công!'
            );
            this.getListByFilter();
          }
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            res.errors[0].errorMessage
          );
        }
        this.visibleImport = false;
        $('#fileOpen').val('');
      });
  }

  selectImportGroup(selectGroup) {
    this.importGroupShare.push({ id: selectGroup.id, name: selectGroup.name });
  }

  unSelectImportGroup(id) {
    this.importGroupShare = this.importGroupShare.filter((en) => en.id !== id);
  }
  cancelImportFailModal() {
    this.visibleImportFail = false;
    this.getListByFilter();
  }

  exportFile() {
    const payload = {
      keyword: this.searchValue,
      groupId: this.groupIdObser,
      fromManagerPage: false,
    };
    this.isExporting = true;
    this.medicationService.exportMedication({}).subscribe((res) => {
      if (res) {
        const filename = 'Medication_export_list';
        const dataType = res.type;
        const binaryData = [];
        binaryData.push(res);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        if (filename) {
          downloadLink.setAttribute('download', filename);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.isExporting = false;
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Không thể xuất file, vui lòng liên hệ quản trị hệ thống!'
        );
        this.isExporting = false;
      }
    });
  }
}
