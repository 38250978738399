import {
  Directive,
  Renderer2,
  ContentChildren,
  QueryList,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[enter-tab]',
})
export class EnterTabDirective implements AfterViewInit {
  @ContentChildren('nextTab', { descendants: true, read: ElementRef })
  controls: QueryList<any>;

  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {}
  ngAfterViewInit(): void {
    //console.log('controls', this.controls);
    let isDone: boolean;

    this.controls.changes.subscribe({
      next: (controls) => {
        this.createKeydownEnter(controls);
        isDone = true;
      },
      error: () => {},

      complete: () => {},
    });

    if (this.controls.length) {
      setTimeout(() => {
        if (!isDone) {
          this.createKeydownEnter(this.controls);
        }
      }, 0);
    }
  }
  private createKeydownEnter(querycontrols: QueryList<any>) {
    // console.log(querycontrols.length);

    querycontrols.forEach((control) => {
      if (control.nativeElement.tagName != 'TEXTAREA') {
        this.renderer.listen(
          control.nativeElement,
          'keydown.enter',
          (event) => {
            if (this.controls.last != control) {
              let controls = querycontrols.toArray();
              let index = controls.findIndex((d) => d == control);
              if (index >= 0) {
                let nextControl = controls.find(
                  (el, i) =>
                    el &&
                    i > index &&
                    !el.nativeElement.attributes.disabled &&
                    !Array.from(el.nativeElement.classList).includes(
                      'ant-select-disabled'
                    )
                );
                // console.log(nextControl);

                if (nextControl) {
                  let input = nextControl.nativeElement.querySelector('input');
                  let isNzSelect =
                    nextControl.nativeElement.tagName == 'NZ-SELECT';
                  let isNzDatePicker =
                    nextControl.nativeElement.tagName == 'NZ-DATE-PICKER';

                  if ((input && !isNzSelect) || isNzDatePicker) {
                    input.focus();
                  } else {
                    nextControl.nativeElement.focus();
                    nextControl.nativeElement.click();
                  }
                  this.cdr.detectChanges();
                  event.preventDefault();
                }
              }
            }
          }
        );
      }
    });
  }
}
