<div class="custom-modal">
  <nz-modal
    nzClassName="custom-modal"
    [(nzVisible)]="isVisible"
    nzTitle="Quản trị danh mục nhóm làm việc của bác sỹ"
    (nzOnCancel)="handleCancel()">
    <div class="row">
      <div
        class="group-tree"
        style="
          height: 500px;
          width: 500px;
          overflow: hidden;
          padding: 0 0 10px 20px;
        ">
        <tree-root
          #tree
          [nodes]="menus"
          class="tree-class"
          (moveNode)="onMoveNode($event)"
          [state]="state"
          [focused]="true"
          [options]="options">
          <ng-template #treeNodeTemplate let-node="node">
            <ng-container *ngIf="node.data.icon !== 'assets/image/folder.svg'">
              <i nz-icon class="image1">
                <img
                  src="{{ node.data.icon }}"
                  class="image"
                  style="width: 28px; height: 25px; margin-right: 10px" />
              </i>
              <span *ngIf="node === editNode">
                <input
                  autofocus
                  [(ngModel)]="node.data.name"
                  (blur)="stopEdit()"
                  (keyup.enter)="stopEdit()" />
              </span>
              <span *ngIf="node !== editNode">{{ node.data.name }}</span>
            </ng-container>
            <ng-container *ngIf="node.data.icon === 'assets/image/folder.svg'">
              <i nz-icon>
                <img
                  src="{{ node.data.icon }}"
                  class="image"
                  style="width: 38px; height: 38px" />
              </i>
              <span *ngIf="node === editNode">
                <input
                  autofocus
                  [(ngModel)]="node.data.name"
                  (blur)="stopEdit()"
                  (keyup.enter)="stopEdit()" />
              </span>
              <span *ngIf="node !== editNode">{{ node.data.name }}</span>
            </ng-container>
          </ng-template>
        </tree-root>
        <div
          class="menu"
          *ngIf="contextMenu"
          [style.left.px]="100"
          [style.top.px]="contextMenu.y + 30">
          <div><b>Menu for:</b> {{ contextMenu.node.data.name }}</div>
          <hr />
          <ul>
            <li
              *ngIf="contextMenu.node.data.icon === 'assets/image/folder.svg'"
              (click)="edit()">
              <a>Sửa tên danh mục</a>
            </li>
            <li
              *ngIf="contextMenu.node.data.icon !== 'assets/image/folder.svg'"
              (click)="edit()">
              <a>Sửa tên riêng do bạn đặt</a>
            </li>
            <li
              *ngIf="contextMenu.node.data.icon === 'assets/image/folder.svg'"
              (click)="removeNode(tree)">
              <a>Xóa</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <button
        nz-button
        (click)="addCategory(tree)"
        nzType="primary"
        class="button-submit">
        Thêm danh mục
      </button>
      <!-- <button nz-button (click)="openModelAddGroup()">Thêm nhóm làm việc</button> -->
    </div>
  </nz-modal>
</div>
