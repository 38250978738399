import { remove } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app-config.service';
import { AppState } from 'src/app/components/app-state/app-state';
import { AddVitalAsignComponent } from 'src/app/components/worklist/visit-detail/add-vital-asign/add-vital-asign.component';
import { Visits } from 'src/app/models/listVisit.class';
import { Observation } from 'src/app/models/observationType.class';
import { MedicalInstructionService } from 'src/app/services/medical-instruction.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ObservationService } from 'src/app/services/observation.service';
import { ShareService } from 'src/app/services/share.service';
import { SubclinicalorderService } from 'src/app/services/subclinicalorder.service';
import { SubClinicalVisitService } from 'src/app/services/subcliniccal-service';
import { VisitService } from 'src/app/services/visit.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-visit-info',
  templateUrl: './visit-info.component.html',
  styleUrls: ['./visit-info.component.scss'],
})
export class VisitInfoComponent implements OnInit {
  _visit: Visits = {
    stt: '',
    sttsearchresult: '',
    id: '',
    patientId: '',
    patientName: '',
    patientCode: '',
    groupId: '',
    doctorId: '',
    creatorId: '',
    patientSex: '',
    patientSexText: '',
    yob: 0,
    dob: '',
    dobString: '',
    phoneNo: '',
    address: '',
    visitDate: null,
    visitReason: '',
    visitTypeText: '',
    visitEndDate: null,
    color: '',
    shortName: '',
    age: 0,
    imageUrl: '',
    diseases: [],
    doctorName: '',
    groupName: '',
    images: [],
    documents: [],
    pdfDocuments: [],
    avatar: '',
    clinicalInfomation: '',
    conclusion: '',
    tags: [],
    dateOfDiagnosis: null,
    dateOfSymptom: null,
    doctorInCareDoct: '',
    nursingInCare: '',
    examinedStatus: false,
    examinedByDoctorId: '',
    examinedByDoctorName: '',
    diagnoseInit: '',
  };
  notPrintAll = false;
  notPrintAll2 = false;
  VISIT_STATUS = Constant.VISIT_STATUS;
  cancelSubclinicalStatus = Constant.SubclinicalVisitHL7Status.CA;
  @Input() set visit(val: Visits) {
    this._visit = val;
    if (val && val.id) {
      this.getSubClinicalVisitByRelated();
      this.getSubOrder();
    }
  }

  get visit() {
    return this._visit;
  }

  @Input() editingFlag = false;
  @Input() disabledEditInfo = false;
  @Input() rooms = [];
  @Input() groupId = '';
  @Input() hideButton: string;

  @Output() visitIdChange = new EventEmitter();
  @Output() editingFlagChange = new EventEmitter();
  @Output() outSubClinicalVisit = new EventEmitter();
  @Output() titleTabRefresh = new EventEmitter();
  @Output() lstRelatedVisitChange = new EventEmitter();
  @Output() vitalSign = new EventEmitter<any>();

  diseasesData = [];
  keywordICD = '';
  suggestDiseases = '';
  curUserId = '';
  showAddSubOrder = false;

  subClinicalVisitSource: any[] = [];
  subClinicalVisitCustomSource = [];
  @Input() subClinicalVisit = [];
  subClinicalVisitModal = [];

  commands = [];
  //height = 600;
  isVisibleForm = false;
  showUpdateStatus = false;
  isAddOder = false;
  isVisibleView = false;

  visibleSubOrderGroupAdd = false;
  filterSubItem = '';
  isSpinningSubOrder = false;
  subOrderSource = [];
  visibleDiscount = false;
  //selectedSubDiscount = null;
  currentOrdersDiscount = [];

  rosIdSelected = '';
  seletedSubVisit: any = null;
  seletedRelatedVisit: any = null;
  services = [];
  visibleAddService = false;
  objService = {
    serviceId: '',
    roomId: '',
  };
  lstRelatedVisit = [];
  @Input() lstRelatedVisitDisplay = [];
  isShowRelatedVisit = false;
  /////////////////
  ///// helper
  ////////////////
  groupSub: Subscription;
  controlSub: Subscription;
  timerSearchService: any;

  callBackFnICD: any;
  callBackFnClinicalInfo: any;
  callBackFnConclusion: any;
  callBackFnDiagnoseInit: any;

  useRoomOrDoctorVisit = false;
  getHISMedicinePrice = false;
  getHISServicePrice = false;

  rosIdConfig = this.configService.getConfig().ROSId;
  rosConstant = Constant.SUBCLINICAL_ORDER_STRING;
  hienGiaDichVuChiDinh = false;
  visitOtherInfoPopupData = {
    isVisible: false,
    data: {} as any
  }
  isVisibleVS: boolean;
  @ViewChild('addVitalSigns') triggerVitalAsign: AddVitalAsignComponent;
  lstObservationType;
  lstObservationVS: Observation[] = [];
  isSubTable = false;

  constructor(
    private visitService: VisitService,
    private shareService: ShareService,
    private subClinicalVisitService: SubClinicalVisitService,
    private medicalInstructionService: MedicalInstructionService,
    private notificationService: NotificationService,
    private subclinicalorderService: SubclinicalorderService,
    private store: Store<AppState>,
    private configService: AppConfigService,
    private nzContextMenuService: NzContextMenuService,
    private modalService: NzModalService,
    private nzMessageService: NzMessageService,
    private observationService: ObservationService,

  ) {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.getHISMedicinePrice = data.getHISMedicinePrice;
        this.getHISServicePrice = data.getHISServicePrice;
        this.useRoomOrDoctorVisit = data.getVisitByRoomOrDoctor;
      }
    });
    this.controlSub = this.store.select('control').subscribe((data) => {
      if (data.reloadCancelOrder) {
        this.getSubClinicalVisitByRelated();
      }
    });
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
  }

  ngOnInit() {
    //this.height = window.innerHeight - 295;
    this.callBackFnClinicalInfo = this.shareService.debounce(this.updateClinicalInfo, 500);
    this.callBackFnICD = this.shareService.debounce(this.onInputICD10, 200);
    this.callBackFnConclusion = this.shareService.debounce(this.updateConclusion, 500);
    this.callBackFnDiagnoseInit = this.shareService.debounce(this.updateDiagnoseInit, 500);
    if (this.useRoomOrDoctorVisit) this.getRelatedVisit();
    this.hienGiaDichVuChiDinh = this.configService.getConfig().hienGiaDichVuChiDinh;
  }
  getSubClinicalVisitByRelated() {
    let payload = {
      visitId: this.visit.id,
      refVisitId: this.visit.refVisitId,
    };
    this.subClinicalVisitService.getByRelated(payload).subscribe((res) => {
      // groupBy subClinicalOrderId
      let data = res.jsonData.sort((a, b) => {
        if (a.visitId == b.visitId) {
          if (a.subClinicalOrderId == b.subClinicalOrderId) {
            return a.status.localeCompare(b.status);
          } else return a.subClinicalOrderId.localeCompare(b.subClinicalOrderId);
        } else return a.visitId.localeCompare(b.visitId);
      });
      this.subClinicalVisitSource = JSON.parse(JSON.stringify(data));
      console.log('subClinicalVisitSource', this.subClinicalVisitSource)
      let j = 0;
      new Promise((resolve, reject) => {
        for (let i = 0; i < data.length; i++) {
          data[i].isOtherVisit = false;
          if (data[i].visitId != this.visit.id) data[i].isOtherVisit = true;
          /// nếu ko là người tạo hoặc status = đã hủy
          data[i].canRemove = false;
          data[i].canDiscount = false;
          if (
            data[i].status == Constant.SubclinicalVisitHL7Status.IP ||
            data[i].status == Constant.SubclinicalVisitHL7Status.SC
          ) {
            data[i].canRemove = true;
            data[i].canDiscount = true;
          }
          if (data[i].status == Constant.SubclinicalVisitHL7Status.ER) {
            data[i].canRemove = true;
          }

          data[i].canEdit = false;
          if (data[i].visitId == this.visit.id) {
            data[i].canEdit = true;
          }

          data[i].isDisabled = false;
          if (
            data[i].status == Constant.SubclinicalVisitHL7Status.CA ||
            data[i].status == Constant.SubclinicalVisitHL7Status.ER
          ) {
            data[i].isDisabled = true;
          }

          if (data[i].discount == null) data[i].discount = 0;
          if (data[i].amount == 0) data[i].amount = 1;

          data[i].ids = [data[i].id];
          data[i].originalFee = data[i].price * data[i].amount;
          data[i].finalFee = data[i].originalFee - data[i].discount;
          /// gộp 2 subclincalVisit cùng OrderId, cùng status
          if (i > 0) {
            if (
              data[i].subClinicalOrderId == data[j].subClinicalOrderId &&
              data[i].status == data[j].status &&
              data[i].doctorId == data[j].doctorId &&
              data[i].discount == data[j].discount
            ) {
              data[j].amount += data[i].amount;
              data[j].finalFee += data[i].finalFee;
              data[j].discount += data[i].discount;
              data[j].canEdit = data[i].canEdit;
              data[j].ids = [...data[j].ids, data[i].id];
            } else {
              j++;
              data[j] = data[i];
            }
          }
        }
        resolve(data);
      }).then((res) => {
        this.subClinicalVisitCustomSource = JSON.parse(JSON.stringify(data.slice(0, j + 1)));
        this.subClinicalVisit = JSON.parse(JSON.stringify(data.slice(0, j + 1)));

        // filter chỉ định Đã hủy hoặc lỗi
        this.subClinicalVisit = this.subClinicalVisit.filter((en) => en.status != this.cancelSubclinicalStatus);
        console.log('this.subClinicalVisit', this.subClinicalVisit);
        this.subClinicalVisitModal = this.subClinicalVisit.filter((en) => en.isDisabled == false);
        this.outSubClinicalVisit.emit(this.subClinicalVisitModal);
      });
    });
  }

  getMedicalOrderByVisitId() {
    this.medicalInstructionService.getByVisitId(this.visit.id).subscribe((res) => {
      this.commands = res;
    });
  }

  onTypingICD(value: string) {
    this.keywordICD = value;
    this.callBackFnICD();
  }

  fieldSelectedICD10(diseases): void {
    if (!this.visit.diseases) {
      this.visit.diseases = [];
    }
    if (this.visit.diseases.filter((en) => en.id === diseases.id).length == 0) {
      this.visit.diseases.push(diseases);
      //save disease
      const payload = {
        Id: diseases.id,
        VisitId: this.visit.id,
        Name: diseases.name,
      };
      this.visitService.saveDiseases(payload).subscribe(
        (res) => {
          if (res !== null) {
          }
        },
        (error) => {
          //  this.messageError = Constant.LOGIN_FAIL;
        }
      );
    }
    this.suggestDiseases = '';
  }

  onInputICD10() {
    const keyword = this.keywordICD.trim();

    if (keyword.length > 1) {
      this.visitService.getDiseasesData(keyword).subscribe((data) => {
        this.diseasesData = data;
      });
    }
  }

  onTypingConclusion(value: string) {
    this.callBackFnConclusion();
  }
  onTypingDiagnoseInit(value: string) {
    this.callBackFnDiagnoseInit();
  }

  onTypingClinicalInfo(value: string) {
    this.callBackFnClinicalInfo();
  }
  clickTosaveVisitInfo(info) {
    if (this.disabledEditInfo) return;
    if (info == 'clinicalInfomation') {
      this.updateClinicalInfo('', true);
    } else if (info == 'conclusion') {
      this.updateConclusion('', true);
    } else if (info == 'diagnoseInit') {
      this.updateDiagnoseInit('diagnoseInit', true);
    }
  }
  opeUpdateStatus() {
    this.showUpdateStatus = true;
  }

  // showModalForm(type, data) {
  //   if (type == 'add') {
  //     this.isAddOder = true;
  //     this.isVisibleForm = true;
  //     this.currentOrder = {
  //       medicalOrderId: '',
  //       id: '',
  //       name: '',
  //       desc: '',
  //       executorId: '',
  //       executorName: '',
  //       executeDate: null,
  //       otherInfo: '',
  //       status: 1,
  //       statusText: '',
  //       creatorName: '',
  //       creatorId: '',
  //       orderResponse: [],
  //     };
  //   } else if (type == 'updateStatus' || type == 'edit' || type == 'view') {
  //     this.isAddOder = false;
  //     this.currentOrder = {
  //       medicalOrderId: data.medicalOrderId,
  //       id: data.id,
  //       name: data.name,
  //       desc: data.desc,
  //       executorId: data.executorId,
  //       executorName: data.executorName,
  //       executeDate: data.executeDate,
  //       otherInfo: data.otherInfo,
  //       status: data.status,
  //       statusText: data.statusText,
  //       creatorName: data.creatorName,
  //       creatorId: data.creatorId,
  //       orderResponse: data.orderResponse,
  //     };
  //     if (type == 'updateStatus') {
  //       this.showUpdateStatus = true;
  //       this.formUpdateStatus = {
  //         id: data.id,
  //         status: 0,
  //         executeDate: null,
  //         otherInfo: '',
  //       };
  //     } else if (type == 'edit') {
  //       this.isVisibleForm = true;
  //     } else if (type == 'view') {
  //       this.isVisibleView = true;
  //     }
  //   }
  // }

  updateClinicalInfo(clinicalInfo?: string, showNotification = false) {
    var clinicalInfo = this.visit.clinicalInfomation;
    if (clinicalInfo != '') {
      const payload = { visitId: this.visit.id, clinicalInfo };
      this.visitService.updateClinicalInfo(payload).subscribe((res) => {
        showNotification
          ? this.notificationService.showNotification(Constant.SUCCESS, 'Cập nhật triệu chứng lâm sàng thành công')
          : null;
      });
    } else {
      this.notificationService.showNotification('info', 'Triệu chứng lâm sàng không được để trống!');
    }
  }

  updateConclusion(clinicalInfo?: string, showNotification = false) {
    const conclusion = this.visit.conclusion;
    if (conclusion != null) {
      const payload = { visitId: this.visit.id, conclusion: conclusion.trim() };
      this.visitService.updateConclusion(payload).subscribe((res) => {
        showNotification
          ? this.notificationService.showNotification(Constant.SUCCESS, 'Cập nhật kết luận thành công')
          : null;
      });
    }
  }
  updateDiagnoseInit(clinicalInfo?: string, showNotification = false) {
    const diagnoseInit = this.visit.diagnoseInit;
    if (diagnoseInit != null) {
      const payload = { visitId: this.visit.id, diagnoseInit: diagnoseInit.trim() };
      this.visitService.updateVisitDiagnoseInit(payload).subscribe((res) => {
        showNotification
          ? this.notificationService.showNotification(Constant.SUCCESS, 'Cập nhật chẩn đoán ban đầu thành công')
          : null;
      });
    }
  }

  removeDiseases(event: any, diseaseId) {
    this.visit.diseases = this.visit.diseases.filter((en) => en.id !== diseaseId);
    const payload = { Id: diseaseId, VisitId: this.visit.id };

    this.visitService.removeDiseases(payload).subscribe(
      (res) => {
        if (res !== null) {
          console.log(res);
        }
      },
      (error) => {
        //  this.messageError = Constant.LOGIN_FAIL;
      }
    );
  }

  loadData() {
    this.getSubClinicalVisitByRelated();
    // this.getMedicalOrderByVisitId();
  }

  showModalAddSubOrder(title) {
    this.rosIdSelected = this.rosIdConfig.Laboratory;
    if (title == this.rosConstant.Imaging) {
      this.rosIdSelected = this.rosIdConfig.Imaging;
    } else if (title == this.rosConstant.Laboratory) {
      this.rosIdSelected = this.rosIdConfig.Laboratory;
    }
    this.showAddSubOrder = true;
    this.subClinicalVisitModal = this.subClinicalVisit.filter((en) => en.isDisabled == false);
  }

  cancelModalAddSubOrder() {
    this.showAddSubOrder = false;
    this.rosIdSelected = '';
    this.subClinicalVisitModal = [];
  }

  switchStatus(data) {
    if (this.editingFlag || this.disabledEditInfo || !data.canEdit) {
      data.isEditing = false;
    } else {
      data.isEditing = !data.isEditing;
      this.editingFlag = data.isEditing;
      this.editingFlagChange.emit(data.isEditing);
    }
  }

  updateAmount(elementId, id) {
    let data = this.subClinicalVisit.find((en) => en.id == id);
    if (elementId != 'sub-' + data.id && elementId != 'memu-context') {
      data.isEditing = false;
      this.editingFlag = false;
      this.editingFlagChange.emit(false);

      let oldAmount = this.subClinicalVisitCustomSource.find((en) => en.id == data.id).amount;
      if (data.amount < 0) data.amount = oldAmount;
      else if (data.amount > oldAmount) {
        this.updateSubclinicalVisit(data);
      } else if (data.canRemove && data.canEdit) {
        this.cancelSubclinicalVisit(oldAmount, data);
      } else data.amount = oldAmount;
    }
  }

  serviceCanceledEvent(data) {
    let oldAmount = data.amount;
    let curAmount = data.amount - 1;
    if (curAmount <= 0) {
      this.confirmCanceled(data);
    }
    /// giảm SL => hủy chỉ định
    else if (curAmount < oldAmount) {
      data.amount -= 1;
      this.confirmReduceAmount(data);
    }
  }

  cancelSubclinicalVisit(oldAmount, data) {
    let curAmount = data.amount;
    if (curAmount <= 0) {
      this.modalService.warning({
        nzTitle: 'Cảnh báo!',
        nzContent:
          'Số lượng phải lớn hơn 0. Nếu bạn muốn hủy tất cả chỉ định, hãy click chuột phải và chọn "Hủy chỉ định"',
        nzOnOk: () => {
          data.amount = oldAmount;
        },
      });
    }
    /// giảm SL => hủy chỉ định
    else if (curAmount < oldAmount) {
      this.confirmReduceAmount(data);
    }
  }

  confirmCanceled(data) {
    this.modalService.confirm({
      nzTitle: 'Xác nhận',
      nzContent: 'Bạn có thực sự muốn hủy chỉ định <b>' + data.name + '</b> hay không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Đóng',
      nzOnOk: () => {
        this.apiDeleteMulti(data);
      },
      nzOnCancel: () => {
        this.subClinicalVisit = JSON.parse(JSON.stringify(this.subClinicalVisitCustomSource));
      },
    });
  }

  confirmReduceAmount(data) {
    this.modalService.confirm({
      nzTitle: 'Xác nhận',
      nzContent:
        'Bạn có muốn giảm số lượng chỉ định <b>' + data.name + '</b> xuống <b>' + data.amount + '</b> hay không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Đóng',
      nzOnOk: () => {
        this.updateSubclinicalVisit(data);
      },
      nzOnCancel: () => {
        this.subClinicalVisit = JSON.parse(JSON.stringify(this.subClinicalVisitCustomSource));
        this.subClinicalVisitModal = this.subClinicalVisit.filter((en) => en.isDisabled == false);
      },
    });
  }

  apiDeleteMulti(data) {
    let payload = { ids: data.ids };
    this.subClinicalVisitService.deleteMulti(payload).subscribe((res) => {
      if (res.isValid) {
        this.getSubClinicalVisitByRelated();
        this.titleTabChange();

        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, Constant.MESSAGE_UPDATE_SUCCESS);
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  updateSubclinicalVisit(data) {
    let subclinicalVisits = this.subClinicalVisitCustomSource.filter((en) => {
      delete en.ids;
      return en.subClinicalOrderId == data.subClinicalOrderId;
    });
    let payload = {
      visitId: this.visit.id,
      subClinicalId: data.subClinicalOrderId,
      status: data.status,
      subclinicalVisits: subclinicalVisits,
      amount: data.amount,
    };
    this.subClinicalVisitService.updateSubclinicalVisit(payload).subscribe((res) => {
      if (res.isValid) {
        this.getSubClinicalVisitByRelated();
        this.titleTabChange();
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật chỉ định thành công!');
      } else if (res.errors && res.errors[0].errorMessage == 'REFRESH') {
        this.showWarningRefresh();
      } else {
        let oldData = this.subClinicalVisitCustomSource.find((en) => en.id == data.id);
        data.amount = oldData.amount;
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  showWarningRefresh() {
    this.modalService.warning({
      nzTitle: 'Cảnh báo',
      nzContent: 'Trạng thái chỉ định đã được thay đổi, bạn vui lòng refresh lại danh sách chỉ định',
      nzOkText: 'Refresh',
      nzOnOk: () => {
        this.getSubClinicalVisitByRelated();
      },
    });
  }

  showModalSubOrderGroup() {
    this.visibleSubOrderGroupAdd = true;
  }

  addSubOrder(id) {
    if (this.shareService.checkEmpty(id)) {
      return;
    }
    let subClinicalOrders = [
      {
        subClinicalOrderId: id,
        amount: 1,
      },
    ];
    this.apiAddOrder(subClinicalOrders);
  }

  apiAddOrder(subClinicalOrders) {
    const payload = {
      visitId: this.visit.id,
      subClinicalOrders: subClinicalOrders,
    };
    this.subClinicalVisitService.addSubClinicalVisit(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật chỉ định thành công!');
        this.titleTabChange();
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  getSubOrder() {
    this.subclinicalorderService.filterByWeight().subscribe((data) => {
      this.subOrderSource = data;
    });
  }

  titleTabChange() {
    this.titleTabRefresh.emit(Constant.TabTitleInsideVisitDetail.All);
  }

  openDiscount(data) {
    console.log('DATA', data)
    this.currentOrdersDiscount = [];
    data.ids.forEach((id) => {
      let item = this.subClinicalVisitSource.find((en) => en.id == id);
      if (item.discountAmount && item.discountAmount > 0) item.withPercent = false;
      else item.withPercent = true;
      if (item.amount == 0) item.amount = 1;
      item.originalFee = item.price * item.amount;
      item.finalFee = item.originalFee - item.discount;
      this.currentOrdersDiscount.push(item);
      console.log('currentOrdersDiscount', this.currentOrdersDiscount)
    });
    this.isSubTable = false;
    this.visibleDiscount = true;
  }
  openDiscountExploreMore(data) {
    console.log('DATA', data);
    this.currentOrdersDiscount = [{
        name: data.serviceName,
        visitId: data.visitId,
        discountAmount: data.discountAmount,
        discountPercent: data.discountPercent,
        servicePrice: data.servicePrice,
    }];

    // Thêm logic kiểm tra if-else giống như trong openDiscount
    let item = this.currentOrdersDiscount[0];
    if (item.discountAmount && item.discountAmount > 0) item.withPercent = false;
    else item.withPercent = true;
    if (item.amount == 0) item.amount = 1;
    item.originalFee = item.servicePrice * (item.amount || 1);
    item.finalFee = item.originalFee - item.discountAmount;

    console.log('currentOrdersDiscount', this.currentOrdersDiscount);

    this.isSubTable = true;
    this.visibleDiscount = true;
}
  // openDiscountExploreMore(data) {
  //   console.log('DATA', data)
  //   this.currentOrdersDiscount = [{
  //     name: data.serviceName,
  //     visitId: data.visitId,
  //     discountAmount: data.discountAmount,
  //     discountPercent: data.discountPercent,
  //     servicePrice: data.servicePrice
  //   }];
  //   console.log(this.currentOrdersDiscount);

  //   this.isSubTable = true;
  //   this.visibleDiscount = true;
  // }
  // discountEvent(discount) {
  //   this.getSubClinicalVisitByRelated();
  // }
  discountEvent(discount) {
    if(this.isSubTable) {
      this.getRelatedVisit()
    } else {
      this.getSubClinicalVisitByRelated()
    } 
    }
  closeModalDiscount() {
    this.currentOrdersDiscount = [];
    this.visibleDiscount = false;
  }
  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent, data): void {
    if (this.editingFlag || this.disabledEditInfo || !data.canEdit) {
      $event.preventDefault();
      return;
    }
    this.nzContextMenuService.create($event, menu);
    this.seletedSubVisit = data;
  }

  contextMenu2($event: MouseEvent, menu: NzDropdownMenuComponent, data): void {
    /*if (this.editingFlag || this.disabledEditInfo || !data.canEdit) {
      $event.preventDefault();
      return;
    }*/
    this.nzContextMenuService.create($event, menu);
    this.seletedRelatedVisit = data;
    console.log('seletedRelatedVisit222', this.seletedRelatedVisit)
  }

  showAddService() {
    this.visibleAddService = true;
    this.objService = {
      serviceId: '',
      roomId: '',
    };
  }

  closeAddService() {
    this.visibleAddService = false;
  }

  addService() {
    let service = this.services.find((en) => en.id == this.objService.serviceId);
    let room = this.rooms.find((en) => en.id == this.objService.roomId);
    if (service == null || room == null) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Vui lòng nhập các trường bắt buộc!');
      return;
    }
    var exist = this.lstRelatedVisit.find((en) => en.serviceId == service.id);
    if (exist != null) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Không được thêm trùng dịch vụ!');
      return;
    }
    let refVisitId = this.visit.id;
    if (!this.shareService.checkEmpty(this.visit.refVisitId)) {
      refVisitId = this.visit.refVisitId;
    }
    let payload = {
      refVisitId: refVisitId,
      personId: this.visit.patientId,
      groupId: this.visit.groupId,
      visitDate: this.shareService.getToday(),
      reason: service.name,
      roomPmrId: room.id,
      roomName: room.name,
      serviceId: service.id,
    };
    this.visitService.addExtraVisit(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, Constant.MESSAGE_ADD_SUCCESS);
        this.visibleAddService = false;
        let newItem = {
          refVisitId: refVisitId,
          visitId: res.jsonData.id,
          serviceId: service.id,
          serviceName: service.name,
          servicePrice: res.jsonData.servicePrice,
        };
        this.lstRelatedVisit = [...this.lstRelatedVisit, ...[newItem]];
        this.lstRelatedVisitDisplay = [...this.lstRelatedVisitDisplay, ...[newItem]];
        this.lstRelatedVisitChange.emit(this.lstRelatedVisitDisplay);
        this.isShowRelatedVisit = true;
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  openRelatedVisit(data) {
    this.visitIdChange.emit(data.visitId);
  }

  getRelatedVisit() {
    let payload = {
      visitId: this.visit.id,
      refVisitId: this.visit.refVisitId,
    };
    this.visitService.getRelatedVisit(payload).subscribe((res) => {
      if (res.isValid) {
        let data = res.jsonData.sort((a, b) => {
          if (a.visitId == b.visitId) {
            if (a.subClinicalOrderId == b.subClinicalOrderId) {
              return a.status.localeCompare(b.status);
            } else return a.subClinicalOrderId.localeCompare(b.subClinicalOrderId);
          } else return a.visitId.localeCompare(b.visitId);
        });
        this.subClinicalVisitSource.push(...JSON.parse(JSON.stringify(data)));
        console.log('subClinicalVisitSource2', this.subClinicalVisitSource)
        this.lstRelatedVisit = res.jsonData;
        this.lstRelatedVisitDisplay = res.jsonData.filter((en) => en.visitId != this.visit.id);
        this.isShowRelatedVisit = this.lstRelatedVisitDisplay.length > 0 ? true : false;
        this.lstRelatedVisitChange.emit(this.lstRelatedVisitDisplay);
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  searchExaminationService(keyword) {
    if (this.shareService.checkEmptyStr(keyword) && this.services.length != 0) return;
    clearTimeout(this.timerSearchService);
    this.timerSearchService = setTimeout(() => {
      let payload = {
        take: 20,
        skip: 0,
        keyword: keyword,
        groupId: this.groupId,
      };
      this.visitService.searchExaminationService(payload).subscribe((res) => {
        if (res.isValid) {
          this.services = res.jsonData.data;
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    }, 500);
  }

  notPrintAllChange($event): void {
    if ($event) {
      this.subClinicalVisit.forEach((en) => {
        en.notPrint = true;
      });
    } else {
      this.subClinicalVisit.forEach((en) => {
        en.notPrint = false;
      });
    }
  }

  notPrintChildChange($event: boolean) {
    if (!$event) {
      this.notPrintAll = false;
    } else {
      this.notPrintAll = true;
      this.subClinicalVisit.forEach((en) => {
        if (!en.notPrint) {
          this.notPrintAll = false;
        }
      });
    }
  }

  notPrintAllChange2($event): void {
    if ($event) {
      this.lstRelatedVisitDisplay.forEach((en) => {
        en.notPrint = true;
      });
    } else {
      this.lstRelatedVisitDisplay.forEach((en) => {
        en.notPrint = false;
      });
    }
    this.visitService.storeVisit(this.lstRelatedVisitDisplay);
  }

  notPrintChildChange2($event: boolean) {
    if (!$event) {
      this.notPrintAll2 = false;
    } else {
      this.notPrintAll2 = true;
      this.lstRelatedVisitDisplay.forEach((en) => {
        if (!en.notPrint) {
          this.notPrintAll2 = false;
        }
      });
    }
    this.visitService.storeVisit(this.lstRelatedVisitDisplay);
  }

  copyDiagnoseInit() {
    if (this.visit.conclusion) {
      this.visit.conclusion = this.visit.conclusion + ' ' + this.visit.diagnoseInit;
    } else {
      this.visit.conclusion = this.visit.diagnoseInit;
    }
    this.nzMessageService.success('Đã copy chẩn đoán ban đầu!');
    if (this.visit.conclusion != null) {
      const payload = { visitId: this.visit.id, conclusion: this.visit.conclusion.trim() };
      this.visitService.updateConclusion(payload).subscribe((res) => { });
    }
  }

  confirmCanceledRelate() {
    this.modalService.confirm({
      nzTitle: 'Xác nhận',
      nzContent: 'Bạn có thực sự muốn hủy chỉ định <b>' + this.seletedRelatedVisit.serviceName + '</b> hay không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Đóng',
      nzOnOk: () => {
        this.visitService.deleteVisitRelated(this.seletedRelatedVisit.visitId).subscribe((res) => {
          if (res.isValid) {
            this.getRelatedVisit();
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, Constant.MESSAGE_UPDATE_SUCCESS);
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
          }
        });
      },
      nzOnCancel: () => {

      },
    });
  }

  showVisitOtherInfo() {
    this.visitOtherInfoPopupData = { ...this.visitOtherInfoPopupData, isVisible: true, data: this.visit }
  }
  showModalAddVS(selectedItem) {
    this.isVisibleVS = true;
    console.log('HELLO')
    // this.triggerVitalAsign.setListObservation(this.visit.id, this.lstObservationType, null, this.visit.patientId);
    this.getListObservationVS(); 76
  }
  closeAddVitalSigns() {
    this.isVisibleVS = false;
  }
  getListObservationVS() {
    this.lstObservationVS = [];
    console.log('HALLO')
    const sendObser = { visitId: this.visit.id, observationCategory: -1 };
    this.observationService.getObservationVS(sendObser).subscribe((response) => {
      this.lstObservationType = response;
      const lst = this.lstObservationType.filter((en) => en.value != null && en.id != '');
      lst.forEach((element) => {
        this.lstObservationVS.push(element);
      });
      this.lstObservationType.forEach((element) => {
        if (element.dataType == Constant.DATATYPENUMBER.SINGLECHOICE) {
          if (element.valueSelections == null) {
            element.valueSelections = [];
          }
        }
      });
      this.triggerVitalAsign.setListObservation(this.visit.id, this.lstObservationType, null, this.visit.patientId);

    });
  }
  addVitalSignsEvent(): void {
    //update observation
    this.isVisibleVS = false;
    console.log('HALLO')
    this.getListObservationVS();
    this.vitalSign.emit();
  }
}
