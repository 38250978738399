<nz-modal
  [nzVisible]="isVisible"
  [nzWidth]="1100"
  (nzOnCancel)="confirmCloseAddModal()"
  nzClassName="calculated-modal">
  <div *nzModalTitle>Nhập chỉ số tính toán</div>
  <div *nzModalContent>
    <div class="row">
      <div class="col-lg-6 right-divided">
        <div class="input-wrapper mg-t-10">
          <label class="fix-label asterisk-icon"> Tên chỉ số tính toán </label>
          <input
            nz-input
            [(ngModel)]="name"
            (ngModelChange)="emptyName()"
            [ngClass]="isNameError ? 'error' : ''" />
          <!-- <span class="required-elm" style="padding-left: 30px;">
            Bạn chưa nhập tên
          </span> -->
        </div>
        <div class="input-wrapper">
          <label class="fix-label asterisk-icon">
            Observation Type (Option)
          </label>
          <!-- <input nz-input [(ngModel)]="name" (ngModelChange)="emptyName()" [ngClass]= "isNameError ? 'error': ''" /> -->
          <nz-select
            nzShowSearch
            nzServerSearch
            [(ngModel)]="observationTypeReferId"
            [nzShowArrow]="false"
            [nzFilterOption]="true"
            (nzOnSearch)="getListObservationTypeRefer($event)"
            style="width: calc(100% - 40px); margin: 0px 20px">
            <nz-option
              *ngFor="let option of lstobservationTypeRefer"
              [nzLabel]="option.name + ' (' + option.oTcode + ')'"
              [nzValue]="option.id"></nz-option>
          </nz-select>
        </div>
        <nz-collapse>
          <nz-collapse-panel
            [nzHeader]="
              'Danh sách tham số' + ' (' + this.variables.length + ')'
            "
            [nzActive]="panels[0]">
            <div
              class="list-variables"
              style="height: 160px; overflow: auto"
              #listVariables>
              <div
                class="variable-box"
                *ngFor="let variable of variables; let i = index">
                <div class="info-label" [title]="variable.sourceName">
                  <i
                    *ngIf="variable.type == constant.VARIABLE_TYPE.COMMON_INFO"
                    title="Common Info"
                    style="margin-bottom: -8px"
                    class="common-info-icon-sm"></i>
                  <i
                    *ngIf="variable.type == constant.VARIABLE_TYPE.OBSERVAION"
                    title="Observation Type"
                    style="margin-bottom: -8px"
                    class="observation-type-icon-sm"></i>
                  <i
                    *ngIf="
                      variable.type ==
                      constant.VARIABLE_TYPE.OBSERVAION_MOST_RECENT
                    "
                    title="Observation Type gần nhất"
                    style="font-size: 24px; margin-bottom: -8px"
                    nz-icon
                    nzType="field-time"
                    nzTheme="outline"></i>
                  <div class="txt-ellipsis" style="width: 300px">
                    {{ variable.sourceName }}
                  </div>
                  <span class="data-type"
                    >({{ variable.dataType | observationDataTypeName }})</span
                  >
                </div>
                <a
                  ><span class="close-icon" (click)="removeVariable(variable)"
                    >X</span
                  ></a
                >
                <div class="row">
                  <div class="col-md-6 input-wrapper">
                    <label class="fix-label asterisk-icon">Tên tham số</label>
                    <input
                      nz-input
                      [(ngModel)]="variable.name"
                      (ngModelChange)="checkVariableName(variable, i)" />
                  </div>
                  <div class="col-md-6 input-wrapper">
                    <label class="fix-label asterisk-icon">Giá trị test</label>
                    <nz-input-number
                      *ngIf="
                        variable.dataType == constant.DATATYPENUMBER.NUMBER
                      "
                      [(ngModel)]="variable.value"
                      style="width: calc(100% - 80px); margin: 0px 20px">
                    </nz-input-number>
                    <input
                      nz-input
                      *ngIf="
                        variable.dataType == constant.DATATYPENUMBER.STRING
                      "
                      [(ngModel)]="variable.value"
                      (ngModelChange)="typeValue(variable)"
                      style="width: calc(100% - 80px); margin: 0px 20px" />
                    <nz-date-picker
                      style="width: calc(100% - 40px)"
                      *ngIf="
                        variable.dataType == constant.DATATYPENUMBER.DATETIME
                      "
                      [(ngModel)]="variable.value">
                    </nz-date-picker>
                    <div
                      *ngIf="variable.isError"
                      [title]="variable.error"
                      class="error-icon">
                      <i nz-icon nzType="close-circle" nzTheme="outline"></i>
                      Chưa nhập giá trị cho {{ variable.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nz-collapse-panel>
          <nz-collapse-panel
            [nzHeader]="'Biếu thức tính toán'"
            [nzActive]="panels[1]">
            <div class="input-wrapper" style="position: relative">
              <label class="fix-label asterisk-icon"
                >Nội dung biểu thức tính toán</label
              >
              <div #backdrop class="backdrop">
                <div class="highlights" [innerHTML]="scriptHtml"></div>
              </div>
              <textarea
                #scriptArea
                spellcheck="false"
                [ngClass]="isScriptError ? 'error' : ''"
                (keyup)="getCaretPos($event, scriptArea)"
                rows="4"
                nz-input
                [(ngModel)]="script"
                (ngModelChange)="syncScript()"
                (scroll)="handleScroll()"></textarea>
              <app-recommend-context
                [(isVisible)]="isVisibleRecommendMenu"
                [variables]="recommentVariables"
                [coordinate]="menuCoordinate"
                (selectVariableEvent)="addVariableToScript($event)">
              </app-recommend-context>
              <div *ngIf="isScriptError" class="error-icon">
                <i nz-icon nzType="close-circle" nzTheme="outline"></i>
                Chưa nhập biểu thức tính toán
              </div>
            </div>
            <div class="btn-flex-end mg-bt-10">
              <button
                nz-button
                class="btn-primary-medium"
                (click)="checkFormula()">
                Kiểm tra
              </button>
            </div>
            <nz-collapse>
              <nz-collapse-panel
                [nzHeader]="'Kết quả test'"
                [nzActive]="panels[2]">
                <app-test-result
                  *ngFor="let result of testResults"
                  [result]="result"></app-test-result>
              </nz-collapse-panel>
            </nz-collapse>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
      <div class="col-lg-6">
        <div>
          <nz-tabset class="tabset-default">
            <nz-tab [nzTitle]="'Common Info'">
              <nz-input-group [nzPrefix]="prefixTemplateSearch">
                <input
                  type="text"
                  [(ngModel)]="search_KW_CI"
                  (ngModelChange)="search_KW_CIChange()"
                  nz-input
                  placeholder="Tìm kiếm..." />
              </nz-input-group>
              <div class="tab-wrapper">
                <nz-list nzBordered nzSize="small">
                  <nz-list-item *ngFor="let item of lstFilterCI">
                    {{ item.title }}
                    <ul nz-list-item-actions>
                      <nz-list-item-action>
                        <a
                          (click)="
                            addVariable(
                              item,
                              constant.VARIABLE_TYPE.COMMON_INFO
                            )
                          "
                          title="Thêm biến">
                          <i nz-icon nzType="plus"></i>
                        </a>
                      </nz-list-item-action>
                    </ul>
                  </nz-list-item>
                </nz-list>
              </div>
              <ng-template #commonInfoTitle>
                <div title="Common Info">
                  <i class="common-info-icon-md"></i>
                </div>
              </ng-template>
            </nz-tab>
            <nz-tab [nzTitle]="'Observation Type'">
              <nz-input-group [nzPrefix]="prefixTemplateSearch">
                <input
                  type="text"
                  [(ngModel)]="search_KW_OT"
                  (ngModelChange)="search_KW_OTChange()"
                  nz-input
                  placeholder="Tìm kiếm..." />
              </nz-input-group>
              <div class="tab-wrapper">
                <nz-list
                  nzBordered
                  nzSize="small"
                  [nzLoading]="isSpinningSearchOT">
                  <nz-list-item *ngFor="let item of lstObservationType">
                    <div>
                      {{ item.name }} (<i> {{ item.oTcode }}</i
                      >)
                    </div>
                    <div style="display: flex">
                      <!-- <span style="float: right; font-style: italic;">({{item.oTcode}})</span> -->
                      <ul nz-list-item-actions>
                        <nz-list-item-action>
                          <a
                            (click)="
                              addVariable(
                                item,
                                constant.VARIABLE_TYPE.OBSERVAION
                              )
                            "
                            title="Thêm biến">
                            <i nz-icon nzType="plus"></i>
                          </a>
                        </nz-list-item-action>
                      </ul>
                    </div>
                  </nz-list-item>
                </nz-list>
              </div>
            </nz-tab>
            <nz-tab [nzTitle]="'Observation Type gần nhất'">
              <nz-input-group [nzPrefix]="prefixTemplateSearch">
                <input
                  type="text"
                  [(ngModel)]="search_KW_OT_Recent"
                  (ngModelChange)="search_KW_OTRecentChange()"
                  nz-input
                  placeholder="Tìm kiếm..." />
              </nz-input-group>
              <div class="tab-wrapper">
                <nz-list
                  nzBordered
                  nzSize="small"
                  [nzLoading]="isSpinningSearchOTRecent">
                  <nz-list-item *ngFor="let item of lstObservationTypeRecent">
                    <div>
                      {{ item.name }} (<i> {{ item.oTcode }}</i
                      >)
                    </div>
                    <div style="display: flex">
                      <!-- <span style="float: right; font-style: italic;">({{item.oTcode}})</span> -->
                      <ul nz-list-item-actions>
                        <nz-list-item-action>
                          <a
                            (click)="
                              addVariable(
                                item,
                                constant.VARIABLE_TYPE.OBSERVAION_MOST_RECENT
                              )
                            "
                            title="Thêm biến">
                            <i nz-icon nzType="plus"></i>
                          </a>
                        </nz-list-item-action>
                      </ul>
                    </div>
                  </nz-list-item>
                </nz-list>
              </div>
            </nz-tab>
          </nz-tabset>
        </div>
        <nz-collapse>
          <nz-collapse-panel
            [nzHeader]="'Hướng dẫn sử dụng'"
            [nzActive]="panels[2]">
            <div class="tab-wrapper" [innerHtml]="projectDescription"></div>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button
      nz-button
      class="btn-primary-medium"
      (click)="submitCalculated()"
      [nzLoading]="isLoading">
      Lưu lại
    </button>
  </div>
</nz-modal>

<ng-template #prefixTemplateSearch><i nz-icon nzType="search"></i></ng-template>

<nz-modal
  [(nzVisible)]="isConfirmSaveVisible"
  [nzBodyStyle]=""
  nzTitle="Công thức đã thay đổi"
  [nzWidth]="270"
  (nzOnCancel)="isConfirmSaveVisible = false">
  <div>Bạn có muốn lưu thay đổi?</div>
  <div *nzModalFooter>
    <button
      nz-button
      nzType="default"
      nzDanger
      (click)="isConfirmSaveVisible = false">
      Hủy
    </button>
    <button nz-button nzType="primary" nzDanger (click)="closeAddModal()">
      Không lưu
    </button>
    <button nz-button nzType="primary" (click)="saveAndClose()">Lưu</button>
  </div>
</nz-modal>
