import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prescrriptionDay',
})
export class PrescrriptionDay implements PipeTransform {
  transform(date: any) {
    return this.getDayMonthYear(date);
  }

  getDayMonthYear(dateStr) {
    let dateObj;
    if (dateStr == undefined) {
      dateObj = new Date();
    } else {
      dateObj = new Date(dateStr);
    }
    let str = '';
    str += 'Ngày ' + dateObj.getDate() + ' tháng ';
    str += dateObj.getMonth() + 1 + ' năm ';
    str += dateObj.getFullYear();
    return str;
  }
}
