import { ShareService } from 'src/app/services/share.service';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, Observer, Subscription } from 'rxjs';
import { CloseAddPatient } from '../redux/modal/modal.action';
import { AppState } from '../../app-state/app-state';
import { Store } from '@ngrx/store';
import { ModalState } from '../../../models/modal-item.model';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Constant } from 'src/app/share/constants/constant.class';
import { NotificationService } from 'src/app/services/notification.service';
import { PatientService } from 'src/app/services/patient.service';
import { AppConfigService } from 'src/app-config.service';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStore],
})
export class AddPatientComponent implements OnInit, AfterViewInit {
  isVisibleAdd: Observable<boolean>;
  modals: Observable<ModalState>;
  isVisible: boolean;
  @Output() addPatient: EventEmitter<any> = new EventEmitter();
  @Output() duplicatePatient: EventEmitter<any> = new EventEmitter();
  formAddPatient: FormGroup;
  submitted = false;
  loading = false;
  avatarUrl?: string;
  dateFormat = 'dd/MM/yyyy';
  header?: any = {
    Authorization: 'Bearer ' + localStorage.getItem(Constant.TOKEN),
  };
  listImgs: any[] = [];
  listImgsUrl: any[] = [];
  checkDate: Boolean = false;
  uploadUrl = '';
  uploadFileUrl = '';
  invalidExpireDate = false;
  groupSub: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private patientService: PatientService,
    private configService: AppConfigService,
    private shareService: ShareService,
    private notificationService: NotificationService //private tabDataService: TabDataService,
  ) {
    this.formAddPatient = this.formBuilder.group({
      id: [null],
      address: [''],
      avatar: '',
      BHYTCode: ['', [], [this.validBHYTAsyncValidator]],
      code: '',
      idNumber: [
        null,
        [
          Validators.pattern(/\b\d{9}\b|\b\d{12}\b/),
          Validators.minLength(9),
          Validators.maxLength(12),
        ],
      ], //chuỗi số liền đúng 9 hoặc 12 kí tự
      dob: [null, [Validators.required]],
      fullName: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
        ],
        [this.emptyAsyncValidator],
      ],
      email: [null, [Validators.email]],
      expireDate: [null, [], [this.expireDateAsyncValidator]],
      visitId: '',
      sex: ['', [Validators.required]],
      phoneNo: [
        null,
        [Validators.required, Validators.pattern(/\b^([0])\d{9}\b/)],
      ],
      validDate: [null, [], [this.validDateAsyncValidator]],
      imageUrls: [''],
    });

    this.uploadUrl =
      this.configService.getConfig().api.baseUrl +
      '/api/Upload/uploadFile?TypeUpload=upload_avatar&FileName=1145564.jpg';

    // "/Media/VisitDocument"
    this.uploadFileUrl =
      this.configService.getConfig().api.baseUrl +
      '/api/Upload/uploadFile?TypeUpload=upload_visit_document';
  }
  disabledDateAfterToday = this.patientService.disabledDateAfterToday;
  disabledDateBeforeToday = this.patientService.disabledDateBeforeToday;

  get f() {
    return this.formAddPatient.controls;
  }

  ngOnInit(): void {
    this.modals = this.store.select((store) => store.modals);
    this.isVisibleAdd = this.store.select(
      (store) => store.modals.isPatientOpen
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      $(document).ready(function () {
        $('.ant-upload.ant-upload-drag').css('background-color', '#EDF8FD');
      });
    }, 500);
  }

  handleOk(): void {
    this.submitted = true;
    if (this.formAddPatient.valid && !this.invalidExpireDate) {
      this.submitted = false;
      let groupId = '';
      this.groupSub = this.store.select('groupAuth').subscribe((data) => {
        if (data.isAuthenticated) {
          groupId = data.groupId;
        }
      });
      const payload = {
        fullName: this.formAddPatient.value.fullName,
        idNumber: this.formAddPatient.value.idNumber,
        dob: this.formAddPatient.value.dob,
        dobString: this.shareService.toDDMMYY(this.formAddPatient.value.dob),
        sex: this.formAddPatient.value.sex,
        phoneNo: this.formAddPatient.value.phoneNo,
        groupId,
      };
      if (new Date().getTime() - this.formAddPatient.value.dob < 0) {
        this.checkDate = true;
        this.submitted = false;
        return;
      } else {
        this.patientService.checkDuplicate(payload).subscribe((res) => {
          if (res && res.Errors && res.Errors.length > 0) {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.WARRING,
              res.errors[0].message
            );
          } else {
            this.store.dispatch(new CloseAddPatient());
            if (res.isSuccess) {
              this.addPatient.emit({
                ...this.formAddPatient.value,
                ...{
                  dobString: this.shareService.toDDMMYY(
                    this.formAddPatient.value.dob
                  ),
                },
              });
            } else {
              const form = {
                ...this.formAddPatient.value,
                ...{
                  dobString: this.shareService.toDDMMYY(
                    this.formAddPatient.value.dob
                  ),
                },
              };
              this.duplicatePatient.emit({ form, res });
            }
            this.formAddPatient.reset();
            this.avatarUrl = '';
            this.listImgs = [];
            this.listImgsUrl = [];
            this.checkDate = false;
          }
        });
      }
    }
  }

  handleCancel(): void {
    this.store.dispatch(new CloseAddPatient());
    this.submitted = false;
    this.formAddPatient.reset();
    this.checkDate = false;
    this.listImgs = [];
    this.listImgsUrl = [];
  }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        observer.complete();
        return;
      }
      const isLt2M = (file.size ?? 0) / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Image must smaller than 2MB!'
        );
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng && isLt2M);
      observer.complete();
    });

  beforeUploadFile = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isLt2M = (file.size ?? 0) / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Image must smaller than 10MB!'
        );
        observer.complete();
        return;
      }
      observer.next(isLt2M);
      observer.complete();
    });

  checkNull(value) {
    try {
      value = value.trim();
      if (value == null || value == '' || value == 'undefined') {
        return true;
      }
      return false;
    } catch (e) {
      return true;
    }
  }

  checkNullObject(value) {
    try {
      if (value == null || value == '' || value == 'undefined') {
        return true;
      }
      return false;
    } catch (e) {
      return true;
    }
  }

  handleRemoveImg = (file: any) =>
    new Observable<boolean>((obs) => {
      this.listImgs = this.listImgs.filter(
        (en) => en.response.fileName !== file.response.fileName
      );
      this.listImgsUrl = this.listImgsUrl.filter(
        (en) => en !== file.response.fileName
      );
      obs.next(true);
    });

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.avatarUrl = info.file.thumbUrl;
        this.formAddPatient.controls.avatar.setValue(
          info.file.response.fileName
        );
        break;
      case 'error':
        this.loading = false;
        break;
    }
  }

  fileChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.listImgs.push(info.file);
        this.listImgsUrl.push(info.file.response.fileName);
        this.formAddPatient.controls.imageUrls.setValue(this.listImgsUrl);
        break;
      case 'error':
        this.loading = false;
        break;
    }
  }

  emptyAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.checkNull(control.value)) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, duplicated: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  validBHYTAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (
        this.checkNull(control.value) &&
        this.formAddPatient &&
        this.formAddPatient.value.expireDate
      ) {
        observer.next({ error: true, empty: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  validDateAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.checkNullObject(control.value)) {
        this.invalidExpireDate = false;
        observer.next(null);
      } else {
        if (control.value > new Date()) {
          observer.next({ error: true, validDate: true });
        } else {
          const validExpire =
            this.formAddPatient &&
            (this.checkNullObject(this.formAddPatient.value.expireDate) ||
              control.value < this.formAddPatient.value.expireDate);
          if (!validExpire) {
            this.invalidExpireDate = true;
            observer.next(null);
          } else {
            this.invalidExpireDate = false;
            observer.next(null);
          }
        }
      }
      observer.complete();
    });

  expireDateAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.checkNullObject(control.value)) {
        observer.next(null);
      } else {
        const validExpire =
          this.formAddPatient &&
          (this.checkNullObject(this.formAddPatient.value.validDate) ||
            control.value > this.formAddPatient.value.validDate);
        if (!validExpire) {
          this.invalidExpireDate = true;
          // observer.next({ error: true, validDate: true });
          observer.next(null);
        } else {
          this.invalidExpireDate = false;
          observer.next(null);
        }
      }
      observer.complete();
    });
}
