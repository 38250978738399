<div class="form-dropdown form-elm">
  <nz-select
    [nzMode]="_type"
    [nzPlaceHolder]="data.observationType.name"
    [(ngModel)]="data.value">
    <nz-option
      *ngFor="let item of data.observationType.dataSource"
      [nzLabel]="item.text"
      [nzValue]="item.text">
    </nz-option>
  </nz-select>
</div>
<!-- <div class="active-elm"></div> -->
