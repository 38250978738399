import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class UploadService extends BaseService {
  saveFile(fileToUpload: Blob): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('FormFile', fileToUpload, 'paste.png');
    return this.post(UrlConstant.UPLOAD_VISIT_IMAGE, formData);
  }

  uploadMediaFile(payload): Observable<any> {
    return this.post(UrlConstant.UPLOAD_MEDIA_FILE, payload);
  }

  saveFileElement(payload): Observable<any> {
    return this.post(UrlConstant.REASON_VISIT, payload);
  }
}
