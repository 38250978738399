<div
  class="mydp"
  [ngStyle]="{
    'width': opts.showInputField ? opts.width : null,
    'border': opts.inline ? 'none' : null
  }">
  <div class="selectiongroup" *ngIf="!opts.inline">
    <input
      *ngIf="opts.showInputField"
      #inputBoxEl
      ngtype="text"
      class="selection"
      [attr.aria-label]="opts.ariaLabelInputField"
      (click)="
        opts.openSelectorOnInputClick &&
          !opts.editableDateField &&
          openBtnClicked()
      "
      [ngClass]="{
        'invaliddate': invalidDate && opts.indicateInvalidDate,
        'inputnoteditable':
          opts.openSelectorOnInputClick && !opts.editableDateField,
        'selectiondisabled': opts.componentDisabled
      }"
      placeholder="{{ placeholder }}"
      [ngStyle]="{
        'height': opts.height,
        'font-size': opts.selectionTxtFontSize
      }"
      [ngModel]="selectionDayTxt"
      (ngModelChange)="onUserDateInput($event)"
      [value]="selectionDayTxt"
      (keyup)="onCloseSelector($event)"
      (focus)="opts.editableDateField && onFocusInput($event)"
      (blur)="opts.editableDateField && onBlurInput($event)"
      [disabled]="opts.componentDisabled"
      [readonly]="!opts.editableDateField"
      autocomplete="off"
      spellcheck="false"
      autocorrect="off" />
    <div class="selbtngroup" [style.height]="opts.height">
      <button
        type="button"
        [attr.aria-label]="opts.ariaLabelDecreaseDate"
        class="btndecrease"
        *ngIf="opts.showDecreaseDateBtn"
        (click)="onDecreaseBtnClicked()"
        [ngClass]="{
          'btndecreaseenabled': !opts.componentDisabled,
          'btndecreasedisabled': opts.componentDisabled,
          'btnleftborderradius': !opts.showInputField
        }"
        [disabled]="opts.componentDisabled">
        <span class="mydpicon icon-mydpleft"></span>
      </button>
      <button
        type="button"
        [attr.aria-label]="opts.ariaLabelIncreaseDate"
        class="btnincrease"
        *ngIf="opts.showIncreaseDateBtn"
        (click)="onIncreaseBtnClicked()"
        [ngClass]="{
          'btnincreaseenabled': !opts.componentDisabled,
          'btnincreasedisabled': opts.componentDisabled,
          'btnleftborderradius':
            !opts.showDecreaseDateBtn && !opts.showInputField
        }"
        [disabled]="opts.componentDisabled">
        <span class="mydpicon icon-mydpright"></span>
      </button>
      <button
        type="button"
        [attr.aria-label]="opts.ariaLabelClearDate"
        class="btnclear"
        *ngIf="selectionDayTxt.length > 0 && opts.showClearDateBtn"
        (click)="removeBtnClicked()"
        [ngClass]="{
          'btnclearenabled': !opts.componentDisabled,
          'btncleardisabled': opts.componentDisabled,
          'btnleftborderradius':
            !opts.showIncreaseDateBtn &&
            !opts.showDecreaseDateBtn &&
            !opts.showInputField
        }"
        [disabled]="opts.componentDisabled">
        <span class="mydpicon icon-mydpremove"></span>
      </button>
      <button
        type="button"
        [attr.aria-label]="opts.ariaLabelOpenCalendar"
        class="btnpicker"
        (click)="openBtnClicked()"
        [ngClass]="{
          'btnpickerenabled': !opts.componentDisabled,
          'btnpickerdisabled': opts.componentDisabled,
          'btnleftborderradius':
            (!opts.showClearDateBtn &&
              !opts.showIncreaseDateBtn &&
              !opts.showDecreaseDateBtn &&
              !opts.showInputField) ||
            (selectionDayTxt.length === 0 && !opts.showInputField)
        }"
        [disabled]="opts.componentDisabled">
        <span class="mydpicon icon-mydpcalendar"></span>
      </button>
    </div>
  </div>
  <div
    class="selector"
    #selectorEl
    [ngStyle]="{
      'width': opts.selectorWidth,
      'height': opts.selectorHeight,
      'bottom': getSelectorTopPosition()
    }"
    *ngIf="showSelector || opts.inline"
    [mydpfocus]="opts.inline ? '0' : '1'"
    [ngClass]="{
      'inlinedp': opts.inline,
      'alignselectorright': opts.alignSelectorRight,
      'selectorarrow': opts.showSelectorArrow && !opts.inline,
      'selectorarrowleft':
        opts.showSelectorArrow && !opts.alignSelectorRight && !opts.inline,
      'selectorarrowright':
        opts.showSelectorArrow && opts.alignSelectorRight && !opts.inline
    }"
    (keyup)="onCloseSelector($event)"
    tabindex="0">
    <div style="display: flex">
      <div>
        <table class="header">
          <tr>
            <td>
              <div style="float: left">
                <div class="headerbtncell">
                  <button
                    type="button"
                    [attr.aria-label]="opts.ariaLabelPrevMonth"
                    class="headerbtn mydpicon icon-mydpleft"
                    (click)="onPrevMonth()"
                    [disabled]="prevMonthDisabled"
                    [ngClass]="{
                      'headerbtnenabled': !prevMonthDisabled,
                      'headerbtndisabled': prevMonthDisabled
                    }"></button>
                </div>
                <div class="headermonthtxt">
                  <button
                    class="headerlabelbtn"
                    type="button"
                    [ngClass]="{ 'monthlabel': opts.monthSelector }"
                    (click)="opts.monthSelector && onSelectMonthClicked($event)"
                    tabindex="{{ opts.monthSelector ? '0' : '-1' }}">
                    {{ visibleMonth.monthTxt }}
                  </button>
                </div>
                <div class="headerbtncell">
                  <button
                    type="button"
                    [attr.aria-label]="opts.ariaLabelNextMonth"
                    class="headerbtn mydpicon icon-mydpright"
                    (click)="onNextMonth()"
                    [disabled]="nextMonthDisabled"
                    [ngClass]="{
                      'headerbtnenabled': !nextMonthDisabled,
                      'headerbtndisabled': nextMonthDisabled
                    }"></button>
                </div>
              </div>
            </td>
            <td>
              <button
                *ngIf="opts.showTodayBtn"
                type="button"
                class="headertodaybtn"
                (click)="onTodayClicked()"
                [disabled]="disableTodayBtn"
                [ngClass]="{
                  'headertodaybtnenabled': !disableTodayBtn,
                  'headertodaybtndisabled': disableTodayBtn
                }">
                <span class="mydpicon icon-mydptoday"></span>
                <span>{{ opts.todayBtnTxt }}</span>
              </button>
            </td>
            <td>
              <div style="float: right">
                <div class="headerbtncell">
                  <button
                    type="button"
                    [attr.aria-label]="opts.ariaLabelPrevYear"
                    class="headerbtn mydpicon icon-mydpleft"
                    (click)="onPrevYear()"
                    [disabled]="prevYearDisabled"
                    [ngClass]="{
                      'headerbtnenabled': !prevYearDisabled,
                      'headerbtndisabled': prevYearDisabled
                    }"></button>
                </div>
                <div class="headeryeartxt">
                  <button
                    class="headerlabelbtn"
                    type="button"
                    [ngClass]="{ 'yearlabel': opts.yearSelector }"
                    (click)="opts.yearSelector && onSelectYearClicked($event)"
                    tabindex="{{ opts.yearSelector ? '0' : '-1' }}">
                    {{ visibleMonth.year }}
                  </button>
                </div>
                <div class="headerbtncell">
                  <button
                    type="button"
                    [attr.aria-label]="opts.ariaLabelNextYear"
                    class="headerbtn mydpicon icon-mydpright"
                    (click)="onNextYear()"
                    [disabled]="nextYearDisabled"
                    [ngClass]="{
                      'headerbtnenabled': !nextYearDisabled,
                      'headerbtndisabled': nextYearDisabled
                    }"></button>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <table class="caltable" *ngIf="!selectMonth && !selectYear">
          <thead>
            <tr>
              <th
                class="weekdaytitle weekdaytitleweeknbr"
                *ngIf="opts.showWeekNumbers && opts.firstDayOfWeek === 'mo'">
                #
              </th>
              <th class="weekdaytitle" scope="col" *ngFor="let d of weekDays">
                {{ d }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let w of dates">
              <td
                class="daycell daycellweeknbr"
                *ngIf="opts.showWeekNumbers && opts.firstDayOfWeek === 'mo'">
                {{ w.weekNbr }}
              </td>
              <td
                class="daycell"
                *ngFor="let d of w.week"
                [ngClass]="{
                  'currmonth': d.cmo === currMonthId && !d.disabled,
                  'selectedday':
                    selectedDate.day === d.dateObj.day &&
                    selectedDate.month === d.dateObj.month &&
                    selectedDate.year === d.dateObj.year &&
                    d.cmo === currMonthId,
                  'disabled': d.disabled,
                  'tablesingleday':
                    (!opts.allowSelectionOnlyInCurrentMonth ||
                      (d.cmo === currMonthId &&
                        opts.allowSelectionOnlyInCurrentMonth)) &&
                    !d.disabled
                }"
                (click)="
                  !d.disabled && onCellClicked(d); $event.stopPropagation()
                "
                (keydown)="onCellKeyDown($event, d)"
                [attr.aria-label]="opts.ariaLabelDay + d.dateObj.day"
                tabindex="0">
                <div
                  *ngIf="d.markedDate.marked"
                  class="markdate"
                  [ngStyle]="{ 'background-color': d.markedDate.color }"></div>
                <div
                  class="datevalue"
                  [ngClass]="{
                    'prevmonth': d.cmo === prevMonthId,
                    'currmonth': d.cmo === currMonthId,
                    'nextmonth': d.cmo === nextMonthId,
                    'highlight': d.highlight
                  }">
                  <span
                    [ngClass]="{
                      'markcurrday': d.currDay && opts.markCurrentDay,
                      'dimday':
                        d.highlight &&
                        (d.cmo === prevMonthId ||
                          d.cmo === nextMonthId ||
                          d.disabled)
                    }"
                    >{{ d.dateObj.day }}</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="monthtable" *ngIf="selectMonth">
          <tbody>
            <tr *ngFor="let mr of months">
              <td
                class="monthcell tablesinglemonth"
                [ngClass]="{
                  'selectedmonth': m.selected,
                  'disabled': m.disabled
                }"
                *ngFor="let m of mr"
                (click)="
                  !m.disabled && onMonthCellClicked(m); $event.stopPropagation()
                "
                (keydown)="onMonthCellKeyDown($event, m)"
                tabindex="0">
                <div
                  class="monthvalue"
                  [ngClass]="{
                    'markcurrmonth': m.currMonth && opts.markCurrentMonth
                  }">
                  {{ m.name }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="yeartable" *ngIf="selectYear">
          <tbody>
            <tr>
              <td
                colspan="5"
                class="yearchangebtncell"
                (click)="$event.stopPropagation()">
                <button
                  type="button"
                  class="yearchangebtn mydpicon icon-mydpup"
                  (click)="onPrevYears($event, years[0][0].year)"
                  [disabled]="prevYearsDisabled"
                  [ngClass]="{
                    'yearchangebtnenabled': !prevYearsDisabled,
                    'yearchangebtndisabled': prevYearsDisabled
                  }"></button>
              </td>
            </tr>
            <tr *ngFor="let yr of years">
              <td
                class="yearcell tablesingleyear"
                [ngClass]="{
                  'selectedyear': y.selected,
                  'disabled': y.disabled
                }"
                *ngFor="let y of yr"
                (click)="
                  !y.disabled && onYearCellClicked(y); $event.stopPropagation()
                "
                (keydown)="onYearCellKeyDown($event, y)"
                tabindex="0">
                <div
                  class="yearvalue"
                  [ngClass]="{
                    'markcurryear': y.currYear && opts.markCurrentYear
                  }">
                  {{ y.year }}
                </div>
              </td>
            </tr>
            <tr>
              <td
                colspan="5"
                class="yearchangebtncell"
                (click)="$event.stopPropagation()">
                <button
                  type="button"
                  class="yearchangebtn mydpicon icon-mydpdown"
                  (click)="onNextYears($event, years[0][0].year)"
                  [disabled]="nextYearsDisabled"
                  [ngClass]="{
                    'yearchangebtnenabled': !nextYearsDisabled,
                    'yearchangebtndisabled': nextYearsDisabled
                  }"></button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div>
                    Date time now
                </div> -->
      </div>
      <!-- <div>
                <div [ngStyle]="{'width': '40px','max-height': opts.selectorHeight, 'overflow-y': 'auto'}">
                    <div [ngStyle]="{'width': '20px'}" *ngFor="let hour of hours">
                        {{hour.hour}}
                    </div>
                </div>
            </div>
            <div>
                <div [ngStyle]="{'width': '40px','max-height': opts.selectorHeight, 'overflow-y': 'auto'}">
                    <div [ngStyle]="{'width': '20px'}" *ngFor="let minute of minutes">
                        {{minute.minute}}
                    </div>
                </div>
            </div> -->
    </div>
  </div>
</div>
