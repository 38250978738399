import { Component, Input, OnInit } from '@angular/core';
import { FormElement } from 'src/app/models/form-model/form.class';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-datatime-element',
  templateUrl: './datatime-element.component.html',
  styleUrls: ['./datatime-element.component.scss'],
})
export class DatatimeElementComponent implements OnInit {
  @Input() data: FormElement;
  constant: Constant;
  constructor() {}

  ngOnInit() {}
}
