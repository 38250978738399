import { InputFilterComponent } from './input-filter/input-filter.component';
import { SelectionFilterComponent } from './selection-filter/selection-filter.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterItemComponent } from './filter-item.component';
import { SharedModule } from 'src/app/share/share.module';
import { InputNumberFilterComponent } from './input-number-filter/input-number-filter.component';
import { DateFilterComponent } from './date-filter/date-filter.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [FilterItemComponent],
  declarations: [
    FilterItemComponent,
    SelectionFilterComponent,
    InputFilterComponent,
    InputNumberFilterComponent,
    DateFilterComponent,
  ],
})
export class FilterItemModule {}
