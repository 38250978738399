<div class="tab-list-visit">
  <div class="search-bar" style="display: flex">
    <div class="search-bar-left">
      <input
        [(ngModel)]="search.patientName"
        class="txt_Search mgr15"
        name="patientName"
        id="txtFullName"
        type="text"
        autocomplete="off"
        placeholder="Tên bệnh nhân *"
        (keydown.enter)="searchListVisit()" />
      <input
        [(ngModel)]="search.patientCode"
        class="txt_Search mgr15"
        name="patientCode"
        id="txtCode"
        type="text"
        autocomplete="off"
        placeholder="Mã bệnh nhân *"
        (keydown.enter)="searchListVisit()" />
      <input
        [(ngModel)]="search.phone"
        class="txt_Search mg-r-10"
        name="phone"
        id="txtPhone"
        type="text"
        autocomplete="off"
        placeholder="Số điện thoại *"
        (keydown.enter)="searchListVisit()" />
      <div (click)="resetSearch()" class="pointer mg-r-10">
        <i>
          <img src="assets/image/DPicon/icon-close.svg" />
        </i>
      </div>
      <div (click)="searchListVisit()" class="pointer mg-r-10">
        <i>
          <img src="assets/image/DPicon/icon-search.svg" />
        </i>
      </div>
      <div class="pointer mg-r-10">
        <i>
          <img src="assets/image/DPicon/icon-gear.svg" />
        </i>
      </div>
    </div>
    <div class="search-bar-right">
      <button
        class="normal-btn btn-primary-medium"
        type="button"
        (click)="showModal()">
        Thêm ca khám
      </button>
    </div>
  </div>
  <!--   -->
  <div style="position: relative">
    <span class="column-btn" (click)="showCustomColumn()">
      <i nz-icon nzType="table" nzTheme="outline" class="fs-16"></i>
    </span>
    <nz-table
      class="main-table-grid listvs-only"
      #fixedTable
      [nzFrontPagination]="false"
      [nzTotal]="total"
      nzShowSizeChanger
      [nzPageSize]="pageSize"
      [(nzPageIndex)]="pageIndex"
      [nzData]="resVisit"
      [nzLoading]="loading"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzScroll]="{ x: '1000px', y: 'calc(100vh - 270px)' }"
      [nzShowTotal]="rangeTemplate"
      [nzPageSizeOptions]="[15, 30, 50, 100]">
      <thead>
        <tr>
          <ng-container *ngFor="let col of showCols">
            <th
              *ngIf="col.width"
              [nzColumnKey]="col.title"
              nz-resizable
              [nzWidth]="col.width"
              [nzMinWidth]="col.minWidth"
              nzBounds="window"
              nzPreview
              (nzResizeEnd)="onResizeCloumn($event, col.title)">
              {{ col.title }}
              <nz-resize-handle nzDirection="right">
                <div class="resize-trigger"></div>
              </nz-resize-handle>
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr
          class="pointer"
          *ngFor="let data of fixedTable.data; index as i"
          (click)="addTab(data.id, data.patientName, data.patientId)"
          (mouseenter)="showToolTip(data)"
          (mouseleave)="hideToolTip()">
          <ng-container *ngFor="let col of showCols">
            <ng-container [ngSwitch]="col.field">
              <td *ngSwitchCase="'STT'" class="text-nowrap" id="tdNone">
                {{ pageSize * (pageIndex - 1) + i + 1 }}
              </td>
              <td *ngSwitchCase="'patientName'" class="text-nowrap" id="tdNone">
                <div
                  *ngIf="
                    data.color &&
                    data.shortName &&
                    (!data.avatar || data.avatar == '')
                  "
                  class="customer-photo"
                  [ngStyle]="{ 'background-color': data.color }">
                  {{ data.shortName }}
                </div>
                <div
                  *ngIf="
                    (!data.color || !data.shortName) &&
                    (!data.avatar || data.avatar == '')
                  "
                  class="customer-photo"
                  style="
                    background-image: url('/assets/image/avt-default2.png');
                    background-repeat: round;
                  "></div>
                <div
                  *ngIf="data.avatar && data.avatar != ''"
                  class="customer-photo"
                  [ngStyle]="{
                    'background-image':
                      'url(' + (baseAvatarurl + data.avatar) + ')'
                  }"></div>
                {{ data.patientName }}
              </td>
              <td *ngSwitchCase="'visitDate'" class="text-nowrap">
                {{ data.visitDate | date: 'dd/MM/yyy hh:mm a' }}
              </td>
              <td *ngSwitchCase="'dateOfDiagnosis'" class="text-nowrap">
                {{ data.dateOfDiagnosis | date: 'dd/MM/yyy' }}
              </td>
              <td *ngSwitchCase="'dateOfSymptom'" class="text-nowrap">
                {{ data.dateOfSymptom | date: 'dd/MM/yyy' }}
              </td>
              <td *ngSwitchDefault>
                {{ data[col.field] }}
              </td>
            </ng-container>
          </ng-container>
        </tr>
      </tbody>

      <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }} {{ 'ca khám' }}
      </ng-template>
    </nz-table>
  </div>
  <div id="tooltip">
    <div>
      <div class="tooltip-part1">
        <div class="part1_1">
          <div
            *ngIf="
              dataTooltip.color &&
              dataTooltip.shortName &&
              (!dataTooltip.avatar || dataTooltip.avatar == '')
            "
            class="tooltip-img"
            [ngStyle]="{ 'background-color': dataTooltip.color }">
            {{ dataTooltip.shortName }}
          </div>
          <div
            *ngIf="
              !dataTooltip.color &&
              !dataTooltip.shortName &&
              (!dataTooltip.avatar || dataTooltip.avatar == '')
            "
            class="tooltip-img"
            style="
              background-image: url('/assets/image/avt-default2.png');
              background-repeat: round;
            "></div>
          <div
            *ngIf="dataTooltip.avatar && dataTooltip.avatar != ''"
            class="tooltip-img"
            [ngStyle]="{
              'background-image':
                'url(' + (baseAvatarurl + dataTooltip.avatar) + ')'
            }"></div>
          <div>
            <span>
              <span class="bigger-font">{{ dataTooltip.patientName }} </span>
              <span
                >({{ dataTooltip.dob }} - {{ dataTooltip.age }} -
                {{ dataTooltip.genderText }})</span
              >
            </span>
            <br />
            <span>Mã bệnh nhân: {{ dataTooltip.patientCode }}</span>
          </div>
        </div>
        <div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12">
              <g id="ic_local_phone_24px" transform="translate(-3 -3)">
                <g
                  id="Group_2895"
                  data-name="Group 2895"
                  transform="translate(3 3)">
                  <path
                    id="Path_2238"
                    data-name="Path 2238"
                    d="M5.413,8.193a10.1,10.1,0,0,0,4.393,4.393l1.467-1.467a.663.663,0,0,1,.68-.16,7.6,7.6,0,0,0,2.38.38.669.669,0,0,1,.667.667v2.327a.669.669,0,0,1-.667.667A11.332,11.332,0,0,1,3,3.667.669.669,0,0,1,3.667,3H6a.669.669,0,0,1,.667.667,7.574,7.574,0,0,0,.38,2.38.669.669,0,0,1-.167.68Z"
                    transform="translate(-3 -3)"
                    fill="#565656" />
                </g>
              </g>
            </svg>
            {{ dataTooltip.phoneNo }}</span
          >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="10"
              viewBox="0 0 12 10">
              <g id="ic_email_24px" transform="translate(-2 -4)">
                <g
                  id="Group_2896"
                  data-name="Group 2896"
                  transform="translate(2 4)">
                  <path
                    id="Path_2239"
                    data-name="Path 2239"
                    d="M12.8,4H3.2A1.224,1.224,0,0,0,2.006,5.25L2,12.75A1.229,1.229,0,0,0,3.2,14h9.6A1.229,1.229,0,0,0,14,12.75V5.25A1.229,1.229,0,0,0,12.8,4Zm0,2.5L8,9.625,3.2,6.5V5.25L8,8.375,12.8,5.25Z"
                    transform="translate(-2 -4)"
                    fill="#565656" />
                </g>
              </g>
            </svg>
            {{ dataTooltip.email }}</span
          >
        </div>
        <div>
          <span class="smaller-font">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="12"
              viewBox="0 0 10 12">
              <g id="ic_add_location_24px" transform="translate(-5 -2)">
                <g
                  id="Group_2897"
                  data-name="Group 2897"
                  transform="translate(5 2)">
                  <path
                    id="Path_2240"
                    data-name="Path 2240"
                    d="M10,2C7.243,2,5,3.884,5,6.2,5,9.35,10,14,10,14s5-4.65,5-7.8C15,3.884,12.757,2,10,2Zm2.857,4.8H10.714V8.6H9.286V6.8H7.143V5.6H9.286V3.8h1.429V5.6h2.143Z"
                    transform="translate(-5 -2)"
                    fill="#565656" />
                </g>
              </g>
            </svg>
            {{ dataTooltip.address }}
          </span>
        </div>
      </div>

      <div class="tooltip-part2">
        <div class="part2_1">
          <div>
            <span class="bigger-weight">BS khám: </span
            ><span style="margin-right: 24px">{{
              dataTooltip.doctorName
            }}</span>
          </div>
          <div>
            <span class="bigger-weight">Thời gian khám: </span
            ><span>{{
              dataTooltip.visitdate | date: 'dd/MM/yyyy hh:mm a'
            }}</span>
          </div>
        </div>
        <div>
          <span class="bigger-weight">Loại khám: </span>
          <span>{{ dataTooltip.visitTypeText }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<app-custom-column
  [(isVisible)]="isCustomColumnVisible"
  [showColumn]="showCols"
  [hideColumn]="hideCols"
  (saveColumns)="changeColumns($event)"></app-custom-column>
