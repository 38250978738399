import { Injectable } from '@angular/core';
import { extend } from 'jquery';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class RulesManagermentService extends BaseService {
  // getListCDSRule(name, status): Observable<any> {
  //   return this.post(UrlConstant.RULES_MANAGERMENT + '/GetList?name=' + name + '&status=' + status ,null);
  // }
  getListCDSRule(payload): Observable<any> {
    return this.post(UrlConstant.RULES_MANAGERMENT + '/GetList', payload);
  }
  saveRulesManagerment(payload): Observable<any> {
    return this.post(UrlConstant.RULES_MANAGERMENT, payload);
  }
  removeRulesManagerment(id: string): Observable<any> {
    return this.delete(UrlConstant.RULES_MANAGERMENT + '/' + id, '');
  }
  updateStatus(ruleId: string, status: boolean): Observable<any> {
    return this.post(UrlConstant.RULES_MANAGERMENT + '/UpdateStatus/' + ruleId + '/' + status, '');
  }
  createReminder2CDSRule(payload): Observable<any> {
    return this.post(UrlConstant.RULE_2_REMINDER, payload);
  }
}
