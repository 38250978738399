<div class="title-wrapper">
  <div class="search-bar row">
    <div class="search-bar-left col-9">
      <div class="row" style="width: 103%">
        <div class="col-2">
          <input
            placeholder="Tên bệnh nhân"
            [(ngModel)]="dataSearch.patientName"
            nz-input
            (input)="callbackSearchFn()"
            [nzAutocomplete]="autosearch" />
          <nz-autocomplete #autosearch>
            <nz-auto-option
              *ngFor="let item of lstPatientSearch"
              [nzValue]="item.fullName"
              (click)="selectPatientName(item)">
              <span>{{ item.fullName }}</span>
            </nz-auto-option>
          </nz-autocomplete>
        </div>
        <div class="col-2">
          <nz-select
            [nzPlaceHolder]="'Người thực hiện'"
            nzAllowClear
            [(ngModel)]="dataSearch.doctorId"
            [nzShowSearch]="true">
            <nz-option
              *ngFor="let item of doctorInGroup"
              [nzLabel]="item.fullName"
              [nzValue]="item.fullName">
            </nz-option>
          </nz-select>
        </div>
        <div class="col-2">
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Trạng thái"
            [(ngModel)]="dataSearch.status">
            <nz-option
              *ngFor="let data of listStatus; let i = index"
              [nzValue]="i"
              [nzLabel]="data">
            </nz-option>
          </nz-select>
        </div>
        <div class="col-2">
          <nz-date-picker
            (ngModelChange)="convertDateFrom($event)"
            style="width: inherit"
            class="date"
            [nzPlaceHolder]="'Từ ngày'"
            [nzFormat]="dateFormat"
            [(ngModel)]="dataSearch.from">
          </nz-date-picker>
        </div>
        <div class="col-2">
          <nz-date-picker
            (ngModelChange)="convertDateTo($event)"
            style="width: inherit"
            class="date"
            [nzPlaceHolder]="'Đến ngày'"
            [nzFormat]="dateFormat"
            [(ngModel)]="dataSearch.to">
          </nz-date-picker>
        </div>
        <div class="col-2">
          <input
            nz-input
            placeholder="Tên công việc"
            [(ngModel)]="dataSearch.title" />
        </div>
      </div>
    </div>

    <div class="search-bar-right col-3">
      <div style="margin-left: -20%">
        <button
          nz-button
          class="pointer mg-r-10"
          (click)="searchReminder(dataSearch)">
          <i nz-icon nzType="search" nzTheme="outline"></i>
        </button>
        <button nz-button class="pointer mg-r-10" (click)="reloadDataTable()">
          <i>
            <img src="assets/image/DPicon/icon-close.svg" />
          </i>
        </button>
      </div>
      <button
        nz-button
        class="btn-primary-small"
        nzType="success"
        (click)="showModalAdd()">
        <i nz-icon nzType="plus" nzTheme="outline"></i>
        Thêm mới
      </button>
    </div>
  </div>
  <nz-table
    class="main-table-grid"
    nzSize="small"
    #fixedTable
    [nzFrontPagination]="false"
    [nzTotal]="total"
    [nzData]="list_reminders"
    [(nzPageSize)]="pageSize"
    [nzShowPagination]="true"
    (nzQueryParams)="onQueryParamsChange($event)"
    nzShowSizeChanger
    [nzPageSizeOptions]="[20, 50, 100]"
    [nzScroll]="{ y: 'calc(100vh - 270px)' }">
    <thead>
      <tr>
        <th nzWidth="70px">STT</th>
        <th>Thời gian thực hiện</th>
        <th>Bệnh nhân</th>
        <th>Công việc</th>
        <th>Mô tả</th>
        <th>Người thực hiện</th>
        <th>SĐT</th>
        <th>Địa chỉ</th>
        <th>Trạng thái</th>
        <th>Hành động</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let data of fixedTable.data; let i = index"
        (click)="rowSelected(data)">
        <td>
          {{ fixedTable.nzPageSize * (fixedTable.nzPageIndex - 1) + i + 1 }}
        </td>
        <td>{{ data.activeDate | date: 'dd/MM/yyy hh:mm a' }}</td>
        <td (click)="addTab(data.visitId, data.patientName, data.patientId)">
          {{ data.patientName }}
        </td>
        <td>{{ data.title }}</td>
        <td>{{ data.detail }}</td>
        <td>{{ data.doctorName }}</td>
        <td>{{ data.patientPhoneNo }}</td>
        <td>{{ data.patientAddress }}</td>
        <td>
          <button
            *ngIf="!data.isEditStatus"
            id="r-{{ data.id }}"
            class="status status-{{ data.status }}"
            (click)="editStatus(data)">
            {{ toTextStatus(data.status) }}
          </button>
          <nz-select
            *ngIf="data.isEditStatus"
            class="w-100px"
            (clickOutside)="log($event, data)"
            [(ngModel)]="data.status"
            (ngModelChange)="saveStatus(data)">
            <nz-option
              style="text-align: center"
              *ngFor="let status of listStatus; let i = index"
              [nzValue]="i"
              [nzLabel]="status">
            </nz-option>
          </nz-select>
        </td>
        <td style="text-indent: 8px">
          <i
            nz-icon
            nzType="edit"
            class="mg-r-10"
            title="Chỉnh sửa"
            (click)="showModal()"
            nzTheme="outline"></i>
          <i
            nz-icon
            nzType="delete"
            class="mg-r-10"
            title="Xóa"
            (click)="deleteReminder(data.id)"
            nzTheme="outline"></i>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<ng-template #itemTemplate let-item>
  <a [innerHTML]="item.fullName"></a>
</ng-template>

<ng-template #notFoundTemplate let-notFound>
  <!-- <div [innerHTML]="notFound"></div> -->
</ng-template>

<app-reminder-form
  [(isVisible)]="visibleForm"
  [curReminder]="curReminder"
  (refreshData)="loadListReminder()"></app-reminder-form>
