import { DpformInfoComponent } from './dpform-info/dpform-info.component';
import { Formula } from './../../../../models/form-model/formula';
import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  NzFormatEmitEvent,
  NzTreeComponent,
  NzTreeNode,
} from 'ng-zorro-antd/tree';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/components/app-state/app-state';
import { AuthModel } from 'src/app/models/auth.model';
import {
  CanvasElement,
  CommonInfo,
  FormElement,
  ImageElement,
  LabelElement,
} from 'src/app/models/form-model/form.class';
import { FormatStyle } from 'src/app/models/form-model/formatStyle.model';
import { TextAreaElement } from 'src/app/models/form-model/textareaElement.model';
import { GroupStateModel } from 'src/app/models/group-state.model';
import { CommonInfoService } from 'src/app/services/commonInfo.service';
import { FormService } from 'src/app/services/form.serivce';
import { GroupMemberService } from 'src/app/services/group-member.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ObservationTypeService } from 'src/app/services/obserType-service';
import { RosService } from 'src/app/services/ros-service';
import { RosCategoryService } from 'src/app/services/rosCategory.service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { Page } from 'src/app/models/form-model/page';
declare let $: any;

@Component({
  selector: 'app-edit-form-static',
  templateUrl: './edit-form-static.component.html',
  styleUrls: ['./edit-form-static.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditFormStaticComponent implements OnInit, AfterContentInit {
  _id = '';
  @Input() adminPage: boolean;

  currentGroup: GroupStateModel = {
    isAuthenticated: false,
    groupId: '',
    groupName: '',
    isCovidGroup: false,
    isAutoAddTreatmentPatient: false,
    isLoadByPermission: false,
    role: 0,
  };

  @Input() isVisible = true;

  @Input() set id(value: string) {
    if (value != '') {
      this._id = value;
      this.initFormData();
    }
  }
  form: any = {};

  get id(): string {
    return this._id;
  }

  private allPageComponent;
  private idEditForm = null;
  htmlText: any;
  canvasElms: CanvasElement[] = [];
  commonInfos: CommonInfo[] = [];
  formulas: Formula[] = [];
  labels: LabelElement[] = [];
  // checkboxs = new Map();
  images: ImageElement[] = [];
  observationTypes: FormElement[] = [];
  observationTypeRecents: FormElement[] = [];
  textareas: TextAreaElement[] = [];
  observationItemInstances: FormElement[] = [];
  formObjects = new Map();
  verticalRulers = new Map();

  isFirstLoad = true;
  isCalculatedModalVisiable = false;
  formMinScope = 99;
  private contentHtml = '';
  pages: Page[] = [];
  activeFn: any;
  bindEventFn: any;
  step = 10;
  currentFormulaId: string;
  lstCommonInfo: any = [];
  lstObservationType: any = [];
  lstObservationTypeRecent: any = [];
  zoom = 100;
  showZoom = false;

  nodes: NzTreeNode[] = [];
  isChange = false;
  @ViewChild('DOMTree', { static: true }) DomTree: NzTreeComponent;
  @ViewChild(DpformInfoComponent) dfFromInfo: DpformInfoComponent;

  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private rosCategoryService: RosCategoryService,
    private rosService: RosService,
    private formService: FormService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private groupMemberService: GroupMemberService,
    private shareService: ShareService,
    private detectChange: ChangeDetectorRef,
    @Inject(DOCUMENT) private doc: any
  ) {}

  searchValue = '';
  isAddInputVisiable = false;
  isConfirmSaveVisible = false;
  isFormatStyleBoxVisible = false;
  isFormControlVisible = false;
  isFormRecentControlVisible = false;
  isCommonInfoControlVisible = false;
  isUploadFileVisiable = false;
  imageType: string = Constant.IMAGETYPE.IMAGE;
  uploadMaxWidth: number;
  uploadMaxHeight: number;
  selectedOT: any;
  isVisiblePreviewModal = false;
  isAddorEdit: string | 'add' | 'edit';
  jsonEventData: any = [];
  formatStyle: FormatStyle = new FormatStyle();
  currentElement: any = {};
  currentElmType: any = '';
  isFocusElm = false;
  currentFormElement: FormElement;
  currentFormElementRecent: FormElement;
  currentCommonInfoElement: any;
  currentObservationItemInstance: any;
  currentObservationItemRecentInstance: any;
  ross: any;
  rosCategorys: any;
  isFormatContainerStyleBoxVisible = true;
  listGroupCate: any = [];
  listGroup: Array<any> = [];
  bindOnReloadFn: any;
  panels = [
    {
      active: true,
      name: 'Thành phần',
      disabled: false,
    },
    {
      active: true,
      disabled: false,
      name: 'Style',
    },
    {
      active: false,
      disabled: false,
      name: 'Element Info',
    },
    {
      active: false,
      disabled: false,
      name: 'Cây cấu trúc',
    },
    {
      active: false,
      disabled: false,
      name: 'Style container',
    },
  ];

  public $addNewPage;
  public $addObservationType;
  public $uploadSuccess;
  public $init;
  public idPageEditForm;
  public isPage = true;
  public ishaveBlockSelected = false;
  public isBlockCanAddItems = false;
  public nzConfirmTitle = [
    'Bạn có chắc muốn xóa Block này ?\n Bao gồm toàn bộ items bên trong ?',
    'Bạn có chắc muốn xóa trang này ?',
  ];
  /*Format Item*/
  public $toggleValue;
  public $selectValue;
  public $resetForm;
  public $div_select: any;
  public $div: any;
  public activeDelete: boolean;
  public x1 = 0;
  public y1 = 0;
  public x2 = 0;
  public y2 = 0;
  public x3 = 0;
  public x4 = 0;
  public y3 = 0;
  public y4 = 0;
  public longpress = 200;
  public start;
  public arrSelected = [];
  public hasMultiSelector: boolean;
  public dataSaved = {
    splitDatas: [],
    html: '',
  };

  initFormData() {
    this.idEditForm = this.id;
    if (this.idEditForm == 'new') {
      this.isAddorEdit = 'add';
      this.addNewPage();
    } else {
      this.isAddorEdit = 'edit';
      new Promise((resolve, reject) => {
        this.loadFormdata(this.idEditForm);
        resolve(true);
      }).then((res) => {
        setTimeout(() => {
          this.bindOnReloadFn();
        }, 200);
      });
    }
    // });

    const $this = this;
    $(document).unbind('updateDomTree', function () {
      $this.getDomTree();
    });
    $(document).on('mapDomTree', function (event, key) {
      $this.activeNodeTreeResponseBlock(key);
    });
  }

  ngOnInit() {
    // this.isCreateNewOrEditOldForm();
    this.initFormMap();
    this.getAllGroup();
  }

  initFormMap() {
    this.formObjects.set(Constant.FORMELEMENT.LABEL, this.labels);
    // this.formObjects.set(Constant.FORMELEMENT.CHECKBOX, this.checkboxs);
    this.formObjects.set(Constant.FORMELEMENT.TEXTAREA, this.textareas);
    this.formObjects.set(Constant.FORMELEMENT.IMAGE, this.images);
    this.formObjects.set(Constant.FORMELEMENT.CANVAS, this.canvasElms);
    this.formObjects.set(
      Constant.FORMELEMENT.OBSERVATION,
      this.observationTypes
    );
    this.formObjects.set(
      Constant.FORMELEMENT.OBSERVATION_RECENT,
      this.observationTypeRecents
    );
    this.formObjects.set(Constant.FORMELEMENT.COMMON_INFO, this.commonInfos);
    this.formObjects.set(Constant.FORMELEMENT.FORMULA, this.formulas);
  }

  async delegateClickPageForm() {
    let countTimeOut;
    await $('#whole-page-content').undelegate('.f-page-content', 'click');
    $('#whole-page-content').delegate(
      '.f-page-content',
      'click',
      async function () {
        await $('.selectedPageForm').removeClass('selectedPageForm');
        await $(this).addClass('selectedPageForm');
        clearTimeout(countTimeOut);
        countTimeOut = setTimeout(function () {
          $(document).trigger('updateDomTree');
        }, 300);
      }
    );
  }

  ngAfterContentInit(): void {
    const $this = this;
    let ctrlPress = false;
    let altPress = false;
    let shiftPress = false;
    let copyElm;

    //Truongnx Added
    function reCalc() {
      $this.x3 = Math.min($this.x1, $this.x2);
      $this.x4 = Math.max($this.x1, $this.x2);
      $this.y3 = Math.min($this.y1, $this.y2);
      $this.y4 = Math.max($this.y1, $this.y2);

      $this.$div.style.left = $this.x3 + 'px';
      $this.$div.style.top = $this.y3 + 'px';
      $this.$div.style.width = $this.x4 - $this.x3 + 'px';
      $this.$div.style.height = $this.y4 - $this.y3 + 'px';
    }
    function unGroupSelectClone($container) {
      $container.find('.df-elm').each(function (index, obj) {
        let top = $(obj).position().top;
        let left = $(obj).position().left;
        const yy3 = +$container[0].style.top.replace('px', '');
        const xx3 = +$container[0].style.left.replace('px', '');

        top += yy3;
        left += xx3;
        const oldId = $(obj).attr('id');
        const dfType = $(obj).attr('df-type');
        storeObj(oldId, dfType, false, top, left);
      });
      $('#group_clone').remove();
    }
    function storeObj(id, dfType, actice, top, left) {
      if ($this.formObjects.get(dfType)) {
        cloneFormObjectElement(id, dfType, actice, top, left);
      }
    }
    function checkDivInsideSelect() {
      $this.arrSelected = [];
      $('.df-elm').each(function (index, obj) {
        const $obj = $(obj);
        const top = $obj.position().top;
        const left = $obj.position().left;
        const width = $(obj).outerWidth();
        const height = $(obj).outerHeight();
        if (
          left >= $this.x3 &&
          left + width <= $this.x4 &&
          top >= $this.y3 &&
          top + height <= $this.y4
        ) {
          //alert($obj.attr('id'));
          $obj.addClass('multi-hightlight');
          const $clone = $obj.detach();
          $clone[0].style.top = top - $this.y3 + 'px';
          $clone[0].style.left = left - $this.x3 + 'px';
          $($this.$div).append($clone);
          $this.arrSelected.push({
            id: $obj.attr('id'),
            top: top - $this.y3,
            left: left - $this.x3,
          });
        }
      });
      if ($this.arrSelected.length === 0) {
        $this.$div.hidden = 1;
        $this.panels[4].active = false;
      } else {
        $this.panels[4].active = true;
      }
    }
    function initDrag(ctl) {
      //jQuery control
      ctl
        .mousedown(function (event) {
          // $(this).draggable('option', { helper: event.ctrlKey ? 'clone' : 'original' });
          $(this).draggable({
            containment: 'parent',
            revert: 'invalid',
            helper: event.ctrlKey ? 'clone' : 'original',
          });
        })
        .draggable();
    }
    function hideDivSelect() {
      const objSelect = $('#div_select');
      const selectPosition = objSelect.position();
      const width = objSelect.outerWidth();
      const height = objSelect.outerHeight();
      const selectMiddle = selectPosition.left + width / 2;
      $this.$div.hidden = 1;
      unGroupSelect(selectMiddle);
    }
    async function unGroupSelect(selectMiddle) {
      if ($this.activeDelete) {
        $this.arrSelected = [];
        return;
      }
      let value = '';
      if ($('.div_select').hasClass('Container-AL-center')) {
        value = 'center';
      } else if ($('.div_select').hasClass('Container-AL-left')) {
        value = 'left';
      } else if ($('.div_select').hasClass('Container-AL-right')) {
        value = 'right';
      }

      $('.multi-hightlight').removeClass('multi-hightlight');
      for (let i = 0; i < $this.arrSelected.length; i++) {
        var item = $this.arrSelected[i];
        const $obj = $('#' + item.id);
        const dfType = $obj.attr('df-type');
        const top = item.top;
        const left = item.left;
        const width = $obj.outerWidth();
        const $clone = $obj.detach();
        $this.y3 = +$this.$div.style.top.replace('px', '');
        $this.x3 = +$this.$div.style.left.replace('px', '');
        const curTop = top + $this.y3;
        let curLeft = left + $this.x3;
        if (value == 'center') {
          curLeft = selectMiddle - width / 2;
        } else if (value == 'left') {
          curLeft = 0;
        } else if (value == 'right') {
          curLeft = $('.workingBlock').innerWidth() - width;
        }
        const pageId = $('.workingBlock').closest('.pageForm').prop('id');

        $clone[0].style.top = curTop + 'px';
        $clone[0].style.left = curLeft + 'px';
        $this.verticalRulers.get(pageId).addPos(item.id, curTop, curLeft);
        // await cloneFormObjectElement(item.id, dfType, false, curTop, curLeft);
        $('.workingBlock').append($clone);

        const formELm = $this.formObjects
          .get(dfType)
          .filter((t) => t.viewId == item.id)[0];
        formELm.top = curTop;
        formELm.left = curLeft;
      }

      $('.div_select').removeClass('Container-AL-' + value);
      $this.arrSelected = [];
    }

    function moveToolbar(selectObj) {
      // move toolbar flow working block (selectObj : 'ten_class')
      if ($('.' + selectObj).length != 0) {
        $this.ishaveBlockSelected = true;
        $this.isPage = $('.' + selectObj).hasClass('f-page-content');
        $this.isBlockCanAddItems = $('.' + selectObj).hasClass(
          'blockCanAddItem'
        );

        let _top = getOffset(selectObj).top;
        if (_top < 65) {
          _top = 65 + 25;
        }
        const _left =
          getOffset(selectObj).left + $('.' + selectObj).outerWidth() + 10;
        $('.toolbar').css({ top: _top + 'px', left: +_left + 'px' });
      } else {
        $this.ishaveBlockSelected = false;
      }
    }

    function addEventClickSelectBlock() {
      $('#whole-page-content').undelegate('.blockCanAddItem', 'click');
      $('#whole-page-content').delegate(
        '.blockCanAddItem',
        'click',
        function () {
          $('.workingBlock').removeClass('workingBlock');
          $(this).addClass('workingBlock');
          moveToolbar('workingBlock');

          $this.hasMultiSelector = $(this).find('.div_select').length > 0;
          if (!$this.hasMultiSelector) {
            $('.div_select').remove();
            $this.$div_select = $(
              '<div class="div_select" id="div_select" hidden><div class="ovl"></div></div>'
            );
            $this.$div = $this.$div_select[0];
            // $this.$div_select.detach();
            $(this).append($this.$div_select);
            this.isFormatContainerStyleBoxVisible = true;
            onmousedown = async function (e) {
              const allowSelectMulti = $(e.target).hasClass('workingBlock');
              const $container = $(e.target).closest('.workingBlock');

              if (allowSelectMulti && $container.length > 0) {
                const mySelft =
                  $(e.target).attr('id') === 'div_select' ||
                  $(e.target).hasClass('ovl');
                if (mySelft) {
                  $this.$div.hidden = 0;
                } else {
                  await hideDivSelect();
                }
                $this.start = new Date().getTime();
                $this.x1 = e.clientX - $container.offset().left;
                $this.y1 = e.clientY - $container.offset().top;
                $($this.$div).addClass('has-border');
                reCalc();
              }
            };
            onmouseup = async function (e) {
              if ($this.start) {
                $('.multi-hightlight').removeClass('multi-hightlight');
                if (new Date().getTime() >= $this.start + $this.longpress) {
                  $this.start = undefined;
                  //$($this.$div).draggable();
                  $($this.$div)
                    .mousedown(function (event) {
                      // $(this).draggable('option', { helper: event.ctrlKey ? 'clone' : 'original' });
                      $(this).draggable({
                        containment: 'parent',
                        revert: 'invalid',
                        helper: event.ctrlKey ? 'clone' : 'original',
                      });
                    })
                    .draggable();

                  checkDivInsideSelect();
                  //$this.$div.hidden = 0;
                  $($this.$div).removeClass('has-border');
                } else {
                  $this.start = undefined;
                  await hideDivSelect();
                  return;
                }
              }
            };
            onmousemove = function (e) {
              if ($this.start) {
                const $container = $(e.target).closest('.workingBlock');
                if ($container.length > 0) {
                  $this.$div.hidden = 0;
                  $this.x2 = e.clientX - $container.offset().left;
                  $this.y2 = e.clientY - $container.offset().top;
                  reCalc();
                }
              }
            };
            $('.workingBlock').droppable({
              async drop(event, ui) {
                console.log(event);
                $this.isChange = true;
                const oldId = $(ui.draggable).attr('id');
                if (event.ctrlKey) {
                  if (oldId === 'div_select') {
                    await hideDivSelect();
                    const $cl = $(ui.helper).clone();

                    $cl.attr('id', 'group_clone');
                    $cl.removeClass('ui-draggable-dragging');
                    $(this).append($cl);
                    initDrag($cl);
                    unGroupSelectClone($cl);
                  } else {
                    var id = ui.helper.context.id;
                    var dfType = $(ui.helper.context).attr('df-type');
                    storeObj(
                      id,
                      dfType,
                      true,
                      ui.position.top,
                      ui.position.left
                    );
                  }
                } else {
                  if (oldId === 'div_select') {
                  } else {
                    var id = ui.helper.context.id;
                    var dfType = $(ui.helper.context).attr('df-type');

                    const label = $this.formObjects
                      .get(dfType)
                      .filter((t) => t.viewId == id)[0];
                    console.log(label);
                    label.top = ui.position.top;
                    label.left = ui.position.left;
                    label.isDirty = true;
                  }
                }
              },
            });
          }
        }
      );
    }

    $this.movePageUP = async function () {
      const indexOfSelectedPage = $('.selectedPageForm').parent().index();
      if (indexOfSelectedPage > 0) {
        await $('.selectedPageForm')
          .parent()
          .insertBefore(
            $('#whole-page-content')
              .children()
              .eq(indexOfSelectedPage - 1)
          );
        $(document).trigger('updateDomTree');
        $('.inner-content').animate(
          {
            scrollTop: $('.selectedPageForm').parent().position().top,
          },
          500
        );
      }
    };

    $this.movePageDown = async function () {
      const indexOfSelectedPage = $('.selectedPageForm').parent().index();
      const totalPage = $('#whole-page-content').children().length;
      if (indexOfSelectedPage < totalPage - 1) {
        await $('.selectedPageForm')
          .parent()
          .insertAfter(
            $('#whole-page-content')
              .children()
              .eq(indexOfSelectedPage + 1)
          );
        $(document).trigger('updateDomTree');
        $('.inner-content').animate(
          {
            scrollTop: $('.selectedPageForm').parent().position().top,
          },
          500
        );
      }
    };

    $this.downFocusChild = async function () {
      await $('.selectedPageForm .blockCanAddItem:first-child').addClass(
        'workingBlock'
      );
      $('.workingBlock')[0].click();
    };

    $this.upFocusParent = async function () {
      await $('.workingBlock').removeClass('workingBlock');
      moveToolbar('selectedPageForm');
      $(document).trigger('updateDomTree');
    };

    $this.$toggleValue = function (className) {
      $('.df-elm.active').children('.form-input').toggleClass(className);
      const id = $('.df-elm.active').attr('id');
    };

    $this.$selectValue = function (className, value) {
      if (className == 'font-size' || className == 'font-family') {
        $('.df-elm.active').children('.form-input').css(`${className}`, value);
      } else {
      }
    };
    //#endregion

    const initActive = function () {
      // let lastIndex = $(".wrapper-elm .active-elm").length;
      $('.wrapper-elm .active-elm').click(function (e) {
        if (e.target !== this) {
          return;
        }
        e.preventDefault();
        const that = this;
        setTimeout(function () {
          const dblclick = parseInt($(that).data('double'), 10);
          if (dblclick > 0) {
            $(that).data('double', dblclick - 1);
          } else {
            singleClick.call(that, e);
          }
        }, 5);
      });
      // resetFormClickEvent();
    };

    $this.bindEventFn = function () {
      // addLabelDbClickEvent();

      document.addEventListener('keydown', (event) => {
        if (event.keyCode == 46) {
          $this.activeDelete = true;
          event.preventDefault();
          if ($this.currentElement.viewId) {
            jConfirm(
              'Chú ý',
              'Bạn muốn xóa Element này?',
              function () {
                switch ($this.currentElmType) {
                  case Constant.FORMELEMENT.OBSERVATION_RECENT:
                    $this.formObjects.set(
                      Constant.FORMELEMENT.OBSERVATION_RECENT,
                      $this.formObjects
                        .get(Constant.FORMELEMENT.OBSERVATION_RECENT)
                        .filter((t) => t.viewId != $this.currentElement.viewId)
                    );
                    break;
                  case Constant.FORMELEMENT.OBSERVATION:
                    $this.formObjects.set(
                      Constant.FORMELEMENT.OBSERVATION,
                      $this.formObjects
                        .get(Constant.FORMELEMENT.OBSERVATION)
                        .filter((t) => t.viewId != $this.currentElement.viewId)
                    );
                    break;
                  case Constant.FORMELEMENT.FORMULA:
                    $this.formObjects.set(
                      Constant.FORMELEMENT.FORMULA,
                      $this.formObjects
                        .get(Constant.FORMELEMENT.FORMULA)
                        .filter((t) => t.viewId != $this.currentElement.viewId)
                    );
                    break;
                  case Constant.FORMELEMENT.LABEL:
                    $this.formObjects.set(
                      Constant.FORMELEMENT.LABEL,
                      $this.formObjects
                        .get(Constant.FORMELEMENT.LABEL)
                        .filter((t) => t.viewId != $this.currentElement.viewId)
                    );
                    break;
                  case Constant.FORMELEMENT.TEXTAREA:
                    $this.formObjects.set(
                      Constant.FORMELEMENT.TEXTAREA,
                      $this.formObjects
                        .get(Constant.FORMELEMENT.TEXTAREA)
                        .filter((t) => t.viewId != $this.currentElement.viewId)
                    );
                    break;
                  case Constant.FORMELEMENT.COMMON_INFO:
                    $this.formObjects.set(
                      Constant.FORMELEMENT.COMMON_INFO,
                      $this.formObjects
                        .get(Constant.FORMELEMENT.COMMON_INFO)
                        .filter((t) => t.viewId != $this.currentElement.viewId)
                    );
                    break;
                  case Constant.FORMELEMENT.IMAGE:
                    $this.formObjects.set(
                      Constant.FORMELEMENT.IMAGE,
                      $this.formObjects
                        .get(Constant.FORMELEMENT.IMAGE)
                        .filter((t) => t.viewId != $this.currentElement.viewId)
                    );
                    break;
                  case Constant.FORMELEMENT.CANVAS:
                    $this.formObjects.set(
                      Constant.FORMELEMENT.CANVAS,
                      $this.formObjects
                        .get(Constant.FORMELEMENT.CANVAS)
                        .filter((t) => t.viewId != $this.currentElement.viewId)
                    );
                    break;
                  default:
                    break;
                }
              },
              ''
            );
          }

          if ($('.div_select .df-elm ').length > 0) {
            jConfirm(
              'Chú ý',
              'Bạn muốn xóa những Element này?',
              function () {
                $('.div_select .df-elm ').each(function (_index, _obj) {
                  const dfType = $(_obj).attr('df-type');
                  const id = $(_obj).attr('id');
                  $this.formObjects.set(
                    dfType,
                    $this.formObjects.get(dfType).filter((t) => t.viewId != id)
                  );
                  $this.changeDetectorRef.detectChanges();
                  $this.activeDelete = false;
                });
              },
              ''
            );
          }
          return;
        }

        if (event.ctrlKey) {
          ctrlPress = true;
        } else {
          ctrlPress = false;
        }

        if (event.altKey) {
          altPress = true;
        } else {
          altPress = false;
        }

        if (event.shiftKey) {
          shiftPress = true;
        } else {
          shiftPress = false;
        }

        if (ctrlPress && event.keyCode == 67) {
          if ($('.cloneable.active').length == 1) {
            copyElm = $('.cloneable.active').clone();
          }
        }

        if (altPress && event.keyCode == 76) {
          event.preventDefault();
          $this.addLabel('', 0, 0, false);
        }

        if (altPress && event.keyCode == 84) {
          event.preventDefault();
          $this.addTextArea('', 0, 0, false);
        }

        if (altPress && event.keyCode == 73) {
          event.preventDefault();
          $this.openAddImage();
        }

        if (altPress && event.keyCode == 67) {
          event.preventDefault();
          $this.openAddCanvas();
        }

        if (altPress && event.keyCode == 78) {
          event.preventDefault();
          $this.addNewPage();
        }

        if (ctrlPress && event.keyCode == 66) {
          event.preventDefault();
          $this.$toggleValue('bold');
        }

        if (ctrlPress && event.keyCode == 73) {
          event.preventDefault();
          $this.$toggleValue('italic');
        }

        if (ctrlPress && event.keyCode == 85) {
          event.preventDefault();
          $this.$toggleValue('underline');
        }

        if (ctrlPress && event.keyCode == 80) {
          event.preventDefault();
          $this.isVisiblePreviewModal = !$this.isVisiblePreviewModal;
        }
      });

      document.addEventListener('keydown', async function (event) {
        if (event.keyCode == 86 && ctrlPress) {
          if (copyElm && $('.workingBlock').length == 1) {
            if (copyElm.hasClass('image-element')) {
              const cur = copyElm
                .clone()
                .prop('id', new Date().getTime())
                .css({ top: '0px', left: '0px' });
              const width = $(cur).width();
              const height = $(cur).height();
              cur.removeClass('active');
              await cur.children('.ui-resizable-handle').remove();
              cur.appendTo('.workingBlock');
              $(cur).resizable({
                aspectRatio: width / height,
                containment: 'parent',
              });

              $(cur).draggable({
                containment: 'parent',
                revert: 'invalid',
                helper: event.ctrlKey ? 'clone' : 'original',
              });

              $(cur).click(function () {
                $('.pageForm div').removeClass('active');
                $(cur).toggleClass('active');
              });

              $(cur).resize(function (event) {
                // console.log(event);
              });
              // resetFormClickEvent();

              // addLabelDbClickEvent();
            } else {
              const newId = new Date().getTime();
              const cur = copyElm
                .clone()
                .prop('id', newId)
                .css({ top: '0px', left: '0px' });
              cur.removeClass('active');
              if (cur.children('.active-elm.active').length == 1) {
                cur.children('.active-elm.active').removeClass('active');
              }
              await cur.children('.ui-resizable-handle').remove();
              cur.appendTo('.workingBlock');
              $(cur).resizable({ containment: 'parent' });
              $(cur).draggable({
                containment: 'parent',
                revert: 'invalid',
                helper: event.ctrlKey ? 'clone' : 'original',
              });

              // addLabelDbClickEvent();
            }
          }
        }
      });

      document.addEventListener('keyup', function (e) {
        if (ctrlPress) {
          ctrlPress = false;
        }
        if (altPress) {
          altPress = false;
        }
        if (shiftPress) {
          shiftPress = false;
        }
      });

      addEventClickSelectBlock();
    };

    $this.bindOnReloadFn = async function () {
      await initResize();
      dropable();
      $this.bindEventFn();
    };

    function initResize() {
      for (let i = 0; i < $('.pageForm').length; i++) {
        const pageId = $('.pageForm')[i].id;

        const formElms = $(`#${pageId} .df-elm`);
        for (let j = 0; j < formElms.length; j++) {
          const elmId = formElms[j].id;
          const dfType = $(formElms[j]).attr('df-type');
          $('#' + elmId).resizable({ containment: 'parent' });
          $(`#${elmId}`).resize(function (e) {
            const elm = $this.formObjects
              .get(dfType)
              .filter((t) => t.viewId == elmId)[0];
            elm.height = e.currentTarget.offsetHeight;
            elm.width = e.currentTarget.offsetWidth;
          });
          $this.draggable(elmId, pageId);
        }
      }

      for (let i = 0; i < $('.default-elm').length; i++) {
        const divElm = $('.default-elm')[i];
        $(divElm).click(function () {
          $('.pageForm div').removeClass('active');
          $(divElm).toggleClass('active');
        });
      }
    }

    function dropable() {
      for (let i = 0; i < $('.f-page-content').length; i++) {
        const pageId = $('.f-page-content')[i].id;
        $this.delegateClickPageForm();
        $('#' + pageId).droppable({
          drop(event, ui) {
            $this.isChange = true;
            droppableEvent(event, ui, this);
          },
        });
      }
    }

    async function singleClick(e) {
      // do something, "this" will be the DOM element
      $('.pageForm div').removeClass('active');
      $('.active-elm.active').removeClass('active');
      $('.df-elm.active').removeClass('active');
      hideDivSelect();
      $(this).addClass('active');
      await $(this).closest('.df-elm').addClass('active');
      // $(document).trigger("updateDomTree");
    }

    async function addLabelDbClickEvent() {
      await $('.form-label .active-elm, .form-textarea .active-elm').unbind(
        'click'
      );
      await $('.form-label .active-elm, .form-textarea .active-elm').unbind(
        'dblclick'
      );
      $('.form-label .active-elm, .form-textarea .active-elm')
        .click(function (e) {
          console.log(e);
          if (e.target !== this) {
            return;
          }
          e.preventDefault();

          const that = this;
          setTimeout(function () {
            const dblclick = parseInt($(that).data('double'), 10);
            if (dblclick > 0) {
              $(that).data('double', dblclick - 1);
            } else {
              console.log(e, this);
              $('.active-elm.active').removeClass('active');
              $('.df-elm.active').removeClass('active');
              $(that).toggleClass('active');
              $(that).closest('.df-elm').toggleClass('active');
            }
          }, 150);
        })
        .dblclick(function (e) {
          console.log('double', e);
          if (e.target !== this) {
            return;
          }
          e.preventDefault();
          $(this).data('double', 2);
          $(this).addClass('focus');
          $(this).parent().find('.form-elm').focus();
        });
    }

    function checkEmpty(value) {
      if (value == undefined || value == '' || value == null) {
        return false;
      }
      return true;
    }

    function cloneFormObjectElement(oldId, dfType, active, top, left) {
      switch (dfType) {
        case Constant.FORMELEMENT.LABEL:
          cloneLabelElement(oldId, active, top, left);
          break;
        case Constant.FORMELEMENT.COMMON_INFO:
          cloneCommonInfo(oldId, active, top, left);
          break;
        case Constant.FORMELEMENT.TEXTAREA:
          cloneTextAreaElement(oldId, active, top, left);
          break;
        case Constant.FORMELEMENT.IMAGE:
          cloneImageElement(oldId, active, top, left);
          break;
        case Constant.FORMELEMENT.CANVAS:
          cloneCanvasElement(oldId, active, top, left);
          break;
        case Constant.FORMELEMENT.OBSERVATION:
          cloneObservationElement(oldId, active, top, left);
          break;
        case Constant.FORMELEMENT.OBSERVATION_RECENT:
          cloneObservationRecentElement(oldId, active, top, left);
          break;
      }
    }

    async function cloneLabelElement(id, active, top, left) {
      const newId = await $this.addLabel(id, top, left, true);

      if (newId != '') {
        // console.log(newId);
        const $div = $(`#${newId}`);
        if (active) {
          setTimeout(() => {
            $div.find('.active-elm').click();
          }, 50);
        }
      }
    }

    async function cloneTextAreaElement(id, active, top, left) {
      const newId = await $this.addTextArea(id, top, left, true);

      if (newId != '') {
        const $div = $(`#${newId}`);
        if (active) {
          setTimeout(() => {
            $div.find('.active-elm').click();
          }, 50);
        }
      }
    }

    async function cloneCommonInfo(id, active, top, left) {
      const existCommonInfo = $this.formObjects
        .get(Constant.FORMELEMENT.COMMON_INFO)
        .filter((t) => t.viewId == id)[0];

      if (!existCommonInfo) {
        return;
      }

      const commonInfo = JSON.parse(JSON.stringify(existCommonInfo));
      const newId = new Date().getTime().toString();

      commonInfo.top = top;
      commonInfo.left = left;
      commonInfo.viewId = newId;

      $this.formObjects.get(Constant.FORMELEMENT.COMMON_INFO).push(commonInfo);
      $this.changeDetectorRef.detectChanges();

      $(`#${newId}`).resizable({ containment: 'parent' });
      $(`#${newId}`).resize(function (e) {
        const elm = $this.formObjects
          .get(Constant.FORMELEMENT.COMMON_INFO)
          .filter((t) => t.viewId == newId)[0];
        elm.height = e.currentTarget.offsetHeight;
        elm.width = e.currentTarget.offsetWidth;
      });

      const pageId = $('.workingBlock').closest('.pageForm').prop('id');
      $this.verticalRulers.get(pageId).addPos(newId, top, left);
      $this.draggable(newId, pageId);

      $(`#${newId}`).click(function () {
        $('.pageForm div').removeClass('active');
        $(`#${newId}`).toggleClass('active');
      });

      // $this.resetFormClickEvent();
      $this.getDomTree();

      const $div = $(`#${newId}`);
      if (active) {
        setTimeout(() => {
          $div.click();
        }, 50);
      }
    }

    function cloneImageElement(id, active, top, left) {
      const existImage = $this.formObjects
        .get(Constant.FORMELEMENT.IMAGE)
        .filter((t) => t.viewId == id)[0];

      if (!existImage) {
        return;
      }

      const newId = new Date().getTime().toString();
      const imageData = JSON.parse(JSON.stringify(existImage));
      imageData.viewId = newId;
      imageData.top = top;
      imageData.left = left;
      const width = imageData.width;
      const height = imageData.height;
      $this.formObjects.get(Constant.FORMELEMENT.IMAGE).push(imageData);
      $this.changeDetectorRef.detectChanges();

      $(`#${newId}`).resizable({
        aspectRatio: width / height,
        containment: 'parent',
      });

      $('#' + newId).resize(function (e) {
        const elm = $this.formObjects
          .get(Constant.FORMELEMENT.IMAGE)
          .filter((t) => t.viewId == newId)[0];
        elm.height = e.currentTarget.offsetHeight;
        elm.width = e.currentTarget.offsetWidth;
      });

      $(`#${newId}`).click(function () {
        $('.pageForm div').removeClass('active');
        $(`#${newId}`).toggleClass('active');
      });

      const pageId = $('.workingBlock').closest('.pageForm').prop('id');
      $this.verticalRulers.get(pageId).addPos(newId, top, left);
      $this.draggable(newId, pageId);
      // $this.resetFormClickEvent();
      $this.getDomTree();

      const $div = $(`#${newId}`);
      if (active) {
        setTimeout(() => {
          $div.click();
        }, 50);
      }
    }

    function cloneCanvasElement(id, active, top, left) {
      const existCanvas = $this.formObjects
        .get(Constant.FORMELEMENT.CANVAS)
        .filter((t) => t.viewId == id)[0];

      if (!existCanvas) {
        return;
      }

      const newId = new Date().getTime().toString();
      const canvasData = JSON.parse(JSON.stringify(existCanvas));
      canvasData.viewId = newId;
      canvasData.top = top;
      canvasData.left = left;
      const width = canvasData.width;
      const height = canvasData.height;

      $this.formObjects.get(Constant.FORMELEMENT.CANVAS).push(canvasData);
      $this.changeDetectorRef.detectChanges();

      $(`#${newId}`).resizable({
        aspectRatio: width / height,
        containment: 'parent',
      });

      $('#' + newId).resize(function (e) {
        const elm = $this.formObjects
          .get(Constant.FORMELEMENT.CANVAS)
          .filter((t) => t.viewId == newId)[0];
        elm.height = e.currentTarget.offsetHeight;
        elm.width = e.currentTarget.offsetWidth;
      });

      $(`#${newId}`).click(function () {
        $('.pageForm div').removeClass('active');
        $(`#${newId}`).toggleClass('active');
      });

      const pageId = $('.workingBlock').closest('.pageForm').prop('id');
      $this.verticalRulers.get(pageId).addPos(newId, top, left);
      $this.draggable(newId, pageId);
      // $this.resetFormClickEvent();
      $this.getDomTree();

      const $div = $(`#${newId}`);
      if (active) {
        setTimeout(() => {
          $div.click();
        }, 50);
      }
    }

    async function cloneObservationElement(id, active, top, left) {
      const existObservation = $this.formObjects
        .get(Constant.FORMELEMENT.OBSERVATION)
        .filter((t) => t.viewId == id)[0];

      if (!existObservation) {
        return;
      }

      const observation = JSON.parse(JSON.stringify(existObservation));
      const newId = new Date().getTime().toString();

      observation.top = top;
      observation.left = left;
      observation.viewId = newId;

      $this.formObjects.get(Constant.FORMELEMENT.OBSERVATION).push(observation);
      $this.changeDetectorRef.detectChanges();

      // await $this.appendObservationComponentToBody(id, $this.formObjects.get(Constant.FORMELEMENT.OBSERVATION).get(id));

      $(`#${newId}`).resizable({ containment: 'parent' });
      const pageId = $('.workingBlock').closest('.pageForm').prop('id');
      $this.verticalRulers.get(pageId).addPos(newId, top, left);

      $this.draggable(newId, pageId);
      $(`#${newId}`).resize(function (e) {
        const elm = $this.formObjects
          .get(Constant.FORMELEMENT.OBSERVATION)
          .filter((t) => t.viewId == newId)[0];
        elm.height = e.currentTarget.offsetHeight;
        elm.width = e.currentTarget.offsetWidth;
      });
      // console.log($div);

      if (active) {
        $(`#${newId}`).find('.active-elm').click();
      }
    }

    async function cloneObservationRecentElement(id, active, top, left) {
      const existObservation = $this.formObjects
        .get(Constant.FORMELEMENT.OBSERVATION_RECENT)
        .filter((t) => t.viewId == id)[0];

      if (!existObservation) {
        return;
      }

      const observation = JSON.parse(JSON.stringify(existObservation));
      const newId = new Date().getTime().toString();

      observation.top = top;
      observation.left = left;
      observation.viewId = newId;

      $this.formObjects
        .get(Constant.FORMELEMENT.OBSERVATION_RECENT)
        .push(observation);
      $this.changeDetectorRef.detectChanges();

      // await $this.appendObservationComponentToBody(id, $this.formObjects.get(Constant.FORMELEMENT.OBSERVATION).get(id));

      $(`#${newId}`).resizable({ containment: 'parent' });
      const pageId = $('.workingBlock').closest('.pageForm').prop('id');
      $this.verticalRulers.get(pageId).addPos(newId, top, left);

      $this.draggable(newId, pageId);
      $(`#${newId}`).resize(function (e) {
        const elm = $this.formObjects
          .get(Constant.FORMELEMENT.OBSERVATION_RECENT)
          .filter((t) => t.viewId == newId)[0];
        elm.height = e.currentTarget.offsetHeight;
        elm.width = e.currentTarget.offsetWidth;
      });
      // console.log($div);

      if (active) {
        $(`#${newId}`).find('.active-elm').click();
      }
    }

    function jConfirm(title, content, confirmEvent, cancelEvent) {
      $.confirm({
        title,
        content,
        type: 'red',
        buttons: {
          confirm: {
            text: 'Xác nhận',
            keys: ['enter'],
            btnClass: 'btn-blue',
            action() {
              if (checkEmpty(confirmEvent)) {
                confirmEvent();
                // createTree();
                $(document).trigger('updateDomTree');
              }
            },
          },
          cancel: {
            text: 'Hủy',
            btnClass: 'btn-red',
            action() {
              if (checkEmpty(cancelEvent)) {
                cancelEvent();
              }
            },
          },
        },
      });
    }

    async function resetFormClickEvent() {
      await $('.blockCanAddItem').unbind('click');
      $('.blockCanAddItem').bind('click', function (e) {
        if (e.target !== this) {
          return;
        }
        e.preventDefault();
        $('.active-elm.focus').removeClass('focus');
        $('.form-elm').blur();
        $('.active-elm.active').removeClass('active');
        $('.df-elm.active').removeClass('active');
        $this.isFormatStyleBoxVisible = false;
        $this.isFormControlVisible = false;
        $this.isFormRecentControlVisible = false;
        $('.default-element.active').removeClass('active');
      });
    }

    $(document).ready(function () {
      // $this.createNewOrEditOldForm(bindOnReload);
      $('body').css('overflow', 'hidden');
      $(document).trigger('updateDomTree');
      $('nz-content').scroll(function () {
        if ($('.workingBlock').length == 0) {
          if ($('.selectedPageForm').length != 0) {
            moveToolbar('selectedPageForm');
          }
        } else {
          moveToolbar('workingBlock');
        }
      });
    });
  }

  /**
   * @description Load Form by Id
   * Init Form Element Value
   */

  loadFormdata(id): any {
    this.formService.getFormToUpdate(id).subscribe((response) => {
      if (response.isValid) {
        this.form = response.jsonData.form;
        this.pages = this.form.pages;
        this.changeDetectorRef.detectChanges();
        this.pages.forEach((item) => {
          const ruler = new VerticalRuler(item.id);
          ruler.init();
          this.verticalRulers.set(item.id, ruler);
        });

        for (let i = 0; i < this.form.images.length; i++) {
          this.formObjects
            .get(Constant.FORMELEMENT.IMAGE)
            .push(this.form.images[i]);
          this.verticalRulers
            .get(this.form.images[i].pageId)
            .addPos(
              this.form.images[i].viewId,
              this.form.images[i].top,
              this.form.images[i].left
            );
        }
        for (let i = 0; i < this.form.labels.length; i++) {
          this.formObjects
            .get(Constant.FORMELEMENT.LABEL)
            .push(this.form.labels[i]);
          this.verticalRulers
            .get(this.form.labels[i].pageId)
            .addPos(
              this.form.labels[i].viewId,
              this.form.labels[i].top,
              this.form.labels[i].left
            );
        }
        // if (form.checkboxs != undefined) {
        //   for (let i = 0; i < form.checkboxs.length; i++) {
        //     this.formObjects
        //       .get(Constant.FORMELEMENT.CHECKBOX)
        //       .set(form.checkboxs[i].viewId, form.checkboxs[i]);
        //   }
        // }
        for (let i = 0; i < this.form.canvasImages.length; i++) {
          this.formObjects
            .get(Constant.FORMELEMENT.CANVAS)
            .push(this.form.canvasImages[i]);
          this.verticalRulers
            .get(this.form.canvasImages[i].pageId)
            .addPos(
              this.form.canvasImages[i].viewId,
              this.form.canvasImages[i].top,
              this.form.canvasImages[i].left
            );
        }
        for (let i = 0; i < response.jsonData.formElms.length; i++) {
          this.formObjects
            .get(Constant.FORMELEMENT.OBSERVATION)
            .push(response.jsonData.formElms[i]);
          this.verticalRulers
            .get(response.jsonData.formElms[i].pageId)
            .addPos(
              response.jsonData.formElms[i].viewId,
              response.jsonData.formElms[i].top,
              response.jsonData.formElms[i].left
            );
        }
        for (let i = 0; i < response.jsonData.formRecentElms.length; i++) {
          this.formObjects
            .get(Constant.FORMELEMENT.OBSERVATION_RECENT)
            .push(response.jsonData.formRecentElms[i]);
          this.verticalRulers
            .get(response.jsonData.formRecentElms[i].pageId)
            .addPos(
              response.jsonData.formRecentElms[i].viewId,
              response.jsonData.formRecentElms[i].top,
              response.jsonData.formRecentElms[i].left
            );
        }
        for (let i = 0; i < this.form.commonInfos.length; i++) {
          this.formObjects
            .get(Constant.FORMELEMENT.COMMON_INFO)
            .push(this.form.commonInfos[i]);
          this.verticalRulers
            .get(this.form.commonInfos[i].pageId)
            .addPos(
              this.form.commonInfos[i].viewId,
              this.form.commonInfos[i].top,
              this.form.commonInfos[i].left
            );
        }
        for (let i = 0; i < this.form.textareas.length; i++) {
          this.formObjects
            .get(Constant.FORMELEMENT.TEXTAREA)
            .push(this.form.textareas[i]);
          this.verticalRulers
            .get(this.form.textareas[i].pageId)
            .addPos(
              this.form.textareas[i].viewId,
              this.form.textareas[i].top,
              this.form.textareas[i].left
            );
        }
        for (let i = 0; i < this.form.formulas.length; i++) {
          this.formObjects
            .get(Constant.FORMELEMENT.FORMULA)
            .push(this.form.formulas[i]);
          this.verticalRulers
            .get(this.form.formulas[i].pageId)
            .addPos(
              this.form.formulas[i].viewId,
              this.form.formulas[i].top,
              this.form.formulas[i].left
            );
        }

        this.jsonEventData = this.form.splitDatas;
        // this.createDynamicComponent(currentContent, this);
        this.changeDetectorRef.detectChanges();
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Invalid Id'
        );
      }
    });
  }

  addNewPage() {
    const id = Date.now().toString();
    const verticalRuler = new VerticalRuler(id);
    const page = new Page();
    page.id = id;
    this.pages.push(page);
    this.changeDetectorRef.detectChanges();
    verticalRuler.init();
    this.verticalRulers.set(id, verticalRuler);

    this.getDomTree();
    $('#' + id + '-content').droppable({
      drop(event, ui) {
        this.isChange = true;
        droppableEvent(event, ui, this);
      },
    });
    // $(`#${id}`).resizable({
    //   handles: 's, n'
    // });

    // $('#' + id).resize(function (e) {
    //   console.log($(this).css("top"));
    //   console.log(e);
    //   // $(this).css("top", '0px');
    // });
  }

  showCalculatedModal() {
    this.currentFormulaId = '';
    this.isCalculatedModalVisiable = true;
  }

  async save(callback) {
    const $this = this;
    let error = await this.dfFromInfo.submitForm();
    if (error) {
      this.isConfirmSaveVisible = false;
      return;
    } else {
      let formInfoForm = this.dfFromInfo.formInfoForm;
      if (formInfoForm.value.formScope == 'group') {
        formInfoForm.patchValue({
          groupId: this.currentGroup.groupId,
        });
      }

      // let data = await this.allPageComponent.save();
      $('#whole-page-content-clone').html('');
      const saveData = {
        canvasImages: this.formObjects.get(Constant.FORMELEMENT.CANVAS),
        commonInfos: this.formObjects.get(Constant.FORMELEMENT.COMMON_INFO),
        formElements: this.formObjects.get(Constant.FORMELEMENT.OBSERVATION),
        formRecentElements: this.formObjects.get(
          Constant.FORMELEMENT.OBSERVATION_RECENT
        ),
        imageElements: this.formObjects.get(Constant.FORMELEMENT.IMAGE),
        labelElements: this.formObjects.get(Constant.FORMELEMENT.LABEL),
        textareas: this.formObjects.get(Constant.FORMELEMENT.TEXTAREA),
        formulas: this.formObjects.get(Constant.FORMELEMENT.FORMULA),
        pages: this.pages,
        ...formInfoForm.value,
      };

      this.formService.saveForm(saveData).subscribe((response) => {
        if (response.isValid) {
          this.isChange = false;
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Lưu thành công'
          );
          formInfoForm.patchValue({
            id: response.jsonData,
          });
          if (callback != 'undefined') {
            //callback();
            $this.router.navigate(['/admin/form-mngt']);
          }
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            response.errors[0].errorMessage
              ? response.errors[0].errorMessage
              : 'Dữ liệu không hợp lệ'
          );
        }
      });
    }
  }

  close(): void {
    if (this.isChange) {
      this.isConfirmSaveVisible = true;
    } else {
      this.closeAnyWay();
    }
  }

  saveAndClose() {
    this.save(this.closeAnyWay);
  }

  closeAnyWay(): void {
    this.router.navigate(['/admin/form-mngt']);
  }

  async addLabelDbClickEvent() {
    const $this = this;
    await $('.form-label .active-elm, .form-textarea .active-elm').unbind(
      'click'
    );
    await $('.form-label .active-elm, .form-textarea .active-elm').unbind(
      'dblclick'
    );
    $('.form-label .active-elm, .form-textarea .active-elm')
      .click(function (e) {
        if (e.target !== this) {
          return;
        }
        e.preventDefault();

        const that = this;
        setTimeout(function () {
          const dblclick = parseInt($(that).data('double'), 10);
          if (dblclick > 0) {
            $(that).data('double', dblclick - 1);
          } else {
            $('.active-elm.active').removeClass('active');
            $('.df-elm.active').removeClass('active');
            $(that).toggleClass('active');
            $(that).closest('.df-elm').toggleClass('active');
          }
        }, 150);
      })
      .dblclick(function (e) {
        if (e.target !== this) {
          return;
        }
        e.preventDefault();
        $(this).data('double', 2);

        if (e.target !== this) {
          return;
        }
        e.preventDefault();
        $(this).addClass('focus');
        $(this).parent().find('.form-elm').focus();
      });
    // this.resetFormClickEvent();
  }

  async resetFormClickEvent(event) {
    console.log(event);
    this.currentElement = {};
    this.currentElmType = '';
    this.formatStyle = new FormatStyle();
    this.isFormatStyleBoxVisible = false;
    this.isFormControlVisible = false;
  }

  selectElement(data) {
    this.isFocusElm = data.clickType == Constant.CLICK_TYPE.DOUBLE;
    this.selectElementDirect(data.item, data.type);
  }

  selectElementDirect(data, type) {
    this.isChange = true;
    this.currentElement = data;
    this.currentElmType = type;
    this.formatStyle = this.currentElement.formatStyle;
    this.isFormatStyleBoxVisible = true;

    switch (this.currentElmType) {
      case Constant.FORMELEMENT.OBSERVATION:
        this.isFormControlVisible = true;
        this.isCommonInfoControlVisible = false;
        this.isFormRecentControlVisible = false;
        this.panels[2].active = true;
        break;
      case Constant.FORMELEMENT.OBSERVATION_RECENT:
        this.isFormRecentControlVisible = true;
        this.isFormControlVisible = false;
        this.isCommonInfoControlVisible = false;
        this.panels[2].active = true;
        break;
      case Constant.FORMELEMENT.COMMON_INFO:
        this.isFormControlVisible = false;
        this.isFormRecentControlVisible = false;
        this.isCommonInfoControlVisible = true;
        this.panels[2].active = true;
        break;
      default:
        this.isFormControlVisible = false;
        this.isFormRecentControlVisible = false;
        this.isCommonInfoControlVisible = false;
        this.panels[2].active = false;
        break;
    }
  }

  draggable(id, pageId) {
    const $this = this;
    $(`#${id}`)
      .mousedown(function (event) {
        // $(this).draggable('option', { helper: event.ctrlKey ? 'clone' : 'original' });
        $(this).draggable({
          containment: 'parent',
          revert: 'invalid',
          helper: event.ctrlKey ? 'clone' : 'original',
          drag(e, ui) {
            $this.verticalRulers
              .get(pageId)
              .mapping(id, ui.position.top, ui.position.left, ui);
          },
          stop(e, ui) {
            $this.verticalRulers
              .get(pageId)
              .updatePos(id, ui.position.top, ui.position.left);
            $this.verticalRulers.get(pageId).stopDrag();
          },
        });
      })
      .draggable();
  }

  addCommonInfo(commonInfo: CommonInfo) {
    if (isAddable()) {
      const $this = this;
      if (!this.checkExistFormElement(commonInfo.id)) {
        return;
      }
      const id = new Date().getTime().toString();
      const pageId = $('.workingBlock').closest('.pageForm').prop('id');

      const formatStyle = new FormatStyle();
      commonInfo.viewId = id;
      commonInfo.pageId = pageId;
      commonInfo.pageIndex = 0;
      commonInfo.width = 200;
      commonInfo.height = 30;

      this.formObjects
        .get(Constant.FORMELEMENT.COMMON_INFO)
        .push({ formatStyle, ...commonInfo });
      this.changeDetectorRef.detectChanges();

      $(`#${id}`).resizable({ containment: 'parent' });
      $('#' + id).resize(function (e) {
        const elm = $this.formObjects
          .get(Constant.FORMELEMENT.COMMON_INFO)
          .filter((t) => t.viewId == id)[0];
        elm.height = e.currentTarget.offsetHeight;
        elm.width = e.currentTarget.offsetWidth;
      });

      this.verticalRulers.get(pageId).addPos(id, 0, 0);
      this.draggable(id, pageId);

      $(`#${id}`).click(function () {
        $('.pageForm div').removeClass('active');
        $(`#${id}`).toggleClass('active');
      });

      // this.resetFormClickEvent();
      this.getDomTree();
    }
  }

  addImageElmement(value) {
    if (isAddable()) {
      const $this = this;
      if (value.type == Constant.IMAGETYPE.IMAGE) {
        const id = new Date().getTime().toString();
        const pageId = $('.workingBlock').closest('.pageForm').prop('id');
        const imageData = new ImageElement();
        imageData.viewId = id;
        imageData.width = value.width;
        imageData.height = value.height;
        imageData.url = value.src;
        imageData.pageId = pageId;
        imageData.pageIndex = 0;
        imageData.name = '';
        this.formObjects.get(Constant.FORMELEMENT.IMAGE).push(imageData);
        this.changeDetectorRef.detectChanges();

        $(`#${id}`).resizable({
          aspectRatio: value.width / value.height,
          containment: 'parent',
        });

        $('#' + id).resize(function (e) {
          const elm = $this.formObjects
            .get(Constant.FORMELEMENT.IMAGE)
            .filter((t) => t.viewId == id)[0];
          elm.height = e.currentTarget.offsetHeight;
          elm.width = e.currentTarget.offsetWidth;
        });

        $(`#${id}`).click(function () {
          $('.pageForm div').removeClass('active');
          $(`#${id}`).toggleClass('active');
        });

        this.verticalRulers.get(pageId).addPos(id, 0, 0);
        this.draggable(id, pageId);
        // this.resetFormClickEvent();
        this.getDomTree();
      } else {
        const id = new Date().getTime().toString();
        const pageId = $('.workingBlock').closest('.pageForm').prop('id');
        const canvasData = new CanvasElement();
        canvasData.viewId = id;
        canvasData.width = value.width;
        canvasData.height = value.height;
        canvasData.url = value.src;
        canvasData.pageIndex = 0;
        canvasData.pageId = pageId;
        canvasData.name = '';
        this.formObjects.get(Constant.FORMELEMENT.CANVAS).push(canvasData);
        this.changeDetectorRef.detectChanges();

        $(`#${id}`).resizable({
          aspectRatio: value.width / value.height,
          containment: 'parent',
        });

        $('#' + id).resize(function (e) {
          const elm = $this.formObjects
            .get(Constant.FORMELEMENT.CANVAS)
            .filter((t) => t.viewId == id)[0];
          elm.height = e.currentTarget.offsetHeight;
          elm.width = e.currentTarget.offsetWidth;
        });

        $(`#${id}`).click(function () {
          $('.pageForm div').removeClass('active');
          $(`#${id}`).toggleClass('active');
        });

        this.verticalRulers.get(pageId).addPos(id, 0, 0);
        this.draggable(id, pageId);
        // this.resetFormClickEvent();
        this.getDomTree();
      }
    }
  }

  addLabel(id, top, left, isClone): string {
    if (isAddable()) {
      const $this = this;
      let label = new LabelElement();
      const newId = Date.now().toString();
      if (this.shareService.checkEmpty(id) && !isClone) {
      } else {
        const existLabel = this.formObjects
          .get(Constant.FORMELEMENT.LABEL)
          .filter((t) => t.viewId == id)[0];
        // console.log(existLabel);
        if (existLabel) {
          label = JSON.parse(JSON.stringify(existLabel));
        } else {
          return '';
        }
      }

      const pageId = $('.workingBlock').closest('.pageForm').prop('id');

      label.viewId = newId;
      label.top = top;
      label.left = left;
      label.pageId = pageId;
      label.pageIndex = 0;

      this.verticalRulers.get(pageId).addPos(newId, top, left);

      this.formObjects.get(Constant.FORMELEMENT.LABEL).push(label);
      this.detectChange.detectChanges();
      $('#' + newId).resizable({ containment: 'parent' });
      $('#' + newId).resize(function (e) {
        const elm = $this.formObjects
          .get(Constant.FORMELEMENT.LABEL)
          .filter((t) => t.viewId == newId)[0];
        elm.height = e.currentTarget.offsetHeight;
        elm.width = e.currentTarget.offsetWidth;
      });
      this.draggable(newId, pageId);
      // this.addLabelDbClickEvent();
      this.getDomTree();

      return newId;
    }
  }

  async addTextArea(id, top, left, isClone) {
    if (isAddable()) {
      const $this = this;
      const newId = Date.now().toString();
      let textarea = new TextAreaElement();

      if (this.shareService.checkEmpty(id) && !isClone) {
      } else {
        const existTextarea = this.formObjects
          .get(Constant.FORMELEMENT.LABEL)
          .filter((t) => t.viewId == id)[0];
        // console.log(existLabel);
        if (existTextarea) {
          textarea = JSON.parse(JSON.stringify(existTextarea));
        } else {
          return '';
        }
      }
      const pageId = $('.workingBlock').closest('.pageForm').prop('id');

      textarea.viewId = newId;
      textarea.top = top;
      textarea.left = left;
      textarea.pageIndex = 0;
      textarea.pageId = pageId;

      this.verticalRulers.get(pageId).addPos(id, top, left);
      this.formObjects.get(Constant.FORMELEMENT.TEXTAREA).push(textarea);
      this.detectChange.detectChanges();
      $('#' + newId).resizable({ containment: 'parent' });
      $('#' + newId).resize(function (e) {
        const elm = $this.formObjects
          .get(Constant.FORMELEMENT.TEXTAREA)
          .filter((t) => t.viewId == newId)[0];
        elm.height = e.currentTarget.offsetHeight;
        elm.width = e.currentTarget.offsetWidth;
      });

      this.draggable(newId, pageId);
      // this.addLabelDbClickEvent();
      this.getDomTree();
    }
  }

  add_OT_Item(value) {
    this.selectedOT = value;
    if (isAddable()) {
      let viewType = 'input';
      if (
        this.selectedOT.datatypeText == Constant.DATATYPE.SINGLECHOICE ||
        this.selectedOT.datatypeText == Constant.DATATYPE.MUILTICHOICE
      ) {
        viewType = 'selection';
      }

      if (
        this.selectedOT.datatypeText == Constant.DATATYPE.BOOLEAN ||
        this.selectedOT.datatypeText == Constant.DATATYPE.POSITIVENEGATIVE
      ) {
        viewType = 'checkbox';
      }

      if (!this.checkExistFormElement(this.selectedOT.id)) {
        return;
      }

      const $this = this;
      const id = new Date().getTime().toString();
      const formElement = new FormElement(
        id,
        this.selectedOT,
        Constant.EDITABLE,
        viewType
      );
      const pageId = $('.workingBlock').closest('.pageForm').prop('id');
      formElement.pageId = pageId;
      formElement.pageIndex = 0;

      this.verticalRulers.get(pageId).addPos(id, 0, 0);
      this.formObjects.get(Constant.FORMELEMENT.OBSERVATION).push(formElement);
      this.observationItemInstances.push(formElement);
      if (
        this.selectedOT.observationType &&
        this.selectedOT.observationType.privacyUse > this.formMinScope
      ) {
        this.formMinScope = this.selectedOT.observationType.privacyUse;
      }

      this.detectChange.detectChanges();
      $('#' + id).resizable({ containment: 'parent' });
      $('#' + id).resize(function (e) {
        const elm = $this.formObjects
          .get(Constant.FORMELEMENT.OBSERVATION)
          .filter((t) => t.viewId == id)[0];
        elm.height = e.currentTarget.offsetHeight;
        elm.width = e.currentTarget.offsetWidth;
      });

      this.draggable(id, pageId);
      this.getDomTree();
    }
  }

  add_OT_Recent(value) {
    this.selectedOT = value;
    if (isAddable()) {
      let viewType = 'input';
      if (
        this.selectedOT.datatypeText == Constant.DATATYPE.SINGLECHOICE ||
        this.selectedOT.datatypeText == Constant.DATATYPE.MUILTICHOICE
      ) {
        viewType = 'selection';
      }

      if (!this.checkExistFormElement(this.selectedOT.id)) {
        return;
      }

      const $this = this;
      const id = new Date().getTime().toString();
      const formElement = new FormElement(
        id,
        this.selectedOT,
        Constant.RECENT_ELM_READONLY,
        viewType
      );
      const pageId = $('.workingBlock').closest('.pageForm').prop('id');
      formElement.pageId = pageId;
      formElement.pageIndex = 0;

      this.verticalRulers.get(pageId).addPos(id, 0, 0);
      this.formObjects
        .get(Constant.FORMELEMENT.OBSERVATION_RECENT)
        .push(formElement);
      this.observationItemInstances.push(formElement);
      if (
        this.selectedOT.observationType &&
        this.selectedOT.observationType.privacyUse > this.formMinScope
      ) {
        this.formMinScope = this.selectedOT.observationType.privacyUse;
      }

      this.detectChange.detectChanges();
      $('#' + id).resizable({ containment: 'parent' });
      $('#' + id).resize(function (e) {
        const elm = $this.formObjects
          .get(Constant.FORMELEMENT.OBSERVATION_RECENT)
          .filter((t) => t.viewId == id)[0];
        elm.height = e.currentTarget.offsetHeight;
        elm.width = e.currentTarget.offsetWidth;
      });

      this.draggable(id, pageId);
      this.getDomTree();
    }
  }

  addFormula(formula: Formula) {
    if (isAddable()) {
      const $this = this;
      const form = this.formObjects
        .get(Constant.FORMELEMENT.FORMULA)
        .filter((t) => t.formulaId == formula.formulaId)[0];
      if (form) {
        form.name = formula.name;
      } else {
        const id = new Date().getTime().toString();
        const pageId = $('.workingBlock').closest('.pageForm').prop('id');

        const formatStyle = new FormatStyle();
        formula.viewId = id;
        formula.pageId = pageId;
        formula.pageIndex = 0;
        formula.formulaId = formula.formulaId;

        this.formObjects
          .get(Constant.FORMELEMENT.FORMULA)
          .push({ formatStyle, ...formula });
        this.changeDetectorRef.detectChanges();

        $(`#${id}`).resizable({ containment: 'parent' });
        $('#' + id).resize(function (e) {
          const elm = $this.formObjects
            .get(Constant.FORMELEMENT.FORMULA)
            .filter((t) => t.viewId == id)[0];
          elm.height = e.currentTarget.offsetHeight;
          elm.width = e.currentTarget.offsetWidth;
        });

        this.verticalRulers.get(pageId).addPos(id, 0, 0);
        this.draggable(id, pageId);

        $(`#${id}`).click(function () {
          $('.pageForm div').removeClass('active');
          $(`#${id}`).toggleClass('active');
        });

        // this.resetFormClickEvent();
        this.getDomTree();
      }
    }
  }

  updateFormula(item) {
    this.currentFormulaId = item.formulaId;
    this.isCalculatedModalVisiable = true;
  }
  checkExistFormElement(id) {
    try {
      const form = this.formObjects
        .get(Constant.FORMELEMENT.OBSERVATION)
        .filter((t) => t.viedId == id)[0];
      return true;
    } catch (error) {
      return false;
    }
  }

  openAddImage(): void {
    if (isAddable()) {
      this.uploadMaxHeight = getHeight('workingBlock');
      this.uploadMaxWidth = getWidth('workingBlock');
      this.imageType = Constant.IMAGETYPE.IMAGE;
      this.isUploadFileVisiable = true;
    }
  }

  openAddCanvas(): void {
    if (isAddable()) {
      this.uploadMaxHeight = getHeight('workingBlock');
      this.uploadMaxWidth = getWidth('workingBlock');
      this.imageType = Constant.IMAGETYPE.CANVAS;
      this.isUploadFileVisiable = true;
    }
  }

  //For OT Modal
  selectOTItem(value): void {
    this.selectedOT = value;
    this.isAddInputVisiable = true;
  }

  toggleValue(value): void {
    this.formatStyle[value] = !this.formatStyle[value];
    this.isChange = true;
    // this.allPageComponent.$toggleValue(value);
  }

  excFormatHtml(command): void {
    console.log(command);
    this.doc.execCommand(command, false, null);
    // document.execCommand(command, false, null);
    // this.allPageComponent.$toggleValue(value);
  }

  updateCommonInfoElement(value): void {
    this.currentCommonInfoElement.defaultField = value.defaultField;
    this.currentCommonInfoElement.description = value.description;
    this.currentCommonInfoElement.id = value.id;
    this.currentCommonInfoElement.idx = value.idx;
    this.currentCommonInfoElement.isDelete = value.isDelete;
    this.currentCommonInfoElement.name = value.name;
    this.currentCommonInfoElement.title = value.title;
  }

  updateElement(value): void {
    this.currentFormElement.editType = value.editType;
    this.formObjects
      .get(Constant.FORMELEMENT.OBSERVATION)
      .filter((t) => t.viewId == value.viewId);
    for (const i in this.formObjects.get(Constant.FORMELEMENT.OBSERVATION)) {
      if (
        this.formObjects.get(Constant.FORMELEMENT.OBSERVATION)[i].viewId ==
        value.viewId
      ) {
        this.formObjects.get(Constant.FORMELEMENT.OBSERVATION)[i] = value;
        break; //Stop this loop, we found it!
      }
    }
  }

  updateRecentElement(value): void {
    this.formObjects
      .get(Constant.FORMELEMENT.OBSERVATION_RECENT)
      .filter((t) => t.viewId == value.viewId);
    for (const i in this.formObjects.get(
      Constant.FORMELEMENT.OBSERVATION_RECENT
    )) {
      if (
        this.formObjects.get(Constant.FORMELEMENT.OBSERVATION_RECENT)[i]
          .viewId == value.viewId
      ) {
        this.formObjects.get(Constant.FORMELEMENT.OBSERVATION_RECENT)[i] =
          value;
        break; //Stop this loop, we found it!
      }
    }
  }

  selectValue(value): void {
    this.isChange = true;
    this.formatStyle[value.attr] = value.value;
    // this.allPageComponent.$selectValue(value.key, value.value);
  }
  alignContainer(value): void {
    this.isChange = true;
    if ($('.div_select').hasClass('Container-AL-center')) {
      $('.div_select').removeClass('Container-AL-center');
    } else if ($('.div_select').hasClass('Container-AL-left')) {
      $('.div_select').removeClass('Container-AL-left');
    } else if ($('.div_select').hasClass('Container-AL-right')) {
      $('.div_select').removeClass('Container-AL-right');
    }

    $('.div_select').addClass('Container-AL-' + value.value);
  }

  /* ****************Preview********************************/
  showModalPreview() {
    this.isVisiblePreviewModal = true;
  }

  getHtmlPrevew() {
    $('.ant-modal').css({ top: '0px', 'padding-bottom': '0px' });
    $('.ant-modal-body').css({
      height: 'calc(100vh - 130px)',
      overflow: 'auto',
      'background-color': '#efefef',
    });
    $('#preview').html($('#whole-page-content').clone());
    $('#preview .workingBlock').removeClass('workingBlock');
    $('#preview .active').removeClass('active');
    $('#preview .multi-hightlight').removeClass('multi-hightlight');
    $(
      '#preview .form-label,#preview .form-textarea,#preview .default-elm.active,#preview .image-element,#preview .wrapper-elm,#preview input,#preview .info-element'
    ).css('border', 'none');
    $(
      '#preview .hsplitter,#preview .vsplitter,#preview .ui-resizable-handle'
    ).remove();
    const $this = this;
    $this.zoom = 100;
    let time;
    $('.ant-modal-body').bind('mousewheel DOMMouseScroll', function (event) {
      if (event.ctrlKey == true) {
        $this.showZoom = true;
        clearTimeout(time);
        time = setTimeout(() => {
          $this.showZoom = false;
        }, 1000);
        event.preventDefault();
        if (event.originalEvent.deltaY < 0) {
          if ($this.zoom < 200) {
            $this.zoom = $this.zoom + 10;
            $('#preview').css('zoom', $this.zoom + '%');
          }
        } else if (event.originalEvent.deltaY > 0) {
          if ($this.zoom > 10) {
            $this.zoom = $this.zoom - 10;
            $('#preview').css('zoom', $this.zoom + '%');
          }
        }
      }
    });
  }

  preview_Modal_handleOk(): void {
    this.isVisiblePreviewModal = false;
  }

  /*********************DOM Tree*********************************************/
  getDomTree() {
    const $this = this;
    function getPageObjectDomTree(idPage, index) {
      if ($('#' + idPage).hasClass('blockCanAddItem') == false) {
        const blockCanAddItems = $('#' + idPage).find('.blockCanAddItem');
        const childObjectList = [];
        for (let i = 0; i < blockCanAddItems.length; i++) {
          childObjectList.push(
            getBlockObjectDomTree(blockCanAddItems[i].id, i + 1)
          );
        }
        return {
          title: 'Page ' + (index + 1),
          key: '#' + idPage,
          selected: $('#' + idPage).hasClass('selectedPageForm'),
          expanded: true,
          children: childObjectList,
        };
      } else {
        const items = $('#' + idPage).children();
        const childObjList = [];

        for (let i = 0; i < items.length; i++) {
          const id = items[i].id;
          const UInode = getUIForTreeNode(id);
          if (UInode.title != 'Unknow') {
            childObjList.push(getItemObjDomTree(id, UInode));
          }
        }

        return {
          title: 'Page' + (index + 1) + ' (No layout)',
          key: '#' + idPage,
          expanded: true,
          selected: $('#' + idPage).hasClass('workingBlock'),
          children: childObjList,
        };
      }
    }

    function getBlockObjectDomTree(idBlock, index) {
      const items = $('#' + idBlock).children();
      const childObjList = [];

      for (let i = 0; i < items.length; i++) {
        const id = items[i].id;
        const UInode = getUIForTreeNode(id);
        if (UInode.title != 'Unknow') {
          childObjList.push(getItemObjDomTree(id, UInode));
        }
      }

      return {
        title: 'block ' + index,
        key: '#' + idBlock,
        expanded: true,
        selected: $('#' + idBlock).hasClass('workingBlock'),
        children: childObjList,
      };
    }

    function getItemObjDomTree(idItem, UInode) {
      // let UInode = getUIForTreeNode(idItem);

      const objdata = {
        title: UInode.title,
        key: '#' + idItem,
        selected: $('#' + idItem).hasClass('active'),
        isLeaf: true,
        icon: UInode.icon,
      };
      // if () {
      //   objdata.selected = true;
      // }
      return objdata;
    }

    function getUIForTreeNode(idItem) {
      const type = $('#' + idItem).attr('df-type');
      let title = '';
      let icon = '';
      switch (type) {
        case Constant.FORMELEMENT.LABEL:
          title += $this.formObjects
            .get(Constant.FORMELEMENT.LABEL)
            .filter((t) => t.viewId == idItem)[0].value;
          icon = Constant.FORMELEMENT.LABEL;
          break;
        case Constant.FORMELEMENT.CHECKBOX:
          title += $this.formObjects
            .get(Constant.FORMELEMENT.CHECKBOX)
            .filter((t) => t.viewId == idItem)[0].value;
          icon = Constant.FORMELEMENT.CHECKBOX;
          break;
        case Constant.FORMELEMENT.TEXTAREA:
          title += $this.formObjects
            .get(Constant.FORMELEMENT.TEXTAREA)
            .filter((t) => t.viewId == idItem)[0].value;
          icon = Constant.FORMELEMENT.TEXTAREA;
          break;
        case Constant.FORMELEMENT.IMAGE:
          title = $this.formObjects
            .get(Constant.FORMELEMENT.IMAGE)
            .filter((t) => t.viewId == idItem)[0].url;
          icon = Constant.FORMELEMENT.IMAGE;
          break;
        case Constant.FORMELEMENT.OBSERVATION:
          title += $this.formObjects
            .get(Constant.FORMELEMENT.OBSERVATION)
            .filter((t) => t.viewId == idItem)[0].observationType.name;
          icon = Constant.FORMELEMENT.OBSERVATION;
          break;
        case Constant.FORMELEMENT.OBSERVATION_RECENT:
          title += $this.formObjects
            .get(Constant.FORMELEMENT.OBSERVATION_RECENT)
            .filter((t) => t.viewId == idItem)[0].observationType.name;
          icon = Constant.FORMELEMENT.OBSERVATION_RECENT;
          break;
        case Constant.FORMELEMENT.COMMON_INFO:
          title += $this.formObjects
            .get(Constant.FORMELEMENT.COMMON_INFO)
            .filter((t) => t.viewId == idItem)[0].description;
          icon = Constant.FORMELEMENT.COMMON_INFO;
          break;
        case Constant.FORMELEMENT.CANVAS:
          title = $this.formObjects
            .get(Constant.FORMELEMENT.CANVAS)
            .filter((t) => t.viewId == idItem)[0].url;
          icon = Constant.FORMELEMENT.CANVAS;
          break;
        default:
          title += 'Unknow';
          break;
      }
      return {
        title,
        icon,
      };
    }

    const dataObjList = [];
    const pages = $('#whole-page-content .f-page-content');
    for (let i = 0; i < pages.length; i++) {
      dataObjList.push(getPageObjectDomTree(pages[i].id, i));
    }
    this.nodes = dataObjList;
  }

  activeNodeTreeResponseBlock(key) {
    // if (this.DomTree.getSelectedNodeList().length != 0)
    //   this.DomTree.getSelectedNodeList()[0].isSelected = false;
    this.DomTree.getTreeNodeByKey(key).isSelected = true;
    // if (this.DomTree.getTreeNodeByKey(key).parentNode != null)
    //   this.DomTree.getTreeNodeByKey(key).parentNode.isSelected = true;
  }

  moveToolbar(selectObj) {
    // move toolbar flow working block (selectObj : 'ten_class')
    if ($('.' + selectObj).length != 0) {
      this.allPageComponent.ishaveBlockSelected = true;
      this.allPageComponent.isPage = $('.' + selectObj).hasClass(
        'f-page-content'
      );
      this.allPageComponent.isBlockCanAddItems = $('.' + selectObj).hasClass(
        'blockCanAddItem'
      );

      let _top = getOffset(selectObj).top;
      if (_top < 65) {
        _top = 65 + 25;
      }
      const _left =
        getOffset(selectObj).left + $('.' + selectObj).outerWidth() + 10;
      $('.toolbar').css({ top: _top + 'px', left: +_left + 'px' });
    } else {
      this.allPageComponent.ishaveBlockSelected = false;
    }
  }

  async nzDomTreeClick(event: NzFormatEmitEvent) {
    if (event.node.level == 0) {
      if (event.keys.length == 0) {
        this.allPageComponent.upFocusParent();
      } else {
        await $('.workingBlock').removeClass('workingBlock');
      }
      await $(event.node.key).click();
      this.moveToolbar('selectedPageForm');
      $('.inner-content').animate(
        {
          scrollTop: $(event.node.key).parent().position().top,
        },
        500
      );
    } else if (event.node.level == 1) {
      if (event.node.isLeaf == false) {
        $(event.node.key).click();
        $('.inner-content').animate(
          {
            scrollTop:
              $(event.node.parentNode.key).parent().position().top +
              ($(event.node.key).clientRect().top -
                $(event.node.parentNode.key).clientRect().top),
          },
          500
        );
      } else {
        $(event.node.key + ' .active-elm').length == 0
          ? $(event.node.key).click()
          : $(event.node.key + ' .active-elm').click();
        $('.inner-content').animate(
          {
            scrollTop:
              $(event.node.parentNode.key).parent().position().top +
              ($(event.node.key).clientRect().top -
                $(event.node.parentNode.key).clientRect().top),
          },
          500
        );
      }
    } else {
      $(event.node.key + ' .active-elm').click();
      $('.inner-content').animate(
        {
          scrollTop:
            $(event.node.parentNode.parentNode.key).parent().position().top +
            ($(event.node.key).clientRect().top -
              $(event.node.parentNode.parentNode.key).clientRect().top),
        },
        500
      );
    }
  }

  getAllGroup() {
    this.groupMemberService.getControllableGroup().subscribe((res) => {
      if (res.isValid) {
        this.listGroup = res.jsonData;
      }
    });
  }

  async movePageUP() {
    const indexOfSelectedPage = $('.selectedPageForm').parent().index();
    if (indexOfSelectedPage > 0) {
      await $('.selectedPageForm')
        .parent()
        .insertBefore(
          $('#whole-page-content')
            .children()
            .eq(indexOfSelectedPage - 1)
        );
      $(document).trigger('updateDomTree');
      $('.inner-content').animate(
        {
          scrollTop: $('.selectedPageForm').parent().position().top,
        },
        500
      );
    }
  }
  async movePageDown() {
    const indexOfSelectedPage = $('.selectedPageForm').parent().index();
    const totalPage = $('#whole-page-content').children().length;
    if (indexOfSelectedPage < totalPage - 1) {
      await $('.selectedPageForm')
        .parent()
        .insertAfter(
          $('#whole-page-content')
            .children()
            .eq(indexOfSelectedPage + 1)
        );
      $(document).trigger('updateDomTree');
      $('.inner-content').animate(
        {
          scrollTop: $('.selectedPageForm').parent().position().top,
        },
        500
      );
    }
  }

  addSize() {
    const id = $('.f-page-content').closest('.pageForm')[0].id;
    const newHeght = $(`#${id}`).height() + this.step;
    this.updatePageHeight(id, newHeght);
    this.changeDetectorRef.detectChanges();
  }

  minusSize() {
    const id = $('.f-page-content').closest('.pageForm')[0].id;
    if ($(`#${id}`).height() > this.step) {
      const newHeght = $(`#${id}`).height() - this.step;
      this.updatePageHeight(id, newHeght);
      this.changeDetectorRef.detectChanges();
    }
  }

  updatePageHeight(id, val) {
    for (let i = 0; i < this.pages.length; i++) {
      if (this.pages[i].id == id) {
        this.pages[i].height = val;
      }
    }
  }

  async downFocusChild() {
    await $('.selectedPageForm .blockCanAddItem:first-child').addClass(
      'workingBlock'
    );
    $('.workingBlock')[0].click();
  }
  async upFocusParent() {
    await $('.workingBlock').removeClass('workingBlock');
    this.moveToolbar('selectedPageForm');
    $(document).trigger('updateDomTree');
  }

  // async deleteWorkingBlock() {
  //   if (this.isPage) {
  //     // await $('.workingBlock').parent().remove();
  //     this.moveToolbar('workingBlock');
  //     $(document).trigger('updateDomTree');
  //     console.log($('.workingBlock').closest('.pageForm').attr('id'));
  //     this.pages = this.pages.filter(
  //       (t) => t != $('.workingBlock').closest('.pageForm').attr('id')
  //     );
  //     console.log(this.pages);
  //   } else {
  //   }
  // }

  labelClickEvent(item) {
    console.log(item);
  }
}

/* ********************************************************************************************************** */
function getOffset(el) {
  const rec = document.getElementsByClassName(el)[0].getBoundingClientRect();
  return { top: rec.top + window.scrollY, left: rec.left + window.scrollX };
}

function getHeight(className) {
  return $($(`.${className}`)[0]).height();
}

async function isAddable() {
  if ($('.workingBlock').length != 0) {
    return true;
  } else {
    await $($('.f-page-content')[0]).addClass('workingBlock');
    return $('.workingBlock').length != 0;
  }
}

function getWidth(className) {
  return $($(`.${className}`)[0]).width();
}

function VerticalRuler(parentId) {
  this.verticalId = '';
  this.horizontalId = '';
  this.dom = '';
  this.isVerticalActive = false;
  this.isHorizontalActive = false;
  this.top = 0;
  this.left = 0;
  this.pos = [];
  const $this = this;

  this.init = function () {
    $this.verticalId = new Date().getTime() + 'vertical';
    const verticalElm = document.createElement('div');
    $(verticalElm).addClass('vertical-ruler');
    $(verticalElm).prop('id', $this.verticalId);
    $(`#${parentId}`).append(verticalElm);

    $this.horizontalId = new Date().getTime() + 'horizontal';
    const horizontalElm = document.createElement('div');
    $(horizontalElm).addClass('horizontal-ruler');
    $(horizontalElm).prop('id', $this.horizontalId);
    $(`#${parentId}`).append(horizontalElm);
  };

  this.activeHorizontal = function (top, left) {
    $this.top = top;
    $this.left = left;
    const cur = $(`#${$this.horizontalId}`);
    cur.css('top', `${top}px`);
    // cur.css("left", `${left}px`);
    cur.addClass('active');
  };

  this.deactiveHorizontal = function () {
    $this.top = 0;
    $this.left = 0;
    const cur = $(`#${$this.horizontalId}`);
    cur.css('top', `0px`);
    // cur.css("left", `0px`);
    cur.removeClass('active');
  };

  this.activeVertical = function (top, left) {
    $this.top = top;
    $this.left = left;
    const cur = $(`#${$this.verticalId}`);
    // cur.css("top", `${top}px`);
    cur.css('left', `${left}px`);
    cur.addClass('active');
  };

  this.deactiveVertical = function () {
    $this.top = 0;
    $this.left = 0;
    const cur = $(`#${$this.verticalId}`);
    // cur.css("top", `0px`);
    cur.css('left', `0px`);
    cur.removeClass('active');
  };

  this.updatePos = function (id, top, left) {
    console.log(id);
    for (const i in $this.pos) {
      if ($this.pos[i].id == id) {
        $this.pos[i].top = top;
        $this.pos[i].left = left;
        break;
      }
    }
  };

  this.addPos = function (id, top, left) {
    if ($this.checkExist(id)) {
      $this.updatePos(id, top, left);
    } else {
      $this.pos.push({ id, top, left });
    }
  };

  this.mapping = function (id, top, left, ui) {
    const lstTop = $this.pos.filter(
      (item) => item.id != id && Math.abs(item.top - top) < 5
    );

    const lstLeft = $this.pos.filter(
      (item) => item.id != id && Math.abs(item.left - left) < 5
    );

    if (lstTop.length > 0) {
      $this.isHorizontalActive = true;

      ui.position.top = lstTop[0].top;

      $this.activeHorizontal(lstTop[0].top, lstTop[0].left);
    } else {
      $this.isHorizontalActive = false;
      $this.deactiveHorizontal();
    }

    if (lstLeft.length > 0) {
      $this.isVerticalActive = true;
      ui.position.left = lstLeft[0].left;
      $this.activeVertical(lstLeft[0].top, lstLeft[0].left);
    } else {
      $this.isVerticalActive = false;
      $this.deactiveVertical();
    }
  };

  this.stopDrag = function () {
    $(`#${$this.verticalId}`).removeClass('active');
    $(`#${$this.horizontalId}`).removeClass('active');
  };

  this.checkExist = function (id) {
    const rs = $this.pos.filter((item) => item.id == id);

    return rs && rs.length > 0;
  };
}

async function droppableEvent(event, ui, targetDrop) {
  const parentOffset = $(targetDrop).offset();
  const positionDrop = {
    top: ui.offset.top - parentOffset.top,
    left: ui.offset.left - parentOffset.left,
  };
  const children = $(targetDrop)
    .children()
    .not('#' + ui.draggable[0].id);
  if (children.length == 0) {
    await $(targetDrop).append(
      ui.draggable.css({
        top: positionDrop.top,
        left: positionDrop.left,
      })
    );
    $(document).trigger('updateDomTree');
  } else {
    let i = 0;
    for (i = 0; i < children.length; i++) {
      if (positionDrop.top < children[i].offsetTop) {
        await ui.draggable
          .css({
            top: positionDrop.top,
            left: positionDrop.left,
          })
          .insertBefore($(children[i]));
        $(document).trigger('updateDomTree');
        break;
      } else if (
        positionDrop.top == children[i].offsetTop &&
        positionDrop.left < children[i].offsetLeft
      ) {
        await ui.draggable
          .css({
            top: positionDrop.top,
            left: positionDrop.left,
          })
          .insertBefore($(children[i]));

        $(document).trigger('updateDomTree');
        break;
      }
    }
    if (i == children.length) {
      await $(targetDrop).append(
        ui.draggable.css({
          top: positionDrop.top,
          left: positionDrop.left,
        })
      );
      $(document).trigger('updateDomTree');
    }
  }
}
