import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicineManagementComponent } from './medicine-management.component';
import { SharedModule } from 'src/app/share/share.module';

@NgModule({
  declarations: [MedicineManagementComponent],
  imports: [CommonModule, SharedModule],
  exports: [MedicineManagementComponent],
})
export class MedicineManagementModule {}
