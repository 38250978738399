import { Component, OnInit, OnDestroy } from '@angular/core';
import { SaveObservationType } from 'src/app/models/observationType.class';
import { ObservationTypeService } from '../../../../services/obserType-service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Constant } from 'src/app/share/constants/constant.class';
import { NotificationService } from 'src/app/services/notification.service';
import { GroupTreeService } from 'src/app/services/groupTree.service';
import { GroupMemberService } from 'src/app/services/group-member.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/components/app-state/app-state';
import { DatePipe } from '@angular/common';
import { CodeMapSystemOT } from 'src/app/models/designated-list.class';
import { SystemListsService } from 'src/app//services/systemLists.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ExcelService } from 'src/app/services/excel.service';
import { ShareService } from 'src/app/services/share.service';
@Component({
  selector: 'app-observation-type',
  templateUrl: './observation-type.component.html',
  styleUrls: ['./observation-type.component.scss'],
  providers: [ObservationTypeService],
})
export class ObservationTypeComponent implements OnInit, OnDestroy {
  groupIdObser = '';
  groupNameObser = '';
  emptyId = '000000000000000000000000';
  selectedRowIndex = this.emptyId;
  listObservationType = [];
  isVisible = false;
  pageIndex = 1;
  pageSize = 20;
  total = 100;
  searchOT = {
    search: '',
    category: -1,
    dataType: 0,
    groupId: this.groupIdObser,
    fromManagerPage: false,
    pageSize: this.pageSize,
    page: this.pageIndex,
  };
  searchDataFilter = [];
  selectedForms: {
    id: string;
    name: string;
  }[] = [];
  groupIds = [];
  ishidePrivacy1 = false;
  ishidePrivacy2 = false;
  ishidePrivacy3 = false;
  myIsAdminGroup = false;
  isShowChooseGroup = false;
  lstRangeProperties = [];
  isShowRangeExtra = false;
  listCodeMapSystem: CodeMapSystemOT[] = [];
  public dataForm: SaveObservationType = {
    id: '000000000000000000000000',
    groupIds: [this.groupIdObser],
    otCode: '',
    unit: '',
    name: '',
    category: 0,
    description: '',
    dataType: 1,
    dataSource: [],
    validMinValue: null,
    validMaxValue: null,
    privacyUse: 1,
    listRange: this.lstRangeProperties,
    listCodeMapSystem: [],
    isDisabled: true,
  };
  inputValue = '';
  listDatasource = [];
  authSub: Subscription;
  groupSub: Subscription;
  systemCodeDataSource: any;
  isFromManager = false;
  normalString: any;
  dataSourceTempId = 0;
  isShowImport = false;
  lstDataReadFromExcel = [];
  lstStatusFromExcel = [];
  validDataExcel = false;
  isExporting = false;

  constructor(
    private observationService: ObservationTypeService,
    private modalService: NzModalService,
    private notificationService: NotificationService,
    private group: GroupTreeService,
    private groupMemberService: GroupMemberService,
    private router: Router,
    private store: Store<AppState>,
    private datePipe: DatePipe,
    private systemListsService: SystemListsService,
    private excelService: ExcelService,
    private shareService: ShareService
  ) {}

  ngOnInit(): void {
    let isAdminGroup;
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupIdObser = data.groupId;
        this.groupNameObser = data.groupName;
        this.searchOT.fromManagerPage = this.isFromManager;
        this.searchOT.groupId = this.groupIdObser;
        isAdminGroup = data.role === 1 || data.role === 2;
      }

      this.loadObserType();
      const item = this.getItemEmptyRange();
      this.lstRangeProperties.push(item);
      this.getListSystemCode();
    });
    const url = this.router.routerState.snapshot.url;
    this.authSub = this.store
      .select((store) => store.auth.userType)
      .subscribe((res) => {
        const userType = res;
        if (
          userType.indexOf(Constant.USERROLE.SystemAdmin) >= 0 ||
          userType.indexOf(Constant.USERROLE.ObservationType) >= 0 ||
          userType.indexOf(Constant.USERROLE.Admin) >= 0
        ) {
          this.ishidePrivacy1 = false;
          if (url.indexOf('observation-type') > 0) {
            this.ishidePrivacy2 = true;
            this.ishidePrivacy3 = true;
            this.isFromManager = true;
          } else {
            this.ishidePrivacy2 = false;
            this.ishidePrivacy3 = false;
            this.dataForm.groupIds = [];
            if (!isAdminGroup) {
              this.ishidePrivacy2 = true;
            }
          }
        } else {
          this.ishidePrivacy1 = true;
          this.getGroupMemeber(userType, this.groupIdObser);
        }
      });
  }
  getGroupMemeber(userType, groupId) {
    this.groupMemberService.getMyGroupMember(groupId).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.myIsAdminGroup = res.jsonData.isAdmin || res.jsonData.isOwner;
            if (
              userType.filter((en) => en === Constant.USERROLE.Doctor).length >
              0
            ) {
              this.ishidePrivacy3 = false;
              if (this.myIsAdminGroup) {
                this.ishidePrivacy2 = false;
              } else {
                this.ishidePrivacy2 = true;
              }
            }
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
  changeSelectedPrivacy(value) {
    if (value === 2 && !this.ishidePrivacy2) {
      this.isShowChooseGroup = true;
    } else {
      this.isShowChooseGroup = false;
    }
  }
  onSearchForm(keyword) {
    if (keyword.trim().length > 1) {
      this.group.getListGroup(keyword).subscribe(
        (response) => {
          if (response !== null) {
            this.searchDataFilter = [...response];
          }
        },
        (error) => {
          console.error(error);
          this.notificationService.showNotification('', 'Lỗi data');
        }
      );
    }
  }
  selectForm(selectGroup) {
    this.selectedForms.push({ id: selectGroup.id, name: selectGroup.name });
  }
  unSelectForm(id) {
    this.selectedForms = this.selectedForms.filter((en) => en.id !== id);
  }
  loadObserType() {
    this.observationService
      .getObservationTypeByRoleManager(this.searchOT)
      .subscribe((data) => {
        if (data !== null) {
          this.total = data.itemCount;
          this.listObservationType = data.source;
        }
      });
  }
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.searchOT.pageSize = pageSize;
    this.searchOT.page = pageIndex;
    this.loadObserType();
  }
  showModal(dataForm): void {
    this.dataSourceTempId = 0;
    if (this.listCodeMapSystem.length == 0) {
      this.addCodeMap();
    }
    this.isVisible = true;
    if (
      this.listCodeMapSystem === null ||
      this.listCodeMapSystem.length === 0
    ) {
      this.listCodeMapSystem = [];
      this.addCodeMap();
    }
    if (dataForm == 'them') {
      this.selectedForms = [];
      this.selectedRowIndex = this.emptyId;
      this.lstRangeProperties = [this.getItemEmptyRange()];
      this.dataForm = {
        id: '000000000000000000000000',
        groupIds: [],
        otCode: '',
        unit: '',
        name: '',
        category: 0,
        description: '',
        dataType: 1,
        dataSource: [],
        validMinValue: null,
        validMaxValue: null,
        privacyUse: this.ishidePrivacy1 === false ? 1 : 3,
        listRange: this.lstRangeProperties,
        listCodeMapSystem: this.listCodeMapSystem,
        isDisabled: false,

      };
      this.isShowChooseGroup = false;
      const url = this.router.routerState.snapshot.url;
      if (!this.isFromManager) {
        const currGroup = {
          id: this.groupIdObser,
          name: this.groupNameObser,
        };
        this.selectedForms[0] = currGroup;
      }
    } else {
      if (dataForm.privacyUse === 2 && !this.ishidePrivacy2) {
        this.isShowChooseGroup = true;
      } else {
        this.isShowChooseGroup = false;
      }
    }

    if (dataForm.privacyUse === 2) {
      this.isShowChooseGroup = true;
    } else {
      this.isShowChooseGroup = false;
    }
  }
  handleOk(): void {
    this.isVisible = false;
  }
  handleCancel(): void {
    this.isVisible = false;
    this.listCodeMapSystem = [];
  }
  searchObser(boolean: boolean) {
    const url = this.router.routerState.snapshot.url;
    if (boolean) {
      this.searchOT = {
        search: this.inputValue == null ? '' : this.inputValue,
        category: -1,
        dataType: 0,
        groupId: this.groupIdObser,
        fromManagerPage: this.isFromManager,
        pageSize: this.pageSize,
        page: this.pageIndex,
      };
    } else {
      this.inputValue = '';
    }
    this.loadObserType();
  }
  rowSelected(u) {
    //khi chon tr se lay du lieu ra cho vao modal
    this.selectedRowIndex = u.id;
    this.listDatasource = [];
    for (const i in u.dataSource) {
      this.listDatasource.push({
        text: u.dataSource[i].text,
        valueNumber: u.dataSource[i].valueNumber,
        imageSrc: '',
        parentId: '',
        id: u.dataSource[i].id,
        status: u.dataSource[i].status,
      });
    } //get and push quetion
    this.selectedForms = u.groups == null ? [] : u.groups;
    this.dataForm = {
      id: this.selectedRowIndex,
      groupIds: this.groupIds,
      otCode: u.otCode,
      unit: u.unit == null ? '' : u.unit,
      name: u.name,
      category: u.category,
      //codeSystem: u.codesystem,
      description: u.description,
      dataType: this.toNum(u.dataType),
      dataSource: this.listDatasource,
      validMinValue: u.validMinValue,
      validMaxValue: u.validMaxValue,
      privacyUse: u.privacyUse,
      listRange: u.listRange,
      listCodeMapSystem: u.listCodeMapSystem,
      isDisabled: u.isDisabled
    };
    this.lstRangeProperties = u.listRange;
    this.listCodeMapSystem =
      u.listCodeMapSystem == null ? [] : u.listCodeMapSystem;
    if (
      (u.dataType === 4 || u.dataType === 9 || u.dataType === 1) &&
      (this.lstRangeProperties === null || this.lstRangeProperties.length === 0)
    ) {
      this.addNewRange();
    }
  }
  checkValidate(item) {
    if (
      (item.otCode != null && item.otCode.trim() == '') ||
      item.name.trim() == ''
    ) {
      this.notificationService.showNotification(
        'error',
        'Chưa nhập đủ dữ liệu bắt buộc!'
      );
      return false;
    }
    const newArr = this.listCodeMapSystem
      .map((en) => en.otherSystem)
      .filter((en) => en != '');
    const duplicate = newArr.some(function (item, idx) {
      return newArr.indexOf(item) != idx;
    });
    if (duplicate) {
      this.notificationService.showNotification(
        'error',
        'Trùng mã chỉ định theo hệ thống khác!'
      );
      return false;
    }
    return true;
  }
  addOrSave(datasend) {
    if (!this.checkValidate(datasend)) {
      return;
    }
    console.log('hhh',datasend)
    if (this.toNum(datasend.dataType) == 1 && this.lstRangeProperties != null) {
      this.lstRangeProperties.forEach((element) => {
        element.rangeTypes = [];
        if (element.enableGender) {
          element.rangeTypes.push(element.genderCode);
        }
        if (element.enableCurrTime) {
          element.rangeTypes.push(element.currTimeCode);
        }
      });
    } else if (
      this.toNum(datasend.dataType) !== 11 &&
      this.toNum(datasend.dataType) !== 9 &&
      this.toNum(datasend.dataType) !== 4
    ) {
      this.lstRangeProperties = [];
    }
    this.groupIds = this.selectedForms.map(function (a) {
      return a.id;
    });
    this.dataForm = {
      id: this.selectedRowIndex,
      groupIds: this.groupIds,
      otCode: datasend.otCode,
      unit: datasend.unit == null ? '' : datasend.unit,
      name: datasend.name,
      category: this.toNum(datasend.category),
      description: datasend.description,
      dataType: this.toNum(datasend.dataType),
      dataSource: datasend.dataSource,
      isDisabled: datasend.isDisabled,
      validMinValue:
        datasend.validMinValue === ''
          ? null
          : parseFloat(datasend.validMinValue),
      validMaxValue:
        datasend.validMaxValue === ''
          ? null
          : parseFloat(datasend.validMaxValue),
      privacyUse: this.toNum(datasend.privacyUse),
      listRange: this.lstRangeProperties.filter((en) => en.otherSystem != ''),
      listCodeMapSystem: this.listCodeMapSystem.filter(
        (en) => en.otherSystem != ''
      ),
    };
    console.log('hh', this.dataForm)
    this.changeSelectedPrivacy(this.dataForm.privacyUse);
    if (
      (this.dataForm.dataType == 9 || this.dataForm.dataType == 10) &&
      this.dataForm.dataSource.length == 0
    ) {
      this.notificationService.showNotification(
        'error',
        'Phải có ít nhất 1 câu trả lời !'
      );
      return;
    } else {
      this.observationService.saveObservationType(this.dataForm).subscribe(
        (data) => {
          if (data.errors.length > 0 || !data.isValid) {
            for (const error of data.errors) {
              this.notificationService.showNotification(
                'error',
                error.errorMessage
              );
            }
          } else {
            this.notificationService.showNotification('success', 'Thành công');
            this.listCodeMapSystem = [];
            this.isVisible = false;
            this.searchObser(true);
            this.selectedRowIndex = this.emptyId;
            this.loadObserType();
            //this.isShowChooseGroup = false;
          }
        },
        (error) => {
          this.notificationService.showNotification('error', error.statusText);
        }
      );
    }
  }
  changeProperty(type) {
    if (type !== 'normal') {
      this.isShowRangeExtra = true;
    } else {
      this.isShowRangeExtra = false;
    }
  }
  deleteOberType() {
    const id = this.selectedRowIndex;
    if (id == this.emptyId) {
      this.notificationService.showNotification(
        'warning',
        'Bạn chưa chọn dòng để xoá !'
      );
    } else {
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn xóa observation type này ?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Huỷ',
        nzOnOk: () => this.deleteItem(id),
      });
    }
  }
  deleteItem(id) {
    this.observationService.deleteObservationType(id).subscribe(
      (data) => {
        this.notificationService.showNotification('success', 'Xoá thành công');
        this.searchObser(true);
      },
      (error) => {
        this.notificationService.showNotification('error', error.statusText);
      }
    );
    this.selectedRowIndex = this.emptyId;
  }
  addQuestion() {
    this.dataSourceTempId = this.dataSourceTempId + 1;
    this.dataForm.dataSource.push({
      text: '',
      valueNumber: null,
      imageSrc: '',
      parentId: '',
      id: this.dataSourceTempId + '',
      status: true,
    });
  }
  enable(type, item) {
    const selectItem = this.lstRangeProperties.filter(
      (en) => en.id == item.id
    )[0];
    if (type == 'Age') {
      selectItem.enableAge = !item.enableAge;
      selectItem.minAge = null;
      selectItem.maxAge = null;
    } else if (type == 'Gender') {
      selectItem.enableGender = !item.enableGender;
    } else if (type == 'CurrTime') {
      selectItem.enableCurrTime = !item.enableCurrTime;
    }
  }
  getItemEmptyRange(): any {
    const sUnix = this.datePipe.transform(new Date(), 'MMddHHmmssfff');
    const item = {
      id: sUnix,
      genderCode: '',
      currTimeCode: '',
      rangeTypes: [],
      minValue: null,
      maxValue: null,
      minAge: null,
      maxAge: null,
      enableAge: false,
      enableGender: false,
      enableCurrTime: false,
      normalValue: false,
      normalString: '',
    };
    return item;
  }
  deleteQuestion(producttext) {
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Bạn có muốn xóa câu trả lời này ?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () => {
        if (this.dataForm.dataSource.length > 1) {
          this.dataForm.dataSource.splice(
            this.dataForm.dataSource.indexOf(producttext),
            1
          );
        } else {
          this.notificationService.showNotification(
            'warning',
            'Phải có ít nhất 1 câu trả lời !'
          );
        }
      },
    });
  }
  addNewRange() {
    if (this.lstRangeProperties == null) {
      this.lstRangeProperties = [];
    }
    const newRange = this.getItemEmptyRange();
    this.lstRangeProperties.push(newRange);
  }
  deleteRange(item) {
    this.lstRangeProperties = this.lstRangeProperties.filter(
      (en) => en.id !== item.id
    );
  }
  toggleStatus(data) {
    this.dataForm.dataSource[this.dataForm.dataSource.indexOf(data)].status =
      this.dataForm.dataSource[this.dataForm.dataSource.indexOf(data)].status ==
      false
        ? true
        : false;
  }
  ngOnDestroy(): void {
    this.authSub.unsubscribe();
    this.groupSub.unsubscribe();
  }
  toNum(text): number {
    switch (text) {
      case '0':
        return 0;
      case 'Number':
      case '1':
        return 1;
      case 'Range':
      case '2':
        return 2;
      case 'MoreLess':
      case '3':
        return 3;
      case 'Boolean':
      case '4':
        return 4;
      case 'String':
      case '5':
        return 5;
      case 'Time':
      case '6':
        return 6;
      case 'Date':
      case '7':
        return 7;
      case 'DateTime':
      case '8':
        return 8;
      case 'SingleChoice':
      case '9':
        return 9;
      case 'MultipleChoice':
      case '10':
        return 10;
      case '99':
        return 99;
      default:
        return text;
    }
  }
  addCodeMap() {
    if (this.listCodeMapSystem.length < this.systemCodeDataSource.length) {
      this.listCodeMapSystem.push({
        otherSystem: '',
        codeOther: '',
      });
    }
  }
  removeCodeMap(index) {
    this.listCodeMapSystem.splice(index, 1);
  }
  getListSystemCode() {
    this.systemListsService.getSystemCodeAll().subscribe((res) => {
      if (res.isValid) {
        this.systemCodeDataSource = res.jsonData;
      }
    });
  }
  openImport() {
    this.isShowImport = !this.isShowImport;
  }
  import() {
    if (this.validDataExcel) {
      this.observationService
        .insertMany(this.lstDataReadFromExcel)
        .subscribe((res) => {
          if (res.isValid) {
            this.loadObserType();
            this.isShowImport = false;
            this.lstDataReadFromExcel = [];
            this.lstStatusFromExcel = [];
            this.validDataExcel = false;
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.SUCCESS,
              'Import thành công'
            );
          } else {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.ERROR,
              res.errors[0].errorMessage
            );
          }
        });
    }
  }
  onFileChange(evt: any) {
    const blob = evt.target.files[0];
    this.observationService.readExcel(blob).subscribe((res) => {
      if (res.isValid) {
        const data = res.jsonData;
        this.lstDataReadFromExcel = [...data.listOT];
        this.lstStatusFromExcel = data.listStatus;
        this.validDataExcel = data.valid;
        if (this.lstDataReadFromExcel.length == 0) {
          this.validDataExcel = false;
        }
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }
  clearValue(event) {
    if (event.value != '') {
      $('#fileOpen').val('');
    }
  }
  downloadFile(url: string, filename: string) {
    this.excelService.downloadFile(url).subscribe(
      (response) => {
        const blob: any = new Blob([response], {
          type: 'text/json; charset=utf-8',
        });
        const url = window.URL.createObjectURL(blob);
        this.shareService.saveLocalFile(url, filename);
      },
      (error) => {
        console.log('Error downloading the file');
        this.notificationService.showNotification(
          'error',
          'Error downloading the file'
        );
      }
    );
  }
  export() {
    const payload = {
      search: '',
      category: -1,
      dataType: 0,
      groupId: this.groupIdObser,
      fromManagerPage: false,
      pageSize: 0,
      page: 0,
    };
    this.isExporting = true;
    this.observationService.exportData(payload).subscribe((res) => {
      if (res) {
        const filename = 'Danh sách Observation Type';
        const dataType = res.type;
        const binaryData = [];
        binaryData.push(res);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        if (filename) {
          downloadLink.setAttribute('download', filename);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.isExporting = false;
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Không thể xuất file, vui lòng liên hệ KTV'
        );
        this.isExporting = false;
      }
    });
  }
  // updateStatus(data,ruleId,idx) {
  //   if (!this.shareService.checkEmpty(ruleId)) {
  //     const type = data.isDisabled ? 'enable' : 'disable';
  //   this.modalService.confirm({
  //     nzTitle: 'Confirm',
  //     nzContent: 'Bạn có muốn <b>' + type + '</b> chỉ định này không?',
  //     nzOkText: 'Đồng ý',
  //     nzCancelText: 'Huỷ',
  //     nzOnOk: () => {
  //       this.observationService
  //         .updateStatus(this.selectedRowIndex)
  //         .subscribe((res) => {
  //           if (res.isValid) {
  //             this.notificationService.showNotification(
  //               Constant.NOTIFY_TYPE.SUCCESS,
  //               'Cập nhật thành công'
  //             );
  //             data.isDisabled = !data.isDisabled;
  //           } else {
  //             this.notificationService.showNotification(
  //               Constant.NOTIFY_TYPE.ERROR,
  //               res.errors[0].errorMessage
  //             );
  //           }
  //         });
  //     },
  //   });
  //   }
    
  // }

  updateStatus(isDisabled,idx) {
    
      const type = isDisabled ? 'enable' : 'disable';
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn <b>' + type + '</b> chỉ định này không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Huỷ',
        nzOnOk: () => {
          this.observationService
            .updateStatus(this.selectedRowIndex)
            .subscribe((res) => {
              if (res.isValid) {
                this.notificationService.showNotification(
                  Constant.NOTIFY_TYPE.SUCCESS,
                  'Cập nhật thành công'
                );
                console.log('h',this.listObservationType[idx])
                console.log('h',idx);
                this.listObservationType[idx].isDisabled = !isDisabled;
              } else {
                this.notificationService.showNotification(
                  Constant.NOTIFY_TYPE.ERROR,
                  res.errors[0].errorMessage
                );
              }
            });
        },
      });
    
  }

}
