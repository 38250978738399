import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppState } from 'src/app/components/app-state/app-state';
import { ExaminationServiceService } from 'src/app/services/examination-service.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { ShareService } from 'src/app/services/share.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-examination-service',
  templateUrl: './examination-service.component.html',
  styleUrls: ['./examination-service.component.scss'],
})
export class ExaminationServiceComponent implements OnInit, AfterViewInit {
  isVisible = false;
  groupSub;
  total;
  loading = false;
  formData: FormGroup;
  pageSize = 20;
  pageIndex = 1;
  listRowDisplay;
  searchModal = false;
  rowSelected;
  searchValue;
  searchResult;
  isAdminGroup;
  groupId;
  rowSelectedIndex;
  callbackSearchFn;
  noWhiteSpacePattern = /[\S]/;
  payloadInit = {
    'take': this.pageSize,
    'skip': 0,
    'keyword': null,
    'groupId': null,
    'code': null,
  };
  constructor(
    private examinationServiceS: ExaminationServiceService,
    private fb: FormBuilder,
    private modalService: NzModalService,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    private shareService: ShareService
  ) {
    this.formData = this.fb.group({
      id: [null],
      name: [
        '',
        [Validators.required, Validators.pattern(this.noWhiteSpacePattern)],
      ],
      code: [
        null,
        [Validators.required, Validators.pattern(this.noWhiteSpacePattern)],
      ],
      price: [
        null,
        [
          Validators.required,
          Validators.pattern(this.noWhiteSpacePattern),
          Validators.min(0),
        ],
      ],
      isDeleted: [false],
    });

    this.callbackSearchFn = this.shareService.debounce(
      this.onSearchChange,
      200
    );
  }

  ngOnInit(): void {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        this.payloadInit.groupId = this.groupId;
        this.getExaminationService(this.payloadInit);
        this.isAdminGroup = data.role === 1 || data.role === 2;
      }
    });
    // for (let index = 100; index < 200; index++) {
    //   this.addExaminationService({
    //     'id': 'string',
    //     'name': `test ${index}`,
    //     'code': `code${index}`,
    //     'price': 1000,
    //     'groupId': '61e0e35720213552454c1397',
    //   });
    // }
  }
  ngAfterViewInit(): void {
    // this.renderer.listen()
  }
  getExaminationService(payload) {
    this.examinationServiceS.getAllExaminationService(payload).subscribe(
      (res) => {
        this.listRowDisplay = res.jsonData.data;
        this.isVisible = false;
        this.total = res.jsonData.total;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  addExaminationService(payload) {
    this.examinationServiceS.addExaminationService(payload).subscribe((res) => {
      this.resultUI(res, 'Thêm mới thành công');
    });
  }

  editExaminationService(payload) {
    this.examinationServiceS
      .editExaminationService(payload)
      .subscribe((res) => {
        this.resultUI(res, 'Sửa thành công');
      });
  }

  deleteExaminationService(id) {
    this.examinationServiceS.deleteExaminationService(id).subscribe((res) => {
      this.resultUI(res, 'Xóa thành công');
    });
  }

  getExaminationServiceById(id) {
    this.examinationServiceS.getExaminationServiceById(id).subscribe((res) => {
      this.resultUI(res, 'Get thành công');
    });
  }

  ///////////

  handleCancel() {
    this.isVisible = false;
    this.rowSelectedIndex = -1;
    this.rowSelected = null;
    this.formData.reset();
    this.payloadInit.keyword = null;
    this.searchModal = false;
  }
  handleOk() {
    this.isVisible = false;
  }

  clickRow(data) {
    this.rowSelected = data;
    this.rowSelectedIndex = data.id;
  }
  showAddModal() {
    this.isVisible = true;
    this.rowSelected = null;
    this.formData.patchValue({ 'isDeleted': false });

    this.formData.get('code').enable();
  }
  showEditModal() {
    this.isVisible = true;
    if (this.rowSelected) this.formData.patchValue(this.rowSelected);
  }

  ////////////

  add() {
    if (!this.formData.valid) {
      Object.values(this.formData.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }

    if (this.formData.valid) {
      // this.notificationService.showNotification('error',"Dữ liệu không hợp lệ")
      console.log(this.groupId);

      this.addExaminationService(
        Object.assign(this.formData.value, {
          groupId: this.groupId
        })
      );
    }
  }

  edit() {
    if (!this.formData.valid) return;
    this.editExaminationService(
      Object.assign(this.formData.getRawValue(), { groupId: this.groupId })
    );
  }
  delete() {
    let id = this.rowSelected.id;
    this.modalService.confirm({
      nzTitle: 'Xác nhận',
      nzContent: 'Bạn có muốn xóa hay không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Hủy',
      nzOnOk: () => this.deleteExaminationService(id),
    });
  }
  changeStatus(data) {
    this.modalService.confirm({
      nzTitle: 'Xác nhận',
      nzContent: `Bạn có thay đổi trạng thái thành <b>${data.isDeleted?'Enable':'Disable'}</b> không?`,
      nzOkText: 'Đồng ý',
      nzCancelText: 'Hủy',
      nzOnOk: () =>
        this.editExaminationService({ ...data, isDeleted: !data.isDeleted }),
    });
  }

  //////
  resultUI(response, message) {
    if (response.isValid) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.SUCCESS,
        message
      );
      this.searchModal = false;
      this.isVisible = false;
      this.formData.reset();
      this.rowSelected = null;
      this.payloadInit.keyword = null;
      this.getExaminationService(this.payloadInit);
    } else {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        response.errors[0].errorMessage
      );
    }
  }
  ///////

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    this.getExaminationService(
      Object.assign(this.payloadInit, {
        take: pageSize,
        skip: pageSize * (pageIndex - 1),
      })
    );
  }

  selectSearch(data) {
    this.getExaminationService({ ...this.payloadInit, keyword: data });
  }

  enterSearch(keyword) {
    this.getExaminationService(
      Object.assign(this.payloadInit, { keyword: keyword })
    );
  }
  onSearchChange() {
    // if(this.searchValue=='')return;
    let fakeElement = { name: this.searchValue };

    this.examinationServiceS
      .getAllExaminationService(
        Object.assign(this.payloadInit, {
          keyword: this.searchValue,
          take: 20,
          skip: 0,
        })
      )
      .subscribe(
        (res) => {
          this.searchResult = res.jsonData.data;
          if (this.searchValue != '') {
            this.searchResult.unshift(fakeElement);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  clear() {
    this.searchValue = '';
    this.payloadInit.keyword='';
    this.getExaminationService({
      ...this.payloadInit,
      keyword: this.searchValue,
    });
  }
}
