<div *ngIf="data.src" (click)="showImage()" class="conv-image-wrapper">
  <div class="conv-image-content">
    <img [src]="data.src" />
  </div>
</div>
<div
  #imageWrapper
  *ngIf="!data.src"
  [innerHTML]="data.message"
  (click)="showImageInMessage()"
  class="image-wrapper"></div>
