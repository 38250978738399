import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { Observable } from 'rxjs';
import { UrlConstant } from '../share/constants/url.class';
import { PostOT, ObservationType, SaveObservationType } from '../models/observationType.class';
@Injectable()
export class ObservationTypeService extends BaseService {
  getObservationType(search: PostOT): Observable<ObservationType> {
    return this.post(UrlConstant.OBSERVATION_TYPE, search);
  }
  getObservationTypeByRole(search: PostOT): Observable<ObservationType> {
    return this.post(UrlConstant.OBSERVATION_TYPE_BY_ROLE, search);
  }
  getByGroup(search: PostOT): Observable<any[]> {
    return this.post(UrlConstant.OBSERVATION_TYPE_BY_GROUP, search);
  }
  getObservationTypeByRoleManager(search: PostOT): Observable<any> {
    return this.post(UrlConstant.OBSERVATION_TYPE_BY_ROLE_MANAGER, search);
  }
  getObservationTypeForForm(search: PostOT): Observable<ObservationType> {
    return this.post(UrlConstant.OBSERVATION_TYPE_FOR_FORM, search);
  }
  saveObservationType(data: SaveObservationType) {
    return this.post(UrlConstant.SAVE_OBSERVATION_TYPE, data);
  }
  deleteObservationType(id: string) {
    return this.delete(UrlConstant.DELETE_OBSERVATION_TYPE + '/' + id, '');
  }
  GetListOTOrderByGroupId(groupId: string): Observable<any> {
    return this.get(UrlConstant.OBSERVATION_TYPE_ORDER + '/' + groupId);
  }
  readExcel(postedFile: Blob): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('postedFile', postedFile);
    return this.post(UrlConstant.EXCEL_OBSERVATION_TYPE, formData);
  }
  exportData(payload): Observable<any> {
    return this.post(UrlConstant.API_OBSERVATION_TYPE + '/ExportData', payload, '', 'blob');
  }
  insertMany(payload): Observable<any> {
    return this.post(UrlConstant.INSERT_OBSERVATION_TYPE, payload);
  }
  updateStatus(id): Observable<any> {
    return this.post(UrlConstant.API_OBSERVATION_TYPE + '/UpdateStatus/' + id, '');
  }
}
