import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
@Directive({
  selector: '[appDirectiveFocusInput]',
})
export class DirectiveFocusInputDirective {
  @Input() defaultColor: boolean;

  @Input('appDirectiveFocusInput') highlightColor: boolean;
  // constructor(public element: ElementRef<HTMLElement>) {

  // }
  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef // private renderer: Renderer2
  ) {
    //      this.highlight(this.focused);
  }

  // ngAfterViewInit() {

  // }
  // @HostListener('focus') onFocus() {
  //   console.log('focus0',focus);
  //  // this.cdr.detectChanges();
  //   console.log('focus1',focus);
  //   setTimeout(() => this.highlight(this.focused), 0);

  // }

  @HostListener('click') onClick() {
    this.highlight(this.highlightColor);

    //this.editable.toViewMode();

    //  this.cdr.detectChanges();
  }
  private highlight(focus: boolean) {
    if (focus) {
      this.elementRef.nativeElement.focus();
      // this.cdr.detectChanges();
    }
  }

  // ngAfterViewChecked(){
  //   this.highlight(this.focused);
  //    //your code to update the model
  //    this.cdr.detectChanges();
  // }
  // ngAfterViewInit(): void {
  //   // ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
  //   if (this.focused) {
  //     setTimeout(() => this.element.nativeElement.focus(), 0);
  //   }
  // }
}
