import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'thumbnailSrc',
})
export class ThumbnailSrcPipe implements PipeTransform {
  transform(fileName: string, baseImageUrl: string) {
    if (fileName.indexOf('http') == 0 || baseImageUrl == '') {return fileName;}
    return baseImageUrl + '/Thumb/' + fileName;
  }
}
