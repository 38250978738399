import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';
import { Constant } from '../share/constants/constant.class';
import { DoctorInGroup } from '../models/doctor-in-group.model';

@Injectable({
  providedIn: 'root',
})
export class GroupDashboardService extends BaseService {
  groupStatistic(groupId: any): Observable<any> {
    return this.get(`/api/Group/Statistic/${groupId}`);
  }
  latestStatistic(groupId: any, type: number): Observable<any> {
    const payload = {
      groupId,
      type,
    };
    return this.get(`/api/Group/LatestStatistic/${groupId}`, payload);
  }
  logStatistict(groupId: any, type: number): Observable<any> {
    const payload = {
      groupId,
      type,
    };
    return this.get(`/api/Group/LogLatestStatistic/${groupId}`, payload);
  }

  closeGroup(groupId): Observable<any> {
    return this.delete(UrlConstant.CLOSE_GROUP, groupId);
  }

  reopenGroup(groupId): Observable<any> {
    return this.get(UrlConstant.REOPEN_GROUP + groupId);
  }

  getAllCovidGroup(): Observable<any> {
    return this.get(UrlConstant.GET_COVID_GROUP);
  }
}
