import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as controlAction from './control.action';
import {
  createFeatureSelector,
  createSelector,
  createAction,
  props,
} from '@ngrx/store';
import { ControlState } from 'src/app/models/control-state';

export const modalAdapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const defaultModal: ControlState = {
  reloadCancelOrder: false,
  visitId: '',
};

/// Helper function to create new state object
const newState = (state, newData) => Object.assign({}, state, newData);

// export const initialState = modalAdapter.getInitialState(defaultModal);
export function controlReducer(
  state = defaultModal,
  action: controlAction.ControlActions
) {
  switch (action.type) {
    case controlAction.ControlAction.ReloadCancelOrder: {
      return newState(state, {
        reloadCancelOrder: action.newState,
        visitId: action.visitId,
      });
    }
    default:
      return state;
  }
}

// const getModalFeatureState = createFeatureSelector<ModalState>(
//     'modals'
// );

// export const getModal = createSelector(
//     getModalFeatureState,
//     modalAdapter.getSelectors().selectAll
// );

// export const getAddVisit = createSelector(
//     getModalFeatureState,
//     (state: ModalState) => state.isVisitOpen
// );

// export const getAddPatientOpen = createSelector(
//     getModalFeatureState,
//     (state: ModalState) => state.isPatientOpen
// );

// export const getDuplicateModal = createSelector(
//     getModalFeatureState,
//     (state: ModalState) => state.isDuplicateOpen
// );
