import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationService } from 'src/app/services/notification.service';
import { ReportService } from 'src/app/services/report.service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AppState } from '../../app-state/app-state';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { GroupMemberService } from 'src/app/services/group-member.service';
import { GroupDashboardService } from 'src/app/services/group-dashboard.service';

@Component({
  selector: 'app-report-summary',
  templateUrl: './report-summary.component.html',
  styleUrls: ['./report-summary.component.scss'],
})
export class ReportSummaryComponent implements OnInit {
  selectedGroupId = '000000000000000000000000';
  selectedGroupName = '';
  commonData = {
    totalPatient: 0,
    totalBeingTreatedPatient: 0,
    totalEndOfTreatmentPatient: 0,
    totalCoordinator: 0,
    totalDoctor: 0,
    totalNurse: 0,
    totalPharmacist: 0,
    totalPsychiatrist: 0,
  };
  range = 7;
  isExporting = false;
  isExportPrescription = false;
  isAdminGroup = false;
  covidGroups: any[] = [];

  constructor(
    private shareService: ShareService,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private groupService: GroupDashboardService,
    @Inject(PLATFORM_ID) private platformId,
    private zone: NgZone,
    private store: Store<AppState>
  ) {}

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnInit() {
    this.getAllCovidGroup();
    this.getAllData();
  }

  getCurrentInfo() {
    const payload = { groupId: this.selectedGroupId };
    this.reportService.getCurrentInfo(payload).subscribe((res) => {
      if (res.isValid) {
        this.commonData = res.jsonData;
      }
    });
  }

  getAllCovidGroup() {
    this.groupService.getAllCovidGroup().subscribe((res) => {
      if (res.isValid) {
        this.covidGroups.push({
          id: '000000000000000000000000',
          name: 'Tất cả',
        });
        this.covidGroups.push(...res.jsonData);
        console.log(this.covidGroups);
      }
    });
  }

  getAllData() {
    this.getCountinuousInfo();
    this.getCurrentInfo();
    this.getSOSRange();
  }

  getCountinuousInfo() {
    const date = new Date();
    const last7Day = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    const today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    last7Day.setDate(today.getDate() - (this.range - 1));

    const payload = {
      groupId: this.selectedGroupId,
      startDate: last7Day,
      endDate: today,
    };
    this.reportService.getContinuousInfo(payload).subscribe((res) => {
      if (res.isValid) {
        res.jsonData.forEach((element) => {
          element.dateString = this.shareService.toDDMMYY(
            new Date(element.date)
          );
        });
        this.initContinuousXYChart(res.jsonData);
      }
    });
  }

  getSOSRange() {
    const payload = { groupId: this.selectedGroupId };
    this.reportService.getSOSRangeInfo(payload).subscribe((res) => {
      this.initSOSPieChart(res.jsonData);
    });
  }

  initSOSPieChart(data) {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);

      const chart = am4core.create('soschartdiv', am4charts.PieChart);
      // Add data
      chart.data = [
        {
          type: 'Xanh',
          patient: data.sos1,
          color: am4core.color('#dbfee3'),
        },
        {
          type: 'Vàng',
          patient: data.sos2,
          color: am4core.color('#fff59e'),
        },
        {
          type: 'Đỏ',
          patient: data.sos3,
          color: am4core.color('#ffaeae'),
        },
        {
          type: 'Red flags',
          patient: data.sos4,
          color: am4core.color('#ff3636'),
        },
      ];
      // Add and configure Series
      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = 'patient';
      pieSeries.dataFields.category = 'type';
      pieSeries.slices.template.propertyFields.fill = 'color';
      chart.radius = am4core.percent(50);
      // chart.legend = new am4charts.Legend();
    });
  }

  initContinuousXYChart(data) {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      // Themes end
      const chart = am4core.create('xychartdiv', am4charts.XYChart);
      chart.colors.step = 2;
      chart.scrollbarX = new am4charts.XYChartScrollbar();
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineY.disabled = true;
      chart.cursor.lineX.disabled = true;
      // chart.plotContainer.visible = false;

      chart.legend = new am4charts.Legend();
      chart.legend.position = 'top';
      chart.legend.paddingBottom = 20;
      chart.legend.labels.template.maxWidth = 95;

      const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      xAxis.dataFields.category = 'dateString';
      xAxis.renderer.cellStartLocation = 0.1;
      xAxis.renderer.cellEndLocation = 0.9;
      xAxis.renderer.grid.template.location = 0;

      const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
      yAxis.min = 0;

      function createSeries(value, name) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = value;
        series.dataFields.categoryX = 'dateString';
        series.name = name;

        series.events.on('hidden', arrangeColumns);
        series.events.on('shown', arrangeColumns);
        series.tooltipText = '{valueY}';
        // let bullet = series.bullets.push(new am4charts.LabelBullet())
        // bullet.interactionsEnabled = false
        // bullet.dy = 30;
        // bullet.label.text = '{valueY}'
        // bullet.label.fill = am4core.color('#ffffff')

        return series;
      }

      chart.data = data;

      createSeries('beingTreatedPatient', 'Đang điều trị');
      createSeries('newPatient', 'Bệnh nhân mới');
      createSeries('endOfTreatmentPatient0', 'Khỏi bệnh');
      createSeries('endOfTreatmentPatient1', 'Tử vong');
      createSeries('endOfTreatmentPatient2', 'Chuyển viện');
      createSeries('waitingPatient', 'Đang chờ tiếp nhận');
      function arrangeColumns() {
        const series = chart.series.getIndex(0);

        const w =
          1 -
          xAxis.renderer.cellStartLocation -
          (1 - xAxis.renderer.cellEndLocation);
        if (series.dataItems.length > 1) {
          const x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX');
          const x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX');
          const delta = ((x1 - x0) / chart.series.length) * w;
          if (am4core.isNumber(delta)) {
            const middle = chart.series.length / 2;

            let newIndex = 0;
            chart.series.each(function(series) {
              if (!series.isHidden && !series.isHiding) {
                series.dummyData = newIndex;
                newIndex++;
              } else {
                series.dummyData = chart.series.indexOf(series);
              }
            });
            const visibleCount = newIndex;
            const newMiddle = visibleCount / 2;

            chart.series.each(function(series) {
              const trueIndex = chart.series.indexOf(series);
              const newIndex = series.dummyData;

              const dx = (newIndex - trueIndex + middle - newMiddle) * delta;

              series.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              );
              series.bulletsContainer.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              );
            });
          }
        }
      }
    });
  }

  backHome() {
    window.location.href = '/common/worklist';
  }
}
