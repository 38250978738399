import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-authorized',
  templateUrl: './authorized.component.html',
  styleUrls: ['./authorized.component.scss'],
})
export class AuthorizedComponent implements OnInit {
  constructor(private route: Router) {}

  ngOnInit(): void {}

  backHome() {
    this.route.navigate(['/']);
  }
}
