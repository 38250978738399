import { Component, Input, OnInit } from '@angular/core';
import { PatientService } from '../../../services/patient.service';
import { NotificationService } from '../../../services/notification.service';
import { TabDataService } from '../../../share/base-service/data-worklist.service';
import { AppConfigService } from 'src/app-config.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AddressService } from 'src/app/services/address.service';
import { ShareService } from 'src/app/services/share.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss'],
})
export class PatientInfoComponent implements OnInit {
  @Input() patientId: any;
  @Input() isEdit = false;
  @Input() inVisit = false;
  @Input() visitId: string;
  patient: any;
  listHistory: Object[];

  personalInfo: FormGroup;
  contact: FormGroup;
  medicalInfo: FormGroup;
  employer: FormGroup;
  statInfo: FormGroup;
  guardian: FormGroup;
  otherInfo: FormGroup;

  header?: any = {
    Authorization: 'Bearer ' + localStorage.getItem(Constant.TOKEN),
  };
  profileStatus = '';
  avatarUrl = '';
  uploadUrl = '';
  loading = false;
  imgloading = false;
  submitted = false;

  groupSub: Subscription;
  lstDoctor = [];
  listProvince = [];
  listDistrict = [];
  listWard = [];
  allFormTab = [];

  visibleProfile = false;
  selectedIndex = 0;

  constructor(
    private patientService: PatientService,
    private notificationService: NotificationService,
    private tabDataService: TabDataService,
    private fb: FormBuilder,
    private addressService: AddressService,
    private shareService: ShareService,
    private configService: AppConfigService,
    private doctorService: DoctorService,
    private store: Store<AppState>,
    private modalService: NzModalService
  ) {
    this.uploadUrl =
      this.configService.getConfig().api.baseUrl +
      '/api/Upload/uploadFile?TypeUpload=upload_avatar&FileName=1145564.jpg';
    this.personalInfo = this.fb.group({
      id: [null],
      fullName: [null, [this.noWhitespaceValidator]],
      sex: [null],
      dob: [null, [Validators.required]],
      maritalStatus: [null],
      idNumber: [null, [Validators.minLength(9), Validators.maxLength(12)]],
      avatar: [null],
      userDefined: [null],
    });
    this.contact = this.fb.group({
      address: [null],
      provinceId: [null],
      districtId: [null],
      wardId: [null],
      phoneNo: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(11)]],
      otherPhoneNo: [null],
      email: [null],
      emergencyContactPerson: [null],
      emergencyContactPhone: [null],
    });
    this.medicalInfo = this.fb.group({
      presenterDoctor: [null],
      sourceInfo: [null],
      allowPhone: [null],
      allowEmail: [null],
      usePatientPortal: [null],
      bhytCode: [null],
      validDate: [null],
      expireDate: [null],
      treatedHospital: [null],
      vaccineHistory: [null],
      medicalHistory: [null],
      habit: [null],
    });
    this.employer = this.fb.group({
      occupation: [null],
      position: [null],
      employerName: [null],
      employerAddress: [null],
    });
    this.statInfo = this.fb.group({
      ethnicity: [null],
      religion: [null],
      language: [null],
      income: [null],
    });
    this.guardian = this.fb.group({
      guardianName: [null],
      relationship: [null],
      guardianYob: [null],
      guardianSex: [null],
      guardianAddress: [null],
      guardianPhone: [null],
      guardianEmail: [null],
    });
    this.otherInfo = this.fb.group({
      deadTime: [null],
      deadReason: [null],
    });
    this.allFormTab = [
      { index: 0, form: this.personalInfo, isEditing: false },
      { index: 1, form: this.contact, isEditing: false },
      { index: 2, form: this.medicalInfo, isEditing: false },
      { index: 3, form: this.employer, isEditing: false },
      { index: 4, form: this.statInfo, isEditing: false },
      { index: 5, form: this.guardian, isEditing: false },
      { index: 6, form: this.otherInfo, isEditing: false },
    ];
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.getDoctorInGroup(data.groupId);
      }
    });
  }
  get personalInfoData() {
    return this.personalInfo.controls;
  }
  get contactData() {
    return this.contact.controls;
  }
  get employerData() {
    return this.employer.controls;
  }
  get statInfoData() {
    return this.statInfo.controls;
  }
  get guardianData() {
    return this.guardian.controls;
  }
  get otherInfoData() {
    return this.otherInfo.controls;
  }

  ngOnInit(): void {
    this.getPatient();
    this.getVisitHistory();
    this.getProvince();
  }

  getVisitHistory(): void {
    const req = { patientId: this.patientId, top: 0, take: -1 };
    this.patientService.getVisitHistory(req).subscribe((res) => {
      this.listHistory = res.source;
    });
  }

  newVisitTab(id, PatientName, patientId): void {
    this.tabDataService.updateTab(id, PatientName, 'VisitDetail', patientId);
  }
  enableEdit(index) {
    this.allFormTab[index].isEditing = true;
    this.allFormTab[index].form.enable();
  }
  backtoView(index) {
    this.allFormTab[index].isEditing = false;
    this.allFormTab[index].form.disable();
    this.patchValueForm(this.patient);
  }

  selectedIndexChange(event) {
    let curForm = this.allFormTab.find((en) => en.isEditing == true);
    if (curForm == null) return;
    if (event != curForm.index && !this.shareService.checkEmpty(curForm)) {
      this.modalService.confirm({
        nzTitle: 'Xác nhận',
        nzContent: 'Bạn có muốn lưu thông tin vừa nhập không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Đóng',
        nzOnOk: () => {
          this.save(curForm.index);
        },
        nzOnCancel: () => {
          this.backtoView(curForm.index);
        },
      });
    }
  }
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true, error: true };
  }

  validDateAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.shareService.checkEmpty(control.value)) {
        observer.next(null);
      } else {
        if (control.value > new Date()) {
          observer.next({ error: true, validDate: true });
        } else {
          const validExpire =
            this.personalInfo &&
            (this.shareService.checkEmptyObj(this.personalInfo.value.expireDate) ||
              control.value < this.personalInfo.value.expireDate);
          if (!validExpire) {
            observer.next(null);
          } else {
            observer.next(null);
          }
        }
      }
      observer.complete();
    });
  validBHYTAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.shareService.checkEmpty(control.value) && this.personalInfo && this.personalInfo.value.expireDate) {
        observer.next({ error: true, empty: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });
  expireDateAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.shareService.checkEmptyObj(control.value)) {
        observer.next(null);
      } else {
        const validExpire =
          this.personalInfo &&
          (this.shareService.checkEmptyObj(this.personalInfo.value.validDate) ||
            control.value > this.personalInfo.value.validDate);
        if (!validExpire) {
          observer.next({ error: true, duplicated: true });
          observer.next(null);
        } else {
          observer.next(null);
        }
      }
      observer.complete();
    });
  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        observer.complete();
        return;
      }
      const isLt2M = (file.size ?? 0) / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, 'Image must smaller than 2MB!');
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng && isLt2M);
      observer.complete();
    });

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.imgloading = true;
        break;
      case 'done':
        this.avatarUrl = info.file.thumbUrl;
        this.personalInfo.controls.avatar.setValue(info.file.response.fileName);
        break;
      case 'error':
        this.imgloading = false;
        break;
    }
  }
  getProvince() {
    this.addressService.getProvince().subscribe((res) => {
      if (res.isValid && res.jsonData.length > 0) {
        this.listProvince = res.jsonData;
      }
    });
  }
  getDistrictByProvinceId(id) {
    if (this.shareService.checkEmpty(id)) {
      this.contact.controls['districtId'].setValue(null);
      this.listDistrict = [];
      return;
    }
    this.addressService.getDistrictByProvinceId(id).subscribe((res) => {
      if (res.isValid) {
        this.listDistrict = res.jsonData;
      }
    });
  }
  getWardByDistrictId(id) {
    if (this.shareService.checkEmpty(id)) {
      this.contact.controls['wardId'].setValue(null);
      this.listWard = [];
      return;
    }
    this.addressService.getWardByProvinceId(id).subscribe((res) => {
      if (res.isValid) {
        this.listWard = res.jsonData;
      }
    });
  }
  getPatient() {
    this.patientService.getPatient(this.patientId).subscribe((res) => {
      if (res.isValid) {
        this.patient = res.jsonData;
        this.patchValueForm(res.jsonData);
        this.allFormTab.forEach((en) => {
          en.form.disable();
        });
      }
    });
  }
  patchValueForm(data) {
    this.personalInfo.patchValue({
      id: data.id,
      fullName: data.fullName,
      sex: data.sex,
      dob: new Date(data.dob),
      maritalStatus: data.maritalStatus,
      idNumber: data.idNumber,
      avatar: data.avatar,
      userDefined: data.userDefined,
    });
    this.contact.patchValue({
      address: data.address,
      provinceId: data.provinceId,
      districtId: data.districtId,
      wardId: data.wardId,
      phoneNo: data.phoneNo,
      otherPhoneNo: data.otherPhoneNo,
      email: data.email,
      emergencyContactPerson: data.emergencyContactPerson,
      emergencyContactPhone: data.emergencyContactPhone,
    });
    this.medicalInfo.patchValue({
      presenterDoctor: data.presenterDoctor,
      sourceInfo: data.sourceInfo,
      allowPhone: data.allowPhone,
      allowEmail: data.allowEmail,
      usePatientPortal: data.usePatientPortal,
      validDate: data.validDate,
      expireDate: data.expireDate,
      treatedHospital: data.treatedHospital,
      vaccineHistory: data.vaccineHistory,
      medicalHistory: data.medicalHistory,
      habit: data.habit,
    });
    this.employer.patchValue({
      occupation: data.occupation,
      position: data.position,
      employerName: data.employerName,
      employerAddress: data.employerAddress,
    });
    this.personalInfo.patchValue({
      ethnicity: data.ethnicity,
      religion: data.religion,
      language: data.language,
      income: data.income,
    });
    this.personalInfo.patchValue({
      guardianName: data.guardianName,
      relationship: data.relationship,
      guardianYob: data.guardianYob,
      guardianSex: data.guardianSex,
      guardianAddress: data.guardianAddress,
      guardianPhone: data.guardianPhone,
      guardianEmail: data.guardianEmail,
    });
    this.personalInfo.patchValue({
      deadTime: data.deadTime,
      deadReason: data.deadReason,
    });
    if (!this.shareService.checkEmpty(data.avatar)) {
      this.avatarUrl = this.configService.getConfig().media.baseUrl + '/Avatar/' + data.avatar;
    }
  }
  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.lstDoctor = res;
      }
    });
  }
  save(index) {
    let form = this.allFormTab[index].form;
    this.submitted = true;
    if (form.valid) {
      this.loading = true;
      let payload = {
        ...this.patient,
        ...form.value,
      };
      this.patientService.updatePatient(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
          console.log('hh', form.value.fullName)
          this.openNewTab(this.patient.id,  form.value.fullName);
          this.loading = false;
          this.submitted = false;
          this.allFormTab[index].isEditing = false;
          this.allFormTab[index].form.disable();
          this.patient = { ...this.patient, ...form.value };
          this.backtoView(index);
        }
        else{
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
          this.loading = false;
          this.submitted = false;
        }
      });
    } else {
      this.selectedIndex = index;
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Kiểm tra lại các trường bắt buộc');
    }
  }
  openNewTab(id, PatientName): void {
    this.tabDataService.updateTab(id, PatientName, 'PatientInfoEdit', id);
  }
  editPatientInfo(id, PatientName, visitId): void {
    //truyền vào id, tên...
    this.tabDataService.updateTab(id, PatientName, 'PatientInfoEdit', visitId);
    // //kiểm tra xem có tab chưa, chưa có  mở tab
    // this.enableEditPatientInfo = true;
    // //sau khi mở tab thì show giao diện sửa
  }
  showTabPatientInfo(id, PatientName, visitId): void {
    this.tabDataService.updateTab(id, PatientName, 'PatientInfo', visitId);
  }
  showModal() {
    if (this.patient.activeStatus == 0) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.WARRING,
        'BN đã nhận được email và kích hoạt tài khoản. Bạn không cần gửi lại nữa!'
      );
    } else if (this.patient.activeStatus == 2) {
      this.modalService.confirm({
        nzTitle: 'Thông báo',
        nzContent: 'Bạn đã gửi thông tin cho BN, bạn có muốn gửi lại không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Bỏ qua',
        nzOnOk: () => {
          this.visibleProfile = true;
        },
      });
    } else {
      this.visibleProfile = true;
    }
  }
  closeProflie() {
    this.visibleProfile = false;
  }
  getStatus(s) {
    this.profileStatus = s;
    this.visibleProfile = false;
  }
  changeEmail(email) {
    this.patient.email = email;
    this.personalInfo.controls['email'].setValue(email);
  }
}
