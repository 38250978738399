<div class="form-input center-input" style="width: 100%">
  <div [ngClass]="[
      data.formatStyle.isBold == true ? 'bold' : '',
      data.formatStyle.isItalic == true ? 'italic' : '',
      data.formatStyle.isUnderline == true ? 'underline' : '',
      data.formatStyle.align
    ]" [ngStyle]="{
      'color': data.formatStyle.color,
      'background': data.formatStyle.bgColor,
      'font-size': data.formatStyle.fontSize + 'px',
      'font-family': data.formatStyle.font
    }">
    <ng-container *ngIf="!data.referId; then thenBlock; else elseBlock">
    </ng-container>
    <ng-template #thenBlock>
      <ng-container *ngIf="data.dataType == null || data.dataType == 5 ">
        <span class="text-mg-l">{{
          value[data.defaultField]
          }}</span></ng-container>
      <ng-container *ngIf="data.dataType == 8">
        <span class="text-mg-l">{{
          updateValue.valueString
          }}</span></ng-container>
      <ng-container *ngIf="data.dataType == 99">
        <span class="text-mg-l">{{
          updateValue.valueNumber
          }}</span></ng-container>
      <ng-container *ngIf="data.dataType != null && data.dataType == 7">
        <span class="text-mg-l">{{
          value[data.defaultField] | date: 'dd/MM/yyyy'
          }}</span>
      </ng-container>
    </ng-template>
    <ng-template #elseBlock>
      <input *ngIf="data.dataType == null || data.dataType == 5" nz-input [(ngModel)]="updateValue.valueString"
        (ngModelChange)="ngOnModelChange()" style="width: 100%" />
      <!-- <input *ngIf="data.dataType == 99" nz-input [(ngModel)]="updateValue.valueNumber"
        (ngModelChange)="ngOnModelChange()" style="width: 100%" /> -->
      <nz-date-picker *ngIf="data.dataType != null && data.dataType == 7" nzFormat="dd/MM/yyyy"
        [(ngModel)]="updateValue.valueDateTime" (ngModelChange)="ngOnModelChange()"></nz-date-picker>
    </ng-template>
  </div>
</div>