<nz-modal
  [(nzVisible)]="isVisible"
  [nzTitle]="
    isAddOder ? 'Thêm' : 'Cập nhật' + ' y lệnh cho BN ' + visit.patientName
  "
  nzWidth="700px"
  nzMaskClosable="false"
  (nzOnCancel)="cancelAdd()">
  <div>
    <div class="row mg-bt-10">
      <div class="col-3">Danh mục y lệnh <span class="red-dot">*</span></div>
      <div class="col-9">
        <nz-select
          class="w-100"
          [(ngModel)]="currentOrder.medicalOrderId"
          (ngModelChange)="selectOrderChange($event)"
          nzAllowClear
          nzPlaceHolder="Chọn loại y lệnh">
          <nz-option
            nzCustomContent
            *ngFor="let medical of lstOrderSource"
            [nzValue]="medical.id"
            [nzLabel]="medical.name">
            <div>{{ medical.name }}</div>
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Tên y lệnh <span class="red-dot">*</span></div>
      <div class="col-9">
        <input
          nz-input
          type="text"
          [(ngModel)]="currentOrder.name"
          placeholder="Nhập tên y lệnh" />
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Mô tả</div>
      <div class="col-9">
        <input
          nz-input
          type="text"
          [(ngModel)]="currentOrder.desc"
          placeholder="Nhập mô tả nội dung" />
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Người thực hiện</div>
      <div class="col-9">
        <nz-select
          class="w-100"
          [(ngModel)]="currentOrder.executorId"
          nzAllowClear
          nzPlaceHolder="Chọn người thực hiện">
          <nz-option
            nzCustomContent
            *ngFor="let user of userInGroup"
            [nzValue]="user.id"
            [nzLabel]="user.fullName">
            <div>{{ user.fullName }}</div>
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Ngày thực hiện</div>
      <div class="col-9">
        <nz-date-picker
          style="width: 200px"
          nzPlaceHolder="DD/MM/YYYY"
          nzFormat="dd/MM/yyyy"
          [(ngModel)]="currentOrder.executeDate">
        </nz-date-picker>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-small" (click)="cancelAdd()">
      Đóng
    </button>
    <button nz-button class="btn-primary-small" (click)="saveMedicalOrder()">
      Lưu
    </button>
  </div>
</nz-modal>
