import { async } from '@angular/core/testing';
import { reject } from 'underscore';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
import { ImageElement } from 'src/app/models/form-model/form.class';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  AngularEditorComponent,
  AngularEditorConfig,
} from '@kolkov/angular-editor';
import { AppConfigService } from 'src/app-config.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UploadService } from 'src/app/services/upload.service';
import { VisitService } from 'src/app/services/visit.service';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs/internal/Observable';
import { Observer } from 'rxjs';
import { Constant } from 'src/app/share/constants/constant.class';
import { ShareService } from 'src/app/services/share.service';
declare let $: any;

@Component({
  selector: 'app-masternote-enlarge',
  templateUrl: './masternote-enlarge.component.html',
  styleUrls: ['./masternote-enlarge.component.scss'],
})
export class MasternoteEnlargeComponent implements OnInit {
  _isVisible = false;

  @Input() set isVisible(val: boolean) {
    if (val) {
      this.getMasterNoteContent();
      this.initEditor();
    }
    this._isVisible = val;
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  @Input() noteId = 'master-note-id';

  @Output() closeModal = new EventEmitter();
  constructor(
    private visitService: VisitService,
    private uploadService: UploadService,
    private notificationService: NotificationService,
    private renderer: Renderer2,
    private tabDataService: TabDataService,
    private configService: AppConfigService,
    private elementRef: ElementRef,
    private shareService: ShareService,
    private cd: ChangeDetectorRef
  ) {}

  isUpload = false;
  isLoading = false;
  editorConfig: AngularEditorConfig = {
    editable: true,
    height: '400px',
    minHeight: '200px',
    maxHeight: '400px',
    width: '100%',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage']],
  };
  content = '';
  patientName = 'Master Note';
  visitDate: Date = new Date();

  selectedId: any;
  isVisibleImageEditor = false;
  isVisibleInsertSection: boolean;
  selectedImage: any;
  isTrigger: boolean;
  curImageUrl: any;
  realWidth: any;
  realHeight: any;
  baseImageurl: any;
  imgWidth: any;
  imgHeight: any;

  isImageLock: boolean;
  saved: any = {};
  selectedSection: any;
  sel: any;

  @ViewChild('htmlEditor') htmlEditor: AngularEditorComponent;
  @ViewChild('materNoteEnlarge') materNoteEnlarge: ElementRef;

  ngOnInit() {
    this.isImageLock = true;
  }

  initEditor() {
    const $this = this;
    setTimeout(function () {
      $this.bindImageUpload();

      /*btn image*/
      const $insertImage = $(
        '<div class="angular-editor-toolbar-set"><a class="insert-img a-custom"><span class="photo-icon"></span></a></div>'
      );
      $($insertImage).click(function () {
        $this.bindInsertImage();
      });
      const elm = $this.materNoteEnlarge.nativeElement;
      $(elm).find('.angular-editor-toolbar').append($insertImage);
    }, 500);
  }

  syncHtml2NgModel() {
    const $this = this;
    this.content = this.htmlEditor.textArea.nativeElement.innerHTML;
    this.cd.detectChanges();
  }

  async bindImageUpload() {
    const $this = this;
    const elm = $this.materNoteEnlarge.nativeElement;
    await $(elm).find('.angular-editor-wrapper img').unbind('click');
    $(elm)
      .find('.angular-editor-wrapper img')
      .click(function () {
        $this.isVisibleImageEditor = true;
        $this.selectedImage = $(this);
        const src = $(this).attr('src');
        // $(this).css("border","dotted 2px #AAA");
        $('#txtImageUrl').val(src);
        $this.curImageUrl = src;

        const myImg = $(this)[0];

        if (myImg instanceof HTMLImageElement) {
          $this.realWidth = myImg.naturalWidth;
          $this.realHeight = myImg.naturalHeight;

          $this.imgWidth = $(this).width();
          $this.imgHeight = $(this).height();
        }
        // alert("Original width=" + $this.realWidth + ", " + "Original height=" + $this.realHeight);
      });
  }

  bindInsertImage() {
    // saved = [ sel.focusNode, sel.focusOffset ];
    this.sel = document.getSelection();
    this.saved.node = this.sel.focusNode;
    this.saved.offset = this.sel.focusOffset;
    const $this = this;
    if (this.selectedImage) {
      $(this.selectedImage).click();
    } else {
      this.curImageUrl = '';
      this.isVisibleImageEditor = true;
    }
  }

  bindInsertSection() {
    this.sel = document.getSelection();
    this.saved.node = this.sel.focusNode;
    this.saved.offset = this.sel.focusOffset;
    this.isVisibleInsertSection = true;
  }

  handleChangeContactAvatar(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        break;
      case 'done':
        this.curImageUrl = this.baseImageurl + info.file.response.uploadUrl;
        break;
      case 'error':
        alert('Network error');
        break;
    }
  }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        alert('You can only upload JPG/PNG file!');
        observer.complete();
        return;
      }
      const isLt2M = (file.size ?? 0) / 1024 / 1024 < 2;
      if (!isLt2M) {
        alert('Image must smaller than 2MB!');
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng && isLt2M);
      observer.complete();
    });
  handleUploadContactAvatar = (item: any) => {
    const $this = this;
    $this.imgWidth = null;
    $this.imgHeight = null;
    const blob = item.file;

    this.isLoading = true;
    this.uploadService.saveFile(blob).subscribe(
      (res) => {
        new Promise((resolve, reject) => {
          this.curImageUrl =
            this.configService.getConfig().media.baseUrl +
            '/VisitImage/' +
            res.fileName;
          resolve(true);
        }).then((res) => {
          setTimeout(() => {
            const myImg = $('#uploadImg')[0];
            if (myImg) {
              $this.realWidth = myImg.naturalWidth;
              $this.realHeight = myImg.naturalHeight;
            } else {
              $this.realWidth = 100;
              $this.realHeight = 100;
            }
            $this.imgWidth = $this.realWidth;
            $this.imgHeight = $this.realHeight;
          }, 500);
          this.isLoading = false;
        });
      },
      (error) => {
        console.log(error);
        this.notificationService.showNotification(
          Constant.ERROR,
          error.message.toString()
        );
      }
    );
  };

  handleImageOk() {
    new Promise((resolve, reject) => {
      this.isVisibleImageEditor = false;
      //document.execCommand('insertImage', false, '<img>');
      if (this.selectedImage) {
        $(this.selectedImage).attr('src', $('#txtImageUrl').val());

        if (
          !this.imgWidth ||
          this.imgWidth === 0 ||
          !this.imgHeight ||
          this.imgHeight === 0
        ) {
          $(this.selectedImage).attr('style', 'max-width:100%');
        } else {
          $(this.selectedImage).attr(
            'style',
            `max-width: 100%;width:${this.imgWidth}px;height:${this.imgHeight}px`
          );
        }
      } else {
        let insertImg = '';
        if (
          !this.imgWidth ||
          this.imgWidth === 0 ||
          !this.imgHeight ||
          this.imgHeight === 0
        ) {
          insertImg = '<img src="' + $('#txtImageUrl').val() + '"/>';
        } else {
          let className = '';
          if (this.imgWidth > this.imgHeight) {
            className = 'note-img-ver';
          } else {
            className = 'note-img-hor';
          }
          insertImg =
            '<img class=' +
            className +
            ' style="' +
            `width:${this.imgWidth}px;height:${this.imgHeight}px` +
            '" src="' +
            $('#txtImageUrl').val() +
            '"/>';
        }
        this.sel.collapse(this.saved.node, this.saved.offset);
        document.execCommand('insertHTML', false, insertImg);
      }
      this.selectedImage = null;
      this.syncHtml2NgModel();
      resolve(true);
    }).then((res) => {
      this.bindImageUpload();
      this.curImageUrl = '';
      this.imgWidth = 0;
      this.imgHeight = 0;
    });
  }

  async updateNotes(chat) {
    const content = await this.shareService.urlify(this.content);
    const payload = {
      id: this.noteId,
      content,
    };
    this.visitService.updateVisitMasterNote(payload).subscribe(
      (resdata) => {
        this.notificationService.showNotification(
          Constant.SUCCESS,
          'Cập nhật note thành công'
        );
        this.closeModal.emit({ noteId: this.noteId, reload: true });
      },
      (error) => {
        this.notificationService.showNotification('', error.statusText);
      }
    );
  }

  getMasterNoteContent() {
    this.visitService.getMasterNoteById(this.noteId).subscribe((res) => {
      if (res.isValid) {
        this.content = res.jsonData.content;
        this.visitDate = res.jsonData.visitDate;
        // this.patientName = res.jsonData.patientName;
      }
    });
  }

  handleCancel() {
    this.isVisibleImageEditor = false;
    this.closeModal.emit({ noteId: this.noteId, reload: false });
  }

  handleCancelShowImage() {
    this.isVisibleImageEditor = false;
    this.curImageUrl = '';
    this.imgWidth = 0;
    this.imgHeight = 0;
  }

  onPaste(event: ClipboardEvent) {
    const clipboardItems = event.clipboardData.items;
    const items = [].slice.call(clipboardItems).filter(function (item) {
      // Filter the image items only
      return item.type.indexOf('image') !== -1;
    });
    if (items.length === 0) {
      return;
    }

    const item = items[0];
    this.isUpload = true;
    const blob = item.getAsFile();
    this.uploadFileDirective(blob);

    event.preventDefault();
  }

  toImageStyle() {
    if (
      !this.imgWidth ||
      this.imgWidth === 0 ||
      !this.imgHeight ||
      this.imgHeight === 0
    ) {
      return '';
    }
    return `width: ${this.imgWidth}px;height: ${this.imgHeight}px`;
  }

  changeImageHeight() {
    if (this.isImageLock) {
      if (!isNaN(this.imgHeight)) {
        this.imgWidth = parseInt(
          '' + (this.imgHeight * this.realWidth) / this.realHeight
        );
      } else {
        this.imgWidth = 0;
      }
    } else {
    }
  }

  changeImageWidth() {
    if (this.isImageLock) {
      if (!isNaN(this.imgWidth)) {
        this.imgHeight = parseInt(
          '' + (this.imgWidth * this.realHeight) / this.realWidth
        );
      } else {
        this.imgHeight = 0;
      }
    } else {
    }
  }

  uploadFileDirective(data): void {
    const $this = this;

    try {
      const node = document.createElement('IMG');

      this.uploadService.saveFile(data).subscribe(
        (resdata) => {
          const img = new Image();
          const src =
            this.configService.getConfig().media.baseUrl +
            '/VisitImage/' +
            resdata.fileName;
          img.addEventListener('load', function () {
            if (this.naturalWidth > this.naturalHeight) {
              node.classList.add('note-img-hor');
            } else {
              node.classList.add('note-img-ver');
            }

            node.setAttribute('src', src);
            node.setAttribute('is', 'show-image');

            const selection = window.getSelection();
            if (!selection.rangeCount) {
              return false;
            }
            selection.deleteFromDocument();
            selection.getRangeAt(0).insertNode(node);
            $this.isUpload = false;
            $this.bindImageUpload();
          });
          img.src = src;
        },
        (error) => {
          this.notificationService.showNotification('', error.statusText);
          this.isUpload = false;
        }
      );
    } catch (error) {
      console.error(error);
      this.isUpload = false;
    }
  }

  toggleLockImage() {
    this.isImageLock = !this.isImageLock;
  }
}
