<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Cập nhật trạng thái y lệnh"
  nzWidth="700px"
  nzMaskClosable="false"
  (nzOnCancel)="cancelUpdateStatus()">
  <div>
    <div class="row mg-bt-10">
      <div class="col-3">Thông tin y lệnh</div>
      <div class="col-9">
        <div>
          BN {{ visit.patientName }} - {{ getPatientAge(visit.dob) }} -
          {{ visit.patientSexText }}
        </div>
        <div>Y lệnh: {{ currentOrder.name }}</div>
        <div>
          BS ra y lệnh: {{ currentOrder.creatorName }} ({{
            currentOrder.executeDate | date: 'dd/MM/yyyy HH:mm'
          }})
        </div>
        <div>Trạng thái: {{ currentOrder.statusText }}</div>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Trạng thái</div>
      <div class="col-9">
        <nz-select
          class="w-100"
          [(ngModel)]="formUpdateStatus.status"
          nzPlaceHolder="Chọn trạng thái">
          <nz-option [nzValue]="1" nzLabel="Mới tạo/Chưa thực hiện"></nz-option>
          <nz-option [nzValue]="2" nzLabel="Đã từ chối"></nz-option>
          <nz-option [nzValue]="3" nzLabel="Đã hủy"></nz-option>
          <nz-option [nzValue]="4" nzLabel="Đã thực hiện"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Thời gian thực hiện</div>
      <div class="col-9">
        <nz-date-picker
          style="width: 200px"
          nzPlaceHolder="DD/MM/YYYY"
          nzFormat="dd/MM/yyyy"
          [(ngModel)]="formUpdateStatus.executeDate">
        </nz-date-picker>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Nội dung thêm</div>
      <div class="col-9">
        <textarea nz-input [(ngModel)]="formUpdateStatus.otherInfo"></textarea>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-small" (click)="cancelUpdateStatus()">
      Đóng
    </button>
    <button nz-button class="btn-primary-small" (click)="updateStatus()">
      Cập nhật
    </button>
  </div>
</nz-modal>
