import { EditFormStaticModule } from './admin-pages/edit-form-static/edit-form-static.module';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';

import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AdminLayoutRoutingModule } from './admin-routing.module';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
// import {ActionsSubject, select, Store} from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { from, Subscription } from 'rxjs';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../share/share.module';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
//import { ObservationTypeComponent } from './admin-pages/observation-type/observation-type.component';
import { FormMngtComponent } from './admin-pages/formMngt/formMngt.component';
import { ListFormService } from 'src/app/services/listForm-service';
import { SubclinicalorderService } from 'src/app/services/subclinicalorder.service';
import { AngularSplitModule } from 'angular-split';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { RoscategoryComponent } from './admin-pages/roscategory/roscategory.component';
import { SubclinicalorderComponent } from './admin-pages/subclinicalorder/subclinicalorder.component';
//import { MedicineManagementComponent } from './admin-pages/medicine-management/medicine-management.component';
import { LogManagementComponent } from './admin-pages/log-management/log-management.component';
import { CategoryComponent } from './admin-pages/category/category.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { DanhSachBaiVietComponent } from './admin-pages/danh-sach-bai-viet/danh-sach-bai-viet.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TargetBlankDirective } from 'src/app/directive/target-blank.directive';
import { ObservationTypeModule } from './admin-pages/observation-type/observation-type.module';
import { SystemListsComponent } from './admin-pages/system-lists/system-lists.component';
import { MedicineManagementModule } from './admin-pages/medicine-management/medicine-management.module';
import { MedicalInstructionComponent } from './admin-pages/medical-instruction/medical-instruction.component';
import { CommonInfoManagementModule } from './admin-pages/common-info-management/common-info-management.module';
import { RulesManagermentModule } from './admin-pages/rules-managerment/rules-managerment.module';
import { ManagementMethodComponent } from './admin-pages/management-method/management-method.component';
//import { RulesManagermentComponent } from './admin-pages/rules-managerment/rules-managerment.component';


const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map((key) => antDesignIcons[key]);
@NgModule({
  declarations: [
    AdminLayoutComponent,
    // ObservationTypeComponent,
    FormMngtComponent,
    RoscategoryComponent,
    SubclinicalorderComponent,
    // MedicineManagementComponent,
    LogManagementComponent,
    CategoryComponent,
    DanhSachBaiVietComponent,
    SystemListsComponent,
    MedicalInstructionComponent,
    ManagementMethodComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([]),
    AdminLayoutRoutingModule,
    NzDropDownModule,
    NzBreadCrumbModule,
    AngularSplitModule.forRoot(),
    NzAffixModule,
    NzPopoverModule,
    // NewEditFormModule,
    EditFormStaticModule,
    TreeModule,
    CKEditorModule,
    ObservationTypeModule,
    CommonInfoManagementModule,
    MedicineManagementModule,
    RulesManagermentModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons },
    ListFormService,
    SubclinicalorderService,
  ],
})
export class AdminModule {}
