<div class="header {{ isMobile ? 'mobile-panel' : '' }}">
  <div class="table-userinfo">
    <div class="tuserInfo">
      <div class="avatar">
        <div class="fileava">
          <button class="icon" nz-button (click)="changeAvatar()">
            <i title="Avatar"
              ><img
                style="margin: 2px 10px 10px 4px; width: 20px; height: 20px"
                src="assets/image/svg/photo-camera.svg"
            /></i>
          </button>
          <!-- <div class="imgUser" *ngIf="user.avatar && user.avatar!=''">                  -->
          <img
            style="
              max-height: 110px;
              border-radius: 50%;
              width: 110px;
              height: 110px;
              margin: -120px 40px 20px -34px;
            "
            src="{{ user.avatar }}" />
        </div>
        <!-- </div>      -->
      </div>
      <div class="editinfo">
        <strong title="{{ user.fullName }}">{{ user.fullName }}</strong>
        <div *ngIf="!isShown">
          <button
            class="mgr10 btn-obser"
            nz-button
            (click)="changePassUser('')"
            ngClass="mg-r-8 btn-add">
            <i title="Đổi mật khẩu"
              ><img src="assets/image/svg/password.svg"
            /></i>
          </button>
          <button
            [disabled]="enableEditUser"
            class="mgr10 btn-obser mgr-15"
            nz-button
            nzType="primary"
            (click)="editUserInfo()"
            type="checkbox">
            <i title="Sửa"><img src="assets/image/svg/edit.svg" /></i>
          </button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isMobile">
      <div class="vertical-line"></div>
      <table *ngIf="!isShown" class="user-info">
        <tr>
          <td>Họ và tên:</td>
          <td title="{{ user.fullName }}">{{ user.fullName }}</td>
          <td>Chức danh:</td>
          <td>{{ user.doctorRankName }}</td>
          <td>Email:</td>
          <td>{{ user.email }}</td>
        </tr>
        <tr>
          <td>Giới tính:</td>
          <td>{{ toStringType(user.sex) }}</td>
          <td>SĐT:</td>
          <td>{{ user.phone }}</td>
          <td>Tên đăng nhập:</td>
          <td>{{ user.userName }}</td>
        </tr>
        <tr>
          <td>Ngày sinh:</td>
          <td>{{ user.dob | date: 'dd/MM/yyyy' }}</td>
          <td>Địa chỉ:</td>
          <td>{{ user.address }}</td>
          <td>Password:</td>
          <td>**********</td>
          <td class="empty-id"></td>
          <td class="empty-id"></td>
        </tr>
      </table>
      <form *ngIf="isShown" [formGroup]="formEditInfo" style="width: 100%">
        <table class="user-edit-info">
          <tr>
            <td>Họ và tên:</td>
            <td>
              <input style="width: 80%" nz-input formControlName="fullName" />
              <div
                class="message-required"
                *ngIf="submitted && f.fullName.errors">
                <div
                  *ngIf="
                    f.fullName.errors.required ||
                    f.fullName.hasError('whitespace')
                  ">
                  <i
                    nz-icon
                    nzType="close-circle"
                    [nzTheme]="'twptone'"
                    [nzTwotoneColor]="'#FF0000'"></i>
                  <span>Bạn chưa nhập tên</span>
                </div>
              </div>
            </td>
            <td>Địa chỉ:</td>
            <td>
              <input style="width: 80%" nz-input formControlName="address" />
            </td>
            <td>Chức danh:</td>
            <td>
              <input style="width: 100%" nz-input formControlName="doctorRank" />
              <!--<select
                style="
                  width: 200%;
                  height: 30px;
                  border: 1px solid #d9d9d9;
                  border-radius: 4px;
                "
                [ngClass]="{ 'is-invalid': submitted && f.doctorRankId.errors }"
                formControlName="doctorRankId">
                <option
                  *ngFor="let doctorRank of listDoctorRank"
                  [value]="doctorRank.id">
                  {{ doctorRank.name }}
                </option>
              </select>-->
            </td>
          </tr>
          <tr>
            <td>Giới tính:</td>
            <td>
              <nz-select
                style="width: 80%"
                nz-menu
                [nzPlaceHolder]="'Giới tính'"
                [ngClass]="{ 'is-invalid': submitted && f.sex.errors }"
                formControlName="sex">
                <nz-option [nzValue]="1" nzLabel="Nam"></nz-option>
                <nz-option [nzValue]="2" nzLabel="Nữ"></nz-option>
                <nz-option [nzValue]="3" nzLabel="Không xác định"></nz-option>
              </nz-select>
              <div class="message-required" *ngIf="submitted && f.sex.errors">
                <div *ngIf="f.sex.errors.pattern || f.sex.errors.required">
                  <i
                    nz-icon
                    nzType="close-circle"
                    [nzTheme]="'twotone'"
                    [nzTwotoneColor]="'#FF0000'">
                  </i>
                  <span>Bạn chưa chọn giới tính</span>
                </div>
              </div>
            </td>
            <td>Email:</td>
            <td>
              <input style="width: 80%" nz-input formControlName="email" />
              <div class="message-required" *ngIf="submitted && f.email.errors">
                <div *ngIf="f.email.errors.required">
                  <i
                    nz-icon
                    nzType="close-circle"
                    [nzTheme]="'towtone'"
                    [nzTwotoneColor]="'#FF0000'"></i>
                  Email không hợp lệ
                </div>
              </div>
            </td>
            <button
              *ngIf="!enableEditUser"
              style="margin-left: 20px"
              nz-button
              nztype="primary"
              (click)="saveInfo()">
              <i title="Lưu"><img src="assets/image/svg/updated.svg" /></i>
            </button>
          </tr>
          <tr>
            <td>Ngày sinh:</td>
            <td>
              <nz-date-picker
                style="width: 80%"
                [nzPlaceHolder]="'DD/MM/YYYY'"
                nzFormat="dd/MM/yyyy"
                [ngClass]="{ 'is-invalid': submitted && f.dob.errors }"
                formControlName="dob">
              </nz-date-picker>
              <div class="message-required" *ngIf="submitted && f.dob.errors">
                <div *ngIf="f.dob.errors.required">
                  <i
                    nz-icon
                    nztype="close-circle"
                    [nzTheme]="'twotone'"
                    [nzTwotoneColor]="'#FF0000'"></i>
                  <span>Bạn chưa chọn ngày sinh</span>
                </div>
              </div>
            </td>
            <td>SĐT:</td>
            <td>
              <input style="width: 80%" nz-input formControlName="phone" />
              <div class="message-required" *ngIf="submitted && f.phone.errors">
                <div *ngIf="f.phone.errors.required">
                  <i
                    nz-icon
                    nzType="close-circle"
                    [nzTheme]="'twotone'"
                    [nzTwotoneColor]="'#FF0000'"></i>
                  SĐT không hợp lệ
                </div>
              </div>
            </td>
            <div class="edit">
              <button
                class="mgr15-btn-obser"
                style="margin-left: 20px"
                nz-button
                (click)="handelCancel()">
                <i title="Hủy"><img src="assets/image/svg/cancel.svg" /></i>
              </button>
            </div>
          </tr>
        </table>
      </form>
    </ng-container>
    <ng-container *ngIf="isMobile">
      <ul class="ul-info" *ngIf="!isShown">
        <li>
          <label>Họ và tên</label>
          <span title="{{ user.fullName }}">{{ user.fullName }}</span>
          <label>Giới tính</label>
          <span>{{ toStringType(user.sex) }}</span>
        </li>
        <li>
          <label>Chức danh</label>
          <span>{{ user.doctorRankName }}</span>
        </li>
        <li>
          <label>Email</label>
          <span>{{ user.email }}</span>
        </li>
        <li>
          <label>SĐT</label>
          <span>{{ user.phone }}</span>
        </li>
        <li>
          <label>Ngày sinh:</label>
          <span>{{ user.dob | date: 'dd/MM/yyyy' }}</span>
        </li>
        <li>
          <label>Địa chỉ:</label>
          <span>{{ user.address }}</span>
        </li>
      </ul>
      <ul class="ul-info" *ngIf="isShown">
        <li>
          <label>Họ và tên</label>
          <input nz-input [(ngModel)]="user.fullName" />
        </li>
        <li>
          <label>Giới tính</label>
          <nz-select
            nz-menu
            [nzPlaceHolder]="'Giới tính'"
            [(ngModel)]="user.sex">
            <nz-option [nzValue]="1" nzLabel="Nam"></nz-option>
            <nz-option [nzValue]="2" nzLabel="Nữ"></nz-option>
            <nz-option [nzValue]="3" nzLabel="Không xác định"></nz-option>
          </nz-select>
        </li>
        <li>
          <label>Chức danh:</label>
          <input nz-input formControlName="doctorRank" />
          <!--<select
            style="height: 30px; border: 1px solid #d9d9d9; border-radius: 4px"
            [(ngModel)]="user.doctorRankId">
            <option
              *ngFor="let doctorRank of listDoctorRank"
              [value]="doctorRank.id">
              {{ doctorRank.name }}
            </option>
          </select>-->

        </li>
        <li>
          <label>Email</label>
          <input nz-input [(ngModel)]="user.email" />
        </li>
        <li>
          <label>SĐT</label>
          <input nz-input [(ngModel)]="user.phone" />
        </li>
        <li>
          <label>Ngày sinh</label>
          <div>
            <nz-date-picker
              style="width: 80%"
              [nzPlaceHolder]="'DD/MM/YYYY'"
              nzFormat="dd/MM/yyyy"
              [ngClass]="{ 'is-invalid': submitted && f.dob.errors }"
              [(ngModel)]="user.dob">
            </nz-date-picker>
          </div>
        </li>
        <li>
          <label>Địa chỉ:</label>
          <input style="width: 80%" nz-input [(ngModel)]="user.address" />
        </li>
        <li>
          <label>&nbsp;</label>
          <button
            *ngIf="!enableEditUser"
            nz-button
            nztype="primary"
            (click)="saveInfo()">
            <i title="Lưu"><img src="assets/image/svg/updated.svg" /></i>
          </button>
          <button
            class="mgr15-btn-obser"
            style="margin-left: 10px"
            nz-button
            (click)="handelCancel()">
            <i title="Hủy"><img src="assets/image/svg/cancel.svg" /></i>
          </button>
        </li>
      </ul>
    </ng-container>
  </div>

  <!-- Lịch sử hoạt động -->
  <div [ngClass]="{ 'flex-box': !isMobile }">
    <div [ngClass]="{ 'left-pane': !isMobile }">
      <div class="portal-footer">
        <div class="title-bold"><b>Lịch sử hoạt động</b></div>
        <hr
          width="700px"
          height="1px"
          margin=" 12px 0 43px"
          background-color="#d4d8e6" />
        <nz-table
          #fixedTable
          [nzLoading]="isLoading"
          [nzScroll]="{ y: '400px' }">
          <li class="item-vs tag-visit" *ngFor="let log of lstLogs; index as i">
            <div [ngClass]="{ 'first-line': !isMobile }">
              <b
                ><span
                  >[{{ log.dateCreated | date: 'dd/MM/yyyy HH:mm' }} -
                  {{ log.action }}]</span
                ></b
              >
            </div>
            <div [ngClass]="{ 'second-line': !isMobile }">
              <span style="margin-left: 15px">{{ log.displayLog }}</span>
            </div>
          </li>
        </nz-table>
      </div>
    </div>

    <!-- Danh sách làm việc -->
    <div [ngClass]="{ 'right-pane': !isMobile, 'mt-3': isMobile }">
      <div class="flex">
        <div class="title-bold"><b>Danh sách Nhóm làm việc</b></div>
        <hr
          width="600px"
          height="1px"
          margin=" 12px 0 43px"
          background-color="#d4d8e6" />
        <div>
          <nz-table
            #fixedTable
            [nzLoading]="isLoading"
            [nzScroll]="{ y: '400px' }">
            <li
              class="item-vs tag-visit"
              *ngFor="let group of listworklist; index as i">
              <div [ngClass]="{ 'first-line': !isMobile }">
                <b
                  ><span>{{ group.groupName }}</span></b
                >
              </div>
              <div [ngClass]="{ 'second-line': !isMobile }">
                <span style="margin-left: 10px"
                  >Ngày tham gia:
                  {{ group.joinedDate | date: 'dd/MM/yyyy ' }}</span
                >
              </div>
            </li>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Popup change password-->
<nz-modal
  [(nzVisible)]="isVisible_pass"
  [nzTitle]="!enableEditUser ? 'Thay đổi mật khẩu' : 'Thay đổi'"
  [nzWidth]="500"
  (nzOnCancel)="handCancel()">
  <div class="row">
    <div class="group-obser width-obser">
      <label>Mật khẩu hiện tại:<span class="red-dot">*</span></label>
      <input
        type="text"
        placeholder="Mật khẩu hiện tại"
        [(ngModel)]="dataForm.currentPassword" />
    </div>
  </div>
  <div class="row">
    <div class="group-obser width-obser">
      <label>Mật khẩu mới:<span class="red-dot">*</span></label>
      <input
        type="text"
        placeholder="Mật khẩu mới"
        [(ngModel)]="dataForm.newPassword" />
    </div>
  </div>
  <div class="row">
    <div class="group-obser width-obser">
      <label>Nhập lại mật khẩu:<span class="red-dot">*</span></label>
      <input
        type="text"
        placeholder="Nhập lại"
        [(ngModel)]="dataForm.repeatPassword" />
    </div>
  </div>
  <div *nzModalFooter>
    <button
      class="btn-primary-large"
      style="margin-right: 10px"
      (click)="savePass(dataForm)">
      Lưu
    </button>
    <button class="button-submit btn-cancel-large" (click)="handCancel()">
      Đóng
    </button>
  </div>
</nz-modal>

<!-- Popup change avatar -->
<nz-modal
  [(nzVisible)]="isVisible_avatar"
  [nzWidth]="770"
  [nzMaskClosable]="false"
  [nzTitle]="!enableEditUser ? 'Thay đổi ảnh đại diện' : 'Thay đổi'"
  (nzOnCancel)="handlCancel()"
  nzClassName="change-avatar">
  <div *nzModalContent>
    <app-image-cropper
      (uploadFileEvent)="uploadFileImage($event)"></app-image-cropper>
  </div>
  <div *nzModalFooter>
    <button class="button-submit btn-cancel-large" (click)="handlCancel()">
      Đóng
    </button>
  </div>
</nz-modal>
