<div [ngSwitch]="data.observationType.dataType" class="form-input form-elm">
  <div *ngSwitchCase="8" style="height: 100%">
    <nz-date-picker
      nzFormat="yyyy-MM-dd HH:mm:ss"
      nzShowTime
      [(ngModel)]="value"
      [ngClass]="[
        data.formatStyle.isBold == true ? 'bold' : '',
        data.formatStyle.isItalic == true ? 'italic' : '',
        data.formatStyle.isUnderline == true ? 'underline' : '',
        data.formatStyle.align
      ]"
      [ngStyle]="{
        'color': data.formatStyle.color,
        'background': data.formatStyle.bgColor,
        'font-size': data.formatStyle.fontSize + 'px',
        'font-family': data.formatStyle.font
      }"
      [nzDisabled]="data.editType == 0 || data.editType == 3"
      [nzPlaceHolder]="data.observationType.name"
      (ngModelChange)="onModelChange($event)">
    </nz-date-picker>
  </div>
  <div *ngSwitchCase="7" style="height: 100%">
    <nz-date-picker
      nzFormat="yyyy-MM-dd"
      [(ngModel)]="value"
      [ngClass]="[
        data.formatStyle.isBold == true ? 'bold' : '',
        data.formatStyle.isItalic == true ? 'italic' : '',
        data.formatStyle.isUnderline == true ? 'underline' : '',
        data.formatStyle.align
      ]"
      [ngStyle]="{
        'color': data.formatStyle.color,
        'background': data.formatStyle.bgColor,
        'font-size': data.formatStyle.fontSize + 'px',
        'font-family': data.formatStyle.font
      }"
      [nzDisabled]="data.editType == 0 || data.editType == 3"
      [nzPlaceHolder]="data.observationType.name"
      (ngModelChange)="onModelChange($event)">
    </nz-date-picker>
  </div>
  <div *ngSwitchCase="6" style="height: 100%">
    <nz-time-picker
      [(ngModel)]="value"
      nzFormat="HH:mm"
      [ngClass]="[
        data.formatStyle.isBold == true ? 'bold' : '',
        data.formatStyle.isItalic == true ? 'italic' : '',
        data.formatStyle.isUnderline == true ? 'underline' : '',
        data.formatStyle.align
      ]"
      [ngStyle]="{
        'color': data.formatStyle.color,
        'background': data.formatStyle.bgColor,
        'font-size': data.formatStyle.fontSize + 'px',
        'font-family': data.formatStyle.font
      }"
      [nzDisabled]="data.editType == 0 || data.editType == 3"
      [nzPlaceHolder]="data.observationType.name"
      (ngModelChange)="onModelChange($event)"></nz-time-picker>
  </div>
</div>
