import { GroupTreeService } from 'src/app/services/groupTree.service';
import { AppConfigService } from 'src/app-config.service';
import { ShareService } from 'src/app/services/share.service';
import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrescriptionService } from 'src/app/services/prescription-service';
import { MedicationService } from 'src/app/services/medication.service';
import { Medication } from 'src/app/models/medication.class';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Constant } from 'src/app/share/constants/constant.class';
import { NotificationService } from 'src/app/services/notification.service';
import { Subscription } from 'rxjs';
import { AppState } from '../../app-state/app-state';
import { Store } from '@ngrx/store';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-prescription-tab',
  templateUrl: './prescription-tab.component.html',
  styleUrls: ['./prescription-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrescriptionComponent implements OnInit {
  _visit: any = {};
  @Input()
  set visit(val: any) {
    if (!this.shareService.checkEmpty(val)) {
      this._visit = val;
      this.visitId = val.id;
    }
  }
  get visit() {
    return this._visit;
  }
  _disabledActiton = false;
  @Input() set disabledActiton(value) {
    this._disabledActiton = value;
    this.isEditMode = this.canEditPrescription && !this.disabledActiton;
  }
  get disabledActiton() {
    return this._disabledActiton;
  }
  @Output() newVisitId: EventEmitter<any> = new EventEmitter();
  @Output() titleTabRefresh = new EventEmitter();

  visitId: string;
  prescription: any = null;
  listMedicationTable = [];
  listMedicationSource = [];
  isShowFormMedication = false;
  selectedMedication: any;
  doctorComment = '';

  addElseUpdate = true;
  validError = false;
  selectedRow = -1;

  isExporting = false;
  isEmptyPrescription = true;

  formMedication: FormGroup;
  prescriptionNoteFG: FormGroup;

  currentUserId = '';
  listPrescriptionsOfVisit: any[] = [];
  listPrescriptionOfPatient: any[] = [];
  listPrescriptionOfPatientAll = [];

  showSample = false;
  showSharing = false;
  isOpenMenu = false;

  isVisibleCreateSample = false;
  isVisibleAmountExistOfPatient = false;
  isVisiblePrescriptionApproving = false;
  reloadGetSample = false;

  canEditPrescription = false;
  canCreatMorePrescription = false;
  canApprovePrescription = false;
  canDenyPrescription = false;
  canUnapprovePrescription = false;

  approvingMessage = '';
  errorEmptyApprovingMsg = '';
  hasApprovedPrescription = false;

  isMobile: boolean;
  printHeader = '';
  generalInfo: any;

  isShowBtnAction = false;
  isShowBtnExport = false;
  isOtherCreated = false;
  isCanCreateMore = false;
  isEditMode = false;

  //////////////////////////////////////////////////
  // helper
  //////////////////////////////////////////////////
  groupId: string;
  groupName: string;
  groupSub: Subscription;

  queryingPrescriptionAction = -1; //0: querying; 1: denying; 2: unapproving
  defaultMedicationFormValue: any;
  prescriptionSharingUrl = '';
  //oldreExaminationDate: any;

  userRoleInGroup = {
    canEditPrescription: false,
    hasDoctorRole: false,
    canApprovePrescription: false,
    canUnapproveOther: false,
  };

  configGroup = {
    isUsePharmacist: false,
    isUseApprovePrescription: false,
    isChangePresciptionStatus: false,
    isConnectToHIS: false,
    isUsePrescriptionDeliveryInfo: false,
    paperSizePrintPrescription: '',
  };

  callbackSearchFn: any;
  callbackSavePrescription: any;
  isInitChanging = false;
  isVisitAgainChanged = false;

  VISIT_STATUS = Constant.VISIT_STATUS;
  templateDrugList = Constant.DEFAULT_TEMPLATE_DRUG_LIST;
  VISIT_AGAIN_UNIT = Constant.VISIT_AGAIN_UNIT;
  PRESCRIPTION_NOTE = Constant.PRESCRIPTION_NOTE;

  options = [
    { label: '1 tuần', value: 1, valueCalc: 1 },
    { label: '2 tuần', value: 2, valueCalc: 2 },
    { label: '1 tháng', value: 3, valueCalc: 1 },
    { label: '2 tháng', value: 4, valueCalc: 2 },
    { label: '3 tháng', value: 5, valueCalc: 3 },
    { label: '6 tháng', value: 6, valueCalc: 6 },
    { label: '12 tháng', value: 7, valueCalc: 12 },
  ];

  dateTimeFormat = 'dd/MM/yyyy HH:mm';
  dateFormat = 'dd/MM/yyyy';
  checked = true;
  isAddMedication = false;
  prescriptionIsShowActiveIngredient = true;
  constructor(
    private modalService: NzModalService,
    private shareService: ShareService,
    private prescriptionService: PrescriptionService,
    private formBuilder: FormBuilder,
    private medicationService: MedicationService,
    private notificationService: NotificationService,
    private configService: AppConfigService,
    private store: Store<AppState>,
    private groupService: GroupTreeService
  ) {
    this.formMedication = this.formBuilder.group({
      id: ['', [Validators.required]],
      medicationName: ['', [Validators.required]],
      amount: ['', [Validators.required, Validators.min(1)]],
      unit: ['', [Validators.required]],
      text: ['', [Validators.required]],
      isFunctionalFoods: [false],
      price: [''],
      existingAmount: [0],
      content:['']
    });
    this.defaultMedicationFormValue = this.formMedication.value;
    this.prescriptionNoteFG = this.formBuilder.group({
      reExaminationDate: [null],
      visitAgainAfter: [null],
    });
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.currentUserId = res;
      });
    this.isMobile = window.innerWidth < 540;

    this.callbackSearchFn = this.shareService.debounce(this.onSearchMedication, 500);
    this.callbackSavePrescription = this.shareService.debounce(this.savePrescription, 500);
  }
  get formMedicationData() {
    return this.formMedication.controls;
  }
  get prescriptionNoteFormData() {
    return this.prescriptionNoteFG.controls;
  }

  async ngOnInit() {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        this.groupName = data.groupName;
        console.log(data);

        const userPermissionInGroup =
          data.doctorPermission == null ? [] : data.doctorPermission.slice().sort((a, b) => a - b);

        this.userRoleInGroup.canEditPrescription = data.isUsePharmacist
          ? userPermissionInGroup.some((en) => en == Constant.DOCTORPERMISSION.EditPrescription)
          : true;
        this.userRoleInGroup.canApprovePrescription = data.isUseApprovePrescription
          ? userPermissionInGroup.some((en) => en == Constant.DOCTORPERMISSION.ApprovePrescription)
          : false;
        this.userRoleInGroup.canUnapproveOther = data.isUseApprovePrescription
          ? userPermissionInGroup.some((en) => en == Constant.DOCTORPERMISSION.UnapprovePrescription)
          : false;
        this.userRoleInGroup.hasDoctorRole = userPermissionInGroup.some((en) => en == Constant.DOCTORPERMISSION.Doctor);
        this.templateDrugList = data.templateDrugList ? data.templateDrugList : Constant.DEFAULT_TEMPLATE_DRUG_LIST;

        this.configGroup = {
          isUsePharmacist: data.isUsePharmacist,
          isUseApprovePrescription: data.isUseApprovePrescription,
          isChangePresciptionStatus: data.isChangePresciptionStatus,
          isConnectToHIS: data.isConnectToHIS,
          isUsePrescriptionDeliveryInfo: data.isUsePrescriptionDeliveryInfo,
          paperSizePrintPrescription: data.paperSizePrintPrescription,
        };
        this.getPrintHeader();
        this.prescriptionIsShowActiveIngredient = data.prescriptionIsShowActiveIngredient;
        // this.loadGroupInfo();
      }
    });
    await this.getVisitPrescriptions();
    this.getAllPrescriptionOfPatient();
  }
  showFormMedication(isShow) {
    this.resetPopup();
    this.isShowFormMedication = isShow;
    this.validError = false;
  }
  editPrescription() {
    if (this.prescription != null && this.prescription.Status > Constant.PrescriptionStatus.Approved) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.WARRING,
        'Đơn thuốc này đã xuất, bạn không thể sửa được nữa'
      );
      return;
    }
    //this.isEdittingPrescription = true;
    if (this.isEmptyPrescription) {
      this.showFormMedication(true);
    }
  }
  getVisitPrescriptions() {
    this.prescriptionService.getPrescriptionByVisit(this.visitId).subscribe((res) => {
      if (res != null && res.isValid == true) {
        let datas = JSON.parse(res.jsonData);
        if (res.jsonData == null || datas.length == 0) {
          this.isEmptyPrescription = true;
          this.canEditPrescription = this.userRoleInGroup.canEditPrescription;
          this.isEditMode = this.canEditPrescription && !this.disabledActiton;
        } else {
          this.listPrescriptionsOfVisit = JSON.parse(res.jsonData);
          this.listPrescriptionsOfVisit.forEach((en) => {
            en.CanEdit = en.CreatorId == this.currentUserId;
            en.MedicationRequests.forEach((e) => {
              e.Title = this.prescriptionService.getMedicationName(e.MedicationName, e.Content, e.ActiveIngredient,this.prescriptionIsShowActiveIngredient);
            });
          });
          this.getPrecriptionFormList();
        }
      }
    });
  }
  getPrecriptionFormList() {
    //check if there is an approved prescription
    if (this.listPrescriptionsOfVisit.length > 0) {
      this.listPrescriptionsOfVisit.forEach((en) => {
        en.StatusString = this.toStatusPrescriptionText(en.Status);
        en.PaymentMethodString = this.toPaymentMethodText(en.PaymentMethod);
      });
      // áp dụng config tính năng phê duyệt
      if (this.configGroup.isUseApprovePrescription) {
        this.hasApprovedPrescription = false;
        this.listPrescriptionsOfVisit.forEach((elem) => {
          // nếu có đơn thuốc đã duyệt => show đơn duyệt
          if (elem.Status >= Constant.PrescriptionStatus.Approved && !this.hasApprovedPrescription) {
            this.prescription = elem;
            this.hasApprovedPrescription = true;
            this.showPrescription();
          }
        });
        // nếu không đơn thuốc nào đã duyệt => show đơn đầu tiên
        if (!this.hasApprovedPrescription) {
          this.prescription = this.listPrescriptionsOfVisit[0];
          this.showPrescription();
        }
      } else {
        // không áp dụng tính năng phê duyệt
        this.prescription = this.listPrescriptionsOfVisit[0];
        this.showPrescription();
      }
    } else {
      this.isEmptyPrescription = true;

      this.canEditPrescription = this.userRoleInGroup.canEditPrescription;
      this.isEditMode = this.canEditPrescription && !this.disabledActiton;
      this.canCreatMorePrescription = false;
    }
    this.checkShowingBtn();
  }
  getPrescription(prescriptionId) {
    this.isAddMedication = false;
    //get đơn thuốc
    this.prescriptionService.getPrescription(prescriptionId).subscribe((res) => {
      if (res != null && res.isValid == true) {
        if (res.jsonData == null) {
          this.isEmptyPrescription = true;
          this.canEditPrescription = this.userRoleInGroup.canEditPrescription;
          this.isEditMode = this.canEditPrescription && !this.disabledActiton;
        } else {
          this.prescription = JSON.parse(res.jsonData);
          this.checkShowingBtn();
          this.showPrescription();
        }
        //this.setFeatureShowingBaseConfig();
      }
    });
  }
  getPrintHeader() {
    this.groupService.getPrintHeader(this.groupId, 1).subscribe((res) => {
      if (res.isValid) {
        this.printHeader = res.jsonData;
      }
    });
  }
  showPrescription() {
    this.listMedicationTable = JSON.parse(JSON.stringify(this.prescription.MedicationRequests));
    //this.oldreExaminationDate = this.prescription.ReExaminationDate;
    if (this.listMedicationTable.length > 0) {
      let index = 0;
      let firstItem = this.listMedicationTable[0];
      if (this.shareService.checkEmpty(firstItem.Order)) {
        this.listMedicationTable.forEach((en) => {
          en.Order = index++;
          en.Title = this.getMedicationName(en.MedicationName, en.Content, en.ActiveIngredient);
        });
        this.listMedicationTable.sort((a, b) => a.Order - b.Order);
      }
      // nếu đang sửa toa hiện tại và chọn toa khác => refresh trạng thái
      this.isShowFormMedication = false;
      //this.isEdittingPrescription = false;

      this.listMedicationTable.sort((a, b) => b.CreatedDate != undefined && b.CreatedDate.localeCompare(a.CreatedDate));
      //TODO: check price from HIS?
      this.listMedicationTable.forEach((item) => {
        this.medicationService.checkMedication(item.MedicationId, this.groupId).subscribe((res) => {
          if (res.jsonData != null) {
            //update price
            item.price = res.jsonData.price;
          }
        });
      });
      this.isEmptyPrescription = false;

      ///// set user role
      if (this.configGroup.isUseApprovePrescription) {
        //cannot edit or approve more prescription
        if (this.hasApprovedPrescription) {
          this.canEditPrescription = false;
          this.canApprovePrescription = false;
          this.canCreatMorePrescription = false;

          this.canDenyPrescription =
            this.prescription.Status >= Constant.PrescriptionStatus.Saved &&
            this.prescription.Status < Constant.PrescriptionStatus.Approved;

          this.canUnapprovePrescription =
            this.prescription.Status >= Constant.PrescriptionStatus.Approved &&
            (this.userRoleInGroup.canUnapproveOther || this.prescription.ApproverId === this.currentUserId);
        } else {
          this.setPermissions4Prescription();
        }
      } else {
        this.canEditPrescription =
          this.userRoleInGroup.canEditPrescription &&
          this.currentUserId === this.prescription.CreatorId &&
          this.prescription.Status < Constant.PrescriptionStatus.Approved;
      }
    } else {
      this.isEmptyPrescription = true;
      this.canEditPrescription = this.userRoleInGroup.canEditPrescription;

      if (this.configGroup.isUseApprovePrescription) {
        this.canApprovePrescription =
          this.userRoleInGroup.canApprovePrescription &&
          this.prescription.Status >= Constant.PrescriptionStatus.Saved &&
          this.prescription.Status < Constant.PrescriptionStatus.Approved;

        this.canUnapprovePrescription = false;
        this.canDenyPrescription = this.userRoleInGroup.canApprovePrescription;
      }
    }
    this.isEditMode = this.canEditPrescription && !this.disabledActiton;
    //this.isEdittingPrescription = this.canEditPrescription;

    ///// formControl patchValue
    this.formPatchValue(this.prescription);
  }
  setPermissions4Prescription() {
    this.canEditPrescription =
      this.userRoleInGroup.canEditPrescription && this.currentUserId === this.prescription.CreatorId;

    this.canApprovePrescription =
      this.userRoleInGroup.canApprovePrescription &&
      this.prescription.Status >= Constant.PrescriptionStatus.Saved &&
      this.prescription.Status < Constant.PrescriptionStatus.Approved;

    this.canDenyPrescription = this.canApprovePrescription;

    this.canCreatMorePrescription =
      this.userRoleInGroup.canEditPrescription && this.currentUserId !== this.prescription.CreatorId;

    this.canUnapprovePrescription =
      this.prescription.Status >= Constant.PrescriptionStatus.Approved &&
      (this.userRoleInGroup.canUnapproveOther || this.prescription.ApproverId === this.currentUserId);
    this.isEditMode = this.canEditPrescription && !this.disabledActiton;
  }
  showOtherPrescription(otherPrescription) {
    //TODO: ask to save current editing
    this.prescription = otherPrescription;
    this.checkShowingBtn();
    this.showPrescription();
    $('.thuoc-container').closest('.ant-tabs-content').animate({ scrollTop: '0' });
  }
  openCreateMorePrescription() {
    this.prescription = null;
    this.checkShowingBtn();
    this.listMedicationTable = [];
    this.doctorComment = '';

    this.isInitChanging = true;
    this.prescriptionNoteFG.reset();
    this.isInitChanging = false;

    //this.isEdittingPrescription = true;
    this.canEditPrescription = this.userRoleInGroup.canEditPrescription;
    this.isEditMode = this.canEditPrescription && !this.disabledActiton;

    this.showFormMedication(true);
    $('.thuoc-container').closest('.ant-tabs-content').animate({ scrollTop: '0' });
  }

  addPrescription() {
    const payload = {
      title: '',
      status: 0,
      visitId: this.visitId,
      note: '',
      patientId: this.visit.patientId,
      medicationRequests: [],
    };
    this.listMedicationTable.forEach((item) => {
      payload.medicationRequests.push({
        medicationId: item.MedicationId,
        medicationName: item.MedicationName,
        medicationCode: item.MedicationCode,
        activeIngredient: item.ActiveIngredient,
        unitName: item.UnitName,
        isFunctionalFoods: item.IsFunctionalFoods,
        amount: item.Amount,
        text: item.Text,
        existingAmount: item.ExistingAmount,
        order: item.Order,
        content: item.Content,
      });
    });
    this.prescriptionService.addPrescription(payload).subscribe((res) => {
      if (res.isValid) {
        this.prescription = JSON.parse(res.jsonData);
        this.prescription.StatusString = this.toStatusPrescriptionText(this.prescription.Status);
        this.isEmptyPrescription = false;
        this.checkShowingBtn();
        this.titleTabRefresh.emit(Constant.TabTitleInsideVisitDetail.Prescription);
        this.notificationService.showNotification(Constant.SUCCESS, Constant.MESSAGE_UPDATE_SUCCESS);
      }
    });
  }

  onSearchMedication() {
    let keyword = this.formMedication.value.medicationName;
    if (this.shareService.checkEmpty(keyword)) return;
    if (this.selectedMedication) {
      this.selectedMedication.id = null;
    }
    const search = {
      keyword: keyword,
      groupId: this.groupId,
    };
    this.medicationService.getMedicationAll(search).subscribe((res) => {
      this.listMedicationSource = res;
      this.listMedicationSource.forEach((en) => {
        en.title = this.getMedicationName(en.name, en.content, en.activeIngredient);
      });
    });
  }
  getMedicationName(name, content, activeIngredient) {
    return this.prescriptionService.getMedicationName(name, content, activeIngredient,this.prescriptionIsShowActiveIngredient);
  }

  addMedication() {
    this.isAddMedication = true;
    if (this.selectedMedication == null) {
      this.validError = true;
      return;
    }
    let exist = this.listMedicationTable.find((en) => en.MedicationId == this.selectedMedication.id);
    if (exist) {
      this.modalService.info({
        nzTitle: 'Thuốc đang nhập đã tồn tại trong đơn',
        nzOkText: 'Đồng ý',
        nzWidth: 'fit-content',
        nzMaskClosable: true,
      });
    } else {
      if (this.formMedication.valid) {
        this.validError = false;
        let data = this.selectedMedication;
        let formValue = this.formMedication.value;
        let leng = this.listMedicationTable.length;

        this.listMedicationTable.push({
          MedicationId: data.id,
          MedicationName: data.name,
          MedicationCode: data.code,
          Title: data.title,
          ActiveIngredient: data.activeIngredient,
          price: data.price,
          Amount: formValue.amount,
          Text: formValue.text,
          UnitName: formValue.unit,
          IsFunctionalFoods: data.isFunctionalFoods,
          ExistingAmount: formValue.existingAmount,
          Order: leng,
          Content:data.content
        });
        this.listMedicationTable = [...this.listMedicationTable];

        this.resetPopup();
        this.savePrescription();
      } else {
        this.validError = true;
      }
    }
  }

  export() {
    this.isExporting = true;
    this.prescriptionService.exportPrescription(this.prescription.Id).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Đã gửi đơn thuốc sang HIS');
        //this.getPrescription(this.prescription.Id);
        this.isExporting = false;
        this.prescription.Status = Constant.PrescriptionStatus.Exported;
        this.prescription.StatusString = this.toStatusPrescriptionText(this.prescription.Status);
        this.isShowBtnExport = false;
        this.canEditPrescription = false;
        this.isEditMode = this.canEditPrescription && !this.disabledActiton;
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        this.isExporting = false;
      }
    });
  }

  updateMedication() {
    if (!this.selectedMedication || this.selectedMedication.id == null) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Chọn thuốc từ bảng để sửa');
    } else if (this.formMedication.valid) {
      this.validError = false;
      const item = this.listMedicationTable[this.selectedRow];

      item.MedicationId = this.selectedMedication.id;
      item.Name = this.selectedMedication.name;
      item.MedicationName = this.selectedMedication.name;
      item.Title = this.selectedMedication.title;
      item.ActiveIngredient = this.selectedMedication.activeIngredient;
      item.UnitName = this.formMedication.value.unit;
      item.IsFunctionalFoods = this.selectedMedication.isFunctionalFoods;
      item.Amount = this.formMedication.value.amount;
      item.Text = this.formMedication.value.text;
      item.price = this.selectedMedication.price;
      item.ExistingAmount = this.formMedication.value.existingAmount;

      this.listMedicationTable = [...this.listMedicationTable];

      this.resetPopup();
      this.savePrescription();
    } else {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Có lỗi xảy ra !');
      this.validError = true;
    }
  }

  deleteMedication(idx) {
    /*if (this.listMedicationTable.length == 1) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, 'Thuốc trong toa không được để trống');
      return;
    }*/
    this.listMedicationTable.splice(idx, 1);
    let index = 0;
    this.listMedicationTable.forEach((en) => {
      en.Order = index++;
    });
    this.listMedicationTable = [...this.listMedicationTable];
    this.resetPopup();
    this.savePrescription();
  }
  selectSearchMedication(data: Medication) {
    console.log(data)
    if (data == null) return;
    this.selectedMedication = {
      id: data.id,
      name: data.name,
      code: data.code,
      activeIngredient: data.activeIngredient,
      unit: data.unit,
      dosages: data.dosages,
      route: data.route,
      usage: data.usage,
      status: data.status,
      turn: data.turn,
      price: undefined,
      title: data.title,
      isFunctionalFoods: data.isFunctionalFoods,
      content: data.content,
    };
    this.formMedication.controls.id.setValue(this.selectedMedication.id);
    this.formMedication.controls.unit.setValue(this.selectedMedication.unit);
    if (this.selectedMedication.usage != null) {
      this.formMedication.controls.text.setValue(this.selectedMedication.usage);
    }
    this.medicationService.checkMedication(data.id, this.groupId).subscribe((res) => {
      if (res.jsonData != null) {
        this.selectedMedication.price = res.jsonData.price;
      }
      if (this.selectedMedication.price != undefined) {
        this.formMedication.controls.price.setValue(this.selectedMedication.price + ' đồng');
      } else {
        this.formMedication.controls.price.setValue('');
      }
    });
  }
  // selectMedication(dataIdx) {
  //   this.showFormMedication(true);
  //   const data = this.listMedicationTable[dataIdx];
  //   console.log('selectMedication', data);
  //   this.formMedication.controls.id.setValue(data.Id);
  //   this.formMedication.controls.medicationName.setValue(data.Title);
  //   this.formMedication.controls.amount.setValue(data.Amount);
  //   this.formMedication.controls.unit.setValue(data.UnitName);
  //   this.formMedication.controls.isFunctionalFoods.setValue(data.isFunctionalFoods);
  //   this.formMedication.controls.text.setValue(data.Text);
  //   this.formMedication.controls.existingAmount.setValue(data.ExistingAmount);

  //   if (data.price != undefined) {
  //     this.formMedication.controls.price.setValue(data.price + ' đồng');
  //   } else {
  //     this.formMedication.controls.price.setValue('');
  //   }

  //   this.selectedMedication = {
  //     id: data.MedicationId,
  //     name: data.MedicationName,
  //     title: data.Title,
  //     text: data.Text,
  //     code: data.code,
  //     activeIngredient: data.ActiveIngredient,
  //     unit: data.UnitName,
  //     isFunctionalFoods: data.isFunctionalFoods,
  //     dosages: data.dosages,
  //     route: data.route,
  //     usage: data.usage,
  //     status: data.Status,
  //     turn: data.turn,
  //     price: data.price,
  //     content: data.content,
  //   };
  //   this.selectedRow = dataIdx;
  //   this.addElseUpdate = false;
  // }
  selectMedication(dataIdx) {
    this.showFormMedication(true);
    const data = this.listMedicationTable[dataIdx];
    console.log('selectMedication', data);
    this.formMedication.controls.id.setValue(data.Id);
    this.formMedication.controls.medicationName.setValue(data.Title);
    this.formMedication.controls.amount.setValue(data.Amount);
    this.formMedication.controls.unit.setValue(data.UnitName);
    this.formMedication.controls.isFunctionalFoods.setValue(data.isFunctionalFoods);
    this.formMedication.controls.text.setValue(data.Text);
    this.formMedication.controls.existingAmount.setValue(data.ExistingAmount);

    if (data.price != undefined) {
        this.formMedication.controls.price.setValue(data.price + ' đồng');
    } else {
        this.formMedication.controls.price.setValue('');
    }

    this.selectedMedication = {
        id: data.MedicationId,
        name: data.MedicationName,
        title: data.Title,
        text: data.Text,
        code: data.code,
        activeIngredient: data.ActiveIngredient,
        unit: data.UnitName,
        isFunctionalFoods: data.IsFunctionalFoods,
        dosages: data.dosages,
        route: data.route,
        usage: data.usage,
        status: data.Status,
        turn: data.turn,
        price: data.price,
        content: data.content,
    };
    this.selectedRow = dataIdx;
    this.addElseUpdate = false;
}

  resetPopup() {
    this.formMedication.reset(this.defaultMedicationFormValue);
    this.selectedMedication = null;

    this.addElseUpdate = true;
    this.validError = false;
    this.selectedRow = -1;
  }
  printPrescription2(){
    let actionUrl = `/Viewer.aspx?report=DonThuoc&id=${this.visitId}&presId=${this.prescription.Id}`;
    window.open(this.configService.getConfig().printReportUrl + actionUrl, '_blank');
  }
  printHDSD(){
    let actionUrl = `/Viewer.aspx?report=HDSDThuoc&id=${this.visitId}`;
    window.open(this.configService.getConfig().printReportUrl + actionUrl, '_blank');
  }
  printPrescription() {
    let styleCommon = this.shareService.getStyleCommonPrint();
    let stylePrescription = `table{
      font-size:8pt
    }
    html,body{
      font-size:9pt
    }`;
    let printContents;
    printContents = document.getElementById('print-div-prescription').innerHTML;
    console.log('printContents', printContents);
    this.shareService.printHtmlContent(printContents, null, styleCommon + stylePrescription);
  }

  exportPdf() {
    const titlePrint = '';
    const printContents = document.getElementById('print-div-prescription').innerHTML;
    let styleCommon = this.shareService.getStyleCommonPrint();
    let stylePrescription = `table{
      font-size:8pt
    }
    html,body{
      font-size:9pt
    }`;
    const content = `
    <html>
    <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <title>${titlePrint}</title>
    <style>
    ${styleCommon}
    ${stylePrescription}
    </style>
    </head>
    <body onload='window.print()'>${printContents}</body>
    </html>`;

    const payload = {
      source: content,
      prescriptionId: this.prescription.Id,
      patientName: this.visit.patientName,
      paperSize: this.configGroup.paperSizePrintPrescription,
    };
    this.prescriptionService.exportPDFPrescription(payload).subscribe((res) => {
      if (res.isValid) {
        const link = document.createElement('a');
        // link.download = res.jsonData;
        link.setAttribute('download', res.jsonData);
        link.href =
          this.configService.getConfig().api.baseUrl +
          '/api/Prescription/GetPdf/' +
          this.prescription.Id +
          '/' +
          res.jsonData;
        if (!this.isMobile) {
          link.target = '_blank';
        }
        link.click();
        if (!this.configGroup.isUseApprovePrescription) {
          this.prescription.Status = Constant.PrescriptionStatus.Exported;
        }
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  copyLink() {
    this.prescriptionService.getShareLinkPrescription(this.prescription.Id).subscribe((res) => {
      if (res.isValid) {
        const data = JSON.parse(res.jsonData);
        if (data.isValid) {
          this.prescriptionSharingUrl = window.location.origin + '/share-prescription/' + data.jsonData;
          this.showSharing = true;
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, data.errors[0].errorMessage);
        }
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  copyMessage(val: string = null) {
    if (!val) {
      val = this.prescriptionSharingUrl;
    }
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationService.showNotification(Constant.SUCCESS, 'Đã copy link đơn thuốc.');
  }

  savePrescription() {
    if (this.isEmptyPrescription || this.prescription == null) {
      this.addPrescription();
      return;
    }
    let initLength = this.listMedicationTable.length;
    // const visitAgainAfter = this.prescriptionNoteFG.value.visitAgainAfter;
    // if (
    //   visitAgainAfter &&
    //   (!Number.isInteger(visitAgainAfter) || visitAgainAfter < 0)
    // ) {
    //   this.notificationService.showNotification(
    //     Constant.NOTIFY_TYPE.WARRING,
    //     'Nhập thời gian hẹn khám là số nguyên dương'
    //   );
    //   return;
    // }
    // if (visitAgainAfter && visitAgainAfter > 10000) {
    //   this.notificationService.showNotification(
    //     Constant.NOTIFY_TYPE.WARRING,
    //     'Thời gian hẹn khám quá lớn'
    //   );
    //   return;
    // }
    const payload = {
      visitId: this.visitId,
      prescriptionId: this.prescription.Id,
      creatorId: this.currentUserId,
      creatorRole: this.userRoleInGroup.hasDoctorRole ? 'Doctor' : 'Pharmacist',
      doctorComment: this.doctorComment,
      visitAgainAfter: this.prescriptionNoteFG.value.visitAgainAfter,
      reExaminationDate: this.prescriptionNoteFG.value.reExaminationDate,
      recipientName: this.generalInfo.recipientName,
      recipientPhoneNo: this.generalInfo.recipientPhoneNo,
      recipientAddress: this.generalInfo.recipientAddress,
      paymentMethod: this.generalInfo.paymentMethod,
      recipientTimePlan: this.generalInfo.recipientTimePlan,
      recipientNote: this.generalInfo.recipientNote,
      medicationRequests: [],
      patientId: this.visit.patientId,
    };
    this.listMedicationTable.forEach((item) => {
      payload.medicationRequests.push({
        medicationId: item.MedicationId,
        medicationName: item.MedicationName,
        medicationCode: item.MedicationCode,
        activeIngredient: item.ActiveIngredient,
        unitName: item.UnitName,
        isFunctionalFoods: item.IsFunctionalFoods,
        amount: item.Amount,
        text: item.Text,
        existingAmount: item.ExistingAmount,
        order: item.Order,
        content: item.Content,
      });
    });
    //re-update prescription for print
    this.prescription.DoctorComment = this.doctorComment;
    this.prescription.ReExaminationDate = this.prescriptionNoteFG.value.reExaminationDate;
    //end re-update
    this.prescriptionService.savePrescription(payload).subscribe((res) => {
      if (res.isValid) {


        //this.notificationService.showNotification(Constant.SUCCESS, Constant.MESSAGE_UPDATE_SUCCESS);
        console.log('this.prescription', this.prescription);
        if (initLength == 0) {
          this.titleTabRefresh.emit(Constant.TabTitleInsideVisitDetail.Prescription);
          this.prescription = {...this.prescription};
        }
        //reload get prescription
        if (this.isAddMedication) {
          this.getPrescription(this.prescription.Id);
        }
      } else {
        this.notificationService.showNotification(Constant.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  cancelEditPrescription() {
    if (this.prescription != null)
      this.listMedicationTable = JSON.parse(JSON.stringify(this.prescription.MedicationRequests));
    else this.listMedicationTable = [];
    this.isShowFormMedication = false;
    this.resetPopup();
    this.addElseUpdate = true;
    this.selectedRow = -1;
    // hủy nhưng đơn chưa có thuốc => trở về trạng thái có btn Tạo đơn
    if (this.prescription == null) {
      this.isEmptyPrescription = true;
      this.canEditPrescription = this.userRoleInGroup.canEditPrescription;
      this.isEditMode = this.canEditPrescription && !this.disabledActiton;
    }
  }

  setGeneralInfo() {
    //fill the general info with the existing prescription if needed
    if (this.prescription == null) {
      let filled = false;
      if (this.listPrescriptionOfPatient.length > 0) {
        const previous = this.listPrescriptionOfPatient[0];
        if (previous.RecipientName != null) {
          this.generalInfo = {
            recipientName: previous.RecipientName,
            recipientPhoneNo: previous.RecipientPhoneNo,
            recipientAddress: previous.RecipientAddress,
            paymentMethod: previous.PaymentMethod,
            recipientTimePlan: previous.RecipientTimePlan,
            recipientNote: previous.RecipientNote,
          };
          filled = true;
        }
      }
      if (!filled && this._visit != null) {
        this.generalInfo = {
          recipientName: this._visit.patientName,
          recipientPhoneNo: this._visit.phoneNo,
          recipientAddress: this._visit.address,
        };
      }
    }
  }
  gotoPrescriptionTab(data) {
    this.visitId = data.VisitId;
    this.newVisitId.emit(data.VisitId);

    this.listPrescriptionOfPatient = this.listPrescriptionOfPatientAll.filter((en) => en.VisitId != data.VisitId);
    let listOfVisit = this.listPrescriptionOfPatientAll.filter((en) => en.VisitId == data.VisitId);
    this.listPrescriptionsOfVisit = listOfVisit;
    this.prescription = data;
    this.checkShowingBtn();
    this.showPrescription();

    $('.thuoc-container').closest('.ant-tabs-content').animate({ scrollTop: '0' });
  }

  async getSampleData(sample) {
    if (this.prescription != null && this.prescription.Status > 5) {
      this.isExporting = true;
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.WARRING,
        'Đơn thuốc này đã xuất, bạn không thể dùng đơn thuốc mẫu được nữa'
      );
      return;
    }
    if (this.prescription != null) {
      this.prescriptionService.addFromSample(this.prescription.Id, sample.Id).subscribe((res) => {
        if (res.d.isValid) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Cập nhật đơn thuốc theo đơn thuốc mẫu thành công'
          );
          this.getPrescription(this.prescription.Id);
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.d.errors[0].errorMessage);
        }
      });
    } else {
      if (sample.MedicationRequests != null && sample.MedicationRequests.length > 0) {
        console.log('H', sample.MedicationRequests)
        sample.MedicationRequests.forEach((en) => {
          en.Title = this.getMedicationName(en.MedicationName, en.Content, en.ActiveIngredient);
        });
        this.listMedicationTable = sample.MedicationRequests;
        this.addPrescription();
      }
    }
    this.isEmptyPrescription = false;
    this.doctorComment = sample.DoctorComment;
  }

  openCreateSample() {
    if (this.listMedicationTable.length == 0) {
      this.notificationService.showNotification(Constant.ERROR, 'Đơn không có thuốc để tạo!');
      return;
    }
    this.isVisibleCreateSample = true;
  }

  //for approving prescription
  approvePrescription() {
    this.modalService.confirm({
      nzTitle: 'Duyệt toa thuốc',
      nzContent: 'Bạn có thực sự muốn duyệt toa thuốc?',
      nzOkText: 'Duyệt',
      nzCancelText: 'Bỏ qua',
      nzOnOk: () => {
        const payload = {
          prescriptionId: this.prescription.Id,
          message: '',
        };
        this.prescriptionService.approvePrescription(payload).subscribe((res) => {
          if (res.isValid) {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Duyệt toa thuốc thành công!');
            this.prescription.Status = Constant.PrescriptionStatus.Approved;
            this.setPermissions4Prescription();
            this.getVisitPrescriptions();
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
          }
        });
      },
    });
  }

  queryPrescription() {
    this.queryingPrescriptionAction = 0;
    this.isVisiblePrescriptionApproving = true;
    this.approvingMessage = '';
  }
  denyPrescription() {
    this.queryingPrescriptionAction = 1;
    this.isVisiblePrescriptionApproving = true;
    this.approvingMessage = '';
  }
  unapprovePrescription() {
    this.queryingPrescriptionAction = 2;
    this.isVisiblePrescriptionApproving = true;
    this.approvingMessage = '';
  }
  removePrescription() {
    this.modalService.confirm({
      nzTitle: 'Bạn có thực sự muốn xóa đơn thuốc hiện tại của ca khám?',
      nzOkText: 'Có',
      nzCancelText: 'Không',
      nzWidth: 'fit-content',
      nzOnOk: () =>
        this.prescriptionService.deletePrescription(this.prescription.Id).subscribe((res) => {
          if (res && res.isValid) {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xoá đơn thuốc thành công!');
            this.listPrescriptionsOfVisit = this.listPrescriptionsOfVisit.filter((en) => en.Id != this.prescription.Id);
            this.prescription = null;
            this.getAllPrescriptionOfPatient();
            this.resetForm();
            this.checkShowingBtn();
            this.titleTabRefresh.emit(4);
            this.listMedicationTable = [];
          }
          //TODO: show error detail from res
          else this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Xoá đơn thuốc thất bại!');
        }),
    });
  }
  getPrescriptionOther(data) {
    console.log('getPrescriptionOther', data);
    this.prescription = data;
    this.showPrescription();
    $('.thuoc-container').closest('.ant-tabs-content').animate({ scrollTop: '0' });
  }
  copyPrescription(data) {
    this.modalService.confirm({
      nzTitle: 'Bạn có muốn sao chép đơn thuốc này không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Hủy',
      nzWidth: 'fit-content',
      nzMaskClosable: true,
      nzOnOk: () => {
        if (this.prescription != null) {
          data.MedicationRequests.forEach((en) => {
            let existItem = this.listMedicationTable.find((e) => e.MedicationId == en.MedicationId);
            if (existItem) {
              existItem.Amount = en.Amount;
              existItem.ExistingAmount = en.ExistingAmount;
              existItem.Text = en.Text;
            } else {
              this.listMedicationTable.push(en);
              en.Title = this.getMedicationName(en.MedicationName, en.Content, en.ActiveIngredient);
            }
          });
          this.listMedicationTable = [...this.listMedicationTable];
          this.formPatchValue(data);
          this.savePrescription();
        } else {
          data.MedicationRequests.forEach((en) => {
            en.Title = this.getMedicationName(en.MedicationName, en.Content, en.ActiveIngredient);
          });
          this.listMedicationTable = [...data.MedicationRequests];
          this.formPatchValue(data);
          this.addPrescription();
        }
      },
    });
  }

  //////////////////////////////////////////////////
  // helper functions
  //////////////////////////////////////////////////
  formPatchValue(data) {
    this.isInitChanging = true;
    this.doctorComment = data.DoctorComment;
    this.prescriptionNoteFG.patchValue({
      visitAgainAfter: data.VisitAgainAfter,
      reExaminationDate: data.ReExaminationDate,
    });

    this.generalInfo = {
      recipientName: data.RecipientName,
      recipientPhoneNo: data.RecipientPhoneNo,
      recipientAddress: data.RecipientAddress,
      paymentMethod: data.PaymentMethod,
      recipientTimePlan: data.RecipientTimePlan,
      recipientNote: data.RecipientNote,
    };
    this.isInitChanging = false;
  }
  resetForm() {
    this.isInitChanging = true;
    this.doctorComment = '';
    this.generalInfo = null;
    this.prescriptionNoteFG.reset();
    this.isInitChanging = false;
  }
  closeModalSample() {
    this.showSample = false;
  }
  openModalSample() {
    this.showSample = true;
  }
  checkApprovingMsgEmpty() {
    if (this.shareService.checkEmptyStr(this.approvingMessage)) {
      this.errorEmptyApprovingMsg = 'Bạn cần nhập lý do!';
    } else {
      this.errorEmptyApprovingMsg = '';
    }
  }
  toStatusPrescriptionText(status: number): string {
    return this.prescriptionService.toStatusPrescriptionText(status, this.configGroup.isUseApprovePrescription);
  }
  toPaymentMethodText(status: number): string {
    return this.prescriptionService.toPaymentMethodText(status);
  }

  convertTextbox2Html(val: string) {
    if (val == undefined || val == null) {
      return '';
    }
    return val.split('\n').join('<br/>');
  }

  getMedicationDetailPrice(item) {
    if (item == undefined || item.price == undefined || item.price == 0) {
      return '';
    } else {
      const sl = this.getAmount(item.Amount, item.ExistingAmount);
      return item.price * sl;
    }
  }

  getTotalPrice() {
    if (this.listMedicationTable == undefined || this.listMedicationTable.length == 0) {
      return 0;
    }
    let total = 0;
    for (let i = 0; i < this.listMedicationTable.length; i++) {
      const sl = this.getAmount(this.listMedicationTable[i].Amount, this.listMedicationTable[i].ExistingAmount);
      const item = this.listMedicationTable[i];
      if (item != undefined && item.price > 0) {
        total += item.price * sl;
      }
    }
    return total;
  }

  getAmount(amount, existingAmount) {
    if (!this.shareService.checkEmpty(existingAmount)) {
      const numb = amount - existingAmount;
      if (numb > 0) {
        return numb;
      } else {
        return 0;
      }
    } else {
      return amount;
    }
  }
  handleCloseSharing() {
    this.showSharing = false;
  }
  checkShowingBtn() {
    if (this.prescription == null) {
      this.isShowBtnAction = false;
      this.isShowBtnExport = false;
      this.isOtherCreated = false;
    } else {
      let status = this.prescription.Status;
      const notApprove = !this.configGroup.isUseApprovePrescription && status >= Constant.PrescriptionStatus.Saved;
      const approve = this.configGroup.isUseApprovePrescription;
      if (
        this.configGroup.isConnectToHIS &&
        ((approve && status == Constant.PrescriptionStatus.Approved) ||
          (notApprove && status <= Constant.PrescriptionStatus.Approved))
      ) {
        this.isShowBtnExport = this.prescription.CreatorId == this.currentUserId;
      }
      if ((approve && status >= Constant.PrescriptionStatus.Approved) || notApprove) {
        this.isShowBtnAction = true;
      }
      this.isOtherCreated = this.prescription.CreatorId == this.currentUserId;

      this.isCanCreateMore =
        this.userRoleInGroup.canEditPrescription &&
        !this.listPrescriptionsOfVisit.some((en) => en.CreatorId == this.currentUserId);
    }
  }
  reExaminationDateChange(event) {
    if (this.isInitChanging) return;
    if (!this.isVisitAgainChanged) {
      this.prescriptionNoteFG.controls.visitAgainAfter.setValue(null);
    }
    this.callbackSavePrescription();
  }
  visitAgainAfterChange(option) {
    if (option == null) return;
    if (this.prescription != null) {
      let optObj = this.options.find((en) => en.value == option);
      let result = new Date(this.prescription.CreatedDate);

      if (option <= 2) {
        let days = optObj.valueCalc * 7;
        result.setDate(result.getDate() + days);
      } else {
        result.setMonth(result.getMonth() + optObj.valueCalc);
      }

      this.isVisitAgainChanged = true;
      this.prescriptionNoteFG.controls.reExaminationDate.setValue(result);
      this.isVisitAgainChanged = false;
    }
  }
  queryAprrovingEvent() {
    this.setPermissions4Prescription();
    this.getVisitPrescriptions();
  }
  changeOrder(data, type) {
    var leng = this.listMedicationTable.length;
    if (type == 1) {
      if (data.Order != 0) {
        let previous = data.Order;
        let current = data.Order - 1;
        this.selectedRow = current;

        this.swapOrder(previous, current);
      }
    } else if (type == -1) {
      if (data.Order != leng - 1) {
        let previous = data.Order;
        let current = data.Order + 1;
        this.selectedRow = current;

        this.swapOrder(previous, current);
      }
    }
  }
  doctorCommentChange() {
    if (this.prescription.DoctorComment != this.doctorComment) {
      this.callbackSavePrescription();
    }
  }
  dropMedicationItem(event: CdkDragDrop<string[]>) {
    if (this.isEditMode) {
      moveItemInArray(this.listMedicationTable, event.previousIndex, event.currentIndex);
      this.swapOrder(event.previousIndex, event.currentIndex);
    }
  }
  swapOrder(previousOrder, currentOrder) {
    let prevItem = this.listMedicationTable.find((en) => en.Order == previousOrder);
    let currItem = this.listMedicationTable.find((en) => en.Order == currentOrder);
    let temp = currItem.Order;
    currItem.Order = prevItem.Order;
    prevItem.Order = temp;

    this.listMedicationTable = [...this.listMedicationTable.sort((a, b) => a.Order - b.Order)];
    this.callbackSavePrescription();
  }
  getAllPrescriptionOfPatient() {
    this.prescriptionService.getAllPrescription(this.visit.patientId).subscribe((res) => {
      if (res.d.isValid) {
        const ret = JSON.parse(res.d.jsonData).JsonData;
        this.listPrescriptionOfPatientAll = JSON.parse(ret);

        this.listPrescriptionOfPatientAll.forEach((en) => {
          en.CanEdit = en.CreatorId == this.currentUserId;
          en.StatusString = this.toStatusPrescriptionText(en.Status);
          en.PaymentMethodString = this.toPaymentMethodText(en.PaymentMethod);

          en.MedicationRequests.forEach((e) => {
            e.Title = this.prescriptionService.getMedicationName(e.MedicationName, e.Content, e.ActiveIngredient,this.prescriptionIsShowActiveIngredient);
          });
        });

        this.listPrescriptionOfPatient = this.listPrescriptionOfPatientAll.filter((en) => en.VisitId != this.visit.id);
        this.setGeneralInfo();
      }
    });
  }
  convertTextTPCN(data) {
    if (data) return 'TPCN'
    else return 'Thuốc'
  }
}
