<div class="title-wrapper">
  <!--Tên danh mục-->
  <!-- <p class="page-title">Quản trị danh sách các hệ thống mã</p> -->
  <div class="head-obsertype">
    <div class="box-searchObser d-flex">
      <input
        style="height: 32px"
        nz-input
        type="text"
        [(ngModel)]="searchKey"
        placeholder="Tìm kiếm..."
        (keydown.enter)="searchName()" />
      <button
        class=""
        style="border-radius: 0px 5px 5px 0px"
        nz-button
        nzType="primary"
        (click)="searchName()">
        <i nz-icon nzType="search" nzTheme="outline"></i>
      </button>
    </div>
    <!--Thêm mới và cập nhật thông tin hệ thống mã-->
    <div class="table-operations">
      <button class="mg-r-10 btn-green" nz-button (click)="showModal('them')">
        <i nz-icon nzType="plus"></i>Thêm mới
      </button>
      <button
        [disabled]="selectedRowIndex == emptyId"
        class="mg-r-10"
        nz-button
        nzType="primary"
        (click)="showModal(dataForm)">
        <i nz-icon nzType="form"></i>Sửa
      </button>
    </div>
  </div>
  <!--Bảng hiển thị thông tin dang sách các hệ thống mã-->
  <nz-table
    #fixedTable
    nzSize="small"
    [nzPageSize]="pageSize"
    [nzData]="listOfDisplayData"
    [nzShowPagination]="true"
    nzShowSizeChanger
    [nzPageSizeOptions]="[10, 20, 50, 100]"
    [nzScroll]="{ y: 'calc(100vh - 260px)' }">
    <thead>
      <tr>
        <th nzWidth="60px">STT</th>
        <th>Tên hệ thống</th>
        <th>Mã hệ thống</th>
        <th>Mô tả</th>
      </tr>
    </thead>
    <tbody>
      <!--Truyền dữ liệu vào bảng-->
      <tr
        *ngFor="let data of fixedTable.data; let i = index"
        [ngClass]="{ 'highlight': selectedRowIndex == data.id }"
        (click)="RowSelected(data)">
        <td>
          {{ fixedTable.nzPageSize * (fixedTable.nzPageIndex - 1) + i + 1 }}
        </td>
        <td>{{ data.sysName }}</td>
        <td>{{ data.sysCode }}</td>
        <td>{{ data.description }}</td>
      </tr>
    </tbody>
  </nz-table>
  <!--popup thêm dữ liệu mới hệ thống mã-->
  <nz-modal
    class="modal-admin"
    [(nzVisible)]="isVisible"
    [nzTitle]="
      selectedRowIndex != emptyId ? 'Chỉnh sửa mã hệ thống' : 'Thêm mã hệ thống'
    "
    [nzWidth]="700"
    (nzOnCancel)="handleCancel()">
    <div class="msc-admin-row">
      <div class="msc-group-control width-obser">
        <label>Tên hệ thống:<span class="red-dot">*</span></label>
        <input
          type="text"
          placeholder="Tên hệ thống..."
          [(ngModel)]="dataForm.sysName" />
      </div>
    </div>
    <div class="msc-admin-row">
      <div class="msc-group-control width-obser">
        <label>Mã hệ thống:<span class="red-dot">*</span></label>
        <input
          type="text"
          placeholder="Mã hệ thống..."
          [(ngModel)]="dataForm.sysCode" />
      </div>
    </div>
    <div class="msc-admin-row">
      <div class="msc-group-control width-obser">
        <label>Mô tả:</label>
        <textarea
          nz-input
          placeholder="Mô tả..."
          [(ngModel)]="dataForm.description"
          >{{ dataForm.description }}</textarea
        >
      </div>
    </div>
    <div *nzModalFooter>
      <button
        *ngIf="selectedRowIndex != emptyId"
        nz-button
        nzType="primary"
        (click)="editSystemLists(dataForm)">
        Lưu
      </button>
      <button
        *ngIf="selectedRowIndex == emptyId"
        nz-button
        nzType="primary"
        (click)="addSystemLists(dataForm)">
        Thêm
      </button>
      <button nz-button nzType="default" (click)="handleCancel()">Huỷ</button>
    </div>
  </nz-modal>
</div>
