<div class="row visit-list" *ngFor="let visit of listVisitGroupByDoctorId">
  <h4 class="col-12 text-center title">
    <img style="margin-bottom: 3px; margin-right: 10px" src="assets/image/DPicon/doctor-fill-white.svg" />{{
    visit[0].doctorName
    }}<span style="margin-left: 10px">-</span><span style="margin-left: 10px; font-weight: bold;">{{ visit.length }}
      CK</span>
  </h4>
  <div class="col-12">
    <table class="week-table">
      <thead>
        <tr>
          <th></th>
          <th *ngFor="let day of listDayofWeek" (click)="onClickDateofWeek(day, visit[0].doctorId)" class="text-center">
            {{ day | date: 'EEEE, d - M' }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let timeSlot of timeSlotsDisplay; index as i">
          <td class="time-line">{{ timeSlot | date: 'HH:mm' }}</td>
          <td *ngFor="let day of listDayofWeek; index as j">
            <div class="d-flex flex-wrap w-100" [innerHTML]="visit | weekViewPipe: timeSlot:timeSlotsDisplay[i + 1]:day"
              (mouseover)="onVisitItemHover($event)" (click)="onVisitItemClick($event)"
              (mouseleave)="onVisitItemLeave($event)"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>