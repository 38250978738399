<div
  class="addPatientForm addPatientAndVisit"
  *ngIf="isVisibleAdd | async as visible">
  <!-- <form [formGroup]="formAddPatient" (ngSubmit)="handleOk()"> -->
  <!-- <nz-modal
      nzTitle="Thêm bệnh nhân"
      [nzVisible]="visible"
      [nzWidth]="770"
      [nzMaskClosable]="false"
      (nzOnCancel)="handleCancel()"
      nzClassName="add-patient"
    > -->
  <app-m-modal
    class="add-patient"
    [modalId]="'add-patient'"
    [isVisible]="visible"
    (closeModal)="handleCancel()"
    [mMask]="true"
    [width]="770"
    [minWidth]="770"
    [height]="700"
    [minHeight]="600"
    [isResiable]="true">
    <ng-template #mModalTitle>
      <div class="header-title">Thêm bệnh nhân</div>
    </ng-template>
    <ng-template #mModalContent>
      <form [formGroup]="formAddPatient" (ngSubmit)="handleOk()">
        <div class="row">
          <div
            class="col-4 mg-t-30 avt-upload"
            style="padding: 5px !important"
            title="Tải ảnh lên">
            <!-- <img class="contain" src="assets/image/avt-default.png" style="width: 224px; height:240px;"> -->

            <nz-upload
              class="avatar-uploader"
              [nzAction]="uploadUrl"
              nzName="FormFile"
              nzListType="picture-card"
              [nzShowUploadList]="false"
              [nzBeforeUpload]="beforeUpload"
              [nzHeaders]="header"
              (nzChange)="handleChange($event)"
              style="width: 100%; height: 100%">
              <ng-container
                *ngIf="!avatarUrl"
                style="width: 100%; height: 100%">
                <!-- <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i> -->
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="50"
                    height="45"
                    viewBox="0 0 491 433">
                    <defs>
                      <clipPath id="clip-iconpic">
                        <rect width="491" height="433" />
                      </clipPath>
                    </defs>
                    <g id="iconpic" clip-path="url(#clip-iconpic)">
                      <rect width="491" height="433" fill="#fafafa" />
                      <g
                        id="Group_1129"
                        data-name="Group 1129"
                        transform="translate(55 59)"
                        opacity="0.5">
                        <g
                          id="Group_1124"
                          data-name="Group 1124"
                          transform="translate(0 0)">
                          <g
                            id="Group_1123"
                            data-name="Group 1123"
                            transform="translate(0 0)">
                            <path
                              id="Path_336"
                              data-name="Path 336"
                              d="M350.824,43.979H29.236A28.927,28.927,0,0,0,0,73.215v256.3a28.927,28.927,0,0,0,29.236,29.236H350.824a28.927,28.927,0,0,0,29.236-29.236V73.215A28.929,28.929,0,0,0,350.824,43.979Zm0,285.532H29.236L29.011,73.234a.93.93,0,0,1,.224-.019H350.824v256.3Z"
                              transform="translate(0 -43.979)"
                              fill="#272848" />
                          </g>
                        </g>
                        <g
                          id="Group_1126"
                          data-name="Group 1126"
                          transform="translate(5.959 87.705)">
                          <g
                            id="Group_1125"
                            data-name="Group 1125"
                            transform="translate(0 0)">
                            <path
                              id="Path_337"
                              data-name="Path 337"
                              d="M147.315,167.419a14.62,14.62,0,0,0-19.909-2.451L8.028,252.674,25.34,276.238l108.268-79.544L287.556,382.44l22.5-18.657Z"
                              transform="translate(-8.028 -162.132)"
                              fill="#272848" />
                          </g>
                        </g>
                        <g
                          id="Group_1128"
                          data-name="Group 1128"
                          transform="translate(198.469 102.816)">
                          <g
                            id="Group_1127"
                            data-name="Group 1127"
                            transform="translate(0 0)">
                            <path
                              id="Path_338"
                              data-name="Path 338"
                              d="M354.729,185.642a14.623,14.623,0,0,0-18.17.024l-69.19,55.06,18.2,22.876,60.118-47.838,79.583,62.968,18.136-22.925Z"
                              transform="translate(-267.369 -182.489)"
                              fill="#272848" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <br />
                  <div class="ant-upload-text">Ảnh đại diện</div>
                </div>
              </ng-container>
              <img
                class="PatientAvatar"
                *ngIf="avatarUrl"
                [src]="avatarUrl"
                style="width: 100%; height: 100%; object-fit: cover" />
              <!-- <img class="contain" src="assets/image/avt-default.png" style="width: 224px; height:240px;"> -->
            </nz-upload>
          </div>
          <div class="col-8" style="padding-left: 0px !important">
            <div class="row">
              <div class="col-6">
                <div
                  class="message-required"
                  *ngIf="submitted && f.fullName.errors">
                  <div *ngIf="f.fullName.errors.required">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"
                      style="vertical-align: baseline"></i>
                    Bạn chưa nhập tên
                  </div>
                  <div *ngIf="f.fullName.errors.minlength">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"></i>
                  </div>
                  <div *ngIf="f.fullName.errors.maxlength">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"></i>
                  </div>
                  <div *ngIf="f.fullName.errors.duplicated">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"
                      style="vertical-align: baseline"></i>
                    Tên bệnh nhân trống
                  </div>
                </div>

                <div class="mg-r-30 mg-t-30 k-info-wrapper">
                  <div class="elm-title">
                    TÊN BỆNH NHÂN (<span class="d-note"> * </span>)
                  </div>
                  <input
                    formControlName="fullName"
                    [ngClass]="{
                      'is-invalid': submitted && f.fullName.errors
                    }" />
                  <!-- <input formControlName="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" /> -->
                </div>
              </div>
              <div class="col-6">
                <div class="message-required" *ngIf="submitted && f.sex.errors">
                  <div *ngIf="f.sex.errors.pattern || f.sex.errors.required">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"
                      style="vertical-align: baseline"></i>
                    Bạn chưa chọn giới tính
                  </div>
                </div>
                <div class="mg-r-30 mg-t-30 k-info-wrapper">
                  <div class="elm-title">
                    GIỚI TÍNH (<span class="d-note"> * </span>)
                  </div>
                  <!-- <input formControlName="sex" /> -->
                  <nz-select
                    nz-menu
                    [nzPlaceHolder]="'Chọn giới tính'"
                    formControlName="sex"
                    [ngClass]="{ 'is-invalid': submitted && f.sex.errors }">
                    <!-- <nz-option nzValue="2" nzLabel="Chọn giới tính"></nz-option> -->
                    <nz-option [nzValue]="1" nzLabel="Nam"></nz-option>
                    <nz-option [nzValue]="0" nzLabel="Nữ"></nz-option>
                    <nz-option
                      [nzValue]="2"
                      nzLabel="Không xác định"></nz-option>
                  </nz-select>
                  <!-- <input formControlName="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" /> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="message-required" *ngIf="submitted && f.dob.errors">
                  <div *ngIf="f.dob.errors.required">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"
                      style="vertical-align: baseline"></i>
                    Bạn chưa chọn ngày sinh
                  </div>
                </div>
                <div class="message-required" *ngIf="submitted && checkDate">
                  <div *ngIf="checkDate">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"
                      style="vertical-align: baseline"></i>
                    Ngày sinh không hợp lệ
                  </div>
                </div>
                <div class="mg-r-30 mg-t-30 k-info-wrapper">
                  <div class="elm-title">
                    NGÀY SINH (<span class="d-note"> * </span>)
                  </div>
                  <nz-date-picker
                    formControlName="dob"
                    [nzPlaceHolder]="'DD/MM/YYYY'"
                    [nzFormat]="dateFormat"
                    [nzDisabledDate]="disabledDateAfterToday"
                    [ngClass]="{ 'is-invalid': submitted && f.dob.errors }"
                    style="margin-top: 7px"></nz-date-picker>

                  <!-- <input formControlName="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" /> -->
                </div>
              </div>
              <div class="col-6">
                <div
                  class="message-required"
                  *ngIf="submitted && f.idNumber.errors">
                  <div
                    *ngIf="
                      f.idNumber.errors.minlength || f.idNumber.errors.maxlength
                    ">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"
                      style="vertical-align: baseline"></i>
                    Số CMND không hợp lệ
                  </div>
                </div>
                <div class="mg-r-30 mg-t-30 k-info-wrapper">
                  <div class="elm-title">CHỨNG MINH THƯ</div>
                  <input
                    formControlName="idNumber"
                    [ngClass]="{
                      'is-invalid': submitted && f.idNumber.errors
                    }" />
                  <!-- <input formControlName="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" /> -->
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div
                  class="message-required"
                  *ngIf="submitted && f.phoneNo.errors">
                  <div *ngIf="f.phoneNo.errors.pattern">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"
                      style="vertical-align: baseline"></i>
                    SĐT không đúng định dạng
                  </div>
                </div>
                <div
                  class="message-required"
                  *ngIf="submitted && f.phoneNo.errors">
                  <div *ngIf="f.phoneNo.errors.required">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"
                      style="vertical-align: baseline"></i>
                    Bạn chưa nhập số điện thoại
                  </div>
                </div>
                <div class="mg-r-30 mg-t-30 k-info-wrapper">
                  <div class="elm-title">
                    SỐ ĐIỆN THOẠI (<span class="d-note"> * </span>)
                  </div>
                  <input formControlName="phoneNo" placeholder="0xxxxxxxxx" />
                  <!-- <input formControlName="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" /> -->
                </div>
              </div>
              <div class="col-6">
                <div
                  class="message-required"
                  *ngIf="submitted && f.email.errors">
                  <div *ngIf="f.email.errors.email">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"
                      style="vertical-align: baseline"></i>
                    Email không hợp lệ
                  </div>
                </div>
                <div class="mg-r-30 mg-t-30 k-info-wrapper">
                  <div class="elm-title">EMAIL</div>
                  <input
                    formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <!-- <input formControlName="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8" style="padding-left: 0px !important">
            <div class="mg-r-30 mg-t-30 k-info-wrapper">
              <div class="elm-title">ĐỊA CHỈ</div>
              <input formControlName="address" />
              <!-- <input formControlName="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" /> -->
            </div>
          </div>
          <div class="col-4">
            <div class="mg-r-30 mg-t-30 k-info-wrapper">
              <div class="elm-title">MÃ BỆNH NHÂN</div>
              <input formControlName="code" />
              <!-- <input formControlName="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" /> -->
            </div>
          </div>
        </div>

        <div class="break-line"></div>
        <div class="row">
          <div class="message-required" *ngIf="submitted && f.BHYTCode.errors">
            <div *ngIf="f.BHYTCode.errors.empty">
              <i
                nz-icon
                nzType="close-circle"
                [nzTheme]="'twotone'"
                [nzTwotoneColor]="'#FF0000'"
                style="vertical-align: baseline"></i>
              Bạn chưa nhập BYTT
            </div>
          </div>
          <div class="col-4" style="padding-left: 0px !important">
            <div class="mg-r-30 mg-t-30 k-info-wrapper">
              <div class="elm-title">SỐ THẺ BẢO HIỂM</div>
              <input
                formControlName="BHYTCode"
                [ngClass]="{ 'is-invalid': submitted && f.BHYTCode.errors }" />
              <!-- <input formControlName="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" /> -->
            </div>
          </div>

          <div class="col-4">
            <div
              class="message-required"
              *ngIf="submitted && f.validDate.errors">
              <div *ngIf="f.validDate.errors.validDate">
                <i
                  nz-icon
                  nzType="close-circle"
                  [nzTheme]="'twotone'"
                  [nzTwotoneColor]="'#FF0000'"
                  style="vertical-align: baseline"></i>
                Ngày không hợp lệ
              </div>
            </div>
            <div class="mg-r-30 mg-t-30 k-info-wrapper">
              <div class="elm-title">TỪ NGÀY</div>
              <nz-date-picker
                formControlName="validDate"
                [nzPlaceHolder]="'Chọn ngày'"
                [nzFormat]="dateFormat"
                style="margin-top: 7px"
                [ngClass]="{ 'is-invalid': submitted && f.validDate.errors }"
                [nzDisabledDate]="disabledDateAfterToday"></nz-date-picker>
              <!-- <input formControlName="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" /> -->
            </div>
          </div>

          <div class="col-4">
            <div
              class="message-required"
              *ngIf="submitted && invalidExpireDate">
              <div *ngIf="invalidExpireDate">
                <i
                  nz-icon
                  nzType="close-circle"
                  [nzTheme]="'twotone'"
                  [nzTwotoneColor]="'#FF0000'"
                  style="vertical-align: baseline"></i>
                Ngày không hợp lệ
              </div>
            </div>
            <div class="mg-r-30 mg-t-30 k-info-wrapper">
              <div class="elm-title">ĐẾN NGÀY</div>
              <nz-date-picker
                formControlName="expireDate"
                [nzPlaceHolder]="'Chọn ngày'"
                [nzFormat]="dateFormat"
                style="margin-top: 7px"
                [ngClass]="{ 'is-invalid': submitted && f.validDate.errors }"
                [nzDisabledDate]="disabledDateBeforeToday"></nz-date-picker>
              <!-- <input formControlName="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" /> -->
            </div>
          </div>
        </div>

        <div class="row mg-t-30" title="Tải ảnh lên">
          <nz-upload
            class="up-files"
            [nzAction]="uploadFileUrl"
            [nzHeaders]="header"
            [nzBeforeUpload]="beforeUploadFile"
            nzName="FormFile"
            [nzLimit]="7"
            [nzFileList]="listImgs"
            [nzRemove]="handleRemoveImg"
            (nzChange)="fileChange($event)"
            style="width: 100%">
            <img class="mgr15" src="/assets/image/upload-cloud.svg" />
            <span>Tải tập tin lên</span>
          </nz-upload>
        </div>
      </form>
    </ng-template>
    <ng-template #mModalFooter>
      <button class="btn-primary-large" (click)="handleOk()">
        Thêm bệnh nhân
      </button>
      <button class="btn-cancel-large" (click)="handleCancel()">
        Quay lại
      </button>
    </ng-template>
  </app-m-modal>
  <!-- <div *nzModalFooter>
        <button class="button-submit btn-cancel-large" (click)="handleCancel()">
          Quay lại
        </button>
        <button class="btn-primary-large" (click)="handleOk()">
          Thêm bệnh nhân
        </button>
      </div> -->
  <!-- </nz-modal> -->
  <!-- </form> -->
</div>
