<div class="form-input form-elm">
  <input
    *ngIf="isMobile"
    class="input-number-mobile"
    type="text"
    [ngModel]="value"
    inputmode="decimal"
    (blur)="validateValue($event)"
    [ngClass]="[
      data.formatStyle.isBold == true ? 'bold' : '',
      data.formatStyle.isItalic == true ? 'italic' : '',
      data.formatStyle.isUnderline == true ? 'underline' : '',
      data.formatStyle.align
    ]"
    [ngStyle]="{
      'color': data.formatStyle.color,
      'background': data.formatStyle.bgColor,
      'font-size': data.formatStyle.fontSize + 'px',
      'font-family': data.formatStyle.font
    }"
    [disabled]="data.editType == 0 || data.editType == 3"
    (ngModelChange)="onModelChange($event)" />
  <nz-input-number
    *ngIf="!isMobile"
    nz-input
    [(ngModel)]="data.valueInteger"
    (keydown.,)="keyComma($event)"
    [ngClass]="[
      data.formatStyle.isBold == true ? 'bold' : '',
      data.formatStyle.isItalic == true ? 'italic' : '',
      data.formatStyle.isUnderline == true ? 'underline' : '',
      data.formatStyle.align
    ]"
    [ngStyle]="{
      'color': data.formatStyle.color,
      'background': data.formatStyle.bgColor,
      'font-size': data.formatStyle.fontSize + 'px',
      'font-family': data.formatStyle.font
    }"
    [nzDisabled]="data.editType == 0 || data.editType == 3"
    (nzBlur)="validateMinMax($event)"
    (ngModelChange)="onModelChange($event)">
  </nz-input-number>
  <span class="valid-range" *ngIf="title != ''">
    {{ title }}
  </span>
</div>
