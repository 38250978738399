<div class="">
  <div class="content-head mgb20 d-flex justify-content-between">
    <div class="d-flex">
      <nz-date-picker [nzAllowClear]="false" nzMode="date" nzFormat="dd/MM/yyyy" [(ngModel)]="dataSearch.fromDate"
        (ngModelChange)="search()" nzPlaceHolder="Từ (dd/MM/yyyy)" style="width: 140px; margin-right: 10px">
      </nz-date-picker>
      <nz-date-picker [nzAllowClear]="false" nzMode="date" nzFormat="dd/MM/yyyy" [(ngModel)]="dataSearch.toDate"
        (ngModelChange)="search()" nzPlaceHolder="Đến (dd/MM/yyyy)" style="width: 140px; margin-right: 10px">
      </nz-date-picker>
      <input [(ngModel)]="dataSearch.name" (keydown.enter)="search()" nz-input type="text" placeholder="Chỉ định..."
        style="width: 140px" />

      <div (click)="resetSearch()" class="btn-searchbar ml-2">
        <img src="assets/image/DPicon/icon-close.svg" />
      </div>
      <div (click)="search()" class="btn-searchbar ml-2">
        <img src="assets/image/DPicon/icon-search.svg" />
      </div>
    </div>
    <div>
      <!-- <button title="Xuất excel" nz-button nzType="default" (click)="showAddPopup()" class="mg-l-10">
        <i nz-icon nzType="plus" nzTheme="outline" style="color: black"></i> Thêm mới
      </button> -->
    </div>
  </div>

</div>

<div nz-row class="data-table">
  <div nz-col nzSpan="24">
    <nz-table class="main-table-grid" #fixedTable nzSize="small" [nzLoading]="loading" [(nzData)]="listDisplay" [nzFrontPagination]="false"
      [nzTotal]="total" (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger
      [(nzPageSize)]="dataSearch.pageSize" [(nzPageIndex)]="dataSearch.page"
      [nzPageSizeOptions]="[10, 20, 50, 100]" [nzScroll]="{ y: 'calc(100vh - 250px)' }" [nzShowTotal]="rangeTemplate">
      <thead>
        <tr>
          <th nzWidth="50px" class="text-align-center">STT</th>
          <th nzWidth="60px" class="text-center">Action</th>
          <th nzWidth="150px">Trạng thái</th>
          <th nzWidth="250px">Trạng thái thực hiện</th>
          <th nzWidth="600px">Chỉ định</th>
          <th>Ngày khám</th>
          <th>Tên bệnh nhân</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fixedTable.data; index as i" >
          <td class="text-align-center">{{ dataSearch.pageSize * (dataSearch.page - 1) + i + 1 }}</td>
          <td class="text-center">
            <img  *ngIf="!data.push2Pacs" (click)="resend(data)" title="Đẩy lại chỉ định" style="height: 20px"
              src="/assets/image/menu-left/repeat.png" alt="" />
            </td>
          <td class="font-weight-bold">
            <span *ngIf="data.push2Pacs">Đã đẩy</span>
            <span *ngIf="!data.push2Pacs" class="text-danger">Chưa đẩy</span>
          </td>
          <td>
            <span *ngIf="data.status == SubclinicalVisitHL7Status.CM">Đã thực hiện</span>
            <span *ngIf="data.status != SubclinicalVisitHL7Status.CM" >Chưa thực hiện hoặc lỗi hệ thống</span>
          </td>
          <td>
            {{data.name}}
          </td>
          <td>{{data.visitDate |date:'dd/MM/yyy H:mm' }}</td>
          <td>{{data.patientName }}</td>
        </tr>
      </tbody>
      <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }}
      </ng-template>
    </nz-table>
  </div>
</div>
