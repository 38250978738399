import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MyDatePicker } from './mydatepicker.component';
import { FocusDirective } from './directives/mydatepicker.focus.directive';
import { MscDatePickerComponent } from './msc-date-picker/msc-date-picker.component';

@NgModule({
  declarations: [MyDatePicker, FocusDirective, MscDatePickerComponent],
  imports: [CommonModule, FormsModule],
  exports: [MyDatePicker, MscDatePickerComponent, FocusDirective],
  // providers: [MyDatePicker, LocaleService, UtilService]
})
export class MyDatePickerModule {}
