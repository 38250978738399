import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Formula } from 'src/app/models/form-model/formula';

@Component({
  selector: 'app-formula-control',
  templateUrl: './formula-control.component.html',
  styleUrls: ['./formula-control.component.scss'],
})
export class FormulaControlComponent implements OnInit {
  @Input() data: Formula;
  @Output() selectElement: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}
}
