<nz-modal
  nzWidth="700px"
  [nzMaskClosable]="false"
  nzClassName="rmd-modal"
  nzTitle="Thông tin lời nhắc"
  [(nzVisible)]="isVisible"
  [nzBodyStyle]="{ 'max-height': '70vh', 'overflow-y': 'auto' }"
  (nzOnCancel)="handleCancel()">
  <div [formGroup]="reminderForm">
    <div class="text-right">
      <i
        style="font-size: 20px; color: #2b4e9b; margin-right: 1px"
        nz-icon
        nzType="unordered-list"
        nzTheme="outline"
        title="Danh sách công việc mẫu"
        (click)="showListSample()"></i>
    </div>
    <div class="row">
      <div class="col-3">
        <label>Công việc<span class="red-dot"> *</span></label>
      </div>
      <div class="col-9">
        <input
          #title
          formControlName="title"
          minLength="2"
          nz-input
          (input)="callbackSearchSampleFn()"
          [nzAutocomplete]="rmdName"
          />
        <nz-autocomplete #rmdName>
          <nz-auto-option
            nzAllowClear
            *ngFor="let item of lstWorkReminderSample"
            [nzValue]="item.title"
            (click)="selectSample(item)">
            <span>{{ item.title }}</span>
          </nz-auto-option>
        </nz-autocomplete>
      </div>
    </div>
    <div class="row">
      <div class="col-3"><label>Mô tả</label></div>
      <div class="col-9">
        <textarea
          nz-input
          formControlName="detail"
          style="height: 60px"></textarea>
      </div>
    </div>
    <div *ngIf="visit == null" class="row">
      <div class="col-3">
        <label>Tên bệnh nhân<span class="red-dot"> *</span></label>
      </div>
      <div class="col-9">
        <input
          formControlName="patientName"
          nz-input
          [nzAutocomplete]="auto"
          (input)="callbackSearchFn()"
          (ngModelChange)="selectSearchPatient($event)" />
        <nz-autocomplete #auto>
          <nz-auto-option
            *ngFor="let item of lstSearchPatient"
            [nzValue]="item.id"
            [nzLabel]="item.fullName">
            <span>
              <p>{{ item.fullName }}</p>
              <i>{{ item.address }}</i>
            </span>
          </nz-auto-option>
        </nz-autocomplete>
      </div>
    </div>
    <div class="row">
      <div class="col-3"><label>Sử dụng lời nhắc cho bản thân</label></div>
      <div class="col-9">
        <label nz-checkbox formControlName="isForMe"></label>
      </div>
    </div>
    <ng-container *ngIf="!reminderForm.value.isForMe" class="mg-l-30">
      <div class="row">
        <div class="col-3">
          <label>Chọn bác sĩ<span class="red-dot"> *</span></label>
        </div>
        <div class="col-9">
          <nz-select
            class="w-200px"
            nzAllowClear
            formControlName="doctorId"
            [nzShowSearch]="true">
            <nz-option
              *ngFor="let item of doctorInGroup"
              [nzLabel]="item.fullName"
              [nzValue]="item.id">
            </nz-option>
          </nz-select>
        </div>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-3"><label>Thông báo trước</label></div>
      <div class="col-9">
        <nz-select
          class="w-200px"
          nzAllowClear
          formControlName="minutesBeforeReminder">
          <nz-option
            *ngFor="let item of listMinutesBeforeReminder"
            [nzLabel]="item.label"
            [nzValue]="item.value">
          </nz-option>
        </nz-select>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <label>Thời gian thực hiện<span class="red-dot"> *</span></label>
      </div>
      <div class="col-9">
        <nz-date-picker
          style="width: 200px"
          nzShowTime
          [nzPlaceHolder]="'DD/MM/YYYY  HH:mm'"
          nzFormat="dd/MM/yyyy HH:mm"
          formControlName="activeDate"
          [nzDisabledDate]="disabledDateBeforeToday"
          (ngModelChange)="onChange($event)">
        </nz-date-picker>
        <div
          *ngIf="isInvalidDate && reminderForm.value.id == ''"
          class="message-required">
          <i
            nz-icon
            nzType="close-circle"
            [nzTheme]="'twotone'"
            [nzTwotoneColor]="'#FF0000'"
            style="vertical-align: baseline"></i>
          <span>Ngày, giờ không hợp lệ</span>
        </div>
      </div>
    </div>
    <div class="reminder-log" *ngIf="reminderForm.value.id != ''">
      <div (click)="toggleShowReminderLog()">
        <span class="mg-bt-30 color-his">Lịch sử chỉnh sửa</span>
        <i
          nz-icon
          [nzType]="!isShowReminderLog ? 'caret-down' : 'caret-up'"
          nzTheme="outline"></i>
      </div>
      <div *ngIf="isShowReminderLog" class="history-edit customTableCrollbar">
        <ul>
          <li *ngFor="let log of currentReminderLog">
            <div class="mock-time">
              [ {{ log.dateModified | date: 'dd/MM/yyy HH:mm' }} -
              {{ log.modifiedBy }}
              ]
            </div>
            <div class="log-content" [innerHTML]="log.content"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button class="btn-primary-medium" nz-button (click)="save()">
      {{ reminderForm.value.id == null ? 'Thêm' : 'Sửa' }}
    </button>
    <button class="btn-cancel-medium" nz-button (click)="handleCancel()">
      Huỷ
    </button>
  </div>
</nz-modal>

<app-reminder-sample
  [(isVisible)]="isShowListSample"
  (usedSample)="usingSample($event)"></app-reminder-sample>
