import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppState } from 'src/app/components/app-state/app-state';
import { Visits } from 'src/app/models/listVisit.class';
import { Reminder } from 'src/app/models/Reminder.class';
import { DoctorService } from 'src/app/services/doctor.service';
import { ListReminderService } from 'src/app/services/ListReminder.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-patient-reminder-list',
  templateUrl: './patient-reminder-list.component.html',
  styleUrls: ['./patient-reminder-list.component.scss'],
})
export class PatientReminderListComponent implements OnInit {
  @Input() visit: Visits = null;
  _isVisible: boolean;
  @Input() set isVisible(val: boolean) {
    this._isVisible = val;
    if (val) {
      this.getPatientReminder();
      if (this.doctorInGroup.length == 0) {
        this.getDoctorInGroup(this.visit.groupId);
      }
    }
  }
  get isVisible(): boolean {
    return this._isVisible;
  }

  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  isVisibleForm = false;
  isNoData = false;
  lstDataOfPatient = [];
  doctorInGroup = [];
  curUserId = '';
  selectedRmd: any = null;
  listStatus = ['New', 'Doing', 'Done', 'Cancel'];

  constructor(
    private listReminderService: ListReminderService,
    private doctorService: DoctorService,
    private modalService: NzModalService,
    private notificationService: NotificationService,
    private store: Store<AppState>
  ) {
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
  }

  ngOnInit(): void {
    if (this.visit.id != '') {
      this.getPatientReminder();
    }
  }
  log(elementId, reminder) {
    if (elementId != 'r-' + reminder.id) {
      reminder.isEditStatus = false;
    }
  }
  getPatientReminder() {
    const payload = {
      patientId: this.visit.patientId,
      pageSize: 0,
      page: 0,
    };
    this.listReminderService.getPatientReminder(payload).subscribe((res) => {
      this.lstDataOfPatient = res.source.map((obj) => ({
        ...obj,
        isEditStatus: false,
      }));
      this.isNoData = this.lstDataOfPatient.length == 0 ? true : false;
    });
  }
  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;
      }
    });
  }
  handleCancel() {
    this.isVisible = false;
    this.closeModal.emit();
  }
  showModalForm(type, data) {
    this.selectedRmd = data;
    if (type == 'edit') {
      this.isVisibleForm = true;
    } else if (type == 'add') {
      this.selectedRmd = null;
      this.isVisibleForm = true;
    } else if (type == 'delete') {
      this.confirm(data.id);
    }
  }
  closeForm() {
    this.isVisibleForm = false;
  }

  confirm(id) {
    this.modalService.confirm({
      nzTitle: '<b>Xác nhận</b>',
      nzContent: 'Bạn có thực sự muốn xóa lời nhắc?',
      nzOnOk: () => {
        this.listReminderService.deleteReminder(id).subscribe((data) => {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Xoá thành công'
          );
          this.getPatientReminder();
        }).unsubscribe;
      },
      nzOkText: 'Xác nhận',
      nzCancelText: 'Không',
    });
  }
  editStatus(data) {
    data.isEditStatus = !data.isEditStatus;
  }
  toTextStatus(status) {
    return this.listReminderService.toTextStatus(status);
  }
  saveStatus(data) {
    const payload = { ...data, groupId: this.visit.groupId };
    this.listReminderService.updateReminder(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Cập nhật thành công'
        );
        data.isEditStatus = false;
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }
}
