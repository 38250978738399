import { Component, Input, OnInit } from '@angular/core';
import { FormElement } from 'src/app/models/form-model/form.class';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
})
export class InputNumberComponent implements OnInit {
  _data: FormElement;
  value: any = '';
  min: number = Number.MIN_VALUE;
  max: number = Number.MAX_VALUE;
  title = '';
  @Input() set data(val: FormElement) {
    if (val) {
      this.title = '';
      this._data = val;
      this.value = this.data.valueInteger;
      if (val.minNumberValue != null) {
        this.min = val.minNumberValue;
        this.title += 'Min: ' + this.min + '; ';
      }
      if (val.maxNumberValue != null) {
        this.max = val.maxNumberValue;
        this.title += 'Max: ' + this.max + '; ';
      }
    }
  }

  get data(): FormElement {
    return this._data;
  }

  isMobile = false;
  constructor(private shareService: ShareService) {
    this.isMobile = this.shareService.isMobileAndTabletCheck();
  }

  ngOnInit() {}

  validateValue(event) {
    try {
      this.value = this.value.toString();
      if (!isNaN(this.value) || this.value.includes(',')) {
        this.value = this.value.replace(',', '.');
        const floatVal = parseFloat(this.value);
        if (floatVal >= this.max && this.max != Number.MAX_VALUE) {
          alert('Giá trị lớn nhất là ' + this.max);
          // this.data.valueInteger = this.max;
          this.value = null;
          return;
        }

        if (floatVal <= this.min && this.min != Number.MIN_VALUE) {
          console.log(floatVal, this.min);
          alert('Giá trị nhỏ nhất là ' + this.min);
          // this.data.value = this.min;
          this.value = null;
          return;
        }

        this.data.valueInteger = floatVal;
      } else {
        this.value = null;
        alert('Not a Number');
      }
    } catch (exp) {
      alert(exp);
    }
  }

  validateMinMax(val) {
    console.log(this.data.valueInteger);
    if (this.data.valueInteger >= this.max && this.max != Number.MAX_VALUE) {
      alert('Giá trị lớn nhất là ' + this.max);
      // this.data.valueInteger = this.max;
      this.data.valueInteger = null;
      return;
    }

    if (this.data.valueInteger <= this.min && this.min != Number.MIN_VALUE) {
      alert('Giá trị nhỏ nhất là ' + this.min);
      // this.data.value = this.min;
      this.data.valueInteger = null;
      return;
    }
  }

  keyComma(val) {
    this.data.valueInteger += '.';
  }

  onModelChange(val) {
    this.data.isDirty = true;
  }
}
