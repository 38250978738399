import { string } from '@amcharts/amcharts4/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Visits } from 'src/app/models/listVisit.class';
import { NotificationService } from 'src/app/services/notification.service';
import { RecordVisitService } from 'src/app/services/record-visit-service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { FormComponent } from '../../form/form.component';

@Component({
  selector: 'app-data-form-mobile',
  templateUrl: './data-form-mobile.component.html',
  styleUrls: ['./data-form-mobile.component.scss'],
})
export class DataFormMobileComponent implements OnInit {
  hideButton: string;
  disabledClass: string;
  formId: string;
  recordId: string;
  visitId: string;
  @ViewChild('form') form: FormComponent;

  constructor(
    private recordVisitService: RecordVisitService,
    private notificationService: NotificationService,
    private shareService: ShareService,
    private route: ActivatedRoute
  ) {}
  visit: Visits;

  ngOnInit() {
    this.formId = this.route.snapshot.paramMap.get('formId');
    this.recordId = this.route.snapshot.paramMap.get('recordId');
    this.visitId = this.route.snapshot.paramMap.get('visitId');
  }

  convertName() {
    const name = this.visit.patientName.replace(' ', '_').toUpperCase();

    return this.shareService.toNomalize(name);
  }

  save() {
    const data = this.form.save();
    if (data == null) {
      return;
    }

    const saveData = {
      ...{
        visitId: this.visitId,
        formId: this.formId,
        recordId: this.recordId,
      },
      ...data,
    };
    this.recordVisitService.saveReport(saveData).subscribe((response) => {
      if (response.isValid) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Lưu Thành công'
        );
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          response.errors.errorMessage
        );
      }
    });
  }

  backToListPatient() {
    window.location.href = 'common-mobile/worklist/patient-list';
  }
}
