import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';

@Component({
  selector: 'app-week-view',
  templateUrl: './week-view.component.html',
  styleUrls: ['./week-view.component.scss'],
  providers: [DatePipe],
})
export class WeekViewComponent implements OnInit {
  _timeSlotsDisplay;
  get timeSlotsDisplay() {
    return this._timeSlotsDisplay;
  }
  @Input() set timeSlotsDisplay(value) {
    this._timeSlotsDisplay = value;
  }

  _listVisitGroupByDoctorId;
  get listVisitGroupByDoctorId() {
    return this._listVisitGroupByDoctorId;
  }
  @Input() set listVisitGroupByDoctorId(value) {
    this._listVisitGroupByDoctorId = value;
  }
  _time;
  get time() {
    return this._time;
  }
  @Input() set time(value) {
    this._time = value;
    this.listDayofWeek = this.getWeekDates(this.time);
  }

  listDayofWeek = [];
  @Output() visitItemHover = new EventEmitter<any>();
  @Output() visitItemLeave = new EventEmitter<any>();
  @Output() clickDateofWeek = new EventEmitter<any>();

  constructor(private tabDataService: TabDataService) {}

  ngOnInit(): void {
    // console.log(this.listDayofWeek);
  }
  getWeekDates(dateInput: Date) {
    var weekDates = [];
    var currentDay = dateInput.getDay();
    var daysToSubtract = currentDay === 0 ? 6 : currentDay - 1;
    var startOfWeek = new Date(dateInput);
    startOfWeek.setDate(startOfWeek.getDate() - daysToSubtract);
    for (var i = 0; i < 7; i++) {
      var newDate = new Date(startOfWeek);
      newDate.setDate(newDate.getDate() + i);
      newDate.setHours(0, 0, 0, 0);

      weekDates.push(newDate);
    }
    weekDates.forEach((el: Date) => {
      el.toISOString();
    });
    return weekDates;
  }
  onVisitItemClick(event) {
    // console.log(event);
    let dataVisit = JSON.parse(event.target.getAttribute('data-visit'));
    let popups = document.querySelectorAll('.popup-patient-info');

    // event.target.style.color = 'var(--dp-primary-2)';

    if (popups) {
      popups.forEach((el) => {
        el.remove();
      });
    }
    if(dataVisit.id && dataVisit.patientName){
      this.tabDataService.updateTab(dataVisit?.id, dataVisit?.patientName, 'VisitDetail', dataVisit?.id);
    }

  }
  onVisitItemHover(event) {
    this.visitItemHover.emit(event);
  }

  onVisitItemLeave(event) {
    this.visitItemLeave.emit();
  }
  onClickDateofWeek(day, doctorId) {
    this.clickDateofWeek.emit({ day, doctorId });
  }
}
