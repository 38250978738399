import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'weekViewPipe',
})
export class WeekViewPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(arr: any, timeFr: any, timeTo: any, day: any): any {
    // console.log(arguments);
    if (!timeFr || !timeTo || !day) return '';
    timeFr = new Date(timeFr);
    timeTo = new Date(timeTo);
    day = new Date(day);

    let dateFr = new Date(
      day.getFullYear(),
      day.getMonth(),
      day.getDate(),
      timeFr.getHours(),
      timeFr.getMinutes(),
      timeFr.getSeconds()
    ).toISOString();
    let dateTo = new Date(
      day.getFullYear(),
      day.getMonth(),
      day.getDate(),
      timeTo.getHours(),
      timeTo.getMinutes(),
      timeTo.getSeconds()
    ).toISOString();
    // console.log('====',dateFr,dateTo);

    let result = arr.filter((el) => {
      let date = new Date(el.visitDate).toISOString();
      return date >= dateFr && date < dateTo;
    });
    let html = '';
    if (result.length > 0) {
      result.forEach((el,i) => {
        html += `<div style="color:#007bff;border: 1px solid #c5baba;margin-top:10px; border-radius: 10px;
        margin-right: 10px;
        padding: 5px;
        background: #ffecb4;
        max-width: 205px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;" class="visit-item"  data-visit='${JSON.stringify(el)}'>
        <div style="margin-right: 49px;">
           ${new DatePipe('en-Us').transform(el.visitDate, 'HH:mm')}
           ${el.patientName}
        </div>
          </div>`;
      });
    }
    return this.sanitized.bypassSecurityTrustHtml(html);
  }
}
