<nz-modal
  class="custom-modal share-visit"
  [(nzVisible)]="isVisible"
  [nzWidth]="1000"
  [nzFooter]="null"
  nzTitle="Chia sẻ ca khám"
  (nzOnCancel)="handleCancel()">
  <div class="row">
    <div class="col-6">
      <span>Chia sẻ link ca khám</span>
      <a
        *ngIf="model.isOn === true"
        class="btnOnOff"
        (click)="onOffShareLink(false)"
        ><img src="assets/image/DPicon/on.svg"
      /></a>
      <a
        *ngIf="model.isOn === false"
        class="btnOnOff"
        (click)="onOffShareLink(true)"
        ><img src="assets/image/DPicon/off.svg"
      /></a>
    </div>
    <div id="lblOnOff" class="col-6">Chia sẻ</div>
  </div>
  <div class="row">
    <div class="col-8">
      <label>Link ca khám</label>
      <input #inputUrl id="url" type="text" [(ngModel)]="model.url" /><input
        id="btnCopy"
        (click)="copyInputUrl(inputUrl)"
        type="button"
        value="Sao chép đường dẫn" />
    </div>
    <div class="col-4">
      <label>Thời hạn</label>
      <nz-date-picker
        nzFormat="dd/MM/yyyy"
        [(ngModel)]="model.expireDate"
        nzPlaceHolder="Thời hạn chia sẻ"
        [nzDisabledDate]="disabledDateBeforeToday"></nz-date-picker>
      <!-- (nzOnOpenChange)="updateShareLink()"  -->
    </div>
  </div>
  <div id="share-vs-privacy" class="row">
    <nz-radio-group [(ngModel)]="model.shareLevel">
      <!-- (ngModelChange)="updateShareLink()" -->
      <label
        class="col-5"
        nz-radio
        [nzValue]="4"
        title="BN được xem dữ liệu và các BS trong group BS được xem/sửa dữ liệu"
        >Chia sẻ cấp 1 - Trả cho bệnh nhân</label
      >
      <label
        class="col-5"
        nz-radio
        [nzValue]="3"
        title="Các BS trong group được xem/sửa dữ liệu"
        >Chia sẻ cấp 2 - Trong nhóm bác sĩ</label
      >
    </nz-radio-group>
  </div>
  <div class="row col-12">Chia sẻ cho nhóm</div>
  <div class="row">
    <div class="col-6">
      <div style="border: solid 1px #d4d4d4; padding: 3px">
        <input
          style="width: 315px"
          #keywordPatient
          placeholder="Nhập tên nhóm muốn chia sẻ"
          minLength="2"
          nz-input
          (input)="onSearchGroup(keywordPatient.value)"
          [nzAutocomplete]="auto" />
        <nz-autocomplete #auto>
          <nz-auto-option
            *ngFor="let item of searchDataFilter"
            [nzValue]="item.name"
            (click)="selectShareGroup(item)">
            <span
              ><p>{{ item.name }}</p></span
            >
          </nz-auto-option>
        </nz-autocomplete>

        <a id="btnShareGroup" (click)="shareGroup()"
          ><i nz-icon nzType="right-circle" nzTheme="twotone"></i
        ></a>
      </div>
      <label style="color: #ff3434"
        >* Lưu ý: Chia sẻ theo nhóm sẽ được chia sẻ thông tin ở cấp 2 - trong
        nhóm bác sỹ</label
      >
    </div>
    <div class="col-6">
      <div *ngIf="data != null && data.length > 0" id="list-group">
        <nz-list nzItemLayout="horizontal" [nzLoading]="loading">
          <nz-list-item *ngFor="let item of data">
            <nz-list-item-meta
              nzAvatar="assets/image/group.svg"
              nzDescription="{{ item.name }}">
            </nz-list-item-meta>
          </nz-list-item>
          <nz-list-empty *ngIf="data.length === 0"></nz-list-empty>
        </nz-list>
      </div>
    </div>
  </div>
</nz-modal>
