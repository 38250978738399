import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable()
export class NotificationService {
  constructor(private notification: NzNotificationService) {}
  showNotification(type: string, message: string) {
    this.notification.create(
      //type, k dùng tới icon type, custom theo design
      'blank',
      message,
      null,
      {
        nzStyle: {
          fontWeight: 'lighter',
          fontSize: 'larger',
        },
        nzDuration: 5000,
        nzClass: type + '-class notifi',
      }
    );
  }
}
