import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { Observable } from 'rxjs';
import { UrlConstant } from '../share/constants/url.class';
import { Subclinicalorder } from '../models/subclinicalorder.class';
@Injectable({
  providedIn: 'root',
})
export class SubclinicalorderService extends BaseService {
  getSubclinicalOrderAll(): Observable<Subclinicalorder[]> {
    return this.get(UrlConstant.SUB_CLINICAL_ORDER + 'GetAll');
  }

  getSubclinicalOrderById(id: string): Observable<any> {
    return this.get(UrlConstant.SUB_CLINICAL_ORDER + id);
  }

  deleteSubOrder(id: string) {
    return this.delete(UrlConstant.SUB_CLINICAL_ORDER + id, null);
  }

  addSubclinicalOrder(data): Observable<any> {
    return this.post(UrlConstant.SUB_CLINICAL_ORDER, data);
  }

  addManySubclinicalOrder(payload): Observable<any> {
    return this.post(UrlConstant.INSERT_MANY_SUBCLINICAL, payload);
  }

  searchByNameAndCode(keyword: string): Observable<any> {
    return this.post(UrlConstant.SUB_CLINICAL_ORDER + 'SearchByCodeAndName', '', { keyword });
  }

  updateSubclinicalOrder(data: any) {
    return this.put(UrlConstant.SUB_CLINICAL_ORDER + data.id, data);
  }
  getByCheckUsed(data: any): Observable<any> {
    return this.post(UrlConstant.SUB_CLINICAL_ORDER + 'GetByCheckUsed', data);
  }
  checkServicePrice(serviceId, groupId): Observable<any> {
    return this.get(UrlConstant.SUB_CLINICAL_ORDER + 'CheckService/' + serviceId + '/' + groupId, '');
  }
  filterByWeight(): Observable<any> {
    return this.get(UrlConstant.SUB_CLINICAL_ORDER + 'FilterByWeight');
  }
  getSubClinicalOrderByCategory(): Observable<any> {
    return this.get(UrlConstant.SUB_CLINICAL_ORDER + 'GetSubClinicalOrderByCategory');
  }
  checkServicesPrice(serviceIds, groupId, isCheckFormHIS): Observable<any> {
    return this.post(UrlConstant.SUB_CLINICAL_ORDER + 'CheckServices', serviceIds, { groupId, isCheckFormHIS });
  }
  deleteSubclinicalOrder(id: string): Observable<any> {
    return this.delete(UrlConstant.SUB_CLINICAL_ORDER + id, '');
  }
  updateStatus(id): Observable<any> {
    return this.post(UrlConstant.SUB_CLINICAL_ORDER + 'UpdateStatus/' + id, '');
  }
  GetFilterAllSubClinicalOrder(payload): Observable<any> {
    return this.post(UrlConstant.SUB_CLINICAL_ORDER + 'GetFilterAllSubClinicalOrder', payload);
  }
  // updateDiscountForOtherService(payload) : Observable<any> {
  //   return this.post(UrlConstant.SUB_CLINICAL_ORDER + 'UpdateDiscountForOtherService', payload);
  // }
  toStringType(number) {
    switch (number) {
      case 1:
        return 'Xét nghiệm';
      case 2:
        return 'CĐHA';
      case 3:
        return 'Giải phẫu bệnh';
      case 4:
        return 'Loại khác';
      case 5:
        return 'Thủ thuật';
      default:
        return '';
    }
  }

  toNumberType(str: string) {
    switch (str) {
      case 'Xét nghiệm':
        return 1;
      case 'CĐHA':
        return 2;
      case 'Giải phẫu bệnh':
        return 3;
      case 'Loại khác':
        return 4;
      case 'Thủ thuật':
        return 5;
      default:
        return 0;
    }
  }

  toStringModality(number) {
    switch (number) {
      case 1:
        return 'CT';
      case 2:
        return 'MR';
      case 3:
        return 'DX';
      case 4:
        return 'ES';
      case 5:
        return 'US';
      case 6:
        return 'WSI';
      case 7:
        return 'PX';
      case 8:
        return 'MG';
      case 9:
        return 'MRI';
      case 10:
        return 'ECG';
      case 11:
        return 'RESP';
      case 12:
        return 'EEG';
      case 13:
        return 'EMG';
      case 14:
        return 'EPS';
      case 15:
        return 'DSA';
      case 16:
        return 'PT';
    }
  }

  toNumberModality(str) {
    switch (str) {
      case 'CT':
        return 1;
      case 'MR':
        return 2;
      case 'DX':
        return 3;
      case 'ES':
        return 4;
      case 'US':
        return 5;
      case 'WSI':
        return 6;
      case 'PX':
        return 7;
      case 'MG':
        return 8;
      case 'MRI':
        return 9;
      case 'ECG':
        return 10;
      case 'RESP':
        return 11;
      case 'EEG':
        return 12;
      case 'EMG':
        return 13;
      case 'EPS':
        return 14;
      case 'DSA':
        return 15;
      case 'PT':
        return 16;
      default:
        return 0;
    }
  }

  toNumberCodeMap(str) {
    switch (str) {
      case 'LOINC':
        return 1;
      case 'BHYT':
        return 2;
      case 'NanoHIS':
        return 3;
      default:
        return 0;
    }
  }
}
