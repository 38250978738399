import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class VideoConferenceService extends BaseService {
  getRoomInfo(id): Observable<any> {
    return this.post(UrlConstant.GET_ROOM_INFO + id, {});
  }
  videoCall(payload): Observable<any> {
    return this.post(UrlConstant.GET_CALL_ROOM, payload);
  }
  joinRoom(notiId, isOk): Observable<any> {
    return this.post(UrlConstant.JOIN_ROOM + notiId, '', { isOk });
  }
  inviteMore(payload): Observable<any> {
    return this.post(UrlConstant.INVITE_MORE_USER, payload);
  }
}
