import { SharedModule } from './../../../share/share.module';
import { CustomColumnComponent } from './custom-column/custom-column.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomColumnAdminComponent } from './custom-column-admin/custom-column-admin.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [CustomColumnComponent, CustomColumnAdminComponent],
  declarations: [CustomColumnComponent, CustomColumnAdminComponent],
})
export class CustomColumnModule {}
