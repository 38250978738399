import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { from } from 'rxjs';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { ObservationTypeComponent } from './admin-pages/observation-type/observation-type.component';
import { CommonInfoManagementComponent } from './admin-pages/common-info-management/common-info-management.component';
import { FormMngtComponent } from './admin-pages/formMngt/formMngt.component';
import { RoscategoryComponent } from './admin-pages/roscategory/roscategory.component';
import { SubclinicalorderComponent } from './admin-pages/subclinicalorder/subclinicalorder.component';
import { MedicineManagementComponent } from './admin-pages/medicine-management/medicine-management.component';
import { LogManagementComponent } from './admin-pages/log-management/log-management.component';
import { CategoryComponent } from './admin-pages/category/category.component';
import { DanhSachBaiVietComponent } from './admin-pages/danh-sach-bai-viet/danh-sach-bai-viet.component';
import { PageNotFoundComponent } from '../auth/page-not-found/page-not-found.component';
import { DefaultComponent } from './admin-pages/default/default.component';
import { RoleGuardClass } from 'src/app/share/guards/role-guards.class';
import { Constant } from 'src/app/share/constants/constant.class';
import { SystemListsComponent } from './admin-pages/system-lists/system-lists.component';
import { FormControllerComponent } from './admin-pages/edit-form-static/form-controller/form-controller.component';
import { TreatmentProcessComponent } from '../common-layout/treatment-process/treatment-process.component';
import { MedicalInstructionComponent } from './admin-pages/medical-instruction/medical-instruction.component';
import { RulesManagermentComponent } from './admin-pages/rules-managerment/rules-managerment.component';
import { ReminderRulesComponent } from './admin-pages/rules-managerment/reminder-rules/reminder-rules.component';
import { ManagementMethodComponent } from './admin-pages/management-method/management-method.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/admin/default',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [RoleGuardClass],
    data: {
      roles: [Constant.USERROLE.SystemAdmin, Constant.USERROLE.Admin],
      type: Constant.ROLETYPE.OR,
    },
    children: [
      {
        path: 'default', // child route path
        component: DefaultComponent, // child route component that the router renders
      },
      {
        path: 'observation-type', // child route path
        component: ObservationTypeComponent, // child route component that the router renders
      },
      {
        path: 'common-info-management', // child route path
        component: CommonInfoManagementComponent, // child route component that the router renders
      },
      {
        path: 'form-mngt',
        component: FormMngtComponent, // another child route component that the router renders
        canActivate: [RoleGuardClass],
        data: {
          roles: [Constant.USERROLE.FormMangament],
          type: Constant.ROLETYPE.OR,
        },
      },
      {
        path: 'system-lists', // child route path
        component: SystemListsComponent, // child route component that the router renders
      },
      {
        path: 'management-method', // child route path
        component: ManagementMethodComponent, // child route component that the router renders
      },
      {
        path: 'roscategory',
        component: RoscategoryComponent,
        canActivate: [RoleGuardClass],
        data: {
          roles: [Constant.USERROLE.LibraryMangament],
          type: Constant.ROLETYPE.OR,
        },
      },
      {
        path: 'ros-category',
        component: RoscategoryComponent,
        canActivate: [RoleGuardClass],
        data: {
          roles: [Constant.USERROLE.LibraryMangament],
          type: Constant.ROLETYPE.OR,
        },
      },
      {
        path: 'subclinicalorder',
        component: SubclinicalorderComponent,
      },
      {
        path: 'medicine-management',
        component: MedicineManagementComponent,
      },
      {
        path: 'quan-ly-ds-bai-viet',
        component: DanhSachBaiVietComponent,
      },
      {
        path: 'log-management',
        component: LogManagementComponent,
      },
      {
        path: 'category',
        component: CategoryComponent,
      },
      {
        path: 'treatment-process',
        component: TreatmentProcessComponent,
      },
      {
        path: 'medical-instruction',
        component: MedicalInstructionComponent,
      },
      {
        path: 'rules-managerment',
        component: RulesManagermentComponent ,
      },
      {
        path: 'rules-managerment',
        component: ReminderRulesComponent ,
      },
      {
        path: 'post',
        loadChildren: () => import('./admin-pages/new-post/new-post.module').then((m) => m.NewPostModule),
      },
      {
        path: 'form/:idPage',
        component: FormControllerComponent,
        canActivate: [RoleGuardClass],
        data: {
          roles: [Constant.USERROLE.FormMangament],
          type: Constant.ROLETYPE.OR,
        },
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./admin-pages/account-management/account-management.module').then((m) => m.AccountManagementModule),
      },
      { path: '**', redirectTo: '/404' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminLayoutRoutingModule {}
