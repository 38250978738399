import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { Observable } from 'rxjs';
import { UrlConstant } from '../share/constants/url.class';
import {
  AddSubClinicalVisit,
  SearchSuborderAndForm,
} from '../models/sub-clinical.class';

@Injectable()
export class SubClinicalVisitService extends BaseService {
  getSubClinicalOrder(id: string): Observable<any> {
    return this.get(UrlConstant.SUB_CLINICAL_ORDER + 'roscate' + '/' + id);
  }

  searchSubcilinicalrder(data: SearchSuborderAndForm) {
    return this.post(UrlConstant.SEARCH_SUB_CLINICAL_ORDER, data);
  }
  getSubClinicalVisit(id: string): Observable<any> {
    //visitId
    return this.get(UrlConstant.SUB_CLINICAL_VISIT + '?id=' + id);
  }
  getByRelated(payload): Observable<any> {
    return this.post(UrlConstant.SUB_CLINICAL_VISIT + '/GetByRelated', payload);
  }
  addSubClinicalVisit(add: AddSubClinicalVisit) {
    return this.post(UrlConstant.SUB_CLINICAL_VISIT, add);
  }

  addSubClinicalVisitGroup(payload: AddSubClinicalVisit) {
    return this.post(
      UrlConstant.SUB_CLINICAL_VISIT + '/AddSubclinicalVisitGroup',
      payload
    );
  }

  deleteSubClinicalVisit(id) {
    //subclinicId
    return this.delete(UrlConstant.SUB_CLINICAL_VISIT + '/' + id, '');
  }
  deleteMulti(payload): Observable<any> {
    return this.post(UrlConstant.SUB_CLINICAL_VISIT + '/Delete', payload);
  }

  getSubClinicalResult(id: string, patientId: string): Observable<any> {
    //visitId
    return this.post(
      UrlConstant.SUB_CLINICAL_RESULT + '/' + id + '/' + patientId,
      null
    );
  }
  updateSubclinicalVisit(payload): Observable<any> {
    return this.post(UrlConstant.SUB_CLINICAL_VISIT + '/Update', payload);
  }
  updateDiscount(payload): Observable<any> {
    return this.post(
      UrlConstant.SUB_CLINICAL_VISIT + '/UpdateDiscount',
      payload
    );
  }
  pushSubClinicalVisit2HIS(visitId): Observable<any> {
    return this.post(
      UrlConstant.SUB_CLINICAL_VISIT + '/PushSubClinicalVisit2HIS',
      '',
      { visitId }
    );
  }
  updateDiscountForOtherService(payload) : Observable<any> {
    return this.post(UrlConstant.SUB_CLINICAL_VISIT + '/UpdateDiscountForOtherService', payload);
  }
  FilterSubClinicalVisit2Pacs(payload): Observable<any> {
    return this.post(UrlConstant.SUB_CLINICAL_VISIT + '/FilterSubClinicalVisit2Pacs', payload);
  }
  ResendSubClinicalVisit2Pacs(payload): Observable<any> {
    return this.post(UrlConstant.SUB_CLINICAL_VISIT + '/ResendSubClinicalVisit2Pacs', payload);
  }
}
