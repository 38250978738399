<div class="clinical-container">
  <div *ngIf="subclinicalResultOfVisit.length > 0" class="clinical-visit">
    <div class="m-tab-title">Danh sách chỉ định CĐHA</div>
    <ng-container *ngTemplateOutlet="result; context: { data: subclinicalResultOfVisit }"></ng-container>
  </div>
  <div *ngIf="otherSubclinicalResultOfPatient.length > 0" class="other">
    <div class="m-tab-title">Các kết quả cũ của BN</div>
    <ng-container style="height: 187px;"
      *ngTemplateOutlet="result; context: { data: otherSubclinicalResultOfPatient }"></ng-container>
  </div>
</div>

<ng-template #result let-data="data">
  <div style="min-height: 150px;" *ngFor="let clinical of data" class="clinical-item">
    <ng-container *ngIf="clinical.source == sourceConstant.NotResult">
      <div class="clinical-header">
        <div class="clinical-title mg-r-15">{{ clinical.name }} - {{ clinical.visitDate | date: 'dd/MM/yyy' }} </div>
        <div *ngIf="clinical.description == 'ES'" click-stop-propagation>
          <a class="mg-r-15" href="{{ resultLink + clinical.referenceId + '&modality=ES' }}" target="_blank"> Link xem
            ca chụp</a>
        </div>
        <div *ngIf="clinical.description == 'US'" click-stop-propagation>
          <a class="mg-r-15" href="{{ resultLink + clinical.referenceId + '&modality=US' }}" target="_blank"> Link xem
            ca chụp</a>
        </div>
        <!-- <div *ngIf="clinical.description == 'MR'" click-stop-propagation>
          <a class="mg-r-15" href="{{ resultLink + clinical.referenceId + '&modality=MR' }}" target="_blank"> Link xem
            ca chụp</a>
        </div> -->
        <!-- <i class="icon-expand" nz-icon [nzType]="clinical.isExpand ? 'down' : 'right'" nzTheme="outline"></i> -->
      </div>
      <div *ngIf="clinical.isExpand">Chưa có kết quả từ PACS</div>
    </ng-container>
    <!-- (click)="expandBox(clinical)" -->
    <ng-container *ngIf="clinical.source == sourceConstant.ResultFromPacs">
      <div class="clinical-header">
        <div class="clinical-title">{{ clinical.name }} - {{ clinical.visitDate | date: 'dd/MM/yyy' }}
          <span>({{clinical.keyImages.length}} hình
            ảnh) - <a href="{{ clinical.pacsUrl }}" target="_blank">Link xem ca chụp </a></span>
        </div>
        <!-- Link xem ca chụp -->
        <!-- <i class="icon-expand" nz-icon [nzType]="clinical.isExpand ? 'down' : 'right'" nzTheme="outline"></i> -->
      </div>

      <ul *ngIf="clinical.isExpand" class="ul-image-general">
        <li style="float: left;" *ngFor="let link of clinical.pdfLinks; index as i" (click)="openPdf(link)">
          <a class="thumbnail-report">
            <img src="assets/image/pdf.svg" /> <br> Phiếu kết quả
          </a>
        </li>
        <app-list-image-general [imageFrom]="imageFrom.TabCDHA" [hideButton]="hideButton"
          [lstImages]="clinical.keyImages" [visitId]="0">
        </app-list-image-general>
      </ul>

    </ng-container>
    <ng-container *ngIf="clinical.source == sourceConstant.General">
      <div class="clinical-header" (click)="expandBox(clinical)">
        <div class="clinical-title mg-r-15">{{ clinical.name }} - {{ clinical.visitDate | date: 'dd/MM/yyy' }}
          <span *ngIf="clinical.keyImages!=null && clinical.keyImages.length > 0">({{clinical.keyImages.length}} hình
            ảnh)</span>
        </div>

        <!-- <div *ngIf="clinical.isExpand" class="d-flex" click-stop-propagation>
          <a class="mg-r-15" href="{{ clinical.pacsUrl }}" target="_blank"> Link xem ca chụp </a>
          <div>
            <img class="img-pdf" src="assets/image/pdf.svg" />
            <a (click)="openPdf(clinical.pdfLinks[0])">File kết quả</a>
          </div>
        </div>
        <i class="icon-expand" nz-icon [nzType]="clinical.isExpand ? 'down' : 'right'" nzTheme="outline"></i> -->
      </div>
      <ul *ngIf="clinical.isExpand" class="ul-image-general">
        <li *ngFor="let link of clinical.pdfLinks; index as i" (click)="openPdf(link)">
          <a class="thumbnail-report">
            <img src="assets/image/pdf.svg" />
          </a>
          <div class="fs-12">Phiếu kết quả</div>
        </li>
        <app-list-image-general [imageFrom]="imageFrom.BoxGeneral" [hideButton]="hideButton"
          [lstImages]="clinical.keyImages" [visitId]="clinical.id">
        </app-list-image-general>
      </ul>
      <nz-tabset *ngIf="clinical.isExpand" [nzType]="'card'" nzSize="small"
        class="tab-normal-inside-visit tab-list-cdha tabset-default">
        <nz-tab *ngFor="let rpt of clinical.reports; index as i" nzTitle="Report {{ i + 1 }}">
          <div class="mg-t-10 mg-l-15">
            <span class="mg-r-15">Trạng thái: <b>Đã duyệt</b></span>
            <span class="mg-r-15">
              BS đọc: <b>{{ rpt.readDoctor }}</b>
            </span>
            <span class="mg-r-15">
              BS duyệt: <b>{{ rpt.appprovedDoctor }}</b>
            </span>
            <span *ngIf="rpt.technician">
              KTV: <b>{{ rpt.technician }}</b>
            </span>
          </div>
          <div class="d-flex">
            <div class="col-md-6 col-sm-12">
              <div class="report-des">
                <label>Mô tả</label>
                <div [innerHtml]="rpt.description | safeHtml" class="html-box"></div>
              </div>
              <div class="report-conclus">
                <label>Kết luận</label>
                <div [innerHtml]="rpt.conlusion | safeHtml" class="html-box"></div>
              </div>
            </div>
            <div *ngIf="rpt.keyImages!=null && rpt.keyImages.length > 0" class="col-md-6 col-sm-12 app-slider">
              <app-slider-image [lstImages]="rpt.keyImages" [visitId]="" [curIndex]="0" [mHeader]="false">
              </app-slider-image>
            </div>
          </div>
        </nz-tab>
      </nz-tabset>
    </ng-container>
  </div>
</ng-template>

<nz-modal nzClassName="slideShowImage" [(nzVisible)]="isVisablePdf" (nzOnCancel)="closePdf()" [nzFooter]="null">
  <div class="pdf-viewer-container">
    <app-m-view-pdf [pdfDocumentSrc]="linkPdf"></app-m-view-pdf>
  </div>
</nz-modal>
