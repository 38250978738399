<div>
  <nz-spin class="loading-icon" [nzSize]="'large'" nzTip="Đang tải thông tin ca khám..." [nzSpinning]="isLoading">
    <app-header *ngIf="visit.id" [isCovidGroup]="isCovidGroup" [hideButton]="hideButton" [visit]="visit"> </app-header>
    <div #body class="containers">
      <div id="container-visit">
        <as-split *ngIf="config" direction="horizontal" [disabled]="config.disabled" (dragEnd)="onDragEnd(-1, $event)">
          <as-split-area *ngIf="config.columns[0].visible" [size]="config.columns[0].size">
            <div class="header-connecter">
              <div id="main-header">
                <span class="title-visit">Ca khám
                  {{ visit.visitDate | date: 'dd-MM-yyy' }}
                  {{ visit.visitDate | date: 'hh:mm a' }}
                  - {{ visit.patientName }}
                  <ng-container *ngIf="treatmentPatient != null">
                    <span class="title-visit">- {{ treatmentPatient.treatmentProcessName }}</span>
                    <span class="title-visit" *ngIf="treatmentPatient.nDate != -1">
                      - Ngày {{ treatmentPatient.nDate }}</span>
                  </ng-container>
                  <ng-container *ngIf="!showFinishedVisited && this.visit.examinedStatus === true">
                    - Đã được khám
                  </ng-container>
                  <i class="da-kham" *ngIf="showFinishedVisited" style="margin-left: 10px" nz-icon nzType="check-circle"
                    title="Set ca khám đã được khám" (click)="finishVisit()" nzTheme="outline"></i>
                  <!-- <i style="margin-left: 10px;" *ngIf="showFinishedVisited" title="Set ca khám đã được khám" (click)="finishVisit()"><img src="assets/image/updated.svg"></i>
                   -->
                </span>
                <div class="btn-group {{ hideButton }}">
                  <!-- <i title="Thành viên trong gia đình" (click)="openFamilyMembers()"
                                    style="font-size: 22px; color: #234080;" nz-icon nzType="usergroup-add" nzTheme="outline"></i> -->
                  <i title="Đồng bộ His" (click)="synchronizedHis(visit.id)" class="action-icon" nz-icon
                    nzType="cloud-upload" nzTheme="outline"></i>
                  <i title="Reminder của bệnh nhân" (click)="openModalPatientReminder()" class="action-icon" nz-icon
                    nzType="bell" nzTheme="outline"></i>
                  <div *ngIf="!isLoadingTreatmentPatient" class="treatment-icon">
                    <div class="{{ treatmentPatient != null ? 'cycle-red' : '' }}"></div>
                    <i title="Quy trình điều trị" (click)="showModalTreatmentProcess()" nz-icon nzType="retweet"
                      nzTheme="outline" class="action-icon"></i>
                  </div>
                  <i title="Sửa thông tin ca khám" (click)="openModalEditVisitCore()" class="action-icon" nz-icon
                    nzType="edit" nzTheme="outline"></i>
                  <i title="Lịch sử hoạt động ca khám" (click)="showVisitLog()">
                    <img class="action-icon-img" src="assets/image/icon-timeline.svg" /></i>
                  <i title="Chia sẻ ca khám" (click)="showModalShareVS()"><img class="action-icon-img"
                      src="assets/image/icon-share.svg" /></i>
                  <i title="Chat với BN" (click)="sendChat2Patient()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                      <path id="messenger"
                        d="M9.5,0A9.5,9.5,0,0,0,1.26,14.229L.036,18.03a.742.742,0,0,0,.934.934l3.8-1.224A9.5,9.5,0,0,0,19,9.5a9.69,9.69,0,0,0-1.786-5.549A9.35,9.35,0,0,0,9.5,0Zm0,17.516A7.992,7.992,0,0,1,5.257,16.3a.743.743,0,0,0-.621-.077l-2.744.884.884-2.744a.743.743,0,0,0-.077-.621,8.016,8.016,0,1,1,6.8,3.773ZM10.428,9.5A.928.928,0,1,1,9.5,8.572.928.928,0,0,1,10.428,9.5Zm3.711,0a.928.928,0,1,1-.928-.928A.928.928,0,0,1,14.139,9.5Zm-7.422,0a.928.928,0,1,1-.928-.928A.928.928,0,0,1,6.717,9.5Zm0,0"
                        fill="#2b4e9b" />
                    </svg>
                  </i>
                  <i title="Video Conference" (click)="showVideoCall()">
                    <img class="action-icon-img" src="assets/image/icon-video-conference.svg" /></i>
                  <i title="Gửi thông tin cho BN" (click)="sendProfile()">
                    <img class="action-icon-img" src="assets/image/icon-send-email.svg" /></i>
                  <i *ngIf="isAdminGroupOrCoordinator" class="action-icon" title="Thông tin tài khoản BN"
                    (click)="viewProfile()" nz-icon nzType="user" nzTheme="outline"></i>
                  <i title="Thêm ca khám" (click)="addVisit()">
                    <img class="action-icon-img" src="assets/image/icon-add.svg" />
                  </i>
                  <span *ngIf="config && config.columns[1].size == 0" class="toggle-split" (click)="toggleNote()">
                    <i nz-icon nzType="arrow-left" nzTheme="outline" class="action-icon"></i>
                  </span>
                </div>
              </div>
            </div>
            <div id="visit-main-info">
              <nz-tabset class="tab-detail-visit tabset-default" [nzAnimated]="false" [(nzSelectedIndex)]="indexTab">
                <nz-tab *ngFor="let tab of tabs" [nzTitle]="titleTemplate" (nzClick)="onTabClick(tab.id)"
                  nzTitle="Thông tin ca khám">
                  <ng-template #titleTemplate>
                    <div class="fs-14" *ngIf="tab.type == 'main'">
                      {{ tab.name }}
                    </div>
                    <div class="fs-14" *ngIf="tab.type !== 'main'" [title]="tab.name">
                      <span class="record-name"> Phiếu {{ tab.name }} </span>
                      <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"
                        style="position: absolute; top: 6px"></i>
                    </div>
                  </ng-template>
                  <!--lần lượt từng tab-->
                  <div *ngIf="tab.id == 'thongtincakham'">
                    <app-tab-visit-info *ngIf="visit.id" [shareId]="shareId" [hideButton]="hideButton"
                      [disabledClass]="disabledClass" [visit]="visit" [(reloadNow)]="tab.reloadNow"
                      (vitalSign)="reloadTab($event)" (openPhieudl)="toTabForm($event)" (newVisit)="loadVisit($event)"
                      (openTab)="changeTabById($event)"
                      (reloadMasterNote)="reloadMasterNote($event)"></app-tab-visit-info>
                  </div>
                  <div *ngIf="tab.id == 'xetnghiem'">
                    <app-xetnghiem-tab [hideButton]="hideButton" [disabledClass]="disabledClass" [visit]="visit"
                      [(reloadNow)]="tab.reloadNow" (saveXN)="reloadTab($event)"></app-xetnghiem-tab>
                  </div>
                  <!-- anticovid hidden -->
                  <div *ngIf="tab.id == 'cdha'">
                    <app-tab-CDHA [hideButton]="hideButton" [disabledClass]="disabledClass" [visit]="visit"
                      (newVisitId)="getVisit($event)">
                    </app-tab-CDHA>
                  </div>
                  <div *ngIf="tab.id == 'thuoc'">
                    <app-prescription-tab *ngIf="visit.id" [visit]="visit"
                      (newVisitId)="getVisit($event)"></app-prescription-tab>
                  </div>
                  <div *ngIf="tab.id == 'phieudl'">
                    <app-list-record [hideButton]="hideButton" [disabledClass]="disabledClass" [visit]="visit"
                      *ngIf="visit.id" [listRecord]="listRecord" (openPhieudl)="toTabForm($event)"
                      (saveDeleteRecord)="reloadPhieudl(event)"></app-list-record>
                  </div>
                  <div *ngIf="tab.id == 'chiso'">
                    <app-vital-signs-tab [hideButton]="hideButton" [disabledClass]="disabledClass" [visit]="visit"
                      *ngIf="visit.id" [(reloadNow)]="tab.reloadNow"></app-vital-signs-tab>
                  </div>
                  <div *ngIf="tab.type == 'sub'">
                    <app-data-form *ngIf="visit.id" [hideButton]="hideButton" [disabledClass]="disabledClass"
                      [visit]="visit" [formId]="tab.formId" [recordId]="tab.id" (openPhieudl)="toTabForm($event)"
                      (saveRecord)="reloadTab($event)"></app-data-form>
                  </div>
                  <div *ngIf="tab.id == 'ylenh'">
                    <app-medical-inst-list *ngIf="visit.id" [visit]="visit"></app-medical-inst-list>
                  </div>
                  <!-- anticovid hidden -->
                  <div *ngIf="tab.id == 'visit-summary'">
                    <app-visit-summary *ngIf="visit.id" [hideButton]="hideButton" [disabledClass]="disabledClass"
                      [visit]="visit" (openPhieudl)="toTabForm($event)" (saveRecord)="reloadTab($event)">
                    </app-visit-summary>
                  </div>
                  <div *ngIf="tab.id == 'patientInfo'">

                    <!-- <app-patient-info *ngIf="visit.id" [visitId]="visit.id"
                      [patientId]="visit.patientId"></app-patient-info> -->
                    <app-patient-info [inVisit]="true" *ngIf="visit.id" [visitId]="visit.id"
                      [patientId]="visit.patientId" class="col-12 col-xl-12 pdl0"></app-patient-info>
                  </div>
                </nz-tab>
              </nz-tabset>
            </div>
          </as-split-area>

          <as-split-area *ngIf="this.config.columns[1].visible" [size]="config.columns[1].size">
            <div class="chat-header">
              <div style="float: right">
                <!-- <span *ngIf="config && config.columns[1].size == 0" class="toggle-split" (click)="toggleNote()">
                <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
              </span> -->
                &nbsp;
                <span *ngIf="config && config.columns[1].size != 0" class="toggle-split" (click)="toggleNote()">
                  <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                </span>
              </div>
            </div>
            <div #visitmainchat id="visit-main-chat" *ngIf="config && config.columns[1].size != 0">
              <div #boxchat id="box-notechat" class="box-notechat {{ isShowChat }} ">
                <div class="head-notechat">
                  <div (click)="chatTabSelect()" class="tab-notechat {{ showChat }}">
                    Chat
                    {{ message > 5 ? '(5+)' : message == 0 ? '' : '(' + message + ')' }}
                  </div>
                  <div (click)="chatTabSelect()" class="tab-notechat {{ !showChat }}">Master Notes</div>
                </div>
                <div class="body-notechat {{ !showChat }}" style="position: relative">
                  <app-masternotes-tab *ngIf="visit.id" #appMasterNote [hideButton]="hideButton"
                    [disabledClass]="disabledClass" [visit]="visit">
                  </app-masternotes-tab>
                </div>
                <div class="body-notechat {{ showChat }}" style="position: relative">
                  <app-chat-tab *ngIf="visit.id" [hideButton]="hideButton" [disabledClass]="disabledClass"
                    [visit]="visit" (getMessage)="getMesssage($event)"></app-chat-tab>
                </div>
              </div>
            </div>
          </as-split-area>
        </as-split>
      </div>
    </div>
  </nz-spin>
  <!-- <app-share-visit [isVisible]="isShareVisit" [visitId]="visitId" (closeModal)="closeModalShareVS()"></app-share-visit> -->
  <app-share-visit-update [isVisible]="isShareVisit" [visitId]="visitId" [groupId]="visit.groupId"
    (closeModal)="closeModalShareVS()">
  </app-share-visit-update>
  <!-- <app-visit-log [isVisible]="isVisitLogVisible" [visitId]="visitId" (closeModal)="closeVisitLog()"></app-visit-log> -->
  <app-patient-profile [isVisible]="isSentPatient" [patient]="patient" [visitId]="visitId"
    (closeProflie)="closeProflie()" (profileStatus)="getStatus($event)" (changeEmail)="changeEmail($event)">
  </app-patient-profile>
  <app-conference-room [isVisible]="isVideoCall" [visitId]="visitId" [patient]="patient"
    (closeModal)="closeVideoCall($event)"></app-conference-room>
  <app-edit-visitinfo-core [isCovidGroup]="isCovidGroup" [isVisible]="isVisibleEditVisitCore" [visit]="visit"
    (closeModal)="closeEditVisitCore()" (editSuccess)="getVisit(visitId)"></app-edit-visitinfo-core>
  <app-treatment-patient [isVisible]="showTreatmentPatient" [patientId]="this.visit.patientId"
    [rawData]="treatmentPatient" (closeModal)="emitTreatmentPatient($event)"></app-treatment-patient>
  <app-patient-reminder-list [visit]="visit" [isVisible]="isVisiblePatientReminder"
    (closeModal)="closePatientReminder()"></app-patient-reminder-list>

  <nz-modal class="list-covid-modal" [(nzVisible)]="isVisiblePatientAccount" (nzOnCancel)="closePatientAccountModal()"
    nzWidth="450px">
    <div *nzModalTitle>
      <div class="header-title">
        Thông tin tài khoản của bệnh nhân:
        <strong>{{ patient.fullName }}</strong>
      </div>
    </div>
    <div *nzModalContent>
      <div class="pd-20">
        <div class="row">
          <div class="col-md-4">Tên đăng nhập</div>
          <div class="col-md-8 fs-16">
            {{ patientAccount.username }}
          </div>
        </div>
        <div class="row mg-t-20">
          <div class="col-md-4">Mật khẩu</div>
          <div class="col-md-8 fs-16">
            <nz-input-group [nzSuffix]="suffixTemplate">
              <input nz-input [(ngModel)]="patientAccount.password" type="password" disabled />
            </nz-input-group>
          </div>
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <button class="btn-cancel-medium" (click)="closePatientAccountModal()">Đóng</button>
    </div>
  </nz-modal>
  <ng-template #suffixTemplate>
    <i (click)="copyMessage(patientAccount.password, 'Copy')" nz-icon nzType="copy" nzTheme="outline"></i>
  </ng-template>
</div>