<div [ngSwitch]="type" style="height: 100%">
  <div *ngSwitchCase="'multiple'" style="height: 100%">
    <nz-checkbox-wrapper
      (nzOnChange)="updateSingleChecked($event)"
      [ngClass]="[
        data.formatStyle.display == 'block' ? 'vertical-display' : '',
        data.formatStyle.isBold == true ? 'bold' : '',
        data.formatStyle.isItalic == true ? 'italic' : '',
        data.formatStyle.isUnderline == true ? 'underline' : '',
        alginFormat.get(data.formatStyle.align)
      ]"
      [ngStyle]="{
        background: data.formatStyle.bgColor,
        'font-size': data.formatStyle.fontSize + 'px',
        'height': '100%',
        'font-family': data.formatStyle.font
      }">
      <label
        nz-checkbox
        *ngFor="let item of data.observationType.dataSource"
        [nzValue]="item.id"
        [ngModel]="data.valueSelections.includes(item.id)"
        [ngStyle]="{
          'margin-left': '8px'
        }"
        [nzDisabled]="data.editType == 0 || data.editType == 3"
        >{{ item.text }}
      </label>
    </nz-checkbox-wrapper>
  </div>
  <div *ngSwitchCase="'single'" style="height: 100%">
    <nz-radio-group
      [(ngModel)]="data.valueSelections"
      nzName="radiogroup"
      [ngClass]="[
        data.formatStyle.display == 'block' ? 'vertical-display' : '',
        data.formatStyle.isBold == true ? 'bold' : '',
        data.formatStyle.isItalic == true ? 'italic' : '',
        data.formatStyle.isUnderline == true ? 'underline' : '',
        alginFormat.get(data.formatStyle.align)
      ]"
      [ngStyle]="{
        background: data.formatStyle.bgColor,
        'font-size': data.formatStyle.fontSize + 'px',
        'height': '100%',
        'font-family': data.formatStyle.font
      }"
      [nzDisabled]="data.editType == 0 || data.editType == 3"
      (ngModelChange)="onModelChange($event)">
      <label
        nz-radio
        *ngFor="let item of data.observationType.dataSource"
        [nzValue]="item.id"
        [ngStyle]="{
          'margin-left': '8px',
          color: data.formatStyle.color,
          background: data.formatStyle.bgColor,
          'font-size': data.formatStyle.fontSize + 'px',
          'font-family': data.formatStyle.font
        }"
        >{{ item.text }}</label
      >
    </nz-radio-group>
  </div>
</div>
