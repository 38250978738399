<div id="print-div-conclusion" style="display: none">
  <div class="p-5 print-paper w-full">
    <div class="header d-flex justify-content-between">
      <div [innerHTML]="printHeader | safeHtml" class=""> </div>
    </div>
    <div class="content">
      <div class="title-1  py-2">
        <div class="h3 text-center font-weight-bold">
          BÁO CÁO Y TẾ
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div><span class="font-weight-bold">Hà Nội, &nbsp;</span>
          <span>Ngày {{today | date:'dd'}} tháng {{today | date:'MM'}} năm {{today | date:'yyyy'}}</span>
        </div>
      </div>
      <div class="mt-2 font-weight-bold font-italic">
        Kính gửi các cơ quan hữu quan
      </div>
      <div class="mt-1">
        <span class="font-weight-bold">Họ và tên người bệnh: &nbsp;
        </span>
        <span>
          {{visit?.patientName}}
        </span>
      </div>
      <div class="mt-1">
        <span class="font-weight-bold">Ngày tháng năm sinh: &nbsp;
        </span>
        <span>
          {{visitInfo?.dobString}}
        </span>
      </div>
      <div class="mt-1">
        <span class="font-weight-bold">Địa chỉ: &nbsp;
        </span>
        <span>
        </span>
      </div>
      <div>
        Người bệnh có tên trên đây có đến khám tại {{groupName}} từ {{visitInfo?.visitDate | date:'HH: mm dd/MM/yyyy'}}
        đến {{visitInfo?.visitEndDate | date:'HH:mm dd/MM/yyyy'}}
      </div>
      <div>
        Với những thông tin sau:
      </div>
      <div class="mt-1">
        <span class="font-weight-bold">Lý do khám: &nbsp;
        </span>
        <span>
          {{visitInfo?.clinicalInfomation}}
          <!-- {{visitInfo?.visitReason}} -->
        </span>
      </div>
      <div class="mt-1">
        <span class="font-weight-bold">Quá trình bệnh lý và diễn biến lâm sàng:
        </span>
      </div>
      <div class="mt-1">
        <span class="">Bệnh sử: &nbsp;
        </span>
        <span>
          {{visitInfo?.vitalSign}}
        </span>
      </div>
      <!-- <div class="mt-1">
        <span>
          {{visitInfo?.clinicalInfomation}}
        </span>
      </div> -->
      <div [innerHTML]="visitInfo?.examination"></div>
      <div class="mt-1">
        <span class="font-weight-bold">Kết quả lâm sàng:
        </span>
      </div>
      <div *ngFor="let item of subClinicalResult">
        <div class="">- {{item.name}}</div>
        <div> &nbsp; &nbsp; {{item.conlusion}}</div>
      </div>
      <div class="mt-1">
        <span class="font-weight-bold">Kết quả XN:
        </span>
      </div>
      <div *ngFor="let item of dsXN">
        <div class="">- {{item.name}}</div>
        <div> &nbsp; &nbsp; {{item.value2Show}} {{ item.unit}}</div>
      </div>
      <div class="mt-1">
        <span class="font-weight-bold">Kết luận:
        </span>
        <span>
          {{visitInfo?.conclusion}}
        </span>
      </div>
      <div class="mt-1">
        <span class="font-weight-bold">Phương pháp điều trị: &nbsp;
        </span>
        <span>

        </span>
      </div>
      <div [innerHTML]="visitInfo?.treatments"></div>
      <div class="mt-1">
        <span class="font-weight-bold">Các thuốc đã dùng: &nbsp;
        </span>
        <span>
          {{visitInfo?.medicationsUsed}}
        </span>
      </div>
      <!--<div class="mt-1">
        <span class="font-weight-bold">Tình trạng khi ra viện: &nbsp;
        </span>
        <span>
          {{visitInfo?.currentCondition}}
        </span>
      </div>-->
      <div class="mt-1">
        <span class="font-weight-bold">Kế hoạch điều trị tiếp theo: &nbsp;
        </span>
        <span>
          {{visitInfo?.nextTreatmentPlan}}
        </span>
      </div>
      <div class="mt-1">
        <span class="font-weight-bold">Ngày hẹn tái khám: &nbsp;
        </span>
        <span>
          {{visitInfo?.nextAppointmentDate | date:'dd/MM/yyyy'}}
        </span>
      </div>

      <!--<div class="d-flex justify-content-end">

      </div>-->

      <div class="row">
        <div class="col-4 font-weight-bold text-center">
          <div>&nbsp;</div>
          BÁC SĨ ĐIỀU TRỊ
        </div>
        <div class="col-4 font-weight-bold text-center">
        </div>
        <div class="col-4 font-weight-bold text-center">
          <div style="font-weight: normal">Ngày {{today | date:'dd'}} tháng {{today | date:'MM'}} năm {{today |
            date:'yyyy'}}</div>
          GIÁM ĐỐC
        </div>
      </div>
    </div>
  </div>
</div>


<!--
<div id="print-div-conclusion" style="display: none">
  <div class="p-5 print-paper w-full">
    <div class="header d-flex justify-content-between">
      <div [innerHTML]="printHeader | safeHtml" class=""> </div>
      <div class="barcode"> <ngx-barcode [bc-font-size]="15" [bc-margin]="1" [bc-width]="1" [bc-height]="40"
          [bc-font]="" [bc-value]="visit?.patientCode" bc-element-type="svg" [bc-display-value]="true"
          [bc-element-type]="'svg'"></ngx-barcode>
      </div>
    </div>
    <div class="content">
      <div class="title-1  py-2">
        <div class="h3 text-center font-weight-bold">
          HỒ SƠ KHÁM BỆNH
        </div>
        <div class="text-center">
          Ngày khám: {{visit?.visitDate | date:'dd/MM/yyyy'}}
        </div>
      </div>
      <div class="h5 py-2 font-weight-bold">A. THÔNG TIN KHÁCH HÀNG</div>
      <div class="info">
        <span>Họ và tên:</span><span> {{visit?.patientName}}</span>
        <span class="ml-5">Năm sinh:</span><span> {{visit?.dob | date:'yyyy'}}</span>
        <span class="ml-5">Giới tính:</span><span> {{visit?.patientSexText}}</span>
      </div>
      <div class="info">
        <span>Địa chỉ:</span><span class="ml-2"> {{visit?.address}}</span>
      </div>
      <div class="info">
        <span>Lý do khám:</span><span class="ml-2"> {{visit?.visitReason}}</span>
      </div>
      <div class="h5 py-2 font-weight-bold">B. BÁO CÁO KẾT QUẢ KHÁM BỆNH
      </div>
      <div style="margin-left: 10mm;">
        <div class="h6 font-weight-bold mb-2">
          I. BỆNH SỬ
        </div>
        <div class="info">
          <span>-Quá trình bệnh lý:
          </span><span> {{visit.clinicalInfomation}}</span>
        </div>
        <div class="h6 font-weight-bold  py-2">
          II. KHÁM LÂM SÀNG
        </div>
        <div class="info">
          <span>Mạch:
          </span>
          <span> {{getVitalSignValue(lonicCode.mach,vitalSign)}}</span>
          <span class="ml-4">Huyết áp:
          </span>
          <span> {{getVitalSignValue(lonicCode.huyetapmin,vitalSign)}}
            {{getVitalSignValue(lonicCode.huyetapmax,vitalSign)}}</span>

        </div>
        <div class="info">
          <span>Chiều cao:
          </span>
          <span>{{getVitalSignValue(lonicCode.chieucao,vitalSign)}}</span>
          <span class="ml-4">Cân nặng:
          </span>
          <span> {{getVitalSignValue(lonicCode.cannang,vitalSign)}}</span>
        </div>
        <div class="h6 font-weight-bold  py-2">
          III. TÓM TẮT CHỈ ĐỊNH CLS VÀ KẾT QUẢ
        </div>
        <div *ngFor="let item of subClinicalResult">
          <div class="font-weight-bold">{{item.name}}</div>
          <div>{{item.conlusion}}</div>
        </div>
        <div class="h6 font-weight-bold  py-2">
          IV. KẾT LUẬN
        </div>
        <div class="info">
          {{visit?.conclusion}}
        </div>
        <div class="h6 font-weight-bold  py-2">
          V. HƯỚNG GIẢI QUYẾT - KHUYẾN CÁO - KẾ HOẠCH KHÁM TIẾP THEO
        </div>
        <div class="py-2 font-weight-bold mt-4 mb-4">
          LỜI DẶN:
        </div>
      </div>

      <div class="row mt-4 text-right">
        <div class="col-6"></div>
        <div class="col-6"><span class="text-center">
            <div class="h6 font-weight-bold">
              BÁC SĨ KẾT LUẬN
            </div>
            <div class="mt-5">
              {{visit?.doctorName}}
            </div>
          </span></div>
      </div>
    </div>
  </div>
</div> -->