<!-- <nz-modal [nzVisible]="isVisible" [nzTitle]="modalTitle ? modalTitle : 'Cột hiển thị'" [nzWidth]="800" [nzMaskClosable]="false"
  (nzOnCancel)='handleCancel()' (nzOnOk)="handleOk()"> -->
<app-m-modal [modalId]="'custom-column'" [isVisible]="isVisible" (closeModal)="handleCancel()" [mMask]="true"
  [width]="800" [minHeight]="400" [minWidth]="500" [isResiable]="true">
  <ng-template #mModalTitle>
    <div class="header-title">
      {{ modalTitle ? modalTitle : 'Cột hiển thị' }}
    </div>
  </ng-template>
  <ng-template #mModalContent>
    <div class="row">
      <div class="col">
        <div class="tabset-default">
          <nz-tabset nzType="card">
            <nz-tab [nzTitle]="'Common Info'">
              <input nz-input placeholder="Tìm kiếm..." [(ngModel)]="search_KW_CI"
                (ngModelChange)="search_KW_CIChange()" />
              <div class="card overflow-auto" style="max-height: 315px; min-height: 315px">
                <ng-container *ngIf="lstFilterCI.length">
                  <div *ngFor="let item of lstFilterCI" class="border-bottom p-2 d-flex justify-content-between">
                    <span>{{ item.title }}</span>
                    <i nz-icon nzType="plus" nzTheme="outline" class="mg-r-8" (click)="addItem(item)"></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="lstFilterCI.length == 0">
                  <div class="font-italic text-danger">* Tất cả cột đã được thêm vào danh sách</div>
                </ng-container>
              </div>
            </nz-tab>
            <nz-tab [nzTitle]="'Observation'">
              <input nz-input placeholder="Tìm kiếm..." [(ngModel)]="search_KW_OT"
                (ngModelChange)="search_KW_OTChange()" />
              <div class="card overflow-auto" style="max-height: 315px; min-height: 315px">
                <div *ngFor="let item of lstObservationType" class="border-bottom p-2 d-flex justify-content-between">
                  <div>
                    <div class="">{{ item.title }}</div>
                    <div class="font-italic font-weight-light">Kiểu: {{ item.dataTypeText }}</div>
                  </div>
                  <i nz-icon nzType="plus" nzTheme="outline" class="mg-r-8" (click)="addItem(item)"></i>
                </div>
              </div>
            </nz-tab>
          </nz-tabset>
        </div>
      </div>
      <div class="col">
        <h3 class="text-align-center">Cột hiển thị</h3>
        <div cdkDropList #hideList="cdkDropList" [cdkDropListData]="_showColumn" class="column-list max-height-365"
          (cdkDropListDropped)="drop($event)">
          <div class="column-box" *ngFor="let item of _showColumn" cdkDrag>
            <span class="pd-r-8">{{ item.title }}</span>
            <i *ngIf="item.field != 'patientName' && item.field != 'genderText'" nz-icon nzType="check"
              nzTheme="outline" class="valid-icon mg-r-8" (click)="removeItem(item)"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #mModalFooter>
    <button class="btn-cancel-medium" (click)="handleCancel()">Đóng</button>
    <button class="btn-primary-medium" (click)="handleOk()">Lưu</button>

  </ng-template>
</app-m-modal>
<!-- </nz-modal> -->