import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonLayoutComponent } from './common-layout/common-layout.component';
import { AuthGuard } from '../../share/guards/guards.class';
import { ViewVisitComponent } from './view-visit/view-visit.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ConferenceRoomCallComponent } from './conference-room-call/conference-room-call.component';
import { CovidReceiveListComponent } from './covid-receive-list/covid-receive-list.component';
import { MobileLayoutComponent } from './common-layout/mobile-layout/mobile-layout.component';
import { SliderImageComponent } from '../modal/slider-image/slider-image.component';
import { UpdateUserProfileComponent } from './update-user-profile/update-user-profile.component';
import { WaitingPageComponent } from './waiting-page/waiting-page.component';
import { ReportSummaryComponent } from '../report/report-summary/report-summary.component';
import { RoleGuardClass } from 'src/app/share/guards/role-guards.class';
import { Constant } from 'src/app/share/constants/constant.class';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/common/worklist',
    pathMatch: 'full',
  },
  {
    path: 'common',
    component: CommonLayoutComponent,
    children: [
      {
        path: 'worklist',
        loadChildren: () =>
          import('../worklist/worklist.module').then((m) => m.WorklistModule),
        // loadChildren: () => import('../list-visit-container/list-visit-container.module').then(m => m.ListVisitContainerModule),
        data: {
          pagename: 'list_visit',
        },
      },
    ],
  },
  {
    path: 'common-mobile',
    component: MobileLayoutComponent,
    children: [
      {
        path: 'worklist',
        loadChildren: () =>
          import('../worklist/worklist.module').then((m) => m.WorklistModule),
        data: {
          pagename: 'list_visit',
        },
      },
    ],
  },
  {
    path: 'thong-ke',
    component: ReportSummaryComponent,
    canActivate: [RoleGuardClass],
    data: {
      roles: [Constant.USERROLE.ViewStatistic],
      type: Constant.ROLETYPE.OR,
    },
  },
  { path: 'view-visit', component: ViewVisitComponent },
  { path: 'user-profile', component: UserProfileComponent },
  {
    path: 'video-conference',
    component: ConferenceRoomCallComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'covid-list',
    component: CovidReceiveListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'silder-image/:visitId',
    component: SliderImageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'update-user-profile',
    component: UpdateUserProfileComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'waiting-page/:groupId',
    component: WaitingPageComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
