<nz-modal
  nzClassName="myClass"
  [(nzVisible)]="isVisible"
  nzTitle="Gửi thông tin ca khám cho bệnh nhân"
  nzWidth="400px"
  (nzOnCancel)="handleCancel()"
  [nzFooter]="null"
  nzWidth="800px">
  <nz-tabset
    [(nzSelectedIndex)]="indexTab"
    (nzSelectedIndexChange)="changeTabIndex($event)"
    class="tabset-default">
    <nz-tab
      *ngIf="isAdminGroup"
      [nzDisabled]="tabs[0].disabled"
      nzTitle="Kích hoạt tài khoản">
      <form nz-form [formGroup]="formActiveAccount">
        <div nz-row>
          <div nz-col nzSpan="10">Tên đăng nhập</div>
          <div nz-col nzSpan="12">{{ patientAccountUsername }}</div>
        </div>
        <nz-form-item>
          <nz-form-label [nzSpan]="10" nzRequired>Mật khẩu</nz-form-label>
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            [nzErrorTip]="passwordActiveAccountErrorTpl">
            <input
              nz-input
              type="password"
              placeholder="Mật khẩu"
              formControlName="password"
              (ngModelChange)="validateActiveAccountConfirmPassword()" />
            <ng-template #passwordActiveAccountErrorTpl let-control>
              <ng-container *ngIf="control.hasError('emptyName')">
                Mật khẩu không được chứa dấu cách
              </ng-container>
              <ng-container *ngIf="control.hasError('required')">
                Hãy nhập mật khẩu
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="10" nzRequired
            >Xác nhận mật khẩu</nz-form-label
          >
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            [nzErrorTip]="passwordActiveErrorTpl">
            <input
              nz-input
              type="password"
              formControlName="confirm"
              placeholder="Xác nhận mật khẩu" />
            <ng-template #passwordActiveErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')"
                >Xác nhận lại mật khẩu</ng-container
              >
              <ng-container *ngIf="control.hasError('confirm')"
                >Kiểm tra lại mật khẩu</ng-container
              >
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row class="register-area">
          <nz-form-control [nzSpan]="6" [nzOffset]="10">
            <button
              nz-button
              nzType="primary"
              (click)="savePatientAccountInfo()">
              Kích hoạt
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-tab>
    <nz-tab
      *ngIf="isAdminGroup"
      [nzDisabled]="tabs[1].disabled"
      nzTitle="Khôi phục mật khẩu">
      <form nz-form [formGroup]="formResetPasswordAccount">
        <div nz-row>
          <div nz-col nzSpan="10" style="text-align: right; padding-right: 8px">
            Tên đăng nhập
          </div>
          <div nz-col nzSpan="12">{{ patientAccountUsername }}</div>
        </div>
        <nz-divider nzType="horizontal"></nz-divider>
        <nz-form-item>
          <nz-form-label [nzSpan]="10" nzRequired>Mật khẩu</nz-form-label>
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            [nzErrorTip]="passwordActiveAccountErrorTpl">
            <input
              nz-input
              type="password"
              formControlName="password"
              placeholder="Mật khẩu"
              (ngModelChange)="validateResetPasswordConfirmPassword()" />
            <ng-template #passwordActiveAccountErrorTpl let-control>
              <ng-container *ngIf="control.hasError('emptyName')">
                Mật khẩu không được chứa dấu cách
              </ng-container>
              <ng-container *ngIf="control.hasError('required')">
                Hãy nhập mật khẩu
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="10" nzRequired
            >Xác nhận mật khẩu</nz-form-label
          >
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            [nzErrorTip]="passwordResetPasswordErrorTpl">
            <input
              nz-input
              type="password"
              formControlName="confirm"
              placeholder="Xác nhận mật khẩu" />
            <ng-template #passwordResetPasswordErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')"
                >Xác nhận lại mật khẩu</ng-container
              >
              <ng-container *ngIf="control.hasError('confirm')"
                >Kiểm tra lại mật khẩu</ng-container
              >
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row class="register-area">
          <nz-form-control [nzSpan]="6" [nzOffset]="10">
            <button
              nz-button
              nzType="primary"
              (click)="savePatientAccountInfo()">
              Đặt lại mật khẩu
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-tab>
    <nz-tab
      [nzDisabled]="tabs[2].disabled"
      nzTitle="Gửi thông tin tài khoản cho bệnh nhân">
      <div class="mgb-25">
        <nz-radio-group
          class="checkbox"
          [(ngModel)]="radioValue"
          (ngModelChange)="onChangeRadio($event)">
          <label nz-radio [nzValue]="0">Gửi bằng email</label>
          <label nz-radio [nzValue]="1" nzDisabled>Gửi bằng SMS</label>
          <label *ngIf="isCreateQuickly" nz-radio [nzValue]="2"
            >Tạo nhanh</label
          >
        </nz-radio-group>
      </div>
      <div *ngIf="radioValue == 0 || radioValue == 1">
        <div class="enter-email mgb-25 is-empty{{ isEmpty }}">
          <span>Gửi tới:</span>
          <span
            ><input
              nz-input
              [(ngModel)]="emailOrSDT"
              (ngModelChange)="onChangeText($event)"
          /></span>
          <span class="icon-edit"
            ><i nz-icon nzType="edit" nzTheme="outline"></i
          ></span>
        </div>
        <button nz-button class="btn-send" (click)="sendProfile()">Gửi</button>
      </div>
      <div *ngIf="radioValue == 2">
        <button
          nz-button
          class="btn-send"
          (click)="createPatientAccountQuickly()">
          Tạo nhanh
        </button>
      </div>
    </nz-tab>
  </nz-tabset>
</nz-modal>

<nz-modal
  nzClassName="myClass"
  [(nzVisible)]="isVisibleCopyLink"
  nzTitle="Link kích hoạt tài khoản BN"
  nzWidth="400px"
  (nzOnCancel)="handleCancelCopyLink()"
  [nzFooter]="null"
  nzWidth="500px">
  <span style="font-weight: bold"
    >Bạn hãy gửi link này cho BN để BN tự kích hoạt tài khoản:</span
  ><br />
  <a
    style="text-decoration: underline"
    target="_blank"
    href="{{ activeAccountUrl }}"
    >{{ activeAccountUrl }}</a
  ><br /><br />
  <button
    nz-button
    class="btn-send"
    (click)="copyLinkActive()"
    title="Copy link">
    <i nz-icon nzType="link" nzTheme="outline"></i>
    Copy link
  </button>
</nz-modal>
