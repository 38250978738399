<!-- [style.width.px]="width" [(style.height.px)]="height" -->
<div
  class="msc-custom-modal resizable-draggable"
  #dragAndResizeModal
  appCustomModalMovable
  [modalId]="modalId"
  [needFocus]="true"
  *ngIf="isVisible"
  [class.active]="status === 1"
  [style.width.px]="width"
  [(style.height.px)]="height"
  (window:mouseup)="setStatus($event, 0)">
  <div class="div-header">
    <ng-container [ngTemplateOutlet]="modalTitle"></ng-container>
    <a class="close-icon" (click)="handleCancel()">
      <i nz-icon class="pd-10" nzType="close" nzTheme="outline"></i>
    </a>
  </div>
  <div class="div-content">
    <ng-container [ngTemplateOutlet]="modalContent"></ng-container>
    <div *ngIf="isResiable && !mFooter" class="resize-action" (mousedown)="setStatus($event, 1)"></div>
  </div>
  <div *ngIf="mFooter" class="div-action">
    <!-- <button nz-button (click)="handleCancel()">Hủy</button>
        <button nz-button nzType="primary" (click)="updateNotes()" class="button-submit">Lưu</button> -->
    <ng-container [ngTemplateOutlet]="modalFooter"></ng-container>
    <div *ngIf="isResiable" class="resize-action" (mousedown)="setStatus($event, 1)"></div>
  </div>
</div>
<div *ngIf="isVisible && status != 0 && !mMask" [ngClass]="mMask ? 'mask' : ''" class="drag-overlay"></div>
<div *ngIf="isVisible && mMask" class="mask"></div>
