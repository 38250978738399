<div class="page-header" style="display: flex" *ngIf="visit.id">
  <div class="left" style="width: 20%">
    <a style="margin-left: 10px" (click)="backToPatientList()">Quay lại</a>
  </div>
  <div class="center" style="width: 60%; text-align: center">
    <strong style="width: 100%" class="page-title">{{
      visit.patientName
    }}</strong>
  </div>
  <div class="right" style="width: 20%">
    <a style="margin-right: 10px" (click)="toggleVisitMenu()">Ca khám</a>
  </div>
</div>

<nz-spin
  class="loading-icon"
  [nzSize]="'large'"
  nzTip="Đang tải thông tin ca khám..."
  [nzSpinning]="isLoading">
  <div *ngIf="arrTab[0]">
    <app-header
      *ngIf="visit.id"
      [isCovidGroup]="isCovidGroup"
      [hideButton]="hideButton"
      [visit]="visit">
    </app-header>
    <app-visit-info-mobile [visit]="visit"></app-visit-info-mobile>
  </div>
  <div *ngIf="arrTab[1]">
    <div class="group-box">
      <div class="box-title" (click)="toggleCollapse(1)">
        <strong>Master notes</strong>
        <i nz-icon [nzType]="arrCollapse[1] ? 'up' : 'down'"></i>
      </div>
      <div
        [ngClass]="arrCollapse[1] ? 'box-content show' : 'box-content hide'"
        style="height: 100%">
        <app-masternotes-tab
          *ngIf="visit.id"
          #appMasterNote
          [hideButton]="hideButton"
          [disabledClass]="disabledClass"
          [visit]="visit">
        </app-masternotes-tab>
      </div>
    </div>
  </div>
  <div *ngIf="arrTab[2]">
    <app-chat-tab
      *ngIf="visit.id"
      [hideButton]="hideButton"
      [disabledClass]="disabledClass"
      [visit]="visit"
      (getMessage)="getMesssage($event)"></app-chat-tab>
  </div>
  <div *ngIf="arrTab[3]">
    <app-vital-signs-tab-mobile
      [hideButton]="hideButton"
      [disabledClass]="disabledClass"
      [visit]="visit"
      *ngIf="visit.id"
      [(reloadNow)]="reloadNow"></app-vital-signs-tab-mobile>
  </div>
  <div *ngIf="arrTab[4]">
    <app-prescription-tab
      [visit]="visit"
      *ngIf="visit.id"
      (newVisitId)="getVisit($event)"></app-prescription-tab>
  </div>
</nz-spin>

<!--SIDE BAR-->
<div class="sidebar sidebar-right" *ngIf="isVisibleVisitMenu">
  <div class="sidebar-header" style="margin: auto">
    <div>
      <div>
        <strong>{{ visit.patientName }}</strong>
      </div>
      <strong>Danh sách ca khám</strong>
      <a style="margin-left: 10px" (click)="toggleVisitMenu()">
        <img _ngcontent-kjb-c497="" src="assets/image/DPicon/icon-close.svg" />
      </a>
    </div>
  </div>
  <div style="padding: 10px">
    <ul class="visit-menu thin-scroll">
      <li
        (click)="loadVisitById(item.id)"
        [ngClass]="item.id === visit.id ? 'active-visit' : ''"
        *ngFor="let item of lstHistory">
        <div class="txt-ellipsis" *ngIf="item.id === visit.id">
          <a>{{ item.visitDate | date: 'dd/MM/yyy hh:mm a' }}</a>
          <span>{{ visit.visitReason }}</span>
          <span *ngIf="item.doctorGroupId != groupId"
            >&nbsp;({{ item.doctorGroupName }})</span
          >
        </div>
        <div class="txt-ellipsis" *ngIf="item.id != visit.id">
          <a>{{ item.visitDate | date: 'dd/MM/yyy hh:mm a' }}</a>
          <span>{{ item.visitReason }}</span>
          <span *ngIf="item.doctorGroupId != groupId"
            >&nbsp;({{ item.doctorGroupName }})</span
          >
        </div>
      </li>
    </ul>
  </div>
</div>
<div class="botnav-mobile">
  <ul>
    <li class="{{ arrTab[0] ? 'active-tab' : '' }}" (click)="tabSelect(0)">
      Home
    </li>
    <li class="{{ arrTab[3] ? 'active-tab' : '' }}" (click)="tabSelect(3)">
      Lâm sàng
    </li>
    <li class="{{ arrTab[4] ? 'active-tab' : '' }}" (click)="tabSelect(4)">
      Thuốc
    </li>
    <li class="{{ arrTab[1] ? 'active-tab' : '' }}" (click)="tabSelect(1)">
      Notes
    </li>
    <li class="{{ arrTab[2] ? 'active-tab' : '' }}" (click)="tabSelect(2)">
      Chat
    </li>
  </ul>
</div>

<!--Floating button-->
<div class="ngx-float-button">
  <nav class="fab-menu">
    <a class="fab-toggle red" (click)="toggleFAB()">
      <mat-icon
        *ngIf="isToggleFAB"
        aria-hidden="false"
        aria-label="Example home icon"
        >close</mat-icon
      >
      <mat-icon
        *ngIf="!isToggleFAB"
        aria-hidden="false"
        aria-label="Example home icon"
        >more_vert</mat-icon
      >
    </a>
    <!-- Tạm thời ẩn nút k cần thiết -->
    <div class="item-list" *ngIf="isToggleFAB">
      <a
        title="Sửa thông tin ca khám"
        (click)="openModalEditVisitCore()"
        class="fab-item">
        <mat-icon aria-hidden="false" aria-label="Example home icon">
          mode_edit
        </mat-icon>
      </a>
      <a
        (click)="showVisitLog()"
        title="Lịch sử hoạt động ca khám"
        class="fab-item">
        <mat-icon aria-hidden="false" aria-label="Example home icon">
          history
        </mat-icon>
      </a>
      <a (click)="showModalShareVS()" title="Chia sẻ ca khám" class="fab-item">
        <mat-icon aria-hidden="false" aria-label="Example home icon">
          insights
        </mat-icon>
      </a>
      <a (click)="showVideoCall()" title="Video Conference" class="fab-item">
        <mat-icon aria-hidden="false" aria-label="Example home icon">
          videocam
        </mat-icon>
      </a>
      <a
        (click)="sendProfile()"
        title="Gửi thông tin cho bệnh nhân"
        class="fab-item">
        <mat-icon aria-hidden="false" aria-label="Example home icon">
          mail
        </mat-icon>
      </a>
      <a (click)="addVisit()" title="Thêm ca khám" class="fab-item">
        <mat-icon aria-hidden="false" aria-label="Example home icon">
          add
        </mat-icon>
      </a>
    </div>
  </nav>
</div>
<app-share-visit-update
  [isVisible]="isShareVisit"
  [visitId]="visitId"
  [groupId]="visit.groupId"
  (closeModal)="closeModalShareVS()">
</app-share-visit-update>
<app-visit-log
  [isVisible]="isVisitLogVisible"
  [visitId]="visitId"
  (closeModal)="closeVisitLog()"></app-visit-log>
<app-patient-profile
  [patient]="patient"
  [visitId]="visitId"
  [isVisible]="isSentPatient"
  (closeProflie)="closeProflie()"
  (profileStatus)="getStatus($event)"
  (changeEmail)="changeEmail($event)">
</app-patient-profile>
<app-conference-room
  [isVisible]="isVideoCall"
  [visitId]="visitId"
  [patient]="patient"
  (closeModal)="closeVideoCall($event)"></app-conference-room>
<app-edit-visitinfo-core
  [isCovidGroup]="isCovidGroup"
  [isVisible]="isVisibleEditVisitCore"
  [visit]="visit"
  (closeModal)="closeEditVisitCore()"
  (editSuccess)="getVisit(visitId)"></app-edit-visitinfo-core>
