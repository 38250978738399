import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { SubClinicalVisitService } from 'src/app/services/subcliniccal-service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-resend-sub-clinical-visit',
  templateUrl: './resend-sub-clinical-visit.component.html',
  styleUrls: ['./resend-sub-clinical-visit.component.scss'],
})
export class ResendSubClinicalVisitComponent implements OnInit {
  listDisplay: any[];
  isLoading = false;
  total: number;
  selectedItemId: number;
  loading = false;
  dataSearch = {
    pageSize: 20,
    page: 1,
    fromDate: moment().startOf('day').toDate(),
    toDate: moment().endOf('day').toDate(),
    name: '',
  };
  initDataSearch;

  subscription;
  SubclinicalVisitHL7Status = Constant.SubclinicalVisitHL7Status;

  popupData = {
    'isVisible': false,
    rowSelected: {},
  };
  formAdd: FormGroup;
  initFormValue: any;
  isVisibleAdd = false;
  timerId: any;
  baseUrl = '';
  doctors = [];
  listUser = [];
  constructor(
    private shareService: ShareService,
    private subclinicalService: SubClinicalVisitService,
    private modalService: NzModalService,
    private notificationService: NotificationService
  ) {
    this.getInitData();
  }

  ngOnInit(): void {
    this.apiGetList(this.dataSearch);
  }

  getInitData() {
    this.initDataSearch = JSON.parse(JSON.stringify(this.dataSearch));
  }
  resetSearch() {
    this.dataSearch = JSON.parse(JSON.stringify(this.initDataSearch));
    this.apiGetList(this.dataSearch);
  }

  apiGetList(payload) {
    this.isLoading = true;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.subclinicalService
      .FilterSubClinicalVisit2Pacs(payload)
      .subscribe((res: any) => {
        this.listDisplay = res.data;
        this.total = res.total;
      })
      .add(() => {
        this.isLoading = false;
      });
  }
  resend(data) {
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: `Bạn có muốn đẩy lại chỉ định ${data.name} này không?`,
      nzOkText: 'Đồng ý',
      nzCancelText: 'Bỏ qua',
      nzOnOk: () => {
        this.subclinicalService
          .ResendSubClinicalVisit2Pacs({ visitId: data.visitId, subClinicalVisitId: data.subClinicalVisitId })
          .subscribe((res: any) => {
            if (res.isValid) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Đẩy lại chỉ định thành công');
              this.apiGetList(this.dataSearch);
            } else {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
            }
          });
      },
    });
  }

  selectItem(data, index) {
    this.selectedItemId = index;
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.selectedItemId = -1;
    const { pageIndex, pageSize } = params;
    this.dataSearch.page = pageIndex;
    this.dataSearch.pageSize = pageSize;
    this.apiGetList(this.dataSearch);
  }
  search() {
    this.apiGetList({ ...this.dataSearch });
  }
}
