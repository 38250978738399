import { SSOLogin, SSOLogout } from './../../components/auth/redux/auth.action';
import { Injectable } from '@angular/core';
import {
  CanLoad,
  CanActivate,
  Router,
  Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app-config.service';
import { AppState } from 'src/app/components/app-state/app-state';
import { LoginSuccess, Logout, LogoutSuccess } from 'src/app/components/auth/redux/auth.action';
import { getAuth } from 'src/app/components/auth/redux/auth.reducer';
import { Join } from 'src/app/components/auth/redux/group-auth.action';
import { AuthModel } from 'src/app/models/auth.model';
import { GroupStateModel } from 'src/app/models/group-state.model';
import { AuthService } from 'src/app/services/auth.service';
import { TabDataService } from '../base-service/data-worklist.service';
import { Constant } from '../constants/constant.class';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {
  authUser: AuthModel = {};
  //let groupState: GroupStateModel = {};

  constructor(
    private router: Router,
    private authService: AuthService,
    private tabDataService: TabDataService,
    private store: Store<AppState>,
    private configService: AppConfigService,
    private route: ActivatedRoute
  ) {
    this.store.select('auth').subscribe((res) => {
      this.authUser = res;
    });
  }

  canLoad(route: Route): Observable<boolean> | boolean | Promise<boolean> {
    return this.isLoggedIn();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean | Promise<boolean> {
    // check authentication
    return this.isLoggedIn(state.url, route.queryParams);
  }

  isLoggedIn(url?: string, queryParam?): Promise<boolean> {
    return new Promise((resolve, reject) => {
      console.log(this.authUser);
      if (this.authUser.isAuthenticated) {
        resolve(true);
      } else {
        if (url.indexOf('view-visit') >= 0) {
          resolve(true);
        }
        const newUserLogined = localStorage.getItem(Constant.SESSION_INVITE_FIRST_USER_PMR);
        console.log('zzzz isUseSSOLogin', newUserLogined);
        // if (newUserLogined != null && newUserLogined != '') {
        //   resolve(true);
        // }
        if (newUserLogined == null || newUserLogined == '') {
          if (this.configService.getConfig().isUseSSOLogin) {
            const returnURL = window.location.origin + '/sso-login?returnUrl=' + url;
            this.store.dispatch(new SSOLogin(returnURL));
            resolve(false);
          } else {
            const currentToken = localStorage.getItem(Constant.TOKEN);

            if (currentToken) {
              this.authService.checkToken().subscribe(
                (res) => {
                  if (res.ret != null && res.ret.code == 401) {
                    this.store.dispatch(new Logout(''));
                    resolve(false);
                  } else {
                    localStorage.setItem(Constant.TOKEN, res.value);
                    localStorage.setItem(Constant.USER_INFO, JSON.stringify(res));
                    this.store.dispatch(new LoginSuccess(res));
                    resolve(true);
                  }
                },
                (err) => {
                  this.store.dispatch(new Logout(''));
                  resolve(false);
                }
              );
            } else {
              this.store.dispatch(new Logout('', queryParam));
              resolve(false);
            }
          }
        } else resolve(false);
      }
    });
  }
}
