<!-- <div id="loader">
  <div>
    <div id="load-title">PMR</div>
    <div id="load-icon"></div>
  </div>
</div> -->
<nz-layout class="layout" (click)="clickPage()">
  <nz-sider id="doctor-sider" nzCollapsible [(nzCollapsed)]="isCollapsed" (nzCollapsedChange)="collapsedChange($event)"
    [nzBreakpoint]="'lg'" nzWidth="240px" nzCollapsedWidth="60" nzTheme="dark" [nzTrigger]="null"
    [ngStyle]="{ 'background-color': 'var(--dp-primary-1)' }" style="overflow-y: auto"
    [ngClass]="isCollapsed ? 'sidebarhover' : ' '" (mouseleave)="hidemenu()" (mouseenter)="showmenu()">
    <div class="box-logo" (click)="logoCollap()">
      <div class="logo-img">
        <img class="image" src="assets/image/logo.png" />
      </div>
      <div [ngStyle]="{ 'display': isCollapsed ? 'none' : 'flex' }" class="brand-name">PMR</div>
    </div>
    <div class="bder-dashed" [ngStyle]="{ 'margin': isCollapsed ? '0px' : '0px 13px' }"></div>
    <!-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
    <ul nz-menu nzTheme="dark" [nzInlineCollapsed]="isCollapsed" nzMode="inline"
      [ngStyle]="{ 'background-color': 'var(--dp-primary-1)' }">
      <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menus }"></ng-container>
      <ng-template #menuTpl let-menus>
        <ul class="ul-menu">
          <li *ngIf="isCovidGroup && (isUserCoordinator || isUserVolunteer)"
            class="sideicon menu-item menu-item-flex responsive-icon" nz-menu-item
            (click)="openNewTab('covid_receive_list', 'CovidReceive', 'CovidReceive')"
            [nzSelected]="currentTabId === 'covid_receive_list' ? true : false">
            <span>
              <i><img style="width: 28px; margin-left: 5px; margin-bottom: 7px" src="/assets/image/register_white.svg"
                  alt="" /></i>
            </span>
            <span>Danh sách tiếp nhận KH</span>
          </li>
          <li *ngIf="isCovidGroup || isUseListPatient" class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab('ListPatient', 'Danh sách bệnh nhân', '')"
            [nzSelected]="currentTabId === 'ListPatient' ? true : false">
            <span>
              <i><img style="height: 20px" src="/assets/image/patient_list.svg" alt="" /></i>
            </span>
            <span>Danh sách bệnh nhân</span>
          </li>
          <li class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab(listVisitComponentName, 'Danh sách ca khám', '')"
            [nzSelected]="currentTabId === listVisitComponentName ? true : false">
            <span>
              <i><img style="height: 20px" src="/assets/image/visit_list.svg" alt="" /></i>
            </span>
            <span>Danh sách ca khám</span>
          </li>
          <li class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab('listVisitCalendar', 'Visit calendar', '')"
            [nzSelected]="currentTabId === listVisitCalendar ? true : false">
            <span>
              <!-- <i><img style="height: 20px" src="/assets/image/DPicon/visit-calendar.svg" alt="" /></i> -->
              <i nz-icon nzType="calendar" nzTheme="outline" style="font-size: 21px; margin-left: 8px;"></i>
            </span>
            <span>Visit calendar</span>
          </li>
          <li class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab('listAppointment', 'Danh sách hẹn khám', '')"
            [nzSelected]="currentTabId === 'listAppointment' ? true : false">
            <span>
              <i><img style="height: 20px" src="/assets/image/appointment_list.svg" alt="" /></i>
            </span>
            <span>Danh sách hẹn khám</span>
          </li>
          <!-- <li class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab('listVisitCalendar', 'Visit calendar', '')"
            [nzSelected]="currentTabId === listVisitCalendar ? true : false">
            <span>
              <i><img style="height: 20px" src="/assets/image/DPicon/visit-calendar.svg" alt="" /></i>
            </span>
            <span>Visit calendar</span>
          </li> -->
          <li *ngIf="isUseReminder" class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab('listReminder', 'Lịch công việc', 'listReminder')"
            [nzSelected]="currentTabId === 'listReminder' ? true : false">
            <span>
              <i><img style="height: 20px" src="/assets/image/LichCongViec.svg" alt="" /></i>
            </span>
            <span>Lịch công việc</span>
          </li>
          <li *ngIf="isUseMedicalOrder" class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab('medicalOrder', 'Danh sách y lệnh', 'medicalOrder')"
            [nzSelected]="currentTabId === 'medicalOrder' ? true : false">
            <span>
              <img style="height: 20px" src="/assets/image/menu-left/medical-order-white.svg" alt="" />
            </span>
            <span>Danh sách y lệnh</span>
          </li>
          <li *ngIf="isUseDividePatientRoom" class="sideicon menu-item menu-item-flex responsive-icon" nz-menu-item
            (click)="openNewTab('patient-group-management', 'Danh sách phòng BN', 'patient-group-management')"
            [nzSelected]="currentTabId === 'patient-group-management' ? true : false">
            <span>
              <img style="height: 20px" src="/assets/image/menu-left/people_white.svg" alt="" />
            </span>
            <span>Danh sách phòng BN</span>
          </li>

          <li  class="sideicon menu-item menu-item-flex responsive-icon" nz-menu-item
            (click)="openNewTab('resend-sub-clinical-visit', 'Đẩy lại chỉ định PACS', 'resend-sub-clinical-visit')"
            [nzSelected]="currentTabId === 'resend-sub-clinical-visit' ? true : false">
            <span>
              <img style="height: 20px" src="/assets/image/menu-left/repeat-white.png" alt="" />
            </span>
            <span>Đẩy lại chỉ định PACS</span>
          </li>

          <li *ngIf="isUseWaitingPage" class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="sendToWaitingPage()">
            <span>
              <img style="height: 24px" src="/assets/image/menu-left/waiting-room-white.svg" alt="" />
            </span>
            <span>Màn hình chờ</span>
          </li>
        </ul>
        <div class="bder-dashed" [ngStyle]="{ 'margin': isCollapsed ? '0px' : '0px 13px' }"></div>
        <ul class="ul-menu">
          <li *ngIf="isUseChat" class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab('chat', 'Tin nhắn (n)', 'Chat')" [nzSelected]="currentTabId === 'chat' ? true : false">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                <path id="messenger"
                  d="M9.5,0A9.5,9.5,0,0,0,1.26,14.229L.036,18.03a.742.742,0,0,0,.934.934l3.8-1.224A9.5,9.5,0,0,0,19,9.5a9.69,9.69,0,0,0-1.786-5.549A9.35,9.35,0,0,0,9.5,0Zm0,17.516A7.992,7.992,0,0,1,5.257,16.3a.743.743,0,0,0-.621-.077l-2.744.884.884-2.744a.743.743,0,0,0-.077-.621,8.016,8.016,0,1,1,6.8,3.773ZM10.428,9.5A.928.928,0,1,1,9.5,8.572.928.928,0,0,1,10.428,9.5Zm3.711,0a.928.928,0,1,1-.928-.928A.928.928,0,0,1,14.139,9.5Zm-7.422,0a.928.928,0,1,1-.928-.928A.928.928,0,0,1,6.717,9.5Zm0,0"
                  fill="#fefefe" />
              </svg>
              <span class="unread-ico" *ngIf="unReadHtml" [innerHTML]="unReadHtml"></span>
            </span>
            <span>Tin nhắn</span>
          </li>
          <li *ngIf="isUseVideoCall" class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openModelMemberRoom()">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="18" viewBox="0 0 26 20">
                <g id="Video_call" transform="translate(-1217.035 -250)">
                  <g id="Polygon_16" data-name="Polygon 16" transform="translate(1232 270) rotate(-90)" fill="none">
                    <path
                      d="M 16.79743957519531 11.00000381469727 L 3.202558994293213 11.00000381469727 C 2.91559910774231 11.00000381469727 2.790318965911865 10.7986536026001 2.749768972396851 10.71208381652832 C 2.709218978881836 10.62551307678223 2.63473916053772 10.40035343170166 2.818449020385742 10.17991352081299 L 9.615888595581055 2.022983551025391 C 9.746338844299316 1.866443514823914 9.912539482116699 1.843073487281799 9.999999046325684 1.843073487281799 C 10.08745861053467 1.843073487281799 10.25365924835205 1.866443514823914 10.38409900665283 2.022983551025391 L 17.18154907226563 10.17990303039551 C 17.36525917053223 10.40035343170166 17.29077911376953 10.62551307678223 17.25022888183594 10.71208381652832 C 17.20968818664551 10.7986536026001 17.08439826965332 11.00000381469727 16.79743957519531 11.00000381469727 Z"
                      stroke="none" />
                    <path
                      d="M 9.999999046325684 3.124095916748047 L 4.270076751708984 10.00000381469727 L 15.72992134094238 10.00000381469727 L 9.999999046325684 3.124095916748047 M 9.999999046325684 0.8430709838867188 C 10.42624187469482 0.8430709838867188 10.85248374938965 1.022978782653809 11.15232944488525 1.382793426513672 L 17.94976806640625 9.53972339630127 C 18.76392936706543 10.51671314239502 18.06919860839844 12.00000381469727 16.79743957519531 12.00000381469727 L 3.202558517456055 12.00000381469727 C 1.930809020996094 12.00000381469727 1.236068725585938 10.51671314239502 2.050228118896484 9.53972339630127 L 8.847668647766113 1.382793426513672 C 9.147514343261719 1.022978782653809 9.573756217956543 0.8430709838867188 9.999999046325684 0.8430709838867188 Z"
                      stroke="none" fill="#fff" />
                  </g>
                  <g id="Rectangle_1967" data-name="Rectangle 1967" transform="translate(1217.035 251)" fill="none"
                    stroke="#fff" stroke-width="2">
                    <rect width="18" height="18" rx="3" stroke="none" />
                    <rect x="1" y="1" width="16" height="16" rx="2" fill="none" />
                  </g>
                </g>
              </svg>
            </span>
            <span>Video call</span>
          </li>
        </ul>
        <div *ngIf="isUseChat || isUseVideoCall" class="bder-dashed"
          [ngStyle]="{ 'margin': isCollapsed ? '0px' : '0px 13px' }"></div>
        <ul class="ul-menu">
          <li class="sideicon menu-item menu-item-flex responsive-icon" nz-menu-item
            (click)="openNewTab('dashboard', 'GroupDashboard', 'GroupDashboard')"
            [nzSelected]="currentTabId === 'dashboard' ? true : false">
            <span>
              <svg id="info" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 32 32">
                <g id="info-2" data-name="info" transform="translate(6.667 6.667)">
                  <path id="Path_179" data-name="Path 179"
                    d="M9.333,0a9.333,9.333,0,1,0,9.333,9.333A9.344,9.344,0,0,0,9.333,0Zm0,16.97A7.636,7.636,0,1,1,16.97,9.333,7.645,7.645,0,0,1,9.333,16.97Z"
                    fill="#fff" />
                  <path id="Path_180" data-name="Path 180"
                    d="M146.133,70a1.131,1.131,0,1,0,1.131,1.132A1.133,1.133,0,0,0,146.133,70Z"
                    transform="translate(-136.8 -66.04)" fill="#fff" />
                  <path id="Path_181" data-name="Path 181"
                    d="M150.848,140a.849.849,0,0,0-.848.848v5.091a.848.848,0,0,0,1.7,0v-5.091A.849.849,0,0,0,150.848,140Z"
                    transform="translate(-141.515 -132.081)" fill="#fff" />
                </g>
                <g id="Rectangle_53" data-name="Rectangle 53" fill="none" stroke="#707070" stroke-width="1" opacity="0">
                  <rect width="32" height="32" stroke="none" />
                  <rect x="0.5" y="0.5" width="31" height="31" fill="none" />
                </g>
              </svg>
            </span>
            <span>Thông tin hoạt động</span>
          </li>
          <li *ngIf="isUseGroupStatistic" class="sideicon menu-item menu-item-flex responsive-icon" nz-menu-item
            (click)="openNewTab('report-group', 'Thống kê', 'report-group')"
            [nzSelected]="currentTabId === 'report-group' ? true : false">
            <span>
              <svg id="Capa_1" enable-background="new 0 0 512 512" height="20" viewBox="0 0 512 512" width="24"
                xmlns="http://www.w3.org/2000/svg" style="fill: white">
                <g>
                  <path d="m512 482h-30v-302h-91v302h-30v-182h-90v182h-30v-242h-90v242h-30v-152h-91v152h-30v30h512z" />
                  <path
                    d="m512 120v-120h-121v30h69.789l-144.789 143.789-120-120-191.605 190.606 21.21 21.21 170.395-169.394 120 120 166-165v68.789z" />
                </g>
              </svg>
            </span>
            <span>Thống kê nhóm</span>
          </li>
          <li class="sideicon menu-item menu-item-flex" nz-menu-item>
            <span>
              <img src="/assets/image/menu-left/icon-share-white.svg" />
            </span>
            <span>Danh sách ca chia sẻ</span>
          </li>
        </ul>
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <div class="bder-dashed" [ngStyle]="{ 'margin': isCollapsed ? '0px' : '0px 13px' }"></div>
        <ul class="ul-menu">
          <li *ngIf="isUserUpdateIdCategory" class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab('subclinicalorderGroup', 'Danh sách nhóm chỉ định', 'subclinicalorderGroup')"
            [nzSelected]="currentTabId === 'subclinicalorderGroup' ? true : false">
            <span>
              <img style="height: 20px" src="/assets/image/appointment_list.svg" alt="" />
            </span>
            <span>Danh sách nhóm chỉ định</span>
          </li>
          <!-- anticovid hidden -->
          <li *ngIf="isUserUpdateForm" class="sideicon menu-item menu-item-flex responsive-icon" nz-menu-item
            (click)="openNewTab('form', 'FormMngt', 'FormMngt')" [nzSelected]="currentTabId === 'form' ? true : false">
            <span>
              <svg id="Lab" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <g id="Rectangle_53" data-name="Rectangle 53" fill="none" stroke="#707070" stroke-width="1" opacity="0">
                  <rect width="32" height="32" stroke="none" />
                  <rect x="0.5" y="0.5" width="31" height="31" fill="none" />
                </g>
                <g id="Group_724" data-name="Group 724" transform="translate(-52.985 5)">
                  <g id="Group_723" data-name="Group 723" transform="translate(60.985 0)">
                    <path id="Path_187" data-name="Path 187"
                      d="M77.062,20.663H71.274V17.9h4.5a.643.643,0,0,0,0-1.286h-4.5v-.643a.643.643,0,0,0-.643-.643H67.416a2.572,2.572,0,0,1,0-5.145h1.051l.758,1.689a.643.643,0,0,0,.85.324l.587-.263.79,1.76a.643.643,0,0,0,.85.324l2.347-1.053a.643.643,0,0,0,.324-.85l-.79-1.76.587-.263a.643.643,0,0,0,.324-.85L71.933,2.2a.643.643,0,0,0-.85-.324L70.5,2.14,69.707.38a.643.643,0,0,0-.85-.324L66.51,1.11a.643.643,0,0,0-.324.85l.79,1.76-.587.263a.643.643,0,0,0-.324.85l.685,1.528a6.432,6.432,0,0,0-1.093,12.583l-.622,1.72H62.914a.643.643,0,1,0,0,1.286H77.062a.643.643,0,0,0,0-1.286Zm-4.7-8.082-.526-1.174,1.174-.526.526,1.174ZM68.8,1.493l.526,1.174-1.173.526L67.623,2.02Zm2.226,1.821,2.632,5.868-.587.263h0l-2.934,1.316L67.5,4.893Zm-8.752,9.444a5.151,5.151,0,0,1,5.042-5.143L67.889,8.9h-.474a3.859,3.859,0,0,0-.88,7.616l-.44,1.217A5.122,5.122,0,0,1,62.271,12.757Zm7.717,7.906H66.4l1.464-4.047h2.121Z"
                      transform="translate(-60.985 0)" fill="#fff" />
                  </g>
                </g>
              </svg>
            </span>
            <span>Quản lý biểu mẫu</span>
          </li>
          <li *ngIf="isUserUpdateIdCategory" class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab('observation', 'ObservationType', 'ObservationType')"
            [nzSelected]="currentTabId === 'observation' ? true : false">
            <span>
              <img class="eye-icon" src="assets/image/DPicon/bright-eye.svg" />
            </span>
            <span>Quản lý mục dữ liệu</span>
          </li>
          <li *ngIf="isUserUpdateMedicine" class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab('medication', 'MedicineManagement', 'MedicineManagement')"
            [nzSelected]="currentTabId === 'medication' ? true : false">
            <span>
              <img class="eye-icon" src="assets/image/DPicon/medicine_management.svg" />
            </span>
            <span>Quản trị DM thuốc</span>
          </li>
          <li *ngIf="isAdminGroup" class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab('examinationservice', 'ExaminationServiceManagement', 'ExaminationServiceManagement')"
            [nzSelected]="currentTabId === 'examinationservice' ? true : false">
            <span>
              <img class="eye-icon" src="assets/image/DPicon/examination-service.svg" />
            </span>
            <span>Quản lý dịch vụ khám</span>
          </li>
          <li *ngIf="isUseTreatmentProcess" class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab('treatment', 'TreatmentProcess', 'TreatmentProcess')"
            [nzSelected]="currentTabId === 'treatment' ? true : false">
            <span>
              <i style="font-size: 24px; padding: 0 0 2px 7px" nz-icon nzType="retweet" nzTheme="outline"></i>
            </span>
            <span>DM quy trình điều trị</span>
          </li>
          <li class="sideicon menu-item menu-item-flex" nz-menu-item
            (click)="openNewTab('rules', 'RulesManagerment', 'RulesManagerment')"
            [nzSelected]="currentTabId === 'rules' ? true : false">
            <span>
              <i style="font-size: 24px; padding: 0 0 2px 7px" nz-icon nzType="control" nzTheme="outline"></i>
            </span>
            <span>QT danh sách CDS Rule</span>
          </li>
        </ul>
        <div *ngIf="isUserUpdateIdCategory || isUserUpdateForm || isUserUpdateMedicine || isUseTreatmentProcess"
          class="bder-dashed" [ngStyle]="{ 'margin': isCollapsed ? '0px' : '0px 13px' }"></div>
        <ul class="ul-menu">
          <li class="sideicon menu-item menu-item-flex" nz-menu-item (click)="openModalAboutPmr()">
            <span>
              <img src="assets/image/about-pmr.svg" />
            </span>
            <span>Về PMR</span>
          </li>
        </ul>
      </ng-template>
    </ul>
  </nz-sider>
  <div class="footer" (click)="isCollapsed = !isCollapsed" [ngStyle]="{ 'width': isCollapsed ? '60px' : '240px' }">
    <div [ngStyle]="{ 'display': isCollapsed ? 'flex' : 'none' }">
      <span class="icon-menu">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
          x="0px" y="0px" width="32px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32"
          xml:space="preserve">
          <style type="text/css">
            .st0 {
              fill: #ffffff;
            }
          </style>
          <g>
            <path id="Path_157" class="st0"
              d="M26.16,16.92H5.84c-0.51,0-0.92-0.41-0.92-0.92s0.41-0.92,0.92-0.92h20.31   c0.51,0,0.92,0.41,0.92,0.92S26.67,16.92,26.16,16.92z" />
            <path id="Path_158" class="st0"
              d="M26.16,9.85H5.84c-0.51,0-0.92-0.41-0.92-0.92S5.33,8,5.84,8h20.31c0.51,0,0.92,0.41,0.92,0.92   S26.67,9.85,26.16,9.85z" />
            <path id="Path_159" class="st0"
              d="M26.16,24H5.84c-0.51,0-0.92-0.41-0.92-0.92c0-0.51,0.41-0.92,0.92-0.92h20.31   c0.51,0,0.92,0.41,0.92,0.92C27.08,23.59,26.67,24,26.16,24z" />
          </g>
          <g id="arrow-pointing-to-right_1_" transform="translate(32 32) rotate(180)">
            <path id="Path_5_1_" class="st0"
              d="M65.14,14.81L57.71,4.02c-0.24-0.45-0.8-0.62-1.26-0.38c-0.16,0.09-0.29,0.22-0.38,0.38   c-0.45,0.73-0.45,1.65,0,2.38l5.44,7.91H41.69c-0.64,0-1.16,0.75-1.16,1.69s0.52,1.69,1.16,1.69h19.83l-5.44,7.91   c-0.45,0.73-0.45,1.65,0,2.38c0.24,0.45,0.8,0.62,1.26,0.38c0.16-0.09,0.29-0.22,0.38-0.38l7.42-10.79   C65.59,16.46,65.59,15.54,65.14,14.81z" />
          </g>
        </svg>
      </span>
    </div>
    <div style="border-top: 1px dashed white" [ngStyle]="{ 'display': isCollapsed ? 'none' : 'flex' }">
      <span class="icon-menu">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
          x="0px" y="0px" width="16px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32"
          xml:space="preserve">
          <style type="text/css">
            .st0 {
              fill: #ffffff;
            }
          </style>
          <g id="arrow-pointing-to-right_1_" transform="translate(32 32) rotate(180)">
            <path id="Path_5_1_" class="st0"
              d="M28.14,14.81L20.71,4.02c-0.24-0.45-0.8-0.62-1.26-0.38c-0.16,0.09-0.29,0.22-0.38,0.38   c-0.45,0.73-0.45,1.65,0,2.38l5.44,7.91H4.69c-0.64,0-1.16,0.75-1.16,1.69s0.52,1.69,1.16,1.69h19.83l-5.44,7.91   c-0.45,0.73-0.45,1.65,0,2.38c0.24,0.45,0.8,0.62,1.26,0.38c0.16-0.09,0.29-0.22,0.38-0.38l7.42-10.79   C28.59,16.46,28.59,15.54,28.14,14.81z" />
          </g>
        </svg>
      </span>
      <span class="label-hide"> Ẩn menu </span>
    </div>
  </div>
  <nz-layout [ngStyle]="{ 'margin-left': isCollapsed ? '60px' : '0px' }">
    <nz-content id="mainContent" class="customTableCrollbar" (scroll)="visitDetail ? onScroll($event) : ''">
      <div class="main-header">
        <div class="hder-right">
          <div class="mg-r-15">
            <div class="hder-left">
              <div class="Dropdownlist">
                <div (click)="showTreeGroup()" class="group-name" style="cursor: pointer">
                  <!-- <span style="float: left; margin-right: 5px;">
                  <i nz-icon>
                    <img *ngIf="selectedGroupAvatar===''||selectedGroupAvatar===null" src="assets/image/group.svg" class="image" style="height: 25px;">
                    <img  *ngIf="selectedGroupAvatar!==''&&selectedGroupAvatar!==null" src="{{PathAvatar+'/GroupImg/'+selectedGroupAvatar}}" class="image" style="height: 25px;">
                  </i>
                </span> -->
                  <div class="Show txt-ellipsis" title="{{ selectedGroupName }}" *ngIf="selectedGroupName !== null">
                    {{ selectedGroupName }}
                  </div>
                  <span>
                    <i style="margin-top: 12px" nz-icon nzType="caret-down" nzTheme="outline"></i>
                  </span>
                </div>
                <div *ngIf="isShowDDLGroup" class="menu-doctor-group thin-scroll">
                  <div class="boxSearch">
                    <nz-input-group [nzSuffix]="suffixIconSearch">
                      <input nz-input class="txt_Search" #txtSearchTreeGroup type="text" autocomplete="off"
                        placeholder="Tìm nhóm/danh mục nhóm" (keyup)="searchGroup($event)" />
                    </nz-input-group>
                    <ng-template #suffixIconSearch>
                      <i nz-icon nzType="search"></i>
                    </ng-template>

                    <!-- <span class="Search-Icon"></span> -->
                  </div>
                  <ul nz-menu [nzTheme]="'light'" [nzMode]="'vertical'" class="thin-scroll">
                    <app-recursive-menu [isSearch]="txtSearchTreeGroup.length > 0" [menus]="list"
                      [selectedId]="selectedGroupId" (newItemEvent)="selectGroup($event)">
                    </app-recursive-menu>
                  </ul>
                  <div class="menu-buton">
                    <!-- Chỉ admin hệ thống mới đc thêm -->
                    <a *ngIf="isSystemAdmin" (click)="openModelAddGroup()" title="Thêm nhóm"><img
                        src="assets/image/addGroup.svg" class="image" style="float: left" /></a>
                    <!-- <a *ngIf="myIsAdminGroup" style="margin-left: 10px;" (click)="openModelSettingGroup()"><i style="font-size: 105px;color:var(--dp-primary-1)" nz-icon nzType="setting" nzTheme="outline"></i></a> -->
                    <a (click)="openModelManagerGroup()" title="Quản trị danh mục nhóm làm việc"><img
                        src="assets/image/menu.svg" class="image" style="float: right" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul class="divright">
            <!-- <li class="normal-li" style="margin-right:24px"><b style="font-size: 19px;">PACS</b></li>
          <li class="normal-li" style="margin-right:24px"><b style="font-size: 19px;">DPS</b></li> -->
            <li *ngIf="userCanViewStatistic" class="normal-li" title="Xem thống kê toàn hệ thống"
              (click)="openStatistic()">
              <svg id="statistic" enable-background="new 0 0 512 512" height="20" viewBox="0 0 512 512" width="20"
                xmlns="http://www.w3.org/2000/svg" style="fill: var(--dp-primary-1)">
                <g>
                  <path d="m512 482h-30v-302h-91v302h-30v-182h-90v182h-30v-242h-90v242h-30v-152h-91v152h-30v30h512z" />
                  <path
                    d="m512 120v-120h-121v30h69.789l-144.789 143.789-120-120-191.605 190.606 21.21 21.21 170.395-169.394 120 120 166-165v68.789z" />
                </g>
              </svg>
            </li>
            <li class="normal-li" (click)="openNewTab('tabsearch', 'Tìm kiếm', '')" title="Tìm kiếm nâng cao">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30"
                  height="30" viewBox="0 0 32 32">
                  <defs>
                    <clipPath id="clip-Artboard_2">
                      <rect width="32" height="32" />
                    </clipPath>
                  </defs>
                  <g id="Artboard_2" data-name="Artboard – 2" clip-path="url(#clip-Artboard_2)">
                    <rect width="32" height="32" fill="#f0f4fc" />
                    <g id="search" transform="translate(7 7)">
                      <path id="search-2" data-name="search"
                        d="M19.146,17.4,14.6,12.671a7.71,7.71,0,1,0-5.9,2.754,7.63,7.63,0,0,0,4.419-1.4L17.7,18.794a1.006,1.006,0,1,0,1.45-1.395ZM8.7,2.012A5.7,5.7,0,1,1,3,7.713,5.707,5.707,0,0,1,8.7,2.012Z"
                        transform="translate(-0.984)" fill="var(--dp-primary-1)" />
                    </g>
                  </g>
                </svg>
              </span>
            </li>
            <!-- <div class="right" style="margin-right: 28px; margin-top: 5px;">
              <a *ngIf="isAdminGroup" style="margin-left: -4px" (click)="openModelSettingGroup()">
                <i class="icon-dashbord success" style="color: var(--dp-primary-1); font-size: 20px;" nz-icon
                  size="last" nzType="setting" nzIconfont="20" nzTheme="outline"></i></a>
            </div> -->
            <!-- <li class="normal-li" style="margin-right:15px; color:#439666">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30"
                viewBox="0 0 32 32">
                <defs>
                  <clipPath id="clip-Artboard_4">
                    <rect width="32" height="32" />
                  </clipPath>
                </defs>
                <g id="Artboard_4" data-name="Artboard – 4" clip-path="url(#clip-Artboard_4)">
                  <rect width="32" height="32" fill="#fff" />
                  <path id="chat_2_" data-name="chat (2)"
                    d="M9.822,31.344C4.406,31.344,0,34.924,0,39.324a7.352,7.352,0,0,0,3.158,5.864A4.892,4.892,0,0,1,.467,47.323a.614.614,0,0,0,.059,1.2,7.6,7.6,0,0,0,1.008.056,13.165,13.165,0,0,0,5.984-1.5,11.653,11.653,0,0,0,2.3.225c5.416,0,9.822-3.58,9.822-7.98S15.238,31.344,9.822,31.344Z"
                    transform="translate(7 -23.344)" fill="var(--dp-header-text-color-2)" />
                </g>
              </svg>
            </span>
          </li> -->
            <li class="normal-li" nz-popover [nzPopoverTitle]="titleNotify" link nzPopoverPlacement="bottom"
              nzPopoverTrigger="null" [(nzPopoverVisible)]="isVisibleNotifications" [nzPopoverContent]="contentTemplate"
              nzPopoverOverlayClassName="notification-popup" (click)="toggleNotification()">
              <ng-template #titleNotify>
                <div class="notifi-title">
                  <div>
                    Bạn có <span>{{ numOfUnreadNotifi }}</span> thông báo chưa đọc
                  </div>
                  <a (click)="viewAllNoti()">Xem tất cả</a>
                </div>
              </ng-template>
              <ng-template #contentTemplate>
                <ul class="sizeNews customTableCrollbar">
                  <li class="notifi-item" *ngFor="let item of listNotification">
                    <div class="user-avatar">
                      <img *ngIf="item.avatar === '' || item.avatar === null" style="height: 40px"
                        src="assets/image/group.svg" alt="" />
                      <img *ngIf="item.avatar !== ''" style="height: 40px"
                        src="{{ PathAvatar + '/Avatar/' + item.avatar }}" alt="" />
                    </div>
                    <div class="item-content" *ngIf="item.type !== 3 && item.type !== 4" (click)="goFromNotify(item)"
                      style="cursor: pointer">
                      <div class="line-clamp" [innerHtml]="item.contentHtml"></div>
                      <div class="time-style">{{ item.createdDate }}</div>
                    </div>
                    <div class="item-content" *ngIf="item.type === 3 || item.type === 4" style="cursor: pointer">
                      <div class="line-clamp" [innerHtml]="item.contentHtml"></div>
                      <div class="time-style">{{ item.createdDate }}</div>
                      <div *ngIf="item.hasButton === 1">
                        <button nz-button class="btn-x-small" style="margin-right: 20px" *ngIf="item.type === 3"
                          (click)="acceptJoinGroup(item.id, false, null)" nz-button nzSize="small">
                          Từ chối
                        </button>
                        <button nz-button class="btn-primary-small" *ngIf="item.type === 3"
                          (click)="acceptJoinGroup(item.id, true, null)" nz-button nzSize="small">
                          Đồng ý
                        </button>
                        <button nz-button class="btn-x-small" style="margin-right: 20px" *ngIf="item.type === 4"
                          (click)="acceptJoin(item.id, false, null)" nz-button nzSize="small">
                          Từ chối
                        </button>
                        <button nz-button class="btn-primary-small" *ngIf="item.type === 4"
                          (click)="acceptJoin(item.id, true, null)" nz-button nzSize="small">
                          Đồng ý
                        </button>
                      </div>
                      <div *ngIf="item.hasButton === 0" [innerHtml]="item.actionHtml">
                        <!-- <span>{{item.actionHtml}}</span> -->
                      </div>
                    </div>
                    <div class="item-status">
                      <div *ngIf="!item.viewed" (click)="readNotifi(item.id)" class="view unViewed"></div>
                      <div *ngIf="item.viewed" class="view viewed"></div>
                    </div>
                  </li>
                </ul>
              </ng-template>
              <span class="notify">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30"
                  height="30" viewBox="0 0 32 32">
                  <defs>
                    <clipPath id="clip-Artboard_6">
                      <rect width="32" height="32" />
                    </clipPath>
                  </defs>
                  <g id="Artboard_6" data-name="Artboard – 6" clip-path="url(#clip-Artboard_6)">
                    <rect width="32" height="32" fill="#f0f4fc" />
                    <g id="notification" transform="translate(8.311 6)">
                      <path id="Path_159" data-name="Path 159" d="M184.9,465.044a3.181,3.181,0,0,0,5.823,0Z"
                        transform="translate(-179.471 -445.886)" fill="var(--dp-header-text-color-2)" />
                      <path id="Path_160" data-name="Path 160"
                        d="M198.72,2.012a7.155,7.155,0,0,1,2.443.428V2.346A2.348,2.348,0,0,0,198.817,0h-.194a2.348,2.348,0,0,0-2.346,2.346v.093A7.169,7.169,0,0,1,198.72,2.012Z"
                        transform="translate(-190.375 0)" fill="var(--dp-header-text-color-2)" />
                      <path id="Path_161" data-name="Path 161"
                        d="M69.069,93.51H53.648a.629.629,0,0,1-.62-.482.6.6,0,0,1,.33-.682,3.272,3.272,0,0,0,1-1.354,15.642,15.642,0,0,0,1.038-6.19,5.966,5.966,0,0,1,11.932-.024c0,.008,0,.016,0,.024a15.642,15.642,0,0,0,1.038,6.19,3.272,3.272,0,0,0,1,1.354.6.6,0,0,1,.33.682A.629.629,0,0,1,69.069,93.51Zm.3-1.16h0Z"
                        transform="translate(-53.013 -75.588)" fill="var(--dp-header-text-color-2)" />
                    </g>
                  </g>
                </svg>
                <span *ngIf="numOfNewNotifi > 0">
                  <span class="notification-number">{{ numOfNewNotifi }}</span>
                </span>
              </span>
            </li>
          </ul>
          <div class="dropdown" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
            <div class="li_toggle" style="margin-right: 11px">
              <span id="username">{{ username }} </span><i nz-icon nzType="down" nzTheme="outline"></i>
            </div>
            <img src="https://image.freepik.com/free-vector/doctor-icon-avatar-white_136162-58.jpg"
              class="avatar-doctor" />
          </div>
        </div>
      </div>
      <div id="myDropdown" class="dropdown-content">
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <!-- <li nz-menu-item>
              <a href="#" class="response-li">PACS</a>
            </li>
            <li nz-menu-item>
              <a href="#" class="response-li">DPS</a>
            </li>
            <li nz-menu-item>
              <a class="response-li" (click)="openNewTab('tabsearch', 'Tìm kiếm', '')">Tìm kiếm</a>
            </li>
            <li nz-menu-item >
              <a href="#" class="response-li">Tin nhắn</a>
            </li>
            <li nz-menu-item>
              <a href="#" class="response-li">Thông báo(n)</a>
            </li> -->
            <li nz-menu-item>
              <a target="_blank" href="/admin">&nbsp;Quản trị hệ thống</a>
            </li>
            <li nz-menu-item>
              <a (click)="openNewTab('tabinfo', 'Hiển thị', 'userProfile')">&nbsp;Thông tin tài khoản</a>
            </li>
            <li nz-menu-item>
              <a (click)="logout()">&nbsp;Đăng xuất</a>
            </li>
            
          </ul>
        </nz-dropdown-menu>
      </div>
      <nz-tabset class="tabset-worklist" [nzType]="'card'" [(nzSelectedIndex)]="indexTab">
        <nz-tab class="tab-worklist" *ngFor="let tab of tabs" [nzTitle]="titleTemplate" (nzClick)="onTabClick(tab.id)">
          <ng-template #titleTemplate>
            <div class="fs-16" *ngIf="tab.id == 'ListPatient'">
              Danh sách bệnh nhân
              <span class="tab-action" *ngIf="!isCovidGroup && !listPatientIsDefaultTab">
                <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
              </span>
            </div>
            <div class="fs-16" *ngIf="tab.id == listVisitComponentName">
              Danh sách ca khám
              <span class="tab-action" *ngIf="isCovidGroup || listPatientIsDefaultTab">
                <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
              </span>
            </div>
            <div class="fs-16" *ngIf="tab.id == 'listVisitCalendar'">
              Visit calendar
              <span class="tab-action">
                <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
              </span>
            </div>
            <div appMouseMiddleClick (callbackFunction)="closeTab(tab)">
              <div class="fs-16" *ngIf="tab.id == 'chat'">
                Tin nhắn
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.id == 'tabsearch'">
                Tìm kiếm
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.id == 'listAppointment'">
                Danh sách hẹn khám
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.id == 'listReminder'">
                Lịch công việc
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.id == 'medicalOrder'">
                Danh sách y lệnh
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.id == 'subclinicalorderGroup'">
                Danh sách nhóm chỉ định
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'VisitDetail'">
                {{ tab.name }}
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'PatientInfo' || tab.tabType == 'PatientInfoEdit'">
                Hồ sơ y tế - {{ tab.name }}
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'ObservationType'">
                Quản lý mục dữ liệu
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'MedicineManagement'">
                Quản trị DM thuốc
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'ExaminationServiceManagement'">
                Quản lý dịch vụ khám
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'TreatmentProcess'">
                Danh mục quy trình điều trị
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'RulesManagerment'">
                Quản trị danh sách CDS Rule
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'FormMngt'">
                Quản lý biểu mẫu
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'Record'">
                Phiếu {{ tab.name }}
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'GroupDashboard'">
                Thông tin hoạt động
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'userProfile'">
                Thông tin tài khoản
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'CovidReceive'">
                Danh sách tiếp nhận khách hàng
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'patient-group-management'">
                Danh sách phòng BN
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'report-group'">
                Thống kê
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
              <div class="fs-16" *ngIf="tab.tabType == 'resend-sub-clinical-visit'">
                Đẩy lại chỉ định PACS
                <span class="tab-action">
                  <i nz-icon nzType="reload" (click)="reloadTab(tab)" class="ant-tabs-reload"></i>
                  <i nz-icon nzType="close" (click)="closeTab(tab)" class="ant-tabs-close-x"></i>
                </span>
              </div>
            </div>
          </ng-template>
          <ng-container *ngIf="tab.reload">
            <div class="reload-tab">
              <nz-spin nzSimple></nz-spin>
            </div>
          </ng-container>
          <ng-container *ngIf="!tab.reload">
            <div *ngIf="tab.id == 'listReminder'" class="inner-content">
              <app-list-reminder></app-list-reminder>
            </div>
            <div *ngIf="tab.id == 'subclinicalorderGroup'" class="inner-content">
              <app-subclinicalorder-group></app-subclinicalorder-group>
            </div>
            <div *ngIf="tab.id == 'medicalOrder'" class="inner-content">
              <app-medical-order></app-medical-order>
            </div>
            <!-- <div *ngIf="(tab.id == 'ListVisit')" class="inner-content">
              <app-listVisit [reloadListVisit]="reloadListVisit"></app-listVisit>
            </div>
            <div *ngIf="(tab.id == 'ListVisitNDetail')" class="inner-content">
              <app-list-n-detail-visit [reloadListVisit]="reloadListVisit"></app-list-n-detail-visit>
            </div> -->
            <div *ngIf="tab.id == listVisitComponentName" class="inner-content">
              <app-list-visit-container></app-list-visit-container>
            </div>
            <div *ngIf="tab.id == 'tabsearch'" class="inner-content-detail-visit" style="padding: 20px 15px">
              <app-search-result [tagNameSearch]="tab.tabType"></app-search-result>
            </div>
            <div *ngIf="tab.id == 'ListPatient'" class="inner-content">
              <app-list-patient></app-list-patient>
            </div>
            <div *ngIf="tab.id == 'listVisitCalendar'" class="inner-content">
              <app-list-visit-calendar></app-list-visit-calendar>
            </div>
            <div *ngIf="tab.id == 'listAppointment'" class="inner-content-detail-visit">
              <app-list-appointment></app-list-appointment>
            </div>
            <div *ngIf="tab.tabType == 'VisitDetail'" class="inner-content-detail-visit">
              <app-visit-detail [visitId]="tab.id" [visitPrivacy]="1"></app-visit-detail>
            </div>
            <div *ngIf="tab.tabType == 'PatientInfo'">
              <!-- tab.patientId trong TH này là visitId -->
              <app-patient-info [patientId]="tab.id" [visitId]="tab.patientId"
                class="inner-content-detail-visit"></app-patient-info>
            </div>
            <div *ngIf="tab.tabType == 'PatientInfoEdit'">
              <!-- tab.patientId trong TH này là visitId -->
              <app-patient-info [inVisit]="false" [patientId]="tab.id" [visitId]="tab.patientId"></app-patient-info>
            </div>
            <div *ngIf="tab.id == 'chat'" class="inner-content-detail-visit">
              <app-chat-component (sendFileToMasterNoteEvent)="reloadTabById($event)"></app-chat-component>
            </div>
            <div *ngIf="tab.tabType == 'ObservationType'" class="inner-content-detail-visit">
              <app-observation-type></app-observation-type>
            </div>
            <div *ngIf="tab.tabType == 'MedicineManagement'" class="inner-content-detail-visit">
              <app-medicine-management></app-medicine-management>
            </div>
            <div *ngIf="tab.tabType == 'ExaminationServiceManagement'" class="inner-content-detail-visit">
              <app-examination-service></app-examination-service>
            </div>
            <div *ngIf="tab.tabType == 'TreatmentProcess'" class="inner-content-detail-visit">
              <app-treatment-process></app-treatment-process>
            </div>
            <div *ngIf="tab.tabType == 'RulesManagerment'" class="inner-content-detail-visit">
              <app-rules-managerment></app-rules-managerment>
            </div>
            <div *ngIf="tab.tabType == 'FormMngt'" class="inner-content-detail-visit">
              <app-form-management-component></app-form-management-component>
            </div>
            <div *ngIf="tab.tabType == 'GroupDashboard'" class="inner-content-detail-visit">
              <app-group-dashboard [isAdminGroup]="myIsAdminGroup"></app-group-dashboard>
            </div>
            <div *ngIf="tab.tabType == 'CovidReceive'" class="inner-content-detail-visit">
              <app-covid-receive-list></app-covid-receive-list>
            </div>
            <div *ngIf="tab.tabType == 'Record'" class="inner-content-detail-visit">
              <app-edit-form-static [adminPage]="false" [isVisible]="true" [id]="tab.id"></app-edit-form-static>
            </div>
            <div *ngIf="tab.tabType == 'userProfile'" class="inner-content-detail-visit">
              <app-user-profile></app-user-profile>
            </div>
            <div *ngIf="tab.tabType == 'patient-group-management'" class="inner-content">
              <app-patient-room></app-patient-room>
            </div>
            <div *ngIf="tab.tabType == 'report-group'" class="inner-content">
              <app-report></app-report>
            </div>
            <div *ngIf="tab.tabType == 'resend-sub-clinical-visit'" class="inner-content">
              <app-resend-sub-clinical-visit></app-resend-sub-clinical-visit>
            </div>
          </ng-container>
        </nz-tab>
      </nz-tabset>
    </nz-content>
  </nz-layout>
</nz-layout>
<app-lock-screen></app-lock-screen>
<app-add-patient #addPatient (duplicatePatient)="duplicatePatientEvent($event)" (addPatient)="addPatientEvent($event)">
</app-add-patient>
<app-add-visit #addVisit [patient]="patient" (selectPatient)="selectPatientEvent($event)"
  (refreshListVisit)="refreshDataListVisit()"></app-add-visit>
<app-duplicate-patient #duplicatePatient [duplicatePatients]="listDuplicatePatient" [editedPatient]="sendEditedPatient"
  (selectPatient)="selectPatientEvent($event)"></app-duplicate-patient>
<app-add-group (closeModal)="closeAddGroup($event)" [isVisible]="isVisibleAddGroup" (addGroup)="addGroupEvent($event)">
</app-add-group>
<!-- <app-config-group (closeModal)="closeSettingGroup($event)" [isVisible]="isVisibleSettingGroup" (addGroup)="settingGroupEvent($event)"></app-config-group> -->
<app-tree-manager-group [menus]="list" (closeModal)="closeManagerGroup($event)" [isVisible]="isVisibleManagerGroup"
  [curGroupId]="selectedGroupId">
</app-tree-manager-group>
<!--<app-invite-member-group (closeModal)="closeAddMemberGroup($event)" [newGroupId]="newGroupId"
  [isVisible]="isVisibleAddMemberGroup"></app-invite-member-group>-->
<app-conference-room (closeModal)="closeModalRoom()" [groupId]="selectedGroupId" [isVisible]="isVisibleMemberRoom">
</app-conference-room>

<app-appointment-add #addAppoinment [patients]="patient" (refreshAppointment)="refreshDataAppointment()">
</app-appointment-add>
<app-notifications (closeModal)="closeViewAllNoti($event)" [isVisible]="showAllNotifications"></app-notifications>

<app-about-pmr [isVisible]="isVisibleAboutPmr" (closeModal)="closeAboutPmr($event)"></app-about-pmr>

<ng-template #customHtml let-notification let-text="text">
  <div class="" role="alert">
    <div class="alert-text">{{ msg.notification.title }}</div>
    <div *ngIf="msg.data.type !== 'InviteRoomTimerStart'" class="alert-text" style="margin-bottom: 10px"
      [innerHtml]="msg.notification.body"></div>
    <div *ngIf="msg.data.type === 'InviteRoomTimerStart' && msg.data.link != undefined && msg.data.link.length > 0"
      class="alert-text" style="margin-bottom: 10px"
      [innerHtml]="msg.notification.body + setLinkHoiChan(msg.data.link)"></div>
    <button class="btn-x-small" style="margin-right: 10px" *ngIf="msg.data.type === 'InviteRoom'"
      (click)="acceptJoin(null, false, notification)" nz-button nzSize="small">
      Từ chối
    </button>
    <button class="btn-primary-small" *ngIf="msg.data.type === 'InviteRoom'"
      (click)="acceptJoin(null, true, notification)" nz-button nzSize="small">
      Đồng ý
    </button>
    <button class="btn-x-small" style="margin-right: 10px" *ngIf="msg.data.type === 'InviteGroup'"
      (click)="acceptJoinGroup(null, false, notification)" nz-button nzSize="small">
      Từ chối
    </button>
    <button class="btn-primary-small" *ngIf="msg.data.type === 'InviteGroup'"
      (click)="acceptJoinGroup(null, true, notification)" nz-button nzSize="small">
      Đồng ý
    </button>
  </div>
</ng-template>

<ng-template #reminderTemplate let-notification let-text="text">
  <div class="" role="alert">
    <div class="alert-text">
      <h5>{{ msg.notification.title }}</h5>
    </div>
    <div class="alert-text">
      <b>{{ msg.notification.subTitle }}</b>
    </div>
    <div class="alert-text" style="margin-bottom: 40px" [innerHtml]="msg.notification.body"></div>
    <div style="text-align: right">
      <button *ngIf="!isShowAddTime" class="btn-primary-small" style="margin-right: 10px" (click)="openRemindLater()"
        nz-button nzSize="small">
        Báo lại sau
      </button>
      <button *ngIf="isShowAddTime" class="btn-primary-small" style="margin-right: 10px" (click)="reminderLater()"
        nz-button nzSize="small">
        Đặt lại
      </button>
      <button class="btn-primary-small" (click)="closeNotify()" nz-button nzSize="small">Ok, đã thực hiện</button>
      <div *ngIf="isShowAddTime" class="mg-t-10">
        <form nz-form [formGroup]="timeForm" class="time-selection">
          <div nz-row [nzGutter]="24">
            <div nz-col class="gutter-row" [nzSpan]="4" nzOffset="8">Sau:</div>
            <div nz-col class="gutter-row" [nzSpan]="5">
              <nz-form-item>
                <nz-form-control>
                  <nz-input-group nzSize="small">
                    <input nz-input formControlName="time" (ngModelChange)="onChange($event)" [nzAutocomplete]="auto" />
                  </nz-input-group>
                </nz-form-control>
                <nz-autocomplete #auto>
                  <nz-auto-option *ngFor="let option of timeOptions" [nzLabel]="option.key" [nzValue]="option.value">
                    {{ option.key }}
                  </nz-auto-option>
                </nz-autocomplete>
              </nz-form-item>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="7">
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzHasFeedback formControlName="unit" (ngModelChange)="timeUnitChange($event)"
                    nzSize="small">
                    <nz-option nzValue="minute" nzLabel="Phút"></nz-option>
                    <nz-option nzValue="hour" nzLabel="Giờ"></nz-option>
                    <nz-option nzValue="day" nzLabel="Ngày"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
        <!-- <div nz-col class="gutter-row" [nzSpan]="5">
          <nz-input-group nzSize="small">
            <input placeholder="Thời gian" nz-input [(ngModel)]="timeSelectOption" (ngModelChange)="onChange($event)"
              [nzAutocomplete]="auto" />
          </nz-input-group>
          <nz-autocomplete #auto>
            <nz-auto-option *ngFor="let option of timeOptions" [nzLabel]="option.key" [nzValue]="option.value">
              {{ option.key }}
            </nz-auto-option>
          </nz-autocomplete>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="6">
          <nz-select [(ngModel)]="timeUnitOption" (ngModelChange)="timeUnitChange($event)" nzSize="small">
            <nz-option nzValue="minute" nzLabel="Phút"></nz-option>
            <nz-option nzValue="hour" nzLabel="Giờ"></nz-option>
            <nz-option nzValue="day" nzLabel="Ngày"></nz-option>
          </nz-select>
        </div> -->
      </div>
    </div>
  </div>
</ng-template>
<!--
<ng-template #cdsRuleTemplate let-notification let-text="text">
  <div class="" role="alert">
    <div class="alert-text">
      <h5>{{ msg.notification.title }}</h5>
    </div>
    <div class="alert-text">
      <b>{{ msg.notification.subTitle }}</b>
    </div>
    <div class="alert-text" style="margin-bottom: 40px" [innerHtml]="msg.notification.body"></div>
    <div style="text-align: right">
      <button class="btn-primary-small" style="margin-right: 10px" (click)="openRemindLater()" nz-button nzSize="small">
        Báo lại sau
      </button>
      <button class="btn-primary-small" style="margin-right: 10px" (click)="reminderLater()" nz-button nzSize="small">
        Đặt lại
      </button>

    </div>
  </div>
</ng-template> -->

<app-add-visit-update [patient]="patient" (refreshListVisit)="refreshDataListVisit()"
  (duplicatePatient)="duplicatePatientEvent($event)"></app-add-visit-update>
<!-- <app-config-group (closeModal)="closeSettingGroup($event)" [isVisible]="isVisibleSettingGroup"
  (addGroup)="settingGroupEvent($event)"></app-config-group> -->
