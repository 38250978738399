<div *ngIf="isVisible">
  <label>Common Info: </label>
  <nz-select
    nzShowSearch
    nzAllowClear
    nzPlaceHolder="Chọn Observation"
    [(ngModel)]="_commonInfoId"
    style="width: 100%"
    class="mg-bt-5"
    (ngModelChange)="commonInfoChange()">
    <nz-option
      *ngFor="let item of commonInfoItems"
      [nzValue]="item.id"
      [nzLabel]="item.name">
    </nz-option>
  </nz-select>
  <br />
  <br />
  <label>Reference Observation: </label>
  <nz-select
    nzShowSearch
    nzAllowClear
    nzPlaceHolder="Tìm kiếm..."
    [(ngModel)]="_referId"
    style="width: 100%"
    class="mg-bt-5"
    (ngModelChange)="updateReferId()">
    <nz-option
      *ngFor="let item of lstObservationType"
      [nzValue]="item.id"
      [nzLabel]="item.name">
    </nz-option>
  </nz-select>
</div>
