import { TabCDHAModule } from './visit-detail/tab-CDHA/tab-CDHA.module';
import { CustomColumnModule } from './custom-column-module/custom-column.module';
import { MobileNotifyComponent } from './mobile-layout/mobile-notify/mobile-notify.component';
import { ChatItemModule } from './chat-item/chat-item.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListVisitComponent } from './list-visit/list-visit.component';
//import { VisitDetailComponent } from './visit-detail/visit-detail.component';
import { LayoutRoutingModule } from './worklist-routing.module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { SharedModule } from '../../share/share.module';
import { ListVisitService } from '../../services/listVisit-service';
import { NotificationService } from '../../services/notification.service';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ModalModule } from '../modal/modal.module';
import { SearchResultComponent } from '../worklist/search-result/search-result.component';
import { RosService } from 'src/app/services/ros-service';
import { SubClinicalVisitService } from 'src/app/services/subcliniccal-service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RecordVisitService } from 'src/app/services/record-visit-service';
import { PatientInfoComponent } from './patient-info/patient-info.component';
import { RosAndSubclinicComponent } from './visit-detail/ros-and-subclinic/ros-and-subclinic.component';
import { ObservationService } from 'src/app/services/observation.service';
import { ObservationTypeService } from 'src/app/services/obserType-service';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { VitalSignsTabComponent } from './visit-detail/vital-signs-tab/vital-signs-tab.component';
import { ChartComponent } from './visit-detail/vital-signs-tab/chart/chart.component';
import { DirectiveFocusInputDirective } from 'src/app/models/form-model/directive-focus-input.directive';
import { ShareVisitComponent } from './visit-detail/share-visit/share-visit.component';
import { NzListModule } from 'ng-zorro-antd/list';
import { ShareVisitService } from 'src/app/services/share-visit.service';
import { ChatComponentComponent } from './chat-component/chat-component.component';
import { HeaderComponent } from './visit-detail/header/header.component';
import { TabVisitInfoComponent } from './visit-detail/tab-visit-info/tab-visit-info.component';
import { ListRecordComponent } from './visit-detail/list-record/list-record.component';
//import { ObservationTypeComponent } from './observation-type1/observation-type.component';
import { FormManagementComponent } from './form-management-component/form-management-component.component';
import { ListAppointmentComponent } from './list-appointment/list-appointment.component';
import { AngularSplitModule } from 'angular-split';
import { VisitSummaryComponent } from './visit-summary/visit-summary.component';
import { NgxPrintModule } from 'ngx-print';
import { ObservationTypeModule } from '../admin/admin-pages/observation-type/observation-type.module';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NzResizableModule } from 'ng-zorro-antd/resizable';
import { ShareVisitUpdateComponent } from './visit-detail/share-visit-update/share-visit-update.component';
import { QrShareLinkComponent } from './visit-detail/qr-share-link/qr-share-link.component';
import { EditFormStaticModule } from '../admin/admin-pages/edit-form-static/edit-form-static.module';
import { ListPatientComponent } from './list-patient/list-patient.component';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AngularEmojisModule } from 'angular-emojis';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { FilterItemModule } from '../filter-item/filter-item.module';
import { FamilyMemberComponent } from './visit-detail/family-member/family-member.component';
import { PatientMobileListComponent } from './mobile-layout/patient-mobile-list/patient-mobile-list.component';
import { VisitDetailMobileComponent } from './mobile-layout/visit-detail-mobile/visit-detail-mobile.component';
import { VisitInfoMobileComponent } from './mobile-layout/visit-info-mobile/visit-info-mobile.component';
import { MatIconModule } from '@angular/material/icon';
import { MedicineManagementModule } from '../admin/admin-pages/medicine-management/medicine-management.module';
//import { ListReminderComponent } from './list-reminder/list-reminder.component';
import { MedicalInstVsModule } from './medical-inst-vs/medical-inst-vs.module';
import { PatientReminderModule } from './patient-reminder/patient-reminder.module';
import { XetNghiemModule } from '../modal/xet-nghiem/xet-nghiem.module';
import { VitalSignsTabModule } from './visit-detail/vital-signs-tab/vital-signs-tab.module';
import { PrescriptionModule } from '../prescription/prescription.module';
import { MedicalOrderComponent } from './medical-order/medical-order.component';
import { ListImageGeneralModule } from './visit-detail/list-image-general/list-image-general.module';
import { SubclinicalorderGroupComponent } from './subclinicalorder-group/subclinicalorder-group.component';
import { VisitHistoryModule } from './visit-history/visit-history.module';
import { ListVisitContainerComponent } from '../list-visit-container/list-visit-container.component';
import { ListNDetailVisitModule } from '../list-n-detail-visit/list-n-detail-visit.module';
import { VisitLogModule } from './visit-detail/visit-log/visit-log.module';
import { SubclinicalVisitModule } from '../subclinical-visit/subclinical-visit.module';
import { ListReminderModule } from './list-reminder/list-reminder.module';
import { PatientFamilyHistoryComponent } from './patient-info/patient-family-history/patient-family-history.component';
import { ListVisitCalendarModule } from './list-visit-calendar/list-visit-calendar.module';
import { ListVisitCalendarComponent } from './list-visit-calendar/list-visit-calendar.component';
import { RulesManagermentModule } from '../admin/admin-pages/rules-managerment/rules-managerment.module';
import { ResendSubClinicalVisitComponent } from './resend-sub-clinical-visit/resend-sub-clinical-visit.component';
import { AddVitalASignModule } from './visit-detail/add-vital-asign/add-vital-asign.module';

@NgModule({
  imports: [
    LayoutRoutingModule,
    NzTableModule,
    NzTabsModule,
    SharedModule,
    NzModalModule,
    ModalModule,
    CKEditorModule,
    NgSelectModule,
    FormsModule,
    NzListModule,
    NgxPrintModule,
    // NewEditFormModule,
    EditFormStaticModule,
    ObservationTypeModule,
    RulesManagermentModule,
    MedicineManagementModule,
    AngularSplitModule,
    NzResizableModule,
    ChatItemModule,
    // PdfViewerModule,
    NzAlertModule,
    InfiniteScrollModule,
    AngularEmojisModule,
    PickerModule,
    FilterItemModule,
    MatIconModule,
    CustomColumnModule,
    MedicalInstVsModule,
    PatientReminderModule,
    XetNghiemModule,
    TabCDHAModule,
    VitalSignsTabModule,
    PrescriptionModule,
    ListImageGeneralModule,
    VisitHistoryModule,
    ListNDetailVisitModule,
    VisitLogModule,
    SubclinicalVisitModule,
    ListReminderModule,
    ListVisitCalendarModule,
    AddVitalASignModule
  ],
  exports: [
    SearchResultComponent,
    CKEditorModule,
    PatientInfoComponent,
    RosAndSubclinicComponent,
    ShareVisitComponent,
    ShareVisitUpdateComponent,
    QrShareLinkComponent,
    ChatComponentComponent,
    HeaderComponent,
    TabVisitInfoComponent,
    ListRecordComponent,
    //ObservationTypeComponent,
    FormManagementComponent,
    ListAppointmentComponent,
    VisitSummaryComponent,
    ListPatientComponent,
    FamilyMemberComponent,
    ListVisitComponent,
    //ListReminderComponent,
    MedicalOrderComponent,
    SubclinicalorderGroupComponent,
    ListVisitContainerComponent,
    ListVisitCalendarComponent,
    ResendSubClinicalVisitComponent,
    
  ], //để sử dụng ở layout module

  declarations: [
    ListVisitComponent,
    SearchResultComponent,
    PatientInfoComponent,
    RosAndSubclinicComponent,
    VisitSummaryComponent,
    DirectiveFocusInputDirective,
    ShareVisitComponent,
    ShareVisitUpdateComponent,
    QrShareLinkComponent,
    ChatComponentComponent,
    HeaderComponent,
    TabVisitInfoComponent,
    ListRecordComponent,
    //ObservationTypeComponent,
    FormManagementComponent,
    ListAppointmentComponent,
    ListPatientComponent,
    FamilyMemberComponent,
    PatientMobileListComponent,
    VisitDetailMobileComponent,
    VisitInfoMobileComponent,
    MobileNotifyComponent,
    //ListReminderComponent,
    MedicalOrderComponent,
    SubclinicalorderGroupComponent,
    ListVisitContainerComponent,
    PatientFamilyHistoryComponent,
    ResendSubClinicalVisitComponent
  ],
  providers: [
    ListVisitService,
    NotificationService,
    RosService,
    SubClinicalVisitService,
    RecordVisitService,
    ShareVisitService,
    ObservationService,
    ObservationTypeService,
  ],
  bootstrap: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WorklistModule {}
