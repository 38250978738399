<div class="title-wrapper">
  <div class="d-flex justify-content-between mg-bt-15">
    <div class="d-flex">
      <div style="width: 200px" class="mg-r-10 msc-group-control width-obser">
        <input placeholder="Tên nhóm" [(ngModel)]="dataSearch.orderGroupName" #keywordPatientSearch
          (keydown.enter)="searchSubClinicalOrder(dataSearch)" minLength="2" nz-input />
      </div>
      <button nz-button class="pointer mg-r-10" (click)="searchSubClinicalOrder(dataSearch)">
        <i nz-icon nzType="search" nzTheme="outline"></i>
      </button>
      <button nz-button class="pointer mg-r-10" (click)="reloadDataTable()">
        <i>
          <img src="assets/image/DPicon/icon-close.svg" />
        </i>
      </button>
    </div>
    <div>
      <button nz-button class="btn-primary-small" nzType="success" (click)="showModal('add')">
        <i nz-icon nzType="plus" nzTheme="outline"></i>
        Thêm mới
      </button>
    </div>
  </div>

  <nz-modal class="modal-admin" [(nzVisible)]="isVisible" [nzTitle]="subClinicalOrderGroupModalTitle" nzDraggable="true"
    nzMaskClosable="false" (nzClosable)="(true)" (nzOnCancel)="handleCancel()">
    <div class="msc-admin-row">
      <div class="msc-group-control width-obser">
        <label>Tên nhóm<span class="red-dot"> *</span></label>
        <input [disabled]="isViewOrderGroupModal" [(ngModel)]="dataForm.orderGroupName" #keywordPatient minLength="2"
          nz-input />
      </div>
    </div>
    <div class="msc-admin-row">
      <div class="msc-group-control width-obser">
        <label>Mô tả</label>
        <textarea [disabled]="isViewOrderGroupModal" [(ngModel)]="dataForm.description"
          style="height: 60px">{{ dataForm.description }}</textarea>
      </div>
    </div>
    <div class="msc-admin-row">
      <div class="msc-group-control width-obser">
        <label>Chọn chỉ định <span class="red-dot"> *</span></label>
        <nz-select nzMode="multiple" nzPlaceHolder="" [(ngModel)]="dataForm.listOrderId" class="select-order">
          <nz-option *ngFor="let item of lstOrderSource" [nzLabel]="item.name" [nzValue]="item.id"
            [nzDisabled]="isViewOrderGroupModal">
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div class="msc-admin-row">
      <label class="col-6 select-privacy">Phạm vi<span class="red-dot"> *</span></label>
      <nz-radio-group [(ngModel)]="dataForm.privacy">
        <label nz-radio [nzDisabled]="isViewOrderGroupModal" [nzValue]="PRIVACY_SUBCLINICALORDER.OnlyMe.value">Chỉ mình
          tôi</label>
        <label nz-radio [nzDisabled]="!isAdminGroup" [nzValue]="PRIVACY_SUBCLINICALORDER.InGroup.value">Trong
          nhóm</label>
      </nz-radio-group>
    </div>

    <div *nzModalFooter>
      <div *ngIf="!isViewOrderGroupModal">
        <button class="btn-green" *ngIf="selectedRowIndex != emptyId" nz-button nzType="primary"
          (click)="modifyOrderGroup(dataForm)">
          Sửa
        </button>
        <button class="btn-green" *ngIf="selectedRowIndex == emptyId" nz-button nzType="primary"
          (click)="addOrSave(dataForm)">
          Thêm
        </button>
        <button class="btn-cancel-medium" nz-button (click)="handleCancel()">
          Huỷ
        </button>
      </div>
      <div *ngIf="isViewOrderGroupModal">
        <button class="btn-cancel-medium" nz-button (click)="handleCancel()">
          Đóng
        </button>
      </div>
    </div>
  </nz-modal>

  <nz-table class="main-table-grid" #fixedTable [nzFrontPagination]="true" [nzData]="lstSubclinicalorder"
    [nzPageSize]="20" nzSize="small" [nzPageIndex]="pageIndex" nzShowPagination
    (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger [nzScroll]="{ y: scrollY }">
    <thead>
      <tr>
        <th class="center" nzWidth="50px">STT</th>
        <th>Tên nhóm</th>
        <th>Mô tả</th>
        <th>Phạm vi</th>
        <th>Hành động</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of fixedTable.data; let i = index" [ngClass]="{ 'highlight': selectedRowIndex == data.id }"
        (click)="onClickRow(data)">
        <td class="center">
          {{ fixedTable.nzPageSize * (fixedTable.nzPageIndex - 1) + i + 1 }}
        </td>
        <td>{{ data.orderGroupName }}</td>
        <td>{{ data.description }}</td>
        <td *ngIf="data.privacy == PRIVACY_SUBCLINICALORDER.OnlyMe.value">
          {{ PRIVACY_SUBCLINICALORDER.OnlyMe.text }}
        </td>
        <td *ngIf="data.privacy == PRIVACY_SUBCLINICALORDER.InGroup.value">
          {{ PRIVACY_SUBCLINICALORDER.InGroup.text }}
        </td>
        <td style="text-indent: 8px">
          <i click-stop-propagation nz-icon nzType="eye" class="mg-r-10" title="Xem chi tiết"
            (click)="rowSelected(data); showModal('view')" nzTheme="outline"></i>
          <i *ngIf="data.canEdit" nz-icon nzType="edit" class="mg-r-10" title="Chỉnh sửa" (click)="showModal('edit')"
            nzTheme="outline"></i>
          <i *ngIf="data.canEdit" nz-icon nzType="delete" class="mg-r-10" title="Xóa"
            (click)="deleteSubclinicalOrder(data.id)" click-stop-propagation nzTheme="outline"></i>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>