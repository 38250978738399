import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from '../app-state/app-state';

@Component({
  selector: 'app-list-visit-container',
  templateUrl: './list-visit-container.component.html',
  styleUrls: ['./list-visit-container.component.scss'],
})
export class ListVisitContainerComponent implements OnInit {
  groupSub: Subscription;
  isUseListNDetailVisit = false;
  isLoading = false;
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.isUseListNDetailVisit = data.isUseListNDetailVisit;
      }
    });
  }
}
