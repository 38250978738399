import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from 'src/app-config.service';

@Component({
  selector: 'app-chat-file',
  templateUrl: './chat-file.component.html',
  styleUrls: ['./chat-file.component.scss'],
})
export class ChatFileComponent implements OnInit {
  @Input() data: any;
  constructor(protected configService: AppConfigService) {}

  ngOnInit() {}

  clickToDownload() {
    const link = document.createElement('a');
    link.download = this.data.message;
    link.target = '_blank';
    link.href =
      this.configService.getConfig().media.baseUrlOnly + this.data.src;
    link.target = '_blank';
    link.click();
  }
}
