import { Visit } from 'src/app/models/visit.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppConfigService } from 'src/app-config.service';
import { AppState } from 'src/app/components/app-state/app-state';
import { Visits } from 'src/app/models/listVisit.class';
import { NotificationService } from 'src/app/services/notification.service';
import { ObservationService } from 'src/app/services/observation.service';
import { PatientService } from 'src/app/services/patient.service';
import { ShareService } from 'src/app/services/share.service';
import { SubClinicalVisitService } from 'src/app/services/subcliniccal-service';
import { VisitService } from 'src/app/services/visit.service';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';

@Component({
  selector: 'app-visit-info-mobile',
  templateUrl: './visit-info-mobile.component.html',
  styleUrls: ['./visit-info-mobile.component.scss'],
})
export class VisitInfoMobileComponent implements OnInit {
  _visit: any = {};
  @Input() set visit(val: Visits) {
    if (val && val.id) {
      this._visit = val;
      this.getVisit(this._visit.id);
    }
  }

  get visit(): Visits {
    return this._visit;
  }
  @Output() reloadMasterNote = new EventEmitter<any>();
  @Input() hideButton: string;

  onlyView = false;
  callBackFnReason: any;
  callBackFnClinicalInfo: any;
  callBackFnConclusion: any;
  callBackFnICD: any;
  arrCollapse: any[];
  keywordICD = '';
  suggestDiseases = '';
  visitId = '';
  diseasesData = [];
  lstDisease = [];
  patientId = '';

  constructor(
    private visitService: VisitService,
    private notificationService: NotificationService,
    private shareService: ShareService
  ) {}

  ngOnInit() {
    this.arrCollapse = [true, true, true, true];
    this.callBackFnReason = this.shareService.debounce(this.updateReason, 500);
    this.callBackFnClinicalInfo = this.shareService.debounce(
      this.updateClinicalInfo,
      500
    );
    this.callBackFnICD = this.shareService.debounce(this.onInputICD10, 200);
    this.callBackFnConclusion = this.shareService.debounce(
      this.updateConclusion,
      500
    );
  }

  toggleCollapse(index: number) {
    this.arrCollapse[index] = !this.arrCollapse[index];
  }

  onTypingReason(value: string) {
    this.callBackFnReason();
  }

  updateReason(reason: string) {
    var reason = this.visit.visitReason;
    if (reason != '') {
      const payload = { visitId: this.visit.id, reason };
      this.visitService.saveVisitReason(payload).subscribe((res) => {});
    } else {
      this.notificationService.showNotification(
        'warning',
        'Lý do khám không được để trống'
      );
    }
  }

  onTypingClinicalInfo(value: string) {
    this.callBackFnClinicalInfo();
  }

  updateClinicalInfo(clinicalInfo: string) {
    var clinicalInfo = this.visit.clinicalInfomation;
    if (clinicalInfo != '') {
      const payload = { visitId: this.visit.id, clinicalInfo };
      this.visitService.updateClinicalInfo(payload).subscribe((res) => {
        //console.log(res);
      });
    } else {
      this.notificationService.showNotification(
        'info',
        'Triệu chứng lâm sàng không được để trống!'
      );
    }
  }

  onTypingICD(value: string) {
    this.keywordICD = value;
    this.callBackFnICD();
  }

  async onInputICD10() {
    const keyword = this.keywordICD.trim();

    if (keyword.length > 1) {
      await this.visitService.getDiseasesData(keyword).subscribe((data) => {
        this.diseasesData = data;
      });
    }
  }

  getVisit(vsId: string): void {
    this.visitId = vsId;
    this.patientId = this.visit.patientId;
    this.lstDisease = [];
    if (this.visit.diseases != null) {this.lstDisease = this.visit.diseases;}
  }

  fieldSelectedICD10(diseases): void {
    if (this.lstDisease.filter((en) => en.id === diseases.id).length == 0) {
      this.lstDisease.push(diseases);
      //save disease
      const payload = {
        Id: diseases.id,
        VisitId: this.visit.id,
        Name: diseases.name,
      };
      this.visitService.saveDiseases(payload).subscribe(
        (res) => {
          if (res !== null) {
          }
        },
        (error) => {
          //  this.messageError = Constant.LOGIN_FAIL;
        }
      );
    }
    this.suggestDiseases = '';
  }

  removeDiseases(event: any, diseaseId) {
    this.lstDisease = this.lstDisease.filter((en) => en.id !== diseaseId);
    const payload = { Id: diseaseId, VisitId: this.visit.id };

    this.visitService.removeDiseases(payload).subscribe(
      (res) => {
        if (res !== null) {
          console.log(res);
        }
      },
      (error) => {
        //  this.messageError = Constant.LOGIN_FAIL;
      }
    );
  }

  onTypingConclusion(value: string) {
    this.callBackFnConclusion();
  }

  updateConclusion(clinicalInfo: string) {
    const conclusion = this.visit.conclusion;
    if (conclusion != null) {
      const payload = { visitId: this.visit.id, conclusion: conclusion.trim() };
      this.visitService.updateConclusion(payload).subscribe((res) => {
        //this.notificationService.showNotification(Constant.SUCCESS, "Cập nhật thành công");
      });
    }
  }

  sendConclusionToMasterNoteAndClinicalInfomation(): void {
    if (this.onlyView) {
      return;
    } else {
      let content = '';
      if (!this.shareService.checkEmptyStr(this.visit.clinicalInfomation)) {
        content +=
          '<strong>Triệu chứng lâm sàng:</strong></br>' +
          this.visit.clinicalInfomation.trim() +
          '</br>';
      }
      if (!this.shareService.checkEmptyStr(this.visit.conclusion)) {
        content +=
          '<strong>Kết luận của bác sĩ:</strong></br>' +
          this.visit.conclusion.trim();
      }
      if (!this.shareService.checkEmptyStr(content)) {
        const chat = {
          visitId: this.visit.id,
          content,
        };
        this.visitService.addVisitMasterNote(chat).subscribe(
          (resdata) => {
            this.reloadMasterNote.emit();
          },
          (error) => {
            this.notificationService.showNotification('', error.statusText);
          }
        );
      }
    }
  }

  openPhieudl(record) {
    // console.log(record);
    window.location.href =
      'record/' +
      record.phieudlid +
      '/' +
      record.recordId +
      '/' +
      this.visit.id;
  }
}
