<div class="form-input form-elm">
  <div
    #editContent
    click-stop-propagation
    class="label-elm"
    [attr.contenteditable]="true"
    [attr.spellcheck]="false"
    [innerHTML]="html"
    (input)="onContentChange($event.target)"
    (click)="inputClick($event)"
    [ngClass]="[
      data.formatStyle.isBold == true ? 'bold' : '',
      data.formatStyle.isItalic == true ? 'italic' : '',
      data.formatStyle.isUnderline == true ? 'underline' : '',
      data.formatStyle.align
    ]"
    [ngStyle]="{
      'color': data.formatStyle.color,
      'background': data.formatStyle.bgColor,
      'font-size': data.formatStyle.fontSize + 'px',
      'font-family': data.formatStyle.font,
      'cursor': 'text',
      'z-index': 999
    }"></div>
  <!-- <input  nz-input/> -->
</div>
<div
  click-stop-propagation
  class="active-elm"
  (click)="labelClickEvent($event, data)"
  (dblclick)="labelDbClickEvent($event, data)"
  [ngClass]="
    isFocus && selectedElement.viewId == data.viewId ? 'focus' : ''
  "></div>
