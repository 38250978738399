<nz-card *ngIf="isVisible" style="width: 100%">
  <div>
    <div class="btn-group" style="display: flex" *ngIf="isFormatEachElement">
      <!-- <div title="Bold - Ctrl+B" (click)="excFormat('bold')">
        <svg width="40" height="40" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
            <rect x="17" y="14" width="136" height="136" rx="8" fill="white" />
          </g>
          <rect x="17" y="14" width="136" height="136" rx="8" fill="url(#paint0_linear)" fill-opacity="0.5"
            style="mix-blend-mode:multiply" />
          <rect x="18" y="15" width="134" height="134" rx="7" stroke="black" stroke-opacity="0.37" stroke-width="2" />
          <path
            d="M91.3516 81.1484C94.3672 81.8516 96.4844 82.7031 97.7031 83.7031C99.4531 85.125 100.328 86.9688 100.328 89.2344C100.328 91.625 99.3672 93.6016 97.4453 95.1641C95.0859 97.0547 91.6562 98 87.1562 98H71.0078V97.1328C72.4766 97.1328 73.4688 97 73.9844 96.7344C74.5156 96.4531 74.8828 96.0938 75.0859 95.6562C75.3047 95.2188 75.4141 94.1406 75.4141 92.4219V71.7969C75.4141 70.0781 75.3047 69 75.0859 68.5625C74.8828 68.1094 74.5156 67.75 73.9844 67.4844C73.4531 67.2188 72.4609 67.0859 71.0078 67.0859V66.2188H86.2422C89.8828 66.2188 92.4609 66.5469 93.9766 67.2031C95.4922 67.8438 96.6875 68.8125 97.5625 70.1094C98.4375 71.3906 98.875 72.7578 98.875 74.2109C98.875 75.7422 98.3203 77.1094 97.2109 78.3125C96.1016 79.5 94.1484 80.4453 91.3516 81.1484ZM82.8203 80.5156C85.0391 80.5156 86.6719 80.2656 87.7188 79.7656C88.7812 79.2656 89.5938 78.5625 90.1562 77.6562C90.7188 76.75 91 75.5938 91 74.1875C91 72.7812 90.7188 71.6328 90.1562 70.7422C89.6094 69.8359 88.8203 69.1484 87.7891 68.6797C86.7578 68.2109 85.1016 67.9844 82.8203 68V80.5156ZM82.8203 82.3438V92.5391L82.7969 93.7109C82.7969 94.5547 83.0078 95.1953 83.4297 95.6328C83.8672 96.0547 84.5078 96.2656 85.3516 96.2656C86.6016 96.2656 87.75 95.9922 88.7969 95.4453C89.8594 94.8828 90.6719 94.0781 91.2344 93.0312C91.7969 91.9688 92.0781 90.7891 92.0781 89.4922C92.0781 88.0078 91.7344 86.6797 91.0469 85.5078C90.3594 84.3203 89.4141 83.4922 88.2109 83.0234C87.0078 82.5547 85.2109 82.3281 82.8203 82.3438Z"
            fill="black" />
          <defs>
            <filter id="filter0_d" x="0" y="0" width="170" height="170" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="8.5" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
            <linearGradient id="paint0_linear" x1="85" y1="14" x2="85" y2="150" gradientUnits="userSpaceOnUse">
              <stop stop-color="white" />
              <stop offset="0.333333" stop-color="white" stop-opacity="0.96209" />
              <stop offset="0.723958" stop-color="#E1E1E1" stop-opacity="0.945486" />
              <stop offset="1" stop-color="#9E9E9E" stop-opacity="0.908955" />
            </linearGradient>
          </defs>
        </svg>
      </div> -->
      <button class="btn" title="Italic - Ctrl+I" (click)="excFormat('bold')">
        <svg
          width="40"
          height="40"
          viewBox="0 0 170 170"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
            <rect x="17" y="14" width="136" height="136" rx="8" fill="white" />
          </g>
          <rect
            x="17"
            y="14"
            width="136"
            height="136"
            rx="8"
            fill="url(#paint0_linear)"
            fill-opacity="0.5"
            style="mix-blend-mode: multiply" />
          <rect
            x="18"
            y="15"
            width="134"
            height="134"
            rx="7"
            stroke="black"
            stroke-opacity="0.37"
            stroke-width="2" />
          <path
            d="M91.3516 81.1484C94.3672 81.8516 96.4844 82.7031 97.7031 83.7031C99.4531 85.125 100.328 86.9688 100.328 89.2344C100.328 91.625 99.3672 93.6016 97.4453 95.1641C95.0859 97.0547 91.6562 98 87.1562 98H71.0078V97.1328C72.4766 97.1328 73.4688 97 73.9844 96.7344C74.5156 96.4531 74.8828 96.0938 75.0859 95.6562C75.3047 95.2188 75.4141 94.1406 75.4141 92.4219V71.7969C75.4141 70.0781 75.3047 69 75.0859 68.5625C74.8828 68.1094 74.5156 67.75 73.9844 67.4844C73.4531 67.2188 72.4609 67.0859 71.0078 67.0859V66.2188H86.2422C89.8828 66.2188 92.4609 66.5469 93.9766 67.2031C95.4922 67.8438 96.6875 68.8125 97.5625 70.1094C98.4375 71.3906 98.875 72.7578 98.875 74.2109C98.875 75.7422 98.3203 77.1094 97.2109 78.3125C96.1016 79.5 94.1484 80.4453 91.3516 81.1484ZM82.8203 80.5156C85.0391 80.5156 86.6719 80.2656 87.7188 79.7656C88.7812 79.2656 89.5938 78.5625 90.1562 77.6562C90.7188 76.75 91 75.5938 91 74.1875C91 72.7812 90.7188 71.6328 90.1562 70.7422C89.6094 69.8359 88.8203 69.1484 87.7891 68.6797C86.7578 68.2109 85.1016 67.9844 82.8203 68V80.5156ZM82.8203 82.3438V92.5391L82.7969 93.7109C82.7969 94.5547 83.0078 95.1953 83.4297 95.6328C83.8672 96.0547 84.5078 96.2656 85.3516 96.2656C86.6016 96.2656 87.75 95.9922 88.7969 95.4453C89.8594 94.8828 90.6719 94.0781 91.2344 93.0312C91.7969 91.9688 92.0781 90.7891 92.0781 89.4922C92.0781 88.0078 91.7344 86.6797 91.0469 85.5078C90.3594 84.3203 89.4141 83.4922 88.2109 83.0234C87.0078 82.5547 85.2109 82.3281 82.8203 82.3438Z"
            fill="black" />
          <defs>
            <filter
              id="filter0_d"
              x="0"
              y="0"
              width="170"
              height="170"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="8.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear"
              x1="85"
              y1="14"
              x2="85"
              y2="150"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="white" />
              <stop
                offset="0.333333"
                stop-color="white"
                stop-opacity="0.96209" />
              <stop
                offset="0.723958"
                stop-color="#E1E1E1"
                stop-opacity="0.945486" />
              <stop offset="1" stop-color="#9E9E9E" stop-opacity="0.908955" />
            </linearGradient>
          </defs>
        </svg>
      </button>
      <button class="btn" title="Italic - Ctrl+I" (click)="excFormat('italic')">
        <svg
          width="40"
          height="40"
          viewBox="0 0 170 170"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
            <rect x="17" y="14" width="136" height="136" rx="8" fill="white" />
          </g>
          <rect
            x="17"
            y="14"
            width="136"
            height="136"
            rx="8"
            fill="url(#paint0_linear)"
            fill-opacity="0.5"
            style="mix-blend-mode: multiply" />
          <rect
            x="18"
            y="15"
            width="134"
            height="134"
            rx="7"
            stroke="black"
            stroke-opacity="0.37"
            stroke-width="2" />
          <path
            d="M81.6328 67.0859L81.8906 66.2188H97.4062L97.1016 67.0859C95.6953 67.0859 94.6172 67.3984 93.8672 68.0234C93.1328 68.6484 92.4844 69.9531 91.9219 71.9375L86.1094 92.2812C85.6875 93.7188 85.4766 94.6797 85.4766 95.1641C85.4766 95.7266 85.6953 96.1641 86.1328 96.4766C86.6953 96.8828 87.7578 97.1016 89.3203 97.1328L89.0859 98H73.3125L73.5703 97.1328C75.1484 97.1328 76.3047 96.8359 77.0391 96.2422C77.7734 95.6328 78.4375 94.3125 79.0312 92.2812L84.8906 71.9375C85.25 70.6875 85.4297 69.7422 85.4297 69.1016C85.4297 68.5234 85.2109 68.0703 84.7734 67.7422C84.3359 67.4141 83.2891 67.1953 81.6328 67.0859Z"
            fill="black" />
          <defs>
            <filter
              id="filter0_d"
              x="0"
              y="0"
              width="170"
              height="170"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="8.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear"
              x1="85"
              y1="14"
              x2="85"
              y2="150"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="white" />
              <stop
                offset="0.333333"
                stop-color="white"
                stop-opacity="0.96209" />
              <stop
                offset="0.723958"
                stop-color="#E1E1E1"
                stop-opacity="0.945486" />
              <stop offset="1" stop-color="#9E9E9E" stop-opacity="0.908955" />
            </linearGradient>
          </defs>
        </svg>
      </button>
      <button
        class="btn"
        title="Underline - Ctrl+U"
        (click)="excFormat('underline')">
        <svg
          width="40"
          height="40"
          viewBox="0 0 170 170"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
            <rect x="17" y="14" width="136" height="136" rx="8" fill="white" />
          </g>
          <rect
            x="17"
            y="14"
            width="136"
            height="136"
            rx="8"
            fill="url(#paint0_linear)"
            fill-opacity="0.5"
            style="mix-blend-mode: multiply" />
          <rect
            x="18"
            y="15"
            width="134"
            height="134"
            rx="7"
            stroke="black"
            stroke-opacity="0.37"
            stroke-width="2" />
          <path
            d="M71.125 66.2188H87.5312V67.0859H86.7109C85.4766 67.0859 84.625 67.2188 84.1562 67.4844C83.7031 67.7344 83.375 68.0859 83.1719 68.5391C82.9844 68.9922 82.8906 70.125 82.8906 71.9375V87.5C82.8906 90.3438 83.1016 92.2266 83.5234 93.1484C83.9609 94.0703 84.6719 94.8359 85.6562 95.4453C86.6406 96.0547 87.8828 96.3594 89.3828 96.3594C91.1016 96.3594 92.5625 95.9766 93.7656 95.2109C94.9844 94.4297 95.8906 93.3594 96.4844 92C97.0938 90.6406 97.3984 88.2734 97.3984 84.8984V71.9375C97.3984 70.5156 97.25 69.5 96.9531 68.8906C96.6562 68.2812 96.2812 67.8594 95.8281 67.625C95.125 67.2656 94.1328 67.0859 92.8516 67.0859V66.2188H103.844V67.0859H103.188C102.297 67.0859 101.555 67.2656 100.961 67.625C100.367 67.9844 99.9375 68.5234 99.6719 69.2422C99.4688 69.7422 99.3672 70.6406 99.3672 71.9375V84.0078C99.3672 87.7422 99.1172 90.4375 98.6172 92.0938C98.1328 93.75 96.9375 95.2734 95.0312 96.6641C93.125 98.0547 90.5234 98.75 87.2266 98.75C84.4766 98.75 82.3516 98.3828 80.8516 97.6484C78.8047 96.6484 77.3594 95.3672 76.5156 93.8047C75.6719 92.2422 75.25 90.1406 75.25 87.5V71.9375C75.25 70.1094 75.1484 68.9766 74.9453 68.5391C74.7422 68.0859 74.3906 67.7266 73.8906 67.4609C73.3906 67.1953 72.4688 67.0703 71.125 67.0859V66.2188Z"
            fill="black" />
          <path d="M70 105.5H104.664V110.07H70V105.5Z" fill="black" />
          <defs>
            <filter
              id="filter0_d"
              x="0"
              y="0"
              width="170"
              height="170"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="8.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear"
              x1="85"
              y1="14"
              x2="85"
              y2="150"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="white" />
              <stop
                offset="0.333333"
                stop-color="white"
                stop-opacity="0.96209" />
              <stop
                offset="0.723958"
                stop-color="#E1E1E1"
                stop-opacity="0.945486" />
              <stop offset="1" stop-color="#9E9E9E" stop-opacity="0.908955" />
            </linearGradient>
          </defs>
        </svg>
      </button>
      <div class="btn-group">
        <div style="margin: 5px">
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="30"
            height="30"
            viewBox="0 0 390.954 390.955"
            style="enable-background: new 0 0 390.954 390.955"
            xml:space="preserve">
            <g>
              <g>
                <path
                  d="M377.314,27.704C360.761,0.494,325.283-8.145,298.076,8.41l-52.561,31.977l-3.607-5.932
        c-10.484-17.229-32.947-22.7-50.179-12.218C174.5,32.72,169.032,55.184,179.512,72.415l7.162,11.771L60.314,161.652
        c-11.612,7.065-30.483,32.364-35.989,39.95c-2.97,4.09-3.191,9.563-0.565,13.881l24.784,40.738
        c2.627,4.317,7.591,6.637,12.587,5.88c9.269-1.402,40.41-6.529,52.024-13.596l126.357-77.467l7.161,11.771
        c10.481,17.229,32.946,22.7,50.178,12.217c17.229-10.481,22.699-32.946,12.217-50.177l-3.607-5.93l52.561-31.978
        C385.229,90.389,393.868,54.912,377.314,27.704z M100.124,227.084l-0.694-59.882l85.469-52.59
        c0.715,8.641,3.392,17.25,8.204,25.161c4.812,7.911,11.229,14.245,18.571,18.853L100.124,227.084z" />
                <path
                  d="M52.666,276.584c-1.823-1.458-4.413-1.459-6.238-0.003C44.745,277.922,5.23,309.82,5.23,343.554
        c0,27.909,18.223,47.4,44.314,47.4c26.836,0,46.314-19.936,46.314-47.4C95.859,311.472,54.43,277.995,52.666,276.584z
         M55.582,378.402c-0.414,0.104-0.829,0.155-1.237,0.155c-2.231,0-4.266-1.506-4.842-3.769c-0.68-2.672,0.931-5.389,3.6-6.075
        c0.915-0.241,20.916-5.754,20.913-25.823c0-2.762,2.237-5,4.999-5.001c2.762,0,5.001,2.238,5.001,4.999
        C84.02,365.254,65.417,375.898,55.582,378.402z" />
              </g>
            </g>
          </svg>
        </div>
        <input
          [(colorPicker)]="formatStyle.color"
          [style.background]="formatStyle.color"
          (colorPickerClose)="onColorPickerClose($event)"
          class="color-picker-input" />
      </div>
    </div>
    <div class="btn-group" style="display: flex" *ngIf="!isFormatEachElement">
      <div
        title="Bold - Ctrl+B"
        class="{{ formatStyle.isBold ? BOLD_ACTIVE : '' }}"
        (click)="toggleBold()">
        <svg
          width="40"
          height="40"
          viewBox="0 0 170 170"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
            <rect x="17" y="14" width="136" height="136" rx="8" fill="white" />
          </g>
          <rect
            x="17"
            y="14"
            width="136"
            height="136"
            rx="8"
            fill="url(#paint0_linear)"
            fill-opacity="0.5"
            style="mix-blend-mode: multiply" />
          <rect
            x="18"
            y="15"
            width="134"
            height="134"
            rx="7"
            stroke="black"
            stroke-opacity="0.37"
            stroke-width="2" />
          <path
            d="M91.3516 81.1484C94.3672 81.8516 96.4844 82.7031 97.7031 83.7031C99.4531 85.125 100.328 86.9688 100.328 89.2344C100.328 91.625 99.3672 93.6016 97.4453 95.1641C95.0859 97.0547 91.6562 98 87.1562 98H71.0078V97.1328C72.4766 97.1328 73.4688 97 73.9844 96.7344C74.5156 96.4531 74.8828 96.0938 75.0859 95.6562C75.3047 95.2188 75.4141 94.1406 75.4141 92.4219V71.7969C75.4141 70.0781 75.3047 69 75.0859 68.5625C74.8828 68.1094 74.5156 67.75 73.9844 67.4844C73.4531 67.2188 72.4609 67.0859 71.0078 67.0859V66.2188H86.2422C89.8828 66.2188 92.4609 66.5469 93.9766 67.2031C95.4922 67.8438 96.6875 68.8125 97.5625 70.1094C98.4375 71.3906 98.875 72.7578 98.875 74.2109C98.875 75.7422 98.3203 77.1094 97.2109 78.3125C96.1016 79.5 94.1484 80.4453 91.3516 81.1484ZM82.8203 80.5156C85.0391 80.5156 86.6719 80.2656 87.7188 79.7656C88.7812 79.2656 89.5938 78.5625 90.1562 77.6562C90.7188 76.75 91 75.5938 91 74.1875C91 72.7812 90.7188 71.6328 90.1562 70.7422C89.6094 69.8359 88.8203 69.1484 87.7891 68.6797C86.7578 68.2109 85.1016 67.9844 82.8203 68V80.5156ZM82.8203 82.3438V92.5391L82.7969 93.7109C82.7969 94.5547 83.0078 95.1953 83.4297 95.6328C83.8672 96.0547 84.5078 96.2656 85.3516 96.2656C86.6016 96.2656 87.75 95.9922 88.7969 95.4453C89.8594 94.8828 90.6719 94.0781 91.2344 93.0312C91.7969 91.9688 92.0781 90.7891 92.0781 89.4922C92.0781 88.0078 91.7344 86.6797 91.0469 85.5078C90.3594 84.3203 89.4141 83.4922 88.2109 83.0234C87.0078 82.5547 85.2109 82.3281 82.8203 82.3438Z"
            fill="black" />
          <defs>
            <filter
              id="filter0_d"
              x="0"
              y="0"
              width="170"
              height="170"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="8.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear"
              x1="85"
              y1="14"
              x2="85"
              y2="150"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="white" />
              <stop
                offset="0.333333"
                stop-color="white"
                stop-opacity="0.96209" />
              <stop
                offset="0.723958"
                stop-color="#E1E1E1"
                stop-opacity="0.945486" />
              <stop offset="1" stop-color="#9E9E9E" stop-opacity="0.908955" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div
        nz-button
        title="Italic - Ctrl+I"
        class="{{ formatStyle.isItalic ? ITALIC_ACTIVE : '' }}"
        (click)="toggleItalic()">
        <svg
          width="40"
          height="40"
          viewBox="0 0 170 170"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
            <rect x="17" y="14" width="136" height="136" rx="8" fill="white" />
          </g>
          <rect
            x="17"
            y="14"
            width="136"
            height="136"
            rx="8"
            fill="url(#paint0_linear)"
            fill-opacity="0.5"
            style="mix-blend-mode: multiply" />
          <rect
            x="18"
            y="15"
            width="134"
            height="134"
            rx="7"
            stroke="black"
            stroke-opacity="0.37"
            stroke-width="2" />
          <path
            d="M81.6328 67.0859L81.8906 66.2188H97.4062L97.1016 67.0859C95.6953 67.0859 94.6172 67.3984 93.8672 68.0234C93.1328 68.6484 92.4844 69.9531 91.9219 71.9375L86.1094 92.2812C85.6875 93.7188 85.4766 94.6797 85.4766 95.1641C85.4766 95.7266 85.6953 96.1641 86.1328 96.4766C86.6953 96.8828 87.7578 97.1016 89.3203 97.1328L89.0859 98H73.3125L73.5703 97.1328C75.1484 97.1328 76.3047 96.8359 77.0391 96.2422C77.7734 95.6328 78.4375 94.3125 79.0312 92.2812L84.8906 71.9375C85.25 70.6875 85.4297 69.7422 85.4297 69.1016C85.4297 68.5234 85.2109 68.0703 84.7734 67.7422C84.3359 67.4141 83.2891 67.1953 81.6328 67.0859Z"
            fill="black" />
          <defs>
            <filter
              id="filter0_d"
              x="0"
              y="0"
              width="170"
              height="170"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="8.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear"
              x1="85"
              y1="14"
              x2="85"
              y2="150"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="white" />
              <stop
                offset="0.333333"
                stop-color="white"
                stop-opacity="0.96209" />
              <stop
                offset="0.723958"
                stop-color="#E1E1E1"
                stop-opacity="0.945486" />
              <stop offset="1" stop-color="#9E9E9E" stop-opacity="0.908955" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div
        nz-button
        title="Underline - Ctrl+U"
        class="{{ formatStyle.isUnderline ? UNDERLINE_ACTIVE : '' }}"
        (click)="toggleUnderline()">
        <svg
          width="40"
          height="40"
          viewBox="0 0 170 170"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
            <rect x="17" y="14" width="136" height="136" rx="8" fill="white" />
          </g>
          <rect
            x="17"
            y="14"
            width="136"
            height="136"
            rx="8"
            fill="url(#paint0_linear)"
            fill-opacity="0.5"
            style="mix-blend-mode: multiply" />
          <rect
            x="18"
            y="15"
            width="134"
            height="134"
            rx="7"
            stroke="black"
            stroke-opacity="0.37"
            stroke-width="2" />
          <path
            d="M71.125 66.2188H87.5312V67.0859H86.7109C85.4766 67.0859 84.625 67.2188 84.1562 67.4844C83.7031 67.7344 83.375 68.0859 83.1719 68.5391C82.9844 68.9922 82.8906 70.125 82.8906 71.9375V87.5C82.8906 90.3438 83.1016 92.2266 83.5234 93.1484C83.9609 94.0703 84.6719 94.8359 85.6562 95.4453C86.6406 96.0547 87.8828 96.3594 89.3828 96.3594C91.1016 96.3594 92.5625 95.9766 93.7656 95.2109C94.9844 94.4297 95.8906 93.3594 96.4844 92C97.0938 90.6406 97.3984 88.2734 97.3984 84.8984V71.9375C97.3984 70.5156 97.25 69.5 96.9531 68.8906C96.6562 68.2812 96.2812 67.8594 95.8281 67.625C95.125 67.2656 94.1328 67.0859 92.8516 67.0859V66.2188H103.844V67.0859H103.188C102.297 67.0859 101.555 67.2656 100.961 67.625C100.367 67.9844 99.9375 68.5234 99.6719 69.2422C99.4688 69.7422 99.3672 70.6406 99.3672 71.9375V84.0078C99.3672 87.7422 99.1172 90.4375 98.6172 92.0938C98.1328 93.75 96.9375 95.2734 95.0312 96.6641C93.125 98.0547 90.5234 98.75 87.2266 98.75C84.4766 98.75 82.3516 98.3828 80.8516 97.6484C78.8047 96.6484 77.3594 95.3672 76.5156 93.8047C75.6719 92.2422 75.25 90.1406 75.25 87.5V71.9375C75.25 70.1094 75.1484 68.9766 74.9453 68.5391C74.7422 68.0859 74.3906 67.7266 73.8906 67.4609C73.3906 67.1953 72.4688 67.0703 71.125 67.0859V66.2188Z"
            fill="black" />
          <path d="M70 105.5H104.664V110.07H70V105.5Z" fill="black" />
          <defs>
            <filter
              id="filter0_d"
              x="0"
              y="0"
              width="170"
              height="170"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="8.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear"
              x1="85"
              y1="14"
              x2="85"
              y2="150"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="white" />
              <stop
                offset="0.333333"
                stop-color="white"
                stop-opacity="0.96209" />
              <stop
                offset="0.723958"
                stop-color="#E1E1E1"
                stop-opacity="0.945486" />
              <stop offset="1" stop-color="#9E9E9E" stop-opacity="0.908955" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="btn-group">
        <div style="margin: 5px">
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="30"
            height="30"
            viewBox="0 0 390.954 390.955"
            style="enable-background: new 0 0 390.954 390.955"
            xml:space="preserve">
            <g>
              <g>
                <path
                  d="M377.314,27.704C360.761,0.494,325.283-8.145,298.076,8.41l-52.561,31.977l-3.607-5.932
        c-10.484-17.229-32.947-22.7-50.179-12.218C174.5,32.72,169.032,55.184,179.512,72.415l7.162,11.771L60.314,161.652
        c-11.612,7.065-30.483,32.364-35.989,39.95c-2.97,4.09-3.191,9.563-0.565,13.881l24.784,40.738
        c2.627,4.317,7.591,6.637,12.587,5.88c9.269-1.402,40.41-6.529,52.024-13.596l126.357-77.467l7.161,11.771
        c10.481,17.229,32.946,22.7,50.178,12.217c17.229-10.481,22.699-32.946,12.217-50.177l-3.607-5.93l52.561-31.978
        C385.229,90.389,393.868,54.912,377.314,27.704z M100.124,227.084l-0.694-59.882l85.469-52.59
        c0.715,8.641,3.392,17.25,8.204,25.161c4.812,7.911,11.229,14.245,18.571,18.853L100.124,227.084z" />
                <path
                  d="M52.666,276.584c-1.823-1.458-4.413-1.459-6.238-0.003C44.745,277.922,5.23,309.82,5.23,343.554
        c0,27.909,18.223,47.4,44.314,47.4c26.836,0,46.314-19.936,46.314-47.4C95.859,311.472,54.43,277.995,52.666,276.584z
         M55.582,378.402c-0.414,0.104-0.829,0.155-1.237,0.155c-2.231,0-4.266-1.506-4.842-3.769c-0.68-2.672,0.931-5.389,3.6-6.075
        c0.915-0.241,20.916-5.754,20.913-25.823c0-2.762,2.237-5,4.999-5.001c2.762,0,5.001,2.238,5.001,4.999
        C84.02,365.254,65.417,375.898,55.582,378.402z" />
              </g>
            </g>
          </svg>
        </div>
        <input
          [(colorPicker)]="formatStyle.color"
          [style.background]="formatStyle.color"
          (colorPickerClose)="onColorPickerClose($event)"
          class="color-picker-input" />
      </div>
    </div>
    <div class="btn-group" style="display: flex">
      <div
        title="Align Left"
        class="{{ formatStyle.align == LEFT ? ALIGN_LEFT_ACTIVE : '' }}"
        (click)="selectAlign(LEFT)">
        <svg
          width="40"
          height="40"
          viewBox="0 0 170 170"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
            <rect x="17" y="14" width="136" height="136" rx="8" fill="white" />
          </g>
          <rect
            x="17"
            y="14"
            width="136"
            height="136"
            rx="8"
            fill="url(#paint0_linear)"
            fill-opacity="0.5"
            style="mix-blend-mode: multiply" />
          <rect
            x="18"
            y="15"
            width="134"
            height="134"
            rx="7"
            stroke="black"
            stroke-opacity="0.37"
            stroke-width="2" />
          <line
            x1="52"
            y1="50"
            x2="117.76"
            y2="50"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="52"
            y1="62"
            x2="102.197"
            y2="62"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="52"
            y1="74"
            x2="117.76"
            y2="74"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="52"
            y1="86"
            x2="102.197"
            y2="86"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="52"
            y1="98"
            x2="102.197"
            y2="98"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="52"
            y1="110"
            x2="117.76"
            y2="110"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <defs>
            <filter
              id="filter0_d"
              x="0"
              y="0"
              width="170"
              height="170"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="8.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear"
              x1="85"
              y1="14"
              x2="85"
              y2="150"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="white" />
              <stop
                offset="0.333333"
                stop-color="white"
                stop-opacity="0.96209" />
              <stop
                offset="0.723958"
                stop-color="#E1E1E1"
                stop-opacity="0.945486" />
              <stop offset="1" stop-color="#9E9E9E" stop-opacity="0.908955" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div
        title="Align Center"
        class="{{ formatStyle.align == CENTER ? ALIGN_CENTER_ACTIVE : '' }}"
        (click)="selectAlign(CENTER)">
        <svg
          width="40"
          height="40"
          viewBox="0 0 170 170"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
            <rect x="17" y="14" width="136" height="136" rx="8" fill="white" />
          </g>
          <rect
            x="17"
            y="14"
            width="136"
            height="136"
            rx="8"
            fill="url(#paint0_linear)"
            fill-opacity="0.5"
            style="mix-blend-mode: multiply" />
          <rect
            x="18"
            y="15"
            width="134"
            height="134"
            rx="7"
            stroke="black"
            stroke-opacity="0.37"
            stroke-width="2" />
          <line
            x1="52"
            y1="50"
            x2="117.76"
            y2="50"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="62"
            y1="63"
            x2="108"
            y2="63"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="52"
            y1="74"
            x2="117.76"
            y2="74"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="62"
            y1="86"
            x2="108"
            y2="86"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="52"
            y1="98"
            x2="118"
            y2="98"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="62"
            y1="110"
            x2="108"
            y2="110"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <defs>
            <filter
              id="filter0_d"
              x="0"
              y="0"
              width="170"
              height="170"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="8.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear"
              x1="85"
              y1="14"
              x2="85"
              y2="150"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="white" />
              <stop
                offset="0.333333"
                stop-color="white"
                stop-opacity="0.96209" />
              <stop
                offset="0.723958"
                stop-color="#E1E1E1"
                stop-opacity="0.945486" />
              <stop offset="1" stop-color="#9E9E9E" stop-opacity="0.908955" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div
        title="Align Right"
        class="{{ formatStyle.align == RIGHT ? ALIGN_RIGHT_ACTIVE : '' }}"
        (click)="selectAlign(RIGHT)">
        <svg
          width="40"
          height="40"
          viewBox="0 0 170 170"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
            <rect x="17" y="14" width="136" height="136" rx="8" fill="white" />
          </g>
          <rect
            x="17"
            y="14"
            width="136"
            height="136"
            rx="8"
            fill="url(#paint0_linear)"
            fill-opacity="0.5"
            style="mix-blend-mode: multiply" />
          <rect
            x="18"
            y="15"
            width="134"
            height="134"
            rx="7"
            stroke="black"
            stroke-opacity="0.37"
            stroke-width="2" />
          <line
            x1="52"
            y1="50"
            x2="117.76"
            y2="50"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="68"
            y1="62"
            x2="118.197"
            y2="62"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="52"
            y1="74"
            x2="117.76"
            y2="74"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="68"
            y1="86"
            x2="118.197"
            y2="86"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="68"
            y1="98"
            x2="118.197"
            y2="98"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <line
            x1="52"
            y1="110"
            x2="117.76"
            y2="110"
            stroke="black"
            stroke-width="4"
            stroke-linecap="round" />
          <defs>
            <filter
              id="filter0_d"
              x="0"
              y="0"
              width="170"
              height="170"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="8.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear"
              x1="85"
              y1="14"
              x2="85"
              y2="150"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="white" />
              <stop
                offset="0.333333"
                stop-color="white"
                stop-opacity="0.96209" />
              <stop
                offset="0.723958"
                stop-color="#E1E1E1"
                stop-opacity="0.945486" />
              <stop offset="1" stop-color="#9E9E9E" stop-opacity="0.908955" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="btn-group">
        <div style="margin: 5px">
          <svg
            id="Capa_1"
            enable-background="new 0 0 512 512"
            height="30"
            viewBox="0 0 512 512"
            width="30"
            xmlns="http://www.w3.org/2000/svg">
            <g>
              <path
                d="m331.125 344.43c2.445-5.712 4.899-11.354 7.357-16.925-3.319-26.986-15.187-53.097-33.484-74.946-12.962-15.478-21.335-34.796-21.335-58.878 0-55.304 39.25-83.503 86.015-97.474 45.932-13.721 42.47-79.81-4.536-89.211-164.018-32.802-344.87 51.108-361.896 223.005-8.573 86.556 29.227 158.47 81.035 215.032 54.771 59.798 145.849 61.673 203.676 12.327 11.068-33.518 25.743-72.223 43.168-112.93z"
                fill="#dbedff" />
              <path
                d="m161.051 445.033c-51.807-56.561-89.608-128.475-81.035-215.032 13.264-133.914 125.953-214.425 252.652-227.959-154.586-16.446-313.514 67.348-329.422 227.959-8.573 86.557 29.228 158.471 81.035 215.032 38.803 42.364 95.827 55.647 146.342 42.25-25.716-6.87-49.97-20.849-69.572-42.25z"
                fill="#b5dcff" />
              <circle cx="211.48" cy="91.985" fill="#f4dd45" r="33.899" />
              <circle cx="196.167" cy="394.804" fill="#64d465" r="39.91" />
              <circle cx="127.944" cy="178.346" fill="#f87c68" r="42.777" />
              <circle cx="113.48" cy="300.989" fill="#7fb3fa" r="42.777" />
              <path
                d="m215.707 91.985c0-11.653 5.881-21.93 14.836-28.031-5.432-3.701-11.994-5.867-19.063-5.867-18.722 0-33.899 15.177-33.899 33.899s15.177 33.899 33.899 33.899c7.069 0 13.631-2.166 19.063-5.868-8.954-6.102-14.836-16.379-14.836-28.032z"
                fill="#fcc101" />
              <path
                d="m194.383 394.804c0-15.137 8.428-28.305 20.847-35.07-5.664-3.086-12.159-4.84-19.063-4.84-22.041 0-39.91 17.868-39.91 39.91s17.868 39.91 39.91 39.91c6.904 0 13.399-1.754 19.063-4.84-12.419-6.765-20.847-19.933-20.847-35.07z"
                fill="#40c140" />
              <path
                d="m123.293 178.346c0-16.773 9.659-31.283 23.714-38.294-5.741-2.864-12.211-4.483-19.063-4.483-23.625 0-42.777 19.152-42.777 42.777s19.152 42.777 42.777 42.777c6.852 0 13.322-1.62 19.063-4.483-14.055-7.011-23.714-21.521-23.714-38.294z"
                fill="#ec625e" />
              <path
                d="m108.829 300.989c0-16.774 9.659-31.283 23.714-38.294-5.741-2.863-12.211-4.483-19.063-4.483-23.625 0-42.777 19.152-42.777 42.777s19.152 42.777 42.777 42.777c6.852 0 13.322-1.62 19.063-4.483-14.054-7.011-23.714-21.521-23.714-38.294z"
                fill="#64a6f4" />
              <path
                d="m420.433 281.068-46.359-19.23-6.628 1.654c-12.265 24.587-24.923 51.908-37.35 80.938-21.323 49.811-38.534 96.637-50.048 134.611-3.655 12.056 2.058 24.973 13.413 30.43 12.145 5.837 26.725 1.252 33.355-10.479 19.52-34.538 41.508-79.298 62.816-129.076 12.427-29.031 23.458-57.049 32.781-82.895z"
                fill="#ce775f" />
              <path
                d="m309.408 482.897c11.514-37.975 28.725-84.8 50.048-134.611 11.883-27.76 23.976-53.95 35.736-77.687l-21.119-8.76-6.628 1.654c-12.265 24.587-24.923 51.908-37.35 80.938-21.323 49.811-38.534 96.637-50.048 134.611-3.655 12.056 2.058 24.973 13.413 30.43 7.58 3.643 16.109 3.225 23.061-.454-7.009-6.496-10.009-16.571-7.113-26.121z"
                fill="#c0694e" />
              <path
                d="m424.504 160.215 33.247 14.232c1.513.648 2.776 1.632 3.757 2.822 11.864-6.765 32.954-21.475 43.001-44.947 11.07-25.861 2.617-72.791-1.901-93.178-1.11-5.01-6.778-7.436-11.169-4.781-17.87 10.803-57.661 37.082-68.731 62.942-10.047 23.472-6.133 48.885-2.839 62.139 1.539-.111 3.123.123 4.635.771z"
                fill="#ffe97b" />
              <path
                d="m448.807 113.445c9.101-21.261 37.606-42.796 57.391-55.79-1.209-7.248-2.511-13.642-3.59-18.511-1.11-5.01-6.778-7.436-11.169-4.781-17.87 10.803-57.661 37.082-68.731 62.942-10.047 23.471-6.133 48.885-2.839 62.139 1.538-.112 3.122.123 4.635.77l19.975 8.55c-2.67-14.096-4.196-35.404 4.328-55.319z"
                fill="#f4dd45" />
              <path
                d="m407.688 190.078c-12.237 19.743-25.971 44.748-40.243 73.413l54.968 23.53c10.889-30.113 19.501-57.311 25.34-79.793-1.238-.025-2.489-.275-3.695-.791l-33.247-14.232c-1.206-.516-2.251-1.249-3.123-2.127z"
                fill="#477b9e" />
              <path
                d="m396.806 267.347c12.513-25.133 24.612-47.45 35.651-65.875l-21.647-9.266c-1.205-.516-2.25-1.249-3.123-2.127-12.237 19.743-25.971 44.748-40.243 73.413l25.804 11.046c1.19-2.42 2.376-4.822 3.558-7.191z"
                fill="#3f6d8e" />
              <path
                d="m458.193 173.417-33.247-14.232c-5.088-2.178-10.979.181-13.157 5.269l-6.687 15.622c-2.178 5.088.181 10.979 5.269 13.157l33.247 14.232c5.088 2.178 10.979-.181 13.157-5.269l6.687-15.622c2.178-5.088-.181-10.978-5.269-13.157z"
                fill="#dbedff" />
              <path
                d="m429.905 190.755 7.181-16.775c1.438-3.36 4.386-5.605 7.712-6.297l-19.852-8.498c-5.088-2.178-10.979.181-13.157 5.269l-6.687 15.622c-2.178 5.088.181 10.979 5.269 13.157l21.781 9.324c-2.987-3.019-4.022-7.657-2.247-11.802z"
                fill="#b5dcff" />
            </g>
          </svg>
        </div>
        <input
          [(colorPicker)]="formatStyle.bgColor"
          [style.background]="formatStyle.bgColor"
          (colorPickerClose)="onBgColorPickerClose($event)"
          cpOutputFormat="hex"
          class="color-picker-input" />
      </div>
    </div>
    <br />
    <div class="btn-group">
      <input
        placeholder="font"
        nz-input
        [(ngModel)]="formatStyle.font"
        (ngModelChange)="onFontChange($event)"
        [nzAutocomplete]="auto" />
      <nz-autocomplete
        [nzDataSource]="filteredFontOptions"
        #auto></nz-autocomplete>

      <input
        placeholder="font"
        nz-input
        [(ngModel)]="formatStyle.fontSize"
        (ngModelChange)="onFontSizeChange($event)"
        [nzAutocomplete]="autos"
        style="width: 50px" />
      <nz-autocomplete
        [nzDataSource]="filteredFontSizeOptions"
        #autos></nz-autocomplete>
    </div>
    <textarea
      *ngIf="element.value != '' && !isFormatEachElement"
      nz-input
      placeholder="Text..."
      [(ngModel)]="element.value"
      nzAutosize></textarea>
  </div>
</nz-card>
