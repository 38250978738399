import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DoctorService } from 'src/app/services/doctor.service';
import { ShareService } from 'src/app/services/share.service';
import { VisitService } from 'src/app/services/visit.service';
import { AppState } from '../../app-state/app-state';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-list-visit-calendar',
  templateUrl: './list-visit-calendar.component.html',
  styleUrls: ['./list-visit-calendar.component.scss'],
})
export class ListVisitCalendarComponent implements OnInit {
  //Config
  timeHourIn = 8;
  timeMinIn = 0;
  timeHourOut = 17;
  timeMinOut = 0;
  step = 30;

  pageIndex = 1;
  total = 100;
  pageSize = 3;

  currentTime = new Date();
  time = this.currentTime;

  doctorInGroup = [];
  groupSub: Subscription;
  selectedGroupId = '';
  selectDoctorId = '';
  viewMode = 'day';
  timeSlotsDisplay: any[] = [];
  listDoctorDisplay = [];
  listVisitGroupByDoctorId = [];
  listVisitGroupByDoctorIdDisplay = [];
  itemCount = 0;
  popup;
  isVisibleCalendar = true;
  rightSize = 90;
  leftSize = 10;
  isLoading = false;
  left = 2;
  right = 10;

  constructor(
    private doctorService: DoctorService,
    private store: Store<AppState>,
    private shareService: ShareService,
    private visitService: VisitService,
    private datePipe: DatePipe
  ) {}
  ngOnInit() {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        if (!this.shareService.checkEmpty(data)) {
          this.selectedGroupId = data.groupId;
          this.store
            .select((store) => store.auth.userId)
            .subscribe((res) => {
              if (res) this.selectDoctorId = res;
            });
          this.getDoctorInGroup(this.selectedGroupId);
          this.timeSlotsDisplay = this.getTimeSlots(this.time);
          this.dayView();
        }
      }
    });
  }

  chooseTimeToday() {
    this.time = new Date();
    this.getListVisit(this.selectDoctorId);
  }

  monthView() {
    this.viewMode = 'month';
    this.getListVisit(this.selectDoctorId);
  }

  weekView() {
    this.viewMode = 'week';
    this.getListVisit(this.selectDoctorId);
  }
  dayView() {
    this.viewMode = 'day';
    this.getListVisit(this.selectDoctorId);
  }

  getTimeSlots(dateInput: Date) {
    let timeSlots = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60 / this.step; j++) {
        let slot = new Date(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDate(), i, j * this.step, 0);
        timeSlots.push(slot);
      }
    }
    timeSlots.push(new Date(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDate(), 23, 59, 59, 59));

    let timeIn = new Date(
      dateInput.getFullYear(),
      dateInput.getMonth(),
      dateInput.getDate(),
      this.timeHourIn,
      this.timeMinIn,
      0,
      0
    );
    let timeOut = new Date(
      dateInput.getFullYear(),
      dateInput.getMonth(),
      dateInput.getDate(),
      this.timeHourOut,
      this.timeMinOut,
      0,
      0
    );
    let timeSlotsDisplay = timeSlots.filter((el) => {
      return el >= timeIn && el <= timeOut;
    });
    timeSlotsDisplay.unshift(new Date(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDate(), 0, 0, 0));
    timeSlotsDisplay.push(new Date(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDate(), 23, 59, 59));
    // console.log(timeSlotsDisplay);
    timeSlotsDisplay.map((el) => el.toISOString());
    return timeSlotsDisplay;
  }
  groupById(arr) {
    // console.log(arr);

    let result = {};
    for (let obj of arr) {
      if (!result[obj.doctorId]) {
        result[obj.doctorId] = [obj];
      } else {
        result[obj.doctorId].push(obj);
        result[obj.doctorId].sort((a, b) => (a?.visitDate > b?.visitDate ? 1 : b?.visitDate > a?.visitDate ? -1 : 0));
      }
    }
    // console.log(result);

    return Object.values(result);
  }

  onValueChange(value: Date): void {
    this.time = value;

    this.getListVisit(this.selectDoctorId);
  }

  onPanelChange(change: { date: Date; mode: string }): void {
    // console.log(`Current value: ${change.date}`);
    // console.log(`Current mode: ${change.mode}`);
  }
  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;
        this.listDoctorDisplay = res;
      }
    });
  }
  selectDoctor(data) {
    this.getListVisit(this.selectDoctorId);
  }
  getListVisit(doctorId) {
    this.timeSlotsDisplay = this.getTimeSlots(this.time);
    let from;
    let to;
    // console.log(this.time);

    switch (this.viewMode) {
      case 'day':
        from = new Date(this.time.setHours(0, 0, 0, 0)).toISOString();
        to = new Date(this.time.setHours(23, 59, 59, 59)).toISOString();
        break;
      case 'week':
        const date = new Date(this.time);
        // const date = new Date(d);
        const day = this.time.getDay(); // 👉️ get day of week

        const diff = this.time.getDate() - day + (day === 0 ? -6 : 1);

        const startOfWeek = new Date(date.setDate(diff));

        startOfWeek.setHours(0, 0, 0, 0);

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6);

        endOfWeek.setHours(23, 59, 59, 999);
        from = startOfWeek.toISOString();
        to = endOfWeek.toISOString();
        break;
      case 'month':
        const startOfMonth = new Date(this.time.getFullYear(), this.time.getMonth(), 1);
        const endOfMonth = new Date(this.time.getFullYear(), this.time.getMonth() + 1, 1);
        from = startOfMonth.toISOString();
        to = endOfMonth.toISOString();
        break;
    }
    this.apiGetListVisit(doctorId, from, to);
  }

  apiGetListVisit(doctorId, from, to) {
    // console.log(arguments);
    this.isLoading = true;
    this.pageIndex = 1;
    this.visitService
      .getVisitCalendar({
        doctorId: doctorId,
        groupId: this.selectedGroupId,
        from: from,
        to: to,
      })
      .subscribe(
        (res) => {
          this.itemCount = res.itemCount;
          if (res.source) {
            this.listVisitGroupByDoctorId = this.groupById(res.source);
            this.total = this.listVisitGroupByDoctorId.length;
            this.listVisitGroupByDoctorIdDisplay = this.applyPagination(
              this.listVisitGroupByDoctorId,
              this.pageIndex,
              this.pageSize
            );
            console.log(this.listVisitGroupByDoctorIdDisplay);
          }
        },
        (err) => {
          console.log(err);
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  applyPagination(arr: Array<any>, pageIndex, pageSize) {
    return arr.slice((pageIndex - 1) * pageSize, pageSize * pageIndex);
  }
  pageIndexChange(pageIndex) {
    this.pageIndex = pageIndex;
    this.listVisitGroupByDoctorIdDisplay = this.applyPagination(
      this.listVisitGroupByDoctorId,
      this.pageIndex,
      this.pageSize
    );
  }
  onVisitItemHover(event) {
    let popups = document.querySelectorAll('.popup-patient-info');
    // console.log(popups);

    if (popups) {
      popups.forEach((el) => {
        el.remove();
      });
    }
    if (event.target && event.target.matches('.visit-item')) {
      let dataVisit = JSON.parse(event.target.getAttribute('data-visit'));
      console.log('h',dataVisit);

      this.popup = document.createElement('div');
      this.popup.style.position = 'absolute';
      if (event.clientX < 1500 && event.clientY < 800) {
        this.popup.style.left = `${event.clientX + 30}px`;
        this.popup.style.top = `${event.clientY - 70}px`;
      } else if (event.clientX < 1500 && event.clientY > 800) {
        this.popup.style.left = `${event.clientX + 30}px`;
        this.popup.style.top = `${event.clientY - 150}px`;
      } else if (event.clientX > 1500 && event.clientY < 800) {
        this.popup.style.left = `${event.clientX - 280}px`;
        this.popup.style.top = `${event.clientY - 70}px`;
      } else {
        this.popup.style.left = `${event.clientX - 280}px`;
        this.popup.style.top = `${event.clientY - 150}px`;
      }
      // this.popup.style.left = `${event.clientX}px`;
      // this.popup.style.top = `${event.clientY}px`;
      this.popup.style.backgroundColor = '#e1f7f3';//#bbf2e7';
      this.popup.style.padding = '25px';
      this.popup.style.boxShadow = '0 4px 6px rgba(0,0,0,0.3)';
      this.popup.style.borderRadius = '5px';
      this.popup.classList.add('popup-patient-info');
      this.popup.innerHTML = `
        <div style="display: flex; align-items: center; margin-bottom: 6px;">

          <p style="margin: 0; color: var(--dp-primary-2);"><b>${dataVisit?.patientName}</b></p>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 16px;">

          <p style="margin: 0;">${dataVisit?.patientGender == 0 ? 'Nam' : 'Nữ'}, ${new DatePipe('en-Us').transform(dataVisit?.patientDOB, 'dd/MM/yyy')}</p>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 6px;">
         <img src="https://cdn-icons-png.flaticon.com/512/10036/10036113.png" alt="" style="width: 15px;">
          <p style="margin: 0;  margin-top: 5px; margin-left: 5px">${new DatePipe('en-Us').transform(dataVisit?.visitDate, 'dd/MM HH:mm')} </p>
        </div>
      <div style="display: flex; align-items: center; margin-bottom: 6px;">
        <img src="https://cdn-icons-png.flaticon.com/512/2904/2904859.png" alt="" style="width: 15px;">
        <p style="margin: 0; margin-top: 4px; margin-left: 5px">${dataVisit?.visitReason}</p>
      </div>
      <div style="display: flex; align-items: center; margin-bottom: 8px;">
        <img src="https://cdn-icons-png.flaticon.com/512/709/709145.png" style="width: 15px;"/>
        <p style="margin: 0; margin-top: 5px; margin-left: 5px">${dataVisit?.doctorName}</p>
      </div>
      `;
      document.body.appendChild(this.popup);
    }
  }

  onVisitItemLeave(event) {
    let popups = document.querySelectorAll('.popup-patient-info');

    // event.target.style.color = 'var(--dp-primary-2)';

    if (popups) {
      popups.forEach((el) => {
        el.remove();
      });
    }
  }
  onClickDateofWeek(data) {
    // console.log(data);
    this.time = data.day;
    this.viewMode = 'day';

    this.getListVisit(data.doctorId);
  }
  prevDay() {
    this.time = new Date(this.time.getFullYear(), this.time.getMonth(), this.time.getDate() - 1);

    this.getListVisit(this.selectDoctorId);
  }
  nextDay() {
    this.time = new Date(this.time.getFullYear(), this.time.getMonth(), this.time.getDate() + 1);
    this.getListVisit(this.selectDoctorId);
  }
  toggleCalendar() {
    this.isVisibleCalendar = !this.isVisibleCalendar;
  }
}
