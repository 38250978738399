import { Patient } from './../../../../models/patient.class';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as fromRoot from '../../../app-state/app-state';
import * as modalAction from './modal.action';
import {
  createFeatureSelector,
  createSelector,
  createAction,
  props,
} from '@ngrx/store';
import { ModalState } from '../../../../models/modal-item.model';

export const modalAdapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const defaultModal: ModalState = {
  // ids: [],
  // entities: {},
  isVisitOpen: false,
  isPatientOpen: false,
  isDuplicateOpen: false,
  isAddAppointmentOpen: false,
  tabId: '',
  appointmentId: '',
  patient: {},
  isVisitUpdateOpen: false,
};

/// Helper function to create new state object
const newState = (state, newData) => Object.assign({}, state, newData);

// export const initialState = modalAdapter.getInitialState(defaultModal);
export function modalReducer(
  state = defaultModal,
  action: modalAction.ModalActions
) {
  switch (action.type) {
    case modalAction.ModalAction.OpenAddPatient: {
      return newState(state, { isPatientOpen: true });
    }

    case modalAction.ModalAction.CloseAddPatient: {
      return newState(state, { isPatientOpen: false });
    }

    case modalAction.ModalAction.OpenAddVisit: {
      return newState(state, {
        isVisitOpen: true,
        tabId: action.tabId,
        patient: action.patient,
        appointmentId: action.appointmentId,
      });
    }

    case modalAction.ModalAction.CloseAddVisit: {
      return newState(state, { isVisitOpen: false });
    }

    case modalAction.ModalAction.OpenDuplicatePatient: {
      return newState(state, { isDuplicateOpen: true });
    }

    case modalAction.ModalAction.CloseDuplicatePatient: {
      return newState(state, { isDuplicateOpen: false });
    }

    case modalAction.ModalAction.OpenAddAppointment: {
      return newState(state, { isAddAppointmentOpen: true });
    }

    case modalAction.ModalAction.CloseAddAppointment: {
      return newState(state, { isAddAppointmentOpen: false });
    }

    case modalAction.ModalAction.ConvertAppointmentToVisit: {
      return newState(state, {
        isAddAppointmentOpen: false,
        patient: action.payload,
      });
    }

    case modalAction.ModalAction.OpenAddVisitUpdate: {
      console.log('OpenAddVisitUpdate');
      return newState(state, {
        isVisitUpdateOpen: true,
        tabId: action.tabId,
        patient: action.patient,
        appointmentId: action.appointmentId,
      });
    }

    case modalAction.ModalAction.CloseAddVisitUpdate: {
      console.log('CloseAddVisitUpdate');
      return newState(state, { isVisitUpdateOpen: false });
    }

    default:
      return state;
  }
}

// const getModalFeatureState = createFeatureSelector<ModalState>(
//     'modals'
// );

// export const getModal = createSelector(
//     getModalFeatureState,
//     modalAdapter.getSelectors().selectAll
// );

// export const getAddVisit = createSelector(
//     getModalFeatureState,
//     (state: ModalState) => state.isVisitOpen
// );

// export const getAddPatientOpen = createSelector(
//     getModalFeatureState,
//     (state: ModalState) => state.isPatientOpen
// );

// export const getDuplicateModal = createSelector(
//     getModalFeatureState,
//     (state: ModalState) => state.isDuplicateOpen
// );
