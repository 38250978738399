<nz-layout class="auth-layout">
  <nz-header class="head-content">
    <div class="head-left"></div>
    <div *ngIf="!isMobile" class="head-right"></div>

    <div class="pmr-mark">
      <div style="display: flex">
        <img src="assets/image/auth/colored-logo.svg" style="height: 62px; width: 62px; margin-right: 25px" />
        <div id="title-pmr">PMR</div>
        <div *ngIf="isMobile" id="app-name">Doctor Portal</div>
      </div>
    </div>
  </nz-header>
  <nz-content class="body-content customTableCrollbar">
    <div class="div-left" *ngIf="!isMobile">
      <div class="slogan">
        <div class="title-name">Doctor Portal Patient Medical Record</div>
        <span class="title-head">
          Không có gì quý giá hơn là sức khỏe tốt. Đó chính là tài sản giá trị nhất của một con người.
        </span>
      </div>
    </div>

    <div class="main-login">
      <div class="auth-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </nz-content>

  <nz-footer class="footer-content">
    <img class="div-left-btm" src="assets/image/auth/login-frame1.svg" />
    <div class="div-right-btm"></div>
  </nz-footer>
</nz-layout>
