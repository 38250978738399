import { Directive, Input, ElementRef, HostListener, OnInit, ChangeDetectorRef } from '@angular/core';
import { TabService } from './tab.service';
import { BehaviorSubject } from 'rxjs';
declare let $: any;

@Directive({
  selector: '[tabIndex]',
})
export class TabDirective implements OnInit {
  private _index: number;

  get index() {
    return this._index;
  }
  @Input()
  set index(i: any) {
    this._index = i;
  }
  @Input() colspan: number;

  @HostListener('keydown', ['$event'])
  onInput(e: any) {
    if (e.which === 37) {
      //left
      e.preventDefault();
      this.tabService.selectedInput.next(this.index - 1);
    }
    if (e.which === 39) {
      //right
      e.preventDefault();
      this.tabService.selectedInput.next(this.index + 1);    }
    if (e.which === 38) {
      //up
      e.preventDefault();
      this.tabService.selectedInput.next(this.index - this.colspan);
    }
    if (e.which === 40) {
      e.preventDefault();
      this.tabService.selectedInput.next(this.index + this.colspan);
    }
  }
  constructor(private el: ElementRef, private tabService: TabService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.tabService.selectedInput.subscribe((i) => {
      if (i === this.index) {
        this.el.nativeElement.click();
        if (this.el.nativeElement.tagName == 'INPUT') {
          this.el.nativeElement.focus();
        }

        this.cdr.detectChanges();
      }
    });
  }
}
