import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root'
})
export class CriteriaService extends BaseService{
  getParameter(): Observable<any> {
    return this.get(UrlConstant.LIST_PARAMETER_FOR_GROUP);
  }
  getFieldParameter(payload): Observable<any> {
    return this.get(UrlConstant.LIST_FIELD + `/GetParameters/${payload}`);
  }
}
