import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Visits } from 'src/app/models/listVisit.class';
import { GroupTreeService } from 'src/app/services/groupTree.service';
import { ShareService } from 'src/app/services/share.service';
import { FormComponent } from '../../form/form.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
@Component({
  selector: 'app-visit-summary',
  templateUrl: './visit-summary.component.html',
  styleUrls: ['./visit-summary.component.scss'],
})
export class VisitSummaryComponent implements OnInit, OnDestroy {
  faDownload = faDownload;
  @Input() visit: Visits;
  formId;
  recordId;
  isProcessing = false;
  groupSub: Subscription;
  lstForms: any[] = [
    {
      id: '60584edcf437dd70c53a3fdc',
      name: 'mark 1 - 22/03',
    },
    {
      id: '60584b96f437dd70c53a3fd9',
      name: 'mark 2',
    },
    {
      id: '60581b04cc64e21566d829d4',
      name: 'mark 3',
    },
  ];
  @Input() hideButton: string;
  @Input() disabledClass: string;
  @Output() saveRecord = new EventEmitter<any>();
  @ViewChild('formContentData') formElm: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  options = {
    backgroundColor: null,
    allowTaint: true,
    useCORS: true,
    logging: true,
    onrendered(canvas) {
      document.body.appendChild(canvas);
    },
  };
  constructor(
    private groupTreeService: GroupTreeService,
    private shareService: ShareService,
    private store: Store<AppState>
  ) {}

  @ViewChild('form') form: FormComponent;

  ngOnInit() {
    this.getForm();
  }

  convertName() {
    const name = this.visit.patientName.replace(' ', '_').toUpperCase();

    return this.shareService.toNomalize(name);
  }

  downloadImage() {
    const name = this.convertName();
    this.form.downloadImage(name);
  }

  getForm() {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        const groupId = data.groupId;
        if (!this.shareService.checkEmpty(groupId)) {
          this.groupTreeService.getGroup(groupId).subscribe((response) => {
            this.lstForms = response.formReportIds;
          });
        }
      }
    });
  }

  print() {
    this.form.print();
  }

  processDone() {
    this.isProcessing = false;
  }
  ngOnDestroy(): void {
    this.groupSub.unsubscribe();
  }
}
