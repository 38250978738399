import { NotificationService } from 'src/app/services/notification.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Visits } from 'src/app/models/listVisit.class';
import { DoctorService } from 'src/app/services/doctor.service';
import { MedicalInstructionService } from 'src/app/services/medical-instruction.service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-command-modal',
  templateUrl: './command-modal.component.html',
  styleUrls: ['./command-modal.component.scss'],
})
export class CommandModalComponent implements OnInit {
  _isVisible: boolean;

  @Input() set isVisible(val: boolean) {
    if (val) {
      this.getUserInGroup(this.visit.groupId);
      this.getMedicalOrderSource(this.visit.groupId);
    }
    this._isVisible = val;
  }

  get isVisible() {
    return this._isVisible;
  }

  @Input() isAddOder = false;
  @Input() visit: Visits;
  // total: number = 0;
  @Input() currentOrder = {
    medicalOrderId: '',
    id: '',
    name: '',
    desc: '',
    executorId: '',
    executorName: '',
    executeDate: null,
    otherInfo: '',
    status: 1,
    statusText: '',
    creatorName: '',
    creatorId: '',
    orderResponse: [],
  };
  lstOrderSource: any[] = [];
  userInGroup: any[] = [];

  @Output() isVisibleChange: EventEmitter<any> = new EventEmitter();
  @Output() reloadData: EventEmitter<any> = new EventEmitter();
  constructor(
    private medicalInstructionService: MedicalInstructionService,
    private doctorService: DoctorService,
    private shareService: ShareService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {}

  cancelAdd() {
    this.isVisibleChange.emit(false);
  }

  getMedicalOrderSource(groupId) {
    this.medicalInstructionService.getByGroupId(groupId).subscribe((res) => {
      this.lstOrderSource = res.data;
    });
  }

  getUserInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.userInGroup = res;
      }
    });
  }

  selectOrderChange(event) {
    const item = this.lstOrderSource.filter((en) => en.id == event)[0];
    this.currentOrder.name = item.name;
    this.currentOrder.desc = item.description;
  }

  saveMedicalOrder() {
    const data = this.currentOrder;
    if (
      this.shareService.checkEmptyStr(data.medicalOrderId) ||
      this.shareService.checkEmptyStr(data.name)
    ) {
      this.notificationService.showNotification(
        Constant.ERROR,
        'Bạn cần nhập các trường bắt buộc!'
      );
      return;
    }
    const payload = {
      id: this.currentOrder.id,
      medicalOrderId: this.currentOrder.medicalOrderId,
      visitId: this.visit.id,
      name: this.currentOrder.name,
      desc: this.currentOrder.desc,
      executorId: this.currentOrder.executorId,
      executeDate: this.currentOrder.executeDate,
      otherInfo: this.currentOrder.otherInfo,
    };
    if (this.currentOrder.id == '') {
      this.medicalInstructionService.add2Visit(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(
            Constant.SUCCESS,
            'Thêm y lệnh thành công!'
          );
          this.isVisibleChange.emit(false);
          this.reloadData.emit();
        } else {
          this.notificationService.showNotification(
            Constant.ERROR,
            res.errors[0].errorMessage
          );
        }
      });
    } else {
      this.medicalInstructionService
        .updateInfo2Visit(payload)
        .subscribe((res) => {
          if (res.isValid) {
            this.notificationService.showNotification(
              Constant.SUCCESS,
              'Cập nhật thành công!'
            );
            this.isVisibleChange.emit(false);
            this.reloadData.emit();
          } else {
            this.notificationService.showNotification(
              Constant.ERROR,
              res.errors[0].errorMessage
            );
          }
        });
    }
  }
}
