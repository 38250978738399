import { Action } from '@ngrx/store';
import { GroupStateModel } from '../../../models/group-state.model';

export enum GroupAuthActionTypes {
  Join = '[GroupAuth] Join Group',
  // Leave = '[GroupAuth] Leave Group',
  //JoinSuccess = '[GroupAuth] JoinSuccess',
  //JoinError = '[GroupAuth] JoinError',
}

export class Join implements Action {
  public readonly type = GroupAuthActionTypes.Join;
  // constructor(public payload: string) {}
  constructor(public payload: any) {}
}
// export class Leave implements Action {
//   public readonly type = GroupAuthActionTypes.Leave;
// // }
// export class JoinSuccess implements Action {
//   public readonly type = GroupAuthActionTypes.JoinSuccess;
//   constructor(public payload: GroupStateModel) {}
// }
// export class JoinError implements Action {
//   public readonly type = GroupAuthActionTypes.JoinError;
//   constructor(public error: any) {}
// }

export type GroupAuthActions = Join;
// | Leave
// | JoinSuccess
// | JoinError
