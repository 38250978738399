<!-- Header khi hiển thị thông tin -->
<table class="table-patient" style="width: 100%" *ngIf="!enableEditPatientInfo">
  <thead>
    <tr>
      <td style="width: 110px"></td>
      <td style="width: 150px"></td>
      <td style="width: 150px"></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  </thead>
  <tr>
    <td rowspan="3">
      <div class="avatar">
        <div
          class="imgPatient"
          style="font-size: 50px; color: white; font-weight: 500"
          *ngIf="
            patient.color &&
            patient.shortName &&
            (!patient.avatar || patient.avatar == '')
          "
          [ngStyle]="{ 'background-color': patient.color }">
          {{ shortName(patient.fullName) }}
        </div>
        <div
          class="imgPatient"
          *ngIf="
            !patient.color &&
            !patient.shortName &&
            (!patient.avatar || patient.avatar == '')
          "
          style="
            background-image: url('/assets/image/avt-default2.png');
            background-repeat: round;
          "></div>
        <div
          class="imgPatient"
          *ngIf="patient.avatar && patient.avatar != ''"
          [ngStyle]="{
            'background-image': 'url(' + (baseAvatarurl + patient.avatar) + ')'
          }"></div>
      </div>
    </td>
    <td title="" colspan="2" class="td_name pdl-15">
      {{ patient.fullName }}
      <i style="margin-left: 10px; font-size: 20px">
        ({{ patient.sex | genderText }} | {{ patient.dob | ageStringFromDOB }}
        )
      </i>
    </td>
    <td class="pdl-30" style="width: 50px">Bs chính:</td>
    <td class="td_content">{{ mainDoctor.fullName }}</td>
    <td class="pdl-15" style="width: 20px">
      <img src="assets/image/DPicon/icon-phone-green.svg" />
    </td>
    <td class="td_content">{{ patient.phoneNo }}</td>
    <td>
      <button class="link_btn" (click)="editPatientInfo()">
        <img class="edit-patient" src="assets/image/DPicon/edit-pencil-2.svg" />
      </button>
    </td>
  </tr>
  <tr>
    <td class="pdl-15">Mã bệnh nhân:</td>
    <td class="td_content" style="width: 350px">{{ patient.code }}</td>

    <td class="pdl-30" style="width: 50px">Bs giới thiệu:</td>
    <td class="td_content" style="width: 350px">
      {{ presenterDoctor.fullName }}
    </td>
    <td class="pdl-15" style="width: 20px">
      <img class="image-icon" src="assets/image/DPicon/icon-mail-green.svg" />
    </td>
    <td class="td_content">
      {{ patient.email }}
    </td>
    <td>
      <button class="link_btn" (click)="showModal()">
        <!-- {{profileStatus}} -->
        <img src="assets/image/send-info.svg" />
      </button>
    </td>
  </tr>
  <tr>
    <td class="pdl-15">CMND/TCC:</td>
    <td class="td_content">{{ patient.idNumber }}</td>
    <td></td>
    <td></td>
    <td class="pdl-15" style="width: 20px">
      <img src="assets/image/DPicon/icon-address-green.svg" />
    </td>
    <td class="td_content">{{ patient.address }}</td>
    <td>
      <button class="link_btn">
        <img class="edit-patient" src="assets/image/image-icon.svg" />
      </button>
    </td>
  </tr>
</table>

<!-- Header khi chỉnh sửa, cập nhật thông tin -->
<table class="table-patient" style="width: 100%" *ngIf="enableEditPatientInfo">
  <thead>
    <tr>
      <td style="width: 110px"></td>
      <td style="width: 150px"></td>
      <td style="width: 150px"></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  </thead>
  <tr>
    <td rowspan="3">
      <div class="avatar">
        <div
          class="imgPatient"
          style="font-size: 50px; color: white; font-weight: 500"
          *ngIf="
            patient.color &&
            patient.shortName &&
            (!patient.avatar || patient.avatar == '')
          "
          [ngStyle]="{ 'background-color': patient.color }">
          {{ shortName(patient.fullName) }}
        </div>
        <div
          class="imgPatient"
          *ngIf="
            !patient.color &&
            !patient.shortName &&
            (!patient.avatar || patient.avatar == '')
          "
          style="
            background-image: url('/assets/image/avt-default2.png');
            background-repeat: round;
          "></div>
        <div
          class="imgPatient"
          *ngIf="patient.avatar && patient.avatar != ''"
          [ngStyle]="{
            'background-image': 'url(' + (baseAvatarurl + patient.avatar) + ')'
          }"></div>
      </div>
    </td>
    <td class="pdl-15">Tên BN:</td>
    <td class="td_content cao">
      <input nz-input [(ngModel)]="patient.fullName" />
    </td>
    <!-- <td class="td_content">{{patient.fullName}}</td> -->
    <td></td>
    <td style="width: 20%">
      <nz-select
        style="width: 47%"
        nz-menu
        [nzPlaceHolder]="'Giới tính'"
        [ngClass]="{ 'is-invalid': submitted && f.sex.errors }"
        [(ngModel)]="patient.sex">
        <nz-option [nzValue]="1" nzLabel="Nam"></nz-option>
        <nz-option [nzValue]="0" nzLabel="Nữ"></nz-option>
        <nz-option [nzValue]="2" nzLabel="Không xác định"></nz-option>
      </nz-select>
      <nz-date-picker
        style="width: 47%; margin-left: 6%"
        [nzPlaceHolder]="'DD/MM/YYYY'"
        nzFormat="dd/MM/yyyy"
        [ngClass]="{ 'is-invalid': submitted && f.dob.errors }"
        [(ngModel)]="patient.dob">
      </nz-date-picker>
    </td>
    <td class="pdl-30">Số điện thoại:</td>
    <td class="td_content">
      <input
        nz-input
        [ngClass]="{ 'is-invalid': submitted && patient.phoneNo.errors }"
        [(ngModel)]="patient.phoneNo" />
    </td>
    <td>
      <button class="mgr15" (click)="saveEditPatientInfo()">
        <img class="update-info" src="assets/image/updated.svg" />
      </button>
    </td>
  </tr>
  <tr style="height: 60px">
    <td class="pdl-15">Mã bệnh nhân:</td>
    <td class="td_content">{{ patient.code }}</td>
    <td class="pdl-30">Bs chính:</td>
    <td class="td_content cao">
      <div class="message-required" *ngIf="submitted && f.doctorId.errors">
        <div *ngIf="f.doctorId.errors.required">
          <i
            nz-icon
            nzType="close-circle"
            [nzTheme]="'twotone'"
            [nzTwotoneColor]="'#FF0000'"
            style="vertical-align: baseline"></i>
          Bạn chưa chọn Bác sĩ chính
        </div>
      </div>
      <div>
        <nz-select
          [nzPlaceHolder]="'Chọn bác sĩ'"
          [(ngModel)]="mainDoctor"
          [ngClass]="{ 'is-invalid': submitted && f.doctorId.errors }">
          <nz-option
            *ngFor="let item of doctorInGroup"
            [nzLabel]="item.fullName"
            [nzValue]="item">
          </nz-option>
        </nz-select>
      </div>
    </td>
    <td class="pdl-30">Email:</td>
    <td class="td_content cao">
      <input nz-input [(ngModel)]="patient.email" />
    </td>
    <td>
      <button class="buttonCancel" (click)="cancelEditPatientInfo()">
        <img class="cancel-info" src="assets/image/cancel.svg" />
      </button>
    </td>
  </tr>
  <tr style="margin-top: 15px">
    <td class="pdl-15">CMND/TCC:</td>
    <td style="width: 15%" class="td_content cao">
      <input nz-input [(ngModel)]="patient.idNumber" />
    </td>
    <td class="pdl-30">Bs giới thiệu:</td>
    <td class="td_content cao">
      <div [(ngModel)]="patient.presenterDoctor">
        <div class="message-required" *ngIf="submitted && f.doctorId.errors">
          <div *ngIf="f.doctorId.errors.required">
            <i
              nz-icon
              nzType="close-circle"
              [nzTheme]="'twotone'"
              [nzTwotoneColor]="'#FF0000'"
              style="vertical-align: baseline"></i>
            Bạn chưa chọn Bác sĩ giới thiệu
          </div>
        </div>
        <div>
          <nz-select
            [nzPlaceHolder]="'Chọn bác sĩ'"
            [(ngModel)]="presenterDoctor"
            [ngClass]="{ 'is-invalid': submitted && f.doctorId.errors }">
            <nz-option
              *ngFor="let item of doctorInGroup"
              [nzLabel]="item.fullName"
              [nzValue]="item">
            </nz-option>
          </nz-select>
        </div>
      </div>
    </td>
    <td class="pdl-30" style="width: 50px">Địa chỉ:</td>
    <td class="td_content cao">
      <input nz-input [(ngModel)]="patient.address" />
    </td>
    <td></td>
  </tr>
</table>

<!-- show when window.innerWidth < 1200  -->
<div class="sm-screen-1200">
  <div>
    <span class="td_name">
      {{ patient.fullName }}
      <i style="margin-left: 10px">
        ({{ patient.sex | genderText }} | {{ patient.dob | ageStringFromDOB }}
        {{ patient.dob ? ' | ' + (patient.dob | date: 'dd/MM/yyyy') : '' }} )
      </i>
    </span>
    <span class="see-more" (click)="moreInfo()">
      <i
        nz-icon
        [nzType]="!openMore ? 'caret-down' : 'caret-up'"
        nzTheme="outline"></i>
    </span>
  </div>
  <div *ngIf="openMore">
    <table>
      <tr>
        <td>Mã bệnh nhân:</td>
        <td class="td_content">{{ patient.code }}</td>
        <td>CMND/TCC:</td>
        <td class="td_content">{{ patient.idNumber }}</td>
      </tr>
      <tr>
        <td>Bs chính:</td>
        <td class="td_content"></td>
        <td>Bs giới thiệu:</td>
        <td class="td_content"></td>
      </tr>
      <tr>
        <td>
          <img src="assets/image/DPicon/icon-phone-green.svg" /><span
            class="td_content"
            >{{ patient.phoneNo }}</span
          >
        </td>
        <td>
          <img src="assets/image/DPicon/icon-mail-green.svg" /><span
            class="td_content"
            >{{ patient.email }}</span
          >
        </td>
        <td>
          <img src="assets/image/DPicon/icon-address-green.svg" /><span
            class="td_content"
            >{{ patient.address }}</span
          >
        </td>
      </tr>
      <tr>
        <td>
          <button
            class="link_btn"
            *ngIf="!enableEditPatientInfo"
            (click)="editPatientInfo()">
            Sửa hồ sơ BN
          </button>
        </td>
        <button class="link_btn" (click)="showModal()">
          {{ profileStatus }}
        </button>
        <td><button class="link_btn">Xem ảnh bổ sung ()</button></td>
      </tr>
    </table>
  </div>
</div>

<!-- popup gửi thông tin BN -->
<app-patient-profile
  [patient]="patient"
  [visitId]="visitId"
  [isVisible]="isVisible"
  (closeProflie)="closeProflie()"
  (profileStatus)="getStatus($event)"
  (changeEmail)="changeEmail($event)"></app-patient-profile>
