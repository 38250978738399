<div class="pd-10 visit-info-cpm card" style="border-top: 0px; overflow: auto; max-height: calc(-323px + 100vh)">
  <div nz-row class="mg-bt-8">
    <div nz-col nzSpan="4" class="info-label">Triệu chứng lâm sàng</div>
    <div nz-col nzSpan="20" class="position-relative">
      <textarea nz-input [disabled]="disabledEditInfo" nzAutosize [(ngModel)]="visit.clinicalInfomation"
        (ngModelChange)="onTypingClinicalInfo($event)">
        </textarea>
      <span nz-icon nz-tooltip nzTooltipTitle="Lưu" nzType="save" class="position-absolute icon-save"  (click)="clickTosaveVisitInfo('clinicalInfomation')"></span>
    </div>
  </div>
  <div nz-row class="mg-bt-8">
    <div nz-col nzSpan="4" class="info-label">Chẩn đoán ban đầu</div>
    <div nz-col nzSpan="20" class="position-relative">
      <textarea nz-input [disabled]="disabledEditInfo" nzAutosize [(ngModel)]="visit.diagnoseInit"
        (ngModelChange)="onTypingDiagnoseInit($event)">
        </textarea>
        <span nz-icon nz-tooltip nzTooltipTitle="Copy chẩn đoán ban đầu" nzType="copy" class="position-absolute icon-copy"
          (click)="copyDiagnoseInit()"></span>
      <span nz-icon nz-tooltip nzTooltipTitle="Lưu" nzType="save" class="position-absolute icon-save"  (click)="clickTosaveVisitInfo('diagnoseInit')"></span>
    </div>
  </div>
  <div nz-row class="mg-bt-8">
    <div nz-col nzSpan="4" class="info-label">Chẩn đoán ICD 10</div>
    <nz-select [disabled]="disabledEditInfo" nz-col nzSpan="8" [nzShowSearch]="true" [nzShowArrow]="true"
      [nzServerSearch]="true" [(ngModel)]="suggestDiseases" nzPlaceHolder="Tìm kiếm..."
      (nzOnSearch)="onTypingICD($event)" (ngModelChange)="fieldSelectedICD10($event)">
      <nz-option *ngFor="let item of diseasesData" nzCustomContent [nzLabel]="item.code" [nzValue]="item">
        <div>{{ item.code }} - {{ item.name }}</div>
      </nz-option>
    </nz-select>
    <div nz-col nzSpan="12">
      <li *ngFor="let disease of visit.diseases" class="li-disease">
        {{ disease.code }} - {{ disease.name }}
        <i *ngIf="!disabledEditInfo" nz-icon nzType="close" nzTheme="outline" style="float: right"
          (click)="removeDiseases($event, disease.id)"></i>
      </li>
    </div>
  </div>
  <div nz-row class="mg-bt-8">
    <div nz-col nzSpan="4" class="info-label">Kết luận của BS</div>
    <div nz-col nzSpan="20" class="position-relative">
      <textarea nz-input [disabled]="disabledEditInfo" nzAutosize [(ngModel)]="visit.conclusion"
        (ngModelChange)="onTypingConclusion($event)"></textarea>
        <span nz-icon nz-tooltip nzTooltipTitle="Lưu" nzType="save" class="position-absolute icon-save"  (click)="clickTosaveVisitInfo('conclusion')"></span>
    </div>
  </div>
  <div nz-row class="mg-bt-8">
    <div nz-col nzSpan="4" class="info-label">Chỉ định CLS</div>
    <div nz-col nzSpan="20">
      <nz-select [nzDisabled]="disabledEditInfo" style="width: 120px" nzShowSearch nzAllowClear
        nzPlaceHolder="Tìm kiếm để thêm..." [nzDropdownStyle]="{ width: '500px' }" [(ngModel)]="filterSubItem"
        (ngModelChange)="addSubOrder($event)">
        <nz-option *ngFor="let sub of subOrderSource" [nzValue]="sub.id"
          [nzLabel]="hienGiaDichVuChiDinh ? sub.name + ' - '+ (sub.serviceCost|currencyFormat) : sub.name"></nz-option>
      </nz-select>
      <button nz-button [disabled]="disabledEditInfo" class="mg-l-10"
        (click)="showModalAddSubOrder(rosConstant.Imaging)">
        CĐHA
      </button>
      <button nz-button [disabled]="disabledEditInfo" class="mg-l-10"
        (click)="showModalAddSubOrder(rosConstant.Laboratory)">
        Xét nghiệm
      </button>
      <button nz-button [disabled]="disabledEditInfo" class="mg-l-10" (click)="showModalSubOrderGroup()">Nhóm</button>
      <button nz-button [disabled]="disabledEditInfo" class="mg-l-10" (click)="showAddService()">DV khám thêm</button>
      <button nz-button [disabled]="disabledEditInfo" class="mg-l-10" (click)="showVisitOtherInfo()">Thông tin khác</button>
      <button nz-button [disabled]="disabledEditInfo" class="mg-l-10" (click)="showModalAddVS(null)">Thêm thông tin lâm sàng
        <img style="width: 20px; height: 20px; margin-left: 10px;" src="assets/image/DPicon/icon-add-green.svg" />
      </button>
    </div>
  </div>
  <div nz-row class="mg-bt-8">
    <div nz-col [nzSpan]="isShowRelatedVisit ? 16 : 24">
      <nz-table #tableOrder nzBordered nzSize="small" [nzData]="subClinicalVisit" [nzShowPagination]="false"
        [nzFrontPagination]="false" class="primary-table">
        <thead>
          <tr>
            <th nzWidth="40px">STT</th>
            <th>Dịch vụ</th>
            <th>Trạng thái</th>
            <th>Người tạo</th>
            <th>T/gian tạo</th>
            <th class="text-align-center">SL</th>
            <th class="text-nowrap" [ngClass]="{'d-none':!hienGiaDichVuChiDinh}">Đơn giá</th>
            <th class="text-nowrap" [ngClass]="{'d-none':!hienGiaDichVuChiDinh}">Thành tiền</th>
            <th class="text-nowrap">Bỏ in
              <label nz-checkbox [(ngModel)]="notPrintAll" (ngModelChange)="notPrintAllChange($event)"></label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of tableOrder.data; let i = index" class="tr-order" [ngClass]="{
              'coral': data.discount > 0,
              'td-italic': data.isOtherVisit
            }" [title]="data.discount | currencyFormat | discountStr" (contextmenu)="contextMenu($event, menu, data)">
            <ng-container *ngIf="data.status != cancelSubclinicalStatus">
            <td>{{ i + 1 }}</td>
            <td>{{ data.name }}</td>
            <td>{{ data.statusString }}</td>
            <td>{{ data.creatorName }}</td>
            <td>{{ data.createdDate | date: 'dd/MM/yyyy HH:mm' }}</td>
            <td class="text-align-center">
              <div *ngIf="!data.isEditing" id="sub-{{ data.id }}" class="btn-amount" (click)="switchStatus(data)">
                {{ data.amount }}
              </div>
              <div *ngIf="data.isEditing" class="btn-amount">
                <input nz-input type="number" min="1" [(ngModel)]="data.amount"
                  (clickOutside)="updateAmount($event, data.id)" />
              </div>
            </td>
            <td class="text-nowrap text-right" [ngClass]="{'d-none':!hienGiaDichVuChiDinh}">
              {{ data.price | currencyFormat }}
            </td>
            <td class="td-into-money" [ngClass]="{'d-none':!hienGiaDichVuChiDinh}">
              {{ data.finalFee | currencyFormat }}
            </td>
            <td class="text-center">
              <label *ngIf="!data.isDisabled" nz-checkbox [(ngModel)]="data.notPrint" (ngModelChange)="notPrintChildChange($event)"></label>
            </td>
            </ng-container>

          </tr>
        </tbody>
      </nz-table>
    </div>
    <div *ngIf="isShowRelatedVisit" class="pdl8" nz-col [nzSpan]="isShowRelatedVisit ? 8 : 24">
      <nz-table #tableRelated nzBordered nzSize="small" [nzData]="lstRelatedVisitDisplay" [nzShowPagination]="false"
        [nzFrontPagination]="false" class="primary-table">
        <thead>
          <tr>
            <th>
              {{ visit.refVisitId ? 'Các ca khám liên quan' : 'Dịch vụ khám thêm' }}
            </th>
            <th class="text-nowrap" [ngClass]="{'d-none':!hienGiaDichVuChiDinh}">Giá tiền</th>
            <th class="text-nowrap text-center">
              Bỏ in
              <label nz-checkbox [(ngModel)]="notPrintAll2" (ngModelChange)="notPrintAllChange2($event)"></label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of tableRelated.data; let i = index" (click)="openRelatedVisit(data)" (contextmenu)="contextMenu2($event, menu2, data)">
            <td>
              <div>{{ data.serviceName }}</div>
              <div class="italic fs-12">{{ data.doctorName }}</div>
            </td>
            <td class="text-nowrap text-right" [ngClass]="{'d-none':!hienGiaDichVuChiDinh}">
              {{ data.servicePrice | currencyFormat }}
            </td>
            <td class="text-center">
              <label *ngIf="!data.isDisabled" nz-checkbox [(ngModel)]="data.notPrint" (ngModelChange)="notPrintChildChange2($event)"></label>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>

<!-- context menu -->
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu *ngIf="seletedSubVisit">
    <li nz-menu-item (click)="switchStatus(seletedSubVisit)" id="memu-context">
      <i nz-icon nzType="edit" nzTheme="outline" class="mg-r-4"></i>
      Sửa số lượng
    </li>
    <li *ngIf="seletedSubVisit.canRemove" nz-menu-item (click)="confirmCanceled(seletedSubVisit)">
      <i nz-icon nzType="delete" nzTheme="outline" class="mg-r-4"></i>
      Hủy chỉ định
    </li>
    <li *ngIf="seletedSubVisit.canDiscount" nz-menu-item (click)="openDiscount(seletedSubVisit)">
      <i nz-icon nzType="fall" nzTheme="outline" class="mg-r-4"></i>
      Nhập giảm giá
    </li>
  </ul>
</nz-dropdown-menu>
<nz-dropdown-menu #menu2="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="openDiscountExploreMore(seletedRelatedVisit)">
      <i nz-icon nzType="fall" nzTheme="outline" class="mg-r-4"></i>
      Nhập giảm giá
    </li>
    <li nz-menu-item (click)="confirmCanceledRelate()">
      <i nz-icon nzType="delete" nzTheme="outline" class="mg-r-4"></i>
      Hủy chỉ định khám thêm
    </li>
  </ul>
</nz-dropdown-menu>


<!-- Khám thêm -->
<nz-modal [(nzVisible)]="visibleAddService" nzTitle="Chỉ định dịch vụ khám thêm" nzMaskClosable="false"
  (nzOnCancel)="closeAddService()">
  <div class="row mg-bt-10">
    <div class="col-4">Dịch vụ khám <span class="red-dot">*</span></div>
    <div class="col-8">
      <nz-select class="w-100" [(ngModel)]="objService.serviceId" [nzShowSearch]="true" nzServerSearch
        (nzOnSearch)="searchExaminationService($event)">
        <nz-option *ngFor="let service of services"
          [nzLabel]="hienGiaDichVuChiDinh ?service.name +' - '+ (service.price|currencyFormat):service.name "
          [nzValue]="service.id"> </nz-option>
      </nz-select>
    </div>
  </div>
  <div class="row">
    <div class="col-4">Phòng khám <span class="red-dot">*</span></div>
    <div class="col-8">
      <nz-select class="w-100" [(ngModel)]="objService.roomId" [nzShowSearch]="true">
        <nz-option *ngFor="let room of rooms" [nzLabel]="room.name" [nzValue]="room.id"> </nz-option>
      </nz-select>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-small" (click)="closeAddService()">Hủy</button>
    <button nz-button class="btn-primary-small" (click)="addService()">Thêm</button>
  </div>
</nz-modal>

<app-subclinical-group-add [(isVisible)]="visibleSubOrderGroupAdd" [groupId]="visit.groupId" [visitId]="visit.id"
  [getHISServicePrice]="getHISServicePrice" (refresh)="loadData(); titleTabChange()">
</app-subclinical-group-add>

<app-subclinical-tree-add [(isVisible)]="showAddSubOrder" [visitId]="visit.id" [rosIdSelected]="rosIdSelected"
  [subClinicalVisit]="subClinicalVisitModal" [getHISServicePrice]="getHISServicePrice"
  (refreshData)="loadData(); titleTabChange()" (cancelModal)="cancelModalAddSubOrder()"
  (cancelSubclinicalVisit)="serviceCanceledEvent($event)"></app-subclinical-tree-add>

<app-discount *ngIf="visibleDiscount" [type]="isSubTable" [data]="currentOrdersDiscount" [isVisible]="visibleDiscount" (closeModal)="closeModalDiscount()" [visitId]="visit.id"
  [currentOrdersDiscount]="currentOrdersDiscount" (discountChange)="discountEvent($event)">
</app-discount>
<app-visit-other-info [popupData]="visitOtherInfoPopupData"></app-visit-other-info>
<app-add-vital-asign
    #addVitalSigns
    [isVisible]="isVisibleVS"
    (addObserVSEmit)="addVitalSignsEvent()"
    (closeModal)="closeAddVitalSigns()"></app-add-vital-asign>
