import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';

@Component({
  selector: 'app-xetnghiem-chart',
  templateUrl: './xetnghiem-chart.component.html',
  styleUrls: ['./xetnghiem-chart.component.scss'],
})
export class XetnghiemChartComponent implements OnInit, OnDestroy, OnChanges {
  _isVisible: boolean;
  Title: string;
  @Input() set isVisible(val: boolean) {
    this._isVisible = val;
    if (val) {
      this.changeDetector.detectChanges();
      setTimeout(() => {
        for (let i = 0; i < this.datas.length; i++) {
          const chartId = 'chartdiv' + this.datas[i].id;
          const xn = this.datas[i];
          this.ngAfterViewInit1(chartId, xn.id);
        }
      }, 200);
    }
  }

  get isVisible(): boolean {
    return this._isVisible;
  }
  @Input() datas: any[];
  @Input() rawData: any[];

  _visit: any;
  @Input() set visit(value: any) {
    this._visit = value;
  }
  get visit(): any {
    return this._visit;
  }

  @Output() isVisibleChange: EventEmitter<any> = new EventEmitter();
  private charts: am4charts.XYChart[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private zone: NgZone,
    private tabDataService: TabDataService,
    private changeDetector: ChangeDetectorRef
  ) {}

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }
  ngOnInit(): void {}
  ngOnChanges(): void {
    this.Title = 'BN ' + this.visit.patientName + ': Biểu đồ XN';
  }

  handleCancel() {
    this.isVisible = false;
    this.isVisibleChange.emit(false);
    for (let i = 0; i < this.charts.length; i++) {
      this.charts[i].dispose();
    }
    this.charts = [];
  }

  ngAfterViewInit1(chartId, xnId) {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      const chart = am4core.create(chartId, am4charts.XYChart);
      chart.paddingRight = 20;
      const data = [];

      const chartData = this.rawData.filter((en) => en.observationTypeId === xnId);

      for (let i = 0; i < chartData.length; i++) {
        const item = chartData[i];
        const d = new Date(item.visitDateTime);
        data.push({
          date: item.visitDate,
          // month: d.getMonth() + 1,
          // year: d.getFullYear(),
          name: item.name,
          value: item.valueText,
          visitId: item.visitId,
          visitDate: item.visitDate,
        });
      }
      const sortedData = data.sort((a, b) => a.date - b.date);
      chart.data = sortedData;


      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      
      valueAxis.renderer.minWidth = 35;

      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = 'date';
      series.dataFields.valueY = 'value';
  
      series.strokeWidth = 2;
      series.minBulletDistance = 15;

  
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.label.minWidth = 40;
      series.tooltip.label.minHeight = 40;
      series.tooltip.label.textAlign = 'start';
    
      var tooltipHTML = `
      <div>Giá trị: {value}</div>
      <div>
        <span>Ngày khám: </span>
        <a class="a-hover"
          title="Mở ca khám"
          onclick="triggerOnClickFuncFromToolTip('{visitId}')">
          {visitDate}
        </a>
      </div>
      `;

      series.tooltipHTML = tooltipHTML;
      series.tooltip.label.interactionsEnabled = true;
      series.showTooltipOn = 'hover';

      console.log('hh5', series.bullets.push(new am4charts.CircleBullet()))
      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.strokeWidth = 2;
      bullet.circle.radius = 4;
      bullet.circle.fill = am4core.color('#48ae95');
      bullet.circle.stroke = am4core.color('#48ae95');

      chart.cursor = new am4charts.XYCursor();
      chart.strokeLinecap = 'round';
      chart.maxZoomCount = 1;
      this.charts.push(chart);
    });
  }

  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.charts) {
        for (let i = 0; i < this.charts.length; i++) {
          this.charts[i].dispose();
        }
        this.charts = [];
      }
    });
  }
  toggleChart(xnId) {
    const toggleItem = this.datas.find((en) => en.observationTypeId === xnId);
    if (toggleItem) {
      toggleItem.visible = !toggleItem.visible;
    }
  }
  openSelectedVisit() {
    this.isVisible = false;
    this.isVisibleChange.emit(false);
    for (let i = 0; i < this.charts.length; i++) {
      this.charts[i].dispose();
    }
    this.charts = [];

    let visitId = document.getElementById('trigger').getAttribute('data-id');
    this.tabDataService.updateTab(visitId, this.visit.patientName, 'VisitDetail', this.visit.patientId);
  }
}
