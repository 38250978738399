import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListImageGeneralComponent } from './list-image-general.component';
import { SharedModule } from 'src/app/share/share.module';
import { ModalModule } from 'src/app/components/modal/modal.module';

@NgModule({
  declarations: [ListImageGeneralComponent],
  imports: [CommonModule, SharedModule, ModalModule],
  exports: [ListImageGeneralComponent],
})
export class ListImageGeneralModule {}
