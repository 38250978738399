import { Component, Input, OnInit } from '@angular/core';
import { FormElement } from 'src/app/models/form-model/form.class';

@Component({
  selector: 'app-input-element',
  templateUrl: './input-element.component.html',
  styleUrls: ['./input-element.component.scss'],
})
export class InputElementComponent implements OnInit {
  _data: FormElement;
  value: string;

  @Input() set data(val: FormElement) {
    if (val) {
      this._data = val;
      this.value = this._data.valueString;
    }
  }

  get data() {
    return this._data;
  }

  constructor() {}

  ngOnInit() {}

  onModelChange(val) {
    this.data.isDirty = true;
    this._data.valueString = val;
  }
}
