import { ShareService } from 'src/app/services/share.service';
import {
  LockScreen,
  ResetLockScreen,
} from './components/auth/redux/auth.action';
import { Store } from '@ngrx/store';
import { Constant } from './share/constants/constant.class';
import { fromEvent, Observable } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';

//import { MessageService } from './services/message.service';

import { NotificationService } from './services/notification.service';
import { delay } from 'rxjs/operators';
import { AppState } from './components/app-state/app-state';
import { AppConfigService } from 'src/app-config.service';
// const config = {
//   /*apiKey: 'AIzaSyClPL2nFwcRw816rORKUn4KTRkxziA9iuI',
//   authDomain: 'prm-prj.firebaseapp.com',
//   databaseURL: 'https://prm-prj.firebaseio.com',
//   projectId: 'prm-prj',
//   storageBucket: 'prm-prj.appspot.com',
//   messagingSenderId: '533885649295',
//   appId: '1:533885649295:web:a50f68346cc29ddf494bfa'*/
//   apiKey: 'AIzaSyA4bw4dlkjRJXnMOEFJ2-thIjVRJqfdPoA',
//   authDomain: 'doctor-pmr.firebaseapp.com',
//   databaseURL: 'https://doctor-pmr.firebaseio.com',
//   projectId: 'doctor-pmr',
//   storageBucket: 'doctor-pmr.appspot.com',
//   messagingSenderId: '934906157901',
//   appId: '1:934906157901:web:c30decc1a7e2e5641ab504'
// };

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit,OnDestroy {
  source$: Observable<Event>;
  title = 'Bedohub';
  lastToken = '';
  curToken = '';
  curLock = '1';
  broadcast = new BroadcastChannel(Constant.BROADCAST_CHANNEL.auth.value);
  // user:any={
  //   uid:'',
  //   fcmTokens:null
  // }
  // message;
  constructor(
    //public messagingService : MessageService,
    //  private notificationService: NotificationService
    private store: Store<AppState>,
    private configService: AppConfigService,
    private shareService: ShareService
  ) {}
  ngOnInit(): void {
    // ignore https
    if (
      this.configService.getConfig().isSecure &&
      location.protocol === 'http:'
    ) {
      window.location.href = location.href.replace('http', 'https');
    }
    document.title = this.configService.getConfig().title;
    // this.lastValue = localStorage.getItem(Constant.TOKEN);
    this.broadcast.onmessage = (event) => {
      if (document.hidden) {
        const ACTION = Constant.BROADCAST_CHANNEL.auth.action;
        if (event.data.action == ACTION.login.value || event.data.action == ACTION.logout.value) {
          location.href = location.origin
        }
      }
    };
  }
  ngOnDestroy(): void {
    this.broadcast.close();
  }
}
