
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/share/share.module';
import { ListImageGeneralModule } from '../list-image-general/list-image-general.module';
import { ModalModule } from 'src/app/components/modal/modal.module';
import { AddVitalAsignComponent } from './add-vital-asign.component';

@NgModule({
  imports: [CommonModule, SharedModule, ListImageGeneralModule, ModalModule],
  declarations: [AddVitalAsignComponent],
  exports: [AddVitalAsignComponent],
})
export class AddVitalASignModule {}
