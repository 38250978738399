import { ShareService } from './../../../services/share.service';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MedicalAppointmentService } from 'src/app/services/medical-appointment.service';
import { OpenAddAppointment } from '../../modal/redux/modal/modal.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { Patient } from '../../../models/patient.class';
import { NotificationService } from 'src/app/services/notification.service';
import { Subscription } from 'rxjs';
import { WorklistColumn } from 'src/app/models/worklist-column';
import { NzResizeEvent } from 'ng-zorro-antd/resizable';
import { CustomColumnService } from 'src/app/services/custom-cloumn.service';

const defaultConfig: WorklistColumn[] = [
  {
    title: 'STT',
    field: 'STT',
    width: '60px',
    minWidth: 50,
  },
  {
    title: 'Trạng thái',
    field: 'statusText',
    width: '180px',
    minWidth: 50,
  },
  {
    title: 'Tên bệnh nhân',
    field: 'fullName',
    width: '300px',
    minWidth: 50,
  },
  {
    title: 'Mã bệnh nhân',
    field: 'patientCode',
    width: '250px',
    minWidth: 50,
  },
  {
    title: 'Ngày tạo',
    field: 'createdDate',
    width: '120px',
    minWidth: 50,
  },
  {
    title: 'Ngày đăng ký',
    field: 'dateAppointment',
    width: '140px',
    minWidth: 50,
  },
  {
    title: 'Khung giờ ĐK',
    field: 'hourAppointment',
    width: '140px',
    minWidth: 50,
  },
  {
    title: 'Nội dung yêu cầu',
    field: 'request',
    width: '300px',
    minWidth: 50,
  },
  {
    title: 'Ghi chú',
    field: 'note',
    width: '300px',
    minWidth: 50,
  },
];
@Component({
  selector: 'app-list-appointment',
  templateUrl: './list-appointment.component.html',
  styleUrls: ['./list-appointment.component.scss'],
})
export class ListAppointmentComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  rawData;
  isVisibleEdit = false;
  visible = false;
  checked = false;
  pageIndex = 1;
  pageSize = 10;
  appointmentId: string;
  dateFormat = 'dd/MM/yyyy';
  openEditHis = false;
  totalVs: number;
  searchvalue;
  patient: Patient;
  listDuplicatePatient: [];
  sendEditedPatient: Patient;
  keyword: any;
  groupSub: Subscription;

  showCols: WorklistColumn[] = [];
  hideCols: WorklistColumn[] = [];
  isCustomColumnVisible = false;

  constructor(
    private medicalAppointmentService: MedicalAppointmentService,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private customColumnService: CustomColumnService,
    private shareService: ShareService
  ) {
    this.keyword = {
      patientName: '',
      patientCode: '',
      patientPhone: '',
    };
  }

  ngOnInit(): void {
    //this.onResize(window);
    this.getColumns();
    this.getListAppointment();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      $(document).ready(function () {
        $('.main-table-grid .ant-table-body').addClass('customTableCrollbar');
      });
    }, 500);
  }

  getListAppointment() {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        const grId = data.groupId;
        const payload = {
          groupId: grId,
          patientName: this.keyword.patientName,
          patientCode: this.keyword.patientCode,
          patientPhone: this.keyword.patientPhone,
          skip: (this.pageIndex - 1) * this.pageSize,
          take: this.pageSize,
        };
        this.medicalAppointmentService
          .getListMedicalAppointment(payload)
          .subscribe((res) => {
            this.rawData = res.data;
            this.totalVs = res.total;
          });
      }
    });
  }

  getColumns() {
    this.customColumnService.getAppoitmentGridColumn().subscribe((res) => {
      if (res.isValid) {
        let curColumn = [];
        if (res.jsonData.length == 0) {
          curColumn = JSON.parse(JSON.stringify(defaultConfig));
        } else {
          res.jsonData.forEach((element) => {
            let elm = defaultConfig.filter((t) => t.field == element.field)[0];
            if (!this.shareService.checkEmptyObj(elm)) {
              elm = { ...elm, ...element };
              curColumn.push(elm);
            }
          });
        }

        this.showCols = curColumn;
        this.hideCols = defaultConfig.filter(
          (x) => !this.showCols.map((t) => t.field).includes(x.field)
        );
      }
    });
  }

  changeColumns(cols: WorklistColumn[]) {
    this.showCols = cols;
    this.saveColumns();
  }

  convertToDate(d) {
    return new Date(null, null, null, d.hourAppointment, d.minuteAppointment);
  }

  closeModalEdit() {
    this.isVisibleEdit = false;
  }

  showPopupEdit(data) {
    this.appointmentId = data.id;
    this.isVisibleEdit = true;
  }

  showPopupAdd() {
    this.store.dispatch(new OpenAddAppointment());
  }

  refreshData() {
    this.getListAppointment();
  }

  resetSearch() {
    this.keyword = {
      patientName: '',
      patientCode: '',
      patientPhone: '',
    };
    this.getListAppointment();
  }
  searchListVisit() {
    this.getListAppointment();
  }

  ngOnDestroy(): void {
    this.groupSub.unsubscribe();
  }

  onResizeCloumn({ width }: NzResizeEvent, col: string): void {
    this.showCols = this.showCols.map((e) =>
      e.title === col ? { ...e, width: `${width}px` } : e
    );
    this.saveColumns();
  }

  saveColumns() {
    const columns = this.showCols.map((t) => ({
      field: t.field,
      width: t.width,
    }));
    const payload = { columns };
    this.customColumnService
      .savetAppoitmentGridColumn(payload)
      .subscribe((res) => {
        if (res.isValid) {
          this.hideCols = defaultConfig.filter(
            (x) => !this.showCols.map((t) => t.field).includes(x.field)
          );
        }
      });
  }

  showCustomColumn(): void {
    this.isCustomColumnVisible = true;
  }
}
