import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { TreatmentProcessService } from 'src/app/services/treatment-process.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AppState } from '../../app-state/app-state';

@Component({
  selector: 'app-treatment-patient',
  templateUrl: './treatment-patient.component.html',
  styleUrls: ['./treatment-patient.component.scss'],
})
export class TreatmentPatientComponent implements OnInit {
  @Input()
  get isVisible() {
    return this._isVisible;
  }
  set isVisible(val) {
    if (val) {
      const data = this.rawData;
      if (data == null) {
        this.isEditProcess = true;
      } else {
        this.treatmentForm.patchValue({
          id: data.id,
          treatmentProcessId: data.treatmentProcessId,
          activeDate: data.activeDate,
        });
      }
    }
    this._isVisible = val;
    //console.log("isVisible", val, this.rawData, this.isEditProcess);
  }
  _isVisible = false;

  @Input() patientId = '';
  @Input() rawData = {
    id: '',
    treatmentProcessId: '',
    treatmentProcessName: '',
    activeDate: '',
    logs: [],
  };

  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  lstTreatmentProcess: any;
  dateFormat = 'dd/MM/yyyy';
  curGroupId = '';
  isEditProcess = false;
  treatmentForm: FormGroup;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private treatmentProcessService: TreatmentProcessService,
    private notificationService: NotificationService,
    private shareService: ShareService
  ) {
    this.store
      .select((store) => store.groupAuth.groupId)
      .subscribe((data) => {
        this.curGroupId = data;
      });
    this.treatmentForm = this.fb.group({
      id: [''],
      treatmentProcessId: [null, [Validators.required]],
      treatmentProcessName: [null],
      activeDate: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getAllTreatmentProcess();
  }

  get formData() {
    return this.treatmentForm.controls;
  }

  handleCancel() {
    this.isVisible = false;
    this.closeModal.emit(this.rawData);
    this.submitted = false;
    this.isEditProcess = false;
  }
  editProcess() {
    this.isEditProcess = true;
    const data = this.rawData;
    this.treatmentForm.patchValue({
      id: data.id,
      treatmentProcessId: data.treatmentProcessId,
      treatmentProcessName: data.treatmentProcessName,
      activeDate: data.activeDate,
    });
    //console.log("editProcess", this.selectedProcess, this.treatmentForm.value.treatmentProcess, this.lstTreatmentProcess);
  }
  saveProcess() {
    this.submitted = true;
    if (this.treatmentForm.valid) {
      const formValue = this.treatmentForm.value;
      const id =
        this.rawData == null ||
        formValue.treatmentProcessId != this.rawData.treatmentProcessId
          ? ''
          : this.rawData.id;
      // console.log("saveProcess", formValue);

      //save if treatment or activeDate change
      if (
        id == '' ||
        id != this.rawData.id ||
        formValue.activeDate != this.rawData.activeDate
      ) {
        const payload = {
          id,
          treatmentProcessId: formValue.treatmentProcessId,
          treatmentProcessName: this.lstTreatmentProcess.filter(
            (en) => en.id == formValue.treatmentProcessId
          )[0].name,
          activeDate: this.shareService.datatimeToDateTimeZero(
            new Date(formValue.activeDate)
          ),
          patientId: this.patientId,
          groupId: this.curGroupId,
        };
        //console.log("saveProcess", payload, this.treatmentForm.value);
        this.treatmentProcessService
          .applyTreatmentPatient(this.patientId, payload)
          .subscribe((res) => {
            if (res.isValid) {
              this.rawData = res.jsonData;
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.SUCCESS,
                'Cập nhật thành công'
              );
            }
          });
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Bạn chưa thực hiện thay đổi'
        );
      }
      this.isEditProcess = false;
      this.submitted = false;
    }
  }
  returnView() {
    if (this.rawData == null) {
      this.isVisible = false;
      this.closeModal.emit(null);
      this.submitted = false;
    } else {
      this.isEditProcess = false;
    }
  }
  getAllTreatmentProcess() {
    this.treatmentProcessService
      .getAllTreatmentProcess(this.curGroupId)
      .subscribe((res) => {
        if (res.isValid) {
          this.lstTreatmentProcess = res.jsonData;
        }
      });
  }
}
