<div class="phieudl-tab">
  <div class="phieudl-header">
    <span class="phieudl-name">Phiếu dữ liệu</span>
    <!-- <button class="btn-printer" type="button">
      <i><img src="assets/image/DPicon/white-print.svg" /></i>
    </button> -->
  </div>
  <div class="phieudl-container">
    <div #formContentData class="phieudl-content {{ disabledClass }}">
      <app-form #form [data]="{ visitId: visit.id, formId: formId, recordId: recordId }"
        (completeDownload)="processDone($event)" id="phieudl-content-id" class="record-content"></app-form>
    </div>
  </div>
</div>
<div class="phieudl-footer {{ hideButton }}">
  <!-- <button class="btn-primary-small mgr15" type="button" (click)="testId()">Test phieu du lieu id</button> -->
  <button class="btn-primary-small mgr15" type="button" (click)="confirmExport()">
    <i *ngIf="isProcessing" nz-icon nzType="loading" nzTheme="outline" style="margin-right: 4px"></i>
    <fa-icon [icon]="faDownload"></fa-icon> Pdf
  </button>
  <button class="btn-printer mgr15" type="button" (click)="confirmPrint()">
    <!-- <button class="btn-printer mgr15" type="button" (click)="print()" > -->
    <i *ngIf="!isPrint"><img src="assets/image/DPicon/white-print.svg" /></i>
    <span *ngIf="isPrint"><i nz-icon nzType="loading" nzTheme="outline"></i></span>
  </button>
  <button nz-button class="btn-primary-small mgr15" type="button" (click)="save()" [nzLoading]="isSaving">
    Hoàn tất
  </button>
</div>

<div id="download">
  <img #canvas />
  <a #downloadLink></a>
</div>