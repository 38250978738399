import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-about-pmr',
  templateUrl: './about-pmr.component.html',
  styleUrls: ['./about-pmr.component.scss'],
})
export class AboutPmrComponent implements OnInit {
  @Input() isVisible = false;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  handleCancel() {
    this.isVisible = false;
    this.closeModal.emit();
  }
}
