<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Thêm ObservationType: {{ obt ? obt.name : '' }}"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  nzOkText="Thêm"
  nzCancelText="Hủy">
  <!-- <div>Kiểu dữ liệu: <span>{{ obt? obt.datatype: ''}}</span></div> -->
  <form
    nz-form
    [nzLayout]="'horizontal'"
    [formGroup]="validateForm"
    (ngSubmit)="submitForm()">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="editable"
        >Editable
      </nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24">
        <nz-radio-group formControlName="editable">
          <label nz-radio nzValue="true">Có</label>
          <label nz-radio nzValue="false">Không</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
    <!-- <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="content">Nội dung</nz-form-label>
      <nz-form-control>
        <textarea nz-input formControlName="content"></textarea>
      </nz-form-control>
    </nz-form-item> -->
    <nz-form-item *ngIf="_viewTypeEnable">
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="viewType"
        >Hiển thị</nz-form-label
      >
      <nz-form-control [nzSm]="12" [nzXs]="24">
        <nz-radio-group formControlName="viewtype">
          <label nz-radio nzValue="dropdown">Dropdown</label>
          <label nz-radio nzValue="selection">Selection</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>
