import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonInfo } from 'src/app/models/form-model/form.class';

@Component({
  selector: 'app-commonInfo-element',
  templateUrl: './commonInfo-element.component.html',
  styleUrls: ['./commonInfo-element.component.scss'],
})
export class CommonInfoElementComponent implements OnInit {
  @Input() data: CommonInfo;
  @Output() selectElement: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}
}
