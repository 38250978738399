import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DoctorService } from 'src/app/services/doctor.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareVisitService } from 'src/app/services/share-visit.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { PatientService } from 'src/app/services/patient.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/components/app-state/app-state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-share-visit',
  templateUrl: './share-visit.component.html',
  styleUrls: ['./share-visit.component.scss'],
})
export class ShareVisitComponent implements OnInit, OnDestroy {
  @Input() isVisible = false;
  @Input() visitId: string;
  model: any = {
    expireDate: '',
    shareLevel: 4,
    isOn: false,
    url: '',
    shareId: '',
  };
  loading = false;
  searchData = [];
  searchDataFilter = [];
  data = [];
  selectGroupId: string;
  today = new Date();
  groupSub: Subscription;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  constructor(
    private shareVSService: ShareVisitService,
    private notificationService: NotificationService,
    private doctorService: DoctorService,
    private patientService: PatientService,
    //private tabDataService: TabDataService,
    private store: Store<AppState>
  ) {}
  disabledDateBeforeToday = this.patientService.disabledDateBeforeToday;

  ngOnInit(): void {
    this.loadListGroupShared();
    this.loadMyGroup();
    this.loadShareLink();
  }

  loadShareLink() {
    //call API load info
    this.shareVSService.getShareVisit(this.visitId).subscribe((response) => {
      if (response.isValid && response.jsonData != null) {
        this.model = response.jsonData;
        this.model.shareId = this.model.url.substring(1, this.model.url.length);
        this.model.url =
          window.location.origin + '/view-visit?Code=' + this.model.shareId;
      } else {
      }
    });
  }
  loadListGroupShared() {
    //get source for data
    this.shareVSService
      .getListGroupShared(this.visitId)
      .subscribe((response) => {
        if (response.isValid) {
          this.data = response.jsonData;
        } else {
        }
      });
  }
  loadMyGroup() {
    //get source for searchData
    this.doctorService.getListGroup().subscribe((response) => {
      if (response != null && response != []) {
        this.searchData = response;
        //let selectedGroupId =this.tabDataService.sharegroup.getValue();
        this.groupSub = this.store.select('groupAuth').subscribe((data) => {
          if (data.isAuthenticated) {
            const selectedGroupId = data.groupId;
            this.searchData = this.searchData.filter(
              (en) => en.id !== selectedGroupId
            );
          }
        });

        //trừ current group ra khỏi list
      } else {
      }
    });
  }
  handleCancel(): void {
    this.isVisible = false;
    this.closeModal.emit();
  }

  updateShareLink() {
    if (this.model.expireDate == null || this.model.expireDate === '') {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn chưa chọn exprire date'
      );
      return;
    }
    if (this.model.shareLevel !== 3 && this.model.shareLevel !== 4) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn chưa chọn phạm vi chia sẻ'
      );
      return;
    }
    const saveData = {
      visitId: this.visitId,
      expireDate: this.model.expireDate,
      shareLevel: this.model.shareLevel,
    };
    this.shareVSService.shareVisit(saveData).subscribe((response) => {
      if (response.isValid) {
        this.model.isOn = true;
        this.model.shareId = response.jsonData.substring(
          1,
          response.jsonData.length
        );
        const url =
          window.location.origin + '/view-visit?Code=' + this.model.shareId;
        this.model.url = url;

        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Lưu Thành công'
        );
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          response.errors.errorMessage
        );
      }
    });
  }
  onOffShareLink(_isOn) {
    //neu null = lần đầu thì tạo share vs và isOn
    //neu khac null thi ON/Off
    if (this.model == null || this.model.url === '' || _isOn === true) {
      //this.model.isOn=_isOn;
      this.updateShareLink();
    } else {
      this.shareVSService
        .OnOffShareVisit(this.model.shareId)
        .subscribe((response) => {
          if (response.isValid) {
            this.model.isOn = false;
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.SUCCESS,
              'Lưu Thành công'
            );
          } else {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.ERROR,
              response.errors.errorMessage
            );
          }
        });
    }
  }
  copyInputUrl(inputElement) {
    inputElement.select();
    document.execCommand('copy');
  }
  onSearchGroup(keyword) {
    if (this.searchData === null || this.searchData === []) {
      this.loadShareLink();
    }
    keyword = keyword.toLowerCase();

    this.searchDataFilter = this.searchData.filter(
      (item) => item.name.toLowerCase().indexOf(keyword) !== -1
    );
  }
  selectShareGroup(selectGroup) {
    this.selectGroupId = selectGroup.id;
  }

  shareGroup() {
    //call API with : this.selectGroupId
    const saveData = {
      visitId: this.visitId,
      groupId: this.selectGroupId,
      shareLevel: 4,
    };
    this.shareVSService.addGroupShared(saveData).subscribe((response) => {
      if (response.isValid) {
        //done
        this.selectGroupId = '';
        this.loadListGroupShared();
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Lưu Thành công'
        );
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          response.errors[0].errorMessage
        );
      }
    });
  }
  ngOnDestroy(): void {
    this.groupSub.unsubscribe();
  }
}
