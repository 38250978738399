import { TextAreaElement } from 'src/app/models/form-model/textareaElement.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-textarea-element',
  templateUrl: './textarea-element.component.html',
  styleUrls: ['./textarea-element.component.scss'],
})
export class TextareaElementComponent implements OnInit {
  html = '';
  _data: TextAreaElement;
  isSingleClick: Boolean = true;
  @Input() set data(data: TextAreaElement) {
    if (data) {
      this.html = data.value;
      this._data = data;
    }
  }

  get data(): TextAreaElement {
    return this._data;
  }

  @Input() selectedElement: any;
  @Input() isFocus: boolean;
  @Output() selectElement: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}

  onContentChange(data) {
    this._data.value = data.innerHTML;
  }

  textareaClickEvent(data, item) {
    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        this.selectElement.emit({
          item,
          type: Constant.FORMELEMENT.TEXTAREA,
          clickType: Constant.CLICK_TYPE.SINGLE,
        });
      }
    }, 250);
  }

  textareaDbClickEvent(data, item) {
    this.isSingleClick = false;
    this.selectElement.emit({
      item,
      type: Constant.FORMELEMENT.TEXTAREA,
      clickType: Constant.CLICK_TYPE.DOUBLE,
    });
  }
}
