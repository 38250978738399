<div class="phieudl-tab">
  <!-- <div class="phieudl-header">
    <span class="phieudl-name">Tổng kết ca khám</span>
  </div> -->

  <div class="form-selector-wrapper">
    <div class="form-selector-label">Chọn biểu mẫu:</div>
    <div class="form-selector">
      <nz-select
        nzShowSearch
        nzAllowClear
        nzPlaceHolder="Chọn biểu mẫu"
        [(ngModel)]="formId">
        <nz-option
          *ngFor="let item of lstForms"
          [nzLabel]="item.name"
          [nzValue]="item.id"></nz-option>
      </nz-select>
    </div>
    <div class="form-btn">
      <button
        class="btn-primary-small mgr15"
        type="button"
        (click)="downloadImage()">
        <fa-icon [icon]="faDownload"></fa-icon> Pdf
      </button>
      <button
        class="btn-printer mgr15"
        type="button"
        [useExistingCss]="true"
        printSectionId="phieudl-content-id"
        ngxPrint>
        <i><img src="assets/image/DPicon/white-print.svg" /></i>
      </button>
      <!-- <button class="btn-primary-small mgr15" type="button" (click)="save()">Hoàn tất</button> -->
    </div>
  </div>

  <div class="phieudl-container">
    <div #formContentData class="phieudl-content {{ disabledClass }}">
      <div class="form-overlay"></div>
      <app-form
        #form
        [data]="{ visitId: visit.id, formId: formId, recordId: recordId }"
        id="phieudl-content-id"></app-form>
    </div>
  </div>
</div>

<div id="download">
  <img #canvas />
  <a #downloadLink></a>
</div>
