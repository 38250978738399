import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from 'src/app-config.service';
import { UrlConstant } from 'src/app/share/constants/url.class';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
// import { AngularFirestore } from 'angularfire2/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AuthModel } from 'src/app/models/auth.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { LoginSuccess, ResetLockScreen } from '../../auth/redux/auth.action';

@Component({
  selector: 'app-view-visit',
  templateUrl: './view-visit.component.html',
  styleUrls: ['./view-visit.component.scss'],
})
export class ViewVisitComponent implements OnInit {
  _visitId = '';
  _visitPrivacy: number;
  message: string;
  model: any;
  isVisible = false;
  isLoading = true;
  code: string;
  constructor(
    //private afs:AngularFirestore,
    private httpClient: HttpClient,
    protected configService: AppConfigService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private store: Store<AppState>
  ) {
    this.code = this.route.snapshot.queryParamMap.get('Code');
    //localStorage.removeItem(Constant.TOKEN);
    const token = localStorage.getItem(Constant.TOKEN);
    if (token === '' || token === null) {
      this.getGuestToken(this.code).subscribe(
        (res) => {
          if (res !== null) {
            //var expiresIn = 499;
            localStorage.setItem(Constant.TOKEN, res.value);
            localStorage.setItem(Constant.USER_INFO, JSON.stringify(res));
            this.store.dispatch(new LoginSuccess(res));
            this.store.dispatch(new ResetLockScreen());
            localStorage.setItem(Constant.LOCKSTATE, '1');
            // const expireDate = new Date().getTime() + expiresIn;
            //Cookie.set(Constant.TOKEN, res.value);
          }
        },
        (error) => {}
      );
    } else {
      //Nếu tồn tại:
      this.authService.checkToken().subscribe((res) => {
        if (res.ret && (res.ret.code === 401 || res.ret.code === 403)) {
          // localStorage.removeItem(Constant.TOKEN);
          // localStorage.removeItem(Constant.FIREBASE_TOKEN);
          // localStorage.removeItem(Constant.USER_INFO);
          //Token ko hợp lệ là tài khoản msa thì lấy mã guest token
          this.getGuestToken(this.code).subscribe(
            (res) => {
              if (res !== null) {
                //var expiresIn = 499;
                localStorage.setItem(Constant.TOKEN, res.value);
                localStorage.setItem(Constant.USER_INFO, JSON.stringify(res));
                this.store.dispatch(new LoginSuccess(res));
                this.store.dispatch(new ResetLockScreen());
                localStorage.setItem(Constant.LOCKSTATE, '1');
                // const expireDate = new Date().getTime() + expiresIn;
                //Cookie.set(Constant.TOKEN, res.value);
              }
            },
            (error) => {}
          );
        } else {
          this._visitPrivacy = 1;
        }
      });
    }
  }

  ngOnInit() {
    //Check nếu localstore token ko tồn tại thì get guest token luôn
    this.isLoading = true;
    this.getShareLink(this.code).subscribe((response) => {
      this.isLoading = false;
      if (response.isValid) {
        this.model = response.jsonData;
        const date = new Date();
        const newDate = new Date(this.model.expireDate);
        if (this.model.isOn && newDate >= date) {
          this.isVisible = true;
          this._visitId = this.model.visitId;
          if (this._visitPrivacy !== 1)
            {this._visitPrivacy = this.model.shareLevel;}
        } else {
          this.isVisible = false;
          this.message = 'Chia sẻ ca khám bị ẩn hoặc hết hạn';
        }
      } else {
        this.isVisible = false;
        this.message = 'Chia sẻ ca khám không tồn tại';
      }
    });
  }
  getShareLink(id): Observable<any> {
    return this.httpClient.get(
      this.configService.getConfig().api.baseUrl +
        UrlConstant.SHARE_LINK_ID +
        id
    );
  }
  getGuestToken(id): Observable<AuthModel> {
    return this.httpClient.post(
      this.configService.getConfig().api.baseUrl +
        UrlConstant.SHARE_LINK_GET_TOKEN +
        id,
      ''
    );
  }

  //   async testfirebase()
  //   {
  // //     const noti={
  // //       msg:'Chào'
  // //     };
  // //     const headers: HttpHeaders = new HttpHeaders({
  // //       'Content-Type': 'application/json',
  // //     //  'Sender':'id=809032041389',
  // //       Authorization: 'key=AAAA2ay_200:APA91bGkjWVQD9pVi-tzJCoLbuskUZPkeJILGvDFVYhkuQBxVuJwMb8AHo6r4orCiHEF90AzoIwxGmuaej14DJm0ahv7CjqLfDPSkNwT6vrMt_byUu4lraVw0McBDJHrv5UqhIdeZ_s1'
  // //     });
  // //     const data:any= {
  // //       title : "goikedanang.com",
  // //       body : 'message'
  // //     };
  // //     const userRef=this.afs.firestore.collection('users').doc('all');
  // //     console.log('le',userRef);
  // //     userRef.get().then(snapshop=>snapshop.data())
  // //       .then(user=>{
  // //         console.log('send',user);
  // //         console.log('send1',user.fcmtokens);
  // //          let arr=[];
  // //         // user.fcmtokens.forEach(element => {
  // //         //   arr
  // //         // });
  // //       //   for(var propertyName in user.fcmtokens) {
  // //       //     // propertyName is what you want
  // //       //     // you can get the value like this: myObject[propertyName]
  // //       //     arr.push(propertyName.ke)
  // //       //  }
  // //       //const tokens={...user.fcmtokens}
  // // const tokens=[
  // // 'foR48EajSrN4sNNWUf7GXT:APA91bHT01oIiI9HFHpmT_L4a7Jb9H6M3qscBKZqx-v4pG1Q-Esv5pNRIklpgZehmjUGScjhjhIK8BaYSw-pGnkIdcOGaDuIeAtDG5_-qIP2-VxzU9MwnKTEYavxEyqsCSFt7hQKzHZ1'
  // //       ];
  // //         const fcmPayload: FCMPayload = {
  // //           registration_ids: tokens,
  // //           collapse_key: 'all',
  // //           data
  // //         };
  // //         this.httpClient.post('https://fcm.googleapis.com/fcm/send' , fcmPayload , {headers}).subscribe(res => {
  // //           console.log(res);
  // //         });
  // //       });

  //    // this.firestore.collection('notifi').add(noti);
  //     //const writeResult = await this.firestore.collection('messages').add({original: 'CHào'});

  //     alert('send');
  //   }
}
//Interfaces containing payload
export interface FCMData {
  body: string;
  title: string;
}

export interface FCMPayload {
  registration_ids: any;
  collapse_key: string;
  data: FCMData;
}
