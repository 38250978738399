import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-thumbnail-image',
  templateUrl: './thumbnail-image.component.html',
  styleUrls: ['./thumbnail-image.component.scss'],
})
export class ThumbnailImageComponent implements OnInit {
  @Input() src: string;
  @Output() deleteImage: EventEmitter<any> = new EventEmitter();

  hover = false;

  constructor() {}

  ngOnInit() {}

  hoverEvent(): void {
    this.hover = true;
  }

  mouseLeave(): void {
    this.hover = false;
  }
}
