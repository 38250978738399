import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { GroupTreeService } from 'src/app/services/groupTree.service';
import { ListFormService } from 'src/app/services/listForm-service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { TreatmentProcessService } from 'src/app/services/treatment-process.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AppState } from '../../app-state/app-state';

@Component({
  selector: 'app-treatment-process',
  templateUrl: './treatment-process.component.html',
  styleUrls: ['./treatment-process.component.scss'],
})
export class TreatmentProcessComponent implements OnInit {
  lstTreatmentProcess: any;
  total = 0;
  loading = false;
  pageIndex = 1;
  pageSize = 10;
  isVisibleForm = false;
  processForm: FormGroup;
  searchFormPPWebFilter = [];
  searchFormPPMobileFilter = [];
  listReminder: {
    idx: number;
    id: string;
    title: string;
    detail: string;
    activeDate: number;
  }[] = [];
  listClinicalForm: {
    idx: number;
    id: string;
    title: string;
    clinicalFormWeb: any;
    clinicalFormMobile: any;
    activeDate: number;
    fromDate: number;
    toDate: number;
    frequency: number;
    pushNotify: boolean;
    pushNotifyTimes: Array<any>;
    dayOfWeek: number;
  }[] = [];
  dataForm: {
    id: string;
    title: string;
    description: string;
    privacyUse: number;
    isAutoEnd: boolean;
    dayEnd: number;
  } = null;
  textSearch = '';
  searchDataGroupFilter = [];
  lstGroupShare: {
    id: string;
    name: string;
  }[] = [];
  isProcessFormAdd = false;
  submitted = false;
  groupShare: any;
  curUserId = '';
  curGroupId = '';
  curGroupName = '';
  fromAdminPage = false;
  isTreatmentManager = false;
  isOnlyViewData = false;

  visibleRmdModal = false;
  visibleClinicalModal = false;
  reminderForm: FormGroup;
  clinicalForm: FormGroup;
  pushNotifyTimes = [];
  isAddRmd = false;
  isAddForm = false;

  isAdminGroup = false;
  privacyUse = Constant.PrivacyUse;

  frequencyConfig = [
    {
      name: '1 lần/tuần',
      code: 1,
    },
    {
      name: '2 ngày/lần',
      code: 2,
    },
    {
      name: '1 lần/ngày',
      code: 3,
    },
    {
      name: '2 lần/ngày',
      code: 4,
    },
    {
      name: '3 lần/ngày',
      code: 5,
    },
    {
      name: '1 lần',
      code: 6,
    },
  ];
  daysOfWeek = [
    {
      name: 'Thứ hai',
      code: 1,
    },
    {
      name: 'Thứ ba',
      code: 2,
    },
    {
      name: 'Thứ tư',
      code: 3,
    },
    {
      name: 'Thứ năm',
      code: 4,
    },
    {
      name: 'Thứ sáu',
      code: 5,
    },
    {
      name: 'Thứ bảy',
      code: 6,
    },
    {
      name: 'Chủ nhật',
      code: 0,
    },
  ];
  disabledActiveDate: boolean;
  disabledActiveFromTo: boolean;

  constructor(
    private fb: FormBuilder,
    private dataService: ListFormService,
    private notificationService: NotificationService,
    private treatmentProcessService: TreatmentProcessService,
    private groupTreeService: GroupTreeService,
    private shareService: ShareService,
    private modalService: NzModalService,
    private store: Store<AppState>,
    private route: ActivatedRoute
  ) {
    this.processForm = this.fb.group({
      id: [null],
      title: [null, [Validators.required, this.noWhitespaceValidator]],
      description: [null, [Validators.required, this.noWhitespaceValidator]],
      privacyUse: [null, [Validators.required]],
      isAutoEnd: [null],
      dayEnd: [null],
    });
    this.reminderForm = this.fb.group({
      idx: [null],
      id: [null],
      title: [null, [Validators.required, this.noWhitespaceValidator]],
      detail: [null, [Validators.required, this.noWhitespaceValidator]],
      activeDate: [null, [Validators.required]],
    });
    this.clinicalForm = this.fb.group({
      idx: [null],
      id: [null],
      title: [null, [Validators.required, this.noWhitespaceValidator]],
      clinicalFormWeb: [null, [Validators.required]],
      clinicalFormMobile: [null, [Validators.required]],
      activeDateType: [null, [Validators.required]],
      activeDate: [null],
      fromDate: [null],
      toDate: [null],
      frequency: [null, [Validators.required]],
      pushNotify: [null],
      pushNotifyTimes: [[]],
      dayOfWeek: [null],
    });
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
    this.store
      .select((store) => store.groupAuth)
      .subscribe((data) => {
        this.curGroupId = data.groupId;
        this.curGroupName = data.groupName;
        this.isAdminGroup =
          data.role == Constant.UserRoleType.Admin ||
          data.role == Constant.UserRoleType.Owner
            ? true
            : false;
      });
    this.store
      .select((store) => store.auth.userType)
      .subscribe((data) => {
        if (data.indexOf(Constant.USERROLE.TreatmentProcessManagement) >= 0) {
          this.isTreatmentManager = true;
        }
      });

    const urlSegment = this.route.snapshot.url;
    if (urlSegment[0].path == 'treatment-process') {
      this.fromAdminPage = true;
      this.isTreatmentManager = true;
    }
  }

  ngOnInit() {
    this.getTreatmentProcesses();
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true, error: true };
  }

  get processFormData() {
    return this.processForm.controls;
  }

  getTreatmentProcesses() {
    const payload = {
      textSearch: this.textSearch.trim(),
      skip: (this.pageIndex - 1) * this.pageSize,
      take: this.pageSize,
      groupId: this.curGroupId,
      fromAdminPage: this.fromAdminPage,
    };
    this.treatmentProcessService
      .getTreatmentProcessByFilter(payload)
      .subscribe((res) => {
        if (res.isValid) {
          this.lstTreatmentProcess = res.jsonData.source;
          this.total = res.jsonData.total;
        }
      });
  }
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    this.getTreatmentProcesses();
  }
  showModalForm(type, data) {
    if (type == 'add') {
      this.isOnlyViewData = false;
      //this.processForm.enable();
      this.processForm.patchValue({
        id: '',
        title: '',
        description: '',
        privacyUse: 3,
        isAutoEnd: false,
        dayEnd: 14,
      });
      this.listReminder = [];
      this.listClinicalForm = [];
      this.lstGroupShare = [];
      if (!this.fromAdminPage && this.isAdminGroup) {
        this.lstGroupShare.push({
          id: this.curGroupId,
          name: this.curGroupName,
        });
      }
      this.isProcessFormAdd = true;
    } else if (type == 'update' || type == 'view') {
      if (type == 'view') {
        //this.processForm.disable();
        this.isOnlyViewData = true;
      } else {
        //this.processForm.enable();
        this.isOnlyViewData = false;
      }
      const item = JSON.parse(JSON.stringify(data));
      this.processForm.patchValue({
        id: item.id,
        title: item.title,
        description: item.description,
        privacyUse: item.privacyUse,
        isAutoEnd: item.isAutoEnd,
        dayEnd: item.dayEnd,
      });
      this.listReminder = item.reminderSamples;
      this.listClinicalForm = item.clinicalInfoForms;
      this.listClinicalForm.forEach((e) => {
        e.pushNotifyTimes = this.convertTimeToTimeSelection(e.pushNotifyTimes);
      });
      this.lstGroupShare = item.groupShare;
      this.isProcessFormAdd = false;
    }
    this.isVisibleForm = true;
  }
  handleCancel() {
    this.isVisibleForm = false;
    this.submitted = false;
  }
  removeTreatmentProcess(id) {
    if (!this.shareService.checkEmpty(id)) {
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn xóa hay không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Bỏ qua',
        nzOnOk: () => {
          this.treatmentProcessService
            .removeTreatmentProcess(id)
            .subscribe((res) => {
              if (res.isValid) {
                this.notificationService.showNotification(
                  Constant.NOTIFY_TYPE.SUCCESS,
                  'Xóa thành công'
                );
                this.isVisibleForm = false;
                this.getTreatmentProcesses();
              } else {
                this.notificationService.showNotification(
                  Constant.NOTIFY_TYPE.ERROR,
                  res.errors[0].errorMessage
                );
              }
            });
        },
      });
    }
  }
  saveTreatmentProcess() {
    this.submitted = true;
    if (this.processForm.valid) {
      this.listClinicalForm.forEach((en) => {
        en.pushNotifyTimes = this.convertTimeSelectionToTime(
          en.pushNotifyTimes
        );
        en.clinicalFormMobile = en.clinicalFormMobile.id;
        en.clinicalFormWeb = en.clinicalFormWeb.id;
        delete en.idx;
      });
      this.listReminder.forEach((en) => delete en.idx);

      const notInForm = {
        reminderSamples: this.listReminder,
        clinicalInfoForms: this.listClinicalForm,
        groupIds: this.lstGroupShare.map((en) => en.id),
      };
      const payload = { ...this.processForm.value, ...notInForm };
      this.treatmentProcessService
        .saveTreatmentProcess(payload)
        .subscribe((res) => {
          if (res.isValid) {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.SUCCESS,
              'Cập nhật thành công'
            );
            this.isVisibleForm = false;
            this.submitted = false;
            this.getTreatmentProcesses();
          } else {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.ERROR,
              res.errors[0].errorMessage
            );
          }
        });
    }
  }
  onSearchPPFormWeb(keyword) {
    const requestData = {
      search: keyword.trim(),
      groupId: '',
      pageSize: 10,
      page: 1,
    };
    this.dataService.getListDPFromForGroup(requestData).subscribe(
      (response) => {
        if (response !== null && response.source != null) {
          this.searchFormPPWebFilter = [...response.source];
        }
      },
      (error) => {
        //console.log(error);
        this.notificationService.showNotification('', 'Lỗi data');
      }
    );
  }
  onSearchPPFormMobile(keyword) {
    const requestData = {
      search: keyword,
      groupId: '',
      pageSize: 10,
      page: 1,
    };
    this.dataService.getListDPFromForGroup(requestData).subscribe(
      (response) => {
        if (response !== null && response.source != null) {
          this.searchFormPPMobileFilter = [...response.source];
        }
      },
      (error) => {
        //console.log('lỗi');
        this.notificationService.showNotification('', 'Lỗi data');
      }
    );
  }
  openModalRmd(data, idx?) {
    if (data == 'add') {
      this.isAddRmd = true;
      this.reminderForm.reset();
    } else {
      this.isAddRmd = false;
      const item = JSON.parse(JSON.stringify(data));
      this.reminderForm.patchValue({
        idx,
        id: item.id,
        title: item.title,
        detail: item.detail,
        activeDate: item.activeDate,
      });
    }
    this.visibleRmdModal = true;
  }
  saveRmd() {
    if (this.reminderForm.valid) {
      const rmd = this.reminderForm.value;
      if (!this.isAddRmd) {
        // edit old item
        this.listReminder[rmd.idx] = rmd;
      } else {
        this.listReminder = [...this.listReminder, rmd];
      }
      this.visibleRmdModal = false;
      this.listReminder = [...this.listReminder];
    } else {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn cần nhập các trường bắt buộc'
      );
    }
  }
  removeRmd(item) {
    this.listReminder = this.listReminder.filter((t) => t !== item);
  }
  openModalClinicalForm(data, idx?) {
    if (data == 'add') {
      this.isAddForm = true;
      this.clinicalForm.reset();
      this.disabledActiveDate = false;
      this.disabledActiveFromTo = false;
    } else {
      this.isAddForm = false;
      const item = JSON.parse(JSON.stringify(data));
      this.clinicalForm.patchValue({
        idx,
        id: item.id,
        title: item.title,
        clinicalFormWeb: item.clinicalFormWeb,
        clinicalFormMobile: item.clinicalFormMobile,
        activeDateType: item.activeDate != null ? 1 : 2,
        activeDate: item.activeDate,
        fromDate: item.fromDate,
        toDate: item.toDate,
        frequency: item.frequency,
        pushNotify: item.pushNotify,
        dayOfWeek: item.dayOfWeek,
      });
      this.pushNotifyTimes = [];
      item.pushNotifyTimes.forEach((en) => {
        this.pushNotifyTimes.push(new Date(en));
      });
      //console.log("openModalClinicalForm", this.pushNotifyTimes)
    }
    this.visibleClinicalModal = true;
  }
  removeClinicalForm(item) {
    this.listClinicalForm = this.listClinicalForm.filter((t) => t !== item);
  }
  onSearchGroup(keyword) {
    if (keyword.trim().length > 1) {
      this.groupTreeService.getListGroup(keyword).subscribe(
        (response) => {
          if (response !== null) {
            this.searchDataGroupFilter = [...response];
          }
        },
        (error) => {
          console.error(error);
          this.notificationService.showNotification('', 'Lỗi data');
        }
      );
    }
  }
  selectGroup(group) {
    if (this.lstGroupShare.filter((en) => en.id == group.id).length == 0) {
      this.lstGroupShare.push({ id: group.id, name: group.name });
    }
    this.groupShare = '';
  }
  unSelectGroup(id) {
    this.lstGroupShare = this.lstGroupShare.filter((en) => en.id !== id);
  }
  frequencyOnChange(code) {
    //console.log("code",code)
    this.pushNotifyTimes = [];
    let leng = 0;
    switch (code) {
      case 1:
      case 2:
      case 3:
      case 6: {
        leng = 1;
        break;
      }
      case 4: {
        leng = 2;
        break;
      }
      case 5: {
        leng = 3;
        break;
      }
    }
    for (let i = 0; i < leng; i++) {
      this.pushNotifyTimes.push(null);
    }
    if (code == 1 || code == 2) {
      this.disabledActiveDate = true;
      this.clinicalForm.value.activeDateType = 2;
    } else if (code == 6) {
      this.disabledActiveFromTo = true;
      this.clinicalForm.value.activeDateType = 1;
    }
  }
  convertTimeSelectionToTime(dates) {
    if (this.shareService.checkEmpty(dates)) {
      return [];
    }
    const times = [];
    for (let i = 0; i < dates.length; i++) {
      //let date = new Date(dates[i]);
      const time = { hour: 0, minute: 0 };
      time.hour = dates[i].getHours();
      time.minute = dates[i].getMinutes();
      times.push(time);
    }
    return times;
  }
  convertTimeToTimeSelection(times) {
    if (this.shareService.checkEmpty(times)) {
      return [];
    }
    const dates = [];
    for (let i = 0; i < times.length; i++) {
      dates.push(new Date(1970, 1, 1, times[i].hour, times[i].minute));
    }
    //console.log("dates", dates);
    return dates;
  }
  toPrivacyText(x): string {
    switch (x) {
      case 1:
        return 'Toàn hệ thống';
      case 2:
        return 'Trong nhóm BS';
      case 3:
        return 'Chỉ mình tôi';
      default:
        return '';
    }
  }
  setTime($event, idx) {
    this.pushNotifyTimes[idx] = $event;
    //console.log("setTime", this.pushNotifyTimes);
  }
  pushNotifyOnChange($event) {
    this.frequencyOnChange(this.clinicalForm.value.frequency);
  }
  updateStatus(id, disabled, idx) {
    if (!this.shareService.checkEmpty(id)) {
      const content = disabled ? 'enable' : 'disable';
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn ' + content + ' quy trình này hay không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Bỏ qua',
        nzOnOk: () => {
          this.treatmentProcessService
            .updateStatus(id, !disabled)
            .subscribe((res) => {
              if (res.isValid) {
                this.notificationService.showNotification(
                  Constant.NOTIFY_TYPE.SUCCESS,
                  'Cập nhật thành công'
                );
                this.lstTreatmentProcess[idx].disabled = !disabled;
              } else {
                this.notificationService.showNotification(
                  Constant.NOTIFY_TYPE.ERROR,
                  res.errors[0].errorMessage
                );
              }
            });
        },
      });
    }
  }
  handleCancelRmd() {
    this.visibleRmdModal = false;
  }
  handleCancelClinical() {
    this.visibleClinicalModal = false;
  }
  saveClinicalForm() {
    if (this.clinicalForm.valid) {
      const form = this.clinicalForm.value;
      //check valid
      if (
        (form.activeDateType == 1 && form.activeDate == null) ||
        (form.activeDateType == 2 &&
          (form.fromDate == null || form.toDate == null))
      ) {
        return;
      }
      if (
        form.pushNotify == true &&
        ((form.frequency.code == 1 && form.dayOfWeek == null) ||
          this.pushNotifyTimes.includes(null))
      ) {
        return;
      }
      // check activeDateType
      if (form.activeDateType == 1) {
        form.fromDate = null;
        form.toDate = null;
      } else if (form.activeDateType == 2) {
        form.activeDate = null;
      }
      form.pushNotifyTimes = this.pushNotifyTimes;
      if (!this.isAddForm) {
        this.listClinicalForm[form.idx] = form;
      } else {
        this.listClinicalForm = [...this.listClinicalForm, form];
      }
      this.listClinicalForm = [...this.listClinicalForm];
      //console.log("listClinicalForm", this.listClinicalForm);
      this.visibleClinicalModal = false;
    } else {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn cần nhập các trường bắt buộc'
      );
    }
    //console.log("saveClinicalForm", this.pushNotifyTimes);
  }
  handleCancelClc() {
    this.visibleClinicalModal = false;
  }
  toTextFrequency(code) {
    return this.frequencyConfig.filter((en) => en.code == code)[0].name;
  }
  toTextDay(code) {
    return this.daysOfWeek.filter((en) => en.code == code)[0].name;
  }
  toTextNotify(push) {
    return push ? 'Yes' : 'No';
  }
}
