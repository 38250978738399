import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { AppConfigService } from 'src/app-config.service';
import { Visits } from 'src/app/models/listVisit.class';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/components/app-state/app-state';
import { Subscription } from 'rxjs';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { NotificationService } from 'src/app/services/notification.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { VisitService } from 'src/app/services/visit.service';
import { ListVisitService } from 'src/app/services/listVisit-service';
import { ObservationService } from 'src/app/services/observation.service';
import { Observation } from 'src/app/models/observationType.class';

@Component({
  selector: 'app-visit-info-header',
  templateUrl: './visit-info-header.component.html',
  styleUrls: ['./visit-info-header.component.scss'],
})
export class VisitInfoHeaderComponent implements OnInit {
  _visit: Visits;
  @Input() set visit(data: any) {
    if (data) {
      this._visit = data;
      this.disabledActiton = this.visit.status == Constant.VISIT_STATUS.Finished.value;
    }
  }
  get visit() {
    return this._visit;
  }
  @Input() hideButton: string;
  @Input() patient: any;
  @Input() isCovidGroup: boolean;
  @Input() subClinicalVisit = [];
  @Input() lstRelatedVisit = [];

  @Output() visitChange = new EventEmitter();
  @Output() clickChangeRoom = new EventEmitter();
  @Output() visitIdChange = new EventEmitter();

  @ViewChild('confirmReturnContent') confirmReturnContent: TemplateRef<any>;

  baseAvatarurl = '';
  age: number;
  formAddVisit: FormGroup;
  mainDoctor: any = {};
  presenterDoctor: any = {};
  isMobile: boolean;
  isExpandInfo: boolean;
  groupSub: Subscription;
  isCollapse = true;

  lstPermission = [
    { name: 'Điều phối viên', type: 1, checked: false },
    { name: 'Bác sĩ phụ trách', type: 2, checked: false },
    { name: 'Dược sĩ lâm sàng', type: 3, checked: false },
    { name: 'Chuyên viên tư vấn', type: 4, checked: false },
    { name: 'Điều dưỡng phụ trách', type: 5, checked: false },
    { name: 'Tình nguyện viên', type: 10, checked: false },
  ];
  VISIT_STATUS = Constant.VISIT_STATUS;
  isDeletedInfo = true;
  disabledActiton = false;
  curUserId = '';
  emptyId = '000000000000000000000000';
  useRoomOrDoctorVisit = false;
  currentUserRoomId = '';
  defaultObservationVitalSigns = [];
  vitualSignLabel = '';
  constructor(
    private tabDataService: TabDataService,
    private configService: AppConfigService,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private shareService: ShareService,
    private modalService: NzModalService,
    private visitService: VisitService,
    private listVisitService: ListVisitService,
    private observationService: ObservationService
  ) {
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
    this.store
      .select((store) => store.auth.roomId)
      .subscribe((data) => {
        this.currentUserRoomId = data;
      });
  }

  ngOnInit() {
    this.isMobile = window.innerWidth < 540;
    this.baseAvatarurl = this.configService.getConfig().media.baseUrl + '/Avatar/';
    this.age = new Date().getFullYear() - new Date(this.visit.dob).getFullYear();
    this.groupSub = this.store
      .select((store) => store.groupAuth)
      .subscribe(async (data) => {
        this.convertToPermissionItem(data.doctorPermissions);
        this.useRoomOrDoctorVisit = data.getVisitByRoomOrDoctor;
        this.defaultObservationVitalSigns = data.defaultObservationVitalSigns;
        this.vitualSignLabel = await this.getVitualSignLabel();
      });
  }
  convertToPermissionItem(values) {
    if (this.shareService.checkEmpty(values)) {
      return;
    }
    this.lstPermission.forEach((en) => {
      if (values.some((x) => x == en.type)) {
        en.checked = true;
      }
    });
  }

  showTabPatientInfo(id, PatientName, visitId): void {
    console.log('ID', id, PatientName, visitId)
    this.tabDataService.updateTab(id, PatientName, 'PatientInfo', visitId);
  }
  showCKInfoFull(id, PatientName, visitId): void {
    console.log('ID', id, PatientName, visitId)
    this.tabDataService.updateTab(visitId, PatientName, 'VisitDetail', id);
  }

  editPatientInfo(id, PatientName, visitId): void {
    this.tabDataService.updateTab(id, PatientName, 'PatientInfoEdit', visitId);
  }

  toggleExpand(): void {
    this.isExpandInfo = !this.isExpandInfo;
  }

  get f() {
    return this.formAddVisit.controls;
  }

  getShortContent(str: any, maxLength = 24) {
    if (!str) {
      return '';
    }
    if (str.length < maxLength) {
      return str;
    } else {
      return str.substring(0, maxLength) + '..';
    }
  }

  getVisitStatus() {
    const currentStatus = Object.values(this.VISIT_STATUS).find((e) => e.value == this.visit.status);
    return currentStatus ? currentStatus.text : 'Không xác định';
  }

  collapseBox() {
    this.isCollapse = !this.isCollapse;
    this.setHeaderHeight(this.isCollapse);
  }

  setHeaderHeight(isCollapsed) {
    if (isCollapsed) {
      const h = this.visit.bhytCode == '' ? '75px' : '100px';
      document.documentElement.style.setProperty('--h-visit-header', h);
    } else {
      document.documentElement.style.setProperty('--h-visit-header', '25px');
    }
  }

  onClickChangeRoom() {
    if (this.visit.status == Constant.VISIT_STATUS.Finished.value) {
      return this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, 'Ca khám đã hoàn tất');
    }
    this.clickChangeRoom.emit();
  }
  receivedVisit() {
    this.visitService.receiveVisit(this.visit.id).subscribe((res) => {
      if (res.isValid) {
        this.visit.isReceived = true;
        this.visit.doctorId = this.curUserId;
        this.visitChange.emit({ ...this.visit });
        this.notificationService.showNotification(Constant.SUCCESS, Constant.MESSAGE_UPDATE_SUCCESS);
      } else {
        this.notificationService.showNotification(Constant.ERROR, res.errors[0].errorMessage);
      }
    });
  }
  // checkValidReturn() {
  //   let subClinicalMainVisit = this.subClinicalVisit.filter((en) => en.visitId == this.visit.id && !en.refVisitId);
  //   console.log('checkValidReturn', this.subClinicalVisit, subClinicalMainVisit.length, this.lstRelatedVisit.length);

  //   if (subClinicalMainVisit.length > 0 || this.lstRelatedVisit.length > 0) {
  //     this.modalService.warning({
  //       nzTitle: 'Confirm',
  //       nzContent: `Bạn cần hủy chỉ định và dịch vụ khám thêm trước khi bỏ nhận ca khám!`,
  //       nzCancelText: 'Bỏ qua',
  //       nzOkText: 'Đồng ý',
  //     });
  //   } else {
  //     this.modalService.confirm({
  //       nzTitle: 'Confirm',
  //       nzContent: this.confirmReturnContent,
  //       nzCancelText: 'Hủy',
  //       nzOkText: 'Đồng ý',
  //       nzOnOk: () => {
  //         this.returnVisit();
  //       },
  //     });
  //   }
  // }

  checkValidReturn() {
    let subClinicalMainVisit = this.subClinicalVisit.filter((en) => en.visitId == this.visit.id);
    let lstRelatedVisit_Children = this.subClinicalVisit.filter((en) => en.refVisitId == this.visit.id);
    console.log(
      'checkValidReturn',
      this.subClinicalVisit,
      subClinicalMainVisit.length,
      this.lstRelatedVisit.length,
      this.lstRelatedVisit
    );

    if (subClinicalMainVisit.length > 0 || lstRelatedVisit_Children.length > 0) {
      this.modalService.warning({
        nzTitle: 'Confirm',
        nzContent: 'Bạn cần hủy chỉ định và dịch vụ khám thêm trước khi bỏ nhận ca khám!',
        nzCancelText: 'Bỏ qua',
        nzOkText: 'Đồng ý',
      });
    } else {
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: this.confirmReturnContent,
        nzCancelText: 'Hủy',
        nzOkText: 'Đồng ý',
        nzOnOk: () => {
          this.returnVisit();
        },
      });
    }
  }
  returnVisit() {
    this.visitService.returnVisit(this.visit.id).subscribe((res) => {
      if (res.isValid) {
        this.visitIdChange.emit(this.visit.id);
        this.notificationService.showNotification(Constant.SUCCESS, Constant.MESSAGE_UPDATE_SUCCESS);
      } else {
        this.notificationService.showNotification(Constant.ERROR, res.errors[0].errorMessage);
      }
    });
  }
  async getVitualSignLabel() {
    let vitualSignLabelArr = [];
    const dataFilter = {
      visitId: this.visit.id,
      observationCategory: -1,
    };
    let res = await this.observationService.getObservationVS(dataFilter).toPromise();

    if (res) {
      //this.observationVS = res;
      this.defaultObservationVitalSigns.forEach((x) => {
        let ot = res.find((y) => {
          return y.otCode == x.code;
        });
        if (ot) {
          vitualSignLabelArr.push(`${x.title}: ${ot.value2Show}${ot.unit}`);
        }
      });
    }
    return vitualSignLabelArr.join(' | ');
  }
}
