<div style="padding: 20px">
  <!-- <div *ngIf="fromAdminPage" class="page-title mg-bt-20">
        Danh mục quy trình điều trị
    </div> -->
  <div class="d-flex justify-content-between mg-bt-15">
    <div class="d-flex">
      <div style="width: 200px" class="mg-r-10">
        <input nz-input [(ngModel)]="textSearch" type="text" placeholder="Tìm kiếm"
          (keydown.enter)="getTreatmentProcesses()" />
      </div>
      <button nz-button (click)="getTreatmentProcesses()">
        <i nz-icon nzType="search" nzTheme="outline"></i>
      </button>
    </div>
    <div>
      <button nz-button class="btn-primary-small" nzType="success" (click)="showModalForm('add', '')">
        <i nz-icon nzType="plus" nzTheme="outline"></i>
        Thêm mới
      </button>
    </div>
  </div>
  <nz-table #treatment class="main-table-grid" [nzData]="lstTreatmentProcess" [nzPageIndex]="pageIndex"
    [nzPageSize]="pageSize" [nzTotal]="total" nzShowSizeChanger [nzFrontPagination]="false"
    [nzPageSizeOptions]="[10, 20, 50, 100]" [nzShowTotal]="rangeTemplate" [nzLoading]="loading"
    (nzQueryParams)="onQueryParamsChange($event)">
    <thead>
      <tr>
        <th>STT</th>
        <th>Tên danh mục</th>
        <th>Phạm vi sử dụng</th>
        <th>Người tạo</th>
        <th>Mô tả</th>
        <th>Thời gian cập nhật</th>
        <th>Trạng thái</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of treatment.data; index as i">
        <td>
          {{ treatment.nzPageSize * (treatment.nzPageIndex - 1) + i + 1 }}
        </td>
        <td>{{ data.title }}</td>
        <td>{{ toPrivacyText(data.privacyUse) }}</td>
        <td>{{ data.creatorName }}</td>
        <td>{{ data.description }}</td>
        <td>{{ data.modifiedDate | date: 'dd/MM/yyy HH:mm' }}</td>
        <td>
          <span *ngIf="data.disabled" class="red-dot">Disable</span>
          <span *ngIf="!data.disabled">Enable</span>
        </td>
        <td *ngIf="data.canEdit">
          <i nz-icon nzType="edit" class="mg-r-10" title="Chỉnh sửa" (click)="showModalForm('update', data)"
            nzTheme="outline"></i>
          <i nz-icon nzType="delete" class="mg-r-10" title="Xóa" (click)="removeTreatmentProcess(data.id)"
            nzTheme="outline"></i>
          <i nz-icon nzType="stop" title="Disable/Enable" (click)="updateStatus(data.id, data.disabled, i)"
            nzTheme="outline"></i>
        </td>
        <td *ngIf="!data.canEdit">
          <i nz-icon nzType="eye" title="Xem" (click)="showModalForm('view', data)" nzTheme="outline"></i>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <ng-template #rangeTemplate let-range="range" let-total>
    {{ range[0] }}-{{ range[1] }} trên {{ total }} quy trình
  </ng-template>
</div>

<nz-modal [(nzVisible)]="isVisibleForm" nzTitle="Nhập danh mục quy trình điều trị" nzWidth="1000px"
  nzMaskClosable="false" (nzOnCancel)="handleCancel()">
  <div class="form-clinical">
    <form [formGroup]="processForm">
      <div class="row mg-bt-10">
        <div class="col-3">Tên danh mục <span class="red-dot">*</span></div>
        <div class="col-9">
          <div *ngIf="isOnlyViewData">{{ processForm.value.title }}</div>
          <div *ngIf="!isOnlyViewData">
            <input nz-input formControlName="title" type="text" placeholder="Tên danh mục..." />
            <div class="message-required" *ngIf="
                submitted &&
                (processFormData.title.errors ||
                  processFormData.title.hasError('whitespace'))
              ">
              <span class="fs-12">*Tên danh mục không được để trống</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mg-bt-10">
        <div class="col-3">Mô tả <span class="red-dot">*</span></div>
        <div class="col-9">
          <div *ngIf="isOnlyViewData">{{ processForm.value.description }}</div>
          <div *ngIf="!isOnlyViewData">
            <input nz-input formControlName="description" type="text" placeholder="Mô tả..." />
            <div class="message-required" *ngIf="
                submitted &&
                (processFormData.description.errors ||
                  processFormData.title.hasError('whitespace'))
              ">
              <span class="fs-12">*Mô tả không được để trống</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mg-bt-10">
        <div class="col-3">Phạm vi sử dụng</div>
        <div class="col-9">
          <div *ngIf="isOnlyViewData">
            {{ toPrivacyText(processForm.value.privacyUse) }}
          </div>
          <div *ngIf="!isOnlyViewData">
            <nz-radio-group formControlName="privacyUse">
              <label nz-radio [nzDisabled]="!isTreatmentManager" [nzValue]="1">Toàn hệ thống</label>
              <label nz-radio [nzDisabled]="!fromAdminPage && !isAdminGroup" [nzValue]="2">Trong nhóm BS</label>
              <label nz-radio [nzValue]="3">Chỉ mình tôi</label>
            </nz-radio-group>
          </div>
        </div>
      </div>
      <div *ngIf="processForm.value.privacyUse == privacyUse.Group">
        <div *ngIf="!isOnlyViewData" class="row mg-bt-10">
          <div class="col-3 pd-l-30">Chọn nhóm</div>
          <div class="col-3">
            <nz-select class="w-100" nzShowSearch [(ngModel)]="groupShare" [ngModelOptions]="{ standalone: true }"
              nzAllowClear nzPlaceHolder="Nhóm..." (nzOnSearch)="onSearchGroup($event)"
              (ngModelChange)="selectGroup($event)">
              <nz-option nzCustomContent *ngFor="let group of searchDataGroupFilter" [nzValue]="group"
                [nzLabel]="group.name">
                <div>{{ group.name }}</div>
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div *ngIf="lstGroupShare.length > 0" class="row mg-bt-10">
          <div class="col-3 pd-l-30">Danh sách nhóm</div>
          <div class="col-9">
            <div class="list-group-share">
              <li *ngFor="let group of lstGroupShare" class="mg-r-10 d-inline">
                {{ group.name }}
                <i *ngIf="!isOnlyViewData" nz-icon nzType="close" style="font-size: 10px" nzTheme="outline"
                  (click)="unSelectGroup(group.id)"></i>
              </li>
            </div>
          </div>
        </div>
      </div>
      <div class="row mg-bt-10">
        <div class="col-3">Tự động kết thúc quy trình điều trị</div>
        <div class="col-9">
          <div *ngIf="isOnlyViewData">
            {{ toTextNotify(processForm.value.isAutoEnd) }}
          </div>
          <div *ngIf="!isOnlyViewData">
            <label nz-checkbox formControlName="isAutoEnd"></label>
          </div>
        </div>
      </div>
      <div *ngIf="processForm.value.isAutoEnd" class="row mg-bt-10">
        <div class="col-3 pd-l-30">Ngày kết thúc</div>
        <div class="col-9">
          <span *ngIf="isOnlyViewData">{{ processForm.value.dayEnd }}</span>
          <span *ngIf="!isOnlyViewData">
            <input style="width: 100px" nz-input formControlName="dayEnd" type="number" min="0" />
          </span>
          <span><i>&nbsp;(Ngày thứ mấy bắt đầu từ ngày N0)</i></span>
        </div>
      </div>
    </form>
    <div class="row mg-bt-10">
      <div class="col-3">Danh sách reminder</div>
      <div class="col-9">
        <nz-table *ngIf="listReminder.length != 0" class="mg-bt-10" #reminderTable [nzData]="listReminder"
          [nzShowPagination]="false" nzSize="small">
          <thead>
            <tr>
              <th nzWidth="30%">Tên reminder</th>
              <th nzWidth="40%">Mô tả</th>
              <th nzWidth="20%">Thời gian (ngày thứ)</th>
              <th nzWidth="10%" *ngIf="!isOnlyViewData"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of reminderTable.data; index as i">
              <td>{{ data.title }}</td>
              <td>{{ data.detail }}</td>
              <td>{{ data.activeDate }}</td>
              <td *ngIf="!isOnlyViewData">
                <i class="mg-r-10" nz-icon nzType="edit" nzTheme="outline" (click)="openModalRmd(data, i)"></i>
                <i nz-icon nzType="delete" nzTheme="outline" (click)="removeRmd(data)"></i>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <button *ngIf="!isOnlyViewData" nz-button (click)="openModalRmd('add')">
          <i nz-icon nzType="plus" nzTheme="outline"></i> Thêm reminder
        </button>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Danh sách form nhập thông tin lâm sàng</div>
      <div class="col-9">
        <nz-table *ngIf="listClinicalForm.length != 0" class="mg-bt-10" #clinicalTable [nzData]="listClinicalForm"
          [nzShowPagination]="false" nzSize="small">
          <thead>
            <tr>
              <th nzWidth="20%">Tên form</th>
              <th nzWidth="30%">Form</th>
              <th nzWidth="15%">Ngày áp dụng</th>
              <th nzWidth="15%">Tần suất</th>
              <th nzWidth="10%">Notify</th>
              <th nzWidth="10%" *ngIf="!isOnlyViewData"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of clinicalTable.data; index as i">
              <td>{{ data.title }}</td>
              <td>
                <div>Web - {{ data.clinicalFormWeb.name }}</div>
                <div>Mobile - {{ data.clinicalFormMobile.name }}</div>
              </td>
              <td>
                <span *ngIf="data.activeDate != null">{{
                  data.activeDate
                  }}</span>
                <span *ngIf="data.activeDate == null">{{ data.fromDate }} - {{ data.toDate }}</span>
              </td>
              <td>{{ toTextFrequency(data.frequency) }}</td>
              <td>
                <div>{{ toTextNotify(data.pushNotify) }}</div>
                <div *ngIf="data.dayOfWeek != null && data.frequency == 1">
                  {{ toTextDay(data.dayOfWeek) }}
                </div>
                <div *ngFor="let time of data.pushNotifyTimes">
                  {{ time | date: 'HH:mm' }}
                </div>
              </td>
              <td *ngIf="!isOnlyViewData">
                <i class="mg-r-10" nz-icon nzType="edit" nzTheme="outline" (click)="openModalClinicalForm(data, i)"></i>
                <i nz-icon nzType="delete" nzTheme="outline" (click)="removeClinicalForm(data)"></i>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <button *ngIf="!isOnlyViewData" nz-button (click)="openModalClinicalForm('add')">
          <i nz-icon nzType="plus" nzTheme="outline"></i> Thêm form
        </button>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-small" (click)="handleCancel()">
      Đóng
    </button>
    <button *ngIf="isProcessFormAdd && !isOnlyViewData" nz-button class="btn-primary-small"
      (click)="saveTreatmentProcess()">
      Thêm
    </button>
    <button *ngIf="!isProcessFormAdd && !isOnlyViewData" nz-button class="btn-primary-small"
      (click)="saveTreatmentProcess()">
      Sửa
    </button>
  </div>
</nz-modal>

<!-- Modal add, edit clinical form -->
<nz-modal [(nzVisible)]="visibleClinicalModal" nzTitle="Thông tin form" nzWidth="700px" nzMaskClosable="false"
  (nzOnCancel)="handleCancelClinical()">
  <form [formGroup]="clinicalForm">
    <div class="row mg-bt-10">
      <div class="col-3">Tên form <span class="red-dot">*</span></div>
      <div class="col-9">
        <input nz-input formControlName="title" type="text" placeholder="Tên form..." />
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Form web <span class="red-dot">*</span></div>
      <div class="col-3">
        <nz-select class="w-100" formControlName="clinicalFormWeb" nzShowSearch [nzServerSearch]="true" nzAllowClear
          nzPlaceHolder="Chọn form..." (nzOnSearch)="onSearchPPFormWeb($event)">
          <nz-option *ngFor="let form of searchFormPPWebFilter" [nzValue]="form" [nzLabel]="form.name">
          </nz-option>
        </nz-select>
      </div>
      <div *ngIf="clinicalForm.value.clinicalFormWeb != null" class="col-6" style="line-height: 30px">
        {{ clinicalForm.value.clinicalFormWeb.name }}
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Form mobile <span class="red-dot">*</span></div>
      <div class="col-3">
        <nz-select class="w-100" formControlName="clinicalFormMobile" nzShowSearch [nzServerSearch]="true" nzAllowClear
          nzPlaceHolder="Chọn form..." (nzOnSearch)="onSearchPPFormMobile($event)">
          <nz-option *ngFor="let form of searchFormPPMobileFilter" [nzValue]="form" [nzLabel]="form.name">
          </nz-option>
        </nz-select>
      </div>
      <div *ngIf="clinicalForm.value.clinicalFormMobile != null" class="col-6" style="line-height: 30px">
        {{ clinicalForm.value.clinicalFormMobile.name }}
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Tần suất <span class="red-dot">*</span></div>
      <div class="col-9">
        <nz-select class="w-100" nzAllowClear nzPlaceHolder="Tần suất..." formControlName="frequency"
          (ngModelChange)="frequencyOnChange($event)">
          <nz-option nzCustomContent *ngFor="let config of frequencyConfig" [nzValue]="config.code"
            [nzLabel]="config.name">
            <div>{{ config.name }}</div>
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Ngày áp dụng <span class="red-dot">*</span></div>
      <div class="col-9">
        <nz-radio-group formControlName="activeDateType">
          <label nz-radio [nzValue]="1" [nzDisabled]="disabledActiveDate">1 ngày</label>
          <label nz-radio [nzValue]="2" [nzDisabled]="disabledActiveFromTo">Từ ngày tới ngày</label>
        </nz-radio-group>
        <div *ngIf="clinicalForm.value.activeDateType == 1" class="mg-t-10">
          <input style="width: 100px" nz-input formControlName="activeDate" type="number" min="0" />
          <span><i>&nbsp;(Ngày thứ mấy bắt đầu từ ngày N0)</i></span>
        </div>
        <div *ngIf="clinicalForm.value.activeDateType == 2" class="mg-t-10">
          <input style="width: 100px" class="mg-r-15" nz-input formControlName="fromDate" type="number" min="0"
            placeholder="Từ ngày" />
          <input style="width: 100px" nz-input formControlName="toDate" type="number" min="0" placeholder="Tới ngày" />
          <span><i>&nbsp;(Ngày thứ mấy bắt đầu từ ngày N0)</i></span>
        </div>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Notify nhắc BN</div>
      <div class="col-9">
        <label nz-checkbox formControlName="pushNotify"></label>
      </div>
    </div>
    <div *ngIf="clinicalForm.value.pushNotify" class="row">
      <div class="col-3">
        Thời gian gửi notify <span class="red-dot">*</span>
      </div>
      <div class="col-9">
        <nz-select *ngIf="clinicalForm.value.frequency == 1" class="mg-r-10" style="width: 120px"
          formControlName="dayOfWeek" nzPlaceHolder="Chọn thứ">
          <nz-option *ngFor="let day of daysOfWeek" [nzValue]="day.code" [nzLabel]="day.name"></nz-option>
        </nz-select>
        <ng-container *ngFor="let time of pushNotifyTimes; index as i">
          <nz-time-picker class="mg-r-10" [ngModel]="time" (ngModelChange)="setTime($event, i)" nzFormat="HH:mm"
            [ngModelOptions]="{ standalone: true }" nzPlaceHolder="Chọn t.gian" [nzMinuteStep]="15">
          </nz-time-picker>
        </ng-container>
      </div>
    </div>
  </form>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-small" (click)="handleCancelClc()">
      Đóng
    </button>
    <button nz-button class="btn-primary-small" (click)="saveClinicalForm()">
      Lưu
    </button>
  </div>
</nz-modal>

<!-- Modal add, edit reminder -->
<nz-modal [(nzVisible)]="visibleRmdModal" nzTitle="Thông tin Reminder" nzMaskClosable="false"
  (nzOnCancel)="handleCancelRmd()">
  <form [formGroup]="reminderForm">
    <div class="row mg-bt-10">
      <div class="col-3">Tên reminder <span class="red-dot">*</span></div>
      <div class="col-9">
        <input nz-input formControlName="title" type="text" placeholder="Tên reminder..." />
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Mô tả <span class="red-dot">*</span></div>
      <div class="col-9">
        <input nz-input formControlName="detail" type="text" placeholder="Mô tả..." />
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Thời gian <span class="red-dot">*</span></div>
      <div class="col-9">
        <input style="width: 100px" nz-input formControlName="activeDate" type="number" min="0" />
        <span><i>&nbsp;(Ngày thứ mấy bắt đầu từ ngày N0)</i></span>
      </div>
    </div>
  </form>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-small" (click)="handleCancelRmd()">
      Đóng
    </button>
    <button nz-button class="btn-primary-small" (click)="saveRmd()">Lưu</button>
  </div>
</nz-modal>