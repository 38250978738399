import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'monthViewPipe',
})
export class MonthViewPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(date, month: any, year, arr): any {
    // console.log(arguments);
    if (!date || month == null || month == undefined || !year) return;
    let timeFr = new Date(year, month, date);
    let timeTo = new Date(year, month, date + 1);

    let isoTimeFr = timeFr.toISOString();
    let isoTimeTo = timeTo.toISOString();

    // console.log('====',isoTimeFr,isoTimeTo);

    let result = arr.filter((el) => {
      let date = new Date(el.visitDate).toISOString();
      return date >= isoTimeFr && date < isoTimeTo;
    });

    let html = '';
    if (result.length > 0) {
      result.forEach((el) => {
        html += `<div style="color:#007bff;border: 1px solid #c5baba;margin-top:10px; border-radius: 10px;
        margin-right: 10px;
        padding: 5px;
        background: #ffecb4;
        max-width: 205px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;" class="visit-item"  data-visit='${JSON.stringify(el)}'>
        <div style="margin-left: 5px;">
           ${new DatePipe('en-Us').transform(el.visitDate, 'HH:mm')}
            ${el.patientName}
        </div>
          </div>`;
      });
    }

    return this.sanitized.bypassSecurityTrustHtml(html);
  }
}
