<nz-select
  [nzPlaceHolder]="selectedItem.label"
  [(ngModel)]="selectedItem"
  nzShowSearch
  (ngModelChange)="selectSearchItem($event)"
  class="mg-bt-4 search-label">
  <nz-option
    *ngFor="let item of filterItems"
    [nzLabel]="item.label"
    [nzValue]="item"
    [nzDisabled]="item.isUsed">
  </nz-option>
</nz-select>
<ng-container [ngSwitch]="selectedItem.type" style="height: 100%">
  <app-input-filter
    *ngSwitchCase="types.FILTER_TYPE.INPUT"
    [searchItem]="selectedItem"
    [searchParam]="searchParam"
    (seachListPatient)="seachListPatient($event)"
    (mFilterValueChange)="mFilterValueChange($event)"></app-input-filter>
  <app-selection-filter
    *ngSwitchCase="types.FILTER_TYPE.SELETECTION"
    [searchItem]="selectedItem"
    [searchParam]="searchParam"
    (endTreatment)="endTreatment($event)"
    (seachListPatient)="seachListPatient($event)"
    (mFilterValueChange)="mFilterValueChange($event)"></app-selection-filter>
  <app-input-number-filter
    *ngSwitchCase="types.FILTER_TYPE.INPUT_NUMBER"
    [searchItem]="selectedItem"
    [searchParam]="searchParam"
    (seachListPatient)="seachListPatient($event)"
    (mFilterValueChange)="mFilterValueChange($event)"></app-input-number-filter>
  <app-date-filter
    *ngSwitchCase="types.FILTER_TYPE.DATE_PICKER"
    [searchItem]="selectedItem"
    [searchParam]="searchParam"
    (seachListPatient)="seachListPatient($event)"
    (mFilterValueChange)="mFilterValueChange($event)"></app-date-filter>
</ng-container>
