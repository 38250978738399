import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { SubClinicalVisitService } from 'src/app/services/subcliniccal-service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
})
export class DiscountComponent implements OnInit, OnChanges {
  @Input() data = null;
  @Input() type = false;
  @Input() isVisible = false;
  @Input() visitId = '';
  @Input() currentOrdersDiscount = [];

  @Output() closeModal = new EventEmitter();
  @Output() discountChange = new EventEmitter();

  totalBillFee = 0;
  totalBillSale = 0;
  isLoading = false;
  currentOrders = [];
  refVisitId = '';
  isPercent = false;
  constructor(
    private shareService: ShareService,
    private subClinicalVisitService: SubClinicalVisitService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    console.log('Data', this.data)
    console.log('TYPE', this.type)
  }

  ngOnChanges(changes: SimpleChanges) {
    var chng = changes.currentOrdersDiscount;
    if (chng && chng.currentValue != null) {
      this.currentOrders = JSON.parse(JSON.stringify(this.currentOrdersDiscount));
      this.getTotalBill();
    }
    console.log('TYPE', this.type)
  }
  cancelDiscount() {
    this.isVisible = false;
    this.closeModal.emit();
  }
  save() {
    if (this.type) {
      console.log('HHH',this.currentOrders)
      this.isLoading = true;
      const payload = {
        visitId: this.visitId,
        refVisitId:this.currentOrders[0].visitId ,
        discountAmount: this.currentOrders[0].discountAmount,
        discountPercent: this.currentOrders[0].discountPercent 
      };
      console.log('H', payload)
      this.subClinicalVisitService.updateDiscountForOtherService(payload).subscribe((res) => {
        if (res.isValid == true) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
          this.isVisible = false;
          this.isLoading = false;
          this.closeModal.emit();
          this.discountChange.emit();
        } else {
          this.isLoading = false;
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    }
    else {
      this.isLoading = true;
      let discountRequests = [];

      this.currentOrders.forEach((en) => {
        if (en.withPercent) en.discountAmount = 0;
        else en.discountPercent = 0;
        let item = {
          visitId: this.visitId,
          subClinicalVisitId: en.id,
          amount: en.amount,
          price: en.price,
          discountAmount: en.discountAmount,
          discountPercent: en.discountPercent,
        };
        discountRequests.push(item);
      });
      const payload = {
        visitId: this.visitId,
        discountRequests: discountRequests,
      };
      this.subClinicalVisitService.updateDiscount(payload).subscribe((res) => {
        if (res.isValid == true) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
          this.isVisible = false;
          this.isLoading = false;
          this.closeModal.emit();
          this.discountChange.emit();
        } else {
          this.isLoading = false;
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    }
  }

  getTotalBill() {
    console.log('currentOrders123',this.currentOrders)
    this.totalBillSale = 0;
    this.totalBillFee = 0;
    // if(!this.isPercent) {
    //   this.currentOrders.forEach((en) => {
    //     if (!en.disabled) {
    //       en.discount = this.calcSaleAmount(en);
    //       en.finalFee = en.originalFee - en.discount;
    //       this.totalBillSale += this.calcSaleAmount(en);
    //       this.totalBillFee += en.originalFee * en.amount;
    //     }
    //   });
    // }
    this.currentOrders.forEach((en) => {
      if (!en.disabled) {
        en.discount = this.calcSaleAmount(en);
        en.finalFee = en.originalFee - en.discount;
        this.totalBillSale += this.calcSaleAmount(en);
        this.totalBillFee += en.originalFee * en.amount;
      }
    });
    // if(this.isPercent) {
    //   this.currentOrders.forEach((en) => {
    //     if (!en.disabled) {
    //       en.discount = this.calcSaleAmount(en);
    //       en.finalFee = en.originalFee - (en.originalFee * en.discountPercent / 100);
    //       this.totalBillSale += this.calcSaleAmount(en);
    //       this.totalBillFee += en.originalFee * en.amount;
    //     }
    //   });
    // }
  }

  typingDiscountPercent(data, input) {
    if (data.discountPercent > 100 || data.discountPercent < 0) {
      data.discountPercent = 0;
      input.value = '';
    }

    data.discount = this.calcSaleAmount(data);
    data.finalFee = data.originalFee - data.discount;
    this.getTotalBill();
  }
  typingDiscountAmount(data, input) {
    if (input != null) {
      if (data.discountAmount > data.price * data.amount) {
        data.discountAmount = 0;
        input.value = '';
      }

      data.discount = this.calcSaleAmount(data);
      data.finalFee = data.originalFee - data.discount;
      this.getTotalBill();
    }
  }
  calcSaleAmount(invoice: any) {
    let sale = 0;
    if (invoice.withPercent == true) {
      if (invoice.discountPercent != null && invoice.discountPercent > 0) {
        sale = Math.round(((invoice.amount??1) * (invoice.price?? invoice.servicePrice) * invoice.discountPercent) / 100);
        console.log(invoice)
        console.log(invoice.price?? invoice.servicePrice)
        console.log(invoice.discountPercent)
        console.log(invoice.discountAmount)
        console.log(((invoice.amount??1) * (invoice.price?? invoice.discountAmount) * invoice.discountPercent) / 100)
      } else {
        sale = 0;
      }
    } else {
      if (invoice.discountAmount != null && invoice.discountAmount > 0) sale = Number(invoice.discountAmount);
    }
    return sale;
  }
  chooseDiscountPercentOrAmount(data: any, isPercentOrAmount: any) {
    data.withPercent = isPercentOrAmount;
    if (isPercentOrAmount && data.discountAmount != null && data.discountAmount > 0) {
      data.discountPercent = Math.round((data.discountAmount * 100) / (data.price * data.amount));
      this.isPercent = true
    }
      // data.discountPercent = Math.round((data.discountAmount * 100) / (data.price * data.amount));
    else if (!isPercentOrAmount && data.discountPercent != null && data.discountPercent > 0) {
      data.discountAmount = Math.round((data.discountPercent * data.price * data.amount) / 100);
      this.isPercent = false

    }
      // data.discountAmount = Math.round((data.discountPercent * data.price * data.amount) / 100);
  }
}
