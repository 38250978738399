<div *ngIf="!isMobile" class="thuoc-table">
  <nz-table
    #tablethuoc2
    class="main-table-grid-wrap-text"
    nzSize="small"
    [nzData]="listPrescription"
    nzShowPagination="false"
    [nzScroll]="{ y: '30vh' }"
    [nzPageSize]="100">
    <thead>
      <tr>
        <th nzWidth="50px">STT</th>
        <th>Thời gian khám</th>
        <th>Thời gian kê toa</th>
        <th>Người tạo</th>
        <th *ngIf="isUseApprovePrescription">Người duyệt</th>
        <th>Trạng thái</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of tablethuoc2.data; index as i">
        <td class="text-center">{{ i + 1 }}</td>
        <td class="text-nowrap" (click)="gotoPrescriptionTab(data)">
          {{ data.VisitDate | date: dateTimeFormat }}
        </td>
        <td class="text-nowrap" (click)="gotoPrescriptionTab(data)">
          {{ data.ModifiedDate | date: dateTimeFormat }}
        </td>
        <td>{{ data.CreatorName }}</td>
        <td *ngIf="isUseApprovePrescription">{{ data.ApproverName }}</td>
        <td>{{ data.StatusString }}</td>
        <td>
          <i
            title="Xem nhanh"
            style="font-size: 20px"
            nz-icon
            nzType="file-search"
            nzTheme="outline"
            (click)="showModalPreview(data)"></i>
          &nbsp;
          <i
            *ngIf="data.CanEdit && isCanActionCurPrescription"
            title="Cập nhật"
            style="font-size: 20px"
            nz-icon
            nzType="edit"
            nzTheme="outline"
            (click)="gotoPrescriptionTab(data)"></i>
          &nbsp;
          <i
            *ngIf="isCanActionCurPrescription"
            title="Dùng lại đơn"
            style="font-size: 20px"
            nz-icon
            nzType="copy"
            nzTheme="outline"
            (click)="copyPrescription(data)"></i>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
<div *ngIf="isMobile">
  <div
    *ngFor="let data of listPrescriptionOfPatient; index as i"
    class="mg-bt-10 pres-item"
    (click)="gotoPrescriptionTab(data)">
    <div style="font-weight: 600">
      {{ i + 1 }} - {{ data.CreatorName }} -
      {{ data.StatusString }}
    </div>
    <div class="d-flex justify-content-between">
      <span class="fs-12">
        Ca khám: {{ data.VisitDate | date: dateTimeFormat }}
      </span>
      <i
        title="Xem nhanh"
        nz-icon
        nzType="file-search"
        nzTheme="outline"
        (click)="showModalPreview(data)"></i>
    </div>
    <div class="d-flex justify-content-between">
      <span class="fs-12">
        Kê toa: {{ data.ModifiedDate | date: dateTimeFormat }}
      </span>
      <i
        title="Cập nhật"
        nz-icon
        nzType="edit"
        nzTheme="outline"
        (click)="gotoPrescriptionTab(data)"></i>
    </div>
  </div>
</div>

<app-prescription-detail
  [isVisible]="showDetail"
  [isUsePrescriptionDeliveryInfo]="isUseDeliveryInfo"
  (closeModal)="closeModalPreview()"
  [prescription]="prescriptionViewDetail"></app-prescription-detail>
