import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-element',
  templateUrl: './image-element.component.html',
  styleUrls: ['./image-element.component.scss'],
})
export class ImageElementComponent implements OnInit {
  @Input() data: any;
  constructor() {}

  ngOnInit() {}
}
