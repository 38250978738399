import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/share/share.module';
import { ListVisitCalendarComponent } from './list-visit-calendar.component';
import { DayViewComponent } from './day-view/day-view.component';
import { WeekViewComponent } from './week-view/week-view.component';
import { MonthViewComponent } from './month-view/month-view.component';
import { AngularSplitModule } from 'angular-split';
import { WeekViewPipe } from './week-view/week-view.pipe';
import { MonthViewPipe } from './month-view/month-view.pipe';
import { DayViewPipe } from './day-view/dayview.pipe';

@NgModule({
  declarations: [
    ListVisitCalendarComponent,
    DayViewComponent,
    WeekViewComponent,
    MonthViewComponent,
    WeekViewPipe,
    MonthViewPipe,
    DayViewPipe,
  ],
  imports: [CommonModule, SharedModule, AngularSplitModule],
  exports: [ListVisitCalendarComponent],
})
export class ListVisitCalendarModule {}
