<div class="custom-modal">
  <nz-modal
    class="custom-modal"
    [nzWidth]="900"
    [(nzVisible)]="isVisible"
    nzTitle="Cập nhật chỉ số xét nghiệm"
    (nzOnCancel)="handleCancel()"
    (nzOnOk)="handleCancel()">
    <div class="tab_container">
      <nz-table
        #basicTable
        [(nzData)]="chisoXN"
        class="table-csxn"
        nzSize="small"
        nzShowPagination="false"
        nzShowSizeChanger
        [nzPageSizeOptions]="[10, 20, 50, 100]">
        <thead>
          <tr>
            <th nzWidth="150px">Chọn chỉ số xét nghiệm</th>
            <th nzWidth="300px">Giá trị</th>
            <th>Thông tin</th>
            <th></th>
            <!-- <ng-container *ngFor="let td of cakhams">
            <th>{{td.visitDate}}</th>
          </ng-container> -->
          </tr>
        </thead>
        <tbody>
          <tr data-id="item.id" *ngFor="let item of chisoXN; let i = index">
            <!-- <td nzShowCheckbox [(nzChecked)]="mapOfCheckedId[item.id]" (nzCheckedChange)="refreshStatus()" nzLeft="0px"
              nzWidth="50px"></td> -->
            <td>
              <!-- <ng-select onchange="changeType(this)" dropdownPosition="top" [multiple]="false" [(ngModel)]="item.observationTypeId">
              <ng-option *ngFor="let ot of lstObservationType" [value]="ot.id">{{ot.name}}</ng-option>
           </ng-select> -->
              <nz-select
                nzShowSearch
                [nzShowArrow]="true"
                [nzDisabled]="item.isDisable"
                [nzServerSearch]="true"
                style="width: 200px"
                nzPlaceHolder="Chọn chỉ số XN"
                (ngModelChange)="changeType(item.id, $event)"
                (nzOnSearch)="onInputOT($event)"
                [(ngModel)]="item.observationTypeId">
                <nz-option
                  *ngFor="let opt of lstObservationType"
                  [nzLabel]="opt.name"
                  [nzValue]="opt.id">
                </nz-option>
              </nz-select>
              <!-- <nz-select
            nzShowSearch
            [nzShowArrow]="true"
            [nzServerSearch]="true"
            [(ngModel)]="suggestDiseases"
            nzPlaceHolder="Tìm kiếm bệnh theo mã ICD10"
            (nzOnSearch)="onInputICD10($event)"
            (ngModelChange)="fieldSelectedICD10($event)"
          >
            <nz-option
              *ngFor="let item of diseasesData"
              nzCustomContent
              [nzLabel]="item.code"
              [nzValue]="item"
            >
              <div>{{ item.code }} - {{ item.name }}</div>
            </nz-option>
          </nz-select> -->
            </td>
            <td class="XN-Add-Value" [ngSwitch]="item.dataType">
              <!-- <div *ngSwitchCase="DataType.NUMBER"> -->
              <nz-input-number
                *ngSwitchCase="DataType.NUMBER"
                nz-input
                [(ngModel)]="item.val"></nz-input-number>

              <!-- <div *ngSwitchCase="DataType.STRING"> -->
              <input
                nz-input
                *ngSwitchCase="DataType.STRING"
                [(ngModel)]="item.val" />

              <!-- <div *ngSwitchCase="DataType.SINGLECHOICE"> -->
              <!-- <ng-select *ngSwitchCase="DataType.SINGLECHOICE" [multiple]="false" [(ngModel)]="item.value">
                    <ng-option *ngFor="let itemSrc of item.dataSource" [value]="itemSrc.id">{{itemSrc.text}}</ng-option>
                </ng-select> -->
              <nz-select
                *ngSwitchCase="DataType.SINGLECHOICE"
                nzPlaceHolder="Chọn giá trị XN"
                [(ngModel)]="item.val">
                <nz-option
                  *ngFor="let itemSrc of item.dataSource"
                  [nzLabel]="itemSrc.text"
                  [nzValue]="itemSrc.id">
                </nz-option>
              </nz-select>
              <!-- <div *ngSwitchCase="DataType.DATE"> -->
              <nz-date-picker
                *ngSwitchCase="DataType.DATE"
                nzFormat="yyyy-MM-dd"
                [(ngModel)]="item.val"
                [nzPlaceHolder]="item.name">
              </nz-date-picker>

              <!-- <div *ngSwitchCase="DataType.DATETIME"> -->
              <nz-date-picker
                *ngSwitchCase="DataType.DATETIME"
                nzFormat="yyyy-MM-dd HH:mm:ss"
                nzShowTime
                [(ngModel)]="item.val"
                [nzPlaceHolder]="item.name">
              </nz-date-picker>
              <nz-radio-group
                *ngSwitchCase="DataType.POSITIVENEGATIVE"
                [(ngModel)]="item.val">
                <!-- (ngModelChange)="updateShareLink()" -->
                <label nz-radio [nzValue]="true" title="Dương tính"
                  >Positive</label
                >
                <label nz-radio [nzValue]="false" title="Âm tính"
                  >Negative</label
                >
              </nz-radio-group>

              <!-- </ng-container> -->
            </td>
            <td [innerHtml]="item.range"></td>
            <td>
              <a
                *ngIf="item.isNew === false && item.isDisable === false"
                (click)="removeThisRow(item.id)"
                >-</a
              >
              <a
                *ngIf="item.isNew === true"
                (click)="addNewRow(item.id, item.observationTypeId, item.value)"
                >+</a
              >
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
    <div *nzModalFooter>
      <button nz-button (click)="handleCancel()">Quay lại</button>
      <button
        nz-button
        nzType="primary"
        (click)="handleOk()"
        class="button-submit">
        Save
      </button>
    </div>
  </nz-modal>
</div>
