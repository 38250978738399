import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  IMyCalendarViewChanged,
  IMyDateModel,
  IMyDpOptions,
  IMyInputFieldChanged,
  IMyInputFocusBlur,
  IMyMarkedDate,
  IMySelector,
} from '../interfaces';
import { MyDatePicker } from '../mydatepicker.component';

export const MSCDP_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MscDatePickerComponent),
  multi: true,
};

@Component({
  selector: 'app-msc-date-picker',
  templateUrl: './msc-date-picker.component.html',
  styleUrls: ['./msc-date-picker.component.scss'],
  providers: [MyDatePicker, MSCDP_VALUE_ACCESSOR],
})
export class MscDatePickerComponent implements OnInit, ControlValueAccessor {
  _mModel: Date;
  @Input() set mModel(val: Date) {
    this._mModel = val;
    this.writeValue(this._mModel);
  }

  get mModel(): Date {
    return this._mModel;
  }
  @Input() disabled: boolean = false;
  @Output() mModelChanged: EventEmitter<any> = new EventEmitter();
  @ViewChild('myDatePicker') myDatePicker: MyDatePicker;

  myDatePickerNormalOptions: IMyDpOptions = {
    todayBtnTxt: 'Hôm nay',
    dateFormat: 'dd / mm / yyyy',
    firstDayOfWeek: 'mo',
    sunHighlight: true,
    markCurrentDay: true,
    height: '34px',
    width: '210px',
    alignSelectorRight: false,
    openSelectorTopOfInput: false,
    indicateInvalidDate: true,
    monthSelector: true,
    yearSelector: true,
    maxYear: 2200,
    componentDisabled: false,
    showClearDateBtn: true,
    showDecreaseDateBtn: false,
    showIncreaseDateBtn: false,
    showSelectorArrow: true,
    showInputField: true,
    openSelectorOnInputClick: false,
    disableHeaderButtons: true,
    showWeekNumbers: false,
    markDates: [],
    satHighlight: false,
    highlightDates: [],
    markWeekends: <IMyMarkedDate>{},
    allowDeselectDate: true,
    disableWeekdays: [],
    allowSelectionOnlyInCurrentMonth: true,
  };
  selectedDateNormal: string = '';
  //disabled: boolean = false;
  placeholder: string = 'dd/mm/yyyy';

  selector: IMySelector = {
    open: false,
  };
  formDate: Date;

  constructor() {}

  onChangeCb: (_: any) => void = () => {};
  onTouchedCb: () => void = () => {};

  writeValue(obj: any): void {
    this.formDate = obj;
    console.log(obj);
  }
  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.myDatePicker) {
      this.myDatePicker.setDisabledState(isDisabled);
    }
  }

  ngAfterViewInit() {
    // let data = {jsdate: this.initDate};
    // this.myDatePicker.writeValue(data);
    this.myDatePicker.registerOnChange(this.onChangeCb);
    this.myDatePicker.registerOnTouched(this.onTouchedCb);
  }
  ngOnInit() {}

  clearDate() {
    this.selectedDateNormal = '';
  }

  onDateChanged(event: Date) {
    // console.log(event);
    // console.log(this.EpochToDate(event.epoc));
    // let date = this.EpochToDate(event.epoc);
    this.mModelChanged.emit(event);
  }

  onInputFieldChanged(event: IMyInputFieldChanged) {
    // console.log(
    //   'onInputFieldChanged(): Value: ',
    //   event.value,
    //   ' - dateFormat: ',
    //   event.dateFormat,
    //   ' - valid: ',
    //   event.valid
    // );
  }

  onCalendarViewChanged(event: IMyCalendarViewChanged) {
    // console.log('onCalendarViewChanged(): Year: ', event.year, ' - month: ', event.month, ' - first: ', event.first, ' - last: ', event.last);
  }

  onInputFocusBlur(event: IMyInputFocusBlur): void {
    // console.log('onInputFocusBlur(): Reason: ', event. reason, ' - Value: ', event.value);
  }

  onCalendarToggle(event: number): void {
    // console.log('onCalendarToggle(): Value: ', event);
  }

  EpochToDate(epoch): Date {
    if (epoch < 10000000000) epoch *= 1000; // convert to milliseconds (Epoch is usually expressed in seconds, but Javascript uses Milliseconds)
    var epoch = epoch + new Date().getTimezoneOffset() * -1; //for timeZone
    return new Date(epoch);
  }
}
