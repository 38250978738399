import { Component, OnInit } from '@angular/core';
import { RosCategoryService } from '../../../../services/rosCategory.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { RosCategory } from 'src/app/models/rosCategory.class';
import { RosService } from '../../../../services/ros-service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NotificationService } from 'src/app/services/notification.service';
import { SubclinicalorderService } from '../../../../services/subclinicalorder.service';

@Component({
  selector: 'app-roscategory',
  templateUrl: './roscategory.component.html',
  styleUrls: ['./roscategory.component.scss'],
})
export class RoscategoryComponent implements OnInit {
  inputValue;
  ros_rosCateData;
  emptyId = '000000000000000000000000';
  selectedRowIndex = this.emptyId;
  rosData;
  rosCateData;
  rosCate;
  isVisible;
  public dataForm: any = {
    id: '000000000000000000000000',
    name: '',
    des: '',
    ros: '',
    parentId: '',
    rosid: '',
    parentName: '',
    order: 0,
  };
  listOfDisplayData;
  filterRosName = [];
  searchRosList;
  checkRosCategory = false;
  curData: any;
  searchKey = '';
  pageSize = 20;
  isEditing: boolean = false;
  constructor(
    private rosCategoryService: RosCategoryService,
    private rosService: RosService,
    private modalService: NzModalService,
    private notificationService: NotificationService,
    private subclinicalorderService: SubclinicalorderService
  ) {
    this.rosCateData = rosCategoryService.getRosCategoryAll();
  }

  ngOnInit(): void {
    this.loadRosCate();
    this.loadRos();
  }

  loadRosCate() {
    this.rosCategoryService.getRosCategoryAll().subscribe((data) => {
      this.rosCate = data;
      this.rosService.getRos().subscribe((rosdatas) => {
        for (const roscate of this.rosCate) {
          if (roscate.parentId == this.emptyId) {
            roscate.parentName = '';
          } else {
            for (const parentros of this.rosCate) {
              if (roscate.parentId == parentros.id) {
                roscate.parentName = parentros.name;
              }
            }
          }

          for (const ros_data of rosdatas) {
            if (roscate.rosid == ros_data.id) {
              roscate.ros = ros_data.name;
            }
          }
        }

        this.listOfDisplayData = [...this.rosCate];
      });
    });
  }

  search(searchRosList: string[]): void {
    this.searchRosList = searchRosList;
    const filterFunc = (item) =>
      this.searchRosList.length
        ? this.searchRosList.some((ros) => item.ros.indexOf(ros) !== -1)
        : true;

    this.listOfDisplayData = this.rosCate.filter((item) => filterFunc(item));
    // this.rosCategoryService.getRosCategoryAll().subscribe(data => {
    //   this.rosCate = data;
    //   this.rosService.getRos().subscribe(rosdatas => {
    //     for (let roscate of this.rosCate) {
    //       if (roscate.parentId == this.emptyId) {
    //         roscate.parentName = "";
    //       } else {
    //         for (let parentros of this.rosCate) {
    //           if (roscate.parentId == parentros.id) {
    //             roscate.parentName = parentros.name;
    //           }
    //         }
    //       }

    //       for (let ros_data of rosdatas) {
    //         if (roscate.rosid == ros_data.id) {
    //           roscate.ros = ros_data.name;
    //         }
    //       }
    //     }

    //     this.listOfDisplayData = [...this.rosCate];

    //     const data = this.listOfDisplayData.filter(item => filterFunc(item));
    //     this.listOfDisplayData = data;
    //   });
    // });
  }

  loadRos() {
    this.rosService.getRos().subscribe((data) => {
      this.rosData = data;
      this.filterRosName = [];
      for (const ros_data of data) {
        const objRosAdd = { text: ros_data.name, value: ros_data.name };
        this.filterRosName.push(objRosAdd);
      }
    });
  }

  showModal(dataForm): void {
    this.isVisible = true;
    if (dataForm == 'them') {
      this.selectedRowIndex = this.emptyId;
      this.dataForm = {
        id: '000000000000000000000000',
        name: '',
        des: '',
        ros: '',
        parentId: '000000000000000000000000',
        rosid: '',
        parentName: '',
        order: 0,
      };
    } else {
      this.rowSelected(this.curData);
      this.isEditing = true;
    }
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  deleteRosCate() {
    const id = this.selectedRowIndex;
    if (id == this.emptyId) {
      this.notificationService.showNotification(
        'warning',
        'Bạn chưa chọn dòng để xoá!'
      );
    } else {
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn xóa hay không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Huỷ',
        nzOnOk: () => this.deleteItem(id),
      });
    }
  }

  deleteItem(id) {
    this.subclinicalorderService
      .getSubclinicalOrderAll()
      .subscribe((subOrder) => {
        for (const sub_order of subOrder) {
          if (sub_order.rosCategoryId == id) {
            this.checkRosCategory = true;
          }
        }

        if (this.checkRosCategory == true) {
          return this.notificationService.showNotification(
            'error',
            'Xóa không thành công do đã tồn tại chỉ định của ros category này!!'
          );
        } else {
          this.rosCategoryService.deleteRosCate(id).subscribe((data) => {
            this.notificationService.showNotification(
              'success',
              'Xoá thành công'
            );
            this.loadRosCate();
          }).unsubscribe;
        }
        this.selectedRowIndex = this.emptyId;
      });
  }

  AddOrSave(datasend) {
    if (datasend.name == '' || datasend.rosid == '') {
      return this.notificationService.showNotification(
        'error',
        'Chưa nhập dữ liệu, không thể thêm'
      );
    }
    datasend.name = datasend.name.trim();
    this.rosService.getRos().subscribe((data) => {
      this.rosCategoryService
        .getRosCateGroup(datasend.rosid)
        .subscribe((cateGroup) => {
          for (const ros_data of data) {
            if (datasend.rosid == ros_data.id) {
              datasend.ros = ros_data.name;
            }
          }
          for (const ros_cate of cateGroup) {
            if (datasend.name == ros_cate.name) {
              return this.notificationService.showNotification(
                'error',
                'Tên bị trùng khi cùng ROS'
              );
            }
          }
          this.dataForm = {
            id: this.selectedRowIndex,
            name: datasend.name,
            ros: datasend.ros,
            des: datasend.des,
            rosid: datasend.rosid,
            parentId: datasend.parentId,
            parentName: datasend.parentName,
            order: datasend.order,
          };

          this.rosCategoryService.addRosCategory(this.dataForm).subscribe(
            (data) => {
              this.notificationService.showNotification(
                'success',
                'Thành công'
              );
              this.isVisible = false;
              this.loadRosCate();
            },
            (error) => {
              this.notificationService.showNotification(
                'error',
                'Chưa nhập hết dữ liệu'
              );
            }
          );
        });
    });
    this.selectedRowIndex = this.emptyId;
  }

  updateRosCategory(datasend) {
    if (datasend.name == '' || datasend.rosid == '') {
      return this.notificationService.showNotification(
        'error',
        'Chưa nhập dữ liệu, Không thể sửa'
      );
    }
    this.rosService.getRos().subscribe((data) => {
      this.rosCategoryService
        .getRosCateGroup(datasend.rosid)
        .subscribe((cateGroup) => {
          for (const ros_data of data) {
            if (datasend.rosid == ros_data.id) {
              datasend.ros = ros_data.name;
            }
          }
          this.dataForm = {
            id: datasend.id,
            name: datasend.name,
            ros: datasend.ros,
            des: datasend.des,
            rosid: datasend.rosid,
            parentId: datasend.parentId,
            parentName: datasend.parentName,
            order: datasend.order,
          };
          this.rosCategoryService.updateRosCate(this.dataForm).subscribe(
            (res) => {
              if (res.isValid) {
                this.notificationService.showNotification(
                  'success',
                  'Thành công'
                );
                this.isVisible = false;
                this.loadRosCate();
              } else {
                this.notificationService.showNotification(
                  Constant.NOTIFY_TYPE.ERROR,
                  res.errors[0].errorMessage
                );
              }
            },
            (error) => {
              this.notificationService.showNotification(
                'error',
                error.statusText
              );
            }
          );
        });
    });
    this.selectedRowIndex = this.emptyId;
  }
  
  

  rowSelected(data) {
    this.curData = data;
    this.selectedRowIndex = data.id;
    this.dataForm = {
      id: this.selectedRowIndex,
      name: data.name,
      ros: data.ros,
      des: data.des,
      rosid: data.rosid,
      parentId: data.parentId,
      parentName: data.parentName,
      order: data.order,
    };
    this.loadRosCateGroup(this.dataForm.rosid);
  }

  loadRosCateGroup(id) {
    this.rosCategoryService.getRosCateGroup(id).subscribe((thisRos) => {
      this.ros_rosCateData = thisRos;
    });
  }

  updateStatus(data) {
    const type = data.isDisabled ? 'enable' : 'disable';
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Bạn có muốn <b>' + type + '</b> ros category này không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () => {
        this.rosCategoryService.updateStatus(data.id).subscribe((res) => {
          if (res.isValid) {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.SUCCESS,
              'Cập nhật thành công'
            );
            data.isDisabled = !data.isDisabled;
          } else {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.ERROR,
              res.errors[0].errorMessage
            );
          }
        });
      },
    });
  }
  searchName() {
    const keyword = this.searchKey.trim();
    if (keyword != '') {
      this.listOfDisplayData = this.rosCate.filter((en) =>
        en.name.toLowerCase().includes(keyword.toLowerCase())
      );
      console.log('listOfDisplayData', this.listOfDisplayData);
    } else {this.listOfDisplayData = this.rosCate;}
  }
}
