import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FormatStyle } from 'src/app/models/form-model/form.class';

@Component({
  selector: 'app-format-container-box',
  templateUrl: './format-container-box.component.html',
  styleUrls: ['./format-container-box.component.scss'],
})
export class FormatContainerBoxComponent implements OnInit {
  FormatContainerBoxComponent;
  _formatStyle: any;
  _element: any;
  @Input() isVisible: boolean;
  @Input() set element(value: any) {
    this._formatStyle =
      value && value.formatStyle ? value.formatStyle : new FormatStyle();
    this._element = value;
  }

  get element(): any {
    return this._element;
  }

  get formatStyle(): any {
    return this._formatStyle;
  }

  @Output() toggleValue: EventEmitter<any> = new EventEmitter();
  @Output() selectValue: EventEmitter<any> = new EventEmitter();

  LEFT = 'left';
  RIGHT = 'right';
  CENTER = 'center';

  constructor() {}

  ngOnInit() {
    // console.log(this.element);
  }

  selectAlign(value): void {
    this.selectValue.emit({ value });
  }
}
