<div
  *ngIf="data.src"
  (click)="showImage()"
  class="image-wrapper {{ data.height ? 'd-fixed' : '' }} {{
    data.isTemp ? 'img-temp' : ''
  }}">
  <div
    [style]="
      data.height
        ? 'display:flex;width:' + data.width + 'px;height:' + data.height + 'px'
        : ''
    ">
    <div style="position: relative; width: 100%; display: block">
      <div class="image-content">
        <img [src]="data.src" />
      </div>
    </div>
  </div>
</div>
<div
  #imageWrapper
  *ngIf="!data.src"
  [innerHTML]="data.message"
  (click)="showImageInMessage()"
  class="image-wrapper"></div>
