export class Userprofile {
  fullName: string;
  sex: string;
  dob: Date;
  email: string;
  phone: string;
  address: string;
  doctorRankName: string;
  doctorRankId: string;
}

export class Password {
  currentPassword: '';
  newPassword: '';
  repeatPassword: '';
}

export class DoctorRank {
  id: string;
  name: string;
}
