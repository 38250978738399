import { Component, Input, OnInit } from '@angular/core';
import { FormElement } from 'src/app/models/form-model/form.class';

@Component({
  selector: 'app-check-box-element',
  templateUrl: './check-box-element.component.html',
  styleUrls: ['./check-box-element.component.scss'],
})
export class CheckBoxElementComponent implements OnInit {
  @Input() data: FormElement;
  constructor() {}

  ngOnInit() {}
  onModelChange(val) {
    this.data.isDirty = true;
  }
}
