import { Pipe, PipeTransform } from '@angular/core';
import { Constant } from '../constants/constant.class';

@Pipe({
  name: 'observationDataTypeName',
})
export class ObservationDataTypeNamePipe implements PipeTransform {
  transform(value: number, args?: any): any {
    switch (value) {
      case Constant.DATATYPENUMBER.BOOLEAN:
        return Constant.DATATYPE.BOOLEAN;
      case Constant.DATATYPENUMBER.DATE:
        return Constant.DATATYPE.DATE;
      case Constant.DATATYPENUMBER.DATETIME:
        return Constant.DATATYPE.DATETIME;
      case Constant.DATATYPENUMBER.MORELESS:
        return Constant.DATATYPE.MORELESS;
      case Constant.DATATYPENUMBER.MUILTICHOICE:
        return Constant.DATATYPE.MUILTICHOICE;
      case Constant.DATATYPENUMBER.NUMBER:
        return Constant.DATATYPE.NUMBER;
      case Constant.DATATYPENUMBER.POSITIVENEGATIVE:
        return Constant.DATATYPE.POSITIVENEGATIVE;
      case Constant.DATATYPENUMBER.RANGE:
        return Constant.DATATYPE.RANGE;
      case Constant.DATATYPENUMBER.SINGLECHOICE:
        return Constant.DATATYPE.SINGLECHOICE;
      case Constant.DATATYPENUMBER.STRING:
        return Constant.DATATYPE.STRING;
      case Constant.DATATYPENUMBER.TIME:
        return Constant.DATATYPE.TIME;
      default:
        return '';
    }
  }
}
