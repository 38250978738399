<div nz-row class="tab-list-detail-visit">
  <as-split
    *ngIf="config"
    direction="horizontal"
    (dragEnd)="onDragEnd(-1, $event)">
    <as-split-area
      *ngIf="config.columns[0].visible"
      [size]="config.columns[0].size">
      <app-list-visit-small
        [currentVisit]="currentVisit"
        [listVisitSize]="config.columns[0].size"
        (selectVisitEvent)="selectVisit($event)">
      </app-list-visit-small>
    </as-split-area>
    <as-split-area
      *ngIf="config.columns[1].visible"
      [size]="config.columns[1].size">
      <div style="height: 100%; overflow: hidden !important;">
        <ng-container *ngIf="isLoading">
          <nz-spin
            [nzSpinning]="isLoading"
            nzSize="large"
            style="height: 100%"
            nzTip="Đang tải thông tin ca khám...">
          </nz-spin>
        </ng-container>
        <ng-container *ngIf="!isLoading">
          <div class="pd-10" *ngIf="currentVisit.id">
            <app-visit-info-header
              [(visit)]="currentVisit"
              [subClinicalVisit]="subClinicalVisit"
              [disabledActiton]="disabledActiton"
              [lstRelatedVisit]="lstRelatedVisit"
              (clickChangeRoom)="onClickChangeRoom()"
              (visitIdChange)="selectVisit($event)">
            </app-visit-info-header>
            <app-visit-detail-small
              [(visit)]="currentVisit"
              [(isOpenChangeVisitRoom)]="isOpenChangeVisitRoom"
              (lstRelatedVisitChange)="getlstRelatedVisit($event)"
              (visitIdChange)="selectVisit($event)"
              (updateVisitStatus)="updateCurrentVisitStatus($event)"
              (outSubClinicalVisit)="getSubClinicalVisit($event)">
            </app-visit-detail-small>
          </div>
          <div *ngIf="!currentVisit.id" class="no-visit">
            <div>
              <i
                nz-icon
                nzType="info-circle"
                nzTheme="outline"
                style="font-size: 48px"></i>
              <h3>Thông tin ca khám</h3>
            </div>
          </div>
        </ng-container>
      </div>
    </as-split-area>
  </as-split>
</div>
