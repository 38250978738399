import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonInfoManagementService extends BaseService {
  getByFilter(): Observable<any> {
    return this.get(UrlConstant.COMMONINFO_INST_GET);
  }
  add(payload): Observable<any> {
    return this.post(UrlConstant.COMMONINFO_INST_POST, payload);
  }
  update(payload): Observable<any> {
    return this.put(UrlConstant.COMMONINFO_INST_PUT + '/' + `${payload.id}`, payload);
  }
  remove(id: string): Observable<any> {
    return this.delete(UrlConstant.COMMONINFO_INST_DELETE + '/' + id, '');
  }
}
