export class CalculatedVariable {
  name: string;
  type: number;
  sourceId: any;
  sourceName: string;
  dataType: number;
  value: any;
  isError: boolean;
  error: string;
}
