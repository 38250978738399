import { ChatMessageComponent } from './chat-message/chat-message.component';
import { ChatVideoComponent } from './chat-video/chat-video.component';
import { ChatLinkComponent } from './chat-link/chat-link.component';
import { SharedModule } from 'src/app/share/share.module';
import { ChatImageComponent } from './chat-image/chat-image.component';
import { ChatFileComponent } from './chat-file/chat-file.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConversationImageComponent } from './conversation-image/conversation-image.component';
import { AngularEmojisModule } from 'angular-emojis';
@NgModule({
  imports: [CommonModule, SharedModule, AngularEmojisModule],
  exports: [
    ChatFileComponent,
    ChatImageComponent,
    ConversationImageComponent,
    ChatLinkComponent,
    ChatVideoComponent,
    ChatMessageComponent,
  ],
  declarations: [
    ChatFileComponent,
    ChatImageComponent,
    ConversationImageComponent,
    ChatLinkComponent,
    ChatVideoComponent,
    ChatMessageComponent,
  ],
})
export class ChatItemModule {}
