import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { Constant } from '../share/constants/constant.class';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class WebhookService extends BaseService {
  getWebhooks(groupId): Observable<any> {
    return this.get(UrlConstant.WEB_HOOK_GET + groupId);
  }
  genKeyWebhook(): Observable<any> {
    return this.post(UrlConstant.WEB_HOOK_GEN_KEY, {});
  }
  saveWebhook(payload): Observable<any> {
    return this.post(UrlConstant.WEB_HOOK_SAVE, payload);
  }
  deleteWebhooks(id): Observable<any> {
    return this.delete(UrlConstant.WEB_HOOK_DELETE, id);
  }
}
