import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusText',
})
export class PrescriptionPipe implements PipeTransform {
  transform(items: any, filterValue: boolean) {
    if (!items) {
      return [];
    }
    return items.filter((it) => it.isInvited === filterValue);
  }
}

@Pipe({
  name: 'dayMonthYearString',
})
export class DayMonthYearStringPipe implements PipeTransform {
  transform(date: any) {
    return this.getDayMonthYear(date);
  }

  getDayMonthYear(dateStr) {
    let dateObj;
    if (dateStr == undefined) {
      dateObj = new Date();
    } else {
      dateObj = new Date(dateStr);
    }

    let str = dateObj.getHours() + 'h ';
    str += dateObj.getMinutes() + 'p, ngày ';
    str += dateObj.getDate() + ' tháng ';
    str += dateObj.getMonth() + 1 + ' năm ';
    str += dateObj.getFullYear();
    return str;
  }
}
