import { NzModalService } from 'ng-zorro-antd/modal';
import { WebhookService } from 'src/app/services/webhook.service';
import { CustomColumnService } from '../../../../services/custom-cloumn.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AppConfigService } from '../../../../../app-config.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subscription } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UrlConstant } from 'src/app/share/constants/url.class';
import { GroupTreeService } from '../../../../services/groupTree.service';
import { ListFormService } from 'src/app/services/listForm-service';
import { ShareService } from 'src/app/services/share.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state/app-state';
import { Join } from '../../../auth/redux/group-auth.action';
import { WorklistColumn } from 'src/app/models/worklist-column';
import { WebHookItem } from 'src/app/models/webhookItem';
import { ApiToken } from 'src/app/models/api-token';
import { ApiTokenService } from 'src/app/services/api-token.service';

@Component({
  selector: 'app-config-group',
  templateUrl: './config-group.component.html',
  styleUrls: ['./config-group.component.scss'],
})
export class ConfigGroupComponent implements OnInit, OnDestroy {
  lstPermissionDefault = [
    { name: 'Điều phối viên', type: 1, checked: false },
    { name: 'Bác sĩ', type: 2, checked: false },
    { name: 'Dược sĩ lâm sàng', type: 3, checked: false },
    { name: 'Chuyên viên tư vấn', type: 4, checked: false },
    { name: 'Điều dưỡng phụ trách', type: 5, checked: false },
    { name: 'Tình nguyện viên', type: 10, checked: false },
    { name: 'Thống kê báo cáo', type: 11, checked: false },
  ];
  listVisitDetailTabsDefault = [
    { name: 'Xét nghiệm', type: 1, checked: false },
    { name: 'CĐHA', type: 2, checked: false },
    { name: 'Thuốc', type: 3, checked: false },
    { name: 'Phiếu dữ liệu', type: 4, checked: false },
    { name: 'Thông tin lâm sàng', type: 5, checked: false },
    { name: 'Y lệnh', type: 6, checked: false },
  ];

  columnSortFilter: any[] = [
    { value: 'sos', label: 'Mức độ cảnh báo' },
    { value: 'visit', label: 'Ca khám' },
    { value: 'modified', label: 'Lần cập nhật' },
    { value: 'name', label: 'Tên bệnh nhân' },
    { value: 'age', label: 'Tuổi' },
  ];
  _isVisible = false;
  @Input() set isVisible(val: boolean) {
    this._isVisible = val;
    if (val) {
      this.loadGroupInfo();
    }
  }

  get isVisible(): boolean {
    return this._isVisible;
  }
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() addGroup = new EventEmitter<any>();

  urlService: string;
  fileName: string;
  fullUrl: string;
  baseImageurl = '/GroupImg/';
  loading = false;
  header?: any;
  formCFGroup: FormGroup;
  submitted = false;
  searchDataFilter = [];
  searchFormPPWebFilter = [];
  searchFormRegisterPPFilter = [];
  searchFormPPMobileFilter = [];
  _defaultObservationVitalSigns = [];
  selectedForms = [];
  clinicalFormsWeb = [];
  registerPPForms = [];
  clinicalFormsMobile = [];
  selectedGroupName = 'Chọn nhóm làm việc';
  isCovidGroup = false;
  isVisibleWebhookModal = false;
  isVisibleApiTokenModal = false;
  isAutoAddTreatmentPatient = false;
  isLoadByPermission = false;
  isCreatePatientAccountQuickly = false;
  isShowToolTip = false;
  groupId: string;
  groupSub: Subscription;
  group: any = {};
  pushNotifyTimes = [];
  isCustomColumnVisible = false;
  isEditingHeader = false;
  isEditingCLSHeader = false;
  showCols: WorklistColumn[] = [];
  hideCols: WorklistColumn[] = [];
  defaultCols: WorklistColumn[] = [];
  webhookItems: WebHookItem[] = [];
  apiTokens: ApiToken[] = [];

  presciptionHeader = '';
  formCLSHeaderA4 = '';
  formCLSHeaderA5 = '';
  laboratoryResultHeader = '';

  currentWebhook: any = {};
  currentApiToken: any = {};
  checked = true
  modalHeaders = [
    { type: 0, paperSize: 0, content: '', title: '' },
    { type: 0, paperSize: 0, content: '', title: '' },
    { type: 0, paperSize: 0, content: '', title: '' },
    { type: 0, paperSize: 0, content: '', title: '' },
  ];
  modalHeader = { type: 0, paperSize: 0, content: '', title: '' };

  listTemplateDrug = Object.values(Constant.TEMPLATE_DRUG_LIST);
  PrintFormHeader = Constant.PrintFormHeader;

  constructor(
    private configService: AppConfigService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private groupTreeService: GroupTreeService,
    private shareService: ShareService,
    private dataService: ListFormService,
    private customColumnService: CustomColumnService,
    private webhookService: WebhookService,
    private apiTokenService: ApiTokenService,
    private modalService: NzModalService,
    private store: Store<AppState>
  ) {
    this.urlService =
      configService.getConfig().api.baseUrl +
      UrlConstant.UPLOAD_FILE +
      '?TypeUpload=upload_avatar&FileCategory=groupimg';
    this.baseImageurl = configService.getConfig().media.baseUrl + this.baseImageurl;
    this.header = {
      Authorization: 'Bearer ' + localStorage.getItem(Constant.TOKEN),
    };
    this.formCFGroup = this.formBuilder.group({
      Id: ['000000000000000000000000'],
      Name: [this.selectedGroupName],
      aliasName: [''],
      shortCode: [''],
      isAutoAddTreatmentPatient: [false],
      isCovidGroup: [false],
      isUseListNDetailVisit: [false],
      orderPrintType: [1],
      isLoadByPermission: [false],
      UrlImage: [''],
      defaultColumnDetail: [[]],
      visitDetailTabs: [[]],
      doctorPermissions: [[]],
      hisKey: [''],
      hisurl: [''],
      hisName: [''],
      isCreatePatientAccountQuickly: [false],
      isUseListPatient: [false],
      defaultUseListPatient: [false],
      isChangePresciptionStatus: [false],
      isUsePrescriptionDeliveryInfo: [false],
      templateDrugList: [Constant.DEFAULT_TEMPLATE_DRUG_LIST],
      isConnectToHIS: [false],
      getHISMedicinePrice: [false],
      getHISServicePrice: [false],
      pushSubclinicalToHIS: [false],
      synchronyVisitToHIS: [false],
      printSeperateSubclinical: [false],
      autoConvert2InProgressVisit: [false],
      autoConvert2FinishedVisit: [false],
      isUseWebhook: [false],
      isFilterByDoctorPermission: [false],
      isOrderBySosRange: [false],
      isUseApprovePrescription: [false],
      paperSizePrintPrescription: [false],
      subClinicalSendType: [1],
      isUseDividePatientRoom: [false],
      isUseReminder: [false],
      isUseCDSRule: [false],
      isUseMedicalOrder: [false],
      isUseWaitingPage: [false],
      isUseChat: [false],
      isUseVideoCall: [false],
      isUseGroupStatistic: [false],
      isUsePatientGroup: [false],
      isUserUpdateIdCategory: [false],
      isUserUpdateForm: [false],
      isUserUpdateMedicine: [false],
      isUseTreatmentProcess: [false],
      isUseReminderSupportClinicalDecision: [false],
      isDoctorSelectRoom: [false],
      isUseClinicalInforPP: [false],
      isUsePharmacist: [false],
      isUseRegisterPP: [false],
      isUserFamilyMember: [false],
      pushNotifyToUpdateRecord: [false],
      registerFormPP: [false],
      isUseFormConclusion: [false],
      defaultSortBy: ['sos'],
      getVisitByRoomOrDoctor: [false],
      subclinicalResultLink: [],
      lisurL_GetTestSampleId: [],
      lisPartnerCode: [],
      prescriptionIsShowActiveIngredient:[true],
      defaultObservationVitalSigns: [],
    });

    this.bindChangeEvent();
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        this.selectedGroupName = data.aliasName ? data.aliasName : data.groupName;
        const selectedGroupAvatar = data.groupAvatar;
        this.isCovidGroup = data.isCovidGroup;
        console.log('h1',data.isAutoAddTreatmentPatient)
        this.isAutoAddTreatmentPatient = data.isAutoAddTreatmentPatient;
        this.isLoadByPermission = data.isLoadByPermission;
        this.isCreatePatientAccountQuickly = data.isCreatePatientAccountQuickly;
        this.fullUrl = selectedGroupAvatar !== 'assets/image/group.svg' ? this.baseImageurl + selectedGroupAvatar : '';
      }
    });
    this.getPrintFormHeader(this.PrintFormHeader.All);
    //this.getHisHeader(2);
  }

  ngOnInit(): void {
    // this.formCFGroup.controls['isAutoAddTreatmentPatient'].setValue(this.formCFGroup.value.isUseListPatient);
  }

  openAddWebhook() {
    this.isVisibleWebhookModal = true;
    this.currentWebhook = {};
  }

  openAddApiToken() {
    this.isVisibleApiTokenModal = true;
    this.currentApiToken = {};
  }

  getPrintFormHeader(type) {
    this.groupTreeService.getPrintHeader(this.groupId, type).subscribe((res) => {
      if (res.isValid) {
        let data = res.jsonData;
        if (type == this.PrintFormHeader.All) {
          this.presciptionHeader = data.printPrescriptionHeader;
          this.formCLSHeaderA4 = data.printClinicalFormHeader;
          this.formCLSHeaderA5 = data.printClinicalFormHeaderA5;
          this.laboratoryResultHeader = data.laboratoryResultHeader;

          this.setModalHeader();
        }
      }
    });
  }

  loadGroupInfo() {
    if (!this.shareService.checkEmpty(this.groupId)) {
      this.groupTreeService.getGroupInfo(this.groupId).subscribe(
        (response) => {
          if (response !== null) {
            this.selectedForms = [...response.formReportIds];
            this.clinicalFormsMobile = [...response.clinicalFormsMobile];
            this.clinicalFormsWeb = [...response.clinicalFormsWeb];
            this.registerPPForms = [...response.registerPPForms];
            this.pushNotifyTimes = this.convertTimeToTimeSelection(response.pushNotifyTimes);
            this.getAllColumn(response.defaultColumnDetail);
            this.fileName = response.urlImage;
            this.convertToPermissionItem(response.doctorPermissions);
            this.convertToVisitDetailTabItem(response.visitDetailTabs);
            this.getApiTokenItems();
            this._defaultObservationVitalSigns = [...response.defaultObservationVitalSigns];
            this.formCFGroup.patchValue({
              Name: response.name,
              shortCode: response.shortCode,
              aliasName: this.selectedGroupName,
              isCreatePatientAccountQuickly: response.isCreatePatientAccountQuickly,
              isCovidGroup: response.isCovidGroup,
              isUseListNDetailVisit: response.isUseListNDetailVisit,
              orderPrintType: response.orderPrintType,
              isLoadByPermission: response.isLoadByPermission,
              UrlImage: response.UrlImage,
              defaultColumnDetail: response.defaultColumnDetail,
              visitDetailTabs: response.visitDetailTabs,
              doctorPermissions: response.doctorPermissions,
              clinicalFormsMobile: response.clinicalFormsMobile,
              hisKey: response.hisKey,
              hisurl: response.hisurl,
              hisName: response.hisName,
              isUseListPatient: response.isUseListPatient,
              isAutoAddTreatmentPatient: response.isUseListPatient,
              defaultUseListPatient: response.defaultUseListPatient,
              isChangePresciptionStatus: response.isChangePresciptionStatus,
              isUsePrescriptionDeliveryInfo: response.isUsePrescriptionDeliveryInfo,
              templateDrugList: response.templateDrugList
                ? response.templateDrugList
                : Constant.DEFAULT_TEMPLATE_DRUG_LIST,
              isConnectToHIS: response.isConnectToHIS,
              getHISMedicinePrice: response.getHISMedicinePrice,
              getHISServicePrice: response.getHISServicePrice,
              synchronyVisitToHIS: response.synchronyVisitToHIS,
              pushSubclinicalToHIS: response.pushSubclinicalToHIS,
              printSeperateSubclinical: response.printSeperateSubclinical,
              autoConvert2InProgressVisit: response.autoConvert2InProgressVisit,
              autoConvert2FinishedVisit: response.autoConvert2FinishedVisit,
              isFilterByDoctorPermission: response.isFilterByDoctorPermission,
              isOrderBySosRange: response.isOrderBySosRange,
              isUseApprovePrescription: response.isUseApprovePrescription,
              paperSizePrintPrescription: response.paperSizePrintPrescription,
              subClinicalSendType: response.subClinicalSendType,
              isUseDividePatientRoom: response.isUseDividePatientRoom,
              isUseReminder: response.isUseReminder,
              isUseCDSRule: response.isUseCDSRule,
              isUseMedicalOrder: response.isUseMedicalOrder,
              isUseWaitingPage: response.isUseWaitingPage,
              isUseChat: response.isUseChat,
              isUseVideoCall: response.isUseVideoCall,
              isUseGroupStatistic: response.isUseGroupStatistic,
              isUsePatientGroup: response.isUsePatientGroup,
              isUserUpdateIdCategory: response.isUserUpdateIdCategory,
              isUserUpdateForm: response.isUserUpdateForm,
              isUserUpdateMedicine: response.isUserUpdateMedicine,
              isUseTreatmentProcess: response.isUseTreatmentProcess,
              isUseReminderSupportClinicalDecision: response.isUseReminderSupportClinicalDecision,
              isDoctorSelectRoom: response.isDoctorSelectRoom,
              isUseClinicalInforPP: response.isUseClinicalInforPP,
              isUsePharmacist: response.isUsePharmacist,
              prescriptionIsShowActiveIngredient: response.prescriptionIsShowActiveIngredient,
              isUseRegisterPP: response.isUseRegisterPP,
              isUserFamilyMember: response.isUserFamilyMember,
              pushNotifyToUpdateRecord: response.pushNotifyToUpdateRecord,
              registerFormPP: response.registerFormPP,
              isUseFormConclusion: response.isUseFormConclusion,
              isUseWebhook: response.isUseWebhook,
              defaultSortBy: response.defaultSortBy,
              getVisitByRoomOrDoctor: response.getVisitByRoomOrDoctor,
              subclinicalResultLink: response.subclinicalResultLink,
              lisurL_GetTestSampleId: response.lisurL_GetTestSampleId,
              lisPartnerCode: response.lisPartnerCode,
              defaultObservationVitalSigns: response.defaultObservationVitalSigns,
            });
            this.isShowToolTip = response.isUseListPatient;
            ///console.log('formCFGroup', this.formCFGroup.value);
            if (response.isUseWebhook) {
              this.getWebhookItems();
            }
          }
        },
        (error) => {
          console.log(error);
          this.notificationService.showNotification('', 'Có lỗi xảy ra');
        }
      );
    }
  }

  convertTimeToTimeSelection(times) {
    if (this.shareService.checkEmpty(times)) {
      return [];
    }
    const dates = [];
    for (let i = 0; i < times.length; i++) {
      // let date = new Date();
      // date.setTime(times[i].hour);
      // date.setMinutes(times[i].minute);
      dates.push(new Date(1970, 1, 1, times[i].hour, times[i].minute));
    }
    return dates;
  }

  convertTimeSelectionToTime(dates) {
    if (this.shareService.checkEmpty(dates)) {
      return [];
    }
    const times = [];
    for (let i = 0; i < dates.length; i++) {
      const time = { hour: 0, minute: 0 };
      time.hour = dates[i].getHours();
      time.minute = dates[i].getMinutes();
      times.push(time);
    }
    return times;
  }
  convertToVisitDetailTabItem(values) {
    if (values === null) {
      for (let i = 0; i < this.listVisitDetailTabsDefault.length; i++) {
        this.listVisitDetailTabsDefault[i].checked = true;
      }
      return;
    }
    if (this.shareService.checkEmpty(values)) {
      return;
    }
    values.forEach((element) => {
      const curTab = this.listVisitDetailTabsDefault.filter((t) => t.type == element);
      if (curTab.length) {
        curTab[0].checked = true;
      }
    });
  }
  convertToPermissionItem(values) {
    if (this.shareService.checkEmpty(values)) {
      return;
    }
    values.forEach((element) => {
      const curPermission = this.lstPermissionDefault.filter((t) => t.type == element);
      if (curPermission.length) {
        curPermission[0].checked = true;
      }
    });
  }
  convertVisitDetailTabsToValue() {
    return this.listVisitDetailTabsDefault.filter((t) => t.checked).map((t) => t.type);
  }
  convertToPermissionValue() {
    return this.lstPermissionDefault.filter((t) => t.checked).map((t) => t.type);
  }
  showCustomColumn() {
    this.isCustomColumnVisible = true;
  }
  editHeader(type) {
    this.isEditingHeader = true;
    this.modalHeader = JSON.parse(JSON.stringify(this.modalHeaders.find((en) => en.type == type)));
  }
  contentSavedEvent(content) {
    this.modalHeaders.find((en) => en.type == this.modalHeader.type).content = content;
  }
  closeModalEditHeader() {
    this.modalHeader.content = '';
  }
  changeColumns(val: WorklistColumn[]) {
    this.showCols = val;
    this.hideCols = this.defaultCols.filter((x) => !this.showCols.map((t) => t.field).includes(x.field));
  }

  getAllColumn(curFieldNames) {
    if (curFieldNames && curFieldNames.length > 0) {
      this.showCols = curFieldNames;
    } else {
      this.customColumnService.getPatientGridDefaultColumn({ type: Constant.GRID_FIELD_TYPE.OT }).subscribe((res) => {
        if (res.isValid) {
          this.showCols = this.defaultCols;
        }
      });
    }
  }

  getWebhookItems() {
    this.webhookService.getWebhooks(this.groupId).subscribe((res) => {
      this.webhookItems = res;
    });
  }

  getApiTokenItems() {
    this.apiTokenService.getApiTokens(this.groupId).subscribe((res) => {
      this.apiTokens = res;
    });
  }

  onSearchForm(keyword) {
    const requestData = {
      search: keyword,
      groupId: this.groupId,
      pageSize: 10,
      page: 1,
    };
    this.dataService.getListFormForConfigGroup(requestData).subscribe(
      (response) => {
        if (response !== null && response.source != null) {
          this.searchDataFilter = [...response.source];
        }
      },
      (error) => {
        console.log(error);
        this.notificationService.showNotification('', 'Lỗi data');
      }
    );
  }
  onSearchPPFormWeb(keyword) {
    const requestData = {
      search: keyword,
      groupId: this.groupId,
      pageSize: 10,
      page: 1,
    };
    this.dataService.getListFormForConfigGroup(requestData).subscribe(
      (response) => {
        if (response !== null && response.source != null) {
          this.searchFormPPWebFilter = [...response.source];
        }
      },
      (error) => {
        console.log(error);
        this.notificationService.showNotification('', 'Lỗi data');
      }
    );
  }
  onSearchRegisterPPForm(keyword) {
    const requestData = {
      search: keyword,
      groupId: this.groupId,
      pageSize: 10,
      page: 1,
    };
    this.dataService.getListFormForConfigGroup(requestData).subscribe(
      (response) => {
        if (response !== null && response.source != null) {
          this.searchFormRegisterPPFilter = [...response.source];
        }
      },
      (error) => {
        console.log(error);
        this.notificationService.showNotification('', 'Lỗi data');
      }
    );
  }
  onSearchPPFormMobile(keyword) {
    const requestData = {
      search: keyword,
      groupId: this.groupId,
      pageSize: 10,
      page: 1,
    };
    this.dataService.getListFormForConfigGroup(requestData).subscribe(
      (response) => {
        if (response !== null && response.source != null) {
          this.searchFormPPMobileFilter = [...response.source];
        }
      },
      (error) => {
        console.log('lỗi');
        this.notificationService.showNotification('', 'Lỗi data');
      }
    );
  }
  selectForm(selectForm) {
    const exist = this.selectedForms.filter((en) => en.id == selectForm.id);
    if (exist.length == 0) {
      this.selectedForms.push({ id: selectForm.id, name: selectForm.name });
    } else {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Form đã có trong danh sách');
    }
  }
  selectFormEvent(event: any, data: any) {
    if (event.isUserInput) {
      this.selectForm(data);
    }
  }
  unSelectForm(id) {
    this.selectedForms = this.selectedForms.filter((en) => en.id !== id);
  }
  selectRegisterPPForm(selectForm) {
    this.registerPPForms.push({ id: selectForm.id, name: selectForm.name });
  }
  selectRegisterPPFormEvent(event: any, data: any) {
    if (event.isUserInput) {
      this.selectRegisterPPForm(data);
    }
  }
  unSelectRegisterPPForm(id) {
    this.registerPPForms = this.registerPPForms.filter((en) => en.id !== id);
  }
  selectWebForm(selectForm) {
    this.clinicalFormsWeb.push({ id: selectForm.id, name: selectForm.name });
  }
  selectWebFormEvent(event: any, data: any) {
    if (event.isUserInput) {
      this.selectWebForm(data);
    }
  }
  unSelectWebForm(id) {
    this.clinicalFormsWeb = this.clinicalFormsWeb.filter((en) => en.id !== id);
  }
  selectMobileForm(selectForm) {
    this.clinicalFormsMobile.push({ id: selectForm.id, name: selectForm.name });
  }
  selectMobileFormEvent(event: any, data: any) {
    if (event.isUserInput) {
      this.selectMobileForm(data);
    }
  }
  unSelectMobileForm(id) {
    this.clinicalFormsMobile = this.clinicalFormsMobile.filter((en) => en.id !== id);
  }
  addNotifyTime() {
    this.pushNotifyTimes.push(new Date());
  }
  removeNotifyTime(index) {
    this.pushNotifyTimes = this.pushNotifyTimes
      .slice(0, index)
      .concat(this.pushNotifyTimes.slice(index + 1, this.pushNotifyTimes.length));
  }

  doctorPermission(id) {
    this.selectedForms = this.selectedForms.filter((en) => en.id !== id);
  }
  addForm() {
    const requestData = {
      formIds: this.selectedForms,
      groupId: this.groupId,
      pageSize: 10,
      page: 1,
    };
  }
  setTime($event, index) {
    this.pushNotifyTimes[index] = $event;
  }
  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        // this.msg.error('You can only upload JPG file!');
        observer.complete();
        return;
      }
      const isLt2M = (file.size ?? 0) / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, 'Image must smaller than 2MB!');
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng && isLt2M);
      observer.complete();
    });

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.fileName = info.file.response.fileName;
        this.fullUrl = this.baseImageurl + this.fileName;
        break;
      case 'error':
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Error');
        this.loading = false;
        break;
    }
  }
  handleCancel(): void {
    this.isVisible = false;
    this.closeModal.emit(false);
  }
  handleOk(): void {
    if (this.formCFGroup.valid) {
      const formCFGroupValue = this.formCFGroup.value;
      if (formCFGroupValue.isUseFormConclusion && this.selectedForms.length == 0) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Bạn cần chọn Form khi áp dụng Tổng kết ca khám'
        );
        return;
      }
      if (formCFGroupValue.isUseRegisterPP && this.registerPPForms.length == 0) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Bạn cần chọn Form khi Đăng ký KH khi áp dụng Đăng ký - Tiếp nhận'
        );
        return;
      }
      this.submitted = false;
      const ids = this.selectedForms.map(function (a) {
        return a.id;
      });
      const clinicalFormsMobile = this.clinicalFormsMobile.map((t) => t.id);
      const clinicalFormsWeb = this.clinicalFormsWeb.map((t) => t.id);
      const registerPPForms = this.registerPPForms.map((t) => t.id);
      const pldefaultObservationVitalSigns= this._defaultObservationVitalSigns;
      console.log('h10000',  this.formCFGroup.value.defaultObservationVitalSigns)
      const pushNotifyTimes = this.convertTimeSelectionToTime(this.pushNotifyTimes);
      const notFormData = {
        Id: this.groupId,
        UrlImage: this.fileName == undefined ? '' : this.fileName,
        FormReportIds: ids,
        clinicalFormsMobile,
        pldefaultObservationVitalSigns,
        clinicalFormsWeb,
        registerPPForms,
        pushNotifyTimes,
        defaultColumnDetail: this.showCols,
        doctorPermissions: this.convertToPermissionValue(),
        visitDetailTabs: this.convertVisitDetailTabsToValue(),
      };

      const payload = { ...this.formCFGroup.value, ...notFormData, };
      this.groupTreeService.updateGroup(payload).subscribe(
        (res) => {
          if (res !== null) {
            if (res.isValid) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cấu hình nhóm thành công');
              this.store.dispatch(
                new Join({
                  groupId: this.groupId,
                  isSaveConfigGroup: true,
                  ...payload,
                })
              );
              this.isVisible = false;
              this.addGroup.emit(this.fullUrl);
            } else {
              if (res.errors && res.errors.length > 0) {
                this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
              }
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  isUseWebhookChange() {
    if (this.formCFGroup.value.isUseWebhook) {
      this.getWebhookItems();
    }
  }

  bindChangeEvent() {
    this.formCFGroup.get('isUseListPatient').valueChanges.subscribe((x) => {});
  }

  ngOnDestroy(): void {
    this.groupSub.unsubscribe();
  }
  isVisibleWebHookModalChange(val) {
    this.isVisibleWebhookModal = val;
    this.getWebhookItems();
    this.currentWebhook = {};
  }

  editWeebhookItem(item) {
    this.currentWebhook = item;
    this.isVisibleWebhookModal = true;
  }

  isVisibleApiTokenModalChange(val) {
    this.isVisibleApiTokenModal = val;
    this.getApiTokenItems();
    this.currentApiToken = {};
  }

  editApiTokenItem(item) {
    this.currentApiToken = item;
    this.isVisibleApiTokenModal = true;
  }

  copyItem(val) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationService.showNotification(Constant.SUCCESS, 'Copy');
  }

  deleteWebhookItem(item) {
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: `Bạn có muốn xóa webhook ${item.name} không?`,
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () => {
        this.confirmDeleteWebhookItem(item.id);
      },
    });
  }

  confirmDeleteWebhookItem(id) {
    this.webhookService.deleteWebhooks(id).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xóa thành công');
        this.getWebhookItems();
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
      }
    });
  }

  deleteApiTokenItem(item) {
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: `Bạn có muốn xóa Api ${item.name} không?`,
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () => {
        this.confirmApiTokenItem(item.id);
      },
    });
  }

  confirmApiTokenItem(id) {
    this.apiTokenService.deleteApiToken(id).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xóa thành công');
        this.getApiTokenItems();
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
      }
    });
  }

  showToolTip() {
    if (this.formCFGroup.value.isUseListPatient) {
      $('#tooltip').css('display', 'inline-block');
      setTimeout(function () {
        $('#tooltip').hide();
      }, 2500);
    }
    this.formCFGroup.controls['isAutoAddTreatmentPatient'].setValue(this.formCFGroup.value.isUseListPatient);
    // const isUseListPatientValue = this.formCFGroup.value.isUseListPatient;
    // this.formCFGroup.controls['isAutoAddTreatmentPatient'].setValue(isUseListPatientValue);
  }
  setModalHeader() {
    this.modalHeaders = [
      {
        type: Constant.PrintFormHeader.Prescription,
        paperSize: Constant.PaperSizePrinter.A4,
        content: this.presciptionHeader,
        title: 'Header của đơn thuốc',
      },
      {
        type: Constant.PrintFormHeader.ClinicalFormA4,
        paperSize: Constant.PaperSizePrinter.A4,
        content: this.formCLSHeaderA4,
        title: 'Header của phiếu in chỉ định',
      },
      {
        type: Constant.PrintFormHeader.ClinicalFormA5,
        paperSize: Constant.PaperSizePrinter.A5,
        content: this.formCLSHeaderA5,
        title: 'Header của phiếu in chỉ định',
      },
      {
        type: Constant.PrintFormHeader.LaboratoryResultHeader,
        paperSize: Constant.PaperSizePrinter.A4,
        content: this.laboratoryResultHeader,
        title: 'Header của phiếu kết quả XN',
      },
    ];
  }
}
