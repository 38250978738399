<div *ngIf="prescription">
  <nz-modal
    [(nzVisible)]="isVisible"
    nzMaskClosable="false"
    [nzFooter]="null"
    nzWidth="700px"
    nzTitle="Chi tiết đơn thuốc"
    (nzOnCancel)="handleCancel()">
    <div nz-row>
      <div nz-col [nzSpan]="12" class="mg-bt-10">
        <div nz-row>
          <div nz-col [nzSpan]="8">Mã đơn thuốc:</div>
          <div nz-col [nzSpan]="16">{{ prescription.ExportCode }}</div>
        </div>
      </div>
      <div nz-col [nzSpan]="12">
        <div nz-row>
          <div nz-col [nzSpan]="8">Thời gian tạo:</div>
          <div nz-col [nzSpan]="16">
            {{ prescription.CreatedDate | date: dateTimeFormat }}
          </div>
        </div>
      </div>
    </div>
    <div nz-row>
      <div nz-col [nzSpan]="24" class="mg-bt-15">
        <div nz-row>
          <div nz-col [nzSpan]="4">Người tạo:</div>
          <div nz-col [nzSpan]="20">{{ prescription.CreatorName }}</div>
        </div>
      </div>
    </div>
    <div nz-row>
      <nz-table
        #detailTable
        class="main-table-grid-wrap-text"
        [nzData]="prescription.MedicationRequests"
        [nzScroll]="{ y: '250px' }"
        nzShowPagination="false"
        [nzPageSize]="100">
        <thead>
          <tr>
            <th nzWidth="40px">#</th>
            <th nzWidth="120px">Tên thuốc</th>
            <th nzWidth="80px">SL</th>
            <th class="text-nowrap" nzWidth="80px">Thực xuất</th>
            <th nzWidth="85px">ĐVT</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of detailTable.data; let i = index">
            <td>{{ i + 1 }}</td>
            <td>
              <div class="text-title">
                {{ data.Title }}
              </div>
              <span class="text-note-small">{{ data.Text }}</span>
            </td>
            <td>{{ data.Amount }}</td>
            <td>{{ getAmount(data.Amount, data.ExistingAmount) }}</td>
            <td>{{ data.UnitName }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
    <div class="mg-bt-15">
      <div class="title-text-medium mg-bt-10">LỜI DẶN</div>
      <div [innerHtml]="prescription.DoctorComment"></div>
    </div>
    <div *ngIf="isUsePrescriptionDeliveryInfo" class="mg-bt-15">
      <div class="title-text-medium mg-bt-10">Thông tin giao thuốc</div>
      <div nz-row>
        <div nz-col [nzSpan]="6">Họ và tên:</div>
        <div nz-col [nzSpan]="18">{{ prescription.RecipientName }}</div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="6">Số điện thoại:</div>
        <div nz-col [nzSpan]="18">{{ prescription.RecipientPhoneNo }}</div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="6">Địa chỉ:</div>
        <div nz-col [nzSpan]="18">{{ prescription.RecipientAddress }}</div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="6">Thời gian có thể nhận:</div>
        <div nz-col [nzSpan]="18">{{ prescription.RecipientTimePlan }}</div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="6">Hình thức thanh toán:</div>
        <div nz-col [nzSpan]="18">
          {{ prescription.PaymentMethodString }}
        </div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="6">Ghi chú:</div>
        <div nz-col [nzSpan]="18">{{ prescription.RecipientNote }}</div>
      </div>
    </div>
  </nz-modal>
</div>
