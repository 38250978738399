import { NumberInput } from 'ng-zorro-antd/core/types';
import { FormatStyle } from './formatStyle.model';
import { FormObject } from './formObject.model';

export class CommonInfo extends FormObject {
  id: string;
  defaultField: string;
  description: string;
  width = 200;
  height = 30;
  name: string;
  title: string;
  value: any;
  formatStyle: FormatStyle = new FormatStyle();
  dataType?: number = 5;
  referId?: string;
  dataSource: ItemDS[];
}
class ItemDS {
  text: string;
  valueNumber: number;
}
