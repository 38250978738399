import { XetnghiemChartComponent } from './xetnghiem-chart/xetnghiem-chart.component';
import { XetnghiemAddComponent } from './xetnghiem-add/xetnghiem-add.component';
import { XetnghiemTabComponent } from './xetnghiem-tab/xetnghiem-tab.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/share/share.module';
import { XnListComponent } from './xn-list/xn-list.component';
import { XnResultComponent } from './xn-result/xn-result.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    XetnghiemTabComponent,
    XetnghiemAddComponent,
    XetnghiemChartComponent,
    XnListComponent,
    XnResultComponent,
  ],
  exports: [
    XetnghiemTabComponent,
    XetnghiemAddComponent,
    XetnghiemChartComponent,
  ],
})
export class XetNghiemModule {}
