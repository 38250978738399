import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NotificationService } from 'src/app/services/notification.service';
import { VisitService } from 'src/app/services/visit.service';

@Component({
  selector: 'app-visit-other-info',
  templateUrl: './visit-other-info.component.html',
  styleUrls: ['./visit-other-info.component.scss'],
})
export class VisitOtherInfoComponent implements OnInit, OnChanges {
  @Input() popupData = {
    isVisible: false,
    data: {} as any,
  };
  visitForm: FormGroup;
  editorConfig: AngularEditorConfig = {
    editable: true,
    height: '100px',
    minHeight: '100px',
    width: '100%',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [{ class: 'times-new-roman', name: 'Times New Roman' }],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: false,
  };
  constructor(private fb: FormBuilder, private visitService: VisitService, private notify: NotificationService) {}

  ngOnInit(): void {
    this.visitForm = this.fb.group({
      visitReason: [''],
      medicalHistory: [''],
      medicationsUsed: [''],
      examination: [''],
      treatments: [''],
      currentCondition: [''],
      nextTreatmentPlan: [''],
      advice: [''],
      nextAppointmentDate: [''],
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('popupData')) {
      this.popupData = changes['popupData'].currentValue;
      if (this.popupData.isVisible) {
        this.getOtherVisitInfo();
      }
    }
  }

  getOtherVisitInfo() {
    this.visitService.GetInfo2(this.popupData.data.id).subscribe((res) => {
      this.mapData(this.visitForm, res);
    });
  }
  mapData(formGroup, response) {
    Object.keys(response).forEach((key) => {
      const control = formGroup.get(key);
      if (control) {
        control.setValue(response[key]);
      }
    });
  }
  trimFormData(formData: any): any {
    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] === 'string') {
        formData[key] = formData[key].trim();
      }
    });
    return formData;
  }
  UIsaveInfo() {
    let formData = this.trimFormData(this.visitForm.value);
    let payload = { ...formData, visitId: this.popupData.data.id };
    this.visitService.UpdateMedicalReportInfo(payload).subscribe((res) => {
      if (res.isValid) {
        this.notify.showNotification('success', 'Lưu thông tin ca khám thành công');
        this.popupData.isVisible = false;
      } else {
        this.notify.showNotification('error', 'Lưu thông tin ca khám thất bại. Vui lòng thử lại');
      }
    });
  }
}
