import { EventEmitter, Output, Type } from '@angular/core';
import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
} from '@angular/core';
import { FormElement } from 'src/app/models/form-model/form.class';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-observation-type-abs',
  templateUrl: './observation-type-abs.component.html',
  styleUrls: ['./observation-type-abs.component.scss'],
})
export class ObservationTypeAbsComponent implements OnInit {
  _data: FormElement = null;
  component: Type<any>;

  @Input() set data(value: FormElement) {
    this._data = value;
    // this.initView(value);
  }

  get data(): FormElement {
    return this._data;
  }
  @Output() selectElement: EventEmitter<any> = new EventEmitter<any>();
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  constant = Constant;
  ngOnInit() {}

  // loadComponent() {
  //   if (this.component) {
  //     const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
  //     const viewContainerRef = this.appObservation.viewContainerRef;
  //     viewContainerRef.clear();

  //     const componentRef = viewContainerRef.createComponent<ObservationInterface>(componentFactory);
  //     componentRef.instance.data = this.data;
  //   }
  // }

  // ngOnChanges(changes: SimpleChanges) {
  //   console.log('onchanges ', changes);

  //   if (changes.viewType) {
  //     let value = changes.viewType.currentValue;
  //     this._data.viewType = value;

  //     if (value) {
  //       switch (value) {
  //         case 'dropdown':
  //           this.component = DropdownElementComponent;
  //           break;
  //         case 'selection':
  //           this.component = SelectionElementComponent;
  //           break;
  //         case 'checked':
  //           this.component = CheckboxElementComponent;
  //           break;
  //       }

  //       this.loadComponent();
  //     }
  //   }

  //   if (changes.data) {
  //     let value = changes.data.currentValue;
  //     this._data = value;
  //     this.initView(value);
  //   }
  // }

  // initView(value) {
  //   if (value) {
  //     switch (this.data.observationType.dataType) {
  //       case Constant.DATATYPENUMBER.NUMBER:
  //         this.component = InputNumberComponent;
  //         break;
  //       case Constant.DATATYPENUMBER.STRING:
  //         this.component = InputElementComponent;
  //         break;
  //       case Constant.DATATYPENUMBER.BOOLEAN:
  //         this.component = CheckboxElementComponent;
  //         break;
  //       case Constant.DATATYPENUMBER.DATE:
  //         this.component = DatatimeElementComponent;
  //         break;
  //       case Constant.DATATYPENUMBER.DATETIME:
  //         this.component = DatatimeElementComponent;
  //         break;
  //       case Constant.DATATYPENUMBER.TIME:
  //         this.component = DatatimeElementComponent;
  //         break;
  //       case Constant.DATATYPENUMBER.SINGLECHOICE:
  //         if (this.data.viewType == 'dropdown') {
  //           this.component = DropdownElementComponent;
  //         } else {
  //           this.component = SelectionElementComponent;
  //         }
  //         break;
  //       case Constant.DATATYPENUMBER.MUILTICHOICE:
  //         if (this.data.viewType == 'dropdown') {
  //           this.component = DropdownElementComponent;
  //         } else {
  //           this.component = SelectionElementComponent;
  //         }
  //         break;
  //       default:
  //         break;
  //     }

  //     this.loadComponent();
  //   }
  // }
}
