<nz-modal
  [nzVisible]="isVisible"
  (nzOnCancel)="onCloseModalView()"
  [nzWidth]="550">
  <div *nzModalTitle>Danh sách bác sĩ: {{ room.name }}</div>
  <div *nzModalContent>
    <div nz-row>
      <div nz-col nzSpan="16">
        <nz-select
          nzShowSearch
          nzServerSearch
          nzPlaceHolder="Tìm kiếm bác sĩ"
          [(ngModel)]="selectedDoctor"
          [nzShowArrow]="false"
          [nzFilterOption]="true"
          (nzOnSearch)="searchDoctor($event)"
          style="width: 95%">
          <nz-option
            *ngFor="let o of listOfOptions"
            [nzLabel]="o.fullName"
            [nzValue]="o.id"></nz-option>
        </nz-select>
      </div>
      <div nz-col nzSpan="8">
        <button
          [disabled]="!selectedDoctor"
          nz-button
          nzType="primary"
          (click)="addDoctor()">
          Thêm bác sĩ
        </button>
      </div>
    </div>
    <nz-divider></nz-divider>
    <nz-table
      class="main-table-grid listvs-only"
      #fixedTable
      nzShowSizeChanger
      [nzPageSize]="pageSize"
      [nzData]="doctors"
      [nzLoading]="loading"
      [nzScroll]="{ y: '400px' }"
      [nzShowTotal]="rangeTemplate">
      <thead>
        <tr>
          <th nzWidth="60px" class="center">STT</th>
          <th nzWidth="250px">Tên bác sĩ</th>
          <th class="center"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let doctor of fixedTable.data; let i = index">
          <td class="center">
            {{ fixedTable.nzPageSize * (fixedTable.nzPageIndex - 1) + i + 1 }}
          </td>
          <td>{{ doctor.fullName }}</td>
          <td class="center">
            <i
              nz-icon
              nzType="delete"
              nzTheme="outline"
              title="Xóa khỏi room"
              (click)="removeDoctor(doctor)"></i>
          </td>
        </tr>
      </tbody>
      <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }} {{ 'bác sĩ' }}
      </ng-template>
    </nz-table>
  </div>
  <div *nzModalFooter>
    <!-- <button nz-button nzType="primary" (click)="onOpenModalAdd()">Thêm bác sĩ</button> -->
    <button nz-button nzType="default" (click)="onCloseModalView()" nzDanger>
      Đóng
    </button>
  </div>
</nz-modal>

<!-- <nz-modal [nzVisible]="isVisibleAdd" (nzOnCancel)="onCloseModalAdd()" [nzWidth]="450">
  <div *nzModalTitle>
    Thêm bác sĩ vào room {{room.name}}
  </div>
  <div *nzModalContent>
    <div nz-row>
      <div nz-col nzSpan="8">
        Tìm kiếm
      </div>
      <div nz-col nzSpan="16">
        <nz-select
        nzShowSearch
        nzServerSearch
        nzPlaceHolder="Tìm kiếm bác sĩ"
        [(ngModel)]="selectedDoctor"
        [nzShowArrow]="false"
        [nzFilterOption]="true"
        (nzOnSearch)="searchDoctor($event)"
      >
        <nz-option *ngFor="let o of listOfOptions" [nzLabel]="o.fullName" [nzValue]="o.id"></nz-option>
      </nz-select>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="onOpenModalAdd()">Thêm</button>
    <button nz-button nzType="default" (click)="onCloseModalAdd()" nzDanger>Đóng</button>
  </div>
</nz-modal> -->
