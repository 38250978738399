import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AddDataDocument } from 'src/app/models/visit-media';
import { NzModalService } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';
import { Observer, Subject, Subscription } from 'rxjs';
import { Visits } from '../../../../models/listVisit.class';
import { VisitService } from '../../../../services/visit.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { NotificationService } from 'src/app/services/notification.service';
import { Observation } from 'src/app/models/observationType.class';
import { ObservationService } from '../../../../services/observation.service';
import { AddVitalAsignComponent } from './../add-vital-asign/add-vital-asign.component';
import { RosAndSubclinicComponent } from './../ros-and-subclinic/ros-and-subclinic.component';
import { IlayoutConfig } from 'src/app/models/ilayout-config';
import { ShareService } from 'src/app/services/share.service';
import { SubClinicalVisitService } from 'src/app/services/subcliniccal-service';
import { AppConfigService } from 'src/app-config.service';
import { Observable } from 'rxjs';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/components/app-state/app-state';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { DatePipe } from '@angular/common';
import { NzResizeEvent } from 'ng-zorro-antd/resizable';
import { RecordVisitService } from 'src/app/services/record-visit-service';

declare let $: any;

const defaultConfig: IlayoutConfig = {
  columns: [
    {
      visible: true,
      size: 75,
      name: 'main-content',
      columns: [
        {
          visible: true,
          size: 50,
          name: 'main',
        },
        {
          visible: true,
          size: 50,
          name: 'sub',
        },
      ],
    },
    {
      visible: true,
      size: 25,
      name: 'chat-content',
      columns: [],
    },
  ],
  disabled: false,
};
@Component({
  selector: 'app-tab-visit-info',
  templateUrl: './tab-visit-info.component.html',
  styleUrls: ['./tab-visit-info.component.scss'],
})
export class TabVisitInfoComponent implements OnInit, OnDestroy {
  _reloadNow = false;
  @Input() shareId = '';
  @Input() visit: Visits;
  @Input() hideButton: string;
  @Input() disabledClass: string;

  resizeTextbox = [
    {
      title: 'visitReason',
      height: '100px',
    },
    {
      title: 'clinicalInfomation',
      height: '100px',
    },
    {
      title: 'conclusion',
      height: '100px',
    },
  ];
  loadRecordDelete = false;

  get reloadNow(): boolean {
    return this._reloadNow;
  }

  @Input() set reloadNow(value: boolean) {
    this._reloadNow = value;
    if (value) {
      setTimeout(() => {
        this.visitId = this.visit.id;
        this.loadRecordDelete = true;
        this.getVisit(this.visitId);
      }, 200);
      if (this.disabledClass != '') {
        this.onlyView = true;
      }
      setTimeout(() => {
        this.reloadNowChange.emit(false);
        this.loadRecordDelete = false;
      }, 500);
    }
  }

  @Output() reloadNowChange = new EventEmitter<any>();
  @Output() openPhieudl = new EventEmitter<any>();
  @Output() newVisit = new EventEmitter<any>();
  @Output() vitalSign = new EventEmitter<any>();
  @Output() openTab = new EventEmitter<any>();
  @Output() reloadMasterNote = new EventEmitter<any>();
  @ViewChild(RosAndSubclinicComponent) triggerDataRos: RosAndSubclinicComponent;
  @ViewChild('addVitalAsign') triggerVitalAsign: AddVitalAsignComponent;

  visitId: string;
  newVisitId: string;
  patientId: string;
  recordId: string;
  formid: string;
  lstDisease = [];
  diseasesData = [];
  suggestDiseases;
  lstImages;
  document;
  lstHistory: any[] = [];
  totalHistory = 0;
  lstHistoryModal: any[] = [];
  observation;
  lstObservationVS: Observation[] = [];
  lstObservationType;
  indexTab: string;
  isVisibleVS: boolean;
  isVisibleVisitHis = false;
  isVisibleAddDoc: boolean;
  isVisibleEditNote: boolean;
  isUserFamilyMember = true;
  isCovidGroup: boolean;

  showChat: boolean;
  onlyView = false;
  isDisableCopy = false;
  subclinicalResult: any[] = [];
  isVisablePdf = false;
  linkPdf = '';
  lstSubclinicalOfDisplay: {
    type: number; //1: pdf, 2: image
    url: string;
  }[] = [];
  lstSubclinicalReports: any = [];
  lstSubclinicalImages: any = [];
  localStorageName = 'angular-split-ws-new';
  config: IlayoutConfig = null;
  currentGroupId = ''; //localStorage.getItem(Constant.CURRENT_GROUPID);
  @ViewChildren('visitmainchat,boxchat') divs: QueryList<ElementRef>;

  public Editor = ClassicEditor;
  isShowslideImage = false;
  isSpinning = true;
  callBackFnReason: any;
  callBackFnClinicalInfo: any;
  callBackFnConclusion: any;
  callBackFnICD: any;
  callBackDiagnoseInit: any;

  pageSize = 10;
  pageIndex = 1;
  totalGeneral = 0;
  keywordICD = '';
  groupSub: Subscription;
  public noteForm: AddDataDocument = {
    id: '',
    report: '',
    privacyPolicy: 4,
    title: '',
  };
  destroy$: Subject<boolean> = new Subject<boolean>();
  newVisitTag = '';
  lstVisitTags: string[] = [];
  header?: any;
  uploadFileUrl = '';
  isUploadingFile: boolean;
  pdfList: NzUploadFile[] = [];
  public indexTabAddDoc = 0;
  pdfDocuments;
  isVisiblePdfDocument = false;
  pagePdfDocument = 1;
  isVisibleEditPdf = false;
  pdfDocumentSrc = '';
  pdfDocumentId = '';

  pdfForm = {
    privacyPolicy: 4,
    comment: '',
    isKeyDocument: false,
  };
  isLoadingPdf = false;
  imageFrom = Constant.ImageForm;
  currentUserOnline = '';
  isUserCreatePdf = false;
  isDisableEditNote = false;
  isNoteOfCurrentUser = false;
  GeneralBoxPrivacy = Constant.GeneralBoxPrivacy;
  expandBox = [false, false, false, false, false, false];
  expandItem = [
    { id: 1, name: 'visitReason', expand: false, parent: 'visit' },
    { id: 2, name: 'clinicalInfomation', expand: false, parent: 'visit' },
    { id: 3, name: 'lstDisease', expand: false, parent: 'visit' },
    { id: 4, name: 'conclusion', expand: false, parent: 'visit' },
    { id: 5, name: 'subclinicalResult', expand: false, parent: '' },
    { id: 6, name: 'observation', expand: false, parent: '' },
    { id: 7, name: 'lstHistory', expand: false, parent: '' },
    { id: 8, name: 'lstVisitTags', expand: false, parent: 'visit' },
    { id: 9, name: 'lstObservationVS', expand: false, parent: '' },
    { id: 10, name: 'general', expand: false, parent: 'visit' },
    { id: 11, name: 'diagnoseInit', expand: false, parent: 'visit' },
  ];
  baseImageurl = '';
  baseFileUrl = '';
  nzFilterOption = () => true;
  constructor(
    private visitService: VisitService,
    private notificationService: NotificationService,
    private observationService: ObservationService,
    private modalService: NzModalService,
    private shareService: ShareService,
    private subClinicalVisitService: SubClinicalVisitService,
    private configService: AppConfigService,
    private tabDataService: TabDataService,
    private store: Store<AppState>,
    private datePipe: DatePipe,
    private recordVisitService: RecordVisitService
  ) {
    this.callBackFnReason = this.shareService.debounce(this.updateReason, 500);
    this.callBackFnClinicalInfo = this.shareService.debounce(this.updateClinicalInfo, 500);
    this.callBackFnICD = this.shareService.debounce(this.onInputICD10, 200);
    this.callBackFnConclusion = this.shareService.debounce(this.updateConclusion, 500);
    this.callBackDiagnoseInit = this.shareService.debounce(this.updateDiagnoseInit, 500);
    this.header = {
      Authorization: 'Bearer ' + localStorage.getItem(Constant.TOKEN),
    };
    this.uploadFileUrl =
      this.configService.getConfig().api.baseUrl + '/api/Upload/uploadFile?TypeUpload=upload_visit_document';
    this.baseImageurl = this.configService.getConfig().media.baseUrl + 'Media/VisitImage/';
    this.baseFileUrl = this.configService.getConfig().media.baseUrl + '/VisitDocument/';
  }

  async ngOnInit() {
    //this.currentGroupId = this.tabDataService.sharegroup.getValue();
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.currentGroupId = data.groupId;
        this.isUserFamilyMember = data.isUserFamilyMember;
        this.isCovidGroup = data.isCovidGroup;
      }
    });
    this.visitId = this.visit.id;
    this.newVisitId = this.visitId;
    await this.getVisit(this.visitId);
    if (this.disabledClass != '') {
      this.onlyView = true;
    }

    if (localStorage.getItem(this.localStorageName)) {
      this.config = JSON.parse(localStorage.getItem(this.localStorageName));
    } else {
      this.resetConfig();
    }
    let cdha = await this.getCDHAData();
    this.isSpinning = false;
    const userIdSub = this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.currentUserOnline = res;
      });

    this.autoResizeTextBox();
    console.log(this.expandItem);
  }

  addVisitPdf(): void {
    const files = this.pdfList.map((en) => en.response.fileName);
    const titles = this.pdfList.map((en) => en.name);
    const payload = {
      visitId: this.visitId,
      fileNames: files,
      fileTitles: titles,
      policy: this.pdfForm.privacyPolicy,
      comment: this.pdfForm.comment,
      isKeyDocument: this.pdfForm.isKeyDocument,
    };
    if (files.length == 0) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Vui lòng chọn file pdf');
    } else {
      this.visitService.addVisitPdf(payload).subscribe(
        (res) => {
          if (res.isValid == false) {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors.message);
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Thêm tài liệu thành công');
            this.visitService
              .getVisit(this.visitId, this.currentGroupId)
              .pipe(takeUntil(this.destroy$))
              .subscribe((data) => {
                this.pdfDocuments = data.pdfDocuments;
                this.totalGeneral = this.document.length + this.lstImages.length + this.pdfDocuments.length;
              });
          }
          this.pdfList = [];
          this.cancelAddDoc();
        },
        (error) => {}
      );
    }
  }

  openPdfVisit(pdfURL) {
    this.pdfDocumentSrc = this.baseFileUrl + pdfURL;
    this.isVisiblePdfDocument = true;
  }
  openEditPdf(pdfDocument) {
    this.pdfDocumentSrc = this.baseFileUrl + pdfDocument.pdfURL;
    this.pdfForm = {
      privacyPolicy: pdfDocument.privacyPolicy,
      comment: pdfDocument.comment,
      isKeyDocument: pdfDocument.isKeyDocument,
    };
    this.pdfDocumentId = pdfDocument.id;
    this.isVisibleEditPdf = true;
    this.isLoadingPdf = true;
    this.isUserCreatePdf = this.currentUserOnline == pdfDocument.creatorId;
  }
  editVisitPdf() {
    const payload = {
      visitId: this.visitId,
      pdfId: this.pdfDocumentId,
      policy: this.pdfForm.privacyPolicy,
      comment: this.pdfForm.comment,
      isKeyDocument: this.pdfForm.isKeyDocument,
    };
    this.visitService.editVisitPdf(payload).subscribe((res) => {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Sửa tài liệu pdf thành công');
      for (let i = 0; i < this.pdfDocuments.length; i++) {
        const pdf = this.pdfDocuments[i];
        if (this.pdfDocumentId == pdf.id) {
          this.pdfDocuments[i].privacyPolicy = this.pdfForm.privacyPolicy;
          this.pdfDocuments[i].comment = this.pdfForm.comment;
          this.pdfDocuments[i].isKeyDocument = this.pdfForm.isKeyDocument;
          break;
        }
      }
      this.pdfDocumentId = '';
      this.cancelEditPdf();
    });
  }
  cancelEditPdf() {
    this.isVisibleEditPdf = false;
    this.pdfDocumentSrc = '';
    this.pdfForm = {
      privacyPolicy: 4,
      comment: '',
      isKeyDocument: false,
    };
  }
  cancelPdfVisit() {
    this.isVisiblePdfDocument = false;
    this.pdfDocumentSrc = '';
  }
  fileChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.isUploadingFile = true;
        break;
      case 'done':
        this.isUploadingFile = false;
        break;
      case 'error':
        this.isUploadingFile = false;
        break;
    }
  }

  beforeUploadFile = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isValid = file.type === 'application/pdf';
      if (!isValid) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, 'Tải lên file định dạng pdf');
        observer.complete();
        return;
      }
      observer.next(isValid);
      observer.complete();
    });

  getVisit(vsId: string): void {
    this.visitId = vsId;
    this.patientId = this.visit.patientId;
    this.getVisitHistory(-1, 0); //get all data
    this.lstImages = this.visit.images;
    this.document = this.visit.documents;
    this.pdfDocuments = this.visit.pdfDocuments;
    this.totalGeneral = this.document.length + this.lstImages.length + this.pdfDocuments.length;
    this.lstDisease = [];
    this.lstVisitTags = this.visit.tags == null ? [] : this.visit.tags;
    if (this.visit.diseases != null) {
      this.lstDisease = this.visit.diseases;
    }
    this.getListObservation();
    this.getListObservationVS();
    this.newVisit.emit(this.visit);
    this.expandInit('visit');
  }

  getVisitHistory(take: number, skip: number) {
    const dataHis = { patientId: this.patientId, take, skip };
    this.visitService.lstVisitHisFast(dataHis).subscribe((data) => {
      this.totalHistory = data.itemCount;
      this.lstHistory = data.source;
      this.isSpinning = false;
      this.expandInit('lstHistory');
    });
  }

  onTypingReason(value: string) {
    this.callBackFnReason();
  }
  onTypingDiagnoseInit(value: string) {
    this.callBackDiagnoseInit();
  }

  updateReason(reason?: string, showNotification = false) {
    var reason = this.visit.visitReason;
    if (reason != '') {
      const payload = { visitId: this.visit.id, reason };
      this.visitService.saveVisitReason(payload).subscribe((res) => {
        showNotification
          ? this.notificationService.showNotification(Constant.SUCCESS, 'Cập nhật lý do khám thành công')
          : null;
      });
    } else {
      this.notificationService.showNotification('warning', 'Lý do khám không được để trống');
    }
  }
  clickTosaveVisitInfo(info) {
    if (this.onlyView) return;
    if (info == 'visitReason') {
      this.updateReason('', true);
    } else if (info == 'conclusion') {
      this.updateConclusion('', true);
    } else if (info == 'clinicalInfomation') {
      this.updateClinicalInfo('', true);
    } else if (info == 'diagnoseInit') {
      this.updateDiagnoseInit('', true);
    }
  }

  onTypingClinicalInfo(value: string) {
    this.callBackFnClinicalInfo();
  }

  updateClinicalInfo(clinicalInfo?: string, showNotification = false) {
    var clinicalInfo = this.visit.clinicalInfomation;
    if (clinicalInfo != '') {
      const payload = { visitId: this.visit.id, clinicalInfo };
      this.visitService.updateClinicalInfo(payload).subscribe((res) => {
        showNotification
          ? this.notificationService.showNotification(Constant.SUCCESS, 'Cập nhật triệu chứng lâm sàng thành công')
          : null;
      });
    } else {
      this.notificationService.showNotification('info', 'Triệu chứng lâm sàng không được để trống!');
    }
  }

  onTypingICD(value: string) {
    this.keywordICD = value;
    this.callBackFnICD();
  }

  async onInputICD10() {
    const keyword = this.keywordICD.trim();

    if (keyword.length > 1) {
      await this.visitService.getDiseasesData(keyword).subscribe((data) => {
        this.diseasesData = data;
      });
    }
  }

  onTypingConclusion(value: string) {
    this.callBackFnConclusion();
  }

  updateConclusion(clinicalInfo?: string, showNotification = false) {
    const conclusion = this.visit.conclusion;
    if (conclusion != null) {
      const payload = { visitId: this.visit.id, conclusion: conclusion.trim() };
      this.visitService.updateConclusion(payload).subscribe((res) => {
        //this.notificationService.showNotification(Constant.SUCCESS, "Cập nhật thành công");
        showNotification
          ? this.notificationService.showNotification(Constant.SUCCESS, 'Cập nhật kết luận thành công')
          : null;
      });
    }
  }
  updateDiagnoseInit(clinicalInfo?: string, showNotification = false) {
    const diagnoseInit = this.visit.diagnoseInit;
    if (diagnoseInit != null) {
      const payload = { visitId: this.visit.id, diagnoseInit: diagnoseInit.trim() };
      this.visitService.updateVisitDiagnoseInit(payload).subscribe((res) => {
        showNotification
          ? this.notificationService.showNotification(Constant.SUCCESS, 'Cập nhật chẩn đoán ban đầu thành công')
          : null;
      });
    }
  }

  fieldSelectedICD10(diseases): void {
    if (this.lstDisease.filter((en) => en.id === diseases.id).length == 0) {
      this.lstDisease.push(diseases);
      //save disease
      const payload = {
        Id: diseases.id,
        VisitId: this.visitId,
        Name: diseases.name,
      };
      this.visitService.saveDiseases(payload).subscribe(
        (res) => {
          if (res !== null) {
          }
        },
        (error) => {
          //  this.messageError = Constant.LOGIN_FAIL;
        }
      );
    }
    this.suggestDiseases = '';
  }

  getListObservation() {
    const sendObser = { visitId: this.newVisitId, observationCategory: 0 };
    this.observationService.getObservation(sendObser).subscribe((data) => {
      this.observation = data;
      this.observation.forEach((element) => {
        if (element.dataType === 11) {
          let valPN = '';
          if (element.value === true) {
            valPN = 'Positive';
          } else if (element.value === false) {
            valPN = 'Negative';
          }
          element.value = valPN;
        }
      });
      this.expandInit('observation');
    });
  }

  openModalAddDoc() {
    this.isVisibleAddDoc = true;
    this.noteForm = {
      id: '',
      report: '',
      privacyPolicy: 4,
      title: '',
    };
    this.pdfForm = {
      privacyPolicy: 4,
      comment: '',
      isKeyDocument: false,
    };
  }
  openModalEditNote(data) {
    this.isVisibleEditNote = true;
    this.noteForm = {
      id: data.id,
      report: data.report,
      privacyPolicy: data.privacyPolicy,
      title: data.title,
    };
    this.isDisableEditNote =
      data.privacyPolicy != this.GeneralBoxPrivacy.EditInGroup && data.creatorId != this.currentUserOnline;
    this.isNoteOfCurrentUser = data.creatorId != this.currentUserOnline;
  }

  addDocumentVisit() {
    const payload = { ...this.noteForm, visitId: this.visitId };
    this.visitService
      .addVisitDocument(payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          if (data.errors.length > 0) {
            for (const error of data.errors) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, error.errorMessage);
            }
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Thành công');
            this.cancelAddDoc();
            this.visitService
              .getVisit(this.visitId, this.currentGroupId)
              .pipe(takeUntil(this.destroy$))
              .subscribe((data) => {
                this.document = data.documents;
                this.totalGeneral = this.document.length + this.lstImages.length + this.pdfDocuments.length;
                this.isVisibleEditNote = false;
              });
          }
        },
        (error) => {
          for (const key in error.error) {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, error.error[key][0]);
          }
        }
      );
  }

  cancelAddDoc() {
    this.isVisibleAddDoc = false;
    this.noteForm.privacyPolicy = 4;
    this.indexTabAddDoc = 0;
  }

  cancelEditNote() {
    this.isVisibleEditNote = false;
    this.noteForm.privacyPolicy = 4;
  }

  deletePdfVisit(pdfId: string): void {
    const payload = { visitId: this.visitId, pdfId };
    this.modalService.confirm({
      nzTitle: 'Thông báo',
      nzContent: 'Bạn có chắc muốn xóa tài liệu pdf này?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () =>
        this.visitService
          .deleteVisitPdf(payload)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xoá thành công');
            this.visitService
              .getVisit(this.visitId, this.currentGroupId)
              .pipe(takeUntil(this.destroy$))
              .subscribe((data) => {
                this.pdfDocuments = data.pdfDocuments;
                this.totalGeneral = this.document.length + this.lstImages.length + this.pdfDocuments.length;
              });
          }),
    });
  }

  deleteDocumentVisit(id: string) {
    const deleteDoc = {
      id,
      visitId: this.visitId,
      report: 'string',
      privacyPolicy: 0,
      title: '',
    };
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Bạn có muốn xóa hay không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () =>
        this.visitService
          .deleteVisitDocument(deleteDoc)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xoá thành công');
            this.visitService
              .getVisit(this.visitId, this.currentGroupId)
              .pipe(takeUntil(this.destroy$))
              .subscribe((data) => {
                this.document = data.documents;
                this.totalGeneral = this.document.length + this.lstImages.length + this.pdfDocuments.length;
              });
          }),
    });
  }

  public onReady(Editor) {
    Editor.ui
      .getEditableElement()
      .parentElement.insertBefore(Editor.ui.view.toolbar.element, Editor.ui.getEditableElement());
  }

  showModalHistory(): void {
    this.isVisibleVisitHis = true;
  }

  addVitalSignsEvent(): void {
    //update observation
    this.isVisibleVS = false;
    this.getListObservationVS();
    this.vitalSign.emit();
  }

  getListObservationVS() {
    this.lstObservationVS = [];
    const sendObser = { visitId: this.visitId, observationCategory: -1 };
    this.observationService.getObservationVS(sendObser).subscribe((response) => {
      this.lstObservationType = response;
      console.log('HH2', this.lstObservationType);

      const lst = this.lstObservationType.filter((en) => en.value != null && en.id != '');
      lst.forEach((element) => {
        this.lstObservationVS.push(element);
      });
      this.lstObservationType.forEach((element) => {
        if (element.dataType == Constant.DATATYPENUMBER.SINGLECHOICE) {
          if (element.valueSelections == null) {
            element.valueSelections = [];
          }
        }
      });
      this.expandInit('lstObservationVS');
    });
  }

  closeAddVitalSigns() {
    this.isVisibleVS = false;
  }

  showModalAddVS(selectedItem) {
    this.isVisibleVS = true;
    this.triggerVitalAsign.setListObservation(this.visitId, this.lstObservationType, selectedItem, this.patientId);
  }
  removeDiseases(event: any, diseaseId) {
    this.lstDisease = this.lstDisease.filter((en) => en.id !== diseaseId);
    const payload = { Id: diseaseId, VisitId: this.visitId };

    this.visitService.removeDiseases(payload).subscribe(
      (res) => {
        if (res !== null) {
          console.log(res);
        }
      },
      (error) => {
        //  this.messageError = Constant.LOGIN_FAIL;
      }
    );
  }

  loadVisitById(newVisitId) {
    this.isSpinning = true;
    this.tabDataService.replaceTab(this.visitId, newVisitId);
  }

  sendDataToForm(data) {
    this.openPhieudl.emit(data);
  }

  openTabXN() {
    this.openTab.emit('xetnghiem');
    this.vitalSign.emit();
  }

  resetConfig() {
    this.config = JSON.parse(JSON.stringify(defaultConfig));

    localStorage.removeItem(this.localStorageName);
  }

  onDragEnd(columnindex: number, e: { gutterNum: number; sizes: Array<number> }) {
    this.config.columns[columnindex].columns
      .filter((c) => c.visible === true)
      .forEach((column, index) => (column.size = e.sizes[index]));

    this.saveLocalStorage();
  }

  toggleDisabled() {
    this.config.disabled = !this.config.disabled;

    this.saveLocalStorage();
  }

  saveLocalStorage() {
    localStorage.setItem(this.localStorageName, JSON.stringify(this.config));
  }

  toggleLeft() {
    if (this.config.columns[0].columns[0].size > 0) {
      this.config.columns[0].columns[1].size = 100;
      this.config.columns[0].columns[0].size = 0;
    } else {
      this.config.columns[0].columns[0].size = 65;
      this.config.columns[0].columns[1].size = 35;
    }

    this.saveLocalStorage();
  }

  toggleRight() {
    if (this.config.columns[0].columns[1].size > 0) {
      this.config.columns[0].columns[0].size = 100;
      this.config.columns[0].columns[1].size = 0;
    } else {
      this.config.columns[0].columns[0].size = 65;
      this.config.columns[0].columns[1].size = 35;
    }
    this.saveLocalStorage();
  }

  getCDHAData() {
    this.subClinicalVisitService.getSubClinicalResult(this.visit.id, this.patientId).subscribe((data) => {
      if (data != null) {
        new Promise((resolve, reject) => {
          this.subclinicalResult = data;
          let locationProtocol = location.protocol;
          for (var i = 0; i < this.subclinicalResult.length; i++) {
            let data_i = this.subclinicalResult[i];
            if (data_i != null && data_i.visitId == this.visitId) {
              if (data_i.reports != null && data_i.reports.length > 0) {
                for (var j = 0; j < data_i.reports.length; j++) {
                  let element = data_i.reports[j];
                  let oldProtocol = new URL(element).protocol;
                  data_i.reports[j] = data_i.reports[j].replace(oldProtocol, locationProtocol);
                }
                this.lstSubclinicalReports = [...this.lstSubclinicalReports, ...data_i.reports];
              }
              if (data_i.keyImages != null) {
                this.lstSubclinicalImages = [...this.lstSubclinicalImages, ...data_i.keyImages];
              }
            }
          }
          resolve(true);
        }).then((res) => {
          this.lstSubclinicalReports = this.shareService.removeDuplicate(this.lstSubclinicalReports);
          const l = this.lstSubclinicalReports.length;
          this.lstSubclinicalImages = l > 10 ? [] : this.lstSubclinicalImages.slice(0, 10 - l);
        });
      }
      if (this.lstSubclinicalReports.length > 0 || this.lstSubclinicalImages.length > 0) {
        this.expandInit('subclinicalResult');
      }
    });
  }

  openTabCDHA() {
    this.openTab.emit('cdha');
  }

  openPdf(rpt) {
    this.isVisablePdf = true;
    this.linkPdf = rpt;
  }

  closePdf() {
    this.isVisablePdf = false;
  }

  closeShowImage() {
    this.isShowslideImage = false;
  }

  viewAllImage() {
    if (this.lstImages.length != 0) {
      this.isShowslideImage = true;
    } else {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, 'Không có ảnh để hiển thị!');
    }
  }

  ngOnDestroy(): void {
    this.groupSub.unsubscribe();
  }

  addVisitTags() {
    if (this.newVisitTag == '') {
      this.notificationService.showNotification(Constant.ERROR, 'Vui lòng nhập tag');
      return;
    }
    const tagSearch = this.newVisitTag.trim();
    if (tagSearch == '') {
      this.notificationService.showNotification(Constant.ERROR, 'Vui lòng nhập tag');
      return;
    }
    const l = this.lstVisitTags.filter((en) => en === tagSearch).length;
    if (l > 0) {
      this.notificationService.showNotification(Constant.ERROR, 'Không được thêm trùng tag');
      return;
    }
    this.lstVisitTags.push(tagSearch);
    const payload = {
      visitId: this.visitId,
      tags: this.lstVisitTags,
    };
    this.visitService.updateTags(payload).subscribe((res) => {
      if (res.isValid) {
        this.newVisitTag = '';
        this.notificationService.showNotification(Constant.SUCCESS, 'Thêm tag thành công');
      }
    });
  }

  removeVisitTags(tag: string) {
    this.lstVisitTags = this.lstVisitTags.filter((en) => en != tag);
    const payload = {
      visitId: this.visitId,
      tags: this.lstVisitTags,
    };
    this.visitService.updateTags(payload).subscribe((res) => {
      if (res.isValid) {
        this.newVisitTag = '';
        this.notificationService.showNotification(Constant.SUCCESS, 'Xóa tag thành công');
      }
    });
  }

  openTabSearch(tagName: string) {
    this.tabDataService.updateTab('tabsearch', 'Tìm kiếm', tagName, '');
  }

  getShortenString(val, maxLength) {
    if (typeof val !== 'string') {
      return val;
    }

    if (val.length < maxLength) {
      return val;
    } else {
      return val.substr(0, maxLength - 2) + '..';
    }
  }

  sendClinicalInfomationToMasterNote(): void {
    if (this.shareService.checkEmpty(this.visit.clinicalInfomation.trim()) && this.onlyView) {
      this.notificationService.showNotification(Constant.ERROR, 'Bạn chưa nhập triệu chứng lâm sàng');
      return;
    } else {
      const chat = {
        visitId: this.visit.id,
        content: '<strong>Triệu chứng lâm sàng:</strong></br>' + this.visit.clinicalInfomation,
      };
      this.visitService.addVisitMasterNote(chat).subscribe(
        (resdata) => {
          this.reloadMasterNote.emit();
        },
        (error) => {
          this.notificationService.showNotification('', error.statusText);
        }
      );
    }
  }

  sendConclusionToMasterNote(): void {
    if (this.shareService.checkEmpty(this.visit.conclusion.trim()) && this.onlyView) {
      this.notificationService.showNotification(Constant.ERROR, 'Bạn chưa nhập kết luận');
      return;
    } else {
      const chat = {
        visitId: this.visit.id,
        content: '<strong>Kết luận của bác sĩ:</strong></br>' + this.visit.conclusion,
      };
      this.visitService.addVisitMasterNote(chat).subscribe(
        (resdata) => {
          this.reloadMasterNote.emit();
        },
        (error) => {
          this.notificationService.showNotification('', error.statusText);
        }
      );
    }
  }

  sendConclusionToMasterNoteAndClinicalInfomation(): void {
    if (this.onlyView && this.isDisableCopy) {
      return;
    } else {
      let content = '';
      if (!this.shareService.checkEmptyStr(this.visit.clinicalInfomation)) {
        content += '<strong>Triệu chứng lâm sàng:</strong></br>' + this.visit.clinicalInfomation.trim() + '</br>';
      }
      if (!this.shareService.checkEmptyStr(this.visit.conclusion)) {
        content += '<strong>Kết luận của bác sĩ:</strong></br>' + this.visit.conclusion.trim();
      }
      if (!this.shareService.checkEmptyStr(content)) {
        this.isDisableCopy = true;
        const chat = {
          visitId: this.visit.id,
          content,
        };
        this.visitService.addVisitMasterNote(chat).subscribe(
          (resdata) => {
            this.reloadMasterNote.emit();
          },
          (error) => {
            this.notificationService.showNotification('', error.statusText);
          }
        );

        setTimeout(() => {
          this.isDisableCopy = false;
        }, 1500);
      }
    }
  }

  refreshDataImage(data) {
    this.lstImages = data;
    this.totalGeneral = this.document.length + this.lstImages.length + this.pdfDocuments.length;
  }

  expand(index: number) {
    // tương ứng index trong expandItem
    this.expandItem[index].expand = !this.expandItem[index].expand;
  }

  expandInit(name) {
    const element = this.expandItem.filter((en) => en.name == name)[0];
    const listItem = this.expandItem.filter((en) => en.parent == name);
    if (name == 'visit') {
      listItem.forEach((element) => {
        if (element.name == 'visitReason') {
          element.expand = this.visit.visitReason != null && this.visit.visitReason.length > 0 ? true : false;
        } else if (element.name == 'clinicalInfomation') {
          element.expand =
            this.visit.clinicalInfomation != null && this.visit.clinicalInfomation.length > 0 ? true : false;
        } else if (element.name == 'conclusion') {
          element.expand = this.visit.conclusion != null && this.visit.conclusion.length > 0 ? true : false;
        } else if (element.name == 'general') {
          element.expand = this.totalGeneral > 0 ? true : false;
        } else if (element.name == 'lstVisitTags') {
          element.expand = this.lstVisitTags.length > 0 ? true : false;
        } else if (element.name == 'lstDisease') {
          element.expand = this.lstDisease.length > 0 ? true : false;
        }
      });
    } else if (name == 'lstHistory') {
      element.expand = this.lstHistory.length > 0 ? true : false;
    } else if (name == 'subclinicalResult') {
      element.expand = this.subclinicalResult.length > 0 ? true : false;
    } else if (name == 'observation') {
      element.expand = !this.shareService.checkEmpty(this.observation) && this.observation.length > 0 ? true : false;
    } else if (name == 'lstObservationVS') {
      element.expand = this.lstObservationVS.length > 0 ? true : false;
    }
  }

  removeVisitConfirm(visit) {
    const title = `Bạn có chắc chắn muốn xóa ca khám ngày ${this.datePipe.transform(
      visit.visitDate,
      'dd/MM/yyy hh:mm a'
    )} của BN ${this.visit.patientName} không?`;
    this.modalService.confirm({
      nzTitle: 'Xác nhận',
      nzContent: title,
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () => this.removeVisit(visit.id),
    });
  }

  removeVisit(visitId) {
    this.visitService.deleteVisit(visitId).subscribe((res) => {
      if (res.isValid) {
        if (this.visit.id == visitId) {
          if (res.jsonData == 'NotExist') {
            this.tabDataService.closeTabById(this.visitId);
          } else {
            this.tabDataService.replaceTab(this.visitId, res.jsonData);
          }
        } else {
          this.getVisitHistory(-1, 0);
        }
        this.notificationService.showNotification(Constant.SUCCESS, 'Xóa thành công');
      } else {
        if (res.errors[0].errorMessage == 'ExistData') {
          this.notificationService.showNotification(
            Constant.ERROR,
            'Ca khám đã có dữ liệu chỉ định hoặc đơn thuốc, bạn hãy xóa dữ liệu trước khi thực hiện xóa ca khám!'
          );
        } else {
          this.notificationService.showNotification(Constant.ERROR, res.errors[0].errorMessage);
        }
      }
    });
  }

  onResize({ width, height }: NzResizeEvent, title: string): void {
    this.resizeTextbox = this.resizeTextbox.map((e) => (e.title === title ? { ...e, height: `${height}px` } : e));
  }

  autoResizeTextBox() {
    if (this.visit.visitReason && this.visit.visitReason.length > 1000) {
      this.resizeTextbox[0].height = '150px';
    }
    if (this.visit.clinicalInfomation && this.visit.clinicalInfomation.length > 1000) {
      this.resizeTextbox[1].height = '150px';
    }
    if (this.visit.conclusion && this.visit.conclusion.length > 1000) {
      this.resizeTextbox[2].height = '150px';
    }
  }
}
