import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-qr-share-link',
  templateUrl: './qr-share-link.component.html',
  styleUrls: ['./qr-share-link.component.scss'],
})
export class QrShareLinkComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() link: string;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  copyInputUrl(val) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  handleCancle() {
    this.closeModal.emit(false);
  }
}
