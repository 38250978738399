<div class="text-align-center">
  <input type="file" (change)="fileChangeEvent($event)" />
  <br />
  <br />
  <ng-container *ngIf="croppedImage">
    <button
      class="btn btn-primary mg-r-4"
      title="Rotate left"
      (click)="rotateLeft()">
      <i nz-icon nzType="rotate-left" nzTheme="outline"></i>
    </button>
    <button
      class="btn btn-primary mg-r-4"
      title="Rotate right"
      (click)="rotateRight()">
      <i nz-icon nzType="rotate-right" nzTheme="outline"></i>
    </button>
    <button
      class="btn btn-primary mg-r-4"
      title="Flip horizontal"
      (click)="flipHorizontal()">
      <i nz-icon nzType="column-width" nzTheme="outline"></i>
    </button>
    <button
      class="btn btn-primary mg-r-4"
      title="Flip vertical"
      (click)="flipVertical()">
      <i nz-icon nzType="column-height" nzTheme="outline"></i>
    </button>
    <button
      class="btn btn-primary mg-r-4"
      title="Rotate left"
      (click)="zoomOut()">
      <i nz-icon nzType="zoom-out" nzTheme="outline"></i>
    </button>
    <button
      class="btn btn-primary mg-r-4"
      title="Rotate left"
      (click)="zoomIn()">
      <i nz-icon nzType="zoom-in" nzTheme="outline"></i>
    </button>
    <!-- <button (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill Aspect Ratio':'Contain Within Aspect Ratio'}}</button> -->
    <!-- <button  class="btn btn-danger mg-r-4"(click)="resetImage()" title="Reset image"><i nz-icon nzType="rollback" nzTheme="outline"></i></button>
    <button  class="btn btn-success mg-r-4"(click)="uploadFile()" title="Upload image"><i nz-icon nzType="upload" nzTheme="outline"></i></button> -->
    <button
      class="btn btn-danger mg-r-4"
      (click)="resetImage()"
      title="Reset image">
      Reset
    </button>
    <button
      class="btn btn-success mg-r-4"
      (click)="uploadFile()"
      title="Upload image">
      Lưu
    </button>
  </ng-container>
</div>
<br />
<!-- <input class="mg-r-4" [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()" />  -->
<div>
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="containWithinAspectRatio"
    [aspectRatio]="1"
    [resizeToWidth]="256"
    [cropperMinWidth]="128"
    [onlyScaleDown]="true"
    [roundCropper]="false"
    [canvasRotation]="canvasRotation"
    [transform]="transform"
    [alignImage]="'left'"
    [style.display]="showCropper ? null : 'none'"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady($event)"
    (loadImageFailed)="loadImageFailed()"></image-cropper>
</div>
<!-- <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" /> -->
