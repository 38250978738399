<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Thêm bệnh nhân"
  [nzWidth]="900"
  nzMaskClosable="false"
  (nzOnCancel)="handleCancel()">
  <div class="covid-form-add">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Họ và tên bệnh nhân <span class="required">*</span></label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="formAdd.fullName" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Ngày/tháng/năm sinh<span class="required">*</span></label>
          <div>
            <nz-date-picker
              [nzPlaceHolder]="'DD/MM/YYYY'"
              nzFormat="dd/MM/yyyy"
              [(ngModel)]="formAdd.dateOfBirth">
            </nz-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Số điện thoại liên lạc <span class="required">*</span></label>
          <input type="text" class="form-control" [(ngModel)]="formAdd.phone" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label
            >Số điện thoại người thân <span class="required">*</span></label
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="formAdd.emergencyPhone" />
        </div>
      </div>
    </div>
    <div class="form-group">
      <label
        >Địa chỉ (nhà hoặc khu vực cách ly của BN). Địa chỉ nhà vui lòng ghi cụ
        thể số nhà, đường, thôn/xã/phường, quận, tỉnh/thành phố<span
          class="required"
          >*</span
        ></label
      >
      <input type="text" class="form-control" [(ngModel)]="formAdd.address" />
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>Tỉnh/Thành Phố<span class="required">*</span></label>
          <nz-select
            class="w-100"
            nzPlaceHolder="Tỉnh/Thành Phố"
            [(ngModel)]="formAdd.provinceId"
            nzShowSearch
            (ngModelChange)="getDistrictByProvinceId($event)">
            <nz-option
              *ngFor="let option of listProvince"
              [nzLabel]="option.fullname"
              [nzValue]="option.id">
            </nz-option>
          </nz-select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Quận/Huyện<span class="required">*</span></label>
          <nz-select
            class="w-100"
            nzPlaceHolder="Quận/Huyện"
            [(ngModel)]="formAdd.districtId"
            nzShowSearch
            (ngModelChange)="getWardByDistrictId($event)">
            <nz-option
              *ngFor="let option of listDistrict"
              [nzLabel]="option.name"
              [nzValue]="option.id">
            </nz-option>
          </nz-select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Xã/Phường<span class="required">*</span></label>
          <nz-select
            class="w-100"
            nzPlaceHolder="Xã/Phường"
            [(ngModel)]="formAdd.wardId"
            nzShowSearch>
            <nz-option
              *ngFor="let option of listWard"
              [nzLabel]="option.name"
              [nzValue]="option.id"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label
            >Chiều cao (cm) <span class="red-dot">*</span>
            <em style="color: red" [ngClass]="'error'">
              <span
                *ngIf="
                  (formAdd.height != null && formAdd.height < 50) ||
                  formAdd.height > 200
                "
                >số không hợp lệ</span
              >
            </em>
          </label>
          <input
            nz-input
            type="number"
            min="50"
            minlength="200"
            [(ngModel)]="formAdd.height" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label
            >Cân nặng (kg) <span class="red-dot">*</span>
            <em style="color: red" [ngClass]="'error'">
              <span *ngIf="formAdd.weight != null && formAdd.weight <= 0"
                >số không hợp lệ</span
              >
            </em>
          </label>
          <input
            nz-input
            type="number"
            min="0"
            minlength="3"
            [(ngModel)]="formAdd.weight" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>BMI</label>
          <div *ngIf="BMIValue() > 0">{{ BMIValue() }}</div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label
        >BN có các tình trạng sức khoẻ sau đây không?<span class="required"
          >*</span
        ></label
      >
      <nz-select
        class="w-100"
        nzMode="multiple"
        nzPlaceHolder="Tình trạng sức khỏe của bạn"
        [(ngModel)]="listOfTagDiseases"
        (ngModelChange)="onChangeDiseases()">
        <nz-option
          *ngFor="let option of myDiseasesSource"
          [nzLabel]="option.label"
          [nzValue]="option.value"
          [nzDisabled]="option.disabled"></nz-option>
      </nz-select>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label
            >Ngày khởi phát triệu chứng đầu tiên (ho, sốt, mất mùi, mất vị,...)
            <span class="required">*</span></label
          >
          <nz-date-picker
            [nzPlaceHolder]="'DD/MM/YYYY'"
            nzFormat="dd/MM/yyyy"
            [(ngModel)]="formAdd.dateOfDiagnosis">
          </nz-date-picker>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="d-block">Ngày xét nghiệm gần nhất (nếu có)</label>
          <nz-date-picker
            [nzPlaceHolder]="'DD/MM/YYYY'"
            nzFormat="dd/MM/yyyy"
            [(ngModel)]="formAdd.dateOfTestCovid">
          </nz-date-picker>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="d-block"
        >BN đã được chích ngừa Covid chưa?
        <span class="required">*</span></label
      >
      <nz-radio-group
        class="mg-l-15"
        [(ngModel)]="formAdd.vaccinated"
        (ngModelChange)="vaccinatedChange($event)">
        <label nz-radio nzValue="Chưa">Chưa</label>
        <label nz-radio nzValue="1 mũi">1 mũi</label>
        <label nz-radio nzValue="2 mũi">2 mũi</label>
        <label nz-radio nzValue="3 mũi">3 mũi</label>
      </nz-radio-group>
    </div>
    <div
      *ngIf="listVaccinated.length > 0 && formAdd.vaccinated != null"
      class="form-group">
      <label
        >Loại vaccine đã tiêm cho từng mũi
        <span class="required">*</span></label
      >
      <div class="d-flex">
        <div *ngFor="let dose of listVaccinated" class="doses">
          <span>{{ dose.label }}</span>
          <input nz-input type="text" [(ngModel)]="dose.value" />
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="d-block"
        >BN có gặp các triệu chứng nặng sau đây không ?<span class="required"
          >*</span
        ></label
      >
      <nz-checkbox-group
        class="mg-l-15"
        [(ngModel)]="trieuChungNangSource"
        (ngModelChange)="
          checkboxGroupChange($event, 'other')
        "></nz-checkbox-group>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-medium" (click)="handleCancel()">
      Đóng
    </button>
    <button
      nz-button
      class="btn-primary-medium"
      [nzLoading]="isLoading"
      (click)="save()">
      Thêm
    </button>
  </div>
</nz-modal>

<nz-modal
  [(nzVisible)]="visibleAccountInfo"
  nzTitle="Thông tin tài khoản"
  nzClosable="false"
  nzMaskClosable="false"
  (nzOnCancel)="cancelAccountInfo()">
  <div>
    <p>
      Thông tin tài khoản của bệnh nhân
      <span class="font-weight-bold">{{ accountInfo.patientName }}</span>
    </p>
    <p style="margin-left: 30px">
      Tên tài khoản: <i class="font-weight-bold">{{ accountInfo.username }}</i
      ><br />
      Mật khẩu: <i class="font-weight-bold">{{ accountInfo.password }}</i>
    </p>
  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-medium" (click)="cancelAccountInfo()">
      Đóng
    </button>
  </div>
</nz-modal>
