<div *ngIf="visit">
  <ng-container *ngIf="isSpinning">
    <div class="reload-tab">
      <nz-spin nzSimple></nz-spin>
    </div>
  </ng-container>
  <div id="main-content">
    <div class="main-body">
      <as-split *ngIf="config" direction="horizontal" [disabled]="config.disabled" (dragEnd)="onDragEnd(0, $event)">
        <as-split-area *ngIf="config.columns[0].columns[0].visible" [size]="config.columns[0].columns[0].size">
          <div id="main-left">
            <div class="clinical-info">
              <div class="portal-item">
                <div class="box-header">
                  <div class="box-title" (click)="expand(0)">
                    <i nz-icon [nzType]="expandItem[0].expand ? 'down' : 'right'" nzTheme="outline"></i>
                    Lý do khám/điều trị
                  </div>
                  <div class="box-tools"></div>
                </div>
                <div class="box-body" *ngIf="expandItem[0].expand">
                  <div
                  class="position-relative"
                    nz-resizable
                    nzPreview
                    (nzResizeEnd)="onResize($event, 'visitReason')"
                    [style.height]="resizeTextbox[0].height"
                    [nzBounds]="'window'">
                    <nz-resize-handles [nzDirections]="['bottom']"></nz-resize-handles>
                    <textarea
                      class="textarea-full"
                      nz-input
                      placeholder="Đây là lí do khám của bệnh nhân"
                      [(ngModel)]="visit.visitReason"
                      (ngModelChange)="onTypingReason($event)"
                      [disabled]="onlyView"></textarea>
                      <span nz-icon nz-tooltip nzTooltipTitle="Lưu" nzType="save" class="position-absolute icon-save"  (click)="clickTosaveVisitInfo('visitReason')"></span>
                  </div>
                </div>
              </div>
              <div class="portal-item">
                <div class="box-header">
                  <div class="box-title" (click)="expand(1)">
                    <i nz-icon [nzType]="expandItem[1].expand ? 'down' : 'right'" nzTheme="outline"></i>
                    Triệu chứng lâm sàng
                  </div>
                  <div class="box-tools" *ngIf="expandItem[1].expand">
                    <i
                      *ngIf="visit.clinicalInfomation && visit.clinicalInfomation != '' && !onlyView && !isDisableCopy"
                      class="icon-md"
                      nz-icon
                      nzType="copy"
                      title="Thêm vào master note"
                      nzTheme="outline"
                      (click)="sendConclusionToMasterNoteAndClinicalInfomation()"></i>
                  </div>
                </div>
                <div class="box-body" *ngIf="expandItem[1].expand">
                  <div
                    nz-resizable
                    nzPreview
                    (nzResizeEnd)="onResize($event, 'clinicalInfomation')"
                    [style.height]="resizeTextbox[1].height"
                    [nzBounds]="'window'"
                    class="position-relative">
                    <nz-resize-handles [nzDirections]="['bottom']"></nz-resize-handles>
                    <textarea
                      class="textarea-full"
                      nz-input
                      placeholder="Nhập triệu chứng lâm sàng"
                      [(ngModel)]="visit.clinicalInfomation"
                      (ngModelChange)="onTypingClinicalInfo($event)"
                      [disabled]="onlyView"></textarea>
                      <span nz-icon nz-tooltip nzTooltipTitle="Lưu" nzType="save" class="position-absolute icon-save"  (click)="clickTosaveVisitInfo('clinicalInfomation')"></span>
                  </div>
                </div>
              </div>
<div class="portal-item">
  <div class="box-header">
    <div class="box-title" (click)="expand(10)">
      <i nz-icon [nzType]="expandItem[10].expand ? 'down' : 'right'" nzTheme="outline"></i>
      Chẩn đoán ban đầu
    </div>
    <!-- <div class="box-tools" *ngIf="expandItem[1].expand">
                                <i
                                  *ngIf="visit.clinicalInfomation && visit.clinicalInfomation != '' && !onlyView && !isDisableCopy"
                                  class="icon-md"
                                  nz-icon
                                  nzType="copy"
                                  title="Thêm vào master note"
                                  nzTheme="outline"
                                  (click)="sendConclusionToMasterNoteAndClinicalInfomation()"></i>
                              </div> -->
  </div>
  <div class="box-body" *ngIf="expandItem[10].expand">
    <!-- <div
                                nz-resizable
                                nzPreview
                                (nzResizeEnd)="onResize($event, 'diagnoseInit')"
                                [style.height]="resizeTextbox[1].height"
                                [nzBounds]="'window'"
                                class="position-relative">
                                <nz-resize-handles [nzDirections]="['bottom']"></nz-resize-handles> -->
    <div class="position-relative">
      <textarea class="textarea-full" nz-input placeholder="Nhập chẩn đoán ban đầu" [(ngModel)]="visit.diagnoseInit"
        (ngModelChange)="onTypingDiagnoseInit($event)" [disabled]="onlyView"></textarea>
      <span nz-icon nz-tooltip nzTooltipTitle="Lưu" nzType="save" class="position-absolute icon-save"
        (click)="clickTosaveVisitInfo('diagnoseInit')"></span>
    </div>
  </div>
</div>

              <div class="portal-item">
                <div class="box-header">
                  <div class="box-title" (click)="expand(2)">
                    <i nz-icon [nzType]="expandItem[2].expand ? 'down' : 'right'" nzTheme="outline"></i>
                    Chẩn đoán theo mã ICD 10
                  </div>
                  <div class="box-tools"></div>
                </div>
                <div class="box-body" *ngIf="expandItem[2].expand">
                  <div class="input-diseases {{ hideButton }}">
                    <nz-select
                      [nzShowSearch]="true"
                      [nzShowArrow]="true"
                      [nzServerSearch]="true"
                      [(ngModel)]="suggestDiseases"
                      nzPlaceHolder="Tìm kiếm bệnh theo mã ICD10"
                      (nzOnSearch)="onTypingICD($event)"
                      (ngModelChange)="fieldSelectedICD10($event)">
                      <nz-option
                        *ngFor="let item of diseasesData"
                        nzCustomContent
                        [nzLabel]="item.code"
                        [nzValue]="item">
                        <div>{{ item.code }} - {{ item.name }}</div>
                      </nz-option>
                    </nz-select>
                  </div>
                  <ul id="ul_disease">
                    <li *ngFor="let disease of lstDisease">
                      <span>{{ disease.code }} - {{ disease.name }}</span>
                      <i
                        class="{{ hideButton }}"
                        nz-icon
                        nzType="close"
                        nzTheme="outline"
                        (click)="removeDiseases($event, disease.id)"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="portal-item">
                <div class="box-header">
                  <div class="box-title" (click)="expand(3)">
                    <i nz-icon [nzType]="expandItem[3].expand ? 'down' : 'right'" nzTheme="outline"></i>
                    Kết luận của bác sĩ
                  </div>
                  <div class="box-tools" *ngIf="expandItem[3].expand">
                    <i
                      *ngIf="visit.conclusion && visit.conclusion != '' && !onlyView && !isDisableCopy"
                      class="icon-md"
                      nz-icon
                      nzType="copy"
                      title="Thêm vào master note"
                      nzTheme="outline"
                      (click)="sendConclusionToMasterNoteAndClinicalInfomation()"></i>
                  </div>
                </div>
                <div class="box-body" *ngIf="expandItem[3].expand">
                  <div
                    nz-resizable
                    nzPreview
                    (nzResizeEnd)="onResize($event, 'conclusion')"
                    [style.height]="resizeTextbox[2].height"
                    [nzBounds]="'window'"
                    class="position-relative"
                    >
                    <nz-resize-handles [nzDirections]="['bottom']"></nz-resize-handles>
                    <textarea
                      class="textarea-full"
                      nz-input
                      placeholder="Nhập kết luận của bác sĩ"
                      [(ngModel)]="visit.conclusion"
                      (ngModelChange)="onTypingConclusion($event)"
                      [disabled]="onlyView"></textarea>
                      <span nz-icon nz-tooltip nzTooltipTitle="Lưu" nzType="save" class="position-absolute icon-save"  (click)="clickTosaveVisitInfo('conclusion')"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="portal-item">
              <app-ros-and-subclinic
                [hideButton]="hideButton"
                [disabledClass]="disabledClass"
                [visitId]="visit.id"
                [loadRecord]="loadRecordDelete"
                (openPhieudl)="sendDataToForm($event)"></app-ros-and-subclinic>
            </div>
            <div class="portal-item">
              <app-family-member *ngIf="isUserFamilyMember" [visit]="visit"></app-family-member>
            </div>
            <div class="portal-item">
              <div class="box-header">
                <div class="box-title" (click)="expand(4)">
                  <i nz-icon [nzType]="expandItem[4].expand ? 'down' : 'right'" nzTheme="outline"></i>
                  Chẩn đoán hình ảnh
                </div>
                <div class="box-tools" *ngIf="expandItem[4].expand">
                  <i style="float: right; cursor: pointer" title="Xem tất cả" (click)="openTabCDHA()"
                    ><img src="assets/image/more.svg"
                  /></i>
                </div>
              </div>
              <div class="box-body" *ngIf="expandItem[4].expand">
                <div class="img-content cdha">
                  <ul class="ul-image-general">
                    <li class="cdha-li" *ngFor="let item of lstSubclinicalReports | slice: 0:9">
                      <a (click)="openPdf(item)">
                        <img class="thumbnail-pdf-icon" src="assets/image/pdf.svg" />
                        <div class="thumbnail-pdf-title">Phiếu kết quả</div>
                      </a>
                    </li>
                    <app-list-image-general
                      #image2
                      [visitId]="0"
                      [imageFrom]="imageFrom.BoxCDHA"
                      [isSliced]="true"
                      [hideButton]="hideButton"
                      [lstImages]="lstSubclinicalImages">
                    </app-list-image-general>
                  </ul>
                </div>
                <nz-modal
                  nzClassName="slideShowImage"
                  [(nzVisible)]="isVisablePdf"
                  (nzOnCancel)="closePdf()"
                  [nzFooter]="null">
                  <!-- <pdf-viewer [src]="linkPdf" [zoom]="0.8" [render-text]="true"></pdf-viewer> -->
                  <div class="pdf-viewer-container">
                    <app-m-view-pdf [pdfDocumentSrc]="linkPdf"></app-m-view-pdf>
                  </div>
                </nz-modal>
              </div>
            </div>
            <div class="portal-item">
              <div class="box-header">
                <div class="box-title" (click)="expand(5)">
                  <i nz-icon [nzType]="expandItem[5].expand ? 'down' : 'right'" nzTheme="outline"></i>
                  Chỉ số xét nghiệm
                </div>
                <div class="box-tools" *ngIf="expandItem[5].expand">
                  <i style="float: right; cursor: pointer" title="Xem tất cả" (click)="openTabXN()"
                    ><img src="assets/image/more.svg"
                  /></i>
                </div>
              </div>
              <div class="box-body" *ngIf="expandItem[5].expand">
                <div class="obser-content">
                  <ul class="lst-csxn customTableCrollbar">
                    <li *ngFor="let listobser of observation">
                      <span>{{ listobser.name }}</span>
                      <span
                        >{{ listobser.value }}
                        <ng-container *ngIf="listobser.unit !== ''">( {{ listobser.unit }} )</ng-container>
                      </span>
                    </li>
                  </ul>
                  <div *ngIf="observation == false">
                    <div class="emptyData">Không có dữ liệu</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </as-split-area>
        <as-split-area *ngIf="config.columns[0].columns[1].visible" [size]="config.columns[0].columns[1].size">
          <div id="main-right">
            <div class="box-right {{ hideButton }}">
              <div class="box-header">
                <div class="box-title" (click)="expand(6)">
                  <i nz-icon [nzType]="expandItem[6].expand ? 'down' : 'right'" nzTheme="outline"></i>
                  Lịch sử khám/điều trị
                  <span>({{ totalHistory }})</span>
                </div>
                <div class="box-tools" *ngIf="expandItem[6].expand">
                  <i title="Xem tất cả" style="cursor: pointer" (click)="showModalHistory()"
                    ><img src="assets/image/more.svg"
                  /></i>
                </div>
              </div>
              <div class="box-body" *ngIf="expandItem[6].expand">
                <ul id="visit-history" class="thin-scroll">
                  <li
                    (click)="loadVisitById(item.id)"
                    [ngClass]="item.id === visit.id ? 'li-active' : ''"
                    class="li-item"
                    *ngFor="let item of lstHistory">
                    <div class="txt-ellipsis">
                      <a>{{ item.visitDate | date: 'dd/MM/yyy hh:mm a' }}</a>
                      <span>{{ item.id === visit.id ? visit.visitReason : item.visitReason }}</span>
                      <span *ngIf="item.doctorGroupId != currentGroupId">&nbsp;({{ item.doctorGroupName }})</span>
                      <span
                        *ngIf="currentUserOnline == item.creatorId"
                        title="Xóa ca khám"
                        class="action-icon"
                        click-stop-propagation
                        (click)="removeVisitConfirm(item)">
                        <i nz-icon nzType="delete" nzTheme="outline"></i>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="box-right {{ hideButton }}">
              <div class="box-header">
                <div class="box-title" (click)="expand(7)">
                  <i nz-icon [nzType]="expandItem[7].expand ? 'down' : 'right'" nzTheme="outline"></i>
                  Tags
                  <span *ngIf="lstVisitTags.length > 0">({{ lstVisitTags.length }})</span>
                </div>
                <div class="box-tools" *ngIf="expandItem[7].expand">
                  <div class="tag-add">
                    <input
                      nz-input
                      [(ngModel)]="newVisitTag"
                      (keyup.enter)="addVisitTags()"
                      type="text"
                      placeholder="Thêm tags" />
                    <i title="Thêm tags" (click)="addVisitTags()">
                      <img style="width: 24px; height: 24px" src="assets/image/DPicon/icon-add-green.svg" />
                    </i>
                  </div>
                </div>
              </div>
              <div class="box-body" *ngIf="expandItem[7].expand">
                <li *ngFor="let tag of lstVisitTags" class="tag-item" (click)="openTabSearch(tag)">
                  <span class="mg-r-10">{{ tag }}</span>
                  <i class="tag-close" (click)="removeVisitTags(tag)" nz-icon nzType="close" nzTheme="outline"></i>
                </li>
              </div>
            </div>
            <div class="box-right {{ disabledClass }}">
              <div class="box-header">
                <div class="box-title" (click)="expand(8)">
                  <i nz-icon [nzType]="expandItem[8].expand ? 'down' : 'right'" nzTheme="outline"></i>
                  Thông tin lâm sàng
                  <span *ngIf="lstObservationVS.length > 0">({{ lstObservationVS.length }})</span>
                </div>
                <div class="box-tools" *ngIf="expandItem[8].expand">
                  <a class="{{ hideButton }}" (click)="showModalAddVS(null)">
                    <i title="Thêm thông tin lâm sàng">
                      <img style="width: 24px; height: 24px" src="assets/image/DPicon/icon-add-green.svg" />
                    </i>
                  </a>
                </div>
              </div>
              <div class="box-body" *ngIf="expandItem[8].expand">
                <div class="vital-sign-list">
                  <ul class="ul-image-general">
                    <li
                      class="thumbnail-img"
                      *ngFor="let listobser of lstObservationVS"
                      (click)="showModalAddVS(listobser.observationTypeId)">
                      <div>{{ listobser.name }}</div>
                      <div class="vs-des" *ngIf="listobser.dataType == 7 || listobser.dataType == 8">
                        {{ listobser.value | date: 'dd/MM/yyyy' }} ( {{ listobser.unit }} )
                      </div>
                      <div class="vs-des" *ngIf="listobser.dataType == 4">
                        <ng-container *ngIf="listobser.value === true"> Có </ng-container>
                        <ng-container *ngIf="listobser.value === false"> Không </ng-container>
                      </div>
                      <div class="vs-des" *ngIf="listobser.dataType == 9 || listobser.dataType == 10">
                        {{ getShortenString(listobser.value2Show, 16) }}
                      </div>
                      <div
                        class="vs-des"
                        *ngIf="listobser.dataType == 1 || listobser.dataType == 2 || listobser.dataType == 5">
                        {{ getShortenString(listobser.value, 16) }}
                        <ng-container *ngIf="listobser.unit.length > 0"> ( {{ listobser.unit }} ) </ng-container>
                      </div>
                      <div class="menu-hover {{ hideButton }}">
                        <div class="text-right">
                          <a class="editImg" (click)="showModalAddVS(listobser.observationTypeId)">
                            <i nz-icon nzType="edit" nzTheme="outline"></i>
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="box-right">
              <div class="box-header">
                <div class="box-title" (click)="expand(9)">
                  <i nz-icon [nzType]="expandItem[9].expand ? 'down' : 'right'" nzTheme="outline"></i>
                  General
                  <span *ngIf="totalGeneral > 0">({{ totalGeneral }})</span>
                </div>
                <div class="box-tools" *ngIf="expandItem[9].expand">
                  <i title="Xem tất cả" style="cursor: pointer" (click)="viewAllImage()"
                    ><img src="assets/image/more.svg"
                  /></i>
                </div>
              </div>
              <div class="box-body" *ngIf="expandItem[9].expand">
                <ul class="ul-image-general" style="margin-right: -15px">
                  <app-list-image-general
                    #image3
                    [viewImageVisible]="isShowslideImage"
                    [imageFrom]="imageFrom.BoxGeneral"
                    [hideButton]="hideButton"
                    [lstImages]="lstImages"
                    [visitId]="newVisitId"
                    [visitDate]="visit.visitDate"
                    (closeShowImage)="closeShowImage()"
                    (refreshData)="refreshDataImage($event)">
                  </app-list-image-general>
                </ul>
                <ul class="ul-document-general" style="margin-right: -15px">
                  <li class="add-doc {{ hideButton }} first">
                    <a (click)="openModalAddDoc()">
                      <img src="assets/image/DPicon/blue-document.svg" />
                      <div class="thumbnail-pdf-title">Thêm tài liệu</div>
                    </a>
                  </li>
                  <li
                    *ngFor="let pdfDocument of pdfDocuments"
                    class="list-media thumbnailDoc"
                    style="overflow: hidden"
                    title="{{ pdfDocument.comment }}">
                    <div (click)="openPdfVisit(pdfDocument.pdfURL)">
                      <img class="thumbnail-pdf-icon" src="assets/image/pdf.svg" />
                      <div class="thumbnail-pdf-title">
                        {{ pdfDocument.title }}
                      </div>
                    </div>
                    <div
                      class="menu-hover {{ hideButton }}"
                      [ngClass]="pdfDocument.creatorId != currentUserOnline ? 'd-none' : ''">
                      <div class="text-right">
                        <a (click)="openEditPdf(pdfDocument)">
                          <i nz-icon nzType="edit" nzTheme="outline"></i>
                        </a>
                        <a class="delImg" (click)="deletePdfVisit(pdfDocument.id)">
                          <i nz-icon nzType="delete" nzTheme="outline"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li
                    *ngFor="let docdata of document | slice: 0:5"
                    class="list-media"
                    id="list-note-general"
                    style="overflow: hidden"
                    title="{{ docdata.title }}">
                    <ckeditor
                      [disabled]="true"
                      [editor]="Editor"
                      data="{{ docdata.report }}"
                      [config]="{ toolbar: [] }"
                      class="preview"
                      (click)="openModalEditNote(docdata)"></ckeditor>
                    <div
                      class="menu-hover {{ hideButton }}"
                      [ngClass]="docdata.creatorId != currentUserOnline ? 'd-none' : ''">
                      <div class="text-right">
                        <a class="delImg" (click)="deleteDocumentVisit(docdata.id)">
                          <i nz-icon nzType="delete" nzTheme="outline"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </as-split-area>
      </as-split>
    </div>
  </div>
  <!-- popup view pdf -->
  <nz-modal
    nzClassName="slideShowImage"
    [(nzVisible)]="isVisiblePdfDocument"
    (nzOnCancel)="cancelPdfVisit()"
    [nzFooter]="null">
    <div class="pdf-viewer-container">
      <app-m-view-pdf [pdfDocumentSrc]="pdfDocumentSrc"></app-m-view-pdf>
    </div>
  </nz-modal>
  <!-- popup sửa pdf -->
  <nz-modal [(nzVisible)]="isVisibleEditPdf" nzTitle="Sửa tài liệu pdf" (nzOnCancel)="cancelEditPdf()" [nzWidth]="1000">
    <div class="row {{ disabledClass }}">
      <div class="col-3">Phạm vi chia sẻ</div>
      <div class="col-9">
        <nz-radio-group [(ngModel)]="pdfForm.privacyPolicy">
          <label nz-radio [nzValue]="4" title="BN được xem dữ liệu và các BS trong group BS được xem/sửa dữ liệu"
            >Trả cho bệnh nhân</label
          >
          <label nz-radio [nzValue]="3" title="Các BS trong group được xem/sửa dữ liệu">Sửa trong nhóm</label>
          <label nz-radio [nzValue]="2" title="Các BS trong group BS được xem, chỉ tôi được sửa dữ liệu"
            >Xem trong nhóm</label
          >
          <label nz-radio [nzValue]="1" title="Chỉ tôi được xem/sửa dữ liệu">Riêng tôi</label>
        </nz-radio-group>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Là dữ liệu CĐHA</div>
      <div class="col-9">
        <label nz-checkbox [(ngModel)]="pdfForm.isKeyDocument"></label>
      </div>
    </div>
    <div class="row {{ disabledClass }}">
      <div class="col-3">Comment</div>
      <div class="col-9">
        <input
          type="text"
          nz-input
          [readonly]="!isUserCreatePdf"
          nz-tooltip
          [nzTooltipTitle]="editCommentTooltip"
          [(ngModel)]="pdfForm.comment" />
        <ng-template #editCommentTooltip>
          <span id="edit-comment-tooltip">Chỉ người tạo được thêm/sửa comment</span>
        </ng-template>
      </div>
    </div>
    <div class="edit-pdf-container">
      <nz-spin class="loading-icon" [nzSpinning]="isLoadingPdf" nzTip="Loading..."></nz-spin>
      <pdf-viewer
        [src]="pdfDocumentSrc"
        [rotation]="0"
        [original-size]="false"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]="0.8"
        [zoom-scale]="'page-width'"
        [stick-to-page]="false"
        [render-text]="true"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="false"
        (after-load-complete)="isLoadingPdf = false"></pdf-viewer>
    </div>
    <div *nzModalFooter class="{{ hideButton }}">
      <button nz-button class="btn-cancel-medium" (click)="cancelEditPdf()">Đóng</button>
      <button nz-button class="btn-primary-medium" (click)="editVisitPdf()">Sửa</button>
    </div>
  </nz-modal>
  <!-- popup thêm doc -->
  <!-- <nz-modal [(nzVisible)]="isVisibleAddDoc" nzTitle="Thêm tài liệu" (nzOnCancel)="cancelAddDoc()" [nzWidth]="1000"> -->
  <app-m-modal
    [modalId]="'tab-visit-info_add-doc'"
    [isVisible]="isVisibleAddDoc"
    (closeModal)="cancelAddDoc()"
    [mMask]="true"
    [width]="1000"
    [minWidth]="700"
    [minHeight]="400"
    [height]="600"
    [isResiable]="true">
    <ng-template #mModalTitle>
      <div class="header-title">Thêm tài liệu</div>
    </ng-template>
    <ng-template #mModalContent>
      <nz-tabset [nzAnimated]="false" [(nzSelectedIndex)]="indexTabAddDoc" class="tabset-unset tabset-default">
        <nz-tab nzTitle="Thêm tài liệu">
          <div class="row {{ disabledClass }}">
            <div class="col-3">Phạm vi chia sẻ</div>
            <div class="col-9">
              <nz-radio-group [(ngModel)]="pdfForm.privacyPolicy">
                <label nz-radio [nzValue]="4" title="BN được xem dữ liệu và các BS trong group BS được xem/sửa dữ liệu"
                  >Trả cho bệnh nhân</label
                >
                <label nz-radio [nzValue]="3" title="Các BS trong group được xem/sửa dữ liệu">Sửa trong nhóm</label>
                <label nz-radio [nzValue]="2" title="Các BS trong group BS được xem, chỉ tôi được sửa dữ liệu"
                  >Xem trong nhóm</label
                >
                <label nz-radio [nzValue]="1" title="Chỉ tôi được xem/sửa dữ liệu">Riêng tôi</label>
              </nz-radio-group>
            </div>
          </div>
          <div class="row">
            <div class="col-3">Là dữ liệu CĐHA</div>
            <div class="col-9">
              <label nz-checkbox [(ngModel)]="pdfForm.isKeyDocument"></label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">Comment</div>
            <div class="col-9">
              <input type="text" nz-input [(ngModel)]="pdfForm.comment" />
            </div>
          </div>
          <div class="row">
            <div class="col-3">File</div>
            <div class="col-9">
              <nz-spin [nzSpinning]="isUploadingFile">
                <nz-upload
                  nzType="drag"
                  [nzAction]="uploadFileUrl"
                  [nzHeaders]="header"
                  nzName="FormFile"
                  nzListType="picture"
                  [nzShowUploadList]="{
                    showRemoveIcon: true,
                    showDownloadIcon: false
                  }"
                  [(nzFileList)]="pdfList"
                  (nzChange)="fileChange($event)">
                  <p>
                    <i class="icon-baseline" nz-icon nzType="inbox" style="margin-right: 10px"></i>Chọn hoặc thả file
                    pdf vào đây
                  </p>
                </nz-upload>
              </nz-spin>
            </div>
          </div>
        </nz-tab>
        <nz-tab nzTitle="Thêm ghi chú">
          <div class="row {{ disabledClass }}">
            <div class="col-3">Phạm vi chia sẻ</div>
            <div class="col-9">
              <nz-radio-group [(ngModel)]="noteForm.privacyPolicy">
                <label nz-radio [nzValue]="4" title="BN được xem dữ liệu và các BS trong group BS được xem/sửa dữ liệu"
                  >Trả cho bệnh nhân</label
                >
                <label nz-radio [nzValue]="3" title="Các BS trong group được xem/sửa dữ liệu">Sửa trong nhóm</label>
                <label nz-radio [nzValue]="2" title="Các BS trong group BS được xem, chỉ tôi được sửa dữ liệu"
                  >Xem trong nhóm</label
                >
                <label nz-radio [nzValue]="1" title="Chỉ tôi được xem/sửa dữ liệu">Riêng tôi</label>
              </nz-radio-group>
            </div>
          </div>
          <div class="row">
            <div class="col-3">Tiêu đề</div>
            <div class="col-9">
              <input type="text" nz-input [(ngModel)]="noteForm.title" />
            </div>
          </div>
          <div>
            <ckeditor
              class="custom-doc {{ disabledClass }}"
              [(ngModel)]="noteForm.report"
              [editor]="Editor"
              (ready)="onReady($event)"></ckeditor>
          </div>
        </nz-tab>
      </nz-tabset>
    </ng-template>
    <ng-template #mModalFooter>
      <button *ngIf="indexTabAddDoc == 0" nz-button class="btn-primary-medium" (click)="addVisitPdf()">Lưu</button>
      <button *ngIf="indexTabAddDoc == 1" nz-button class="btn-primary-medium" (click)="addDocumentVisit()">Lưu</button>
      <button nz-button class="btn-cancel-medium" (click)="cancelAddDoc()">Đóng</button>
    </ng-template>
    <!-- <div *nzModalFooter class="{{ hideButton }}">
      <div *ngIf="indexTabAddDoc==0">
        <button nz-button nzType="primary" (click)="addVisitPdf()">
          Lưu
        </button>
        <button nz-button nzType="default" (click)="cancelAddDoc()">Đóng</button>
      </div>
      <div *ngIf="indexTabAddDoc==1">
        <button *ngIf="addOrEdit" nz-button nzType="primary" (click)="addDoc(true)">
          Thêm
        </button>
        <button *ngIf="!addOrEdit" nz-button nzType="primary" (click)="addDoc(false)">
          Sửa
        </button>
        <button nz-button nzType="default" (click)="cancelAddDoc()">Đóng</button>
      </div>
    </div> -->
  </app-m-modal>
  <!-- </nz-modal> -->

  <!-- popup sửa ghi chú -->
  <nz-modal [(nzVisible)]="isVisibleEditNote" nzTitle="Sửa ghi chú" (nzOnCancel)="cancelEditNote()" [nzWidth]="1000">
    <div class="row {{ disabledClass }}">
      <div class="col-3">Phạm vi chia sẻ</div>
      <div class="col-9">
        <nz-radio-group [nzDisabled]="isNoteOfCurrentUser" [(ngModel)]="noteForm.privacyPolicy">
          <label nz-radio [nzValue]="4" title="BN được xem dữ liệu và các BS trong group BS được xem/sửa dữ liệu"
            >Trả cho bệnh nhân</label
          >
          <label nz-radio [nzValue]="3" title="Các BS trong group được xem/sửa dữ liệu">Sửa trong nhóm</label>
          <label nz-radio [nzValue]="2" title="Các BS trong group BS được xem, chỉ tôi được sửa dữ liệu"
            >Xem trong nhóm</label
          >
          <label nz-radio [nzValue]="1" title="Chỉ tôi được xem/sửa dữ liệu">Riêng tôi</label>
        </nz-radio-group>
      </div>
    </div>
    <div class="row {{ disabledClass }}">
      <div class="col-3">Tiêu đề</div>
      <div class="col-9">
        <input type="text" nz-input [readonly]="isDisableEditNote" [(ngModel)]="noteForm.title" />
      </div>
    </div>
    <div>
      <!-- <ckeditor class="custom-doc {{ disabledClass }}" [(ngModel)]="noteForm.report" [editor]="Editor" -->
      <ckeditor
        [disabled]="isDisableEditNote"
        class="custom-doc {{ disabledClass }}"
        [(ngModel)]="noteForm.report"
        [editor]="Editor"
        [config]="{ toolbar: { shouldNotGroupWhenFull: true } }"
        (ready)="onReady($event)"></ckeditor>
    </div>
    <div *nzModalFooter class="{{ hideButton }}">
      <button nz-button class="btn-cancel-medium" (click)="cancelEditNote()">Đóng</button>
      <button
        nz-button
        [disabled]="isDisableEditNote"
        [ngClass]="{ 'btn-primary-medium': !isDisableEditNote }"
        (click)="addDocumentVisit()">
        Sửa
      </button>
    </div>
  </nz-modal>

  <!-- popup Xem tất cả Lịch sử khám -->
  <app-visit-history
    [(isVisible)]="isVisibleVisitHis"
    [visit]="visit"
    (loadVisit)="loadVisitById($event)"></app-visit-history>

  <!-- popup thêm chỉ số sinh tồn -->
  <app-add-vital-asign
    #addVitalAsign
    [isVisible]="isVisibleVS"
    (addObserVSEmit)="addVitalSignsEvent()"
    (closeModal)="closeAddVitalSigns()"></app-add-vital-asign>
</div>
