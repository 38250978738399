import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
declare let $: any;

@Component({
  selector: 'app-canvas-element',
  templateUrl: './canvas-element.component.html',
  styleUrls: ['./canvas-element.component.scss'],
})
export class CanvasElementComponent implements OnInit, OnDestroy, AfterViewInit {
  id: string;
  _data: any;
  @Input() set data(value: any) {
    this._data = value;

    this.id = value.viewId + '-canvas';
  }

  get data(): any {
    return this._data;
  }

  @ViewChild('canvas') canvas: ElementRef;
  top: 20;
  left: 20;
  cx: CanvasRenderingContext2D;
  drawingSubscription: Subscription;
  penMode = true;
  canvasClass = '';
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    const $this = this;
    const canvasElm = $(`#${this.id}`)[0];
    canvasElm.width = $(`#${this.data.viewId}`).width();
    canvasElm.height = $(`#${this.data.viewId}`).height();
    let ctx = canvasElm.getContext('2d');
    let lastX;
    let lastY;
    const strokeColor = 'red';
    const strokeWidth = 3;
    let mouseX;
    let mouseY;
    const canvasOffset = $(`#${this.id}`).offset();
    let isMouseDown = false;

    function initCanvas(data) {
      ctx = canvasElm.getContext('2d');
      const img = new Image();
      img.onload = function () {
        ctx.drawImage(img, 0, 0); // Or at whatever offset you like
      };
      img.src = data;
    }

    function save() {
      const dataURL = canvasElm.toDataURL();
      // ctx = myCanvas.getContext('2d');
      // let img = new Image;
      // img.onload = function () {
      //   ctx.drawImage(img, 0, 0); // Or at whatever offset you like
      // };
      return dataURL;
    }

    function handleMouseDown(e) {
      mouseX = e.clientX - $(`#${$this.id}`).offset().left;
      mouseY = e.clientY - $(`#${$this.id}`).offset().top;

      // Put your mousedown stuff here
      lastX = mouseX;
      lastY = mouseY;
      isMouseDown = true;
    }

    function handleMouseUp(e) {
      mouseX = e.clientX - $(`#${$this.id}`).offset().left;
      mouseY = e.clientY - $(`#${$this.id}`).offset().top;

      // Put your mouseup stuff here
      isMouseDown = false;
      $this.data.value = save();
    }

    function handleMouseOut(e: any) {
      mouseX = e.clientX - $(`#${$this.id}`).offset().left;
      mouseY = e.clientY - $(`#${$this.id}`).offset().top;

      // Put your mouseOut stuff here
      isMouseDown = false;
    }

    function handleMouseMove(e) {
      mouseX = e.clientX - $(`#${$this.id}`).offset().left;
      mouseY = e.clientY - $(`#${$this.id}`).offset().top;

      // Put your mousemove stuff here
      if (isMouseDown) {
        ctx.beginPath();
        if ($this.penMode) {
          ctx.globalCompositeOperation = 'source-over';
          ctx.moveTo(lastX, lastY);
          ctx.lineTo(mouseX, mouseY);
          ctx.strokeStyle = strokeColor;
          ctx.lineWidth = strokeWidth;
          ctx.stroke();
        } else {
          ctx.globalCompositeOperation = 'destination-out';
          ctx.arc(lastX, lastY, 8, 0, Math.PI * 2, false);
          ctx.fill();
        }
        lastX = mouseX;
        lastY = mouseY;
      }
    }

    $(canvasElm).mousedown(function (e) {
      handleMouseDown(e);
    });
    $(canvasElm).mousemove(function (e) {
      handleMouseMove(e);
    });
    $(canvasElm).mouseup(function (e) {
      handleMouseUp(e);
    });
    $(canvasElm).mouseout(function (e) {
      handleMouseOut(e);
    });

    console.log(this.data);
    $(document).ready(function () {
      if ($this.data && $this.data.value) {
        initCanvas($this.data.value);
      }
    });
  }

  ngOnDestroy() {
    // this will remove event lister when this component is destroyed
    // this.drawingSubscription.unsubscribe();
  }

  toggeMode() {
    this.penMode = !this.penMode;
    if (!this.penMode) {
      this.canvasClass = 'delete-mode';
    } else {
      this.canvasClass = '';
    }
  }
}
