<div class="boxSearch">
  <div class="box-kw">
    <div class="input-kw">
      <i
        nz-icon
        nzType="search"
        style="width: 20px; margin-left: 20px; margin-top: 15px"></i
      ><input
        [(ngModel)]="searchParam.keyword"
        id="keyword"
        type="search"
        placeholder="Tìm kiếm trên..."
        (keydown.enter)="searchdata()" />
      <!-- <div class="btn-search">
        <svg style="cursor: pointer;" (click)='searchdata()' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32"
          viewBox="0 0 200 200">
          <defs>
            <clipPath id="clip-seach">
              <rect width="200" height="200" />
            </clipPath>
          </defs>
          <g id="seach" clip-path="url(#clip-seach)">
            <rect width="200" height="200" fill="none" />
            <path id="search_2_" data-name="search (2)"
              d="M85.46,75.17A47.482,47.482,0,1,0,75.168,85.462l30.993,30.993,10.292-10.292L85.46,75.17ZM47.309,80.064A32.752,32.752,0,1,1,80.061,47.311,32.788,32.788,0,0,1,47.309,80.064Z"
              transform="translate(41.865 37.998)" fill="#08338f" />
          </g>
        </svg>
      </div> -->
    </div>
    <div class="opt-all-or-ingroup">
      <div
        [ngClass]="searchParam.inGroup ? 'range-search' : 'selectRangeOfSearch'"
        (click)="searchParam.inGroup = false">
        Toàn hệ thống
      </div>
      <div
        [ngClass]="searchParam.inGroup ? 'selectRangeOfSearch' : 'range-search'"
        (click)="searchParam.inGroup = true"
        style="width: 45%; margin-left: 10px">
        Trong nhóm bác sỹ
      </div>
    </div>
  </div>

  <button nz-button nzType="primary" (click)="searchdata()" class="search-btn">
    Search
  </button>
  <a
    style="margin-left: 40px; color: rgba(0, 0, 0, 0.65)"
    class="search"
    (click)="ShowAndHide()"
    >Advanced search &#9660;</a
  >
  <div
    id="filter"
    *ngIf="isShown"
    class="row"
    style="margin-bottom: 40px; width: 90%">
    <!-- <input id="patientName" type="text" placeholder="Tên bệnh nhân" (keyup)="filterData($event)"  [(ngModel)]='dataParameter.patientName' />
    <input id="patientCode" type="text" placeholder="Mã bệnh nhân" (keyup)="filterData($event)"  [(ngModel)]='dataParameter.patientCode' />
    <input id="address" type="text" placeholder="Địa chỉ" (keyup)="filterData($event)" [(ngModel)]='dataParameter.address' />
    <div class="option-vsdate">
      <div>
        <nz-select nzAllowClear nzPlaceHolder="Thời gian khám" [(ngModel)]="ngayKham" (ngModelChange)='getRangeofDate()' >
          <nz-option nzCustomContent nzLabel='*Tất cả ngày khám' nzValue="all">*Tất cả ngày khám</nz-option>
          <nz-option nzCustomContent nzLabel='Hôm nay' nzValue="today">Hôm nay</nz-option>
          <nz-option nzCustomContent nzLabel='Hôm qua' nzValue='yesterday'>Hôm qua</nz-option>
          <nz-option nzCustomContent nzLabel='Tuần này' nzValue='thisweek'>Tuần này</nz-option>
          <nz-option nzCustomContent nzLabel='3 ngày trước' nzValue='3day'>3 ngày trước</nz-option>
          <nz-option nzCustomContent nzLabel='7 ngày trước' nzValue='7day'>7 ngày trước</nz-option>
          <nz-option nzCustomContent nzLabel='30 ngày trước' nzValue='30day'>30 ngày trước</nz-option>
          <nz-option nzCustomContent nzLabel='{{nzlabelFromTo}}' nzValue='fromTo'>Từ ngày-đến ngày</nz-option>
        </nz-select>
      </div>
      <div style="position: absolute;" [ngStyle]=" {'display': showTimePicker? 'block':'none' }">
        <nz-range-picker [(ngModel)]='rangeofdate' (ngModelChange)='getDatesfromDatePicker($event)'
          [nzPlaceHolder]='["Từ ngày","Đến ngày"]' [nzOpen]='showTimePicker'></nz-range-picker>
      </div>
    </div> -->
    <div class="col-3">
      <app-filter-item
        #filter1
        [index]="0"
        [filterItems]="currentFilters"
        [searchParam]="searchParam"
        [resetFilter]="resetFilter"
        (seachEvent)="searchdata()"></app-filter-item>
    </div>
    <div class="col-3">
      <app-filter-item
        #filter2
        [index]="1"
        [filterItems]="currentFilters"
        [searchParam]="searchParam"
        [resetFilter]="resetFilter"
        (seachEvent)="searchdata()"></app-filter-item>
    </div>
    <div class="col-3">
      <app-filter-item
        #filter3
        [index]="2"
        [filterItems]="currentFilters"
        [searchParam]="searchParam"
        [resetFilter]="resetFilter"
        (seachEvent)="searchdata()"></app-filter-item>
    </div>
    <div class="col-3">
      <app-filter-item
        #filter4
        [index]="3"
        [filterItems]="currentFilters"
        [searchParam]="searchParam"
        [resetFilter]="resetFilter"
        (seachEvent)="searchdata()"></app-filter-item>
    </div>
  </div>
</div>

<nz-table
  id="tabsearch"
  class="main-table-grid"
  #searchTable
  [nzData]="listDataShow"
  [nzScroll]="{ x: scrollX, y: '540px' }"
  [nzShowPagination]="true"
  [nzLoading]="loading"
  nzShowSizeChanger
  [nzPageSize]="pageSize"
  [nzPageSize]="pageSize"
  [(nzPageIndex)]="pageIndex"
  [nzFrontPagination]="false"
  [nzTotal]="total"
  (nzQueryParams)="onQueryParamsChange($event)"
  [nzPageSizeOptions]="[10, 20, 50, 100]">
  <thead>
    <tr>
      <th class="text-nowrap" nzWidth="4%">STT</th>
      <th class="text-nowrap" nzWidth="15%" class="nzEllipsis">
        Tên bệnh nhân
      </th>
      <th class="text-nowrap" nzWidth="15%" class="nzEllipsis">Mã bệnh nhân</th>
      <th class="text-nowrap" nzWidth="8%" class="nzEllipsis">Giới tính</th>
      <th class="text-nowrap" nzWidth="10%">Ngày sinh</th>
      <th class="text-nowrap" nzWidth="10%">Số điện thoại</th>
      <th class="text-nowrap" nzWidth="15%">Địa chỉ</th>
      <th class="text-nowrap" nzWidth="10%">Thời gian khám</th>
      <th class="nzEllipsis text-nowrap">Lý do khám</th>
      <!-- <th class="nzEllipsis text-nowrap">Tên bác sỹ</th> -->
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let data of searchTable.data; index as i"
      (click)="addTab(data.id, data.patientName, data.patientId)">
      <td class="text-nowrap" title="{{ data.sttsearchresult }}">
        {{ data.sttsearchresult }}
      </td>
      <td>
        <div
          *ngIf="
            data.color && data.shortName && (!data.avatar || data.avatar == '')
          "
          class="customer-photo"
          [ngStyle]="{ 'background-color': data.color }">
          {{ data.shortName }}
        </div>
        <div
          *ngIf="
            !data.color &&
            !data.shortName &&
            (!data.avatar || data.avatar == '')
          "
          class="customer-photo"
          style="
            background-image: url('/assets/image/avt-default2.png');
            background-repeat: round;
          "></div>
        <div
          *ngIf="data.avatar && data.avatar != ''"
          class="customer-photo"
          [ngStyle]="{
            'background-image': 'url(' + (baseAvatarurl + data.avatar) + ')'
          }"></div>
        {{ data.patientName }}
      </td>
      <td title="{{ data.patientCode }}" class="nzEllipsis">
        {{ data.patientCode }}
      </td>
      <td title="{{ data.genderText }}">{{ data.genderText }}</td>
      <td class="text-nowrap" title="{{ data.dobString }}">
        {{ data.dobString }}
      </td>
      <td title="{{ data.phoneNo }}">{{ data.phoneNo }}</td>
      <td title="{{ data.address }}" class="nzEllipsis">{{ data.address }}</td>
      <td
        class="text-nowrap"
        title="{{ data.visitDate | date: 'dd/MM/yy hh:mm a' }}">
        {{ data.visitDate | date: 'dd/MM/yy hh:mm a' }}
      </td>
      <td title="{{ data.visitReason }}" class="nzEllipsis">
        {{ data.visitReason }}
      </td>
      <!-- <td title="{{ data.doctorName}}" class="nzEllipsis">{{ data.doctorName }}</td> -->
    </tr>
  </tbody>
</nz-table>
