<i class="btn-add" title="Thêm bệnh sử" (click)="showModal()">
  <img src="assets/image/DPicon/icon-add-green.svg" />
</i>
<!-- <div class="div-btn" (click)="showModal()">
  <i>
    <img src="assets/image/DPicon/icon-add-green.svg" />
  </i>
  Thêm bệnh sử
</div> -->
<nz-table
  #table
  class="second-table-grid"
  nzSize="small"
  [nzData]="mockArray"
  [nzShowPagination]="false"
  nzShowSizeChanger
  [nzPageSizeOptions]="[10, 20, 50, 100]">
  <thead>
    <tr>
      <th>Thành viên</th>
      <th>Bệnh sử</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of table.data">
      <td>
        <img
          src="assets/image/DPicon/family-avatar.svg"
          style="margin-right: 15px" /><strong>{{
          getRelationShipName(item.relationShip)
        }}</strong>
      </td>
      <td>
        {{ item.disease }}
      </td>
    </tr>
  </tbody>
</nz-table>

<div class="custom-modal">
  <nz-modal
    class="custom-modal"
    [(nzVisible)]="isVisibleModal"
    nzClosable="false"
    nzTitle="Thông tin bệnh sử">
    <div>
      <div class="tab_container">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Quan hệ</label>
          <div class="col-sm-9">
            <select
              class="form-control"
              [(ngModel)]="selectedItem.relationShip">
              <option *ngFor="let o of relationShips" [value]="o.id">
                {{ o.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Bệnh sử</label>
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              placeholder=""
              [(ngModel)]="selectedItem.disease"
              style="border: 1px solid #c3c3c3" />
          </div>
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <button nz-button (click)="handleCancel()" class="btn-cancel-small">
        Bỏ qua
      </button>
      <button nz-button (click)="handleOk()" class="btn-primary-small">
        Lưu
      </button>
    </div>
  </nz-modal>
</div>
