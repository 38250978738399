<div class="txt-area">
  <textarea
    class="textarea"
    [disabled]="isDisabled"
    (ngModel)="(model)"
    (ngModelChange)="modelChangeValue($event)"></textarea>
  <div class="resize"></div>
  <div *ngIf="canMove" class="overlay"></div>
</div>
<!--  -->
