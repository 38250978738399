<!-- <nz-modal class="video-modal" [(nzVisible)]="isVisible" [nzWidth]="406" nzTitle="Tạo cuộc gọi video"
  (nzOnCancel)="handleCancel()" (nzOnOk)="handleCancel()"> -->
<app-m-modal
  [modalId]="'conference-room'"
  [isVisible]="isVisible"
  (closeModal)="handleCancel()"
  [mMask]="true"
  [width]="406"
  [minWidth]="400"
  [minHeight]="400"
  [height]="550"
  [isResiable]="true">
  <ng-template #mModalTitle>
    <div class="header-title">Tạo cuộc gọi video</div>
  </ng-template>
  <ng-template #mModalContent>
    <div class="mg-t-30"><b>Chọn người mời</b></div>
    <div class="input-box">
      <!-- Chọn khách để mời vào cuộc gọi -->
      <div class="input-member row">
        <nz-select
          class="col-md-12 input-search"
          nzDropdownClassName="user-selecter"
          [nzShowSearch]="true"
          [nzServerSearch]="true"
          [(ngModel)]="ListOfMember"
          [nzShowArrow]="false"
          (nzOnSearch)="loadMember(1, $event)"
          nzPlaceHolder="Tìm người tham gia"
          (ngModelChange)="fieldSelectedMember($event)">
          <div>
            <nz-option
              *ngFor="let item of listMember; let indexOfelement = index"
              nzCustomContent
              [nzValue]="item">
              <div>{{ item.userName }}</div>
              <div>{{ item.email }}</div>
            </nz-option>
          </div>
        </nz-select>
        <!-- <button class="col-md-2" type="submit" nz-button nzType="primary" class="submit">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="20.275" height="21" viewBox="0 0 20.275 21">
            <path id="search"
              d="M20.95,19.128l-5-5.2a8.475,8.475,0,1,0-6.49,3.028,8.388,8.388,0,0,0,4.858-1.534l5.036,5.238a1.106,1.106,0,1,0,1.594-1.533ZM9.463,2.212A6.267,6.267,0,1,1,3.2,8.479,6.274,6.274,0,0,1,9.463,2.212Z"
              transform="translate(-0.984)" fill="#fff" />
          </svg>
        </span>
      </button> -->
      </div>
    </div>
    <!-- Hiển thị danh sách khách mời đã chọn -->
    <div class="invite-display">
      <div class="elm-title">
        Danh sách khách mời - <span>{{ listInvited.length }}</span> người
      </div>
      <ul id="listEmail">
        <li *ngIf="hasPatient">
          <img
            class="media-object imageRoom"
            [src]="baseAvatarurl + patient.avatar" />
          <div class="memberOfCall">
            <div>
              <b>{{ patient.fullName }}</b>
            </div>
            <div>{{ patient.email }}</div>
          </div>
          <a (click)="unSelectToInvite('patient')" title="Bỏ chọn">
            <i nz-icon nzType="close-circle" nzTheme="outline"></i>
          </a>
        </li>
        <li *ngFor="let item of listInvited">
          <img
            class="media-object imageRoom"
            [src]="baseAvatarurl + item.imageUrl" />
          <div class="memberOfCall">
            <div>
              <b>{{ item.userName }}</b>
            </div>
            <div>{{ item.email }}</div>
          </div>
          <a
            (click)="unSelectToInvite(item.userId, item.userName, item.email)"
            title="Bỏ chọn">
            <i nz-icon nzType="close-circle" nzTheme="outline"></i>
          </a>
        </li>
      </ul>
      <div>
        <div class="mg-t-30"><b>Thời điểm hội chẩn</b></div>
        <nz-radio-group [(ngModel)]="radioValue">
          <label nz-radio nzValue="A">Ngay bây giờ</label>
          <label nz-radio nzValue="B">Chọn thời điểm hội chẩn</label>
        </nz-radio-group>
      </div>
      <div class="time-alarm">
        <div class="time-select">
          <div><b>Chọn ngày</b></div>
          <nz-date-picker
            (nzOnOpenChange)="datepickerChange()"
            nzFormat="dd/MM/yyyy"
            [(ngModel)]="datetime"></nz-date-picker>
        </div>
        <div class="time-select1">
          <div><b>Chọn giờ</b></div>
          <nz-time-picker
            (nzOpenChange)="datepickerChange()"
            [(ngModel)]="timeCall"
            nzFormat="HH:mm"></nz-time-picker>
        </div>
      </div>
      <!-- <div class="row" *nzModalFooter>
      <div>
        <button nz-button nzType="primary" (click)="handleCall()" class="button-submit footerRow">Tạo phòng</button>
      </div>
    </div> -->
    </div>
  </ng-template>
  <ng-template #mModalFooter>
    <button nz-button nzType="primary" (click)="handleCall()" class="footerRow">
      Tạo phòng
    </button>
  </ng-template>
</app-m-modal>
<!-- </nz-modal> -->
