<img [src]="data.url" style="width: 100%; position: absolute; top: 0px" />
<canvas
  #canvas
  [id]="id"
  style="width: 100%; height: 100%; position: absolute; top: 0px; left: 0px"
  [ngClass]="canvasClass"></canvas>
<div class="action-btn" (click)="toggeMode()">
  <img *ngIf="penMode" src="assets/image/eraser-removebg.png" width="20px" />
  <span *ngIf="!penMode" nz-icon nzType="edit" nzTheme="fill" style="width: 25px"></span>
</div>
