import { ShareService } from './../../../services/share.service';
import { AppConfigService } from 'src/app-config.service';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';
import { VisitService } from '../../../services/visit.service';
import { Visits } from '../../../models/listVisit.class';
import { NotificationService } from '../../../services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Constant } from '../../../share/constants/constant.class';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DOCUMENT } from '@angular/common';
import { UploadService } from 'src/app/services/upload.service';
import { UrlConstant } from 'src/app/share/constants/url.class';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import {
  CloseAddPatient,
  OpenAddPatient,
  OpenAddVisit,
} from '../redux/modal/modal.action';
import { ImageElementComponent } from '../image-element/image-element.component';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { reject } from 'underscore';
import { NzModalService } from 'ng-zorro-antd/modal';
declare let $: any;

@Component({
  selector: 'app-masternotes-tab',
  templateUrl: './masternotes-tab.component.html',
  styleUrls: ['./masternotes-tab.component.scss'],
})
export class MasternotesTabComponent implements OnInit {
  userInfo: any;
  chats = [];
  chatForm: FormGroup;
  images: any[] = [];
  isUpload = false;
  isVisibleListImage = false;
  lstImages: any[] = [];
  public Editor = ClassicEditor;
  baseImageurl: string;
  _reload = false;
  @Input() visit: Visits;
  @Input() hideButton: string;
  @Input() disabledClass: string;
  @ViewChild('chatcontent') chatcontent: ElementRef;
  @ViewChild('chateditor') chateditor: ElementRef;

  slide: any = { index: 0, id: '', url: '' };
  currentUserId = '';
  editorConfig: AngularEditorConfig = {
    editable: true,
    height: '400',
    minHeight: '200',
    maxHeight: '400',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Nhập nội dung...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        //'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        //'insertUnorderedList',
        //'insertOrderedList',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  };

  constructor(
    private visitService: VisitService,
    private uploadService: UploadService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private tabDataService: TabDataService,
    private configService: AppConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: NzModalService,
    private shareService: ShareService,
    private elementRef: ElementRef,
    private store: Store<AppState>
  ) {
    this.baseImageurl =
      this.configService.getConfig().media.baseUrl + 'Media/VisitImage/';
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.currentUserId = res;
      });
  }

  ngOnInit(): void {
    //this.userInfo = JSON.parse(localStorage.getItem(Constant.USER_INFO));
    this.chatForm = this.formBuilder.group({
      content: [null, Validators.required],
    });
    this.getListNotes();
  }

  showImage() {
    this.isVisibleListImage = true;
  }

  getListNotes() {
    const $this = this;
    this.visitService.getVisitMasterNote(this.visit.patientId).subscribe(
      (resdata) => {
        new Promise((resolve, reject) => {
          this.chats = resdata;
          for (let i = 0; i < this.chats.length; i++) {
            const item = this.chats[i];
            item.showEditor = false;
            item.showEnlarge = false;
          }
          this.changeDetectorRef.detectChanges();
          resolve(true);
        }).then(() => {
          setTimeout(() => {
            const elm = this.elementRef.nativeElement;
            if (this.elementRef.nativeElement.querySelector('.note-img-hor')) {
              $(elm).find('.note-img-hor').unbind('click');
              $(elm)
                .find('.note-img-hor')
                .click(function (e) {
                  $this.slide.url = e.target.src;
                  $this.isVisibleListImage = true;
                });
            }
            if (this.elementRef.nativeElement.querySelector('.note-img-ver')) {
              $(elm).find('.note-img-ver').unbind('click');
              $(elm)
                .find('.note-img-ver')
                .click(function (e) {
                  $this.slide.url = e.target.src;
                  $this.isVisibleListImage = true;
                });
            }
          }, 200);
          // $('.note-img-ver').click((e) => {
          //   console.log(".note-img-ver");
          //   this.isVisibleListImage = true;
          // });
        });
        // this.scrollToElement();
      },
      (error) => {
        this.notificationService.showNotification('', error.statusText);
      }
    );
  }
  closeEnlargeModal(payload: any) {
    const chat = this.chats.filter((t) => t.id == payload.noteId)[0];
    chat.showEnlarge = false;
    if (payload.reload) {
      this.cancelUpdate(chat);
    }
  }

  cancelUpdate(chat) {
    this.visitService.getMasterNoteById(chat.id).subscribe((res) => {
      if (res.isValid) {
        this.chats.forEach((item) => {
          if (item.id === chat.id) {
            item.content = res.jsonData.content;
            chat.showEditor = false;
          }
        });
      }
    });
  }

  confirmDelete(chat) {
    this.modalService.confirm({
      nzTitle: 'Xóa MasterNote',
      nzContent: 'Bạn có muốn xóa hay không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Bỏ qua',
      nzOnOk: () => this.deleteNote(chat),
    });
  }
  deleteNote(chat) {
    this.visitService.deleteMasterNoteById(chat.id).subscribe((res) => {
      if (res.isValid) {
        // this.chats.forEach((item) => {
        //   if (item.id === chat.id) {
        //     item.content = res.jsonData.content;
        //     chat.showEditor = false;
        //   }
        // });
        this.chats = this.chats.filter((t) => t.id != chat.id);
      } else {
        this.notificationService.showNotification(
          '',
          res.error[0].errorMessage
        );
      }
    });
  }

  scrollToElement(): void {
    $(this.chatcontent.nativeElement).animate(
      { scrollTop: this.chatcontent.nativeElement.scrollHeight },
      500
    );
  }

  sendChat(form: any): void {
    const chat = form;
    chat.visitId = this.visit.id;
    chat.content = this.shareService.urlify(chat.content);
    this.visitService.addVisitMasterNote(chat).subscribe(
      (resdata) => {
        this.getListNotes();
        this.notificationService.showNotification(
          Constant.SUCCESS,
          'Thêm mới note thành công'
        );
        this.chatForm = this.formBuilder.group({
          content: [null, Validators.required],
        });
      },
      (error) => {
        this.notificationService.showNotification('', error.statusText);
      }
    );
  }

  showEditor(chat): void {
    chat.showEditor = true;
  }

  async updateNotes(chat) {
    chat.content = await this.shareService.urlify(chat.content);
    chat.showEditor = false;
    this.visitService.updateVisitMasterNote(chat).subscribe(
      (resdata) => {
        this.notificationService.showNotification(
          Constant.SUCCESS,
          'Cập nhật note thành công'
        );
      },
      (error) => {
        this.notificationService.showNotification('', error.statusText);
      }
    );
  }

  changeContent(): void {
    // console.log('change');
  }

  onPaste(event: ClipboardEvent) {
    const clipboardItems = event.clipboardData.items;
    const items = [].slice.call(clipboardItems).filter(function (item) {
      // Filter the image items only
      return item.type.indexOf('image') !== -1;
    });
    if (items.length === 0) {
      return;
    }

    const item = items[0];
    this.isUpload = true;
    const blob = item.getAsFile();
    this.uploadFileDirective(blob);

    event.preventDefault();
  }

  onFileDropped($event) {
    for (let i = 0; i < $event.length; i++) {
      this.isUpload = true;
      this.dropFileToEdit($event[i]);
    }
  }

  deleteFile(index: number) {
    this.images.splice(index, 1);
  }

  dropFileToEdit(data): void {
    const $this = this;
    try {
      const node = document.createElement('IMG');
      this.uploadService.saveFile(data).subscribe(
        (resdata) => {
          const img = new Image();
          const src =
            this.configService.getConfig().media.baseUrl +
            'Media/VisitImage/' +
            resdata.fileName;

          img.addEventListener('load', function () {
            node.classList.add('note-img');
            if (this.naturalWidth > this.naturalHeight) {
              node.classList.add('note-img-hor');
            } else {
              node.classList.add('note-img-ver');
            }
            node.setAttribute('src', src);
            node.setAttribute('is', 'show-image');
            $this.chatForm.patchValue({
              content:
                ($this.chatForm.value.content
                  ? $this.chatForm.value.content
                  : '') + node.outerHTML,
            });
            $this.isUpload = false;
          });
          img.src = src;
        },
        (error) => {
          this.notificationService.showNotification('', error.statusText);
          this.isUpload = false;
        }
      );
    } catch (error) {
      console.error(error);
      this.isUpload = false;
    }
  }

  uploadFileToServer(data): boolean {
    const $this = this;
    try {
      const reader = new FileReader();
      reader.onloadend = async function (event) {
        $this.images.push(event.target.result);
      };
      reader.readAsDataURL(data);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  cancelSlideShow(): void {
    this.isVisibleListImage = false;
  }

  showSlideShow(): void {
    this.isVisibleListImage = true;
  }

  nextImage(index: number): void {
    this.currentImage(index + 1);
  }

  preImage(index: number): void {
    this.currentImage(index - 1);
  }

  currentImage(n: number): void {
    let index = this.slide.index;
    if (n > this.lstImages.length - 1) {
      index = 0;
    } else if (n < 0) {
      index = this.lstImages.length - 1;
    } else {
      index = n;
    }
    this.slide = {
      index,
      id: this.lstImages[index].id,
      url: this.lstImages[index].url,
    };
  }

  uploadFileDirective(data): void {
    const $this = this;

    try {
      const node = document.createElement('IMG');

      this.uploadService.saveFile(data).subscribe(
        (resdata) => {
          const img = new Image();
          const src =
            this.configService.getConfig().media.baseUrl +
            '/VisitImage/' +
            resdata.fileName;
          img.addEventListener('load', function () {
            if (this.naturalWidth > this.naturalHeight) {
              node.classList.add('note-img-hor');
            } else {
              node.classList.add('note-img-ver');
            }

            node.setAttribute('src', src);
            node.setAttribute('is', 'show-image');

            const selection = window.getSelection();
            if (!selection.rangeCount) {
              return false;
            }
            selection.deleteFromDocument();
            selection.getRangeAt(0).insertNode(node);
            $this.isUpload = false;
          });
          img.src = src;
        },
        (error) => {
          this.notificationService.showNotification('', error.statusText);
          this.isUpload = false;
        }
      );
    } catch (error) {
      console.error(error);
      this.isUpload = false;
    }
  }

  openVisit(id): void {
    if (this.visit.id != id) {
      this.addTab(id);
    }
  }

  addTab(id) {
    this.tabDataService.updateTab(
      id,
      this.visit.patientName,
      'VisitDetail',
      this.visit.patientId
    );
  }

  showEnlarge(chat) {
    chat.showEnlarge = true;
  }

  setSize(height) {
    $('app-masternotes-tab .chat-body').height(height - 115);
  }
}

// class ShowImage extends HTMLImageElement {
//   constructor(private store: Store<AppState>) {
//     // Always call super first in constructor
//     super();
//     this.onclick = function () {
//       showImage($(this).attr('src'));
//     };
//   }
// }

// customElements.define('show-image', ShowImage, { extends: 'img' });

// function showImage(src) {
//   let wrapper = document.createElement('div');
//   let close = document.createElement('div');
//   close.classList.add('close-btn');
//   let btnClose = document.createTextNode('X');
//   close.appendChild(btnClose);
//   wrapper.appendChild(close);
//   close.onclick = function () {
//     document.getElementById('slide-show').removeChild(wrapper);
//   };
//   wrapper.classList.add('gallery');
//   let img = document.createElement('img');
//   img.setAttribute('src', src);
//   wrapper.appendChild(img);
//   document.getElementById('slide-show').appendChild(wrapper);
// }
