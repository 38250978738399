import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuItemTree } from '../models/grouptree.model';
import { HttpClient } from '@angular/common/http';
import { Constant } from '../share/constants/constant.class';
import { AppConfigService } from '../../app-config.service';
import { UrlConstant } from '../share/constants/url.class';
import { BaseService } from '../share/base-service/base-service.service';
@Injectable()
export class GroupTreeService extends BaseService {
  resolveIems(isEdit): Observable<any> {
    // let token = 'Bearer ' + localStorage.getItem(Constant.TOKEN);
    // const headers = {
    //   'Authorization': 'Bearer ' + localStorage.getItem(Constant.TOKEN),
    // };
    const payload = { isEdit };

    return this.post(UrlConstant.LIST_TREE_MENU + '/' + isEdit, isEdit);
  }
  addGroup(payload): Observable<any> {
    // let token = 'Bearer ' + localStorage.getItem(Constant.TOKEN);
    // const headers = {
    //   'Authorization': 'Bearer ' + localStorage.getItem(Constant.TOKEN),
    // };
    return this.post(UrlConstant.ADD_GROUP, payload);
  }
  updateGroup(payload): Observable<any> {
    // let token = 'Bearer ' + localStorage.getItem(Constant.TOKEN);
    // const headers = {
    //   'Authorization': 'Bearer ' + localStorage.getItem(Constant.TOKEN),
    // };
    return this.post(UrlConstant.UPDATE_GROUP, payload);
  }

  getGroup(groupId): Observable<any> {
    return this.get(UrlConstant.GET_GROUP + '/' + groupId);
  }
  getGroupInfo(groupId): Observable<any> {
    return this.get(UrlConstant.GET_GROUP_INFO + '/' + groupId);
  }
  getCurrentGroup(): Observable<any> {
    return this.get(UrlConstant.GET_CURR_GROUP);
  }
  getListGroup(name): Observable<any> {
    return this.get(UrlConstant.LIST_GROUP + '?name=' + name);
  }
  updatePrintHeader(payload): Observable<any> {
    return this.post(UrlConstant.UPDATE_PRINT_HEADER, payload);
  }
  saveAliasGroupName(payload): Observable<any> {
    return this.post(UrlConstant.EDIT_ALIAS_GROUP_NAME, payload);
  }
  getPrintHeader(groupId, type): Observable<any> {
    return this.get(UrlConstant.GET_PRINT_HEADER + groupId, { type });
  }

  // inviteRoom(payload): Observable<any> {
  //   // let token = 'Bearer ' + localStorage.getItem(Constant.TOKEN);
  //   // const headers = {
  //   //   'Authorization': 'Bearer ' + localStorage.getItem(Constant.TOKEN),
  //   // };
  //   return this.post(UrlConstant.INVITE_ROOM, payload);
  // }
  saveToken(token): Observable<any> {
    return this.post(UrlConstant.SAVE_FCM_TOKEN, '', { token });
  }
}
