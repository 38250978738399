import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { CommonInfo } from 'src/app/models/form-model/form.class';
import { ObservationTypeService } from 'src/app/services/obserType-service';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-commonInfo-control',
  templateUrl: './commonInfo-control.component.html',
  styleUrls: ['./commonInfo-control.component.scss'],
})
export class CommonInfoControlComponent implements OnInit {
  _element: any;
  _commonInfoId: string;
  _referId: string;
  search_KW_OT = '';
  isSpinningSearchOT = true;

  @Input() isVisible: boolean;
  @Input() adminPage: boolean;
  @Input() groupId: string;
  @Input() commonInfoItems: any = [];

  @Input() set element(value: any) {
    if (value) {
      console.log(value);
      this._element = value;
      this._commonInfoId = value.id;
      this._referId = value.referId;
      this.getListObservationType();
    }
  }

  @Output() updateElement: EventEmitter<any> = new EventEmitter();

  get element(): any {
    return this._element;
  }

  lstObservationType: any = [];
  callbackGetlistOTFn: any;

  constructor(
    private OTService: ObservationTypeService,
    private shareService: ShareService
  ) {}

  ngOnInit() {
    this.callbackGetlistOTFn = this.shareService.debounce(
      this.getListObservationType,
      500
    );
  }

  search_KW_OTChange(val): void {
    console.log(val);
    this.isSpinningSearchOT = true;
    this.search_KW_OT = val;
    this.callbackGetlistOTFn();
  }

  getListObservationType(): void {
    let payload = {
      search: this.search_KW_OT,
      category: -1,
      dataType: this.element.dataType != null ? this.element.dataType : 5,
      groupId: '',
    };
    if (this.adminPage) {
    } else {
      payload = {
        search: this.search_KW_OT,
        category: -1,
        dataType: 0,
        groupId: this.groupId,
      };
    }
    this.OTService.getObservationTypeForForm(payload).subscribe((response) => {
      this.lstObservationType = response;
      this.isSpinningSearchOT = false;
    });
  }

  commonInfoChange() {
    const cur = this.commonInfoItems.filter((item) => item.id == this._commonInfoId)[0];

    if (cur && this.element && this.isVisible) {
      this.element = cur;
      this.updateElement.emit(cur);
    }
  }

  updateReferId() {
    this.element.referId = this._referId;
  }
}
