import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';

@Component({
  selector: 'app-form-controller',
  templateUrl: './form-controller.component.html',
  styleUrls: ['./form-controller.component.scss'],
})
export class FormControllerComponent implements OnInit, AfterViewInit {
  id = 'new';
  isVisible = true;

  constructor(
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this._Activatedroute.paramMap.subscribe((params) => {
      // this.ngOnInit();
      // if(this._childRef){
      //   this._childRef.ngOnInit();
      // }
      // this.isVisible = false;
      this.id = params.get('idPage');
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {}
}
