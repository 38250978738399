import { Patient } from 'src/app/models/patient.class';
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export enum ControlAction {
  ReloadCancelOrder = '[ReloadCancelOrder] ReloadCancelOrder',
}

export class ReloadCancelOrder implements Action {
  constructor(public newState: boolean, public visitId: string) {}
  public readonly type = ControlAction.ReloadCancelOrder;
}

export type ControlActions = ReloadCancelOrder;
