import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
export class UrlConstant {
  public static readonly LOGIN = '/oauth/token';
  public static readonly LOGOUT = '/logout';
  public static readonly VALIDATE = '/validate';
  public static readonly LIST_USER = '/users/filter';
  public static readonly DETAIL_USER = '/users';
  public static readonly DELETE_USER = '/users/delete/';
  public static readonly ADD_USER = '/users/add';
  public static readonly UPDATE_USER = '/users/update';
  public static readonly LIST_ROLE = '/role';
  public static readonly SAVE_FCM_TOKEN = '/api/Doctor/SaveFcmToken';
  public static readonly ADD_GROUP = '/api/Group/AddGroup';
  public static readonly UPDATE_GROUP = '/api/Group/UpdateGroup';
  public static readonly GET_GROUP_MEMBER = '/api/GroupMember/GetMember';
  public static readonly GET_MY_GROUP_MEMBER = '/api/GroupMember/GetMyGroupMember';
  public static readonly GET_GROUP_MEMBER_COUNT = '/api/GroupMember/MemberCount';
  public static readonly GET_CONTROLLABLE_GROUP = '/api/GroupMember/getControllableGroup';
  public static readonly ADD_USER_TO_GROUP_MEMBER = '/api/GroupMember/AddUser';
  public static readonly CHECK_GROUP_AUTHEN = '/api/Group/checkAuthen/';
  public static readonly EXPORT_LIST_DOCTOR = '/api/Group/ExportListDoctor/';
  public static readonly EXPORT_PRESCRIPTION_GROUP = '/ExportApprovedPrescription/';
  public static readonly INVITE_GET = '/api/Group/Invitation';
  public static readonly INVITE_USER = '/api/Group/InviteUser';
  public static readonly INVITE_MULTI_USER = '/api/Group/InviteMultiUser';
  public static readonly JOIN_GROUP = '/api/Group/JoinGroup';
  public static readonly REJECT_JOIN_GROUP = '/api/Group/RejectGroup';
  public static readonly CHANGE_OWNER_GROUP = '/api/Group/ChangeOwner';
  public static readonly REMOVE_MEMBER_GROUP = '/api/Group/RemoveMember';
  public static readonly REMOVE_ADMIN_GROUP = '/api/Group/RemoveAdmin';
  public static readonly SET_ADMIN_GROUP = '/api/Group/SetAdmin';
  public static readonly GET_GROUP = '/api/Group/Get';
  public static readonly GET_GROUP_INFO = '/api/Group/GetInfo';
  public static readonly GET_CURR_GROUP = '/api/Group/GetCurrentGroup';
  public static readonly SET_CUR_GROUP = '/api/Group/SetCurrentGroup';
  public static readonly LIST_GROUP = '/api/group/GetListGroup';
  public static readonly DOCTOR_PERMISSION = '/api/Group/SetDoctorPermisson';
  public static readonly UPDATE_PRINT_HEADER = '/api/Group/UpdatePrintFormHeader';
  public static readonly GET_PRINT_HEADER = '/api/Group/GetPrintFormHeader/';
  // public static readonly INVITE_ROOM = '/api/Group/InviteDoctorToRoom';
  public static readonly LIST_ACTION = '/action';
  public static readonly LIST_MENU = '/menu';
  public static readonly LIST_TREE_MENU = '/api/Doctor/GetListMenuCategory';
  public static readonly SAVE_GROUP_CATEGORY = '/api/Doctor/Savefolder';
  //public static readonly SAVE_CURRENT_GROUP_ID = '/api/Doctor/UpdateCurrentGroupId';
  public static readonly DELETE_GROUP_CATEGORY = '/api/Doctor/DeleteFolder';
  public static readonly CLOSE_GROUP = '/api/Doctor/CloseGroup/';
  public static readonly REOPEN_GROUP = '/api/Doctor/ReOpenGroup/';
  public static readonly GET_COVID_GROUP = '/api/Group/CovidGroup';
  public static readonly MOVE_GROUP_TO_CATEGORY = '/api/Doctor/MoveGroup';
  public static readonly EDIT_ALIAS_GROUP_NAME = '/api/Doctor/EditAliasGroupName';
  public static readonly LIST_VISIT_HIS = '/api/Visit/ListVisitOfPatientByUser';
  public static readonly LIST_VISIT_HIS_FAST = '/api/Visit/ListVisitOfPatientByUserFast';
  public static readonly LIST_VISIT = '/api/Visit/GetListVisit';
  public static readonly LIST_VISIT_SMALL = '/api/Visit/GetListVisitSmall';
  public static readonly VISIT = '/api/Visit';
  public static readonly ExaminationService = '/api/ExaminationService/Search';
  public static readonly VISIT_RECEIVE = '/api/VisitReceive';
  public static readonly GET_VISIT_SHARE_LINK = '/api/Visit/GetInfoByShareLink';
  public static readonly SUB_CLINICAL_VISIT = '/api/SubClinicalVisit';
  public static readonly SUB_CLINICAL_RESULT = '/api/Visit/GetSubClinicalResult';
  public static readonly SEARCH_SUB_CLINICAL_ORDER = '/api/SubClinicalOrder/search';
  public static readonly OBSERVATION = '/api/Visit/GetObservationOfVisit';
  public static readonly OBSERVATION_VS = '/api/Visit/GetObservationVSOfVisit';
  public static readonly OBSERVATION_TYPE = '/api/ObservationType/GetList';
  public static readonly OBSERVATION_TYPE_BY_ROLE = '/api/ObservationType/GetListByRole';
  public static readonly OBSERVATION_TYPE_BY_GROUP = '/api/ObservationType/GetByGroup';
  public static readonly OBSERVATION_TYPE_BY_ROLE_MANAGER = '/api/ObservationType/GetListByRoleManager';
  public static readonly OBSERVATION_TYPE_ORDER = '/api/ObservationType/GetListOTOrderByGroupId';
  public static readonly OBSERVATION_TYPE_FOR_FORM = '/api/ObservationType/GetListForForm';
  public static readonly SAVE_OBSERVATION_TYPE = '/api/ObservationType';
  public static readonly SAVE_LIST_OBSERVATION_VS = '/api/Visit/SaveListObservationVS';
  public static readonly SAVE_LIST_OBSERVATION_VS_STATUS = '/api/Visit/SaveListObservationAndOrderStatus';
  public static readonly SAVE_LIST_OBSERVATION = '/api/Visit/SaveListObservation';
  public static readonly SAVE_OBSERVATION = '/api/Visit/SaveObservation';
  public static readonly DELETE_OBSERVATION_TYPE = '/api/ObservationType';
  public static readonly EXCEL_OBSERVATION_TYPE = '/api/ObservationType/ReadExcelObservationType';
  public static readonly INSERT_OBSERVATION_TYPE = '/api/ObservationType/InsertMany';
  public static readonly API_OBSERVATION_TYPE = '/api/ObservationType';
  public static readonly VISIT_SAVE_DISEASE = '/api/Visit/SaveDisease';
  public static readonly VISIT_REMOVE_DISEASE = '/api/Visit/RemoveDisease';
  public static readonly DISEASE_DATA = '/api/Disease/';
  public static readonly LIST_FORM = '/api/DPForm/GetListForm';
  public static readonly LIST_FORM_FOR_GROUP = '/api/DPForm/GetListFormForGroup';
  public static readonly LIST_PARAMETER_FOR_GROUP = '/api/CommonInfo';
  public static readonly LIST_FIELD = '/CDSRule';
  public static readonly LIST_EDITABLE_FORM = '/api/DPForm/GetListEditableForm';
  public static readonly LIST_FORM_CONFIG_GROUP = '/api/DPForm/GetListFormForConfigGroup';
  public static readonly DPFFORM = '/api/DPForm/';
  public static readonly LIST_FORM_BY_ROSCATE = '/api/DPForm/GetListByROSCate';
  public static readonly CREATE_FORM = '/api/DPForm/addForm';
  public static readonly EXPORT_FORM = '/api/DPForm/ExportForm/';
  public static readonly IMPORT_FORM = '/api/DPForm/ImportForm';
  public static readonly GETFORM_TO_EDIT = '/api/DPForm/getForm/';
  public static readonly GETFORM_TO_UPDATE = '​/api/DPForm/getFormToEdit/';
  public static readonly ROS = '/api/ROS/GetAll';
  public static readonly ROTATE_VISIT_IMAGE = '/api/Visit/RotateVisitImage';
  public static readonly UPLOAD_FILE = '/api/Upload/uploadFile';

  public static readonly ROS_CATEGORY = '/api/ROSCategory/';
  public static readonly SUB_CLINICAL_ORDER = '/api/SubClinicalOrder/';
  public static readonly INSERT_MANY_SUBCLINICAL = '/api/SubClinicalOrder/InsertMany/';

  public static readonly EDIT_VISIT_IMAGE = '/api/Visit/EditVisitImage';
  public static readonly EDIT_VISIT_PDF = '/api/Visit/EditVisitPdf';
  public static readonly ADD_IMAGES_VISIT = '/api/Visit/InsertVisitImage';
  public static readonly REMOVE_IMAGES_VISIT = '/api/Visit/RemoveVisitImage';
  public static readonly EDIT_IMAGES_VISIT = '/api/Visit/EditVisitImage';
  public static readonly GET_VISIT_IMAGE = '/api/Visit/GetVisitImage';

  public static readonly ADD_PDFS_VISIT = '/api/Visit/InsertVisitPdf';
  public static readonly DELETE_PDF_VISIT = '/api/Visit/RemoveVisitPdf';

  public static readonly ADD_DOCUMENTS_VISIT = '/api/Visit/AddVisitDocument';
  public static readonly DELETE_DOCUMENTS_VISIT = '/api/Visit/RemoveVisitDocument';

  public static readonly RECORD_VISIT = '/api/RecordVisit';
  public static readonly RECORD_VISIT_EDIT = '/api/RecordVisit/GetToEdit';
  public static readonly GET_VISIT_SUMMARY = '/api/RecordVisit/GetVisitSummary';
  public static readonly RECORD_SAVE = '/api/RecordVisit/saveRecord';
  public static readonly RECORD_PRINT = '/api/RecordVisit/PrintReport';
  public static readonly RECORD_EXPORT = '/api/RecordVisit/ExportPDFReport';
  public static readonly MEDICAL_HISTORY = '/api/Patient/UpdateMedicalHistory';
  public static readonly VACCINE_HISTORY = '/api/Patient/UpdateVaccineHistory';
  public static readonly REASON_VISIT = '/api/Visit/UpdateVisitReason';
  public static readonly UPDATE_VISIT_ROOM = '/api/Visit/UpdateVisitRoom';
  public static readonly VISIT_STATUS = '/api/Visit/UpdateVisitStatus';
  public static readonly FINISH_VISIT = '/api/Visit/UpdateExaminedStatus';
  public static readonly CLINICAL_INFO = '/api/Visit/UpdateVisitClinicalInfo';
  public static readonly CONCLUSION = '/api/Visit/UpdateVisitConclusion';
  public static readonly UpdateVisitDiagnoseInit = '/api/Visit/UpdateVisitDiagnoseInit';
  public static readonly TAGS = '/api/Visit/UpdateVisitTag';
  public static readonly VISITINFO_CORE = '/api/Visit/UpdateVisitInfoCore';
  public static readonly IS_TEST = '/api/Visit/IsTest';
  public static readonly DELETE_VISIT = '/api/Visit/DeleteVisit/';
  public static readonly DELETE_VISIT_RELATED = '/api/Visit/DeleteVisitRelated/';
  public static readonly TOTAL_TAB = '/api/Visit/GetTotalElementOfTab';
  public static readonly VISIT_STATUS_2HIS = '/api/Visit/PushVisitStatus2HIS';
  public static readonly VISIT_ROOM_2HIS = '/api/Visit/PushVisitRoom2HIS';
  public static readonly RECORD_VISIT_DELETE = '/api/RecordVisit/';

  public static readonly GET_PRESCRIPTION = '/api/Prescription/Get/';
  public static readonly GET_PRESCRIPTION_BY_VISIT = '/api/Prescription/GetByVisit/';
  public static readonly GET_LIST_PRESCRIPTION = '/api/Prescription/GetList/';
  public static readonly ADD_PRESCRIPTION = '/api/Prescription/add';
  public static readonly ADD_MEDICATION_PRESCRIPTION = '/api/Prescription/AddMedicationRequest';
  public static readonly EXPORT_MEDICATION_PRESCRIPTION = '/api/Prescription/Export/';
  public static readonly UPDATE_PRESCRIPTION = '/api/Prescription/UpdateMedicationRequest';
  public static readonly DELETE_PRESCRIPTION = '/api/Prescription/RemovePrescription/';
  public static readonly EXPORT_PRESCRIPTION = '/api/Prescription/ExportPdf';
  public static readonly SHARE_PRESCRIPTION = '/api/Prescription/SharePrescription/';
  public static readonly SHARELINK_PRESCRIPTION = '/api/Prescription/LinkSharePrescription/';
  public static readonly SAVE_PRESCRIPTION = '/api/Prescription/save';
  public static readonly SAVE_PRESCRIPTION_GENERAL_INFO = '/api/Prescription/saveGeneralInfo';
  public static readonly GET_PRESCRIPTION_SAMPLE = '/api/Prescription/GetListPrescriptionSample/';
  public static readonly CREATE_PRESCRIPTION_SAMPLE = '/api/Prescription/CreatePrescriptionSample/';
  public static readonly SAVE_PRESCRIPTION_SAMPLE = '/api/Prescription/SavePrescriptionSample/';
  public static readonly ADD_FROM_SAMPLE = '/api/Prescription/AddFromSample/';
  public static readonly REMOVE_PRESCRIPTION_SAMPLE = '/api/Prescription/RemovePrescriptionSample/';
  public static readonly APPROVE_PRESCRIPTION = '/api/Prescription/approve';
  public static readonly QUERYING_PRESCRIPTION = '/api/Prescription/query';
  public static readonly DENY_PRESCRIPTION = '/api/Prescription/deny';
  public static readonly UNAPPROVE_PRESCRIPTION = '/api/Prescription/unapprove';

  public static readonly MEDICATION = '/api/Medication/';
  public static readonly CHECK_MEDICATION = '/api/Medication/CheckMedication/';
  public static readonly IMPORT_MEDICATION = '/api/Medication/ReadDanhMucThuoc';
  public static readonly EXPORT_MEDICATION = '/api/Medication/ExportData';

  public static readonly SHARE_VISIT = '/api/Doctor/ShareLinkVisit';
  public static readonly SHARE_VISIT_DELETE = '/api/Doctor/DeleteLinkShare/';
  public static readonly SHARE_VISIT_ON_OFF = '/api/Doctor/OnOffShareLink/';
  public static readonly SHARE_VISIT_INFO = '/api/Doctor/ShareLinkVisitInfo/';
  public static readonly SHARE_LINK_ID = '/api/Doctor/ShareLinkId/';
  public static readonly SHARE_LINK_GET_TOKEN = '/api/Logins/getShareLink/';
  public static readonly SHARE_VISIT_GROUP = '/api/Doctor/ShareGroupVisit';
  public static readonly GET_SHARE_VISIT_GROUP = '/api/Doctor/GetShareGroupVisit/';
  public static readonly CREATE_USER_ACCOUNT = '/api/Doctor/CreateUserByPatient';
  public static readonly QUICK_CREATE_USER_ACCOUNT = '/api/Doctor/QuickCreateUserByPatient';
  public static readonly GET_LIST_DOCTORRANK = '/DoctorRank/GetListDoctorRank';

  /**
   * @desc PatientApi URL
   */
  public static readonly PATIENT = '/api/Patient/';
  public static readonly DUPLICATE_PATIENT = '/api/Patient/Duplicates';
  public static readonly PATIENT_AUTO_GENERATE_INFO = '/api/Doctor/ShowAutoAccountInfo/';
  public static readonly PATIENT_USERNAME = '/api/Doctor/ShowAccountInfo/';

  /**
   * @desc GroupApi URL
   */
  public static readonly DOCTOR_IN_GROUP = '/api/Group/GetListDoctor/';
  public static readonly GROUP_OF_DOCTOR = '/api/Doctor/GetListMyGroup';
  public static readonly COVID_GROUP_OF_DOCTOR = '/api/Doctor/GetMyCovidGroups';

  /**
   * @description Category Api
   */
  public static readonly SAVE_CATEGORY = '/api/News/category/save';
  public static readonly GET_CATEGORY_TREE = '/api/News/category';

  /**
   * @description News Api
   */
  public static readonly SAVE_NEWS = '/api/News/save';
  public static readonly GET_NEWS_BY_URL = '/api/News/GetByUrl';
  public static readonly GET_NEWS = '/api/News/Get';

  /**
   * @description GetCommonInfo
   */
  public static readonly COMMON_INFO = '/api/CommonInfo';

  /**
   * @description GroupUser API
   */
  public static readonly GROUP_USER = '/GroupUser';
  public static readonly GROUP_ALL_USER = '/GroupUser/GetAllUser';
  public static readonly ADD_USER_2_GROUP_USER = '/GroupUser/AddUser2Group/';
  public static readonly REMOVE_USER_2_GROUP_USER = '/GroupUser/RemoveUserFromGroup/';
  public static readonly UPDATE_ROLE_GROUP_USER = '/GroupUser/UpdateGroupRoles';
  public static readonly GET_USER_IN_GROUP = '/GroupUser/GetUserInGroup';
  public static readonly RESET_PATIENT_PASSWORD = '/GroupUser/ResetPassword';

  /**
   * @description */
  public static readonly ROLE = '/Role';

  /**
   *@description FormAPI
   */

  /**
   * @description Chat Service
   */
  public static readonly SAVE_VISIT_CHAT = '/api/Conversation/SendMessageToVisit';
  public static readonly SAVE_PRIVATE_CHAT = '/api/Conversation/SendMessagePrivate';
  public static readonly LOAD_CHAT = '/api/Conversation/GetMessageChatByChannel';
  public static readonly GET_VISIT_CONVERSATION = '/api/Conversation/GetChannelChatByVisit';
  public static readonly GET_PRIVATE_CONVERSATION = '/api/Conversation/GetChannelChatPrivate';

  /**
   *@description Media server
   */

  public static readonly MEDIA_SERVER = 'https://mediatest.pmr.vn/';
  public static readonly UPLOAD_VISIT_IMAGE = '/api/Upload/uploadFile?TypeUpload=upload_visit_image';
  public static readonly UPLOAD_MEDIA_FILE = '/api/Upload/upMediaFile';

  /**
   *@description Medical Appointment
   */
  public static readonly LIST_MEDICAL = '/MedicalAppointment/GetListMedicalAppointmentByGroup';
  public static readonly MEDICAL = '/MedicalAppointment/GetMedicalAppointmentById';
  public static readonly UPDATE_APPOINTMENT = '/MedicalAppointment/UpdateMedicalAppointment';
  public static readonly ADD_APPOINTMENT = '/MedicalAppointment/AddMedicalAppointment';
  public static readonly ADD_VISIT_TO_APPOINTMENT = '/MedicalAppointment/AddVisit';

  /**
   *@description Authen Group
   */
  public static readonly AUTHEN_GROUP = '/auth/group';

  /**
   * @description SYSTEM LISTS
   */
  public static readonly SYSTEM_CODE = '/api/SystemCode';
/**
   * @description SYSTEM LISTS
   */
public static readonly Modality = '/api/Modality';
  /**
   * @description User Profile
   */
  public static readonly USER_PROFILE = '/api/User/GetInfo';
  public static readonly UPDATEINFO = '/api/User/UpdateInfo';
  public static readonly CHANGEAVATAR = '/api/User/ChangeAvatar?Avatar=';
  public static readonly UPDATE_USER_ROOM = '/api/User/UpdateUserRoom?roomId=';
  public static readonly USER_GROUP = '/api/User/GetUserGroupMember';
  public static readonly MEDICAL_LOG = '/api/User/GetMedicalLog';
  public static readonly DOCTOR_RANK = '/DoctorRank/GetListDoctorRank';
  public static readonly CHANGEPASS = '/changepassword';
  /**
   * @description Log Api
   */
  public static readonly GET_LOG = '/api/Visit/GetVisitLog/';

  public static readonly GET_CALL_ROOM = '/api/VideoConference';
  public static readonly GET_ROOM_INFO = '/api/VideoConference/GetVideoRoomInfo/';
  public static readonly JOIN_ROOM = '/api/VideoConference/AcceptToRoom/';
  public static readonly VIEW_NOTIFI = '/api/Notification/ViewNotifi';
  public static readonly COUNT_NEW_NOTIFI = '/api/Notification/CountNewNotifi';
  public static readonly NOTIFI_READ_ALL = '/api/Notification/ReadAll';
  public static readonly CONFIRM_NOTI_INVITE_GROUP = '/api/Notification/ConfirmInviteGroup';
  public static readonly GET_TOP_NOTIFI = '/api/Notification/GetTopNotifi';
  public static readonly GET_ALL_BY_TYPE_NOTIFI = '/api/Notification/GetAllNotificationByType';
  public static readonly INVITE_MORE_USER = '/api/Videoconference/InviteMoreUser';

  public static readonly MAIN_GRID_COULUMN = '/UserSetting/MainGrid';
  public static readonly PATIENT_GRID_COULUMN = '/UserSetting/PatientGrid';
  public static readonly PATIENT_GRID_DEFAULT_COULUMN = '/UserSetting/PatientGridDefault';
  public static readonly APPOINTMENT_GRID_COULUMN = '/UserSetting/AppointmentGrid';
  public static readonly COVID_RECEIVE_GRID_COULUMN = '/UserSetting/CovidReceiveGrid';

  public static readonly LIST_TREATMENT_PATIENT = '/api/Visit/GetListTreatmentPatientGroup';
  public static readonly UPDATE_COVID_NUMBER = '/api/Visit/UpdateCovidNumber';
  public static readonly EXPORT_TREATMENT_PATIENT = '/api/Visit/GetListTreatmentPatientGroupExcel';
  public static readonly EDIT_STATUS_PATIENT = '/api/Visit/UpdateStatusTreatmentPatientGroup';

  /**
   * @description Family Member
   */
  public static readonly PATIENT_FAMILY_MEMBER = '/api/PatientFamily/GetListMember/';
  public static readonly PATIENT_FAMILY_MEMBER_CREATE = '/api/PatientFamily/SaveMember';
  public static readonly PATIENT_FAMILY_MEMBER_DELETE = '/api/PatientFamily/DeleteMember/';
  public static readonly RESET_PASSWORD = '/api/Doctor/ActiveAndSetPassForPatient';

  /**
   * @description PatientRoom
   */
  public static readonly PATIENT_ROOM_ADD = '/api/RoomPatient/SaveRoom';
  public static readonly PATIENT_ROOM_GETALL = '/api/RoomPatient/GetListRoom/';
  public static readonly PATIENT_ROOM_GET = '/api/RoomPatient/GetById/';
  public static readonly PATIENT_ROOM_ADD_PATIENT = '/api/RoomPatient/AddPatientToRoom';
  public static readonly PATIENT_ROOM_ROMOVE_PATIENT = '/api/RoomPatient/RemovePatientInRoom';
  public static readonly PATIENT_ROOM_MOVE_PATIENT = '/api/RoomPatient/MovePatientToRoom';
  public static readonly PATIENT_ROOM_ADD_DOCTOR = '/api/RoomPatient/AddDoctorToRoom';
  public static readonly PATIENT_ROOM_REMOVE_DOCTOR = '/api/RoomPatient/RemoveDoctorInRoom';

  /**
   * @description Report
   */
  public static readonly REPORT_CURRENT_INFO = '/api/Report/CurrentInfo';
  public static readonly REPORT_CONTINUOUS_INFO = '/api/Report/ContinuousInfo';
  public static readonly REPORT_SOSRANGE = '/api/Report/SOSRangeInfo';
  public static readonly EXPORT_COVID_PATIENT = '/ExportCovidPatient/';

  /**
   * @description Treatment Process
   */
  public static readonly TREATMENT_PROCESS = '/api/TreatmentProcess';
  public static readonly TREATMENT_PATIENT = '/GetTreatmentProcessPatient';
  public static readonly APPLY_TREATMENT_PATIENT = '/ApplyTreatmentProcess';

  /**
   * @description Reminder
   */
  public static readonly REMINDER = '/Reminder';
  public static readonly REMINDER_DELETE = '/Reminder/';
  public static readonly REMINDER_GROUP = '/Reminder/GroupReminder';
  public static readonly REMINDER_SEARCH = '/Reminder/SearchReminder';

  /**
   * @description Webhook
   */
  public static readonly WEB_HOOK_GEN_KEY = '/api/GroupWebhook/GenSecretKey';
  public static readonly WEB_HOOK_SAVE = '/api/GroupWebhook/SaveGroupWebhook';
  public static readonly WEB_HOOK_DELETE = '/api/GroupWebhook/DeleteGroupWebhook/';
  public static readonly WEB_HOOK_GET = '/api/GroupWebhook/GetGroupWebhook/';

  /**
   * @description GROUP API TOKEN
   */
  public static readonly GROUP_API_TOKEN_GEN_KEY = '/api/GroupApiToken/GenApiToken';
  public static readonly GROUP_API_TOKEN_SAVE = '/api/GroupApiToken/SaveGroupApiToken';
  public static readonly GROUP_API_TOKEN_DELETE = '/api/GroupApiToken/DeleteGroupApiToken/';
  public static readonly GROUP_API_TOKEN_GET = '/api/GroupApiToken/GetGroupApiToken/';

  /**
   * @description Medical Instruction
   */
  public static readonly MEDICAL_INST_ADD = '/api/MedicalOrder/AddOrder';
  public static readonly MEDICAL_INST_UPDATE = '/api/MedicalOrder/UpdateOrder';
  public static readonly MEDICAL_INST_DELETE = '/api/MedicalOrder/DeleteOrder';
  public static readonly MEDICAL_INST_GET = '/api/MedicalOrder/GetFilter';
  public static readonly MEDICAL_INST_ADD_VISIT = '/api/MedicalOrder/AddMedicalOrder2Visit';
  public static readonly MEDICAL_INST_GET_VISIT = '/api/MedicalOrder/GetMedicalOrderByVisitId';
  public static readonly MEDICAL_INST_GET_GROUP = '/api/MedicalOrder/GetMedicalOrderByGroupId';
  public static readonly MEDICAL_INST_GET_PATIENT = '/api/MedicalOrder/GetMedicalOrderByPatientId';
  public static readonly MEDICAL_INST_UPDATE_STATUS_VISIT = '/api/MedicalOrder/UpdateMedicalOrder2Visit';
  public static readonly MEDICAL_INST_UPDATE_VISIT = '/api/MedicalOrder/UpdateInfoMedicalOrder2Visit';
  public static readonly MEDICAL_INST_GET_ALL_VISIT = '/api/MedicalOrder/GetListMedicalOrderVisitByGroupId';

  /**
   * @description Work Reminder Sample
   */
  public static readonly REMINDER_SAMPLE_SEARCH = '/WorkReminderSample/SearchWorkReminderSample';
  public static readonly REMINDER_SAMPLE_ADD = '/WorkReminderSample';
  public static readonly REMINDER_SAMPLE_UPDATE = '/WorkReminderSample';
  public static readonly REMINDER_SAMPLE_DELETE = '/WorkReminderSample/';

  /**
   * @description Waiting Page
   */
  public static readonly WAITING_PAGE_ADD_CONFIG = '/api/WaitingPage/AddConfig';
  public static readonly WAITING_PAGE_GET_CONFIG = '/api/WaitingPage/GetConfigByUser';
  public static readonly WAITING_PAGE_GET_VISIT = '/api/WaitingPage/GetListVisitWaiting';
  public static readonly WAITING_PAGE_UPDATE_CONFIG = '/api/WaitingPage/UpdateConfig';

  /**
   * @description SubClinicalOrder Group
   */

  public static readonly SUBCLINICALORDER_GROUP_SEARCH = '/SubClinicalOrderGroup/SearchSubClinicalOrderGroup';
  public static readonly SUBCLINICALORDER_GROUP_GET_LIST = '/SubClinicalOrderGroup/GetListSubClinicalOrderGroup';
  public static readonly SUBCLINICALORDER_GROUP_GET_BY_ID = '/SubClinicalOrderGroup';
  public static readonly SUBCLINICALORDER_GROUP_UPDATE = '/SubClinicalOrderGroup';
  public static readonly SUBCLINICALORDER_GROUP_ADD = '/SubClinicalOrderGroup';
  public static readonly SUBCLINICALORDER_GROUP_DELETE = '/SubClinicalOrderGroup/';

  public static readonly SUMMARY_REPORT = '/api/FormTemplate/SummaryReport';
  public static readonly TEMPLATE_LAB_RESULT = '/api/FormTemplate/GetLaboratoryResultReport';
  /**
   * @description Examination Service
   */
  public static readonly EXAMINATION_SERVICE_SAVE = '/api/ExaminationService';
  public static readonly EXAMINATION_SERVICE_GET_BY_ID = '/api/ExaminationService/';
  public static readonly EXAMINATION_SERVICE_DELETE = '/api/ExaminationService/';
  public static readonly EXAMINATION_SERVICE_SEARCH = '/api/ExaminationService/Search';
  public static readonly EXAMINATION_SERVICE_SEARCH_ALL = '/api/ExaminationService/All';
  /**
   * @description Common-Info  Service
   */
  public static readonly COMMONINFO_INST_GET = '/api/CommonInfo';
  public static readonly COMMONINFO_INST_POST = '/api/CommonInfo';
  public static readonly COMMONINFO_INST_PUT = '/api/CommonInfo';
  public static readonly COMMONINFO_INST_DELETE = '/api/CommonInfo';

  /**
   * @description Criteria  Service
   */
  // public static readonly  CDSRULE_SERVICE= '/CDSRule';
  public static readonly CRITERIA_SERVICE = '/CDSRule';
  public static readonly CRITERIA_SERVICE_GET = '/CDSRule/Criteria/GetParameters';
  /**
   * @description Common-Info  Service
   */
  public static readonly RULES_MANAGERMENT = '/CDSRule';
  public static readonly RULE_2_REMINDER = '/CDSRule/CreateReminder2CDSRule';
  public static readonly RULES_MANAGERMENT_DELETE = '/CDSRule/RemoveCriteria';
  public static readonly RULES_MANAGERMENT_REMOVE = '/CDSRule/UpdateStatus';
}
