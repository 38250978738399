import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';
import { Constant } from '../share/constants/constant.class';
import { DoctorInGroup } from '../models/doctor-in-group.model';

@Injectable({
  providedIn: 'root',
})
export class CovidService extends BaseService {
  getListReceive(filter: any): Observable<any> {
    return this.post('/api/ReceivingPatientCovid19/GetByFilter', filter);
  }
  updateInfo(data: any): Observable<any> {
    return this.post('/api/ReceivingPatientCovid19/Update', data);
  }
  toReceived(id, groupId, source): Observable<any> {
    return this.post(
      `/api/ReceivingPatientCovid19/Received/${id}/${groupId}/${source}`,
      null
    );
  }
  toDenied(id, lydo): Observable<any> {
    const reason = { id, reason: lydo };
    return this.post(`/api/ReceivingPatientCovid19/Denied`, reason);
  }
  addPatientCovid19ForDoctor(payload): Observable<any> {
    return this.post(
      `/api/ReceivingPatientCovid19/AddPatientCovid19ForDoctor`,
      payload
    );
  }
  verifyInfo(id: string, verified: boolean): Observable<any> {
    return this.post(
      '/api/ReceivingPatientCovid19/UpdateVerifiedStatus/' + id,
      '',
      { verified }
    );
  }
}
