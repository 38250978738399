import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';
import { Observable } from 'rxjs';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

@Injectable({
  providedIn: 'root',
})
export class MedicalAppointmentService extends BaseService {
  disabledDate = (curr: Date) => differenceInCalendarDays(curr, new Date()) < 0;
  getListMedicalAppointment(payload): Observable<any> {
    return this.post(UrlConstant.LIST_MEDICAL, payload);
  }
  getMedicalAppointmentById(id): Observable<any> {
    return this.get(UrlConstant.MEDICAL + '/' + id);
  }
  addAppointment(payload): Observable<any> {
    return this.post(UrlConstant.ADD_APPOINTMENT, payload);
  }
  updateAppointment(payload): Observable<any> {
    return this.post(UrlConstant.UPDATE_APPOINTMENT, payload);
  }
  // addVisitToAppointment(payload): Observable<any>{
  //   return this.post(UrlConstant.ADD_VISIT_TO_APPOINTMENT, payload);
  // }

  toStatusText(status) {
    switch (status) {
      case 1:
        return 'Chờ xác nhận';
      case 2:
        return 'Đã xác nhận';
      case 3:
        return 'Bệnh nhân đã đến';
      case 4:
        return 'Đã tạo ca khám';
      case 5:
        return 'BN hủy lịch hẹn';
      case 6:
        return 'BN không đến';
    }
  }
  toPriorityText(priority) {
    switch (priority) {
      case 3:
        return 'Bình thường';
      case 2:
        return 'Ưu tiên';
      case 1:
        return 'Vip';
    }
  }
  toGenderText(priority) {
    switch (priority) {
      case 0:
        return 'Nữ';
      case 1:
        return 'Nam';
      case 2:
        return 'Không xác định';
    }
  }
}
