import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/components/app-state/app-state';
import { DoctorInGroup } from 'src/app/models/doctor-in-group.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { ListReminderService } from 'src/app/services/ListReminder.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PatientService } from 'src/app/services/patient.service';
import { ShareService } from 'src/app/services/share.service';
import { WorkReminderSampleService } from 'src/app/services/workReminderSample.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-reminder-form',
  templateUrl: './reminder-form.component.html',
  styleUrls: ['./reminder-form.component.scss'],
})
export class ReminderFormComponent implements OnInit, OnChanges {
  @Input() isVisible = false;
  @Input() curReminder: any;
  @Input() visit = null;

  @Output() isVisibleChange = new EventEmitter<any>();
  @Output() refreshData = new EventEmitter<any>();

  curUserId = '';
  groupId = '';
  isShowReminderLog = false;
  currentReminderLog: any;
  doctorInGroup: DoctorInGroup[] = [];
  selectedPatient = null;
  isInvalidDate = false;
  isShowListSample = false;
  listMinutesBeforeReminder = [
    {
      label: '15 phút',
      value: 15,
    },
    {
      label: '30 phút',
      value: 30,
    },
    {
      label: '1 giờ',
      value: 60,
    },
    {
      label: '2 giờ',
      value: 120,
    },
    {
      label: '1 ngày',
      value: 1440,
    },
  ];
  lstWorkReminderSample = [];
  currentTime = new Date();
  disabledDateBeforeToday = this.patientService.disabledDateBeforeToday;
  lstSearchPatient = [];
  reminderForm: FormGroup;
  callbackSearchFn: any;
  callbackSearchSampleFn: any;

  constructor(
    private store: Store<AppState>,
    private workReminderSampleService: WorkReminderSampleService,
    private notificationService: NotificationService,
    private listReminderService: ListReminderService,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private formBuilder: FormBuilder,
    private shareService: ShareService
  ) {
    this.reminderForm = this.formBuilder.group({
      id: [null],
      doctorId: [null],
      patientName: [null],
      activeDate: [null, [Validators.required]],
      title: [null, [Validators.required]],
      detail: [null],
      status: 0,
      isForMe: false,
      minutesBeforeReminder: [0],
    });
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
    this.store
      .select((store) => store.groupAuth)
      .subscribe((data) => {
        if (data.isAuthenticated) {
          this.groupId = data.groupId;
          this.getDoctorInGroup(data.groupId);
        }
      });
    this.callbackSearchFn = this.shareService.debounce(
      this.onSearchPatient,
      500
    );
    this.callbackSearchSampleFn = this.shareService.debounce(
      this.onSearchSample,
      500
    );
  }

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    let chng = changes.curReminder;
    if (
      !this.shareService.checkEmpty(chng) &&
      !this.shareService.checkEmpty(chng.currentValue)
    ) {
      this.reminderForm.reset();
      this.currentReminderLog = [];

      let data = chng.currentValue;
      this.patchValue(data);
      this.getReminderLog();
    }

    let chngVisible = changes.isVisible;
    if (chngVisible != null && chngVisible.currentValue) {
      this.reminderForm.reset();
      this.currentReminderLog = [];

      let data = this.curReminder;
      if (!this.shareService.checkEmpty(data)) {
        this.patchValue(data);
        this.getReminderLog();
      }
    }
  }
  patchValue(data) {
    this.reminderForm.patchValue({
      id: data.id,
      doctorId: data.doctorId,
      patientName: data.patientName,
      activeDate: data.activeDate,
      title: data.title,
      detail: data.detail,
      status: data.status,
      isForMe: data.isForMe,
    });
    this.selectedPatient = {
      patientId: data.patientId,
      patientName: data.patientName,
      patientCode: data.patientCode,
      patientPhoneNo: data.patientPhoneNo,
      patientAddress: data.patientAddress,
    };
  }
  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;
      }
    });
  }
  onSearchSample() {
    let keyword = this.reminderForm.value.title;
    if (keyword != null && keyword != '') {
      keyword = keyword.trim();
      const payload = {
        title: keyword,
        groupId: this.groupId,
        pageSize: 20,
        page: 1,
      };
      this.workReminderSampleService.searchReminderSample(payload).subscribe(
        (res) => {
          if (res.source == null) {
            this.lstWorkReminderSample = [];
          } else {
            this.lstWorkReminderSample = res.source;
          }
        },
        (error) => {
          this.notificationService.showNotification(
            'error',
            'Không thể lấy dữ liệu'
          );
        }
      );
    }
  }
  selectSample(data) {
    this.reminderForm.controls['title'].setValue(data.title);
    this.reminderForm.controls['detail'].setValue(data.detail);
    this.reminderForm.controls['minutesBeforeReminder'].setValue(
      data.minutesBeforeReminder
    );
  }
  toggleShowReminderLog() {
    this.isShowReminderLog = !this.isShowReminderLog;
  }
  getReminderLog() {
    this.listReminderService
      .getReminderLog(this.reminderForm.value.id)
      .subscribe(
        (res) => {
          this.currentReminderLog = res;
        },
        (error) => {
          console.error('Get log reminder error');
        }
      );
  }
  save() {
    /// add from VisitDetail
    /// add from ListReminder => check require patient

    /// formValue check valid
    let formValue = this.reminderForm.value;
    formValue.minutesBeforeReminder = formValue.minutesBeforeReminder ?? 0;
    formValue.detail = formValue.detail ?? '';
    formValue.doctorId = formValue.doctorId ?? '';
    formValue.isForMe = formValue.isForMe ?? false;
    formValue.status = formValue.status ?? 0;

    if (
      !this.reminderForm.valid ||
      (formValue.id == '' && this.isInvalidDate) ||
      (!formValue.isForMe && formValue.doctorId == '')
    ) {
      this.notificationService.showNotification(
        Constant.ERROR,
        'Bạn cần nhập các trường bắt buộc!'
      );
      return;
    }
    if (this.visit == null && this.selectedPatient == null) {
      this.notificationService.showNotification(
        Constant.ERROR,
        'Không tồn tại bệnh nhân'
      );
      return;
    }

    let doctor = this.doctorInGroup.find((en) => en.id == formValue.doctorId);
    let payload = {
      ...formValue,
      groupId: this.groupId,
      doctorName: doctor?.fullName ?? '',
    };

    /// patient info
    if (this.visit != null) {
      let patient = {
        patientId: this.visit.patientId,
        patientName: this.visit.patientName,
        patientCode: this.visit.patientCode,
        patientPhoneNo: this.visit.phoneNo,
        patientAddress: this.visit.address,
      };
      payload = { ...payload, ...patient };
    } else {
      payload = { ...payload, ...this.selectedPatient };
    }

    if (this.shareService.checkEmpty(formValue.id)) {
      this.listReminderService.addReminder(payload).subscribe(
        (res) => {
          this.notificationService.showNotification('success', 'Thành công');
          this.isVisible = false;
          this.isVisibleChange.emit(false);
          this.refreshData.emit();
        },
        (error) => {
          this.notificationService.showNotification(
            'error',
            'Chưa nhập hết dữ liệu'
          );
        }
      );
    } else {
      this.listReminderService.updateReminder(payload).subscribe(
        (res) => {
          this.notificationService.showNotification('success', 'Thành công');
          this.isVisible = false;
          this.isVisibleChange.emit(false);
          this.refreshData.emit();
        },
        (error) => {
          this.notificationService.showNotification(
            'error',
            'Chưa nhập hết dữ liệu'
          );
        }
      );
    }
  }
  showListSample() {
    this.isShowListSample = true;
  }
  usingSample(data) {
    this.reminderForm.controls['title'].setValue(data.title);
    this.reminderForm.controls['detail'].setValue(data.detail);
    this.reminderForm.controls['minutesBeforeReminder'].setValue(
      data.minutesBeforeReminder
    );
  }
  onChange(date) {
    this.isInvalidDate =
      Date.parse(date) < Date.parse(this.currentTime.toString());
  }
  handleCancel(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(false);

    this.isShowReminderLog = false;
  }

  onSearchPatient() {
    let keyword = this.reminderForm.value.patientName;
    if (keyword != null && keyword != '') {
      keyword = keyword.trim();
      const payload = {
        textSearch: keyword,
        groupId: this.groupId,
      };
      this.patientService.searchAllPatientInGroup(payload).subscribe((res) => {
        this.lstSearchPatient = res ? res : [];
      });
    }
  }
  selectSearchPatient(id) {
    let data = this.lstSearchPatient.find((en) => en.id == id);
    if (data != null) {
      this.selectedPatient = {
        patientId: data.id,
        patientName: data.fullName,
        patientCode: data.code,
        patientPhoneNo: data.phoneNo,
        patientAddress: data.address,
      };
    }
  }
}
