<div class="notifi-title">
  <div>
    Bạn có <span>{{ numOfUnreadNotifi }}</span> thông báo chưa đọc
  </div>
  <!-- <a (click)="viewAllNoti()">Xem tất cả</a> -->
</div>
<ul
  class="sizeNews customTableCrollbar"
  data-scroll="true"
  infiniteScroll
  [infiniteScrollUpDistance]="2"
  [infiniteScrollThrottle]="50"
  [scrollWindow]="false"
  (scrolled)="onScrollDown()"
  style="overflow: auto; position: relative; height: calc(100vh - 90px)">
  <li class="notifi-item" *ngFor="let item of listNotification">
    <div class="user-avatar">
      <img
        *ngIf="item.avatar != ''; else other_content"
        src="{{ PathAvatar + '/Avatar/' + item.avatar }}"
        alt="" />
      <ng-template #other_content>
        <img src="assets/image/group.svg" alt="" />
      </ng-template>
    </div>
    <div
      class="item-content"
      *ngIf="item.type !== 3 && item.type !== 4"
      (click)="goFromNotify(item)"
      style="cursor: pointer">
      <div class="line-clamp" [innerHtml]="item.contentHtml"></div>
      <div class="time-style">{{ item.createdDate }}</div>
    </div>
    <div
      class="item-content"
      *ngIf="item.type === 3 || item.type === 4"
      style="cursor: pointer">
      <div class="line-clamp" [innerHtml]="item.contentHtml"></div>
      <div class="time-style">{{ item.createdDate }}</div>
      <div *ngIf="item.hasButton === 1">
        <button
          nz-button
          class="btn-x-small"
          style="margin-right: 20px"
          *ngIf="item.type === 3"
          (click)="acceptJoinGroup(item.id, false, null)"
          nz-button
          nzSize="small">
          Từ chối
        </button>
        <button
          nz-button
          class="btn-primary-small"
          *ngIf="item.type === 3"
          (click)="acceptJoinGroup(item.id, true, null)"
          nz-button
          nzSize="small">
          Đồng ý
        </button>
        <button
          nz-button
          class="btn-x-small"
          style="margin-right: 20px"
          *ngIf="item.type === 4"
          (click)="acceptJoin(item.id, false, null)"
          nz-button
          nzSize="small">
          Từ chối
        </button>
        <button
          nz-button
          class="btn-primary-small"
          *ngIf="item.type === 4"
          (click)="acceptJoin(item.id, true, null)"
          nz-button
          nzSize="small">
          Đồng ý
        </button>
      </div>
      <div *ngIf="item.hasButton === 0" [innerHtml]="item.actionHtml">
        <!-- <span>{{item.actionHtml}}</span> -->
      </div>
    </div>
    <div class="item-status">
      <div
        *ngIf="!item.viewed"
        (click)="readNotifi(item.id)"
        class="view unViewed"></div>
      <div *ngIf="item.viewed" class="view viewed"></div>
    </div>
  </li>
</ul>
