import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app-config.service';
import { AppState } from 'src/app/components/app-state/app-state';
import { LoginSuccess, Logout, SSOLogin, SSOLogout } from 'src/app/components/auth/redux/auth.action';
import { AuthModel } from 'src/app/models/auth.model';
import { AuthService } from 'src/app/services/auth.service';
import { Constant } from '../constants/constant.class';

@Injectable()
export class RoleGuardClass implements CanActivate, CanLoad {
  authUser: AuthModel = {};

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
    private configService: AppConfigService
  ) {
    this.store.select('auth').subscribe((res) => {
      this.authUser = res;
    });
  }

  canLoad(route: Route): Observable<boolean> | boolean | Promise<boolean> {
    return true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean | Promise<boolean> {
    const roles = route.data.roles;
    const type = route.data.type;

    return this.isAuthenticate(roles, type, state.url);
  }

  isAuthenticate(roles: number[], type: number, url?: string): Promise<boolean> {
    return new Promise((resolve) => {
      // if (this.authUser.isAuthenticated && currentUser) {
      //   const currentUserObj = JSON.parse(currentUser);
      //   if (this.authUser.value == currentUserObj.value) {
      //     resolve(true);
      //   } else {
      //     resolve(false);
      //   }
      // }

      // console.log(this.authUser);
      if (this.authUser.isAuthenticated) {
        resolve(true);
      } else {
        if (this.configService.getConfig().isUseSSOLogin) {
          // alert(this.authUser.isAuthenticated);
          const returnURL = window.location.origin + '/sso-login?returnUrl=' + url;
          this.store.dispatch(new SSOLogin(returnURL));
        } else {
          const currentToken = localStorage.getItem(Constant.TOKEN);
          if (currentToken) {
            this.authService.checkToken().subscribe(
              (res) => {
                if (res.ret != null && res.ret.code == 401) {
                  this.store.dispatch(new Logout(''));
                  resolve(false);
                } else {
                  localStorage.setItem(Constant.TOKEN, res.value);
                  localStorage.setItem(Constant.USER_INFO, JSON.stringify(res));
                  this.store.dispatch(new LoginSuccess(res));
                  resolve(true);
                }
              },
              () => {
                this.store.dispatch(new Logout(''));
                resolve(false);
              }
            );
          } else {
            this.store.dispatch(new Logout(''));
            resolve(false);
          }
        }
      }
    }).then(
      (data) =>
        new Promise((resolve, reject) => {
          const isAuthozire = this.isAuthozire(roles, type);
          resolve(isAuthozire);
        })
    );
  }

  isAuthozire(roles: number[], type: number): boolean {
    try {
      const currentUser = this.authUser;
      let isAuthorize = false;
      if (currentUser && currentUser !== Constant.TOKEN) {
        if (type == Constant.ROLETYPE.AND) {
          isAuthorize = roles.every((r) => currentUser.userType.indexOf(r) >= 0);
        } else {
          isAuthorize = roles.some((r) => currentUser.userType.indexOf(r) >= 0);
        }
      }
      if (!isAuthorize) {
        this.router.navigate(['/unauthorized']);
      } else {
        isAuthorize = true;
      }
      return isAuthorize;
    } catch (error) {
      return false;
    }
  }
}
