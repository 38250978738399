<div
  data-toggle="modal"
  data-target="#myModal"
  class="observation-info"
  (click)="selectOT()">
  <p>
    <span class="name">
      {{ type ? obt.name : obt.title }}
      <span *ngIf="type"
        >(<i>{{ obt.oTcode }}</i
        >)</span
      >
    </span>
  </p>
  <span class="type">Kiểu: {{ type ? obt.datatypeText : 'CommonInfo' }}</span>
  <i nz-icon nzType="plus" nzTheme="outline" style="float: right"></i>
</div>
