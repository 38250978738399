<div class="form-input" style="height: 100%">
  <div
    nz-input
    [ngClass]="[
      data.formatStyle.isBold == true ? 'bold' : '',
      data.formatStyle.isItalic == true ? 'italic' : '',
      data.formatStyle.isUnderline == true ? 'underline' : '',
      data.formatStyle.align
    ]"
    [ngStyle]="{
      'color': data.formatStyle.color,
      'background': data.formatStyle.bgColor,
      'font-size': data.formatStyle.fontSize + 'px',
      'font-family': data.formatStyle.font,
      'display': 'flex',
      'height': '100%',
      'align-items': 'center'
    }">
    <i nz-icon nzType="calculator" nzTheme="outline"></i>
    <span style="padding-left: 4px" class="text-mg-l">{{ value }}</span>
  </div>
</div>
<!-- <div class="form-input" *ngIf="data.editType == 4"> 
  <div nz-input [ngClass]="[data.formatStyle.isBold == true ? 'bold' : '', 
  data.formatStyle.isItalic == true ? 'italic' : '', 
  data.formatStyle.isUnderline == true ? 'underline' : '',  data.formatStyle.align]" [ngStyle]="{'color' : data.formatStyle.color,
                'background' : data.formatStyle.bgColor,
                'font-size' : data.formatStyle.fontSize + 'px',
                'font-family' : data.formatStyle.font}"><i nz-icon nzType="calculator" nzTheme="outline"></i> <span style="padding-left: 4px;">{{data.value}}</span></div>
</div> -->
