<ng-container *ngFor="let menu of menus">
  <li
    class="custom-menu-dropdown"
    nz-menu-item
    *ngIf="!(menu.children && menu.children.length > 0)"
    [nzSelected]="menu.id == selectedId">
    <ng-container *ngIf="menu.isLeaf">
      <a (click)="selectGroup(menu)">
        <i nz-icon>
          <img src="{{ menu.icon }}" class="image" />
        </i>
        <span *ngIf="menu.status === -1"
          ><i
            nz-icon
            nzType="lock"
            nzTheme="outline"
            class="lock-group-icon"></i
        ></span>
        <span title class="{{ menu.status === -1 ? 'lock-group' : '' }}">{{
          menu.name
        }}</span>
      </a>
    </ng-container>
    <ng-container *ngIf="!menu.isLeaf">
      <i nz-icon>
        <img
          src="{{ menu.icon }}"
          class="image"
          style="width: 38px; height: 38px" />
      </i>
      <span title>{{ menu.name }}</span>
    </ng-container>
  </li>
  <!-- <li class="custom-menu-dropdown" nz-submenu [nzMenuClassName]="custom-submenu-li" *ngIf="menu.children&&menu.children.length>0&&!menu.expanded" [nzTitle]="menu.name" nzIcon="mail" >
        
        <ul class="custom-menu-dropdown-ul">
            <app-recursive-menu [menus]="menu.children" [selectedId]="selectedId" (newItemEvent)="newItemEvent.emit($event)" ></app-recursive-menu>
        </ul>
      </li> -->
  <li
    class="custom-menu-dropdown"
    nz-submenu
    [nzMenuClassName]="'custom-submenu-li'"
    *ngIf="menu.children && menu.children.length > 0"
    [nzTitle]="menu.name"
    nzIcon="mail">
    <ul class="custom-menu-dropdown-ul thin-scroll">
      <app-recursive-menu
        [menus]="menu.children"
        [selectedId]="selectedId"
        (newItemEvent)="newItemEvent.emit($event)">
      </app-recursive-menu>
    </ul>
  </li>
</ng-container>
<!-- <ng-template #submenuicon><img src="assets/image/folder.svg" class="image" style="width: 35px; height: 35px;"></ng-template> -->
<!-- <i nz-icon ><img src="{{menu.icon}}" class="image" style="width: 35px; height: 35px;"></i>      isExpanAll   [nzOpen]="menu.expanded"-->
