<div class="conversation-wrapper {{ isMobile ? 'chat-mobile' : '' }}">
  <div class="doc-title">
    <a
      *ngIf="isMobile && (arrTab[1] || arrTab[2])"
      class="btn-back"
      (click)="back()">
      <mat-icon style="color: #837f7f" aria-hidden="false"
        >arrow_back_ios</mat-icon
      >
    </a>
    <div style="padding-top: 8px; display: flex">
      <div class="short-name">
        <!--nếu conversation có tồn tại-->
        <div>{{ shortName(selectedGroupName) }}</div>
        <!--<angular-emojis size="20" [name]="'smile'"> </angular-emojis>-->
        <!--<emoji-mart (emojiClick)="addEmoji($event)"></emoji-mart>-->
        <!--<img *ngIf="selectedConv.lastSenderAvatar" src="{{selectedConv.lastSenderAvatar}}" />-->
      </div>
      <div>
        <div class="docname">{{ selectedGroupName }}</div>
        <div class="docposition" *ngIf="selectedConv && selectedConv.info">
          <span class="fa fa-fw fa-user"></span>
          {{ selectedConv.info.member.length }} thành viên
        </div>
      </div>
      <div
        class="conv-button"
        *ngIf="selectedConv && selectedConv.type !== 1 && !isMobile">
        <a (click)="addMoreDoctor()">
          <img src="/assets/image/user-add-icon.png" />
          <span>Thêm thành viên</span></a
        >
      </div>
      <div
        ngbDropdown
        class="d-inline-block"
        *ngIf="isMobile && selectedConv"
        style="padding-left: 10px">
        <button class="btn btn-default" id="dropdownBasic1" ngbDropdownToggle>
          <mat-icon style="color: #837f7f" aria-hidden="false"
            >settings</mat-icon
          >
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem (click)="addMoreDoctor()">
            <span>Thêm thành viên</span>
          </button>
          <button ngbDropdownItem (click)="showMedia()">Tệp và hình ảnh</button>
        </div>
      </div>
    </div>
  </div>
  <div class="conversation-body-wrapper">
    <div
      class="conversation-list-wrapper {{ isMobile ? 'm-wraper' : '' }}"
      *ngIf="arrTab[0]">
      <div class="conversation-tool" style="display: flex">
        <div class="search">
          <div class="searchBar">
            <i><img src="assets/image/DPicon/search-icon.svg" /></i>
            <!-- search icon-->
            <input
              type="text"
              [(ngModel)]="filterName"
              placeholder="Tìm kiếm" />
          </div>
        </div>
        <div class="filter-icon">
          <i><img src="assets/image/DPicon/filter-burger.svg" /></i>
          <!-- filter icon-->
        </div>
      </div>
      <div class="conversation-list">
        <div>
          <!--nếu conversation có tồn tại-->
          <ul
            *ngIf="cons.length > 0"
            style="
              height: calc(100vh - 130px - 190px);
              overflow: auto;
              position: relative;
            ">
            <li
              class="li-conv li-{{ o.type }} {{
                selectedConv.id === o.id ? ' selected-doc ' : ''
              }}"
              *ngFor="let o of cons | filterMessageName: filterName"
              (click)="openConv(o, true)">
              <div style="display: flex; width: 80%">
                <div style="margin-right: 15px">
                  <!--<img class="img-avatar" *ngIf="o.lastSenderAvatar" [src]="o.fromUserAvatar"/>-->
                  <div class="short-avatar">{{ shortName(o.name) }}</div>
                </div>
                <div class="lastest-mess" style="width: 100%; overflow: hidden">
                  <div style="font-size: 14px; font-weight: bold">
                    {{ o.name }}
                  </div>
                  <!--{{o.read === 0 && o.chatToUserId === curUser.userId ? 'not-read' : 'read'-->
                  <div class="ellipsis-chat no-read">
                    {{
                      o.lastSenderId === curUser.userId ? '' : o.lastSenderName
                    }}
                    <ng-container [ngSwitch]="o.lastMessageType">
                      <ng-container *ngSwitchCase="'message'">{{
                        o.lastMessage
                      }}</ng-container>
                      <ng-container *ngSwitchCase="'image'"
                        >Đã gửi 1 ảnh</ng-container
                      >
                      <ng-container *ngSwitchCase="'file'"
                        >Đã gửi 1 tệp</ng-container
                      >
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="lastest-time">
                {{ o.lastSendAt | date: 'dd/MM/yyyy HH:mm:ss' | lastmessage }}
                <div class="not-read-dot">
                  <div class="dot" *ngIf="o.newMessage > 0">
                    {{ o.newMessage > 5 ? '+5' : o.newMessage }}
                  </div>
                </div>
                <!-- nếu bệnh nhân là người nhận và read = 0-->
              </div>
              <!-- status icon-->
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="conversation-detail-wrapper {{ isMobile ? 'm-wraper' : '' }}"
      *ngIf="arrTab[1]">
      <div
        *ngIf="cons.length > 0"
        class="chat-body"
        style="height: auto !important">
        <div
          class="chat-content"
          id="chatcontent"
          #chatWrapper
          style="
            overflow: auto;
            position: relative;
            height: calc(100vh - 130px - 90px - 130px - 30px);
          "
          data-scroll="true"
          infinite-scroll
          [infiniteScrollUpDistance]="modalScrollDistance"
          [infiniteScrollThrottle]="modalScrollThrottle"
          [scrollWindow]="false"
          (scrolledUp)="onScrollUp()">
          <div class="message-box" *ngFor="let chat of chats; let i = index">
            <div
              class="chat-status"
              text-center
              *ngIf="
                chat.type === 'join' || chat.type === 'exit';
                else message
              ">
              <!-- <span class="chat-date">{{chat.date | date: 'dd/MM/yyyy HH:mm'}}</span> -->
              <span class="chat-date">{{
                chat.date | date: 'yyyy-MM-dd HH:mm:ss'
              }}</span>
              <span class="chat-content-center">{{ chat.message }}</span>
            </div>
            <ng-template #message>
              <div class="chat-message">
                <div
                  class="max-wrap-width right-bubble"
                  [ngClass]="{
                    'right-bubble': chat.userId === curUser.userId,
                    'left-bubble': chat.userId !== curUser.userId
                  }">
                  <div
                    class="user-avatar {{
                      i === 0 || (i > 0 && chats[i - 1].userId != chat.userId)
                    }}"
                    *ngIf="chat.userId !== curUser.userId">
                    {{ shortName(chat.userName) }}
                  </div>
                  <!--<img *ngIf="chat.userAvatar && chat.userId !== curUser.userId" class="user-avatar"
                    src="{{chat.userAvatar}}" />-->
                  <div class="content-right">
                    <div
                      class="msg-title"
                      *ngIf="
                        i === 0 || (i > 0 && chats[i - 1].userId != chat.userId)
                      ">
                      <span
                        class="msg-name"
                        *ngIf="chat.userId === curUser.userId"
                        >Tôi</span
                      >
                      <span
                        class="msg-name"
                        *ngIf="chat.userId !== curUser.userId"
                        >{{ chat.userName }}</span
                      >
                      <span class="msg-date">
                        at {{ chat.date | date: 'dd-MM-yy HH:mm' }}</span
                      >
                    </div>
                    <div class="divider"></div>
                    <ng-container [ngSwitch]="chat.type">
                      <!-- <p *ngSwitchCase="'message'" text-wrap [innerHtml]="chat.message"></p> -->
                      <p *ngSwitchCase="'message'">
                        <app-chat-message [data]="chat"></app-chat-message>
                      </p>
                      <p
                        (contextmenu)="
                          fileContextMenu($event, chatFileMenu, chat)
                        "
                        *ngSwitchCase="'image'"
                        text-wrap>
                        <app-chat-image
                          [data]="chat"
                          (showImageEvent)="
                            showImageEvent($event)
                          "></app-chat-image>
                      </p>
                      <p
                        (contextmenu)="
                          fileContextMenu($event, chatFileMenu, chat)
                        "
                        *ngSwitchCase="'file'">
                        <app-chat-file [data]="chat"></app-chat-file>
                      </p>
                      <p *ngSwitchCase="'video'">
                        <app-chat-video [data]="chat"></app-chat-video>
                      </p>
                      <nz-dropdown-menu #chatFileMenu="nzDropdownMenu">
                        <ul nz-menu>
                          <li nz-menu-item (click)="sendFileToMasterNote(chat)">
                            Copy vào Master Note
                          </li>
                        </ul>
                      </nz-dropdown-menu>
                      <ng-container *ngSwitchDefault>
                        <p
                          *ngIf="!isUrlValid(chat.message)"
                          [innerHtml]="chat.message"></p>
                        <app-chat-link
                          *ngIf="isUrlValid(chat.message)"
                          [data]="chat.message">
                          app-chat-link</app-chat-link
                        >
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div *ngIf="cons.length > 0" class="chat-box">
        <div
          class="middle-wrapper-icon"
          title="Tải file lên"
          style="position: relative">
          <div>
            <nz-upload
              class="chat-action-icon"
              [nzAction]="uploadFileUrl"
              nzName="FormFile"
              [nzCustomRequest]="handleUploadFile"
              [nzShowUploadList]="false"
              [nzBeforeUpload]="beforeUploadFile"
              (nzChange)="fileChange($event)"
              style="width: 100%; height: 100%">
              <img
                class="action-icon"
                src="assets/image/DPicon/paper-clip.svg" />
            </nz-upload>
          </div>
          <div *ngIf="isUploadingFile" class="loading-icon">
            <i nz-icon nzType="loading" nzTheme="outline"></i>
          </div>
        </div>
        <div class="middle-wrapper-icon" title="Tải ảnh lên">
          <div>
            <nz-upload
              class="chat-action-icon"
              [nzAction]="uploadUrl"
              nzName="FormFile"
              [nzShowUploadList]="false"
              [nzBeforeUpload]="beforeUpload"
              [nzCustomRequest]="handleUploadImage"
              (nzChange)="handleChange($event)"
              style="width: 100%; height: 100%">
              <img class="action-icon" src="assets/image/photo.svg" />
            </nz-upload>
          </div>
        </div>
        <form
          class="message-form"
          [formGroup]="formSendMessage"
          (ngSubmit)="sendChatOnly()"
          style="display: flex; width: 100%">
          <input
            id="chat-private-contet"
            class="form-control"
            autocomplete="off"
            formControlName="message"
            (paste)="onPaste($event)"
            dropFile
            (fileDropped)="onFileDropped($event)" />
          <button id="btn-send">
            Gửi
            <i style="margin-left: 10px"
              ><img src="assets/image/DPicon/paper-plane.svg"
            /></i>
          </button>
        </form>
      </div>
    </div>
    <div
      class="conversation-info {{ isMobile ? 'm-wraper' : '' }}"
      *ngIf="arrTab[2]">
      <div>
        <div class="group-header">{{ selectedConv.name }}</div>
        <div class="group-box">
          <div class="group-title" (click)="toggleCollapse(0)">
            <strong>Thành viên nhóm</strong>
            <i nz-icon [nzType]="arrCollapse[0] ? 'up' : 'down'"></i>
          </div>
          <div
            class="info-group"
            *ngIf="selectedConv.info"
            [ngClass]="arrCollapse[0] ? 'show' : 'hide'">
            <ul class="ul-member">
              <li
                (contextmenu)="contextMenu($event, menu, member)"
                class="li-{{ member.type }}"
                *ngFor="
                  let member of selectedConv.info.member | orderBy: '-isAdmin'
                ">
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu>
                    <li nz-menu-item (click)="chatPrivate(member)">Nhắn tin</li>
                    <li nz-menu-item (click)="openVisit(member)">
                      Mở hồ sơ ca khám
                    </li>
                  </ul>
                </nz-dropdown-menu>

                <div class="short-name-icon">
                  {{ shortName(member.username) }}
                </div>
                <div class="box-name">
                  <label>
                    <!--{{member.type}} - -->
                    {{ member.fullname }}
                  </label>
                  <em *ngIf="member.isAdmin">Quản trị</em>
                  <em *ngIf="member.type === 'Patient'">Bệnh nhân</em>

                  <!--Không hiên menu với TH là người khác làm quản trị hoặc chỉ có 1 quản trị-->
                  <ng-container *ngIf="isVisibleUserMenu(member)">
                    <ng-container *ngIf="member.type === 'Doctor'">
                      <nz-button-group>
                        <a
                          nz-dropdown
                          [nzDropdownMenu]="menu1"
                          nzPlacement="bottomRight">
                          <i nz-icon nzType="ellipsis"></i>
                        </a>
                        <nz-dropdown-menu #menu1="nzDropdownMenu">
                          <ul nz-menu>
                            <li
                              nz-menu-item
                              (click)="removeMember(member.id, member.fullname)"
                              *ngIf="
                                !member.isAdmin &&
                                selectedConv.hasRoleAdmin &&
                                member.id !== curUser.userId
                              ">
                              Mời khỏi nhóm
                            </li>
                            <li
                              nz-menu-item
                              (click)="promoteAdmin(member.id, member.fullname)"
                              *ngIf="
                                !member.isAdmin && selectedConv.hasRoleAdmin
                              ">
                              Gán làm quản trị viên
                            </li>

                            <li
                              nz-menu-item
                              (click)="leaveGroup(member.id)"
                              *ngIf="
                                !member.isAdmin && member.id === curUser.userId
                              ">
                              Rời nhóm
                            </li>
                            <li
                              nz-menu-item
                              (click)="leaveGroup(member.id)"
                              *ngIf="
                                member.isAdmin &&
                                member.id === curUser.userId &&
                                selectedConv.info.admin.length > 1
                              ">
                              Bỏ quyền admin và rời nhóm
                            </li>
                          </ul>
                        </nz-dropdown-menu>
                      </nz-button-group>
                    </ng-container>
                  </ng-container>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="group-box">
          <div class="group-title" (click)="toggleCollapse(1)">
            <strong>Hình ảnh</strong>
            <i nz-icon [nzType]="arrCollapse[1] ? 'up' : 'down'"></i>
          </div>
          <div class="info-group" [ngClass]="arrCollapse[1] ? 'show' : 'hide'">
            <ul class="ul-conv-image">
              <li
                (contextmenu)="fileContextMenu($event, imgMenu, img)"
                *ngFor="let img of images">
                <app-conversation-image
                  [data]="img"
                  (showImageEvent)="
                    showImageEvent($event)
                  "></app-conversation-image>
                <nz-dropdown-menu #imgMenu="nzDropdownMenu">
                  <ul nz-menu>
                    <li nz-menu-item (click)="sendFileToMasterNote(img)">
                      Copy vào Master Note
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </li>
            </ul>
          </div>
        </div>
        <div class="group-box">
          <div class="group-title" (click)="toggleCollapse(2)">
            <strong>Tài liệu</strong>
            <i nz-icon [nzType]="arrCollapse[2] ? 'up' : 'down'"></i>
          </div>
          <div class="info-group" [ngClass]="arrCollapse[2] ? 'show' : 'hide'">
            <ul class="ul-conv-file">
              <li
                (contextmenu)="fileContextMenu($event, fileMenu, file)"
                *ngFor="let file of files; let i = index">
                <div class="file-time">
                  <ng-container
                    *ngIf="
                      i === 0 ||
                      (i > 0 && !compareDate(files[i - 1].date, file.date))
                    ">
                    <h5 class="file-title">
                      {{ file.date | date: 'dd-MM-yyyy' }}
                    </h5>
                  </ng-container>
                </div>
                <app-chat-file [data]="file"></app-chat-file>
                <nz-dropdown-menu #fileMenu="nzDropdownMenu">
                  <ul nz-menu>
                    <li nz-menu-item (click)="sendFileToMasterNote(file)">
                      Copy vào Master Note
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<nz-modal
  nzClassName="slideShowImage"
  [(nzVisible)]="isVisibleListImage"
  (nzOnCancel)="cancelSlideShow()"
  [nzFooter]="null">
  <div class="bigImage">
    <img [src]="curImage.src" />
  </div>
</nz-modal>

<div id="hide-image-div"></div>

<nz-modal
  nzWidth="500px"
  nzTitle="Thêm thành viên"
  nzClassName="addMoreDoctor"
  [(nzVisible)]="isVisibleMoreDoctor"
  (nzOnCancel)="cancelAddDoctor()"
  [nzFooter]="null">
  <div
    class="modal-content"
    style="height: 300px; overflow-y: scroll; padding: 15px; border: none">
    <!--<input type="text" placeholder="Tìm kiếm bác sỹ" (ngModelChange)="searchDoctor($event)" [(ngModel)]="textSearchDoctor" class="form-control" style="margin-bottom: 10px">-->

    <div style="border: solid 1px #08c; padding: 5px 10px; margin-bottom: 10px">
      <nz-tag
        *ngFor="let tag of invitedDoctors; let i = index"
        nzMode="closeable"
        (nzOnClose)="handleClose(tag)">
        {{ tag.label }}
      </nz-tag>
      <input
        (ngModelChange)="searchDoctor($event)"
        [(ngModel)]="textSearchDoctor"
        #inputElement
        nz-input
        nzSize="small"
        type="text"
        class="search-input"
        placeholder="Tìm kiếm theo tên"
        style="width: auto; border: none; outline: none; font-size: 12px" />
    </div>
    <nz-checkbox-group
      class="li-checkbox"
      [(ngModel)]="doctorInGroup"
      (ngModelChange)="doctorChecked($event)">
    </nz-checkbox-group>
  </div>
  <div class="modal-footer">
    <button nz-button nzType="primary" (click)="inviteDoctor()">Mời</button>
    <button nz-button nzType="default" (click)="cancelAddDoctor()">Hủy</button>
  </div>
</nz-modal>
