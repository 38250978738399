<div class="custom-modal">
  <app-m-modal class="custom-modal" [modalId]="'config-group'" [isVisible]="isVisible" (closeModal)="handleCancel()"
    [mMask]="true" [width]="900" [minWidth]="900" [height]="730" [isResiable]="true">
    <ng-template #mModalTitle>
      <div class="header-title">Cấu hình nhóm làm việc</div>
    </ng-template>
    <ng-template #mModalContent>
      <form [formGroup]="formCFGroup">
        <div class="group-modal container-fluid">
          <nz-tabset [nzAnimated]="false" class="tabset-default">
            <nz-tab nzTitle="Cấu hình chung">
              <div class="config-tab">
                <div class="row">
                  <div class="col-sm-5">Tên nhóm</div>
                  <div class="col-sm-7">
                    <input style="width: 70%" formControlName="Name" />
                    <div>
                      <i class="fs-12">(Hiển thị khi user không dùng tên Alias)</i>
                    </div>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <div class="col-sm-5">Tên hiển thị (Alias)</div>
                  <div class="col-sm-7">
                    <input style="width: 70%" formControlName="aliasName" /><br />
                    <div><i class="fs-12">(Chỉ hiển thị với tôi)</i></div>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <div class="col-sm-5">Mã nhóm</div>
                  <div class="col-sm-7">
                    <input style="width: 70%" formControlName="shortCode" />
                  </div>
                </div>
                <div class="row mg-t-20">
                  <label class="col-sm-5">Upload ảnh đại diện</label>
                  <div class="col-sm-7 imgUpload">
                    <nz-upload nzAction="{{ urlService }}" nzName="FormFile" [nzShowUploadList]="false"
                      [nzBeforeUpload]="beforeUpload" [nzHeaders]="header" (nzChange)="handleChange($event)">
                      <button nz-button><i nz-icon nzType="upload"></i>Click to Upload</button>
                    </nz-upload>
                  </div>
                </div>
                <ng-container>
                  <div class="row mg-t-10" *ngIf="fullUrl">
                    <label class="col-sm-5">Ảnh đại diện</label>
                    <div class="col-sm-7 imgUpload">
                      <img [src]="fullUrl" [alt]="''" style="max-width: 120px" />
                    </div>
                  </div>
                </ng-container>
              </div>
            </nz-tab>
            <nz-tab nzTitle="Cấu hình nghiệp vụ">
              <div class="config-tab">
                <!-- <div class="row">
                  <div class="col-sm-5">Tự động thêm ca khám Điều trị</div>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isAutoAddTreatmentPatient"></label>
                  </div>
                </div> -->
                <div class="row mg-t-10">
                  <div class="col-sm-5">Tự động lọc ca khám theo quyền</div>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isLoadByPermission"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <div class="col-sm-5">Nhóm theo dõi Covid</div>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isCovidGroup"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <div class="col-sm-5">Tạo nhanh tài khoản BN</div>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isCreatePatientAccountQuickly"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <div class="col-sm-5">Sử dụng trang Danh sách ca khám kết hợp Chi tiết ca khám</div>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseListNDetailVisit"></label>
                  </div>
                </div>
                <ng-container *ngIf="formCFGroup.value.isUseListNDetailVisit" class="mg-r-30">
                  <div class="card pd-10 outer-card">
                    <div class="row mg-t-10">
                      <label class="col-sm-5"> In tách hay gộp chỉ định</label>
                      <div class="col-sm-7">
                        <!-- <label
                          nz-checkbox
                          formControlName="printSeperateSubclinical"></label> -->
                        <nz-radio-group formControlName="orderPrintType">
                          <label nz-radio [nzValue]="1">In gộp</label>
                          <label nz-radio [nzValue]="2"> In tách theo loại chỉ định (CĐHA, XN, khác) </label>
                          <label nz-radio [nzValue]="3"> In tách theo địa điểm thực hiện </label>
                        </nz-radio-group>
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5">Tự động chuyển trạng thái ca khám sang <b>Đang thực hiện</b> khi In chỉ
                        định
                      </label>
                      <div class="col-sm-7">
                        <label nz-checkbox formControlName="autoConvert2InProgressVisit"></label>
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5">Tự động chuyển trạng thái ca khám sang <b>Hoàn tất</b> khi In kết
                        luận</label>
                      <div class="col-sm-7">
                        <label nz-checkbox formControlName="autoConvert2FinishedVisit"></label>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="row mg-t-10">
                  <div id="isUseListPatient" class="col-sm-5">
                    <label>Sử dụng trang danh sách BN</label>
                    <nz-checkbox class="col-3" nz-checkbox formControlName="isUseListPatient"
                      (ngModelChange)="showToolTip()"></nz-checkbox>
                    <span style="margin-top: 20px;" id="tooltip">Hệ thống sẽ thêm cập nhật thông tin cho các bệnh nhân
                      cũ</span>
                  </div>
                  <div class="col-sm-6">
                    <label>Tự động thêm ca khám Điều trị</label>
                    <input [nzValue]="true" class="col-3" type="checkbox" checked disabled
                      formControlName="isAutoAddTreatmentPatient">
                  </div>
                </div>
                <!-- <div class="row mg-t-10">
                  <label class="col-sm-5">Tự động thêm ca khám Điều trị</label>
                  <div class="col-sm-7">
                    <label class="">Tự động thêm ca khám Điều trị</label>
                    <label nz-checkbox formControlName="isAutoAddTreatmentPatient"></label>
                  </div>
                </div> -->
                <ng-container *ngIf="formCFGroup.value.isUseListPatient" class="mg-r-30">
                  <div class="card pd-10 outer-card">
                    <div class="row">
                      <label class="col-sm-5">DS các cột dữ liệu sẽ hiển thị trên DS BN (ngoài thông tin chung của
                        BN)</label>
                      <div class="col-sm-7">
                        <button nzType="primary" nz-button nzShape="round" (click)="showCustomColumn()">
                          Xem danh sách cột ({{ showCols.length }})
                        </button>
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5">Sắp xếp DS BN theo mức độ nguy hiểm</label>
                      <div class="col-sm-7">
                        <label nz-checkbox formControlName="isOrderBySosRange"></label>
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5">Tự động filter BN theo role</label>
                      <div class="col-sm-7">
                        <label nz-checkbox formControlName="isFilterByDoctorPermission"></label>
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5">Sử dụng DS BN là mặc định thay cho DS ca khám</label>
                      <div class="col-sm-7">
                        <label nz-checkbox formControlName="defaultUseListPatient"></label>
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5">Sắp xếp theo</label>
                      <div class="col-sm-7">
                        <nz-select formControlName="defaultSortBy" [nzPlaceHolder]="'Sắp xếp theo'" style="width: 50%">
                          <nz-option *ngFor="let item of columnSortFilter" [nzLabel]="item.label"
                            [nzValue]="item.value">
                          </nz-option>
                        </nz-select>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Các tab sử dụng ở trang Chi tiết ca khám</label>
                  <div class="col-sm-3">
                    <div class="list-group">
                      <ul>
                        <li>
                          <label nz-checkbox class="mg-r-8" [nzDisabled]="true" [nzChecked]="true"></label>
                          <span nz-tooltip [nzTooltipTitle]="visitTabsTooltip"> Thông tin ca khám </span>
                          <ng-template #visitTabsTooltip>
                            <span id="visit-tabs-tooltip">Mặc định hiển thị Thông tin ca khám</span>
                          </ng-template>
                        </li>
                        <li *ngFor="let item of listVisitDetailTabsDefault.slice(0, 2)">
                          <label nz-checkbox [(ngModel)]="item.checked" class="mg-r-8"
                            [ngModelOptions]="{ standalone: true }"></label>
                          <span>{{ item.name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="list-group">
                      <ul>
                        <li *ngFor="let item of listVisitDetailTabsDefault.slice(-4)">
                          <label nz-checkbox [(ngModel)]="item.checked" class="mg-r-8"
                            [ngModelOptions]="{ standalone: true }"></label>
                          <span>{{ item.name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Các quyền về user phụ trách cần sử dụng</label>
                  <div class="col-sm-3">
                    <div class="list-group">
                      <!-- <nz-list nzItemLayout="horizontal">
                                    <nz-list-item *ngFor="let item of lstPermissionDefault">
                                        <nz-list-item-meta>
                                            <nz-list-item-meta-title>{{item.name}}</nz-list-item-meta-title>
                                            <nz-list-item-action>

                                            </nz-list-item-action>
                                        </nz-list-item-meta>
                                    </nz-list-item>
                                </nz-list> -->
                      <ul>
                        <li *ngFor="let item of lstPermissionDefault.slice(0, 4)">
                          <label nz-checkbox [(ngModel)]="item.checked" class="mg-r-8"
                            [ngModelOptions]="{ standalone: true }"></label>
                          <span>{{ item.name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="list-group">
                      <ul>
                        <li *ngFor="let item of lstPermissionDefault.slice(-3)">
                          <label nz-checkbox [(ngModel)]="item.checked" class="mg-r-8"
                            [ngModelOptions]="{ standalone: true }"></label>
                          <span>{{ item.name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Sử dụng thành viên gia đình</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUserFamilyMember"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Sử dụng tính năng room BN</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseDividePatientRoom"></label>
                  </div>
                </div>
                <ng-container *ngIf="formCFGroup.value.isUseDividePatientRoom" class="mg-r-30">
                  <div class="card pd-10 outer-card">
                    <div class="row">
                      <label class="col-sm-5"> Bác sĩ tự chọn room BN cho mình </label>
                      <div class="col-sm-7">
                        <label nz-checkbox formControlName="isDoctorSelectRoom"></label>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <div class="row mg-t-10">
                  <label class="col-sm-5">Áp dụng quyền kê đơn thuốc</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUsePharmacist"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">In/Tìm kiếm thuốc hiện hoạt chất</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="prescriptionIsShowActiveIngredient"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Áp dụng tính năng duyệt & quyền duyệt đơn thuốc </label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseApprovePrescription"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Áp dụng gán ca khám theo phòng hay theo BS </label>
                  <div class="col-sm-7">
                    <nz-radio-group formControlName="getVisitByRoomOrDoctor">
                      <label nz-radio [nzValue]="false">Bác sĩ</label>
                      <label nz-radio [nzValue]="true">Phòng</label>
                    </nz-radio-group>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">In đơn thuốc</label>
                  <div class="col-sm-7">
                    <nz-radio-group formControlName="paperSizePrintPrescription">
                      <label nz-radio nzValue="A4">A4</label>
                      <label nz-radio nzValue="A5">A5</label>
                    </nz-radio-group>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Config đẩy chỉ định sang HIS & PACS/LIS </label>
                  <div class="col-sm-7">
                    <nz-radio-group formControlName="subClinicalSendType">
                      <label nz-radio [nzValue]="1"> Đẩy chỉ định ngay khi cập nhật </label>
                      <label nz-radio [nzValue]="2"> Đẩy chỉ định sau khi click "Chuyển CLS" hoặc "In" </label>
                    </nz-radio-group>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Đổi status là đã xuất thuốc sau khi xuất file pdf </label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isChangePresciptionStatus"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Sử dụng thông tin giao nhận thuốc </label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUsePrescriptionDeliveryInfo"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Mẫu hiển thị danh sách thuốc trên đơn thuốc </label>
                  <div class="col-sm-7">
                    <nz-select nzPlaceHolder="Chọn mẫu hiển thị danh sách thuốc" formControlName="templateDrugList"
                      class="select-template-drug">
                      <nz-option *ngFor="let temp of listTemplateDrug" [nzValue]="temp.value"
                        [nzLabel]="temp.label"></nz-option>
                    </nz-select>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Header của đơn thuốc</label>
                  <div class="col-sm-6">
                    <span class="card pd-10" [innerHTML]="modalHeaders[0].content"></span>
                  </div>
                  <div class="col-sm-1">
                    <i title="Sửa thông tin header" (click)="editHeader(PrintFormHeader.Prescription)"
                      style="font-size: 22px; color: #1890ff" nz-icon nzType="edit" nzTheme="outline"></i>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Header của phiếu in chỉ định cận lâm sàng </label>
                </div>
                <div class="row mg-t-10 pdl20">
                  <label class="col-sm-5">Phiếu A4</label>
                  <div class="col-sm-6">
                    <span class="card pd-10" [innerHTML]="modalHeaders[1].content"></span>
                  </div>
                  <div class="col-sm-1">
                    <i title="Sửa thông tin header" (click)="editHeader(PrintFormHeader.ClinicalFormA4)"
                      style="font-size: 22px; color: #1890ff" nz-icon nzType="edit" nzTheme="outline"></i>
                  </div>
                </div>
                <div class="row mg-t-10 pdl20">
                  <label class="col-sm-5">Phiếu A5</label>
                  <div class="col-sm-6">
                    <span class="card pd-10" [innerHTML]="modalHeaders[2].content"></span>
                  </div>
                  <div class="col-sm-1">
                    <i title="Sửa thông tin header" (click)="editHeader(PrintFormHeader.ClinicalFormA5)"
                      style="font-size: 22px; color: #1890ff" nz-icon nzType="edit" nzTheme="outline"></i>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Header của phiếu kết quả XN</label>
                  <div class="col-sm-6">
                    <span class="card pd-10" [innerHTML]="modalHeaders[3].content"></span>
                  </div>
                  <div class="col-sm-1">
                    <i title="Sửa thông tin header" (click)="editHeader(PrintFormHeader.LaboratoryResultHeader)"
                      style="font-size: 22px; color: #1890ff" nz-icon nzType="edit" nzTheme="outline"></i>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Áp dụng tổng kết ca khám</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseFormConclusion"></label>
                  </div>
                </div>
                <ng-container *ngIf="formCFGroup.value.isUseFormConclusion">
                  <div class="card pd-10 outer-card">
                    <div class="row">
                      <label class="col-sm-5">Chọn Form</label>
                      <div class="col-sm-7 imgUpload">
                        <input style="width: 315px" #keywordFormConclusion placeholder="Tìm kiếm..." minLength="2"
                          nz-input (input)="onSearchForm(keywordFormConclusion.value)" [nzAutocomplete]="auto"
                          (focus)="onSearchForm(' ')" />
                        <nz-autocomplete #auto>
                          <nz-auto-option *ngFor="let item of searchDataFilter" [nzValue]="item.name"
                            (selectionChange)="selectFormEvent($event, item)">
                            <span>
                              <p>{{ item.name }}</p>
                            </span>
                          </nz-auto-option>
                        </nz-autocomplete>
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5"> Danh sách Form tổng kết ca khám </label>
                      <div class="col-sm-7 imgUpload">
                        <div *ngIf="selectedForms !== null" class="list-group">
                          <nz-list nzItemLayout="horizontal">
                            <nz-list-item *ngFor="let item of selectedForms">
                              <nz-list-item-meta>
                                <nz-list-item-meta-title>{{ item.name }} </nz-list-item-meta-title>
                              </nz-list-item-meta>
                              <ul nz-list-item-actions>
                                <nz-list-item-action>
                                  <a (click)="unSelectForm(item.id)" title="Bỏ chọn">X</a>
                                </nz-list-item-action>
                              </ul>
                            </nz-list-item>
                            <nz-list-empty *ngIf="selectedForms.length === 0"></nz-list-empty>
                          </nz-list>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Áp dụng tính năng đăng ký – tiếp nhận </label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseRegisterPP"></label>
                  </div>
                </div>
                <ng-container *ngIf="formCFGroup.value.isUseRegisterPP">
                  <div class="card pd-10 outer-card">
                    <div class="row">
                      <label class="col-sm-5">Chọn Form đăng ký KH</label>
                      <div class="col-sm-7 imgUpload">
                        <input style="width: 315px" #keywordFormRegisterPP placeholder="Tìm kiếm..." minLength="2"
                          nz-input (input)="onSearchRegisterPPForm(keywordFormRegisterPP.value)" [nzAutocomplete]="auto"
                          (focus)="onSearchRegisterPPForm(' ')" />
                        <nz-autocomplete #auto>
                          <nz-auto-option *ngFor="let item of searchFormRegisterPPFilter" [nzValue]="item.name"
                            (selectionChange)="selectRegisterPPFormEvent($event, item)">
                            <span>
                              <p>{{ item.name }}</p>
                            </span>
                          </nz-auto-option>
                        </nz-autocomplete>
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5">Danh sách Form đăng ký KH</label>
                      <div class="col-sm-7 imgUpload">
                        <div *ngIf="registerPPForms !== null" class="list-group">
                          <nz-list nzItemLayout="horizontal">
                            <nz-list-item *ngFor="let item of registerPPForms">
                              <nz-list-item-meta>
                                <nz-list-item-meta-title>{{ item.name }} </nz-list-item-meta-title>
                              </nz-list-item-meta>
                              <ul nz-list-item-actions>
                                <nz-list-item-action>
                                  <a (click)="unSelectRegisterPPForm(item.id)" title="Bỏ chọn">X</a>
                                </nz-list-item-action>
                              </ul>
                            </nz-list-item>
                            <nz-list-empty *ngIf="registerPPForms.length === 0"></nz-list-empty>
                          </nz-list>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Áp dụng nhập thông tin lâm sàng trên Patient Portal </label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseClinicalInforPP"></label>
                  </div>
                </div>
                <ng-container *ngIf="formCFGroup.value.isUseClinicalInforPP">
                  <div class="card pd-10 outer-card">
                    <div class="row">
                      <label class="col-sm-5"> Chọn Form nhập thông tin lâm sàng Web </label>
                      <div class="col-sm-7 imgUpload">
                        <input style="width: 315px; max-width: 100%" #keywordFormPPWeb placeholder="Tìm kiếm..."
                          minLength="2" nz-input (input)="onSearchPPFormWeb(keywordFormPPWeb.value)"
                          [nzAutocomplete]="autoFormPPWeb" (focus)="onSearchPPFormWeb('')" />
                        <nz-autocomplete #autoFormPPWeb>
                          <nz-auto-option *ngFor="let item of searchFormPPWebFilter" [nzValue]="item.name"
                            (selectionChange)="selectWebFormEvent($event, item)">
                            <span>
                              <p>{{ item.name }}</p>
                            </span>
                          </nz-auto-option>
                        </nz-autocomplete>
                        <!-- <a id="btnShareGroup" (click)="addForm()"><i nz-icon nzType="right-circle" nzTheme="twotone"></i></a> -->
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5"> Danh sách Form nhập thông tin lâm sàng Web </label>
                      <div class="col-sm-7 imgUpload">
                        <div *ngIf="clinicalFormsWeb !== null" class="list-group">
                          <nz-list nzItemLayout="horizontal">
                            <nz-list-item *ngFor="let item of clinicalFormsWeb">
                              <nz-list-item-meta>
                                <nz-list-item-meta-title>{{ item.name }} </nz-list-item-meta-title>
                              </nz-list-item-meta>
                              <ul nz-list-item-actions>
                                <nz-list-item-action>
                                  <a (click)="unSelectWebForm(item.id)" title="Bỏ chọn">X</a>
                                </nz-list-item-action>
                              </ul>
                            </nz-list-item>
                            <nz-list-empty *ngIf="clinicalFormsWeb.length === 0"> </nz-list-empty>
                          </nz-list>
                        </div>
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5"> Chọn Form nhập thông tin lâm sàng Mobile </label>
                      <div class="col-sm-7 imgUpload">
                        <input style="width: 315px; max-width: 100%" #keywordFormPPMobile placeholder="Tìm kiếm..."
                          minLength="2" nz-input (input)="onSearchPPFormMobile(keywordFormPPMobile.value)"
                          [nzAutocomplete]="autoFormPPMobile" (focus)="onSearchPPFormMobile(' ')" />
                        <nz-autocomplete #autoFormPPMobile>
                          <nz-auto-option *ngFor="let item of searchFormPPMobileFilter" [nzValue]="item.name"
                            (selectionChange)="selectMobileFormEvent($event, item)">
                            <span>
                              <p>{{ item.name }}</p>
                            </span>
                          </nz-auto-option>
                        </nz-autocomplete>
                        <!-- <a id="btnShareGroup" (click)="addForm()"><i nz-icon nzType="right-circle" nzTheme="twotone"></i></a> -->
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5"> Danh sách Form nhập thông tin lâm sàng Mobile </label>
                      <div class="col-sm-7 imgUpload">
                        <div *ngIf="clinicalFormsMobile !== null" class="list-group">
                          <nz-list nzItemLayout="horizontal">
                            <nz-list-item *ngFor="let item of clinicalFormsMobile">
                              <nz-list-item-meta>
                                <nz-list-item-meta-title>{{ item.name }} </nz-list-item-meta-title>
                              </nz-list-item-meta>
                              <ul nz-list-item-actions>
                                <nz-list-item-action>
                                  <a (click)="unSelectMobileForm(item.id)" title="Bỏ chọn">X</a>
                                </nz-list-item-action>
                              </ul>
                            </nz-list-item>
                            <nz-list-empty *ngIf="clinicalFormsMobile.length === 0"> </nz-list-empty>
                          </nz-list>
                        </div>
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5"> Áp dụng gửi notify nhắc BN nhập thông tin </label>
                      <div class="col-sm-7">
                        <label nz-checkbox formControlName="pushNotifyToUpdateRecord"></label>
                      </div>
                    </div>
                    <ng-container *ngIf="formCFGroup.value.pushNotifyToUpdateRecord">
                      <div class="card pd-10">
                        <div class="row">
                          <label class="col-sm-5">Thời gian gửi notify</label>
                          <div class="col-sm-7 time-push-notify">
                            <div *ngFor="let time of pushNotifyTimes; let i = index">
                              <nz-time-picker [ngModel]="time" nzFormat="HH:mm" (ngModelChange)="setTime($event, i)"
                                [ngModelOptions]="{ standalone: true }" [nzMinuteStep]="15">
                              </nz-time-picker>
                              <i class="mg-l-8" nz-icon nzType="minus" nzTheme="outline"
                                (click)="removeNotifyTime(i)"></i>
                            </div>
                            <div>
                              <button class="mg-t-4" nz-button nzType="primary" (click)="addNotifyTime()">
                                <i nz-icon nzType="plus" nzTheme="outline"></i>Thêm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Sử dụng tính năng lịch công việc </label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseReminder"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Sử dụng tính năng hỗ trợ lâm sàng</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseCDSRule"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Sử dụng tính năng y lệnh</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseMedicalOrder"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Sử dụng tính năng màn hình chờ</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseWaitingPage"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Sử dụng tính năng nhắn tin với BN </label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseChat"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Sử dụng tính năng video call</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseVideoCall"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Sử dụng trang thống kê nhóm</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseGroupStatistic"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Áp dụng nhóm BN</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUsePatientGroup"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Cho phép user cập nhật danh sách nhóm chỉ định</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUserUpdateIdCategory"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Cho phép user cập nhật biểu mẫu </label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUserUpdateForm"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5"> Cho phép user cập nhật danh mục thuốc </label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUserUpdateMedicine"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Sử dụng quy trình điều trị</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseTreatmentProcess"></label>
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Sử dụng reminder hỗ trợ quyết định lâm sàng</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseReminderSupportClinicalDecision"></label>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-5">Cấu hình chỉ số sinh tồn</label>
                  <!-- <div class="col-sm-7">
                    <label nz-checkbox formControlName="defaultObservationVitalSigns"></label>
                  </div> -->
                </div>
                <ng-container>
                  <!-- <div class="card pd-10">
                    <div class="row mg-t-10" *ngFor="let vitalSign of _defaultObservationVitalSigns"> 
                      <label class="col-sm-5">{{ vitalSign .title }} - {{ vitalSign.codeType }} - {{ vitalSign.name }} - {{ vitalSign.otId }}</label>
                      <div class="col-sm-7">
                        <input  nz-input style="width: 70%" [(ngModel)]="vitalSign.code"  />
                      </div> 
                    </div>
                  </div> -->
                  <div class="card pd-10">
                    <div class="row mg-t-10" *ngFor="let vitalSign  of _defaultObservationVitalSigns"> 
                      <label class="col-sm-5">{{ vitalSign .title }} - {{ vitalSign.codeType }} - {{ vitalSign.name }} - {{ vitalSign.otId }}</label>
                      <div class="col-sm-7">
                        <input nz-input style="width: 70%" [ngModelOptions]="{standalone: true}" [(ngModel)]="vitalSign.code" />
                      </div> 
                    </div>
                  </div>
                </ng-container>
              </div>
            </nz-tab>
            <nz-tab nzTitle="Kết nối hệ thống khác">
              <div class="config-tab">
                <div class="row">
                  <label class="col-sm-5">Kết nối với HIS</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isConnectToHIS"></label>
                  </div>
                </div>
                <ng-container *ngIf="formCFGroup.value.isConnectToHIS">
                  <div class="card pd-10">
                    <div class="row mg-t-10">
                      <label class="col-sm-5">HIS URL</label>
                      <div class="col-sm-7">
                        <input style="width: 70%" formControlName="hisurl" />
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5">HIS key</label>
                      <div class="col-sm-7">
                        <input style="width: 70%" formControlName="hisKey" />
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5">HIS name</label>
                      <div class="col-sm-7">
                        <input style="width: 70%" formControlName="hisName" />
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5">Đồng bộ lại dữ liệu HIS</label>
                      <div class="col-sm-7">
                        <label nz-checkbox formControlName="synchronyVisitToHIS"></label>
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5">Lấy giá thuốc từ HIS</label>
                      <div class="col-sm-7">
                        <label nz-checkbox formControlName="getHISMedicinePrice"></label>
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5">Lấy giá dịch vụ từ HIS</label>
                      <div class="col-sm-7">
                        <label nz-checkbox formControlName="getHISServicePrice"></label>
                      </div>
                    </div>
                    <div class="row mg-t-10">
                      <label class="col-sm-5">Đẩy chỉ định CLS sang HIS</label>
                      <div class="col-sm-7">
                        <label nz-checkbox formControlName="pushSubclinicalToHIS"></label>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Link xem ca chụp nội soi PACS</label>
                  <div class="col-sm-7">
                    <input style="width: 70%" formControlName="subclinicalResultLink" />
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">LIS Testsamples URL</label>
                  <div class="col-sm-7">
                    <input style="width: 70%" formControlName="lisurL_GetTestSampleId" />
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">LIS partner code</label>
                  <div class="col-sm-7">
                    <input style="width: 70%" formControlName="lisPartnerCode" />
                  </div>
                </div>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Sử dụng Webhook</label>
                  <div class="col-sm-7">
                    <label nz-checkbox formControlName="isUseWebhook" (ngModelChange)="isUseWebhookChange()"></label>
                  </div>
                </div>
                <ng-container *ngIf="formCFGroup.value.isUseWebhook">
                  <div class="card pd-10">
                    <div class="row">
                      <div class="col-sm-12">
                        <button class="mg-r-10" nz-button nzType="primary" style="float: right"
                          (click)="openAddWebhook()">
                          <i nz-icon nzType="plus" nzTheme="outline"></i>Thêm
                        </button>
                      </div>
                    </div>
                    <div class="row pd-10">
                      <div class="col-sm-12">
                        <nz-table #basicTable [nzData]="webhookItems" [nzScroll]="{ y: '250px' }"
                          [nzFrontPagination]="false" [nzShowPagination]="false">
                          <thead>
                            <tr>
                              <th nzWidth="30px">#</th>
                              <th nzWidth="200px">Tên</th>
                              <th nzWidth="200px">Url</th>
                              <th nzWidth="80px"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let data of basicTable.data; let i = index" (dblclick)="editWeebhookItem(data)">
                              <td>
                                {{ basicTable.nzPageSize * (basicTable.nzPageIndex - 1) + i + 1 }}
                              </td>
                              <td>{{ data.name }}</td>
                              <td>{{ data.url }}</td>
                              <td>
                                <a (click)="editWeebhookItem(data)"><i nz-icon nzType="edit" nzTheme="outline"></i></a>
                                <nz-divider nzType="vertical"></nz-divider>
                                <a (click)="deleteWebhookItem(data)"><i nz-icon nzType="delete"
                                    nzTheme="outline"></i></a>
                              </td>
                            </tr>
                          </tbody>
                        </nz-table>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="row mg-t-10">
                  <label class="col-sm-5">Sử dụng PMR Api</label>
                </div>
                <div class="card pd-10">
                  <div class="row">
                    <div class="col-sm-12">
                      <button class="mg-r-10" nz-button nzType="primary" style="float: right"
                        (click)="openAddApiToken()">
                        <i nz-icon nzType="plus" nzTheme="outline"></i>Thêm
                      </button>
                    </div>
                  </div>
                  <div class="row pd-10">
                    <div class="col-sm-12">
                      <nz-table #apiTokenTable [nzData]="apiTokens" [nzScroll]="{ y: '250px' }"
                        [nzFrontPagination]="false" [nzShowPagination]="false">
                        <thead>
                          <tr>
                            <th nzWidth="30px">#</th>
                            <th nzWidth="200px">Tên</th>
                            <th nzWidth="205px">Token</th>
                            <th nzWidth="80px"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let data of apiTokenTable.data; let i = index"
                            (dblclick)="editApiTokenItem(data)">
                            <td>
                              {{ apiTokenTable.nzPageSize * (apiTokenTable.nzPageIndex - 1) + i + 1 }}
                            </td>
                            <td>{{ data.name }}</td>
                            <td>{{ data.token }}</td>
                            <td>
                              <!-- <a (click)="copyItem(data.token)"><i nz-icon nzType="copy" nzTheme="outline"></i></a>
                                                        <nz-divider nzType="vertical"></nz-divider> -->
                              <a (click)="editApiTokenItem(data)"><i nz-icon nzType="edit" nzTheme="outline"></i></a>
                              <nz-divider nzType="vertical"></nz-divider>
                              <a (click)="deleteApiTokenItem(data)"><i nz-icon nzType="delete"
                                  nzTheme="outline"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </nz-table>
                    </div>
                  </div>
                </div>
              </div>
            </nz-tab>
          </nz-tabset>
        </div>
      </form>
    </ng-template>
    <ng-template #mModalFooter>
      <button class="btn-primary-medium" (click)="handleOk()">Lưu</button>
      <button class="btn-cancel-medium" (click)="handleCancel()">Đóng</button>
    </ng-template>
  </app-m-modal>
</div>

<app-webhook-modal [isVisible]="isVisibleWebhookModal" [currentWebhook]="currentWebhook" [groupId]="groupId"
  (isVisibleChange)="isVisibleWebHookModalChange($event)"></app-webhook-modal>
<app-api-token-modal [isVisible]="isVisibleApiTokenModal" [groupId]="groupId" [currentApiToken]="currentApiToken"
  (isVisibleChange)="isVisibleApiTokenModalChange($event)"></app-api-token-modal>
<app-edit-header [(isVisible)]="isEditingHeader" [groupId]="groupId" [content]="modalHeader.content"
  [paperSize]="modalHeader.paperSize" [typeHeader]="modalHeader.type" [titleModal]="modalHeader.title"
  (contentSaved)="contentSavedEvent($event)" (isVisibleChange)="closeModalEditHeader()">
</app-edit-header>
<app-custom-column-admin [(isVisible)]="isCustomColumnVisible" [showColumn]="showCols" [hideColumn]="hideCols"
  (saveColumns)="changeColumns($event)" [modalTitle]="'Lựa chọn các cột dữ liệu sẽ hiển thị trên DS BN'">
</app-custom-column-admin>
