import { CustomColumnService } from 'src/app/services/custom-cloumn.service';
import { AppConfigService } from 'src/app-config.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CovidService } from '../../../services/covid.service';
import { WorklistColumn } from '../../../models/worklist-column';
import { Subscription } from 'rxjs';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NotificationService } from '../../../services/notification.service';
import { Constant } from '../../../share/constants/constant.class';
import { DoctorService } from '../../../services/doctor.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { ShareService } from '../../../services/share.service';
import { TabDataService } from '../../../share/base-service/data-worklist.service';
import { NzResizeEvent } from 'ng-zorro-antd/resizable';
import { AddressService } from 'src/app/services/address.service';
import { NzModalService } from 'ng-zorro-antd/modal';

const defaultConfig: WorklistColumn[] = [
  {
    title: '#',
    field: '#',
    width: '60px',
    minWidth: 50,
  },
  {
    title: 'Thời gian ĐK',
    field: 'dateCreated',
    width: '180px',
    minWidth: 50,
  },
  {
    title: 'Họ và tên',
    field: 'fullName',
    width: '300px',
    minWidth: 50,
  },
  {
    title: 'Đã xác thực thông tin',
    field: 'verified',
    width: '100px',
    minWidth: 50,
  },
  {
    title: 'Tình nguyện viên',
    field: 'volunteerName',
    width: '200px',
    minWidth: 50,
  },
  {
    title: 'Năm sinh',
    field: 'dateOfBirth',
    width: '100px',
    minWidth: 50,
  },
  {
    title: 'Địa chỉ',
    field: 'address',
    width: '260px',
    minWidth: 50,
  },
  {
    title: 'Email',
    field: 'email',
    width: '180px',
    minWidth: 50,
  },
  {
    title: 'Số điện thoại',
    field: 'phone',
    width: '180px',
    minWidth: 50,
  },
  {
    title: 'Trạng thái',
    field: 'status',
    width: '150px',
    minWidth: 50,
  },
  {
    title: 'KQ test nhanh',
    field: 'quickTestCovidNew',
    width: '100px',
    minWidth: 50,
  },
  {
    title: 'KQ test PCR',
    field: 'pcrTestCovidNew',
    width: '100px',
    minWidth: 50,
  },
  {
    title: 'Ngày test covid',
    field: 'dateOfTestCovid',
    width: '150px',
    minWidth: 50,
  },
  {
    title: 'Ngày khởi phát triệu chứng',
    field: 'dateOfDiagnosis',
    width: '150px',
    minWidth: 50,
  },
  {
    title: 'Ngày tiếp xúc với F1',
    field: 'dateMeetF1',
    width: '150px',
    minWidth: 50,
  },
  {
    title: 'Có bệnh nền',
    field: 'myDiseases',
    width: '100px',
    minWidth: 50,
  },
  {
    title: 'Lý do từ chối',
    field: 'reasonDenied',
    width: '200px',
    minWidth: 50,
  },
  {
    title: 'Nguồn đăng ký',
    field: 'utm_source',
    width: '150px',
    minWidth: 50,
  },
];

@Component({
  selector: 'app-covid-receive-list',
  templateUrl: './covid-receive-list.component.html',
  styleUrls: ['./covid-receive-list.component.scss'],
})
export class CovidReceiveListComponent implements OnInit, OnDestroy {
  cols: WorklistColumn[] = [];
  pageIndex = 1;
  pageSize = 20;
  total = 20;
  loading = false;
  isSaving = false;
  resizing = false;
  isCustomColumnVisible = false;

  showCols: WorklistColumn[] = [];
  hideCols: WorklistColumn[] = [];
  groupSub: Subscription;
  lockScreenSub: Subscription;

  search: any = {
    fullName: '',
    status: 0,
    verified: 0,
  };
  datas: any[];
  isVisibleDetail: boolean;
  isVisibleEdit: boolean;
  item: any;
  itemCovid: any;
  itemEdit: any;
  selectedGroupId: any;
  receivingGroupId = '';
  beginDateTreatment = new Date();
  intervalReloadGrid: any;
  reloadTime = 120000;
  isVisibleReceive: boolean;
  listOfTagDiseases = [];
  currentDiseasesChecked = [];
  isVisitablePatientAccount: boolean;
  patientAccount = {
    username: 'danhnd98',
    password: '123465',
    visitId: '',
    patientName: '',
    patientId: '',
  };
  appNameSource = [
    { label: 'Zalo', value: 'Zalo', checked: false },
    {
      label: 'Messenger - Facebook',
      value: 'Messenger - Facebook',
      checked: false,
    },
    { label: 'Viber', value: 'Viber', checked: false },
    { label: 'Không sử dụng', value: 'Không sử dụng', checked: false },
    { label: 'Ứng dụng khác', value: 'Khác', checked: false },
  ];
  myDiseasesSource = [
    { label: 'Không', value: 'Không', disabled: false },
    { disabled: false, label: 'Đái tháo đường', value: 'Đái tháo đường' },
    {
      disabled: false,
      label: 'Bệnh phổi tắc nghẽn mãn tính và các bệnh phổi khác',
      value: 'Bệnh phổi tắc nghẽn mãn tính và các bệnh phổi khác',
    },
    {
      disabled: false,
      label:
        'Ung thư (đặc biệt là các khối u ác tính về huyết học, ung thư phổi và bệnh ung thư di căn khác)',
      value:
        'Ung thư (đặc biệt là các khối u ác tính về huyết học, ung thư phổi và bệnh ung thư di căn khác)',
    },
    {
      disabled: false,
      label: 'Bệnh thận mạn tính',
      value: 'Bệnh thận mạn tính',
    },
    {
      disabled: false,
      label: 'Ghép tạng hoặc cấy ghép tế bào gốc tạo máu',
      value: 'Ghép tạng hoặc cấy ghép tế bào gốc tạo máu',
    },
    {
      disabled: false,
      label: 'Bệnh tim mạch (suy tim, bệnh động mạch vành hoặc bệnh cơ tim)',
      value: 'Bệnh tim mạch (suy tim, bệnh động mạch vành hoặc bệnh cơ tim)',
    },
    {
      disabled: false,
      label: 'Bệnh lý mạch máu não (đột quỵ, cơn thoáng thiếu máu não)',
      value: 'Bệnh lý mạch máu não (đột quỵ, cơn thoáng thiếu máu não)',
    },
    { disabled: false, label: 'Hội chứng Down', value: 'Hội chứng Down' },
    { disabled: false, label: 'HIV/AIDS', value: 'HIV/AIDS' },
    {
      disabled: false,
      label: 'Bệnh lý thần kinh, bao gồm cả chứng sa sút trí tuệ',
      value: 'Bệnh lý thần kinh, bao gồm cả chứng sa sút trí tuệ',
    },
    {
      disabled: false,
      label: 'Bệnh hồng cầu hình liềm',
      value: 'Bệnh hồng cầu hình liềm',
    },
    {
      disabled: false,
      label: 'Rối loạn sử dụng chất gây nghiện',
      value: 'Rối loạn sử dụng chất gây nghiện',
    },
    {
      disabled: false,
      label: 'Sử dụng corticosteroid hoặc các thuốc ức chế miễn dịch khác',
      value: 'Sử dụng corticosteroid hoặc các thuốc ức chế miễn dịch khác',
    },
    {
      disabled: false,
      label: 'Các loại bệnh hệ thống',
      value: 'Các loại bệnh hệ thống',
    },
    {
      disabled: false,
      label: 'Bệnh tăng huyết áp',
      value: 'Bệnh tăng huyết áp',
    },
    { disabled: false, label: 'Hen phế quản', value: 'Hen phế quản' },
    {
      disabled: false,
      label: 'Thiếu hụt miễn dịch',
      value: 'Thiếu hụt miễn dịch',
    },
    { disabled: false, label: 'Bệnh gan', value: 'Bệnh gan' },
    { disabled: false, label: 'Béo phì', value: 'Béo phì' },
    { disabled: false, label: 'Có thai', value: 'Có thai' },
  ];
  healthMeasuringDeviceSource = [
    { label: 'Nhiệt kế', value: 'Nhiệt kế', checked: false },
    { label: 'Máy đo huyết áp', value: 'Máy đo huyết áp', checked: false },
    { label: 'Máy đo SpO2', value: 'Máy đo SpO2', checked: false },
    {
      label: 'Không có',
      value: 'Không có',
      checked: false,
      otherValue: 'other',
    },
  ];
  internetTypeSource = [
    { label: 'Mạng dây', value: 'Mạng dây', checked: false },
    { label: 'Wifi', value: 'Wifi', checked: false },
    { label: '4G', value: '4G', checked: false },
    {
      label: 'Không truy cập được mạng',
      value: 'Không truy cập được mạng',
      checked: false,
      otherValue: 'other',
    },
  ];
  deviceTypeSource = [
    {
      label: 'Smart phone, máy tính bảng',
      value: 'Smart phone, máy tính bảng',
      checked: false,
    },
    { label: 'Máy tính', value: 'Máy tính', checked: false },
    { label: 'Laptop', value: 'Laptop', checked: false },
    {
      label: 'Thiết bị khác',
      value: 'Thiết bị khác',
      checked: false,
      otherValue: 'other',
    },
  ];
  listVaccinatedSource = [
    { label: 'Mũi 1', value: '', used: 1 },
    { label: 'Mũi 2', value: '', used: 2 },
    { label: 'Mũi 3', value: '', used: 3 },
  ];
  listVaccinated = [];
  hoSource: any[] = [
    { value: 0, label: 'Không' },
    { value: 1, label: 'Ho khan' },
    { value: 2, label: 'Ho có đàm' },
    { value: 3, label: 'Ho càng ngày càng nhiều hơn' },
    { value: 4, label: 'Ho ra máu' },
  ];

  matMuiMatViSource: any[] = [
    { value: 0, label: 'Không' },
    { value: 1, label: 'Mất mùi' },
    { value: 2, label: 'Mất vị' },
  ];

  dauDauSource: any[] = [
    { value: 0, label: 'Không' },
    { value: 1, label: 'Mới có' },
    { value: 2, label: 'Ngày càng nhiều' },
  ];

  khoThoSource: any[] = [
    { value: 0, label: 'Không' },
    { value: 1, label: 'Khó thở mới xuất hiện' },
    { value: 2, label: 'Khó thở ngày càng nhiều' },
    { value: 3, label: 'Khó thở khi vận động nhiều' },
    { value: 4, label: 'Khó thở khi nói chuyện' },
    { value: 5, label: 'Khó thở khi nghỉ ngơi' },
  ];

  chongMatSource: any[] = [
    { value: 0, label: 'Không' },
    { value: 1, label: 'Mới xuất hiện' },
    { value: 2, label: 'Ngày càng nhiều' },
  ];

  spO2Source: any[] = [
    { value: 0, label: '>96%' },
    { value: 1, label: '96%' },
    { value: 2, label: '94-95%' },
    { value: 3, label: '<94%' },
  ];
  userInfo: any;
  doctorInGroup: any[];
  covidGroups: any[] = [];
  isMobile: boolean;
  utmSourceName = {
    web: 'Web',
    'mobile web': 'Mobile Web',
    zalo: 'Zalo',
    app_mobile: 'Mobile App',
  };

  listProvince = [];
  listDistrict = [];
  listWard = [];
  isVisibleAddPatient = false;
  userCanReceive = false;
  provinceCodeDefault = this.configService.getConfig().provinceCode;

  listDoctorInCase: any[];
  listCoordinator: any[];
  listPharmacist: any[];
  listPsychiatrist: any[];
  listNursingInCase: any[];
  listVolunteer: any[];

  constructor(
    private covidService: CovidService,
    private notificationService: NotificationService,
    private doctorService: DoctorService,
    private store: Store<AppState>,
    private shareService: ShareService,
    private tabDataService: TabDataService,
    private appConfigService: AppConfigService,
    private customColumnService: CustomColumnService,
    private addressService: AddressService,
    private modalService: NzModalService,
    private configService: AppConfigService
  ) {
    this.reloadTime = this.appConfigService.getConfig().reloadTimeCovid
      ? this.appConfigService.getConfig().reloadTimeCovid
      : 120000;

    //le.huathi - temp rem get custom columns to fix after
    this.getColumns();
    this.cols = JSON.parse(JSON.stringify(defaultConfig));
    this.isMobile = window.innerWidth < 540;
    this.store
      .select((store) => store.auth)
      .subscribe((res) => {
        this.userInfo = res;
      });
  }

  ngOnInit(): void {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        if (!this.shareService.checkEmpty(data)) {
          this.selectedGroupId = data.groupId;
          //this.reloadDataTable();
          this.getDoctorInGroup(this.selectedGroupId);

          this.userCanReceive = data.doctorPermission.some(
            (en) => en == Constant.DOCTORPERMISSION.Coordinator
          );
        }
      }
    });

    this.lockScreenSub = this.store
      .select((store) => store.auth.isLock)
      .subscribe((data) => {
        if (this.intervalReloadGrid) {
          clearTimeout(this.intervalReloadGrid);
        }
        if (!data) {
          this.searchListPatient();
        }
      });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.intervalReloadGrid) {
      clearTimeout(this.intervalReloadGrid);
    }
    this.groupSub.unsubscribe();
    this.lockScreenSub.unsubscribe();
  }

  getColumns() {
    this.customColumnService.getCovidReceiveGridColumn().subscribe((res) => {
      if (res.isValid) {
        this.cols = [];
        if (res.jsonData.length == 0) {
          this.cols = JSON.parse(JSON.stringify(defaultConfig));
        } else {
          res.jsonData.forEach((element) => {
            let elm = defaultConfig.filter((t) => t.field == element.field)[0];
            if (!this.shareService.checkEmptyObj(elm)) {
              elm = { ...elm, ...element };
              this.cols.push(elm);
            }
          });
        }
        this.showCols = this.cols;
        this.hideCols = defaultConfig.filter(
          (x) => !this.showCols.map((t) => t.field).includes(x.field)
        );
      }
    });
  }
  showCustomColumn(): void {
    this.isCustomColumnVisible = true;
  }
  changeColumns(cols: WorklistColumn[]) {
    this.showCols = cols;
    this.saveColumns();
  }
  searchListPatient() {
    const $this = this;
    if (this.intervalReloadGrid) {
      clearTimeout(this.intervalReloadGrid);
    }

    this.intervalReloadGrid = setTimeout(function () {
      $this.searchListPatient();
    }, this.reloadTime);

    const payload = {
      fullName: this.search.fullName,
      status: this.search.status,
      verified: this.search.verified,
      pageSize: this.pageSize,
      page: this.pageIndex,
      groupId: this.selectedGroupId,
    };
    this.covidService.getListReceive(payload).subscribe((res) => {
      this.datas = res.source;
      this.total = res.total;
    });
  }
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.searchListPatient();
  }
  onResizeCloumn({ width }: NzResizeEvent, col: string): void {
    this.showCols = this.showCols.map((e) =>
      e.title === col ? { ...e, width: `${width}px` } : e
    );
    this.saveColumns();
  }
  toStatus(status) {
    switch (status) {
      case 0:
        return 'Chưa xử lý';
      case 1:
        return 'Đã tiếp nhận';
      case 2:
        return 'Đã từ chối';
    }
  }
  resetSearch() {
    this.search = {
      fullName: '',
      status: -1,
      verified: 0,
    };
    this.searchListPatient();
  }
  getRowIndex = (index, pageIndex, pageSize) =>
    index + 1 + pageSize * (pageIndex - 1);
  toTestCovid(result) {
    if (result === 1) {
      return 'Dương tính';
    } else if (result === 0) {
      return 'Âm tính';
    } else {
      return 'Chưa XN';
    }
  }
  toTestCovidStatus(result) {
    if (result === 1) {
      return 'error';
    } else if (result === 0) {
      return 'success';
    } else {
      return '';
    }
  }
  checkMyDiseases(diseases) {
    if (diseases == null) {
      return false;
    }
    if (diseases.length > 5) {
      return true;
    } else {
      return false;
    }
  }

  handleCancelDetail() {
    this.isVisibleDetail = false;
  }

  showDetail(item: any) {
    this.item = item;
    this.itemCovid = item.firstRecordCovid19;
    if (
      this.itemCovid &&
      this.itemCovid.trieuChungKhac &&
      !this.itemCovid.trieuChungNang.includes(this.itemCovid.trieuChungKhac)
    ) {
      this.itemCovid.trieuChungNang.push(this.itemCovid.trieuChungKhac);
    }
    this.bindVaccined();
    this.isVisibleDetail = true;
  }
  appNameChange($event: any[]) {
    const no = $event.find((en) => en.value === 'Không sử dụng' && en.checked);

    setTimeout(() => {
      if (no) {
        $event.forEach((en) => {
          if (en.value !== 'Không sử dụng') {
            en.checked = false;
          }
        });
      }
    }, 100);
  }
  toSourceText(str) {
    if (str == null) {
      return '';
    } else {
      return str.replace(/#/g, ', ');
    }
  }

  bindMutipleChoice() {
    if (this.itemEdit.appName != null) {
      const appNameSourceDb = this.itemEdit.appName.split('#');
      this.appNameSource.forEach((en) => {
        if (appNameSourceDb.includes(en.value)) {
          en.checked = true;
        }
      });
    }

    if (this.itemEdit.deviceType != null) {
      const deviceTypeSourceDb = this.itemEdit.deviceType.split('#');
      this.deviceTypeSource.forEach((en) => {
        if (deviceTypeSourceDb.includes(en.value)) {
          en.checked = true;
        }
      });
    }

    const myDiseasesSourceDb = this.itemEdit.myDiseases.split('#');
    this.listOfTagDiseases = [];
    this.myDiseasesSource.forEach((en) => {
      if (myDiseasesSourceDb.includes(en.value)) {
        this.listOfTagDiseases.push(en.value);
      }
      this.currentDiseasesChecked = [];
    });

    if (this.itemEdit.healthMeasuringDevice != null) {
      const healthMeasuringDeviceSourceDb =
        this.itemEdit.healthMeasuringDevice.split('#');
      this.healthMeasuringDeviceSource.forEach((en) => {
        if (healthMeasuringDeviceSourceDb.includes(en.value)) {
          en.checked = true;
        }
      });
    }

    if (this.itemEdit.internetType != null) {
      const internetTypeSourceDb = this.itemEdit.internetType.split('#');
      this.internetTypeSource.forEach((en) => {
        if (internetTypeSourceDb.includes(en.value)) {
          en.checked = true;
        }
      });
    }
    this.checkboxGroupChange(this.healthMeasuringDeviceSource, 'other');
    this.checkboxGroupChange(this.deviceTypeSource, 'other');
    this.checkboxGroupChange(this.internetTypeSource, 'other');
  }
  showEditForm() {
    this.itemEdit = Object.assign({}, this.item);
    this.isVisibleEdit = true;
    this.bindMutipleChoice();
    this.bindVaccined();

    // tỉnh/huyện/xã
    if (!this.shareService.checkEmpty(this.itemEdit.provinceId)) {
      this.getProvince();
      this.getDistrictByProvinceId(this.itemEdit.provinceId);
      this.getWardByDistrictId(this.itemEdit.districtId);
    }
  }

  handleCancelEdit() {
    this.isVisibleEdit = false;
    this.refreshCheckboxGroup(this.healthMeasuringDeviceSource);
    this.refreshCheckboxGroup(this.deviceTypeSource);
    this.refreshCheckboxGroup(this.internetTypeSource);
  }

  updateInfo() {
    if (this.itemEdit.height != null && this.itemEdit.height <= 0) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.WARRING,
        'Bạn cần nhập Chiều cao hợp lệ'
      );
      return;
    }
    if (this.itemEdit.weight != null && this.itemEdit.weight <= 0) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.WARRING,
        'Bạn cần nhập Cân nặng hợp lệ'
      );
      return;
    }
    const appName = [];
    this.appNameSource.forEach((en) => {
      if (en.checked) {
        appName.push(en.value);
      }
    });

    const myDiseases = this.listOfTagDiseases;

    const internetType = [];
    this.internetTypeSource.forEach((en) => {
      if (en.checked) {
        internetType.push(en.value);
      }
    });

    const healthMeasuringDevice = [];
    this.healthMeasuringDeviceSource.forEach((en) => {
      if (en.checked) {
        healthMeasuringDevice.push(en.value);
      }
    });

    const deviceType = [];
    this.deviceTypeSource.forEach((en) => {
      if (en.checked) {
        deviceType.push(en.value);
      }
    });
    const listVaccinated = [];
    this.listVaccinated.forEach((en) => {
      listVaccinated.push(en.value);
    });

    this.itemEdit.healthMeasuringDevice = healthMeasuringDevice.join('#');
    this.itemEdit.appName = appName.join('#');
    this.itemEdit.internetType = internetType.join('#');
    this.itemEdit.myDiseases = myDiseases.join('#');
    this.itemEdit.deviceType = deviceType.join('#');
    this.itemEdit.listVaccinated = listVaccinated;

    if (this.itemEdit.addressType === 'Khác') {
      this.itemEdit.addressType = this.itemEdit.addressTypeOther;
    }
    /* if (this.itemEdit.deviceType === 'Khác') {
       this.itemEdit.deviceType = this.itemEdit.deviceTypeOther;
     }*/

    //tỉnh/huyện/xã
    if (!this.shareService.checkEmpty(this.itemEdit.provinceId)) {
      this.itemEdit.provinceName = this.listProvince.filter(
        (en) => en.id == this.itemEdit.provinceId
      )[0].fullname;
      this.itemEdit.districtName = this.listDistrict.filter(
        (en) => en.id == this.itemEdit.districtId
      )[0].name;
      this.itemEdit.wardName = this.listWard.filter(
        (en) => en.id == this.itemEdit.wardId
      )[0].name;
    }
    if (!this.itemEdit.haveAllergies) {
      this.itemEdit.allergic = '';
    }
    this.addVolunteerNameToForm();
    this.covidService.updateInfo(this.itemEdit).subscribe((res) => {
      this.isVisibleEdit = false;
      this.item = this.itemEdit;
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.SUCCESS,
        'Cập nhật thành công'
      );
      this.searchListPatient();
    });
  }
  addVolunteerNameToForm() {
    if (this.itemEdit.volunteer != '') {
      const volunteer = this.listVolunteer.find(
        (v) => v.id == this.itemEdit.volunteer
      );
      if (volunteer) {
        this.itemEdit.volunteerName = volunteer.fullName;
      }
    } else {
      this.itemEdit.volunteerName = '';
    }
  }
  async toReceiveForm() {
    this.getMyCovidGroups();
    this.getDoctorInGroup(this.selectedGroupId);
    this.receivingGroupId = this.selectedGroupId;
    this.beginDateTreatment = new Date();
    this.isVisibleReceive = true;
    this.itemEdit = Object.assign({}, this.item);
    this.bindMutipleChoice();
  }

  onChangeReceivingGroup(item) {
    this.itemEdit.coordinatorInCare = '';
    this.itemEdit.doctorInCareDoct = '';
    this.itemEdit.nursingInCare = '';
    this.itemEdit.volunteer = '';
    this.itemEdit.pharmacistInCare = '';
    this.itemEdit.psychologistInCare = '';
    this.getDoctorInGroup(item);
  }
  addTab(id, PatientName, patientId) {
    this.tabDataService.updateTab(id, PatientName, 'VisitDetail', patientId);
  }
  toDeny() {
    const lydo = prompt(
      `Bạn có chắc muốn TỪ CHỐI tiếp nhận bệnh nhân ${this.item.fullName.toUpperCase()} không? Lý do`,
      'Lý do từ chối'
    );
    if (lydo) {
      this.covidService.toDenied(this.item.id, lydo).subscribe((res) => {
        this.isVisibleDetail = false;
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          `Từ chối thành công`
        );
        this.searchListPatient();
      });
    }
  }

  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;
        // console.log("doctorInGroup", this.doctorInGroup);

        this.listDoctorInCase = this.doctorInGroup.filter(
          (en) =>
            en.doctorPermissions != null &&
            en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Doctor)
        );

        this.listCoordinator = this.doctorInGroup.filter(
          (en) =>
            en.doctorPermissions != null &&
            en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Coordinator)
        );

        this.listPharmacist = this.doctorInGroup.filter(
          (en) =>
            en.doctorPermissions != null &&
            en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Pharmacist)
        );

        this.listPsychiatrist = this.doctorInGroup.filter(
          (en) =>
            en.doctorPermissions != null &&
            en.doctorPermissions.includes(
              Constant.DOCTORPERMISSION.Psychiatrist
            )
        );

        this.listNursingInCase = this.doctorInGroup.filter(
          (en) =>
            en.doctorPermissions != null &&
            en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Nursing)
        );

        this.listVolunteer = this.doctorInGroup.filter(
          (en) =>
            en.doctorPermissions != null &&
            en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Volunteer)
        );
      }
    });
  }

  getMyCovidGroups() {
    this.doctorService.getMyCovidGroups().subscribe((res) => {
      this.covidGroups = res;
    });
  }

  checkAppOther() {
    const other = this.appNameSource.find(
      (en) => en.value === 'Khác' && en.checked
    );
    if (other) {
      return true;
    } else {
      return false;
    }
  }
  toOneChoiceValue(value, source: any[]) {
    const option = source.find((en) => en.value === value);
    if (option) {
      return option.label;
    } else {
      return '';
    }
  }
  toMultiChoiceValue(value, source: any[]) {
    const option = source.find((en) => en.value === value);
    if (option) {
      return option.label;
    } else {
      return '';
    }
  }

  updateAndReceiveInfo() {
    if (this.shareService.checkEmpty(this.beginDateTreatment)) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Ngày bắt đầu điều trị không được để trống!'
      );
      return;
    }
    const c = confirm(
      `Bạn có chắc muốn TIẾP NHẬN bệnh nhân ${this.item.fullName.toUpperCase()} không`
    );
    if (c) {
      this.isSaving = true;
      this.addVolunteerNameToForm();
      const payload = {
        ...this.itemEdit,
        ...{
          groupId: this.receivingGroupId,
          beginDateTreatment: this.beginDateTreatment,
        },
      };
      this.covidService.updateInfo(payload).subscribe((resUpdate) => {
        if (!resUpdate.isValid) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            `Tiếp nhận bệnh nhân ${this.item.fullName.toUpperCase()} không thành công`
          );
          return;
        }

        const source = this.isMobile ? 2 : 1;
        this.covidService
          .toReceived(this.itemEdit.id, this.receivingGroupId, source)
          .subscribe((res) => {
            this.isSaving = false;
            if (res && res.isValid) {
              this.isVisibleDetail = false;
              this.isVisibleReceive = false;
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.SUCCESS,
                `Tiếp nhận bệnh nhân ${this.item.fullName.toUpperCase()} thành công`
              );

              if (res.jsonData.autoGenUsername) {
                this.isVisitablePatientAccount = true;
                this.patientAccount.username = res.jsonData.autoGenUsername;
                this.patientAccount.password = res.jsonData.autoGenPassword;
                this.patientAccount.visitId = res.jsonData.visitId;
                this.patientAccount.patientName = res.jsonData.patientName;
                this.patientAccount.patientId = res.jsonData.patientId;
              } else {
                this.isVisitablePatientAccount = false;
                this.patientAccount.username = '';
                this.patientAccount.password = '';
                this.patientAccount.patientId = '';
                this.patientAccount.patientName = '';
                this.patientAccount.visitId = '';
                this.searchListPatient();
                //TH khác nhóm k mở tab mới để tránh lỗi
                if (this.receivingGroupId == this.selectedGroupId) {
                  this.addTab(
                    res.jsonData.visitId,
                    res.jsonData.patientName,
                    res.jsonData.patientId
                  );
                }
              }
            } else {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.ERROR,
                `Tiếp nhận bệnh nhân ${this.item.fullName.toUpperCase()} không thành công`
              );
              // this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
            }
          });
      });
    }
  }

  closePatientAccountModal() {
    this.isVisitablePatientAccount = false;
    this.searchListPatient();
    //TH khác nhóm k mở tab mới để tránh lỗi
    if (this.receivingGroupId == this.selectedGroupId) {
      this.addTab(
        this.patientAccount.visitId,
        this.patientAccount.patientName,
        this.patientAccount.patientId
      );
    }
  }

  handleCancelReceive() {
    this.isVisibleReceive = false;
  }
  saveColumns() {
    const columns = this.showCols.map((t) => ({
      field: t.field,
      width: t.width,
    }));
    const payload = { columns };
    this.customColumnService
      .savetCovidReceiveGridColumn(payload)
      .subscribe((res) => {
        if (res.isValid) {
          this.hideCols = defaultConfig.filter(
            (x) => !this.showCols.map((t) => t.field).includes(x.field)
          );
        }
      });
  }
  onChangeDiseases() {
    const khongco = this.myDiseasesSource[0].value;
    if (this.listOfTagDiseases.some((en) => en == khongco)) {
      this.currentDiseasesChecked = this.listOfTagDiseases.filter(
        (en) => en != khongco
      );
      this.listOfTagDiseases = [khongco];
      this.myDiseasesSource.forEach((en) => {
        if (en.value != khongco) {
          en.disabled = true;
        }
      });
    } else {
      if (this.currentDiseasesChecked.length != 0) {
        this.listOfTagDiseases = this.currentDiseasesChecked;
      }
      this.myDiseasesSource.forEach((en) => {
        en.disabled = false;
      });
    }
  }
  BMIValue() {
    const h = this.itemEdit.height;
    const w = this.itemEdit.weight;
    if (h != null && h != 0 && w != null && w != 0) {
      return (w / ((h / 100) * (h / 100))).toFixed(1);
    }
    return 0;
  }

  copyMessage(val: string, message: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationService.showNotification(Constant.SUCCESS, message);
  }
  getProvince() {
    this.addressService.getProvince().subscribe((res) => {
      if (res.isValid && res.jsonData.length > 0) {
        // nếu có config provinceCode
        if (this.provinceCodeDefault.length > 0) {
          this.provinceCodeDefault.forEach((element) => {
            const province = res.jsonData.filter((en) => en.code == element)[0];
            this.listProvince.push(province);
          });
        } else {
          this.listProvince = res.jsonData;
        }
      }
    });
  }
  getDistrictByProvinceId(id) {
    this.addressService.getDistrictByProvinceId(id).subscribe((res) => {
      if (res.isValid) {
        this.listDistrict = res.jsonData;
      }
    });
  }
  getWardByDistrictId(id) {
    this.addressService.getWardByProvinceId(id).subscribe((res) => {
      if (res.isValid) {
        this.listWard = res.jsonData;
      }
    });
  }
  onChangeProvinceId(id) {
    this.itemEdit.districtId = '';
    this.itemEdit.wardId = '';
    this.getDistrictByProvinceId(id);
  }
  onChangeByDistrictId(id) {
    this.itemEdit.wardId = '';
    this.getWardByDistrictId(id);
  }
  toTextReceiveType(type) {
    if (type == 1) {
      return 'Email';
    } else if (type == 2) {
      return 'Điện thoại';
    } else {
      return '';
    }
  }
  showModalAddPatient() {
    this.isVisibleAddPatient = true;
  }
  closeModalAddPatient() {
    this.isVisibleAddPatient = false;
  }
  refreshDataAddPatient() {
    this.search.status = -1;
    this.searchListPatient();
    this.isVisibleAddPatient = false;
  }
  verifyInfo() {
    this.modalService.confirm({
      nzTitle: '<b>Xác nhận</b>',
      nzContent: 'Bạn có chắc muốn xác thực thông tin của bệnh nhân không?',
      nzOkText: 'Xác nhận',
      nzCancelText: 'Không',
      nzOnOk: () => {
        this.covidService.verifyInfo(this.item.id, true).subscribe((res) => {
          if (res.isValid) {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.SUCCESS,
              'Xác thực thông tin thành công'
            );
            this.item.verified = true;
            this.isVisibleDetail = false;
          } else {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.ERROR,
              res.errors[0].errorMessage
            );
          }
        });
      },
    });
  }
  vaccinatedChange(vaccinated) {
    let result = 0;
    switch (vaccinated) {
      case '1 mũi':
        result = 1;
        break;
      case '2 mũi':
        result = 2;
        break;
      case '3 mũi':
        result = 3;
        break;
      case 'Chưa':
      case 'null':
        result = 0;
        break;
      default:
        result = 0;
        break;
    }
    this.listVaccinated = this.listVaccinatedSource.filter(
      (en) => en.used <= result
    );
  }
  bindVaccined() {
    this.listVaccinated = [];
    this.listVaccinatedSource.forEach((en) => (en.value = ''));
    if (this.item.listVaccinated != null) {
      let i = 0;
      this.item.listVaccinated.forEach((element) => {
        const vaccine = this.listVaccinatedSource[i];
        vaccine.value = element;
        this.listVaccinated.push(vaccine);
        i++;
      });
    }
  }
  checkboxGroupChange(source, option) {
    const khong = source
      .filter((en) => en.checked)
      .some((en) => en.otherValue == option);
    if (khong) {
      source.forEach((en) => {
        if (en.otherValue != option) {
          en.disabled = true;
          en.checked = false;
        }
      });
    } else {
      source.forEach((en) => {
        en.disabled = false;
      });
    }
  }
  refreshCheckboxGroup(source) {
    source.forEach((en) => {
      en.checked = false;
      en.disabled = false;
    });
  }
}
