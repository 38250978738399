import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class VisitLogService extends BaseService {
  getLog(id: string): Observable<any> {
    return this.post(UrlConstant.GET_LOG + id, {});
  }
}
