<div class="fit-form">
  <input
    class="form-control"
    placeholder="dd/mm/yyyy"
    name="d2"
    [(ngModel)]="date"
    ngbDatepicker
    #d2="ngbDatepicker"
    (dateSelect)="dateChangeEvent($event)" />
  <div class="input-group-append">
    <button
      class="btn btn-outline-secondary calendar"
      (click)="d2.toggle()"
      type="button"></button>
  </div>
</div>
