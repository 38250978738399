<ng-container [ngSwitch]="data.observationType.dataType" style="height: 100%">
  <app-input-number
    *ngSwitchCase="constant.DATATYPENUMBER.NUMBER"
    [data]="data"></app-input-number>
  <app-input-element
    *ngSwitchCase="constant.DATATYPENUMBER.STRING"
    [data]="data"></app-input-element>
  <app-checkbox-element
    *ngSwitchCase="constant.DATATYPENUMBER.BOOLEAN"
    [data]="data"></app-checkbox-element>
  <app-checkbox-element
    *ngSwitchCase="constant.DATATYPENUMBER.POSITIVENEGATIVE"
    [data]="data"></app-checkbox-element>
  <app-datatime-element
    *ngSwitchCase="constant.DATATYPENUMBER.DATE"
    [data]="data"></app-datatime-element>
  <app-datatime-element
    *ngSwitchCase="constant.DATATYPENUMBER.DATETIME"
    [data]="data"></app-datatime-element>
  <app-datatime-element
    *ngSwitchCase="constant.DATATYPENUMBER.TIME"
    [data]="data"></app-datatime-element>
</ng-container>
<app-dropdown-element
  *ngIf="data.viewType == 'dropdown'"
  [data]="data"></app-dropdown-element>
<app-selection-element
  *ngIf="data.viewType == 'selection'"
  [data]="data"></app-selection-element>
<div class="active-elm"></div>
