<nz-modal class="modal-obser" [nzWidth]="800" [(nzVisible)]="popupData.isVisible" nzTitle="Thông tin khác của ca khám"
  [nzClosable]="false" [nzMask]="false" nzDraggable>
  <div [formGroup]="visitForm">
    <!-- <input type="hidden" formControlName="visitId" /> -->

    <div class="row">
      <label class="col-3">Lý do khám</label>
      <div class="col-9">
        <input type="text" nz-input formControlName="visitReason" />
      </div>
    </div>

    <div class="row mt-2">
      <label class="col-3">Bệnh sử</label>
      <div class="col-9">
        <input nz-input formControlName="medicalHistory">
      </div>
    </div>

    <div class="row mt-2">
      <label class="col-3">Các thuốc đã dùng</label>
      <div class="col-9">
        <input type="text" nz-input formControlName="medicationsUsed" />
      </div>
    </div>

    <div class="row mt-2">
      <label class="col-3">Thăm khám</label>
      <div class="col-9">
        <!-- <textarea nz-input formControlName="examination"></textarea> -->
        <angular-editor [config]="editorConfig" formControlName="examination"
          [ngStyle]="{ 'background-color': '#ffffff' }"></angular-editor>
      </div>
    </div>

    <div class="row mt-2">
      <label class="col-3">Phương pháp điều trị</label>
      <div class="col-9">
        <!-- <textarea nz-input formControlName="treatments"></textarea> -->
        <angular-editor [config]="editorConfig" formControlName="treatments"
          [ngStyle]="{ 'background-color': '#ffffff' }"></angular-editor>

      </div>
    </div>

    <div class="row mt-2">
      <label class="col-3">Tình trạng người bệnh hiện tại</label>
      <div class="col-9">
        <textarea nz-input formControlName="currentCondition"></textarea>
      </div>
    </div>

    <div class="row mt-2">
      <label class="col-3">Kế hoạch điều trị và chăm sóc tiếp theo</label>
      <div class="col-9">
        <textarea nz-input formControlName="nextTreatmentPlan"></textarea>
      </div>
    </div>

    <div class="row mt-2">
      <label class="col-3">Lời dặn</label>
      <div class="col-9">
        <textarea nz-input formControlName="advice"></textarea>
      </div>
    </div>

    <div class="row mt-2">
      <label class="col-3">Ngày hẹn tái khám</label>
      <div class="col-9">
        <nz-date-picker formControlName="nextAppointmentDate" nzFormat="dd/MM/yyyy"></nz-date-picker>
      </div>
    </div>
  </div>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="popupData.isVisible =false">Huỷ</button>
    <button nz-button nzType="primary" (click)="UIsaveInfo()">Lưu</button>
  </div>
</nz-modal>
