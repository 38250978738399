import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { NzResizeEvent } from 'ng-zorro-antd/resizable';

@Component({
  selector: 'app-textarea-resize',
  templateUrl: './textarea-resize.component.html',
  styleUrls: ['./textarea-resize.component.scss'],
})
export class TextareaResizeComponent implements OnInit, AfterViewInit {
  width = 400;
  height = 30;
  canMove = false;
  id = -1;

  @Input() model: string;
  @Input() isDisabled: boolean;
  @Input() minHeight = 30;
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  constructor(private elementRef: ElementRef, private render: Renderer2) {}

  getResizeItem() {
    return this.elementRef.nativeElement.querySelector(`.resize`);
  }

  getWrapperItem() {
    return this.elementRef.nativeElement.querySelector(`.txt-area`);
  }

  geTextareaItem() {
    return this.elementRef.nativeElement.querySelector(`.textarea`);
  }

  ngOnInit() {}

  modelChangeValue(val) {
    this.modelChange.emit(val);
  }

  ngAfterViewInit() {
    const resizeItem = this.getResizeItem();
    const wrapperItem = this.getWrapperItem();
    const textareaItem = this.geTextareaItem();
    if (resizeItem) {
      this.height = wrapperItem.offsetHeight;
    }

    const $this = this;

    // this.render.listen(wrapperItem, 'mouseup', function(event){
    //   console.log("mouseup")
    //   $this.canMove = false;
    // }.bind(this));

    this.render.listen(
      window,
      'mouseup',
      function (event) {
        console.log('mouseup');
        $this.canMove = false;
      }.bind(this)
    );

    this.render.listen(
      resizeItem,
      'mousedown',
      function (event) {
        console.log('mousedown');
        $this.canMove = true;
      }.bind(this)
    );

    this.render.listen(
      resizeItem,
      'mousemove',
      function (event) {
        console.log(event);
        if (wrapperItem instanceof HTMLElement && $this.canMove) {
          // if(this.height + event.offsetY >= this.minHeight){
          //   this.height += event.offsetY;
          //   $this.render.setStyle(textareaItem, "height", `${$this.height}px`);
          // }
          const position = wrapperItem.getBoundingClientRect();
          const height = event.clientY - position.top;
          console.log(height);
          $this.height = height;
          $this.render.setStyle(wrapperItem, 'height', `${$this.height}px`);
        }
      }.bind(this)
    );
  }
}
