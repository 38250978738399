<div class="title-wrapper">
  <!-- <p class="page-title">Quản trị Danh sách Category theo ROS</p> -->
  <div class="head-obsertype">
    <div class="box-searchObser d-flex">
      <input
        style="height: 32px"
        nz-input
        type="text"
        [(ngModel)]="searchKey"
        placeholder="Tìm kiếm..."
        (keydown.enter)="searchName()" />
      <button
        class=""
        style="border-radius: 0px 5px 5px 0px"
        nz-button
        nzType="primary"
        (click)="searchName()">
        <i nz-icon nzType="search" nzTheme="outline"></i>
      </button>
    </div>
    <div class="table-operations">
      <button class="mg-r-10 btn-green" nz-button (click)="showModal('them')"> 
        <i nz-icon nzType="plus"></i>Thêm mới
      </button>
      <button
        [disabled]="selectedRowIndex == emptyId"
        class="mg-r-10"
        nz-button
        nzType="primary"
        (click)="showModal(dataForm)">
        <i nz-icon nzType="form"></i>Sửa
      </button>

    </div>
  </div>
  <nz-table
    #fixedTable
    [nzData]="listOfDisplayData"
    nzSize="small"
    [nzPageSize]="pageSize"
    [nzShowPagination]="true"
    nzShowSizeChanger
    [nzPageSizeOptions]="[10, 20, 50, 100]"
    [nzScroll]="{ y: 'calc(100vh - 280px)' }">
    <thead>
      <tr>
        <th nzWidth="60px">STT</th>
        <th nzWidth="40px"></th>
        <th>Name</th>
        <th>CategoryId</th>
        <th
          nzShowFilter
          [nzFilters]="filterRosName"
          (nzFilterChange)="search($event)"
          [nzFilterMultiple]="true">
          ROS
        </th>
        <th>Description</th>
        <th>Parent ROS</th>
        <th>Index</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let data of fixedTable.data; let i = index"
        [ngClass]="{ highlight: selectedRowIndex == data.id }"
        (click)="rowSelected(data)">
        <td>
          {{ fixedTable.nzPageSize * (fixedTable.nzPageIndex - 1) + i + 1 }}
        </td>
        <td (click)="updateStatus(data)">
          <i
            *ngIf="!data.isDisabled"
            nz-icon
            nzType="check-circle"
            [nzTheme]="'twotone'"
            [nzTwotoneColor]="'#52c41a'"></i>
          <i
            *ngIf="data.isDisabled"
            nz-icon
            nzType="stop"
            [nzTheme]="'twotone'"
            [nzTwotoneColor]="'#ff0000'"></i>
        </td>
        <td>{{ data.name }}</td>
        <td>{{ data.id }}</td>
        <td>{{ data.ros }}</td>
        <td>{{ data.des }}</td>
        <td>{{ data.parentName }}</td>
        <td>{{ data.order }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>

<nz-modal
  class="modal-admin"
  [(nzVisible)]="isVisible"
  nzTitle="Phân loại ROS"
  nzDraggable="true"
  nzMaskClosable="false"
  (nzClosable)="(true)"
  (nzOnCancel)="handleCancel()">
  <div class="msc-admin-row">
    <div class="msc-group-control width-obser">
      <label>Name <span class="red-dot">*</span></label>
      <input type="text" [(ngModel)]="dataForm.name" />
    </div>
  </div>
  <div class="msc-admin-row">
    <div class="msc-group-control width-obser">
      <label>Mô tả</label>
      <textarea [(ngModel)]="dataForm.des" style="height: 60px">{{
        dataForm.des
      }}</textarea>
    </div>
  </div>
  <div class="msc-admin-row">
    <div class="msc-group-control width-obser">
      <label>ROS <span class="red-dot">*</span></label>
      <select
        [(ngModel)]="dataForm.rosid"
        name="ros"
        (ngModelChange)="loadRosCateGroup($event)">
        <option *ngFor="let data of rosData" [value]="data.id">
          {{ data.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="msc-admin-row">
    <div class="msc-group-control width-obser">
      <label>ROS Category Parent</label>
      <select [(ngModel)]="dataForm.parentId" name="rosCategoryId">
        <option [ngValue]="emptyId">---Chọn ROS Category Parent---</option>
        <option *ngFor="let data of ros_rosCateData" [ngValue]="data.id">
          {{ data.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="msc-admin-row">
    <div class="msc-group-control width-obser">
      <label>Index</label>
      <input type="number" [(ngModel)]="dataForm.order" />
    </div>
  </div>

  <div *nzModalFooter>
    <!-- <button
      *ngIf="selectedRowIndex != emptyId"
      nz-button
      nzType="primary"
      (click)="updateRosCategory(dataForm)">
      Sửa
    </button> -->
    <button
    *ngIf="!isEditing && selectedRowIndex == emptyId"
      nz-button
      nzType="primary"
      (click)="AddOrSave(dataForm)">
      Thêm
    </button>
    <button
        *ngIf="isEditing"
        nz-button
        nzType="primary"
        (click)="updateRosCategory(dataForm)">
         Sửa
    </button>

    <button nz-button nzType="default" (click)="handleCancel()">Huỷ</button>
  </div>
</nz-modal>
