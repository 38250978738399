import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AppConfigService } from 'src/app-config.service';

@Component({
  selector: 'app-chat-image',
  templateUrl: './chat-image.component.html',
  styleUrls: ['./chat-image.component.scss'],
})
export class ChatImageComponent implements OnInit {
  _data: any;
  @Input() set data(val) {
    if (val) {
      this._data = val;
    }
    /*if(val){
      this._data = val;
      if (val.imageType === 'temp'){
        // todo
      }
      else if (val.src) {
        let containBaseUrl = val.src.includes(this.configBaseUrl);
        if(!containBaseUrl){
          this._data.src = this.configBaseUrl + val.src;
        }
      }
    }*/
  }

  get data() {
    const val = this._data;
    if (val) {
      if (val.isTemp) {
        // todo
      } else if (val.src) {
        const containBaseUrl = val.src.includes(this.configBaseUrl);
        if (!containBaseUrl) {
          val.src = this.configBaseUrl + val.src;
        }
      }
    }
    return val;
  }

  @Output() showImageEvent = new EventEmitter();
  @ViewChild('imageWrapper') imageWrapper: ElementRef;

  configBaseUrl = '';
  constructor(protected configService: AppConfigService) {
    this.configBaseUrl = this.configService.getConfig().media.baseUrlOnly;
  }

  ngOnInit() {}

  showImage() {
    this.showImageEvent.emit(this.data);
  }

  showImageInMessage() {
    const image = this.imageWrapper.nativeElement.querySelector('img');
    if (image) {
      this.showImageEvent.emit({ ...this.data, ...{ src: image.src } });
    }
  }
}
