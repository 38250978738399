<div *ngIf="isVisible" class="lock-screen">
  <div class="lock-wrapper">
    <div>
      <img
        class="avt-img"
        src="https://image.freepik.com/free-vector/doctor-icon-avatar-white_136162-58.jpg" />
    </div>
    <div class="login-form-wrapper">
      <div class="username">
        <span>{{ username }}</span>
      </div>
      <div class="status">
        <i nz-icon nzType="lock" nzTheme="outline"></i>
        <span>&ensp; Đã khóa</span>
      </div>
      <form
        nz-form
        [nzLayout]="'vertical'"
        [formGroup]="validateForm"
        (ngSubmit)="submitForm()"
        class="login-form">
        <nz-form-item>
          <nz-form-control nzErrorTip="Bạn chưa nhập mật khẩu!">
            <nz-input-group>
              <input
                formControlName="password"
                nz-input
                type="password"
                placeholder="Mật khẩu" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <div *ngIf="messageError">
            <div class="error-message">
              {{ messageError }}
            </div>
          </div>
          <nz-form-control>
            <button nz-button nzType="primary" [nzLoading]="isLoading">
              Đăng nhập
            </button>
          </nz-form-control>
          <div class="other-account" (click)="changeAccount()">
            Không phải <span class="not-me">{{ username }}</span
            >?
          </div>
        </nz-form-item>
      </form>
    </div>
  </div>
</div>
