<nz-tabset [nzType]="'card'" ngClass="small-tab tabset-default" [(nzSelectedIndex)]="selectTabIndex" style="margin-top: 10px;">
  <nz-tab nzTitle="Thông tin ca khám">
    <app-visit-info [visit]="visit" [subClinicalVisit]="" [rooms]="roomsAdditionVisit" [groupId]="curGroupId"
      [disabledEditInfo]="disabledEditInfo" (lstRelatedVisitChange)="getRelatedVisitChange($event)"
      (visitIdChange)="visitIdEvent($event)" (editingFlagChange)="editingFlagEvent($event)"
      (outSubClinicalVisit)="getSubClinicalVisit($event)"
      (titleTabRefresh)="onTitleTabRefresh($event)"></app-visit-info>
  </nz-tab>
  <nz-tab nzTitle="KQ Xét nghiệm {{ totalElementTab.Test }}">
    <ng-template nz-tab>
      <app-xetnghiem-tab [visit]="visit" [subClinicalXNs]="subClinicalXNs" [disabledAction]="disabledBtnActiton"
        (titleTabRefresh)="onTitleTabRefresh($event)">
      </app-xetnghiem-tab>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="CĐHA {{ totalElementTab.DiagnosticImaging }}">
    <ng-template nz-tab>
      <app-tab-CDHA [visit]="visit" (titleTabRefresh)="onTitleTabRefresh($event)"> </app-tab-CDHA>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="Đơn thuốc {{ totalElementTab.Prescription }}">
    <ng-template nz-tab>
      <div style="border-top: 0px; overflow: auto; max-height: calc(-323px + 100vh);">
        <app-prescription-tab [visit]="visit" [disabledActiton]="disabledBtnActiton"
          (titleTabRefresh)="onTitleTabRefresh($event)">
        </app-prescription-tab>
      </div>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="Thông tin lâm sàng">
    <ng-template nz-tab>
      <app-vital-signs-tab [visit]="visit"></app-vital-signs-tab>
    </ng-template>
  </nz-tab>
  <!-- <nz-tab nzTitle="Thông tin ca khám">
    <ng-template nz-tab>
      <app-tab-visit-info *ngIf="visit.id" [shareId]="shareId" [hideButton]="hideButton"
                      [disabledClass]="disabledClass" [visit]="visit" [(reloadNow)]="tab.reloadNow"
                      (vitalSign)="reloadTab($event)" (openPhieudl)="toTabForm($event)" (newVisit)="loadVisit($event)"
                      (openTab)="changeTabById($event)"
                      (reloadMasterNote)="reloadMasterNote($event)">
      </app-tab-visit-info>
    </ng-template>
  </nz-tab> -->
</nz-tabset>

<div class="btn-footer" style="margin-top: -62px">
  <div class="btn-left" style="position: relative;; margin-top: 0px;">
    <i style="margin-top: 8px" nz-icon class="mg-r-15" nzType="unordered-list" nzTheme="outline"></i>
    <div class="btn-vs-history" (click)="showModalHistory()">Lịch sử khám</div>
    <div class="btn-vs-log" (click)="showModalLog()">Log ca khám</div>
  </div>
  <div class="btn-visit-status" style="float: right">
    <button nz-button title="Alt + I" [disabled]="disabledBtnActiton" (click)="printOrder()">In chỉ định</button>
    <button *ngIf="!configGroup.autoConvert2InProgressVisit" nz-button title="Alt + M" [disabled]="disabledBtnActiton"
      (click)="onClickUpdateStatus(VISIT_STATUS.InProgress.value, false)">
      Chuyển CLS
    </button>
    <button nz-button title="Alt + P" (click)="doPrintConclusion()">Xuất BC y tế</button>
    <button nz-button title="Alt + C" [disabled]="disabledBtnActiton" [disabled]="disabledBtnActiton"
      (click)="onClickUpdateStatus(VISIT_STATUS.Finished.value, false)">
      Hoàn tất
    </button>
    <button nz-button title="Alt + D" [disabled]="disabledBtnUndoFinished"
      (click)="onClickUpdateStatus(VISIT_STATUS.InProgress.value, true)">
      Bỏ hoàn tất
    </button>
  </div>
</div>

<nz-modal nzClassName="m-print-order" [nzWidth]="1000" [(nzVisible)]="showOrderPrinterPreview" nzMaskClosable="false">
  <div class="print-content" [innerHTML]="printContents | safe"></div>
  <div *nzModalFooter>
    <button nz-button nzType="danger" (click)="closePrintPreview()">Đóng</button>
    <button nz-button nzType="primary" (click)="printNow()">In</button>
  </div>
</nz-modal>
<app-print-conclusion #printConclusion></app-print-conclusion>
<app-visit-history [(isVisible)]="isVisibleVisitHis" [visit]="visit" (loadVisit)="loadVisitById($event)">
</app-visit-history>

<app-visit-log [isVisible]="isVisibleVisitLog" [visit]="visit" (closeModal)="closeVisitLog()"></app-visit-log>

<nz-modal [nzVisible]="isOpenChangeVisitRoom" nzTitle="Thay đổi phòng khám" (nzOnCancel)="closeChangeVisitRoom()">
  <div class="modal-visit-room-body">
    <div>Chọn phòng khám cho ca khám hiện tại</div>
    <div class="row">
      <div class="col-8 select-visit-room">
        <nz-select [nzPlaceHolder]="" [(ngModel)]="currentVisitRoomId" [nzShowSearch]="true" style="width: 100%">
          <nz-option *ngFor="let room of rooms" [nzLabel]="room.name" [nzValue]="room.id"> </nz-option>
        </nz-select>
      </div>
    </div>
    <div *nzModalFooter>
      <button nz-button nzType="primary" (click)="submitVisitRoom()">Thay đổi</button>
    </div>
  </div>
</nz-modal>
