import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
})
export class TreeSelectComponent {
  @Input() tree = [];
  @Input() selectedNodeId = '';

  @Output() selectdNodeChange = new EventEmitter();

  objectIdEmpty = '000000000000000000000000';

  constructor() {}

  selectNode(node) {
    if (node.id != this.objectIdEmpty) {
      this.selectedNodeId = node.id;
      this.selectdNodeChange.emit(node);
    }
  }
  expandNode(node) {
    if (node.isExpand != null) {
      node.isExpand = !node.isExpand;
    }
  }
  nodeIdChangeEvent(node) {
    this.selectedNodeId = node.id;
    this.selectdNodeChange.emit(node);
  }
}
