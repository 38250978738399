import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-link',
  templateUrl: './chat-link.component.html',
  styleUrls: ['./chat-link.component.scss'],
})
export class ChatLinkComponent implements OnInit {
  _data: any;
  @Input() set data(val) {
    if (val) {
      this._data = this.urlify(val);
    }
  }

  get data() {
    return this._data;
  }

  constructor() {}

  ngOnInit() {}

  urlify(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return (
        '<a href="' +
        url +
        '" style="text-decoration: underline;" target="_blank">' +
        url +
        '</a>'
      );
    });
  }
}
