<nz-modal
  [(nzVisible)]="isVisible"
  [nzWidth]="400"
  nzTitle="Link chia sẻ ca khám"
  nzClassName="no-header">
  <div class="pd-12">
    <a class="link-share" href="{{ link }}">{{ link }}</a>
  </div>
  <div class="divider"></div>
  <div>
    <qrcode [qrdata]="link" [width]="400" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
  <div *nzModalFooter>
    <button
      nz-button
      nzType="primary"
      class="btn-add-color"
      (click)="copyInputUrl(link)">
      Copy link
    </button>
    <!-- <button nz-button (click)="handleOk()">Lưu</button> -->
    <button nz-button (click)="handleCancle()">Đóng</button>
  </div>
</nz-modal>
