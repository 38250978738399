export class Constant {
  public static readonly DATE_FMT = 'dd/MM/yyyy';
  public static readonly MESSAGE_DELETE_SUCCESS = 'Xóa thành công';
  public static readonly MESSAGE_ADD_SUCCESS = 'Tạo mới thành công';
  public static readonly MESSAGE_ADD_ERROR = 'Tạo mới thất bại';
  public static readonly MESSAGE_UPDATE_SUCCESS = 'Cập nhật thành công';
  public static readonly MESSAGE_UPDATE_ERROR = 'Cập nhật thất bại';
  public static readonly MESSAGE_SAVE_SUCCESS = 'Lưu thành công';
  public static readonly SUCCESS = 'success';
  public static readonly ERROR = 'error';
  public static readonly TOKEN = 'dp-token';
  public static readonly JITSI_PWD = 'jitsi-password';
  public static readonly FIREBASE_TOKEN = 'firebase-token';
  public static readonly LOCKSTATE = 'lock-state';
  public static readonly SESSION_INVITE_FIRST_USER_PMR = 'invite-first-user';
  public static readonly ACTION = 'action';
  public static readonly USER_INFO = 'user';
  public static readonly LOGIN_FAIL = 'Tên đăng nhập hoặc mật khẩu không chính xác';
  public static readonly DELETE = 'Xóa';
  public static readonly CREATE = 'Thêm';
  public static readonly UPDATE = 'Sửa';
  public static readonly WELCOME = 'common/worklist';
  public static readonly WELCOME_MOBILE = 'common-mobile/worklist/patient-list';
  public static readonly DAY_MILISECOND = 86400000; //1000*60*60*24;
  //public static readonly CURRENT_GROUP_INFO = 'curr-group';
  // public static readonly CURRENT_GROUPID = 'curr-group';
  // public static readonly CURRENT_GROUPNAME = 'curr-group-name';
  // public static readonly CURRENT_GROUPAVATAR = 'curr-group-avatar';

  // public static readonly NO_CURRENT_GROUP = 'Chưa chọn nhóm';
  public static readonly NOTIFY_TYPE = {
    SUCCESS: 'success',
    INFO: 'info',
    WARRING: 'warning',
    ERROR: 'error',
  };

  public static readonly GRID_FIELD_TYPE = {
    CI: 'CI',
    OT: 'OT',
  };

  /**
   * Form Attribute
   */
  public static readonly READONLY = 0;
  public static readonly EDITABLE = 1;
  public static readonly CALCULATED = 2;
  public static readonly RECENT_ELM_READONLY = 3;
  public static readonly RECENT_ELM_EDITABLE = 4;

  public static readonly DATATYPE = {
    NUMBER: 'Number',
    RANGE: 'Range',
    MORELESS: 'MoreLess',
    BOOLEAN: 'Boolean',
    STRING: 'String',
    TIME: 'Time',
    DATE: 'Date',
    DATETIME: 'DateTime',
    SINGLECHOICE: 'Single Choice',
    MUILTICHOICE: 'Multiple Choice',
    POSITIVENEGATIVE: 'Positive Negative',
    AGE: 'Age',
  };

  public static readonly DATATYPENUMBER = {
    NUMBER: 1,
    RANGE: 2,
    MORELESS: 3,
    BOOLEAN: 4,
    STRING: 5,
    TIME: 6,
    DATE: 7,
    DATETIME: 8,
    SINGLECHOICE: 9,
    MUILTICHOICE: 10,
    POSITIVENEGATIVE: 11,
    AGE: 99,
  };

  public static readonly FORMELEMENT = {
    LABEL: 'label',
    CHECKBOX: 'Checkbox',
    IMAGE: 'image',
    TEXTAREA: 'textarea',
    // DROPDOWN: 'dropdown',
    OBSERVATION: 'observation',
    OBSERVATION_RECENT: 'observationRecent',
    CANVAS: 'canvas',
    COMMON_INFO: 'commoninfo',
    FORMULA: 'formula',
    // SELECTION: 'selection',
  };

  public static readonly CLICK_TYPE = {
    SINGLE: 'single',
    DOUBLE: 'double',
  };

  public static readonly FORMELEMENTMAP = new Map([
    ['label', 'label'],
    ['Checkbox', 'Checkbox'],
    ['image', 'image'],
    ['textarea', 'textarea'],
    ['dropdown', 'dropdown'],
    ['observation', 'observation'],
    ['canvas', 'canvas'],
    ['commoninfo', 'commoninfo'],
    ['selection', 'selection'],
  ]);

  public static readonly IMAGETYPE = {
    IMAGE: 'Image',
    CANVAS: 'Canvas',
  };

  public static readonly USERROLE = {
    Patient: 0,
    Doctor: 1,
    ObservationType: 10,
    CommonInfoManagement: 10,
    FormMangament: 11,
    LibraryMangament: 12,
    SubclinicalOrderMangament: 13,
    MedicationMangament: 14,
    Admin: 90,
    SystemAdmin: 100,
    SystemLists: 55,
    TreatmentProcessManagement: 15,
    ViewStatistic: 16,
    MedicalInstructionMngt: 17,
    RulesManagermentMngt: 18,
  };

  public static readonly ROLETYPE = {
    AND: 0,
    OR: 1,
  };

  public static readonly GROUPROLE = {
    Owner: 1,
    Admin: 2,
    Member: 3,
  };

  public static readonly DOCTORPERMISSION = {
    Coordinator: 1,
    Doctor: 2,
    Pharmacist: 3,
    Psychiatrist: 4,
    Nursing: 5,
    EditPrescription: 6,
    ApprovePrescription: 7,
    UnapprovePrescription: 8,
    Volunteer: 10,
  };

  public static readonly NotificationType = {
    Visit: 0,
    Communication: 1,
    Calendar: 2,
    InviteGroup: 3,
    InviteRoom: 4,
    InvitePatientRoom: 10,
    Conversation: 11,
    ChatInVisit: 12,
    KickGroup: 99,
  };

  public static readonly FILTER_TYPE = {
    INPUT: 1,
    SELETECTION: 2,
    INPUT_NUMBER: 3,
    DATE_PICKER: 4,
  };

  public static readonly VARIABLE_TYPE = {
    COMMON_INFO: 1,
    OBSERVAION: 2,
    OBSERVAION_MOST_RECENT: 3,
    RECORD: 4,
  };

  public static readonly PrescriptionStatus = {
    New: 0,
    Retired: 1,
    EnterInError: 2,
    Saved: 5,
    Querying: 6,
    Denied: 8,
    Approved: 9,
    Exported: 10, //exported to HIS
    Received: 15,
    Cancelled: 20,
  };

  public static readonly UserRoleType = {
    Owner: 1,
    Admin: 2,
    Member: 3,
  };
  // Phạm vi sử dụng của đối tượng dữ liệu mẫu
  public static readonly PrivacyUse = {
    System: 1,
    Group: 2,
    OnlyMe: 3,
  };

  public static readonly ImageForm = {
    TabCDHA: 1,
    BoxGeneral: 2,
    BoxCDHA: 3,
  };

  public static readonly IndicationType = {
    XN: 1,
    CDHA: 2,
    GiaiPhauBenh: 3,
  };

  public static readonly GeneralBoxPrivacy = {
    OnlyMe: 1,
    ViewInGroup: 2,
    EditInGroup: 3,
    ToPatient: 4,
  };

  public static readonly VISIT_STATUS = {
    Planned: {
      value: -1,
      text: 'Đã đặt lịch',
    },
    Arrived: {
      value: 0,
      text: 'Chờ khám',
    },
    InProgress: {
      value: 1,
      text: 'Đang thực hiện',
    },
    Finished: {
      value: 2,
      text: 'Đã hoàn tất',
    },
    Cancelled: {
      value: 3,
      text: 'Đã hủy',
    },
  };
  public static readonly TEMPLATE_DRUG_LIST = {
    Table: {
      value: 1,
      label: 'Hiển thị dạng bảng',
    },
    List: {
      value: 2,
      label: 'Hiển thị dạng danh sách',
    },
  };
  public static readonly DEFAULT_TEMPLATE_DRUG_LIST = Constant.TEMPLATE_DRUG_LIST.Table.value;
  public static readonly VISIT_AGAIN_UNIT = ['Ngày', 'Tuần', 'Tháng', 'Năm'];
  public static readonly PRESCRIPTION_NOTE = 'Khám lại khi hết thuốc. Khi có dấu hiệu bất thường thì khám lại ngay.';
  // public static readonly TypeHeader = {
  //   Prescription: 1,
  //   SubclinicalOrder: 2,
  // };
  public static readonly PRIVACY_SUBCLINICALORDER = {
    OnlyMe: {
      value: 1,
      text: 'Chỉ mình tôi',
    },
    InGroup: {
      value: 2,
      text: 'Trong nhóm',
    },
  };
  public static readonly TabTitleInsideVisitDetail = {
    All: 0,
    VisitInfo: 1,
    DiagnosticImaging: 2,
    Test: 3,
    Prescription: 4,
    ClinicalInformation: 5,
  };

  public static readonly COMMON_WORKLIST = '/common/worklist';
  public static readonly ADMINPAGE = '/admin';
  public static readonly SUBCLINICAL_ORDER_STRING = {
    Laboratory: 'Laboratory',
    Imaging: 'Imaging',
    Procedure: 'Procedure',
    Other: 'Other',
  };

  public static readonly OrderPrintType = {
    Combine: 1,
    SplitByOrderType: 2,
    SplitByPlace: 3,
  };

  public static readonly DISCOUNT_TYPE = {
    Amount: 1,
    Percent: 2,
  };

  public static readonly SubClinicalVisitSync = {
    NotSync: 1, // Mới khởi tạo
    Waiting: 2, // Request để Sync nhưng chưa thành công
    Sync: 3, // Đã đồng bộ
  };

  public static readonly VisitType = {
    KhongChon: 0,
    KhamBenh: 1,
    DieuTri: 2,
    CanThiep: 3,
  };
  public static readonly SubClinicalSendType = {
    AfterUpdate: 1,
    ClickPrintOrChangeStatus: 2,
  };
  public static readonly SubClinicalType = {
    // TatCa: { label: 'Tất cả', value: 0 },
    XN: { label: 'Xét nghiệm', value: 1 },
    CDHA: { label: 'CĐHA', value: 2 },
    GPB: { label: 'Giải phẫu bệnh', value: 3 },
    Khac: { label: 'Khác', value: 4 },
    ThuThuat: { label: 'Thủ thuật', value: 5 },
  };
  public static readonly PrintFormHeader = {
    All: 0,
    Prescription: 1,
    ClinicalFormA4: 2,
    ClinicalFormA5: 3,
    LaboratoryResultHeader: 4,
  };
  public static readonly SubclinicalVisitHL7Status = {
    IP: 'IP', //Chưa đóng tiền
    SC: 'SC', //Chưa thực hiện
    A: 'A', //Đã chụp/Đang đọc
    CM: 'CM', //Đã thực hiện
    CA: 'CA', //Đã hủy
    ER: 'ER', //Lỗi hệ thống
  };
  public static readonly SubClinicalVisitUtility = {
    OnCreate: 1,
    CreateByDoctor: 2,
  };
  public static readonly SubclinicalResultSource = {
    NotResult: 1,
    ResultFromPacs: 2,
    General: 3,
  };
  public static readonly PaperSizePrinter = {
    A4: 1,
    A5: 2,
  };
  public static readonly BROADCAST_CHANNEL = {
    'auth': {
      value: 'auth',
      action: {
        'login': { label: 'login', value: 'login' },
        'logout': { label: 'logout', value: 'logout' },
      },
    },
  };
  public static readonly MODALITY = {
    MR: { label: 'MR', value: 'MR', description: 'MR-cộng hưởng từ' },
    CT: { label: 'CT', value: 'CT', description: 'CT-cắt lớp vi tính' },
    DX: { label: 'DX', value: 'DX', description: 'DX-XQuang' },
    ES: { label: 'ES', value: 'ES', description: 'ES-Nội soi' },
    US: { label: 'US', value: 'US', description: 'US-Siêu âm' },
    WSI: { label: 'WSI', value: 'WSI', description: 'WSI' },
    PX: { label: 'PX', value: 'PX', description: 'X-Quang răng toàn cảnh' },
    MG: { label: 'MG', value: 'MG', description: 'Mammography(XQuang tuyến vú)' },
    MRI: { label: 'MRI', value: 'MRI', description: 'Cộng hưởng từ' },
    ECG: { label: 'ECG', value: 'ECG', description: 'Điện tim' },
    RESP: { label: 'RESP', value: 'RESP', description: 'Đo chức năng hô hấp' },
    EEG: { label: 'EEG', value: 'EEG', description: 'Điện não' },
    EMG: { label: 'EMG', value: 'EMG', description: 'Điện cơ' },
    EPS: { label: 'EPS', value: 'EPS', description: 'Điện gắng sức' },
    DSA: { label: 'DSA', value: 'DSA', description: 'Chụp mạch xóa nền' },
    PT: { label: 'PT', value: 'PT', description: 'PET CT' },
    ENT: { label: 'ENT', value: 'ENT', description: 'Nội soi TMH' },
    BM: { label: 'BM', value: 'BM', description: 'Đo loãng xương' },
  };
  public static readonly DefaultObservationVitalSigns = [
    {
      'title': 'Chiều cao',
      'code': '8302-2',
      'codeType': 'LOINC',
      'otId': '609d182b4f38c0e8ffee2e49',
      'name': 'BodyHeight',
    },
    {
      'title': 'Cân nặng',
      'code': '29463-7',
      'codeType': 'LOINC',
      'otId': '609d18814f38c0e8ffee2e4d',
      'name': 'BodyWeight',
    },
    {
      'title': 'Mạch',
      'code': '9279-1',
      'codeType': 'LOINC',
      'otId': '658133c37d408e40e275c2c1',
      'name': 'RespiratoryRate',
    },
    {
      'title': 'Nhiệt độ',
      'code': '8310-5',
      'codeType': 'LOINC',
      'otId': '609f9f9e5d5ca1331f0e0f49',
      'name': 'BodyTemperature',
    },
    {
      'title': 'Huyết áp tâm thu',
      'code': '8480-6',
      'codeType': 'LOINC',
      'otId': '609d188e4f38c0e8ffee2e50',
      'name': 'SystolicBloodPressure',
    },
    {
      'title': 'Huyết áp tâm trương',
      'code': '8462-4',
      'codeType': 'LOINC',
      'otId': '658133c37d408e40e275c2b4',
      'name': 'DiastolicBloodPressure',
    },
    {
      'title': 'SPO2',
      'code': '59408-5',
      'codeType': 'LOINC',
      'otId': '609fa02f5d5ca1331f0e0f58',
      'name': 'OxygenSaturation',
    },
  ];
}
