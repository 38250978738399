import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/components/app-state/app-state';
import { GroupTreeService } from 'src/app/services/groupTree.service';
import { ListVisitService } from 'src/app/services/listVisit-service';
import { ShareService } from 'src/app/services/share.service';
import { SubClinicalVisitService } from 'src/app/services/subcliniccal-service';
import { VisitService } from 'src/app/services/visit.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-print-conclusion',
  templateUrl: './print-conclusion.component.html',
  styleUrls: ['./print-conclusion.component.scss'],
})
export class PrintConclusionComponent implements OnInit {
  _isVisible: boolean;
  _cashierLogType: number;

  @Input() popupData = {
    isVisible: false,
    data: {} as any,
  };
  hospitalInfo: any;
  userOnline: any;
  groupId = '';
  groupSub: Subscription;
  printHeader = '';
  visit: any = {};
  subClinicalResult = [];
  vitalSign = [];
  lonicCode = {
    'mach': '8889-8',
    'huyetapmin': '8462-6',
    'huyetapmax': '8462-4',
    'chieucao': '8302-2',
    'cannang': '29463-7',
  };
  today = new Date();
  visitInfo = {} as any;
  groupName = '';
  dsXN = [];
  constructor(
    // private configService: PharmacyConfigService,
    private shareService: ShareService,
    private store: Store<AppState>,
    private groupService: GroupTreeService,
    private subClinicalVisitService: SubClinicalVisitService,
    private listVisitService: ListVisitService,
    private visitService: VisitService
  ) {}

  ngOnInit(): void {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        this.groupName = data.groupName;
        this.getPrintHeader();
      }
    });
    // this.userOnline = JSON.parse(localStorage.getItem(Constant.USER_INFO));
    // this.getHospitalInfo();
  }
  getPrintHeader() {
    this.groupService.getPrintHeader(this.groupId, 1).subscribe((res) => {
      if (res.isValid) {
        this.printHeader = res.jsonData;
      }
    });
  }

  async print(payload: any) {
    this.today = new Date();
    this.visit = payload;

    let response = await this.subClinicalVisitService
      .getSubClinicalResult(this.visit.id, this.visit.patientId)
      .toPromise();
    if (Array.isArray(response)) {
      this.subClinicalResult = response;
    }
    const dataFilter = {
      visitId: this.visit.id,
      patientId: this.visit.patientId,
      observationCategory: 2,
    };
    let res2 = await this.listVisitService.getObservationOfPatient(dataFilter).toPromise();
    if (res2.observations && Array.isArray(res2.observations)) {
      this.vitalSign = res2.observations;
    }
    const dataFilterXN = {
      visitId: this.visit.id,
      patientId: this.visit.patientId,
      observationCategory: 0,
    };
    let resXn = await this.listVisitService.getObservationOfPatient(dataFilterXN).toPromise();
    if (resXn.observations && Array.isArray(resXn.observations)) {
      this.dsXN = resXn.observations;
    }
    let res3 = await this.visitService.GetInfo2(this.visit.id).toPromise();
    if (res3.id) {
      this.visitInfo = res3;
      console.log('this.visitInfo', this.visitInfo);
    }

    console.log(payload);
    let additionCSS = `
    @media print {
      html,
      body{
        width: 210mm;
        height: 297mm;
        font-size: 13pt;
        font-family: "Times New Roman", Times, serif !important;
        margin: 0px;
      }
      .print-paper {
        width:100%;
      }
      @page {
      size: A4;
      margin: 0cm;
      }
    }
    `;
    setTimeout(() => {
      let printContents = document.getElementById('print-div-conclusion').innerHTML;
      this.shareService.printHtmlContent(printContents, 'In kết luận', additionCSS, true);
    }, 400);
  }

  getVitalSignValue(loincCode, source: any[]) {
    let item = source.find((el) => {
      return el.listCodeOtherSystem?.[0] == loincCode;
    });
    return item ? item.value2Show + item.unit : '';
  }
}
