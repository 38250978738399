import { PatientInRoomComponent } from './patient-in-room/patient-in-room.component';
import { DoctorInRoomComponent } from './doctor-in-room/doctor-in-room.component';
import { SharedModule } from './../../share/share.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientRoomComponent } from './patient-room.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [PatientRoomComponent],
  declarations: [
    PatientRoomComponent,
    DoctorInRoomComponent,
    PatientInRoomComponent,
  ],
})
export class PatientRoomModule {}
