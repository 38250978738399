<!-- <label class="label-input txt-ellipsis">{{searchItem.label}}</label> -->
<nz-select
  classx="mgr15"
  [nzPlaceHolder]="searchItem.label"
  [(ngModel)]="_value"
  [nzShowSearch]="searchItem.searchable"
  [nzAllowClear]="searchItem.clearable"
  (ngModelChange)="selectSearchItem($event)">
  <nz-option
    *ngFor="let item of searchItem.options"
    [nzLabel]="item[searchItem.keyField]"
    [nzValue]="item[searchItem.valueField]">
  </nz-option>
</nz-select>
