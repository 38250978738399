<div *ngIf="isVisibleAdd | async as visible">
  <!-- <nz-modal nzTitle="Thêm lịch hẹn" nzClassName="appointment-popup" [nzVisible]="visible" [nzWidth]="770" nzMaskClosable="false"
        (nzOnCancel)='cancelAdd()'> -->
  <app-m-modal
    class="appointment-popup"
    [modalId]="'appointment-add'"
    [isVisible]="visible"
    (closeModal)="cancelAdd()"
    [mMask]="true"
    [width]="770"
    [minWidth]="860"
    [height]="800"
    [minHeight]="600"
    [isResiable]="true">
    <ng-template #mModalTitle>
      <div class="header-title">Thêm lịch hẹn</div>
    </ng-template>
    <ng-template #mModalContent>
      <form [formGroup]="info">
        <div class="potal customTableCrollbar">
          <div class="row mg-bt-20">
            <div class="col-8">
              <div class="mg-r-30 k-info-wrapper" style="height: 60px">
                <div class="elm-title">
                  <div style="color: #9f9f9f; font-size: 12px; font-size: 500">
                    Tìm kiếm bệnh nhân
                  </div>
                  <input
                    #keywordPatient
                    minLength="2"
                    nz-input
                    (input)="onSearchPatient(keywordPatient.value)"
                    [nzAutocomplete]="auto" />
                  <nz-autocomplete #auto>
                    <nz-auto-option
                      *ngFor="let item of searchvalue"
                      [nzValue]="item.fullName"
                      (click)="selectSearchPatient(item)">
                      <span>
                        <p>{{ item.fullName }}</p>
                        <i>{{ item.address }}</i>
                      </span>
                    </nz-auto-option>
                  </nz-autocomplete>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="mg-r-30 p-button-action" (click)="showAddPatient()">
                <span style="margin-right: 15px">Thêm bệnh nhân &ensp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.255"
                  height="20.255"
                  viewBox="0 0 20.255 20.255">
                  <path
                    id="Path_247"
                    data-name="Path 247"
                    d="M10.128,0A10.128,10.128,0,1,0,20.255,10.128,10.139,10.139,0,0,0,10.128,0Zm4.431,10.971H10.971v3.587a.844.844,0,0,1-1.688,0V10.971H5.7a.844.844,0,0,1,0-1.688H9.284V5.7a.844.844,0,0,1,1.688,0V9.284h3.587a.844.844,0,0,1,0,1.688Zm0,0"
                    fill="#fff" />
                </svg>
              </div>
            </div>
          </div>
          <div class="message-required" *ngIf="submitted && f.fullName.errors">
            <div *ngIf="f.fullName.errors.required">
              <i
                nz-icon
                nzType="close-circle"
                [nzTheme]="'twotone'"
                [nzTwotoneColor]="'#FF0000'"
                style="vertical-align: baseline"></i>
              Bạn chưa chọn Bệnh nhân
            </div>
          </div>
          <div class="row mg-bt-20 info-card">
            <div class="col-12">
              <div class="info-header">Thông tin bệnh nhân</div>
            </div>
            <div class="col-12">
              <div class="info-content">
                <table style="width: 100%">
                  <tr>
                    <td>
                      Họ tên :
                      <span class="data-binding">{{
                        patients ? patients.fullName : ''
                      }}</span>
                    </td>
                    <td>
                      Mã :
                      <span class="data-binding">{{
                        patients ? patients.code : ''
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Giới tính :
                      <span class="data-binding"
                        >{{ patients ? (patients.sex | genderText) : '' }}
                      </span>
                    </td>
                    <td>
                      Ngày Sinh :
                      <span class="data-binding">
                        {{
                          patients ? (patients.dob | date: 'dd/MM/yyyy') : ''
                        }}</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Số điện thoại<span class="data-binding">
                        {{ patients.phoneNo }}</span
                      >
                    </td>
                    <td>
                      Ngày khám gần đây :
                      <span class="data-binding"
                        >{{
                          patients && patients.lastVisit
                            ? (patients.lastVisit.visitDate
                              | date: 'dd/MM/yyyy HH:mm')
                            : ''
                        }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Địa chỉ :
                      <span class="data-binding"
                        >{{ patients ? patients.address : '' }}
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div>
            <div class="potal-left">
              <div class="box-left">
                <div class="box-info">
                  <div class="font-style">
                    Ngày khám (<span class="d-note"> * </span>)
                  </div>
                  <nz-date-picker
                    formControlName="dateAppointment"
                    nzFormat="dd/MM/yyyy"
                    (ngModelChange)="setValueChange()"
                    [nzDisabledDate]="disabledDateBeforeToday">
                  </nz-date-picker>
                </div>
                <div
                  class="message-required"
                  *ngIf="submitted && f.dateAppointment.errors">
                  <div *ngIf="f.dateAppointment.errors.required">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"
                      style="vertical-align: baseline"></i>
                    Bạn chưa nhập ngày khám
                  </div>
                </div>
              </div>
              <div class="box-right">
                <div class="box-info">
                  <div class="font-style">
                    Giờ khám (<span class="d-note"> * </span>)
                  </div>
                  <nz-time-picker
                    formControlName="timeAppointment"
                    [nzMinuteStep]="30"
                    nzFormat="HH:mm">
                  </nz-time-picker>
                  <div class="message-required" *ngIf="submitted">
                    <div
                      *ngIf="
                        f.timeAppointment.errors &&
                        f.timeAppointment.errors.required
                      ">
                      <i
                        nz-icon
                        nzType="close-circle"
                        [nzTheme]="'twotone'"
                        [nzTwotoneColor]="'#FF0000'"
                        style="vertical-align: baseline"></i>
                      Bạn chưa nhập giờ khám
                    </div>
                    <div *ngIf="f.timeAppointment.hasError('unableHour')">
                      <i
                        nz-icon
                        nzType="close-circle"
                        [nzTheme]="'twotone'"
                        [nzTwotoneColor]="'#FF0000'"
                        style="vertical-align: baseline"></i>
                      Chọn trước giờ ngày hiện tại
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-info">
                <div class="font-style">Nội dung yêu cầu</div>
                <textarea
                  rows="2"
                  nz-input
                  formControlName="request"></textarea>
              </div>
              <div class="box-info">
                <div class="font-style">Chỉ định cận lâm sàng</div>
                <div class="box-cls">
                  <nz-select
                    nzShowSearch
                    nzServerSearch
                    nzAllowClear
                    nzPlaceHolder="Tìm kiếm hoặc chọn chỉ định"
                    [(ngModel)]="clsSelected"
                    [ngModelOptions]="{ standalone: true }"
                    (nzOnSearch)="onTyping($event)"
                    (ngModelChange)="addClsSelected($event)">
                    <nz-option
                      *ngFor="let item of clsDataSource"
                      nzCustomContent
                      [nzValue]="item"
                      [nzLabel]="item.name">
                      <div>{{ item.code }}</div>
                      <div>{{ item.name }}</div>
                    </nz-option>
                  </nz-select>
                  <ul class="list-cls customTableCrollbar">
                    <li *ngFor="let item of listClsDisplay">
                      <span>{{ item.code }}: {{ item.name }}</span>
                      <i
                        style="float: right"
                        (click)="removeItemCls(item.id)"
                        nz-icon
                        nzType="close"
                        nzTheme="outline"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="">
                <div class="font-style">Ghi chú</div>
                <textarea rows="2" nz-input formControlName="note"></textarea>
              </div>
            </div>
            <div class="potal-right" style="padding-left: 0px">
              <div>
                <div class="fs-16 mg-bt-20 font-style">
                  Trạng thái:
                  <span class="color-red">{{
                    toStatusText(f['status'].value)
                  }}</span>
                </div>
                <nz-select class="mg-bt-30" formControlName="status">
                  <nz-option [nzValue]="2" nzLabel="Đã xác nhận"></nz-option>
                  <nz-option
                    [nzValue]="3"
                    nzLabel="Bệnh nhân đã đến"></nz-option>
                  <nz-option [nzValue]="4" nzLabel="Đã tạo ca khám"></nz-option>
                  <nz-option
                    [nzValue]="5"
                    nzLabel="BN hủy lịch hẹn"></nz-option>
                  <nz-option [nzValue]="6" nzLabel="BN không đến"></nz-option>
                </nz-select>
              </div>
              <div>
                <div class="fs-16 mg-bt-20 font-style">
                  Mức độ ưu tiên:
                  <span class="color-red">{{
                    toPriorityText(f['priority'].value)
                  }}</span>
                </div>
                <nz-select formControlName="priority">
                  <nz-option [nzValue]="3" nzLabel="Bình thường"></nz-option>
                  <nz-option [nzValue]="2" nzLabel="Ưu tiên"></nz-option>
                  <nz-option [nzValue]="1" nzLabel="Vip"></nz-option>
                </nz-select>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!-- <div *nzModalFooter>
                    <button class="button-submit btn-cancel-large" (click)="cancelAdd()">Quay lại</button>
                    <button nz-button class="btn-primary-large" (click)="handleOk()" [nzLoading]="isLoading">Thêm lịch
                        hẹn</button>
                </div>
            </div>
        </nz-modal> -->
    </ng-template>
    <ng-template #mModalFooter>
      <button class="btn-primary-medium" (click)="handleOk()">
        Thêm lịch hẹn
      </button>
      <button class="btn-cancel-medium" (click)="cancelAdd()">Quay lại</button>
    </ng-template>
  </app-m-modal>
</div>
