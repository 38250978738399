import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Visits } from 'src/app/models/listVisit.class';
import { MedicalInstructionService } from 'src/app/services/medical-instruction.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-command-detail-modal',
  templateUrl: './command-detail-modal.component.html',
  styleUrls: ['./command-detail-modal.component.scss'],
})
export class CommandDetailModalComponent implements OnInit {
  @Input() isVisible = false;
  @Input() visit: Visits;
  @Input() curUserId = '';
  @Input() currentOrder: any;
  @Output() isVisibleChange: EventEmitter<any> = new EventEmitter();
  @Output() reloadData: EventEmitter<any> = new EventEmitter();
  @Output() opeUpdateStatus: EventEmitter<any> = new EventEmitter();
  VISIT_STATUS = Constant.VISIT_STATUS;

  constructor(
    private medicalInstructionService: MedicalInstructionService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {}
  cancelView() {
    this.isVisibleChange.emit(false);
  }
  getPatientAge(dob): string {
    if (dob != null) {
      const age = new Date().getFullYear() - new Date(dob).getFullYear();
      return age.toString() + ' tuổi';
    } else {return '';}
  }

  viewToUpdateStatus() {
    this.isVisibleChange.emit(false);
    this.opeUpdateStatus.emit();
  }

  cancelOrder() {
    const payload = {
      id: this.currentOrder.id,
      status: 3, // đã hủy
      executeDate: new Date(),
    };
    this.medicalInstructionService.update2Visit(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(
          Constant.SUCCESS,
          'Cập nhật thành công!'
        );
        this.isVisibleChange.emit(false);
        this.reloadData.emit();
      } else {
        this.notificationService.showNotification(
          Constant.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }
}
