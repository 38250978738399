<div class="patient-info row" *ngIf="allFormTab.length > 0">
  <div class="col-12 col-xl-8 pdl0" [ngClass]="{'col-xl-12':inVisit}">
    <nz-tabset [nzTabPosition]="'left'" [(nzSelectedIndex)]="selectedIndex"
      (nzSelectedIndexChange)="selectedIndexChange($event)">
      <nz-tab nzTitle="Thông tin cá nhân">
        <div class="tab-content" [formGroup]="allFormTab[0].form" enter-tab>
          <ng-container *ngTemplateOutlet="iconaction; context: { index: allFormTab[0].index }"> </ng-container>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Họ tên<span class="red-dot"> *</span> :</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab [ngClass]="{
                  'invalid': submitted && personalInfoData.fullName.errors
                }" nz-input formControlName="fullName" />
              <div class="message-required" *ngIf="submitted && personalInfoData.fullName.errors">
                Nhập trường dữ liệu bắt buộc
              </div>
            </div>
            <div class="col-12 col-md-auto col-lg-2">Giới tính <span class="red-dot"> *</span>:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <nz-select #nextTab [ngClass]="{
                  'invalid': submitted && personalInfoData.sex.errors
                }" nzShowSearch formControlName="sex">
                <nz-option [nzValue]="0" nzLabel="Nữ"></nz-option>
                <nz-option [nzValue]="1" nzLabel="Nam"></nz-option>
                <nz-option [nzValue]="2" nzLabel="Không xác định"></nz-option>
              </nz-select>
              <div class="message-required" *ngIf="submitted && personalInfoData.sex.errors">
                Nhập trường dữ liệu bắt buộc
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Ngày sinh<span class="red-dot"> *</span> :</div>
            <div class="col-12 col-md-auto col-lg-4">
              <app-msc-date-picker #nextTab [disabled]="!allFormTab[0].isEditing" [ngClass]="{
                  'invalidmydp': submitted && personalInfoData.dob.errors
                }" formControlName="dob"></app-msc-date-picker>
              <div class="message-required" *ngIf="submitted && personalInfoData.dob.errors">
                Nhập trường dữ liệu bắt buộc
              </div>
            </div>
            <div class="col-12 col-md-auto col-lg-2">Tình trạng hôn nhân:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <nz-select #nextTab nzAllowClear nzShowSearch formControlName="maritalStatus">
                <nz-option [nzValue]="1" nzLabel="Đã kết hôn"></nz-option>
                <nz-option [nzValue]="2" nzLabel="Chưa kết hôn"></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">CCCD:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab [ngClass]="{
                  'invalid': submitted && personalInfoData.idNumber.errors
                }" nz-input formControlName="idNumber" />
              <div class="message-required" *ngIf="submitted && personalInfoData.idNumber.errors">
                Nhập từ 9-12 chữ số
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Đặc điểm nhận dạng:</div>
            <div class="col-12 col-md-auto col-lg-8">
              <input #nextTab nz-input formControlName="userDefined" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Ảnh:</div>
            <div class="col-12 col-md-auto col-lg-10">
              <nz-upload #nextTab class="avatar-upload" nzName="FormFile" nzListType="picture-card"
                [nzDisabled]="!allFormTab[0].isEditing" [nzAction]="uploadUrl" [nzShowUploadList]="false"
                [nzBeforeUpload]="beforeUpload" [nzHeaders]="header" (nzChange)="handleChange($event)">
                <div *ngIf="!avatarUrl">
                  <i class="picture" nz-icon nzType="picture" nzTheme="outline"></i>
                  <div class="fs-12">Ảnh đại diện</div>
                </div>
                <img class="PatientAvatar" *ngIf="avatarUrl" [src]="avatarUrl"
                  style="width: 100%; height: 100%; object-fit: cover" />
              </nz-upload>
            </div>
          </div>
          <ng-container *ngIf="allFormTab[0].isEditing">
            <ng-container *ngTemplateOutlet="savebtn; context: { index: allFormTab[0].index }"> </ng-container>
          </ng-container>
        </div>
      </nz-tab>
      <!-- ------------------------------------------ -->
      <nz-tab nzTitle="Liên lạc">
        <div class="tab-content" [formGroup]="allFormTab[1].form" enter-tab>
          <ng-container *ngTemplateOutlet="iconaction; context: { index: allFormTab[1].index }"></ng-container>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Địa chỉ:</div>
            <div class="col-12 col-md-auto col-lg-10">
              <input #nextTab nz-input formControlName="address" />
            </div>
          </div>
          <div class="row form-group">
            <div class="col-12 col-md-auto col-lg-2">Tỉnh:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <nz-select #nextTab class="w-100" nzPlaceHolder="Tỉnh/Thành Phố" formControlName="provinceId" nzAllowClear
                nzShowSearch (ngModelChange)="getDistrictByProvinceId($event)">
                <nz-option *ngFor="let option of listProvince" [nzLabel]="option.fullname"
                  [nzValue]="option.id"></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-12 col-md-auto col-lg-2">Huyện:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <nz-select #nextTab class="w-100" nzPlaceHolder="Quận/Huyện" formControlName="districtId" nzAllowClear
                nzShowSearch (ngModelChange)="getWardByDistrictId($event)">
                <nz-option *ngFor="let option of listDistrict" [nzLabel]="option.name"
                  [nzValue]="option.id"></nz-option>
              </nz-select>
            </div>
            <div class="col-12 col-md-auto col-lg-2">Xã:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <nz-select #nextTab class="w-100" nzPlaceHolder="Xã/Phường" formControlName="wardId" nzAllowClear
                nzShowSearch>
                <nz-option *ngFor="let option of listWard" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-12 col-md-auto col-lg-2">SĐT<span class="red-dot"> *</span>:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab [ngClass]="{
                  'invalid': submitted && contactData.phoneNo.errors
                }" nz-input formControlName="phoneNo" />
              <div *ngIf="submitted && contactData.phoneNo.errors">
                <div class="message-required" *ngIf="contactData.phoneNo.errors.required">
                  Nhập trường dữ liệu bắt buộc
                </div>
                <div class="message-required"
                  *ngIf="contactData.phoneNo.errors.minlength || contactData.phoneNo.errors.maxlength">
                  Nhập từ 10-11 chữ số
                </div>
              </div>
            </div>
            <div class="col-12 col-md-auto col-lg-2">SĐT phụ:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab [ngClass]="{
                  'invalid': submitted && contactData.otherPhoneNo.errors
                }" nz-input formControlName="otherPhoneNo" />
              <div *ngIf="submitted && contactData.otherPhoneNo.errors" class="message-required">
                Nhập từ 10-11 chữ số
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-12 col-md-auto col-lg-2">Email:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="email" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Người liên lạc khẩn cấp:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="emergencyContactPerson" />
            </div>
            <div class="col-12 col-md-auto col-lg-2">SĐT người liên lạc:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="emergencyContactPhone" />
            </div>
          </div>
          <ng-container *ngIf="allFormTab[1].isEditing">
            <ng-container *ngTemplateOutlet="savebtn; context: { index: allFormTab[1].index }"> </ng-container>
          </ng-container>
        </div>
      </nz-tab>
      <!-- ------------------------------------------ -->
      <nz-tab nzTitle="Thông tin y tế">
        <div class="tab-content" [formGroup]="allFormTab[2].form" enter-tab>
          <ng-container *ngTemplateOutlet="iconaction; context: { index: allFormTab[2].index }"></ng-container>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">BS giới thiệu:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <nz-select #nextTab nzAllowClear nzShowSearch nzPlaceHolder="Chọn bác sĩ"
                formControlName="presenterDoctor">
                <nz-option *ngFor="let item of lstDoctor" [nzLabel]="item.fullName" [nzValue]="item.id"> </nz-option>
              </nz-select>
            </div>
            <div class="col-12 col-md-auto col-lg-2">Nguồn thông tin:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="sourceInfo" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Chấp nhận nghe điện thoại:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <label #nextTab nz-checkbox formControlName="allowPhone"></label>
            </div>
            <div class="col-12 col-md-auto col-lg-2">Chấp nhận email:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <label #nextTab nz-checkbox formControlName="allowEmail"></label>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Sử dụng Patient Portal:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <label #nextTab nz-checkbox formControlName="usePatientPortal"></label>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Số thẻ BHYT:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="bhytCode" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Từ ngày:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <nz-date-picker #nextTab style="width: 150px" formControlName="validDate" nzPlaceHolder="Từ ngày"
                nzFormat="dd/MM/yyyy">
              </nz-date-picker>
            </div>
            <div class="col-12 col-md-auto col-lg-2">Đến ngày:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <nz-date-picker #nextTab style="width: 150px" formControlName="expireDate" nzPlaceHolder="Đến ngày"
                nzFormat="dd/MM/yyyy">
              </nz-date-picker>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">BV đang điều trị:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="treatedHospital" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Tình trạng tiêm vắc xin:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="vaccineHistory" />
            </div>
            <div class="col-12 col-md-auto col-lg-2">Tình trạng dị ứng thuốc:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="medicalHistory" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Thói quen sống:</div>
            <div class="col-12 col-md-auto col-lg-10">
              <input #nextTab nz-input formControlName="habit" />
            </div>
          </div>
          <ng-container *ngIf="allFormTab[2].isEditing">
            <ng-container *ngTemplateOutlet="savebtn; context: { index: allFormTab[2].index }"> </ng-container>
          </ng-container>
        </div>
      </nz-tab>
      <!-- ------------------------------------------ -->
      <nz-tab nzTitle="Công việc">
        <div class="tab-content" [formGroup]="allFormTab[3].form" enter-tab>
          <ng-container *ngTemplateOutlet="iconaction; context: { index: allFormTab[3].index }"></ng-container>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Nghề nghiệp:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="occupation" />
            </div>
            <div class="col-12 col-md-auto col-lg-2">Chức vụ:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="position" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Tên công ty:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="employerName" />
            </div>
            <div class="col-12 col-md-auto col-lg-2">Địa chỉ:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="employerAddress" />
            </div>
          </div>
          <ng-container *ngIf="allFormTab[3].isEditing">
            <ng-container *ngTemplateOutlet="savebtn; context: { index: allFormTab[3].index }"> </ng-container>
          </ng-container>
        </div>
      </nz-tab>
      <!-- ------------------------------------------ -->
      <nz-tab nzTitle="Thông tin xã hội">
        <div class="tab-content" [formGroup]="allFormTab[4].form" enter-tab>
          <ng-container *ngTemplateOutlet="iconaction; context: { index: allFormTab[4].index }"></ng-container>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Dân tộc:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="ethnicity" />
            </div>
            <div class="col-12 col-md-auto col-lg-2">Tôn giáo:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="religion" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Ngôn ngữ sử dụng:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="language" />
            </div>
            <div class="col-12 col-md-auto col-lg-2">Mức thu nhập:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <nz-select #nextTab nzAllowClear nzShowSearch formControlName="income">
                <nz-option [nzValue]="1" nzLabel="Thấp"></nz-option>
                <nz-option [nzValue]="2" nzLabel="Trung bình"></nz-option>
                <nz-option [nzValue]="3" nzLabel="Cao"></nz-option>
                <nz-option [nzValue]="4" nzLabel="Rất cao"></nz-option>
              </nz-select>
            </div>
          </div>
          <ng-container *ngIf="allFormTab[4].isEditing">
            <ng-container *ngTemplateOutlet="savebtn; context: { index: allFormTab[4].index }"> </ng-container>
          </ng-container>
        </div>
      </nz-tab>
      <!-- ------------------------------------------ -->
      <nz-tab nzTitle="Người giám hộ">
        <div class="tab-content" [formGroup]="allFormTab[5].form" enter-tab>
          <ng-container *ngTemplateOutlet="iconaction; context: { index: allFormTab[5].index }"></ng-container>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Họ tên:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="guardianName" />
            </div>
            <div class="col-12 col-md-auto col-lg-2">Mối quan hệ:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="relationship" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Năm sinh:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="guardianYob" />
            </div>
            <div class="col-12 col-md-auto col-lg-2">Giới tính:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <nz-select #nextTab nzAllowClear nzShowSearch formControlName="guardianSex">
                <nz-option [nzValue]="0" nzLabel="Nữ"></nz-option>
                <nz-option [nzValue]="1" nzLabel="Nam"></nz-option>
                <nz-option [nzValue]="2" nzLabel="Không xác định"></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Địa chỉ:</div>
            <div class="col-12 col-md-auto col-lg-10">
              <input #nextTab nz-input formControlName="guardianAddress" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">SĐT:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="guardianPhone" />
            </div>
            <div class="col-12 col-md-auto col-lg-2">Email:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="guardianEmail" />
            </div>
          </div>
          <ng-container *ngIf="allFormTab[5].isEditing">
            <ng-container *ngTemplateOutlet="savebtn; context: { index: allFormTab[5].index }"> </ng-container>
          </ng-container>
        </div>
      </nz-tab>
      <!-- ------------------------------------------ -->
      <nz-tab nzTitle="Thông tin khác">
        <div class="tab-content" [formGroup]="allFormTab[6].form" enter-tab>
          <ng-container *ngTemplateOutlet="iconaction; context: { index: allFormTab[6].index }"></ng-container>
          <div class="form-group row">
            <div class="col-12 col-md-auto col-lg-2">Thời gian tử vong:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <nz-date-picker #nextTab style="width: 150px" formControlName="deadTime" nzPlaceHolder="Từ ngày"
                nzFormat="dd/MM/yyyy">
              </nz-date-picker>
            </div>
            <div class="col-12 col-md-auto col-lg-2">Lý do tử vong:</div>
            <div class="col-12 col-md-auto col-lg-4">
              <input #nextTab nz-input formControlName="deadReason" />
            </div>
          </div>
          <ng-container *ngIf="allFormTab[6].isEditing">
            <ng-container *ngTemplateOutlet="savebtn; context: { index: allFormTab[6].index }"> </ng-container>
          </ng-container>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>
  <!----------------------------------------------------------------------------------------------------------------------- -->
  <div *ngIf="!inVisit" class="col-12 col-xl-4 tab-content-right">
    <div class="row">
      <div class="col-6 col-xl-12 box-pane mg-bt-20">
        <div class="box-title">
          <label class="title-text">Lịch sử ca khám</label>
        </div>
        <div class="box-content">
          <nz-table #basicTable class="second-table-grid" nzSize="small" [nzindex]="listHistory"
            [nzShowPagination]="false" [nzScroll]="{ x: '400px', y: '170px' }" nzShowSizeChanger
            [nzPageSizeOptions]="[10, 20, 50, 100]">
            <thead>
              <tr>
                <th class="text-nowrap">Thời gian khám</th>
                <th class="text-nowrap">Lý do khám</th>
                <th class="text-nowrap">Bác sĩ khám</th>
                <th class="text-nowrap">Bệnh chẩn đoán</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let visit of basicTable.index">
                <td class="text-nowrap">
                  <a class="link-visit" (click)="newVisitTab(visit.id, patient.fullName, patient.id)">
                    {{ visit.visitDate | date: 'dd/MM/yyyy hh:mm a' }}
                  </a>
                </td>
                <td>{{ visit.visitReason }}</td>
                <td class="text-nowrap">{{ visit.doctorName }}</td>
                <td>
                  <ul *ngFor="let disease of visit.diseases">
                    <li>{{ disease.name }}</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
      <div class="col-6 col-xl-12 box-pane family-history">
        <div class="box-title">
          <label class="title-text">Bệnh sử gia đình</label>
        </div>
        <div class="box-content" *ngIf="patient">
          <app-patient-family-history [patient]="patient"></app-patient-family-history>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #iconaction let-index="index">
  <div class="btn-action">
    <i class="mgr10" title="Gửi thông tin ca khám cho bệnh nhân" (click)="showModal()">
      <img src="assets/image/send-info.svg" />
    </i>
    <i nz-icon class="btn-edit" title="Chỉnh sửa" nzType="edit" nzTheme="outline" (click)="enableEdit(index)"></i>
  </div>
</ng-template>
<ng-template #savebtn let-index="index">
  <div class="row">
    <div class="col-2"></div>
    <div class="save-btn col-10">
      <button nz-button class="btn-cancel-medium mgr15" (click)="backtoView(index)">Quay lại</button>
      <button #nextTab nz-button [nzLoading]="loading" class="btn-primary-medium" (click)="save(index)">
        Cập nhật
      </button>
    </div>
  </div>
</ng-template>

<app-patient-profile [patient]="patient" [visitId]="visitId" [isVisible]="visibleProfile"
  (closeProflie)="closeProflie()" (profileStatus)="getStatus($event)"
  (changeEmail)="changeEmail($event)"></app-patient-profile>