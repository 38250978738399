<nz-table
  #basicTable
  class="table-csxn main-table-grid"
  nzSize="small"
  [nzData]="chisoXNFilter"
  [nzFrontPagination]="false"
  [nzShowPagination]="false"
  (nzCurrentPageDataChange)="currentPageDataChange($event)">
  <thead>
    <tr>
      <th
        nzShowCheckbox
        nzLeft="0px"
        nzWidth="50px"
        [(nzChecked)]="isAllDisplayDataChecked"
        [nzIndeterminate]="isIndeterminate"
        (nzCheckedChange)="checkAllRow($event)"></th>
      <th>Yêu cầu xét nghiệm</th>
      <ng-container *ngFor="let td of cakhams">
        <th>{{ td.visitDate }}</th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of basicTable.data; let i = index">
      <td
        nzShowCheckbox
        [nzDisabled]="item.dataType !== 1"
        [(nzChecked)]="mapOfCheckedId[item.id]"
        (nzCheckedChange)="refreshStatus()"
        nzLeft="0px"
        nzWidth="50px"></td>
      <td class="xn-col" [innerHtml]="item.name"></td>
      <ng-container *ngFor="let td of cakhams">
        <td [ngClass]="{ XNSelected: td.visitId === visit.id }" class="xn-col" nzWidth="30px">
          <div class="value" [innerHtml]="item['c' + td.visitId]">
            <ng-container *ngIf="td.visitId === visit.id && item['c' + td.visitId] !== null">
              <a class="edit-xn-hover" (click)="editXetNghiem(item)">
                <i nz-icon nzType="edit" nzTheme="outline"></i>
              </a>
            </ng-container>
          </div>
          <div class="range">
            <div *ngIf="item['r' + td.visitId] !== ''" class="border {{ item['r' + td.visitId] }}"></div>
            <div *ngIf="item['r' + td.visitId] === ''"></div>
            <ng-container *ngIf="item['r' + td.visitId] !== ''">
              <div class="inner"></div>
            </ng-container>
          </div>
        </td>
      </ng-container>
    </tr>
  </tbody>
</nz-table>
