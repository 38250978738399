<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="ApiToken"
  nzMaskClosable="false"
  (nzClosable)="(true)"
  (nzOnCancel)="handleCancel()">
  <div *nzModalContent>
    <form nz-form [formGroup]="formData">
      <nz-form-item>
        <nz-form-label [nzSpan]="5" nzRequired nzFor="name">Tên</nz-form-label>
        <nz-form-control nzHasFeedback [nzErrorTip]="nameErrorTpl">
          <input formControlName="name" nz-input placeholder="Tên" />
          <ng-template #nameErrorTpl let-control>
            <ng-container *ngIf="control.hasError('emptyName')">
              Tên không được để trống!
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">
              Hãy nhập tên!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5" nzRequired nzFor="token"
          >Token</nz-form-label
        >
        <nz-form-control nzHasFeedback [nzErrorTip]="keyErrorTpl">
          <input formControlName="token" nz-input [disabled]="true" />
          <ng-template #keyErrorTpl let-control>
            <ng-container *ngIf="control.hasError('emptyName')">
              Token không được để trống!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5" nzFor="groupId">GroupId</nz-form-label>
        <nz-form-control nzHasFeedback>
          <input formControlName="groupId" nz-input [disabled]="true" />
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()" nzDanger>
      Huỷ
    </button>
    <button
      *ngIf="!currentApiToken.id"
      nz-button
      nzType="primary"
      ngClass="btn-add"
      (click)="saveApiToken()">
      Thêm
    </button>
    <button
      *ngIf="currentApiToken.id"
      nz-button
      nzType="primary"
      (click)="saveApiToken()">
      Sửa
    </button>
  </div>
</nz-modal>
