import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { Observable } from 'rxjs';
import { SearchUser } from '../models/searchUser.class';
import { UrlConstant } from '../share/constants/url.class';

@Injectable()
export class UserService extends BaseService {
  getUser(search: SearchUser): Observable<any> {
    return this.post(UrlConstant.LIST_USER, search);
  }
  addUser(user): Observable<any> {
    return this.post(UrlConstant.ADD_USER, user);
  }
  deleteUser(id: number): Observable<any> {
    return this.delete(UrlConstant.DELETE_USER + id, null);
  }
  updateUser(user): Observable<any> {
    return this.put(UrlConstant.UPDATE_USER, user);
  }
  getUserByUsername(username): Observable<any> {
    return this.get(UrlConstant.DETAIL_USER + '/' + username);
  }
  countNewNotifi(): Observable<any> {
    return this.get(UrlConstant.COUNT_NEW_NOTIFI);
  }
  confirmInviteGroup(payload): Observable<any> {
    return this.post(UrlConstant.CONFIRM_NOTI_INVITE_GROUP, payload);
  }
  getTopNotifi(): Observable<any> {
    return this.get(UrlConstant.GET_TOP_NOTIFI);
  }
  getAllNotifiByType(payload): Observable<any> {
    return this.post(UrlConstant.GET_ALL_BY_TYPE_NOTIFI, payload);
  }
  viewNotifi(notiId): Observable<any> {
    return this.post(UrlConstant.VIEW_NOTIFI, '', { notiId });
  }
  readAllNotifi(): Observable<any> {
    return this.post(UrlConstant.NOTIFI_READ_ALL, null);
  }
}
