<div class="form-input center-input">
  <div
    nz-input
    [ngClass]="[
      data.formatStyle.isBold == true ? 'bold' : '',
      data.formatStyle.isItalic == true ? 'italic' : '',
      data.formatStyle.isUnderline == true ? 'underline' : '',
      data.formatStyle.align
    ]"
    [ngStyle]="{
      'color': data.formatStyle.color,
      'background': data.formatStyle.bgColor,
      'font-size': data.formatStyle.fontSize + 'px',
      'font-family': data.formatStyle.font,
      'height': '100%',
      'display': 'flex',
      'align-items': 'center'
    }">
    <span class="text-mg-l">{{ '[' + data.name + ']' }}</span>
  </div>
</div>
<div class="active-elm"></div>
