import { error } from 'selenium-webdriver';
import { PatientService } from './../../../services/patient.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { DoctorService } from 'src/app/services/doctor.service';
import { Observable, Observer, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { ShareService } from 'src/app/services/share.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.scss'],
})
export class PatientProfileComponent implements OnInit, OnChanges {
  indexTab = 0;
  tabs = [
    {
      disabled: false,
    },
    {
      disabled: false,
    },
    {
      disabled: false,
    },
  ];

  _patient: any;
  @Input() set patient(val) {
    this._patient = val;
    if (this._patient) {
      // console.log(this._patient)
      if (this._patient.activeStatus == 0) {
        this.tabs[0].disabled = true;
        this.tabs[2].disabled = true;
        this.indexTab = 1;
        this.getPatientUsername();
      } else if (this._patient.activeStatus == 2) {
        this.tabs[0].disabled = true;
        this.tabs[2].disabled = false;
        this.indexTab = 1;
      } else {
        this.tabs[0].disabled = false;
        this.tabs[2].disabled = false;
        this.indexTab = 0;
      }
    }
  }

  get patient() {
    return this._patient;
  }

  @Input()
  get visitId(): string {
    return this._visitId;
  }
  set visitId(value: string) {
    this._visitId = value;
  }
  private _visitId: string;
  private _isVisible: boolean;

  @Input() set isVisible(value: boolean) {
    this._isVisible = value;
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  @Output() outStatus = new EventEmitter();
  @Output() changeEmail = new EventEmitter();
  @Output() closeProflie = new EventEmitter();

  checked = true;
  isEmpty = false;
  emailOrSDT = '';
  profileStatus: string;
  radioValue = 0;
  groupSub: Subscription;
  isCreateQuickly = false;
  isVisibleCopyLink = false;
  activeAccountUrl = '';

  formActiveAccount: FormGroup;
  formResetPasswordAccount: FormGroup;
  isAdminGroup = false;
  patientAccountUsername = '';

  constructor(
    private notificationService: NotificationService,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private store: Store<AppState>,
    private shareService: ShareService,
    private fbActiveAccount: FormBuilder,
    private fbResetPassword: FormBuilder,
    private modalService: NzModalService
  ) {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        if (!this.shareService.checkEmpty(data)) {
          this.isCreateQuickly = data.isCreatePatientAccountQuickly;
          if (
            data.role == Constant.UserRoleType.Admin ||
            data.role == Constant.UserRoleType.Owner
          ) {
            this.isAdminGroup = true;
          }
        }
      }
    });

    this.formActiveAccount = this.fbActiveAccount.group({
      password: ['', [Validators.required], [this.emptyAsyncValidator]],
      confirm: ['', [this.confirmActiveAccountValidator]],
    });

    this.formResetPasswordAccount = this.fbResetPassword.group({
      password: ['', [Validators.required], [this.emptyAsyncValidator]],
      confirm: ['', [this.confirmResetPasswordAccountValidator]],
    });
  }

  ngOnInit(): void {}

  ngOnChanges(change: SimpleChanges) {
    if (this.patient) {
      this.emailOrSDT = this.patient.email;
    }
  }

  sendProfile() {
    const value = this.emailOrSDT.trim();
    if (value == '') {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn chưa nhập giá trị'
      );
    } else {
      if (this.radioValue == 0 && !this.validateEmail(value)) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Sai định dạng email!'
        );
        return;
      }
      const payload = {
        patientId: this.patient.id,
        isEmail: this.checked,
        emailOrPhone: value,
        visitId: this.visitId,
      };
      this.doctorService.createUserAcc(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Gửi thông tin thành công!'
          );
          this.isVisible = false;
          this.profileStatus = 'Đã gửi thông tin cho BN';
          this.outStatus.emit(this.profileStatus);
          this.changeEmail.emit(this.emailOrSDT);
          this.closeProflie.emit();
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            res.errors[0].errorMessage
          );
        }
      });
    }
  }

  getPatientUsername() {
    this.patientService
      .getPatientUsername(this.patient.code)
      .subscribe((res) => {
        if (res.isValid) {
          this.patientAccountUsername = res.jsonData.username;
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            res.errors[0].errorMessage
          );
        }
      });
  }

  handleCancel() {
    this.isVisible = false;
    this.closeProflie.emit();
    this.radioValue = 0;
  }

  onChangeText(event: string) {
    if (event.trim().length == 0) {
      this.isEmpty = true;
      return;
    }
    this.isEmpty = false;
  }

  onChangeRadio(radioValue) {
    if (radioValue == 0 || radioValue == 3) {
      this.emailOrSDT = this.patient.email;
    } else if (radioValue == 0) {
      this.emailOrSDT = this.patient.phoneNo;
    }
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  createPatientAccountQuickly() {
    const payload = {
      patientId: this.patient.id,
    };
    this.doctorService.quickCreateUserByPatient(payload).subscribe((res) => {
      if (res.isValid) {
        this.activeAccountUrl = res.jsonData;
        this.isVisibleCopyLink = true;
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }

  copyLinkActive() {
    const val = this.activeAccountUrl;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationService.showNotification(
      Constant.SUCCESS,
      'Đã copy link kích hoạt thành công.'
    );
  }

  handleCancelCopyLink() {
    this.isVisibleCopyLink = false;
    this.isVisible = false;
    this.closeProflie.emit();
    this.radioValue = 0;
  }

  validateActiveAccountConfirmPassword(): void {
    setTimeout(() =>
      this.formActiveAccount.controls.confirm.updateValueAndValidity()
    );
  }

  validateResetPasswordConfirmPassword(): void {
    setTimeout(() =>
      this.formResetPasswordAccount.controls.confirm.updateValueAndValidity()
    );
  }

  confirmActiveAccountValidator = (
    control: FormControl
  ): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (
      control.value !== this.formActiveAccount.controls.password.value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };

  confirmResetPasswordAccountValidator = (
    control: FormControl
  ): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (
      control.value !== this.formResetPasswordAccount.controls.password.value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };

  emptyAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (
        this.shareService.checkEmptyStr(control.value) ||
        control.value.includes(' ')
      ) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, emptyName: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  nonUnicodeAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (
        !this.shareService.NON_UNICODE_REGEX.test(control.value) ||
        this.shareService.NON_UNICODE_REGEX.test(control.value)
      ) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, nonUnicodeWhitespace: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  changeTabIndex(val) {
    if (val == 2 && this.patient.activeStatus == 2) {
      this.modalService.confirm({
        nzTitle: 'Thông báo',
        nzContent: 'Bạn đã gửi thông tin cho BN, bạn có muốn gửi lại không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Bỏ qua',
        nzOnOk: () => (this.indexTab = 2),
        nzOnCancel: () => (this.indexTab = 1),
      });
    }
  }

  savePatientAccountInfo() {
    let payload = {};
    if (this.indexTab == 0) {
      this.validateFormActiveAccount();
      if (this.formActiveAccount.valid) {
        payload = {
          patientId: this.patient.id,
          password: this.formActiveAccount.value.password,
          rePassword: this.formActiveAccount.value.confirm,
        };
      } else {
        return false;
      }
    } else {
      this.validateFormResetPasswordAccount();
      if (this.formResetPasswordAccount.valid) {
        payload = {
          patientId: this.patient.id,
          password: this.formResetPasswordAccount.value.password,
          rePassword: this.formResetPasswordAccount.value.confirm,
        };
      } else {
        return;
      }
    }

    this.patientService.resetPassword(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Cập nhật thành công'
        );
        this.formActiveAccount.reset();
        this.formResetPasswordAccount.reset();
        this.closeProflie.emit();
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }

  validateFormActiveAccount() {
    for (const i in this.formActiveAccount.controls) {
      this.formActiveAccount.controls[i].markAsDirty();
      this.formActiveAccount.controls[i].updateValueAndValidity();
    }
  }

  validateFormResetPasswordAccount() {
    for (const i in this.formResetPasswordAccount.controls) {
      this.formResetPasswordAccount.controls[i].markAsDirty();
      this.formResetPasswordAccount.controls[i].updateValueAndValidity();
    }
  }
}
