import { Component, Input, OnInit } from '@angular/core';
import { TestResult } from 'src/app/models/form-model/test-result';

@Component({
  selector: 'app-test-result',
  templateUrl: './test-result.component.html',
  styleUrls: ['./test-result.component.scss'],
})
export class TestResultComponent implements OnInit {
  @Input() result: TestResult;
  constructor() {}

  ngOnInit() {}
}
