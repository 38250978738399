import { Component, Input, OnInit } from '@angular/core';
import { FormElement } from 'src/app/models/form-model/form.class';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
})
export class InputNumberComponent implements OnInit {
  _data: FormElement;
  @Input() set data(value: FormElement) {
    this._data = value;
  }

  get data(): FormElement {
    return this._data;
  }

  constructor() {}

  ngOnInit() {}
}
