import { VisitDetailSmallModule } from './visit-detail-small/visit-detail-small.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListNDetailVisitComponent } from './list-n-detail-visit.component';
import { WorklistModule } from '../worklist/worklist.module';
import { SharedModule } from 'src/app/share/share.module';
import { ListVisitSmallComponent } from './list-visit-small/list-visit-small.component';
import { VisitInfoHeaderComponent } from './visit-info-header/visit-info-header.component';
import { AngularSplitModule } from 'angular-split';
import { NzResizableModule } from 'ng-zorro-antd/resizable';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GenderToTextPipe } from 'src/app/share/pipe/gender-to-text.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    VisitDetailSmallModule,
    AngularSplitModule,
    NzResizableModule,
    InfiniteScrollModule,
  ],
  declarations: [
    ListNDetailVisitComponent,
    ListVisitSmallComponent,
    VisitInfoHeaderComponent,
  ],
  exports: [ListNDetailVisitComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [GenderToTextPipe],
})
export class ListNDetailVisitModule {}
