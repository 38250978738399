import { Component, Input, OnInit } from '@angular/core';
import { FormElement } from 'src/app/models/form-model/form.class';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-dropdown-element',
  templateUrl: './dropdown-element.component.html',
  styleUrls: ['./dropdown-element.component.scss'],
})
export class DropdownElementComponent implements OnInit {
  _data: any;
  _type: string;

  @Input() set data(value: FormElement) {
    this._data = value;
    // this._data.valueSelections = this._data.valueSelections.length > 0 ? this._data.valueSelections[0] : '';
    this._type =
      this._data &&
      (this._data.observationType.datatypeText ==
        Constant.DATATYPE.SINGLECHOICE ||
        this._data.observationType.dataType ==
          Constant.DATATYPENUMBER.SINGLECHOICE)
        ? 'default'
        : 'multiple';
    if (this._type == 'default') {
      if (this._data.valueSelections && this._data.valueSelections.length > 0) {
        this._data.valueSelections = this._data.valueSelections[0];
      } else {
        this._data.valueSelections = '';
      }
    }
  }

  get data(): FormElement {
    return this._data;
  }
  constructor() {}

  ngOnInit() {}

  onModelChange(val) {
    this.data.isDirty = true;
  }
}
