<div class="search-bar d-flex justify-content-between">
  <div class="search-bar-left">
    <input style="height: 33px" nz-input [(ngModel)]="search.fullName" class="mgr15" id="txtFullName" type="text"
      autocomplete="off" placeholder="Tên bệnh nhân *" (keydown.enter)="searchListPatient()" />
    <nz-select [(ngModel)]="search.status" (ngModelChange)="searchListPatient()"
      style="width: 200px; margin-right: 10px">
      <nz-option [nzValue]="-1" nzLabel="--Tất cả--"></nz-option>
      <nz-option [nzValue]="0" nzLabel="Chưa xử lý"></nz-option>
      <nz-option [nzValue]="1" nzLabel="Đã tiếp nhận"></nz-option>
      <nz-option [nzValue]="2" nzLabel="Đã từ chối"></nz-option>
    </nz-select>
    <nz-select [(ngModel)]="search.verified" (ngModelChange)="searchListPatient()"
      style="width: 200px; margin-right: 10px">
      <nz-option [nzValue]="0" nzLabel="--Tất cả--"></nz-option>
      <nz-option [nzValue]="1" nzLabel="Chưa xác thực"></nz-option>
      <nz-option [nzValue]="2" nzLabel="Đã xác thực"></nz-option>
    </nz-select>
    <button (click)="resetSearch()" class="pointer mg-r-10">
      <i title="Clear dữ liệu">
        <img src="assets/image/DPicon/icon-close.svg" />
      </i>
    </button>
    <button (click)="searchListPatient()" class="pointer mg-r-10">
      <i title="Tìm kiếm">
        <img src="assets/image/DPicon/icon-search.svg" />
      </i>
    </button>
  </div>
  <div>
    <button class="normal-btn btn-primary-medium" type="button" (click)="showModalAddPatient()">
      Thêm bệnh nhân
    </button>
  </div>
</div>
<div style="position: relative">
  <span class="column-btn" (click)="showCustomColumn()">
    <i nz-icon nzType="table" nzTheme="outline" class="fs-16"></i>
  </span>
  <nz-table class="main-table-grid listvs-only covid-list list-covid-grid" #fixedTable [nzFrontPagination]="false"
    [nzTotal]="total" nzShowSizeChanger [nzPageSize]="pageSize" [(nzPageIndex)]="pageIndex" [nzData]="datas"
    [nzLoading]="loading" (nzQueryParams)="onQueryParamsChange($event)" [nzShowTotal]="rangeTemplate"
    [nzScroll]="{ x: '1200px', y: 'calc(100vh - 300px)' }" [nzPageSizeOptions]="[20, 30, 50, 100]">
    <thead>
      <tr>
        <!-- <th nzLeft  class="center" nzWidth="50px">#</th>
      <th nzLeft nzWidth="150px">Thời gian ĐK</th>
      <th nzLeft nzWidth="180px">Họ và tên</th>
      <th nzWidth="100px">Năm sinh</th>
      <th nzWidth="260px">Địa chỉ</th>
      <th nzWidth="180px">Email</th>
      <th nzWidth="180px">Số điện thoại</th>
      <th nzWidth="120px">Trạng thái</th>
      <th nzWidth="100px">Test Covid nhanh</th>
      <th nzWidth="100px">Test Covid PCR</th>
      <th nzWidth="150px">Ngày test covid</th>
      <th nzWidth="150px">Ngày khởi phát triệu chứng</th>
      <th nzWidth="150px">Ngày tiếp xúc với F1</th>
      <th nzWidth="100px">Có bệnh nền</th>
      <th nzWidth="200px" *ngIf="search.status === 2">Lý do từ chối</th> -->
        <ng-container *ngFor="let col of showCols">
          <th *ngIf="
              col.width && (col.field != 'reasonDenied' || search.status === 2)
            " [ngClass]="col.field == '#' ? 'center' : ''" [nzColumnKey]="col.title" nz-resizable [nzWidth]="col.width"
            [nzMinWidth]="col.minWidth" nzBounds="window" nzPreview (nzResizeEnd)="onResizeCloumn($event, col.title)"
            [nzLeft]="
              col.field == '#' ||
              col.field == 'dateCreated' ||
              col.field == 'fullName'
            ">
            <!-- [nzLeft]="col.field == 'STT' || col.field == 'patientName'" -->
            <div class="col-content">
              <div class="col-name">
                <span>
                  {{ col.title }}
                </span>
              </div>
              <div style="position: relative; height: 30px">
                <nz-resize-handle nzDirection="right">
                  <div class="resize-trigger"></div>
                </nz-resize-handle>
              </div>
            </div>
          </th>
        </ng-container>
        <!-- <th nzWidth="200px" *ngIf="search.status === 2">Lý do từ chối</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of fixedTable.data; let i = index" [ngClass]="'status-' + item.status"
        (click)="showDetail(item)">
        <ng-container *ngFor="let col of showCols">
          <ng-container [ngSwitch]="col.field">
            <td *ngSwitchCase="'#'" nzLeft class="center">
              {{
              getRowIndex(i, fixedTable.nzPageIndex, fixedTable.nzPageSize)
              }}
            </td>
            <td *ngSwitchCase="'dateCreated'" nzLeft>
              {{ item.dateCreated | date: 'dd/MM/yyy HH:mm' }}
            </td>
            <td *ngSwitchCase="'fullName'" nzLeft>{{ item.fullName }}</td>
            <td *ngSwitchCase="'dateOfBirth'">
              {{ item.dateOfBirth | date: 'yyy' }}
            </td>
            <td *ngSwitchCase="'address'">{{ item.address }}</td>
            <td *ngSwitchCase="'email'">{{ item.email }}</td>
            <td *ngSwitchCase="'phone'">{{ item.phone }}</td>
            <td *ngSwitchCase="'utm_source'">
              {{
              utmSourceName[item.utm_source]
              ? utmSourceName[item.utm_source]
              : item.utm_source
              }}
            </td>
            <td *ngSwitchCase="'status'">
              {{ toStatus(item.status) }}
            </td>
            <td *ngSwitchCase="'quickTestCovidNew'">
              <nz-alert [nzType]="toTestCovidStatus(item.quickTestCovidNew)" nzMessage="{{
                  toTestCovid(item.quickTestCovidNew)
                }}"></nz-alert>
            </td>
            <td *ngSwitchCase="'pcrTestCovidNew'">
              <nz-alert [nzType]="toTestCovidStatus(item.pcrTestCovidNew)"
                nzMessage="{{ toTestCovid(item.pcrTestCovidNew) }}"></nz-alert>
            </td>
            <td *ngSwitchCase="'dateOfTestCovid'">
              {{ item.dateOfTestCovid | date: 'dd/MM/yyy' }}
            </td>
            <td *ngSwitchCase="'dateOfDiagnosis'">
              {{ item.dateOfDiagnosis | date: 'dd/MM/yyy' }}
            </td>
            <td *ngSwitchCase="'dateMeetF1'">
              {{ item.dateMeetF1 | date: 'dd/MM/yyy' }}
            </td>
            <td *ngSwitchCase="'myDiseases'" class="center">
              <i style="color: red; font-weight: bold" *ngIf="checkMyDiseases(item.myDiseases)" class="checkmark">✓</i>
            </td>
            <td *ngSwitchCase="'reasonDenied'" [ngClass]="{ 'display-none': search.status !== 2 }">
              {{ item.reasonDenied }}
            </td>
            <td *ngSwitchCase="'verified'" class="center">
              <i style="color: #23a25e; font-weight: bold" *ngIf="item.verified" class="checkmark"
                [title]="item.volunteerName ? item.volunteerName : ''">✓</i>
            </td>
            <td *ngSwitchCase="'volunteerName'">
              {{ item.volunteerName }}
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </tbody>
    <ng-template #rangeTemplate let-range="range" let-total>
      {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }} {{ 'bệnh nhân' }}
    </ng-template>
  </nz-table>
</div>
<app-custom-column [(isVisible)]="isCustomColumnVisible" [showColumn]="showCols" [hideColumn]="hideCols"
  (saveColumns)="changeColumns($event)"></app-custom-column>
<nz-modal [nzWidth]="itemCovid ? 1200 : 900" [(nzVisible)]="isVisibleDetail" title="Xem hồ sơ" nzClosable="true"
  nzMaskClosable="true" (nzOnCancel)="handleCancelDetail()" nzClassName="list-covid-modal">
  <div *nzModalTitle>
    HỒ SƠ BỆNH NHÂN: <strong>{{ item.fullName }}</strong>
  </div>
  <ng-container *nzModalContent>
    <div class="modal-content modal-detail" style="padding: 20px" *ngIf="item">
      <div class="row">
        <div [class]="itemCovid ? 'col-md-9' : 'col-md-12'">
          <div class="form-group">
            <label>Trạng thái</label>
            <span class="info-verified">{{
              item.verified ? 'Đã xác thực' : 'Chưa xác thực'
              }}</span>
          </div>
          <div *ngIf="item.receiveType != null" class="form-group">
            <label>Nhận thông tin tài khoản bằng</label>
            <strong>{{ toTextReceiveType(item.receiveType) }}</strong>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Họ và tên người bệnh</label>
                <strong>{{ item.fullName }}</strong>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Giới tính</label>
                <strong>{{ item.sex === 1 ? 'Nam' : 'Nữ' }}</strong>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Năm sinh</label>
                <strong>{{ item.dateOfBirth | date: 'yyy' }}</strong>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Địa chỉ</label>
            <strong>{{ item.address }}</strong>
          </div>
          <div *ngIf="item.provinceId != null" class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Tỉnh/Thành Phố</label>
                <strong>{{ item.provinceName }}</strong>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Quận/Huyện</label>
                <strong>{{ item.districtName }}</strong>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Xã/Phường</label>
                <strong>{{ item.wardName }}</strong>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Hiện bạn đang ở khuôn viên</label>
            <strong>{{ item.addressType }}</strong>
          </div>
          <div class="form-group">
            <label>Bạn đang ở với ai</label>
            <strong>{{ item.withWhom }}</strong>
          </div>
          <div class="form-group">
            <label>Bạn có đang ở phòng riêng không</label>
            <strong>{{ item.privateRoom ? 'Có' : 'Không' }}</strong>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Số điện thoại liên lạc </label>
                <strong>{{ item.phone }}</strong>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Số điện thoại người thân</label>
                <strong>{{ item.emergencyPhone }}</strong>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Email</label>
                <strong>{{ item.email }}</strong>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Chiều cao (cm)</label>
                <strong>{{ item.height }}</strong>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Cân nặng (kg)</label>
                <strong>{{ item.weight }}</strong>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>BMI</label>
                <strong *ngIf="item.height != null && item.weight != null">
                  {{
                  (
                  item.weight /
                  ((item.height / 100) * (item.height / 100))
                  ).toFixed(1)
                  }}
                </strong>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Bạn có các tình trạng sức khoẻ sau đây không?</label>
            <strong>{{ toSourceText(item.myDiseases) }}</strong>
          </div>
          <div class="form-group">
            <label>Bạn có dị ứng thuốc, thức ăn nào không</label>
            <strong>{{ item.haveAllergies ? item.allergic : 'Không' }}</strong>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Bạn đã tìm hiểu hoặc được hướng dẫn cách ly tại nhà
                  chưa</label>
                <strong>{{ item.learnAboutCovid ? 'Rồi' : 'Chưa' }}</strong>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Ngày khởi phát triệu chứng đầu tiên (ho, sốt, mất mùi, mất
                  vị,...)</label>
                <strong>{{ item.dateOfDiagnosis | date: 'dd/MM/yyy' }}</strong>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <!-- <div class="col-md-3">
                <label>Bạn đã được test Covid chưa</label>
              </div> -->
              <div class="col-md-4">
                <label>Test nhanh</label>
                <strong>{{
                  item.quickTestCovidNew === 1
                  ? 'Dương tính'
                  : item.quickTestCovidNew === 0
                  ? 'Âm tính'
                  : 'Chưa xét nghiệm'
                  }}</strong>
              </div>
              <div class="col-md-4">
                <label>PCR</label>
                <strong>{{
                  item.pcrTestCovidNew === 1
                  ? 'Dương tính'
                  : item.pcrTestCovidNew === 0
                  ? 'Âm tính'
                  : 'Chưa xét nghiệm'
                  }}</strong>
              </div>
              <div class="col-md-4">
                <label>Ngày test</label>
                <strong>{{ item.dateOfTestCovid | date: 'dd/MM/yyy' }}</strong>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="item.haveCovidNumber">
            <div class="row">
              <div class="col-md-3">
                <label>Mã số người bệnh Covid<span class="required">*</span></label>
                <strong>
                  {{ item.covidNumber }}
                </strong>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Bạn/Người khác có tiếp xúc gần với người nhiễm Covid hay
                  không (F1)</label>
                <strong>{{ item.isF1 ? 'Có' : 'Không' }}</strong>
              </div>
              <div class="col-md-6" *ngIf="item.isF1">
                <label>Ngày tiếp xúc</label>
                <strong>{{ item.dateMeetF1 | date: 'dd/MM/yyy' }}</strong>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 m-height">
              <div class="form-group">
                <label>Bạn đã được chích ngừa Covid chưa</label>
                <strong>{{ item.vaccinated }}</strong>
              </div>
              <div *ngIf="item.dateOfVaccinatedNew != null" class="form-group">
                <label class="mg-r-10">Ngày chích Covid gần nhất</label>
                <strong>{{
                  item.dateOfVaccinatedNew | date: 'dd/MM/yyy'
                  }}</strong>
              </div>
            </div>
            <div *ngIf="
                item.listVaccinated != null && item.listVaccinated.length > 0
              " class="col-md-6">
              <label>Loại vaccine đã tiêm cho từng mũi</label>
              <div class="d-flex">
                <strong *ngFor="let dose of item.listVaccinated; index as i" class="mg-r-10">
                  Mũi {{ i + 1 }}: {{ dose }}
                </strong>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 m-height">
              <div class="form-group">
                <label>Thiết bị đo sức khỏe bạn đang có</label>
                <strong>{{ toSourceText(item.healthMeasuringDevice) }}</strong>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 m-height">
              <div class="form-group">
                <label>Thiết bị bạn hay sử dụng</label>
                <strong>{{ toSourceText(item.deviceType) }}</strong>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Tại nơi bạn đang ở, bạn có thể truy cập mạng</label>
                <strong>{{ toSourceText(item.internetType) }}</strong>
                <!--<nz-checkbox-group [(ngModel)]="internetTypeSource"></nz-checkbox-group>-->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Nguồn đăng ký</label>
                <strong>{{
                  utmSourceName[item.utm_source]
                  ? utmSourceName[item.utm_source]
                  : item.utm_source
                  }}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3" *ngIf="itemCovid">
          <div class="form-ls-content">
            <h3>Thông tin lâm sàng</h3>
            <div class="form-group">
              <label>Nhiệt độ</label>
              <strong>{{ itemCovid.nhietDo }}</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.nhietDo >= 38.5">
              <strong style="color: red">Sốt >= 38.5</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.bungKhoChiu">
              <strong>Bụng khó chịu</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.oiMua">
              <strong>Ói mửa</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.tieuChay">
              <strong>Tiêu chảy</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.ho && itemCovid.ho !== 0">
              <strong>{{ toOneChoiceValue(itemCovid.ho, hoSource) }}</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.matMuiMatVi && itemCovid.matMuiMatVi !== 0">
              Mất mùi vị:
              <strong>{{
                toOneChoiceValue(itemCovid.matMuiMatVi, matMuiMatViSource)
                }}</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.dauHong">
              <strong>Đau họng</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.dauDau && itemCovid.dauDau !== 0">
              Đau đầu:
              <strong>
                {{ toOneChoiceValue(itemCovid.dauDau, dauDauSource) }}</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.khoTho && itemCovid.khoTho !== 0">
              Khó thở:
              <strong>{{
                toOneChoiceValue(itemCovid.khoTho, khoThoSource)
                }}</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.chongMat && itemCovid.chongMat !== 0">
              Chóng mặt:
              <strong>{{
                toOneChoiceValue(itemCovid.chongMat, chongMatSource)
                }}</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.coBapDauNhuc">
              <strong>Cơ bắp đau nhức</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.trieuChungNang">
              Triệu chứng nặng:
              <strong>
                {{ itemCovid.trieuChungNang.join(', ') }}
              </strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.spO2">
              <strong>Chỉ số SpO2:
                {{ toOneChoiceValue(itemCovid.spO2, spO2Source) }}</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.khoThoKhiVanDong">
              <strong>Khó thở khi vận động</strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.thoNhanhThoGapHutHoi">
              <strong>Thở nhanh, thở, thở gấp, thở hụt hơi </strong>
            </div>
            <div class="form-group" *ngIf="itemCovid.mongMuon">
              Mong muốn:
              <strong>
                {{ itemCovid.mongMuon.join(', ') }}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="showEditForm()">
      Cập nhật thông tin
    </button>
    <button *ngIf="!item.verified" nz-button class="btn-primary-small" (click)="verifyInfo()">
      Xác thực thông tin
    </button>
    <button *ngIf="userCanReceive" nz-button nzType="primary" (click)="toReceiveForm()">
      Thực hiện tiếp nhận
    </button>
    <button *ngIf="userCanReceive" nz-button nzType="danger" (click)="toDeny()">
      Từ chối tiếp nhận
    </button>
  </div>
</nz-modal>

<nz-modal nzWidth="1100px" [(nzVisible)]="isVisibleEdit" title="Xem hồ sơ" nzClosable="true" nzMaskClosable="true"
  (nzOnCancel)="handleCancelEdit()" nzClassName="list-covid-modal">
  <div *nzModalTitle>CHỈNH SỬA HỒ SƠ {{ item.fullName }}</div>
  <ng-container *nzModalContent>
    <div class="modal-content modal-edit" style="padding: 20px" *ngIf="itemEdit">
      <div>
        <div *ngIf="itemEdit.receiveType != null" class="form-group">
          <label>Bạn muốn nhận thông tin tài khoản bằng cách nào?<span class="required">*</span></label>
          <nz-radio-group [(ngModel)]="itemEdit.receiveType">
            <label nz-radio [nzValue]="1">Qua email</label>
            <label nz-radio [nzValue]="2">Qua điện thoại</label>
          </nz-radio-group>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Họ và tên người bệnh <span class="required">*</span></label>
              <input type="text" class="form-control" [(ngModel)]="itemEdit.fullName" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Giới tính<span class="required">*</span></label>
              <nz-radio-group [(ngModel)]="itemEdit.sex">
                <label nz-radio [nzValue]="0">Nữ</label>
                <label nz-radio [nzValue]="1">Nam</label>
              </nz-radio-group>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Ngày/tháng/năm sinh<span class="required">*</span></label>
              <nz-date-picker style="width: 200px" [nzPlaceHolder]="'DD/MM/YYYY'" nzFormat="dd/MM/yyyy"
                [(ngModel)]="itemEdit.dateOfBirth">
              </nz-date-picker>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Địa chỉ (nhà hoặc khu vực cách ly). Địa chỉ nhà vui lòng ghi cụ thể
            số nhà, đường, thôn/xã/phường, quận, tỉnh/ thành phố<span class="required">*</span></label>
          <input type="text" class="form-control" [(ngModel)]="itemEdit.address" />
        </div>
        <div *ngIf="itemEdit.provinceId != null" class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Tỉnh/Thành Phố<span class="required">*</span></label>
              <nz-select class="w-100" nzPlaceHolder="Tỉnh/Thành Phố" [(ngModel)]="itemEdit.provinceId" nzShowSearch
                (ngModelChange)="onChangeProvinceId($event)">
                <nz-option *ngFor="let option of listProvince" [nzLabel]="option.fullname"
                  [nzValue]="option.id"></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Quận/Huyện<span class="required">*</span></label>
              <nz-select class="w-100" nzPlaceHolder="Quận/Huyện" [(ngModel)]="itemEdit.districtId" nzShowSearch
                (ngModelChange)="onChangeByDistrictId($event)">
                <nz-option *ngFor="let option of listDistrict" [nzLabel]="option.name"
                  [nzValue]="option.id"></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Xã/Phường<span class="required">*</span></label>
              <nz-select class="w-100" nzPlaceHolder="Xã/Phường" [(ngModel)]="itemEdit.wardId" nzShowSearch>
                <nz-option *ngFor="let option of listWard" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Hiện bạn đang ở khuôn viên <span class="required">*</span></label>
          <nz-radio-group [(ngModel)]="itemEdit.addressType">
            <label nz-radio nzValue="Nhà riêng">Nhà riêng</label>
            <label nz-radio nzValue="Chung cư">Chung cư</label>
            <label nz-radio nzValue="Nhà thuê">Nhà thuê</label>
            <label nz-radio nzValue="Khu điều trị tập trung">Khu điều trị tập trung</label>
            <label nz-radio nzValue="Khác">Khác</label>
          </nz-radio-group>
          <input *ngIf="itemEdit.addressType === 'Khác'" placeholder="Nhập nội dung khác" type="text"
            class="form-control" [(ngModel)]="itemEdit.addressTypeOther" />
        </div>
        <div class="form-group">
          <label>Bạn đang ở với ai:<span class="required">*</span></label>
          <nz-radio-group [(ngModel)]="itemEdit.withWhom">
            <label nz-radio nzValue="Một mình">Một mình</label>
            <label nz-radio nzValue="Với các thành viên trong gia đình">Với các thành viên trong gia đình</label>
            <label nz-radio nzValue="Bạn bè, đồng nghiệp">Bạn bè, đồng nghiệp</label>
            <label nz-radio nzValue="Nhân viên y tế">Nhân viên y tế</label>
          </nz-radio-group>
        </div>
        <div class="form-group">
          <label>Bạn có đang ở phòng riêng không<span class="required">*</span></label>
          <nz-radio-group [(ngModel)]="itemEdit.privateRoom">
            <label nz-radio [nzValue]="true">Có</label>
            <label nz-radio [nzValue]="false">Không</label>
          </nz-radio-group>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Số điện thoại liên lạc <span class="required">*</span></label>
              <input type="text" class="form-control" [(ngModel)]="itemEdit.phone" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Số điện thoại người thân <span class="required">*</span></label>
              <input type="text" class="form-control" [(ngModel)]="itemEdit.emergencyPhone" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Email <span class="required">*</span></label>
              <input type="text" class="form-control" [(ngModel)]="itemEdit.email" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Chiều cao (cm) <span class="red-dot">*</span>
                <em style="color: red" [ngClass]="'error'">
                  <span *ngIf="
                      (itemEdit.height != null && itemEdit.height < 50) ||
                      itemEdit.height > 200
                    ">số không hợp lệ</span>
                </em>
              </label>
              <input nz-input type="number" min="50" max="200" [(ngModel)]="itemEdit.height" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Cân nặng (kg) <span class="red-dot">*</span>
                <em style="color: red" [ngClass]="'error'">
                  <span *ngIf="itemEdit.weight != null && itemEdit.weight <= 0">số không hợp lệ</span>
                </em>
              </label>
              <input nz-input type="number" min="0" minlength="3" [(ngModel)]="itemEdit.weight" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>BMI</label>
              <div *ngIf="BMIValue() > 0">{{ BMIValue() }}</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Bạn có các tình trạng sức khoẻ sau đây không?<span class="required">*</span></label>
          <nz-select class="w-100" nzMode="multiple" nzPlaceHolder="Tình trạng sức khỏe của bạn"
            [(ngModel)]="listOfTagDiseases" (ngModelChange)="onChangeDiseases()">
            <nz-option *ngFor="let option of myDiseasesSource" [nzLabel]="option.label" [nzValue]="option.value"
              [nzDisabled]="option.disabled"></nz-option>
          </nz-select>
          <!-- <nz-checkbox-group [(ngModel)]="myDiseasesSource"></nz-checkbox-group> -->
        </div>
        <div class="form-group">
          <label>Bạn có dị ứng thuốc, thức ăn nào không</label>
          <nz-radio-group [(ngModel)]="itemEdit.haveAllergies">
            <label nz-radio [nzValue]="false">Không</label>
            <label nz-radio [nzValue]="true">Có</label>
          </nz-radio-group>
          <input *ngIf="itemEdit.haveAllergies" type="text"
            placeholder="Vui lòng ghi rõ loại thuốc hay thức ăn bạn dị ứng" class="form-control mt-10"
            [(ngModel)]="itemEdit.allergic" />
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Bạn đã tìm hiểu hoặc được hướng dẫn cách ly tại nhà chưa<span class="required">*</span></label>
              <nz-radio-group [(ngModel)]="itemEdit.learnAboutCovid">
                <label nz-radio [nzValue]="false">Chưa</label>
                <label nz-radio [nzValue]="true">Rồi</label>
              </nz-radio-group>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Ngày khởi phát triệu chứng đầu tiên (ho, sốt, mất mùi, mất
                vị,...) <span class="required">*</span></label>
              <nz-date-picker [nzPlaceHolder]="'DD/MM/YYYY'" nzFormat="dd/MM/yyyy"
                [(ngModel)]="itemEdit.dateOfDiagnosis">
              </nz-date-picker>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <!-- <div class="col-md-3"> -->
            <!-- <label>Bạn đã được test Covid chưa<span class="required">*</span></label> -->
            <!--<nz-radio-group [(ngModel)]="itemEdit.testCovid">
                <label nz-radio [nzValue]="false">Chưa</label>
                <label nz-radio [nzValue]="true">Có</label>
              </nz-radio-group>-->
            <!-- </div> -->
            <div class="col-md-4">
              <label>Test nhanh</label>
              <nz-radio-group [(ngModel)]="itemEdit.quickTestCovidNew">
                <label nz-radio [nzValue]="0">Âm tính</label>
                <label nz-radio [nzValue]="1">Dương tính</label>
                <label nz-radio [nzValue]="2">Chưa xét nghiệm</label>
              </nz-radio-group>
            </div>
            <div class="col-md-4">
              <label>PCR</label>
              <nz-radio-group [(ngModel)]="itemEdit.pcrTestCovidNew">
                <label nz-radio [nzValue]="0">Âm tính</label>
                <label nz-radio [nzValue]="1">Dương tính</label>
                <label nz-radio [nzValue]="2">Chưa xét nghiệm</label>
              </nz-radio-group>
            </div>
            <div class="col-md-4">
              <label>Ngày test</label>
              <nz-date-picker [nzPlaceHolder]="'DD/MM/YYYY'" nzFormat="dd/MM/yyyy"
                [(ngModel)]="itemEdit.dateOfTestCovid">
              </nz-date-picker>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-md-3">
              <label>Mã số người bệnh Covid<span class="required">*</span></label>
              <nz-radio-group [(ngModel)]="itemEdit.haveCovidNumber">
                <label nz-radio [nzValue]="false">Chưa</label>
                <label nz-radio [nzValue]="true">Đã có</label>
              </nz-radio-group>
              <input *ngIf="itemEdit.haveCovidNumber" type="text" placeholder="Nhập mã số" class="form-control mt-10"
                [(ngModel)]="itemEdit.covidNumber" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <label>Bạn/Người khác có tiếp xúc gần với người nhiễm Covid hay không
                (F1) <span class="required">*</span></label>
              <nz-radio-group [(ngModel)]="itemEdit.isF1">
                <label nz-radio [nzValue]="false">Không</label>
                <label nz-radio [nzValue]="true">Có</label>
              </nz-radio-group>
            </div>
            <div class="col-md-6" *ngIf="itemEdit.isF1">
              <label>Ngày tiếp xúc <span class="required">*</span></label>
              <nz-date-picker [nzPlaceHolder]="'DD/MM/YYYY'" nzFormat="dd/MM/yyyy" [(ngModel)]="itemEdit.dateMeetF1">
              </nz-date-picker>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 m-height">
            <div class="form-group">
              <label>Bạn đã được chích ngừa Covid chưa
                <span class="required">*</span></label>
              <nz-radio-group [(ngModel)]="itemEdit.vaccinated" (ngModelChange)="vaccinatedChange($event)">
                <label nz-radio nzValue="Chưa">Chưa</label>
                <label nz-radio nzValue="1 mũi">1 mũi</label>
                <label nz-radio nzValue="2 mũi">2 mũi</label>
                <label nz-radio nzValue="3 mũi">3 mũi</label>
              </nz-radio-group>
            </div>
          </div>
          <div *ngIf="listVaccinated != null && listVaccinated.length > 0" class="col-md-6">
            <label>Loại vaccine đã tiêm cho từng mũi
              <span class="required">*</span></label>
            <div class="d-flex">
              <div *ngFor="let dose of listVaccinated" class="doses">
                <span>{{ dose.label }}</span>
                <input nz-input type="text" [(ngModel)]="dose.value" />
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="listVaccinated.length > 0 && item.vaccinated != null" class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Ngày chích Covid gần nhất<span class="required">*</span></label>
              <nz-date-picker style="width: 200px" [nzPlaceHolder]="'DD/MM/YYYY'" nzFormat="dd/MM/yyyy"
                [nzDisabledDate]="disabledDateAfter" [(ngModel)]="item.dateOfVaccinatedNew">
              </nz-date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 m-height">
            <div class="form-group">
              <label>Thiết bị đo sức khỏe bạn đang có</label>
              <nz-checkbox-group [(ngModel)]="healthMeasuringDeviceSource" (ngModelChange)="
                  checkboxGroupChange($event, 'other')
                "></nz-checkbox-group>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 m-height">
            <div class="form-group">
              <label>Thiết bị bạn hay sử dụng</label>
              <nz-checkbox-group [(ngModel)]="deviceTypeSource" (ngModelChange)="
                  checkboxGroupChange($event, 'other')
                "></nz-checkbox-group>
            </div>
          </div>
          <div class="col-md-6 m-height">
            <div class="form-group">
              <label>Tại nơi bạn đang ở, bạn có thể truy cập mạng</label>
              <nz-checkbox-group [(ngModel)]="internetTypeSource" (ngModelChange)="
                  checkboxGroupChange($event, 'other')
                "></nz-checkbox-group>
            </div>
          </div>
        </div>
        <h2>Thông tin thêm</h2>
        <hr />
        <div class="form-group">
          <label>Gói đăng ký</label>
          <input type="text" class="form-control" [(ngModel)]="itemEdit.subPackage" />
        </div>
        <div class="form-group">
          <label>Yêu cầu tư vấn </label>
          <input type="text" class="form-control" [(ngModel)]="itemEdit.requestDesc" />
        </div>
        <div class="form-group">
          <label>Toa thuốc </label>
          <input type="text" class="form-control" [(ngModel)]="itemEdit.prescription" />
        </div>
        <hr />
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Điều phối viên phụ trách </label>
              <nz-select style="width: 100%" class="mgr15" nzPlaceHolder="Điều phối viên"
                [(ngModel)]="itemEdit.coordinatorInCare" nzAllowClear="true">
                <nz-option *ngFor="let item of listCoordinator" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Tình nguyện viên phụ trách </label>
              <nz-select style="width: 100%" class="mgr15" nzPlaceHolder="Tình nguyện viên"
                [(ngModel)]="itemEdit.volunteer" nzAllowClear="true">
                <nz-option *ngFor="let item of listVolunteer" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Bác sỹ phụ trách </label>
              <nz-select style="width: 100%" class="mgr15" nzPlaceHolder="BS phụ trách"
                [(ngModel)]="itemEdit.doctorInCareDoct" nzAllowClear="true">
                <nz-option *ngFor="let item of listDoctorInCase" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Điều dưỡng phụ trách </label>
              <nz-select style="width: 100%" class="mgr15" nzPlaceHolder="Điều dưỡng phụ trách"
                [(ngModel)]="itemEdit.nursingInCare" nzAllowClear="true">
                <nz-option *ngFor="let item of listNursingInCase" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Dược sỹ phụ trách </label>
              <nz-select style="width: 100%" class="mgr15" nzPlaceHolder="Dược sỹ phụ trách"
                [(ngModel)]="itemEdit.pharmacistInCare" nzAllowClear="true">
                <nz-option *ngFor="let item of listPharmacist" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Chuyên viên tâm lý phụ trách </label>
              <nz-select style="width: 100%" class="mgr15" nzPlaceHolder="Chuyên viên tâm lý phụ trách"
                [(ngModel)]="itemEdit.psychologistInCare" nzAllowClear="true">
                <nz-option *ngFor="let item of listPsychiatrist" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <span class="mg-r-15 font-weight-bold">Thông tin đã được xác thực</span>
          <span nz-checkbox [(ngModel)]="itemEdit.verified"></span>
        </div>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="updateInfo()">
      Lưu thông tin
    </button>
    <button nz-button nzType="default" (click)="handleCancelEdit()">
      Bỏ qua
    </button>
  </div>
</nz-modal>

<nz-modal nzWidth="600px" [(nzVisible)]="isVisibleReceive" title="Tiếp nhận hồ sơ" nzClosable="true"
  nzMaskClosable="true" (nzOnCancel)="handleCancelReceive()" nzClassName="list-covid-modal">
  <div *nzModalTitle>TIẾP NHẬN HỒ SƠ {{ item.fullName }}</div>
  <ng-container *nzModalContent>
    <div class="modal-content modal-edit" style="padding: 20px" *ngIf="itemEdit">
      <div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label><strong>NHÓM TIẾP NHẬN </strong><span class="red-dot">*</span></label>
              <nz-select style="width: 100%" class="mgr15" nzPlaceHolder="Nhóm tiếp nhận" [(ngModel)]="receivingGroupId"
                (ngModelChange)="onChangeReceivingGroup($event)">
                <nz-option *ngFor="let item of covidGroups" [nzLabel]="item.name" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
          <div class="col-md-6">
            <div *ngIf="receivingGroupId == selectedGroupId" class="italic note-blue" style="margin-top: 35px">
              (Nhóm hiện tại)
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div class="note italic">
                *Lưu ý: Bệnh nhân sẽ được quản lý trong nhóm tiếp nhận
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label><strong>Ngày bắt đầu điều trị </strong><span class="red-dot">*</span></label>
            <nz-date-picker style="width: 100%" [nzPlaceHolder]="'DD/MM/YYYY'" nzFormat="dd/MM/yyyy"
              [(ngModel)]="beginDateTreatment">
            </nz-date-picker>
          </div>
        </div>
        <nz-divider></nz-divider>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Điều phối viên phụ trách </label>
              <nz-select style="width: 100%" class="mgr15" nzPlaceHolder="Điều phối viên"
                [(ngModel)]="itemEdit.coordinatorInCare" nzAllowClear="true">
                <nz-option *ngFor="let item of listCoordinator" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Bác sỹ phụ trách </label>
              <nz-select style="width: 100%" class="mgr15" nzPlaceHolder="BS phụ trách"
                [(ngModel)]="itemEdit.doctorInCareDoct" nzAllowClear="true">
                <nz-option *ngFor="let item of listDoctorInCase" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Điều dưỡng phụ trách </label>
              <nz-select style="width: 100%" class="mgr15" nzPlaceHolder="Điều dưỡng phụ trách"
                [(ngModel)]="itemEdit.nursingInCare" nzAllowClear="true">
                <nz-option *ngFor="let item of listNursingInCase" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Dược sỹ phụ trách </label>
              <nz-select style="width: 100%" class="mgr15" nzPlaceHolder="Dược sỹ phụ trách"
                [(ngModel)]="itemEdit.pharmacistInCare" nzAllowClear="true">
                <nz-option *ngFor="let item of listPharmacist" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Chuyên viên tâm lý phụ trách </label>
              <nz-select style="width: 100%" class="mgr15" nzPlaceHolder="Chuyên viên tâm lý phụ trách"
                [(ngModel)]="itemEdit.psychologistInCare" nzAllowClear="true">
                <nz-option *ngFor="let item of listPsychiatrist" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Tình nguyện viên phụ trách </label>
              <nz-select style="width: 100%" class="mgr15" nzPlaceHolder="Tình nguyện viên phụ trách"
                [(ngModel)]="itemEdit.volunteer" nzAllowClear="true">
                <nz-option *ngFor="let item of listVolunteer" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="updateAndReceiveInfo()" [nzLoading]="isSaving">
      Tiếp nhận
    </button>
    <button nz-button nzType="default" (click)="handleCancelReceive()">
      Bỏ qua
    </button>
  </div>
</nz-modal>

<nz-modal class="list-covid-modal" [(nzVisible)]="isVisitablePatientAccount" (nzOnCancel)="closePatientAccountModal()">
  <div *nzModalTitle>
    <div class="header-title">
      Thông tin tài khoản của bệnh nhân: <strong>{{ item.fullName }}</strong>
    </div>
  </div>
  <div *nzModalContent>
    <div class="pd-20">
      <div class="row">
        <div class="col-md-4">Tên đăng nhập:</div>
        <div class="col-md-8 fs-16">
          {{ patientAccount.username }}
        </div>
      </div>
      <div class="row mg-t-20">
        <div class="col-md-4">Mật khẩu:</div>
        <div class="col-md-8 fs-16">
          <nz-input-group [nzSuffix]="suffixTemplate">
            <input nz-input [(ngModel)]="patientAccount.password" type="password" disabled />
          </nz-input-group>
        </div>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button class="btn-cancel-medium" (click)="closePatientAccountModal()">
      Đóng
    </button>
  </div>
</nz-modal>
<ng-template #suffixTemplate>
  <i (click)="copyMessage(patientAccount.password, 'Copy')" nz-icon nzType="copy" nzTheme="outline"></i>
</ng-template>

<app-covid-add-patient [isVisible]="isVisibleAddPatient" [groupId]="selectedGroupId"
  (closeModal)="closeModalAddPatient()" (refreshData)="refreshDataAddPatient()"></app-covid-add-patient>