<div class="custom-modal">
  <nz-modal class="custom-modal" [nzWidth]="900" [(nzVisible)]="isVisible" [nzTitle]="Title"
    (nzOnCancel)="handleCancel()" (nzOnOk)="handleCancel()">
    <div style="">
      <div class="tab_container">
        <div class="ttab_left">
          <ul>
            <li *ngFor="let tab of datas">
              <span [innerHTML]="tab.name | safeHtml"></span>
            </li>
          </ul>
        </div>
        <div class="ttab_content">
          <div class="chart-content" *ngFor="let tab of datas">
            <h2>
              <span [innerHTML]="tab.name | safeHtml"></span>
              <a class="btn-toggle" (click)="toggleChart(tab.id)"><i nz-icon
                  [nzType]="tab.visible ? 'caret-up' : 'caret-down'" nzTheme="outline"></i></a>
            </h2>
            <div class="c-{{ tab.visible }}" id="chartdiv{{ tab.id }}" style="width: 90%; height: 400px"></div>
          </div>
        </div>
      </div>
    </div>
  </nz-modal>
</div>
<button class="d-none" data-id="" id="trigger" (click)="openSelectedVisit()"></button>