<div>
  <div class="header-text" *ngIf="callOk">
    <div class="control-right">
      <div class="add-invite">
        <a class="displayRoomName">
          {{ roomName }}
        </a>
        <a class="button-invited" (click)="openInviterUser()">
          <img src="assets/image/svg/add-invite.svg" />
        </a>
      </div>
      <div class="display-screen-left" id="meet"></div>
    </div>
    <!-- <ul id="listJioned">
      <li *ngFor="let item of listInvited">
        Place screens of user in video call -->

    <!--</li>
    </ul> -->
  </div>
  <div *ngIf="!callOk" style="text-align: center">
    <h1>Xin chào!</h1>
    <h2 style="color: rgb(10, 94, 38)">{{ html }}</h2>
  </div>
</div>
<app-conference-add-user
  [roomId]="roomId"
  [groupId]="groupId"
  (closeModal)="closeInviteUser($event)"
  [isVisible]="isVisibleInvite"></app-conference-add-user>
