import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Visits } from 'src/app/models/listVisit.class';
import { GroupTreeService } from 'src/app/services/groupTree.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';
import * as _ from 'underscore';
import { AppState } from '../../app-state/app-state';

@Component({
  selector: 'app-subclinical-print',
  templateUrl: './subclinical-print.component.html',
  styleUrls: ['./subclinical-print.component.scss'],
})
export class SubclinicalPrintComponent implements OnInit {
  @Input() isPrint: boolean;
  @Input() visit: Visits;
  @Input() subClinicalCDHAs = [];
  @Input() subClinicalXNs = [];

  _showPrinter = false;
  printClass = 'd-none';
  @Input() set showPrinter(val: boolean) {
    if (this.isPrint && val) {
      if (!this.printSeperateSubclinical) {
        this.lstService = [...this.subClinicalCDHAs, ...this.subClinicalXNs];
        if (this.isPrint && this.lstService.length == 0) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.WARRING,
            'Không có chỉ định'
          );
          return;
        }
      } else {
        if (
          this.subClinicalCDHAs.length == 0 &&
          this.subClinicalXNs.length == 0
        ) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.WARRING,
            'Không có chỉ định'
          );
          return;
        }
      }

      this.printClass = 'd-none';
      this.print();
    }

    if (!this.isPrint && val) {
      this.printClass = 'print-preview';

      if (!this.printSeperateSubclinical) {
        this.lstService = [...this.subClinicalCDHAs, ...this.subClinicalXNs];
        this.showMainTable = true;
      } else {
        this.showCdhaTable = true;
        this.showXnTable = false;
      }
    }

    this._showPrinter = val;
  }
  get showPrinter(): boolean {
    return this._showPrinter;
  }
  @Output() closePrint: EventEmitter<any> = new EventEmitter();
  @Output() printNow: EventEmitter<any> = new EventEmitter();

  @ViewChild('printClinicalForm') printClinicalForm: ElementRef;

  printHeader = '';
  lstService = [];
  groupSub: Subscription;
  printSeperateSubclinical = false;
  showMainTable = false;
  showCdhaTable = false;
  showXnTable = false;
  toggleCDHA_XN_text = 'Xét nghiệm';

  constructor(
    private groupService: GroupTreeService,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef,
    private shareService: ShareService
  ) {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.printSeperateSubclinical = data.printSeperateSubclinical;
      }
    });
  }

  ngOnInit(): void {
    this.getPrintHeader();
  }

  getPrintHeader() {
    this.groupService.getPrintHeader(this.visit.groupId, 2).subscribe((res) => {
      if (res.isValid) {
        this.printHeader = res.jsonData;
      }
    });
  }

  // getAgeFromDOB(dobStr, isShowTextAge) {
  //   return this.shareService.calcAgeString(dobStr, isShowTextAge);
  // }

  // getDayMonthYear(dateStr) {
  //   let dateObj = new Date();
  //   if (dateStr == undefined) {dateObj = new Date();}
  //   else {dateObj = new Date(dateStr);}

  //   let str = 'Ngày ';
  //   str += dateObj.getDate() + ' tháng ';
  //   str += dateObj.getMonth() + 1 + ' năm ';
  //   str += dateObj.getFullYear();
  //   return str;
  // }

  print() {
    const titlePrint = '';
    let printContents;
    let printContentCdha;
    let printContentXn;
    let body = '';
    if (!this.printSeperateSubclinical) {
      this.showMainTable = true;
      this.changeDetector.detectChanges();
      printContents = this.printClinicalForm.nativeElement.innerHTML;
      body = printContents;
      this.documentOpen(titlePrint, body);
    } else {
      if (this.subClinicalCDHAs.length != 0) {
        this.showCdhaTable = true;
        this.changeDetector.detectChanges();
        printContentCdha = this.printClinicalForm.nativeElement.innerHTML;
        body += '<div>' + printContentCdha + '</div>';
      }
      this.showCdhaTable = false;
      this.changeDetector.detectChanges();

      setTimeout(() => {
        if (this.subClinicalXNs.length != 0) {
          this.showXnTable = true;
          this.changeDetector.detectChanges();
          printContentXn = this.printClinicalForm.nativeElement.innerHTML;
          body +=
            "<div style='page-break-before: always'>" +
            printContentXn +
            '</div>';
        }
        this.documentOpen(titlePrint, body);
      }, 0);
    }
  }
  documentOpen(titlePrint, body) {
    let popupWin;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
    <title>${titlePrint}</title>
    <style>
        strong{color:#000;}
        .table-sign {
          border: none !important;
        }
        .table-sign .label{
          font-weight:bold;
        } 
        .table-sign tr {
          text-align: center;
        }
        .tr-sign i {
          font-size: 12px;
        }
        .bold{
          font-weight: bold;
        }
        .d-none{
          display: none;
        }
        .center{
          text-align: center;
        }
        .right{
          text-align: right;
        }
        .table-detail td, .table-detail th{
          padding: 5px;
          border: none;
        }
        .table-detail tr:not(:last-child) td, .table-detail tr th{
          border-bottom: 1px solid black;
        }
        .table-detail tr td:not(:last-child), .table-detail tr th:not(:last-child){
          border-right: 1px solid black;
        }
        .table-detail{
          border: 1px solid black;
        }
        table{
          width: 100%;
          page-break-inside:auto;
        }
        .label{
          display: inline-block;
          margin-right: 5px;
          font-weight: bold;
        }
        .bold{
          font-weight: bold;
        }
        .col-item{
          display: inline-block;
          margin-right: 15px;
          margin-bottom: 10px;
        }
        @page{ 
          size: auto;
          margin: 7mm;
        }
        td{
          page-break-inside:avoid; 
          page-break-after:auto;
        }

        .row{
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 4px;
        }

        .col-2{
          width: 16.66667%
        }

        .col-3{
          width: 25%
        }

        .col-4{
          width: 33.33333%
        }

        .col-5{
          width: 41.66667%
        }

        .col-8{
          width: 66.66667%
        }

        .col-12{
          width: 100%
        }

        .info-label{
          display: inline-block;
          min-width: 85px;
        }

        .info-label-small {
          display: inline-block;
          min-width: 50px;
        }
    </style>
    </head>
    <body onload='window.print();window.close()'>${body}</body>
    </html>`);
    popupWin.document.close();
    this.closePrint.emit();
    this.refreshDisplay();
  }
  refreshDisplay() {
    this.showMainTable = false;
    this.showCdhaTable = false;
    this.showXnTable = false;
  }
  getDetailPrice(amount, price, discount) {
    if (
      this.shareService.checkEmpty(price) ||
      this.shareService.checkEmpty(amount)
    ) {
      return '';
    } else {
      return amount * (price - discount ?? 0);
    }
  }
  getTotalPrice(listData) {
    let total = 0;
    listData.forEach((item) => {
      if (
        !this.shareService.checkEmpty(item.price) &&
        !this.shareService.checkEmpty(item.amount)
      ) {
        total += item.amount * (item.price - item.discount ?? 0);
      }
    });
    return total;
  }
  getTotalDiscount(listData) {
    let total = 0;
    listData.forEach((item) => {
      if (
        !this.shareService.checkEmpty(item.amount) &&
        !this.shareService.checkEmpty(item.discount)
      ) {
        total += item.amount * item.discount;
      }
    });
    return total;
  }

  closeModal() {
    this.closePrint.emit();
  }

  toggleCDHA_XN() {
    this.showCdhaTable = !this.showCdhaTable;
    this.showXnTable = !this.showXnTable;
    this.toggleCDHA_XN_text = this.showXnTable ? 'CĐHA' : 'Xét nghiệm';
  }

  toPrint() {
    this.printNow.emit();
  }
}
