<div class="form-input form-elm">
  <!-- <textarea rows="4" nz-input [(ngModel)]="data.value" [ngClass]="[data.formatStyle.isBold == true ? 'bold' : '', 
                data.formatStyle.isItalic == true ? 'italic' : '', 
                data.formatStyle.isUnderline == true ? 'underline' : '', 
                data.formatStyle.align]" [ngStyle]="{'color' : data.formatStyle.color,
                  'background' : data.formatStyle.bgColor,
                  'font-size' : data.formatStyle.fontSize + 'px',
                  'font-family' : data.formatStyle.font}" disabled></textarea> -->

  <div
    class="text-elm"
    [innerHTML]="data.value"
    [ngClass]="[
      data.formatStyle.isBold == true ? 'bold' : '',
      data.formatStyle.isItalic == true ? 'italic' : '',
      data.formatStyle.isUnderline == true ? 'underline' : '',
      data.formatStyle.align
    ]"
    [ngStyle]="{
      'color': data.formatStyle.color,
      'background': data.formatStyle.bgColor,
      'font-size': data.formatStyle.fontSize + 'px',
      'font-family': data.formatStyle.font
    }"></div>
</div>
