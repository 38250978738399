import {
  ApplicationRef,
  Compiler,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Injector,
  Input,
  NgModuleRef,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Visits } from 'src/app/models/listVisit.class';
import { NotificationService } from 'src/app/services/notification.service';

import { RecordVisitService } from 'src/app/services/record-visit-service';
import { Constant } from 'src/app/share/constants/constant.class';
import { FormComponent } from '../../form/form.component';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { ShareService } from 'src/app/services/share.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-data-form',
  templateUrl: './data-form.component.html',
  styleUrls: ['./data-form.component.scss'],
})
export class DataFormComponent implements OnInit {
  faDownload = faDownload;
  isSaving = false;
  isPrint = false;
  @Input() visit: Visits;
  @Input() formId;
  @Input() recordId;
  @Input() hideButton: string;
  @Input() disabledClass: string;
  @Output() saveRecord = new EventEmitter<any>();
  @ViewChild('formContentData') formElm: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  isProcessing = false;
  options = {
    backgroundColor: null,
    allowTaint: true,
    useCORS: true,
    logging: true,
    onrendered(canvas) {
      document.body.appendChild(canvas);
    },
  };
  constructor(
    private recordVisitService: RecordVisitService,
    private notificationService: NotificationService,
    private modalService: NzModalService,
    private shareService: ShareService
  ) {}

  @ViewChild('form') form: FormComponent;

  ngOnInit() {}

  convertName() {
    const name = this.visit.patientName.replace(' ', '_').toUpperCase();

    return this.shareService.toNomalize(name);
  }

  downloadImage() {
    const name = this.convertName();
    this.isProcessing = true;
    const $this = this;
    this.form.downloadImage(name);
  }

  save() {
    const data = this.form.save();
    if (data == null) {
      return;
    }

    this.isSaving = true;
    const saveData = {
      ...{
        visitId: this.visit.id,
        formId: this.formId,
        recordId: this.recordId,
      },
      ...data,
    };

    this.recordVisitService.saveReport(saveData).subscribe((response) => {
      this.isSaving = false;
      if (response.isValid) {
        this.saveRecord.emit('reload now');
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Lưu Thành công'
        );
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          response.errors.errorMessage
        );
      }
    });
  }

  confirmPrint() {
    this.modalService.confirm({
      nzTitle: '<b>Xác nhận</b>',
      nzContent:
        'Bản in sẽ lấy thông tin từ lần cập nhật gần nhất. Hãy xác nhận bạn đã lưu phiếu!',
      nzOnOk: () => this.print(),
      nzOkText: 'Xác nhận',
      nzCancelText: 'Hủy',
    });
  }

  print() {
    const data = {
      recordId: this.recordId,
    };
    this.isPrint = true;

    this.recordVisitService.printReport(data).subscribe((response) => {
      this.isPrint = false;
      if (response.isValid) {
        const printContent = response.jsonData;
        const WindowPrt = window.open(
          '',
          'Print-Window',
          'width=100%,height=width=100vh'
        );
        WindowPrt.document.write(
          '<html><body onload="window.print()">' +
            printContent +
            '</body></html>'
        );
        WindowPrt.document.close();
        setTimeout(function() {
          WindowPrt.close();
        }, 10);
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          response.errors.errorMessage
        );
      }
    });
  }

  confirmExport() {
    this.modalService.confirm({
      nzTitle: '<b>Xác nhận</b>',
      nzContent:
        'Bản PDF sẽ lấy thông tin từ lần cập nhật gần nhất. Hãy xác nhận bạn đã lưu phiếu!',
      nzOnOk: () => this.exportPDF(),
      nzOkText: 'Xác nhận',
      nzCancelText: 'Hủy',
    });
  }

  exportPDF() {
    const data = {
      recordId: this.recordId,
    };
    this.isProcessing = true;

    this.recordVisitService.exportReport(data).subscribe((res) => {
      if (res && res.size > 0) {
        const filename = res.fileDownloadName;
        const dataType = res.type;
        const binaryData = [];
        binaryData.push(res);
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('target', '_blank');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        if (filename) {downloadLink.setAttribute('download', filename);}
        document.body.appendChild(downloadLink);
        downloadLink.click();
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Không thể xuất file, vui lòng liên hệ KTV'
        );
      }
      this.isProcessing = false;
    });
  }

  
  processDone(data) {
    this.isProcessing = false;
  }
}
