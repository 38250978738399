<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Thông tin lịch công việc"
  nzWidth="700px"
  nzMaskClosable="false"
  (nzOnCancel)="handleCancel()">
  <div class="row" style="position: relative">
    <div class="col-3"><label>Lời nhắc cho bản thân</label></div>
    <div class="col-9">
      <label nz-checkbox [(ngModel)]="dataForm.isForMe"></label>
    </div>
    <div style="position: absolute; right: 10px">
      <i
        style="font-size: 20px; color: #2b4e9b; margin-right: 1px"
        nz-icon
        nzType="unordered-list"
        nzTheme="outline"
        title="Danh sách công việc mẫu"
        (click)="showListSample()"></i>
    </div>
  </div>
  <div *ngIf="!dataForm.isForMe" class="row">
    <div class="col-3" style="padding-left: 30px">
      <label>Chọn bác sĩ<span class="red-dot"> *</span></label>
    </div>
    <div class="col-9">
      <nz-select
        [nzPlaceHolder]=""
        nzAllowClear
        [(ngModel)]="dataForm.doctorId"
        nzShowSearch>
        <nz-option
          *ngFor="let item of doctorInGroup"
          [nzLabel]="item.fullName"
          [nzValue]="item.id">
        </nz-option>
      </nz-select>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <label>Thời gian thực hiện<span class="red-dot"> *</span></label>
    </div>
    <div class="col-9">
      <nz-date-picker
        (ngModelChange)="onChangeActiveDate($event)"
        nzShowTime
        style="width: 200px"
        [nzPlaceHolder]="'DD/MM/YYYY  HH:mm'"
        nzFormat="dd/MM/yyyy HH:mm"
        [(ngModel)]="dataForm.activeDate"
        [nzDisabledDate]="disabledDateBeforeToday">
      </nz-date-picker>
    </div>
  </div>
  <div class="row">
    <div class="col-3"><label>Thông báo trước</label></div>
    <div class="col-9">
      <nz-select
        [nzPlaceHolder]=""
        nzAllowClear
        [(ngModel)]="dataForm.minutesBeforeReminder">
        <nz-option
          *ngFor="let item of lstMinutesBeforeRmd"
          [nzLabel]="item.label"
          [nzValue]="item.value">
        </nz-option>
      </nz-select>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <label>Công việc<span class="red-dot"> *</span></label>
    </div>
    <div class="col-9">
      <input nz-input [(ngModel)]="dataForm.title" />
    </div>
  </div>
  <div class="row">
    <div class="col-3"><label>Mô tả</label></div>
    <div class="col-9">
      <textarea nz-input [(ngModel)]="dataForm.detail">{{
        dataForm.detail
      }}</textarea>
    </div>
  </div>
  <div *nzModalFooter>
    <button
      class="btn-primary-medium"
      nz-button
      nzType="primary"
      (click)="saveReminder()">
      {{ dataForm.id == '' ? 'Thêm' : 'Sửa' }}
    </button>
    <button class="btn-cancel-medium" nz-button (click)="handleCancel()">
      Huỷ
    </button>
  </div>
</nz-modal>

<app-reminder-sample
  [(isVisible)]="isShowListSample"
  (usedSample)="usingSample($event)"></app-reminder-sample>
