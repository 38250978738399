import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class MedicalInstructionService extends BaseService {
  getByFilter(payload): Observable<any> {
    return this.post(UrlConstant.MEDICAL_INST_GET, payload);
  }
  getByGroupId(id): Observable<any> {
    return this.post(UrlConstant.MEDICAL_INST_GET_GROUP + '/' + id, '');
  }
  add(payload): Observable<any> {
    return this.post(UrlConstant.MEDICAL_INST_ADD, payload);
  }
  update(payload): Observable<any> {
    return this.put(UrlConstant.MEDICAL_INST_UPDATE, payload);
  }
  remove(id: string): Observable<any> {
    return this.delete(UrlConstant.MEDICAL_INST_DELETE + '/' + id, '');
  }
  add2Visit(payload): Observable<any> {
    return this.post(UrlConstant.MEDICAL_INST_ADD_VISIT, payload);
  }
  getByVisitId(visitId): Observable<any> {
    return this.post(UrlConstant.MEDICAL_INST_GET_VISIT + '/' + visitId, '');
  }
  getByPatientId(patientId): Observable<any> {
    return this.post(
      UrlConstant.MEDICAL_INST_GET_PATIENT + '/' + patientId,
      ''
    );
  }
  update2Visit(payload): Observable<any> {
    return this.post(UrlConstant.MEDICAL_INST_UPDATE_STATUS_VISIT, payload);
  }
  updateInfo2Visit(payload): Observable<any> {
    return this.post(UrlConstant.MEDICAL_INST_UPDATE_VISIT, payload);
  }
  getAllByGroupId(payload): Observable<any> {
    return this.post(UrlConstant.MEDICAL_INST_GET_ALL_VISIT, payload);
  }
  toTextPrivacy(privacy: number) {
    switch (privacy) {
      case 1:
        return 'Toàn hệ thống';
      case 2:
        return 'Trong nhóm BS';
      case 3:
        return 'Chỉ mình tôi';
      default:
        return '';
    }
  }
  toTextType(type: number) {
    switch (type) {
      case 1:
        return 'Đơn thuốc';
      case 2:
        return 'Chỉ định CĐHA';
      case 3:
        return 'Chỉ định xét nghiệm';
      case 4:
        return 'Khác';
      default:
        return '';
    }
  }
}
