<!-- ảnh thumbnail -->
<!-- <ul class="thumbnail-img"
  *ngFor="let image of imageFrom == ImageFrom.BoxCDHA ? (lstImages | slice: 0:5) : lstImages; index as i"
  title="{{ image.comment }}">
  <li class="w-100 h-100 d-flex align-items-center justify-content-center" (click)="showImage(i)">
    <img style="width: 100px; margin-left: -928px;" id="i{{ image.id }}" rtvalue="0" class="img-visit"
      [src]="image.imageURL | thumbnailSrc: baseImageurl" />
  </li>
  <div class="menu-hover"
    [ngClass]="image.creatorId != userIdOnline || imageFrom != ImageFrom.BoxGeneral ? 'd-none' : ''">
    <div class="d-flex justify-content-end">
      <a (click)="showModalEditImg(image)">
        <i nz-icon nzType="edit" nzTheme="outline"></i>
      </a>
      <a (click)="deleteImage(visitId, image.id, i)">
        <i nz-icon nzType="delete" nzTheme="outline"></i>
      </a>
    </div>
  </div>
</ul> -->
<div style="overflow: hidden">
  <div class="thumbnail-img"
       *ngFor="let image of imageFrom == ImageFrom.BoxCDHA ? (lstImages | slice: 0:5) : lstImages; index as i"
       title="{{ image.comment }}" style="float: left;">
    <div class="ul-image-general">
      <div (click)="showImage(i)">
        <img style="width: 80px; height: 80px; margin-left: 15px" id="i{{ image.id }}" rtvalue="0" class="img-visit"
             [src]="image.imageURL | thumbnailSrc: baseImageurl"/>
        <div class="menu-hover"
             [ngClass]="image.creatorId != userIdOnline || imageFrom != ImageFrom.BoxGeneral ? 'd-none' : ''">
          <div class="d-flex justify-content-end">
            <a (click)="showModalEditImg(image)">
              <i nz-icon nzType="edit" nzTheme="outline"></i>
            </a>
            <a (click)="deleteImage(visitId, image.id, i)">
              <i nz-icon nzType="delete" nzTheme="outline"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- small popup -->

<app-m-modal class="slideshow--small" [modalId]="'image-mode-view_small-popup'" [isVisible]="visibleSmallSize"
             (closeModal)="cancelSmallSize()" [width]="1200" [minWidth]="770" [height]="1000" [minHeight]="400"
             [isResiable]="true"
             [mFooter]="false">
  <ng-template #mModalTitle>
    <div class="d-flex">
      <div class="header-title">Danh sách hình ảnh</div>
      <div class="list-icon">
        <i nz-icon class="pd-10" title="Toàn màn hình" nzType="fullscreen" nzTheme="outline"
           (click)="openFullScreen()"></i>
        <i nz-icon class="pd-10" title="Mở cửa sổ mới" nzType="select" nzTheme="outline"
           (click)="viewInNewWindow()"></i>
      </div>
    </div>
  </ng-template>
  <ng-template #mModalContent>
    <app-slider-image #slider1 [id]="'slider2'" [lstImages]="lstImages" [visitId]="visitId" [(curIndex)]="selectedIndex"
                      [imageFrom]="imageFrom" [userIdOnline]="userIdOnline" [isResetSize]="visibleSmallSize"
                      (listImageChange)="refreshData($event)"></app-slider-image>
  </ng-template>
</app-m-modal>

<!-- fullscreen -->
<nz-modal nzClassName="slideshow-images" [(nzVisible)]="visibleFullScreen" (nzOnCancel)="cancelFullScreen()"
          [nzFooter]="null">
  <div class="d-flex size-action">
    <i nz-icon class="mg-r-15" title="Màn hình nhỏ" nzType="fullscreen-exit" nzTheme="outline"
       (click)="returnSmallPopup()"></i>
    <i nz-icon class="mg-r-10" title="Mở cửa sổ mới" nzType="expand-alt" nzTheme="outline"
       (click)="viewInNewWindow()"></i>
  </div>
  <app-slider-image #slider1 [id]="'slider1'" [userIdOnline]="userIdOnline" [lstImages]="lstImages" [visitId]="visitId"
                    [(curIndex)]="selectedIndex" [imageFrom]="imageFrom" [isResetSize]="visibleFullScreen"
                    (listImageChange)="refreshData($event)"></app-slider-image>
</nz-modal>
