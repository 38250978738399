import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ITreeOptions,
  ITreeState,
  TreeModel,
  TreeNode,
  TREE_ACTIONS,
} from '@circlon/angular-tree-component';
import { v4 } from 'uuid';
import { MenuItemTree } from 'src/app/models/grouptree.model';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { DoctorService } from 'src/app/services/doctor.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/components/app-state/app-state';
import { Join } from 'src/app/components/auth/redux/group-auth.action';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-tree-manager-group',
  templateUrl: './tree-manager-group.component.html',
  styleUrls: ['./tree-manager-group.component.scss'],
})
export class TreeManagerGroupComponent implements OnInit {
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Input() isVisible = false;
  @Input() menus: Array<MenuItemTree> = [];
  @Input() curGroupId = '';

  isVisibleAddGroup = false;
  contextMenu: { node: TreeNode; x: number; y: number } = null;
  sourceNode: TreeNode = null;
  editNode: TreeNode = null;
  state: ITreeState = {
    expandedNodeIds: {
      1: true,
      2: true,
    },
    hiddenNodeIds: {},
    activeNodeIds: {},
  };
  options: ITreeOptions = {
    nodeHeight: 30,
    getNodeClone: (node) => ({
      ...node.data,
      id: v4(),
      name: `copy of ${node.data.name}`,
    }),
    allowDrag: true,
    allowDrop: (node, to) => {
      if (to.parent.data.icon === 'assets/image/folder.svg') {
        return true;
      } else {
        return false;
      }
    },
    actionMapping: {
      mouse: {
        contextMenu: (
          treeModel: TreeModel,
          treeNode: TreeNode,
          e: MouseEvent
        ) => {
          e.preventDefault();
          if (this.contextMenu && treeNode === this.contextMenu.node) {
            return this.closeMenu();
          }
          this.contextMenu = {
            node: treeNode,
            x: 0,
            y: e.pageY,
          };
        },
        click: (treeModel: TreeModel, treeNode: TreeNode, e: MouseEvent) => {
          this.closeMenu();
          TREE_ACTIONS.TOGGLE_ACTIVE(treeModel, treeNode, e);
        },
      },
    },
  };

  constructor(
    private doctorService: DoctorService,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    @Inject(DOCUMENT) private dom: Document
  ) {}

  onMoveNode($event) {
    const node = $event.node;
    const to = $event.to.parent;
    if (!to.isLeaf) {
      if (node.isLeaf) {
        //nếu kéo group
        const payload = {
          groupId: node.id,
          groupCategoryId: to.id,
        };
        this.doctorService
          .moveGrouptoCategory(payload)
          .subscribe((response) => {
            if (response.isValid) {
            } else {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.ERROR,
                response.errors.errorMessage
              );
            }
          });
      } //nếu kéo category
      else {
        this.saveGroupCategoryToDB(node.id, node.name, to.id, node.icon);
      }
    } else {
      return;
    }
  }
  closeMenu = () => {
    this.contextMenu = null;
  };

  stopEdit = () => {
    let parent = '';
    if (this.editNode.parent.data.virtual) {
      parent = '';
    } else {
      parent = this.editNode.parent.data.id;
    }
    this.saveGroupCategoryToDB(
      this.editNode.data.id,
      this.editNode.data.name,
      parent,
      this.editNode.data.icon
    );
    this.editNode = null;
  };
  edit = (type) => {
    this.editNode = this.contextMenu.node;

    this.closeMenu();
  };
  removeNode = (tree: any) => {
    this.editNode = this.contextMenu.node;
    this.closeMenu();

    this.doctorService
      .deleteGroupCategory(this.editNode.data.id)
      .subscribe((response) => {
        if (response.isValid) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Xóa danh mục thành công'
          );
          if (!this.editNode.parent.data.virtual) {
            const parent = this.findNodeById(
              this.menus,
              this.editNode.parent.data.id
            );
            const childId = parent.children.indexOf(this.editNode.data);
            parent.children.splice(childId, 1);
          } else {
            this.menus = this.menus.filter(
              (item) => item.id !== this.editNode.data.id
            );
          }
          tree.treeModel.update();
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            response.errors.errorMessage
          );
        }
      });
  };
  findNodeById(list: Array<MenuItemTree>, id: string) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.id == id) {
        return item;
      } else if (item.children != null && item.children.length > 0) {
        const result = this.findNodeById(item.children, id);
        if (result) {
          return result;
        }
      }
    }
    return false;
  }

  saveGroupCategoryToDB(id, name, parent, icon) {
    const tempId = 'xxx';
    if (id === tempId) {
      id = '';
    }
    if (icon === 'assets/image/folder.svg') {
      const payload = {
        id,
        name,
        parentId: parent,
      };
      this.doctorService.saveGroupCategory(payload).subscribe((response) => {
        if (response.isValid) {
          if (id === '') {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.SUCCESS,
              'Thêm danh mục thành công'
            );
            const node = this.findNodeById(this.menus, tempId);
            node.id = response.jsonData;
          } else {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.SUCCESS,
              'Cập nhật thành công'
            );
          }
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            response.errors.errorMessage
          );
        }
      });
    } else {
      const payload = {
        groupId: id,
        aliasGroupName: name,
        groupCategoryId: parent,
      };
      this.doctorService.editAliasGroupName(payload).subscribe((response) => {
        if (response.isValid) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Cập nhật tên nhóm thành công'
          );
          if (id == this.curGroupId) {
            this.store.dispatch(
              new Join({
                groupId: id,
                groupName: name,
                groupAvatar: icon,
              })
            );
          }
        } else {
          const node = this.findNodeById(this.menus, id);
          node.name = node.text;
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            response.errors[0].errorMessage
          );
        }
      });
    }
  }

  ngOnInit(): void {}
  handleCancel(): void {
    this.isVisible = false;
    this.closeModal.emit(false);
  }
  addCategory(tree) {
    if (this.contextMenu !== null) {
      this.closeMenu();
    }
    this.addNode(tree);
  }

  addNode(tree: any) {
    const newNodeName = 'Nhập tên danh mục';
    const newNode = {
      id: 'xxx',
      name: newNodeName,
      icon: 'assets/image/folder.svg',
      children: [],

      isLeaf: false,
      expanded: false,
      selectable: true,
    };
    const activeNode = tree.treeModel.activeNodes[0];
    if (activeNode) {
      activeNode.data.children.unshift(newNode);
    } else {
      this.menus.unshift(newNode);
      this.dom.documentElement.getElementsByTagName(
        'tree-viewport'
      )[0].scrollTop = 0;
    }
    tree.treeModel.update();
    const node = tree.treeModel.getNodeById('xxx');
    this.editNode = node;
    if (!node.parent.isExpanded) {
      TREE_ACTIONS.TOGGLE_EXPANDED(tree.treeModel, node.parent, null);
    }
  }
}
