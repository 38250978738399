import { Formula } from './../../../../models/form-model/formula';
import { CoordinateElement } from './../../../../models/form-model/coordinate-element';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { CommonInfoService } from 'src/app/services/commonInfo.service';
import { TestResult } from './../../../../models/form-model/test-result';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CalculatedVariable } from 'src/app/models/form-model/calculated-variable';
import { Constant } from 'src/app/share/constants/constant.class';
import { ObservationTypeService } from 'src/app/services/obserType-service';
import { GroupStateModel } from 'src/app/models/group-state.model';
import { FormService } from 'src/app/services/form.serivce';
import { FormGroup } from '@angular/forms';
import { reverseString } from '@amcharts/amcharts4/.internal/core/utils/Utils';
import { HttpClient } from '@angular/common/http';

const properties = [
  'direction', // RTL support
  'boxSizing',
  'width', // on Chrome and IE, exclude the scrollbar, so the mirror div wraps exactly as the textarea does
  'height',
  'overflowX',
  'overflowY', // copy the scrollbar for IE

  'borderTopWidth',
  'borderRightWidth',
  'borderBottomWidth',
  'borderLeftWidth',
  'borderStyle',

  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',

  // https://developer.mozilla.org/en-US/docs/Web/CSS/font
  'fontStyle',
  'fontVariant',
  'fontWeight',
  'fontStretch',
  'fontSize',
  'fontSizeAdjust',
  'lineHeight',
  'fontFamily',

  'textAlign',
  'textTransform',
  'textIndent',
  'textDecoration', // might not make a difference, but better be safe

  'letterSpacing',
  'wordSpacing',

  'tabSize',
  'MozTabSize',
];

const reservedWord = [
  'abstract',
  'as',
  'base',
  'bool',
  'break',
  'byte',
  'case',
  'catch',
  'char',
  'checked',
  'class',
  'const',
  'continue',
  'decimal',
  'default',
  'delegate',
  'do',
  'double',
  'else',
  'enum',
  'event',
  'explicit',
  'extern',
  'false',
  'finally',
  'fixed',
  'float',
  'for',
  'foreach',
  'goto',
  'if',
  'implicit',
  'in',
  'int',
  'interface',
  'internal',
  'is',
  'lock',
  'long',
  'namespace',
  'new',
  'null',
  'object',
  'operator',
  'out',
  'override',
  'params',
  'private',
  'protected',
  'public',
  'readonly',
  'ref',
  'return',
  'sbyte',
  'sealed',
  'short',
  'sizeof',
  'stackalloc',
  'static',
  'string',
  'struct',
  'switch',
  'this',
  'throw',
  'true',
  'try',
  'typeof',
  'uint',
  'ulong',
  'unchecked',
  'unsafe',
  'ushort',
  'using',
  'virtual',
  'void',
  'volatile',
  'while',
];
@Component({
  selector: 'app-calculated-element',
  templateUrl: './calculated-element.component.html',
  styleUrls: ['./calculated-element.component.scss'],
})
export class CalculatedElementComponent implements OnInit {
  _isVisible: boolean;
  @Input() set isVisible(val: boolean) {
    this._isVisible = val;
    if (val) {
      this.getListCommonInfo();
      this.getListObservationTypeRefer('');
      if (this.formulaId != '') {
        this.getFormulaById();
      } else {
        this.variables = [];
        this.name = '';
        this.script = '';
      }
      this.search_KW_CI = '';
      this.search_KW_OT = '';
    }
  }

  get isVisible(): boolean {
    return this._isVisible;
  }
  @Input() adminPage: boolean;
  @Input() formulaId: string;
  isLoading: boolean;
  name = '';
  observationTypeReferId = '';
  script = '';
  scriptHtml = '';
  variables: CalculatedVariable[] = [];
  panels = [
    {
      active: true,
    },
    {
      active: false,
    },
    {
      active: true,
    },
  ];
  recommentVariables: CalculatedVariable[] = [];
  listOfControl: Array<{ id: number; controlInstance: number }> = [];

  testResults: TestResult[] = [];
  constant = Constant;
  lstCommonInfo: any = [];
  lstFilterCI: any = [];
  lstObservationType: any = [];
  lstObservationTypeRecent: any = [];
  lstobservationTypeRefer: any = [];
  lstFilterOT: any = [];
  search_KW_OT = '';
  search_KW_OT_Recent = '';
  search_KW_CI = '';
  variableForm!: FormGroup;

  currentGroup: GroupStateModel = {
    isAuthenticated: false,
    groupId: '',
    groupName: '',
    isCovidGroup: false,
    isAutoAddTreatmentPatient: false,
    isLoadByPermission: false,
    role: 0,
  };
  isSpinningSearchOT = false;
  isSpinningSearchOTRecent = false;
  callbackSeachOTFn: any;
  callbackSeachOTRecentFn: any;
  // callbackSeachOTReferFn: any;
  isVisibleRecommendMenu = false;
  isConfirmSaveVisible = false;
  isChange = false;
  menuCoordinate: CoordinateElement = new CoordinateElement();
  isScriptError: boolean;
  isNameError: boolean;
  descriptionPath = 'guideForCSTT.html';
  projectDescription = '';
  isBrowser = typeof window !== 'undefined';
  isFirefox = this.isBrowser && window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  @Output() isVisibleChange: EventEmitter<any> = new EventEmitter();
  @Output() addFormulaToForm: EventEmitter<any> = new EventEmitter();

  @ViewChild('listVariables') private listVariables: ElementRef;
  @ViewChild('scriptArea') private scriptArea: ElementRef;
  @ViewChild('backdrop') private backdrop: ElementRef;
  @ViewChild('inputOTRefer') private inputOTRefer: HTMLInputElement;

  constructor(
    private commonInfoService: CommonInfoService,
    private OTService: ObservationTypeService,
    private notificationService: NotificationService,
    private shareService: ShareService,
    private formService: FormService,
    private http: HttpClient
  ) {
    this.http.get('assets/' + this.descriptionPath, { responseType: 'text' }).subscribe((data) => {
      this.projectDescription = data;
    });
    this.callbackSeachOTFn = this.shareService.debounce(this.getListObservationType, 500);
    this.callbackSeachOTRecentFn = this.shareService.debounce(this.getListObservationTypeRecent, 500);
    // this.callbackSeachOTReferFn = this.shareService.debounce(
    //   this.getListObservationTypeRefer,
    //   500
    // );
  }

  ngOnInit() {}

  getFormulaById() {
    this.formService.getFormula(this.formulaId).subscribe((res) => {
      if (res.isValid) {
        this.name = res.jsonData.name;
        this.observationTypeReferId = res.jsonData.observationTypeId;
        this.script = res.jsonData.script;
        this.variables = res.jsonData.variables;
      }
    });
  }

  getCaretPos(event, oField) {
    if (event.ctrlKey && event.keyCode == 32) {
      if (oField.selectionStart || oField.selectionStart == '0') {
        this.recommentVariable();
        this.menuCoordinate = this.getCaretCoordinates(oField, oField.selectionStart, { debug: true });
        this.menuCoordinate.left += oField.offsetLeft;
        this.menuCoordinate.top += oField.offsetTop;
        this.isVisibleRecommendMenu = true;
      }
    }
  }

  addVariable(item, type) {
    const variable = new CalculatedVariable();
    variable.type = type;
    if (type == Constant.VARIABLE_TYPE.COMMON_INFO) {
      variable.dataType = Constant.DATATYPENUMBER.STRING;
    } else {
      variable.dataType = item.dataType;
    }

    variable.sourceId = item.id;
    variable.name = this.shareService.toCamelCase(item.name);
    variable.sourceName = item.title ? item.title : item.name;
    if (reservedWord.filter((t) => t == variable.name).length > 0) {
      // this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, 'Tên biến không được trùng các từ đặc biệt');
      const prefix = this.getPrefix(type);
      variable.name = prefix + variable.name;
    }

    if (item.name.match(/^[0-9]/)) {
      const prefix = this.getPrefix(type);
      variable.name = prefix + variable.name;
    }

    if (this.variables.filter((t) => t.dataType == variable.dataType && t.sourceId == variable.sourceId).length > 0) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Biến đã được thêm');
    } else {
      this.isChange = true;
      this.variables.push(variable);
      setTimeout(() => {
        this.scrollToBottomVariables();
      }, 0);
    }
    console.log(this.variables);
  }

  getPrefix(type) {
    let prefix = '_';
    switch (type) {
      case Constant.VARIABLE_TYPE.COMMON_INFO:
        prefix += 'ci_';
        break;
      case Constant.VARIABLE_TYPE.OBSERVAION:
        prefix += 'ot_';
        break;
      case Constant.VARIABLE_TYPE.OBSERVAION_MOST_RECENT:
        prefix += 'otr_';
        break;
      default:
        break;
    }

    return prefix;
  }

  removeVariable(variable: CalculatedVariable) {
    this.variables = this.variables.filter((t) => t.sourceId != variable.sourceId);
    this.isChange = true;
  }

  closeAddModal() {
    this.isChange = false;
    this.isVisible = false;
    this.isConfirmSaveVisible = false;
    this.isVisibleChange.emit();
  }

  saveAndClose() {
    this.submitCalculated();
  }

  confirmCloseAddModal() {
    if (this.isChange) {
      this.isConfirmSaveVisible = true;
    } else {
      this.closeAddModal();
    }
  }

  getListCommonInfo(): void {
    this.commonInfoService.getAllCommonInfo().subscribe((response) => {
      this.lstCommonInfo = response;
      this.lstFilterCI = this.lstCommonInfo;
    });
  }

  getListObservationType(): void {
    let payload = {
      search: this.search_KW_OT,
      category: -1,
      dataType: 1,
      groupId: '',
    };
    if (this.adminPage) {
    } else {
      payload = {
        search: this.search_KW_OT,
        category: -1,
        dataType: 1,
        groupId: this.currentGroup.groupId,
      };
    }
    this.OTService.getObservationTypeForForm(payload).subscribe((response) => {
      this.lstObservationType = response;
      this.isSpinningSearchOT = false;
    });
  }

  getListObservationTypeRecent(): void {
    let payload = {
      search: this.search_KW_OT_Recent,
      category: -1,
      dataType: 1,
      groupId: '',
    };
    if (this.adminPage) {
    } else {
      payload = {
        search: this.search_KW_OT_Recent,
        category: -1,
        dataType: 1,
        groupId: this.currentGroup.groupId,
      };
    }
    this.OTService.getObservationTypeForForm(payload).subscribe((response) => {
      this.lstObservationTypeRecent = response;
      this.isSpinningSearchOTRecent = false;
    });
  }

  getListObservationTypeRefer(value: string): void {
    // const value = (event.target as HTMLInputElement).value;

    let payload = {
      search: value,
      category: -1,
      dataType: 1,
      groupId: '',
    };
    if (!this.adminPage) {
      payload = {
        search: value,
        category: -1,
        dataType: 1,
        groupId: this.currentGroup.groupId,
      };
    }
    this.OTService.getObservationTypeForForm(payload).subscribe((response) => {
      this.lstobservationTypeRefer = response;
    });
  }

  handleScroll() {
    const scrollTop = this.scriptArea.nativeElement.scrollTop;
    this.backdrop.nativeElement.scrollTop = scrollTop;

    const scrollLeft = this.scriptArea.nativeElement.scrollLeft;
    this.backdrop.nativeElement.scrollLeft = scrollLeft;
  }

  search_KW_CIChange(): void {
    const keyword = this.search_KW_CI.toLowerCase();
    if (keyword) {
      this.lstFilterCI = this.lstCommonInfo.filter((item) => item.title.toLowerCase().includes(keyword));
    }
  }

  search_KW_OTChange(): void {
    this.isSpinningSearchOT = true;
    this.callbackSeachOTFn();
  }

  search_KW_OTRecentChange(): void {
    this.isSpinningSearchOTRecent = true;
    this.callbackSeachOTRecentFn();
  }

  // search_OT_ReferChange(event: Event): void {
  //   this.callbackSeachOTReferFn();
  // }

  async checkFormula() {
    const isValidScriptVaribales = await this.checkScriptContainsAllVariables();
    const isValidScript = await this.emptyScript();
    const isValidVariable = await this.checkVariableValue();
    if (isValidScript || isValidVariable || isValidScriptVaribales) {
      return;
    }

    const payload = {
      formulaVariables: this.variables,
      script: this.script,
    };
    this.formService.checkFormula(payload).subscribe(
      (res) => {
        if (res.isValid) {
          this.testResults = [];
          this.testResults.push(res.jsonData);
          if (res.jsonData.state == 2) {
            this.errorScriptInPosition(res.jsonData.errorPos);
          } else {
            return this.notificationService.showNotification('success', 'Test chỉ số tính toán thành công');
          }
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  errorScriptInPosition(pos: number) {
    this.scriptHtml = this.script;
    this.scriptHtml = this.replaceCharAt(pos, `<mark>${this.scriptHtml.charAt(pos)}</mark>`, this.scriptHtml);
  }

  replaceCharAt(index, replace, src) {
    return src.substr(0, index) + replace + src.substr(index + 1);
  }

  checkScriptContainsAllVariables() {
    if (!this.variables.every((el) => this.script.includes(el.name))) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Chưa nhập hết tham số vào biểu thức');
      return true;
    } else return false;
  }
  async submitCalculated() {
    const isValidScript = await this.emptyScript();
    const isValidVariable = await this.checkVariableValue();
    const isValidName = await this.emptyName();
    if (isValidScript || isValidName || isValidVariable) {
      return;
    }

    const payload = {
      formulaVariables: this.variables,
      script: this.script,
      name: this.name,
      id: this.formulaId,
      observationTypeId: this.observationTypeReferId,
    };

    this.formService.saveFormula(payload).subscribe(
      (res) => {
        if (res.jsonData.state == 2) {
          this.testResults.push(res.jsonData);
        } else {
          const formula = new Formula();
          formula.formulaId = res.jsonData.result.formulaId;
          formula.name = res.jsonData.result.name;
          this.addFormulaToForm.emit(formula);
          this.closeAddModal();
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  checkVariableValue() {
    if (this.variables.length == 0) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Chưa có biến');
      return true;
    }
    let isError = false;
    for (let index = 0; index < this.variables.length; index++) {
      if (this.shareService.checkEmpty(this.variables[index].value)) {
        this.variables[index].isError = true;
        this.variables[index].error = 'Chưa nhập giá trị';
        isError = true;
      } else {
        this.variables[index].isError = false;
        this.variables[index].error = '';
      }
    }
    return isError;
  }

  checkVariableName(variable: CalculatedVariable, index: number) {
    const duplicateName = this.variables.filter((t) => t.name == variable.name);
    if (duplicateName.length > 0) {
      this.variables[index].isError = true;
      this.variables[index].error = 'Tên đã sử dụng';
    } else {
      this.variables[index].isError = false;
      this.variables[index].error = '';
    }
  }

  scrollToBottomVariables(): void {
    try {
      this.listVariables.nativeElement.scrollTop = this.listVariables.nativeElement.scrollHeight;
    } catch (err) {}
  }

  recommentVariable() {
    // this.scriptHtml = this.script.replace(/[\n\r]/g, '</br>');
    this.scriptHtml = this.script;
    const oneLine = this.script.replace(/[\n\r]/g, ' ');
    const lastWord = this.shareService.splitLastWord(oneLine);
    if (lastWord) {
      this.recommentVariables = this.variables.filter((t) => t.name.startsWith(lastWord));

      if (this.recommentVariables.length == 0) {
        this.isVisibleRecommendMenu = false;
      }
    } else {
      this.isVisibleRecommendMenu = false;
    }
  }

  syncScript() {
    // this.scriptHtml = this.script.replace(/[\n\r]/g, '</br>');
    this.scriptHtml = this.script;
    this.isScriptError = false;
  }

  typeValue(variable: CalculatedVariable) {}

  addVariableToScript(item: CalculatedVariable) {
    console.log(this.script);
    const lastSpace = this.script.lastIndexOf(' ');
    const lastEnter = this.script.lastIndexOf('\n');
    const lastIndex = lastSpace > lastEnter ? lastSpace : lastEnter;
    this.script = this.script.slice(0, lastIndex + 1);
    this.script += item.name;
    this.scriptArea.nativeElement.focus();
    this.scriptArea.nativeElement.selectionEnd = lastIndex + 1 + item.name.length;
    this.isVisibleRecommendMenu = false;
    this.syncScript();
    console.log(this.script);
  }

  getCaretCoordinates(element, position, options) {
    if (!this.isBrowser) {
      throw new Error('textarea-caret-position#getCaretCoordinates should only be called in a browser');
    }

    const debug = (options && options.debug) || false;
    if (debug) {
      const el = document.querySelector('#input-textarea-caret-position-mirror-div');
      if (el) {
        el.parentNode.removeChild(el);
      }
    }

    // The mirror div will replicate the textarea's style
    const div = document.createElement('div');
    div.id = 'input-textarea-caret-position-mirror-div';
    document.body.appendChild(div);

    const style = div.style;
    const computed = window.getComputedStyle ? window.getComputedStyle(element) : element.currentStyle; // currentStyle for IE < 9
    const isInput = element.nodeName === 'INPUT';

    // Default textarea styles
    style.whiteSpace = 'pre-wrap';
    if (!isInput) {
      style.wordWrap = 'break-word';
    } // only for textarea-s

    // Position off-screen
    style.position = 'absolute'; // required to return coordinates properly
    if (!debug) {
      style.visibility = 'hidden';
    } // not 'display: none' because we want rendering

    // Transfer the element's properties to the div
    properties.forEach(function (prop) {
      if (isInput && prop === 'lineHeight') {
        // Special case for <input>s because text is rendered centered and line height may be != height
        if (computed.boxSizing === 'border-box') {
          const height = parseInt(computed.height);
          const outerHeight =
            parseInt(computed.paddingTop) +
            parseInt(computed.paddingBottom) +
            parseInt(computed.borderTopWidth) +
            parseInt(computed.borderBottomWidth);
          const targetHeight = outerHeight + parseInt(computed.lineHeight);
          if (height > targetHeight) {
            style.lineHeight = height - outerHeight + 'px';
          } else if (height === targetHeight) {
            style.lineHeight = computed.lineHeight;
          } else {
            style.lineHeight = '0';
          }
        } else {
          style.lineHeight = computed.height;
        }
      } else {
        style[prop] = computed[prop];
      }
    });

    if (this.isFirefox) {
      // Firefox lies about the overflow property for textareas: https://bugzilla.mozilla.org/show_bug.cgi?id=984275
      if (element.scrollHeight > parseInt(computed.height)) {
        style.overflowY = 'scroll';
      }
    } else {
      style.overflow = 'hidden'; // for Chrome to not render a scrollbar; IE keeps overflowY = 'scroll'
    }

    div.textContent = element.value.substring(0, position);
    // The second special handling for input type="text" vs textarea:
    // spaces need to be replaced with non-breaking spaces - http://stackoverflow.com/a/13402035/1269037
    if (isInput) {
      div.textContent = div.textContent.replace(/\s/g, '\u00a0');
    }

    const span = document.createElement('span');
    // Wrapping must be replicated *exactly*, including when a long word gets
    // onto the next line, with whitespace at the end of the line before (#7).
    // The  *only* reliable way to do that is to copy the *entire* rest of the
    // textarea's content into the <span> created at the caret position.
    // For inputs, just '.' would be enough, but no need to bother.
    span.textContent = element.value.substring(position) || '.'; // || because a completely empty faux span doesn't render at all
    div.appendChild(span);

    const coordinates = {
      top: span.offsetTop + parseInt(computed.borderTopWidth),
      left: span.offsetLeft + parseInt(computed.borderLeftWidth),
      height: parseInt(computed.lineHeight),
    };

    if (debug) {
      span.style.backgroundColor = '#aaa';
    } else {
      document.body.removeChild(div);
    }

    return coordinates;
  }

  emptyScript() {
    this.isChange = true;
    if (this.script.length > 0) {
      this.isScriptError = false;
      return false;
    }
    this.isScriptError = true;
    return true;
  }

  emptyName() {
    this.isChange = true;
    if (this.name.length > 0) {
      this.isNameError = false;
      return false;
    }
    this.isNameError = true;
    return true;
  }
}
