import { FormStyle } from '@angular/common';
import { FormatStyle } from './form.class';

export class ObservationType {
  id: string;
  category: string;
  datasource: any;
  dataType: string;
  description: string;
  groupId: string;
  name: string;
  unit: string;

  constructor(observationType: any) {
    this.id = observationType.Id;
    this.category = observationType.Category;
    this.datasource = observationType.DataSource;
    this.dataType = observationType.DataType;
    this.description = observationType.Description;
    this.groupId = observationType.GroupId;
    this.name = observationType.Name;
    this.unit = observationType.Unit;
  }
}
