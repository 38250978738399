import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppState } from 'src/app/components/app-state/app-state';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { WorkReminderSampleService } from 'src/app/services/workReminderSample.service';

@Component({
  selector: 'app-reminder-sample',
  templateUrl: './reminder-sample.component.html',
  styleUrls: ['./reminder-sample.component.scss'],
})
export class ReminderSampleComponent implements OnInit, OnChanges {
  @Input() isVisible = false;
  //@Input() listMinutesBeforeReminder = [];

  @Output() isVisibleChange = new EventEmitter<any>();
  @Output() usedSample = new EventEmitter<any>();

  selectedSample = {
    creatorId: '',
    title: '',
    detail: '',
    id: '',
    minutesBeforeReminder: null,
  };
  isEditing = false;
  isShowReminderLog = false;
  keywordsearch = '';
  curUserId = '';
  groupId = '';
  lstWorkReminderSample = [];
  sampleForm: FormGroup;
  listMinutesBeforeReminder = [
    {
      label: '15 phút',
      value: 15,
    },
    {
      label: '30 phút',
      value: 30,
    },
    {
      label: '1 giờ',
      value: 60,
    },
    {
      label: '2 giờ',
      value: 120,
    },
    {
      label: '1 ngày',
      value: 1440,
    },
  ];

  constructor(
    private notificationService: NotificationService,
    private workReminderSampleService: WorkReminderSampleService,
    private modalService: NzModalService,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private shareService: ShareService
  ) {
    this.sampleForm = this.formBuilder.group({
      id: [null],
      creatorId: [''],
      title: ['', [Validators.required, this.hasWhiteSpaceValidator]],
      detail: [''],
      minutesBeforeReminder: [0],
    });
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
    this.store
      .select((store) => store.groupAuth.groupId)
      .subscribe((res) => {
        this.groupId = res;
      });
  }

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    let chngVisible = changes.isVisible;
    if (chngVisible != null && chngVisible.currentValue) {
      this.selectedSample = {
        id: '',
        creatorId: '',
        detail: '',
        title: '',
        minutesBeforeReminder: null,
      };
      this.sampleForm.reset();
      this.sampleForm.enable();
      this.keywordsearch = '';

      if (this.lstWorkReminderSample.length == 0) {
        this.getWorkReminderSample();
      }
    }
  }
  hasWhiteSpaceValidator = (control: FormControl): { [s: string]: boolean } => {
    if (this.shareService.hasWhiteSpace(control.value)) {
      // you have to return `{error: true}` to mark it as an error event
      return { error: true, emptyName: true };
    } else {
      return {};
    }
  };
  getWorkReminderSample() {
    let payload = {
      title: '',
      groupId: this.groupId,
      pageSize: 20,
      page: 1,
    };
    this.workReminderSampleService.searchReminderSample(payload).subscribe(
      (res) => {
        if (res.source == null) {
          this.lstWorkReminderSample = [];
        } else {
          this.lstWorkReminderSample = res.source;
        }
      },
      (error) => {
        this.notificationService.showNotification(
          'error',
          'Không thể lấy dữ liệu'
        );
      }
    );
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(false);

    this.isEditing = false;
  }
  searchListSample() {
    const payload = {
      title: this.keywordsearch,
      groupId: this.groupId,
      pageSize: 20,
      page: 1,
    };
    this.workReminderSampleService.searchReminderSample(payload).subscribe(
      (res) => {
        if (res.source == null) {
          this.lstWorkReminderSample = [];
        } else {
          this.lstWorkReminderSample = res.source;
        }
      },
      (error) => {
        this.notificationService.showNotification(
          'error',
          'Không thể lấy dữ liệu'
        );
      }
    );
  }
  selectSampleChange(data) {
    this.isEditing = false;
    if (this.selectedSample.id == data.id) {
      this.selectedSample = {
        id: '',
        title: '',
        creatorId: '',
        detail: '',
        minutesBeforeReminder: null,
      };
      this.sampleForm.enable();
      this.sampleForm.reset();
    } else {
      this.selectedSample = data;
      this.sampleForm.patchValue({
        id: data.id,
        title: data.title,
        creatorId: data.creatorId,
        detail: data.detail,
        minutesBeforeReminder: data.minutesBeforeReminder,
      });
      this.sampleForm.disable();
    }
  }
  save() {
    if (!this.sampleForm.valid) {
      this.notificationService.showNotification(
        'error',
        'Bạn cần nhập các trường bắt buộc!'
      );
      return;
    }
    let formValue = this.sampleForm.value;
    const payload = {
      id: formValue.id ?? '',
      groupId: this.groupId,
      title: formValue.title ?? '',
      detail: formValue.detail ?? '',
      minutesBeforeReminder: formValue.minutesBeforeReminder ?? 0,
    };
    if (formValue.id == null) {
      this.workReminderSampleService.addReminderSample(payload).subscribe(
        (res) => {
          this.notificationService.showNotification(
            'success',
            'Tạo mới thành công'
          );
          this.sampleForm.reset();
          this.getWorkReminderSample();
        },
        (error) => {
          this.notificationService.showNotification(
            'error',
            'Không thể thêm công việc'
          );
        }
      );
    } else {
      this.workReminderSampleService.updateReminderSample(payload).subscribe(
        (res) => {
          this.notificationService.showNotification(
            'success',
            'Cập nhật thành công'
          );
          this.isEditing = false;
          this.sampleForm.disable();
          this.getWorkReminderSample();
        },
        (error) => {
          this.notificationService.showNotification(
            'error',
            'Không cập nhật dữ liệu'
          );
        }
      );
    }
  }

  deleteSample() {
    const id = this.selectedSample.id;
    if (id == '') {
      return this.notificationService.showNotification(
        'warning',
        'Bạn chưa chọn mẫu để xoá!'
      );
    } else {
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn xóa hay không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Huỷ',
        nzOnOk: () => this.deleteSampleOk(id),
      });
    }
  }

  deleteSampleOk(id) {
    this.workReminderSampleService
      .deleteReminderSample(id)
      .subscribe((data) => {
        this.notificationService.showNotification('success', 'Xoá thành công');
        this.lstWorkReminderSample = this.lstWorkReminderSample.filter(
          (en) => en.id != id
        );
        this.sampleForm.reset();
        this.sampleForm.enable();
        this.refreshSelected();
      }).unsubscribe;
  }
  refreshSelected() {
    this.selectedSample = {
      creatorId: '',
      title: '',
      detail: '',
      id: '',
      minutesBeforeReminder: null,
    };
  }

  useSample() {
    let formValue = this.sampleForm.value;
    if (this.shareService.checkEmpty(formValue.id) && !this.sampleForm.valid) {
      this.notificationService.showNotification(
        'error',
        'Bạn cần nhập các trường bắt buộc!'
      );
      return;
    }
    let obj = {
      title: formValue.title,
      detail: formValue.detail,
      minutesBeforeReminder: formValue.minutesBeforeReminder,
    };
    this.usedSample.emit(obj);
    console.log('usedSample')
    this.isVisible = false;
    this.isVisibleChange.emit(false);
    this.isEditing = false;
  }

  editSample() {
    this.isEditing = true;
    this.sampleForm.enable();
  }
  handleCancelEditSample() {
    this.isEditing = false;
    this.sampleForm.disable();

    let data = this.selectedSample;
    this.sampleForm.patchValue({
      id: data.id,
      title: data.title,
      creatorId: data.creatorId,
      detail: data.detail,
      minutesBeforeReminder: data.minutesBeforeReminder,
    });
  }
}
