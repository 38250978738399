<div
  style="
    display: flex;
    justify-content: center;
    background: #9e9e9e;
    min-height: 100%;
  ">
  <div style="width: 210mm; background: #fff">
    <app-print-template
      [data]="listMedicationTable"
      [config]="templateDrugList"
      [prescription]="prescription"
      [visit]="visit"
      [printHeader]="visit.printHeader"
      [isUseDeliveryInfo]="isUsePrescriptionDeliveryInfo"></app-print-template>
  </div>
</div>
<!-- <div
  style="
    display: flex;
    justify-content: center;
    background: #9e9e9e;
    min-height: 100%;
  ">
  <div id="print-div-prescription" style="width: 210mm; background: white">
    <div [innerHTML]="visit.printHeader | safeHtml"></div>
    <div style="padding: 20px">
      <div class="row">
        <div class="col-5">
          <label class="info-label">Tên BN: </label>
          <label class="label">{{ visit.patientName }}</label>
        </div>
        <div class="col-4">
          <label class="info-label">Giới tính: </label>
          <label class="label">{{ visit.patientSexText }}</label>
        </div>
        <div class="col-3">
          <label class="info-label">Tuổi: </label>
          <label class="label">{{ visit.dob | ageStringFromDOB: false }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <label class="info-label">Địa chỉ: </label>
          <label class="label">{{ visit.address }}</label>
        </div>
        <div class="col-4">
          <label class="info-label">SĐT: </label>
          <label class="label">{{ visit.phoneNo }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <label class="info-label">Ngày khám: </label>
          <label class="label">{{
            visit.visitDate | date: 'dd/MM/yyyy'
          }}</label>
        </div>
        <div class="col-7">
          <label class="info-label">Lý do khám: </label>
          <label class="label">{{ visit.visitReason }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label class="info-label">Chẩn đoán: </label>
          <label class="label">{{ visit.conclusion }}</label>
        </div>
      </div>
      <app-print-template
        [data]="tabledata"
        [config]="templateDrugList"></app-print-template>
      <br /><br />
      <table
        style="width: 100%; margin-bottom: 20px"
        class="table-prescription-info">
        <tr>
          <td colspan="2">
            <label style="font-weight: bold" class="label">LỜI DẶN:</label>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div
              [innerHTML]="
                convertTextbox2Html(prescription.doctorComment)
              "></div>
          </td>
        </tr>
        <tr style="display: inline-grid; margin-top: 10px">
          <td colspan="2">
            <label style="font-weight: bold" class="label">LƯU Ý:</label>
            {{ PRESCRIPTION_NOTE }}
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div [innerHTML]="getTextOfPrescriptionNote()"></div>
          </td>
        </tr>
        <ng-container *ngIf="isUsePrescriptionDeliveryInfo">
          <tr>
            <td colspan="2">
              <label style="font-weight: bold" class="label"
                >THÔNG TIN GIAO THUỐC:</label
              >
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label"
                >Họ tên người nhận:</label
              >
            </td>
            <td>
              <label>{{ prescription.recipientName }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Số điện thoại:</label>
            </td>
            <td>
              <label>{{ prescription.recipientPhoneNo }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Địa chỉ:</label>
            </td>
            <td>
              <label>{{ prescription.recipientAddress }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label"
                >Thời gian có thể nhận:</label
              >
            </td>
            <td>
              <label>{{ prescription.recipientTimePlan }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label"
                >Hình thức thanh toán:</label
              >
            </td>
            <td>
              <label>{{ prescription.paymentMethod }}</label>
            </td>
          </tr>
          <tr>
            <td style="width: 160px">
              <label style="width: 100%" class="label">Ghi chú:</label>
            </td>
            <td>
              <label>{{ prescription.recipientNote }}</label>
            </td>
          </tr>
        </ng-container>
      </table>
      <br />
      <table
        style="
          width: 40%;
          margin-bottom: 20px;
          text-align: center;
          float: right;
        "
        class="table-prescription-sign">
        <tr>
          <td colspan="2">
            <label style="font-weight: bold" class="label">
              {{ prescription.createdDate | dayMonthYearString }}
            </label>
          </td>
        </tr>
        <ng-container *ngIf="prescription.creatorRole === 'Doctor'">
          <tr>
            <td colspan="2">
              <div>BS kê đơn</div>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="padding-top: 50px">
              {{ prescription.creatorName }}
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
</div> -->
