import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/share/share.module';
import { ExaminationServiceComponent } from './examination-service.component';



@NgModule({
  declarations: [ExaminationServiceComponent],
  imports: [
    CommonModule,SharedModule
  ],
  exports: [ExaminationServiceComponent]
})
export class ExaminationServiceModule { }
