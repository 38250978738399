<ng-container *ngIf="isVisible">
  <div
    style="height: calc(100vh - 100px)"
    appCustomModalMovable
    [modalId]="noteId"
    [needFocus]="true"
    *ngIf="isVisible"
    class="msc-custom-modal"
    nzClosable="false"
    nzMaskClosable="false"
    #prescriptionHeader>
    <div class="div-header">
      <div>{{ titleModal }}</div>
      <a (click)="handleCancel()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </a>
    </div>
    <div class="div-content d-flex">
      <div class="content-editor">
        <div class="preview-text m-title">Chỉnh sửa nội dung</div>
        <div class="editor">
          <angular-editor
            *ngIf="isVisible"
            [config]="editorConfig"
            [(ngModel)]="content"
            #htmlEditor
            [placeholder]="'Enter text here...'"
            (paste)="onPaste($event)"></angular-editor>
        </div>
        <div class="btn-action">
          <button nz-button nzType="primary" (click)="updateNotes()" class="btn-primary-small mgr10">Lưu</button>
          <button nz-button class="btn-cancel-small" (click)="handleCancel()">Hủy</button>
        </div>
      </div>
      <div class="content-preview" style="height: calc(100% - 20px)">
        <div class="preview-text m-title">Xem trước</div>
        <div class="preview-overal">
          <div class="preview" [innerHtml]="content | safeHtml"></div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="width: 600px"
    appCustomModalMovable
    *ngIf="isVisibleImageEditor"
    class="msc-custom-modal image-modal"
    nzClosable="false"
    nzMaskClosable="false">
    <div class="div-header">
      <div>Ảnh</div>
      <a (click)="handleCancelShowImage()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </a>
    </div>
    <div class="div-content">
      <table style="width: 100%">
        <tr>
          <td>
            <input
              nz-input
              [disabled]="true"
              class="custom-text"
              type="text"
              id="txtImageUrl"
              [(ngModel)]="curImageUrl"
              style="width: 393px" />
          </td>
          <td>
            <nz-upload
              nz-col
              [nzSpan]="16"
              [nzBeforeUpload]="beforeUpload"
              [nzCustomRequest]="handleUploadContactAvatar"
              nzName="contactIdBack"
              [nzShowUploadList]="false"
              (nzChange)="handleChangeContactAvatar($event)">
              <button nz-button><i nz-icon nzType="upload"></i>Click to Upload</button>
            </nz-upload>
          </td>
        </tr>
      </table>
      <br />
      <table style="width: 100%">
        <tr>
          <td>
            <div class="div-inline">
              <label class="size-label">Width: </label>
              <input
                nz-input
                class="custom-text size-input"
                type="number"
                [(ngModel)]="imgWidth"
                (ngModelChange)="changeImageWidth()"
                [min]="0" />
            </div>
            <div class="div-inline">
              <label class="size-label">Height: </label>
              <input
                nz-input
                class="custom-text size-input"
                type="number"
                [(ngModel)]="imgHeight"
                (ngModelChange)="changeImageHeight()"
                [min]="0" />
            </div>
          </td>
          <td>
            <div style="width: 300px; height: 200px; overflow: auto; border: solid 1px #ccc">
              <nz-spin [nzSpinning]="isLoading" style="height: 100%">
                <img id="uploadImg" *ngIf="curImageUrl" style="{{ toImageStyle() }}" [src]="curImageUrl" #imageUpload />
              </nz-spin>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="div-action">
      <button nz-button nzType="primary" (click)="handleImageOk()" class="btn-primary-medium">Lưu</button>
      <button nz-button class="btn-cancel-medium" (click)="handleCancelShowImage()">Hủy</button>
    </div>
  </div>
</ng-container>
