import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';

@Component({
  selector: 'app-month-view',
  templateUrl: './month-view.component.html',
  styleUrls: ['./month-view.component.scss'],
})
export class MonthViewComponent implements OnInit {
  _timeSlotsDisplay;
  get timeSlotsDisplay() {
    return this._timeSlotsDisplay;
  }
  @Input() set timeSlotsDisplay(value) {
    this._timeSlotsDisplay = value;
  }

  _time;
  get time() {
    return this._time;
  }
  @Input() set time(value) {
    this._time = value;
    // console.log(value);

    this.processDate();

  }
  _listVisitGroupByDoctorId;
  get listVisitGroupByDoctorId() {
    return this._listVisitGroupByDoctorId;
  }
  @Input() set listVisitGroupByDoctorId(value) {
    this._listVisitGroupByDoctorId = value;
  }
  @Output() visitItemHover = new EventEmitter<any>();
  @Output() visitItemLeave = new EventEmitter<any>();

  constructor(private tabDataService: TabDataService) {}

  monthName: string;
  month;
  year: number;
  daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  weeks = [[], [], [], [], [], []];

  ngOnInit() {}

  processDate() {
    this.monthName = this.getMonthName();
    this.month = this.time.getMonth();
    this.year = this.time.getFullYear();


    let firstDay = new Date(this.year, this.time.getMonth(), 1);
    // console.log(firstDay);

    let lastDay = new Date(this.year, this.time.getMonth() + 1, 0);
    let date = 1;
    let dayCounter = (firstDay.getDay() === 0 ? 7 : firstDay.getDay()) - 1;

    for (let i = 0; i < 6; i++) {
      for (let j = dayCounter; j < 7; j++) {
        if (date > lastDay.getDate()) {
          break;
        }
        this.weeks[i][j] = date;
        date++;
      }
      dayCounter = 0;
    }
  }

  getMonthName(): string {
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[this.time.getMonth()];
  }
  onVisitItemClick(event) {
    let dataVisit = JSON.parse(event.target.getAttribute('data-visit'));
    let popups = document.querySelectorAll('.popup-patient-info');

    // event.target.style.color = 'var(--dp-primary-2)';

    if (popups) {
      popups.forEach((el) => {
        el.remove();
      });
    }
    if(dataVisit.id && dataVisit.patientName){
      this.tabDataService.updateTab(dataVisit?.id, dataVisit?.patientName, 'VisitDetail', dataVisit?.id);
    }
  }
  onVisitItemHover(event) {
    this.visitItemHover.emit(event);
  }

  onVisitItemLeave(event) {
    this.visitItemLeave.emit();
  }
}
