import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { Observable } from 'rxjs';
import { UrlConstant } from '../share/constants/url.class';
import { Ros } from '../models/ros.class';

@Injectable({
  providedIn: 'root',
})
export class RosService extends BaseService {
  getRos(): Observable<Ros[]> {
    return this.get(UrlConstant.ROS);
  }
  getRosCategory(id: string) {
    return this.get(UrlConstant.ROS_CATEGORY + 'ros' + '/' + id);
  }
}
