import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

@Injectable({
  providedIn: 'root',
})
export class PatientService extends BaseService {
  disabledDateAfterToday = (curr: Date) => differenceInCalendarDays(curr, new Date()) > 0;

  disabledDateBeforeToday = (curr: Date) => differenceInCalendarDays(curr, new Date()) < 0;

  addPatient(payload): Observable<any> {
    return this.post(UrlConstant.PATIENT, payload);
  }

  checkDuplicate(payload): Observable<any> {
    return this.post(UrlConstant.DUPLICATE_PATIENT, payload);
  }

  getPatient(keyword): Observable<any> {
    return this.get(UrlConstant.PATIENT + keyword);
  }

  getPatientProfile(patientCode): Observable<any> {
    return this.post(UrlConstant.PATIENT_AUTO_GENERATE_INFO + patientCode, {});
  }

  getPatientUsername(patientCode): Observable<any> {
    return this.post(UrlConstant.PATIENT_USERNAME + patientCode, {});
  }

  searchPatient(keyword): Observable<any> {
    return this.get(UrlConstant.PATIENT + 'SearchPatient/' + keyword);
  }

  searchPatientInGroup(payload): Observable<any> {
    return this.post(UrlConstant.PATIENT + 'SearchPatientByGroup', payload);
  }

  // all patient in patient list and visit list
  searchAllPatientInGroup(payload): Observable<any> {
    return this.post(UrlConstant.PATIENT + 'SearchAllPatientByGroup', payload);
  }

  getVisitHistory(patientId): Observable<any> {
    return this.post(UrlConstant.LIST_VISIT_HIS, patientId);
  }

  updateMedicalHistory(medical): Observable<any> {
    return this.post(UrlConstant.MEDICAL_HISTORY, medical);
  }

  UpdateVaccineHistory(vaccine): Observable<any> {
    return this.post(UrlConstant.VACCINE_HISTORY, vaccine);
  }

  updatePatient(payload) {
    return this.put(UrlConstant.PATIENT + payload.id, payload);
  }

  getPatientFamilyHistory(patientId): Observable<any> {
    return this.get('/api/FamilyHistory/GetListByPatientId/' + patientId);
  }

  updateFamilyHistory(payload): any {
    return this.put('/api/FamilyHistory/update', payload);
  }

  selectPatient(id): Observable<any> {
    return this.get(UrlConstant.PATIENT + 'SelectPatient/' + id);
  }

  getListTreatmentPatientGroup(payload): Observable<any> {
    return this.post(UrlConstant.LIST_TREATMENT_PATIENT, payload);
  }

  exportListTreatmentPatientGroup(data): Observable<any> {
    return this.httpClient.post(
      this.configService.getConfig().api.baseUrl +
        UrlConstant.EXPORT_TREATMENT_PATIENT,
      data,
      {
        headers: this.createHeaders() || {},
        responseType: 'blob' as 'json',
      }
    );
  }

  editStatusTreatmentPatient(payload): Observable<any> {
    return this.post(UrlConstant.EDIT_STATUS_PATIENT, payload);
  }

  getPatientFamilyMember(patientId): Observable<any> {
    return this.get(UrlConstant.PATIENT_FAMILY_MEMBER + patientId);
  }

  createPatientFamilyMember(payload): Observable<any> {
    return this.post(UrlConstant.PATIENT_FAMILY_MEMBER_CREATE, payload);
  }

  deletePatientFamilyMember(relationId): Observable<any> {
    return this.post(UrlConstant.PATIENT_FAMILY_MEMBER_DELETE + relationId, {});
  }

  resetPassword(payload): Observable<any> {
    return this.post(UrlConstant.RESET_PASSWORD, payload);
  }

  updateCovidNumber(payload): Observable<any> {
    return this.post(UrlConstant.UPDATE_COVID_NUMBER, payload);
  }
}
