import { DoctorInGroup } from './../../../models/doctor-in-group.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ListReminderService } from 'src/app/services/ListReminder.service';
import { WorkReminderSampleService } from 'src/app/services/workReminderSample.service';
import { PatientService } from 'src/app/services/patient.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Patient } from '../../../models/patient.class';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { DoctorService } from 'src/app/services/doctor.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { ShareService } from 'src/app/services/share.service';
import { ActivatedRoute , Router} from '@angular/router';

@Component({
  selector: 'app-list-reminder',
  templateUrl: './list-reminder.component.html',
  styleUrls: ['./list-reminder.component.scss'],
})
export class ListReminderComponent implements OnInit {
  @Input() patient: Patient;

  @Output() selectPatient: EventEmitter<any> = new EventEmitter();

  groupSub: Subscription;
  groupId: string;
  list_reminders = [];
  listStatus = ['New', 'Doing', 'Done', 'Cancel'];
  lstPatientSearch = [];
  doctorInGroup: DoctorInGroup[] = [];

  pageIndex = 1;
  pageSize = 20;
  total = 1;
  dataSearch: any = {
    patientName: '',
    doctorName: '',
    patientCode: '',
    patientPhoneNo: '',
    status: -1,
    from: '',
    to: '',
  };
  selectedPatient: any;
  listMinutesBeforeReminder = [
    {
      label: '15 phút',
      value: 15,
    },
    {
      label: '30 phút',
      value: 30,
    },
    {
      label: '1 giờ',
      value: 60,
    },
    {
      label: '2 giờ',
      value: 120,
    },
    {
      label: '1 ngày',
      value: 1440,
    },
  ];
  currentTime = new Date();
  dateFormat = 'dd/MM/yyyy';
  disabledDateBeforeToday = this.patientService.disabledDateBeforeToday;
  isVisibleView = false;
  justClicked = false;
  doubleClicked = false;
  curUserId = '';
  isShowReminderLog = false;
  currentReminderLog: any;
  lstWorkReminderSample = [];
  visibleForm = false;
  curReminder: any;
  isInvalidDate = false;
  callbackSearchFn: any;

  constructor(
    private notificationService: NotificationService,
    private doctorService: DoctorService,
    private store: Store<AppState>,
    private listReminderService: ListReminderService,
    private patientService: PatientService,
    private modalService: NzModalService,
    private tabDataService: TabDataService,
    private workReminderSampleService: WorkReminderSampleService,
    private shareService: ShareService,
    private activeRoute:ActivatedRoute,
    private router:Router
  ) {
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
    this.callbackSearchFn = this.shareService.debounce(
      this.onSearchPatient,
      500
    );
  }

  ngOnInit() {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        this.getDoctorInGroup(this.groupId);
      }
    });
    this.list_reminders = [];
    this.loadListReminder();
    setTimeout(() => {
      const idReminderParam=this.activeRoute.snapshot.queryParamMap.get('other');
      console.log(idReminderParam);
      if(idReminderParam){
        this.listReminderService.getReminder(idReminderParam).subscribe((res)=>{
          if(res){
            console.log(idReminderParam);

            this.curReminder = res;
            this.visibleForm = true;
          }
          else{
            this.notificationService.showNotification('error',"Không tìm được công việc")
          }
          this.router.navigate([], {
            queryParams: {
              'id': null,
              'name': null,
              'type': null,
              'other': null,
            },
            queryParamsHandling: 'merge'
          })
        })
      }
      }, 1000);
  }

  onSearchSample(keyword: string) {
    if (keyword != null && keyword != '') {
      keyword = keyword.trim();
      const payload = {
        title: keyword,
        groupId: this.groupId,
        pageSize: this.pageSize,
        page: this.pageIndex,
      };
      this.workReminderSampleService.searchReminderSample(payload).subscribe(
        (res) => {
          if (res.source == null) {
            this.lstWorkReminderSample = [];
          } else {
            this.lstWorkReminderSample = res.source;
            this.total = res.itemCount;
          }
        },
        (error) => {
          this.notificationService.showNotification(
            'error',
            'Không thể lấy dữ liệu'
          );
        }
      );
    }
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.loadListReminder();
  }

  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;
      }
    });
  }

  reloadDataTable() {
    this.pageIndex = 1;
    this.dataSearch = {
      patientName: '',
      doctorName: '',
      patientCode: '',
      patientPhoneNo: '',
      status: -1,
      from: '',
      to: '',
    };
    this.loadListReminder();
  }

  loadListReminder() {
    let payload = {
      ...this.dataSearch,
      pageSize: this.pageSize,
      page: this.pageIndex,
      groupId: this.groupId,
      patientCode: '',
    };
    this.listReminderService.searchReminder(payload).subscribe(
      (res) => {
        if (res.source == null) {
          this.list_reminders = [];
        } else {
          this.list_reminders = res.source.map((obj) => ({
            ...obj,
            isEditStatus: false,
          }));
          this.total = res.itemCount;
        }
      },
      (error) => {
        this.notificationService.showNotification(
          'error',
          'Không thể nhận dữ liệu'
        );
      }
    );
  }
  toggleShowReminderLog() {
    this.isShowReminderLog = !this.isShowReminderLog;
  }
  getReminderLog() {
    this.listReminderService.getReminderLog(this.curReminder.id).subscribe(
      (res) => {
        this.currentReminderLog = res;
      },
      (error) => {
        console.error('Get log reminder error');
      }
    );
  }
  showModalAdd() {
    this.curReminder = null;
    this.visibleForm = true;
  }
  showModal(): void {
    this.visibleForm = true;
  }

  deleteReminder(data) {
    const id = data;
    if (id == '') {
      this.notificationService.showNotification(
        'warning',
        'Bạn chưa chọn dòng để xoá!'
      );
    } else {
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn xóa hay không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Huỷ',
        nzOnOk: () => this.deleteItem(id),
      });
    }
  }

  deleteItem(id) {
    this.listReminderService.deleteReminder(id).subscribe((data) => {
      this.notificationService.showNotification('success', 'Xoá thành công');
      this.loadListReminder();
    }).unsubscribe;
  }
  saveStatus(data) {
    if (!data.minutesBeforeReminder) {
      data.minutesBeforeReminder = 0;
    }
    const payload = { ...data, groupId: this.groupId };
    this.listReminderService.updateReminder(payload).subscribe(
      (data) => {
        this.notificationService.showNotification(
          'success',
          'Cập nhật thành công'
        );
        this.loadListReminder();
      },
      (error) => {
        this.notificationService.showNotification(
          'error',
          'Chưa nhập hết dữ liệu'
        );
      }
    );
  }

  searchReminder(datasend) {
    if (datasend.status == null) {
      datasend.status = -1;
    }
    this.loadListReminder();
  }

  rowSelected(data) {
    this.curReminder = data;

    if (this.justClicked === true) {
      this.doubleClicked = true;
      this.doubleClick();
    } else {
      this.justClicked = true;
      setTimeout(() => {
        this.justClicked = false;
        if (this.doubleClicked === false) {
          this.singleClick();
        }
        this.doubleClicked = false;
      }, 500);
    }
  }

  doubleClick() {
    this.visibleForm = true;
  }
  singleClick() {}

  selectSearchPatient(data) {
    this.patientService.selectPatient(data.id).subscribe((response) => {
      if (response && response.isValid) {
        this.selectedPatient = response.jsonData;
      }
    });
  }

  selectSearchPatientEvent(event: any, data: any) {
    if (event.isUserInput) {
      //do something
      this.selectSearchPatient(data);
    }
  }

  selectPatientName(data) {
    this.patientService.selectPatient(data.id).subscribe((response) => {
      if (response && response.isValid) {
        this.dataSearch.patientName = response.jsonData.fullName;
      }
    });
  }

  onSearchPatient() {
    let keyword = this.dataSearch.patientName;
    if (keyword != null && keyword != '') {
      keyword = keyword.trim();
      const payload = {
        textSearch: keyword,
        groupId: this.groupId,
      };
      this.patientService.searchAllPatientInGroup(payload).subscribe((res) => {
        this.lstPatientSearch = res ? res : [];
      });
    }
  }

  editStatus(data) {
    data.isEditStatus = !data.isEditStatus;
  }

  convertDateFrom(date) {
    if (date) {
      const time = date.setHours(0, 0, 0, 0);
      const date2 = new Date(time);
      this.dataSearch.from = date2.toString();
    } else {
      this.dataSearch.from = '';
    }
  }

  convertDateTo(date) {
    if (date) {
      const time = date.setHours(0, 0, 0, 0);
      const date2 = new Date(time);
      this.dataSearch.to = date2.toString();
    } else {
      this.dataSearch.to = '';
    }
  }

  onChange(date) {
    this.isInvalidDate =
      Date.parse(date) < Date.parse(this.currentTime.toString());
  }

  addTab(id, PatientName, patientId) {
    //clearTimeout(settime);
    //$('#tooltip').hide();
    this.isVisibleView = false;
    this.tabDataService.updateTab(id, PatientName, 'VisitDetail', patientId);
  }

  log(elementId, reminder) {
    if (elementId != 'r-' + reminder.id) {
      reminder.isEditStatus = false;
    }
  }
  toTextStatus(status) {
    return this.listReminderService.toTextStatus(status);
  }
}
