import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Observation } from 'src/app/models/observationType.class';
import { NotificationService } from 'src/app/services/notification.service';
import { ObservationTypeService } from 'src/app/services/obserType-service';
import { ObservationService } from 'src/app/services/observation.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AppState } from '../../../app-state/app-state';
@Component({
  selector: 'app-xetnghiem-add',
  templateUrl: './xetnghiem-add.component.html',
  styleUrls: ['./xetnghiem-add.component.scss'],
})
export class XetnghiemAddComponent implements OnInit, OnDestroy {
  @Input() visitId: string;
  @Input() isVisible: any;

  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() addObserEmit = new EventEmitter<any>();
  readonly DataType: typeof Constant.DATATYPENUMBER = Constant.DATATYPENUMBER;
  chisoXN: any[];
  lstObservationType: any;
  selectedRowOTType: any;
  existchisoXN: any;
  groupId: string;
  groupSub: Subscription;
  constructor(
    private OTService: ObservationTypeService,
    private ObsService: ObservationService,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    const firstItem = this.getItemEmptychisoXN();
    this.chisoXN = [firstItem];
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
      }
    });
  }
  getItemEmptychisoXN(): any {
    const item = {
      id: '',
      observationTypeId: 0,
      name: '',
      val: null,
      dataType: 0,
      unit: '',
      range: '-',
      minValue: 0,
      maxValue: 0,
      isNew: true,
      isDisable: false,
    };
    return item;
  }
  getListObservationType2(editItem): void {
    //let editItem=this.editItem;
    if (editItem !== null && editItem !== '') {
      //editItem.value=editItem['c2'+this.visitId];
      this.chisoXN = [
        {
          id: editItem.id,
          observationTypeId: editItem.observationTypeId,
          name: editItem.name,
          val: editItem['c2' + this.visitId],
          dataType: editItem.dataType,
          unit: editItem.unit,
          range: editItem.range,
          minValue: 0,
          maxValue: 0,
          isNew: false,
          isDisable: true,
        },
      ];
    }

    const payload = {
      search: '',
      category: 0,
      dataType: 0,
      groupId: this.groupId,
    };
    this.OTService.getObservationTypeByRole(payload).subscribe((response) => {
      this.lstObservationType = response;
      this.lstObservationType = this.lstObservationType.filter(
        (en) =>
          en.dataType === Constant.DATATYPENUMBER.NUMBER ||
          en.dataType === Constant.DATATYPENUMBER.STRING ||
          en.dataType === Constant.DATATYPENUMBER.POSITIVENEGATIVE
      );

      if (editItem === null || editItem === '') {
        const unchosen = [];
        this.lstObservationType.forEach((obj) => {
          if (
            this.existchisoXN &&
            this.existchisoXN.filter((en) => en.observationTypeId === obj.id)
              .length === 0
          ) {
            unchosen.push(obj);
          }
        });
        this.lstObservationType = unchosen;
      } else if (editItem !== null && editItem !== '') {
        this.changeType(editItem.id, editItem.observationTypeId);
      }
    });
  }
  getListObservationType(editItem) {
    //  await this.getListObservation();
    const sendObser = { visitId: this.visitId, observationCategory: 0 };
    this.ObsService.getObservation(sendObser).subscribe((data) => {
      this.existchisoXN = data;
      if (editItem !== null && editItem !== '') {
        this.chisoXN = [
          {
            id: editItem.id,
            observationTypeId: editItem.observationTypeId,
            name: editItem.name,
            val: editItem['c2' + this.visitId],
            dataType: editItem.dataType,
            unit: editItem.unit,
            range: editItem.range,
            minValue: 0,
            maxValue: 0,
            isNew: false,
            isDisable: true,
          },
        ];
        const existCSXN = this.existchisoXN.filter(
          (en) => en.id === editItem.id
        )[0];
        this.lstObservationType = [
          {
            id: editItem.observationTypeId,

            dataType: editItem.dataType,

            name: existCSXN.name,
          },
        ];
        this.changeType(editItem.id, editItem.observationTypeId);
      } else {
        const firstItem = this.getItemEmptychisoXN();
        this.chisoXN = [firstItem];
      }
    });
  }
  onInputOT(keyword: string): void {
    keyword = keyword.trim();
    if (keyword.length > 1) {
      const payload = {
        search: keyword,
        category: 0,
        dataType: 0,
        groupId: this.groupId,
      };
      this.OTService.getObservationTypeByRole(payload).subscribe((response) => {
        this.lstObservationType = response;
        this.lstObservationType = this.lstObservationType.filter(
          (en) =>
            en.dataType === Constant.DATATYPENUMBER.NUMBER ||
            en.dataType === Constant.DATATYPENUMBER.STRING ||
            en.dataType === Constant.DATATYPENUMBER.POSITIVENEGATIVE
        );
        const unchosen = [];
        this.lstObservationType.forEach((obj) => {
          if (
            this.existchisoXN &&
            this.existchisoXN.filter((en) => en.observationTypeId === obj.id)
              .length === 0
          ) {
            unchosen.push(obj);
          }
        });
        this.lstObservationType = unchosen;
      });
    }
  }

  handleCancel() {
    this.lstObservationType = [];
    this.isVisible = false;
    this.closeModal.emit(false);
  }
  handleOk() {
    this.lstObservationType = [];
    //save Data
    const lstPr = [];
    const lstPrDel = [];

    this.chisoXN.forEach((it) => {
      if (it.val !== null && it.val !== '') {
        const item = new Observation();
        switch (it.dataType) {
          case Constant.DATATYPENUMBER.NUMBER:
            item.valueInteger = it.val;
            break;
          case Constant.DATATYPENUMBER.STRING:
            item.valueString = it.val;
            break;
          case Constant.DATATYPENUMBER.BOOLEAN:
            item.valueBoolean = false;
            break;
          case Constant.DATATYPENUMBER.DATE:
            item.valueDateTime = it.val;
            break;
          case Constant.DATATYPENUMBER.DATETIME:
            item.valueDateTime = it.val;
            break;
          case Constant.DATATYPENUMBER.TIME:
            item.valueTime = '';
            break;
          case Constant.DATATYPENUMBER.SINGLECHOICE:
            //item.valueSelections=it.value;
            item.valueSelections = [it.val];
            break;
          case Constant.DATATYPENUMBER.MUILTICHOICE:
            item.valueSelections = [''];
            break;
          case Constant.DATATYPENUMBER.POSITIVENEGATIVE:
            item.valueBoolean = it.val;
            break;
          default:
            break;
        }
        item.id = it.id;
        item.observationTypeId = it.observationTypeId;
        lstPr.push(item);
      }
    });
    if (lstPr.length === 0) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.WARRING,
        'Nhập thông tin chỉ số XN không hợp lệ'
      );
      return;
    }
    const sendObser: any = {
      visitId: this.visitId,
      patientId: '000000000000000000000000',
      listObservation: lstPr,
      listObservationDel: lstPrDel,
    };
    this.ObsService.SaveListObservation(sendObser).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.SUCCESS,
              'Lưu chỉ số xét nghiệm thành công'
            );
            const firstItem = this.getItemEmptychisoXN();

            this.chisoXN.forEach((obj) => {
              this.lstObservationType = this.lstObservationType.filter(
                (en) => en.id !== obj.observationTypeId
              );
            });

            //reset
            this.chisoXN = [firstItem];
            this.addObserEmit.emit();
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.WARRING,
                res.errors[0].message
              );
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  addNewRow(id, otId, value) {
    this.lstObservationType = [];
    //chèn vào vị trí 0

    //chisoXN.push(otId, value);
    if (otId !== null) {
      const oldItem = this.chisoXN.filter((en) => en.id === id)[0];
      oldItem.dataType = this.selectedRowOTType.dataType;
      oldItem.range = this.selectedRowOTType.unit;
      //save curRow vào chisoXN
      if (id === '' || id === null) {
        const sUnix = this.datePipe.transform(new Date(), 'MMddHHmmss');
        oldItem.id = otId + sUnix;
      }

      oldItem.value = value;
      oldItem.isNew = false;
      this.chisoXN.filter((en) => en.id === id)[0] = oldItem;
      const firstItem = this.getItemEmptychisoXN();
      this.lstObservationType = this.lstObservationType.filter(
        (en) => en.id !== otId
      );
      this.chisoXN.push(firstItem);

      if (
        this.existchisoXN.filter((en) => en.observationTypeId === otId)
          .length === 0
      ) {
        const newExist: any = {
          observationTypeId: otId,
        };
        this.existchisoXN.push(newExist);
      }
    }
  }
  removeThisRow(id) {
    this.lstObservationType = [];
    const item = this.chisoXN.filter((en) => en.id === id)[0];
    this.chisoXN = this.chisoXN.filter((en) => en.id !== id);
    this.existchisoXN = this.existchisoXN.filter(
      (en) => en.observationTypeId !== item.observationTypeId
    );
  }
  changeType(id, otId) {
    //this.selectedRowOTType=val;
    this.selectedRowOTType = this.lstObservationType.filter(
      (en) => en.id === otId
    )[0];
    //this.selectedRowOTType=thisOT.dataType;
    const oldItem = this.chisoXN.filter((en) => en.id === id)[0];
    oldItem.dataType = this.selectedRowOTType.dataType;
    if (
      oldItem.dataType === Constant.DATATYPENUMBER.NUMBER ||
      oldItem.dataType === Constant.DATATYPENUMBER.POSITIVENEGATIVE
    ) {
      const ranges = this.selectedRowOTType.lstRange;
      if (ranges != null && ranges.length > 0) {
        let sRange = '';
        let unit = '';
        ranges.forEach((element) => {
          if (
            this.selectedRowOTType.unit !== '' &&
            this.selectedRowOTType.unit !== null
          ) {
            unit = ' ' + this.selectedRowOTType.unit;
          }
          if (sRange === '') {
            sRange += element.text + unit;
          } else {
            sRange += ';<br/>' + element.text + unit;
          }
        });
        oldItem.range = sRange;
      }
    } else {
      oldItem.range = this.selectedRowOTType.unit;
    }
    oldItem.dataSource = this.selectedRowOTType.dataSource;
    oldItem.value = null;
    //load type  & unit/range of OT
    //update vào list
  }
  ngOnDestroy(): void {
    this.groupSub.unsubscribe();
  }
}
