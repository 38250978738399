import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableSelectionAbstract } from 'src/app/share/component/table/table-selection.abstract';

@Component({
  selector: 'app-xn-result',
  templateUrl: './xn-result.component.html',
  styleUrls: ['./xn-result.component.scss'],
})
export class XnResultComponent extends TableSelectionAbstract implements OnInit {
  @Input() visit;
  @Input() chisoXNFilter = [];
  @Input() cakhams = [];
  @Output() editXN = new EventEmitter();

  constructor() {
    super('id');
  }

  ngOnInit(): void {}
  editXetNghiem(item) {
    this.editXN.emit(item);
  }
}
