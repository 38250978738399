import { NzModalService } from 'ng-zorro-antd/modal';
import { ShareService } from 'src/app/services/share.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { error } from 'selenium-webdriver';
import { NotificationService } from 'src/app/services/notification.service';
import { PatientRoomService } from 'src/app/services/patient-room.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { fas } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-doctor-in-room',
  templateUrl: './doctor-in-room.component.html',
  styleUrls: ['./doctor-in-room.component.scss'],
})
export class DoctorInRoomComponent implements OnInit {
  _isVisible = false;
  isVisibleAdd = false;
  loading = false;

  @Input() set isVisible(val) {
    if (val) {
      this.getDoctorInGroup();
      this.getRoom();
    }
    this._isVisible = val;
  }

  @Input() roomId: string;
  @Input() groupId: string;
  get isVisible(): boolean {
    return this._isVisible;
  }
  room: any = {};
  doctors: any[] = [];
  doctorsInGroup: any[] = [];
  pageSize = 10;
  selectedDoctor = '';
  listOfOptions: any[] = [];

  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() changeDoctor: EventEmitter<any> = new EventEmitter();

  constructor(
    private roomService: PatientRoomService,
    private doctorService: DoctorService,
    private shareService: ShareService,
    private modalService: NzModalService,
    private notificatiionService: NotificationService
  ) {}

  ngOnInit() {}

  getRoom() {
    this.loading = true;
    this.roomService.getRoom(this.roomId).subscribe(
      (res) => {
        if (res) {
          this.room = res;
          this.doctors = res.listDoctor;
          this.loading = false;
        }
      },
      (error) => {
        this.notificatiionService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Không tồn tại room'
        );
        this.loading = false;
      }
    );
  }

  getDoctorInGroup() {
    this.doctorService.getDoctorInGroup(this.groupId).subscribe((res) => {
      if (res) {
        this.doctorsInGroup = [];
        res.forEach((item) => {
          item.unsignFullName = this.shareService.toUnsign(item.fullName);
          this.doctorsInGroup.push(item);
        });
      }
    });
  }

  addDoctor(doctorId) {
    if (
      this.doctors.filter((t) => t.userId == this.selectedDoctor).length > 0
    ) {
      this.notificatiionService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bác sĩ đã thuộc room'
      );
    } else {
      const payload = {
        userId: this.selectedDoctor,
        roomId: this.roomId,
      };

      this.roomService.addDoctor(payload).subscribe((res) => {
        if (res && res.isValid) {
          this.getRoom();
          this.notificatiionService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Thêm thành công'
          );
          this.selectedDoctor = '';
          this.changeDoctor.emit();
        } else {
          this.notificatiionService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            res.errors[0].errorMessage
          );
        }
      });
    }
  }

  removeDoctor(doctor) {
    this.modalService.confirm({
      nzTitle: '<b>Xác nhận</b>',
      nzContent: 'Bạn muốn xóa ' + doctor.fullName + ' ra khỏi room?',
      nzOnOk: () => this.confiremRemoveDoctor(doctor.userId),
    });
  }

  confiremRemoveDoctor(doctorId) {
    const payload = {
      userId: doctorId,
      roomId: this.roomId,
    };

    this.roomService.removeDoctor(payload).subscribe((res) => {
      if (res) {
        this.getRoom();
        this.notificatiionService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Xóa thành công'
        );
        this.changeDoctor.emit();
      } else {
        this.notificatiionService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }

  onOpenModalAdd() {
    this.isVisibleAdd = true;
    this.getDoctorInGroup();
  }

  onCloseModalAdd() {
    this.isVisibleAdd = false;
  }

  onCloseModalView() {
    this.closeModal.emit();
  }

  searchDoctor(val) {
    if (!val) {
      this.listOfOptions = this.doctorsInGroup;
    } else {
      val = this.shareService.toUnsign(val);
      this.listOfOptions = this.doctorsInGroup.filter((t) =>
        t.unsignFullName.includes(val)
      );
    }
  }
}
