<nz-modal nzClassName="visit-discount" nzMaskClosable="false" [(nzVisible)]="isVisible" nzTitle="Giảm giá dịch vụ"
  nzWidth="1000px" (nzOnCancel)="cancelDiscount()">
  <nz-table #basicTable [nzData]="currentOrders" nzSize="small" nzShowSizeChanger nzShowPagination="false">
    <thead>
      <tr>
        <th class="text-nowrap align-center" width="550px">STT</th>
        <th [ngStyle]="{'width': type === false ? '25%' : '1300px'}" class="align-left">Tên dịch vụ</th>
        <!-- <th width="45%" class="align-left">Tên dịch vụ</th> -->
        <th *ngIf="type === false" width="10%" class="align-left">Trạng thái</th>
        <th [ngStyle]="{'width': type === false ? '9%' : '25%'}" class="align-right">Đơn giá</th>
        <th *ngIf="type === false" width="7%" class="align-right">SL</th>
        <th *ngIf="type === false" width="12%" class="align-right">Thành tiền</th>
        <th [ngStyle]="{'width': type === false ? '18%' : '31%'}" class="align-center">Giảm giá</th>
        <th width="12%" class="align-right">Còn lại</th>
      </tr>

    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data; let i = index">
        <td class="text-nowrap align-center">{{ i + 1 }}</td>
        <td class="align-left">
          <span>{{ data.name }}</span>
        </td>
        <td *ngIf="type === false" class="align-left">{{ data.statusString }}</td>
        <td *ngIf="data.servicePrice !== undefined" class="text-left">{{ data.servicePrice | currencyFormat }}</td>
        <td *ngIf="data.price !== undefined && data.servicePrice === undefined" class="text-right">{{ data.price | currencyFormat }}</td>
        <!-- <td class="text-right">{{ data.price | currencyFormat }}</td> -->
        <td *ngIf="type === false" class="align-right">{{ data.amount }}</td>
        <td *ngIf="type === false" class="text-right">
          {{ data.originalFee | currencyFormat }}
        </td>
        <td class="text-left" style="min-width: 150px">
          <input nz-input *ngIf="data.withPercent" #inputPercent style="width: 85px; text-align: right"
            [(ngModel)]="data.discountPercent" class="txt_sale" type="number" min="0" max="100" maxlength="4"
            autocomplete="off" placeholder="%" (ngModelChange)="typingDiscountPercent(data, inputPercent)" />
          <input nz-input *ngIf="data.withPercent == false" #inputDiscount style="width: 85px; text-align: right"
            [(ngModel)]="data.discountAmount" class="txt_sale" type="text" autocomplete="off" currencyMask [options]="{
              prefix: '',
              allowNegative: false,
              thousands: '.',
              suffix: '',
              precision: 0,
              align: 'right',
              nullable: true
            }" placeholder="đ" (ngModelChange)="typingDiscountAmount(data, inputDiscount)" />
          <span class="discount-btn" style="margin-left: 2px" [ngClass]="{ 'discount-btn-active': data.withPercent }"
            (click)="chooseDiscountPercentOrAmount(data, true)">
            %
          </span>
          <span class="discount-btn" [ngClass]="{ 'discount-btn-active': !data.withPercent }"
            (click)="chooseDiscountPercentOrAmount(data, false)">
            đ
          </span>
        </td>
        <td class="text-left" >
          {{ data.finalFee | currencyFormat }}
        </td>

      </tr>
      <tr *ngIf="type === false">
        <td colspan="5"><strong>Tổng tiền (VNĐ)</strong></td>
        <td class="text-right">
          <strong>
            {{ totalBillFee | currencyFormat }}
          </strong>
        </td>
        <td style="text-align: right; padding-right: 25px">
          <strong>
            {{ totalBillSale | currencyFormat }}
          </strong>
        </td>
        <td class="text-right">
          <strong style="color: red">
            {{ (totalBillFee - totalBillSale) | currencyFormat }}
          </strong>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <div *nzModalFooter>
    <button class="btn-primary-medium" nz-button [nzLoading]="isLoading" [disabled]="discountError" (click)="save()">
      Lưu
    </button>
    <button nz-button class="btn-cancel-medium" (click)="cancelDiscount()">Huỷ</button>
  </div>
</nz-modal>
