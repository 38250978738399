import { SSOReturnURL } from './../models/sso-returnURL';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthModel } from '../models/auth.model';
import { HttpClient } from '@angular/common/http';
import { Constant } from '../share/constants/constant.class';
import { AppConfigService } from '../../app-config.service';
import { UrlConstant } from '../share/constants/url.class';
import { BaseService } from '../share/base-service/base-service.service';
import { SSOToken } from '../models/sso-token';
import { ReturnURL } from '../models/returnURL';
@Injectable()
export class AuthService extends BaseService {
  login(payload): Observable<AuthModel> {
    return this.post('/login', payload);
  }

  ssoLogin(payload: SSOReturnURL): Observable<SSOReturnURL> {
    console.log('call ssologin');
    return this.post('/ssologin', payload);
  }

  ssoToken(payload: SSOToken): Observable<AuthModel> {
    return this.post('/login/token', payload);
  }

  ssologout(payload: SSOReturnURL): Observable<SSOReturnURL> {
    return this.post('/ssologout', payload);
  }

  // payload = {groupId : "groupId"}
  authenGroup(payload): Observable<any> {
    return this.post(UrlConstant.AUTHEN_GROUP, payload);
  }

  identify(payload, email): Observable<any> {
    return this.post('/identify?email=' + email, payload);
  }

  lossPass(payload): Observable<any> {
    return this.post('/forgot_password', payload);
  }

  logout(payload: ReturnURL): Observable<ReturnURL> {
    return this.post(UrlConstant.LOGOUT, payload);
  }

  checkToken(): Observable<any> {
    return this.post(UrlConstant.VALIDATE, {});
  }

  regist(payload): Observable<any> {
    return this.post('/regist', payload);
  }
  changepassword(payload): Observable<any> {
    return this.post(UrlConstant.CHANGEPASS, payload);
  }
}
