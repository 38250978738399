<div style="padding: 20px">
  <!-- <div class="page-title mg-bt-20">
        Danh mục y lệnh
    </div> -->
  <div class="d-flex justify-content-between mg-bt-15">
    <div class="d-flex">
      <div style="width: 200px" class="mg-r-10">
        <input
          nz-input
          [(ngModel)]="textSearch"
          type="text"
          placeholder="Tìm kiếm"
          (keydown.enter)="getByFilter()" />
      </div>
      <button nz-button (click)="getByFilter()">
        <i nz-icon nzType="search" nzTheme="outline"></i>
      </button>
    </div>
    <div>
      <button
        nz-button
        class="btn-primary-small"
        nzType="success"
        (click)="showModalForm('add', '')">
        <i nz-icon nzType="plus" nzTheme="outline"></i>
        Thêm mới
      </button>
    </div>
  </div>
  <nz-table
    #fixedTable
    [(nzData)]="listData"
    [nzLoading]="isLoading"
    [nzFrontPagination]="false"
    [nzTotal]="total"
    (nzQueryParams)="onQueryParamsChange($event)"
    [nzShowTotal]="rangeTemplate"
    nzShowSizeChanger
    [nzPageSize]="pageSize"
    [(nzPageIndex)]="pageIndex"
    [nzPageSizeOptions]="[10, 20, 50, 100]">
    <thead>
      <tr>
        <th nzWidth="50px">STT</th>
        <th>Tên y lệnh</th>
        <th>Mô tả</th>
        <th>Loại y lệnh</th>
        <th>Phạm vi sử dụng</th>
        <th>Người tạo</th>
        <th>Thời gian cập nhật</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of fixedTable.data; let i = index">
        <td>{{ pageSize * (pageIndex - 1) + i + 1 }}</td>
        <td>{{ data.name }}</td>
        <td>{{ data.description }}</td>
        <td>{{ toTextType(data.type) }}</td>
        <td>{{ toTextPrivacy(data.privacy) }}</td>
        <td>{{ data.creatorName }}</td>
        <td>{{ data.modifiedDate | date: 'dd/MM/yyy HH:mm' }}</td>
        <td *ngIf="curUserId == data.creatorId">
          <i
            nz-icon
            nzType="edit"
            class="mg-r-10"
            title="Chỉnh sửa"
            (click)="showModalForm('update', data)"
            nzTheme="outline"></i>
          <i
            nz-icon
            nzType="delete"
            class="mg-r-10"
            title="Xóa"
            (click)="remove(data.id)"
            nzTheme="outline"></i>
        </td>
        <td *ngIf="curUserId !== data.creatorId">
          <i
            nz-icon
            nzType="eye"
            title="Xem"
            (click)="showModalForm('view', data)"
            nzTheme="outline"></i>
        </td>
      </tr>
    </tbody>
    <ng-template #rangeTemplate let-range="range" let-total>
      {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }}
    </ng-template>
  </nz-table>
</div>
<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Nhập danh mục y lệnh"
  nzWidth="700px"
  nzMaskClosable="false"
  (nzOnCancel)="handleCancel()">
  <div>
    <div class="row mg-bt-10">
      <div class="col-3">Tên y lệnh <span class="red-dot">*</span></div>
      <div class="col-9">
        <input nz-input type="text" [(ngModel)]="itemEdit.name" />
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Mô tả <span class="red-dot">*</span></div>
      <div class="col-9">
        <input nz-input type="text" [(ngModel)]="itemEdit.description" />
      </div>
    </div>
    <div class="row mg-bt-10 type_medical">
      <div class="col-3">Loại y lệnh <span class="red-dot">*</span></div>
      <div class="col-9">
        <nz-radio-group [(ngModel)]="itemEdit.type">
          <label nz-radio [nzValue]="1">Đơn thuốc</label>
          <label nz-radio [nzValue]="2">Chỉ định CĐHA</label>
          <label nz-radio [nzValue]="3">Chỉ định xét nghiệm</label>
          <label nz-radio [nzValue]="4">Khác</label>
        </nz-radio-group>
        <!-- <input type="text" nz-input *ngIf="itemEdit.type === 4" /> -->
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Phạm vi sử dụng <span class="red-dot">*</span></div>
      <div class="col-9">
        <nz-radio-group [(ngModel)]="itemEdit.privacy">
          <label nz-radio [nzValue]="1">Toàn hệ thống</label>
          <label nz-radio [nzValue]="2">Trong nhóm BS</label>
          <label nz-radio [nzValue]="3">Chỉ mình tôi</label>
        </nz-radio-group>
      </div>
    </div>
    <div *ngIf="itemEdit.privacy == 2" class="row mg-bt-10">
      <div class="col-3 pd-l-30">Chọn nhóm <span class="red-dot">*</span></div>
      <div class="col-6">
        <nz-select
          class="w-100"
          nzShowSearch
          [(ngModel)]="groupShare"
          nzAllowClear
          nzPlaceHolder="Nhóm..."
          (nzOnSearch)="onSearchGroup($event)"
          (ngModelChange)="selectGroup($event)">
          <nz-option
            nzCustomContent
            *ngFor="let group of searchDataGroupFilter"
            [nzValue]="group"
            [nzLabel]="group.name">
            <div>{{ group.name }}</div>
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngIf="lstGroupName.length > 0" class="row mg-bt-10">
      <div class="col-3 pd-l-30">Danh sách nhóm</div>
      <div class="col-9">
        <div class="list-group-share">
          <li *ngFor="let group of lstGroupName" class="mg-r-10 d-inline">
            {{ group }}
            <i
              nz-icon
              nzType="close"
              style="font-size: 10px"
              nzTheme="outline"
              (click)="unSelectGroup(group.id)"></i>
          </li>
        </div>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-small" (click)="handleCancel()">
      Đóng
    </button>
    <button nz-button class="btn-primary-small" (click)="submit()">Lưu</button>
  </div>
</nz-modal>

<nz-modal
  [(nzVisible)]="isVisibleView"
  nzTitle="Danh mục y lệnh"
  nzWidth="700px"
  nzMaskClosable="false"
  (nzOnCancel)="cancelView()">
  <div>
    <div class="row mg-bt-10">
      <div class="col-3">Tên y lệnh <span class="red-dot">*</span></div>
      <div class="col-9">{{ itemEdit.name }}</div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Mô tả <span class="red-dot">*</span></div>
      <div class="col-9">{{ itemEdit.description }}</div>
    </div>
    <div class="row mg-bt-10 type_medical">
      <div class="col-3">Loại y lệnh <span class="red-dot">*</span></div>
      <div class="col-9">{{ toTextType(itemEdit.type) }}</div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Phạm vi sử dụng <span class="red-dot">*</span></div>
      <div class="col-9">{{ toTextPrivacy(itemEdit.privacy) }}</div>
    </div>
    <div *ngIf="lstGroupName.length > 0" class="row mg-bt-10">
      <div class="col-3 pd-l-30">Danh sách nhóm</div>
      <div class="col-9">
        <div class="list-group-share">
          <li *ngFor="let group of lstGroupName" class="mg-r-10 d-inline">
            {{ group }}
          </li>
        </div>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-small" (click)="cancelView()">
      Đóng
    </button>
  </div>
</nz-modal>
