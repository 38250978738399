<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Webhook"
  nzMaskClosable="false"
  (nzClosable)="(true)"
  (nzOnCancel)="handleCancel()">
  <div *nzModalContent>
    <form nz-form [formGroup]="formData">
      <nz-form-item>
        <nz-form-label [nzSpan]="5" nzRequired nzFor="name">Tên</nz-form-label>
        <nz-form-control nzHasFeedback [nzErrorTip]="nameErrorTpl">
          <input formControlName="name" nz-input placeholder="Tên" />
          <ng-template #nameErrorTpl let-control>
            <ng-container *ngIf="control.hasError('emptyName')">
              Tên không được để trống!
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">
              Hãy nhập tên!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5" nzRequired nzFor="code"
          >Secret Key</nz-form-label
        >
        <nz-form-control nzHasFeedback [nzErrorTip]="keyErrorTpl">
          <input formControlName="secretKey" nz-input [disabled]="true" />
          <ng-template #keyErrorTpl let-control>
            <ng-container *ngIf="control.hasError('emptyName')">
              Secret Key không được để trống!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5" nzRequired nzFor="name">URL</nz-form-label>
        <nz-form-control nzHasFeedback [nzErrorTip]="urlErrorTpl">
          <input formControlName="url" nz-input placeholder="Url" />
          <ng-template #urlErrorTpl let-control>
            <ng-container *ngIf="control.hasError('emptyName')">
              URL không được để trống!
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">
              Hãy nhập URL!
            </ng-container>
            <ng-container *ngIf="control.hasError('invalidUrl')">
              URL không hợp lệ!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </form>
    <nz-divider nzType="horizontal"></nz-divider>
    <div nz-row>
      <div nz-col nzSpan="2"></div>
      <div nz-col nzSpan="4">Events:</div>
      <div nz-col nzSpan="18" nzOffset="6">
        <ul>
          <li *ngFor="let item of events">
            <label nz-checkbox [(ngModel)]="item.eventActive"></label
            ><span> {{ item.eventNameVN }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()" nzDanger>
      Huỷ
    </button>
    <button
      *ngIf="!currentWebhook.id"
      nz-button
      nzType="primary"
      ngClass="btn-add"
      (click)="saveWebhook()">
      Thêm
    </button>
    <button
      *ngIf="currentWebhook.id"
      nz-button
      nzType="primary"
      (click)="saveWebhook()">
      Sửa
    </button>
  </div>
</nz-modal>
