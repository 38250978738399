import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormElement } from 'src/app/models/form-model/form.class';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
})
export class FormControlComponent implements OnInit {
  _element: FormElement = new FormElement('', '', '', '');
  _observationId: string;
  _viewType: string;
  _showType: string;
  _editType: string;
  _dataType: number;
  _minValue: number;
  _maxValue: number;
  _isRequired: boolean;
  isSelection: boolean;
  isBoolean: boolean;

  @Input() isVisible: boolean;
  // @Input() isChangeAttribute: boolean;
  @Input() isRecentElement: boolean;
  @Input() observationItems: any = [];

  @Input() set element(value: FormElement) {
    if (value) {
      this._element = value;
      this._observationId = value.observationType.id;
      this._editType =
        value.editType == 1 || value.editType == 4 ? 'editable' : 'readonly'; // 1 -> currentElement || 4 -> recent element
      this._minValue = value.minNumberValue;
      this._maxValue = value.maxNumberValue;
      this._isRequired = value.isRequired ? value.isRequired : false;
      this._dataType = value.observationType.dataType;
      this._showType =
        value.formatStyle.display == 'block' ? 'vertical' : 'horizontal';
      this.isSelection =
        value.observationType.dataType ==
          Constant.DATATYPENUMBER.SINGLECHOICE ||
        value.observationType.dataType == Constant.DATATYPENUMBER.MUILTICHOICE;
      this.isBoolean =
        value.observationType.dataType == Constant.DATATYPENUMBER.BOOLEAN ||
        value.observationType.dataType ==
          Constant.DATATYPENUMBER.POSITIVENEGATIVE;
      this._viewType = value.viewType;
      if (this.isBoolean) {
        this._viewType = value.viewType == 'radio' ? 'radio' : 'checkbox';
      }
    }
  }

  @Output() updateElement: EventEmitter<any> = new EventEmitter();

  get element(): FormElement {
    return this._element;
  }
  constructor() {}

  ngOnInit() {}

  editTypeChange() {
    if (!this.isRecentElement) {
      this.element.editType = this._editType == 'editable' ? 1 : 0;
    } else {
      this.element.editType = this._editType == 'editable' ? 4 : 3;
    }
  }

  viewTypeChange() {
    this.element.viewType = this._viewType;
    this.updateElement.emit(this.element);
  }

  onMinValueChange() {
    this.element.minNumberValue = this._minValue;
    this.updateElement.emit(this.element);
  }

  onMaxValueChange() {
    this.element.maxNumberValue = this._maxValue;
    this.updateElement.emit(this.element);
  }

  onRequiredChange() {
    if (this._isRequired) {
      this._editType == 'editable';
      this.element.editType = 1;
    }

    this.element.isRequired = this._isRequired;
    this.updateElement.emit(this.element);
  }

  showTypeChange() {
    this.element.formatStyle.display =
      this._showType == 'horizontal' ? '' : 'block';
  }

  editObservationTypeChange() {
    const cur = this.observationItems.filter((item) => item.id == this._observationId)[0];

    if (cur && this.element && this.isVisible) {
      this.element.observationType = cur;
      if (cur.dataType == 9 || cur.dataType == 10) {
        this.element.viewType = 'selection';
        this._viewType = 'selection';
      } else {
        this._viewType = 'input';
        this.element.viewType = 'input';
      }
      this._dataType = this.element.observationType.dataType;
      this.updateElement.emit(this.element);
    }
  }
}
