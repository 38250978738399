import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appCustomModalMovable]',
})
export class CustomModalMovableDirective implements AfterViewInit {
  @Input() modalId = '';
  @Input() needFocus = false;
  constructor(private elementRef: ElementRef, private render: Renderer2) {}

  //Only when the mouse is clicked, the mouse can be moved before it is lifted.
  private canMove = false;
  //modal, x,y coordinates when starting to move
  private modalX = 0;
  private modalY = 0;
  //The coordinates of the mouse when the mouse is clicked
  private mouseDownX = 0;
  private mouseDownY = 0;
  private modalElement: any;
  private modalTitleElement: any;

  getModalElement() {
    // return document.querySelector(`#${this.id}`);
    return this.elementRef.nativeElement;
  }
  getModalTitleElment() {
    return this.elementRef.nativeElement.querySelector(`.div-header`);
  }

  ngAfterViewInit() {
    const modalElement = this.getModalElement();
    const modalTitleElement = this.getModalTitleElment();
    const $this = this;

    if (modalTitleElement) {
      $this.focus($this);
      this.render.listen(
        modalTitleElement,
        'mousedown',
        function (event) {
          if (modalElement instanceof HTMLElement) {
            $this.mouseDownX = event.clientX;
            $this.mouseDownY = event.clientY;
            $this.modalX = modalElement.offsetLeft;
            $this.modalY = modalElement.offsetTop;
            // $this.render.setStyle(modalElement,"position","absolute");
            $this.render.setStyle(modalElement, 'top', `${this.modalY}px`);
            $this.render.setStyle(modalElement, 'left', `${this.modalX}px`);
            $this.focus($this);
            $this.canMove = true;
          }
        }.bind(this)
      );

      this.render.listen(
        modalTitleElement,
        'mouseup',
        function (event) {
          $this.canMove = false;
        }.bind(this)
      );

      this.render.listen(
        modalElement,
        'mousemove',
        function (event) {
          // console.log('render');
          // console.log($this.render);
          if ($this.canMove) {
            const moveX = event.clientX - this.mouseDownX;
            const moveY = event.clientY - this.mouseDownY;
            const newModalX = this.modalX + moveX;
            const newModalY = this.modalY + moveY;
            // $this.render.setStyle(modalElement,"margin-left",`0px`);
            $this.render.setStyle(modalElement, 'top', `${newModalY}px`);
            $this.render.setStyle(modalElement, 'left', `${newModalX}px`);
          }
        }.bind(this)
      );

      this.render.listen(
        modalElement,
        'mousedown',
        function (event) {
          $this.focus($this);
        }.bind(this)
      );
    }
  }

  focus(elm) {
    if (this.needFocus) {
      const elems = document.querySelector(`.msc-custom-modal.focus`);
      if (elems !== null) {
        elems.classList.remove('focus');
      }
      elm.elementRef.nativeElement.classList.add('focus');
    }
  }
}
