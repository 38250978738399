import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { Visits } from 'src/app/models/listVisit.class';
import { PatientRoom } from 'src/app/models/patient-room';
import { NotificationService } from 'src/app/services/notification.service';
import { PatientRoomService } from 'src/app/services/patient-room.service';
import { ShareService } from 'src/app/services/share.service';
import { SubClinicalVisitService } from 'src/app/services/subcliniccal-service';
import { VisitService } from 'src/app/services/visit.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AppState } from '../../app-state/app-state';
import { AppConfigService } from 'src/app-config.service';
import { PrintConclusionComponent } from './print-conclusion/print-conclusion.component';
@Component({
  selector: 'app-visit-detail-small',
  templateUrl: './visit-detail-small.component.html',
  styleUrls: ['./visit-detail-small.component.scss'],
})
export class VisitDetailSmallComponent implements OnInit {
  _visit: Visits;
  @Input() set visit(data: any) {
    if (data) {
      this._visit = data;
      this.getTotalElementOfTab(Constant.TabTitleInsideVisitDetail.All);
      this.setBtnStatus();
    }
  }
  get visit() {
    return this._visit;
  }
  @Output() visitChange = new EventEmitter();

  @Input() isOpenChangeVisitRoom!: boolean;
  @Output() isOpenChangeVisitRoomChange = new EventEmitter<boolean>();

  @Output() updateVisitStatus: EventEmitter<any> = new EventEmitter();
  @Output() visitIdChange = new EventEmitter();
  @Output() outSubClinicalVisit = new EventEmitter();
  @Output() lstRelatedVisitChange = new EventEmitter();

  VISIT_STATUS = Constant.VISIT_STATUS;
  showOrderPrinterPreview = false;

  subClinicalVisit = [];
  lstRelatedVisitDisplay= [];
  subClinicalXNs = [];

  groupSub: Subscription;
  userRoomNameSub: Subscription;
  userRoomIdSub: Subscription;
  selectTabIndex = 0;
  configGroup = {
    autoConvert2InProgressVisit: false,
    pushSubclinicalToHIS: false,
    orderPrintType: 1,
  };
  isDoctorSelectRoom = false;
  isUseDividePatientRoom = false;
  useRoomOrDoctorVisit = false;
  subClinicalSendType = 0;

  functionsKey = ['1', '2', '3', '4', '5', 'i', 'p', 'm', 'c', 'd', 'I', 'P', 'M', 'C', 'D'];
  tabFunctionsKey = ['1', '2', '3', '4', '5'];
  totalElementTab = {
    VisitInfo: '',
    DiagnosticImaging: '',
    Test: '',
    Prescription: '',
    ClinicalInformation: '',
  };
  isVisibleVisitHis = false;
  curGroupId = '';

  currentUserRoomName = '';
  currentUserRoomId = '';
  currentVisitRoomId = '';
  rooms: PatientRoom[] = [];
  selectedGroupId = '';
  isVisibleVisitLog = false;

  disabledEditInfo = false;
  disabledBtnActiton = false;
  disabledBtnUndoFinished = false;
  hienGiaDichVuChiDinh = false;
  printContents = [];
  curUserId = '';
  roomsAdditionVisit = [];
  @ViewChild('printConclusion') printConclusionComponent: PrintConclusionComponent;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // console.log(event)
    if (event.altKey && this.functionsKey.includes(event.key)) {
      if (this.tabFunctionsKey.includes(event.key)) {
        this.selectTabIndex = this.functionsKey.indexOf(event.key);
      } else {
        switch (event.key.toUpperCase()) {
          case 'I':
            if (this.visit.status != this.VISIT_STATUS.Finished.value) {
              this.printOrder();
            }
            break;
          case 'P':
            // if (this.visit.status != this.VISIT_STATUS.Finished.value) {
            //   this.onClickUpdateStatus(this.VISIT_STATUS.InProgress.value, false)
            // }
            break;
          case 'M':
            if (
              !this.configGroup.autoConvert2InProgressVisit &&
              this.visit.status != this.VISIT_STATUS.Finished.value
            ) {
              this.onClickUpdateStatus(this.VISIT_STATUS.InProgress.value, false);
            }
            break;
          case 'C':
            if (this.visit.status != this.VISIT_STATUS.Finished.value) {
              this.onClickUpdateStatus(this.VISIT_STATUS.Finished.value, false);
            }
            break;
          case 'D':
            if (this.visit.status == this.VISIT_STATUS.Finished.value) {
              this.onClickUpdateStatus(this.VISIT_STATUS.InProgress.value, true);
            }
            break;
          default:
            break;
        }
      }
    }
  }

  constructor(
    private visitService: VisitService,
    private modalService: NzModalService,
    private notificationService: NotificationService,
    private roomService: PatientRoomService,
    private store: Store<AppState>,
    private subClinicalVisitService: SubClinicalVisitService,
    private shareService: ShareService,
    private configService: AppConfigService
  ) {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.selectedGroupId = data.groupId;
        this.configGroup.autoConvert2InProgressVisit = data.autoConvert2InProgressVisit;
        this.configGroup.pushSubclinicalToHIS = data.pushSubclinicalToHIS;
        this.configGroup.orderPrintType = data.orderPrintType;

        this.isUseDividePatientRoom = data.isUseDividePatientRoom;
        this.isDoctorSelectRoom = data.isDoctorSelectRoom;
        this.useRoomOrDoctorVisit = data.getVisitByRoomOrDoctor;
        this.subClinicalSendType = data.subClinicalSendType;
        this.curGroupId = data.groupId;
      }
    });
    this.hienGiaDichVuChiDinh = this.configService.getConfig().hienGiaDichVuChiDinh;
    this.userRoomNameSub = this.store
      .select((store) => store.auth.roomName)
      .subscribe((data) => {
        this.currentUserRoomName = data;
      });
    this.userRoomIdSub = this.store
      .select((store) => store.auth.roomId)
      .subscribe((data) => {
        this.currentUserRoomId = data;
        this.currentVisitRoomId = data;
        this.roomsAdditionVisit = this.rooms.filter((en) => en.id != data);
      });
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
  }

  ngOnInit() {
    this.getRoomInGroup(this.selectedGroupId);

    this.visitService.dataVisit$.subscribe((newData) => {
      // alert(newData);
      if (newData) {
        console.log('dataVisit$', newData);
        this.lstRelatedVisitDisplay = newData;
      }
    });
  }

  onClickUpdateStatus(status: number, isUndoFinished: boolean) {
    let confirmContent = '';
    if (isUndoFinished) {
      confirmContent = 'Bỏ hoàn tất ca khám?';
    } else {
      confirmContent =
        status == this.VISIT_STATUS.InProgress.value ? 'Chuyển ca khám sang cận lâm sàng?' : 'Hoàn tất ca khám?';
    }
    this.modalService.confirm({
      nzTitle: '<b>Xác nhận</b>',
      nzContent: confirmContent,
      nzOnOk: () => this.updateStatus(status, isUndoFinished),
      nzOkText: 'Xác nhận',
      nzCancelText: 'Đóng',
    });
  }

  updateStatus(status: number, isUndoFinished: boolean) {
    const payload = { visitId: this.visit.id, visitStatus: status };
    this.visitService.saveVisitStatus(payload).subscribe(
      (res) => {
        if (res.isValid) {
          this.notificationService.showNotification(Constant.SUCCESS, 'Cập nhật trạng thái ca khám thành công');
          this.updateVisitStatus.emit(status);
          this.pushVisitStatus2HIS(payload);
        } else {
          this.notificationService.showNotification(Constant.ERROR, Constant.MESSAGE_UPDATE_ERROR);
        }
      },
      (error) => {
        this.notificationService.showNotification(Constant.ERROR, Constant.MESSAGE_UPDATE_ERROR);
      }
    );
    if (!isUndoFinished) {
      this.checkVisitRoom();
    }
  }
  pushSubClinicalVisit2HIS() {
    if (this.visit.status <= this.VISIT_STATUS.Finished.value && this.configGroup.pushSubclinicalToHIS) {
      this.subClinicalVisitService.pushSubClinicalVisit2HIS(this.visit.id).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Đẩy chỉ định sang HIS thành công');
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    }
  }

  checkVisitRoom() {
    if (this.useRoomOrDoctorVisit && this.isUseDividePatientRoom && this.isDoctorSelectRoom) {
      if (!this.visit.roomName) {
        this.addPaientToRoom();
      } else if (this.visit.roomId != this.currentUserRoomId) {
        this.movePaientToRoom();
      }
    }
  }

  addPaientToRoom() {
    if (this.currentUserRoomId) {
      const payload = {
        patientId: this.visit.patientId,
        roomId: this.currentUserRoomId,
      };
      this.roomService.addPatient(payload).subscribe((res) => {
        if (!res?.isValid) {
          console.log('Lỗi cập nhật room addPatient');
        }
      });
      this.updateVisitRoom(this.currentUserRoomId, this.currentUserRoomName);
    }
  }

  movePaientToRoom() {
    if (this.currentUserRoomId) {
      this.modalService.confirm({
        nzTitle: '<b>Thay đổi phòng khám</b>',
        nzContent: `Ca khám của BN ${this.visit.patientName} đang được gán phòng khám là <b>${this.visit.roomName}</b>.
          Bạn có muốn sửa thông tin phòng khám của ca khám sang phòng hiện tại là <b>${this.currentUserRoomName}</b> không?`,
        nzOkText: 'Có',
        nzCancelText: 'Không',
        nzOnOk: () => {
          const payload = {
            patientId: this.visit.patientId,
            currentRoomId: this.visit.roomId,
            destinationRoomId: this.currentUserRoomId,
          };
          this.roomService.movePatient(payload).subscribe((res) => {
            if (!res?.isValid) {
              console.log('Lỗi cập nhật room movePatient');
            }
          });
          this.updateVisitRoom(this.currentUserRoomId, this.currentUserRoomName);
        },
      });
    }
  }

  updateVisitRoom(roomId, roomName) {
    const payload = {
      visitId: this.visit.id,
      roomId,
      roomName,
    };
    this.visitService.updateVisitRoom(payload).subscribe((res) => {
      if (!res?.isValid) {
        console.log('Lỗi cập nhật room updateVisit');
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật phòng khám thành công');
      }
    });
    this.visit.roomId = roomId;
    this.visit.roomName = roomName;
    this.visitService.pushVisitRoom2HIS(payload).subscribe((res) => {
      if (!res?.isValid) {
        // this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, "Cập nhật phòng khám sang HIS không thành công");
        console.log('Cập nhật phòng khám sang HIS không thành công');
      }
    });
  }

  pushVisitStatus2HIS(payload) {
    this.visitService.pushVisitStatus2HIS(payload).subscribe((res) => {
      if (!res.isValid) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Đẩy trạng thái ca khám sang HIS không thành công'
        );
      }
    });
  }
  printOrder() {

    let printServiceIds = this.subClinicalVisit.filter(en => !en.notPrint).map(en => en.subClinicalOrderId);
    let printRelateServiceIds = this.lstRelatedVisitDisplay.filter(en => !en.notPrint).map(en => en.visitId);

    console.log(this.subClinicalVisit, this.lstRelatedVisitDisplay);
    console.log(printServiceIds, printRelateServiceIds);
    //return;
    //printServiceIds = [ ...printServiceIds, ...printRelateServiceIds];

    if (printServiceIds.length > 0 || printRelateServiceIds.length > 0) {
      this.visitService.getPrintTemplate2(this.visit.id, this.configGroup.orderPrintType, printServiceIds, this.hienGiaDichVuChiDinh, printRelateServiceIds).subscribe((res) => {
        if (res.isValid) {
          this.printContents = res.jsonData;
          this.showOrderPrinterPreview = true;
        } else {
          if (res.errors[0].key == 'EMPTY')
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
          else this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    } else {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, 'Không có chỉ định để in!');
    }
  }

  getPrintTemplate() {
    this.visitService.getPrintTemplate(this.visit.id, this.configGroup.orderPrintType).subscribe((res) => {
      if (res.isValid) {
        const printContents = res.jsonData;
        let content = '';
        printContents.forEach((en) => {
          content += '<div style="page-break-after:always">' + en + '</div>';
        });
        const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`${content}`);
        popupWin.document.close();
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  // printPreview() {
  //   if (this.subClinicalCDHAs.length != 0 || this.subClinicalXNs.length != 0) {
  //     this.showOrderPrinterPreview = true;
  //     this.showOrderPrinter = false;
  //   } else {
  //     this.notificationService.showNotification(
  //       Constant.NOTIFY_TYPE.WARRING,
  //       'Không có chỉ định để in!'
  //     );
  //   }
  // }

  // closePrintCLS() {
  //   this.showOrderPrinter = false;
  // }
  closePrintPreview() {
    this.showOrderPrinterPreview = false;
  }

  printNow() {
    this.showOrderPrinterPreview = false;
    // this.printOrder();
    if (this.printContents.length > 0) {
      let content = '';
      this.printContents.forEach((en) => {
        content += '<div style="page-break-after:always">' + en + '</div>';
      });
      const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
          <html>
          <head>
          <style>
              strong{color:#000;}
          </style>
          </head>
          <body onload='window.print();window.close()'>${content}</body>
          </html>`);
      popupWin.document.close();
    }
    if (this.configGroup.autoConvert2InProgressVisit && this.visit.status != this.VISIT_STATUS.Finished.value) {
      this.updateStatus(this.VISIT_STATUS.InProgress.value, false);
    }
    if (this.subClinicalSendType == Constant.SubClinicalSendType.ClickPrintOrChangeStatus) {
      this.pushSubClinicalVisit2HIS();
    }
  }
  doPrintConclusion() {
    this.printConclusionComponent.print(this.visit);
  }

  getSubClinicalVisit(data) {
    this.subClinicalVisit = data;
    this.subClinicalXNs = data.filter((t) => t.type == Constant.SubClinicalType.XN.value);
    this.outSubClinicalVisit.emit(data);
  }
  onTitleTabRefresh(tab) {
    console.log('onTitleTabRefresh', tab);

    this.getTotalElementOfTab(tab);
    this.checkVisitRoom();
  }
  getTotalElementOfTab(tab) {
    const payload = {
      visitId: this.visit.id,
      patientId: this.visit.patientId,
      tab,
    };
    this.visitService.getTotalElementOfTab(payload).subscribe((res) => {
      if (res.isValid) {
        const data = res.jsonData;
        if (tab == Constant.TabTitleInsideVisitDetail.All) {
          this.totalElementTab.DiagnosticImaging = data.diagnosticImaging;
          this.totalElementTab.Test = data.test;
          this.totalElementTab.Prescription = data.prescription;
        } else if (tab == Constant.TabTitleInsideVisitDetail.DiagnosticImaging) {
          this.totalElementTab.DiagnosticImaging = data.diagnosticImaging;
        } else if (tab == Constant.TabTitleInsideVisitDetail.Test) {
          this.totalElementTab.Test = data.test;
        } else if (tab == Constant.TabTitleInsideVisitDetail.Prescription) {
          this.totalElementTab.Prescription = data.prescription;
        } else if (tab == Constant.TabTitleInsideVisitDetail.ClinicalInformation) {
          this.totalElementTab.ClinicalInformation = data.clinicalInformation;
        }
      }
    });
  }
  showModalHistory(): void {
    this.isVisibleVisitHis = true;
  }
  showModalLog(): void {
    this.isVisibleVisitLog = true;
  }
  closeVisitLog(): void {
    this.isVisibleVisitLog = false;
  }
  loadVisitById(vsId) {
    this.visitIdChange.emit(vsId);
  }
  getRoomInGroup(groupId) {
    this.roomService.getAllRoomInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.rooms = res;
        this.roomsAdditionVisit = this.rooms.filter((en) => en.id != this.currentUserRoomId);
      }
    });
  }
  closeChangeVisitRoom() {
    this.isOpenChangeVisitRoom = false;
    this.isOpenChangeVisitRoomChange.emit(false);
  }
  submitVisitRoom() {
    const room = this.rooms.find((r) => r.id == this.currentVisitRoomId);
    const roomName = room?.name;
    if (this.visit.roomName) {
      const payload = {
        patientId: this.visit.patientId,
        currentRoomId: this.visit.roomId,
        destinationRoomId: this.currentVisitRoomId,
      };
      this.roomService.movePatient(payload).subscribe((res) => {
        if (!res?.isValid) {
          console.log('Lỗi cập nhật room movePatient');
        }
      });
    } else {
      const payload = {
        patientId: this.visit.patientId,
        roomId: this.currentVisitRoomId,
      };
      this.roomService.addPatient(payload).subscribe((res) => {
        if (!res?.isValid) {
          console.log('Lỗi cập nhật room addPatient');
        }
      });
    }
    this.updateVisitRoom(this.currentVisitRoomId, roomName);
    this.closeChangeVisitRoom();
    this.currentVisitRoomId = this.currentUserRoomId;
  }
  editingFlagEvent(flag) {
    this.disabledBtnActiton = flag;
  }
  setBtnStatus() {
    let isFinished = this.visit.status == this.VISIT_STATUS.Finished.value;
    /// using config useRoomOrDoctorVisit
    if (this.useRoomOrDoctorVisit) {
      /// mở ca khám khác phòng && doctorId # userOnline: disabled all action
      if (this.currentUserRoomId != this.visit.roomId) {
        this.disabledEditInfo = true;
        this.disabledBtnActiton = true;
        this.disabledBtnUndoFinished = true;
      }
      /// ca khám chưa nhận: disabled all action
      else if (!this.visit.isReceived) {
        this.disabledEditInfo = true;
        this.disabledBtnActiton = true;
        this.disabledBtnUndoFinished = true;
      }
      /// ca khám đã nhận: disabled depend on ca khám đã hoàn tất
      else if (this.visit.isReceived) {
        this.disabledEditInfo = this.disabledBtnActiton = isFinished;
        this.disabledBtnUndoFinished = !isFinished;
      }
    } else {
      this.disabledEditInfo = this.disabledBtnActiton = isFinished;
      this.disabledBtnUndoFinished = !isFinished;
    }
  }

  visitIdEvent(id) {
    this.visitIdChange.emit(id);
  }

  getRelatedVisitChange(data) {
    this.lstRelatedVisitDisplay = data;
    console.log('getRelatedVisitChange', this.lstRelatedVisitDisplay);
    this.lstRelatedVisitChange.emit(data);
  }
}
