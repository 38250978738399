<div *ngIf="visit" style="overflow: hidden;">
  <div class="header">
    <div class="heading-1">
      <div style="line-height: 47px;">
        <a
        class="visit-title "
        (click)="
          showTabPatientInfo(visit.patientId, visit.patientName, visit.id)
        ">
        <span style="font-size: 21px; color: white; font-weight: 700">{{
          visit.patientName
        }}</span>
        <span class="fs-18" style="color: white;">
          - {{ visit.dob | ageStringFromDOB }}, {{ visit.patientSexText }} -
          {{ visit.patientCode }}
        </span>
        <span style="color: white;"> - {{ visit.visitReason }} </span>
        <span
          *ngIf="
            useRoomOrDoctorVisit &&
            visit.doctorName != '' &&
            visit.doctorId != curUserId
          " style="color: white;">
          - {{ visit.doctorName }}
        </span>
        <span style="color: white;"> - {{ getVisitStatus() }} </span>
      </a>
      <ng-container
      *ngIf="
        useRoomOrDoctorVisit &&
        visit.roomId == currentUserRoomId &&
        (visit.doctorId == emptyId || visit.doctorId == curUserId)
      ">
      <button
        *ngIf="visit.isReceived"
        nz-button
        class="mg-l-15 mg-t-4"
        [disabled]="disabledActiton"
        (click)="checkValidReturn()">
        Bỏ nhận ca khám
      </button>  
      <!-- <i nz-icon title="Xem toàn bộ ca khám" nzType="idcard" nzTheme="outline" style="color: white; font-size: 24px; position: fixed; top: 63px; right: 57px;" (click)="showCKInfoFull(visit.patientId, visit.patientName, visit.id)"></i> -->
    </ng-container>
    <div style="color: white; float: right; margin-right: 13px;">
      <i  nz-icon title="Xem toàn bộ ca khám" nzType="select" nzTheme="outline" style="font-size: 24px; margin-right: 7px;" (click)="showCKInfoFull(visit.patientId, visit.patientName, visit.id)"></i> 
      <!-- <i  nz-icon [nzType]="isCollapse ? 'caret-down' : 'caret-right'" nzTheme="outline" (click)="collapseBox()" ></i> -->
    </div>   
    </div>
    </div>
    <div id="table_visitinfo">
      <div *ngIf="!isMobile" style="margin-top: -7px;">
        <table class="tVisitInfo collapse-{{ isCollapse }}" style="width: 100%; color: white;">
          <tr (click)="collapseBox()" class="cursor-pointer">
            <td colspan="3" *ngIf="visit.address">
              <img src="assets/image/user/home.svg" title="Địa chỉ" />
              <span title="{{ visit.address }}" class="patient_info">
                {{ visit.address }}
              </span>
            </td>
          </tr>
          <ng-container *ngIf="isCollapse">
            <tr *ngIf="vitualSignLabel">
              <td colspan="3">
                <img src="assets/image/DPicon/clinical-order.svg" title="Thông tin sinh tồn" />
                {{ vitualSignLabel }}
              </td>
            </tr>
            <tr>
              <td *ngIf="visit.phoneNo">
                <img src="assets/image/user/phone.svg" title="Số điện thoại" />
                {{ visit.phoneNo }}
              </td>
              <td *ngIf="visit.email">
                <img src="assets/image/user/email.svg" title="Email" />
                <span title="{{ visit.email }}" class="patient_info">
                  {{ getShortContent(visit.email) }}
                </span>
              </td>
              <td *ngIf="visit.presenterDoctorName">
                <img src="assets/image/user/user.svg" height="14" title="Bác sỹ giới thiệu" />
                {{ visit.presenterDoctorName }}
              </td>
            </tr>
            <tr>
              <td *ngIf="visit.visitDate">
                <img style="color: white;" src="assets/image/user/date_range_24px.svg" height="14" title="Ngày khám" />
                {{ visit.visitDate | date: 'dd/MM/yyyy h:mm a' }}
              </td>
              <td *ngIf="visit.visitReason">
                <img src="assets/image/DPicon/clinical-order.svg" title="Dịch vụ khám" />
                <span title="{{ visit.visitReason }}" class="patient_info">
                  {{ getShortContent(visit.visitReason, 20) }}
                </span>
              </td>
              <td *ngIf="visit.roomName">
                <img src="assets/image/user/location.png" title="Phòng khám" />
                <span title="{{ visit.roomName }}" class="patient_info">
                  {{ visit.roomName }}
                </span>
                <i *ngIf="!useRoomOrDoctorVisit" title="Thay đổi phòng khám" nz-icon nzType="edit" nzTheme="outline" (click)="onClickChangeRoom()"></i>
              </td>
            </tr>
            <tr *ngIf="visit.bhytCode">
              <td colspan="3">
                <img src="assets/image/user/badge.svg" height="14" title="Số BHYT" />
                <span>{{ visit.bhytCode }} </span>
                <i>(từ ngày {{ visit.validDate | date: 'dd/MM/yyyy' }} tới ngày {{ visit.expireDate | date: 'dd/MM/yyyy' }})</i>
              </td>
            </tr>
            <div id="editAndinfo" class="{{ hideButton }}">
              <div class="v-btn-info mg-r-10" (click)="showTabPatientInfo(visit.patientId, visit.patientName, visit.id)">
                <i title="Hồ sơ bệnh nhân" nz-icon nzType="idcard" nzTheme="outline"></i>
              </div>
              <div class="v-btn-info" (click)="editPatientInfo(visit.patientId, visit.patientName, visit.id)">
                <i title="Sửa thông tin bệnh nhân" nz-icon nzType="edit" nzTheme="outline"></i>
              </div>
            </div>
          </ng-container>
        </table>
        <!-- <div class="collapse-icon cursor-pointer"  (click)="collapseBox()">
          <i  nz-icon [nzType]="isCollapse ? 'caret-down' : 'caret-right'" nzTheme="outline"></i>
        </div> -->
      </div>
      <div *ngIf="isMobile" class="tVisitInfo" style="width: 100%">
        <div class="row">
          <div class="info-title col-1">
            <svg
              id="patient"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20">
              <path
                id="Path_3131"
                data-name="Path 3131"
                d="M13.374,22.362a.8.8,0,0,1-.662-.354l-1.354-2.032H9.8a.8.8,0,0,1,0-1.59h1.988a.8.8,0,0,1,.662.354l.83,1.245,1.773-3.546A.8.8,0,0,1,15.729,16a.784.784,0,0,1,.713.385L18.6,19.976h1.538a.8.8,0,1,1,0,1.59H18.145a.794.794,0,0,1-.682-.386l-1.641-2.734-1.738,3.476a.8.8,0,0,1-.662.438Z"
                transform="translate(-0.929 -2.362)"
                fill="#fff" />
              <circle
                id="Ellipse_140"
                data-name="Ellipse 140"
                cx="4"
                cy="4"
                r="4"
                transform="translate(3.247)"
                fill="#fff" />
              <path
                id="Path_3132"
                data-name="Path 3132"
                d="M5.567,18.362a2.389,2.389,0,0,1,2.386-2.386H9.941a2.384,2.384,0,0,1,1.159.3l.683-1.367a2.372,2.372,0,0,1,1.761-1.268A3.964,3.964,0,0,0,10.338,12H3.976A3.977,3.977,0,0,0,0,15.976v2.585a.6.6,0,0,0,.6.6H5.713A2.359,2.359,0,0,1,5.567,18.362Z"
                transform="translate(0 -1.543)"
                fill="#fff" />
            </svg>
          </div>
          <div title="{{ visit.patientName }}" class="patient_info col-10">
            {{ visit.patientName }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="info-title col-1">
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 162.978 162.978"
                  style="enable-background: new 0 0 162.978 162.978"
                  xml:space="preserve"
                  fill="#fff">
                  <g>
                    <g>
                      <path
                        d="M162.978,101.101l-19.611-39.224l-19.611,39.224h13.209c-8.315,25.958-32.633,44.826-61.324,44.826
                                        c-35.529,0-64.438-28.908-64.438-64.438c0-35.529,28.909-64.438,64.438-64.438c27.376,0,50.764,17.19,60.077,41.325l6.44-12.882
                                        c-12.813-23.595-37.82-39.649-66.512-39.649C33.936,5.844,0,39.778,0,81.489c0,41.708,33.936,75.645,75.645,75.645
                                        c34.924,0,64.331-23.809,72.997-56.032H162.978z" />
                      <path
                        d="M35.486,96.582h7.084l2.15-7.749h8.645l2.332,7.749h7.345l-9.362-30.192h-8.96L35.486,96.582z M47.494,77.32
                                        c0.493-1.749,0.941-4.034,1.39-5.823h0.088c0.449,1.789,0.988,4.036,1.527,5.823l1.882,6.413h-6.675L47.494,77.32z" />
                      <path
                        d="M81.737,71.722c3.311,0,5.371,0.583,7.029,1.294l1.436-5.466c-1.479-0.715-4.482-1.48-8.377-1.48
                                        c-9.901,0-17.2,5.731-17.253,15.769c-0.042,4.434,1.48,8.372,4.26,10.978c2.778,2.688,6.763,4.076,12.277,4.076
                                        c3.98,0,7.975-0.985,10.075-1.701V79.289H79.943v5.331h4.665v6.313c-0.542,0.274-1.798,0.449-3.365,0.449
                                        c-5.604,0-9.497-3.677-9.497-9.904C71.746,74.944,76.042,71.722,81.737,71.722z" />
                      <polygon
                        points="115.175,71.993 115.175,66.395 96.539,66.395 96.539,96.582 115.804,96.582 115.804,90.989 103.394,90.989
                                        103.394,83.821 114.507,83.821 114.507,78.261 103.394,78.261 103.394,71.993 		" />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="patient_info col-5">{{ age }}</div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="info-title col-1">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512"
                  xml:space="preserve"
                  fill="#fff"
                  width="20px">
                  <g>
                    <g>
                      <path
                        d="M403.921,0v31.347h35.36l-68.982,65.409c-24.421-24.99-58.474-40.53-96.092-40.53c-50.603,0-94.759,28.112-117.687,69.535
                                        c-1.964-0.086-3.938-0.138-5.924-0.138c-74.118,0-134.417,60.299-134.417,134.418c0,68.816,51.984,125.71,118.743,133.498v41.657
                                        H87.995v31.347h46.929V512h31.347v-45.458h48.977v-31.347h-48.977v-41.657c43.948-5.127,81.488-31.533,102.013-68.616
                                        c1.964,0.086,3.937,0.138,5.922,0.138c74.119,0,134.418-60.299,134.418-134.417c0-25.187-6.969-48.774-19.071-68.944
                                        l74.919-71.038v38.933h31.347V0H403.921z M150.598,363.11c-56.833,0-103.07-46.237-103.07-103.071
                                        c0-54.619,42.705-99.442,96.477-102.853c-2.751,10.7-4.215,21.91-4.215,33.457c0,60.464,40.132,111.726,95.157,128.562
                                        C216.281,345.738,185.432,363.11,150.598,363.11z M249.044,290.6c-44.709-11.26-77.906-51.802-77.906-99.957
                                        c0-10.636,1.62-20.901,4.625-30.561c44.709,11.26,77.906,51.803,77.906,99.958C253.669,270.676,252.048,280.94,249.044,290.6z
                                         M280.801,293.495c2.751-10.7,4.215-21.909,4.215-33.456c0-60.464-40.132-111.726-95.156-128.563
                                        c18.666-26.532,49.516-43.905,84.349-43.905c56.834,0,103.071,46.237,103.071,103.071
                                        C377.278,245.261,334.573,290.085,280.801,293.495z" />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="patient_info col-5">{{ visit.patientSexText }}</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="info-title col-1">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background: new 0 0 512 512"
              xml:space="preserve"
              style="margin-right: 8px; fill: #fff; stroke: #fff; width: 16px">
              <g>
                <g>
                  <g>
                    <circle cx="386" cy="210" r="20" />
                    <path
                      d="M432,40h-26V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v20h-91V20c0-11.046-8.954-20-20-20
                                    c-11.046,0-20,8.954-20,20v20h-90V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v20H80C35.888,40,0,75.888,0,120v312
                                    c0,44.112,35.888,80,80,80h153c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20H80c-22.056,0-40-17.944-40-40V120
                                    c0-22.056,17.944-40,40-40h25v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h90v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h91
                                    v20c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80h26c22.056,0,40,17.944,40,40v114c0,11.046,8.954,20,20,20
                                    c11.046,0,20-8.954,20-20V120C512,75.888,476.112,40,432,40z" />
                    <path
                      d="M391,270c-66.72,0-121,54.28-121,121s54.28,121,121,121s121-54.28,121-121S457.72,270,391,270z M391,472
                                    c-44.663,0-81-36.336-81-81s36.337-81,81-81c44.663,0,81,36.336,81,81S435.663,472,391,472z" />
                    <path
                      d="M420,371h-9v-21c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v41c0,11.046,8.954,20,20,20h29
                                    c11.046,0,20-8.954,20-20C440,379.954,431.046,371,420,371z" />
                    <circle cx="299" cy="210" r="20" />
                    <circle cx="212" cy="297" r="20" />
                    <circle cx="125" cy="210" r="20" />
                    <circle cx="125" cy="297" r="20" />
                    <circle cx="125" cy="384" r="20" />
                    <circle cx="212" cy="384" r="20" />
                    <circle cx="212" cy="210" r="20" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="patient_info col-10">
            {{ visit.visitDate | date: 'dd/MM/yyyy h:mm a' }}
          </div>
        </div>
        <div class="row">
          <div class="info-title col-1">
            <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 321.067 321.067"
              style="enable-background: new 0 0 321.067 321.067"
              xml:space="preserve"
              width="20px"
              fill="#fff">
              <g>
                <g>
                  <rect x="153.6" width="13.867" height="26.773" />
                </g>
              </g>
              <g>
                <g>
                  <rect
                    x="86.757"
                    y="17.913"
                    transform="matrix(0.8648 -0.5021 0.5021 0.8648 -3.0492 51.2737)"
                    width="13.867"
                    height="26.773" />
                </g>
              </g>
              <g>
                <g>
                  <rect
                    x="37.81"
                    y="66.818"
                    transform="matrix(0.5021 -0.8648 0.8648 0.5021 -47.0835 78.6281)"
                    width="13.867"
                    height="26.773" />
                </g>
              </g>
              <g>
                <g>
                  <rect x="13.44" y="140.16" width="26.773" height="13.867" />
                </g>
              </g>
              <g>
                <g>
                  <rect
                    x="31.275"
                    y="207.143"
                    transform="matrix(0.8648 -0.5021 0.5021 0.8648 -101.4503 51.366)"
                    width="26.773"
                    height="13.867" />
                </g>
              </g>
              <g>
                <g>
                  <rect
                    x="269.378"
                    y="200.579"
                    transform="matrix(0.5021 -0.8648 0.8648 0.5021 -47.465 345.4892)"
                    width="13.867"
                    height="26.773" />
                </g>
              </g>
              <g>
                <g>
                  <rect x="280.853" y="140.16" width="26.773" height="13.867" />
                </g>
              </g>
              <g>
                <g>
                  <rect
                    x="262.935"
                    y="73.335"
                    transform="matrix(0.8648 -0.5021 0.5021 0.8648 -2.946 149.5944)"
                    width="26.773"
                    height="13.867" />
                </g>
              </g>
              <g>
                <g>
                  <rect
                    x="214.021"
                    y="24.368"
                    transform="matrix(0.5021 -0.8648 0.8648 0.5021 86.1553 212.2484)"
                    width="26.773"
                    height="13.867" />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M261.013,140.373c0-55.467-45.013-100.587-100.48-100.587c-3.84,0-7.68,0.213-11.52,0.64
                                        c-45.76,5.013-82.88,41.92-88.32,87.787c-2.773,23.467,2.453,46.293,15.04,66.027c11.413,17.92,19.84,37.973,24.96,59.627H80.32
                                        v13.867h23.253c0.747,4.267,1.387,8.533,1.813,12.907H86.933v13.867h19.627c0.213,4.16,0.32,8.32,0.32,12.587v13.973h107.307
                                        v-16.853c0-3.2,0.107-6.507,0.213-9.707h19.733V280.64H215.68c0.533-4.267,1.173-8.64,2.027-12.907h23.04v-13.867H220.8
                                        c5.44-21.227,14.4-42.347,26.347-62.72C256.213,175.787,261.013,158.187,261.013,140.373z M200.427,304.213v2.987h-79.68v-0.213
                                        c0-4.16-0.107-8.32-0.32-12.48h80.213C200.533,297.707,200.427,301.013,200.427,304.213z M201.813,280.64H119.36
                                        c-0.427-4.373-1.067-8.64-1.707-12.907h86.08C202.88,272,202.347,276.373,201.813,280.64z M206.613,253.867h-91.627
                                        c-5.44-24.427-14.72-47.04-27.52-67.2c-10.773-16.96-15.253-36.587-12.907-56.853c4.693-39.467,36.693-71.253,76.16-75.627
                                        c3.307-0.32,6.613-0.533,9.92-0.533c47.787,0,86.613,38.933,86.613,86.72c0,15.36-4.16,30.507-11.947,43.733
                                        C221.973,206.72,212.267,230.187,206.613,253.867z" />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M193.707,125.973c-2.773-12.373-13.013-22.613-25.387-25.387c-10.453-2.347-21.12,0-29.333,6.613
                                        c-8.213,6.613-12.907,16.427-12.907,26.88h13.867c0-6.293,2.773-12.053,7.68-16c5.013-4.053,11.307-5.44,17.707-3.947
                                        c7.253,1.6,13.227,7.68,14.933,14.933c1.813,7.893-0.853,15.893-7.147,20.907c-12.48,10.027-19.627,24.107-19.627,38.613v0.213
                                        h13.76v-0.32c0-10.24,5.227-20.373,14.4-27.733C192.107,152.533,196.693,139.2,193.707,125.973z" />
                </g>
              </g>
              <g>
                <g>
                  <rect x="153.6" y="197.227" width="13.867" height="13.44" />
                </g>
              </g>
            </svg>
          </div>
          <div title="{{ visit.visitReason }}" class="patient_info col-10">
            {{ visit.visitReason }}
          </div>
        </div>
        <div class="row">
          <div class="info-title col-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              style="margin-right: 8px; fill: #fff">
              <path
                id="Path_2258"
                data-name="Path 2258"
                d="M6.218,9.924a13.465,13.465,0,0,0,5.858,5.858l1.956-1.956a.884.884,0,0,1,.907-.213,10.14,10.14,0,0,0,3.173.507.892.892,0,0,1,.889.889v3.1a.892.892,0,0,1-.889.889A15.11,15.11,0,0,1,3,3.889.892.892,0,0,1,3.889,3H7a.892.892,0,0,1,.889.889A10.1,10.1,0,0,0,8.4,7.062a.892.892,0,0,1-.222.907Z"
                transform="translate(-3 -3)"
                fill="#fff" />
            </svg>
          </div>
          <div class="patient_info col-10">{{ visit.phoneNo }}</div>
        </div>
        <div class="row">
          <div class="info-title col-1">
            <svg
              id="home-52"
              xmlns="http://www.w3.org/2000/svg"
              width="17.137"
              height="16.857"
              viewBox="0 0 17.137 16.857"
              style="margin-right: 8px; fill: #fff">
              <g
                id="Group_2922"
                data-name="Group 2922"
                transform="translate(0 0)">
                <path
                  id="Path_2256"
                  data-name="Path 2256"
                  d="M10.671,5.951,4,11.67v7.652a.556.556,0,0,0,.556.556H9V15.43H12.34v4.448h4.448a.556.556,0,0,0,.556-.556V11.671Zm1.669,7.255H9v-2.78H12.34Z"
                  transform="translate(-2.104 -3.022)"
                  fill="#fff" />
                <path
                  id="Path_2257"
                  data-name="Path 2257"
                  d="M9.157.683,5.266,4.019V1.971H3.042V5.925L.59,8.027l.724.844L9.157,2.148,17,8.871l.724-.844Z"
                  transform="translate(-0.59 -0.683)"
                  fill="#fff" />
              </g>
            </svg>
          </div>
          <div title="{{ visit.address }}" class="patient_info col-10">
            {{ visit.address }}
          </div>
        </div>
        <!-- <td>Mã BN:</td>
                        <td title="{{visit.patientCode}}" class="patient_info col-10">{{visit.patientCode}}</td> -->
        <nz-divider *ngIf="isExpandInfo"></nz-divider>
        <div *ngIf="isExpandInfo">
          <div class="row">
            <div class="col-5">Nhóm bác sĩ:</div>
            <div class="patient_info col-7">{{ visit.groupName }}</div>
          </div>
          <div class="row">
            <div class="col-5">Bác sĩ khám:</div>
            <div class="patient_info col-7">{{ visit.doctorName }}</div>
          </div>
          <div class="row" *ngIf="isCovidGroup">
            <div class="col-5">Dược sĩ lâm sàng:</div>
            <div class="patient_info col-7">{{ visit.pharmacistName }}</div>
          </div>
          <div class="row" *ngIf="isCovidGroup">
            <div class="col-5">ĐD phụ trách:</div>
            <div class="patient_info col-7">{{ visit.nursingInCareName }}</div>
          </div>
        </div>
        <div *ngIf="isExpandInfo">
          <div class="row" *ngIf="!isCovidGroup">
            <div class="col-5">Loại khám:</div>
            <div class="patient_info col-7">{{ visit.visitTypeText }}</div>
          </div>
          <div class="row" *ngIf="isCovidGroup">
            <div class="col-5">BS phụ trách:</div>
            <div class="patient_info col-7">
              {{ visit.doctorInCareDoctName }}
            </div>
          </div>
          <div class="row" *ngIf="isCovidGroup">
            <div class="col-5">Điều phối viên:</div>
            <div class="patient_info col-7">{{ visit.coordinatorName }}</div>
          </div>
          <div class="row" *ngIf="isCovidGroup">
            <div class="col-5">CV tâm lý:</div>
            <div class="patient_info col-7">{{ visit.psychiatristName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="editAndinfo" class="{{ hideButton }}">
      <!-- <div class="btnHoso" (click)="showTabPatientInfo(visit.patientId, visit.patientName, visit.id)">
                <i title="Hồ sơ bệnh nhân" nz-icon nzType="idcard" nzTheme="outline"></i>
            </div>
            <div class="btnSua" (click)="editPatientInfo(visit.patientId, visit.patientName, visit.id)">
                <i title="Sửa thông tin bệnh nhân" nz-icon nzType="edit" nzTheme="outline"></i>
            </div> -->
      <div class="btnSua" *ngIf="isMobile" (click)="toggleExpand()">
        <i *ngIf="!isExpandInfo" nz-icon nzType="eye" nzTheme="outline"></i>
        <i
          *ngIf="isExpandInfo"
          nz-icon
          nzType="eye-invisible"
          nzTheme="outline"></i>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmReturnContent>
  <div>Bạn có chắc chắn muốn bỏ nhận ca khám này?</div>
  <label nz-checkbox nzDisabled [(ngModel)]="isDeletedInfo"></label>
  <span class="mg-l-15">Bỏ các thông tin đã nhập vào ca khám</span>
</ng-template>
