import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { MedicationService } from 'src/app/services/medication.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PrescriptionService } from 'src/app/services/prescription-service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AppState } from '../../app-state/app-state';
import { GroupTreeService } from 'src/app/services/groupTree.service';

@Component({
  selector: 'app-prescription-sample',
  templateUrl: './prescription-sample.component.html',
  styleUrls: ['./prescription-sample.component.scss'],
})
export class PrescriptionSampleComponent implements OnInit, OnChanges {
  @Input() isVisible: boolean;
  @Input()
  get reload(): boolean {
    return this._reload;
  }
  set reload(value: boolean) {
    if (value) {
      this._reload = value;
      this.getListPrescriptionSample();
    }
  }
  private _reload = false;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() sampleEmit: EventEmitter<any> = new EventEmitter();

  lstSample: any;
  groupId: string;
  groupSub: Subscription;
  prescription: any = {
    Id: '',
  };
  presSampleEdit = {
    Id: '',
    Title: '',
    DoctorComment: '',
  };
  showDetail = false;
  lstNewMedication: {
    Id: '';
    CreatorId: '';
    MedicationId: '';
    MedicationName: '';
    MedicationCode: '';
    Amount: '';
    UnitName: '';
    Text: '';
    Price: undefined;
    ActiveIngredient: '';
    Content: '';
    IsFunctionalFoods: false;
  }[] = [];
  showSampleInfo = false;
  isEditMedication = false;
  isAddSample = false;
  formMedication: FormGroup;
  listMedicationSource: any = [];
  submitted = false;
  currentUserId = '';
  callbackSearchFn: any;
  prescriptionIsShowActiveIngredient = true;

  constructor(
    private store: Store<AppState>,
    private prescriptionService: PrescriptionService,
    private formBuilder: FormBuilder,
    private medicationService: MedicationService,
    private notificationService: NotificationService,
    private modalService: NzModalService,
    private shareService: ShareService,
    private groupService: GroupTreeService
  ) {
    this.formMedication = this.formBuilder.group({
      Id: [null],
      MedicationId: [null],
      MedicationName: ['', [Validators.required]],
      MedicationCode: ['', []],
      Amount: ['', [Validators.required, Validators.min(1)]],
      UnitName: ['', [Validators.required]],
      Text: ['', [Validators.required]],
      Price: [''],
      ActiveIngredient: [''],
      Content: [''],
      IsFunctionalFoods: ['']
    });
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.currentUserId = res;
      });
    this.callbackSearchFn = this.shareService.debounce(this.onSearchMedication, 500);
  }
  get formMedicationData() {
    return this.formMedication.controls;
  }

  ngOnInit(): void {
    console.log();
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        this.getListPrescriptionSample();
        this.prescriptionIsShowActiveIngredient = data.prescriptionIsShowActiveIngredient;
        // this.loadGroupInfo();
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'isVisible': {
            if (changes['isVisible'].currentValue) {
              this.getListPrescriptionSample();
            }
          }
        }
      }
    }
  }

  getListPrescriptionSample() {
    this.prescriptionService.getListPrescriptionSample(this.groupId).subscribe((res) => {
      if (res.d.isValid) {
        this.lstSample = JSON.parse(res.d.jsonData);
        console.log('getListPrescriptionSample', this.lstSample);
      }
    });
  }

  openModalCreate() {
    this.showSampleInfo = true;
    this.isAddSample = true;
    this.submitted = false;
    this.isEditMedication = false;
    this.presSampleEdit = {
      Id: '',
      Title: '',
      DoctorComment: '',
    };
    this.lstNewMedication = [];
    this.formMedication.reset();
  }

  handleCancel() {
    this.isVisible = false;
    this.showDetail = false;
    this.closeModal.emit();
    this.prescription = {};
  }

  showSampleDetail(data) {
    this.showDetail = true;
    this.prescription = data;
    this.prescription.MedicationRequests = this.checkMedication(data.MedicationRequests);
  }

  openModalSampleInfo(data) {
    if (data.CreatorId != this.currentUserId) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn không thể sửa đơn thuốc của người khác'
      );
      return;
    }
    this.lstNewMedication = this.checkMedication(data.MedicationRequests);
    this.presSampleEdit = JSON.parse(JSON.stringify(data));
    this.showSampleInfo = true;
    this.isAddSample = false;
    this.submitted = false;
    this.isEditMedication = false;
    this.formMedication.reset();
  }

  usingSample(data) {
    console.log('DATA', data)
    this.sampleEmit.emit(data);
    this.closeModal.emit();
    this.showDetail = false;
    this.prescription = {};
  }

  closeSampleInfo() {
    this.showSampleInfo = false;
    this.presSampleEdit = {
      Id: '',
      Title: '',
      DoctorComment: '',
    };
    this.formMedication.reset();
    this.lstNewMedication = [];
  }

  onSearchMedication() {
    let keyword = this.formMedication.value.MedicationName;
    console.log(keyword);

    if (this.shareService.checkEmpty(keyword)) return;
    const search = {
      keyword: keyword,
      groupId: this.groupId,
    };
    this.medicationService.getMedicationAll(search).subscribe((res) => {
      this.listMedicationSource = res;
      this.listMedicationSource.forEach((en) => {
        en.title = this.getMedicationName(en.name, en.content, en.activeIngredient);
      });
    });
  }

  selectMedication(data) {
    this.formMedication.patchValue({
      Id: '',
      MedicationId: data.id,
      MedicationName: data.name,
      MedicationCode: data.code,
      UnitName: data.unit,
      Text: data.usage,
      Price: data.price,
      ActiveIngredient: data.activeIngredient,
      Content: data.content,
      IsFunctionalFoods: data.isFunctionalFoods
    });
    this.medicationService.checkMedication(data.id, this.groupId).subscribe((res) => {
      if (res.jsonData != null) {
        const price = res.jsonData.price;
        if (price != undefined) {
          this.formMedication.controls.Price.setValue(price);
        } else {
          this.formMedication.controls.Price.setValue(0);
        }
      }
    });
  }

  resetFormMedication() {
    this.formMedication.reset();
    this.isEditMedication = false;
    this.submitted = false;
  }

  addMedication() {
    this.submitted = true;
    if (this.formMedication.valid) {
      const formValue = this.formMedication.value;
      const length = this.lstNewMedication.filter((en) => en.MedicationId == formValue.MedicationId).length;
      if (length > 0) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Thuốc này đã có trong đơn');
        return;
      }
      this.lstNewMedication.push(this.formMedication.value);
      this.lstNewMedication = [...this.lstNewMedication];
      this.formMedication.reset();
      this.submitted = false;
    }
  }

  updateMedication() {
    const formValue = this.formMedication.value;
    for (let i = 0; i < this.lstNewMedication.length; i++) {
      if (this.lstNewMedication[i].MedicationId == formValue.MedicationId) {
        this.lstNewMedication[i] = formValue;
      }
    }
    this.lstNewMedication = [...this.lstNewMedication];
    this.formMedication.reset();
    this.isEditMedication = false;
  }

  checkValidSample() {
    if (this.lstNewMedication.length == 0) {
      this.notificationService.showNotification(Constant.ERROR, 'Vui lòng thêm thuốc vào đơn');
      return false;
    }
    if (!this.presSampleEdit.Title) {
      this.notificationService.showNotification(Constant.ERROR, 'Vui lòng nhập tên đơn thuốc mẫu');
      return false;
    }
    return true;
  }
  createSample() {
    if (!this.checkValidSample()) return;
    const medicationRequests = [];
    for (let i = 0; i < this.lstNewMedication.length; i++) {
      const item = this.lstNewMedication[i];
      const newItem = {
        medicationId: item.MedicationId,
        medicationName: item.MedicationName,
        medicationCode: item.MedicationCode,
        activeIngredient: item.ActiveIngredient,
        amount: item.Amount,
        unitName: item.UnitName,
        text: item.Text,
        content: item.Content,
        //price: item.Price,
        isFunctionalFoods: item.IsFunctionalFoods
      };
      medicationRequests.push(newItem);
    }
    const payload = {
      title: this.presSampleEdit.Title,
      doctorComment: this.presSampleEdit.DoctorComment,
      medicationRequests,
    };
    this.prescriptionService.createPrescriptionSample(this.groupId, payload).subscribe((res) => {
      if (res.d.isValid) {
        this.notificationService.showNotification(Constant.SUCCESS, 'Tạo đơn thuốc mẫu thành công');
        this.showSampleInfo = false;
        this.getListPrescriptionSample();
      } else {
        this.notificationService.showNotification(Constant.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  updateSample() {
    if (!this.checkValidSample()) return;
    const medicationRequests = [];
    for (let i = 0; i < this.lstNewMedication.length; i++) {
      const item = this.lstNewMedication[i];
      const newItem = {
        medicationId: item.MedicationId,
        medicationName: item.MedicationName,
        medicationCode: item.MedicationCode,
        activeIngredient: item.ActiveIngredient,
        amount: item.Amount,
        unitName: item.UnitName,
        text: item.Text,
        content: item.Content,
        isFunctionalFoods: item.IsFunctionalFoods
      };
      medicationRequests.push(newItem);
    }
    const sampleId = this.presSampleEdit.Id;
    const payload = {
      //id: this.presSampleEdit.Id,
      title: this.presSampleEdit.Title,
      creatorId: this.currentUserId,
      doctorComment: this.presSampleEdit.DoctorComment,
      medicationRequests,
    };
    this.prescriptionService.savePrescriptionSample(sampleId, payload).subscribe((res) => {
      if (res.d.isValid) {
        this.showSampleInfo = false;
        this.getListPrescriptionSample();
        this.prescription.Title = this.presSampleEdit.Title;
        this.prescription.DoctorComment = this.presSampleEdit.DoctorComment;
        //this.prescription.MedicationRequests = this.lstNewMedication;
        this.prescription.MedicationRequests = [...this.lstNewMedication];
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
      } else {
        this.notificationService.showNotification(Constant.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  deleteMedication(data) {
    this.lstNewMedication = this.lstNewMedication.filter((en) => en.MedicationId != data.MedicationId);
    this.formMedication.reset();
    this.isEditMedication = false;
  }

  editMedication(data) {
    this.isEditMedication = true;
    this.formMedication.patchValue({
      Id: data.Id,
      MedicationId: data.MedicationId,
      MedicationName: data.MedicationName,
      MedicationCode: data.MedicationCode,
      Amount: data.Amount,
      UnitName: data.UnitName,
      Text: data.Text,
      Price: data.Price,
      ActiveIngredient: data.ActiveIngredient,
      IsFunctionalFoods: data.IsFunctionalFoods
    });
  }

  getMedicationDetailPrice(item) {
    //console.log("getMedicationDetailPrice", item);
    if (item == undefined || item.Price == undefined || item.Price == 0) {
      return '';
    } else {
      return item.Price * item.Amount;
    }
  }

  getTotalPrice(data) {
    if (data == undefined || data == 0) {
      return 0;
    }
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item != undefined && item.Price > 0) {
        total += item.Price * item.Amount;
      }
    }
    return total;
  }

  checkMedication(data) {
    if ((data != null || data != undefined) && data.length > 0) {
      data.forEach((item) => {
        this.medicationService.checkMedication(item.MedicationId, this.groupId).subscribe((res) => {
          if (res.jsonData != null) {
            item.Price = res.jsonData.price;
          }
        });
      });
    }
    return data;
  }

  getMedicationName(name, content, ingredient) {
    return this.prescriptionService.getMedicationName(
      name,
      content,
      ingredient,
      this.prescriptionIsShowActiveIngredient
    );
  }
  removeSample(id) {
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Bạn có muốn xóa hay không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Bỏ qua',
      nzOnOk: () => {
        this.prescriptionService.removePrescriptionSample(id).subscribe((res) => {
          if (res.isValid) {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xóa thành công!');
            this.lstSample = this.lstSample.filter((en) => en.Id != id);
            this.lstSample = [...this.lstSample];
            this.prescription = {};
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
          }
        });
      },
    });
  }
}
