import { Component, OnInit, Output, OnChanges, SimpleChanges, Input, EventEmitter } from '@angular/core';
import { CloseAddAppointment, OpenAddPatient } from '../../modal/redux/modal/modal.action';
import { MedicalAppointmentService } from 'src/app/services/medical-appointment.service';
import { SubclinicalorderService } from 'src/app/services/subclinicalorder.service';

import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { Observable, Observer } from 'rxjs';
import { PatientService } from 'src/app/services/patient.service';
import { Patient } from '../../../models/patient.class';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Constant } from 'src/app/share/constants/constant.class';
import { NotificationService } from 'src/app/services/notification.service';
import { DatePipe } from '@angular/common';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-appointment-add',
  templateUrl: './appointment-add.component.html',
  styleUrls: ['./appointment-add.component.scss'],
})
export class AppointmentAddComponent implements OnInit, OnChanges {
  @Input() patients: Patient;

  @Output() selectPatient = new EventEmitter();
  @Output() refreshAppointment = new EventEmitter();

  isVisibleAdd: Observable<boolean>;
  groupId: string;
  searchvalue;
  submitted = false;
  isLoading = false;
  info: FormGroup;
  clsDataSource: any;
  clsSelected: any;
  listClsDisplay: any[] = [];
  keyword = '';
  initialDataSourceCls: any;
  initialFormValue: any;
  appointmentDate: any = Date.now;

  constructor(
    private store: Store<AppState>,
    private medicalService: MedicalAppointmentService,
    private patientService: PatientService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private subclinicalorderService: SubclinicalorderService,
    private shareService: ShareService
  ) {
    this.isVisibleAdd = this.store.select((store) => store.modals.isAddAppointmentOpen);

    this.info = this.fb.group({
      userId: [null],
      groupId: [null],
      fullName: [null, [Validators.required]],
      patientCode: [null],
      dateAppointment: [null, [Validators.required]],
      timeAppointment: [null, [Validators.required], [this.validatorsTime]],
      hourAppointment: [0],
      minuteAppointment: [0],
      request: [null],
      note: [null],
      status: 2,
      priority: 3,
    });
    this.store.select('modals').subscribe((res) => {
      if (res.isPatientOpen) {
        this.info.disable();
      }
      if (!res.isPatientOpen) {
        this.info.enable();
      }
    });
    this.store.select('groupAuth').subscribe((res) => {
      this.groupId = res.groupId;
      this.info.patchValue({
        groupId: this.groupId,
      });
    });
    this.initialFormValue = this.info.value;
  }
  callBackFn = this.shareService.throttle(this.searchCLS, 500);
  disabledDateBeforeToday = this.patientService.disabledDateBeforeToday;

  ngOnInit(): void {
    this.getClsDataSource();
  }

  getClsDataSource() {
    this.subclinicalorderService.getSubclinicalOrderAll().subscribe((res) => {
      this.clsDataSource = res;
      this.initialDataSourceCls = res;
    });
  }

  onTyping(event) {
    if (event.trim() != '') {
      this.keyword = event.trim();
      this.callBackFn();
    } else {
      this.clsDataSource = this.initialDataSourceCls;
    }
  }

  searchCLS() {
    this.subclinicalorderService.searchByNameAndCode(this.keyword).subscribe((res) => {
      if (res.isValid) {
        this.clsDataSource = res.jsonData;
      }
    });
  }

  removeItemCls(id: string) {
    this.listClsDisplay = this.listClsDisplay.filter((en) => en.id != id);
  }

  addClsSelected(cls) {
    const exist = this.listClsDisplay.some((en) => en.id === cls.id);
    if (exist) {
      this.notificationService.showNotification('warning', 'Đã tồn tại chỉ định trong danh sách');
    } else {
      this.listClsDisplay.push(cls);
      this.scrollToBottom();
    }
    this.clsSelected = {};
  }

  scrollToBottom() {
    $('.list-cls').animate({ scrollTop: '100' });
  }

  setValueChange() {
    this.info.controls.timeAppointment.setValue(null);
  }

  validatorsTime = (control: FormControl) =>
    new Observable((observer: Observer<any>) => {
      if (control.value) {
        const hour = control.value.getHours();
        const min = control.value == null ? 0 : control.value.getMinutes();
        const date = this.info.value.dateAppointment;

        if (
          date != undefined &&
          date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) &&
          hour != null &&
          hour < new Date().getHours()
        ) {
          observer.next({ unableHour: true, error: true });
          observer.complete();
        } else if (min != 30 && min != 0) {
          control.setValue(null);
          observer.next(null);
          observer.complete();
        } else {
          observer.next(null);
          observer.complete();
        }
      }
    });

  get f() {
    return this.info.controls;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const chng = changes[propName];
      const cur = JSON.stringify(chng.currentValue);
      const prev = JSON.stringify(chng.previousValue);
      if (cur != prev) {
        this.info.controls.fullName.setValue(this.patients.fullName);
      }
    }
  }

  onSearchPatient(keyword: string) {
    if (keyword != null && keyword != '') {
      keyword = keyword.trim();
      this.patientService.searchPatient(keyword).subscribe((res) => {
        this.searchvalue = res ? res : null;
      });
    }
  }

  selectSearchPatient(data) {
    this.patients = data;
    this.info.controls.fullName.setValue(this.patients.fullName);
    this.selectPatient.emit(this.patients);
  }

  showAddPatient() {
    this.store.dispatch(new OpenAddPatient());
  }

  handleOk() {
    this.submitted = true;
    console.log('info', this.info.valid, this.info);
    if (this.info.valid) {
      const subClinicalOrderIds = Array.from(this.listClsDisplay, (en) => en.id);
      const form = this.info.value;
      const payload = {
        userId: JSON.parse(localStorage.getItem(Constant.USER_INFO)).userId,
        groupId: this.groupId,
        fullName: this.patients.fullName,
        gender: this.patients.sex,
        address: this.patients.address,
        phoneNo: this.patients.phoneNo,
        soBHYT: this.patients.BHYTCode,
        patientId: this.patients.id,
        patientCode: this.patients.code,
        dob: this.patients.dob,
        dobString: this.patients.dobString,
        dateAppointment: form.dateAppointment,
        hourAppointment: Number(this.datePipe.transform(new Date(form.timeAppointment), 'HH')),
        minuteAppointment: Number(this.datePipe.transform(new Date(form.timeAppointment), 'mm')),
        request: form.request,
        note: form.note,
        status: form.status,
        priority: form.priority,
        subClinicalOrderIds,
      };

      this.medicalService.addAppointment(payload).subscribe((res) => {
        if (res.isValid) {
          this.refreshAppointment.emit();
          this.store.dispatch(new CloseAddAppointment());
          this.notificationService.showNotification('success', 'Thêm hẹn khám thành công');
          this.info.reset(this.initialFormValue);
          this.patients = {};
          this.listClsDisplay = [];
          this.submitted = false;
        } else {
          this.notificationService.showNotification('error', res.errors[0].errorMessage);
        }
      });
    }
  }

  cancelAdd() {
    this.store.dispatch(new CloseAddAppointment());
    this.info.reset(this.initialFormValue);
    this.patients = {};
    this.listClsDisplay = [];
  }

  toStatusText(status) {
    return this.medicalService.toStatusText(status);
  }

  toPriorityText(priority) {
    return this.medicalService.toPriorityText(priority);
  }
}
