import { ShareService } from './../../../../services/share.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DoctorService } from 'src/app/services/doctor.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PatientService } from 'src/app/services/patient.service';
import { ShareVisitService } from 'src/app/services/share-visit.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-share-visit-update',
  templateUrl: './share-visit-update.component.html',
  styleUrls: ['./share-visit-update.component.scss'],
})
export class ShareVisitUpdateComponent implements OnInit {
  _isVisible = false;
  @Input() set isVisible(value: boolean) {
    if (value) {
      this.loadListGroupShared();
      this.loadMyGroup();
      this.loadShareLink();
    }
    this._isVisible = value;
  }

  get isVisible() {
    return this._isVisible;
  }

  @Input() visitId: string;
  @Input() groupId: string;
  isVisibleQR = false;
  model: any = {
    expireDate: '',
    shareLevel: 4,
    isOn: false,
    url: '',
    shareId: '',
  };
  loading = false;
  searchData = [];
  searchDataFilter = [];

  data = [];
  linkShares: any = [];
  selectGroupId: string;
  shareAllVisit: boolean;
  today = new Date();
  groupSub: Subscription;
  currentLinkShare = 'cur link';
  searchGroupInput = '';
  disabledDateBeforeToday = this.patientService.disabledDateBeforeToday;

  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  constructor(
    private shareVSService: ShareVisitService,
    private notificationService: NotificationService,
    private doctorService: DoctorService,
    private shareService: ShareService,
    private patientService: PatientService,
    private modalService: NzModalService
  ) {}

  ngOnInit(): void {}

  closeQR(val) {
    this.isVisibleQR = false;
  }

  copyInputUrl(val) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  detele(id) {
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Bạn có muốn xóa hay không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () => this.deleteLink(id),
    });
  }

  deleteLink(id) {
    this.shareVSService.deleteShareVisit(id).subscribe((response) => {
      if (response.isValid) {
        this.loadShareLink();
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          response.errors[0].errorMessage
        );
      }
    });
  }

  loadShareLink() {
    //call API load info
    this.shareVSService.getShareVisit(this.visitId).subscribe((response) => {
      if (response.isValid && response.jsonData != null) {
        const rs = [];
        response.jsonData.forEach((element) => {
          element.url =
            window.location.origin + '/view-visit?Code=' + element.url;
          rs.push(element);
        });
        this.linkShares = rs;
      }
    });
  }

  loadListGroupShared() {
    //get source for data
    this.shareVSService
      .getListGroupShared(this.visitId)
      .subscribe((response) => {
        if (response.isValid) {
          this.data = response.jsonData;
        } else {
        }
      });
  }

  loadMyGroup() {
    this.doctorService.getListGroup().subscribe((response) => {
      if (response != null && response != []) {
        this.searchData = response;
        this.searchData = this.searchData.filter(
          (en) => en.id !== this.groupId
        );
        //trừ current group ra khỏi list
      } else {
      }
    });
  }

  handleCancel(): void {
    this.isVisible = false;
    this.closeModal.emit();
  }

  openQRCode(link) {
    this.currentLinkShare = link;
    this.isVisibleQR = true;
  }

  updateShareLink() {
    if (this.model.expireDate == null || this.model.expireDate === '') {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn chưa chọn exprire date'
      );
      return;
    }
    if (this.model.shareLevel !== 3 && this.model.shareLevel !== 4) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn chưa chọn phạm vi chia sẻ'
      );
      return;
    }
    const saveData = {
      visitId: this.visitId,
      expireDate: this.model.expireDate,
      shareLevel: this.model.shareLevel,
    };
    this.shareVSService.shareVisit(saveData).subscribe((response) => {
      if (response.isValid) {
        this.loadShareLink();
        this.model.expireDate = '';
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Lưu Thành công'
        );
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          response.errors[0].errorMessage
        );
      }
    });
  }

  onSearchGroup(keyword) {
    if (this.searchData === null || this.searchData === []) {
      this.loadShareLink();
    }
    keyword = keyword.toLowerCase();

    this.searchDataFilter = this.searchData.filter(
      (item) => item.name.toLowerCase().indexOf(keyword) !== -1
    );
  }

  selectShareGroup(selectGroup) {
    this.selectGroupId = selectGroup.id;
  }

  shareGroup() {
    if (this.shareService.checkEmpty(this.selectGroupId)) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn chưa chọn nhóm'
      );
      return;
    }

    const saveData = {
      visitId: this.visitId,
      groupId: this.selectGroupId,
      shareLevel: 4,
      shareAllVisit: this.shareAllVisit,
    };
    this.shareVSService.addGroupShared(saveData).subscribe((response) => {
      if (response.isValid) {
        this.selectGroupId = '';
        this.searchGroupInput = '';
        this.loadListGroupShared();
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Lưu Thành công'
        );
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          response.errors[0].errorMessage
        );
      }
    });
  }

  shareVisit() {
    // if(this.model.shareLevel == 3){
    //   this.shareGroup();
    // }

    // if(this.model.shareLevel == 4){
    this.updateShareLink();
    // }
  }

  convertHourToText(val) {
    switch (val) {
      case 4:
        return '4 giờ';
      case 8:
        return '8 giờ';
      case 12:
        return '12 giờ';
      case 24:
        return '24 giờ';
      case 168:
        return '7 ngày';
      case 720:
        return '30 ngày';
      case 9999:
        return 'Vô hạn';
      default:
        return '';
    }
  }
}
