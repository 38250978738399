<div style="padding: 20px">
  <div class="table-operations" style="margin-bottom: 10px; text-align: right">
    <button
      class="mg-r-10 btn-green"
      nz-button
      (click)="newRecordTab('new', 'mới')">
      <i nz-icon nzType="plus"></i>Thêm mới
    </button>
    <button
      class="mg-r-10"
      nz-button
      nzType="primary"
      (click)="newRecordTab(currentForm.id, currentName)">
      <i nz-icon nzType="form"></i>Sửa
    </button>
    <button class="" nz-button nzType="danger" (click)="deleteItem()">
      <i nz-icon nzType="close"></i>Xoá
    </button>
  </div>
  <div class="search-box">
    <nz-input-group
      [nzSuffix]="suffixTemplate"
      style="width: 200px; margin-right: 15px">
      <input
        type="text"
        nz-input
        [(ngModel)]="inputValue"
        placeholder="Search..."
        [nzAutocomplete]="auto"
        (ngModelChange)="ontyping($event)" />
    </nz-input-group>
    <ng-template #suffixTemplate>
      <i
        nz-icon
        nz-tooltip
        class="ant-input-clear-icon"
        nzTheme="fill"
        nzType="close-circle"
        *ngIf="inputValue"
        (click)="reset()"></i>
    </ng-template>
    <nz-autocomplete [nzDataSource]="filterOption" #auto></nz-autocomplete>
    <!--dropdown suggestions -->
    <button
      nz-button
      nzSize="default"
      nzType="primary"
      (click)="search()"
      class="search-button">
      Search
    </button>
  </div>
  <nz-table
    class="main-table-grid"
    #Formtable
    [nzData]="listForms"
    [nzPageIndex]="requestData.page"
    nzShowSizeChanger
    [nzPageSizeOptions]="[10, 20, 50, 100]"
    [nzScroll]="{ y: '550px' }">
    <thead>
      <tr>
        <th style="width: 50px">STT</th>
        <th>Tên</th>
        <th>Người tạo</th>
        <th>Thời gian tạo</th>
        <th>Phạm vi sử dụng</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let data of Formtable.data"
        [ngClass]="{ 'highlight': currentForm && currentForm.id == data.id }"
        (click)="selectForm(data, data.name)">
        <td>{{ data.stt }}</td>
        <td>{{ data.name }}</td>
        <td>{{ data.creator }}</td>
        <td>{{ data.createddate }}</td>
        <td>{{ data.groupName }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>
