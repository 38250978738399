import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { SubclinicalorderService } from 'src/app/services/subclinicalorder.service';
import { SubClinicalVisitService } from 'src/app/services/subcliniccal-service';
import { Constant } from 'src/app/share/constants/constant.class';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { AppState } from '../../app-state/app-state';
import { Store } from '@ngrx/store';
import { AppConfigService } from 'src/app-config.service';

@Component({
  selector: 'app-subclinical-tree-add',
  templateUrl: './subclinical-tree-add.component.html',
  styleUrls: ['./subclinical-tree-add.component.scss'],
})
export class SubclinicalTreeAddComponent implements OnInit, OnDestroy {
  @Input() isVisible = false;
  @Input() visitId = '';
  @Input() getHISServicePrice = false;

  private _rosIdSelected = '';
  @Input()
  get rosIdSelected() {
    return this._rosIdSelected;
  }
  set rosIdSelected(value) {
    this._rosIdSelected = value;
    this.setSelectedNode();
  }
  private _subClinicalVisit = [];
  @Input()
  get subClinicalVisit() {
    return this._subClinicalVisit;
  }
  set subClinicalVisit(data) {
    this._subClinicalVisit = data;
    if (data != null) {
      this.lstServiceChecked = data;
      this.totalSubclinical = this.getTotal();
    }
  }

  @Output() cancelModal = new EventEmitter<any>();
  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  @Output() cancelSubclinicalVisit: EventEmitter<any> = new EventEmitter();

  treeSource = [];
  serviceFilter = [];
  serviceSearch = [];
  lstServiceChecked = [];

  textSearch = '';
  curCategoryId = 0;
  isSaving = false;
  selectdNode = {
    id: '',
    name: '',
    data: [],
  };

  selectedGroupId = '';
  groupSub: Subscription;
  totalSubclinical = 0;
  hienGiaDichVuChiDinh = false;
  constructor(
    private modalService: NzModalService,
    private subclinicalorderService: SubclinicalorderService,
    private shareService: ShareService,
    private subClinicalVisitService: SubClinicalVisitService,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    private configService: AppConfigService
  ) {}

  ngOnInit(): void {
    console.log('isVisible', this.isVisible);
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      this.selectedGroupId = data.groupId;
    });
    this.getSubClinicalOrderByCategory();
    this.hienGiaDichVuChiDinh = this.configService.getConfig().hienGiaDichVuChiDinh;
  }
  getSubClinicalOrderByCategory() {
    if (this.treeSource.length > 0) return;
    this.subclinicalorderService.getSubClinicalOrderByCategory().subscribe((res) => {
      if (res.isValid) {
        this.treeSource = res.jsonData;
      }
    });
  }
  handleCancel() {
    this.isVisible = false;
    this.cancelModal.emit();
    this.textSearch = '';
    this.rosIdSelected = '';
    // this.lstServiceChecked = JSON.parse(JSON.stringify(this.subClinicalVisit));
    // this.totalSubclinical = this.getTotal();
  }
  selectNode(category) {
    this.curCategoryId = category.id;
    this.serviceFilter = category.data;

    this.serviceSearch = this.serviceFilter.filter((en) =>
      en.name.toLowerCase().includes(this.textSearch.trim().toLowerCase())
    );
  }
  nodeExpand(node) {
    node.isExpand = !node.isExpand;
  }
  traversalGetNodeSelectedData(list) {
    let lstRet = [];
    if (!this.shareService.checkEmptyArray(list)) {
      list.forEach((en) => {
        if (!this.shareService.checkEmpty(en.data)) {
          lstRet = [...lstRet, ...en.data];
        }
        if (!this.shareService.checkEmpty(en.children)) {
          lstRet = [...lstRet, ...this.traversalGetNodeSelectedData(en.children)];
        }
      });
    }
    return lstRet;
  }
  onSearch() {
    const textSearch = this.textSearch.trim().toLowerCase();
    this.serviceSearch = this.serviceFilter.filter((en) => en.name.toLowerCase().includes(textSearch));
  }
  updateOrder() {
    //const lstCheckedId = this.lstServiceChecked.map((en) => en.id);
    this.isSaving = true;
    var subClinicalOrders = [];
    for (var item of this.lstServiceChecked) {
      //const itemOld = this.lstCheckedInit.find((en) => en.id == item.id);
      if (item.id == '') {
        const request = {
          subClinicalOrderId: item.subClinicalOrderId,
          amount: item.amount,
        };
        subClinicalOrders.push(request);
      }
    }
    const payload = {
      visitId: this.visitId,
      subClinicalOrders: subClinicalOrders,
    };
    this.subClinicalVisitService.addSubClinicalVisit(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật chỉ định thành công!');
        this.isVisible = false;
        this.isSaving = false;
        this.textSearch = '';
        this.rosIdSelected = '';
        this.cancelModal.emit();
        this.refreshData.emit();
      } else {
        this.isSaving = false;
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }
  selectdNodeEvent(category) {
    this.curCategoryId = category.id;
    if (category.id == '0') {
      this.serviceFilter = this.traversalGetNodeSelectedData(this.treeSource);
    } else {
      const arr = category.data == null ? [] : category.data;
      this.serviceFilter = [...arr, ...this.traversalGetNodeSelectedData(category.children)];
    }
    this.serviceSearch = this.serviceFilter.filter((en) =>
      en.name.toLowerCase().includes(this.textSearch.trim().toLowerCase())
    );
  }
  setSelectedNode() {
    this.serviceFilter = [];
    this.serviceSearch = [];

    this.treeSource.forEach((en) => {
      if (en.id == this.rosIdSelected) {
        en.isExpand = true;
        this.serviceFilter = this.traversalGetNodeSelectedData(en.children);
        this.serviceSearch = this.serviceFilter;
        return;
      } else {
        en.isExpand = false;
      }
    });
  }
  addNewItem(item) {
    item.status = 0;
    let oldItem = this.lstServiceChecked.find((en) => en.subClinicalOrderId == item.id && en.status == item.status);
    item.selected = true;
    if (oldItem != null) {
      //oldItem.amount += 1;
    } else {
      let newItem = {
        subClinicalOrderId: item.id,
        name: item.name,
        canEdit: true,
        status: 0,
        statusString: 'Mới tạo',
        amount: 1,
        id: '',
        onlyIncreasing: false,
        isAdded: false,
        disabled: false,
      };
      this.totalSubclinical += 1;
      this.lstServiceChecked.push(newItem);
    }

  }
  removeData(item) {
    // Mới tạo
    if (item.id == '') {
      if (item.amount == 1) {
        let oldIndex = this.lstServiceChecked.findIndex((en) => en.id == item.id && en.subClinicalOrderId == item.subClinicalOrderId);
        if (oldIndex != -1) {
          this.lstServiceChecked.splice(oldIndex, 1);
        }
      } else item.amount -= 1;
      this.totalSubclinical -= 1;
    } else {
      this.cancelSubclinicalVisit.emit(item);
    }

    console.log(this.serviceSearch, item);
    const searchItem = this.serviceSearch.find(en => en.id === item.subClinicalOrderId);
    if (searchItem) {
      searchItem.selected = false;
    }
  }
  getTotal() {
    let total = 0;
    this.lstServiceChecked.forEach((en) => {
      total += en.amount;
    });
    return total;
  }
  ngOnDestroy() {
    this.groupSub.unsubscribe();
  }
}
