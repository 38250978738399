<!-- <div class="title-wrapper">
  <p class="page-title">Quản trị danh mục chỉ định</p>
</div>  -->
<div class="content">
  <div class="content-body">
    <div class="content-head">
      <div class="box-search d-flex">
        <input nz-input type="text" placeholder="Tìm kiếm theo mã chỉ định, tên, mã chỉ định theo hệ thống khác..."
          [(ngModel)]="dataSearch.keyword" (keyup.enter)="loadSubOrder()"  style="width:140px" />
          <nz-select
          nzAllowClear
          nzPlaceHolder="Trạng thái"
          [(ngModel)]="dataSearch.isActive"
          (ngModelChange)="loadSubOrder()"
          style="width: 140px"
          class="ml-10">
          <nz-option nzLabel="Tất cả trạng thái" [nzValue]="null"></nz-option>
          <nz-option nzLabel="Enable" [nzValue]="true"></nz-option>
          <nz-option nzLabel="Disable" [nzValue]="false"></nz-option>
        </nz-select>
        <button nz-button class="ml-2" (click)="clearSearch()">
          <i nz-icon nzType="close" nzTheme="outline"></i>
        </button>
        <button nz-button class="ml-2" (click)="loadSubOrder()">
          <i nz-icon nzType="search" nzTheme="outline"></i>
        </button> 
      </div>
      <div class="btn-action">
        <button class="btn-ex btn-export" nz-button (click)="exportFile()">
          <i nz-icon nzType="export" nzTheme="outline"></i>Export
        </button>
        <button class="btn-ex btn-import" nz-button (click)="openImport()">
          <i nz-icon nzType="import" nzTheme="outline"></i>Import
        </button>
        <button class="mg-r-10 btn-green" nz-button (click)="showModal('them')">
          <i nz-icon nzType="plus"></i>Thêm mới
        </button>
        <button [disabled]="selectedRowIndex == emptyId" class="mg-r-10" nz-button nzType="primary"
          (click)="showModal(dataForm)">
          <i nz-icon nzType="form"></i>Sửa
        </button>
        <!-- <button [disabled]="selectedRowIndex == emptyId" class="" nz-button nzType="danger" (click)="deleteSubOrder()"><i nz-icon nzType="close"></i>Xoá</button> -->
      </div>
    </div>
    <div class="import-file" *ngIf="isImport">
      <div class="mg-bt-20">
        <button nz-button class="mg-r-15" (click)="
            downloadFile(
              '/assets/download/subclinical_order_sample.xlsx',
              'subclinical_order_sample.xlsx'
            )
          ">
          <i nz-icon nzType="download" nzTheme="outline"></i>DownLoad Sample
        </button>
        <input id="fileOpen" type="file" multiple="false" accept=".xlsx, .xls, .csv" (click)="clearValue($event)"
          (change)="onFileChange($event)" />
      </div>
      <nz-table #importContact [nzData]="importContacts" [nzShowPagination]="false" nzShowSizeChanger>
        <thead>
          <tr>
            <th nzWidth="60px">STT</th>
            <th>Mã chỉ định*</th>
            <th>Mã chỉ định map với hệ thống khác</th>
            <th>Tên chỉ định*</th>
            <th>Loại chỉ định*</th>
            <th>OT Codes*</th>
            <th>Modality*</th>
            <th>Category Id*</th>
            <th>Giá tiền dịch vụ</th>
            <th>Giá tiền bảo hiểm</th>
            <th>Trạng thái</th>
          </tr>
        </thead>
        <tbody>
          <tr [ngClass]="statusData[i] !== '' ? 'error-data' : ''" *ngFor="let d of importContact.data; index as i">
            <td>{{ i + 1 }}</td>
            <td>{{ d.code || '#NAME?' }}</td>
            <td>{{ d.listCodeMapSystem }}</td>
            <td>{{ d.name || '#NAME?' }}</td>
            <td>{{ d.type }}</td>
            <td>{{ d.otCodes }}</td>
            <td>{{ d.modality }}</td>
            <td>{{ d.categoryId || '#NAME?' }}</td>
            <td>{{ d.serviceCost }}</td>
            <td>{{ d.insuranceCost }}</td>
            <td [innerHtml]="statusData[i]"></td>
          </tr>
        </tbody>
      </nz-table>
      <button nz-button class="mg-t-20" (click)="mergeData()">
        Import Data
      </button>
    </div>

    <nz-table #fixedTable [nzData]="listOfDisplayData" nzSize="small" [nzShowPagination]="true"
      [nzScroll]="{ y: 'calc(100vh - 270px)' }" nzShowSizeChanger [nzPageSize]="pageSize" [(nzPageIndex)]="pageIndex"
      [nzPageSizeOptions]="[10, 20, 50, 100]">
      <thead>
        <tr>
          <th nzWidth="60px">STT</th>
          <th nzWidth="50px" nzLeft></th>
          <th>Mã chỉ định</th>
          <th>Mã chỉ định map với hệ thống khác</th>
          <th>Tên chỉ định</th>
          <th>Loại chỉ định</th>
          <th>OT Codes</th>
          <th>Modality</th>
          <th nzShowFilter [nzFilters]="filterRosName" (nzFilterChange)="filterByROSName($event)"
            [nzFilterMultiple]="true">
            ROS
          </th>
          <th>Category Name</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fixedTable.data; let i = index" [ngClass]="{ 'highlight': selectedRowIndex == data.id }"
          (click)="rowSelected(data)">
          <td>
            {{ fixedTable.nzPageSize * (fixedTable.nzPageIndex - 1) + i + 1 }}
          </td>
          <td (click)="updateStatus(data)" nzLeft>
            <i *ngIf="data.isActive" nz-icon nzType="check-circle" [nzTheme]="'twotone'"
              [nzTwotoneColor]="'#52c41a'"></i>
            <i *ngIf="!data.isActive" nz-icon nzType="stop" [nzTheme]="'twotone'" [nzTwotoneColor]="'#ff0000'"></i>
          </td>
          <td>{{ data.code }}</td>
          <td>
            <div *ngFor="let en of data.listCodeMapSystem">
              {{ en.otherSystem }}: {{ en.otherSubClinicalCode }}
            </div>
          </td>
          <td>{{ data.name }}</td>
          <td>{{ toStringType(data.type) }}</td>
          <td>{{ data.otCodes }}</td>
          <td>{{ data.modality }}</td>
          <td>{{ data.ros }}</td>
          <td>{{ data.rosCateName }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<!-- popup them, sua -->
<nz-modal nzClassName="modal-admin" nzWidth="600px" [(nzVisible)]="isVisible" nzTitle="Thông tin chỉ định"
  nzDraggable="true" nzMaskClosable="false" (nzClosable)="(true)" (nzOnCancel)="handleCancel()">
  <div class="mgb-10">
    <label>Mã chỉ định <span class="red-dot">*</span></label>
    <input nz-input type="text" maxlength="50" [(ngModel)]="dataForm.code" placeholder="Nhập mã chỉ định" />
  </div>

  <div class="mgb-10">
    <label>Tên chỉ định <span class="red-dot">*</span></label>
    <input nz-input type="text" [(ngModel)]="dataForm.name" placeholder="Nhập tên chỉ định" />
  </div>

  <div>
    <!-- <div style="display: flex; margin: 0px -15px;">
      <label class="col-md-10 mg-r-10">Mã chỉ định map với hệ thống khác</label>
    </div> -->
    <div style="position: relative">
      <label>Mã chỉ định map với hệ thống khác</label>
      <span style="position: absolute; right: 20px">
        <i title="Thêm mã chỉ định" nz-icon nzType="plus-circle" nzTheme="outline" (click)="addCodeMap()"></i>
      </span>
    </div>

    <div class="other-system">
      <div class="item-system row" *ngFor="let en of listCodeMapSystem; index as i">
        <div class="col-md-6">
          <nz-select [(ngModel)]="en.otherSystem" nzPlaceHolder="Chọn hệ thống">
            <nz-option nzCustomContent *ngFor="let item of systemCodeDataSource" [nzValue]="item.sysCode"
              [nzLabel]="item.sysCode">
              <div>{{ item.sysCode }} - {{ item.sysName }}</div>
            </nz-option>
          </nz-select>
        </div>
        <div class="col-md-6 btn-sub">
          <input [(ngModel)]="en.otherSubClinicalCode" nz-input placeholder="Nhập mã tương ứng" />
          <i *ngIf="listCodeMapSystem.length > 1" title="Xóa mã chỉ định" nz-icon nzType="minus-circle"
            nzTheme="outline" (click)="removeCodeMap(i)"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="mgb-10 row">
    <div class="col-md-6">
      <label>Loại chỉ định <span class="red-dot">*</span></label>
      <nz-select [(ngModel)]="dataForm.type" nzPlaceHolder="Chọn loại chỉ định">
        <nz-option [nzValue]="1" nzLabel="Xét nghiệm"></nz-option>
        <nz-option [nzValue]="2" nzLabel="CĐHA"></nz-option>
        <nz-option [nzValue]="3" nzLabel="Giải phẫu bệnh"></nz-option>
        <nz-option [nzValue]="5" nzLabel="Thủ thuật"></nz-option>
        <nz-option [nzValue]="4" nzLabel="Loại khác"></nz-option>
      </nz-select>
    </div>
    <div class="col-md-6" *ngIf="dataForm.type == 1">
      <label>OT Code <span class="red-dot">*</span></label>
      <nz-select nzShowSearch nzServerSearch [(ngModel)]="otCodeSearch" nzPlaceHolder="Nhập OT Code"
        (nzOnSearch)="loadObservationTypeCode($event)" (ngModelChange)="selectOTCode($event)">
        <nz-option *ngFor="let item of observationType" nzCustomContent [nzValue]="item.otCode">
          <div>{{ item.otCode }} - {{ item.name }}</div>
        </nz-option>
      </nz-select>
      <div class="list-box" *ngIf="dataForm.otCodes.length > 0">
        <span *ngFor="let name of dataForm.otCodes" class="item-box">{{ name }}
          <i nz-icon nzType="close" nzTheme="outline" (click)="removeItemOtCode(name)"></i>
        </span>
      </div>
    </div>
    <div class="col-md-6" *ngIf="dataForm.type == 2 || dataForm.type == 3">
      <label>Modality <span class="red-dot">*</span></label>
      <nz-select [(ngModel)]="dataForm.modality" nzPlaceHolder="Chọn Modality">
        <nz-option nzValue="CT" nzLabel="CT">CT-cắt lớp vi tính</nz-option>
        <nz-option nzValue="MR" nzLabel="MR">MR-cộng hưởng từ</nz-option>
        <nz-option nzValue="DX" nzLabel="DX">DX-XQuang</nz-option>
        <nz-option nzValue="ES" nzLabel="ES">ES-Nội soi</nz-option>
        <nz-option nzValue="US" nzLabel="US">US-Siêu âm</nz-option>
        <nz-option nzValue="WSI" nzLabel="WSI">WSI</nz-option>
        <nz-option nzValue="PX" nzLabel="PX">X-Quang răng toàn cảnh</nz-option>
        <nz-option nzValue="MG" nzLabel="MG">Mammography(XQuang tuyến vú)</nz-option>
        <nz-option nzValue="MRI" nzLabel="MRI">Cộng hưởng từ</nz-option>
        <nz-option nzValue="ECG" nzLabel="ECG">Điện tim</nz-option>
        <nz-option nzValue="RESP" nzLabel="RESP">Đo chức năng hô hấp</nz-option>
        <nz-option nzValue="EEG" nzLabel="EEG">Điện não</nz-option>
        <nz-option nzValue="EMG" nzLabel="EMG">Điện cơ</nz-option>
        <nz-option nzValue="EPS" nzLabel="EPS">Điện gắng sức</nz-option>
        <nz-option nzValue="DSA" nzLabel="DSA">Chụp mạch xóa nền</nz-option>
        <nz-option nzValue="PT" nzLabel="EMG">PET CT</nz-option>
        <nz-option nzValue="ENT" nzLabel="ENT">Nội soi TMH</nz-option>
        <nz-option nzValue="BM" nzLabel="BM">Đo loãng xương</nz-option>
      </nz-select>
    </div>
  </div>

  <div class="dpl-flx row">
    <div class="col-md-6">
      <label>ROS <span class="red-dot">*</span></label>
      <nz-select [(ngModel)]="dataForm.rosid" name="rosid" (ngModelChange)="loadRosCateGroup($event, 1)"
        nzPlaceHolder="Chọn ROS">
        <nz-option *ngFor="let data of rosData" [nzValue]="data.id" [nzLabel]="data.name"></nz-option>
      </nz-select>
    </div>

    <div class="col-md-6">
      <label>ROS Category <span class="red-dot">*</span></label>
      <nz-select [(ngModel)]="dataForm.rosCategoryId" name="rosCategoryId" nzPlaceHolder="Chọn ROS Category">
        <nz-option *ngFor="let data of rosCategoryDataSource" [nzValue]="data.id" [nzLabel]="data.name"></nz-option>
      </nz-select>
    </div>
  </div>

  <div class="dpl-flx row">
    <div class="col-md-6">
      <label>Giá tiền dịch vụ</label>
      <input nz-input type="number" min="0" nzPlaceHolder="VND" [(ngModel)]="dataForm.serviceCost" />
    </div>
    <div class="col-md-6">
      <label>Giá tiền bảo hiểm</label>
      <input nz-input type="number" min="0" nzPlaceHolder="VND" [(ngModel)]="dataForm.insuranceCost" />
    </div>
  </div>

  <div *nzModalFooter>
    <button *ngIf="selectedRowIndex != emptyId" nz-button nzType="primary" (click)="updateSubOrder(dataForm)">
      Sửa
    </button>
    <button *ngIf="selectedRowIndex == emptyId" nz-button nzType="primary" (click)="addSubclinicalOrder(dataForm)">
      Thêm
    </button>
    <button nz-button nzType="default" (click)="handleCancel()">Huỷ</button>
  </div>
</nz-modal>
