<div class="left_menu customScrollbar">
  <form nz-form [nzLayout]="'horizontal'" [formGroup]="formInfoForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="name" nzRequired
        >Form</nz-form-label
      >
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Bạn chưa nhập tên Form!">
        <input nz-input formControlName="name" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="desc">Mô tả</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <input nz-input formControlName="desc" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="ros" nzRequired
        >Ros</nz-form-label
      >
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-select
          nzPlaceHolder="Chọn Ros"
          formControlName="ros"
          nzErrorTip="Bạn chưa chọn Ros!"
          (ngModelChange)="chooseRos()">
          <nz-option
            *ngFor="let ros of ross"
            [nzValue]="ros.id"
            [nzLabel]="ros.name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="ros" nzRequired
        >Loại Ros</nz-form-label
      >
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-select
          nzPlaceHolder="Chọn Ros Category"
          formControlName="rosCategory"
          nzErrorTip="Bạn chưa chọn Ros!">
          <nz-option
            *ngFor="let ros of rosCategorys"
            [nzValue]="ros.id"
            [nzLabel]="ros.name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="formScope"
        >Phạm vi sử dụng</nz-form-label
      >
      <nz-form-control [nzSm]="12" [nzXs]="24">
        <nz-radio-group formControlName="formScope">
          <label
            *ngFor="let item of lstFormScopeCurrent"
            nz-radio
            [nzValue]="item.value"
            [nzDisabled]="formMinScope <= item.scope"
            >{{ item.name }}</label
          >
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
  </form>

  <nz-tabset class="tabset-default">
    <nz-tab [nzTitle]="observationTypeTitle" (nzClick)="clearSearch()">
      <div class="listOption">
        <div class="options customScrollbar">
          <div class="searchInput">
            <div class="input-group">
              <input
                [(ngModel)]="search_KW_OT"
                type="text"
                class="form-control search"
                (ngModelChange)="search_KW_OTChange()"
                placeholder="Tìm kiếm..." />
              <span class="input-group-btn">
                <a href="#" class="btn submit">
                  <i class="icon-magnifier"></i>
                </a>
              </span>
            </div>
          </div>
          <div id="obserList" class="options-contents customScrollbar">
            <nz-spin [nzSpinning]="isSpinningSearchOT">
              <div *ngFor="let item of lstObservationType">
                <app-observation-type-item
                  [obt]="item"
                  (selectItem)="add_OT_Item($event)"
                  [type]="true">
                </app-observation-type-item>
              </div>
            </nz-spin>
          </div>
        </div>
      </div>

      <ng-template #observationTypeTitle>
        <div title="Observation Type">
          <i class="observation-type-icon-sm"></i>
        </div>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="commonInfoTitle" (nzClick)="clearSearch()">
      <div class="listOption">
        <div class="options customScrollbar">
          <div class="searchInput">
            <div class="input-group">
              <input
                [(ngModel)]="search_KW_CI"
                type="text"
                class="form-control search"
                (ngModelChange)="search_KW_CIChange()"
                placeholder="Tìm kiếm..." />
              <span class="input-group-btn">
                <a href="#" class="btn submit">
                  <i class="icon-magnifier"></i>
                </a>
              </span>
            </div>
          </div>
          <div id="commonInfo" class="options-contents customScrollbar">
            <div *ngFor="let item of lstFilterCI">
              <app-observation-type-item
                [obt]="item"
                (selectItem)="addCommonInfo($event)"
                [type]="false">
              </app-observation-type-item>
            </div>
          </div>
        </div>
      </div>
      <ng-template #commonInfoTitle>
        <div title="Common Info">
          <i class="common-info-icon-sm"></i>
        </div>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="OTMostRecentTitle" (nzClick)="clearSearch()">
      <div class="listOption">
        <div class="options customScrollbar">
          <div class="searchInput">
            <div class="input-group">
              <input
                [(ngModel)]="search_KW_OT_Recent"
                type="text"
                class="form-control search"
                (ngModelChange)="search_KW_OT_RecentChange()"
                placeholder="Tìm kiếm..." />
              <span class="input-group-btn">
                <a href="#" class="btn submit">
                  <i class="icon-magnifier"></i>
                </a>
              </span>
            </div>
          </div>
          <div class="options-contents customScrollbar">
            <nz-spin [nzSpinning]="isSpinningSearchRecentOT">
              <div *ngFor="let item of lstObservationTypeRecent">
                <app-observation-type-item
                  [obt]="item"
                  (selectItem)="add_OT_Recent($event)"
                  [type]="true">
                </app-observation-type-item>
              </div>
            </nz-spin>
          </div>
        </div>
      </div>
      <ng-template #OTMostRecentTitle>
        <div title="Observation Type gần nhất">
          <i
            style="font-size: 24px"
            nz-icon
            nzType="field-time"
            nzTheme="outline"></i>
        </div>
      </ng-template>
    </nz-tab>
  </nz-tabset>
</div>
