import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VisitLogService } from 'src/app/services/visit-log.service';

@Component({
  selector: 'app-visit-log',
  templateUrl: './visit-log.component.html',
  styleUrls: ['./visit-log.component.scss'],
})
export class VisitLogComponent implements OnInit {
  @Input() visit;

  _isVisible: boolean;
  @Input() set isVisible(val: boolean) {
    this._isVisible = val;
    if (val) {
      this.getVisitLog();
    }
  }

  get isVisible(): boolean {
    return this._isVisible;
  }
  isMobile: boolean;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  lstLogs = [];
  constructor(private visitLogService: VisitLogService) {
    this.isMobile = window.innerWidth < 540;
  }

  ngOnInit() {}

  getVisitLog() {
    this.visitLogService.getLog(this.visit.id).subscribe((res) => {
      this.lstLogs = res;
      console.log(this.lstLogs);
      console.log(this.visit.id);
    });
  }

  closePopUp() {
    this.closeModal.emit();
  }
}
