<input
  nz-input
  [(ngModel)]="_value"
  class="mgr15"
  id="txtFullName"
  type="text"
  autocomplete="off"
  [placeholder]="searchItem.label"
  (keydown.enter)="seachNow()"
  (ngModelChange)="onModelChange($event)" />

<!-- (keydown.enter)="searchListPatient()"  -->
