import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { Constant } from '../share/constants/constant.class';
import { UrlConstant } from '../share/constants/url.class';
@Injectable({
  providedIn: 'root',
})
export class PrescriptionService extends BaseService {
  getPrescription(id: string) {
    //get đơn thuốc
    return this.get(UrlConstant.GET_PRESCRIPTION + id);
  }

  getPrescriptionByVisit(visitId: string) {
    //get list đơn thuốc trong visit
    return this.get(UrlConstant.GET_PRESCRIPTION_BY_VISIT + visitId);
  }

  getAllPrescription(patientId: string) {
    //get đơn thuốc
    return this.get(UrlConstant.GET_LIST_PRESCRIPTION + patientId);
  }

  addPrescription(data) {
    //thêm đơn thuốc
    return this.post(UrlConstant.ADD_PRESCRIPTION, data);
  }

  addMedication(data) {
    //thêm thuốc
    return this.post(UrlConstant.ADD_MEDICATION_PRESCRIPTION, data);
  }

  exportPrescription(id: string) {
    //xuất đơn thuốc
    return this.get(UrlConstant.EXPORT_MEDICATION_PRESCRIPTION + id);
  }

  updatePrescription(data) {
    //sửa thuốc
    return this.post(UrlConstant.UPDATE_PRESCRIPTION, data);
  }

  deletePrescription(prescriptionId) {
    return this.post(UrlConstant.DELETE_PRESCRIPTION + prescriptionId, null);
  }

  exportPDFPrescription(data) {
    return this.post(UrlConstant.EXPORT_PRESCRIPTION, data);
  }

  getSharePrescription(token) {
    return this.get(UrlConstant.SHARE_PRESCRIPTION + token);
  }
  getShareLinkPrescription(id) {
    return this.get(UrlConstant.SHARELINK_PRESCRIPTION + id);
  }

  savePrescription(payload: any) {
    return this.post(UrlConstant.SAVE_PRESCRIPTION, payload);
  }

  saveGeneralInfo(payload: any) {
    return this.post(UrlConstant.SAVE_PRESCRIPTION_GENERAL_INFO, payload);
  }

  getListPrescriptionSample(groupId: string) {
    return this.get(UrlConstant.GET_PRESCRIPTION_SAMPLE + groupId);
  }

  createPrescriptionSample(groupId: string, payload: any) {
    return this.post(UrlConstant.CREATE_PRESCRIPTION_SAMPLE + groupId, payload);
  }

  savePrescriptionSample(sampleId: string, payload: any) {
    return this.post(UrlConstant.SAVE_PRESCRIPTION_SAMPLE + sampleId, payload);
  }

  addFromSample(prescriptionId: string, sampleId: string) {
    return this.post(UrlConstant.ADD_FROM_SAMPLE + prescriptionId, '', {
      sampleId,
    });
  }

  removePrescriptionSample(id: string) {
    return this.delete(UrlConstant.REMOVE_PRESCRIPTION_SAMPLE + id, '');
  }

  approvePrescription(payload: any) {
    return this.post(UrlConstant.APPROVE_PRESCRIPTION, payload);
  }

  queryPrescription(payload: any) {
    return this.post(UrlConstant.QUERYING_PRESCRIPTION, payload);
  }

  denyPrescription(payload: any) {
    return this.post(UrlConstant.DENY_PRESCRIPTION, payload);
  }

  unapprovePrescription(payload: any) {
    return this.post(UrlConstant.UNAPPROVE_PRESCRIPTION, payload);
  }
  toStatusPrescriptionText(status: number, isUseApprove = false): string {
    if (isUseApprove) {
      switch (status) {
        case Constant.PrescriptionStatus.New:
          return 'Mới tạo';
        case Constant.PrescriptionStatus.Saved:
          return 'Chưa xử lý';
        case Constant.PrescriptionStatus.Approved:
          return 'Đã duyệt';
        case Constant.PrescriptionStatus.Querying:
          return 'Đang yêu cầu kiểm tra';
        case Constant.PrescriptionStatus.Denied:
          return 'Đã từ chối duyệt';
        case Constant.PrescriptionStatus.Exported:
          return 'Đã xuất thuốc';
        case Constant.PrescriptionStatus.Received:
          return 'Đã nhận hàng';
        default:
          return '';
      }
    } else {
      if (
        status == Constant.PrescriptionStatus.New ||
        (status >= Constant.PrescriptionStatus.Saved &&
          status < Constant.PrescriptionStatus.Exported)
      ) {
        return 'Đã lưu';
      } else if (status >= Constant.PrescriptionStatus.Exported) {
        return 'Đã xuất thuốc';
      } else {
        return '';
      }
    }
  }
  toPaymentMethodText(status: number): string {
    switch (status) {
      case 0:
        return 'Tiền mặt';
      case 1:
        return 'Chuyển khoản';
      case 2:
        return 'Miễn phí';
      default:
        return '';
    }
  }
  getMedicationName(name, content, activeIngredient,configGroupPrescriptionIsShowActiveIngredient) {
    let rs = '';
    if (!this.checkEmpty(name)) {
      rs = name;
    }
    if (!this.checkEmpty(content)) {
      if(!configGroupPrescriptionIsShowActiveIngredient){
        rs += ' ' + content;
      }
    }
    if (!this.checkEmpty(activeIngredient)) {
      if(configGroupPrescriptionIsShowActiveIngredient){
        rs += ' (' + activeIngredient + ')';
      }
    }
    return rs;
  }
  checkEmpty(value) {
    if (value == null || value == '' || value == 'undefined') {
      return true;
    }
    return false;
  }
}
