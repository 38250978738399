import {
  Component,
  OnInit,
  Injectable,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    try {
      const date = value.split(this.DELIMITER);
      const day = parseInt(date[0], 10);
      const month = parseInt(date[1], 10);
      const year = parseInt(date[2], 10);

      const existDate = new Date(year, month, day);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : '';
  }
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class DatePickerComponent implements OnInit {
  date: Date = new Date();
  @Output() dateChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  dateChangeEvent(value) {
    this.dateChange.emit(value);
  }
}
