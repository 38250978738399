import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {
  _data: any;
  isLink = false;

  @Input() set data(val: any) {
    if (val) {
      this._data = val;
      if (this.isUrlValid(val.message)) {
        this.isLink = true;
      }
    }
  }

  get data() {
    const val = this._data;
    return val;
  }

  constructor() {}

  ngOnInit() {}

  isUrlValid(userInput) {
    // console.log(userInput);
    const res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) {return false;}
    else {return true;}
  }
}
