<div *ngIf="isVisible | async as visible">
  <nz-modal [nzVisible]="visible" [nzTitle]="showSelectPatient ? 'Thêm ca khám' : 'Thêm bệnh nhân'" nzWidth="770px"
    nzMaskClosable="false" (nzOnCancel)="handleCancel()">
    <div class="add-visit-content">
      <div class="vs-title">Thông tin bệnh nhân</div>
      <div class="vs-content patient-form" [formGroup]="patientForm">
        <div class="row">
          <div class="col-4 switch-status">
            <label>Họ tên<span class="red-dot"> *</span></label>
            <div>
              <input #keywordPatient minLength="2" formControlName="fullName" nz-input
                (input)="onSearchPatient(keywordPatient.value)"
                (clickOutside)="outsidePatientName(keywordPatient.value)" [nzAutocomplete]="auto" />
              <nz-autocomplete #auto nzOverlayClassName="search-patient-dropdown">
                <nz-auto-option *ngFor="let item of searchPatientFilter" [nzValue]="item.fullName"
                  (click)="selectSearchPatient(item)">
                  <span>
                    <i class="font-size"><b style="font-size: 15px;">{{ item.fullName }}</b> - {{ item.sex | genderText
                      }} - {{ item.dobString }}</i> <br>
                    <i class="font-size">CCCD: {{ item.idNumber }} - SĐT: {{ item.phoneNo }}</i> <br>
                    <i class="font-size">{{ item.address }}</i>
                  </span>
                  <!-- <span></span> -->
                </nz-auto-option>
              </nz-autocomplete>
            </div>
            <div class="icon-switch" (click)="switchStatus()">
              <i nz-icon title="Clear dữ liệu" nzType="close-circle" nzTheme="outline"></i>
            </div>
          </div>
          <div class="col-4">
            <label>Giới tính<span class="red-dot"> *</span></label>
            <nz-select class="w-100" [nzPlaceHolder]="'Chọn giới tính'" formControlName="sex">
              <nz-option [nzValue]="1" nzLabel="Nam"></nz-option>
              <nz-option [nzValue]="0" nzLabel="Nữ"></nz-option>
              <nz-option [nzValue]="2" nzLabel="Không xác định"></nz-option>
            </nz-select>
          </div>
          <div class="col-4">
            <label>Ngày sinh<span class="red-dot"> *</span></label>
            <!-- <nz-date-picker class="w-100" [nzPlaceHolder]="'DD/MM/YYYY'" [nzFormat]="dateFormat"
                        [nzDisabledDate]="disabledDateAfterToday" formControlName="dob">
                    </nz-date-picker> -->
            <app-msc-date-picker [disabled]="isSelectOldPatient" formControlName="dob"></app-msc-date-picker>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="row">
              <div class="col-6">
                <label>Mã BN</label>
                <input nz-input type="text" formControlName="code" />
              </div>
              <div class="col-6">
                <label>SĐT<span class="red-dot"> *</span></label>
                <div *ngIf="submitted && patientFormData.phoneNo.errors && patientFormData.phoneNo.errors.pattern"
                  class="error-message">
                  không đúng định dạng
                </div>
                <input nz-input type="text" formControlName="phoneNo" placeholder="0xxxxxxxxx" />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label>Địa chỉ</label>
                <textarea nz-input formControlName="address"></textarea>
              </div>
            </div>
          </div>
          <div class="col-4">
            <nz-upload class="avatar-upload" [nzAction]="uploadUrl" nzName="FormFile" nzListType="picture-card"
              [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload" [nzHeaders]="header"
              (nzChange)="handleChange($event)" [nzDisabled]="isSelectOldPatient">
              <div *ngIf="!avatarUrl">
                <i class="picture" nz-icon nzType="picture" nzTheme="outline"></i>
                <div class="fs-12">Ảnh đại diện</div>
              </div>
              <img class="PatientAvatar" *ngIf="avatarUrl" [src]="avatarUrl"
                style="width: 100%; height: 100%; object-fit: cover" />
            </nz-upload>
          </div>
        </div>
        <div class="mg-bt-15">
          <div (click)="openMoreInfo()" class="more-info">
            <label class="mg-r-15">Thông tin thêm</label>
            <i nz-icon [nzType]="visibleMoreInfo ? 'caret-down' : 'caret-up'" nzTheme="outline"></i>
          </div>
          <div *ngIf="visibleMoreInfo" class="more-content">
            <div class="row">
              <div class="col-4">
                <label>CMND/CCCD</label>
                <input nz-input type="text" formControlName="idNumber" />
                <div *ngIf="submitted && patientFormData.idNumber.errors" class="error-message">
                  không đúng định dạng
                </div>
              </div>
              <div class="col-4">
                <label>Email</label>
                <input nz-input type="text" formControlName="email" />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label>Thẻ BHYT</label>
                <input nz-input type="text" formControlName="bhytCode" />
              </div>
              <div class="col-4">
                <label>Từ ngày</label>
                <nz-date-picker class="w-100" [nzPlaceHolder]="'DD/MM/YYYY'" [nzFormat]="dateFormat"
                  formControlName="validDate" [nzDisabledDate]="disabledDateAfterToday">
                </nz-date-picker>
              </div>
              <div class="col-4">
                <label>Đến ngày</label>
                <nz-date-picker class="w-100" [nzPlaceHolder]="'DD/MM/YYYY'" [nzFormat]="dateFormat"
                  formControlName="expireDate" [nzDisabledDate]="disabledDateBeforeToday">
                </nz-date-picker>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="mg-r-15">File đính kèm</label>
                <nz-upload *ngIf="!isSelectOldPatient" [nzAction]="uploadFileUrl" [nzHeaders]="header"
                  [nzBeforeUpload]="beforeUploadFile" nzName="FormFile" [nzLimit]="7" [nzFileList]="listImgs"
                  [nzRemove]="handleRemoveImg" (nzChange)="fileChange($event)">
                  <img src="/assets/image/upload-cloud.svg" />
                </nz-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vs-title">Thông tin ca khám</div>
      <div class="vs-content visit-form" [formGroup]="visitForm">
        <div class="row">
          <div class="col-4">
            <label>Ngày, giờ khám<span class="red-dot"> *</span></label>
            <nz-date-picker class="w-100" formControlName="visitDate" [nzPlaceHolder]="'DD/MM/YYYY HH:mm'"
              [nzFormat]="dateFormat" nzShowTime="true">
            </nz-date-picker>
          </div>
          <div class="col-4">
            <label>Loại khám<span class="red-dot"> *</span></label>
            <nz-select class="w-100" formControlName="type" [nzPlaceHolder]="'Chọn yêu cầu khám'">
              <nz-option nzValue="2" nzLabel="Điều trị"></nz-option>
              <nz-option nzValue="1" nzLabel="Khám bệnh"></nz-option>
              <nz-option nzValue="3" nzLabel="Can thiệp"></nz-option>
            </nz-select>
          </div>
          <div class="col-4">
            <label>BS Khám<span class="red-dot"> *</span></label>
            <nz-select class="w-100" formControlName="doctorId" [nzPlaceHolder]="'Chọn bác sĩ khám'">
              <nz-option *ngFor="let item of listDoctorInCase" [nzLabel]="item.fullName" [nzValue]="item.id">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div>
          <label>Lý do khám<span class="red-dot"> *</span></label>
          <textarea nz-input formControlName="reason"></textarea>
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <button nz-button class="btn-primary-medium" [nzLoading]="isLoading" (click)="handleOk()">Thêm</button>
      <button nz-button class="btn-cancel-medium" (click)="handleCancel()">Đóng</button>
    </div>
  </nz-modal>
</div>