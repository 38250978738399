import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Contact } from 'src/app/models/designated-list.class';
import { CodeMapSystem } from '../../../../models/designated-list.class';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NotificationService } from '../../../../services/notification.service';
import { SystemListsService } from '../../../../services/systemLists.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { ShareService } from 'src/app/services/share.service';
import { ManagementMethodService } from 'src/app/services/managementMethod';

@Component({
  selector: 'app-management-method',
  templateUrl: './management-method.component.html',
  styleUrls: ['./management-method.component.scss'],
})
export class ManagementMethodComponent implements OnInit {
  [x: string]: any;
  inputValue;
  dataForm: any = {};
  emptyId = '000000000000000000000000';
  selectedRowIndex = this.emptyId;
  isVisible = false;
  listOfDisplayData = [];
  modalityDataSource = [];
  modalitys = {
    modality: '',
    descriptions: '',
  };
  public filterSysList = new Map();
  filterSysName = [];
  importContacts: Contact[] = [];
  exportContacts: Contact[] = [];
  statusData: string[] = [];
  checkVaid = true;
  listDataExport: {
    'Mã hệ thống': '';
    'Mô tả': '';
  }[] = [];
  observationType: any;
  listOfOption: any[] = [];
  codeMap = 'LOINC';
  listCodeMapSystem: CodeMapSystem[] = [];
  pageSize = 20;
  searchKey = '';

  constructor(
    private modalService: NzModalService,
    private notificationService: NotificationService,
    private managementMethodService: ManagementMethodService,
    private ShareService: ShareService
  ) {}

  ngOnInit() {
    this.loadModalityLists();
  }
  // Hiển thị thông tin mã hệ thống
  showModal(dataForm): void {
    this.isVisible = true;
    if (dataForm == 'them') {
      this.selectedRowIndex = this.emptyId;
      this.dataForm = {
        modality: '',
        descriptions: '',
      };
    }
  }
  // Khai báo lệnh hủy
  handleCancel(): void {
    this.isVisible = false;
  }
  // Xóa danh sách bất kỳ: ko thấy yêu cầu xóa

  // Thêm hệ thống mã vào bảng
  addModalityLists(datasend) {
    if (datasend.modality == '') {
      return this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Chưa nhập đủ dữ liệu yêu cầu!'
      );
    }
    // datasend.sysName = datasend.sysName.trim();
    datasend.modality = datasend.modality.trim();
    this.managementMethodService.saveModality(datasend).subscribe((data) => {
      console.log('hk', data)
      if (data) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Thành công'
        );
        this.loadModalityLists();
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Trùng mã hệ thống!'
        );
      }
    });
    this.selectedRowIndex = this.emptyId;
  }

  // Tải dữ liệu vào hệ thống
  loadModalityLists() {
    this.managementMethodService.getModalityAll().subscribe((data) => {
      if (data) {
        this.isVisible = false;
        this.modalityDataSource = data;
        this.listOfDisplayData = data;
        console.log()
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          data.errors[0].errorMessage
        );
      }
    });
  }
  // Sửa hệ thống mã đã chọn
  editModalityLists(datasend) {
    datasend.modality = datasend.modality.trim();
    if (datasend.modality == '') {
      return this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Chưa nhập đủ dữ liệu, không thể thay đổi.'
      );
    }
    if (datasend.turn == false) {
      datasend.status = 0;
    } else {
      datasend.status = 1;
    }

    this.modalitys = {
      modality: datasend.modality,
      descriptions: datasend.description,
    };
    this.managementMethodService
      .updateModality(this.selectedRowIndex, this.dataForm)
      .subscribe((data) => {
        if (data) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Thay đổi thành công'
          );
          this.isVisible = false;
          this.loadModalityLists();
          this.selectedRowIndex = this.emptyId;
        } else {
          for (const error of data) {
            this.notificationService.showNotification(
              Constant.NOTIFY_TYPE.ERROR,
              'Không thể thay đổi.'
            );
          }
        }
      });
  }

  RowSelected(data) {
    this.curData = data;
    this.selectedRowIndex = data.id;
    this.dataForm = {
      id: this.selectedRowIndex,
      modality: data.modality,
      description: data.description,
    };
  }
}
