import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-add-input-item',
  templateUrl: './add-input-item.component.html',
  styleUrls: ['./add-input-item.component.scss'],
})
export class AddInputItemComponent implements OnInit {
  _obt: any;
  _viewTypeEnable: boolean;
  @Input() isVisible: boolean;
  @Output() isVisibleChange = new EventEmitter();
  @Output() addItem = new EventEmitter();
  @Input() set obt(value: any) {
    this._obt = value;
    this._viewTypeEnable =
      this.obt &&
      (this.obt.datatypeText == Constant.DATATYPE.SINGLECHOICE ||
        this.obt.datatypeText == Constant.DATATYPE.MUILTICHOICE);
  }

  get obt(): any {
    return this._obt;
  }

  validateForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.validateForm = this.fb.group({
      editable: ['true', [Validators.required]],
      // content: null,
      viewtype: ['selection'],
    });
  }

  ngOnInit() {}

  handleOk(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
    if (this._viewTypeEnable) {
      this.addItem.emit(Object.assign({}, this.validateForm.value));
    } else {
      this.addItem.emit(
        Object.assign(this.validateForm.value, { viewtype: 'input' })
      );
    }
    this.validateForm.reset({ editable: 'true', viewtype: 'selection' });
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
    this.validateForm.reset({ editable: 'true', viewtype: 'selection' });
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
  }
}
