<!-- <nz-modal [(nzVisible)]="isVisibleChart" nzTitle="Biểu đồ" [nzWidth]="900" (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleCancel()"> -->
<app-m-modal
  [modalId]="'tab-chart'"
  [isVisible]="isVisibleChart"
  (closeModal)="handleCancel()"
  [mMask]="true"
  [width]="900"
  [minWidth]="600"
  [height]="500"
  [minHeight]="400"
  [isResiable]="true"
  [mFooter]="false">
  <ng-template #mModalTitle>
    <div class="header-title">Biểu đồ</div>
  </ng-template>
  <ng-template #mModalContent>
    <div class="modal-container">
      <div class="tab-left" *ngIf="!isMobile">
        <ul>
          <li class="tab-name" *ngFor="let tab of datas">
            <div [innerHtml]="tab.name"></div>
            <!-- <div>({{tab.unit}})</div> -->
          </li>
        </ul>
      </div>
      <div class="tab-chart {{ isMobile ? 'm-width' : '' }}">
        <div class="tab-div" *ngFor="let tab of datas">
          <div class="title" style="color: #333">
            <div style="font-weight: bold" [innerHtml]="tab.name"></div>
            <div *ngIf="tab.dataType == 4">
              <span>Giá trị tương ứng: 0: Không, 1: Có</span>
            </div>
            <div *ngIf="tab.dataType == 9">
              <span>Giá trị tương ứng: </span>
              <span>{{ tab.lstValueSingleChoice }}</span>
            </div>
            <a (click)="toggleChart(tab.id)"
              ><i
                nz-icon
                [nzType]="tab.visible ? 'caret-up' : 'caret-down'"
                nzTheme="outline"></i
            ></a>
          </div>
          <div
            class="c-{{ tab.visible }} chart-child"
            id="chartDiv{{ tab.id }}"></div>
        </div>
      </div>
    </div>
    <!-- </nz-modal> -->
  </ng-template>
</app-m-modal>
