<nz-modal
  [(nzVisible)]="isVisible"
  nzWidth="500px"
  nzTitle="Tạo toa thuốc mẫu từ toa hiện tại"
  nzMaskClosable="false"
  (nzOnCancel)="closeCreateSample()">
  <label>Nhập tên toa thuốc mẫu:</label>
  <input
    nz-input
    [(ngModel)]="newSampleName"
    (ngModelChange)="checkNameEmpty()"
    type="text" />
  <div class="div-error" *ngIf="errorEmptyName != ''">{{ errorEmptyName }}</div>
  <div *nzModalFooter>
    <button class="btn-cancel-small" nz-button (click)="closeCreateSample()">
      Hủy
    </button>
    <button class="btn-primary-small" nz-button (click)="createSample()">
      Tạo mẫu
    </button>
  </div>
</nz-modal>
