import { Component, Input, OnInit } from '@angular/core';
import { FormElement } from 'src/app/models/form-model/form.class';

@Component({
  selector: 'app-checkbox-element',
  templateUrl: './checkbox-element.component.html',
  styleUrls: ['./checkbox-element.component.scss'],
})
export class CheckboxElementComponent implements OnInit {
  @Input() data: FormElement;
  constructor() {}

  ngOnInit(): void {
    console.log(this.data);
  }
}
