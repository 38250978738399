import { Component, Input, OnInit } from '@angular/core';
import {
  FormElement,
  ObservationType,
} from 'src/app/models/form-model/form.class';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-input-element',
  templateUrl: './input-element.component.html',
  styleUrls: ['./input-element.component.scss'],
})
export class InputElementComponent implements OnInit {
  @Input() data: FormElement;

  constructor() {}

  ngOnInit() {
    // console.log(this.data);
  }
}
