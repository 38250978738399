<div class="row-login">
  <div class="title-login">
    <div>Đăng nhập</div>
    <span *ngIf="isNormal"
      >Vui lòng đăng nhập tài khoản <span style="color: #fd3c3c">PMR</span> của
      bạn</span
    >
    <span *ngIf="!isNormal"
      >Vui lòng đăng nhập tài khoản <span style="color: #fd3c3c">PMR</span> của
      bạn để tham gia nhóm được mời</span
    >
  </div>
  <div class="login-form">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control nzErrorTip="Bạn chưa nhập tên đăng nhập">
          <nz-input-group class="input-text" nzPrefixIcon="user">
            <input
              type="text"
              style="background-color: #f8f9fc; margin-left: 15px"
              nz-input
              formControlName="username"
              placeholder="Tên đăng nhập" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control nzErrorTip="Bạn chưa nhập mật khẩu">
          <nz-input-group class="input-text" nzPrefixIcon="lock">
            <input
              type="password"
              style="background-color: #f8f9fc; margin-left: 15px"
              nz-input
              formControlName="password"
              placeholder="Mật khẩu" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <div class="row">
        <div class="col-md-6">
          <input type="radio" />
          <span style="margin-left: 5px">Ghi nhớ mật khẩu</span>
        </div>
        <div class="col-md-6">
          <a style="float: right" href="/forgot_password">Quên mật khẩu</a>
        </div>
      </div>
      <div *ngIf="messageError">
        <div class="alert alert-danger" role="alert">
          {{ messageError }}
        </div>
      </div>
      <nz-form-item>
        <nz-form-control style="justify-content: center; text-align: center">
          <button
            nz-button
            class="login-form-button"
            [nzType]="'primary'"
            [nzLoading]="isLoading">
            Đăng nhập
          </button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>
