import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/share/constants/constant.class';
@Component({
  templateUrl: './loss-password.component.html',
  styleUrls: ['./loss-password.component.scss'],
})
export class LossPasswordComponent implements OnInit {
  urlChangePwd: string;
  messageError: string;
  step = 1;
  code: string;
  email = '';
  updatePasswordForm: FormGroup;
  submittedPassword = false;
  passwordVisible: boolean;
  repeatpasswordVisible: boolean;
  submittedEmail = false;
  errorMessageEmail = '';
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private notification: NotificationService,
    private fb: FormBuilder
  ) {
    this.updatePasswordForm = this.fb.group(
      {
        code: [null],
        email: [null],
        password: [
          null,
          [Validators.required, Validators.pattern(/(^\S.{6,}(\S)$)/)],
        ],
        repassword: [
          null,
          [Validators.required, Validators.pattern(/(^\S.{6,}(\S)$)/)],
        ],
      },
      { validators: this.checkPasswords }
    );
  }

  get formData() {
    return this.updatePasswordForm.controls;
  }

  checkPasswords: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    if (group != undefined) {
      const pass = group.get('password').value;
      const repassword = group.get('repassword').value;
      return pass === repassword ? null : { confirm: true, error: true };
    }
  };

  ngOnInit(): void {
    this.code = this.route.snapshot.queryParamMap.get('code');
    this.email = this.route.snapshot.queryParamMap.get('email');
    if (this.code !== null && this.email !== null) {
      this.step = 3;
    }
  }

  sendEmail() {
    this.submittedEmail = true;
    if (!this.checkEmailValid()) {return;}

    const payload = { email: this.email };
    this.authService.identify(payload, this.email).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.step = 2;
          } else {
            this.notification.showNotification(
              Constant.NOTIFY_TYPE.WARRING,
              res.errors[0].errorMessage
            );
          }
        }
      },
      (error) => {
        console.log('lỗi', error);
        this.messageError = error;
      }
    );
  }

  checkEmailValid() {
    if (this.submittedEmail) {
      if (this.email === '' || this.email === null) {
        this.errorMessageEmail = 'Email không được để trống!';
        return false;
      }
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(this.email).toLowerCase())) {
        this.errorMessageEmail = 'Email không hợp lệ!';
        return false;
      }
      this.errorMessageEmail = '';
      return true;
    }
  }
  changePass() {
    this.submittedPassword = true;
    const form = this.updatePasswordForm.value;
    if (this.updatePasswordForm.valid) {
      const payload = {
        code: this.code,
        email: this.email,
        password: form.password,
        repassword: form.repassword,
      };
      this.authService.lossPass(payload).subscribe(
        (res) => {
          if (res !== null) {
            if (res.isValid) {
              this.notification.showNotification(
                Constant.NOTIFY_TYPE.SUCCESS,
                'Đổi mật khẩu thành công!'
              );
              this.router.navigate(['/login']);
            } else {
              this.notification.showNotification(
                Constant.NOTIFY_TYPE.ERROR,
                res.errors[0].errorMessage
              );
            }
          }
        },
        (error) => {
          console.log('lỗi', error);
          this.messageError = error;
        }
      );
    }
  }

  reSendEmail() {
    this.step = 1;
  }
}
