<div class="phieudl-tab">
  <div class="phieudl-header">
    <span class="phieudl-name">Phiếu dữ liệu</span>
  </div>
  <div class="phieudl-container">
    <div #formContentData class="phieudl-content {{ disabledClass }}">
      <app-form
        #form
        [data]="{ visitId: visitId, formId: formId, recordId: recordId }"
        id="phieudl-content-id"
        class="record-content"></app-form>
    </div>
  </div>
  <div class="phieudl-footer">
    <button
      nz-button
      nzType="primary"
      class="mg-r-15"
      nzDanger
      (click)="backToListPatient()">
      Trở về
    </button>
    <button nz-button nzType="primary" (click)="save()">Hoàn tất</button>
  </div>
</div>
