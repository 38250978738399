import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Visits } from 'src/app/models/listVisit.class';
import { VisitService } from 'src/app/services/visit.service';

@Component({
  selector: 'app-visit-history',
  templateUrl: './visit-history.component.html',
  styleUrls: ['./visit-history.component.scss'],
})
export class VisitHistoryComponent implements OnInit {
  _isVisible = false;
  @Input()
  get isVisible(): boolean {
    return this._isVisible;
  }
  set isVisible(value: boolean) {
    this._isVisible = value;
    if (value) {
      this.getVisitHistoryModal(
        this.pageSize,
        (this.pageIndex - 1) * this.pageSize
      );
    }
  }
  @Input() visit: any;

  @Output() isVisibleChange = new EventEmitter<any>();
  @Output() loadVisit = new EventEmitter<any>();

  pageSize = 10;
  pageIndex = 1;
  totalHistory = 0;
  lstHistoryModal = [];
  expandSet = new Set<number>();

  constructor(private visitService: VisitService) {}

  ngOnInit(): void {}

  cancelHistory() {
    this.isVisible = false;
    this.isVisibleChange.emit();
  }

  getVisitHistoryModal(take: number, skip: number) {
    const dataHis = { patientId: this.visit.patientId, take, skip };
    this.visitService.lstVisitHis(dataHis).subscribe((data) => {
      this.lstHistoryModal = data.source;
      this.totalHistory = data.itemCount;
    });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.expandSet.clear();
    this.pageIndex = pageIndex;
    this.getVisitHistoryModal(
      this.pageSize,
      (this.pageIndex - 1) * this.pageSize
    );
  }

  onExpandChange(id: number): void {
    if (this.expandSet.has(id)) {
      this.expandSet.delete(id);
    } else {
      this.visitService.getMasterNoteByVisitId(id).subscribe((res) => {
        if (res.isValid) {
          this.lstHistoryModal.forEach((item) => {
            if (item.id === id) {
              item.notes = res.jsonData;
            }
          });
          this.expandSet.add(id);
        }
      });
    }
  }

  openVisit(visitId) {
    this.isVisible = false;
    this.isVisibleChange.emit();
    if (this.visit.id != visitId) {this.loadVisit.emit(visitId);}
  }
}
