import { TextareaResizeComponent } from './component/textarea-resize/textarea-resize.component';
import { MViewPdfComponent } from './component/m-view-pdf/m-view-pdf.component';
import { ObservationDataTypeNamePipe } from './pipe/observation-data-type-name.pipe';
import { MModalComponent } from './component/m-modal/m-modal.component';
import { ImageCropperComponent } from './component/image-cropper/image-cropper.component';
import { CustomModalMovableDirective } from './../directive/custom-modal-movable.directive';
import { ModalMovableDirective } from './../directive/modal-movable.directive';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { I18nModule } from '../i18n/i18n.module';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { FilterPipe } from './pipe/search-name.pipe';
import { CountArrayPipe } from './pipe/count-item.pipe';
import { NzUploadModule } from 'ng-zorro-antd/upload';

import { NzNotificationModule } from 'ng-zorro-antd/notification';

import { NzFormModule } from 'ng-zorro-antd/form';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { from } from 'rxjs';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { ColorPickerModule } from 'ngx-color-picker';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { GenderToTextPipe } from './pipe/gender-to-text.pipe';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FilterMessageNamePipe } from './pipe/search-message-name.pipe';
import { LastMessageTimePipe } from './pipe/message-time.pipe';
import { CurrencyFormatPipe } from './pipe/currency-format.pipe';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerComponent } from './component/date-picker/date-picker.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MouseMiddleClickDirective } from '../directive/mouse-middle-click.directive';
import { TargetBlankDirective } from '../directive/target-blank.directive';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { ReadMoreDirective } from '../directive/read-more.directive';
import { QRCodeModule } from 'angularx-qrcode';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { ResizableModule } from 'angular-resizable-element';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DropFileDirective } from '../directive/drop-file.directive';
import { NgPipesModule } from 'ngx-pipes';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { TabDirective } from '../directive/tab.directive';
import { NzListModule } from 'ng-zorro-antd/list';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClickOutsideDirective } from '../directive/click-outside.directive';
import { ThumbnailSrcPipe } from './pipe/thumbnail-src.pipe';
import { ClickStopPropagationDirective } from '../directive/click-stop-propagation.directive';
import { MyDatePickerModule } from 'projects/mydatepicker/src/lib/mydatepicker.module';
import { SafePipe } from './pipe/safe.pipe';
import { NzResizableModule } from 'ng-zorro-antd/resizable';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TreeSelectComponent } from './component/tree-select/tree-select.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { AmountCalcPipe, DiscountStrPipe } from './pipe/amount-calc.pipe';
import { ProtocalFormatPipe } from './pipe/protocal-format.pipe';
import { AgeStringFromDOBPipe } from './pipe/age-text.pipe';
import { DayMonthYearStringPipe } from './pipe/prescrription.pipe';
import { EnterForDirective } from '../directive/enter-for.directive';
import { EnterTabDirective } from '../directive/enter-tab.directive';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import {NgxBarcodeModule} from 'ngx-barcode';
import { PrescrriptionDay } from './pipe/prescrription-day.pipe';
import { NzMessageModule } from 'ng-zorro-antd/message';

@NgModule({
  imports: [
    FormsModule,
    NzTreeModule,
    NzTreeSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    NzTableModule,
    NzPaginationModule,
    NzInputModule,
    NzInputNumberModule,
    NzButtonModule,
    NzSelectModule,
    NzModalModule,
    NzIconModule,
    NzLayoutModule,
    ScrollingModule,
    NzCollapseModule,
    NzNotificationModule,
    NzFormModule,
    NzResultModule,
    NzTagModule,
    NzDropDownModule,
    NzDatePickerModule,
    ScrollingModule,
    DragDropModule,
    I18nModule,
    NzAutocompleteModule,
    DpDatePickerModule,
    NzTabsModule,
    NzAvatarModule,
    NzUploadModule,
    NzMenuModule,
    NzTimePickerModule,
    NzRadioModule,
    ColorPickerModule,
    NzCarouselModule,
    NzCardModule,
    NzCheckboxModule,
    NzToolTipModule,
    CKEditorModule,
    NzSwitchModule,
    NzSpinModule,
    NgbModule,
    QRCodeModule,
    NzPopoverModule,
    CommonModule,
    ResizableModule,
    ImageCropperModule,
    NzPopconfirmModule,
    NgPipesModule,
    NzDividerModule,
    PdfViewerModule,
    NzListModule,
    MyDatePickerModule,
    NzResizableModule,
    AutocompleteLibModule,
    NgxCurrencyModule,
    NzCalendarModule,
    NzEmptyModule,
    NgxBarcodeModule,
    NzMessageModule
  ],
  exports: [
    CommonModule,
    NzTreeModule,
    NzTreeSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NzTableModule,
    NzPaginationModule,
    NzInputModule,
    NzInputNumberModule,
    NzButtonModule,
    NzSelectModule,
    NzModalModule,
    NzIconModule,
    NzCollapseModule,
    NzNotificationModule,
    NzFormModule,
    NzResultModule,
    NzTagModule,
    NzDropDownModule,
    NzDatePickerModule,
    ScrollingModule,
    DragDropModule,
    NzAutocompleteModule,
    FilterPipe,
    GenderToTextPipe,
    CountArrayPipe,
    ObservationDataTypeNamePipe,
    SafeHtmlPipe,
    CurrencyFormatPipe,
    DayMonthYearStringPipe,
    DpDatePickerModule,
    NzLayoutModule,
    NzTabsModule,
    NzAvatarModule,
    NzUploadModule,
    NzMenuModule,
    NzRadioModule,
    ColorPickerModule,
    NzCarouselModule,
    NzTimePickerModule,
    NzCardModule,
    NzCheckboxModule,
    NzToolTipModule,
    CKEditorModule,
    NzSwitchModule,
    FilterMessageNamePipe,
    LastMessageTimePipe,
    ThumbnailSrcPipe,
    AmountCalcPipe,
    ProtocalFormatPipe,
    DiscountStrPipe,
    AgeStringFromDOBPipe,
    NzSpinModule,
    NgbModule,
    FontAwesomeModule,
    MouseMiddleClickDirective,
    TargetBlankDirective,
    ReadMoreDirective,
    TabDirective,
    EnterForDirective,
    EnterTabDirective,
    DatePickerComponent,
    QRCodeModule,
    ModalMovableDirective,
    CustomModalMovableDirective,
    NzPopoverModule,
    ResizableModule,
    ImageCropperModule,
    NzPopconfirmModule,
    DropFileDirective,
    ImageCropperComponent,
    NgPipesModule,
    NzDividerModule,
    NzListModule,
    PdfViewerModule,
    MModalComponent,
    MViewPdfComponent,
    ClickOutsideDirective,
    MyDatePickerModule,
    ClickStopPropagationDirective,
    SafePipe,
    TextareaResizeComponent,
    AutocompleteLibModule,
    TreeSelectComponent,
    NgxCurrencyModule,
    NzCalendarModule,
    NzEmptyModule,
    NgxBarcodeModule,
    PrescrriptionDay,
    NzMessageModule
  ],
  declarations: [
    MModalComponent,
    MViewPdfComponent,
    DatePickerComponent,
    FilterPipe,
    CountArrayPipe,
    ObservationDataTypeNamePipe,
    SafeHtmlPipe,
    CurrencyFormatPipe,
    DayMonthYearStringPipe,
    GenderToTextPipe,
    FilterMessageNamePipe,
    LastMessageTimePipe,
    ThumbnailSrcPipe,
    AmountCalcPipe,
    ProtocalFormatPipe,
    DiscountStrPipe,
    AgeStringFromDOBPipe,
    TargetBlankDirective,
    ReadMoreDirective,
    TabDirective,
    EnterForDirective,
    EnterTabDirective,
    ModalMovableDirective,
    CustomModalMovableDirective,
    ImageCropperComponent,
    DropFileDirective,
    MouseMiddleClickDirective,
    ClickOutsideDirective,
    ClickStopPropagationDirective,
    SafePipe,
    TextareaResizeComponent,
    TreeSelectComponent,
    PrescrriptionDay
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
