import { Component, Input, OnInit } from '@angular/core';
import { FormElement } from 'src/app/models/form-model/form.class';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-dropdown-element',
  templateUrl: './dropdown-element.component.html',
  styleUrls: ['./dropdown-element.component.scss'],
})
export class DropdownElementComponent implements OnInit {
  _data: any;
  _type: string;

  @Input() set data(value: FormElement) {
    this._data = value;
    this._type =
      this._data &&
      this._data.observationType.datatypeText == Constant.DATATYPE.SINGLECHOICE
        ? 'default'
        : 'multiple';
  }

  get data(): FormElement {
    return this._data;
  }

  constructor() {}

  ngOnInit() {}
}
