<div class="row visit-list" *ngFor="let visit of listVisitGroupByDoctorId">
  <h4 class="w-100 text-center">
    <img style="margin-bottom: 3px; margin-right: 10px" src="assets/image/DPicon/doctor-fill-white.svg" />{{
    visit[0]?.doctorName
    }}<span style="margin-left: 10px">-</span><span style="margin-left: 10px; font-weight: bold">{{ visit.length }}
      CK</span>
  </h4>
  <div class="col-12">
    <div class="month-table">
      <table class="w-100">
        <thead>
          <tr>
            <th *ngFor="let day of daysOfWeek">{{ day }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let week of weeks">
            <td *ngFor="let day of week">
              <div>{{ day }}</div>
              <div class="text-left d-flex flex-wrap w-100 " [innerHTML]="day | monthViewPipe: month:year:visit"
                (mouseover)="onVisitItemHover($event)" (click)="onVisitItemClick($event)"
                (mouseleave)="onVisitItemLeave($event)"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>