import { Component, OnInit } from '@angular/core';
import { Constant } from 'src/app/share/constants/constant.class';
import { SubclinicalorderService } from '../../../../services/subclinicalorder.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NotificationService } from 'src/app/services/notification.service';
import { RosCategoryService } from '../../../../services/rosCategory.service';
import { RosService } from '../../../../services/ros-service';
import { Contact, CodeMapSystem } from 'src/app/models/designated-list.class';
import { ExcelService } from 'src/app/services/excel.service';
import { ObservationTypeService } from '../../../../services/obserType-service';
import { SystemListsService } from 'src/app//services/systemLists.service';

import { ShareService } from 'src/app/services/share.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subclinicalorder',
  templateUrl: './subclinicalorder.component.html',
  styleUrls: ['./subclinicalorder.component.scss'],
})
export class SubclinicalorderComponent implements OnInit {
  emptyId = '000000000000000000000000';
  selectedRowIndex = this.emptyId;
  subOrder;
  rosCateData;
  rosCate;
  rosData;
  ros_id;
  isVisible;
  visible = false;
  searchValue = '';
  rosCategoryDataSource: any;
  public listOfDisplayData;
  searchNameList = [];
  searchRosList = [];
  pageIndex = 1;
  pageSize = 20;
  total = 100;
  emptyDataForm: any = {
    id: '000000000000000000000000',
    code: '',
    codeMap: '',
    type: 0,
    name: '',
    ros: '',
    rosCategoryId: '',
    rosCateName: '',
    otCodes: [],
    modality: null,
    rosid: '',
    serviceCost: null,
    insuranceCost: null,
    listCodeMapSystem: [],
  };
  dataForm: any;
  inputValue;
  public filterRosList = new Map();
  filterRosName = [];
  importContacts: Contact[] = [];
  exportContacts: Contact[] = [];
  isImport = false;
  isExport = false;
  statusData: string[] = [];
  checkVaid = true;
  dataSearch = {
    keyword: '',
    isActive: null,
  };
  listDataExport: {
    'Mã chỉ định': '';
    'Mã chỉ định map với hệ thống khác': '';
    'Tên chỉ định': '';
    'Loại chỉ định': '';
    'OT Codes': '';
    'Modality': '';
    'ROS': '';
    'Category Name': '';
    'Giá tiền dịch vụ': '';
    'Giá tiền bảo hiểm': '';
  }[] = [];
  observationType: any;
  codeMap = '';
  listCodeMapSystem: CodeMapSystem[] = [];
  systemCodeDataSource: any;
  public sortMap = {
    name: null,
    ros: null,
  };
  sortName = null;
  sortValue = null;
  currentData: any;
  otCodeSearch = '';
  modality = Constant.MODALITY;
  subClinicalType = Constant.SubClinicalType;

  constructor(
    private modalService: NzModalService,
    private notificationService: NotificationService,
    private subclinicalorderService: SubclinicalorderService,
    private rosCategoryService: RosCategoryService,
    private rosService: RosService,
    private excelService: ExcelService,
    private observationService: ObservationTypeService,
    private systemListsService: SystemListsService,
    private shareService: ShareService,
    private route: ActivatedRoute
  ) {
    this.checkAndLoadSubOrder();
  }

  ngOnInit(): void {
    this.loadSubOrder();
    this.loadRosCategory();
    this.loadRos();
    //this.loadObservationTypeCode();
    this.dataForm = JSON.parse(JSON.stringify(this.emptyDataForm));
    this.getListSystemCode();
  }

  loadRosCategory() {
    this.rosCategoryService.getRosCategoryAll().subscribe((thisRos) => {
      this.rosCateData = thisRos;
    });
  }

  loadRos() {
    this.rosService.getRos().subscribe((data) => {
      this.rosData = data;
      this.filterRosName = [];
      for (const en of data) {
        const objRosAdd = { text: en.name, value: en.name };
        this.filterRosName.push(objRosAdd);
      }
    });
  }

  filterByROSName(searchRosList: string[]): void {
    this.searchRosList = searchRosList;
    const filterFunc = (item) =>
      (this.searchRosList.length ? this.searchRosList.some((ros) => item.ros.indexOf(ros) !== -1) : true) &&
      (this.searchNameList.length ? this.searchNameList.some((name) => item.name.indexOf(name) !== -1) : true);

    this.listOfDisplayData = [...this.subOrder];
    this.visible = false;

    const data = this.listOfDisplayData.filter((item) => filterFunc(item));
    this.listOfDisplayData = data;
    this.listOfDisplayData = this.listOfDisplayData.filter((item) => item.name.indexOf(this.searchValue) !== -1);
  }
  checkAndLoadSubOrder() {
    const code = this.route.snapshot.queryParamMap.get('Code');
    if (code) {
      this.loadSubOrderFromHisCode(code);
    }
  }
  loadSubOrderFromHisCode(code) {
    this.subclinicalorderService
      .GetFilterAllSubClinicalOrder({
        keyword: code,
        isActive: null,
      })
      .subscribe((data) => {
        let subOrder = data;
        this.rosCategoryService.getRosCategoryAll().subscribe((res) => {
          for (const sub of subOrder) {
            for (const en of res) {
              if (sub.rosCategoryId == en.id) {
                sub.rosCateName = en.name;
                sub.rosid = en.rosid;
                sub.ros = en.ros;
                break;
              } else {
                sub.rosCateName = 'Không tồn tại ROS Category';
              }
            }
          }
          if (subOrder[0]) {
            this.rowSelected(subOrder[0])
            this.showModal(subOrder[0]);
          }
        });
      });
  }
  loadSubOrder() {
    this.subclinicalorderService.GetFilterAllSubClinicalOrder(this.dataSearch).subscribe((data) => {
      this.subOrder = data;
      this.rosCategoryService.getRosCategoryAll().subscribe((res) => {
        for (const sub of this.subOrder) {
          for (const en of res) {
            if (sub.rosCategoryId == en.id) {
              sub.rosCateName = en.name;
              sub.rosid = en.rosid;
              sub.ros = en.ros;
              break;
            } else {
              sub.rosCateName = 'Không tồn tại ROS Category';
            }
          }
        }
        this.listOfDisplayData = [...this.subOrder];
        // this.visible = false;
        // if (this.subOrder && this.subOrder.length === 1) {
        //   this.rowSelected(this.subOrder[0]);
        //   this.showModal(this.currentData);
        // }
      });
    });
  }
  // showModalIfNeeded(): void {
  //   if (this.subOrder && this.subOrder.length === 1) {
  //     this.rowSelected(this.subOrder[0]);
  //     this.showModal(this.currentData);
  //   }
  // }
  clearSearch() {
    this.dataSearch = {
      keyword: '',
      isActive: null,
    };
    this.loadSubOrder();
  }

  searchByNameAndCode(keyword) {
    keyword = keyword.trim();
    if (keyword == '') {
      this.listOfDisplayData = this.subOrder;
    } else {
      this.subclinicalorderService.searchByNameAndCode(keyword).subscribe((res) => {
        if (res.isValid) {
          this.listOfDisplayData = res.jsonData;
          for (const item of this.listOfDisplayData) {
            for (const en of this.rosCateData) {
              if (item.rosCategoryId == en.id) {
                item.rosCateName = en.name;
                item.rosid = en.rosid;
                item.ros = en.ros;
                break;
              }
            }
          }
        }
      });
    }
  }

  showModal(value): void {
    if (value == 'them') {
      this.selectedRowIndex = this.emptyId;
      this.loadDataToModal(this.emptyDataForm);
    } else {
      this.loadRosCateGroup(this.currentData.rosid, 0);
      this.loadDataToModal(this.currentData);
      this.getDataCodeMapSystem(this.currentData);
    }
    if (this.listCodeMapSystem.length == 0) {
      this.addCodeMap();
    }
    this.isVisible = true;
  }

  deleteSubOrder() {
    const id = this.selectedRowIndex;
    if (id == this.emptyId) {
      this.notificationService.showNotification('warning', 'Bạn chưa chọn dòng để xoá!');
    } else {
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn xóa hay không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Huỷ',
        nzOnOk: () => this.deleteItem(id),
      });
    }
  }

  deleteItem(id) {
    this.subclinicalorderService.deleteSubOrder(id).subscribe(
      (data) => {
        this.notificationService.showNotification('success', 'Xoá thành công');
        this.loadSubOrder();
      },
      (error) => {
        this.notificationService.showNotification('error', 'Xóa không thành công');
      }
    );
    this.selectedRowIndex = this.emptyId;
  }

  addSubclinicalOrder(datasend) {
    const d = datasend;
    datasend.name = datasend.name.trim();

    if (datasend.type == 1) datasend.modality = null;
    else if (datasend.type == 2 || datasend.type == 3) datasend.otCodes = [];
    else {
      datasend.modality = null;
      datasend.otCodes = [];
    }

    if (!this.checkValidate(datasend)) {
      return;
    }
    this.rosService.getRos().subscribe((dataros) => {
      this.subclinicalorderService.getSubclinicalOrderAll().subscribe((subdata) => {
        for (const sub of subdata) {
          if (datasend.name == sub.name && datasend.rosCategoryId == sub.rosCategoryId) {
            return this.notificationService.showNotification('error', 'Tên bị trùng khi cùng ROS Category');
          }
        }

        for (const en of dataros) {
          if (datasend.rosid == en.id) {
            datasend.ros = en.name;
          }
        }
        const payload = {
          id: this.selectedRowIndex,
          code: datasend.code,
          type: datasend.type,
          name: datasend.name,
          ros: datasend.ros,
          rosCategoryId: datasend.rosCategoryId,
          rosCateName: datasend.rosCateName,
          otCodes: datasend.otCodes,
          modality: datasend.modality,
          rosid: datasend.rosid,
          serviceCost: datasend.serviceCost,
          insuranceCost: datasend.insuranceCost,
          listCodeMapSystem: this.listCodeMapSystem.filter((en) => en.otherSystem != ''),
        };
        this.subclinicalorderService.addSubclinicalOrder(payload).subscribe((data) => {
          if (data.errors.length > 0) {
            for (const error of data.errors) {
              this.notificationService.showNotification('error', error.errorMessage);
            }
          } else {
            this.notificationService.showNotification('success', 'Thành công');
            this.isVisible = false;
            this.loadSubOrder();
            this.listCodeMapSystem = [];
          }
        });
      });
    });
    this.selectedRowIndex = this.emptyId;
  }

  checkValidate(item) {
    //console.log('checkValidate', item);
    if ((item.serviceCost != null && item.serviceCost < 0) || (item.insuranceCost != null && item.insuranceCost < 0)) {
      this.notificationService.showNotification('error', 'Giá tiền phải là số dương');
      return false;
    }
    if (
      item.code.trim() == '' ||
      item.name.trim() == '' ||
      item.rosid == '' ||
      item.rosCategoryId == '' ||
      item.name.$valid == false ||
      (item.type == 1 && item.otCodes.length == 0) ||
      (item.type == 2 && this.shareService.checkEmpty(item.modality))
    ) {
      this.notificationService.showNotification('error', 'Chưa nhập đủ dữ liệu bắt buộc!');
      return false;
    }
    const newArr = this.listCodeMapSystem.map((en) => en.otherSystem).filter((en) => en != '');
    const duplicate = newArr.some(function (item, idx) {
      return newArr.indexOf(item) != idx;
    });
    if (duplicate) {
      this.notificationService.showNotification('error', 'Trùng tên hệ thống!');
      return false;
    }
    return true;
  }

  updateSubOrder(datasend) {
    if (datasend.type == 1) datasend.modality = null;
    else if (datasend.type == 2 || datasend.type == 3) datasend.otCodes = [];
    else {
      datasend.modality = null;
      datasend.otCodes = [];
    }
    if (!this.checkValidate(datasend)) return;

    let roscategory = this.rosCategoryDataSource.find((en) => en.id == datasend.rosCategoryId);
    const payload = {
      id: datasend.id,
      code: datasend.code,
      type: datasend.type,
      name: datasend.name,
      ros: this.rosData.filter((en) => en.id == datasend.rosid)[0].name,
      rosCategoryId: datasend.rosCategoryId,
      rosCateName: roscategory?.name,
      otCodes: datasend.otCodes,
      modality: datasend.modality,
      rosid: datasend.rosid,
      serviceCost: datasend.serviceCost,
      insuranceCost: datasend.insuranceCost,
      listCodeMapSystem: this.listCodeMapSystem.filter((en) => en.otherSystem != ''),
    };
    this.subclinicalorderService.updateSubclinicalOrder(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification('success', 'Thành công');
        this.isVisible = false;
        this.loadSubOrder();
        this.listCodeMapSystem = [];
        this.selectedRowIndex = this.emptyId;
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    }).unsubscribe;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.listCodeMapSystem = [];
    this.dataForm.otCodes = [];
  }

  rowSelected(data) {
    this.currentData = data;
    this.selectedRowIndex = data.id;
  }

  loadDataToModal(value) {
    const data = JSON.parse(JSON.stringify(value));
    this.dataForm = {
      id: this.selectedRowIndex,
      code: data.code,
      type: data.type,
      name: data.name,
      ros: data.ros,
      rosCategoryId: data.rosCategoryId,
      rosCateName: data.rosCateName,
      otCodes: data.otCodes,
      modality: data.modality,
      rosid: data.rosid,
      serviceCost: data.serviceCost,
      insuranceCost: data.insuranceCost,
      listCodeMapSystem: data.listCodeMapSystem,
    };
  }

  getDataCodeMapSystem(data) {
    if (data.listCodeMapSystem != null) {
      for (const en of data.listCodeMapSystem) {
        const system: CodeMapSystem = {
          otherSystem: en.otherSystem,
          otherSubClinicalCode: en.otherSubClinicalCode,
        };
        if (!this.listCodeMapSystem.some((e) => e.otherSystem == en.otherSystem)) {
          this.listCodeMapSystem.push(system);
        }
      }
    }
  }

  loadRosCateGroup(id, type) {
    //type: 1 onChangeROS, 0: open modal
    this.rosCategoryService.getRosCateGroup(id).subscribe((res) => {
      this.rosCategoryDataSource = res;
    });
    if (type == 1) {
      this.dataForm.rosCategoryId = '';
    }
  }

  loadRosCateById(id) {
    this.rosCategoryService.getRosCateById(id).subscribe((data) => {
      this.rosCate = data;
    });
  }

  formatNumber(n) {
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formatCurrency(type, c) {
    const curency = this.formatNumber(c); //this.currencyPipe.transform(c, 'VND');
    if (type == 'service') {
      this.dataForm.serviceCost = curency;
    } else {
      this.dataForm.insuranceCost = curency;
    }
  }

  async loadObservationTypeCode(event) {
    const payload = {
      search: event,
      category: -1,
      dataType: 0,
      groupId: '',
      fromManagerPage: true,
      page: 1,
      pageSize: 15,
    };
    await this.observationService.getObservationTypeByRoleManager(payload).subscribe((data) => {
      this.observationType = data.source;
    });
  }

  openImport() {
    this.isImport = !this.isImport;
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) {
      this.notificationService.showNotification('error', 'Không thể chọn nhiều file');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const data = <any[]>this.excelService.importFromFile(bstr);

      if (data) {
        const header: string[] = Object.getOwnPropertyNames(new Contact());
        const importedData = data.slice(1);

        this.importContacts = importedData.map((arr) => {
          const obj = {};
          for (let i = 0; i < header.length; i++) {
            const k = header[i];
            obj[k] = arr[i];
          }
          return <Contact>obj;
        });
      }
    };
    reader.readAsBinaryString(target.files[0]);

    setTimeout(() => {
      this.checkDuplicateData(this.importContacts);
    }, 200);
  }

  checkDuplicateData(arr) {
    // arr = this.importContacts
    //tao status tuong ung voi cac obj duoc import
    this.statusData = Array.from({ length: arr.length }, (x) => '');
    const arrIndex = [];

    for (var i = 0; i < arr.length; i++) {
      // check đã tồn tại mã trong HT
      var exist = false;
      this.subOrder.forEach((e) => {
        if (arr[i].code == e.code) {
          exist = true;
        }
      });
      if (exist) {
        this.statusData[i] += 'Đã tồn tại mã chỉ định trong hệ thống </br>';
      }

      // check
      let isLackOfObligatedData = false;
      if (
        arr[i].code == undefined ||
        arr[i].name == undefined ||
        arr[i].categoryId == undefined ||
        arr[i].type == undefined
      ) {
        isLackOfObligatedData = true;
      }
      if (
        (arr[i].code != undefined && arr[i].code.trim() == '') ||
        (arr[i].name != undefined && arr[i].name.trim() == '') ||
        (arr[i].type != undefined && arr[i].type.trim() == '') ||
        (arr[i].categoryId != undefined && arr[i].categoryId.trim() == '')
      ) {
        isLackOfObligatedData = true;
      }
      if (isLackOfObligatedData) {
        this.statusData[i] += 'Thiếu dữ liệu bắt buộc' + '</br>';
      }

      // check exist Category Id
      const rosInfo = this.rosCateData.find((en) => en.id == arr[i].categoryId); //this.getROSByCategoryId(arr[i].categoryId);
      if (arr[i].categoryId != undefined && rosInfo == undefined) {
        this.statusData[i] += 'Không tồn tại Category Id trong hệ thống' + '</br>';
      }

      // check exist OTCodes
      if (arr[i].otCodes != undefined) {
        const listOTCode = arr[i].otCodes.split(',');
        let OTIsNull = '';
        listOTCode.forEach((value) => {
          const OTValue = this.observationType.find((en) => en.oTcode == value.trim());
          if (OTValue == undefined) {
            OTIsNull += value;
          }
        });
        if (OTIsNull !== '') {
          this.statusData[i] += 'Không tồn tại OTCode ' + OTIsNull + ' trong hệ thống' + '</br>';
        }
      }

      //check loại chỉ định
      const type = this.toNumberType(arr[i].type);
      if (type == 0) {
        this.statusData[i] += 'Không tồn tại loại chỉ định trong hệ thống </br>';
      } else {
        if (type == 1 && arr[i].otCodes == undefined) {
          this.statusData[i] += 'Cần nhập OTCodes khi loại chỉ định là Xét nghiệm </br>';
        }
        //check modality
        if (arr[i].modality == undefined || arr[i].modality == '') {
          if (type == 2 || type == 3) {
            this.statusData[i] += 'Cần nhập Modality khi loại chỉ định là CĐHA hoặc Giải phẫu bệnh </br>';
          }
        } else {
          const modality = this.toNumberModality(arr[i].modality);
          if (modality == 0) {
            this.statusData[i] += 'Không tồn tại Modality trong hệ thống </br>';
          }
        }
      }
      // check price not number
      let isPositive = true;
      if (
        arr[i].serviceCost != undefined &&
        (!Number.isInteger(arr[i].serviceCost) || (Number.isInteger(arr[i].serviceCost) && arr[i].serviceCost < 0))
      ) {
        isPositive = false;
      }
      if (
        arr[i].insuranceCost != undefined &&
        (!Number.isInteger(arr[i].insuranceCost) ||
          (Number.isInteger(arr[i].insuranceCost) && arr[i].insuranceCost < 0))
      ) {
        isPositive = false;
      }
      if (!isPositive) {
        this.statusData[i] += 'Giá tiền phải là số dương' + '</br>';
      }

      // check dupplicate code
      for (var j = i + 1; j < arr.length; j++) {
        arrIndex.forEach((e) => {
          if (j == e) {
            j++;
          }
        });
        if (j < arr.length && arr[i].code == arr[j].code) {
          this.statusData[j] += 'Dữ liệu lỗi do trùng Mã chỉ định với dòng ' + (i + 1) + '</br>';
          arrIndex.push(j);
        }
      }

      // //check codemap in system
      // const lstCodeMap = this.splitCodeMapFromString(arr[i]);
      // lstCodeMap.forEach((en) => {
      //   const e = this.toNumberCodeMap(en.otherSystem);
      //   if (e == 0) {
      //     this.statusData[i] += 'Không tồn tại mã hệ thống' + en.otherSystem + '</br>';
      //   }
      // });
    }
  }

  getROSByCategoryId(id: string) {
    const category = this.rosCateData.find((en) => en.id == id);
    console.log('getROSByCategoryId', id);
    if (category == undefined) console.log('getROSByCategoryId err', this.rosCateData, id);
    const ros = {
      rOS: category.rOS,
      rosCateName: category.name,
    };
    return ros;
  }

  splitCodeMapFromString(item) {
    let listCodeMap = item.listCodeMapSystem == undefined ? [] : item.listCodeMapSystem.replace(/\s/g, '').split(',');
    listCodeMap = listCodeMap.filter((en) => en != '');
    const lstRes: CodeMapSystem[] = [];
    for (const e of listCodeMap) {
      const en = e.split(':');
      const list: CodeMapSystem = {
        otherSystem: en[0],
        otherSubClinicalCode: en[1],
      };
      lstRes.push(list);
    }
    return lstRes;
  }

  mergeData() {
    this.statusData.forEach((e) => {
      if (e != '') {
        this.checkVaid = false;
      }
    });
    if (!this.checkVaid || this.importContacts.length == 0) {
      this.notificationService.showNotification('error', 'Dữ liệu thiếu hoặc lỗi');
    } else {
      const listData = [];
      this.importContacts.forEach((item) => {
        const type = this.toNumberType(item.type);
        // split string list codeMap
        const lstRes = this.splitCodeMapFromString(item);

        const rosInfo = this.getROSByCategoryId(item.categoryId);
        var arr = [];
        if (item.otCodes != undefined && item.otCodes.length > 0 && item.otCodes.indexOf(',') <= 0)
          arr[0] = item.otCodes;
        else {
          item.otCodes = item.otCodes + '';
          console.log('aj', item.otCodes);
          arr = item.otCodes.split(',');
        }
        const data = {
          id: '000000000000000000000000',
          code: item.code,
          name: item.name,
          type,
          modality: type == 1 ? '' : item.modality, //nếu là type=XN thì modality = ""
          rOS: rosInfo.rOS,
          rOSCategoryId: item.categoryId,
          rosCateName: rosInfo.rosCateName,
          otCodes: item.otCodes == undefined || type != 1 ? [] : arr,
          serviceCost: item.serviceCost,
          insuranceCost: item.insuranceCost,
          listCodeMapSystem: lstRes,
        };
        listData.push(data);
      });

      this.subclinicalorderService.addManySubclinicalOrder(listData).subscribe((res) => {
        if (res.isValid) {
          this.isImport = false;
          this.importContacts = [];
          $('#fileOpen').val('');
          this.loadSubOrder();
        }
      });
    }
  }

  toNumberType(str): any {
    return this.subclinicalorderService.toNumberType(str);
  }

  toNumberModality(str): any {
    return this.subclinicalorderService.toNumberModality(str);
  }

  toNumberCodeMap(str): any {
    return this.subclinicalorderService.toNumberCodeMap(str);
  }

  exportFile() {
    this.listDataExport = [];
    const filename = 'Danh sách chỉ định';
    this.listOfDisplayData.forEach((e) => {
      const type = this.toStringType(e.type);

      let strCodeMap: any = '';
      for (const code of e.listCodeMapSystem) {
        strCodeMap += code.otherSystem + ': ' + code.otherSubClinicalCode + ', ';
      }

      strCodeMap = strCodeMap.trim().slice(0, -1); // cut dấu phẩy cuối cùng
      const obj = {
        'Mã chỉ định': e.code,
        'Mã chỉ định map với hệ thống khác': strCodeMap,
        'Tên chỉ định': e.name,
        'Loại chỉ định': type,
        'OT Codes': e.otCodes == null ? '' : e.otCodes.toString(),
        Modality: e.modality,
        ROS: e.ros,
        'Category Name': e.rosCateName,
        'Giá tiền dịch vụ': e.serviceCost,
        'Giá tiền bảo hiểm': e.insuranceCost,
      };
      this.listDataExport.push(obj);
    });
    this.excelService.exportToFile(filename, this.listDataExport);
  }

  downloadFile(url: string, filename: string) {
    this.excelService.downloadFile(url).subscribe(
      (response) => {
        const blob: any = new Blob([response], {
          type: 'text/json; charset=utf-8',
        });
        const url = window.URL.createObjectURL(blob);
        this.shareService.saveLocalFile(url, filename);
      },
      (error) => {
        console.log('Error downloading the file');
        this.notificationService.showNotification('error', 'Error downloading the file');
      }
    );
  }

  clearValue(evt) {
    if (evt.value != '') {
      $('#fileOpen').val('');
    }
  }

  toStringType(number): any {
    return this.subclinicalorderService.toStringType(number);
  }

  toStringModality(number): any {
    return this.subclinicalorderService.toStringModality(number);
  }

  addCodeMap() {
    if (this.listCodeMapSystem.length < this.systemCodeDataSource.length) {
      this.listCodeMapSystem.push({
        otherSystem: '',
        otherSubClinicalCode: '',
      });
      this.systemCodeDataSource.filer;
    }
  }

  removeCodeMap(index) {
    this.listCodeMapSystem.splice(index, 1);
  }

  getListSystemCode() {
    this.systemListsService.getSystemCodeAll().subscribe((res) => {
      if (res.isValid) {
        this.systemCodeDataSource = res.jsonData;
      }
    });
  }

  selectOTCode(name) {
    if (!this.dataForm.otCodes.includes(name)) {
      this.dataForm.otCodes.push(name);
    }
    this.otCodeSearch = '';
  }

  removeItemOtCode(name) {
    this.dataForm.otCodes.splice(this.dataForm.otCodes.indexOf(name), 1);
  }
  updateStatus(data) {
    let type = data.isActive ? 'disable' : 'enable';
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Bạn có muốn <b>' + type + '</b> dịch vụ này không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () => {
        this.subclinicalorderService.updateStatus(this.selectedRowIndex).subscribe((res) => {
          if (res.isValid) {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
            data.isActive = !data.isActive;
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
          }
        });
      },
    });
  }
}
