import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/components/app-state/app-state';
import { Store } from '@ngrx/store';
import { VisitService } from 'src/app/services/visit.service';
import { IlayoutConfig } from 'src/app/models/ilayout-config';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { UpdateRoom } from '../auth/redux/auth.action';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';

const defaultConfig: IlayoutConfig = {
  columns: [
    {
      visible: true,
      size: 25,
      name: 'list-visit',
      columns: [],
    },
    {
      visible: true,
      size: 75,
      name: 'visit-detail',
      columns: [],
    },
  ],
  disabled: false,
};

@Component({
  selector: 'app-list-n-detail-visit',
  templateUrl: './list-n-detail-visit.component.html',
  styleUrls: ['./list-n-detail-visit.component.scss'],
})
export class ListNDetailVisitComponent implements OnInit, OnDestroy {
  _reloadListVisit = false;
  @Input() set reloadListVisit(val: boolean) {
    if (val) {
      this._reloadListVisit = val;
    }
  }
  get reloadListVisit(): boolean {
    return this._reloadListVisit;
  }
  isLoading = false;
  config: IlayoutConfig = null;
  localStorageName = 'small-split-ws';
  //curUserRoomId = '';
  curUserRoomName = '';

  isOpenChangeVisitRoom = false;
  selectedVisit: any = {};
  currentVisit: any = {}; //full visit object
  groupId = '';
  groupSub: Subscription;
  subClinicalVisit = [];
  //disabledActiton = false;

  disabledEditInfo = false;
  //disabledBtnActiton = false;
  // disabledBtnUndoFinished = false;
  // disabledBtnReceived = false;
  // visibleBtnReceived = true;

  currentUserRoomId = '';
  useRoomOrDoctorVisit = false;
  lstRelatedVisit = [];

  constructor(
    private visitService: VisitService,
    private store: Store<AppState>,
    private userprofileService: UserProfileService,
    private shareService: ShareService
  ) {}

  ngOnDestroy(): void {
    this.groupSub.unsubscribe();
  }

  ngOnInit() {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
      } else {
        console.log('groupAuth not isAuthenticated');

        this.groupId = '000000000000000000000000';
      }
    });

    if (localStorage.getItem(this.localStorageName)) {
      this.config = JSON.parse(localStorage.getItem(this.localStorageName));
    } else {
      this.resetConfig();
    }
    this.currentVisit.status = null;
    //this.getUserInfo();
  }

  onDragEnd(
    columnindex: number,
    e: { gutterNum: number; sizes: Array<number> }
  ) {
    // Column dragged
    if (columnindex === -1) {
      // Set size for all visible columns
      this.config.columns
        .filter((c) => c.visible === true)
        .forEach((column, index) => (column.size = e.sizes[index]));
      // this.caculateNoteTabSize();
    }

    this.saveLocalStorage();
  }

  saveLocalStorage() {
    localStorage.setItem(this.localStorageName, JSON.stringify(this.config));
  }

  selectVisit(vsId) {
    if (this.shareService.checkEmpty(vsId)) {
      this.currentVisit = {};
    } else {
      this.isLoading = true;
      this.visitService.getVisit(vsId, this.groupId).subscribe((data) => {
        this.currentVisit = data;
        this.isLoading = false;
      });
    }
  }

  resetConfig() {
    this.config = JSON.parse(JSON.stringify(defaultConfig));
    localStorage.removeItem(this.localStorageName);
  }

  updateCurrentVisitStatus(status: number) {
    this.currentVisit.status = status;
    this.currentVisit = JSON.parse(JSON.stringify(this.currentVisit));
  }

  getUserInfo() {
    this.userprofileService.GetInfo().subscribe((res) => {
      if (res.d?.isValid) {
        this.store.dispatch(
          new UpdateRoom(res.d.jsonData.roomId, res.d.jsonData.roomName)
        );
      }
    });
  }

  onClickChangeRoom() {
    this.isOpenChangeVisitRoom = true;
  }

  getSubClinicalVisit(data) {
    this.subClinicalVisit = data;
  }

  getlstRelatedVisit(data) {
    this.lstRelatedVisit = data;
  }
}
