<nz-table
    #listtable
    class="main-table-grid"
    nzSize="small"
    [nzData]="subClinicalXNs"
    [nzShowPagination]="false"
    (nzCurrentPageDataChange)="currentPageDataChange($event)">
  <thead>
  <tr>
    <th
      nzShowCheckbox
      nzLeft="0px"
      nzWidth="50px"
      [(nzChecked)]="isAllDisplayDataChecked"
      [nzIndeterminate]="isIndeterminate"
      (nzCheckedChange)="checkAllRow($event)"></th>
    <th>Chỉ định</th>
    <th>Trạng thái</th>
    <th>Kết quả</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of listtable.data; let i = index">
    <td
      nzShowCheckbox
      [nzDisabled]="item.resultType == 2"
      [(nzChecked)]="mapOfCheckedId[item.id]"
      (nzCheckedChange)="refreshStatus()"
      nzLeft="0px"
      nzWidth="50px"></td>
    <td>{{ item.name }}</td>
    <td>{{ item.statusString }}</td>
    <td>
      <ng-container *ngIf="!disabledAction">
        <a *ngIf="item.resultType == 1" (click)="viewResult(item)">Xem KQ</a>
        <a *ngIf="item.resultType == 2" (click)="enterResult(item)">Nhập KQ</a>
      </ng-container>
    </td>
  </tr>
  </tbody>
</nz-table>
<!-- chỉ nhập trong dải giá trị của từng chỉ số -->
<!-- ví dụ -->
<!-- [nzMin]="shareService.checkEmptyObj(item.validMinValue) ? -Infinity : item.validMinValue" -->

<div *ngIf="currentTesting">
  <nz-modal
    [nzVisible]="isVisibleEnterResult"
    nzWidth="800px"
    nzTitle="Nhập kết quả xét nghiệm"
    (nzOnCancel)="closeTestingResult()">
    <div class="mg-bt-20">
      Chỉ định: <b>{{ currentTesting.name }}</b>
    </div>
    <div enter-tab class="list-observation">
      <div *ngFor="let item of currentTesting.listOT" class="row form-group">
        <div class="col-7">
          <span>{{ item.name }}</span>
          <span *ngIf="item.validMinValue != null">
            <b>&nbsp;[{{ item.validMinValue }} - {{ item.validMaxValue }}]</b>
          </span>
        </div>
        <div class="col-3">
          <ng-container [ngSwitch]="item.dataType">
            <ng-container *ngSwitchCase="contants.DATATYPENUMBER.NUMBER">
              <div class="d-flex">
                <nz-select class="w-100" #nextTab [(ngModel)]="item.valueRangeType">
                  <nz-option [nzLabel]="'<'" [nzValue]="-1"></nz-option>
                  <nz-option [nzLabel]="'='" [nzValue]="0"></nz-option>
                  <nz-option [nzLabel]="'>'" [nzValue]="1"></nz-option>
                </nz-select>
                <nz-input-number class="w-100" #nextTab [(ngModel)]="item.value"></nz-input-number>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="contants.DATATYPENUMBER.STRING">
              <input #nextTab nz-input [(ngModel)]="item.value"/>
            </ng-container>
            <ng-container *ngSwitchCase="contants.DATATYPENUMBER.POSITIVENEGATIVE">
              <nz-select class="w-100" #nextTab [(ngModel)]="item.value">
                <nz-option [nzLabel]="'Positive'" [nzValue]="true"></nz-option>
                <nz-option [nzLabel]="'Negative'" [nzValue]="false"></nz-option>
              </nz-select>
            </ng-container>
            <ng-container *ngSwitchCase="contants.DATATYPENUMBER.DATETIME">
              <nz-date-picker nzFormat="dd/MM/yyy HH:mm" nzShowTime [(ngModel)]="item.value"></nz-date-picker>
            </ng-container>
            <ng-container *ngSwitchCase="contants.DATATYPENUMBER.SINGLECHOICE">
              <nz-select
                class="w-100"
                #nextTab
                [(ngModel)]="item.value"
                nzAllowClear
                nzMode="multiple"
                [nzMaxMultipleCount]="1">
                <nz-option
                  *ngFor="let datasource of item.dataSource"
                  [nzLabel]="datasource.text"
                  [nzValue]="datasource.id"></nz-option>
              </nz-select>
            </ng-container>
          </ng-container>
        </div>
        <div class="col-2">
          <i>({{ item.unit }})</i>
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <button class="button-submit btn-cancel-large" (click)="closeTestingResult()">Hủy</button>
      <button nz-button class="btn-primary-large" (click)="saveListObservationVS()">Lưu</button>
    </div>
  </nz-modal>
</div>

<nz-modal
  nzWidth="800px"
  [nzVisible]="isVisibleResult"
  [nzTitle]="'Kết quả xét nghiệm'"
  nzClassName="m-modal-result"
  (nzOnCancel)="closeResult()">
  <nz-table
      #basicTable
      class="main-table-grid"
      nzSize="small"
      [nzData]="currentResults"
      [nzFrontPagination]="false"
      [nzShowPagination]="false">
    <thead>
    <tr>
      <th>Yêu cầu xét nghiệm</th>
      <th>{{ visit.visitDate | date: 'dd/MM/yyy' }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of basicTable.data; let i = index">
      <td class="xn-col" [innerHtml]="item.name"></td>
      <td class="xn-col" nzWidth="30px">
        <div class="value" [innerHtml]="item['c' + visit.id]">
          <a class="edit-xn-hover" (click)="editXetNghiem(item)">
            <i nz-icon nzType="edit" nzTheme="outline"></i>
          </a>
        </div>
        <div class="range">
          <div *ngIf="item['r' + visit.id] !== ''" class="border {{ item['r' + visit.id] }}"></div>
          <div *ngIf="item['r' + visit.id] !== ''" class="inner"></div>
        </div>
      </td>
    </tr>
    </tbody>
  </nz-table>
  <div *nzModalFooter>
    <button class="btn-cancel-large" (click)="closeResult()">Đóng</button>
  </div>
</nz-modal>
