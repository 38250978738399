<div class="tab-list-patient" (window:resize)="onResize($event)">
  <div class="search-bar row" style="display: flex">
    <div class="search-bar-left col-12">
      <div class="row" style="width: 100%">
        <div class="col-6">
          <div class="row">
            <div class="col-4">
              <app-filter-item
                #filter1
                [index]="0"
                [filterItems]="currentFilters"
                [searchParam]="search"
                [resetFilter]="resetFilter"
                (endTreatmentEvent)="endTreatmentEvent($event)"
                (seachEvent)="searchListPatient()"
                (mFilterChange)="mFilterChange($event)"></app-filter-item>
            </div>
            <div class="col-4">
              <app-filter-item
                #filter2
                [index]="1"
                [filterItems]="currentFilters"
                [searchParam]="search"
                [resetFilter]="resetFilter"
                (endTreatmentEvent)="endTreatmentEvent($event)"
                (seachEvent)="searchListPatient()"
                (mFilterChange)="mFilterChange($event)"></app-filter-item>
            </div>
            <div class="col-4">
              <app-filter-item
                #filter3
                [index]="2"
                [filterItems]="currentFilters"
                [searchParam]="search"
                [resetFilter]="resetFilter"
                (endTreatmentEvent)="endTreatmentEvent($event)"
                (seachEvent)="searchListPatient()"
                (mFilterChange)="mFilterChange($event)"></app-filter-item>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-4">
              <app-filter-item
                #filter4
                [index]="3"
                [filterItems]="currentFilters"
                [searchParam]="search"
                [resetFilter]="resetFilter"
                (endTreatmentEvent)="endTreatmentEvent($event)"
                (seachEvent)="searchListPatient()"
                (mFilterChange)="mFilterChange($event)"></app-filter-item>
            </div>
            <div class="col-4">
              <app-filter-item
                #filter5
                [index]="4"
                [filterItems]="currentFilters"
                [searchParam]="search"
                [resetFilter]="resetFilter"
                (endTreatmentEvent)="endTreatmentEvent($event)"
                (seachEvent)="searchListPatient()"
                (mFilterChange)="mFilterChange($event)"></app-filter-item>
            </div>
            <!-- <div class="col-3">
              <label class="label-input txt-ellipsis">Chuyên viên tâm lý</label>
              <nz-select class="mgr15" nzPlaceHolder="Chuyên viên tâm lý" [(ngModel)]="search.psychiatristId"
                nzShowSearch nzAllowClear>
                <nz-option *ngFor="let item of listPsychiatrist" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
            <div class="col-3">
              <label class="label-input txt-ellipsis">Điều dưỡng phụ trách</label>
              <nz-select class="mgr15" nzPlaceHolder="Điều dưỡng phụ trách" [(ngModel)]="search.nursingInCareId"
                nzShowSearch nzAllowClear>
                <nz-option *ngFor="let item of listNursingInCase" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
            <div class="col-3">
              <label class="label-input txt-ellipsis">Trạng thái điều trị</label>
              <nz-select class="mgr15" nzPlaceHolder="Trạng thái" [(ngModel)]="search.endTreatment">
                <nz-option nzLabel="Đang điều trị" [nzValue]="1"></nz-option>
                <nz-option nzLabel="Kết thúc điều trị" [nzValue]="2"></nz-option>
              </nz-select>
            </div> -->
            <div class="search-bar-right col-4">
              <div class="d-flex">
                <div (click)="resetSearch()" class="pointer mg-r-10">
                  <i title="Clear dữ liệu">
                    <img src="assets/image/DPicon/icon-close.svg" />
                  </i>
                </div>
                <div (click)="searchListPatient()" class="pointer mg-r-10">
                  <i title="Tìm kiếm">
                    <img src="assets/image/DPicon/icon-search.svg" />
                  </i>
                </div>
                <div
                  *ngIf="isAdminGroup"
                  (click)="exportListPatientTreatment()"
                  class="pointer mg-r-10"
                  title="Xuất Excel">
                  <i
                    *ngIf="!exporting"
                    nz-icon
                    nzType="export"
                    nzTheme="outline"></i>
                  <i
                    *ngIf="exporting"
                    nz-icon
                    nzType="loading"
                    nzTheme="outline"></i>
                </div>
              </div>
              <button
                class="normal-btn btn-primary-medium"
                style="margin-right: -30px"
                type="button"
                (click)="showAddPatient()">
                Thêm bệnh nhân
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <nz-select class="mgr15" nzPlaceHolder="Cấp độ" [(ngModel)]="search.sosRange" nzAllowClear=true>
                    <nz-option nzLabel="Tất cả" [nzValue]="0"></nz-option>
                    <nz-option nzLabel="F1 và F0/nghi ngờ không triệu chứng" [nzValue]="1"></nz-option>
                    <nz-option nzLabel="Triệu chứng nhẹ" [nzValue]="2"></nz-option>
                    <nz-option nzLabel="Triệu chứng nguy cơ diễn tiến nặng" [nzValue]="3"></nz-option>
                    <nz-option nzLabel="Triệu chứng cấp cứu" [nzValue]="4"></nz-option>
                </nz-select> -->
    </div>
    <div class="search-bar-left col-12 column-sort-filter">
      <div class="row" style="width: 100%">
        <div class="col-8">
          <div class="row">
            <div class="col-2">
              <label class="mg-r-4" style="line-height: 30px"
                >Sắp xếp theo:</label
              >
            </div>
            <div class="col-3">
              <nz-select
                [(ngModel)]="sortColumn"
                [nzPlaceHolder]="'Sắp xếp theo'"
                (ngModelChange)="searchListPatient()"
                nzAllowClear
                class="column-sort-select">
                <nz-option
                  *ngFor="let item of columnSortFilter"
                  [nzLabel]="item.label"
                  [nzValue]="item.value">
                </nz-option>
              </nz-select>
            </div>
            <div class="col-6">
              <nz-radio-group
                [(ngModel)]="sortColumnOrder"
                (ngModelChange)="searchListPatient()">
                <label nz-radio nzValue="0">Tăng dần</label>
                <label nz-radio nzValue="1">Giảm dần</label>
              </nz-radio-group>
            </div>
          </div>
        </div>
        <div class="col-4 btn-custom-column-wrapper">
          <button
            nz-button
            class="btn-custom-column"
            (click)="showCustomColumn()">
            <i nz-icon nzType="table" nzTheme="outline"></i>
            Cột hiển thị
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--   -->
  <div style="position: relative">
    <!-- <span class="column-btn" (click)="showCustomColumn()">
      <i nz-icon nzType="table" nzTheme="outline" class="fs-16"></i>
    </span> -->
    <nz-table
      class="main-table-grid list-patient-only"
      #fixedTable
      [nzFrontPagination]="false"
      [nzTotal]="total"
      nzShowSizeChanger
      [nzPageSize]="pageSize"
      [(nzPageIndex)]="pageIndex"
      [nzData]="resVisit"
      [nzLoading]="loading"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzScroll]="{ x: '1000px', y: scrollY }"
      [nzShowTotal]="rangeTemplate"
      [nzPageSizeOptions]="[15, 30, 50]">
      <thead>
        <tr>
          <ng-container *ngFor="let col of filterByEndtreatment">
            <th
              *ngIf="
                col.field != 'typeEndTreatmented' ||
                (col.field == 'typeEndTreatmented' && isFilterEndTreatment)
              "
              [nzColumnKey]="col.title"
              nz-resizable
              [nzWidth]="col.width"
              [nzMinWidth]="col.minWidth"
              nzBounds="window"
              nzPreview
              (nzResizeEnd)="onResizeCloumn($event, col.title)"
              [nzLeft]="col.field == 'STT' || col.field == 'patientName'">
              <!-- [nzLeft]="col.field == 'STT' || col.field == 'patientName'" -->
              <div class="col-content">
                <div class="col-name">
                  <span>
                    {{ col.title }}
                  </span>
                </div>
                <div style="position: relative; height: 30px">
                  <nz-resize-handle nzDirection="right">
                    <div class="resize-trigger"></div>
                  </nz-resize-handle>
                </div>
              </div>
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr
          class="pointer"
          *ngFor="let data of fixedTable.data; index as i"
          (contextmenu)="contextMenu($event, menu, data)"
          (click)="addTab(data.visitId, data.patientName, data.patientId)"
          [ngClass]="'sos-' + data.sosRange">
          <ng-container *ngFor="let col of filterByEndtreatment">
            <ng-container [ngSwitch]="col.field">
              <td
                *ngSwitchCase="'STT'"
                class="text-nowrap center"
                [ngClass]="'z-index-2 sos-' + data.sosRange"
                nzLeft>
                {{ pageSize * (pageIndex - 1) + i + 1 }}
              </td>
              <td
                *ngSwitchCase="'patientName'"
                class="text-nowrap"
                [ngClass]="'z-index-2 sos-' + data.sosRange"
                [title]="data.patientName"
                nzLeft>
                <div
                  *ngIf="
                    data.color &&
                    data.shortName &&
                    (!data.avatar || data.avatar == '')
                  "
                  class="customer-photo"
                  [ngStyle]="{ 'background-color': data.color }">
                  {{ data.shortName }}
                </div>
                <div
                  *ngIf="
                    (!data.color || !data.shortName) &&
                    (!data.avatar || data.avatar == '')
                  "
                  class="customer-photo"
                  style="
                    background-image: url('/assets/image/avt-default2.png');
                    background-repeat: round;
                  "></div>
                <div
                  *ngIf="data.avatar && data.avatar != ''"
                  class="customer-photo"
                  [ngStyle]="{
                    'background-image':
                      'url(' + (baseAvatarurl + data.avatar) + ')'
                  }"></div>
                {{ data.patientName }}
              </td>
              <td
                *ngSwitchCase="'userReported'"
                class="text-nowrap center"
                title="{{ userReportedToText(data.userReported) }}">
                <span *ngIf="data.userReported">{{
                  userReportedToText(data.userReported)
                }}</span>
                <nz-alert
                  *ngIf="!data.userReported"
                  [nzType]="'error'"
                  nzMessage="{{ userReportedToText(data.userReported) }}"
                  class="alert-error"></nz-alert>
              </td>
              <td
                *ngSwitchCase="'visitDate'"
                class="text-nowrap"
                title="{{ data.visitDate | date: 'dd/MM/yyy hh:mm a' }}">
                {{ data.visitDate | date: 'dd/MM/yyy hh:mm a' }}
              </td>
              <td
                *ngSwitchCase="'dateOfDiagnosis'"
                class="text-nowrap"
                title="{{ data.dateOfDiagnosis | date: 'dd/MM/yyy' }}">
                {{ data.dateOfDiagnosis | date: 'dd/MM/yyy' }}
              </td>
              <td
                *ngSwitchCase="'totalNewPrescription'"
                class="center"
                title="{{ data.totalNewPrescription }}">
                <nz-alert
                  *ngIf="data.totalNewPrescription > 0"
                  [nzType]="'success'"
                  nzMessage="{{ data.totalNewPrescription + ' đơn mới' }}"
                  class="alert-error msc-alert-success"></nz-alert>
              </td>
              <td
                *ngSwitchCase="'dateOfSymptom'"
                class="text-nowrap"
                title="{{ data.dateOfSymptom | date: 'dd/MM/yyy' }}">
                {{ data.dateOfSymptom | date: 'dd/MM/yyy' }}
              </td>
              <td
                *ngSwitchCase="'examinedStatus'"
                class="text-nowrap da-kham"
                title="{{
                  data.examinedStatus === true
                    ? 'Đã được khám bởi ' + data.examinedByDoctorName
                    : ''
                }}">
                <i
                  *ngIf="data.examinedStatus === true"
                  nz-icon
                  nzType="check-circle"
                  nzTheme="outline"></i>
              </td>
              <td
                *ngSwitchCase="'typeEndTreatmented'"
                class="text-nowrap"
                title="{{ typeEndTreatmentedToText(data.typeEndTreatmented) }}">
                {{ typeEndTreatmentedToText(data.typeEndTreatmented) }}
              </td>
              <td
                *ngSwitchCase="'dayNo'"
                class="text-nowrap center"
                title="{{ calcDay(data) }}">
                {{ calcDay(data) }}
              </td>
              <td
                *ngSwitchCase="'COVID-RTPCR'"
                class="text-nowrap center"
                title="{{ laboratoryValue(data) }}">
                <nz-alert
                  *ngIf="data['COVID-RTPCR'] == true"
                  [nzType]="'success'"
                  nzMessage="{{ laboratoryValue(data['COVID-RTPCR']) }}"
                  class="alert-error msc-alert-success"></nz-alert>
                <nz-alert
                  *ngIf="data['COVID-RTPCR'] == false"
                  [nzType]="'error'"
                  nzMessage="{{ laboratoryValue(data['COVID-RTPCR']) }}"
                  class="alert-error"></nz-alert>
              </td>
              <td
                *ngSwitchCase="'SARS-CoV-2-Fast-test'"
                class="text-nowrap center"
                title="{{ laboratoryValue(data) }}">
                <nz-alert
                  *ngIf="data['SARS-CoV-2-Fast-test'] == true"
                  [nzType]="'success'"
                  nzMessage="{{
                    laboratoryValue(data['SARS-CoV-2-Fast-test'])
                  }}"
                  class="alert-error msc-alert-success"></nz-alert>
                <nz-alert
                  *ngIf="data['SARS-CoV-2-Fast-test'] == false"
                  [nzType]="'error'"
                  nzMessage="{{
                    laboratoryValue(data['SARS-CoV-2-Fast-test'])
                  }}"
                  class="alert-error"></nz-alert>
              </td>
              <td *ngSwitchDefault [title]="data[col.field]">
                {{ data[col.field] }}
              </td>
            </ng-container>
          </ng-container>
        </tr>
      </tbody>

      <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }} {{ 'bệnh nhân' }}
      </ng-template>
    </nz-table>
  </div>
  <!-- <div id="tooltip">
      <div>
        <div class="tooltip-part1">
          <div class="part1_1">
            <div
              *ngIf="
                dataTooltip.color &&
                dataTooltip.shortName &&
                (!dataTooltip.avatar || dataTooltip.avatar == '')
              "
              class="tooltip-img"
              [ngStyle]="{ 'background-color': dataTooltip.color }"
            >
              {{ dataTooltip.shortName }}
            </div>
            <div
              *ngIf="
                !dataTooltip.color &&
                !dataTooltip.shortName &&
                (!dataTooltip.avatar || dataTooltip.avatar == '')
              "
              class="tooltip-img"
              style="
                background-image: url('/assets/image/avt-default2.png');
                background-repeat: round;
              "
            ></div>
            <div
              *ngIf="dataTooltip.avatar && dataTooltip.avatar != ''"
              class="tooltip-img"
              [ngStyle]="{
                'background-image':
                  'url(' + (baseAvatarurl + dataTooltip.avatar) + ')'
              }"
            ></div>
            <div>
              <span>
                <span class="bigger-font">{{ dataTooltip.patientName }} </span>
                <span
                  >({{ dataTooltip.dob }} - {{ dataTooltip.age }} -
                  {{ dataTooltip.genderText }})</span
                >
              </span>
              <br />
              <span>Mã bệnh nhân: {{ dataTooltip.patientCode }}</span>
            </div>
          </div>
          <div>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
              >
                <g id="ic_local_phone_24px" transform="translate(-3 -3)">
                  <g
                    id="Group_2895"
                    data-name="Group 2895"
                    transform="translate(3 3)"
                  >
                    <path
                      id="Path_2238"
                      data-name="Path 2238"
                      d="M5.413,8.193a10.1,10.1,0,0,0,4.393,4.393l1.467-1.467a.663.663,0,0,1,.68-.16,7.6,7.6,0,0,0,2.38.38.669.669,0,0,1,.667.667v2.327a.669.669,0,0,1-.667.667A11.332,11.332,0,0,1,3,3.667.669.669,0,0,1,3.667,3H6a.669.669,0,0,1,.667.667,7.574,7.574,0,0,0,.38,2.38.669.669,0,0,1-.167.68Z"
                      transform="translate(-3 -3)"
                      fill="#565656"
                    />
                  </g>
                </g>
              </svg>
              {{ dataTooltip.phoneNo }}</span
            >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="10"
                viewBox="0 0 12 10"
              >
                <g id="ic_email_24px" transform="translate(-2 -4)">
                  <g
                    id="Group_2896"
                    data-name="Group 2896"
                    transform="translate(2 4)"
                  >
                    <path
                      id="Path_2239"
                      data-name="Path 2239"
                      d="M12.8,4H3.2A1.224,1.224,0,0,0,2.006,5.25L2,12.75A1.229,1.229,0,0,0,3.2,14h9.6A1.229,1.229,0,0,0,14,12.75V5.25A1.229,1.229,0,0,0,12.8,4Zm0,2.5L8,9.625,3.2,6.5V5.25L8,8.375,12.8,5.25Z"
                      transform="translate(-2 -4)"
                      fill="#565656"
                    />
                  </g>
                </g>
              </svg>
              {{ dataTooltip.email }}</span
            >
          </div>
          <div>
            <span class="smaller-font">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="12"
                viewBox="0 0 10 12"
              >
                <g id="ic_add_location_24px" transform="translate(-5 -2)">
                  <g
                    id="Group_2897"
                    data-name="Group 2897"
                    transform="translate(5 2)"
                  >
                    <path
                      id="Path_2240"
                      data-name="Path 2240"
                      d="M10,2C7.243,2,5,3.884,5,6.2,5,9.35,10,14,10,14s5-4.65,5-7.8C15,3.884,12.757,2,10,2Zm2.857,4.8H10.714V8.6H9.286V6.8H7.143V5.6H9.286V3.8h1.429V5.6h2.143Z"
                      transform="translate(-5 -2)"
                      fill="#565656"
                    />
                  </g>
                </g>
              </svg>
              {{ dataTooltip.address }}
            </span>
          </div>
        </div>

        <div class="tooltip-part2">
          <div class="part2_1">
            <div>
              <span class="bigger-weight">BS khám: </span
              ><span style="margin-right: 24px">{{
                dataTooltip.doctorName
              }}</span>
            </div>
            <div>
              <span class="bigger-weight">Thời gian khám: </span
              ><span>{{
                dataTooltip.visitdate | date: "dd/MM/yyyy hh:mm a"
              }}</span>
            </div>
          </div>
          <div>
            <span class="bigger-weight">Loại khám: </span>
            <span>{{ dataTooltip.visitTypeText }}</span>
          </div>
        </div>
      </div>
    </div> -->
  <!-- </div> -->
  <app-custom-column
    [(isVisible)]="isCustomColumnVisible"
    [showColumn]="showCols"
    [hideColumn]="hideCols"
    (saveColumns)="changeColumns($event)"></app-custom-column>

  <!-- context menu -->
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li
        nz-menu-item
        *ngIf="isCoordinator && !seletedTreatmeant.roomId"
        (click)="openAddRoomModal()">
        <i nz-icon nzType="usergroup-add" nzTheme="outline" class="mg-r-4"></i>
        Thêm bệnh nhân vào nhóm
      </li>
      <li
        nz-menu-item
        *ngIf="isCoordinator && seletedTreatmeant.roomId"
        (click)="openMoveRoomModal()">
        <i nz-icon nzType="swap" nzTheme="outline" class="mg-r-4"></i> Chuyển
        nhóm của bệnh nhân
      </li>
      <li
        nz-menu-item
        *ngIf="isCoordinator && seletedTreatmeant.roomId"
        (click)="confirmRemovePaientToRoom()">
        <i
          nz-icon
          nzType="usergroup-delete"
          nzTheme="outline"
          class="mg-r-4"></i>
        Xóa bệnh nhân khỏi nhóm
      </li>
      <li
        nz-menu-item
        *ngIf="isCoordinator && isCovidGroup"
        (click)="openCovidNumberModal()">
        <i nz-icon nzType="barcode" nzTheme="outline" class="mg-r-4"></i> Thêm
        mã số covid
      </li>
      <li nz-menu-item (click)="openModalEditStatus()">
        <i nz-icon nzType="stop" nzTheme="outline" class="mg-r-4"></i> Nhập
        trạng thái dừng điều trị
      </li>
      <li nz-menu-item *ngIf="isAdminGroup" (click)="openModalPatientTest()">
        <i nz-icon nzType="user-delete" nzTheme="outline" class="mg-r-4"></i>
        Gán BN là BN test
      </li>
    </ul>
  </nz-dropdown-menu>

  <!-- modal sửa trạng thái điều trị BN -->
  <nz-modal
    [(nzVisible)]="isVisibleEditStatus"
    [nzTitle]="titlePopup"
    nzMaskClosable="false"
    (nzOnCancel)="cancelEditStatus()">
    <div [formGroup]="formEditStatus">
      <div class="mg-bt-15">
        <label class="label-medium-bold" style="margin-right: 35px"
          >Trạng thái</label
        >
        <nz-radio-group formControlName="isEndTreatmented">
          <label nz-radio [nzValue]="true">Kết thúc điều trị</label>
          <label *ngIf="search.endTreatment == 2" nz-radio [nzValue]="false"
            >Tiếp tục điều trị</label
          >
        </nz-radio-group>
      </div>
      <div *ngIf="f['isEndTreatmented'].value">
        <div class="mg-bt-15">
          <label class="label-medium-bold mg-r-15">Nguyên nhân</label>
          <nz-radio-group formControlName="typeEndTreatmented">
            <label nz-radio [nzValue]="0">Khỏi bệnh</label>
            <label nz-radio [nzValue]="1">Tử vong</label>
            <label nz-radio [nzValue]="2">Chuyển viện</label>
            <label nz-radio [nzValue]="3">Khác</label>
          </nz-radio-group>
        </div>
        <div>
          <label class="label-medium-bold mg-r-15"
            >Ngày kết thúc điều trị <span class="red-dot">*</span></label
          >
          <nz-date-picker
            nzFormat="dd/MM/yyyy"
            formControlName="dateEndTreatmented"></nz-date-picker>
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <button
        class="button-submit btn-cancel-small"
        (click)="cancelEditStatus()">
        Quay lại
      </button>
      <button nz-button class="btn-primary-small" (click)="updateStatus()">
        Cập nhật
      </button>
    </div>
  </nz-modal>

  <nz-modal
    [(nzVisible)]="isVisibleAddPatientRoom"
    nzMaskClosable="false"
    (nzOnCancel)="closeAddRoomModal()"
    [nzWidth]="450">
    <div *nzModalTitle>
      Thêm bệnh nhân {{ seletedTreatmeant.patientName }} vào room
    </div>
    <div *nzModalContent>
      <div nz-row>
        <div nz-col nzSpan="8">Tên bênh nhân:</div>
        <div nz-col nzSpan="16">{{ seletedTreatmeant.patientName }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8">Ngày sinh:</div>
        <div nz-col nzSpan="16">{{ seletedTreatmeant.dobString }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8">Địa chỉ:</div>
        <div nz-col nzSpan="16">{{ seletedTreatmeant.address }}</div>
      </div>
      <nz-divider></nz-divider>
      <div nz-row>
        <div nz-col nzSpan="8">Chọn room:</div>
        <div nz-col nzSpan="16">
          <nz-select
            nzShowSearch
            nzServerSearch
            nzPlaceHolder="Tìm kiếm room"
            [(ngModel)]="selectedAddRoomId"
            [nzShowArrow]="false"
            [nzFilterOption]="true"
            (nzOnSearch)="searchAddRoomUnsign($event)"
            style="width: 95%">
            <nz-option
              *ngFor="let o of listOfRoomOptions"
              [nzLabel]="o.name"
              [nzValue]="o.id"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <button
        class="button-submit btn-cancel-small"
        (click)="closeAddRoomModal()">
        Quay lại
      </button>
      <button nz-button class="btn-primary-small" (click)="addPaientToRoom()">
        Lưu
      </button>
    </div>
  </nz-modal>

  <nz-modal
    [(nzVisible)]="isVisibleMovePatientRoom"
    nzMaskClosable="false"
    (nzOnCancel)="closeMoveRoomModal()"
    [nzWidth]="450">
    <div *nzModalTitle>
      Chuyến room cho bệnh nhân {{ seletedTreatmeant.patientName }}
    </div>
    <div *nzModalContent>
      <div nz-row>
        <div nz-col nzSpan="8">Tên bênh nhân:</div>
        <div nz-col nzSpan="16">{{ seletedTreatmeant.patientName }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8">Ngày sinh:</div>
        <div nz-col nzSpan="16">{{ seletedTreatmeant.dobString }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8">Địa chỉ:</div>
        <div nz-col nzSpan="16">{{ seletedTreatmeant.address }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8">Room hiện tại:</div>
        <div nz-col nzSpan="16">{{ seletedTreatmeant.roomName }}</div>
      </div>
      <nz-divider></nz-divider>
      <div nz-row>
        <div nz-col nzSpan="8">Chọn room:</div>
        <div nz-col nzSpan="16">
          <nz-select
            nzShowSearch
            nzServerSearch
            nzPlaceHolder="Tìm kiếm room"
            [(ngModel)]="selectedMoveRoomId"
            [nzShowArrow]="false"
            [nzFilterOption]="true"
            (nzOnSearch)="searchMoveRoomUnsign($event)"
            style="width: 95%">
            <nz-option
              *ngFor="let o of listOfMoveRoomOptions"
              [nzLabel]="o.name"
              [nzValue]="o.id"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <button
        class="button-submit btn-cancel-small"
        (click)="closeMoveRoomModal()">
        Quay lại
      </button>
      <button nz-button class="btn-primary-small" (click)="movePaientToRoom()">
        Cập nhật
      </button>
    </div>
  </nz-modal>

  <nz-modal
    [(nzVisible)]="isVisibleCovidNumberModal"
    nzMaskClosable="false"
    (nzOnCancel)="closeCovidNumberModal()"
    [nzWidth]="450">
    <div *nzModalTitle>Mã số Covid {{ seletedTreatmeant.patientName }}</div>
    <div *nzModalContent>
      <div nz-row>
        <div nz-col nzSpan="8">Tên bênh nhân:</div>
        <div nz-col nzSpan="16">{{ seletedTreatmeant.patientName }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8">Ngày sinh:</div>
        <div nz-col nzSpan="16">{{ seletedTreatmeant.dobString }}</div>
      </div>
      <nz-divider></nz-divider>
      <div nz-row>
        <div nz-col nzSpan="8">Mã số Covid:</div>
        <div nz-col nzSpan="16">
          <input [(ngModel)]="covidNumber" />
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <button
        class="button-submit btn-cancel-small"
        (click)="closeCovidNumberModal()">
        Đóng
      </button>
      <button nz-button class="btn-primary-small" (click)="updateCovidNumber()">
        Cập nhật
      </button>
    </div>
  </nz-modal>
</div>
