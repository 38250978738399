import { SharePrescriptionComponent } from './components/common-layout/share-prescription/share-prescription.component';
import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  Compiler,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { NZ_DATE_LOCALE, NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { enUS } from 'date-fns/locale';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './components/common-layout/layout.module';
import { AdminModule } from './components/admin/admin.module';
import { AuthModule } from './components/auth/auth.module';
import { ModalModule } from './components/modal/modal.module';
import { SharedModule } from './share/share.module';
import { AppConfigService } from '../app-config.service';
import { LoaderService } from './services/loader.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptor } from './share/interceptor/error.interceptor';
import { AuthGuard } from './share/guards/guards.class';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { AppStateModule } from './components/app-state/app-state.module';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { MessageService } from './services/message.service';
import { AsyncPipe } from '@angular/common';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { RoleGuardClass } from './share/guards/role-guards.class';
import { CommonModule } from '@angular/common';
import { PrescriptionModule } from './components/prescription/prescription.module';
import { SsoLoginModule } from './components/sso-login/sso-login.module';

//import { ConferenceRoomCallComponent } from './components/common-layout/conference-room-call/conference-room-call.component';
//import { ViewVisitComponent } from './components/common-layout/view-visit/view-visit.component';
// import { JitCompilerFactory } from '@angular/platform-browser-dynamic';

// export function createJitCompiler() {
//   return new JitCompilerFactory().createCompiler([{
//     useJit: true
//   }]);
// }

export function configServiceFactory(config: AppConfigService) {
  return () => config.load();
}
@NgModule({
  declarations: [AppComponent, SharePrescriptionComponent], //ConferenceRoomCallComponent,
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    AuthModule,
    SharedModule,
    FontAwesomeModule,
    AdminModule,
    ModalModule,
    AppStateModule,
    AngularFireModule.initializeApp(environment.firebasecf),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    PrescriptionModule,
    SsoLoginModule,
  ],
  providers: [
    LoaderService,
    AppConfigService,
    MessageService,
    AsyncPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: configServiceFactory,
      deps: [AppConfigService],
      multi: true,
    },
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: NZ_DATE_LOCALE, useValue: enUS }, // Set the value of NZ_DATE_LOCALE to activate date-fns to format date
    // {provide: Compiler, useFactory: createJitCompiler},
    AuthGuard,
    RoleGuardClass,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {}
}
