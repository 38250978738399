<div [ngSwitch]="data.observationType.datatypeText" class="form-input form-elm">
  <div *ngSwitchCase="'DateTime'" style="height: 100%">
    <nz-date-picker
      nzFormat="yyyy-MM-dd HH:mm:ss"
      nzShowTime
      [(ngModel)]="data.value"
      [ngClass]="[
        data.formatStyle.isBold == true ? 'bold' : '',
        data.formatStyle.isItalic == true ? 'italic' : '',
        data.formatStyle.isUnderline == true ? 'underline' : '',
        data.formatStyle.align
      ]"
      [ngStyle]="{
        'color': data.formatStyle.color,
        'background': data.formatStyle.bgColor,
        'font-size': data.formatStyle.fontSize + 'px',
        'font-family': data.formatStyle.font
      }"
      [nzDisabled]="data.editType == 0"
      [nzPlaceHolder]="data.observationType.name">
    </nz-date-picker>
  </div>
  <div *ngSwitchCase="'Date'" style="height: 100%">
    <nz-date-picker
      nzFormat="yyyy-MM-dd"
      [(ngModel)]="data.value"
      [ngClass]="[
        data.formatStyle.isBold == true ? 'bold' : '',
        data.formatStyle.isItalic == true ? 'italic' : '',
        data.formatStyle.isUnderline == true ? 'underline' : '',
        data.formatStyle.align
      ]"
      [ngStyle]="{
        'color': data.formatStyle.color,
        'background': data.formatStyle.bgColor,
        'font-size': data.formatStyle.fontSize + 'px',
        'font-family': data.formatStyle.font
      }"
      [nzDisabled]="data.editType == 0"
      [nzPlaceHolder]="data.observationType.name">
    </nz-date-picker>
  </div>
  <div *ngSwitchCase="'Time'" style="height: 100%">
    <nz-time-picker
      [(ngModel)]="data.value"
      nzFormat="HH:mm"
      [ngClass]="[
        data.formatStyle.isBold == true ? 'bold' : '',
        data.formatStyle.isItalic == true ? 'italic' : '',
        data.formatStyle.isUnderline == true ? 'underline' : '',
        data.formatStyle.align
      ]"
      [ngStyle]="{
        'color': data.formatStyle.color,
        'background': data.formatStyle.bgColor,
        'font-size': data.formatStyle.fontSize + 'px',
        'font-family': data.formatStyle.font
      }"
      [nzDisabled]="data.editType == 0"
      [nzPlaceHolder]="data.observationType.name"></nz-time-picker>
  </div>
</div>
<!-- <div class="active-elm"></div> -->
