import {
  Component,
  OnInit,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { event } from 'jquery';
import { Subscription } from 'rxjs';
import { SearchVisit, Visits } from 'src/app/models/listVisit.class';
import { ListVisitService } from 'src/app/services/listVisit-service';
import { NotificationService } from 'src/app/services/notification.service';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AppConfigService } from '../../../../app-config.service';
import { AppState } from '../../app-state/app-state';
import { FilterItemComponent } from './../../filter-item/filter-item.component';
import { FilterItem } from 'src/app/models/filter-model/filter-item.class';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

const defaultFilters: FilterItem[] = [
  {
    field: 'patientName',
    label: 'Tên bệnh nhân',
    type: Constant.FILTER_TYPE.INPUT,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: true,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'patientCode',
    label: 'Mã bệnh nhân',
    type: Constant.FILTER_TYPE.INPUT,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: true,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'phone',
    label: 'Số điện thoại',
    type: Constant.FILTER_TYPE.INPUT,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: true,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'address',
    label: 'Địa chỉ',
    type: Constant.FILTER_TYPE.INPUT,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: true,
    keyField: '',
    valueField: '',
    options: [],
  },
];

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  providers: [NotificationService, ListVisitService],

  // encapsulation: ViewEncapsulation.None,
})
export class SearchResultComponent implements OnInit, OnDestroy {
  isShown = false;
  isFirstLoad = true;
  visitReason: string;
  visitDate: string;
  doctorName: string;
  genderText: string;
  @Input()
  get tagNameSearch(): string {
    return this._tagNameSearch;
  }
  set tagNameSearch(value: string) {
    this._tagNameSearch = value;
    this.searchParam.keyword = this.tagNameSearch;
    if (value) {
      this.searchdata();
    }
  }
  currentFilters: FilterItem[] = [];
  userPermissionInGroup: number[] = [];
  currentUserId = '';
  data: any = {
    inGroup: false,
    patientName: '',
    genderText: '',
    address: '',
    phone: '',
    patientCode: '',
    doctorName: '',
    visitReason: '',
    visitDate: '',
    from: '',
    to: '',
    newId: '',
    groupId: '',
  };
  private _tagNameSearch = '';
  public listDataShow: SearchVisit[];
  public loading = false;
  public showTimePicker = false;
  private beginCountTimeFilter;
  keyword: '';
  resetFilter = false;
  searchParam = {
    keyword: '',
    patientName: '',
    genderText: '',
    address: '',
    phone: '',
    patientCode: '',
    doctorName: '',
    visitReason: '',
    visitDate: '',
    from: '',
    to: '',
    inGroup: false,
    pageSize: 50,
    page: 1,
    newId: '',
    groupId: '',
  };

  public ngayKham: string = null;
  public nzlabelFromTo = 'Từ ngày - đến ngày';
  public rangeofdate: Date[] = [];
  scrollX = '';
  pageIndex = 1;
  pageSize = 10;
  total = 0;
  baseAvatarurl = '';
  groupId: string;
  groupSub: Subscription;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth <= 1200) {
      this.scrollX = '1100px';
    }
  }
  constructor(
    private tabContrl: TabDataService,
    private notify: NotificationService,
    private dataControl: ListVisitService,
    private configService: AppConfigService,
    private store: Store<AppState>
  ) {
    this.currentUserId = JSON.parse(
      localStorage.getItem(Constant.USER_INFO)
    ).userId;
  }
  ngOnInit() {
    // this.getlistDataShow();
    this.baseAvatarurl =
      this.configService.getConfig().media.baseUrl + '/Avatar/';
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
      }
    });
    this.listDataShow = [];
    this.currentFilters = JSON.parse(JSON.stringify(defaultFilters));
  }

  async getlistDataShow() {
    if (!this.isFirstLoad) {
      this.loading = true;
      //this.searchParam.groupId = "5d7a0f011a46690d24cb10f6";
      this.searchParam.page = this.pageIndex;
      this.searchParam.pageSize = this.pageSize;

      this.searchParam.groupId = this.groupId;
      if (this.searchParam.groupId !== null) {
        this.dataControl.getListVisit(this.searchParam).subscribe(
          (response) => {
            if (response !== null && response.source != null) {
              this.listDataShow = [...response.source];
              this.total = response.itemCount;
            } else {
              this.listDataShow = [];
            }
            this.loading = false;
          },

          (error) => {
            this.notify.showNotification('', 'Lỗi data');
          }
        );
      }
    } else {
      this.isFirstLoad = false;
    }
  }

  async searchdata() {
    this.loading = true;
    // this.isLoadTableAgain = true;
    this.listDataShow = [];
    this.searchParam.page = 1;
    await this.getlistDataShow();
  }

  filterData($event) {
    clearTimeout(this.beginCountTimeFilter);
    if ($event.keyCode != 13) {
      this.beginCountTimeFilter = setTimeout(() => {
        this.searchdata();
      }, 1000);
    } else {
      this.searchdata();
    }
  }
  getFormatDate(date: string | number): string {
    const datedata = new Date(date);
    return (
      datedata.getDate() +
      '/' +
      (datedata.getMonth() + 1) +
      '/' +
      datedata.getFullYear()
    );
  }
  getRangeofDate() {
    switch (this.ngayKham) {
      case 'all':
        this.showTimePicker = false;
        this.searchParam.from = '';
        this.searchParam.to = '';
        break;
      case 'today':
        this.showTimePicker = false;
        this.searchParam.from = this.getFormatDate(Date.now());
        this.searchParam.to = this.getFormatDate(Date.now());
        break;
      case 'yesterday':
        this.showTimePicker = false;
        this.searchParam.from = this.getFormatDate(
          Date.now() - 24 * 60 * 60 * 1000
        );
        this.searchParam.to = this.getFormatDate(Date.now());
        break;
      case 'thisweek':
        this.showTimePicker = false;
        const day = new Date().getDay() == 0 ? 7 : new Date().getDay();
        this.searchParam.from = this.getFormatDate(
          Date.now() - (day - 1) * 24 * 60 * 60 * 1000
        );

        this.searchParam.to = this.getFormatDate(Date.now());
        break;
      case '3day':
        this.showTimePicker = false;
        this.searchParam.from = this.getFormatDate(
          Date.now() - 3 * 24 * 60 * 60 * 1000
        );
        this.searchParam.to = this.getFormatDate(Date.now());
        break;
      case '7day':
        this.showTimePicker = false;
        this.searchParam.from = this.getFormatDate(
          Date.now() - 7 * 24 * 60 * 60 * 1000
        );
        this.searchParam.to = this.getFormatDate(Date.now());
        break;
      case '30day':
        this.showTimePicker = false;
        this.searchParam.from = this.getFormatDate(
          Date.now() - 30 * 24 * 60 * 60 * 1000
        );
        this.searchParam.to = this.getFormatDate(Date.now());
        break;
      case 'fromTo':
        this.showTimePicker = true;
        break;
      default:
        this.showTimePicker = false;
        this.searchParam.from = '';
        this.searchParam.to = '';
    }
  }

  getDatesfromDatePicker(result: Date) {
    this.searchParam.from =
      result[0].getDate() +
      '/' +
      (result[0].getMonth() + 1) +
      '/' +
      result[0].getFullYear();
    this.searchParam.to =
      result[1].getDate() +
      '/' +
      (result[1].getMonth() + 1) +
      '/' +
      result[1].getFullYear();
    this.nzlabelFromTo = this.searchParam.from + ' - ' + this.searchParam.to;
    this.showTimePicker = false;
  }

  loadMoredata(): void {
    this.loading = true;
    this.searchParam.page++;
    this.dataControl.getListVisit(this.searchParam).subscribe(
      (response) => {
        if (response !== null && response.source != null) {
          setTimeout(() => {
            this.listDataShow = [...this.listDataShow, ...response.source];
            this.loading = false;
          }, 50);
        } else {
          this.loading = false;
          this.notify.showNotification('info', 'No more data');
        }
      },
      (error) => {
        this.notify.showNotification('error', 'Lỗi data');
      }
    );
  }
  addTab(id, PatientName, patientId) {
    this.tabContrl.updateTab(id, PatientName, 'VisitDetail', patientId);
  }
  ngOnDestroy(): void {
    this.groupSub.unsubscribe();
  }

  ShowAndHide() {
    this.isShown = !this.isShown;
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.getlistDataShow();
  }
}
