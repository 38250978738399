<app-m-modal
  [modalId]="'tab-visit-info_vs-history'"
  [isVisible]="isVisible"
  [mMask]="true"
  [width]="1000"
  [minWidth]="1000"
  [minHeight]="400"
  [isResiable]="true"
  (closeModal)="cancelHistory()">
  <ng-template #mModalTitle>
    <div class="header-title">Lịch sử khám</div>
  </ng-template>
  <ng-template #mModalContent>
    <nz-table
      #table
      [nzData]="lstHistoryModal"
      [nzFrontPagination]="false"
      [nzTotal]="totalHistory"
      [nzPageSize]="pageSize"
      [(nzPageIndex)]="pageIndex"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzScroll]="{ y: '450px' }"
      ngClass="history-modal"
      nzShowSizeChanger
      [nzPageSizeOptions]="[10, 20, 50]">
      <thead>
        <tr>
          <th nzWidth="150px">Thời gian</th>
          <th nzWidth="150px">Lý do khám</th>
          <th nzWidth="150px">Bác sỹ khám</th>
          <th nzWidth="180px">Nhóm bác sỹ</th>
          <th>Bệnh chẩn đoán</th>
          <th nzWidth="100px">Thao tác</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of table.data">
          <tr
            (dblclick)="openVisit(data.id)"
            title="Double click để mở ca khám">
            <td>{{ data.visitDate | date: 'dd/MM/yyyy HH:mm' }}</td>
            <td>
              <div>{{ data.visitReason }}</div>
            </td>
            <td class="username">{{ data.doctorName }}</td>
            <td>{{ data.doctorGroupName }}</td>
            <td>
              <ul>
                <li *ngFor="let disease of data.diseases">
                  {{ disease.name }}
                </li>
              </ul>
            </td>
            <td>
              <button
                nz-button
                nzType="primary"
                nzSize="small"
                (click)="openVisit(data.id)"
                class="mg-r-4"
                title="Mở ca khám">
                <i nz-icon nzType="right-circle" nzTheme="outline"></i>
              </button>
              <button
                nz-button
                nzType="primary"
                nzSize="small"
                (click)="onExpandChange(data.id)"
                title="Mở Master Note"
                *ngIf="data.totalNotes > 0 && !expandSet.has(data.id)"
                nzGhost>
                <!-- <i nz-icon nzType="form" nzTheme="outline"></i> -->
                <i nz-icon nzType="caret-down" nzTheme="fill"></i>
                <div class="total-notes">{{ data.totalNotes }}</div>
              </button>
              <button
                nz-button
                *ngIf="data.totalNotes > 0 && expandSet.has(data.id)"
                (click)="onExpandChange(data.id)"
                nzSize="small"
                title="Đóng Master Note"
                nzType="default"
                nzDanger>
                <!-- <i nz-icon nzType="close-square" nzTheme="outline"></i> -->
                <i nz-icon nzType="caret-up" nzTheme="fill"></i>
                <div class="total-notes">{{ data.totalNotes }}</div>
              </button>
            </td>
          </tr>
          <tr [nzExpand]="expandSet.has(data.id)" class="expand-tr">
            <div *ngFor="let chat of data.notes" class="note-content">
              <div class="msg-header">
                <!-- <span class="msg-name" *ngIf="chat.doctorId === userInfo.userId">Tôi</span> -->
                <span class="msg-name">BS: {{ chat.doctorName }}</span>
                <!-- <span class="msg-name" *ngIf="chat.showEditor"> chỉnh sửa </span> -->
                <span class="msg-date">
                  | {{ chat.dateLog | date: 'dd/MM/yyyy hh:mm:ss' }}
                </span>
              </div>
              <p
                class="note-content"
                text-wrap
                [innerHtml]="chat.content | safeHtml"></p>
            </div>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </ng-template>
  <ng-template #mModalFooter>
    <button nz-button class="btn-cancel-medium" (click)="cancelHistory()">
      Đóng
    </button>
  </ng-template>
</app-m-modal>
