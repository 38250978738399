<div class="chat-wrapper">
  <div class="chat-body" #chatcontent>
    <div class="chat-content" id="chatcontent">
      <div class="message-box" *ngFor="let chat of chats">
        <div
          class="chat-status"
          text-center
          *ngIf="chat.type === 'join' || chat.type === 'exit'; else message">
          <span class="chat-date">{{
            chat.date | date: 'dd/MM/yyyy HH:mm'
          }}</span>
          <span class="chat-content-center">{{ chat.message }}</span>
        </div>
        <ng-template #message>
          <div class="chat-message">
            <div
              class="right-bubble"
              [ngClass]="{
                'right-bubble': chat.userId === userInfo.userId,
                'left-bubble': chat.userId !== userInfo.userId
              }">
              <div class="user-avatar" *ngIf="chat.userId !== userInfo.userId">
                {{ shortName(chat.userName) }}
              </div>
              <div class="content-right">
                <div class="msg-title">
                  <span class="msg-name" *ngIf="chat.userId === userInfo.userId"
                    >Tôi</span
                  >
                  <span class="msg-name" *ngIf="chat.userId !== userInfo.userId"
                    >BS: {{ chat.userName }}</span
                  >
                  <span class="msg-date"> at {{ chat.date }}</span>
                </div>
                <div class="divider"></div>
                <ng-container [ngSwitch]="chat.type">
                  <p
                    *ngSwitchCase="'message'"
                    text-wrap
                    [innerHtml]="chat.message"></p>
                  <p *ngSwitchCase="'image'" text-wrap>
                    <app-chat-image
                      [data]="chat"
                      (showImageEvent)="showSlideShow($event)"></app-chat-image>
                  </p>
                  <p *ngSwitchCase="'file'">
                    <app-chat-file [data]="chat"></app-chat-file>
                  </p>
                  <ng-container *ngSwitchDefault>
                    <p
                      *ngIf="!isUrlValid(chat.message)"
                      [innerHtml]="chat.message"></p>
                    <app-chat-link
                      *ngIf="isUrlValid(chat.message)"
                      [data]="chat.message"></app-chat-link>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="chat-footer {{ hideButton }}">
    <form class="message-form" [formGroup]="chatForm">
      <nz-spin [nzSpinning]="isUpload">
        <div title="Tải file lên">
          <nz-upload
            [nzAction]="UPLOAD_URL"
            nzName="FormFile"
            [nzHeaders]="header"
            class="upload-wrapper"
            [nzShowUploadList]="false"
            [nzLimit]="1"
            [nzBeforeUpload]="beforeUploadFile"
            [nzCustomRequest]="handleUploadFile">
            <img src="assets/image/DPicon/paper-clip.svg" />
          </nz-upload>
        </div>
        <div title="Tải ảnh lên">
          <nz-upload
            [nzAction]="UPLOAD_URL"
            nzName="FormFile"
            [nzHeaders]="header"
            class="upload-wrapper"
            [nzShowUploadList]="false"
            [nzLimit]="1"
            [nzBeforeUpload]="beforeUploadImage"
            [nzCustomRequest]="handleUploadImage">
            <img src="assets/image/photo.svg" style="width: 20px" />
          </nz-upload>
        </div>

        <!-- (nzChange)="handleChange($event)" -->
        <!-- <div class="upload-wrapper">          
          <img src="assets/image/DPicon/paper-clip.svg">
        </div> -->
        <div
          #chateditor
          (focus)="commentFocus(true)"
          (blur)="commentFocus(false)"
          (keypress)="commentKeyPress($event)"
          id="txtComment"
          class="inputor default"
          spellcheck="false"
          contenteditable="false"
          (paste)="onPaste($event)"
          dropFile
          (fileDropped)="onFileDropped($event)"></div>
      </nz-spin>
      <button (click)="sendChatOnly()">Gửi</button>
    </form>
  </div>

  <nz-modal
    nzClassName="slideShowImage"
    [(nzVisible)]="isVisibleListImage"
    (nzOnCancel)="cancelSlideShow()"
    [nzFooter]="null">
    <!-- <i class="rotateIcon {{hideButton}}" (click)="rotateVisitImage(visitId,slide.id)">
        <img src="assets/image/DPicon/rotate.svg">
    </i> -->
    <i
      class="left action-icon"
      nz-icon
      nzType="left"
      nzTheme="outline"
      (click)="preImage(slide.index)"></i>
    <i
      class="right action-icon"
      nz-icon
      nzType="right"
      nzTheme="outline"
      (click)="nextImage(slide.index)"></i>
    <div class="bigImage">
      <img class="i{{ slide.id }}" [src]="slide.url" />
    </div>
    <div class="index">{{ slide.index + 1 }}/{{ lstImages.length }}</div>
  </nz-modal>
</div>
