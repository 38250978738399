<div class="title-wrapper">
  <!-- <p *ngIf="isFromManager" class="page-title">Quản trị Observation Type</p> -->
  <div *ngIf="!isFromManager" style="padding-top: 30px"></div>
  <div class="head-obsertype d-flex justify-content-between">
    <div class="box-searchObser">
      <input type="text" [(ngModel)]="inputValue" placeholder="Tìm kiếm..." (keydown.enter)="searchObser(true)" />
      <button class="" style="border-radius: 0px 5px 5px 0px" nz-button nzType="primary" (click)="searchObser(true)">
        <i nz-icon nzType="search" nzTheme="outline"></i>
      </button>
    </div>
    <div class="table-operations">
      <button class="btn-ex btn-export" nz-button [nzLoading]="isExporting" (click)="export()">
        <i nz-icon nzType="export" nzTheme="outline"></i>Export
      </button>
      <button class="btn-ex btn-import" nz-button (click)="openImport()">
        <i nz-icon nzType="import" nzTheme="outline"></i>Import
      </button>
      <button class="mg-r-10 btn-green" nz-button (click)="showModal('them')">
        <i nz-icon nzType="plus"></i>Thêm mới
      </button>
      <button [disabled]="selectedRowIndex == emptyId" class="mg-r-10" nz-button nzType="primary"
        (click)="showModal(dataForm)">
        <i nz-icon nzType="form"></i>Sửa
      </button>
      <!-- <button [disabled]="selectedRowIndex == emptyId" class="" nz-button nzType="danger"
        (click)="deleteOberType()"><i nz-icon nzType="close"></i>Xoá</button> -->
    </div>
  </div>
  <div class="import-file" *ngIf="isShowImport">
    <div class="mg-bt-10">
      <button nz-button class="mg-r-15" (click)="
          downloadFile(
            '/assets/download/observation_type_sample.xlsx',
            'observation_type_sample.xlsx'
          )
        ">
        <i nz-icon nzType="download" nzTheme="outline"></i>DownLoad Sample
      </button>
      <input id="fileOpen" type="file" multiple="false" accept=".xlsx, .xls, .csv" (click)="clearValue($event)"
        (change)="onFileChange($event)" />
    </div>
    <nz-table #importDataTable nzSize="small" [nzData]="lstDataReadFromExcel" [nzShowPagination]="false"
      [nzScroll]="{ y: '400px' }">
      <thead>
        <tr>
          <th nzWidth="60px">STT</th>
          <th>Name*</th>
          <th>OT code*</th>
          <th>Loại*</th>
          <th>Mô tả</th>
          <th>Kiểu dữ liệu*</th>
          <th>Đơn vị</th>
          <th>Trạng thái</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of lstDataReadFromExcel; index as i"
          [ngClass]="lstStatusFromExcel[i] !== 'Valid' ? 'error-data' : ''">
          <td>{{ i + 1 }}</td>
          <td>{{ data.name || '#N/A?' }}</td>
          <td>{{ data.otCode || '#N/A?' }}</td>
          <td>{{ data.categoryText || '#N/A?' }}</td>
          <td>{{ data.description }}</td>
          <td>{{ data.datatypeText || '#N/A?' }}</td>
          <td>{{ data.unit }}</td>
          <td [innerHtml]="lstStatusFromExcel[i]"></td>
        </tr>
      </tbody>
    </nz-table>
    <button nz-button class="mg-t-10" (click)="import()" [disabled]="!validDataExcel">
      Import Data
    </button>
  </div>

  <nz-table #fixedTable nzSize="small" [nzData]="listObservationType" [nzFrontPagination]="false" [nzTotal]="total"
    (nzQueryParams)="onQueryParamsChange($event)" [nzShowTotal]="rangeTemplate" nzShowSizeChanger
    [nzPageSize]="pageSize" [(nzPageIndex)]="pageIndex" nzShowSizeChanger="" [nzPageSizeOptions]="[10, 20, 50, 100]"
    [nzScroll]="{ y: 'calc(100vh - 250px)' }">
    <thead>
      <tr>
        <th nzWidth="60px">STT</th>
        <th>Name</th>
        <th>OT code</th>
        <th>Mã OT map với HT khác</th>
        <th>Loại</th>
        <th>Kiểu dữ liệu</th>
        <th>Đơn vị</th>
        <th>Người tạo</th>
        <th>Thời gian tạo</th>
        <th nzWidth="60px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of fixedTable.data; let i = index" [ngClass]="{ 'highlight': selectedRowIndex == data.id }"
        (click)="rowSelected(data)">
        <td>
          {{ fixedTable.nzPageSize * (fixedTable.nzPageIndex - 1) + i + 1 }}
        </td>
        <!-- <td (click)="updateStatus(data)">
          <i *ngIf="!data.isDisabled" nz-icon nzType="check-circle" [nzTheme]="'twotone'"
            [nzTwotoneColor]="'#52c41a'"></i>
          <i *ngIf="data.isDisabled" nz-icon nzType="stop" [nzTheme]="'twotone'" [nzTwotoneColor]="'#ff0000'"></i>
        </td> -->
        <td>{{ data.name }}</td>
        <td>{{ data.otCode }}</td>
        <td>
          <div *ngFor="let en of data.listCodeMapSystem">
            {{ en.otherSystem }}: {{ en.codeOther }}
          </div>
        </td>
        <td>{{ data.categoryText }}</td>
        <td>{{ data.datatypeText }}</td>
        <td>{{ data.unit }}</td>
        <td>{{ data.creatorName }}</td>
        <td>{{ data.dateCreated | date: 'dd-MM-yyyy HH:mm' }}</td>
        <td (click)="updateStatus(data.isDisabled, i)">
          <i *ngIf="!data.isDisabled" nz-icon nzType="check-circle" [nzTheme]="'twotone'"
            [nzTwotoneColor]="'#52c41a'"></i>
          <i *ngIf="data.isDisabled" nz-icon nzType="stop" [nzTheme]="'twotone'" [nzTwotoneColor]="'#ff0000'"></i>
        </td>
      </tr>
    </tbody>
    <ng-template #rangeTemplate let-range="range" let-total>
      {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }}
    </ng-template>
  </nz-table>
</div>

<!-- popup thêm, sửa -->
<nz-modal class="modal-admin" nzWidth="800px" [(nzVisible)]="isVisible" nzTitle="Observation Type" nzDraggable="true"
  nzMaskClosable="false" (nzClosable)="(true)" (nzOnCancel)="handleCancel()">
  <div class="row">
    <div class="col-md-6">
      <div class="mgb-10 row">
        <label class="col-md-2">Mã <span class="red-dot">*</span></label>
        <div class="col-md-10">
          <input nz-input type="text" [(ngModel)]="dataForm.otCode" placeholder="Nhập mã observation type" />
        </div>
      </div>
      <div class="mgb-10 row">
        <label class="col-md-2">Tên <span class="red-dot">*</span></label>
        <div class="col-md-10">
          <input nz-input type="text" [(ngModel)]="dataForm.name" placeholder="Nhập tên observation type" />
        </div>
      </div>
      <div class="mgb-10 row">
        <label class="col-md-2">Loại</label>
        <div class="col-md-10">
          <nz-select style="width: 100%" [(ngModel)]="dataForm.category" nzPlaceholder="Chọn loại">
            <nz-option [nzValue]="0" nzLabel="Laboratory">Laboratory</nz-option>
            <nz-option [nzValue]="1" nzLabel="Procedure">Procedure</nz-option>
            <nz-option [nzValue]="2" nzLabel="Vital Signs">Vital Signs</nz-option>
            <nz-option [nzValue]="3" nzLabel="Imaging">Imaging</nz-option>
            <nz-option [nzValue]="4" nzLabel="Measure">Measure</nz-option>
            <nz-option [nzValue]="99" nzLabel="Custom">Custom</nz-option>
          </nz-select>
        </div>
      </div>
      <div class="mgb-10 row">
        <label class="col-md-2">Mô tả</label>
        <div class="col-md-10">
          <textarea [rows]="2" nz-input [(ngModel)]="dataForm.description" placeholder="Nhập mã mô tả"></textarea>
        </div>
      </div>
      <div class="">
        <label>Mã OT map với hệ thống khác</label>
        <div class="other-system">
          <div class="item-system" *ngFor="let en of listCodeMapSystem; index as i">
            <div class="width-divided">
              <nz-select [(ngModel)]="en.otherSystem" nzPlaceHolder="Chọn hệ thống">
                <nz-option nzCustomContent *ngFor="let item of systemCodeDataSource" [nzValue]="item.sysCode"
                  [nzLabel]="item.sysCode">
                  <div>{{ item.sysCode }} - {{ item.sysName }}</div>
                </nz-option>
              </nz-select>
            </div>
            <div class="width-divided btn-sub">
              <input [(ngModel)]="en.codeOther" nz-input placeholder="Nhập mã" />
              <i *ngIf="i != 0" nz-icon nzType="minus-circle" nzTheme="outline" (click)="removeCodeMap(i)"></i>
              <i *ngIf="i == 0" nz-icon nzType="plus-circle" nzTheme="outline" (click)="addCodeMap()"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="mgb-10 row">
        <div class="col-3">Trạng thái</div>
        <nz-switch class="col-9" [(ngModel)]="!dataForm.isDisabled"></nz-switch>
      </div>
      <div class="msc-admin-row">
        <div class="msc-group-control width-obser">
          <label>Phạm vi sử dụng</label>
          <nz-radio-group [(ngModel)]="dataForm.privacyUse" (ngModelChange)="changeSelectedPrivacy($event)"
            fxLayout="row">
            <label nz-radio [nzDisabled]="ishidePrivacy1" [nzValue]="1">Toàn hệ thống</label>
            <label nz-radio [nzDisabled]="ishidePrivacy3" [nzValue]="3">Chỉ mình tôi</label>
            <label nz-radio [nzDisabled]="ishidePrivacy2" [nzValue]="2">Trong nhóm</label>
          </nz-radio-group>
        </div>
      </div>
    </div>
    <div class="col-md-6" style="border-right: 1px solid">
      <div class="">
        <div class="row mgb-10">
          <label class="col-md-4">Kiểu dữ liệu</label>
          <div class="col-md-8">
            <nz-select style="width: 100%" [(ngModel)]="dataForm.dataType">
              <nz-option [nzValue]="1" nzLabel="Number">Number</nz-option>
              <nz-option [nzValue]="11" nzLabel="Positive/Negative">Positive/Negative</nz-option>
              <nz-option [nzValue]="2" nzLabel="Range">Range</nz-option>
              <nz-option [nzValue]="3" nzLabel="MoreLess">MoreLess</nz-option>
              <nz-option [nzValue]="4" nzLabel="Boolean">Boolean</nz-option>
              <nz-option [nzValue]="5" nzLabel="String">String</nz-option>
              <nz-option [nzValue]="6" nzLabel="Time">Time</nz-option>
              <nz-option [nzValue]="7" nzLabel="Date">Date</nz-option>
              <nz-option [nzValue]="8" nzLabel="DateTime">DateTime</nz-option>
              <nz-option [nzValue]="9" nzLabel="SingleChoice">SingleChoice</nz-option>
              <nz-option [nzValue]="10" nzLabel="MultipleChoice">MultipleChoice</nz-option>
            </nz-select>
          </div>
        </div>
        <div class="">
          <div *ngIf="dataForm.dataType == 1 || dataForm.dataType == 2" class="row mgb-10">
            <label class="col-md-4">Đơn vị</label>
            <div class="col-md-8">
              <input nz-input [(ngModel)]="dataForm.unit" type="text" placeholder="Nhập đơn vị" />
            </div>
          </div>
          <div *ngIf="dataForm.dataType == 9 || dataForm.dataType == 10" class="row mgb-10">
            <label class="col-md-4"></label>
            <div class="col-md-8">
              <button class="btn-add-question" (click)="addQuestion()">
                + Thêm câu trả lời
              </button>
            </div>
          </div>
        </div>
        <div class="row mgb-10" *ngIf="dataForm.dataType == 1">
          <label class="col-md-4">Valid value</label>
          <div class="col-md-8 msc-admin-row-even">
            từ
            <nz-input-number nz-input [(ngModel)]="dataForm.validMinValue"></nz-input-number>
            đến
            <nz-input-number nz-input [(ngModel)]="dataForm.validMaxValue"></nz-input-number>
          </div>
        </div>
      </div>
      <div *ngIf="dataForm.dataType == 9 || dataForm.dataType == 10" class="msc-admin-row">
        <div id="list-wrapper" class="msc-group-control width-obser">
          <div id="listObserQuestions">
            <div *ngFor="let product of dataForm.dataSource">
              <div>
                <input [(ngModel)]="product.text" class="questions" type="text" />&nbsp;
                <nz-input-number nz-input [(ngModel)]="product.valueNumber"
                  style="width: 64px; margin-right: 8px"></nz-input-number>
                <i title="Xoá câu trả lời" nz-icon nzType="delete" nzTheme="outline" (click)="deleteQuestion(product)"
                  style="
                    color: #fc5959;
                    font-size: 16px;
                    margin-right: 8px;
                  "></i>
                <i title="Kích hoạt trả lời" nz-icon nzType="enable" nzTheme="eye" *ngIf="product.status == false"
                  (click)="toggleStatus(product)" style="color: #1890ff; font-size: 16px"></i>
                <i title="Vô hiệu hoá câu trả lời" nz-icon nzType="eye-invisible" nzTheme="outline"
                  *ngIf="product.status == true" (click)="toggleStatus(product)"
                  style="color: #1890ff; font-size: 16px"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mgb-10" *ngIf="dataForm.dataType == 11">
        <label class="col-md-4">Normal range</label>
        <div class="col-md-8">
          <ng-container *ngFor="let item of lstRangeProperties; index as i">
            <nz-radio-group [(ngModel)]="item.normalValue">
              <label nz-radio nzChecked [nzValue]="false">Negative</label>
              <label nz-radio [nzValue]="true">Positive</label>
            </nz-radio-group>
          </ng-container>
        </div>
      </div>
      <div class="row mgb-10" *ngIf="dataForm.dataType == 4">
        <label class="col-md-4">Normal range</label>
        <div class="col-md-8">
          <ng-container *ngFor="let item of lstRangeProperties; index as i">
            <nz-radio-group [(ngModel)]="item.normalValue">
              <label nz-radio nzChecked nzValue="false">No</label>
              <label nz-radio nzValue="true">Yes</label>
            </nz-radio-group>
          </ng-container>
        </div>
      </div>
      <div class="row mgb-10" *ngIf="dataForm.dataType == 9">
        <label class="col-md-4">Normal range</label>
        <div class="col-md-8">
          <ng-container *ngFor="let item of lstRangeProperties; index as i">
            <nz-select style="width: 100%" nzPlaceHolder="Chọn giá trị" [(ngModel)]="item.normalString">
              <nz-option *ngFor="let product of dataForm.dataSource" [nzLabel]="product.text" [nzValue]="product.id">
              </nz-option>
            </nz-select>
          </ng-container>
        </div>
      </div>
      <div class="range-div customTableCrollbar" style="margin-bottom: 20px; background: #dedede69"
        *ngIf="dataForm.dataType == 1 || dataForm.dataType == 2">
        <ng-container *ngFor="let item of lstRangeProperties; index as i">
          <div style="padding: 10px; border-bottom: 1px solid #fff">
            <div>
              <div>
                Lọc theo:
                <i *ngIf="i == 0" nz-icon nzType="plus-circle" style="float: right" (click)="addNewRange()"
                  nzTheme="outline"></i>
                <i *ngIf="i != 0" nz-icon nzType="minus-circle" style="float: right" (click)="deleteRange(item)"
                  nzTheme="outline"></i>
              </div>
              <div class="msc-admin-row-odd" style="padding-left: 10px">
                <div class="" style="margin-bottom: 5px">
                  <input type="checkbox" [checked]="item.enableAge" (change)="enable('Age', item)" title="Tuổi" />
                  <label>&nbsp;Tuổi: </label>
                  từ &nbsp;
                  <nz-input-number [nzDisabled]="!item.enableAge" nz-input [(ngModel)]="item.minAge"></nz-input-number>
                  đến &nbsp;
                  <nz-input-number [nzDisabled]="!item.enableAge" nz-input [(ngModel)]="item.maxAge"></nz-input-number>
                </div>
                <div class="" style="margin-bottom: 5px">
                  <span class="">
                    <input type="checkbox" [checked]="item.enableGender" (change)="enable('Gender', item)"
                      title="Giới tính" />
                    <label>&nbsp;Giới tính&nbsp;</label>
                    <nz-select class="sl-100" [(ngModel)]="item.genderCode" [nzDisabled]="!item.enableGender">
                      <nz-option nzValue="248152002" nzLabel="Nữ"></nz-option>
                      <nz-option nzValue="248153007" nzLabel="Nam"></nz-option>
                    </nz-select>
                  </span>
                  <span class="">
                    <input type="checkbox" title="Thời gian" [checked]="item.enableCurrTime"
                      (change)="enable('CurrTime', item)" />
                    <label>&nbsp;Thời gian&nbsp;</label>
                    <nz-select class="sl-100" [(ngModel)]="item.currTimeCode" [nzDisabled]="!item.enableCurrTime">
                      <nz-option nzValue="BDHAM" nzLabel="Sáng"></nz-option>
                      <nz-option nzValue="BDHPM" nzLabel="Chiều"></nz-option>
                    </nz-select>
                  </span>
                </div>
              </div>
            </div>
            <div class="msc-admin-row-even">
              <span>Giá trị:</span>
              <span class="">
                từ
                <nz-input-number nz-input [(ngModel)]="item.minValue"></nz-input-number>
                đến
                <nz-input-number nz-input [(ngModel)]="item.maxValue"></nz-input-number>
              </span>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="isShowChooseGroup" class="msc-admin-row">
        <div class="width-obser">
          <label>Chọn nhóm</label>
          <input style="width: 315px" #keywordPatient placeholder="Nhập tên nhóm muốn chia sẻ" minLength="2" nz-input
            (input)="onSearchForm(keywordPatient.value)" [nzAutocomplete]="auto" />
          <nz-autocomplete #auto>
            <nz-auto-option *ngFor="let item of searchDataFilter" [nzValue]="item.name" (click)="selectForm(item)">
              <span>
                <p>{{ item.name }}</p>
              </span>
            </nz-auto-option>
          </nz-autocomplete>
        </div>
      </div>
      <div *ngIf="isShowChooseGroup" class="msc-admin-row">
        <div class="msc-group-control width-obser">
          <label>Danh sách nhóm</label>
          <ul id="list-group">
            <li *ngFor="let item of selectedForms">
              {{ item.name }}
              <i nz-icon nzType="close" nzTheme="outline" style="float: right" (click)="unSelectForm(item.id)"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button *ngIf="selectedRowIndex != emptyId" nz-button (click)="addOrSave(dataForm)">
      Sửa
    </button>
    <button *ngIf="selectedRowIndex == emptyId" nz-button (click)="addOrSave(dataForm)">
      Thêm
    </button>
    <button nz-button nzType="default" (click)="handleCancel()">Huỷ</button>
  </div>
</nz-modal>