<div class="box-left borderVisitInfo">
  <div>Danh sách phiếu dữ liệu</div>
  <div class="box-subvisit">
    <ul class="customTableCrollbar">
      <li *ngFor="let record of listRecord" title="Click đúp để mở phiếu"
        [ngClass]="{ 'highlight-select': selectedRowSubVisit == record.id }" (click)="chooseSubVisit(record.id)">
        <div (dblclick)="openTabPhieudl(record)">
          <i style="margin-right: 15px">
            <img src="assets/image/DPicon/clinical-form.svg" />
          </i>
          {{ record.name }}
        </div>
        <span class="{{ hideButton }}">
          <!-- <i
            style="margin-right: 15px"
            *ngIf="selectedRowSubVisit == record.id"
            class="icon-suborder"
            (click)="openTabPhieudl(record)">
            <img src="assets/image/DPicon/edit-pencil.svg" />
          </i> -->
          <i style="margin-right: 15px" class="icon-suborder" (click)="openTabPhieudl(record)">
            <img src="assets/image/DPicon/edit-pencil.svg" />
          </i>
          <!-- <i *ngIf="selectedRowSubVisit == record.id" class="icon-suborder" (click)="deleteRecordVisit(record.id)">
            <img src="assets/image/DPicon/icon-sub-green.svg" />
          </i> -->
          <i class="icon-suborder" (click)="deleteRecordVisit(record.id)">
            <img src="assets/image/DPicon/icon-sub-green.svg" />
          </i>
        </span>
      </li>
    </ul>
  </div>
</div>