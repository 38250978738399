import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfigService } from '../../../../app-config.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UrlConstant } from 'src/app/share/constants/url.class';
import { GroupTreeService } from '../../../services/groupTree.service';
import { MenuItemTree } from 'src/app/models/grouptree.model';
@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss'],
  providers: [GroupTreeService],
})
export class AddGroupComponent implements OnInit {
  @Input() isVisible: any;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() addGroup = new EventEmitter<any>();

  urlService: string;
  fileName: string;
  baseImageurl = '/GroupImg/';
  loading = false;
  header?: any;
  formAddGroup: FormGroup;
  submitted = false;
  constructor(
    private configService: AppConfigService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private groupTreeService: GroupTreeService
  ) {
    this.urlService =
      configService.getConfig().api.baseUrl +
      UrlConstant.UPLOAD_FILE +
      '?TypeUpload=upload_avatar&FileCategory=groupimg';
    this.baseImageurl =
      configService.getConfig().media.baseUrl + this.baseImageurl;
    this.header = {
      Authorization: 'Bearer ' + localStorage.getItem(Constant.TOKEN),
    };
    this.formAddGroup = this.formBuilder.group({
      Id: ['000000000000000000000000'],
      Name: [''],
      shortCode: [''],
      UrlImage: [''],
    });
  }

  ngOnInit(): void {}

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        // this.msg.error('You can only upload JPG file!');
        observer.complete();
        return;
      }
      const isLt2M = (file.size ?? 0) / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.WARRING,
          'Image must smaller than 2MB!'
        );
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng && isLt2M);
      observer.complete();
    });

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.INFO,
          'Uploading'
        );
        break;
      case 'done':
        // this.avatarUrl = info.file.thumbUrl;
        this.fileName = info.file.response.fileName;
        // this.formAddPatient.controls['avatar'].setValue(info.file.response["fileName"]);
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Success'
        );
        break;
      case 'error':
        // this.msg.error('Network error');
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Error'
        );
        this.loading = false;
        break;
    }
  }
  handleCancel(): void {
    this.isVisible = false;
    this.closeModal.emit(false);
  }
  handleOk(): void {
    // let height = this.container.nativeElement.offsetHeight;
    // let naturalHeight = this.container.nativeElement.naturalHeight;
    // let naturalWidth = this.container.nativeElement.naturalWidth;
    // let width = height * naturalWidth / naturalHeight;

    // this.addImage.emit({ type: this.imageType, src: this.avatarUrl, height: height, width: width });
    this.isVisible = false;
    this.closeModal.emit(false);

    //this.isVisibleChange.emit(this.isVisible);
    if (this.formAddGroup.valid) {
      this.submitted = false;

      const payload = {
        Id: '000000000000000000000000',
        Name: this.formAddGroup.value.Name,
        shortCode: this.formAddGroup.value.shortCode,
        UrlImage: this.fileName == undefined ? '' : this.fileName,
        FormReportIds: [],
      };

      this.groupTreeService.addGroup(payload).subscribe(
        (res) => {
          if (res !== null) {
            if (res.isValid) {
              this.notificationService.showNotification(
                Constant.NOTIFY_TYPE.SUCCESS,
                'Thêm nhóm thành công'
              );
              const newMenu = new MenuItemTree();
              newMenu.name = this.formAddGroup.value.Name;
              //newMenu.text=this.formAddGroup.value.Name;
              newMenu.id = res.jsonData;
              newMenu.icon =
                this.fileName == undefined
                  ? 'assets/image/group.svg'
                  : this.fileName;
              newMenu.children = null;
              newMenu.expanded = false;
              newMenu.isLeaf = true;
              newMenu.selectable = false;
              this.addGroup.emit(newMenu);
            } else {
              if (res.errors && res.errors.length > 0) {
                this.notificationService.showNotification(
                  Constant.NOTIFY_TYPE.WARRING,
                  res.errors[0].errorMessage
                );
              }
            }
          }
          this.formAddGroup.reset();
        },
        (error) => {
          console.log('lept lỗi');
        }
      );
    }
  }
}
