import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { GroupTreeService } from 'src/app/services/groupTree.service';
import { ListFormService } from 'src/app/services/listForm-service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state/app-state';
import { ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { CriteriaService } from 'src/app/services/criteria.service';
import { RulesManagermentService } from 'src/app/services/rules-managerment.service';
import { time } from '@amcharts/amcharts4/core';

@Component({
  selector: 'app-rules-managerment',
  templateUrl: './rules-managerment.component.html',
  styleUrls: ['./rules-managerment.component.scss'],
})
export class RulesManagermentComponent implements OnInit {
  readonly DataType: typeof Constant.DATATYPENUMBER = Constant.DATATYPENUMBER;
  @Output() refreshData = new EventEmitter<any>();
  @Output() usedSample = new EventEmitter<any>();
  @Output() isVisibleChange = new EventEmitter<any>();
  lstObservationType: any = [];
  lstRulesManagerment: any;
  numRow = 0;
  total = 0;
  loading = false;
  pageIndex = 1;
  pageSize = 20;
  isVisibleForm = false;
  processForm: FormGroup;
  switchValue = true;
  searchLogicalOperator = [];
  searchParameter = [];

  listCriteriaForm: {
    idx: number;
    id: string;
    operator: 0;
    operatorString: string;
    logicalOperator: 0;
    parameter: string;
    parameterTitle: string;
    parameterValue: string;
  }[] = [];

  listActionForm: {
    idx: number;
    id: string;
    title: string;
    detail: string;
    minutesBeforeReminder: 0;
  }[] = [];
  dataForm: {
    id: string;
    name: string;
    description: string;
    groupId: string;
    status: true;
    privacyUse: number;
  } = null;
  textSearch = '';
  search: any = {
    name: '',
    status: -1,
  };
  searchDataGroupFilter = [];
  lstGroupShare: {
    id: string;
    name: string;
  }[] = [];
  isProcessFormAdd = false;
  submitted = false;
  groupShare: any;
  curUserId = '';
  curGroupId = '';
  curGroupName = '';
  fromAdminPage = false;
  status = -1;
  isRulesManager = false;
  isOnlyViewData = false;

  visibleCriteriaModal = false;
  visibleActionModal = false;
  criteriaForm: FormGroup;
  actionForm: FormGroup;
  pushNotifyTimes = [];
  isAddCriteria = false;
  isAddAction = false;

  isAdminGroup = false;
  privacyUse = Constant.PrivacyUse;
  logicalOperator = [
    {
      name: 'AND',
      code: 1,
    },
    {
      name: 'OR',
      code: 2,
    },
  ];
  searchOperator = [
    {
      name: 'Equal',
      code: 1,
    },
    {
      name: 'Greater',
      code: 2,
    },
    {
      name: 'Less',
      code: 3,
    },
    {
      name: 'Contain',
      code: 4,
    },
    {
      name: 'Less And Equal',
      code: 5,
    },
    {
      name: 'Greater And Equal',
      code: 6,
    },
    {
      name: 'Not Equal',
      code: 7,
    },
  ];

  disableActiveDate: boolean;
  disableActiveFormTo: boolean;
  isShowListSample = false;
  dataSearch: any = {
    patientName: '',
    doctorName: '',
    patientCode: '',
    patientPhoneNo: '',
    status: -1,
    from: '',
    to: '',
  };
  groupId: string;
  list_reminders = [];
  selectedParameter: {
    dataType: 1;
  };
  dataType: number;
  validMinValue: number;
  isLoading = false;
  isRulesManagerment = false;
  constructor(
    // private covidService: CovidService,
    private fb: FormBuilder,
    private criteriaService: CriteriaService,
    private rulesManagermentService: RulesManagermentService,
    private notificationService: NotificationService,
    private groupTreeService: GroupTreeService,
    private shareService: ShareService,
    private modalService: NzModalService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.processForm = this.fb.group({
      id: [''],
      name: [null, [Validators.required]],
      description: [null, [Validators.required]],
      groupId: [null],
      status: [true, [Validators.required]],
      privacyUse: [null, [Validators.required]],
    });
    this.criteriaForm = this.fb.group({
      id: [null],
      idx: [null],
      operator: [null],
      logicalOperator: [null],
      parameter: [null, [Validators.required, this.noWhitespaceValidator]],
      parameterValue: [null, [Validators.required, this.noWhitespaceValidator]],
      dataType: [null],
      parameterValueText: [null],
    });
    this.actionForm = this.fb.group({
      id: [null],
      idx: [null],
      title: [null, [Validators.required]],
      detail: [null],
      minutesBeforeReminder: [null, [Validators.required, this.noWhitespaceValidator]],
    });
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
    this.store
      .select((store) => store.groupAuth)
      .subscribe((data) => {
        this.curGroupId = data.groupId;
        this.curGroupName = data.groupName;
        this.isAdminGroup =
          data.role == Constant.UserRoleType.Admin || data.role == Constant.UserRoleType.Owner ? true : false;
      });
    this.store
      .select((store) => store.auth.userType)
      .subscribe((data) => {
        if (data.indexOf(Constant.USERROLE.RulesManagermentMngt) >= 0) {
          this.isRulesManagerment = true;
        }
      });
    const urlSegment = this.route.snapshot.url;
    if (urlSegment[0].path == 'rules-managerment') {
      this.fromAdminPage = true;
      this.isRulesManagerment = true;
    }
  }

  ngOnInit(): void {
    this.getRulesManagerment();
    this.getListParameter();
    //this.selectedParameter = {};
    this.criteriaForm.controls.parameter.valueChanges.subscribe((val) => {
      console.log(val);
      var objs = this.searchParameter.filter((en) => en.defaultField == val);
      var first = objs[0];
      if (first != null) this.selectedParameter = first;
      // this.searchOperator = first;
      // this.criteriaForm.patchValue({
      //   parameterValue: first.dataSource
      // })
      console.log('okkkkk', this.selectedParameter);
    });
    
  }

  noWhitespaceValidator(control: FormControl) {
    // const isWhitespace = (control.value || '').trim().length === 0;
    // const isValid = !isWhitespace;
    // return isValid ? null : { isWhitespace: true, error: true };
    return true;
  }
  get processFormData() {
    return this.processForm.controls;
  }
  getRulesManagerment() {
    const payload = {
      name: this.search.name,
      skip: (this.pageIndex - 1) * this.pageSize,
      take: this.pageSize,
      groupId: this.curGroupId,
      status: this.search.status,
      fromAdminPage: this.fromAdminPage
    };
    this.rulesManagermentService.getListCDSRule(payload).subscribe((res) => {
      if (res.isValid) {
        this.lstRulesManagerment = res.jsonData.source;
        console.log('getRulesManagerment', this.lstRulesManagerment);

        this.total = res.jsonData.total;
        console.log('hoang', this.total);
      }
    });
  }
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    this.getRulesManagerment();
  }
  showModalForm(type, data) {
    console.log('showModalForm');
    if (type == 'add') {
      this.isOnlyViewData = false;
      this.processForm.patchValue({
        id: '',
        name: '',
        description: '',
        groupId: '',
        status: true,
        privacyUse: 3,
      });
      this.listCriteriaForm = [];
      this.listActionForm = [];
      this.lstGroupShare = [];
      if (!this.fromAdminPage && this.isAdminGroup) {
        this.lstGroupShare.push({
          id: this.curGroupId,
          name: this.curGroupName,
        });
      }
      this.isProcessFormAdd = true;
    } else if (type == 'update' || type == 'view') {
      if (type == 'view') {
        this.isOnlyViewData = true;
      } else {
        this.isOnlyViewData = false;
      }
      const item = JSON.parse(JSON.stringify(data));
      this.processForm.patchValue({
        id: item.id,
        name: item.name,
        description: item.description,
        groupId: item.groupId,
        status: item.status,
        privacyUse: item.privacyUse,
      });
      this.listCriteriaForm = item.criterias;
      this.listActionForm = item.actions;
      this.lstGroupShare = item.groupShare;
      this.isProcessFormAdd = false;

      console.log('showModalUpdate', this.listCriteriaForm);
    }
    this.isVisibleForm = true;
  }
  removeRulesManagerment(id) {
    console.log('id', id);
    if (!this.shareService.checkEmpty(id)) {
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn xóa hay không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Bỏ qua',
        nzOnOk: () => {
          this.rulesManagermentService.removeRulesManagerment(id).subscribe((res) => {
            if (res.isValid) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xóa thành công');
              this.isVisibleForm = false;
              this.getRulesManagerment();
            } else {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
            }
          });
        },
      });
    }
  }
  updateStatus(ruleId, status, idx) {
    if (!this.shareService.checkEmpty(ruleId)) {
      const content = status ? 'inactive' : 'active';
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn ' + content + ' quy trình này hay không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Bỏ qua',
        nzOnOk: () => {
          this.rulesManagermentService.updateStatus(ruleId, !status).subscribe((res) => {
            if (res.isValid) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
              this.lstRulesManagerment[idx].status = !status;
            } else {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
            }
          });
        },
      });
    }
  }
  handleCancel() {
    this.isVisibleForm = false;
    this.submitted = false;
    this.isVisibleChange.emit(false);
  }
  counter(i: number) {
    return new Array(i);
  }
  saveRulesManagerment() {
    console.log('saveRulesManagerment', this.processForm.valid);
    this.submitted = true;
    if (this.processForm.valid) {
      this.listCriteriaForm.forEach((en) => {
        delete en.idx;
      });
      this.listCriteriaForm.forEach((en) => delete en.idx);

      const notInForm = {
        criterias: this.listCriteriaForm,
        actions: this.listActionForm,
        groupIds: this.lstGroupShare.map((en) => en.id),

      };
      console.log('listCriteriaForm', this.listCriteriaForm);

      const payload = { ...this.processForm.value, ...notInForm };
      console.log('saveRulesManagerment', payload);

      this.rulesManagermentService
      .saveRulesManagerment(payload)
      .subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
          this.isVisibleForm = false;
          this.submitted = false;
          this.getRulesManagerment();
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    }
  }
  onSearchParameter(keyword) {
    this.criteriaService.getParameter().subscribe(
      (response) => {
        if (response !== null && response.source != null) {
          this.searchLogicalOperator = [...response.source];
        }
      },
      (error) => {
        //console.log(error);
        this.notificationService.showNotification('', 'Lỗi data');
      }
    );
  }
  getListParameter() {
    this.criteriaService.getParameter().subscribe(
      (response) => {
        console.log('respone parmam', response);
        if (response !== null) {
          this.searchParameter = [...response];
          console.log('searchParameter0', this.searchParameter);
          this.searchParameter = this.searchParameter.filter(
            (it) =>
              it.dataType === Constant.DATATYPENUMBER.BOOLEAN ||
              it.dataType === Constant.DATATYPENUMBER.SINGLECHOICE ||
              it.dataType === Constant.DATATYPENUMBER.AGE ||
              it.dataType === Constant.DATATYPENUMBER.NUMBER ||
              it.dataType === Constant.DATATYPENUMBER.STRING
          );
          console.log('searchParameter', this.searchParameter);
        }
      },
      (error) => {
        this.notificationService.showNotification('', 'Lỗi data');
      }
    );
  }
  openModalCriteria(data, idx?) {
    if (data == 'add') {
      this.isAddCriteria = true;
      this.criteriaForm.reset();
    } else {
      this.isAddCriteria = false;
      const item = JSON.parse(JSON.stringify(data));
      this.criteriaForm.patchValue({
        idx,
        operator: item.operator,
        logicalOperator: item.logicalOperator,
        parameter: item.parameter,
        parameterValue: item.parameterValue,
        parameterValueText: item.parameterValueText,
      });
    }
    this.visibleCriteriaModal = true;
  }
  openModalAction(data, idx?) {
    if (data == 'add') {
      this.isAddAction = true;
      this.actionForm.reset();
    } else {
      this.isAddAction = false;
      const item = JSON.parse(JSON.stringify(data));
      this.actionForm.patchValue({
        idx,
        title: item.title,
        detail: item.detail,
        minutesBeforeReminder: item.minutesBeforeReminder,
      });
    }
    this.visibleActionModal = true;
  }

  saveCriteria() {
    if (this.criteriaForm.valid) {
      const newItem = this.criteriaForm.value;

      var objs = this.searchParameter.filter((en) => en.defaultField == newItem.parameter);
      var first = objs[0];
      newItem.parameterTitle = first.title;

      //  newItem.parameterValueText = first.=;
      let tieudeTT = this.getPrefix(newItem.operator);
      newItem.operatorString = tieudeTT;
      newItem.dataType = first.dataType;
      if (first.dataType == Constant.DATATYPENUMBER.SINGLECHOICE) {
        var ds = first.dataSource;
        var valFilter = ds.filter((it) => it.valueNumber == newItem.parameterValue);
        var val = valFilter[0];
        newItem.parameterValueText = val.text;
      }
      console.log('hh', first);
      console.log('saveCriteria', newItem, this.isAddCriteria);

      if (this.listCriteriaForm == null) {
         this.listCriteriaForm = [];
      }
      if (!this.isAddCriteria) {
        // edit old itemư
        this.listCriteriaForm[newItem.idx] = newItem;
      } else {
        console.log('hhh', this.listCriteriaForm, newItem);
        this.listCriteriaForm = [...this.listCriteriaForm, newItem];
      }
      this.visibleCriteriaModal = false;
      this.listCriteriaForm = [...this.listCriteriaForm];
    } else {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Bạn cần nhập các trường bắt buộc');
    }
  }

  removeCriteria(item) {
    if (!this.shareService.checkEmpty(item)) {
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn xóa hay không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Bỏ qua',
        nzOnOk: () => {
          this.listCriteriaForm = this.listCriteriaForm.filter((t) => t !== item);
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xóa thành công');
        },
      });
    }
  }
  removeAction(item) {
    if (!this.shareService.checkEmpty(item)) {
      this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: 'Bạn có muốn xóa hay không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Bỏ qua',
        nzOnOk: () => {
          this.listActionForm = this.listActionForm.filter((t) => t !== item);
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xóa thành công');
        },
      });
    }
  }

  convertTimeSelectionTime(dates) {
    if (this.shareService.checkEmpty(dates)) {
      return [];
    }
    const times = [];
    for (let i = 0; i < dates.length; i++) {
      const time = { hour: 0, minute: 0 };
      time.hour = dates[i].getHours();
      time.minute = dates[i].getMinutes();
    }
    return times;
  }

  handleCancelRmd() {
    this.visibleCriteriaModal = false;
  }
  handleCancelAction() {
    this.visibleActionModal = false;
  }
  handleCancelClc() {
    this.visibleActionModal = false;
  }
  usingSample(data) {
    this.actionForm.controls['title'].setValue(data.title);
    this.actionForm.controls['detail'].setValue(data.detail);
    this.actionForm.controls['minutesBeforeReminder'].setValue(data.minutesBeforeReminder);
    if (this.listActionForm == null) {
      this.listActionForm = [];
    }
    this.listActionForm = [...this.listActionForm, data];
  }
  toLogicalOperator(code) {
    return this.logicalOperator.filter((en) => en.code == code)[0].name;
  }
  getPrefix(cat) {
    switch (cat) {
      case 1:
        return 'Equal';
      case 2:
        return 'Greater';
      case 3:
        return 'Less';
      case 4:
        return 'Contain';
      case 5:
        return 'Less And Equal';
      case 6:
        return 'Greater And Equal';
      default:
        return '';
    }
  }
  getShortContent(str: any, maxLength = 24) {
    if (!str) {
      return '';
    }
    if (str.length < maxLength) {
      return str;
    } else {
      return str.substring(0, maxLength) + '..';
    }
  }

  toPrivacyText(x): string {
    switch (x) {
      case 1:
        return 'Toàn hệ thống';
      case 2:
        return 'Trong nhóm BS';
      case 3:
        return 'Chỉ mình tôi';
      default:
        return '';
    }
  }
  onSearchGroup(keyword) {
    if (keyword.trim().length > 1) {
      this.groupTreeService.getListGroup(keyword).subscribe(
        (response) => {
          if (response !== null) {
            this.searchDataGroupFilter = [...response];
          }
        },
        (error) => {
          console.error(error);
          this.notificationService.showNotification('', 'Lỗi data');
        }
      );
    }
  }
  selectGroup(group) {
    if (this.lstGroupShare.filter((en) => en.id == group.id).length == 0) {
      this.lstGroupShare.push({ id: group.id, name: group.name });
    }
    this.groupShare = '';
  }
  unSelectGroup(id) {
    this.lstGroupShare = this.lstGroupShare.filter((en) => en.id !== id);
  }
}
