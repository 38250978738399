<div *ngIf="isVisible" class="form-admin">
  <nz-layout class="inner-layout">
    <nz-sider nzWidth="310px" nzTheme="light">
      <app-dpform-info
        #dfFromInfo
        [adminPage]="adminPage"
        [form]="form"
        (addCommonInfoEvent)="addCommonInfo($event)"
        (addOTItemEvent)="add_OT_Item($event)"
        (addOTRecentEvent)="add_OT_Recent($event)"></app-dpform-info>
    </nz-sider>
    <nz-content class="inner-content">
      <!-- <div id="wholepage"> -->
      <ng-container #allPage>
        <span
          class="toolbar"
          [ngStyle]="{ 'display': ishaveBlockSelected ? 'block' : 'none' }">
          <span
            (click)="movePageUP()"
            nz-tooltip
            nzTooltipPlacement="right"
            nzTooltipTitle="Lên"
            [ngStyle]="{ 'display': isPage ? 'grid' : 'none' }">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-arrow-up"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
            </svg>
          </span>
          <span
            (click)="movePageDown()"
            nz-tooltip
            nzTooltipPlacement="right"
            nzTooltipTitle="Xuống"
            [ngStyle]="{ 'display': isPage ? 'grid' : 'none' }">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-arrow-down"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
            </svg>
          </span>
          <span
            (click)="minusSize()"
            nz-tooltip
            nzTooltipPlacement="right"
            nzTooltipTitle="Giảm kích thước trang"
            [ngStyle]="{ 'display': isPage ? 'grid' : 'none' }">
            <i nz-icon nzType="minus" nzTheme="outline"></i>
          </span>
          <span
            (click)="addSize()"
            nz-tooltip
            nzTooltipPlacement="right"
            nzTooltipTitle="Tăng kích thước trang"
            [ngStyle]="{ 'display': isPage ? 'grid' : 'none' }">
            <i nz-icon nzType="plus" nzTheme="outline"></i>
          </span>
          <span
            (click)="downFocusChild()"
            nz-tooltip
            nzTooltipPlacement="right"
            nzTooltipTitle="Down Children"
            [ngStyle]="{
              'display': !isBlockCanAddItems && isPage ? 'grid' : 'none'
            }">
            <i nz-icon nzType="plus" nzTheme="outline"></i
          ></span>
          <span
            (click)="downFocusChild()"
            nz-tooltip
            nzTooltipPlacement="right"
            nzTooltipTitle="Down Children"
            [ngStyle]="{
              'display': !isBlockCanAddItems && isPage ? 'grid' : 'none'
            }">
            <i nz-icon nzType="minus" nzTheme="outline"></i
          ></span>
          <!-- <span nz-tooltip nzTooltipPlacement="right" nzTooltipTitle="Xóa" nz-popconfirm
            [nzPopconfirmTitle]="isPage? nzConfirmTitle[1] : nzConfirmTitle[0]" (nzOnConfirm)="deleteWorkingBlock()"
            nzPopconfirmPlacement="bottom">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
              <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
            </svg>
          </span> -->
        </span>
        <div id="whole-page-content-clone" style="display: none"></div>
        <div>
          <div id="whole-page-content" style="position: relative">
            <div
              (click)="resetFormClickEvent($event)"
              [id]="item.id"
              *ngFor="let item of pages"
              class="pageForm"
              [ngStyle]="{ height: item.height + 'px' }">
              <div
                [id]="item.id + '-content'"
                class="f-page-content blockCanAddItem">
                <div
                  [id]="data.viewId"
                  *ngFor="let data of formObjects.get('label')"
                  class="df-elm form-label cloneable"
                  df-type="label"
                  [ngStyle]="{
                    'top': data.top + 'px',
                    'left': data.left + 'px',
                    'width': data.width + 'px',
                    'height': data.height + 'px'
                  }"
                  [ngClass]="
                    currentElement.viewId == data.viewId ? 'active' : ''
                  ">
                  <app-label-element
                    [data]="data"
                    [selectedElement]="currentElement"
                    [isFocus]="isFocusElm"
                    (selectElement)="selectElement($event)"></app-label-element>
                </div>
                <!-- <div [id]="data.viewId" *ngFor="let data of formObjects.get('Checkbox')" class="df-elm form-label cloneable"
                df-type="Checkbox"
                [ngStyle]="{ 'top': data.top + 'px','left': data.left + 'px', 'width': data.width + 'px', 'height': data.height + 'px'}">
                <app-checkbox-element [data]="data"></app-checkbox-element>
              </div> -->
                <div
                  [id]="data.viewId"
                  *ngFor="let data of formObjects.get('textarea')"
                  class="df-elm form-textarea cloneable"
                  df-type="textarea"
                  [ngStyle]="{
                    'top': data.top + 'px',
                    'left': data.left + 'px',
                    'width': data.width + 'px',
                    'height': data.height + 'px'
                  }"
                  [ngClass]="
                    currentElement.viewId == data.viewId ? 'active' : ''
                  ">
                  <app-textarea-element
                    [data]="data"
                    [selectedElement]="currentElement"
                    [isFocus]="isFocusElm"
                    (selectElement)="
                      selectElement($event)
                    "></app-textarea-element>
                </div>
                <div
                  click-stop-propagation
                  [id]="data.viewId"
                  *ngFor="let data of formObjects.get('image')"
                  class="df-elm image-element cloneable default-elm"
                  df-type="image"
                  [ngStyle]="{
                    'top': data.top + 'px',
                    'left': data.left + 'px',
                    'width': data.width + 'px',
                    'height': data.height + 'px'
                  }"
                  [ngClass]="
                    currentElement.viewId == data.viewId ? 'active' : ''
                  "
                  (click)="selectElementDirect(data, 'image')">
                  <app-image-element [data]="data"></app-image-element>
                </div>
                <div
                  click-stop-propagation
                  [id]="data.viewId"
                  *ngFor="let data of formObjects.get('observation')"
                  class="df-elm wrapper-elm df-obser"
                  df-type="observation"
                  [ngStyle]="{
                    'top': data.top + 'px',
                    'left': data.left + 'px',
                    'width': data.width + 'px',
                    'height': data.height + 'px'
                  }"
                  [title]="data.observationType.name"
                  [ngClass]="
                    currentElement.viewId == data.viewId ? 'active' : ''
                  "
                  (click)="selectElementDirect(data, 'observation')">
                  <app-observation-type-abs
                    [data]="data"></app-observation-type-abs>
                  <span *ngIf="data.isRequired" class="required-elm"
                    >* Bắt buộc</span
                  >
                </div>
                <div
                  click-stop-propagation
                  [id]="data.viewId"
                  *ngFor="let data of formObjects.get('observationRecent')"
                  class="df-elm wrapper-elm df-obser-recent"
                  df-type="observationRecent"
                  [ngStyle]="{
                    'top': data.top + 'px',
                    'left': data.left + 'px',
                    'width': data.width + 'px',
                    'height': data.height + 'px'
                  }"
                  [title]="data.observationType.name"
                  [ngClass]="
                    currentElement.viewId == data.viewId ? 'active' : ''
                  "
                  (click)="selectElementDirect(data, 'observationRecent')">
                  <app-observation-type-abs
                    [data]="data"></app-observation-type-abs>
                  <span *ngIf="data.editType == 3" class="data-type-icon">
                    <i
                      style="font-size: 20px"
                      nz-icon
                      nzType="field-time"
                      nzTheme="outline"></i>
                  </span>
                  <span *ngIf="data.editType == 4" class="data-type-icon">
                    <i
                      style="font-size: 20px"
                      nz-icon
                      nzType="edit"
                      nzTheme="outline"></i>
                  </span>
                </div>
                <div
                  click-stop-propagation
                  [id]="data.viewId"
                  *ngFor="let data of formObjects.get('canvas')"
                  class="df-elm canvas-element cloneable default-elm"
                  df-type="canvas"
                  [ngStyle]="{
                    'top': data.top + 'px',
                    'left': data.left + 'px',
                    'width': data.width + 'px',
                    'height': data.height + 'px'
                  }"
                  [ngClass]="
                    currentElement.viewId == data.viewId ? 'active' : ''
                  "
                  (click)="selectElementDirect(data, 'canvas')">
                  <app-image-element [data]="data"></app-image-element>
                </div>
                <div
                  click-stop-propagation
                  [id]="data.viewId"
                  *ngFor="let data of formObjects.get('commoninfo')"
                  class="df-elm info-element cloneable default-elm"
                  df-type="commoninfo"
                  [title]="data.name"
                  [ngStyle]="{
                    'top': data.top + 'px',
                    'left': data.left + 'px',
                    'width': data.width + 'px',
                    'height': data.height + 'px'
                  }"
                  [ngClass]="
                    currentElement.viewId == data.viewId ? 'active' : ''
                  "
                  (click)="selectElementDirect(data, 'commoninfo')">
                  <app-commonInfo-element
                    [data]="data"
                    style="height: 100%"></app-commonInfo-element>
                </div>
                <div
                  click-stop-propagation
                  [id]="data.viewId"
                  *ngFor="let data of formObjects.get('formula')"
                  class="df-elm formula-elm default-elm"
                  df-type="formula"
                  [title]="data.name"
                  [ngStyle]="{
                    'top': data.top + 'px',
                    'left': data.left + 'px',
                    'width': data.width + 'px',
                    'height': data.height + 'px'
                  }"
                  (dblclick)="updateFormula(data)"
                  [ngClass]="
                    currentElement.viewId == data.viewId ? 'active' : ''
                  "
                  (click)="selectElementDirect(data, 'formula')">
                  <app-formula-control
                    [data]="data"
                    style="height: 100%"></app-formula-control>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- </div> -->
    </nz-content>

    <nz-sider
      nzWidth="285px"
      nzTheme="light"
      style="height: calc(100vh - 65px); overflow: auto">
      <nz-collapse>
        <nz-collapse-panel
          [nzHeader]="panels[0].name"
          [nzActive]="panels[0].active"
          [nzDisabled]="panels[0].disabled">
          <div id="options-add-item" class="tool-btn">
            <button
              mwlDraggable
              nz-button
              nzType="default"
              title="Alt + L"
              (click)="addLabel('', 0, 0, false)"
              nzShape="square">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-fonts"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.258 3H3.747l-.082 2.46h.479c.26-1.544.758-1.783 2.693-1.845l.424-.013v7.827c0 .663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.43.013c1.935.062 2.434.301 2.694 1.846h.479L12.258 3z" />
              </svg>
              <span>Label</span>
            </button>
            <!-- <button mwlDraggable nz-button nzType="dashed" title="Alt + L" (click)="addCheckbox()" nzShape="round">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-fonts" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.258 3H3.747l-.082 2.46h.479c.26-1.544.758-1.783 2.693-1.845l.424-.013v7.827c0 .663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.43.013c1.935.062 2.434.301 2.694 1.846h.479L12.258 3z" />
            </svg>
            <span>Checkbox</span>
          </button> -->
            <button
              nz-button
              nzType="dashed"
              title="Alt + T"
              (click)="addTextArea('', 0, 0, false)"
              nzShape="square">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-textarea-resize"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M0 3.5A2.5 2.5 0 0 1 2.5 1h11A2.5 2.5 0 0 1 16 3.5v8.854a2.5 2.5 0 0 1-2.5 2.5h-11a2.5 2.5 0 0 1-2.5-2.5V3.5zM2.5 2A1.5 1.5 0 0 0 1 3.5v8.854a1.5 1.5 0 0 0 1.5 1.5h11a1.5 1.5 0 0 0 1.5-1.5V3.5A1.5 1.5 0 0 0 13.5 2h-11zm10.854 6.5a.5.5 0 0 1 0 .707l-3 3a.5.5 0 1 1-.708-.707l3-3a.5.5 0 0 1 .708 0zm0 2.5a.5.5 0 0 1 0 .707l-.5.5a.5.5 0 0 1-.708-.707l.5-.5a.5.5 0 0 1 .708 0z" />
              </svg>
              <span>TextArea</span>
            </button>
            <button
              nz-button
              nzType="default"
              title="Alt + I"
              (click)="openAddImage()"
              nzShape="square">
              <svg
                width="1.0625em"
                height="1em"
                viewBox="0 0 17 16"
                class="bi bi-image"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M14.002 2h-12a1 1 0 0 0-1 1v9l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094L15.002 9.5V3a1 1 0 0 0-1-1zm-12-1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm4 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
              <span>Image</span>
            </button>
            <button
              nz-button
              nzType="default"
              title="Alt + C"
              (click)="openAddCanvas()"
              nzShape="square">
              <span
                ><i nz-icon nzType="radar-chart" nzTheme="outline"></i
              ></span>
              <span>Canvas </span>
            </button>
            <button
              nz-button
              nzType="default"
              title="Alt + N"
              (click)="addNewPage()"
              nzShape="square">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-file-plus"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H4z" />
                <path
                  fill-rule="evenodd"
                  d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5z" />
              </svg>
              <span>New page</span>
            </button>
            <button
              nz-button
              nzType="default"
              (click)="showCalculatedModal()"
              nzShape="square">
              <i nz-icon nzType="calculator" nzTheme="outline"></i>
              <span style="margin-left: 0px">Calculate</span>
            </button>
            <br />

            <div class="line"></div>
            <br />
            <button
              nz-button
              nzType="primary"
              (click)="save('undefined')"
              nzShape="square">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-cloud-upload"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                <path
                  fill-rule="evenodd"
                  d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
              </svg>
              <span> Save</span>
            </button>
            <button
              *ngIf="this.adminPage"
              nz-button
              nzType="danger"
              (click)="close()"
              nzShape="square">
              <i nz-icon nzType="close" nzTheme="outline"></i>
              <span> Close</span>
            </button>

            <button
              nz-button
              nzType="default"
              title="Ctrl + P"
              (click)="showModalPreview()"
              nzShape="square">
              <i nz-icon nzType="eye" nzTheme="outline"></i>
              <span>Preview </span>
            </button>

            <nz-modal
              [(nzVisible)]="isVisiblePreviewModal"
              [nzBodyStyle]=""
              nzTitle="Preview complete form"
              nzOkText="Ok"
              (nzOnOk)="preview_Modal_handleOk()"
              [nzWidth]="900"
              (nzOnCancel)="preview_Modal_handleOk()"
              (nzAfterOpen)="getHtmlPrevew()">
              <div
                id="zoom"
                style="
                  position: absolute;
                  z-index: 1000;
                  background: black;
                  color: aliceblue;
                  padding: 15px;
                  border-radius: 5px;
                  box-shadow: 0px 0px 10px 0px grey;
                  opacity: 0.5;
                  font-size: 16px;
                  font-weight: bold;
                  top: 0px;
                  left: 43%;
                "
                [ngStyle]="{ display: showZoom ? 'block' : 'none' }">
                Zoom: {{ zoom }}%
              </div>
              <div id="preview"></div>
            </nz-modal>
          </div>
        </nz-collapse-panel>

        <nz-collapse-panel
          [nzHeader]="panels[1].name"
          [nzActive]="panels[1].active"
          [nzDisabled]="panels[1].disabled">
          <div>
            <app-format-box
              [element]="currentElement"
              [elementType]="currentElmType"
              [(isVisible)]="isFormatStyleBoxVisible"
              (toggleValue)="toggleValue($event)"
              (excFormatHtml)="excFormatHtml($event)"
              (selectValue)="selectValue($event)"></app-format-box>
          </div>
        </nz-collapse-panel>

        <nz-collapse-panel
          [nzHeader]="panels[2].name"
          [nzActive]="panels[2].active"
          [nzDisabled]="panels[2].disabled">
          <div>
            <app-form-control
              [element]="currentFormElement"
              [(isVisible)]="isFormControlVisible"
              [observationItems]="lstObservationType"
              (updateElement)="updateElement($event)"
              [isRecentElement]="false"></app-form-control>
            <app-form-control
              [element]="currentFormElementRecent"
              [(isVisible)]="isFormRecentControlVisible"
              [observationItems]="lstObservationType"
              (updateElement)="updateRecentElement($event)"
              [isRecentElement]="true"></app-form-control>
            <app-commonInfo-control
              [element]="currentCommonInfoElement"
              [(isVisible)]="isCommonInfoControlVisible"
              [commonInfoItems]="lstCommonInfo"
              (updateElement)="updateCommonInfoElement($event)"
              [adminPage]="adminPage"
              [groupId]="currentGroup.groupId">
            </app-commonInfo-control>
          </div>
        </nz-collapse-panel>
        <nz-collapse-panel
          [nzHeader]="panels[3].name"
          [nzActive]="panels[3].active"
          [nzDisabled]="panels[3].disabled">
          <div style="margin-top: 20px">
            <nz-input-group [nzSuffix]="suffixIcon">
              <input
                type="text"
                nz-input
                placeholder="Search"
                [(ngModel)]="searchValue" />
            </nz-input-group>
            <ng-template #suffixIcon>
              <i nz-icon nzType="search"></i>
            </ng-template>
            <nz-tree
              style="
                height: 300px;
                overflow: auto;
                border-bottom: 0.5px solid gray;
              "
              #DOMTree
              [nzData]="nodes"
              (nzClick)="nzDomTreeClick($event)"
              [nzSearchValue]="searchValue"
              [nzTreeTemplate]="nzTreeTemplate">
            </nz-tree>
            <ng-template #nzTreeTemplate let-node let-origin="origin">
              <span
                class="custom-node"
                class="nzEllipsis"
                style="width: 155px; display: block">
                <span
                  *ngIf="!node.isLeaf"
                  [ngStyle]="{
                    'background-color': node.isSelected ? 'gray' : ''
                  }">
                  <span class="folder-name">{{ node.title }}</span>
                </span>
                <!-- *ngIf="node.isLeaf" -->
                <span
                  *ngIf="node.isLeaf"
                  [ngStyle]="{
                    'background-color': node.isSelected ? 'yellow' : ''
                  }">
                  <span *ngIf="node.icon == 'label'">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-fonts"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.258 3H3.747l-.082 2.46h.479c.26-1.544.758-1.783 2.693-1.845l.424-.013v7.827c0 .663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.43.013c1.935.062 2.434.301 2.694 1.846h.479L12.258 3z" />
                    </svg>
                  </span>
                  <span *ngIf="node.icon == 'checkbox'">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-fonts"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.258 3H3.747l-.082 2.46h.479c.26-1.544.758-1.783 2.693-1.845l.424-.013v7.827c0 .663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.43.013c1.935.062 2.434.301 2.694 1.846h.479L12.258 3z" />
                    </svg>
                  </span>
                  <span *ngIf="node.icon == 'textarea'">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-textarea-resize"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        d="M0 3.5A2.5 2.5 0 0 1 2.5 1h11A2.5 2.5 0 0 1 16 3.5v8.854a2.5 2.5 0 0 1-2.5 2.5h-11a2.5 2.5 0 0 1-2.5-2.5V3.5zM2.5 2A1.5 1.5 0 0 0 1 3.5v8.854a1.5 1.5 0 0 0 1.5 1.5h11a1.5 1.5 0 0 0 1.5-1.5V3.5A1.5 1.5 0 0 0 13.5 2h-11zm10.854 6.5a.5.5 0 0 1 0 .707l-3 3a.5.5 0 1 1-.708-.707l3-3a.5.5 0 0 1 .708 0zm0 2.5a.5.5 0 0 1 0 .707l-.5.5a.5.5 0 0 1-.708-.707l.5-.5a.5.5 0 0 1 .708 0z" />
                    </svg>
                  </span>
                  <span *ngIf="node.icon == 'image'">
                    <svg
                      width="1.0625em"
                      height="1em"
                      viewBox="0 0 17 16"
                      class="bi bi-image"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        d="M14.002 2h-12a1 1 0 0 0-1 1v9l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094L15.002 9.5V3a1 1 0 0 0-1-1zm-12-1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm4 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                  </span>
                  <span *ngIf="node.icon == 'input'">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-pencil-square"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </span>
                  <span *ngIf="node.icon == 'commoninfo'">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-info-square"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path
                        fill-rule="evenodd"
                        d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      <path
                        d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                      <circle cx="8" cy="4.5" r="1" />
                    </svg>
                  </span>
                  <span *ngIf="node.icon == 'canvas'">
                    <i nz-icon nzType="radar-chart" nzTheme="outline"></i>
                  </span>
                  <span *ngIf="node.icon == 'observation'">
                    <i nz-icon nzType="security-scan" nzTheme="outline"></i>
                  </span>
                  <span
                    *ngIf="node.icon != 'canvas' && node.icon != 'image'"
                    style="margin-left: 5px"
                    >{{ node.title }}</span
                  >
                  <span
                    *ngIf="node.icon == 'canvas' || node.icon == 'image'"
                    style="margin-left: 5px">
                    <img [src]="node.title" height="15px" alt="" />
                  </span>
                </span>
              </span>
            </ng-template>
          </div>
        </nz-collapse-panel>
        <nz-collapse-panel
          [nzHeader]="panels[4].name"
          [nzActive]="panels[4].active"
          [nzDisabled]="panels[4].disabled">
          <div>
            <app-format-container-box
              [element]="currentElement"
              [(isVisible)]="isFormatContainerStyleBoxVisible"
              (toggleValue)="toggleValue($event)"
              (selectValue)="alignContainer($event)"></app-format-container-box>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </nz-sider>

    <!-- <app-add-input-item [(isVisible)]="isAddInputVisiable" [obt]="selectedOT" (addItem)="add_OT_Item($event)">
    </app-add-input-item> -->
    <app-upload-image
      [(isVisible)]="isUploadFileVisiable"
      [imageType]="imageType"
      [uploadMaxSize]="{ height: uploadMaxHeight, width: uploadMaxWidth }"
      (addImage)="addImageElmement($event)">
    </app-upload-image>

    <nz-modal
      [(nzVisible)]="isConfirmSaveVisible"
      [nzBodyStyle]=""
      nzTitle="Form đã thay đổi"
      [nzWidth]="270"
      (nzOnCancel)="isConfirmSaveVisible = false">
      <div>Bạn có muốn lưu thay đổi?</div>
      <div *nzModalFooter>
        <button
          nz-button
          nzType="default"
          nzDanger
          (click)="isConfirmSaveVisible = false">
          Hủy
        </button>
        <button nz-button nzType="primary" nzDanger (click)="closeAnyWay()">
          Không lưu
        </button>
        <button nz-button nzType="primary" (click)="saveAndClose()">Lưu</button>
      </div>
    </nz-modal>
  </nz-layout>
</div>
<app-calculated-element
  [formulaId]="currentFormulaId"
  [(isVisible)]="isCalculatedModalVisiable"
  (addFormulaToForm)="addFormula($event)"></app-calculated-element>
