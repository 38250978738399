import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Observer, Subscription } from 'rxjs';
import { Visits } from 'src/app/models/listVisit.class';
import { DoctorService } from 'src/app/services/doctor.service';
import { NotificationService } from 'src/app/services/notification.service';
import { VisitService } from 'src/app/services/visit.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AppState } from '../../app-state/app-state';
import { ShareService } from 'src/app/services/share.service';

@Component({
  selector: 'app-edit-visitinfo-core',
  templateUrl: './edit-visitinfo-core.component.html',
  styleUrls: ['./edit-visitinfo-core.component.scss'],
})
export class EditVisitinfoCoreComponent implements OnInit {
  @Input() isVisible = false;
  //@Input() visitId: string;
  @Input() isCovidGroup: boolean;
  @Input()
  get visit() {
    return this._visit;
  }
  set visit(val) {
    if (val) {
      this._visit = val;
      this.getVisit();
    }
  }
  private _visit: any;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() editSuccess: EventEmitter<any> = new EventEmitter();
  datetimeFormat = 'dd/MM/yyyy HH:mm';
  dateFormat = 'dd/MM/yyyy';
  formEditVisit: FormGroup;
  curUserId = '';
  submitted = false;
  checkDate: any;
  doctorInGroup: any[] = [];
  groupSub: Subscription;
  groupId = '';
  //visit: any;
  initialValue: any;

  listDoctorInCase: any[] = [];
  listCoordinator: any[] = [];
  listPharmacist: any[] = [];
  listPsychiatrist: any[] = [];
  listNursingInCase: any[] = [];
  listVolunteer: any[] = [];

  isMobile: boolean;
  lstPermissionDefault = [
    {
      name: 'Điều phối viên',
      type: 1,
      checked: false,
    },
    {
      name: 'Bác sĩ phụ trách',
      type: 2,
      checked: false,
    },
    {
      name: 'Dược sĩ lâm sàng',
      type: 3,
      checked: false,
    },
    {
      name: 'Chuyên viên tư vấn',
      type: 4,
      checked: false,
    },
    {
      name: 'Điều dưỡng phụ trách',
      type: 5,
      checked: false,
    },
    {
      name: 'Tình nguyện viên',
      type: 10,
      checked: false,
    },
  ];

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private doctorService: DoctorService,
    private visitService: VisitService,
    private notificationService: NotificationService,
    private shareService: ShareService
  ) {
    try {
      this.store
        .select((store) => store.auth.userId)
        .subscribe((res) => {
          this.curUserId = res;
        });
    } catch (error) {
      console.log(error);
    }
    this.formEditVisit = this.fb.group({
      visitId: [null],
      doctorId: [this.curUserId, [Validators.required]],
      visitDate: [new Date(), [Validators.required]],
      dateOfDiagnosis: [null],
      dateOfSymptom: [null],
      doctorInCareDoct: [null],
      nursingInCare: [null],
      coordinator: [null],
      pharmacist: [null],
      psychiatrist: [null],
      volunteer: [null],
    });
  }

  async ngOnInit() {
    this.isMobile = window.innerWidth < 540;
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        this.getDoctorInGroup(this.groupId);
        this.convertToPermissionItem(data.doctorPermissions);
      }
    });
    await this.getVisit();
  }

  convertToPermissionItem(values) {
    if (this.shareService.checkEmpty(values)) {
      return;
    }
    this.lstPermissionDefault.map((t) => (t.checked = false));
    values.forEach((element) => {
      const curPermission = this.lstPermissionDefault.filter(
        (t) => t.type == element
      );
      if (curPermission.length) {
        curPermission[0].checked = true;
      }
    });
  }
  getVisit(): void {
    //this.visitService.getVisit(this.visitId, this.groupId).subscribe((data) => {
    //this.visit = data;
    this.formEditVisit.patchValue({
      visitId: this.visit.id,
      doctorId: this.visit.doctorId,
      visitDate: this.visit.visitDate,
      dateOfDiagnosis: this.visit.dateOfDiagnosis,
      dateOfSymptom: this.visit.dateOfSymptom,
      doctorInCareDoct: this.visit.doctorInCareDoct,
      nursingInCare: this.visit.nursingInCare,
      coordinator: this.visit.coordinator,
      pharmacist: this.visit.pharmacist,
      psychiatrist: this.visit.psychiatrist,
      volunteer: this.visit.volunteer,
    });
    this.initialValue = this.formEditVisit.value;
    //console.log("formEditVisit", data);
    //});
  }

  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;

        this.listDoctorInCase = this.doctorInGroup.filter(
          (en) =>
            en.doctorPermissions != null &&
            en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Doctor)
        );

        this.listCoordinator = this.doctorInGroup.filter(
          (en) =>
            en.doctorPermissions != null &&
            en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Coordinator)
        );

        this.listPharmacist = this.doctorInGroup.filter(
          (en) =>
            en.doctorPermissions != null &&
            en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Pharmacist)
        );

        this.listPsychiatrist = this.doctorInGroup.filter(
          (en) =>
            en.doctorPermissions != null &&
            en.doctorPermissions.includes(
              Constant.DOCTORPERMISSION.Psychiatrist
            )
        );

        this.listNursingInCase = this.doctorInGroup.filter(
          (en) =>
            en.doctorPermissions != null &&
            en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Nursing)
        );

        this.listVolunteer = this.doctorInGroup.filter(
          (en) =>
            en.doctorPermissions != null &&
            en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Volunteer)
        );
      }
    });
  }

  emptyAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.checkNull(control.value)) {
        observer.next({ error: true, duplicated: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  checkNull(value) {
    try {
      value = value.trim();
      if (value == null || value == '' || value == 'undefined') {
        return true;
      }
      return false;
    } catch (e) {
      return true;
    }
  }

  get f() {
    return this.formEditVisit.controls;
  }

  handleOk() {
    this.submitted = true; //updateVisitInfoCore
    if (this.formEditVisit.valid) {
      const formvalue = this.formEditVisit.value;
      let payload;
      if (!this.isCovidGroup) {
        // nếu không là nhóm covid
        payload = {
          visitId: formvalue.visitId,
          visitDate: formvalue.visitDate,
          doctorId: formvalue.doctorId,
          isCovidGroup: this.isCovidGroup,
        };
      } else {
        payload = {
          visitId: formvalue.visitId,
          visitDate: formvalue.visitDate,
          doctorId: formvalue.doctorId,
          isCovidGroup: this.isCovidGroup,
          dateOfDiagnosis: formvalue.dateOfDiagnosis,
          dateOfSymptom: formvalue.dateOfSymptom,
          doctorInCareDoct: formvalue.doctorInCareDoct,
          nursingInCare: formvalue.nursingInCare,
          coordinator: formvalue.coordinator,
          pharmacist: formvalue.pharmacist,
          psychiatrist: formvalue.psychiatrist,
          volunteer: formvalue.volunteer,
        };
      }
      this.visitService.updateVisitInfoCore(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(
            Constant.SUCCESS,
            'Sửa thông tin thành công!'
          );
          this.submitted = false;
          this.isVisible = false;
          this.editSuccess.emit();
          this.closeModal.emit();
          this.getVisit();
        } else {
          this.notificationService.showNotification(
            Constant.ERROR,
            res.errors[0].errorMessage
          );
        }
      });
    }
  }

  handleCancel() {
    this.isVisible = false;
    this.closeModal.emit();
    setTimeout(() => {
      this.formEditVisit.reset(this.initialValue);
    }, 100);
  }
}
