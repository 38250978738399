import { Time } from '@angular/common';

export class ObservationSave {
  formElementId: string;
  isDirty = false;
  valueString: string;
  valueBoolean: boolean;
  valueInteger: string;
  valueTime: any;
  valueDateTime: any;
  valueSelections: Array<string>;

  constructor(formElement: any) {
    this.formElementId = formElement.id;
    this.isDirty = formElement.isDirty;
  }
}
