import { NotificationService } from 'src/app/services/notification.service';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Constant } from '../constants/constant.class';
import { LoaderService } from '../../services/loader.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private notificationService: NotificationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.headers.get('skipLoading')) {
      this.totalRequests++;
      this.loaderService.show();
    }
    return next.handle(req).pipe(
      // retry(1),

      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          // localStorage.removeItem(Constant.TOKEN);
          // localStorage.removeItem(Constant.FIREBASE_TOKEN);
          // localStorage.removeItem(Constant.USER_INFO);
          // const url = this.router.routerState.snapshot.url;
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            'Có lỗi xay ra khi gọi API. Code 403'
          );
          // this.router.navigate(['/login'], { queryParams: { returnUrl: url } });
          // return throwError(error);
        } else if (error.status === 404) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            'Có lỗi xay ra khi gọi API. Code 404'
          );
          // this.router.navigate(['/page-not-found']);
        }
        return throwError(error);
      }),
      finalize(() => {
        if (!req.headers.get('skipLoading')) {
          this.totalRequests--;
        }
        if (this.totalRequests === 0) {
          this.loaderService.hide();
        }
      })
    );
  }
}
