import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Constant } from 'src/app/share/constants/constant.class';
import { PrescriptionService } from 'src/app/services/prescription-service';
import { NotificationService } from 'src/app/services/notification.service';

const TABLE_HEIGHT_SHORT = 'calc(100vh - 360px)';
const TABLE_HEIGHT_LONG = 'calc(100vh - 180px)';
@Component({
  selector: 'app-delivery-info',
  templateUrl: './delivery-info.component.html',
  styleUrls: ['./delivery-info.component.scss'],
})
export class DeliveryInfoComponent implements OnInit {
  @Input() canEditPrescription = false;
  @Input() prescription = null;
  @Input() listMedicationTable = [];
  @Input() currentUserId = '';
  @Input() isUsePrescriptionDeliveryInfo = false;

  _generalInfo: any;
  @Input() get generalInfo() {
    return this._generalInfo;
  }
  set generalInfo(data) {
    this.isEdittingGeneralInfo = false;
    if (data != null) {
      this.generalInfoForm.patchValue({
        recipientName: data.recipientName,
        recipientPhoneNo: data.recipientPhoneNo,
        recipientAddress: data.recipientAddress,
        paymentMethod: data.paymentMethod,
        paymentMethodString: this.toPaymentMethodText(data.paymentMethod),
        recipientTimePlan: data.recipientTimePlan,
        recipientNote: data.recipientNote,
      });
    }
  }

  isVisibleAmountExistOfPatient = false;
  isEdittingGeneralInfo = false;
  isEdittingMedical = false;
  disabledActiton = false;
  generalInfoForm: FormGroup;
  isExpandSearch = false;
  maxTableHeight = TABLE_HEIGHT_LONG;

  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private prescriptionService: PrescriptionService
  ) {
    this.generalInfoForm = this.formBuilder.group({
      recipientName: [''],
      recipientPhoneNo: [''],
      recipientAddress: [''],
      paymentMethod: [null],
      paymentMethodString: [''],
      recipientTimePlan: [''],
      recipientNote: [''],
    });
  }
  get generalInfoFormData() {
    return this.generalInfoForm.controls;
  }

  ngOnInit(): void {}
  openModalEditAmountExist() {
    this.isVisibleAmountExistOfPatient = true;
  }
  toPaymentMethodText(status: number): string {
    return this.prescriptionService.toPaymentMethodText(status);
  }
  cancelEditGeneralInfo() {
    this.isEdittingGeneralInfo = false;
  }

  editGeneralInfo() {
    this.isEdittingGeneralInfo = true;
  }
  saveGeneralInfo() {
    // nút lưu general info
    if (!this.generalInfoForm.valid) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.WARRING,
        'Bạn cần điền những thông tin bắt buộc!'
      );
    } else {
      let formValue = this.generalInfoForm.value;
      const payload = {
        visitId: this.prescription.VisitId,
        prescriptionId: this.prescription.Id,
        updatorId: this.currentUserId,
        recipientName: formValue.recipientName,
        recipientPhoneNo: formValue.recipientPhoneNo,
        recipientAddress: formValue.recipientAddress,
        paymentMethod: formValue.paymentMethod,
        recipientTimePlan: formValue.recipientTimePlan,
        recipientNote: formValue.recipientNote,
        medicationRequests: [],
      };
      this.listMedicationTable.forEach((item) => {
        payload.medicationRequests.push({
          medicationId: item.MedicationId,
          existingAmount: item.ExistingAmount,
        });
      });
      this.prescriptionService.saveGeneralInfo(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(
            Constant.SUCCESS,
            Constant.MESSAGE_UPDATE_SUCCESS
          );
          this.generalInfoForm.controls.paymentMethodString.setValue(
            this.toPaymentMethodText(formValue.paymentMethod)
          );
          this.isEdittingGeneralInfo = false;
          this.isVisibleAmountExistOfPatient = false;
        } else {
          this.notificationService.showNotification(
            Constant.ERROR,
            res.errors[0].errorMessage
          );
        }
      });
    }
  }
  toggleExpandSearch() {
    this.isExpandSearch = !this.isExpandSearch;
    this.maxTableHeight = this.isExpandSearch
      ? TABLE_HEIGHT_SHORT
      : TABLE_HEIGHT_LONG;
  }
}
