<!-- <nz-modal [(nzVisible)]="visible" nzClassName="appointment-popup " nzTitle="Xử lý thông tin hẹn khám"
    nzMaskClosable="false" (nzOnCancel)="cancelEdit()" nzWidth="860px" [nzFooter]="null"> -->
<app-m-modal
  class="appointment-popup"
  [modalId]="'appointment-edit'"
  [isVisible]="visible"
  (closeModal)="cancelEdit()"
  [mMask]="true"
  [width]="860"
  [minWidth]="860"
  [height]="850"
  [minHeight]="600"
  [isResiable]="true"
  [mFooter]="false">
  <ng-template #mModalTitle>
    <div class="header-title">Xử lý thông tin hẹn khám</div>
  </ng-template>
  <ng-template #mModalContent>
    <form [formGroup]="info">
      <div class="potal customTableCrollbar">
        <div class="potal-body">
          <div class="potal-left">
            <div class="box-left font-style">
              <div class="box-info">
                <div>Họ tên</div>
                <input nz-input value="{{ patient.fullName }}" disabled />
              </div>
              <div class="box-info">
                <div>Địa chỉ</div>
                <input nz-input value="{{ patient.address }}" disabled />
              </div>
              <div class="box-info">
                <div>Số thẻ BHYT</div>
                <input nz-input value="{{ patient.soBHYT }}" disabled />
              </div>
              <div class="box-info">
                <div>Ngày khám (<span class="d-note"> * </span>)</div>
                <nz-date-picker
                  formControlName="dateAppointment"
                  nzFormat="dd/MM/yyyy"
                  [nzDisabledDate]="disabledDate"></nz-date-picker>
              </div>
              <div
                class="message-required"
                *ngIf="submitted && f.dateAppointment.errors">
                <div *ngIf="f.dateAppointment.errors.required">
                  <i
                    nz-icon
                    nzType="close-circle"
                    [nzTheme]="'twotone'"
                    [nzTwotoneColor]="'#FF0000'"
                    style="vertical-align: baseline"></i>
                  Bạn chưa nhập ngày khám
                </div>
              </div>
            </div>
            <div class="box-right font-style">
              <div class="box-info">
                <div>Giới tính</div>
                <input
                  nz-input
                  value="{{ toGenderText(patient.gender) }}"
                  disabled />
              </div>
              <div class="box-info">
                <div>Số điện thoại</div>
                <input
                  nz-input
                  value="{{ patient.phoneNo }}"
                  type="number"
                  disabled />
              </div>
              <div class="box-info">
                <div>Mã BN</div>
                <input nz-input value="{{ patient.patientCode }}" disabled />
              </div>
              <div class="box-info">
                <div>Giờ khám (<span class="d-note"> * </span>)</div>
                <nz-time-picker
                  formControlName="timeAppointment"
                  [nzMinuteStep]="30"
                  nzFormat="HH:mm">
                </nz-time-picker>
                <div class="message-required" *ngIf="submitted">
                  <div
                    *ngIf="
                      f.timeAppointment.errors &&
                      f.timeAppointment.errors.required
                    ">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"
                      style="vertical-align: baseline"></i>
                    Bạn chưa nhập giờ khám
                  </div>
                  <div *ngIf="f.timeAppointment.hasError('unableHour')">
                    <i
                      nz-icon
                      nzType="close-circle"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#FF0000'"
                      style="vertical-align: baseline"></i>
                    Chọn trước giờ ngày hiện tại
                  </div>
                </div>
              </div>
            </div>
            <div class="request-note">
              <div class="font-style">Nội dung yêu cầu</div>
              <textarea rows="2" nz-input formControlName="request"></textarea>
            </div>
            <div class="box-info">
              <div class="font-style">Chỉ định cận lâm sàng</div>
              <div class="box-cls">
                <nz-select
                  nzShowSearch
                  nzServerSearch
                  nzAllowClear
                  nzPlaceHolder="Tìm kiếm hoặc chọn chỉ định"
                  [(ngModel)]="clsSelected"
                  [ngModelOptions]="{ standalone: true }"
                  (nzOnSearch)="onTyping($event)"
                  (ngModelChange)="addClsSelected($event)">
                  <nz-option
                    *ngFor="let item of clsDataSource"
                    nzCustomContent
                    [nzValue]="item"
                    [nzLabel]="item.name">
                    <div>{{ item.code }}</div>
                    <div>{{ item.name }}</div>
                  </nz-option>
                </nz-select>
                <ul class="list-cls customTableCrollbar">
                  <li *ngFor="let item of listClsDisplay">
                    <span>{{ item.code }}: {{ item.name }}</span>
                    <i
                      style="float: right"
                      (click)="removeItemCls(item.id)"
                      nz-icon
                      nzType="close"
                      nzTheme="outline"></i>
                  </li>
                </ul>
              </div>
            </div>
            <div class="request-note">
              <div class="font-style">Ghi chú</div>
              <textarea rows="2" nz-input formControlName="note"></textarea>
            </div>
          </div>
          <div class="potal-right font-style">
            <div>
              <div class="fs-16 mg-bt-20">
                Trạng thái:
                <span class="color-red">{{
                  toStatusText(f['status'].value)
                }}</span>
              </div>
              <nz-select class="list-status mg-bt-30" formControlName="status">
                <nz-option [nzValue]="1" nzLabel="Chờ xác nhận"></nz-option>
                <nz-option [nzValue]="2" nzLabel="Đã xác nhận"></nz-option>
                <nz-option [nzValue]="3" nzLabel="Bệnh nhân đã đến"></nz-option>
                <nz-option [nzValue]="4" nzLabel="Đã tạo ca khám"></nz-option>
                <nz-option [nzValue]="5" nzLabel="BN hủy lịch hẹn"></nz-option>
                <nz-option [nzValue]="6" nzLabel="BN không đến"></nz-option>
              </nz-select>
              <div
                *ngIf="f['status'].value == 1"
                class="status-detail mg-bt-30">
                <nz-radio-group formControlName="subStatus">
                  <label nz-radio [nzValue]="0">Chưa xử lý</label>
                  <label nz-radio [nzValue]="1">Đang xử lý</label>
                  <label nz-radio [nzValue]="2">KH tắt máy</label>
                  <label nz-radio [nzValue]="3">Đổ chuông chưa nghe máy</label>
                  <label nz-radio [nzValue]="4">KH đang bận</label>
                  <label nz-radio [nzValue]="6"
                    >Chờ KH kiểm tra thông tin</label
                  >
                  <label nz-radio [nzValue]="5"
                    >Chờ NV kiểm tra thông tin</label
                  >
                </nz-radio-group>
              </div>
            </div>
            <div>
              <div class="fs-16 mg-bt-20">
                Mức độ ưu tiên:
                <span class="color-red">{{
                  toPriorityText(f['priority'].value)
                }}</span>
              </div>
              <nz-select formControlName="priority">
                <nz-option [nzValue]="3" nzLabel="Bình thường"></nz-option>
                <nz-option [nzValue]="2" nzLabel="Ưu tiên"></nz-option>
                <nz-option [nzValue]="1" nzLabel="Vip"></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="request-note">
            <button
              nz-button
              class="btn-printer mg-r-30"
              (click)="updateInfo()">
              Cập nhật
            </button>
            <button nz-button (click)="cancelEdit()">Hủy bỏ</button>
          </div>
        </div>
        <div class="portal-footer">
          <div class="mg-bt-20" (click)="scrollDow()">
            <span class="fs-16 mg-bt-30 color-his">Lịch sử chỉnh sửa</span>
            <i
              nz-icon
              [nzType]="!isCaretUp ? 'caret-down' : 'caret-up'"
              nzTheme="outline"></i>
          </div>
          <div *ngIf="isCaretUp" class="history-edit customTableCrollbar">
            <ul>
              <li *ngFor="let log of f['logs'].value">
                <div class="mock-time">
                  [ {{ log.dateModified | date: 'dd/MM/yyy HH:mm' }} -
                  {{ log.modifiedBy }}
                  ]
                </div>
                <div class="log-content" [innerHTML]="log.content"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
</app-m-modal>
