import { CommandModalComponent } from './command-modal/command-modal.component';
import { CommandStateModalComponent } from './command-state-modal/command-state-modal.component';
import { CommandDetailModalComponent } from './command-detail-modal/command-detail-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MedicalInstVsRoutingModule } from './medical-inst-vs-routing.module';
import { MedicalInstListComponent } from './medical-inst-list/medical-inst-list.component';
import { SharedModule } from 'src/app/share/share.module';

@NgModule({
  declarations: [
    MedicalInstListComponent,
    CommandDetailModalComponent,
    CommandStateModalComponent,
    CommandModalComponent,
  ],
  imports: [
    CommonModule,
    //MedicalInstVsRoutingModule,
    SharedModule,
  ],
  exports: [
    MedicalInstListComponent,
    CommandDetailModalComponent,
    CommandStateModalComponent,
    CommandModalComponent,
  ],
})
export class MedicalInstVsModule {}
