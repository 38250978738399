<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Chi tiết y lệnh"
  nzMaskClosable="false"
  (nzOnCancel)="cancelView()">
  <div class="row_info d-flex">
    <div class="title">Thông tin BN:&nbsp;</div>
    <div>
      {{ visit.patientName }} - {{ getPatientAge(visit.dob) }} -
      {{ visit.patientSexText }}<br />
      Địa chỉ: {{ visit.address }}<br />
      SĐT: {{ visit.phoneNo }}<br />
    </div>
  </div>
  <div class="row_info">
    <span class="title">Y lệnh: </span>{{ currentOrder.name }}
  </div>
  <div class="row_info">
    <span class="title">BS ra y lệnh: </span>{{ currentOrder.creatorName }}
  </div>
  <div class="row_info">
    <span class="title">Ca khám: </span
    >{{ visit.visitDate | date: 'dd/MM/yyyy HH:mm' }}
  </div>
  <div class="row_info">
    <span class="title">Trạng thái: </span>{{ currentOrder.statusText }}
  </div>
  <div class="row_info">
    <span class="title">Thời gian dự kiến: </span
    >{{ currentOrder.executeDate | date: 'dd/MM/yyyy HH:mm' }}
  </div>
  <div class="row_info">
    <span class="title">Người thực hiện: </span>{{ currentOrder.creatorName }}
  </div>
  <div *ngIf="currentOrder.orderResponse.length > 0" class="row_info">
    <span class="title"
      >Danh sách cập nhật trạng thái ({{
        currentOrder.orderResponse.length
      }}):</span
    >
    <ul class="log-content">
      <li *ngFor="let log of currentOrder.orderResponse" class="d-flex">
        <div class="title">
          - {{ log.date | date: 'dd/MM/yyyy HH:mm' }}:&nbsp;
        </div>
        <div>
          {{ log.who }} - {{ log.orderStatus }} <br />
          {{ log.description }}
        </div>
      </li>
    </ul>
  </div>
  <div *nzModalFooter>
    <button
      nz-button
      [disabled]="visit.status == VISIT_STATUS.Finished.value"
      [ngClass]="{
        'btn-primary-small': visit.status != VISIT_STATUS.Finished.value
      }"
      (click)="viewToUpdateStatus()">
      Cập nhật trạng thái
    </button>
    <button
      *ngIf="curUserId == currentOrder.creatorId && currentOrder.status != 3"
      nz-button
      class="btn-cancel-small"
      (click)="cancelOrder()">
      Hủy y lệnh
    </button>
    <button nz-button class="btn-cancel-small" (click)="cancelView()">
      Đóng
    </button>
  </div>
</nz-modal>
