<div class="title-wrapper">
  <!-- <p class="page-title">Quản trị danh sách bài viết</p> -->
  <div class="head-obsertype">
    <div class="box-searchObser d-flex">
      <input
        style="height: 32px"
        nz-input
        type="text"
        [(ngModel)]="searchKey"
        placeholder="Tìm kiếm..."
        (keydown.enter)="searchName()" />
      <button
        class=""
        style="border-radius: 0px 5px 5px 0px"
        nz-button
        nzType="primary"
        (click)="searchName()">
        <i nz-icon nzType="search" nzTheme="outline"></i>
      </button>
    </div>
    <div
      class="table-operations"
      style="margin-bottom: 10px; text-align: right">
      <button
        nz-button
        nzSize="default"
        nzType="primary"
        (click)="showModal()"
        class="search-button mg-r-10">
        Search
      </button>
      <button class="mg-r-10 btn-green" nz-button routerLink="/admin/post/new">
        <i nz-icon nzType="plus"></i>Thêm mới
      </button>
      <button
        class="mg-r-10"
        nz-button
        nzType="primary"
        [routerLink]="'/admin/post/' + selectedUrl">
        <i nz-icon nzType="form"></i>Sửa
      </button>
      <!-- <button class="" nz-button nzType="danger"><i nz-icon nzType="close"></i>Xoá</button> -->
    </div>
  </div>

  <nz-modal
    class="modal-admin"
    [(nzVisible)]="isVisible"
    nzTitle="Search"
    nzDraggable="true"
    nzMaskClosable="false"
    (nzClosable)="(true)"
    (nzOnCancel)="handleCancel()">
    <div class="msc-admin-row">
      <div class="msc-group-control width-obser">
        <label>Tên bài viết</label>
        <input type="text" [(ngModel)]="requestData.keyword" />
      </div>
    </div>

    <div class="msc-admin-row">
      <div class="msc-group-control width-obser">
        <label>Ngày tạo</label>
        <nz-date-picker
          class="date"
          [nzPlaceHolder]="'DD/MM/YYYY'"
          [nzFormat]="dateFormat"
          [(ngModel)]="requestData.startDate"></nz-date-picker>
      </div>
    </div>

    <div class="msc-admin-row">
      <div class="msc-group-control width-obser">
        <label>Người tạo </label>
        <nz-select
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="Select Creator"
          [(ngModel)]="requestData.creatorId">
          <nz-option nzCustomContent nzLabel="(trống)" nzValue=""
            >(trống)</nz-option
          >
          <nz-option
            nzCustomContent
            *ngFor="let data of creators"
            nzLabel="{{ data.name }}"
            nzValue="{{ data.id }}"
            >{{ data.name }}</nz-option
          >
        </nz-select>
      </div>
    </div>

    <div class="msc-admin-row">
      <div class="msc-group-control width-obser">
        <label>Category<span class="red-dot">*</span></label>
        <nz-select
          [nzMaxTagCount]="10"
          [nzMaxTagPlaceholder]="tagPlaceHolder"
          nzMode="multiple"
          nzPlaceHolder="Please select"
          [(ngModel)]="requestData.listCategory"
          style="width: 100%">
          <nz-option
            *ngFor="let item of list_child"
            [nzLabel]="item.name"
            [nzValue]="item.id"></nz-option>
        </nz-select>
        <ng-template #tagPlaceHolder let-selectedList>
          and {{ selectedList.length }} more selected
        </ng-template>
      </div>
    </div>

    <div *nzModalFooter>
      <button nz-button nzType="primary" (click)="getPosts(requestData)">
        Search
      </button>
      <button nz-button nzType="default" (click)="reset()">Reset</button>
    </div>
  </nz-modal>

  <nz-table
    #fixedTable
    nzSize="small"
    [nzPageSize]="pageSize"
    [nzData]="listOfDisplayData"
    [nzShowPagination]="true"
    nzShowSizeChanger
    [nzPageSizeOptions]="[10, 20, 50, 100]"
    [nzScroll]="{ y: 'calc(100vh - 280px)' }">
    <thead>
      <tr>
        <th nzWidth="60px">STT</th>
        <th>Tên bài viết</th>
        <th>Người tạo</th>
        <th>Ngày tạo</th>
        <th>Thể loại</th>
        <th>Url Name</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let data of fixedTable.data; let i = index"
        [ngClass]="{ 'highlight': selectedRowIndex == data.id }"
        (click)="RowSelected(data)">
        <td>
          {{ fixedTable.nzPageSize * (fixedTable.nzPageIndex - 1) + i + 1 }}
        </td>
        <td>{{ data.title }}</td>
        <td>{{ data.creatorName }}</td>
        <td>{{ data.createdDate | date: 'dd/MM/yyyy' }}</td>
        <td style="display: flex">
          <div *ngFor="let category of data.listCategory">
            {{ category.name }},&nbsp;
          </div>
        </td>
        <td>{{ data.urlName }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>
