<div class="form-input">
  <div
    nz-input
    [ngClass]="[
      data.formatStyle.isBold == true ? 'bold' : '',
      data.formatStyle.isItalic == true ? 'italic' : '',
      data.formatStyle.isUnderline == true ? 'underline' : '',
      data.formatStyle.align
    ]"
    [ngStyle]="{
      'color': data.formatStyle.color,
      'background': data.formatStyle.bgColor,
      'font-size': data.formatStyle.fontSize + 'px',
      'font-family': data.formatStyle.font,
      'height': '100%',
      'display': 'flex',
      'align-items': 'center'
    }">
    <span class="text-mg-l">
      <i nz-icon nzType="calculator" nzTheme="outline"></i>
      <span style="padding-left: 4px">{{ data.name }}</span>
    </span>
  </div>
</div>
<div click-stop-propagation class="active-elm"></div>
