import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Patient } from '../../../models/patient.class'; //api
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppState } from '../../app-state/app-state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  OpenDuplicatePatient,
  CloseDuplicatePatient,
  OpenAddPatient,
} from '../redux/modal/modal.action';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app-config.service';

@Component({
  selector: 'app-duplicate-patient',
  templateUrl: './duplicate-patient.component.html',
  styleUrls: ['./duplicate-patient.component.scss'],
})
export class DuplicatePatientComponent implements OnInit {
  isVisibleDuplicate: Observable<boolean>;
  @Input() numberDuplicate: number;
  @Input() duplicatePatients: Patient[];
  @Input() editedPatient: Patient;
  @Output() selectPatient: EventEmitter<any> = new EventEmitter();
  baseAvatarurl: string;

  constructor(
    private store: Store<AppState>,
    private configService: AppConfigService,
    private route: Router
  ) {
    this.isVisibleDuplicate = this.store.select(
      (store) => store.modals.isDuplicateOpen
    );
  }

  ngOnInit() {
    this.baseAvatarurl =
      this.configService.getConfig().media.baseUrl + '/Avatar/';
  }

  handleCancel(): void {
    this.store.dispatch(new CloseDuplicatePatient());
  }

  handleOk(): void {
    // console.log(this.editedPatient);
  }

  patientInfoToVisit(data: Patient, isCreateNew: boolean) {
    this.selectPatient.emit({
      ...data,
      lastVisitDate: data.lastVisit ? data.lastVisit.visitDate : '',
      isCreateNew,
    });
    this.store.dispatch(new CloseDuplicatePatient());
  }
}
