import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observation} from 'src/app/models/observationType.class';
import {NotificationService} from 'src/app/services/notification.service';
import {ObservationService} from 'src/app/services/observation.service';
import {ShareService} from 'src/app/services/share.service';
import {VisitService} from 'src/app/services/visit.service';
import {TableSelectionAbstract} from 'src/app/share/component/table/table-selection.abstract';
import {Constant} from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-xn-list',
  templateUrl: './xn-list.component.html',
  styleUrls: ['./xn-list.component.scss'],
})
export class XnListComponent extends TableSelectionAbstract implements OnInit {
  @Input() chisoxns = [];
  @Input() mockData = [];
  @Input() subClinicalXNs = [];
  @Input() visit;
  @Input() disabledAction = false;

  @Output() refresh = new EventEmitter();

  isVisibleEnterResult = false;
  isVisibleResult = false;
  currentTesting = null;
  currentResults = [];

  contants = Constant;

  constructor(
    private observationService: ObservationService,
    private notificationService: NotificationService,
    private visitService: VisitService,
    public shareService: ShareService
  ) {
    super('id');
  }

  ngOnInit(): void {
  }

  openTestingResult(data) {
    this.isVisibleEnterResult = true;
    this.currentTesting = JSON.stringify(JSON.parse(data));
    this.currentTesting.listOT.forEach(en => {
      en.valueRangeType = 0;
    });
  }

  closeTestingResult() {
    this.isVisibleEnterResult = false;
  }

  viewResult(data) {
    let listOTId = data.listOT.map((en) => en.id);
    this.isVisibleResult = true;
    this.currentResults = this.chisoxns.filter((en) => listOTId.includes(en.observationTypeId));

    console.log('currentResults', this.currentResults, data.listOT, this.chisoxns);
  }

  enterResult(data) {
    if (data.isMap == false) {
      let listOTId = data.listOT.map((en) => en.id);

      let lstResult = this.mockData.filter(
        (en) => en.visitId == this.visit.id && listOTId.includes(en.observationTypeId)
      );

      data.listOT.forEach((element) => {
        let old = lstResult.find((en) => en.observationTypeId == element.id);
        if (old != null) {
          element.value = old.value;
          element.observationId = old.id;
        }
        console.log(element);
        element.valueRangeType = element.valueRangeType ? element.valueRangeType : 0;
      });
    }

    this.currentTesting = data;
    this.isVisibleEnterResult = true;

    this.currentTesting.listOT.forEach((element) => {
      element.valueRangeType = element.valueRangeType ? element.valueRangeType : 0;
    });
    console.log(this.currentTesting);
  }

  closeResult() {
    this.isVisibleResult = false;
  }

  saveListObservationVS(): void {
    const listObservation = [];
    let lstObservationType = this.currentTesting.listOT;
    let valid = lstObservationType.find((en) => en.value == null || en.value == undefined);
    if (valid) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, ' Vui lòng nhập hết kết quả');
      return;
    }
    lstObservationType.forEach((en) => {
      const item = new Observation();
      this.convertData(item, en.value, en.dataType);
      item.observationTypeId = en.id;
      item.id = en.observationId;
      item.valueRangeType = en.valueRangeType;
      listObservation.push(item);
    });
    const payload: any = {
      subclincalVisitId: this.currentTesting.id,
      visitId: this.visit.id,
      patientId: this.visit.patientId,
      listObservation: listObservation,
      listObservationDel: [],
    };
    console.log('saveListObservationVS', payload);
    //return;
    this.observationService.saveListObservationAndOrderStatus(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, Constant.MESSAGE_UPDATE_SUCCESS);
        this.isVisibleEnterResult = false;
        //this.getObservationOfPatient();
        this.refresh.emit();
        this.currentTesting.resultType = 1;
        this.currentTesting.statusString = 'Đã thực hiện';
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  convertData(item: Observation, value, dataType) {
    let result = this.observationService.convertData(item, value, dataType);
    return result;
  }

  printResult() {
    let ids = this.getCheckedIdList();
    if (ids.length == 0) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Không có chỉ định để in');
      return;
    }
    let payload = {
      visitId: this.visit.id,
      subclinicalVisitIds: ids,
    };
    console.log('printResult', ids);

    //return;
    this.visitService.getLaboratoryResultReport(payload).subscribe((res) => {
      if (res.isValid) {
        let content = res.jsonData;
        this.shareService.printHtmlContent(content);
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }
}
