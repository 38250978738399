import { string } from '@amcharts/amcharts4/core';
import { Component, OnInit } from '@angular/core';
import { NewsService } from 'src/app/services/news.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-danh-sach-bai-viet',
  templateUrl: './danh-sach-bai-viet.component.html',
  styleUrls: ['./danh-sach-bai-viet.component.scss'],
})
export class DanhSachBaiVietComponent implements OnInit {
  public listForms: FormData[] = [];
  currentForm;
  isVisible = false;
  public inputValue?: string;
  public filterOption: string[] = [];
  public options: string[] = [];
  public creators = [];
  public requestData = {
    keyword: '',
    startDate: null,
    creatorId: '',
    endDate: null,
    take: 1000,
    skip: 0,
    listCategory: [],
  };

  listCategory = [];

  dateFormat = 'dd/MM/yyyy';

  emptyId = '000000000000000000000000';
  selectedRowIndex = this.emptyId;
  selectedUrl;

  list_child = [];
  list_posts;
  listOfDisplayData = [];
  searchKey = '';
  pageSize = 20;

  constructor(
    private notificationService: NotificationService,
    private newsService: NewsService
  ) {}

  ngOnInit() {
    this.selectedUrl = '';
    this.list_posts = [];
    this.creators = [];
    this.requestData = {
      keyword: '',
      startDate: null,
      creatorId: '',
      endDate: null,
      take: 1000,
      skip: 0,
      listCategory: [],
    };
    //this.showTable();
    this.list_child = [];
    this.getCreators();
    this.getListCategory();
    this.getPosts(this.requestData);
  }

  ontyping(value: string): void {
    this.filterOption = this.options.filter(
      (option) => option.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  reset(): void {
    this.requestData = {
      keyword: '',
      startDate: null,
      endDate: null,
      creatorId: '',
      take: 1000,
      skip: 0,
      listCategory: [],
    };
    this.getPosts(this.requestData);
  }

  getPosts(datasend) {
    datasend.keyword = datasend.keyword.trim();
    this.requestData = {
      keyword: datasend.keyword,
      startDate: datasend.startDate,
      endDate: null,
      creatorId: datasend.creatorId,
      take: 1000,
      skip: 0,
      listCategory: datasend.listCategory,
    };
    this.newsService.getNews(this.requestData).subscribe((data) => {
      this.list_posts = data.jsonData.data;
      this.listOfDisplayData = data.jsonData.data;
    });
    this.isVisible = false;
  }

  RowSelected(data) {
    this.selectedRowIndex = data.id;
    this.selectedUrl = data.urlName;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  showModal(): void {
    this.isVisible = true;
  }

  getListCategory() {
    this.newsService.getCategoryTree().subscribe((data) => {
      this.listCategory = data.jsonData;
      for (let i = 0; i < this.listCategory.length; i++) {
        this.listChilds(this.listCategory[i]);
      }
    });
  }

  haveChilds(category) {
    if (category.childs.length > 0) {
      return true;
    } else {return false;}
  }

  listChilds(category) {
    if (this.haveChilds(category)) {
      for (let j = 0; j < category.childs.length; j++) {
        if (this.haveChilds(category.childs[j])) {
          this.listChilds(category.childs[j]);
        } else {
          this.list_child.push(category.childs[j]);
        }
      }
    } else {
      this.list_child.push(category);
    }
  }

  getCreators() {
    this.newsService.getNews(this.requestData).subscribe((data) => {
      for (const i of data.jsonData.data) {
        if (this.checkId(i.creatorId, this.creators)) {
          const creator = { id: i.creatorId, name: i.creatorName };
          this.creators.push(creator);
        }
      }
    });
  }

  checkId(id, arr) {
    for (const i of arr) {
      if (id == i.id) {
        return false;
      }
    }

    return true;
  }
  searchName() {
    const keyword = this.searchKey.trim();
    if (keyword != '') {
      this.listOfDisplayData = this.list_posts.filter((en) =>
        en.title.toLowerCase().includes(keyword.toLowerCase())
      );
    } else {this.listOfDisplayData = this.list_posts;}
  }
}
