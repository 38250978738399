import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/components/app-state/app-state';
import { WorklistColumn } from 'src/app/models/worklist-column';
import { CommonInfoService } from 'src/app/services/commonInfo.service';
import { CustomColumnService } from 'src/app/services/custom-cloumn.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ObservationTypeService } from 'src/app/services/obserType-service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-custom-column-admin',
  templateUrl: './custom-column-admin.component.html',
  styleUrls: ['./custom-column-admin.component.scss'],
})
export class CustomColumnAdminComponent implements OnInit {
  _isVisible = false;
  @Input() set isVisible(val: boolean) {
    this._isVisible = val;
    if (val) {
      // let show = this.showColumn.filter(t => t.title != 'STT');
      this._showColumn = Object.assign([], this.showColumn);
      this._hideColumn = Object.assign([], this.hideColumn);
      this.updateCommonInfo();
    }
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  _showColumn: WorklistColumn[] = [];
  _hideColumn: WorklistColumn[] = [];
  lstCommonInfo: any = [];
  lstObservationType: any = [];
  lstFilterCI: any = [];
  groupId: string;
  groupSub: Subscription;
  search_KW_CI = '';
  search_KW_OT = '';
  callbackGetlistOTFn: any;
  isSpinningSearchOT: boolean;

  @Input() showColumn: WorklistColumn[] = [];
  @Input() hideColumn: WorklistColumn[] = [];
  @Input() modalTitle: string;

  @Output() isVisibleChange = new EventEmitter();
  @Output() saveColumns = new EventEmitter();
  constructor(
    private commonInfoService: CommonInfoService,
    private store: Store<AppState>,
    private OTService: ObservationTypeService,
    private shareService: ShareService,
    private notificationService: NotificationService,
    private customColumnService: CustomColumnService
  ) {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
      }
    });
    this.callbackGetlistOTFn = this.shareService.debounce(this.onInputOT, 500);
  }

  search_KW_OTChange(): void {
    this.isSpinningSearchOT = true;
    this.callbackGetlistOTFn();
  }

  ngOnInit() {
    this.getListCommonInfo();
    this.onInputOT();
  }

  addItem(item) {
    if (item.type == Constant.GRID_FIELD_TYPE.CI) {
      if (this._showColumn.filter((t) => t.field == item.field).length == 0) {
        this._showColumn.push(item);
        this.updateCommonInfo();
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Cột đã được thêm');
      }
    } else {
      if (this._showColumn.filter((t) => t.fieldId == item.fieldId).length == 0) {
        this._showColumn.push(item);
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Cột đã được thêm');
      }
    }
  }

  onInputOT(): void {
    const keyword = this.search_KW_OT.trim();

    const payload = {
      search: keyword,
      category: 0,
      dataType: 0,
      groupId: this.groupId,
    };
    this.OTService.getByGroup(payload).subscribe((response) => {
      let lst = [];
      response.forEach((data) => {
        let item = {
          field: data.oTcode,
          title: data.name,
          width: '120px',
          type: Constant.GRID_FIELD_TYPE.OT,
          fieldId: data.id,
          dataType: data.dataType,
          dataTypeText: data.datatypeText,
        };
        lst.push(item);
      });
      this.lstObservationType = lst;
    });
  }

  getListCommonInfo(): void {
    this.customColumnService.getPatientGridDefaultColumn({ type: 'CI' }).subscribe((response) => {
      this.lstCommonInfo = response.jsonData;
      this.updateCommonInfo();
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  search_KW_CIChange(): void {
    const keyword = this.search_KW_CI.toLowerCase();
    if (keyword) {
      this.lstFilterCI = this.lstCommonInfo.filter((item) => item.title.toLowerCase().includes(keyword));
    }
  }

  handleCancel() {
    this.isVisibleChange.emit(false);
  }
  handleOk() {
    this.saveColumns.emit(this._showColumn);
    this.isVisibleChange.emit(false);
  }

  removeItem(item) {
    if (item.type == Constant.GRID_FIELD_TYPE.CI) {
      this._showColumn = this._showColumn.filter((t) => t.field != item.field);
      this.lstFilterCI.add(item);
    } else {
      this._showColumn = this._showColumn.filter((t) => t.fieldId != item.fieldId);
    }
  }

  updateCommonInfo() {
    this.lstFilterCI = this.lstCommonInfo.filter(
      (col) => this._showColumn.filter((t) => t.field == col.field).length == 0
    );
  }
}
