import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';
import { LabelElement } from 'src/app/models/form-model/form.class';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-label-element',
  templateUrl: './label-element.component.html',
  styleUrls: ['./label-element.component.scss'],
})
export class LabelElementComponent implements OnInit {
  html = '';
  _data: LabelElement;
  isSingleClick: Boolean = true;
  @Input() set data(data: LabelElement) {
    if (data) {
      this.html = data.value;
      this._data = data;
    }
  }

  get data(): LabelElement {
    return this._data;
  }

  @Input() selectedElement: any;
  @Input() isFocus: boolean;
  @Output() selectElement: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('editContent') editContent: ElementRef;
  constructor() {}

  ngOnInit() {}

  onResizeEnd(event: ResizeEvent): void {}

  onContentChange(data) {
    this._data.value = data.innerHTML;
  }

  labelClickEvent(data, item) {
    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        this.selectElement.emit({
          item,
          type: Constant.FORMELEMENT.LABEL,
          clickType: Constant.CLICK_TYPE.SINGLE,
        });
      }
    }, 250);
  }

  labelDbClickEvent(data, item) {
    this.isSingleClick = false;
    this.selectElement.emit({
      item,
      type: Constant.FORMELEMENT.LABEL,
      clickType: Constant.CLICK_TYPE.DOUBLE,
    });
    this.editContent.nativeElement.focus();
  }

  inputClick(e) {
    console.log(e);
  }
}
