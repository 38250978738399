import { FormatStyle } from './formatStyle.model';
import { FormObject } from './formObject.model';

export class FormElement extends FormObject {
  id: string;
  width = 200;
  height = 30;
  observationType: any;
  editType: number;
  viewType: string;
  value: any;
  valueString: string;
  valueBoolean: boolean;
  valueInteger: any;
  valueTime: any;
  valueDateTime: any;
  valueSelections: any;
  maxNumberValue?: number;
  minNumberValue?: number;
  isRequired?: boolean = false;
  formatStyle: FormatStyle = new FormatStyle();

  constructor(id, observationType, editType, viewType) {
    super();
    this.viewId = id;
    this.observationType = observationType;
    this.editType = editType;
    this.viewType = viewType;
  }

  toSave(): any {
    return {
      viewId: this.viewId,
      id: this.id,
      OId: this.observationType.Id,
      editType: this.editType ? 1 : 0,
      viewType: this.viewType,
    };
  }
}
