import { CoordinateElement } from './../../../../../models/form-model/coordinate-element';
import { select } from '@ngrx/store';
import { CalculatedVariable } from './../../../../../models/form-model/calculated-variable';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-recommend-context',
  templateUrl: './recommend-context.component.html',
  styleUrls: ['./recommend-context.component.scss'],
})
export class RecommendContextComponent implements OnInit {
  _isVisible: boolean;
  @Input() variables: CalculatedVariable[];
  @Input() coordinate: CoordinateElement;
  @Input() set isVisible(val) {
    this._isVisible = val;
    if (val) {
    }
  }

  get isVisible() {
    return this._isVisible;
  }

  @Output() selectVariableEvent: EventEmitter<any> = new EventEmitter();
  @Output() isVisibleChange: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  showContext() {}

  selectVariable(item) {
    this.selectVariableEvent.emit(item);
  }
}
