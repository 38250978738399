<app-m-modal
  [modalId]="'add-vital-asign'"
  [isVisible]="isVisible"
  (closeModal)="handleCancel()"
  [mMask]="true"
  [minWidth]="700"
  [isResiable]="true">
  <ng-template #mModalTitle>
    <div class="header-title">Cập nhật chỉ số sinh tồn</div>
  </ng-template>
  <ng-template #mModalContent>
    <div *ngIf="lstObservationType.length > 0" class="mModalContent">
      <div class="row" *ngFor="let in of counter(numRow); let i = index">
        <div class="col-6" [ngSwitch]="lstObservationType[i * 2].dataType">
          <span>{{ lstObservationType[i * 2].name }}</span>
          <div class="col6-element" *ngSwitchCase="DataType.NUMBER">
            <ng-container
              *ngIf="
                lstObservationType[i * 2].validMinValue != null && lstObservationType[i * 2].validMaxValue != null;
                else numberNormal
              ">
              <nz-input-number
                nz-input
                [nzMin]="lstObservationType[i * 2].validMinValue"
                [nzMax]="lstObservationType[i * 2].validMaxValue"
                [(ngModel)]="lstObservationType[i * 2].value"></nz-input-number>
              <span class="valid-range">
                <ng-container *ngIf="lstObservationType[i * 2].validMinValue != null"
                  >Min: {{ lstObservationType[i * 2].validMinValue }};</ng-container
                >
                <ng-container *ngIf="lstObservationType[i * 2].validMaxValue != null"
                  >Max: {{ lstObservationType[i * 2].validMaxValue }};</ng-container
                >
              </span>
            </ng-container>
            <ng-template #numberNormal>
              <nz-input-number nz-input [(ngModel)]="lstObservationType[i * 2].value"></nz-input-number>
            </ng-template>
          </div>
          <div class="col6-element" *ngSwitchCase="DataType.STRING">
            <input nz-input [(ngModel)]="lstObservationType[i * 2].value" />
          </div>
          <div class="col6-element" *ngSwitchCase="DataType.BOOLEAN">
            <nz-radio-group [(ngModel)]="lstObservationType[i * 2].value">
              <label nz-radio nzChecked [nzValue]="true">Có</label>
              <label nz-radio [nzValue]="false">Không</label>
            </nz-radio-group>
          </div>
          <div class="col6-element" *ngSwitchCase="DataType.SINGLECHOICE">
            <nz-select
              [nzMode]="default"
              [nzPlaceHolder]="lstObservationType[i * 2].name"
              [(ngModel)]="lstObservationType[i * 2].value"
              style="width: 309px;">
              <nz-option
                *ngFor="let item of lstObservationType[i * 2].dataSource"
                [nzLabel]="item.text"
                [nzValue]="item.id">
              </nz-option>
            </nz-select>
          </div>
          <div class="col6-element" *ngSwitchCase="DataType.MUILTICHOICE">
            <nz-select
              nzAllowClear
              nzPlaceHolder="Select item"
              nzMode="multiple"
              [(ngModel)]="lstObservationType[i * 2].value"
              style="width: 309px;">
              <nz-option
                *ngFor="let item of lstObservationType[i * 2].dataSource"
                [nzLabel]="item.text"
                [nzValue]="item.id"></nz-option>
            </nz-select>
          </div>
          <div class="col6-element" *ngSwitchCase="DataType.DATE">
            <nz-date-picker
              nzFormat="yyyy-MM-dd"
              [(ngModel)]="lstObservationType[i * 2].value"
              [nzPlaceHolder]="lstObservationType[i * 2].name">
            </nz-date-picker>
          </div>
          <div class="col6-element" *ngSwitchCase="DataType.DATETIME">
            <nz-date-picker
              nzFormat="yyyy-MM-dd HH:mm:ss"
              nzShowTime
              [(ngModel)]="lstObservationType[i * 2].value"
              [nzPlaceHolder]="lstObservationType[i * 2].name">
            </nz-date-picker>
          </div>
        </div>
        <div
          *ngIf="lstObservationType.length >= i * 2 + 2"
          class="col-6"
          [ngSwitch]="lstObservationType[i * 2 + 1].dataType">
          <span>{{ lstObservationType[i * 2 + 1].name }}</span>
          <div class="col6-element" *ngSwitchCase="DataType.NUMBER">
            <ng-container
              *ngIf="
                lstObservationType[i * 2 + 1].validMinValue != null &&
                  lstObservationType[i * 2 + 1].validMaxValue != null;
                else numberNormalEven
              ">
              <nz-input-number
                nz-input
                [nzMin]="lstObservationType[i * 2 + 1].validMinValue"
                [nzMax]="lstObservationType[i * 2 + 1].validMaxValue"
                [(ngModel)]="lstObservationType[i * 2 + 1].value"></nz-input-number>
              <span class="valid-range">
                <ng-container *ngIf="lstObservationType[i * 2 + 1].validMinValue != null">
                  Min: {{ lstObservationType[i * 2 + 1].validMinValue }};
                </ng-container>
                <ng-container *ngIf="lstObservationType[i * 2 + 1].validMaxValue != null">
                  Max: {{ lstObservationType[i * 2 + 1].validMaxValue }};
                </ng-container>
              </span>
            </ng-container>
            <ng-template #numberNormalEven>
              <nz-input-number nz-input [(ngModel)]="lstObservationType[i * 2 + 1].value"></nz-input-number>
            </ng-template>
          </div>
          <div class="col6-element" *ngSwitchCase="DataType.STRING">
            <input nz-input [(ngModel)]="lstObservationType[i * 2 + 1].value" />
          </div>
          <div class="col6-element" *ngSwitchCase="DataType.BOOLEAN">
            <nz-radio-group [(ngModel)]="lstObservationType[i * 2 + 1].value">
              <label nz-radio nzChecked [nzValue]="true">Có </label>
              <label nz-radio [nzValue]="false">Không</label>
            </nz-radio-group>
          </div>
          <div class="col6-element" *ngSwitchCase="DataType.SINGLECHOICE">
            <nz-select
              [nzMode]="default"
              [nzPlaceHolder]="lstObservationType[i * 2 + 1].name"
              [(ngModel)]="lstObservationType[i * 2 + 1].value"
              style="width: 309px;">
              <nz-option
                *ngFor="let item of lstObservationType[i * 2 + 1].dataSource"
                [nzLabel]="item.text"
                [nzValue]="item.id">
              </nz-option>
            </nz-select>
          </div>
          <div class="col6-element" *ngSwitchCase="DataType.MUILTICHOICE">
            <ng-select [multiple]="true" [(ngModel)]="lstObservationType[i * 2 + 1].value">
              <ng-option *ngFor="let item of lstObservationType[i * 2 + 1].dataSource" [value]="item.id">{{
                item.text
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col6-element" *ngSwitchCase="DataType.DATE">
            <nz-date-picker
              nzFormat="yyyy-MM-dd"
              [(ngModel)]="lstObservationType[i * 2 + 1].value"
              [nzPlaceHolder]="lstObservationType[i * 2 + 1].name">
            </nz-date-picker>
          </div>
          <div class="col6-element" *ngSwitchCase="DataType.DATETIME">
            <nz-date-picker
              nzFormat="yyyy-MM-dd HH:mm:ss"
              nzShowTime
              [(ngModel)]="lstObservationType[i * 2 + 1].value"
              [nzPlaceHolder]="lstObservationType[i * 2 + 1].name">
            </nz-date-picker>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #mModalFooter>
    <button nz-button class="btn-primary-medium" (click)="handleOk()">Cập nhật</button>
    <button nz-button class="btn-cancel-medium" (click)="handleCancel()">Quay lại</button>
  </ng-template>
</app-m-modal>
