import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { AppState } from '../../app-state/app-state';
import { LoginSuccess, Logout, ResetLockScreen } from '../../auth/redux/auth.action';

@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.scss'],
})
export class LockScreenComponent implements OnInit {
  isVisible = false;
  isLoading = false;
  isLock: Observable<boolean>;
  username: string;
  messageError: string;

  validateForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private authService: AuthService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
    this.isLock = this.store.select((store) => store.auth.isLock);

    this.isLock.subscribe((res) => {
      this.isVisible = res;
    });

    this.store.select('auth').subscribe((res) => {
      this.username = res.lastname ? res.lastname : res.userName;
      this.validateForm.patchValue({
        username: res.userName,
      });
    });
  }

  changeAccount() {
    this.store.dispatch(new Logout(''));
    this.route.navigate(['/login']);
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.invalid) {
      return;
    }

    const payload = {
      userName: this.validateForm.value.username,
      password: this.validateForm.value.password,
    };

    this.authService.login(payload).subscribe(
      (res) => {
        if (res !== null) {
          localStorage.setItem(Constant.TOKEN, res.value);
          localStorage.setItem(Constant.USER_INFO, JSON.stringify(res));
          this.store.dispatch(new LoginSuccess(res));
          localStorage.setItem(Constant.LOCKSTATE, '1');
          this.store.dispatch(new ResetLockScreen());
          this.validateForm.reset();
          this.messageError = '';
        }
      },
      (error) => {
        this.messageError = 'Sai mật khẩu';
        this.isLoading = false;
      }
    );
  }
}
