<div class="chat-wrapper">
  <div class="chat-header {{ hideButton }}">
    <form class="message-form" [formGroup]="chatForm" (ngSubmit)="sendChat(chatForm.value)">
      <nz-spin [nzSpinning]="isUpload">
        <div dropFile (fileDropped)="onFileDropped($event)">
          <angular-editor
            #chateditor
            [config]="editorConfig"
            formControlName="content"
            [placeholder]="'Nhập nội dung ghi chép'"
            (paste)="onPaste($event)"
            [ngStyle]="{ 'background-color': '#ffffff' }"></angular-editor>
          <button class="btn-save btn-add" *ngIf="chatForm.get('content').valid">
            <i nz-icon nzType="save" nzTheme="fill"></i>
          </button>
          <div style="overflow: auto">
            <div class="lst-img" *ngFor="let image of images; let i = index">
              <app-thumbnail-image [src]="image" (deleteImage)="deleteFile(i)"></app-thumbnail-image>
            </div>
          </div>
        </div>
      </nz-spin>
    </form>
  </div>
  <div class="chat-body" #chatcontent>
    <div class="chat-content" id="chatcontent">
      <div class="message-box" *ngFor="let chat of chats">
        <div class="chat-status" text-center *ngIf="chat.type === 'join' || chat.type === 'exit'; else message">
          <span class="chat-date">
            {{ chat.dateLog | date: 'dd/MM/yyyy' }}
          </span>
          <span class="chat-content-center">{{ chat.message }}</span>
        </div>
        <ng-template #message>
          <div class="chat-message">
            <div class="center-bubble">
              <div class="" style="position: relative">
                <div class="msg-title d-flex">
                  <div>
                    <!-- <span nz-icon nzType="clock-circle" nzTheme="outline"></span> -->
                    <span *ngIf="chat.visitId == visit.id" class="msg-date">
                      {{ chat.visitDate | date: 'dd/MM/yyyy HH:mm' }}
                    </span>
                    <span
                      *ngIf="chat.visitId != visit.id"
                      class="msg-date msg-date-hover"
                      (click)="openVisit(chat.visitId)"
                      title="Mở ca khám ngày {{ chat.visitDate | date: 'dd/MM/yyyy HH:mm' }}">
                      {{ chat.visitDate | date: 'dd/MM/yyyy HH:mm' }}
                    </span>
                  </div>
                  <div>
                    <span class="msg-reason m-l-2">
                      - <span class="text-reason">{{ chat.reason }}</span></span
                    >
                  </div>

                  <div class="div-btn {{ hideButton }}" *ngIf="chat.canEdit">
                    <button
                      *ngIf="chat.visitId != visit.id"
                      (click)="openVisit(chat.visitId)"
                      title="Mở ca khám"
                      class="btn-redirect mg-r-4">
                      <i nz-icon nzType="right-circle" nzTheme="outline"></i>
                    </button>
                    <ng-container *ngIf="chat.doctorId === currentUserId">
                      <button
                        *ngIf="!chat.showEditor"
                        (click)="showEditor(chat)"
                        class="btn-add mg-r-4 bd-r-4"
                        title="Chỉnh sửa">
                        <i nz-icon nzType="edit" nzTheme="fill"></i>
                      </button>
                      <button
                        *ngIf="!chat.showEnlarge"
                        (click)="showEnlarge(chat)"
                        class="btn-add mg-r-4 bd-r-4"
                        title="Phóng to">
                        <i nz-icon nzType="form" nzTheme="outline"></i>
                      </button>
                      <button
                        *ngIf="!chat.showEditor"
                        (click)="confirmDelete(chat)"
                        class="btn-cancel bd-r-4"
                        title="Xóa">
                        <i nz-icon nzType="close" nzTheme="outline"></i>
                      </button>
                      <button
                        *ngIf="chat.showEditor"
                        (click)="cancelUpdate(chat)"
                        class="mg-r-4 btn-cancel bd-r-4"
                        title="Hủy">
                        <i nz-icon nzType="close-square" nzTheme="outline"></i>
                      </button>
                      <button
                        *ngIf="chat.showEditor"
                        (click)="updateNotes(chat)"
                        class="btn-add bd-r-4"
                        title="Cập nhật">
                        <i nz-icon nzType="save" nzTheme="fill"></i>
                      </button>
                    </ng-container>
                  </div>
                </div>
                <div class="divider"></div>
                <ng-container *ngIf="chat.showEditor">
                  <angular-editor
                    *ngIf="chat.canEdit"
                    [config]="editorConfig"
                    [(ngModel)]="chat.content"
                    [placeholder]="'Enter text here...'"
                    (paste)="onPaste($event)"></angular-editor>
                </ng-container>
                <div class="msg-content">
                  <p *ngIf="!chat.showEditor" text-wrap read-more [innerHtml]="chat.content | safeHtml"></p>
                </div>
                <div class="divider"></div>
                <div class="msg-footer">
                  <!-- <span class="msg-name" *ngIf="chat.doctorId === userInfo.userId">Tôi</span> -->
                  <span class="msg-name font-weight-bold"
                    ><span nz-icon nzType="user" nzTheme="outline" class="icon-user"></span> {{ chat.doctorName }}</span
                  >
                  <!-- <span class="msg-name" *ngIf="chat.showEditor"> chỉnh sửa </span> -->
                  <span class="msg-date"> | {{ chat.dateLog | date: 'dd/MM/yyyy hh:mm' }} </span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <nz-modal
    nzClassName="slideShowImage"
    [(nzVisible)]="isVisibleListImage"
    (nzOnCancel)="cancelSlideShow()"
    [nzFooter]="null">
    <!-- <i class="rotateIcon {{hideButton}}" (click)="rotateVisitImage(visitId,slide.id)">
        <img src="assets/image/DPicon/rotate.svg">
    </i> -->
    <!-- <i
      class="left"
      nz-icon
      nzType="left"
      nzTheme="outline"
      (click)="preImage(slide.index)"
    ></i>
    <i
      class="right"
      nz-icon
      nzType="right"
      nzTheme="outline"
      (click)="nextImage(slide.index)"
    ></i> -->
    <div class="bigImage">
      <img class="i{{ slide.id }}" [src]="slide.url" />
    </div>
    <!-- <div class="index">{{ slide.index + 1 }}/{{ lstImages.length }}</div> -->
  </nz-modal>
  <app-masternote-enlarge
    *ngFor="let item of chats"
    [isVisible]="item.showEnlarge"
    [noteId]="item.id"
    (closeModal)="closeEnlargeModal($event)"></app-masternote-enlarge>
</div>
