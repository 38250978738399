import { FormulaViewComponent } from './formula-view/formula-view.component';
import { NgxPrinterModule } from './../../../../projects/ngx-printer/src/lib/ngx-printer.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form.component';
import { CanvasElementComponent } from './canvas-element/canvas-element.component';
import { SharedModule } from 'src/app/share/share.module';
import { LabelComponent } from './label/label.component';
import { CommonInfoComponent } from './common-info/common-info.component';
import { ImageElementComponent } from './image-element/image-element.component';
import { InputElementComponent } from './input-element/input-element.component';
import { DropdownElementComponent } from './dropdown-element/dropdown-element.component';
import { TextareaElementComponent } from './textarea-element/textarea-element.component';
import { DatatimeElementComponent } from './datatime-element/datatime-element.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { SelectionElementComponent } from './selection-element/selection-element.component';
import { CheckBoxElementComponent } from './check-box-element/check-box-element.component';
import { CanvasElementAngularComponent } from './canvas-element-angular/canvas-element-angular.component';

@NgModule({
  imports: [CommonModule, NgxPrinterModule.forRoot({ printOpenWindow: true }), SharedModule],
  declarations: [
    FormComponent,
    CanvasElementComponent,
    CanvasElementAngularComponent,
    LabelComponent,
    CommonInfoComponent,
    ImageElementComponent,
    InputElementComponent,
    DropdownElementComponent,
    TextareaElementComponent,
    DatatimeElementComponent,
    InputNumberComponent,
    SelectionElementComponent,
    CanvasElementComponent,
    CheckBoxElementComponent,
    FormulaViewComponent,
  ],
  exports: [FormComponent],
})
export class FormModule {}
