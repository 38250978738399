import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class TreatmentProcessService extends BaseService {
  getTreatmentProcessByFilter(payload): Observable<any> {
    return this.post(UrlConstant.TREATMENT_PROCESS + '/GetByFilter', payload);
  }

  getAllTreatmentProcess(groupId): Observable<any> {
    return this.get(UrlConstant.TREATMENT_PROCESS + '/GetAll', {
      groupId,
    });
  }

  getTreatmentProcessById(id: string): Observable<any> {
    return this.get(UrlConstant.TREATMENT_PROCESS + '/' + id);
  }

  saveTreatmentProcess(payload): Observable<any> {
    return this.post(UrlConstant.TREATMENT_PROCESS, payload);
  }

  // updateTreatmentProcess(id: string, payload): Observable<any> {
  //   return this.put(UrlConstant.TREATMENT_PROCESS + '/' + id, payload);
  // }

  removeTreatmentProcess(id: string): Observable<any> {
    return this.delete(UrlConstant.TREATMENT_PROCESS + '/' + id, '');
  }

  updateStatus(id: string, disabled: boolean): Observable<any> {
    return this.post(
      UrlConstant.TREATMENT_PROCESS + '/UpdateStatus/' + id,
      '',
      { disabled }
    );
  }
  getTreatmentPatient(pId: string): Observable<any> {
    return this.get(UrlConstant.TREATMENT_PATIENT + '/' + pId);
  }
  applyTreatmentPatient(pId: string, payload): Observable<any> {
    return this.post(UrlConstant.APPLY_TREATMENT_PATIENT + '/' + pId, payload);
  }
}
