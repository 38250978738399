<div *ngIf="isVisible">
  <nz-select
    nzShowSearch
    nzAllowClear
    nzPlaceHolder="Chọn Observation"
    [(ngModel)]="_observationId"
    style="width: 100%"
    class="mg-bt-5"
    (ngModelChange)="editObservationTypeChange()">
    <nz-option
      *ngFor="let observation of observationItems"
      [nzValue]="observation.id"
      [nzLabel]="observation.name">
    </nz-option>
  </nz-select>
  <br />
  <nz-select
    [(ngModel)]="_dataType"
    style="width: 100%"
    [nzDisabled]="true"
    class="mg-bt-5">
    <nz-option [nzValue]="1" nzLabel="Number"> </nz-option>
    <nz-option [nzValue]="5" nzLabel="STRING"> </nz-option>
    <nz-option [nzValue]="4" nzLabel="BOOLEAN"> </nz-option>
    <nz-option [nzValue]="6" nzLabel="TIME"> </nz-option>
    <nz-option [nzValue]="7" nzLabel="DATE"> </nz-option>
    <nz-option [nzValue]="8" nzLabel="DATETIME"> </nz-option>
    <nz-option [nzValue]="9" nzLabel="SINGLECHOICE"> </nz-option>
    <nz-option [nzValue]="10" nzLabel="MUILTICHOICE"> </nz-option>
  </nz-select>
  <br />
  <!-- RencentElement Editable -->
  <nz-radio-group
    [(ngModel)]="_editType"
    class="mg-bt-5"
    (ngModelChange)="editTypeChange()">
    <label nz-radio nzValue="readonly">ReadOnly</label>
    <label nz-radio nzValue="editable">Editable</label>
  </nz-radio-group>

  <ng-container *ngIf="!isRecentElement">
    <nz-radio-group
      [(ngModel)]="_isRequired"
      class="mg-bt-5"
      (ngModelChange)="onRequiredChange()">
      <label nz-radio [nzValue]="true"
        ><span style="color: red">* </span>Bắt buộc</label
      >
      <label nz-radio [nzValue]="false">Không bắt buộc</label>
    </nz-radio-group>
  </ng-container>

  <nz-radio-group
    *ngIf="isSelection"
    [(ngModel)]="_viewType"
    class="mg-bt-5"
    (ngModelChange)="viewTypeChange()">
    <label nz-radio nzValue="selection">Selection</label>
    <label nz-radio nzValue="dropdown">Dropdown</label>
  </nz-radio-group>

  <nz-radio-group
    *ngIf="isBoolean"
    [(ngModel)]="_viewType"
    class="mg-bt-5"
    (ngModelChange)="viewTypeChange()">
    <label nz-radio nzValue="checkbox">Checkbox</label>
    <label nz-radio nzValue="radio">Radio</label>
  </nz-radio-group>

  <nz-radio-group
    *ngIf="_viewType == 'selection'"
    [(ngModel)]="_showType"
    class="mg-bt-5"
    (ngModelChange)="showTypeChange()">
    <label nz-radio nzValue="horizontal">Ngang</label>
    <label nz-radio nzValue="vertical">Dọc</label>
  </nz-radio-group>
  <div *ngIf="!isRecentElement && _dataType == 1">
    <label>Min: </label>
    <nz-input-number
      class="mg-l-2"
      [(ngModel)]="_minValue"
      (ngModelChange)="onMinValueChange()"></nz-input-number>
    <label class="mg-l-2">Max: </label>
    <nz-input-number
      class="mg-l-2"
      [(ngModel)]="_maxValue"
      (ngModelChange)="onMaxValueChange()"></nz-input-number>
  </div>
</div>
