<div
  class="form-input form-elm"
  *ngIf="data.viewType == 'input' || data.viewType == 'checkbox'">
  <label
    nz-checkbox
    [ngClass]="[
      data.formatStyle.isBold == true ? 'bold' : '',
      data.formatStyle.isItalic == true ? 'italic' : '',
      data.formatStyle.isUnderline == true ? 'underline' : '',
      data.formatStyle.align
    ]"
    [ngStyle]="{
      'color': data.formatStyle.color,
      'background': data.formatStyle.bgColor,
      'font-size': data.formatStyle.fontSize + 'px',
      'font-family': data.formatStyle.font
    }"
    [nzDisabled]="data.editType == 0"
    >{{ data.observationType.name }}</label
  >
</div>

<div class="form-input form-elm" *ngIf="data.viewType == 'radio'">
  <nz-radio-group
    [ngClass]="[
      data.formatStyle.isBold == true ? 'bold' : '',
      data.formatStyle.isItalic == true ? 'italic' : '',
      data.formatStyle.isUnderline == true ? 'underline' : ''
    ]"
    [ngStyle]="{
      background: data.formatStyle.bgColor,
      'font-size': data.formatStyle.fontSize + 'px',
      'height': '100%',
      'font-family': data.formatStyle.font
    }">
    <label
      nz-radio
      [nzValue]="true"
      [ngStyle]="{ 'margin-left': '8px', color: data.formatStyle.color }"
      >Có</label
    >
    <label
      nz-radio
      [nzValue]="false"
      [ngStyle]="{ 'margin-left': '8px', color: data.formatStyle.color }"
      >Không</label
    >
  </nz-radio-group>
</div>
