import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NzModalService} from 'ng-zorro-antd/modal';
import {AddSubClinicalVisit, SearchSuborderAndForm} from 'src/app/models/sub-clinical.class';
import {RecordVisitService} from 'src/app/services/record-visit-service';
import {RosService} from 'src/app/services/ros-service';
import {SubClinicalVisitService} from 'src/app/services/subcliniccal-service';
import {Constant} from 'src/app/share/constants/constant.class';
import {NotificationService} from 'src/app/services/notification.service';
import {ShareService} from 'src/app/services/share.service';
import {AppConfigService} from 'src/app-config.service';
import {Visits} from 'src/app/models/listVisit.class';
import {SubclinicalorderService} from 'src/app/services/subclinicalorder.service';
import {Subscription, throwError} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/components/app-state/app-state';
import {VisitService} from "../../../../services/visit.service";
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-ros-and-subclinic',
  templateUrl: './ros-and-subclinic.component.html',
  styleUrls: ['./ros-and-subclinic.component.scss'],
})
export class RosAndSubclinicComponent implements OnInit {
  _visitId = '';
  _loadRecord: boolean;

  _visit: Visits = {
    stt: '',
    sttsearchresult: '',
    id: '',
    patientId: '',
    patientName: '',
    patientCode: '',
    groupId: '',
    doctorId: '',
    creatorId: '',
    patientSex: '',
    patientSexText: '',
    yob: 0,
    dob: '',
    dobString: '',
    phoneNo: '',
    address: '',
    visitDate: null,
    visitReason: '',
    visitTypeText: '',
    visitEndDate: null,
    color: '',
    shortName: '',
    age: 0,
    imageUrl: '',
    diseases: [],
    doctorName: '',
    groupName: '',
    images: [],
    documents: [],
    pdfDocuments: [],
    avatar: '',
    clinicalInfomation: '',
    conclusion: '',
    tags: [],
    dateOfDiagnosis: null,
    dateOfSymptom: null,
    doctorInCareDoct: '',
    nursingInCare: '',
    examinedStatus: false,
    examinedByDoctorId: '',
    examinedByDoctorName: '',
  };
  @Output() outSubClinicalVisit = new EventEmitter();
  @Output() titleTabRefresh = new EventEmitter();


  @Input() set visitId(val: string) {
    this._visitId = val;
    if (this._visitId) {
      this.loadData(this._visitId);
    }
  }

  get visitId(): string {
    return this._visitId;
  }

  get loadRecord(): boolean {
    return this._loadRecord;
  }

  @Input() set visit(val: Visits) {
    this._visit = val;
    if (val && val.id) {
      this.getSubClinicalVisitByRelated();
      this.getSubOrder();
    }
  }


  get visit() {
    return this._visit;
  }

  @Input() set loadRecord(val: boolean) {
    this._loadRecord = val;
    if (this._loadRecord) {
      this.loadData(this.visitId);
    }
  }

  @Input() groupId = '';

  @Input() master: string;
  @Input() hideButton: string;
  @Input() disabledClass: string;
  @Input() disabledEditInfo = false;

  @Output() openPhieudl = new EventEmitter<any>();

  ros;
  recordId = '';
  selectedRowCate = '';
  selectedRowClinic;
  selectedRowSubVisit;
  filterRos = '';
  rosId: any = '';
  rosCategory = [];
  rosName = 'ROS';
  // groupId = '000000000000000000000000';
  subclivisit = [];
  subcliorder;
  listPhieudl = [];
  dataAddSubVisit: AddSubClinicalVisit;
  listRecord: [];
  callbackFn: any;
  isSpinning = false;
  isMobile = false;
  isCollapsed = true;
  filterRosItem: any;
  totalOfSubclinical = 0;

  // showAddCdha: boolean = false;
  // showAddXn: boolean = false;
  showAddSubOrder = false;
  lstCdhaSource: any;
  lstXnSource: any;
  groupSub: Subscription;

  rosIdConfig = this.configService.getConfig().ROSId;
  rosConstant = Constant.SUBCLINICAL_ORDER_STRING;
  rosIdSelected = '';
  visibleSubOrderGroupAdd = false;
  subClinicalVisitSource = [];
  subClinicalVisitCustomSource = [];
  subClinicalVisit = [];
  subClinicalVisitModal = [];
  subOrderSource = [];
  isLoading = false;
  showOrderPrinterPreview = false;
  printContents: any;
  configGroup = {
    autoConvert2InProgressVisit: false,
    pushSubclinicalToHIS: false,
    orderPrintType: 1,
  };
  printAll = false;
  isErrorNotificationDisplayed: boolean = false;

  constructor(
    private modalService: NzModalService,
    private rosService: RosService,
    private shareService: ShareService,
    private subClinicalVisitService: SubClinicalVisitService,
    private recordVisitService: RecordVisitService,
    private notificationService: NotificationService,
    private configService: AppConfigService,
    private subclinicalorderService: SubclinicalorderService,
    private store: Store<AppState>,
    private visitService: VisitService,
  ) {
    this.callbackFn = this.shareService.debounce(this.getSubClinicalAndForm, 1000);
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
      }
    });
  }

  ngOnInit() {
  }

  loadData(vsid) {
    if (!this.shareService.checkEmpty(this.groupId)) {
      this.getSubClinicalVisit(vsid);
      this.getListRecordVisitAwait(vsid);
    }
  }

  getListRos() {
    this.rosService.getRos().subscribe((data) => {
      this.ros = data;
    });
  }

  // getSubClinicalVisit(vsId: string) {
  //   this.subClinicalVisitService.getSubClinicalVisit(vsId).subscribe((data) => {
  //     this.subclivisit = data;
  //     this.totalOfSubclinical = this.subclivisit.length;
  //   });
  // }

  getSubClinicalVisit(vsId: string) {

    /*this.subClinicalVisitService.getSubClinicalVisit(vsId).subscribe((data) => {
      this.subclivisit = data;
      console.log('getSubClinicalVisit', this.subclivisit);
      this.totalOfSubclinical = this.subclivisit.length;
    });*/
    this.visit.id = vsId;
    this.getSubClinicalVisitByRelated();
  }

  // apiDeleteMulti(data) {
  //   let payload = { ids: data.ids };
  //   this.subClinicalVisitService.deleteMulti(payload).subscribe((res) => {
  //     if (res.isValid) {
  //       this.getSubClinicalVisitByRelated();
  //       this.titleTabChange();

  //       this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, Constant.MESSAGE_UPDATE_SUCCESS);
  //     } else {
  //       this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
  //     }
  //   });
  // }
  getSubClinicalAndForm() {
    if (
      this.shareService.checkEmpty(this.filterRos) &&
      this.shareService.checkEmpty(this.selectedRowCate) &&
      this.shareService.checkEmpty(this.rosId)
    ) {
      this.subcliorder = [];
      this.listPhieudl = [];
    } else {
      const searchData = new SearchSuborderAndForm();
      searchData.keyword = this.filterRos;
      searchData.rosCategoryId = this.selectedRowCate;
      searchData.type = 0;
      searchData.rosId = this.rosId != '' ? this.rosId.id : '';
      searchData.rosName = this.rosId != '' ? this.rosId.name : '';

      this.subClinicalVisitService.searchSubcilinicalrder(searchData).subscribe((data) => {
        this.subcliorder = data.lstSubClinicalOrder.map((obj) => ({
          ...obj,
          isSubclinical: true,
        }));
        this.listPhieudl = data.lstFormResponse.map((obj) => ({
          ...obj,
          isSubclinical: false,
        }));
      });
    }
    this.isSpinning = false;
  }

  getListRecordVisitAwait(vsId: string) {
    return new Promise((resolve) => {
      this.recordVisitService.getRecordVisit(vsId).subscribe((data) => {
        this.listRecord = data;
        this.totalOfSubclinical += this.listRecord.length;
        if (this.totalOfSubclinical > 0) {
          this.isCollapsed = false;
        }
        resolve(data);
      });
    });
  }

  // deleteSubClinicalVisit(id: string) {
  //   this.modalService.confirm({
  //     nzTitle: 'Confirm',
  //     nzContent: 'Bạn có muốn xóa hay không ?',
  //     nzOkText: 'Đồng ý',
  //     nzCancelText: 'Huỷ',
  //     nzOnOk: () =>
  //       this.subClinicalVisitService.deleteSubClinicalVisit(id).subscribe((data) => {
  //         this.getSubClinicalVisit(this.visitId);
  //       }),
  //   });
  // }
  deleteSubClinicalVisit(data: any) {
    console.log(data);
    let payload = {ids : data.ids};
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Bạn có muốn xóa hay không ?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () => {
        this.subClinicalVisitService.deleteMulti(payload).subscribe(
          (res) => {
            if (!res.isValid) {
              const errorMessage = res.errors[0].errorMessage;
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, errorMessage);
            } else {
              this.getSubClinicalVisit(this.visitId);
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, Constant.MESSAGE_UPDATE_SUCCESS);
            }
          },
          (error) => {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Có lỗi xảy ra khi xóa.');
          }
        );
      },
    });
  }



  apiDeleteMulti(data) {
    let payload = { ids: data.ids };
    this.subClinicalVisitService.deleteMulti(payload).subscribe((res) => {
      if (res.isValid) {
        this.getSubClinicalVisitByRelated();
        this.titleTabChange();

        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, Constant.MESSAGE_UPDATE_SUCCESS);
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }
  // deleteSubClinicalVisit(id: string) {
  //   this.modalService.confirm({
  //     nzTitle: 'Confirm',
  //     nzContent: 'Bạn có muốn xóa hay không ?',
  //     nzOkText: 'Đồng ý',
  //     nzCancelText: 'Huỷ',
  //     nzOnOk: () => {
  //       this.subClinicalVisitService.deleteSubClinicalVisit(id).subscribe(
  //         () => {
  //           this.getSubClinicalVisit(this.visitId);
  //           this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, Constant.MESSAGE_UPDATE_SUCCESS);
  //         },
  //         (error) => {
  //           let errorMessage = 'Có lỗi xảy ra khi xóa.';
  //           if (error && error.error && error.error.message) {
  //             errorMessage = error.error.message;
  //           }
  //           this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, errorMessage);
  //         }
  //       );
  //     }
  //   });
  // }


  chooseRos(idros) {
    if (idros) {
      this.rosService.getRosCategory(idros.id).subscribe((data) => {
        this.rosCategory = data;
        this.rosName = idros.name;
      });
    } else {
      this.selectedRowCate = '';
      this.rosCategory = [];
      this.rosName = 'ROS';
    }
    this.getSubClinicalAndForm();
  }

  chooseRosCategory(idroscate) {
    this.selectedRowCate = idroscate;
    this.getSubClinicalAndForm();
  }

  chooseClinical(idSubOrder) {
    this.selectedRowClinic = idSubOrder;
  }

  chooseSubVisit(idSubVisit) {
    this.selectedRowSubVisit = idSubVisit;
  }

  addSubOrder(idsuborder) {
    this.dataAddSubVisit = {
      visitId: this.visitId,
      subClinicalOrders: [
        {
          subClinicalOrderId: idsuborder,
          amount: 1,
        },
      ],
    };
    this.subClinicalVisitService.addSubClinicalVisit(this.dataAddSubVisit).subscribe(
      (data) => {
        if (data.code == -1) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Không thêm trùng chỉ định !');
          return;
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Thêm thành công');
          this.getSubClinicalVisit(this.visitId);
        }
      },
      (error) => {
        this.notificationService.showNotification('error', 'Có lỗi xảy ra' + error);
      }
    );
  }

  async addSubVisit(phieudlid, phieudlname) {
    const submitdata = {
      visitId: this.visitId,
      dpFormId: phieudlid,
      name: phieudlname,
      source: this.isMobile ? 'mobile-web' : 'web',
    };
    this.recordVisitService.submitRecordVisit(submitdata).subscribe(async (data) => {
      if (data.code == 1) {
        this.recordId = data.message;
        const listRe = <any>await this.getListRecordVisitAwait(this.visitId);

        this.openPhieudl.emit({
          name: phieudlname,
          phieudlid,
          recordId: this.recordId,
          listRecord: listRe,
          // groupId: this.visit.groupId,
        });
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Không thể thêm báo cáo');
      }
    });
  }

  afterSubmitTabPhieudl(phieudlid, phieudlname) {
    this.addSubVisit(phieudlid, phieudlname);
  }

  openTabPhieudl(record) {
    this.openPhieudl.emit({
      name: record.name,
      phieudlid: record.dpFormId,
      recordId: record.id,
    });
  }

  ontyping(event): void {
    this.isLoading = true;
    this.filterRos = event;
    this.isSpinning = true;
    this.callbackFn();
  }

  collapseBox() {
    this.isCollapsed = !this.isCollapsed;
  }

  addRosItem(item) {
    if (item.isSubclinical) {
      this.addSubOrder(item.id);
    } else {
      this.afterSubmitTabPhieudl(item.id, item.name);
    }
  }

  showModalAddSubOrder(title) {
    console.log('showAddSubOrder', this.showAddSubOrder);
    if (title == this.rosConstant.Imaging) {
      this.rosIdSelected = this.rosIdConfig.Imaging;
    } else if (title == this.rosConstant.Laboratory) {
      this.rosIdSelected = this.rosIdConfig.Laboratory;
    }
    this.showAddSubOrder = true;
  }

  async deleteRecordVisit(phieudlid) {
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Bạn có muốn xóa hay không ?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Huỷ',
      nzOnOk: () =>
        this.recordVisitService.deleteRecordVisit(phieudlid).subscribe(async (data) => {
          if (data.code == 1) {
            const listRe = <any>await this.getListRecordVisitAwait(this.visitId);
            this.openPhieudl.emit({
              listRecord: listRe,
            });
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Không xóa được');
          }
        }),
    });
  }

  cancelModalAddSubOrder() {
    this.showAddSubOrder = false;
    this.rosIdSelected = '';
  }

  showModalSubOrderGroup() {
    this.visibleSubOrderGroupAdd = true;
  }

  loadDatas() {
    this.getSubClinicalVisitByRelated();
    // this.getMedicalOrderByVisitId();
  }

  getSubClinicalVisitByRelated() {
    let payload = {
      visitId: this.visit.id,
      refVisitId: this.visit.refVisitId,
    };
    this.subClinicalVisitService.getByRelated(payload).subscribe((res) => {
      // groupBy subClinicalOrderId
      let data = res.jsonData.sort((a, b) => {
        if (a.visitId == b.visitId) {
          if (a.subClinicalOrderId == b.subClinicalOrderId) {
            return a.status.localeCompare(b.status);
          } else return a.subClinicalOrderId.localeCompare(b.subClinicalOrderId);
        } else return a.visitId.localeCompare(b.visitId);
      });
      this.subClinicalVisitSource = JSON.parse(JSON.stringify(data));
      let j = 0;
      new Promise((resolve, reject) => {
        for (let i = 0; i < data.length; i++) {
          data[i].isOtherVisit = false;
          if (data[i].visitId != this.visit.id) data[i].isOtherVisit = true;
          /// nếu ko là người tạo hoặc status = đã hủy
          data[i].canRemove = false;
          data[i].canDiscount = false;
          if (
            data[i].status == Constant.SubclinicalVisitHL7Status.IP ||
            data[i].status == Constant.SubclinicalVisitHL7Status.SC
          ) {
            data[i].canRemove = true;
            data[i].canDiscount = true;
          }

          data[i].canEdit = false;
          if (data[i].visitId == this.visit.id) {
            data[i].canEdit = true;
          }

          data[i].isDisabled = false;
          if (
            data[i].status == Constant.SubclinicalVisitHL7Status.CA ||
            data[i].status == Constant.SubclinicalVisitHL7Status.ER
          ) {
            data[i].isDisabled = true;
          }

          if (data[i].discount == null) data[i].discount = 0;
          if (data[i].amount == 0) data[i].amount = 1;

          data[i].ids = [data[i].id];
          data[i].originalFee = data[i].price * data[i].amount;
          data[i].finalFee = data[i].originalFee - data[i].discount;
          /// gộp 2 subclincalVisit cùng OrderId, cùng status
          if (i > 0) {
            if (
              data[i].subClinicalOrderId == data[j].subClinicalOrderId &&
              data[i].status == data[j].status &&
              data[i].doctorId == data[j].doctorId &&
              data[i].discount == data[j].discount
            ) {
              data[j].amount += data[i].amount;
              data[j].finalFee += data[i].finalFee;
              data[j].discount += data[i].discount;
              data[j].canEdit = data[i].canEdit;
              data[j].ids = [...data[j].ids, data[i].id];
            } else {
              j++;
              data[j] = data[i];
            }
          }
        }
        resolve(data);
      }).then((res) => {
        this.subClinicalVisitCustomSource = JSON.parse(JSON.stringify(data.slice(0, j + 1)));
        this.subClinicalVisit = JSON.parse(JSON.stringify(data.slice(0, j + 1)));
        this.totalOfSubclinical = 0;
        this.subClinicalVisit.forEach(en => {
          this.totalOfSubclinical += en.amount;
        });
        // filter chỉ định Đã hủy hoặc lỗi
        this.subClinicalVisitModal = this.subClinicalVisit.filter((en) => en.isDisabled == false);
        this.outSubClinicalVisit.emit(this.subClinicalVisitModal);
      });
    });
  }


  getSubOrder() {
    this.subclinicalorderService.filterByWeight().subscribe((data) => {
      this.subOrderSource = data;
    });
  }

  titleTabChange() {
    this.titleTabRefresh.emit(Constant.TabTitleInsideVisitDetail.All);
  }


  printOrder() {
    const hienGiaDichVuChiDinh = this.configService.getConfig().hienGiaDichVuChiDinh;

    console.log(this.subClinicalVisit);
    //console.log(this.outSubClinicalVisit, this.subclivisit);
    let printServiceIds = this.subClinicalVisit.filter(en => en.isPrint).map(en => en.subClinicalOrderId);
    //console.log(printServiceIds);
    if (this.subClinicalVisit.length > 0) {
      this.visitService.getPrintTemplate2(this.visitId, this.configGroup.orderPrintType, printServiceIds, hienGiaDichVuChiDinh, []).subscribe((res) => {
        if (res.isValid) {
          this.printContents = res.jsonData;
          this.showOrderPrinterPreview = true;
        } else {
          if (res.errors[0].key == 'EMPTY')
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].errorMessage);
          else this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    } else {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, 'Không có chỉ định để in!');
    }
  }

  closePrintPreview() {
    this.showOrderPrinterPreview = false;
  }

  printNow() {
    this.showOrderPrinterPreview = false;
    // this.printOrder();
    if (this.printContents.length > 0) {
      let content = '';
      this.printContents.forEach((en) => {
        content += '<div style="page-break-after:always">' + en + '</div>';
      });
      const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
          <html>
          <head>
          <style>
              strong{color:#000;}
          </style>
          </head>
          <body onload='window.print();window.close()'>${content}</body>
          </html>`);
      popupWin.document.close();
    }
    /*if (this.configGroup.autoConvert2InProgressVisit && this.visit.status != this.VISIT_STATUS.Finished.value) {
      this.updateStatus(this.VISIT_STATUS.InProgress.value, false);
    }
    if (this.subClinicalSendType == Constant.SubClinicalSendType.ClickPrintOrChangeStatus) {
      this.pushSubClinicalVisit2HIS();
    }*/
  }

  printChildChange($event: boolean) {
    if (!$event) {
      this.printAll = false;
    } else {
      this.printAll = true;
      this.subClinicalVisit.forEach(en => {
        if (!en.isPrint) {
          this.printAll = false;
        }
      });
    }
  }

  switchStatus(data) {
    if (!data.canEdit) {
      data.isEditing = false;
    } else {
      data.isEditing = !data.isEditing;
    }
  }

  updateAmount(elementId, data) {
    let id = data.id;
    //let data = this.subClinicalVisit.find((en) => en.id == id);
    /*if (elementId != 'sub-' + data.id && elementId != 'memu-context') {

    }*/
    data.isEditing = false;
    let oldAmount = this.subClinicalVisitCustomSource.find((en) => en.id == data.id).amount;
    if (data.amount < 0) data.amount = oldAmount;
    else if (data.amount > oldAmount) {
      this.updateSubclinicalVisit(data);
    } else if (data.canRemove && data.canEdit) {
      this.cancelSubclinicalVisit(oldAmount, data);
    } else data.amount = oldAmount;
  }
  updateSubclinicalVisit(data) {
    let subclinicalVisits = this.subClinicalVisitCustomSource.filter((en) => {
      delete en.ids;
      return en.subClinicalOrderId == data.subClinicalOrderId;
    });
    let payload = {
      visitId: this.visit.id,
      subClinicalId: data.subClinicalOrderId,
      status: data.status,
      subclinicalVisits: subclinicalVisits,
      amount: data.amount,
    };
    this.subClinicalVisitService.updateSubclinicalVisit(payload).subscribe((res) => {
      if (res.isValid) {
        this.getSubClinicalVisitByRelated();
        this.titleTabChange();
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật chỉ định thành công!');
      } else if (res.errors && res.errors[0].errorMessage == 'REFRESH') {
        this.showWarningRefresh();
      } else {
        let oldData = this.subClinicalVisitCustomSource.find((en) => en.id == data.id);
        data.amount = oldData.amount;
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }
  cancelSubclinicalVisit(oldAmount, data) {
    let curAmount = data.amount;
    if (curAmount <= 0) {
      this.modalService.warning({
        nzTitle: 'Cảnh báo!',
        nzContent:
          'Số lượng phải lớn hơn 0. Nếu bạn muốn hủy tất cả chỉ định, hãy click chuột phải và chọn "Hủy chỉ định"',
        nzOnOk: () => {
          data.amount = oldAmount;
        },
      });
    }
    /// giảm SL => hủy chỉ định
    else if (curAmount < oldAmount) {
      this.confirmReduceAmount(data);
    }
  }
  showWarningRefresh() {
    this.modalService.warning({
      nzTitle: 'Cảnh báo',
      nzContent: 'Trạng thái chỉ định đã được thay đổi, bạn vui lòng refresh lại danh sách chỉ định',
      nzOkText: 'Refresh',
      nzOnOk: () => {
        this.getSubClinicalVisitByRelated();
      },
    });
  }
  confirmReduceAmount(data) {
    this.modalService.confirm({
      nzTitle: 'Xác nhận',
      nzContent:
        'Bạn có muốn giảm số lượng chỉ định <b>' + data.name + '</b> xuống <b>' + data.amount + '</b> hay không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Đóng',
      nzOnOk: () => {
        this.updateSubclinicalVisit(data);
      },
      nzOnCancel: () => {
        this.subClinicalVisit = JSON.parse(JSON.stringify(this.subClinicalVisitCustomSource));
        this.subClinicalVisitModal = this.subClinicalVisit.filter((en) => en.isDisabled == false);
      },
    });
  }
}
