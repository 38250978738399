import { ShareService } from 'src/app/services/share.service';
import { OpenAddVisit } from 'src/app/components/modal/redux/modal/modal.action';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MedicalAppointmentService } from 'src/app/services/medical-appointment.service';
import { NotificationService } from 'src/app/services/notification.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable, Observer } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { Patient } from 'src/app/models/patient.class';
import { SubclinicalorderService } from 'src/app/services/subclinicalorder.service';

@Component({
  selector: 'app-appointment-edit',
  templateUrl: './appointment-edit.component.html',
  styleUrls: ['./appointment-edit.component.scss'],
})
export class AppointmentEditComponent implements OnInit, OnChanges {
  @Input() visible: boolean;
  @Input() Id;

  @Output() closeModal = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();

  isCaretUp = false;
  info: FormGroup;
  disabledDate: any;
  submitted = false;
  disabled = true;
  patient: any;
  clsSelected: any;
  listClsDisplay: any[] = [];
  clsDataSource: any;
  keyword = '';
  initialDataSourceCls: any;

  constructor(
    private medicalService: MedicalAppointmentService,
    private notificationService: NotificationService,
    private modalService: NzModalService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private store: Store<AppState>,
    private shareService: ShareService,
    private subclinicalorderService: SubclinicalorderService
  ) {
    this.patient = {
      fullName: '',
      gender: 0,
      address: '',
      phoneNo: '',
      soBHYT: '',
      patientCode: '',
    };
    this.info = this.fb.group({
      id: [null],
      patientCode: [null],
      dateAppointment: [0, [Validators.required]],
      // timeAppointment: [0, [Validators.required], [this.validatorsTime]],
      timeAppointment: [0, [Validators.required]],
      request: [null],
      note: [null],
      status: 1,
      subStatus: 0,
      priority: 3,
      logs: [null],
      subClinicalOrderIds: [null],
    });
    this.disabledDate = this.medicalService.disabledDate;
  }
  callBackFn = this.shareService.throttle(this.searchCLS, 500);

  ngOnInit(): void {
    this.getClsDataSource();
  }

  getClsDataSource() {
    this.subclinicalorderService.getSubclinicalOrderAll().subscribe((res) => {
      this.clsDataSource = res;
      this.initialDataSourceCls = res;
    });
  }

  onTyping(event) {
    if (event.trim() != '') {
      this.keyword = event.trim();
      this.callBackFn();
    } else {
      this.clsDataSource = this.initialDataSourceCls;
    }
  }

  searchCLS() {
    this.subclinicalorderService
      .searchByNameAndCode(this.keyword)
      .subscribe((res) => {
        if (res.isValid) {
          this.clsDataSource = res.jsonData;
        }
      });
  }

  removeItemCls(id: string) {
    this.listClsDisplay = this.listClsDisplay.filter((en) => en.id != id);
  }

  addClsSelected(cls) {
    if (this.listClsDisplay == undefined) {
      this.listClsDisplay = [];
    }
    const exist = this.listClsDisplay.some((en) => en.id === cls.id);
    if (exist) {
      this.notificationService.showNotification(
        'warning',
        'Đã tồn tại chỉ định trong danh sách'
      );
    } else {
      this.listClsDisplay.push(cls);
      this.scrollToBottom();
      this.clsSelected = {};
    }
  }

  scrollToBottom() {
    $('.list-cls').animate({ scrollTop: '100' });
  }

  validatorsTime = (control: FormControl) =>
    new Observable((observer: Observer<any>) => {
      if (control.value) {
        const hour = control.value.getHours();
        const date = new Date(this.info.value.dateAppointment);
        if (
          date != undefined &&
          date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
        ) {
          if (hour != null && hour < new Date().getHours()) {
            observer.next({ unableHour: true, error: true });
          }
        }
        const min = control.value == null ? 0 : control.value.getMinutes();
        if (min != 30 && min != 0) {
          control.setValue(null);
        }
        observer.complete();
      }
    });

  get f() {
    return this.info.controls;
  }

  async ngOnChanges(change: SimpleChanges) {
    const id = this.Id;
    if (id) {
      await this.getAppointmentById(id);
    }
  }

  getAppointmentById(id) {
    this.listClsDisplay = [];
    this.medicalService.getMedicalAppointmentById(id).subscribe(
      (res) => {
        if (res) {
          const d = res;
          this.patient = {
            fullName: d.fullName,
            gender: d.gender,
            address: d.address,
            phoneNo: d.phoneNo,
            soBHYT: d.soBHYT,
            patientCode: d.patientCode,
            patientId: d.patientId,
            dob: d.dob,
            dobString: d.dobString,
          };
          this.info.patchValue({
            id: d.id,
            timeAppointment: new Date(
              null,
              null,
              null,
              d.hourAppointment,
              d.minuteAppointment
            ),
            dateAppointment: d.dateAppointment,
            request: d.request,
            note: d.note,
            status: d.status,
            subStatus: d.subStatus,
            priority: d.priority,
            logs: d.logs,
          });
          if (d.subClinicalOrderIds != null) {
            for (const item of this.initialDataSourceCls) {
              for (const id of d.subClinicalOrderIds) {
                if (item.id == id) {
                  this.listClsDisplay.push(item);
                }
              }
            }
          }
        }
      },
      (err) => {
        this.notificationService.showNotification('error', err);
      }
    );
  }

  cancelEdit() {
    this.visible = false;
    this.closeModal.emit(false);
    this.listClsDisplay = [];
  }

  createNewVisit() {
    const patient: Patient = {
      fullName: this.patient.fullName,
      sex: this.patient.gender,
      address: this.patient.address,
      phoneNo: this.patient.phoneNo,
      code: this.patient.patientCode,
      id: this.patient.patientId,
      BHYTCode: this.patient.soBHYT,
      dob: this.patient.dob,
      dobString: this.patient.dobString,
    };
    this.store.dispatch(
      new OpenAddVisit('AppointmentEdit', patient, this.info.value.id)
    );
  }

  updateInfo() {
    this.submitted = true;
    if (this.info.valid) {
      const subClinicalOrderIds = Array.from(
        this.listClsDisplay,
        (en) => en.id
      );
      const form = this.info.value;
      const p = this.patient;
      const payload = {
        id: form.id,
        fullName: p.fullName,
        gender: p.gender,
        address: p.address,
        phoneNo: p.phoneNo,
        soBHYT: p.soBHYT,
        patientCode: p.patientCode,
        patientId: p.patientId,
        dob: p.dob,
        dobString: p.dobString,
        dateAppointment: form.dateAppointment,
        hourAppointment: Number(
          this.datePipe.transform(new Date(form.timeAppointment), 'HH')
        ),
        minuteAppointment: Number(
          this.datePipe.transform(new Date(form.timeAppointment), 'mm')
        ),
        request: form.request,
        note: form.note,
        status: form.status,
        subStatus: form.subStatus,
        priority: form.priority,
        subClinicalOrderIds,
      };
      this.medicalService.updateAppointment(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(
            'success',
            'Cập nhật hẹn khám thành công'
          );
          this.visible = false;
          if (res.jsonData == '201') {
            this.showConfirm();
          }
          this.refresh.emit();
          this.closeModal.emit(false);
          this.listClsDisplay = [];
        } else {
          this.notificationService.showNotification(
            'error',
            res.errors[0].messageError
          );
        }
      });
    } else {
      console.log(this.info.valid);
    }
  }

  toStatusText(status) {
    return this.medicalService.toStatusText(status);
  }

  toPriorityText(priority) {
    return this.medicalService.toPriorityText(priority);
  }

  toGenderText(gender) {
    return this.medicalService.toGenderText(gender);
  }

  scrollDow() {
    this.isCaretUp = !this.isCaretUp;
    $('.potal').animate({ scrollTop: '1000' });
  }

  showConfirm(): void {
    this.modalService.confirm({
      nzTitle: 'Xác nhận',
      nzContent: 'Bạn có muốn tạo ca khám cho với ca hẹn khám này?',
      nzOkText: 'Có',
      nzCancelText: 'Bỏ qua',
      nzOnOk: () => this.createNewVisit(),
    });
  }
}
