<div class="title-wrapper">
  <div style="padding-top: 30px"></div>
  <div class="head-obsertype d-flex justify-content-between">
    <div class="box-searchObser">
      <input type="text" [(ngModel)]="textSearch" placeholder="Tìm kiếm..." (keydown.enter)="doSearch()" />
      <button class="" nz-button nzType="primary" style="border-radius: 0px 5px 5px 0px" (click)="doSearch()">
        <i nz-icon nzType="search" nzTheme="outline"></i>
      </button>
    </div>
    <div class="table-operations">
      <button class="mg-r-10 btn-green" nz-button (click)="showModalForm('add', itemEdit)">
        <i nz-icon nzType="plus"></i>Thêm mới
      </button>
    </div>
  </div>
  <div class="import-file">
    <nz-table #fixedTable class="main-table-grid" nzSize="small" [(nzData)]="filterData" [nzLoading]="isLoading"
      [nzFrontPagination]="true" [nzTotal]="total" (nzQueryParams)="onQueryParamsChange($event)"
      [nzShowTotal]="rangeTemplate" nzShowSizeChanger [nzShowPagination]="true" [nzPageSize]="pageSize"
      [(nzPageIndex)]="pageIndex" [nzPageSizeOptions]="[10, 20, 50, 100]"
      [nzScroll]="{ x: '1000px', y: 'calc(100vh - 260px)' }">
      <thead>
        <tr>
          <th nzWidth="150px">STT</th>
          <th>Name</th>
          <th>Field</th>
          <th>Title</th>
          <th>Description</th>
          <th>Source</th>
          <th>DataType</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fixedTable.data; let i = index"
          [ngClass]="{ 'highlight': selectedRowIndex == data.id }">
          <td>{{ pageSize * (pageIndex - 1) + i + 1 }}</td>
          <td>{{ data.name }}</td>
          <td>{{ data.defaultField }}</td>
          <td>{{ data.title }}</td>
          <td>{{ data.description }}</td>
          <td>{{ data.source }}</td>
          <td>{{ toNum(data.dataType) }}</td>
          <!-- <td>
            <div *ngFor="let en of data.listCodeMapSystem">
              {{ en.text }}: {{ en.valueNumber }}
            </div>
          </td> -->

          <td>
            <i nz-icon nzType="edit" class="mg-r-10" title="Chỉnh sửa" nzTheme="outline"
              (click)="showModalForm('update', data)"></i>
            <i nz-icon nzType="delete" class="mg-r-10" title="Xóa" nzTheme="outline" (click)="remove(data.id)"></i>
          </td>
        </tr>
      </tbody>
      <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }}
      </ng-template>
    </nz-table>
  </div>
</div>
<!-- Thêm mới,edit -->
<nz-modal [(nzVisible)]="isVisibleAdd" [(nzTitle)]="isTitle" nzWidth="700px" nzMaskClosable="false"
  (nzOnCancel)="handleCancel()">
  <div>
    <div class="row mg-bt-10">
      <div class="col-3">Tên Common-Info <span class="red-dot">*</span></div>
      <div class="col-9">
        <input nz-input type="text" [(ngModel)]="itemEdit.name" />
      </div>
    </div>
    <!-- <div class="row mg-bt-10">
      <div class="col-3">Field <span class="red-dot">*</span></div>
      <div class="col-9">
        <input nz-input type="text" [(ngModel)]="itemEdit.defaultField" />
      </div>
    </div> -->
    <div class="row mg-bt-10">
      <div class="col-3">Source</div>
      <div class="col-9">
        <nz-select class="w-100" [(ngModel)]="itemEdit.source" (ngModelChange)="onSearchParameter($event)">
          <nz-option nzValue="Patient" nzLabel="Patient">
          </nz-option>
          <nz-option nzValue="Visit" nzLabel="Visit">
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Field <span class="red-dot">*</span></div>
      <div class="col-9">
        <nz-select class="w-100" [(ngModel)]="itemEdit.defaultField" nzShowSearch [nzServerSearch]="true" nzAllowCleart>
          <nz-option *ngFor="let form of  searchParameter" [nzValue]="form" [nzLabel]="form">
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Mô tả</div>
      <div class="col-9">
        <input nz-input type="text" [(ngModel)]="itemEdit.description" />
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Title <span class="red-dot">*</span></div>
      <div class="col-9">
        <input nz-input type="text" [(ngModel)]="itemEdit.title" />
      </div>
    </div>

    <div class="row mg-bt-10">
      <div class="col-3">DataType <span class="red-dot">*</span></div>
      <div class="col-9">
        <nz-select style="width: 100%" [(ngModel)]="itemEdit.dataType">
          <nz-option [nzValue]="1" nzLabel="Number">Number</nz-option>
          <nz-option [nzValue]="5" nzLabel="String">String</nz-option>
          <nz-option [nzValue]="7" nzLabel="Date">Date</nz-option>
          <nz-option [nzValue]="4" nzLabel="Boolean">Boolean</nz-option>
          <nz-option [nzValue]="9" nzLabel="Single choice">Single choice</nz-option>
          <nz-option [nzValue]="99" nzLabel="Tuổi BN">Tuổi BN</nz-option>
        </nz-select>
      </div>
    </div>

    <div class="row mg-bt-10" *ngIf="itemEdit.dataType == 9">
      <div class="col-3"></div>
      <div class="other-system">
        <div class="item-system" *ngFor="let en of lstDataTypeBoolean; index as i">
          <div class="width-divided">
            <input [(ngModel)]="en.text" nz-input placeholder="Nhập text" />
          </div>
          <div class="width-divided btn-sub">
            <input [(ngModel)]="en.valueNumber" nz-input placeholder="Nhập giá trị" />
            <!-- <i nz-icon nzType="minus-circle" nzTheme="outline"></i> -->
            <i *ngIf="i != 0" nz-icon nzType="minus-circle" nzTheme="outline" (click)="removeDataTypeBoolean(i)"></i>
            <i *ngIf="i == 0" nz-icon nzType="plus-circle" nzTheme="outline" (click)="addDataTypeBoolean()"></i>

          </div>
        </div>
      </div>
    </div>

  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-small" (click)="handleCancel()">Đóng</button>
    <button nz-button class="btn-primary-small" (click)="submit()">Lưu</button>
  </div>
</nz-modal>