import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { GroupMemberService } from '../../../services/group-member.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { UserReturn } from '../../../models/userInvite.model';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AppConfigService } from '../../../../app-config.service';
import { Router } from '@angular/router';
import { Observable, Observer, Subscription } from 'rxjs';
import { Join } from '../../auth/redux/group-auth.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { GroupTreeService } from 'src/app/services/groupTree.service';
import { ShareService } from 'src/app/services/share.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-invite-member-group',
  templateUrl: './invite-member-group.component.html',
  styleUrls: ['./invite-member-group.component.scss'],
  providers: [GroupMemberService],
})
export class InviteMemberGroupComponent implements OnInit {
  @Input() isVisible: any;
  @Input() newGroupId: any;
  @Input() isCovidGroup: boolean;

  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  listMember: Array<UserReturn> = [];
  listUser: Array<UserReturn> = [];
  listInvited: Array<UserReturn> = [];
  nonWhitespaceRegex = /^$|\s+/;

  PathAvatar: string = this.configService.getConfig().media.baseUrl + '/Avatar/';
  cursltValue = 1;
  curKeyword = '';
  searchEmailOrUsername = '';
  message: string;
  pipeCountArr = false;
  myIsOwnerGroup = false;
  myIsAdminGroup = false;
  lstPermission: number[] = [];
  groupPermissions: any[] = [];
  doctorRanks: any[] = [];
  lstPermissionUserOnl: number[] = [];
  lstPermissionDefault = [
    {
      name: 'Điều phối viên',
      type: 1,
      checked: false,
    },
    {
      name: 'Bác sĩ',
      type: 2,
      checked: false,
    },
    {
      name: 'Dược sĩ lâm sàng',
      type: 3,
      checked: false,
    },
    {
      name: 'Chuyên viên tư vấn',
      type: 4,
      checked: false,
    },
    {
      name: 'Điều dưỡng phụ trách',
      type: 5,
      checked: false,
    },
    {
      name: 'Quản trị room BN',
      type: 9,
      checked: false,
    },
    {
      name: 'Tình nguyện viên',
      type: 10,
      checked: false,
    },
    {
      name: 'Thống kê báo cáo',
      type: 11,
      checked: false,
    },
  ];
  quantriroom = {
    name: 'Quản trị room BN',
    type: 9,
    checked: false,
  };
  kedon = {
    name: 'Kê đơn thuốc',
    type: 6,
    checked: false,
  };
  duyet = [
    {
      name: 'Duyệt đơn thuốc',
      type: 7,
      checked: false,
    },
    {
      name: 'Bỏ duyệt đơn thuốc của người khác',
      type: 8,
      checked: false,
    },
  ];

  currMemberId = '';
  curUserId = '';

  totalMemberCount = 0;
  totalInvitedCount = 0;

  timer: any;

  isVisibleAddUser = false;
  formData: FormGroup;
  isAddUser = false;
  baseUrl = this.configService.getConfig().api.baseUrl;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private configService: AppConfigService,
    private doctorService: DoctorService,
    private groupTreeService: GroupTreeService,
    private shareService: ShareService,
    private notificationService: NotificationService,
    private groupMemberService: GroupMemberService,
    private store: Store<AppState>,
    private nzMessageService: NzMessageService
  ) {
    this.store
      .select((store) => store.auth.userId)
      .subscribe((res) => {
        this.curUserId = res;
      });
    this.formData = this.fb.group({
      username: ['', [Validators.required], [this.emptyAsyncValidator], [this.nonUnicodeAsyncValidator]],
      password: ['', [Validators.required], [this.nonUnicodeAsyncValidator]],
      confirmPassword: ['', [Validators.required, this.confirmationValidator]],
      fullName: ['', [Validators.required]],
      //doctorRankId: [''],
      doctorRank: [''],
      email: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    // if(this.newGroupId==='000000000000000000000000')
    //   this.newGroupId = localStorage.getItem(Constant.CURRENT_GROUPID);
    // let curUser = JSON.parse(localStorage.getItem(Constant.USER_INFO));
    // this.curUserId = curUser.userId;
    this.getGroupPermissions();
  }
  getGroupPermissions(): void {
    if (!this.shareService.checkEmpty(this.newGroupId)) {
      this.groupTreeService.getGroupInfo(this.newGroupId).subscribe(
        (response) => {
          if (response !== null) {
            if (response.doctorPermissions !== null) {
              this.groupPermissions = this.lstPermissionDefault.filter((e) =>
                response.doctorPermissions.includes(e.type)
              );
            }
            if (response.isUseApprovePrescription) {
              this.groupPermissions = [...this.groupPermissions, ...this.duyet];
            }
            if (response.isUseDividePatientRoom) {
              this.groupPermissions.push(this.quantriroom);
            }
            if (response.isUsePharmacist) {
              this.groupPermissions.push(this.kedon);
            }
          }
        },
        (error) => {
          console.log(error);
          this.notificationService.showNotification('', 'Có lỗi xảy ra');
        }
      );
    }
  }
  //le.huathi - add this function to call in group dashboard
  onShow(): void {
    this.getMyGroupMemberInfo(this.newGroupId);
    this.callService(this.cursltValue, '', this.newGroupId);

    this.groupMemberService.getGroupMemberCount(this.newGroupId).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.totalMemberCount = res.jsonData.totalMember;
            this.totalInvitedCount = res.jsonData.totalInvitation;
          }
        }
      },
      (error) => {
        console.log('getGroupMemberCount exception!');
      }
    );
  }

  getMyGroupMemberInfo(groupId) {
    this.groupMemberService.getMyGroupMember(groupId).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.myIsAdminGroup = res.jsonData.isAdmin;
            this.myIsOwnerGroup = res.jsonData.isOwner;
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  loadMember(sltValue, keyword) {
    this.callService(parseInt(sltValue), keyword, this.newGroupId);
  }

  callService(sltValue, keyword, groupId) {
    this.curKeyword = keyword;
    this.cursltValue = sltValue;
    const payload = {
      groupId, //this.newGroupId,
      keyword,
      status: sltValue,
    };
    // this.PathAvatar =
    //   + this.PathAvatar;
    this.groupMemberService.getListMember(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.listMember = res.jsonData;
            for (let i = 0; i < this.listMember.length; i++) {
              this.listMember[i].fullName = this.listMember[i].fullName.trim();
              // if (this.listMember[i].doctorPermissions != null) {
              //   this.listMember[i].permission = this.listMember[i].doctorPermissions[0];
              // }
              if (this.listMember[i].imageUrl == null || this.listMember[i].imageUrl == '') {
                this.listMember[i].imageUrl = 'assets/image/group.svg';
              } else {
                this.listMember[i].imageUrl = this.PathAvatar + this.listMember[i].imageUrl;
              }
            }
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].message);
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }

  onKeyPress(x) {
    // appending the updated value to the variable
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const keyword = x.target.value;
      const payload = {
        groupId: this.newGroupId,
        keyword,
        status: 1000,
      };
      this.groupMemberService.getListMember(payload).subscribe(
        (res) => {
          if (res !== null) {
            if (res.isValid) {
              this.listUser = res.jsonData.splice(0, 20);
              if (this.listUser == null || this.listUser.length == 0) {
                const validEmail = this.isEmail(keyword);
                if (validEmail) {
                  const u = new UserReturn();
                  u.userId = '';
                  u.userName = '';
                  u.email = keyword;
                  u.isAdmin = false;
                  u.isInvited = false;
                  u.isMember = false;
                  u.isOwner = false;
                  u.isAddUser = true;

                  this.listUser = [u];
                }
              }
              console.log(this.listUser);
            } else {
              if (res.errors && res.errors.length > 0) {
                this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].message);
              }
            }
          }
        },
        (error) => {
          console.log('lept lỗi');
        }
      );
    }, 500);
  }

  handleOk(): void {
    if (this.listInvited.length == 0) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, 'Bạn chưa chọn bác sỹ để mời');
      return;
    }

    const payload = {
      Users: this.listInvited,
      GroupId: this.newGroupId,
      //Email: (this.fileName==undefined?'':this.fileName)
      Message: this.message,
      InviteLink: window.location.origin + '/confirm-invite?Code={0}&Type={1}&NotiId={2}',
    };

    this.groupMemberService.inviteMultiUser(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.listInvited = [];
            this.listUser = [];
            this.message = '';
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Mời thành viên thành công');
            if (this.cursltValue === 0 || this.cursltValue === -1) {
              this.callService(this.cursltValue, this.curKeyword, this.newGroupId);
            }
            //this.addGroup.emit('test'+res.jsonData);
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].message);
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }

  reSendInivitaion(id, email) {
    let listResendUser = [];
    const u = new UserReturn();
    u.userId = id;
    u.userName = '';
    u.email = email;
    u.isAdmin = false;
    u.isInvited = false;
    u.isMember = false;
    u.isOwner = false;

    listResendUser = [u];
    const payload = {
      Users: listResendUser,
      GroupId: this.newGroupId,
      //Email: (this.fileName==undefined?'':this.fileName)
      Message: '',
      InviteLink: window.location.origin + '/confirm-invite?Code={0}&Type={1}&NotiId={2}',
    };
    this.groupMemberService.inviteMultiUser(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.listInvited = [];
            if (this.cursltValue === 0 || this.cursltValue === -1) {
              this.callService(this.cursltValue, this.curKeyword, this.newGroupId);
            }
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Mời thành viên thành công');
            //this.addGroup.emit('test'+res.jsonData);
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].message);
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  handleCancel(): void {
    this.isVisible = false;
    this.closeModal.emit(false);
    // cmt bug reload popup
    // if (JSON.stringify(this.lstPermissionUserOnl) != JSON.stringify(this.lstPermission)) {
    //   this.store.dispatch(new Join({
    //     doctorPermissions: this.lstPermissionUserOnl,
    //   }));
    // }
  }

  selectToInvite(userId, userName, email) {
    if (
      this.listInvited.filter(
        (en) => (en.email === email && email.length > 0) || (en.userId === userId && userId.length > 0)
      ).length === 0
    ) {
      const u = new UserReturn();
      u.userId = userId;
      u.userName = userName;
      u.email = email;
      u.isAdmin = false;
      u.isInvited = false;
      u.isMember = false;
      u.isOwner = false;
      this.listInvited.push(u);
    } else {
      alert('Đã chọn');
    }
  }
  unSelectToInvite(userId, userName, email) {
    this.listInvited = this.listInvited.filter((en) => en.email !== email && en.userId !== userId);
  }
  removeMember(userId) {
    const payload = {
      UserId: userId,
      GroupId: this.newGroupId,
    };
    this.groupMemberService.RemoveMember(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.listMember = this.listMember.filter((en) => en.userId !== userId);
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Remove member group success');
            //this.addGroup.emit('test'+res.jsonData);
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].message);
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  changeOwnerGroup(userId) {
    const oldOwner = this.listMember.filter((en) => en.isOwner === true)[0];
    const payload = {
      UserId: oldOwner.userId,
      GroupId: this.newGroupId, //localStorage.getItem(Constant.CURRENT_GROUPID),
      OrtherId: userId,
    };

    this.groupMemberService.ChangeOwnerGroup(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.listMember.filter((en) => en.userId === oldOwner.userId)[0].isMember = true;
            this.listMember.filter((en) => en.userId === oldOwner.userId)[0].isOwner = false;
            this.listMember.filter((en) => en.userId === userId)[0].isOwner = true;
            this.listMember.filter((en) => en.userId === userId)[0].isMember = false;
            this.listMember.filter((en) => en.userId === userId)[0].isAdmin = false;
            this.myIsAdminGroup = false;
            this.myIsOwnerGroup = false;
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Change owner group success');
            //this.addGroup.emit('test'+res.jsonData);
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].message);
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  removeGroupAdmin(userId) {
    const payload = {
      UserId: userId,
      GroupId: this.newGroupId, //localStorage.getItem(Constant.CURRENT_GROUPID),
    };

    this.groupMemberService.RemoveGroupAdmin(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.listMember.filter((en) => en.userId === userId)[0].isAdmin = false;
            this.listMember.filter((en) => en.userId === userId)[0].isMember = true;
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Remove Admin Group Success');
            //this.addGroup.emit('test'+res.jsonData);
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].message);
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }

  setGroupAdmin(userId) {
    const payload = {
      UserId: userId,
      GroupId: this.newGroupId, //localStorage.getItem(Constant.CURRENT_GROUPID),
    };
    this.groupMemberService.SetGroupAdmin(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.listMember.filter((en) => en.userId === userId)[0].isAdmin = true;
            this.listMember.filter((en) => en.userId === userId)[0].isMember = false;
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Set Admin Group Success');
            //this.addGroup.emit('test'+res.jsonData);
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].message);
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }

  removeUser(userId) {
    const payload = {
      UserId: userId,
      GroupId: this.newGroupId, //localStorage.getItem(Constant.CURRENT_GROUPID),
    };

    this.groupMemberService.RemoveMember(payload).subscribe(
      (res) => {
        if (res !== null) {
          if (res.isValid) {
            this.listMember = this.listMember.filter((en) => en.userId !== userId);
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xóa thành viên thành công');
            //this.addGroup.emit('test'+res.jsonData);
          } else {
            if (res.errors && res.errors.length > 0) {
              this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, res.errors[0].message);
            }
          }
        }
      },
      (error) => {
        console.log('lept lỗi');
      }
    );
  }
  // changeOwner() {}

  isEmail(search: string): boolean {
    let serchfind: boolean;

    const regexp = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    serchfind = regexp.test(search);
    return serchfind;
  }

  setUserPermission(userId: string) {
    const payload = {
      groupId: this.newGroupId,
      userId,
      doctorPermissions: this.lstPermission,
    };
    this.groupMemberService.setDoctorPermission(payload).subscribe((res) => {
      if (res.isValid) {
        if (this.curUserId == userId) {
          this.lstPermissionUserOnl = this.lstPermission;
        }
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Phân quyền thành công');
        this.listMember = this.listMember.map((e) =>
          e.userId == userId ? { ...e, doctorPermissions: this.lstPermission } : e
        );
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  isVisibleChange(visible, doctorPermissions, userId) {
    this.groupPermissions = this.groupPermissions.map((e) => ({
      ...e,
      checked: false,
    }));
    if (visible) {
      this.currMemberId = userId;
      const permis = JSON.parse(JSON.stringify(doctorPermissions));
      this.lstPermission = permis != null ? permis : [];

      for (const item of this.lstPermission) {
        this.groupPermissions = this.groupPermissions.map((e) => (e.type == item ? { ...e, checked: true } : e));
      }
    }
    // else {
    //   this.groupPermissions = this.groupPermissions.map(e => ({ ...e, checked: false }));
    // }
  }

  addUserPermission(type: number, checked: boolean) {
    this.groupPermissions = this.groupPermissions.map((e) => (e.type == type ? { ...e, checked } : e));
    if (this.lstPermission.filter((e) => e == type).length == 0) {
      this.lstPermission.push(type);
    } else {
      this.lstPermission = this.lstPermission.filter((e) => e != type);
    }
  }

  openAddUser() {
    this.formData.patchValue({
      email: this.searchEmailOrUsername,
    });
    this.isVisibleAddUser = true;
    this.getDoctorRank();
  }

  handleCancelAddUser() {
    this.isVisibleAddUser = false;
    this.formData.reset();
    this.listUser = [];
    this.searchEmailOrUsername = '';
  }

  getDoctorRank() {
    this.doctorService.getListDoctorRank().subscribe((res) => {
      this.doctorRanks = res;
    });
  }

  saveAddUser() {
    if (this.formData.valid) {
      const payload = {
        groupId: this.newGroupId,
        keyword: '',
        status: 1000,
      };

      const addMemberData = {
        ...this.formData.value,
        ...{ groupId: this.newGroupId },
      };
      this.groupMemberService.addUser(addMemberData).subscribe(
        (res) => {
          if (res.isValid) {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Thêm user thành công');
            //Đóng popup
            this.handleCancelAddUser();
            this.onShow();
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
          }
        },
        (error) => {
          this.notificationService.showNotification('error', 'Không thể thêm user');
        }
      );
    } else {
      this.validateForm();
    }
  }

  emptyAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.shareService.checkEmpty(control.value)) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, emptyName: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  nonUnicodeAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.nonWhitespaceRegex.test(control.value)) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, nonUnicodeWhitespace: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.formData.controls.confirmPassword.updateValueAndValidity());
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.formData.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  validateForm() {
    for (const i in this.formData.controls) {
      this.formData.controls[i].markAsDirty();
      this.formData.controls[i].updateValueAndValidity();
    }
  }
  copytoClipBoard(data) {
    if (!navigator.clipboard) {
      this.nzMessageService.error('Vui lòng cho phép sử dụng clipboard!');
      return;
    }
    const text = window.location.origin + '/confirm-invite?Code=' + data.invitationId + '&Type=1';
    navigator.clipboard.writeText(text).then(
      () => {
        this.nzMessageService.success('Đã copy clipboard!');
      },
      () => {
        this.nzMessageService.error('Có lỗi xảy ra');
      }
    );
  }
}
