<div style="padding: 20px">
  <div class="d-flex justify-content-between mg-bt-15">
    <div class="d-flex">
      <div style="width: 200px" class="mg-r-10">
        <input nz-input [(ngModel)]="search.name" type="text" placeholder="Tìm kiếm"
          (keydown.enter)="getRulesManagerment()" />
      </div>
      <div style="width: 150px" class="mg-r-10">
        <nz-select class="w-100" [(ngModel)]="search.status" (ngModelChange)="getRulesManagerment()"
          [nzServerSearch]="true" nzAllowClear nzPlaceHolder="Trạng thái">
          <nz-option [nzValue]="-1" nzLabel="Trạng thái"></nz-option>
          <nz-option [nzValue]="1" nzLabel="Active"></nz-option>
          <nz-option [nzValue]="0" nzLabel="Inactive"></nz-option>
        </nz-select>
      </div>
      <button nz-button (click)="getRulesManagerment()">
        <i nz-icon nzType="search" nzTheme="outline"></i>
      </button>
    </div>
    <div>
      <button nz-button class="btn-primary-small" nzType="success" (click)="showModalForm('add', '')">
        <i nz-icon nzType="plus" nzTheme="outline"></i>
        Thêm mới
      </button>
    </div>
  </div>
  <nz-table #rules class="main-table-grid" [nzData]="lstRulesManagerment" [nzPageIndex]="pageIndex"
    [nzPageSize]="pageSize" [nzTotal]="total" nzShowSizeChanger [nzFrontPagination]="false"
    [nzPageSizeOptions]="[10, 20, 50, 100]" [nzShowTotal]="rangeTemplate" [nzLoading]="loading"
    (nzQueryParams)="onQueryParamsChange($event)">
    <thead>
      <tr>
        <th nzWidth="60px">STT</th>
        <th nzWidth="150px">Tên CDS Rule</th>
        <th>Phạm vi sử dụng</th>
        <th>Mô tả</th>
        <th nzWidth="350px">Criterias</th>
        <th nzWidth="350px">Actions</th>
        <th>Người tạo</th>
        <!-- <th nzWidth="150px">Phạm vi sử dụng</th> -->
        <th>Trạng thái</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of rules.data; index as i">
        <td>
          {{ rules.nzPageSize * (rules.nzPageIndex - 1) + i + 1 }}
        </td>
        <td>{{ data.name }}</td>
        <td>{{ toPrivacyText(data.privacyUse) }}</td>
        <td>{{ data.description }}</td>
        <td>{{ data.criteriaTexts }}</td>
        <td>{{ data.actionTexts }}</td>
        <td>{{ data.creator }}</td>
        <td>
          <span *ngIf="data.status">Active</span>
          <span *ngIf="!data.status" class="red-dot">Inactive</span>
        </td>
        <td>
          <i nz-icon nzType="edit" class="mg-r-10" title="Chỉnh sửa" (click)="showModalForm('update', data)"
            nzTheme="outline"></i>
          <i nz-icon nzType="delete" class="mg-r-10" title="Xóa" (click)="removeRulesManagerment(data.id)"
            nzTheme="outline"></i>
          <i nz-icon nzType="stop" title="Inactive/Active" (click)="updateStatus(data.id, data.status, i)"
            nzTheme="outline"></i>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <ng-template #rangeTemplate let-range="range" let-total>
    {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }} quy trình
  </ng-template>
</div>

<nz-modal [(nzVisible)]="isVisibleForm" nzTitle="Nhập danh sách CDS Rule" nzWidth="1000px" nzMaskClosable="false"
  (nzOnCancel)="handleCancel()">
  <div class="">
    <form [formGroup]="processForm">
      <!-- <div class="row mg-bt-10">
        <div class="col-3">Tên Rule <span class="red-dot">*</span></div>
        <nz-form-control class="col-9" nzErrorTip="Tên Rule không được để trống">
          <input nz-input formControlName="name" id="name" />
        </nz-form-control>
      </div>
      
      <div class="row mg-bt-10">
        <div class="col-3">Mô tả <span class="red-dot">*</span></div>
        <nz-form-control class="col-9" nzErrorTip="Mô tả không được để trống">
          <input nz-input formControlName="description" id="description" />
        </nz-form-control>
      </div> -->

      <div class="row mg-bt-10">
        <div class="col-3">Tên Rule <span class="red-dot">*</span></div>
        <div class="col-9">
          <div *ngIf="isOnlyViewData">{{ processForm.value.name }}</div>
          <div *ngIf="!isOnlyViewData">
            <input nz-input formControlName="name" type="text" placeholder="Tên Rule..." />
            <div class="message-required" *ngIf="
                submitted &&
                (processFormData.name.errors ||
                  processFormData.name.hasError('whitespace'))
              ">
              <span class="fs-12">*Tên Rule không được để trống</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mg-bt-10">
        <div class="col-3">Mô tả <span class="red-dot">*</span></div>
        <div class="col-9">

          <div *ngIf="isOnlyViewData">{{ processForm.value.description }}</div>
          <div *ngIf="!isOnlyViewData">
            <input nz-input formControlName="description" type="text" placeholder="Mô tả..." />
            <div class="message-required" *ngIf="
                submitted &&
                (processFormData.description.errors ||
                  processFormData.description.hasError('whitespace'))
              ">
              <span class="fs-12">*Mô tả không được để trống</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mg-bt-10">
        <div class="col-3">Trạng thái <span class="red-dot">*</span></div>
        <nz-switch class="col-9" formControlName="status"></nz-switch>
      </div>
      <div class="row mg-bt-10">
        <div class="col-3">Phạm vi sử dụng</div>
        <div class="col-9">
          <div *ngIf="isOnlyViewData">
            {{ toPrivacyText(processForm.value.privacyUse) }}
          </div>
          <div *ngIf="!isOnlyViewData">
            <nz-radio-group formControlName="privacyUse">
              <label nz-radio [nzDisabled]="!isRulesManagerment" [nzValue]="1">Toàn hệ thống</label>
              <label nz-radio [nzDisabled]="!fromAdminPage && !isAdminGroup" [nzValue]="2">Trong nhóm BS</label>
              <label nz-radio [nzDisabled]="fromAdminPage" [nzValue]="3">Chỉ mình tôi</label>
            </nz-radio-group>
          </div>
        </div>
      </div>
      <div *ngIf="processForm.value.privacyUse == privacyUse.Group">
        <div *ngIf="!isOnlyViewData" class="row mg-bt-10">
          <div class="col-3 pd-l-30">Chọn nhóm</div>
          <div class="col-3">
            <nz-select class="w-100" nzShowSearch [(ngModel)]="groupShare" [ngModelOptions]="{ standalone: true }"
              nzAllowClear nzPlaceHolder="Nhóm..." (nzOnSearch)="onSearchGroup($event)"
              (ngModelChange)="selectGroup($event)">
              <nz-option nzCustomContent *ngFor="let group of searchDataGroupFilter" [nzValue]="group"
                [nzLabel]="group.name">
                <div>{{ group.name }}</div>
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div *ngIf="lstGroupShare.length > 0" class="row mg-bt-10">
          <div class="col-3 pd-l-30">Danh sách nhóm</div>
          <div class="col-9">
            <div class="list-group-share">
              <li *ngFor="let group of lstGroupShare" class="mg-r-10 d-inline">
                {{ group.name }}
                <i *ngIf="!isOnlyViewData" nz-icon nzType="close" style="font-size: 10px" nzTheme="outline"
                  (click)="unSelectGroup(group.id)"></i>
              </li>
            </div>
          </div>
        </div>
      </div>

    </form>
    <div class="row mg-bt-10">
      <div class="col-3">Danh sách criteria</div>
      <div class="col-9">
        <nz-table class="mg-bt-10" #criteriaTable [nzData]="listCriteriaForm" [nzShowPagination]="false" nzSize="small">
          <thead>
            <tr>
              <th nzWidth="20%">Logical Operator</th>
              <th nzWidth="30%">Parameter</th>
              <th nzWidth="20%">Operator</th>
              <th nzWidth="15%">Value</th>
              <th nzWidth="15%"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of criteriaTable.data; index as i">
              <td>{{ toLogicalOperator(data.logicalOperator) }}</td>
              <td>{{ data.parameterTitle }}</td>
              <td>{{ data.operatorString }}</td>
              <td>
                <span *ngIf="data.dataType == DataType.SINGLECHOICE">{{ data.parameterValueText }}</span>
                <span *ngIf="data.dataType == DataType.BOOLEAN">{{ data.parameterValueText=='true'?'Yes':'No' }}</span>
                <span
                  *ngIf=" data.dataType==DataType.NUMBER || data.dataType==DataType.STRING|| data.dataType==DataType.AGE">{{
                  data.parameterValue }}</span>
              </td>
              <td>
                <i class="mg-r-10" nz-icon nzType="edit" nzTheme="outline" (click)="openModalCriteria(data, i)"></i>
                <i nz-icon nzType="delete" nzTheme="outline" (click)="removeCriteria(data)"></i>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <button nz-button (click)="openModalCriteria('add')">
          <i nz-icon nzType="plus" nzTheme="outline"></i> Thêm criteria
        </button>
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Danh sách action</div>
      <div class="col-9">
        <nz-table class="mg-bt-10" #actionTable [nzData]="listActionForm" [nzShowPagination]="false" nzSize="small">
          <thead>
            <tr>
              <th nzWidth="35%">Công việc</th>
              <th nzWidth="35%">Mô tả chi tiết</th>
              <th nzWidth="15%">Thông báo trước</th>
              <th nzWidth="15%"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of actionTable.data; index as i">
              <td>{{ data.title }}</td>
              <td>{{ data.detail }}</td>
              <td>
                <div>{{ data.minutesBeforeReminder }}</div>
              </td>
              <td>
                <i class="mg-r-10" nz-icon nzType="edit" nzTheme="outline" (click)="openModalAction(data, i)"></i>
                <i nz-icon nzType="delete" nzTheme="outline" (click)="removeAction(data)"></i>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <button nz-button (click)="openModalAction('add')">
          <i nz-icon nzType="plus" nzTheme="outline"></i>Thêm action
        </button>
      </div>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-small" (click)="handleCancel()">Đóng</button>
    <button *ngIf="isProcessFormAdd && !isOnlyViewData" nz-button class="btn-primary-small"
      (click)="saveRulesManagerment()">
      Lưu
    </button>
    <button *ngIf="!isProcessFormAdd && !isOnlyViewData" nz-button class="btn-primary-small"
      (click)="saveRulesManagerment()">
      Sửa
    </button>
  </div>
</nz-modal>
<nz-modal [(nzVisible)]="visibleCriteriaModal" nzTitle="Edit CDS Criteria" nzWidth="700px" nzMaskClosable="false"
  (nzOnCancel)="handleCancelRmd()">
  <form [formGroup]="criteriaForm">
    <div class="row mg-bt-10">
      <div class="col-3">Logical operator <span class="red-dot">*</span></div>
      <div class="col-3">
        <nz-select class="w-100" formControlName="logicalOperator" nzShowSearch [nzServerSearch]="true" nzAllowClear
          nzPlaceHolder="--Tất cả--">
          <nz-option nzCustomContent *ngFor="let form of logicalOperator" [nzValue]="form.code" [nzLabel]="form.name">
            <div>{{ form.name }}</div>
          </nz-option>
        </nz-select>
      </div>
      <div *ngIf="criteriaForm.value.actionFormLogicalOperator != null" class="col-6" style="line-height: 30px">
        {{ criteriaForm.value.criteriaLogicalOperator.name }}
      </div>
    </div>
    <div class="row mg-bt-10">
      <div class="col-3">Parameter <span class="red-dot">*</span></div>
      <div class="col-9">
        <nz-select class="w-100" formControlName="parameter" nzShowSearch [nzServerSearch]="true" nzAllowClear
          nzPlaceHolder="--Chọn parameter--" (nzOnSearch)="onSearchParameter($event)">
          <nz-option *ngFor="let form of  searchParameter" [nzValue]="form.defaultField" [nzLabel]="form.title">
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div class="row mg-bt-10" *ngIf="selectedParameter">
      <div class="col-3">Operator <span class="red-dot"></span></div>
      <div class="col-6" [ngSwitch]="selectedParameter.dataType">
        <div class="col6-element" *ngSwitchCase="DataType.NUMBER">
          <nz-select class="w-100" nzAllowClear nzPlaceHolder="--Chọn toán tử--" formControlName="operator">
            <nz-option [nzValue]="1" nzLabel="Equal">
            </nz-option>
            <nz-option [nzValue]="2" nzLabel="Greater">
            </nz-option>
            <nz-option [nzValue]="3" nzLabel="Less">
            </nz-option>
            <nz-option [nzValue]="5" nzLabel="Less And Equal">
            </nz-option>
            <nz-option [nzValue]="6" nzLabel="Greater And Equal">
            </nz-option>
            <nz-option [nzValue]="7" nzLabel="Not Equal">
            </nz-option>
          </nz-select>
        </div>
        <div class="col6-element" *ngSwitchCase="DataType.AGE">
          <nz-select class="w-100" nzAllowClear nzPlaceHolder="--Chọn toán tử--" formControlName="operator">
            <nz-option [nzValue]="1" nzLabel="Equal">
            </nz-option>
            <nz-option [nzValue]="2" nzLabel="Greater">
            </nz-option>
            <nz-option [nzValue]="3" nzLabel="Less">
            </nz-option>
            <nz-option [nzValue]="5" nzLabel="Less And Equal">
            </nz-option>
            <nz-option [nzValue]="6" nzLabel="Greater And Equal">
            </nz-option>
            <nz-option [nzValue]="7" nzLabel="Not Equal">
            </nz-option>
          </nz-select>
        </div>
        <div class="col6-element" *ngSwitchCase="DataType.STRING">
          <nz-select class="w-100" nzAllowClear nzPlaceHolder="--Chọn toán tử--" formControlName="operator">
            <nz-option [nzValue]="1" nzLabel="Equal">
            </nz-option>
            <nz-option [nzValue]="4" nzLabel="Contain">
            </nz-option>
            <nz-option [nzValue]="7" nzLabel="Not Equal">
            </nz-option>
          </nz-select>
        </div>
        <div class="col6-element" *ngSwitchCase="DataType.BOOLEAN">
          <nz-select class=" w-100" nzAllowClear nzPlaceHolder="--Chọn toán tử--" formControlName="operator">
            <nz-option [nzValue]="1" nzLabel="Equal">
            </nz-option>
            <nz-option [nzValue]="7" nzLabel="Not Equal">
            </nz-option>
          </nz-select>
        </div>
        <div class="col6-element" *ngSwitchCase="DataType.SINGLECHOICE">
          <nz-select class=" w-100" nzAllowClear nzPlaceHolder="--Chọn toán tử--" formControlName="operator">
            <nz-option [nzValue]="1" nzLabel="Equal">
            </nz-option>
            <nz-option [nzValue]="7" nzLabel="Not Equal">
            </nz-option>
          </nz-select>
        </div>
      </div>
      <!-- <div class="col-6">
        <nz-select class="w-100" nzAllowClear nzPlaceHolder="--Chọn toán tử--" formControlName="operator">
          <nz-option *ngFor="let form of searchOperator" [nzValue]="form.code" [nzLabel]="form.name">
            <div>{{ form.name }}</div>
          </nz-option>
        </nz-select>
      </div> -->
    </div>

    <div class="row mg-bt-10" *ngIf="selectedParameter">
      <div class="col-3">Value <span class="red-dot">*</span></div>
      <div class="col-6" [ngSwitch]="selectedParameter.dataType">
        <div class="col6-element" *ngSwitchCase="DataType.NUMBER">
          <nz-input-number nz-input formControlName="parameterValue"></nz-input-number>
        </div>
        <div class="col6-element" *ngSwitchCase="DataType.AGE">
          <nz-input-number nz-input formControlName="parameterValue"></nz-input-number>
        </div>
        <div class="col6-element" *ngSwitchCase="DataType.STRING">
          <input nz-input formControlName="parameterValue" />
        </div>
        <div class="col6-element" *ngSwitchCase="DataType.BOOLEAN">
          <nz-radio-group nz-input formControlName="parameterValue">
            <label nz-radio [nzValue]="true">
              Yes</label>
            <label nz-radio [nzValue]="false">
              No</label>
          </nz-radio-group>
        </div>
        <div class="col6-element" *ngSwitchCase="DataType.SINGLECHOICE">
          <nz-radio-group nz-input formControlName="parameterValue">
            <label nz-radio *ngFor="let opt of selectedParameter.dataSource" [nzValue]="opt.valueNumber+''">
              {{opt.text}}</label>
          </nz-radio-group>
        </div>
      </div>
    </div>

  </form>
  <div *nzModalFooter>
    <button nz-button class="btn-cancel-small" (click)="handleCancelRmd()">
      Đóng
    </button>
    <button nz-button class="btn-primary-small" (click)="saveCriteria()">
      Lưu
    </button>
  </div>
</nz-modal>

<!-- <app-reminder-rules [(isVisible)]="visibleActionModal" (refreshData)="loadListReminder()"
  (usedSample)="saveAction($event)"></app-reminder-rules> -->
<app-reminder-rules [(isVisible)]="visibleActionModal" (refreshData)="getRulesManagerment()"
  (usedAction)="usingSample($event)"></app-reminder-rules>