import { DataFormMobileComponent } from './../modal/data-form-mobile/data-form-mobile.component';
import { MobileNotifyComponent } from './mobile-layout/mobile-notify/mobile-notify.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CovidReceiveListComponent } from '../common-layout/covid-receive-list/covid-receive-list.component';
import { GroupDashboardComponent } from '../common-layout/group-dashboard/group-dashboard.component';
import { PatientMobileListComponent } from './mobile-layout/patient-mobile-list/patient-mobile-list.component';
import { UserProfileComponent } from '../common-layout/user-profile/user-profile.component';
import { VisitDetailMobileComponent } from './mobile-layout/visit-detail-mobile/visit-detail-mobile.component';
import { ChatComponentComponent } from './chat-component/chat-component.component';
import { ListVisitContainerComponent } from '../list-visit-container/list-visit-container.component';

//import { VisitDetailComponent } from './visit-detail/visit-detail.component';
const routes: Routes = [
  { path: '', component: ListVisitContainerComponent },
  { path: 'covid-list', component: CovidReceiveListComponent },
  { path: 'user-profile', component: UserProfileComponent },
  { path: 'patient-list', component: PatientMobileListComponent },
  { path: 'group-dashboard', component: GroupDashboardComponent },
  { path: 'visit/:visitId', component: VisitDetailMobileComponent }, //Bỏ PatientID
  { path: 'chat', component: ChatComponentComponent },
  { path: 'notify', component: MobileNotifyComponent },
  {
    path: 'record/:formId/:recordId/:visitId',
    component: DataFormMobileComponent,
  },
  // //  { path: 'visit/:visitId', component: VisitDetailComponent }
  // {
  //   path: '',
  //   redirectTo: '/common/worklist',
  //   pathMatch: 'full',
  //   canActivate: [AuthGuard]
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
