<!-- <div class="video-modal">  -->
<nz-modal
  nzClassName="video-modal"
  [(nzVisible)]="isVisible"
  [nzWidth]="406"
  nzTitle="Thêm người vào cuộc gọi"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleCancel()">
  <div class="mg-t-30"><b>Chọn người mời</b></div>
  <div class="input-box">
    <!-- Chọn khách để mời vào cuộc gọi -->
    <div class="input-member row">
      <nz-select
        class="col-md-12 input-search"
        [nzShowSearch]="true"
        [nzServerSearch]="true"
        [(ngModel)]="ListOfMember"
        [nzShowArrow]="false"
        (nzOnSearch)="loadMember(1, $event)"
        nzPlaceHolder="Tìm người tham gia"
        (ngModelChange)="fieldSelectedMember($event)">
        <div>
          <nz-option
            *ngFor="let item of listMember; let indexOfelement = index"
            nzCustomContent
            [nzValue]="item">
            <div>{{ item.userName }}</div>
            <div>{{ item.email }}</div>
          </nz-option>
        </div>
      </nz-select>
    </div>
  </div>
  <!-- Hiển thị danh sách khách mời đã chọn -->
  <div class="invite-display">
    <div class="elm-title">
      Danh sách khách mời - <span>{{ listInvited.length }}</span> người
    </div>
    <ul id="listEmail">
      <li *ngFor="let item of listInvited">
        <img
          class="media-object imageRoom"
          [src]="baseAvatarurl + item.imageUrl" />
        <div class="memberOfCall">
          <div>
            <b>{{ item.userName }}</b>
          </div>
          <div>{{ item.email }}</div>
        </div>
        <a
          (click)="unSelectToInvite(item.userId, item.userName, item.email)"
          title="Bỏ chọn">
          <i nz-icon nzType="close-circle" nzTheme="outline"></i>
        </a>
      </li>
    </ul>
  </div>
  <div class="row" *nzModalFooter>
    <div>
      <button
        nz-button
        nzType="primary"
        (click)="handleCall()"
        class="button-submit only-style">
        Mời
      </button>
    </div>
  </div>
</nz-modal>
<!-- </div> -->
