import { RecommendContextComponent } from './recommend-context/recommend-context.component';
import { TestResultComponent } from './test-result/test-result.component';
import { SharedModule } from 'src/app/share/share.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculatedElementComponent } from './calculated-element.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [CalculatedElementComponent],
  declarations: [
    CalculatedElementComponent,
    TestResultComponent,
    RecommendContextComponent,
  ],
})
export class CalculatedElementModule {}
