import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AppConfigService } from 'src/app-config.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { SliderImageComponent } from '../slider-image/slider-image.component';

@Component({
  selector: 'app-image-mode-view',
  templateUrl: './image-mode-view.component.html',
  styleUrls: ['./image-mode-view.component.scss'],
})
export class ImageModeViewComponent implements OnInit {
  @Input() visitId: string;
  @Input() userIdOnline: string;
  @Input() selectedIndex: number;
  @Input() lstImages: any;
  // @Input() useFullImageUrl:boolean = false;
  @Input() imageFrom = 0;

  @Output() closeShowImage = new EventEmitter();
  @Output() editImage = new EventEmitter();
  @Output() refresh = new EventEmitter();

  @ViewChild(SliderImageComponent) sliderImage;

  visibleSmallSize = false;
  visibleFullScreen = false;
  baseImageurl = this.configService.getConfig().media.baseUrl + '/VisitImage/';
  ImageFrom = Constant.ImageForm;

  constructor(private configService: AppConfigService) {}

  ngOnInit(): void {}

  openFullScreen() {
    this.visibleSmallSize = false;
    this.visibleFullScreen = true;
  }

  viewInNewWindow() {
    this.visibleSmallSize = false;
    this.visibleFullScreen = false;
    window.open(
      window.location.origin + '/silder-image/' + this.visitId,
      '_blank',
      'toolbar=0,location=0,menubar=0'
    );
  }

  returnSmallPopup() {
    this.visibleSmallSize = true;
    this.visibleFullScreen = false;
  }

  cancelSmallSize() {
    this.visibleSmallSize = false;
    this.closeShowImage.emit();
  }

  cancelFullScreen(): void {
    this.visibleFullScreen = false;
    this.closeShowImage.emit();
  }

  showImage(index: number): void {
    this.visibleSmallSize = true;
    this.selectedIndex = index;
  }

  deleteImage(visitId, imageId, index) {
    this.sliderImage.deleteImage(visitId, imageId, index);
  }

  showModalEditImg(image) {
    this.editImage.emit(image);
  }

  refreshData(data) {
    this.lstImages = data;
    this.refresh.emit(data);
  }

  getShortenString(val, maxLength) {
    if (typeof val !== 'string') {return val;}

    if (val.length < maxLength) {return val;}
    else {return val.substr(0, maxLength - 2) + '..';}
  }
}
