import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonInfoManagementComponent } from './common-info-management.component';
import { SharedModule } from 'src/app/share/share.module';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';

@NgModule({
  declarations: [CommonInfoManagementComponent],
  imports: [CommonModule, SharedModule, NzPaginationModule],
  exports: [CommonInfoManagementComponent],
})
export class CommonInfoManagementModule {}
