import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-content',
  templateUrl: './form-content.component.html',
  styleUrls: ['./form-content.component.scss'],
})
export class FormContentComponent implements OnInit {
  @Input() count: number;
  @Output() incrementCount: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {
    // console.log("init");
    // console.log(this.count);
  }

  inrement() {
    this.incrementCount.emit(this.count++);
  }
}
