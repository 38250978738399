<div *ngIf="isVisibleDuplicate | async as visible">
  <nz-modal
    nzTitle="Danh sách Bệnh nhân trùng"
    [nzVisible]="visible"
    [nzWidth]="1200"
    [nzFooter]="null"
    [nzMaskClosable]="false"
    (nzOnCancel)="handleCancel()">
    <div class="col-12 mg-t-15 d-header">
      <div class="col-lg-9">
        <div class="info">
          <span
            >Có {{ duplicatePatients ? duplicatePatients.length : '' }} bệnh
            nhân</span
          >
          <span> trùng thông tin với bệnh nhân mới thêm.</span>
        </div>
        <div class="note">
          <i
            >Click đúp vào bệnh nhân mà bạn muốn thêm ca khám mới cho chính bệnh
            nhân đó.</i
          >
        </div>
      </div>
      <button
        class="btn-primary-large"
        (click)="patientInfoToVisit(this.editedPatient, true)">
        Tạo bệnh nhân mới
      </button>
    </div>

    <div class="mg-t-10 wrapper">
      <nz-table
        id="duplicatePatientTable"
        #duplicatePatientTable
        nzSize="default"
        [nzData]="duplicatePatients"
        nzShowSizeChanger
        [nzPageSizeOptions]="[10, 20, 50, 100]">
        <thead>
          <tr>
            <th>Tên bệnh nhân</th>
            <th nzWidth="100px">Giới tính</th>
            <th nzWidth="150px">Ngày sinh</th>
            <th nzWidth="150px">Số điện thoại</th>
            <th nzWidth="150px">Chứng minh thư</th>
            <th>Địa chỉ</th>
            <th>Ngày khám gần nhất</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let data of duplicatePatientTable.data"
            id="{{ data.id }}"
            (dblclick)="patientInfoToVisit(data, false)">
            <td>
              <div
                *ngIf="
                  data.color &&
                  data.shortName &&
                  (!data.avatar || data.avatar == '')
                "
                class="customer-photo"
                [ngStyle]="{ 'background-color': data.color }">
                {{ data.shortName }}
              </div>
              <div
                *ngIf="
                  !data.color &&
                  !data.shortName &&
                  (!data.avatar || data.avatar == '')
                "
                class="customer-photo"
                style="
                  background-image: url('/assets/image/avt-default2.png');
                  background-repeat: round;
                "></div>
              <div
                *ngIf="data.avatar && data.avatar != ''"
                class="customer-photo"
                [ngStyle]="{
                  'background-image':
                    'url(' + (baseAvatarurl + data.avatar) + ')'
                }"></div>
              {{ data.fullName }}
            </td>
            <td>{{ data.sex | genderText }}</td>
            <td>{{ data.dobString }}</td>
            <td>{{ data.phoneNo }}</td>
            <td>{{ data.idNumber }}</td>
            <td>{{ data.address }}</td>
            <td>
              {{ data.lastVisit.visitDate | date: 'dd/MM/yyyy HH:mm' }} -
              {{ data.lastVisit.doctorName }} - {{ data.lastVisit.visitReason }}
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
    <div *nzModalFooter>
      <button nz-button (click)="handleCancel()">Quay lại</button>
      <button
        nz-button
        nzType="primary"
        (click)="handleOk()"
        class="button-submit">
        Thêm ca khám
      </button>
    </div>
  </nz-modal>
</div>
