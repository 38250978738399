import { UrlConstant } from './../share/constants/url.class';
import { Constant } from './../share/constants/constant.class';
import { Observable } from 'rxjs';
import { BaseService } from './../share/base-service/base-service.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends BaseService {
  getCurrentInfo(payload): Observable<any> {
    return this.post(UrlConstant.REPORT_CURRENT_INFO, payload);
  }

  getContinuousInfo(payload): Observable<any> {
    return this.post(UrlConstant.REPORT_CONTINUOUS_INFO, payload);
  }

  getSOSRangeInfo(payload): Observable<any> {
    return this.post(UrlConstant.REPORT_SOSRANGE, payload);
  }
  exportCovidPatient(groupId): Observable<any> {
    return this.httpClient.get(
      this.configService.getConfig().api.baseUrl +
        UrlConstant.EXPORT_COVID_PATIENT +
        groupId,
      {
        headers: this.createHeaders() || {},
        responseType: 'blob' as 'json',
      }
    );
  }
  exportPrescription(groupId): Observable<any> {
    return this.get(
      UrlConstant.EXPORT_PRESCRIPTION_GROUP + groupId,
      {},
      'blob'
    );
  }
}
