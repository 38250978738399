import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';
@Injectable({
  providedIn: 'root',
})
export class RecordVisitService extends BaseService {
  getRecordVisit(id: string) {
    return this.get(UrlConstant.RECORD_VISIT + '?id=' + id);
  }

  submitRecordVisit(data) {
    return this.post(UrlConstant.RECORD_VISIT, data);
  }

  getRecordToEdit(data): Observable<any> {
    return this.post(UrlConstant.RECORD_VISIT_EDIT, data);
  }

  getVisitSummary(data): Observable<any> {
    return this.post(UrlConstant.GET_VISIT_SUMMARY, data);
  }

  saveReport(data) {
    return this.post(UrlConstant.RECORD_SAVE, data);
  }

  printReport(data) {
    return this.post(UrlConstant.RECORD_PRINT, data);
  }

  exportReport(data): Observable<any> {
    return this.httpClient.post(this.configService.getConfig().api.baseUrl + UrlConstant.RECORD_EXPORT, data, {
      headers: this.createHeaders() || {},
      responseType: 'blob' as 'json',
    });
  }
  deleteRecordVisit(id) {
    return this.delete(UrlConstant.RECORD_VISIT_DELETE + '?recordId=' + id, '');
  }
}
