<div class="list-visit-small">
  <div class="search-visit-small">
    <div class="row">
      <div class="col-12">
        <nz-radio-group [(ngModel)]="search.isReceived" (ngModelChange)="searchListVisit()">
          <label nz-radio [nzValue]="false">Chờ gọi</label>
          <label nz-radio [nzValue]="null">Tất cả</label>
        </nz-radio-group>
        </div>
        <div class="col-12">
        <nz-radio-group [(ngModel)]="search.visitStatus" (ngModelChange)="searchListVisit()">
          <label nz-radio [nzValue]="0">Chờ khám</label>
          <label nz-radio [nzValue]="1">Chờ kết luận</label>
          <label nz-radio [nzValue]="null">Tất cả</label>
        </nz-radio-group>
        <span class="search-visit-small-icon" title="Refresh" (click)="searchListVisit()">
          <i class="i-icon" nz-icon nzType="reload" nzTheme="outline"></i>
        </span>
        <i class="icon-expand" nz-icon [nzType]="isExpandSearch ? 'down' : 'right'" nzTheme="outline"
          (click)="toggleExpandSearch()"></i>
      </div>
    </div>
    <div class="advanced-search" *ngIf="isExpandSearch">
      <div class="row">
        <label class="col-4">Mã bệnh nhân:</label>
        <div class="col-8">
          <input [(ngModel)]="search.patientCode" nz-input name="patientCode" id="txtCode" type="text"
            autocomplete="off" placeholder="Mã bệnh nhân" (keydown.enter)="searchListVisit()" />
        </div>
      </div>
      <div class="row">
        <label class="col-4">Tên bệnh nhân:</label>
        <div class="col-8">
          <input [(ngModel)]="search.patientName" nz-input name="patientName" id="txtFullName" type="text"
            autocomplete="off" placeholder="Tên bệnh nhân" (keyup)="onSearchPatientName($event)" />
        </div>
      </div>
      <div class="row">
        <label class="col-4">Mã ca khám:</label>
        <div class="col-8">
          <input nz-input name="visitCode" id="txtVisitCode" type="text" autocomplete="off" placeholder="Mã ca khám" />
        </div>
      </div>
      <div class="row">
        <label class="col-4">Ngày khám:</label>
        <div class="col-8">
          <nz-date-picker class="search-visit-date" (ngModelChange)="getDate($event)" [nzPlaceHolder]="'Ngày khám'"
            [nzFormat]="'dd/MM/yyyy'" [(ngModel)]="search.visitDate">
          </nz-date-picker>
        </div>
      </div>

      <div class="row">
        <div class="col-4"></div>
        <div class="col-8">
          <div class="btn-search-container">
            <span (click)="resetSearch()" class="search-visit-small-icon pointer" title="Bỏ lọc">
              <i nz-icon [nzType]="'close'" class="nz-i-close"></i>
            </span>
            <span (click)="searchListVisit()" class="search-visit-small-icon pointer i-search" title="Lọc">
              <i nz-icon [nzType]="'search'" class="nz-i-search"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="table-container" #tableContainer>
    <div class="total-visit">
      <b>Tổng số: {{ totalVisit }} ca khám</b>
    </div>
    <nz-table class="main-table-grid list-visit-table" #listVisitsTable [nzFrontPagination]="false"
      [nzData]="listVisits" [nzLoading]="loading" infinite-scroll (scrolled)="onTableScroll($event)"
      [infiniteScrollThrottle]="10" [infiniteScrollDistance]="0" [alwaysCallback]="true" [immediateCheck]="true"
      [scrollWindow]="false" style="max-height: calc(-395px + 100vh);">
      <thead>
        <tr>
          <ng-container *ngFor="let col of cols; index as i">
            <th nz-resizable [nzWidth]="col.width + 'px'" nzPreview (nzResizeEnd)="onResizeColumn($event, col.title)">
              <div class="col-content">
                <div class="col-name">
                  <span>
                    {{ col.title }}
                  </span>
                </div>
                <div *ngIf="i < cols.length - 1">
                  <nz-resize-handle nzDirection="right">
                    <div class="resize-trigger"></div>
                  </nz-resize-handle>
                </div>
              </div>
            </th>
          </ng-container>
          <!-- <th> {{ cols[2].title }} </th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of listVisitsTable.data; index as i" (click)="selectVisit(data)" [ngClass]="{
            'selected': currentVisit.id == data.id,
            'table-row-dark': currentVisit.id != data.id && i % 2,
            'in-progress-visit':
              data.visitStatus == VISIT_STATUS.InProgress.value,
            'finished-visit': data.visitStatus == VISIT_STATUS.Finished.value
          }">
          <td class="center">{{ i + 1 }}</td>
          <td [title]="data.patientName">
            <span class="td-icon-inside">
              <ng-container *ngIf="
                  data.visitStatus == VISIT_STATUS.Arrived.value &&
                  useRoomOrDoctorVisit
                ">
                <i *ngIf="data.isReceived" nz-icon nzType="pushpin" nzTheme="fill"></i>
              </ng-container>
              <ng-container *ngIf="data.visitStatus > VISIT_STATUS.Arrived.value">
                <i *ngIf="data.visitStatus == VISIT_STATUS.InProgress.value" nz-icon nzType="build" nzTheme="fill"></i>
                <i *ngIf="data.visitStatus == VISIT_STATUS.Finished.value" nz-icon nzType="check-circle"
                  nzTheme="fill"></i>
              </ng-container>
            </span>
            <span>{{ data.patientName }}</span>
          </td>
          <td>
            {{ data.patientGender | genderText }},
            {{ data.patientDOB | ageStringFromDOB }}
          </td>
          <td> <i *ngIf="data.canReceive" nz-icon nzType="notification" nzTheme="fill" (click)="showReceivePopup(data)"></i></td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div class="user-room-container" *ngIf="isUseDividePatientRoom && isDoctorSelectRoom">
    {{ getCurrentUserFullTitle() }}
    <i nz-icon nzType="edit" nzTheme="twotone" style="font-size: 16px" (click)="openSelectRoom(true)"></i>
  </div>
</div>

<nz-modal [nzVisible]="isVisibleSelectRoom" [nzTitle]="isUpdateUserRoom ? 'Thay đổi phòng khám' : 'Chọn phòng khám'"
  [nzClosable]="false">
  <div class="modal-user-room-body">
    <div>Mời bạn chọn phòng khám để làm việc</div>
    <div class="row">
      <div class="col-8 select-user-room">
        <nz-select [nzPlaceHolder]="" [(ngModel)]="currentUserRoomId" [nzShowSearch]="true" style="width: 100%">
          <nz-option *ngFor="let room of rooms" [nzLabel]="room.name" [nzValue]="room.id">
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div *nzModalFooter>
      <button nz-button nzType="primary" (click)="updateUserRoom()">
        Cập nhật
      </button>
      <button *ngIf="isUpdateUserRoom" nz-button (click)="cancelUpdateUserRoom()">
        Hủy
      </button>
    </div>
  </div>
</nz-modal>
<nz-modal [nzVisible]="isVisibleReceiveVisit" [nzTitle]="'Gọi bệnh nhận vào phòng khám'" (nzOnCancel)="handleCancel()">
  <div class="text-center">
    <h5>Đang gọi và chờ BN</h5>
    <h5><span style="color:#25b396; font-size: 21px;margin-top: 15px;">{{visitReceiveInfo?.patientName}}</span> vào PK
    </h5>
    <div *nzModalFooter>
      <button nz-button nzType="primary" (click)="receiveVisit()">
        Bắt đầu khám
      </button>
      <button nz-button (click)="receiveNextVisit()">
        Hủy gọi và gọi BN tiếp theo
      </button>
    </div>
  </div>
</nz-modal>
