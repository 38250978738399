import { select } from '@ngrx/store';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FilterItem } from 'src/app/models/filter-model/filter-item.class';
import { Constant } from 'src/app/share/constants/constant.class';
import { SearchListPatient } from 'src/app/models/list-patient.class';

@Component({
  selector: 'app-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss'],
})
export class FilterItemComponent implements OnInit {
  selectedItem: FilterItem = {
    field: '',
    label: '',
    defaultValue: '',
    isOT: false,
    type: Constant.FILTER_TYPE.INPUT,
    searchable: false,
    clearable: false,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  };
  defaultItem: FilterItem = {
    field: '',
    label: '',
    defaultValue: '',
    isOT: false,
    type: Constant.FILTER_TYPE.INPUT,
    searchable: false,
    clearable: false,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  };
  _filterItems: FilterItem[];
  _resetFilter = false;
  @Input() set filterItems(val: FilterItem[]) {
    if (val && val.length > 0) {
      this._filterItems = val;
      this.resetData();
    }
  }

  get filterItems(): FilterItem[] {
    return this._filterItems;
  }

  @Input() set resetFilter(val: boolean) {
    if (val) {
      this.resetData();
    }
  }

  get resetFilter(): boolean {
    return this._resetFilter;
  }

  @Input() index: number;
  @Input() searchParam: SearchListPatient;

  @Output() endTreatmentEvent: EventEmitter<any> = new EventEmitter();
  @Output() seachEvent: EventEmitter<any> = new EventEmitter();
  @Output() mFilterChange: EventEmitter<any> = new EventEmitter();

  lastSelected: FilterItem = {
    field: '',
    label: '',
    type: 0,
    isOT: false,
    operator: '',
    otDatatype: 0,
    defaultValue: '',
    searchable: false,
    clearable: false,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  };
  types = Constant;
  constructor() {}

  ngOnInit() {}

  endTreatment(val) {
    this.endTreatmentEvent.emit(val);
  }

  selectSearchItem(val: FilterItem) {
    val.isUsed = true;
    if (this.lastSelected.field == 'endTreatment') {
      this.endTreatmentEvent.emit(false);
    }
    this.filterItems.find(
      (t) =>
        t.field === this.lastSelected.field &&
        t.operator == this.lastSelected.operator
    ).isUsed = false;
    if (this.lastSelected.isOT) {
      this.searchParam.oTSearchFields = this.searchParam.oTSearchFields.filter(
        (t) =>
          !(
            t.oTCode == this.lastSelected.field &&
            t.operator == this.lastSelected.operator
          )
      );
    } else {
      this.searchParam[this.lastSelected.field] =
        this.lastSelected.defaultValue;
    }

    this.lastSelected = val;
  }

  resetData() {
    this.selectedItem = this.filterItems.filter((t) => t.isUsed)[this.index]
      ? this.filterItems.filter((t) => t.isUsed)[this.index]
      : this.defaultItem;
    this.lastSelected = this.selectedItem;

    this.searchParam = JSON.parse(JSON.stringify(this.searchParam ?? null));
    this.endTreatmentEvent.emit(false);
  }

  seachListPatient(val) {
    this.seachEvent.emit();
  }

  mFilterValueChange(val) {
    // this.searchParam = {...val};
    console.log(val);
    this.mFilterChange.emit(val);
  }
}
