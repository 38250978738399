<div class="mobile-layout">
  <div class="m-topnav">
    <div class="top-left">
      <a class="btn-sidebar" (click)="toggleMenu('#sidebar-left')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          width="32px"
          viewBox="0 0 32 32"
          style="enable-background: new 0 0 32 32"
          xml:space="preserve">
          <style type="text/css">
            .st0 {
              fill: #ffffff;
            }
          </style>
          <g>
            <path
              id="Path_157"
              class="st0"
              d="M26.16,16.92H5.84c-0.51,0-0.92-0.41-0.92-0.92s0.41-0.92,0.92-0.92h20.31   c0.51,0,0.92,0.41,0.92,0.92S26.67,16.92,26.16,16.92z" />
            <path
              id="Path_158"
              class="st0"
              d="M26.16,9.85H5.84c-0.51,0-0.92-0.41-0.92-0.92S5.33,8,5.84,8h20.31c0.51,0,0.92,0.41,0.92,0.92   S26.67,9.85,26.16,9.85z" />
            <path
              id="Path_159"
              class="st0"
              d="M26.16,24H5.84c-0.51,0-0.92-0.41-0.92-0.92c0-0.51,0.41-0.92,0.92-0.92h20.31   c0.51,0,0.92,0.41,0.92,0.92C27.08,23.59,26.67,24,26.16,24z" />
          </g>
          <g
            id="arrow-pointing-to-right_1_"
            transform="translate(32 32) rotate(180)">
            <path
              id="Path_5_1_"
              class="st0"
              d="M65.14,14.81L57.71,4.02c-0.24-0.45-0.8-0.62-1.26-0.38c-0.16,0.09-0.29,0.22-0.38,0.38   c-0.45,0.73-0.45,1.65,0,2.38l5.44,7.91H41.69c-0.64,0-1.16,0.75-1.16,1.69s0.52,1.69,1.16,1.69h19.83l-5.44,7.91   c-0.45,0.73-0.45,1.65,0,2.38c0.24,0.45,0.8,0.62,1.26,0.38c0.16-0.09,0.29-0.22,0.38-0.38l7.42-10.79   C65.59,16.46,65.59,15.54,65.14,14.81z" />
          </g>
        </svg>
      </a>
    </div>
    <div class="top-center">
      <img class="image" src="assets/image/logo.png" />
      <span style="color: #fff; font-size: 24px"> PMR </span>
    </div>
    <div class="top-right">
      <div class="notify mg-r-4" (click)="toggleNotification()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="30"
          height="30"
          viewBox="0 0 32 32">
          <defs>
            <clipPath id="clip-Artboard_6">
              <rect width="32" height="32" />
            </clipPath>
          </defs>
          <g
            id="Artboard_6"
            data-name="Artboard – 6"
            clip-path="url(#clip-Artboard_6)">
            <rect width="32" height="32" fill="var(--dp-primary-1)" />
            <g id="notification" transform="translate(8.311 6)">
              <path
                id="Path_159"
                data-name="Path 159"
                d="M184.9,465.044a3.181,3.181,0,0,0,5.823,0Z"
                transform="translate(-179.471 -445.886)"
                fill="#fff" />
              <path
                id="Path_160"
                data-name="Path 160"
                d="M198.72,2.012a7.155,7.155,0,0,1,2.443.428V2.346A2.348,2.348,0,0,0,198.817,0h-.194a2.348,2.348,0,0,0-2.346,2.346v.093A7.169,7.169,0,0,1,198.72,2.012Z"
                transform="translate(-190.375 0)"
                fill="#fff" />
              <path
                id="Path_161"
                data-name="Path 161"
                d="M69.069,93.51H53.648a.629.629,0,0,1-.62-.482.6.6,0,0,1,.33-.682,3.272,3.272,0,0,0,1-1.354,15.642,15.642,0,0,0,1.038-6.19,5.966,5.966,0,0,1,11.932-.024c0,.008,0,.016,0,.024a15.642,15.642,0,0,0,1.038,6.19,3.272,3.272,0,0,0,1,1.354.6.6,0,0,1,.33.682A.629.629,0,0,1,69.069,93.51Zm.3-1.16h0Z"
                transform="translate(-53.013 -75.588)"
                fill="#fff" />
            </g>
          </g>
        </svg>
        <span *ngIf="numOfNewNotifi > 0">
          <span class="notification-number">{{ numOfNewNotifi }}</span>
        </span>
      </div>
      <div class="dropdown" (click)="toggleUserInfo('#myDropdown')">
        <img
          src="https://image.freepik.com/free-vector/doctor-icon-avatar-white_136162-58.jpg"
          class="avatar-doctor" />
      </div>
    </div>
  </div>
  <div class="m-main" (click)="closeAllSideBar()">
    <router-outlet></router-outlet>
  </div>
</div>
<div class="sidebar sidebar-right" *ngIf="sideBarRightShow">
  <div class="sidebar-header">
    <em>Tài khoản</em>
    <strong>{{ username }}</strong>
  </div>
  <ul class="ul-menu">
    <li
      class="{{ activetab === 'user-profile' ? 'active-tab' : '' }}"
      (click)="menuClick('user-profile')">
      <span
        ><img
          style="width: 28px; margin-left: 5px; margin-bottom: 7px"
          src="/assets/image/register_white.svg"
          alt=""
      /></span>
      <span>Thông tin tài khoản</span>
    </li>
    <li>
      <span
        ><img
          style="width: 28px; margin-left: 5px; margin-bottom: 7px"
          src="/assets/image/register_white.svg"
          alt=""
      /></span>
      <span>Cài đặt</span>
    </li>
    <li (click)="logout()">
      <span
        ><img
          style="width: 28px; margin-left: 5px; margin-bottom: 7px"
          src="/assets/image/register_white.svg"
          alt=""
      /></span>
      <span>Đăng xuất</span>
    </li>
  </ul>
</div>

<div class="sidebar sidebar-left" *ngIf="sideBarLeftShow">
  <div class="sidebar-header">
    <em>Nhóm làm việc</em>
    <strong (click)="showModalGroup()">{{ selectedGroupName }}</strong>
  </div>
  <ul class="ul-menu">
    <li *ngIf="isCovidGroup">
      <span
        ><img
          style="width: 28px; margin-left: 5px; margin-bottom: 7px"
          src="/assets/image/register_white.svg"
          alt=""
      /></span>
      <span>Danh sách tiếp nhận KH</span>
    </li>
    <li
      class="{{ activetab === 'patient-list' ? 'active-tab' : '' }}"
      (click)="menuClick('patient-list')">
      <span
        ><img style="height: 20px" src="/assets/image/patient_list.svg" alt=""
      /></span>
      <span>Danh sách bệnh nhân</span>
    </li>
    <li>
      <span
        ><img style="height: 20px" src="/assets/image/visit_list.svg" alt=""
      /></span>
      <span>Danh sách ca khám</span>
    </li>
    <li>
      <span
        ><img
          style="height: 20px"
          src="/assets/image/appointment_list.svg"
          alt=""
      /></span>
      <span>Danh sách hẹn khám</span>
    </li>
    <li
      class="{{ activetab === 'group-dashboard' ? 'active-tab' : '' }}"
      (click)="menuClick('group-dashboard')">
      <span>
        <svg
          id="info"
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 32 32">
          <g id="info-2" data-name="info" transform="translate(6.667 6.667)">
            <path
              id="Path_179"
              data-name="Path 179"
              d="M9.333,0a9.333,9.333,0,1,0,9.333,9.333A9.344,9.344,0,0,0,9.333,0Zm0,16.97A7.636,7.636,0,1,1,16.97,9.333,7.645,7.645,0,0,1,9.333,16.97Z"
              fill="#fff" />
            <path
              id="Path_180"
              data-name="Path 180"
              d="M146.133,70a1.131,1.131,0,1,0,1.131,1.132A1.133,1.133,0,0,0,146.133,70Z"
              transform="translate(-136.8 -66.04)"
              fill="#fff" />
            <path
              id="Path_181"
              data-name="Path 181"
              d="M150.848,140a.849.849,0,0,0-.848.848v5.091a.848.848,0,0,0,1.7,0v-5.091A.849.849,0,0,0,150.848,140Z"
              transform="translate(-141.515 -132.081)"
              fill="#fff" />
          </g>
          <g
            id="Rectangle_53"
            data-name="Rectangle 53"
            fill="none"
            stroke="#707070"
            stroke-width="1"
            opacity="0">
            <rect width="32" height="32" stroke="none" />
            <rect x="0.5" y="0.5" width="31" height="31" fill="none" />
          </g>
        </svg>
      </span>
      <span>Thông tin hoạt động</span>
    </li>
    <li
      class="{{ activetab === 'chat' ? 'active-tab' : '' }}"
      (click)="menuClick('chat')">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19">
          <path
            id="messenger"
            d="M9.5,0A9.5,9.5,0,0,0,1.26,14.229L.036,18.03a.742.742,0,0,0,.934.934l3.8-1.224A9.5,9.5,0,0,0,19,9.5a9.69,9.69,0,0,0-1.786-5.549A9.35,9.35,0,0,0,9.5,0Zm0,17.516A7.992,7.992,0,0,1,5.257,16.3a.743.743,0,0,0-.621-.077l-2.744.884.884-2.744a.743.743,0,0,0-.077-.621,8.016,8.016,0,1,1,6.8,3.773ZM10.428,9.5A.928.928,0,1,1,9.5,8.572.928.928,0,0,1,10.428,9.5Zm3.711,0a.928.928,0,1,1-.928-.928A.928.928,0,0,1,14.139,9.5Zm-7.422,0a.928.928,0,1,1-.928-.928A.928.928,0,0,1,6.717,9.5Zm0,0"
            fill="#fefefe" />
        </svg>
      </span>
      <span>
        <em>Tin nhắn</em>
        <em class="unread-ico" *ngIf="unReadHtml" [innerHTML]="unReadHtml"></em>
      </span>
    </li>
  </ul>
</div>

<ng-template #customHtml let-notification let-text="text">
  <div class="" role="alert">
    <div class="alert-text">{{ msg.notification.title }}</div>
    <div
      *ngIf="msg.data.type !== 'InviteRoomTimerStart'"
      class="alert-text"
      style="margin-bottom: 10px"
      [innerHtml]="msg.notification.body"></div>
    <div
      *ngIf="
        msg.data.type === 'InviteRoomTimerStart' &&
        msg.data.link != undefined &&
        msg.data.link.length > 0
      "
      class="alert-text"
      style="margin-bottom: 10px"
      [innerHtml]="msg.notification.body + setLinkHoiChan(msg.data.link)"></div>
    <button
      class="btn-x-small"
      style="margin-right: 10px"
      *ngIf="msg.data.type === 'InviteRoom'"
      (click)="acceptJoin(null, false, notification)"
      nz-button
      nzSize="small">
      Từ chối
    </button>
    <button
      class="btn-primary-small"
      *ngIf="msg.data.type === 'InviteRoom'"
      (click)="acceptJoin(null, true, notification)"
      nz-button
      nzSize="small">
      Đồng ý
    </button>
    <button
      class="btn-x-small"
      style="margin-right: 10px"
      *ngIf="msg.data.type === 'InviteGroup'"
      (click)="acceptJoinGroup(null, false, notification)"
      nz-button
      nzSize="small">
      Từ chối
    </button>
    <button
      class="btn-primary-small"
      *ngIf="msg.data.type === 'InviteGroup'"
      (click)="acceptJoinGroup(null, true, notification)"
      nz-button
      nzSize="small">
      Đồng ý
    </button>
  </div>
</ng-template>

<app-mobile-select-group
  [isVisible]="isVisibleSelectGroup"
  (selectItem)="selectGroup($event)"
  (closeModal)="closeModalGroup()"
  [menus]="listGroups"
  (searchGroupEvent)="searchGroup($event)"></app-mobile-select-group>
<app-add-visit
  #addVisitMobile
  [patient]="patient"
  (selectPatient)="selectPatientEvent($event)"
  (refreshListVisit)="refreshDataListVisit()"></app-add-visit>
