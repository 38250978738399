import { DpformInfoComponent } from './dpform-info/dpform-info.component';
import { FormulaControlComponent } from './formula-control/formula-control.component';
import { CalculatedElementModule } from './../calculated-element/calculated-element.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditFormStaticComponent } from './edit-form-static.component';
import { SharedModule } from 'src/app/share/share.module';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { FormContentComponent } from './form-content/form-content.component';
import { FormatBoxComponent } from '../editForm/format-box/format-box.component';
import { FormatContainerBoxComponent } from '../editForm/format-container-box/format-container-box.component';
import { AddInputItemComponent } from '../editForm/add-input-item/add-input-item.component';
import { UploadImageComponent } from '../editForm/upload-image/upload-image.component';
import { LabelElementComponent } from './label-element/label-element.component';
import { CommonInfoElementComponent } from './commonInfo-element/commonInfo-element.component';
import { FormControlComponent } from './form-control/form-control.component';
import { CommonInfoControlComponent } from './commonInfo-control/commonInfo-control.component';
import { ObservationTypeAbsComponent } from './observation-type-abs/observation-type-abs.component';
import { ObservationDirective } from 'src/app/models/form-model/observation.directive';
import { FormControllerComponent } from './form-controller/form-controller.component';
import { CheckboxElementComponent } from './checkbox-element/checkbox-element.component';
import { ImageElementComponent } from './image-element/image-element.component';
import { InputElementComponent } from './input-element/input-element.component';
import { TextareaElementComponent } from './textarea-element/textarea-element.component';
import { DropdownElementComponent } from './dropdown-element/dropdown-element.component';
import { SelectionElementComponent } from './selection-element/selection-element.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { DatatimeElementComponent } from './datatime-element/datatime-element.component';
import { ObservationTypeItemComponent } from '../editForm/observation-type-item/observation-type-item.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DragAndDropModule,
    CalculatedElementModule,
  ],
  declarations: [
    EditFormStaticComponent,
    FormContentComponent,
    ObservationTypeItemComponent,
    FormatBoxComponent,
    FormatContainerBoxComponent,
    AddInputItemComponent,
    UploadImageComponent,
    LabelElementComponent,
    TextareaElementComponent,
    CommonInfoElementComponent,
    ImageElementComponent,
    DropdownElementComponent,
    SelectionElementComponent,
    InputElementComponent,
    InputNumberComponent,
    DatatimeElementComponent,
    FormControlComponent,
    CommonInfoControlComponent,
    ObservationTypeAbsComponent,
    FormControllerComponent,
    CheckboxElementComponent,
    FormulaControlComponent,
    ObservationDirective,
    DpformInfoComponent,
  ],
  exports: [
    ObservationTypeItemComponent,
    FormatBoxComponent,
    FormatContainerBoxComponent,
    AddInputItemComponent,
    UploadImageComponent,
    EditFormStaticComponent,
  ],
})
export class EditFormStaticModule {}
