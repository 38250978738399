import { UrlConstant } from './../share/constants/url.class';
import { Observable } from 'rxjs';
import { BaseService } from './../share/base-service/base-service.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomColumnService extends BaseService {
  getMainGridColumn(): Observable<any> {
    return this.get(UrlConstant.MAIN_GRID_COULUMN);
  }

  savetMainGridColumn(payload): Observable<any> {
    return this.post(UrlConstant.MAIN_GRID_COULUMN, payload);
  }

  getPatientGridColumn(groupId: string): Observable<any> {
    return this.get(UrlConstant.PATIENT_GRID_COULUMN + '/' + groupId);
  }

  getPatientGridDefaultColumn(params?: any): Observable<any> {
    return this.get(UrlConstant.PATIENT_GRID_DEFAULT_COULUMN, params);
  }

  savetPatientGridColumn(payload, groupId: string): Observable<any> {
    return this.post(UrlConstant.PATIENT_GRID_COULUMN + '/' + groupId, payload);
  }

  getAppoitmentGridColumn(): Observable<any> {
    return this.get(UrlConstant.APPOINTMENT_GRID_COULUMN);
  }

  savetAppoitmentGridColumn(payload): Observable<any> {
    return this.post(UrlConstant.APPOINTMENT_GRID_COULUMN, payload);
  }

  getCovidReceiveGridColumn(): Observable<any> {
    return this.get(UrlConstant.COVID_RECEIVE_GRID_COULUMN);
  }

  savetCovidReceiveGridColumn(payload): Observable<any> {
    return this.post(UrlConstant.COVID_RECEIVE_GRID_COULUMN, payload);
  }
}
