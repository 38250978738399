import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { IlayoutConfig } from '../../../../models/ilayout-config';
import { ConferenceRoomComponent } from '../../../modal/conference-room/conference-room.component';
import { Visits } from '../../../../models/listVisit.class';
import { Observable, Subject, Subscription } from 'rxjs';
import { ChatTabComponent } from '../../../modal/chat-tab/chat-tab.component';
import { MasternotesTabComponent } from '../../../modal/masternotes-tab/masternotes-tab.component';
import { VitalSignsTabComponent } from '../../visit-detail/vital-signs-tab/vital-signs-tab.component';
import { VisitService } from '../../../../services/visit.service';
import { TabDataService } from '../../../../share/base-service/data-worklist.service';
import { AppConfigService } from '../../../../../app-config.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state/app-state';
import { PatientService } from '../../../../services/patient.service';
import { NotificationService } from '../../../../services/notification.service';
import { VideoConferenceService } from '../../../../services/video-conference.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ChatService } from '../../../../services/chat.service';
import { Constant } from '../../../../share/constants/constant.class';
import { OpenAddVisit } from '../../../modal/redux/modal/modal.action';
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { XetnghiemTabComponent } from 'src/app/components/modal/xet-nghiem/xetnghiem-tab/xetnghiem-tab.component';
declare let $: any;
const defaultConfig: IlayoutConfig = {
  columns: [
    {
      visible: true,
      size: 75,
      name: 'main-content',
      columns: [
        {
          visible: true,
          size: 75,
          name: 'main',
        },
        {
          visible: true,
          size: 25,
          name: 'sub',
        },
      ],
    },
    {
      visible: true,
      size: 25,
      name: 'chat-content',
      columns: [],
    },
  ],
  disabled: false,
};
@Component({
  selector: 'app-visit-detail-mobile',
  templateUrl: './visit-detail-mobile.component.html',
  styleUrls: ['./visit-detail-mobile.component.scss'],
})
export class VisitDetailMobileComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @Input() visitId: string;
  @Input() visitPrivacy = 1; //default:1-all quyền//3:view-share for BN, //4: view-sharefor BS, //5 : CK da bi dong : có nut mở lại, con lại giống như value3+4
  @Input() shareId = '';
  @ViewChild(ConferenceRoomComponent) CreateVideoCall: ConferenceRoomComponent;
  faNotesMedical = faNotesMedical;
  formid: any;
  recordId: any;
  listRecord: any;
  isVisibleVisitMenu: boolean;
  currentGroupId: any;
  arrCollapse: any[];
  arrTab: any[];
  reloadNow: boolean;
  visit: Visits = {
    stt: '',
    sttsearchresult: '',
    id: '',
    patientId: '',
    patientName: '',
    patientCode: '',
    groupId: '',
    doctorId: '',
    creatorId: '',
    patientSex: '',
    patientSexText: '',
    yob: 0,
    dob: new Date().toJSON(),
    dobString: '',
    phoneNo: '',
    address: '',
    visitDate: new Date(),
    visitReason: '',
    visitTypeText: '',
    visitEndDate: new Date(),
    color: '',
    shortName: '',
    age: 0,
    imageUrl: '',
    diseases: [],
    doctorName: '',
    groupName: '',
    images: [],
    documents: [],
    pdfDocuments: [],
    avatar: '',
    clinicalInfomation: '',
    conclusion: '',
    tags: [],
    dateOfDiagnosis: new Date(),
    dateOfSymptom: new Date(),
    doctorInCareDoct: '',
    nursingInCare: '',
    examinedStatus: false,
    examinedByDoctorId: '',
    examinedByDoctorName: '',
  };
  destroy$: Subject<boolean> = new Subject<boolean>();
  indexTab: number;
  showChat: boolean;
  showFinishedVisited = true;
  disabledClass = '';
  hideButton = '';
  isShowChat = '';
  message = 0;
  localStorageName = 'angular-split-ws';
  config: IlayoutConfig = null;
  curWidth = 200;
  //currentGroupId = localStorage.getItem(Constant.CURRENT_GROUPID);
  groupId = '';
  // patientId: any;
  patient: any;
  isShareVisit: boolean;
  isVisitLogVisible = false;
  isOpenAddVisit: Observable<boolean>;
  isSentPatient = false;
  profileStatus = 'Gửi hồ sơ';
  tabChatTitle = '';
  isVideoCall: boolean;
  groupSub: Subscription;
  isVisibleEditVisitCore = false;
  isCovidGroup = false;
  isLoading = false;
  tabs: Array<any> = [
    {
      id: 'thongtincakham',
      name: 'Thông tin ca khám',
      type: 'main',
      reloadNow: false,
    },
    { id: 'xetnghiem', name: 'Xét nghiệm', type: 'main', reloadNow: false },
    { id: 'cdha', name: 'CĐHA', type: 'main' },
    { id: 'thuoc', name: 'Thuốc', type: 'main', reloadNow: false },
    { id: 'phieudl', name: 'Phiếu dữ liệu', type: 'main' },
    { id: 'chiso', name: 'Thông tin lâm sàng', type: 'main', reloadNow: false },
    {
      id: 'visit-summary',
      name: 'Tổng kết ca khám',
      type: 'main',
      reloadNow: false,
    },
  ];
  totalHistory: any;
  lstHistory: any;
  isSpinning: boolean;
  isToggleFAB: boolean;

  @ViewChild(ChatTabComponent) chatTab: ChatTabComponent;
  @ViewChild(MasternotesTabComponent) noteTab: MasternotesTabComponent;
  @ViewChildren('visitmainchat,boxchat') divs: QueryList<ElementRef>;
  @ViewChild(XetnghiemTabComponent) XNTab: XetnghiemTabComponent;
  @ViewChild(VitalSignsTabComponent) VSTab: VitalSignsTabComponent;
  constructor(
    private visitService: VisitService,
    private tabDataService: TabDataService,
    private configService: AppConfigService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
    private patientService: PatientService,
    private notificationService: NotificationService,
    private videoConfernceService: VideoConferenceService,
    private modalService: NzModalService,
    private chatService: ChatService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.visitService.indexTab.subscribe((index) => {
      this.indexTab = index;
    });
    this.isOpenAddVisit = this.store.select(
      (store) => store.modals.isVisitOpen
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Unsubscribe to avoid memory leak
    this.destroy$.unsubscribe();
    this.groupSub.unsubscribe();
  }

  ngOnInit(): void {
    this.arrCollapse = [true, true, true];
    this.arrTab = [true, false, false, false, false];
    this.visitId = this.route.snapshot.paramMap.get('visitId');
    // this.patientId = this.route.snapshot.paramMap.get('patientId');
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        this.isCovidGroup = data.isCovidGroup;
        const userPermissionInGroup =
          data.doctorPermission == null ? [] : data.doctorPermission;
        if (
          userPermissionInGroup.filter(
            (en) => en == Constant.DOCTORPERMISSION.Doctor
          ).length == 0
        ) {
          this.showFinishedVisited = false;
        } else {
          this.showFinishedVisited = true;
        }
      }
      if (this.isCovidGroup) {
        const chiso = this.tabs.filter((obj) => obj.id === 'chiso')[0];
        // anticovid hidden
        this.tabs = this.tabs.filter(
          (en) =>
            en.id != 'chiso' && en.id != 'cdha' && en.id != 'visit-summary'
        );
        this.tabs.splice(1, 0, chiso);
      } else {
        this.groupId = '000000000000000000000000';
      }
    });
    this.getVisit(this.visitId);
    this.showChat = false;
    this.setUserRole();
    //this.groupId = this.tabDataService.sharegroup.getValue();
    if (localStorage.getItem(this.localStorageName)) {
      this.config = JSON.parse(localStorage.getItem(this.localStorageName));
    } else {
      this.resetConfig();
    }
  }

  ngAfterViewChecked(): void {
    if (this.curWidth != $('#visit-main-chat').width()) {
      const width = $('#visit-main-chat').width();
      $('#box-notechat').width(width);
      this.curWidth = width;
    }
  }

  getMesssage(val) {
    this.message = val;
    this.tabChatTitle =
      'Chat' + (this.message > 5)
        ? '(5+)'
        : this.message == 0
        ? ''
        : '(' + this.message + ')';
  }

  getVisit(vsId: string): void {
    this.isLoading = true;
    if (this.shareId.length > 0) {
      this.visitService
        .getVisitInShareLink(vsId, this.groupId, this.shareId)
        .subscribe((data) => {
          this.visit = data;
          this.visitPrivacy = data.userRole;
          this.getPatient();
          this.isLoading = false;
          this.visit.patientId;
          this.getVisitHistory(1000, 0);
        });
    } else {
      this.visitService.getVisit(vsId, this.groupId).subscribe((data) => {
        this.visit = data;
        this.visitPrivacy = data.userRole;
        this.getPatient();
        this.isLoading = false;
        this.showFinishedVisited =
          this.showFinishedVisited &&
          (this.visit.examinedStatus === true ? false : true);
        this.reloadNow = true;
        this.getVisitHistory(1000, 0);
      });
    }
  }

  chatTabSelect() {
    this.showChat = !this.showChat;
  }

  showTabPatientInfo(id, PatientName): void {
    this.tabDataService.updateTab(id, PatientName, 'PatientInfo', '');
  }
  finishVisit() {
    if (this.showFinishedVisited) {
      this.visitService.finishVist(this.visit.id).subscribe((res) => {
        if (res.isValid) {
          this.showFinishedVisited = false;
          this.visit.examinedStatus = true;
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Cập nhật thành công'
          );
        } else {
          this.notificationService.showNotification(
            Constant.ERROR,
            res.errors[0].errorMessage
          );
        }
      });
    }
  }
  sendChat2Patient(): void {
    // call GetConversationPrivate
    this.chatService.checkHasConversation(this.visitId).subscribe((res) => {
      if (res.isValid) {
        this.tabDataService.updateTab(
          'chat',
          'Tin nhắn',
          this.patient.fullName,
          this.patient.userId
        );
      } else if (res.errors[0].errorMessage === 'JOIN') {
        const c = confirm(
          'Bạn không thuộc cuộc hội thoại này. Bạn có muốn tham gia không?'
        );
        if (c) {
          this.chatService.joinGroup(res.jsonData.id).subscribe((res2) => {
            this.tabDataService.updateTab(
              'chat',
              'Tin nhắn',
              this.patient.fullName,
              this.patient.userId
            );
          });
        }
      } else {
        this.notificationService.showNotification(
          Constant.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
    /*if(this.patient.userId != null && this.patient.userId != "" && this.patient.userId != "000000000000000000000000") {
      this.chatService.createPrivateChat(this.patient.id).subscribe(res => {
        this.tabDataService.updateTab('chat', 'Tin nhắn', this.patient.fullName, this.patient.userId);
      })
    }
    else {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.WARRING, "Bệnh nhân chưa kích hoạt tài khoản nên chưa thể chat với BN!");
    }*/
  }

  /**
   * @description open record tab
   **/
  async toTabForm(data) {
    await this.addtab(data.recordId, data.name, data.phieudlid);
    this.formid = data.phieudlid;
    this.recordId = data.recordId;
    if (data.listRecord) {
      this.listRecord = data.listRecord;
    }
    this.visitService.changeTab(this.tabs.length - 1);
  }

  setUserRole() {
    switch (this.visitPrivacy) {
      case 1: //All quyền
        break;
      case 3: //BS
        this.disabledClass = 'disabled-class';
        this.hideButton = 'hide-button';
        break;
      case 4: //BN
        this.disabledClass = 'disabled-class';
        this.hideButton = 'hide-button';
        this.isShowChat = 'hide-button';
        break;
      case 5: //CK đã bị đóng
        this.hideButton = 'visit-is-locked';
        break;
    }
  }

  closeTab(data) {
    this.tabs.splice(this.tabs.indexOf(data), 1);
  }

  closeTabMiddle(data) {}

  onTabClick(id) {
    if (id === 'xetnghiem') {
      this.XNTab.getObservationOfPatient();
    } else if (id === 'chiso') {
      this.VSTab.getOTOrder();
      //(this.VSTab).getObservationOfPatient();
    }
  }

  addtab(id, name, phieudlid) {
    //chỉ thêm form
    return new Promise((resolve) => {
      let check = true;
      for (const i in this.tabs) {
        if (this.tabs[i].id === id) {
          this.indexTab = Number(i);
          check = false;
        }
      }
      if (check) {
        this.tabs.push({ id, name, type: 'sub', formId: phieudlid });
        this.cdr.detectChanges();
      }
      resolve(null);
    });
  }

  reloadTab(e) {
    const tabs = this.tabs.filter(
      (e) => e.id == 'thongtincakham' || e.id == 'xetnghiem' || e.id == 'chiso'
    );
    tabs.forEach((tab) => {
      tab.reloadNow = true;
    });
  }

  reloadTabFromXN(e) {
    const tabs = this.tabs.filter(
      (e) => e.id == 'thongtincakham' || e.id == 'chiso'
    );
    tabs.forEach((tab) => {
      tab.reloadNow = true;
    });
  }

  loadVisit(newVisit) {
    this.visit = newVisit;
  }

  resetConfig() {
    this.config = JSON.parse(JSON.stringify(defaultConfig));
    localStorage.removeItem(this.localStorageName);
  }

  onDragEnd(
    columnindex: number,
    e: { gutterNum: number; sizes: Array<number> }
  ) {
    // Column dragged
    if (columnindex === -1) {
      // Set size for all visible columns
      this.config.columns
        .filter((c) => c.visible === true)
        .forEach((column, index) => (column.size = e.sizes[index]));
      // this.caculateNoteTabSize();
    }

    this.saveLocalStorage();
  }

  toggleDisabled() {
    this.config.disabled = !this.config.disabled;
    this.saveLocalStorage();
  }

  saveLocalStorage() {
    localStorage.setItem(this.localStorageName, JSON.stringify(this.config));
  }

  onScroll(e) {
    if (this.chatTab && this.noteTab) {
      this.chatTab.setSize(e.height);
      this.noteTab.setSize(e.height);
      $('.box-notechat').css('top', e.top);
    }
  }

  toggleNote() {
    if (this.config.columns[1].size > 0) {
      this.config.columns[0].size = 100;
      this.config.columns[1].size = 0;
    } else {
      this.config.columns[0].size = 75;
      this.config.columns[1].size = 25;
    }
    this.saveLocalStorage();
  }

  caculateNoteTabSize() {
    const width = $(this.divs.first.nativeElement).width();
    if (width != 0) {
      $(this.divs.last.nativeElement).css('width', width);
    }
  }

  showModalShareVS() {
    this.isShareVisit = true;
  }

  closeModalShareVS() {
    this.isShareVisit = false;
  }

  showVisitLog() {
    this.isVisitLogVisible = true;
  }

  showVideoCall() {
    this.isVideoCall = true;
    this.CreateVideoCall.initPopup();
  }

  closeVideoCall(val) {
    this.isVideoCall = false;
  }

  closeVisitLog() {
    this.isVisitLogVisible = false;
  }

  getPatient() {
    this.patientService.getPatient(this.visit.patientId).subscribe((res) => {
      if (res.isValid) {
        this.patient = res.jsonData;
      }
    });
  }

  sendProfile() {
    if (this.patient.activeStatus == 0) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.WARRING,
        'BN đã nhận được email và kích hoạt tài khoản. Bạn không cần gửi lại nữa!'
      );
    } else if (this.patient.activeStatus == 2) {
      this.modalService.confirm({
        nzTitle: 'Thông báo',
        nzContent: 'Bạn đã gửi thông tin cho BN, bạn có muốn gửi lại không?',
        nzOkText: 'Đồng ý',
        nzCancelText: 'Bỏ qua',
        nzOnOk: () => (this.isSentPatient = true),
      });
    } else {
      this.isSentPatient = true;
    }
  }

  addVisit() {
    this.store.dispatch(new OpenAddVisit('VisitDetail', this.patient, ''));
  }

  getStatus(status) {
    this.profileStatus = status;
  }

  closeProflie() {
    this.isSentPatient = false;
  }
  changeEmail(email) {
    // chưa có api sửa
    //this.patientService.UpdateVaccineHistory
  }

  openModalEditVisitCore() {
    this.isVisibleEditVisitCore = true;
  }

  closeEditVisitCore() {
    this.isVisibleEditVisitCore = false;
  }

  reloadMasterNote(val) {
    this.noteTab.getListNotes();
  }

  toggleVisitMenu() {
    this.isVisibleVisitMenu = !this.isVisibleVisitMenu;
  }

  getVisitHistory(take: number, skip: number) {
    const dataHis = { patientId: this.visit.patientId, take, skip };
    this.visitService.lstVisitHisFast(dataHis).subscribe((data) => {
      this.totalHistory = data.itemCount;
      this.lstHistory = data.source;
      this.isSpinning = false;
    });
  }

  loadVisitById(id: any) {
    /*this.router.navigateByUrl(`common-mobile/worklist/visit/${id}/${this.visit.patientId}`);
    this.isVisibleVisitMenu = false;
    this.visit.id = id;
    this.getVisit(this.visitId);*/
    location.href = `/common-mobile/worklist/visit/${id}`;
  }

  backToPatientList() {
    location.href = `/common-mobile/worklist/patient-list`;
  }

  toggleCollapse(index: number) {
    this.arrCollapse[index] = !this.arrCollapse[index];
  }
  tabSelect(index: number) {
    this.arrTab = [false, false, false, false, false];
    this.arrTab[index] = true;
    if (index === 3) {
      this.reloadNow = true;
    }
  }
  toggleFAB() {
    this.isToggleFAB = !this.isToggleFAB;
  }
}
