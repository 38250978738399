<div class="addPatientAndVisit" *ngIf="isVisibleAddVisit | async as visible">
  <!-- <form [formGroup]="formAddVisit" (ngSubmit)="handleOk()"> -->
  <!-- <nz-modal appModalMovable [modalId]="'add-visit'" nzClassName="add-visit" nzTitle="Thêm ca khám"
      [nzVisible]="visible" [nzWidth]="770" [nzMaskClosable]="false" (nzOnCancel)='handleCancel()'> -->
  <app-m-modal [modalId]="'add-visit'" [isVisible]="visible" class="add-visit" (closeModal)="handleCancel()"
    [mMask]="true" [width]="770" [minWidth]="770" [height]="700" [minHeight]="600" [isResiable]="true">
    <ng-template #mModalTitle>
      <div class="header-title">Thêm ca khám</div>
    </ng-template>
    <ng-template #mModalContent>
      <form [formGroup]="formAddVisit" (ngSubmit)="handleOk()">
        <div class="row" *ngIf="tabId == listVisitComponentName">
          <div class="col-8" style="padding-left: 0px !important">
            <div class="mg-r-30 mg-t-30 k-info-wrapper" style="height: 60px">
              <div class="elm-title">
                <!-- <div>TÌM KIẾM BỆNH NHÂN</div> -->
                <div style="color: #9f9f9f; font-size: 12px; font-size: 500">Tìm kiếm bệnh nhân</div>
                <input #keywordPatient minLength="2" nz-input (input)="onSearchPatient(keywordPatient.value)"
                  [nzAutocomplete]="auto" />
                <nz-autocomplete #auto>
                  <nz-auto-option *ngFor="let item of searchvalue" [nzValue]="item.fullName"
                    (click)="selectSearchPatient(item)">
                    <span>
                      <p>{{ item.fullName }}</p>
                      <i>{{ item.address }}</i>
                    </span>
                  </nz-auto-option>
                </nz-autocomplete>
              </div>
              <!-- <p-autoComplete id="autoCompletePatientName" formControlName="searchPatient" [(ngModel)]="inputValue"
              [suggestions]="options" (completeMethod)="onInput($event)" [minLength]="2" #autoCompletePatientName>
            </p-autoComplete> -->
            </div>
          </div>

          <div class="col-md-4">
            <div class="mg-t-30 p-button-action" (click)="showAddPatient()">
              <span style="margin-right: 15px">Thêm bệnh nhân &ensp;</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20.255" height="20.255" viewBox="0 0 20.255 20.255">
                <path id="Path_247" data-name="Path 247"
                  d="M10.128,0A10.128,10.128,0,1,0,20.255,10.128,10.139,10.139,0,0,0,10.128,0Zm4.431,10.971H10.971v3.587a.844.844,0,0,1-1.688,0V10.971H5.7a.844.844,0,0,1,0-1.688H9.284V5.7a.844.844,0,0,1,1.688,0V9.284h3.587a.844.844,0,0,1,0,1.688Zm0,0"
                  fill="var(--dp-primary-1)" />
              </svg>
            </div>
          </div>
        </div>

        <div class="message-required" *ngIf="submitted && f.patientName.errors && tabId == listVisitComponentName">
          <div *ngIf="f.patientName.errors.required">
            <i nz-icon nzType="close-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#FF0000'"
              style="vertical-align: baseline"></i>
            Bạn chưa chọn Bệnh nhân
          </div>
        </div>
        <div class="row info-card">
          <div class="col-12" style="padding-left: 0px !important">
            <div class="info-header" [ngClass]="tabId == listVisitComponentName ? 'mg-t-30' : 'mg-t-15'">
              Thông tin bệnh nhân
            </div>
          </div>
          <div class="col-12" style="padding-left: 0px !important">
            <div class="info-content">
              <table style="width: 100%" *ngIf="!isMobile">
                <tr>
                  <td>
                    Họ tên :
                    <span class="data-binding">{{ patient ? patient.fullName : '' }}</span>
                  </td>
                  <td>
                    Mã :
                    <span class="data-binding">{{ patient ? patient.code : '' }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    Giới tính :
                    <span class="data-binding">{{ patient ? (patient.sex | genderText) : '' }} </span>
                  </td>
                  <td>
                    Ngày Sinh :
                    <span class="data-binding"> {{ patient ? patient.dobString : '' }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    Số điện thoại :
                    <span class="data-binding">{{ patient ? patient.phoneNo : '' }} </span>
                  </td>
                  <td>
                    Ngày khám gần đây :
                    <span class="data-binding">{{ patient && patient.lastVisitDate ? (patient.lastVisitDate | date:
                      'dd/MM/yyyy HH:mm') : '' }}
                    </span>
                  </td>
                </tr>
                <tr>
                  Địa chỉ :
                  <span class="data-binding">{{ patient ? patient.address : '' }} </span>
                </tr>
              </table>
              <div style="width: 100%" *ngIf="isMobile">
                <div>
                  <div>
                    <svg id="patient" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                      <path id="Path_3131" data-name="Path 3131"
                        d="M13.374,22.362a.8.8,0,0,1-.662-.354l-1.354-2.032H9.8a.8.8,0,0,1,0-1.59h1.988a.8.8,0,0,1,.662.354l.83,1.245,1.773-3.546A.8.8,0,0,1,15.729,16a.784.784,0,0,1,.713.385L18.6,19.976h1.538a.8.8,0,1,1,0,1.59H18.145a.794.794,0,0,1-.682-.386l-1.641-2.734-1.738,3.476a.8.8,0,0,1-.662.438Z"
                        transform="translate(-0.929 -2.362)" fill="var(--dp-primary-1)" />
                      <circle id="Ellipse_140" data-name="Ellipse 140" cx="4" cy="4" r="4" transform="translate(3.247)"
                        fill="var(--dp-primary-1)" />
                      <path id="Path_3132" data-name="Path 3132"
                        d="M5.567,18.362a2.389,2.389,0,0,1,2.386-2.386H9.941a2.384,2.384,0,0,1,1.159.3l.683-1.367a2.372,2.372,0,0,1,1.761-1.268A3.964,3.964,0,0,0,10.338,12H3.976A3.977,3.977,0,0,0,0,15.976v2.585a.6.6,0,0,0,.6.6H5.713A2.359,2.359,0,0,1,5.567,18.362Z"
                        transform="translate(0 -1.543)" fill="var(--dp-primary-1)" />
                    </svg>
                    <span class="data-binding">{{ patient ? patient.fullName : '' }}</span>
                  </div>
                </div>
                <div class="mg-t-10">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 22"
                      fill="var(--dp-primary-1)">
                      <g id="noun_patient_card_3198039" data-name="noun_patient card_3198039"
                        transform="translate(-6 -8.745)">
                        <path id="Path_3443" data-name="Path 3443"
                          d="M19.559,9.414A2.153,2.153,0,0,0,18,8.745a2.242,2.242,0,0,0-2.2,2.277v1.765H8a2.038,2.038,0,0,0-2,2.07V28.675a2.038,2.038,0,0,0,2,2.07H28a2.038,2.038,0,0,0,2-2.07V14.857a2.038,2.038,0,0,0-2-2.07H20.2V11.022A2.3,2.3,0,0,0,19.559,9.414ZM16.8,11.022A1.223,1.223,0,0,1,18,9.78a1.176,1.176,0,0,1,.852.366,1.252,1.252,0,0,1,.348.876v3.669H16.8V11.022Zm11.2,2.8a1.019,1.019,0,0,1,1,1.035V28.675a1.019,1.019,0,0,1-1,1.035H8a1.019,1.019,0,0,1-1-1.035V14.857a1.019,1.019,0,0,1,1-1.035h7.8v.87h-.667a.518.518,0,0,0,0,1.035h5.733a.518.518,0,0,0,0-1.035H20.2v-.87Z" />
                        <path id="Path_3444" data-name="Path 3444"
                          d="M19.773,29.8H17.259V27.286a.532.532,0,0,0-.533-.533H14.67a.532.532,0,0,0-.533.533V29.8H11.623a.532.532,0,0,0-.533.533v2.056a.532.532,0,0,0,.533.533h2.514v2.514a.532.532,0,0,0,.533.533h2.056a.532.532,0,0,0,.533-.533V32.922h2.514a.532.532,0,0,0,.533-.533V30.333A.532.532,0,0,0,19.773,29.8Zm-.533,2.056H16.726a.532.532,0,0,0-.533.533V34.9H15.2V32.389a.532.532,0,0,0-.533-.533H12.156v-.991H14.67a.532.532,0,0,0,.533-.533V27.819h.991v2.514a.532.532,0,0,0,.533.533h2.514Z"
                          transform="translate(-2.38 -8.875)" />
                        <path id="Path_3445" data-name="Path 3445"
                          d="M39.169,31.261a2.554,2.554,0,1,0-2.554-2.554A2.557,2.557,0,0,0,39.169,31.261Zm0-4.043a1.489,1.489,0,1,1-1.488,1.489A1.49,1.49,0,0,1,39.169,27.218Z"
                          transform="translate(-15.557 -8.475)" />
                        <path id="Path_3446" data-name="Path 3446"
                          d="M33.913,40.251a.533.533,0,0,0,.532.555h6.922a.533.533,0,0,0,.532-.555,4,4,0,0,0-7.986,0Zm6.846-.51H35.053a2.932,2.932,0,0,1,5.706,0Z"
                          transform="translate(-14.61 -13.453)" />
                      </g>
                    </svg>
                    <span class="data-binding">{{ patient ? patient.code : '' }}</span>
                  </div>
                </div>
                <div style="display: flex" class="mg-t-10">
                  <div class="col-6">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                      style="enable-background: new 0 0 512 512" xml:space="preserve" fill="var(--dp-primary-1)"
                      width="20px">
                      <g>
                        <g>
                          <path d="M403.921,0v31.347h35.36l-68.982,65.409c-24.421-24.99-58.474-40.53-96.092-40.53c-50.603,0-94.759,28.112-117.687,69.535
                      c-1.964-0.086-3.938-0.138-5.924-0.138c-74.118,0-134.417,60.299-134.417,134.418c0,68.816,51.984,125.71,118.743,133.498v41.657
                      H87.995v31.347h46.929V512h31.347v-45.458h48.977v-31.347h-48.977v-41.657c43.948-5.127,81.488-31.533,102.013-68.616
                      c1.964,0.086,3.937,0.138,5.922,0.138c74.119,0,134.418-60.299,134.418-134.417c0-25.187-6.969-48.774-19.071-68.944
                      l74.919-71.038v38.933h31.347V0H403.921z M150.598,363.11c-56.833,0-103.07-46.237-103.07-103.071
                      c0-54.619,42.705-99.442,96.477-102.853c-2.751,10.7-4.215,21.91-4.215,33.457c0,60.464,40.132,111.726,95.157,128.562
                      C216.281,345.738,185.432,363.11,150.598,363.11z M249.044,290.6c-44.709-11.26-77.906-51.802-77.906-99.957
                      c0-10.636,1.62-20.901,4.625-30.561c44.709,11.26,77.906,51.803,77.906,99.958C253.669,270.676,252.048,280.94,249.044,290.6z
                       M280.801,293.495c2.751-10.7,4.215-21.909,4.215-33.456c0-60.464-40.132-111.726-95.156-128.563
                      c18.666-26.532,49.516-43.905,84.349-43.905c56.834,0,103.071,46.237,103.071,103.071
                      C377.278,245.261,334.573,290.085,280.801,293.495z" />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                    <span class="data-binding">{{ patient ? (patient.sex | genderText) : '' }} </span>
                  </div>
                  <div class="col-6">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                      fill="var(--dp-primary-1)">
                      <g id="noun_date_4128628" transform="translate(-4.942 -3.8)">
                        <path id="Path_3447" data-name="Path 3447"
                          d="M23.779,7.46a.954.954,0,0,0,.954-.955V4.755a.954.954,0,1,0-1.909,0v1.75A.954.954,0,0,0,23.779,7.46Z"
                          transform="translate(-13.107)" />
                        <path id="Path_3448" data-name="Path 3448"
                          d="M47.262,7.46a.955.955,0,0,0,.954-.955V4.755a.955.955,0,0,0-1.91,0v1.75A.955.955,0,0,0,47.262,7.46Z"
                          transform="translate(-30.319)" />
                        <path id="Path_3449" data-name="Path 3449"
                          d="M70.747,7.46a.955.955,0,0,0,.956-.955V4.755a.955.955,0,0,0-1.91,0v1.75A.955.955,0,0,0,70.747,7.46Z"
                          transform="translate(-47.535)" />
                        <path id="Path_3450" data-name="Path 3450"
                          d="M25.242,9.947v1.109a2.045,2.045,0,0,1-4.089,0V9.933H18.988v1.123a2.046,2.046,0,0,1-4.091,0V9.933H12.732v1.123a2.046,2.046,0,0,1-4.091,0V9.947c-2.067.136-3.7,1.562-3.7,3.3V30.558a1.654,1.654,0,0,0,1.846,1.788H27.1a1.654,1.654,0,0,0,1.846-1.788V13.247C28.943,11.509,27.309,10.083,25.242,9.947Zm2.136,20.612a2.145,2.145,0,0,1-.017.228.74.74,0,0,1-.264.033H6.789a.736.736,0,0,1-.264-.033,1.892,1.892,0,0,1-.018-.228V15.809h20.87Z"
                          transform="translate(0 -4.546)" />
                        <path id="Path_3451" data-name="Path 3451"
                          d="M23.838,46.839a.821.821,0,0,1-.821.821H21.787a.821.821,0,0,1-.821-.821V45.608a.821.821,0,0,1,.821-.82h1.23a.821.821,0,0,1,.821.82v1.231Z"
                          transform="translate(-11.739 -30.336)" />
                        <path id="Path_3452" data-name="Path 3452"
                          d="M47.324,46.839a.819.819,0,0,1-.819.821H45.273a.819.819,0,0,1-.819-.821V45.608a.819.819,0,0,1,.819-.82H46.5a.819.819,0,0,1,.819.82Z"
                          transform="translate(-28.946 -30.336)" />
                        <path id="Path_3453" data-name="Path 3453"
                          d="M70.806,46.839a.821.821,0,0,1-.821.821h-1.23a.821.821,0,0,1-.821-.821V45.608a.821.821,0,0,1,.821-.82h1.23a.821.821,0,0,1,.821.82Z"
                          transform="translate(-46.148 -30.336)" />
                        <path id="Path_3454" data-name="Path 3454"
                          d="M23.838,69.038a.821.821,0,0,1-.821.821H21.787a.821.821,0,0,1-.821-.821V67.807a.821.821,0,0,1,.821-.82h1.23a.821.821,0,0,1,.821.82v1.231Z"
                          transform="translate(-11.739 -46.766)" />
                        <path id="Path_3455" data-name="Path 3455"
                          d="M47.324,69.038a.819.819,0,0,1-.819.821H45.273a.819.819,0,0,1-.819-.821V67.807a.819.819,0,0,1,.819-.82H46.5a.819.819,0,0,1,.819.82Z"
                          transform="translate(-28.946 -46.766)" />
                        <path id="Path_3456" data-name="Path 3456"
                          d="M70.806,69.038a.821.821,0,0,1-.821.821h-1.23a.821.821,0,0,1-.821-.821V67.807a.821.821,0,0,1,.821-.82h1.23a.821.821,0,0,1,.821.82Z"
                          transform="translate(-46.148 -46.766)" />
                      </g>
                    </svg>
                    <span class="data-binding"> {{ patient ? patient.dobString : '' }}</span>
                  </div>
                </div>
                <div class="mg-t-10">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                      style="margin-right: 8px; fill: var(--dp-primary-1)">
                      <path id="Path_2258" data-name="Path 2258"
                        d="M6.218,9.924a13.465,13.465,0,0,0,5.858,5.858l1.956-1.956a.884.884,0,0,1,.907-.213,10.14,10.14,0,0,0,3.173.507.892.892,0,0,1,.889.889v3.1a.892.892,0,0,1-.889.889A15.11,15.11,0,0,1,3,3.889.892.892,0,0,1,3.889,3H7a.892.892,0,0,1,.889.889A10.1,10.1,0,0,0,8.4,7.062a.892.892,0,0,1-.222.907Z"
                        transform="translate(-3 -3)" fill="var(--dp-primary-1)" />
                    </svg>
                    <span class="data-binding">{{ patient ? patient.phoneNo : '' }} </span>
                  </div>
                </div>
                <div class="mg-t-10">
                  <div>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                      style="enable-background: new 0 0 512 512" xml:space="preserve"
                      style="margin-right: 8px; fill: var(--dp-primary-1); stroke: var(--dp-primary-1); width: 16px">
                      <g>
                        <g>
                          <g>
                            <circle cx="386" cy="210" r="20" />
                            <path d="M432,40h-26V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v20h-91V20c0-11.046-8.954-20-20-20
                          c-11.046,0-20,8.954-20,20v20h-90V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v20H80C35.888,40,0,75.888,0,120v312
                          c0,44.112,35.888,80,80,80h153c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20H80c-22.056,0-40-17.944-40-40V120
                          c0-22.056,17.944-40,40-40h25v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h90v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h91
                          v20c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80h26c22.056,0,40,17.944,40,40v114c0,11.046,8.954,20,20,20
                          c11.046,0,20-8.954,20-20V120C512,75.888,476.112,40,432,40z" />
                            <path d="M391,270c-66.72,0-121,54.28-121,121s54.28,121,121,121s121-54.28,121-121S457.72,270,391,270z M391,472
                          c-44.663,0-81-36.336-81-81s36.337-81,81-81c44.663,0,81,36.336,81,81S435.663,472,391,472z" />
                            <path d="M420,371h-9v-21c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v41c0,11.046,8.954,20,20,20h29
                          c11.046,0,20-8.954,20-20C440,379.954,431.046,371,420,371z" />
                            <circle cx="299" cy="210" r="20" />
                            <circle cx="212" cy="297" r="20" />
                            <circle cx="125" cy="210" r="20" />
                            <circle cx="125" cy="297" r="20" />
                            <circle cx="125" cy="384" r="20" />
                            <circle cx="212" cy="384" r="20" />
                            <circle cx="212" cy="210" r="20" />
                          </g>
                        </g>
                      </g>
                    </svg>
                    <span class="data-binding">{{ patient && patient.lastVisitDate ? (patient.lastVisitDate | date:
                      'dd/MM/yyyy HH:mm') : '' }}
                    </span>
                  </div>
                </div>
                <div class="mg-t-10">
                  <svg id="home-52" xmlns="http://www.w3.org/2000/svg" width="17.137" height="16.857"
                    viewBox="0 0 17.137 16.857" style="margin-right: 8px; fill: var(--dp-primary-1)">
                    <g id="Group_2922" data-name="Group 2922" transform="translate(0 0)">
                      <path id="Path_2256" data-name="Path 2256"
                        d="M10.671,5.951,4,11.67v7.652a.556.556,0,0,0,.556.556H9V15.43H12.34v4.448h4.448a.556.556,0,0,0,.556-.556V11.671Zm1.669,7.255H9v-2.78H12.34Z"
                        transform="translate(-2.104 -3.022)" fill="var(--dp-primary-1)" />
                      <path id="Path_2257" data-name="Path 2257"
                        d="M9.157.683,5.266,4.019V1.971H3.042V5.925L.59,8.027l.724.844L9.157,2.148,17,8.871l.724-.844Z"
                        transform="translate(-0.59 -0.683)" fill="var(--dp-primary-1)" />
                    </g>
                  </svg>
                  <span class="data-binding">{{ patient ? patient.address : '' }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4" style="padding-left: 0px !important">
            <div class="message-required" *ngIf="submitted && f.visitDate.errors">
              <div *ngIf="f.visitDate.errors.required">
                <i nz-icon nzType="close-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#FF0000'"
                  style="vertical-align: baseline"></i>
                Bạn chưa chọn Ngày khám
              </div>
            </div>

            <div class="message-required" *ngIf="submitted && checkDate">
              <div *ngIf="checkDate">
                <i nz-icon nzType="close-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#FF0000'"
                  style="vertical-align: baseline"></i>
                Ngày khám không hợp lệ
              </div>
            </div>

            <div class="mg-r-30 mg-t-30 k-info-wrapper" style="height: 65px">
              <div class="elm-title">NGÀY KHÁM (<span class="d-note"> * </span>)</div>
              <!-- <dp-date-picker theme="dp-material" mode="daytime" [config]="datePickerConfig" formControlName="visitDate"
            required="true">
          </dp-date-picker> -->
              <!-- <p-calendar formControlName="visitDate" [showTime]="true" [baseZIndex]="11001"></p-calendar> -->
              <!-- <nz-date-picker formControlName="visitDate"  [nzPlaceHolder]="'DD/mm/YYYY hh:MM'" [nzFormat]="dateFormat" nzShowTime="true" [nzDisabledDate]="disabledDateAfterToday"></nz-date-picker> -->
              <nz-date-picker formControlName="visitDate" [nzPlaceHolder]="'DD/MM/YYYY hh:MM'"
                [nzFormat]="datetimeFormat" nzShowTime="true"></nz-date-picker>
              <br />
              <!-- <input formControlName="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" /> -->
            </div>
          </div>

          <div class="col-md-4">
            <div class="message-required" *ngIf="submitted && f.doctorId.errors">
              <div *ngIf="f.doctorId.errors.required">
                <i nz-icon nzType="close-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#FF0000'"
                  style="vertical-align: baseline"></i>
                Bạn chưa chọn Bác sĩ khám
              </div>
            </div>
            <div class="mg-r-30 mg-t-30 k-info-wrapper">
              <div class="elm-title">BÁC SĨ KHÁM (<span class="d-note"> * </span>)</div>
              <nz-select [nzPlaceHolder]="'Chọn bác sĩ khám'" formControlName="doctorId"
                [ngClass]="{ 'is-invalid': submitted && f.doctorId.errors }">
                <!-- <nz-option [nzLabel]="'Chọn bác sĩ khám'" [nzValue]="0">  </nz-option>-->
                <nz-option *ngFor="let item of listDoctorInCase" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>

          <div *ngIf="lstPermissionDefault[1].checked" class="col-md-4">
            <div class="mg-r-30 mg-t-30 k-info-wrapper" style="height: 65px">
              <div class="elm-title">BÁC SĨ PHỤ TRÁCH</div>
              <nz-select [nzPlaceHolder]="'Chọn bác sĩ phụ trách'" formControlName="doctorInCareDoct"
                [ngClass]="{ 'is-invalid': submitted && f.doctorId.errors }">
                <nz-option *ngFor="let item of listDoctorInCase" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4" style="padding-left: 0px !important">
            <div class="mg-r-30 mg-t-30 k-info-wrapper" style="height: 65px">
              <div class="elm-title">NGÀY PHÁT HIỆN BỆNH</div>
              <nz-date-picker formControlName="dateOfDiagnosis" [nzPlaceHolder]="'DD/MM/YYYY'" [nzFormat]="dateFormat">
              </nz-date-picker>
              <br />
            </div>
          </div>
          <div class="col-md-4">
            <div class="mg-r-30 mg-t-30 k-info-wrapper" style="height: 65px">
              <div class="elm-title">NGÀY KHỞI PHÁT TRIỆU CHỨNG</div>
              <nz-date-picker formControlName="dateOfSymptom" [nzPlaceHolder]="'DD/MM/YYYY'" [nzFormat]="dateFormat">
              </nz-date-picker>
              <br />
            </div>
          </div>

          <div *ngIf="lstPermissionDefault[4].checked" class="col-md-4">
            <div class="mg-r-30 mg-t-30 k-info-wrapper" style="height: 65px">
              <div class="elm-title">ĐIỀU DƯỠNG PHỤ TRÁCH</div>
              <nz-select [nzPlaceHolder]="'Chọn điều dưỡng phụ trách'" formControlName="nursingInCare"
                [ngClass]="{ 'is-invalid': submitted && f.doctorId.errors }">
                <nz-option *ngFor="let item of listNursingInCase" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngIf="lstPermissionDefault[5].checked" class="col-md-4" style="padding-left: 0px !important">
            <div class="mg-r-30 mg-t-30 k-info-wrapper" style="height: 65px">
              <div class="elm-title">TÌNH NGUYỆN VIÊN</div>
              <nz-select [nzPlaceHolder]="'Chọn tình nguyện viên'" formControlName="volunteer"
                [ngClass]="{ 'is-invalid': submitted && f.doctorId.errors }">
                <nz-option *ngFor="let item of listVolunteer" [nzLabel]="item.fullName" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
          </div>
          <div class="col-md-8"></div>
        </div>
        <div class="row">
          <div class="col-md-4" style="padding-left: 0px !important">
            <div class="message-required" *ngIf="submitted && f.type.errors">
              <div *ngIf="f.type.errors.pattern || f.type.errors.required">
                <i nz-icon nzType="close-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#FF0000'"
                  style="vertical-align: baseline"></i>
                Bạn chưa chọn Loại khám
              </div>
            </div>
            <div class="mg-r-30 mg-t-30 k-info-wrapper">
              <div class="elm-title">LOẠI KHÁM (<span class="d-note"> * </span>)</div>
              <nz-select [nzPlaceHolder]="'Chọn yêu cầu khám'" nz-menu formControlName="type"
                [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                <nz-option nzValue="2" nzLabel="Điều trị"></nz-option>
                <nz-option nzValue="1" nzLabel="Khám bệnh"></nz-option>
                <nz-option nzValue="3" nzLabel="Can thiệp"></nz-option>
              </nz-select>
            </div>
          </div>

          <div class="col-md-8">
            <div class="message-required" *ngIf="submitted && f.reason.errors">
              <div *ngIf="f.reason.errors.required">
                <i nz-icon nzType="close-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#FF0000'"
                  style="vertical-align: baseline"></i>
                Bạn chưa nhập Lý do khám
              </div>
              <div *ngIf="f.reason.errors.duplicated">
                <i nz-icon nzType="close-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#FF0000'"
                  style="vertical-align: baseline"></i>
                Lý do khám không được để trắng
              </div>
            </div>
            <div class="mg-r-30 mg-t-30 k-info-wrapper">
              <div class="elm-title">LÝ DO KHÁM (<span class="d-note"> * </span>)</div>
              <textarea rows="1" formControlName="reason" [ngClass]="{ 'is-invalid': submitted && f.reason.errors }">
              </textarea>
              <!-- <input formControlName="fullName" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" /> -->
            </div>
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template #mModalFooter>
      <button nz-button class="btn-primary-large" (click)="handleOk()" [nzLoading]="isLoading">Thêm ca khám</button>
      <button nz-button class="btn-cancel-large" (click)="handleCancel()">Quay lại</button>
    </ng-template>
    <!-- <div *nzModalFooter>
        <button class="button-submit btn-cancel-large" (click)="handleCancel()">Quay lại</button>
        <button nz-button class="btn-primary-large" (click)="handleOk()" [nzLoading]="isLoading">Thêm ca khám</button>
      </div> -->
    <!-- </nz-modal> -->
  </app-m-modal>
  <!-- </form> -->
</div>