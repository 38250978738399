import { Component, Input, OnInit } from '@angular/core';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-print-template',
  templateUrl: './print-template.component.html',
  styleUrls: ['./print-template.component.scss'],
})
export class PrintTemplateComponent implements OnInit {
  @Input() config = 0;

  @Input() visit;
  @Input() printHeader;
  @Input() isUseDeliveryInfo = false;

  _prescription;
  _data;
  @Input()
  set prescription(value: any) {
    if (!this.shareService.checkEmpty(value)) {
      this._prescription = value;
    }
  }
  get prescription() {
    return this._prescription;
  }

  @Input()
  set data(value: any) {
    if (!this.shareService.checkEmpty(value)) {
      this._data = value;
      this.functionalFoods= [];
      this.medicines= [];
      value.forEach((el)=>{
        if(el.IsFunctionalFoods){
          this.functionalFoods.push(el);
        }
        else{
          this.medicines.push(el);
        }
      })
    }
  }
  get data() {
    return this._data;
  }

  templateDrugList = Constant.DEFAULT_TEMPLATE_DRUG_LIST;
  VISIT_AGAIN_UNIT = Constant.VISIT_AGAIN_UNIT;
  PRESCRIPTION_NOTE = Constant.PRESCRIPTION_NOTE;
  templateConstant = Constant.TEMPLATE_DRUG_LIST;
  dateFormat = 'dd/MM/yyyy';
  functionalFoods = [];
  medicines = [];
  constructor(private shareService: ShareService) {}

  ngOnInit(): void {}
  getAmount(amount, existingAmount) {
    if (!this.shareService.checkEmpty(existingAmount)) {
      const numb = amount - existingAmount;
      if (numb > 0) {
        return numb;
      } else {
        return 0;
      }
    } else {
      return amount;
    }
  }
  convertTextbox2Html(val: string) {
    if (val == undefined || val == null) {
      return '';
    }
    return val.split('\n').join('<br/>');
  }
}
