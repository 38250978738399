import { Patient } from 'src/app/models/patient.class';
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export enum ModalAction {
  OpenAddPatient = '[AddPatient] Open',
  CloseAddPatient = '[AddPatient] Close',
  ToggleAddPatient = '[AddPatient] Toggle',
  OpenAddVisit = '[AddVisit] Open',
  CloseAddVisit = '[AddVisit] Close',
  ToggleAddVisit = '[AddVisit] Toggle',
  OpenDuplicatePatient = '[DuplicatePatient] Open',
  CloseDuplicatePatient = '[DuplicatePatient] Close',
  ToggleDuplicatePatient = '[DuplicatePatient] Toggle',
  OpenAddAppointment = '[AddAppointment] Open',
  CloseAddAppointment = '[AddAppointment] Close',
  ToggleAddAppointment = '[AddAppointment] Toggle',
  ConvertAppointmentToVisit = '[AddAppointment] Convert',
  OpenAddVisitUpdate = '[AddVisitUpdate] Open',
  CloseAddVisitUpdate = '[AddVisitUpdate] Close',
}

export class OpenAddPatient implements Action {
  public readonly type = ModalAction.OpenAddPatient;
}

export class CloseAddPatient implements Action {
  public readonly type = ModalAction.CloseAddPatient;
}

export class OpenAddVisit implements Action {
  constructor(
    public _tabId: string,
    public _patient: any,
    public _appointmentId: string
  ) {}
  public readonly type = ModalAction.OpenAddVisit;
  public readonly tabId = this._tabId;
  public readonly patient = this._patient;
  public readonly appointmentId = this._appointmentId;
}

export class CloseAddVisit implements Action {
  public readonly type = ModalAction.CloseAddVisit;
}

export class OpenDuplicatePatient implements Action {
  public readonly type = ModalAction.OpenDuplicatePatient;
}

export class CloseDuplicatePatient implements Action {
  public readonly type = ModalAction.CloseDuplicatePatient;
}

export class OpenAddAppointment implements Action {
  public readonly type = ModalAction.OpenAddAppointment;
}

export class CloseAddAppointment implements Action {
  public readonly type = ModalAction.CloseAddAppointment;
}
export class ConvertAppointmentToVisit implements Action {
  public readonly type = ModalAction.ConvertAppointmentToVisit;
  constructor(public payload: Patient) {}
}

export class OpenAddVisitUpdate implements Action {
  constructor(
    public _tabId: string,
    public _patient: any,
    public _appointmentId: string
  ) {}
  public readonly type = ModalAction.OpenAddVisitUpdate;
  public readonly tabId = this._tabId;
  public readonly patient = this._patient;
  public readonly appointmentId = this._appointmentId;
}

export class CloseAddVisitUpdate implements Action {
  public readonly type = ModalAction.CloseAddVisitUpdate;
}

export type ModalActions =
  | OpenAddPatient
  | CloseAddPatient
  | OpenAddVisit
  | CloseAddVisit
  | OpenDuplicatePatient
  | CloseDuplicatePatient
  | OpenAddAppointment
  | CloseAddAppointment
  | ConvertAppointmentToVisit
  | OpenAddVisitUpdate
  | CloseAddVisitUpdate;
