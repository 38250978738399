import { Pipe, PipeTransform } from '@angular/core';
import { ShareService } from 'src/app/services/share.service';
@Pipe({
  name: 'amountCalc',
})
export class AmountCalcPipe implements PipeTransform {
  constructor(public shareService: ShareService) {}
  transform(price, amount, discount = null) {
    return this.getTotalMoney(price, amount, discount);
  }
  getTotalMoney(price, amount, discount) {
    let total = 0;
    if (!this.shareService.checkEmpty(price)) {
      total = price * amount;
    }
    if (discount != null) {
      total = total - discount;
    }
    return total;
  }
}

@Pipe({
  name: 'discountStr',
})
export class DiscountStrPipe implements PipeTransform {
  constructor(public shareService: ShareService) {}
  transform(discount) {
    return this.getDiscountString(discount);
  }
  getDiscountString(discount) {
    let str = '';
    if (!this.shareService.checkEmpty(discount) && discount != '0') {
      str = 'Giảm giá ' + discount + ' VNĐ';
    }
    return str;
  }
}
