import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  ɵConsole,
  Input,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Md5 } from 'ts-md5/dist/md5';
import { PatientService } from 'src/app/services/patient.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { DoctorService } from 'src/app/services/doctor.service';

@Component({
  selector: 'app-member-registration',
  templateUrl: './member-registration.component.html',
  styleUrls: ['./member-registration.component.scss'],
})
export class MemberRegistrationComponent implements OnInit {
  @Output() eventRegist = new EventEmitter<any>();
  @Input() email: string;
  registrationForm: FormGroup;
  submitted = false;
  passwordVisible: boolean;
  repeatpasswordVisible: boolean;
  checkPassword = true;
  checked: boolean;
  md5 = new Md5();
  doctorRanks: any[] = [];

  constructor(
    private fb: FormBuilder,
    private patientService: PatientService,
    private doctorService: DoctorService
  ) {}

  disabledDateAfterToday = this.patientService.disabledDateAfterToday;

  ngOnInit(): void {
    //console.log(this.email);
    this.registrationForm = this.fb.group(
      {
        fullname: [
          null,
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(100),
          ],
        ],
        email: [
          this.email,
          [
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
          ],
        ],
        phoneNo: [null, [Validators.required]],
        dob: [null],
        address: [null],
        username: [
          null,
          [Validators.required, Validators.pattern('^[a-z0-9]{4,}$')],
        ],
        password: [
          null,
          [Validators.required, Validators.pattern(/(^\S.{6,}(\S)$)/)],
        ],
        repeatPassword: [
          null,
          [Validators.required, Validators.pattern(/(^\S.{6,}(\S)$)/)],
        ],
        doctorRank: [null],
      },
      { validators: this.checkPasswords }
    );
    this.getListDoctorRank();
  }

  getListDoctorRank() {
    this.doctorService.getListDoctorRank().subscribe((res) => {
      this.doctorRanks = res;
    });
  }

  confirmPassword = (control: FormControl) => {
    if (!control.value) {
      return { require: true };
    } else if (control.value !== this.registrationForm.value.password) {
      return { confirm: true, error: true };
    }
    return {};
  };

  checkPasswords: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    if (group != undefined) {
      const pass = group.get('password').value;
      const repeatPass = group.get('repeatPassword').value;
      return pass === repeatPass ? null : { confirm: true, error: true };
    }
  };

  get f() {
    return this.registrationForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    const formValue = this.registrationForm.value;
    if (this.registrationForm.valid && this.checked) {
      const payload = {
        fullname: formValue.fullname,
        email: formValue.email,
        phoneNo: formValue.phoneNo,
        dob: formValue.dob,
        address: formValue.address,
        username: formValue.username,
        password: formValue.password,
        repeatPassword: formValue.repeatPassword,
        doctorRankId:
          formValue.doctorRank != null ? formValue.doctorRank.id : '',
        doctorRankName:
          formValue.doctorRank != null ? formValue.doctorRank.name : '',
      };
      //formValue.password = this.md5.appendStr(formValue.password).end();
      this.eventRegist.emit(payload);
    }
  }
}
