import { ShareService } from 'src/app/services/share.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Patient } from '../../../models/patient.class';
import { DoctorInGroup } from '../../../models/doctor-in-group.model'; //api
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable, Observer, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state/app-state';
import { OpenAddVisit, CloseAddVisit, OpenAddPatient } from '../redux/modal/modal.action';
import { DoctorService } from 'src/app/services/doctor.service';
import { VisitService } from 'src/app/services/visit.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { NotificationService } from 'src/app/services/notification.service';
import { PatientService } from 'src/app/services/patient.service';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { MedicalAppointmentService } from 'src/app/services/medical-appointment.service';

@Component({
  selector: 'app-add-visit',
  templateUrl: './add-visit.component.html',
  styleUrls: ['./add-visit.component.scss'],
})
export class AddVisitComponent implements OnInit, OnChanges, OnDestroy {
  isVisibleAddVisit: Observable<boolean>;
  @Input() patient: Patient;
  @Output() refreshListVisit: EventEmitter<any> = new EventEmitter();
  @Output() selectPatient: EventEmitter<any> = new EventEmitter();
  isMobile: boolean = window.innerWidth < 540;
  doctorInGroup: any[] = [];
  selectedDate: any;
  formAddVisit: FormGroup;
  submitted = false;
  options: string[] = [];
  inputValue?: string;
  searchvalue;
  datetimeFormat = 'dd/MM/yyyy HH:mm';
  dateFormat = 'dd/MM/yyyy';
  curUserId = '';
  groupSub: Subscription;
  groupId: string;
  datePickerConfig = {
    disabled: false,
  };
  checkDate: Boolean = false;
  isLoading = false;
  tabId: string;
  appointmentId: string;
  isClinic = false;
  listVisitComponentName = 'ListVisit';
  lstPermissionDefault = [
    {
      name: 'Điều phối viên',
      type: 1,
      checked: false,
    },
    {
      name: 'Bác sĩ phụ trách',
      type: 2,
      checked: false,
    },
    {
      name: 'Dược sĩ lâm sàng',
      type: 3,
      checked: false,
    },
    {
      name: 'Chuyên viên tư vấn',
      type: 4,
      checked: false,
    },
    {
      name: 'Điều dưỡng phụ trách',
      type: 5,
      checked: false,
    },
    {
      name: 'Tình nguyện viên',
      type: 10,
      checked: false,
    },
  ];
  listDoctorInCase: any[] = [];
  listNursingInCase: any[] = [];
  listVolunteer: any[] = [];
  doctorPermissions: any[] = [];

  timer: any;

  constructor(
    private store: Store<AppState>,
    private doctorService: DoctorService,
    private visitService: VisitService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private tabDataService: TabDataService,
    private shareService: ShareService,
    private medicalService: MedicalAppointmentService,
    private cd: ChangeDetectorRef
  ) {
    try {
      this.store
        .select((store) => store.auth.userId)
        .subscribe((res) => {
          this.curUserId = res;
        });
    } catch (error) {
      console.log(error);
    }

    this.formAddVisit = this.formBuilder.group({
      searchPatient: [''],
      visitId: [null],
      personId: [null],
      groupId: [null],
      doctorId: [this.curUserId, [Validators.required, Validators.minLength(24), Validators.maxLength(24)]],
      visitDate: [new Date(), [Validators.required]],
      reason: ['', [Validators.required], [this.emptyAsyncValidator]],
      patientName: ['', [Validators.required, Validators.minLength(0)]],
      creatorId: [null],
      type: ['1', [Validators.required]],
      ros: [''],
      dateOfDiagnosis: [null],
      dateOfSymptom: [null],
      doctorInCareDoct: [null],
      nursingInCare: [null],
      volunteer: [null],
    });
    this.isVisibleAddVisit = this.store.select((store) => store.modals.isVisitOpen);
    this.store
      .select((store) => store.modals.tabId)
      .subscribe((data) => {
        if (data == this.listVisitComponentName) {
          this.patient = {};
        }
        this.tabId = data;
      });
    this.store
      .select((store) => store.modals.patient)
      .subscribe((data) => {
        this.patient = data;
        this.formAddVisit.controls.patientName.setValue(data.fullName);
      });
    this.store
      .select((store) => store.modals.appointmentId)
      .subscribe((data) => {
        this.appointmentId = data;
      });
  }

  // public style: object = {};

  // validate(event: ResizeEvent): boolean {
  //   const MIN_DIMENSIONS_PX: number = 50;
  //   if (
  //     event.rectangle.width &&
  //     event.rectangle.height &&
  //     (event.rectangle.width < MIN_DIMENSIONS_PX ||
  //       event.rectangle.height < MIN_DIMENSIONS_PX)
  //   ) {
  //     return false;
  //   }
  //   return true;
  // }

  // onResizeEnd(event: ResizeEvent): void {
  //   console.log(event);
  //   this.style = {
  //     // position: 'fixed',
  //     // left: `${event.rectangle.left}px`,
  //     // top: `${event.rectangle.top}px`,
  //     width: `${event.rectangle.width}px`,
  //     height: `${event.rectangle.height}px`
  //   };
  // }

  disabledDateAfterToday = this.patientService.disabledDateAfterToday;

  get f() {
    return this.formAddVisit.controls;
  }

  ngOnInit(): void {
    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.groupId = data.groupId;
        this.getDoctorInGroup(this.groupId);
        this.convertToPermissionItem(data.doctorPermissions);
        this.doctorPermissions = data.doctorPermissions; //các permission được config
      }
    });
  }

  convertToPermissionItem(values) {
    if (this.shareService.checkEmpty(values)) {
      return;
    }
    this.lstPermissionDefault.map((t) => (t.checked = false));
    values.forEach((element) => {
      const curPermission = this.lstPermissionDefault.filter((t) => t.type == element);
      if (curPermission.length) {
        curPermission[0].checked = true;
      }
    });
  }

  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;

        // nếu có check config BS => filter
        if (this.doctorPermissions != null && this.doctorPermissions.includes(Constant.DOCTORPERMISSION.Doctor)) {
          this.listDoctorInCase = this.doctorInGroup.filter(
            (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Doctor)
          );
        } else {
          // nếu không => hiển thị hết user
          this.listDoctorInCase = this.doctorInGroup;
        }

        this.listNursingInCase = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Nursing)
        );

        this.listVolunteer = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Volunteer)
        );
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const chng = changes[propName];
      const cur = JSON.stringify(chng.currentValue);
      const prev = JSON.stringify(chng.previousValue);
      if (cur != prev) {
        this.formAddVisit.controls.patientName.setValue(this.patient.fullName);
      }
    }
  }

  ngOnDestroy() {
    this.groupSub.unsubscribe();
  }

  checkNull(value) {
    try {
      value = value.trim();
      if (value == null || value == '' || value == 'undefined') {
        return true;
      }
      return false;
    } catch (e) {
      return true;
    }
  }

  handleOk(): void {
    this.submitted = true;
    if (this.patient.id) {
      //thêm ca khám cho bệnh nhân
      if (this.formAddVisit.valid) {
        this.isLoading = true;
        const payload = {
          visitId: '000000000000000000000000',
          personId: this.patient.id,
          groupId: this.groupId, // localStorage.getItem(Constant.CURRENT_GROUPID),
          doctorId: this.formAddVisit.value.doctorId,
          visitDate: this.formAddVisit.value.visitDate,
          reason: this.formAddVisit.value.reason,
          type: parseInt(this.formAddVisit.value.type),
          appointmentId: this.appointmentId,
          dateOfDiagnosis: this.formAddVisit.value.dateOfDiagnosis,
          dateOfSymptom: this.formAddVisit.value.dateOfSymptom,
          doctorInCareDoct: this.formAddVisit.value.doctorInCareDoct,
          nursingInCare: this.formAddVisit.value.nursingInCare,
          volunteer: this.formAddVisit.value.volunteer,
        };
        // if (new Date().getTime() - payload.visitDate.getTime() < 0) {
        //   this.checkDate = true;
        //   this.isLoading = false;
        //   return;
        // } else {
        this.checkDate = false;
        this.visitService.addVisitOnly(payload).subscribe((res) => {
          if (res.isValid) {
            this.submitted = false;
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, Constant.MESSAGE_ADD_SUCCESS);
            this.store.dispatch(new CloseAddVisit());

            this.formAddVisit.reset();
            this.resetDefaultValue();
            this.isLoading = false;
            if (this.isMobile) {
              location.href = 'common-mobile/worklist/visit/' + res.jsonData.id;
            } else {
              // this.tabDataService.updateTab(
              //   'ListVisitContainer',
              //   'Danh sách ca khám',
              //   '',
              //   ''
              // );
              this.tabDataService.updateTab(
                res.jsonData.id,
                res.jsonData.patientName,
                'VisitDetail',
                res.jsonData.patientId
              );

              this.refreshListVisit.emit(); //gửi trigger lên load lại listvisit
            }
            this.patient = null;

            //callback update Appoitment
            // if(!this.shareService.checkEmpty(this.appointmentId)){
            //   this.addVisitToAppointment(res.jsonData.id);
            // }
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Lỗi khi thêm!');
          }
        });
        // }
      }
    } else {
      //thêm mới ca khám và bệnh nhân
      if (this.formAddVisit.valid) {
        this.isLoading = true;
        const payload = {
          fullName: this.patient.fullName,
          visitId: '000000000000000000000000',
          code: this.patient.code,
          dob: this.patient.dob,
          dobString: this.patient.dobString,
          sex: parseInt(this.patient.sex),
          idNumber: this.patient.idNumber,
          phoneNo: this.patient.phoneNo,
          email: this.patient.email,
          address: this.patient.address,
          avatar: this.patient.avatar,
          bhytCode: this.patient.BHYTCode,
          validDate: this.patient.validDate,
          expireDate: this.patient.expireDate,
          groupId: this.groupId,
          doctorId: this.formAddVisit.value.doctorId,
          visitDate: this.formAddVisit.value.visitDate,
          reason: this.formAddVisit.value.reason,
          dateOfDiagnosis: this.formAddVisit.value.dateOfDiagnosis,
          dateOfSymptom: this.formAddVisit.value.dateOfSymptom,
          doctorInCareDoct: this.formAddVisit.value.doctorInCareDoct,
          nursingInCare: this.formAddVisit.value.nursingInCare,
          volunteer: this.formAddVisit.value.volunteer,
          type: parseInt(this.formAddVisit.value.type),
          additionImages: this.patient.imageUrls ? this.patient.imageUrls : null,
          mainDoctor: null,
          presenterDoctor: null,
          appointmentId: this.appointmentId,
        };
        this.visitService.addVisitContainPatient(payload).subscribe((res) => {
          if (res.isValid) {
            const patientName = this.patient.fullName;
            this.checkDate = false;
            this.submitted = false;
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, Constant.MESSAGE_ADD_SUCCESS);

            this.formAddVisit.reset();
            this.resetDefaultValue();

            this.patient = null;
            this.isLoading = false;

            //callback update Appoitment
            // if(!this.shareService.checkEmpty(this.appointmentId)){
            //   this.addVisitToAppointment(res.jsonData.id);
            // }
            this.store.dispatch(new CloseAddVisit());
            if (this.isMobile) {
              location.href = 'common-mobile/worklist/visit/' + res.jsonData;
            } else {
              this.tabDataService.updateTab(res.jsonData.id, res.jsonData.patientName, 'VisitDetail', res.jsonData.id);
              this.refreshListVisit.emit(); //gửi trigger lên load lại listvisit
            }
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Lỗi khi thêm!');
          }
        });
      }
    }
  }

  // addVisitToAppointment(visitId){
  //   this.medicalService.addVisitToAppointment({visitId: visitId, appointmentId: this.appointmentId}).subscribe(res => {

  //   })
  // }
  handleCancel(): void {
    this.submitted = false;
    this.formAddVisit.reset();
    this.resetDefaultValue();
    this.store.dispatch(new CloseAddVisit());
    //this.patient = {};
  }

  resetDefaultValue() {
    this.formAddVisit.patchValue({
      type: '1',
      visitDate: new Date(),
      doctorId: this.curUserId,
    });
  }

  emptyAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.checkNull(control.value)) {
        // you have to return `{error: true}` to mark it as an error event
        observer.next({ error: true, duplicated: true });
      } else {
        observer.next(null);
      }
      observer.complete();
    });

  validDateAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      if (this.checkNullObject(control.value)) {
        observer.next(null);
      } else {
        if (control.value > new Date()) {
          observer.next({ error: true, validDate: true });
        } else {
          observer.next(null);
        }
      }
      observer.complete();
    });

  onInput(event): void {
    const value = event.query;
    this.options = value ? [value, value + value, value + value + value] : [];
  }

  showAddPatient(): void {
    this.store.dispatch(new OpenAddPatient());
  }

  onSearchPatient(keyword: string) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (keyword != null && keyword != '') {
        keyword = keyword.trim();
        this.patientService.searchPatient(keyword).subscribe((res) => {
          this.searchvalue = res.slice(0, 20) ? res.slice(0, 20) : null;
        });
      }
    }, 500);
  }

  selectSearchPatient(data) {
    this.patientService.selectPatient(data.id).subscribe((response) => {
      if (response && response.isValid) {
        data = response.jsonData;
        this.patient = data;
        this.patient.dob = !data.dob && data.yob ? new Date('01/01/' + data.yob) : data.dob;
        this.patient.lastVisitDate = data.lastVisitDate;
        this.selectPatient.emit(this.patient);
      }
    });
  }

  checkNullObject(value) {
    try {
      if (value == null || value == '' || value == 'undefined') {
        return true;
      }
      return false;
    } catch (e) {
      return true;
    }
  }
}
