<div
  (mouseenter)="hoverEvent()"
  (mouseleave)="mouseLeave()"
  class="thumb-wrapper">
  <div>
    <div *ngIf="hover" class="delete-bg">
      <i
        nz-icon
        nzType="delete"
        [nzTheme]="'fill'"
        style="font-size: 28px; color: #ffffff"></i>
    </div>
    <img class="thumbnail" [src]="src" alt="ảnh nè" />
    <img class="thumbnail-bg" [src]="src" />
  </div>
</div>
