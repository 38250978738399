import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import * as GroupAuthAction from './group-auth.action';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { Constant } from 'src/app/share/constants/constant.class';
import { GroupMemberService } from 'src/app/services/group-member.service';

@Injectable()
export class GroupAuthEffects {
  constructor(
    private actions$: Actions,
    private groupMemberService: GroupMemberService
  ) {}

  // @Effect()
  // join$: Observable<Action> = this.actions$.pipe(
  //   ofType<GroupAuthAction.Join>(GroupAuthAction.GroupAuthActionTypes.Join),
  //   mergeMap((action: GroupAuthAction.Join) =>
  //     this.groupMemberService.checkGroupAuthen(action.payload).pipe(
  //       map((data: any) => {
  //           if(data.isValid){
  //               return new GroupAuthAction.JoinSuccess(data.jsonData);
  //           }else{
  //               return new GroupAuthAction.JoinError(data.errors[0].messageError);
  //           }
  //       }),
  //       catchError((err) => of(new GroupAuthAction.JoinError(err)))
  //     )
  //   )
  // );

  // join$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType<GroupAuthAction.Join>(GroupAuthAction.GroupAuthActionTypes.Join),
  //     tap(action => {
  //       //TODO: call service save current group
  //       // console.log('action.payload:', action.payload);
  //       this.groupMemberService.setCurrentGroup(action.payload.groupId).subscribe()
  //       {};
  //     })
  //   ), { dispatch: false });

  // leave$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType<GroupAuthAction.Leave>(GroupAuthAction.GroupAuthActionTypes.Leave),
  //     tap(action => {
  //       //localStorage.removeItem(Constant.CURRENT_GROUP_INFO);
  //       //localStorage.removeItem(Constant.CURRENT_GROUPNAME);
  //     })
  //   ), { dispatch: false });
}
