import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constant } from 'src/app/share/constants/constant.class';
import { ShareService } from 'src/app/services/share.service';
import { PrescriptionService } from 'src/app/services/prescription-service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-modal-query-aprroving',
  templateUrl: './modal-query-aprroving.component.html',
  styleUrls: ['./modal-query-aprroving.component.scss'],
})
export class ModalQueryAprrovingComponent implements OnInit {
  @Input() isVisible = false;
  @Output() isVisibleChange = new EventEmitter();
  @Output() querySuccess = new EventEmitter();

  @Input() prescription = null;
  @Input() queryingAction = -1; //0: querying; 1: denying; 2: unapproving

  approvingMessage = '';
  errorEmptyApprovingMsg = '';

  constructor(
    private shareService: ShareService,
    private prescriptionService: PrescriptionService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {}
  closePopupPrescriptionApproving() {
    this.isVisible = false;
    this.isVisibleChange.emit(false);
  }
  checkApprovingMsgEmpty() {
    if (this.shareService.checkEmptyStr(this.approvingMessage)) {
      this.errorEmptyApprovingMsg = 'Bạn cần nhập lý do!';
    } else {
      this.errorEmptyApprovingMsg = '';
    }
  }
  getQueryingPrescriptionTitle() {
    switch (this.queryingAction) {
      case 0:
        return 'Yêu cầu kiểm tra lại';
      case 1:
        return 'Từ chối';
      case 2:
        return 'Bỏ duyệt';
    }
  }
  doQueryingPrescription() {
    if (this.shareService.checkEmptyStr(this.approvingMessage)) {
      this.checkApprovingMsgEmpty();
      return;
    }
    if (this.queryingAction == 0) {
      const payload = {
        prescriptionId: this.prescription.Id,
        message: this.approvingMessage,
      };
      this.prescriptionService.queryPrescription(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Gửi yêu cầu kiểm tra toa thuốc thành công!'
          );
          this.prescription.Status = Constant.PrescriptionStatus.Querying;
          this.isVisible = false;
          //this.setPermissions4Prescription();
          //this.getVisitPrescriptions();
          this.isVisibleChange.emit(false);
          this.querySuccess.emit();
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            res.errors[0].errorMessage
          );
        }
      });
    } else if (this.queryingAction == 1) {
      const payload = {
        prescriptionId: this.prescription.Id,
        message: this.approvingMessage,
      };
      this.prescriptionService.denyPrescription(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.SUCCESS,
            'Từ chối toa thuốc thành công!'
          );
          this.prescription.Status = Constant.PrescriptionStatus.Denied;
          this.isVisible = false;
          // this.setPermissions4Prescription();
          // this.getVisitPrescriptions();
          this.isVisibleChange.emit(false);
          this.querySuccess.emit();
        } else {
          this.notificationService.showNotification(
            Constant.NOTIFY_TYPE.ERROR,
            res.errors[0].errorMessage
          );
        }
      });
    } else {
      this.doUnapprovePrescription();
    }
  }
  doUnapprovePrescription() {
    const payload = {
      prescriptionId: this.prescription.Id,
      message: this.approvingMessage,
    };
    this.prescriptionService.unapprovePrescription(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.SUCCESS,
          'Bỏ duyệt toa thuốc thành công!'
        );
        this.prescription.Status = Constant.PrescriptionStatus.Saved;
        this.isVisible = false;
        this.isVisibleChange.emit(false);
        this.querySuccess.emit();
        // this.setPermissions4Prescription();
        // this.getVisitPrescriptions();
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          res.errors[0].errorMessage
        );
      }
    });
  }
}
