<div id="regist-form">
  <div class="header mg-bt-10">
    <img src="assets/image/logo.svg" />
    <p>Patient Medical Record</p>
  </div>
  <div class="containers">
    <div class="col-md-10 col-lg-8 content">
      <p>Cập nhật thông tin tài khoản để hoàn thiện tham gia nhóm</p>
      <form [formGroup]="updateForm">
        <div class="row mg-t-10">
          <div class="col-6">
            <label class="mg-r-15">Email:</label>
            <strong>{{ email }}</strong>
          </div>
          <div class="col-6">
            <label class="mg-r-15">User name:</label>
            <strong>{{ userName }}</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <div class="input-wrap">
              <nz-select
                class="mg-r-15 user-rank"
                nzPlaceHolder="Chức danh"
                formControlName="doctorRank"
                [nzSize]="'large'"
                nzAllowClear
                nzShowSearch>
                <nz-option
                  *ngFor="let rank of doctorRanks"
                  [nzValue]="rank"
                  [nzLabel]="rank.name"></nz-option>
              </nz-select>
              <input
                nz-input
                formControlName="fullName"
                placeholder="Họ tên"
                [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" />
            </div>
            <div
              class="message-required"
              *ngIf="submitted && f.fullName.errors">
              <div *ngIf="f.fullName.errors.required">Hãy nhập họ tên</div>
              <div *ngIf="f.fullName.errors.minlength">
                Độ dài tối thiểu là 8 ký tự
              </div>
              <div *ngIf="f.fullName.errors.maxlength">
                <i
                  nz-icon
                  nzType="close-circle"
                  [nzTheme]="'twotone'"
                  [nzTwotoneColor]="'#FF0000'"></i>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="input-wrap">
              <input
                nz-input
                formControlName="address"
                placeholder="Địa chỉ"
                [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="input-wrap">
              <input
                nz-input
                formControlName="phoneNo"
                placeholder="Số điện thoại"
                [ngClass]="{ 'is-invalid': submitted && f.phoneNo.errors }" />
            </div>
            <div class="message-required" *ngIf="submitted && f.phoneNo.errors">
              <div *ngIf="f.phoneNo.errors.required">Hãy nhập SĐT</div>
            </div>
          </div>
          <div class="col-6">
            <div class="input-wrap">
              <nz-date-picker
                formControlName="dob"
                nzPlaceHolder="Ngày sinh"
                nzFormat="dd/MM/yyyy"
                [nzDisabledDate]="disabledDateAfterToday"
                [ngClass]="{ 'is-invalid': submitted && f.dob.errors }">
              </nz-date-picker>
            </div>
          </div>
        </div>
        <button class="btn-regist" nz-button (click)="onSubmit()">
          Cập nhật
        </button>
      </form>
    </div>
  </div>
</div>
