import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { MenuItemTree } from 'src/app/models/grouptree.model';

@Component({
  selector: 'app-mobile-recursive-group',
  templateUrl: './mobile-recursive-group.component.html',
  styleUrls: ['./mobile-recursive-group.component.scss'],
})
export class MobileRecursiveGroupComponent implements OnInit {
  @Input() isActive: boolean;
  @Input() menus: Array<MenuItemTree> = [];
  @Output() selectItem: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  selectGroup(val) {
    this.selectItem.emit(val);
  }
}
