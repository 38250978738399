<!-- <nz-modal [nzVisible]="isVisible" [nzTitle]="modalTitle ? modalTitle : 'Cột hiển thị'" [nzWidth]="800" [nzMaskClosable]="false"
  (nzOnCancel)='handleCancel()' (nzOnOk)="handleOk()"> -->
<app-m-modal [modalId]="'custom-column'" [isVisible]="isVisible" (closeModal)="handleCancel()" [mMask]="true"
  [width]="800" [minHeight]="400" [minWidth]="500" [isResiable]="true">
  <ng-template #mModalTitle>
    <div class="header-title">
      {{ modalTitle ? modalTitle : 'Cột hiển thị' }}
    </div>
  </ng-template>
  <ng-template #mModalContent>
    <div class="row">
      <div class="col">
        <h3 class="text-align-center">Cột hiển thị</h3>
        <div cdkDropList #showList="cdkDropList" [cdkDropListData]="_showColumn" [cdkDropListConnectedTo]="[hideList]"
          class="column-list max-height-365" (cdkDropListDropped)="drop($event)"
          [cdkDropListEnterPredicate]="noReturnPredicate">
          <div class="column-box" *ngFor="let item of _showColumn" cdkDrag>
            <span class="pd-r-8">{{ item.title }}</span><i nz-icon nzType="check" nzTheme="outline" cdkDragDisabled=true
              class="valid-icon mg-r-8"></i>
          </div>
        </div>
      </div>
      <div class="col">
        <h3 class="text-align-center">Cột ẩn</h3>
        <div cdkDropList #hideList="cdkDropList" [cdkDropListData]="_hideColumn" [cdkDropListConnectedTo]="[showList]"
          class="column-list max-height-365" (cdkDropListDropped)="drop($event)"
          [cdkDropListEnterPredicate]="evenPredicate">
          <div class="column-box" *ngFor="let item of _hideColumn" cdkDrag>
            <span class="pd-r-8">{{ item.title }}</span><i nz-icon nzType="close-circle" nzTheme="outline"
              class="error-icon mg-r-8"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #mModalFooter>
    <button class="btn-primary-medium" (click)="handleOk()">Lưu</button>
    <button class="btn-cancel-medium" (click)="handleCancel()">Đóng</button>
  </ng-template>
</app-m-modal>
<!-- </nz-modal> -->