import { EditHeaderComponent } from './edit-header/edit-header.component';
import { ConfigGroupComponent } from './config-group/config-group.component';
import { SharedModule } from '../../../share/share.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzListModule } from 'ng-zorro-antd/list';
import { CustomColumnModule } from '../../worklist/custom-column-module/custom-column.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { WebhookModalComponent } from './webhook-modal/webhook-modal.component';
import { ApiTokenModalComponent } from './api-token-modal/api-token-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NzListModule,
    CustomColumnModule,
    AngularEditorModule,
  ],
  exports: [ConfigGroupComponent],
  declarations: [
    ConfigGroupComponent,
    EditHeaderComponent,
    WebhookModalComponent,
    ApiTokenModalComponent,
  ],
})
export class ConfigGroupModule {}
