import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { Observable } from 'rxjs';
import { UrlConstant } from '../share/constants/url.class';
import { Visits } from '../models/listVisit.class';
@Injectable()
export class ListVisitService extends BaseService {
  getListVisit(dataLstVisit): Observable<any> {
    return this.post(UrlConstant.LIST_VISIT, dataLstVisit);
  }
  getListVisitSmall(dataLstVisit): Observable<any> {
    return this.post(UrlConstant.LIST_VISIT_SMALL, dataLstVisit);
  }
  ///api/Visit/GetObservationOfPatient
  getObservationOfPatient(data): Observable<any> {
    return this.post('/api/Visit/GetObservationOfPatient', data);
  }
}
