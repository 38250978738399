<div class="pd-10">
  <div nz-row [nzGutter]="[10, 0]">
    <div nz-col nzSpan="4">
      <nz-input-group>
        <input placeholder="Tìm kiếm" [(ngModel)]="searchValue" nz-input (input)="callbackSearchFn()"
          [nzAutocomplete]="autosearch" #searchInput (keydown.enter)="selectSearch(searchInput.value)"/>
      </nz-input-group>

      <nz-autocomplete #autosearch>
        <nz-auto-option *ngFor="let item of searchResult" [nzValue]="item.name" (click)="selectSearch(item.name)">
          <span>{{ item.name }}</span>
        </nz-auto-option>
      </nz-autocomplete>

      <!-- <ng-template #suffixIconSearch>
        <i nz-icon nzType="search" (click)="enterSearch(searchValue)"></i>
      </ng-template> -->
    </div>
    <div nz-col nzSpan="2">
      <button nz-button nzType="default" (click)="enterSearch(searchValue)"><i nz-icon nzType="search"></i></button>
      <button class="mg-l-10" nz-button nzType="default" (click)="clear()"><i nz-icon nzType="close"></i></button>
    </div>


    <div nz-col nzSpan="18" id="align-button">
      <button id="btn-add" nz-button (click)="showAddModal()">
        <i nz-icon nzType="plus"></i>Thêm mới
      </button>
    </div>
  </div>

  <div nz-row class="data-table main-table-grid">
    <div nz-col nzSpan="24">
      <nz-table #fixedTable nzSize="small" [nzLoading]="loading" [(nzData)]="listRowDisplay" [nzFrontPagination]="false"
        [nzTotal]="total" (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger [(nzPageSize)]="pageSize"
        [(nzPageIndex)]="pageIndex" nzShowSizeChanger="" [nzPageSizeOptions]="[10, 20, 50, 100]"
        [nzScroll]="{ y: 'calc(100vh - 250px)' }" [nzShowTotal]="rangeTemplate">
        <thead>
          <tr>
            <th nzWidth="50px" class="text-align-center">STT</th>
            <th nzWidth="100px" class="text-align-center">Trạng thái</th>
            <th nzWidth="600px">Tên dịch vụ khám</th>
            <th nzWidth="200px">Mã</th>
            <th nzWidth="200px" class="text-align-center">Giá tiền(VND)</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of fixedTable.data; index as i" [ngClass]="{ highlight: rowSelectedIndex == data.id }"
            (click)="clickRow(data)" (dblclick)="showEditModal()">
            <td class="text-align-center">{{ pageSize * (pageIndex - 1) + i + 1 }}</td>
            <td class="text-align-center icons-list" (click)="changeStatus(data)">
              <i *ngIf="!data.isDeleted" nz-icon nzType="check-circle" [nzTheme]="'twotone'"
                [nzTwotoneColor]="'#52c41a'"></i>
              <i *ngIf="data.isDeleted" nz-icon nzType="stop" [nzTheme]="'twotone'" [nzTwotoneColor]="'#ff0000'"></i>

            </td>
            <td>{{ data.name }}</td>
            <td>{{ data.code }}</td>
            <td class="a-right">{{ data.price | currencyFormat }}<span>đ</span></td>

          </tr>
        </tbody>
        <ng-template #rangeTemplate let-range="range" let-total>
          {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }}
        </ng-template>
      </nz-table>
    </div>
  </div>

  <nz-modal class="modal-admin" nzWidth="500px" [(nzVisible)]="isVisible" nzTitle="Dịch vụ khám" nzDraggable="true"
    nzMaskClosable="false" (nzClosable)="(true)" (nzOnCancel)="handleCancel()">
    <form [formGroup]="formData" (ngSubmit)="handleOk()">
      <div *nzModalContent>
        <nz-form-item>
          <nz-form-label [nzSpan]="5" nzRequired nzFor="name">Tên dịch vụ</nz-form-label>
          <nz-form-control nzHasFeedback [nzErrorTip]="nameErrorTpl">
            <input formControlName="name" nz-input placeholder="Tên" />
            <ng-template #nameErrorTpl let-control>
              <ng-container *ngIf="control.hasError('pattern')">
                Tên không được để trống!
              </ng-container>
              <ng-container *ngIf="control.hasError('required')">
                Hãy nhập tên!
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="5" nzRequired nzFor="code">Mã dịch vụ</nz-form-label>
          <nz-form-control nzHasFeedback [nzErrorTip]="codeErrorTpl">
            <input formControlName="code" nz-input placeholder="Code" />
            <ng-template #codeErrorTpl let-control>
              <ng-container *ngIf="control.hasError('pattern')">
                Mã dịch vụ không được để trống!
              </ng-container>
              <ng-container *ngIf="control.hasError('required')">
                Hãy nhập mã dịch vụ khám!
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="5" nzRequired nzFor="price">Giá tiền</nz-form-label>
          <nz-form-control nzHasFeedback [nzErrorTip]="priceErrorTpl">
            <input formControlName="price" nz-input placeholder="Giá tiền" currencyMask [options]="{
              prefix: '',
              allowNegative: false,
              thousands: '.',
              suffix: '',
              precision: 0,
              align: 'left',
              nullable: true
            }" />
            <ng-template #priceErrorTpl let-control>
              <ng-container *ngIf="control.hasError('pattern')">
                Giá tiền không được để trống!
              </ng-container>
              <ng-container *ngIf="control.hasError('required')">
                Hãy nhập giá tiền!
              </ng-container>
              <ng-container *ngIf="control.hasError('min')">
                Giá tiền không thể bé hơn 0!
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="5" nzRequired nzFor="price">Trạng thái</nz-form-label>
          <nz-form-control>
            <!-- <label nz-checkbox formControlName="isDeleted"></label> -->
            <nz-radio-group formControlName="isDeleted">
              <label nz-radio [nzValue]="false">Enable</label>
              <label nz-radio [nzValue]="true">Disable</label>

            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div *nzModalFooter>
        <button *ngIf="rowSelected" nz-button (click)="edit()" class="btn btn-primary">
          <i nz-icon nzType="save" nzTheme="outline"></i>Cập nhật
        </button>
        <button *ngIf="!rowSelected" nz-button (click)="add()" class="btn btn-primary">
          <i nz-icon nzType="plus" nzTheme="outline"></i>Thêm mới
        </button>
        <!-- <button *ngIf="rowSelected || searchModal" class="btn btn-danger" nz-button (click)="delete()">
          <i nz-icon nzType="close"></i>Xóa
        </button> -->
      </div>
    </form>
  </nz-modal>
</div>
