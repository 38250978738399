<nz-modal
  [(nzVisible)]="isVisible"
  nzWidth="500px"
  [nzTitle]="getQueryingPrescriptionTitle()"
  nzMaskClosable="false"
  (nzOnCancel)="closePopupPrescriptionApproving()">
  <label>Nhập lý do:</label>
  <input
    nz-input
    [(ngModel)]="approvingMessage"
    (ngModelChange)="checkApprovingMsgEmpty()"
    type="text" />
  <div class="div-error" *ngIf="errorEmptyApprovingMsg != ''">
    {{ errorEmptyApprovingMsg }}
  </div>
  <div *nzModalFooter>
    <button
      class="btn-cancel-small"
      nz-button
      (click)="closePopupPrescriptionApproving()">
      Hủy
    </button>
    <button
      class="btn-primary-small"
      nz-button
      (click)="doQueryingPrescription()">
      {{ getQueryingPrescriptionTitle() }}
    </button>
  </div>
</nz-modal>
