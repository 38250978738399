import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeStyle,
  SafeScript,
  SafeUrl,
  SafeResourceUrl,
} from '@angular/platform-browser';
@Pipe({
  name: 'filterName',
})
export class FilterPipe implements PipeTransform {
  //pipe search by 'name'
  constructor(protected sanitizer: DomSanitizer) {}
  transform(items: any, searchText: string) {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((it) => it.name.toLowerCase().includes(searchText));
  }
}
