<div class="m-tab-container">
  <div class="survival-top-bar">
    <!-- <div class="title m-tab-title">Thông tin lâm sàng</div> -->
    <div class="btn-right">
      <input
        class="mgr15"
        nz-input
        [(ngModel)]="searchText"
        (ngModelChange)="searchVitalSign()"
        placeholder="Tên thông tin lâm sàng" />
      <button class="btn-primary-small mgr15" (click)="viewChart()" nz-button>
        Lập biểu đồ
      </button>
      <button class="btn-printer {{ hideButton }}">
        <i><img src="assets/image/DPicon/white-print.svg" /></i>
      </button>
    </div>
  </div>
  <!-- <nz-table id="tableSurvival" #tableSurvival nzPageSize="100" [nzData]="vitalSignFilter" class="m-table-primary" [nzShowPagination]="false" -->
  <nz-table
    id="tableSurvival"
    nzSize="small"
    #tableSurvival
    nzPageSize="100"
    [nzData]="vitalSignFilter"
    class="table-survival m-table-primary"
    [nzShowPagination]="false"
    (nzCurrentPageDataChange)="currentPageDataChange($event)"
    nzShowSizeChanger
    [nzPageSizeOptions]="[50, 100]"
    [nzScroll]="{ x: '800px', y: scrollY }">
    <thead>
      <tr>
        <th
          nzLeft
          class="th-index"
          nzShowCheckbox
          [(nzChecked)]="isAllDisplayDataChecked"
          nzLeft="0px"
          nzWidth="50px"
          (nzCheckedChange)="checkAllRow($event)"></th>
        <th nzLeft nzWidth="200px" class="th-index">Thông tin lâm sàng</th>
        <th class="text-nowrap" nzWidth="150px" *ngFor="let th of cakhams">
          {{ th.visitDate | date: 'dd/MM/yyyy ' }}<br />{{
            th.visitDate | date: 'HH:mm'
          }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of tableSurvival.data">
        <td
          nzLeft
          nzShowCheckbox
          class="th-index"
          [(nzChecked)]="mapOfCheckedId[item.id]"
          [nzDisabled]="item.typeDisableChecked"
          (nzCheckedChange)="refreshStatus()"></td>
        <td nzLeft class="xn-col th-index" [innerHtml]="item.name">
          <!-- <td>
                    <div>{{item.name}}</div>
                    <div>({{item.unit}})</div> -->
        </td>
        <ng-container *ngFor="let td of cakhams">
          <td
            [ngClass]="{ XNSelected: td.visitId === visit.id }"
            class="xn-col">
            <div
              *ngIf="item['c' + td.visitId] !== ''"
              class="value"
              [innerHtml]="item['c' + td.visitId]"></div>
            <div
              *ngIf="item['s' + td.visitId] !== ''"
              class="value-special"
              [innerHtml]="item['s' + td.visitId]"></div>
            <div class="range">
              <div
                *ngIf="item['r' + td.visitId] !== ''"
                class="border {{ item['r' + td.visitId] }}"></div>
              <div *ngIf="item['r' + td.visitId] === ''"></div>
              <ng-container *ngIf="item['r' + td.visitId] !== ''">
                <div class="inner"></div>
              </ng-container>
            </div>
          </td>
        </ng-container>
        <!-- <td *ngFor="let td of cakhams">
                    <div class="value">{{item['c'+ td.visitId]}}</div>
                    <div *ngIf="item['isDisplay']" class="rangeType">
                        <div class="inner"></div>
                        <div class="level {{item['r'+ td.visitId]}}"></div>
                    </div>
                </td> -->
      </tr>
    </tbody>
  </nz-table>
</div>
<app-chart
  (closeChart)="closeChart($event)"
  [isVisibleChart]="isVisibleChart"
  [datas]="vitalSignOnChart"
  [rawData]="initData"></app-chart>
