<div class="title-wrapper">
  <!-- <p class="page-title">
    Quản trị biểu mẫu
  </p> -->

  <div class="head-obsertype">
    <div class="search-box">
      <nz-input-group
        [nzSuffix]="suffixTemplate"
        style="width: 200px; margin-right: 15px">
        <input
          type="text"
          nz-input
          [(ngModel)]="inputValue"
          placeholder="Tìm kiếm..."
          [nzAutocomplete]="auto"
          (ngModelChange)="ontyping($event)" />
      </nz-input-group>
      <ng-template #suffixTemplate>
        <i
          nz-icon
          nz-tooltip
          class="ant-input-clear-icon"
          nzTheme="fill"
          nzType="close-circle"
          *ngIf="inputValue"
          (click)="reset()"></i>
      </ng-template>
      <nz-autocomplete [nzDataSource]="filterOption" #auto></nz-autocomplete>
      <!--dropdown suggestions -->
      <button
        nz-button
        nzSize="default"
        nzType="primary"
        (click)="search()"
        class="search-button">
        Tìm kiếm
      </button>
    </div>
    <div
      class="table-operations"
      style="margin-bottom: 10px; text-align: right">
      <button
        class="mg-r-10 btn-green"
        appTargetBlank
        nz-button
        routerLink="/admin/form/new">
        <i nz-icon nzType="plus"></i>Thêm mới
      </button>
      <button
        class="mg-r-10"
        nz-button
        nzType="primary"
        [disabled]="emptyId()"
        appTargetBlank
        [routerLink]="'/admin/form/' + currentForm.id">
        <!-- appTargetBlank -->
        <i nz-icon nzType="form"></i>Sửa
      </button>
      <button
        class="mg-r-10"
        nz-button
        nzType="primary"
        [disabled]="emptyId()"
        (click)="copy()">
        <!-- appTargetBlank -->
        <i nz-icon nzType="copy"></i>Copy
      </button>
      <button
        class="mg-r-10"
        nz-button
        nzType="primary"
        (click)="openImportModal()">
        <i nz-icon nzType="upload"></i>Import
      </button>
      <button
        class=""
        nz-button
        nzType="danger"
        [disabled]="emptyId()"
        (click)="deleteItem()">
        <i nz-icon nzType="close"></i>Xoá
      </button>
    </div>
  </div>
  <nz-table
    #Formtable
    nzSize="small"
    [nzData]="listForms"
    [nzPageIndex]="requestData.page"
    nzPageSize="20"
    [nzLoading]="isLoading"
    nzShowSizeChanger
    [nzPageSizeOptions]="[10, 20, 50, 100]"
    [nzScroll]="{ y: 'calc(100vh - 270px)' }">
    <thead>
      <tr>
        <th nzWidth="50px">STT</th>
        <th>Tên</th>
        <th>Người tạo</th>
        <th>Thời gian tạo</th>
        <th>Phạm vi sử dụng</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let data of Formtable.data"
        [ngClass]="{ highlight: currentForm.id == data.id }"
        (click)="selectForm(data)">
        <td>{{ data.stt }}</td>
        <td>{{ data.name }}</td>
        <td>{{ data.creator }}</td>
        <td>{{ data.createddate }}</td>
        <td>{{ data.groupName }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>

<nz-modal
  [(nzVisible)]="isVisibleImport"
  nzTitle="Import Form"
  [nzWidth]="700"
  (nzOnCancel)="cancelImportModal()">
  <div>
    <div class="row mg-bt-10">
      <div class="col-3">Upload file</div>
      <div class="col-9">
        <input
          #fileInput
          id="fileOpen"
          type="file"
          multiple="false"
          accept=".json" />
      </div>
    </div>
    <div *ngIf="showError">
      <nz-collapse [nzBordered]="false">
        <nz-collapse-panel
          #p
          *ngFor="let panel of panels; let isFirst = first"
          [nzHeader]="panel.name"
          [nzActive]="panel.active"
          [nzExpandedIcon]="expandedIcon">
          <ul>
            <li *ngFor="let item of panel.items">
              <i nz-icon nzType="exclamation-circle" nzTheme="outline"></i>
              {{ item.name }} (<i>{{ item.field }}</i
              >)
              <span *ngIf="item.formula">
                - <b>{{ item.formula }}</b></span
              >
            </li>
          </ul>
          <ng-template #expandedIcon let-active>
            {{ active }}
            <i
              nz-icon
              nzType="caret-right"
              class="ant-collapse-arrow"
              [nzRotate]="p.nzActive ? 90 : -90"></i>
          </ng-template>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>
  <div *nzModalFooter>
    <button
      *ngIf="showError"
      nz-button
      class="btn-primary-medium"
      (click)="importForm(true)">
      Tiếp tục lưu
    </button>
    <button
      *ngIf="!showError"
      nz-button
      class="btn-primary-medium"
      (click)="importForm(false)">
      Lưu
    </button>
    <button nz-button class="btn-cancel-medium" (click)="cancelImportModal()">
      Đóng
    </button>
  </div>
</nz-modal>
