<nz-spin [nzSpinning]="isLoading" class="loading-icon" nzTip="Đang tải ..."></nz-spin>
<div *ngIf="isVisible" class="form-only">
  <div #formContentPrint id="form-content">
    <div #formContent class="danh-test"></div>
    <div [id]="item" *ngFor="let item of pages" class="pageForm" [ngStyle]="{ height: item.height + 'px' }">
      <div [id]="item + '-content'" class="f-page-content blockCanAddItem">
        <div [id]="data.viewId" *ngFor="let data of labels" class="df-elm form-label cloneable" df-type="label"
          [ngStyle]="{
            'top': data.top + 'px',
            'left': data.left + 'px',
            'width': data.width + 'px',
            'height': data.height + 'px'
          }">
          <app-label [data]="data"></app-label>
        </div>
        <!-- <div [id]="data.viewId" *ngFor="let data of formObjects.get('Checkbox')" class="df-elm form-label cloneable"
          df-type="Checkbox"
          [ngStyle]="{ 'top': data.top + 'px','left': data.left + 'px', 'width': data.width + 'px', 'height': data.height + 'px'}">
          <app-checkbox-element [data]="data"></app-checkbox-element>
        </div> -->
        <div [id]="data.viewId" *ngFor="let data of textareas" class="df-elm form-textarea cloneable" df-type="textarea"
          [ngStyle]="{
            'top': data.top + 'px',
            'left': data.left + 'px',
            'width': data.width + 'px',
            'height': data.height + 'px'
          }">
          <app-textarea-element [data]="data"></app-textarea-element>
        </div>
        <div [id]="data.viewId" *ngFor="let data of images" class="df-elm image-element cloneable default-elm"
          df-type="image" [ngStyle]="{
            'top': data.top + 'px',
            'left': data.left + 'px',
            'width': data.width + 'px',
            'height': data.height + 'px'
          }">
          <app-image-element [data]="data"></app-image-element>
        </div>
        <div [id]="data.viewId" *ngFor="let data of observationTypes" class="df-elm wrapper-elm df-obser"
          df-type="observation" [ngStyle]="{
            'top': data.top + 'px',
            'left': data.left + 'px',
            'width': data.width + 'px',
            'height': data.height + 'px'
          }">
          <ng-container [ngSwitch]="data.observationType.dataType" style="height: 100%">
            <app-input-number *ngSwitchCase="constant.DATATYPENUMBER.NUMBER" [data]="data"></app-input-number>
            <app-input-element *ngSwitchCase="constant.DATATYPENUMBER.STRING" [data]="data"></app-input-element>
            <app-check-box-element *ngSwitchCase="constant.DATATYPENUMBER.BOOLEAN"
              [data]="data"></app-check-box-element>
            <app-check-box-element *ngSwitchCase="constant.DATATYPENUMBER.POSITIVENEGATIVE"
              [data]="data"></app-check-box-element>
            <app-datatime-element *ngSwitchCase="constant.DATATYPENUMBER.DATE" [data]="data"></app-datatime-element>
            <app-datatime-element *ngSwitchCase="constant.DATATYPENUMBER.DATETIME" [data]="data"></app-datatime-element>
            <app-datatime-element *ngSwitchCase="constant.DATATYPENUMBER.TIME" [data]="data"></app-datatime-element>
          </ng-container>
          <app-dropdown-element *ngIf="data.viewType == 'dropdown'" [data]="data"></app-dropdown-element>
          <app-selection-element *ngIf="data.viewType == 'selection'" [data]="data"></app-selection-element>
          <span *ngIf="data.isRequired" class="required-elm">* Bắt buộc</span>
        </div>
        <div [id]="data.viewId" *ngFor="let data of observationTypeRecents" class="df-elm wrapper-elm df-obser-recent"
          df-type="observationRecent" [ngStyle]="{
            'top': data.top + 'px',
            'left': data.left + 'px',
            'width': data.width + 'px',
            'height': data.height + 'px'
          }">
          <ng-container [ngSwitch]="data.observationType.dataType" style="height: 100%">
            <app-input-number *ngSwitchCase="constant.DATATYPENUMBER.NUMBER" [data]="data"></app-input-number>
            <app-input-element *ngSwitchCase="constant.DATATYPENUMBER.STRING" [data]="data"></app-input-element>
            <app-check-box-element *ngSwitchCase="constant.DATATYPENUMBER.BOOLEAN"
              [data]="data"></app-check-box-element>
            <app-check-box-element *ngSwitchCase="constant.DATATYPENUMBER.POSITIVENEGATIVE"
              [data]="data"></app-check-box-element>
            <app-datatime-element *ngSwitchCase="constant.DATATYPENUMBER.DATE" [data]="data"></app-datatime-element>
            <app-datatime-element *ngSwitchCase="constant.DATATYPENUMBER.DATETIME" [data]="data"></app-datatime-element>
            <app-datatime-element *ngSwitchCase="constant.DATATYPENUMBER.TIME" [data]="data"></app-datatime-element>
          </ng-container>
          <app-dropdown-element *ngIf="data.viewType == 'dropdown'" [data]="data"></app-dropdown-element>
          <app-selection-element *ngIf="data.viewType == 'selection'" [data]="data"></app-selection-element>
          <span class="data-type-icon" [title]="data.visitDate">
            <i style="font-size: 20px" nz-icon nzType="field-time" nzTheme="outline"></i>
          </span>
        </div>
        <div [id]="data.viewId" *ngFor="let data of canvasElms" class="df-elm canvas-element cloneable default-elm"
          df-type="canvas" [ngStyle]="{
            'top': data.top + 'px',
            'left': data.left + 'px',
            'width': data.width + 'px',
            'height': data.height + 'px'
          }">
          <app-canvas-element [data]="data"></app-canvas-element>
        </div>
        <div [id]="data.viewId" *ngFor="let data of commonInfos" class="df-elm info-element cloneable default-elm"
          df-type="commoninfo" [title]="data.name" [ngStyle]="{
            'top': data.top + 'px',
            'left': data.left + 'px',
            'width': data.width + 'px',
            'height': data.height + 'px'
          }">
          <app-common-info [value]="commonInfo" [data]="data"
            (referValueChanged)="referValueChanged($event)"></app-common-info>
        </div>
        <div [id]="data.viewId" *ngFor="let data of formulas" class="df-elm formula-element cloneable default-elm"
          df-type="formula" [title]="data.value" [ngStyle]="{
            'top': data.top + 'px',
            'left': data.left + 'px',
            'width': data.width + 'px',
            'height': data.height + 'px'
          }">
          <app-formula-view [data]="data"></app-formula-view>
        </div>
      </div>
    </div>
  </div>

  <div id="download">
    <img #canvas />
    <a #downloadLink></a>
  </div>
</div>