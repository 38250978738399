<div class="title-wrapper">
  <!-- <p class="page-title">
    Quản trị Danh mục
  </p> -->
  <div class="head-obsertype">
    <div class="table-operations">
      <button class="mg-r-10 btn-green" (click)="addNew()" nz-button>
        <i nz-icon nzType="plus"></i>Thêm mới
      </button>
      <button
        class="mg-r-10"
        nz-button
        nzType="primary"
        (click)="updateCategory()">
        <i nz-icon nzType="form"></i>Sửa
      </button>
      <!-- <button class="" nz-button nzType="danger">
        <i nz-icon nzType="close"></i>Xoá
      </button> -->
    </div>
  </div>
  <div class="container-fluid" style="display: flex; margin-top: 40px">
    <div class="col-3">
      <h1>Danh mục</h1>
      <tree-root
        #categoryTree
        [(state)]="state"
        [nodes]="nodes"
        [options]="options"
        (activate)="selectNode($event)">
        <ng-template #treeNodeTemplate let-node let-index="index">
          <ng-container>
            <i class=""></i>
            <div class="tree-note">{{ node.data.name }}</div>
          </ng-container>
        </ng-template>
      </tree-root>
    </div>
    <div class="vr"></div>
    <div class="col-8">
      <div class="title">
        <h2 *ngIf="isAdd">Thêm mới Danh mục</h2>
        <h2 *ngIf="!isAdd">Cập nhập Danh mục</h2>
      </div>

      <form nz-form [formGroup]="validateForm">
        <nz-form-item>
          <nz-form-label [nzSpan]="5">Tên Danh mục</nz-form-label>
          <nz-form-control
            nzHasFeedback
            [nzSpan]="12"
            nzErrorTip="Bạn chưa nhập Tên Danh mục">
            <input nz-input formControlName="name" required />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="5">Nút cha</nz-form-label>
          <nz-form-control [nzSpan]="12">
            <input nz-input formControlName="parentName" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control
            [nzSpan]="12"
            [nzOffset]="5"
            style="text-align: center">
            <button nz-button nzType="primary" (click)="submitForm()">
              Lưu
            </button>
            <button
              nz-button
              nzType="danger"
              (click)="cancel()"
              style="margin-left: 20px">
              Hủy
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>
</div>
