import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';
import { Observable } from 'rxjs';
import { UrlConstant } from '../share/constants/url.class';
import { SubclinicalorderGroup } from '../models/subclinicalorder-group.class';
@Injectable({
  providedIn: 'root',
})
export class SubclinicalorderGroupService extends BaseService {
  getSubclinicalOrderGroupAll(payload): Observable<any> {
    return this.post(UrlConstant.SUBCLINICALORDER_GROUP_GET_LIST, payload);
  }

  searchSubclinicalOrderGroup(payload): Observable<any> {
    return this.post(UrlConstant.SUBCLINICALORDER_GROUP_SEARCH, payload);
  }

  getSubclinicalOrderGroupById(id: string): Observable<any> {
    return this.get(UrlConstant.SUBCLINICALORDER_GROUP_GET_BY_ID + id);
  }

  updateSubclinicalOrderGroup(
    payload: SubclinicalorderGroup
  ): Observable<SubclinicalorderGroup> {
    return this.put(UrlConstant.SUBCLINICALORDER_GROUP_UPDATE, payload);
  }

  addSubclinicalOrderGroup(
    payload: SubclinicalorderGroup
  ): Observable<SubclinicalorderGroup> {
    return this.post(UrlConstant.SUBCLINICALORDER_GROUP_UPDATE, payload);
  }

  deleteSubclinicalOrderGroup(id) {
    return this.delete(UrlConstant.SUBCLINICALORDER_GROUP_DELETE + id, '');
  }

  toTextPrivacy(privacy: number): string {
    switch (privacy) {
      case 1:
        return 'Riêng tôi';
      case 2:
        return 'Trong nhóm';
      default:
        return '';
    }
  }
}
