import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  NgZone,
  PLATFORM_ID,
  Inject,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isVisibleChart: boolean;
  @Input() rawData: any[];
  @Input() datas: any[];
  @Output() closeChart: EventEmitter<any> = new EventEmitter();

  chartData: any[] = [];
  toggle = false;
  isMobile: boolean;
  private chart: am4charts.XYChart;

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) {}

  ngOnInit(): void {
    this.isMobile = window.innerWidth < 540;
  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnChanges() {
    setTimeout(() => {
      for (let i = 0; i < this.datas.length; i++) {
        const id = this.datas[i].id;
        const chartDiv = 'chartDiv' + id;
        this.formatDataToChart(chartDiv, id);
      }
    }, 200);
  }

  formatDataToChart(charDiv: any, vitalSignId: string) {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      const chart = am4core.create(charDiv, am4charts.XYChart);
      const data = [];
      for (let i = 0; i < this.rawData.length; i++) {
        const item = this.rawData[i];
        if (item.observationTypeId == vitalSignId) {
          const d = new Date(item.visitDateTime);
          if (item.dataType === 9 && item.value.length > 0) {
            const ds = item.dataSource.filter((en) => en.id === item.value[0]);
            if (ds != null && ds.length > 0)
              {data.push({ date: d, value: ds[0].valueNumber });}
            else {data.push({ date: d, value: item.value });}
          } else {data.push({ date: d, value: item.value });}
        }
      }

      chart.data = data;
      const dateAxisX = chart.xAxes.push(new am4charts.DateAxis());
      dateAxisX.renderer.grid.template.location = 0;
      dateAxisX.dateFormats.setKey('day', 'dd/MM/yyyy');
      dateAxisX.periodChangeDateFormats.setKey('day', 'dd/MM/yyyy');
      dateAxisX.dateFormats.setKey('month', 'dd/MM/yyyy');
      dateAxisX.periodChangeDateFormats.setKey('month', 'dd/MM/yyyy');

      const valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxisY.tooltip.disabled = true;
      valueAxisY.renderer.minWidth = 35;

      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = 'date';
      series.dataFields.valueY = 'value';
      series.tooltipText = '{valueY.value}';
      series.strokeWidth = 2;
      series.minBulletDistance = 15;

      series.tooltip.background.cornerRadius = 20;
      series.tooltip.background.strokeOpacity = 0;
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.label.minWidth = 40;
      series.tooltip.label.minHeight = 40;
      series.tooltip.label.textAlign = 'middle';
      series.tooltip.label.textValign = 'middle';

      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.strokeWidth = 2;
      bullet.circle.radius = 4;
      bullet.circle.fill = am4core.color('#48ae95');
      bullet.circle.stroke = am4core.color('#48ae95');
      const bullethover = bullet.states.create('hover');
      bullethover.properties.scale = 1.3;

      chart.cursor = new am4charts.XYCursor();
      chart.strokeLinecap = 'round';
      //const scrollbarX = new am4charts.XYChartScrollbar();
      //scrollbarX.series.push(series);
      //chart.scrollbarX = scrollbarX;
      chart.maxZoomCount = 1;
      this.chart = chart;
    });
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
        delete this.chart;
      }
    });
  }
  handleCancel() {
    this.isVisibleChart = false;
    this.closeChart.emit(false);
  }
  toggleChart(id: any) {
    const item = this.datas.find((en) => en.observationTypeId === id);
    if (item) {
      item.visible = !item.visible;
    }
  }
}
