<div class="tab-list-apm">
  <div class="search-bar" style="display: flex">
    <div class="search-bar-left">
      <input
        class="txt_Search mgr15"
        [(ngModel)]="keyword.patientName"
        id="txtFullName"
        type="text"
        autocomplete="off"
        placeholder="Tên bệnh nhân *"
        (keydown.enter)="searchListVisit()" />
      <input
        class="txt_Search mgr15"
        [(ngModel)]="keyword.patientCode"
        id="txtCode"
        type="text"
        autocomplete="off"
        placeholder="Mã bệnh nhân *"
        (keydown.enter)="searchListVisit()" />
      <input
        class="txt_Search mg-r-10"
        [(ngModel)]="keyword.patientPhone"
        id="txtPhone"
        type="text"
        autocomplete="off"
        placeholder="Số điện thoại *"
        (keydown.enter)="searchListVisit()" />
      <div (click)="resetSearch()" class="pointer mg-r-10">
        <img src="assets/image/DPicon/icon-close.svg" />
      </div>
      <div (click)="searchListVisit()" class="pointer mg-r-10">
        <img src="assets/image/DPicon/icon-search.svg" />
      </div>
      <div class="pointer mg-r-10">
        <img src="assets/image/DPicon/icon-gear.svg" />
      </div>
    </div>
    <div class="search-bar-right">
      <button
        class="normal-btn btn-primary-medium"
        type="button"
        (click)="showPopupAdd()">
        Thêm lịch hẹn
      </button>
    </div>
  </div>
  <div style="position: relative">
    <span class="column-btn" (click)="showCustomColumn()">
      <i nz-icon nzType="table" nzTheme="outline" class="fs-16"></i>
    </span>

    <nz-table
      class="main-table-grid listvs-only"
      [nzScroll]="{ y: 'calc(100vh - 320px)' }"
      #fixedTable
      [nzData]="rawData"
      [nzTotal]="totalVs"
      [(nzPageIndex)]="pageIndex"
      [(nzPageSize)]="pageSize"
      (nzQueryParams)="getListAppointment()"
      [nzFrontPagination]="false"
      nzShowSizeChanger
      [nzPageSizeOptions]="[10, 20, 50, 100]">
      <thead>
        <tr>
          <ng-container *ngFor="let col of showCols">
            <th
              *ngIf="col.width"
              [nzColumnKey]="col.title"
              nz-resizable
              [nzWidth]="col.width"
              [nzMinWidth]="col.minWidth"
              nzBounds="window"
              nzPreview
              (nzResizeEnd)="onResizeCloumn($event, col.title)">
              {{ col.title }}
              <nz-resize-handle nzDirection="right">
                <div class="resize-trigger"></div>
              </nz-resize-handle>
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of fixedTable.data; index as i"
          class="pointer"
          (click)="showPopupEdit(data)">
          <!-- <td>{{ pageSize * (pageIndex - 1) + i + 1 }}</td>
        <td>{{ data.statusText }}</td>
        <td>{{ data.fullName }}</td>
        <td>{{ data.patientCode }}</td>
        <td class="text-nowrap">{{ data.createdDate | date: "dd/MM/yyyy" }}</td>
        <td class="text-nowrap">
          {{ data.dateAppointment | date: "dd/MM/yyyy" }}
        </td>
        <td class="text-nowrap">{{ convertToDate(data) | date: "HH:mm" }}</td>
        <td>{{ data.request }}</td>
        <td>{{ data.note }}</td> -->

          <ng-container *ngFor="let col of showCols">
            <ng-container [ngSwitch]="col.field">
              <td *ngSwitchCase="'STT'" class="text-nowrap">
                {{ pageSize * (pageIndex - 1) + i + 1 }}
              </td>
              <td *ngSwitchCase="'createdDate'" class="text-nowrap">
                {{ data.createdDate | date: 'dd/MM/yyyy' }}
              </td>
              <td *ngSwitchCase="'dateAppointment'" class="text-nowrap">
                {{ data.dateAppointment | date: 'dd/MM/yyyy' }}
              </td>
              <td *ngSwitchCase="'hourAppointment'" class="text-nowrap">
                {{ convertToDate(data) | date: 'HH:mm' }}
              </td>
              <td *ngSwitchDefault>
                {{ data[col.field] }}
              </td>
            </ng-container>
          </ng-container>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
<!-- popup Xử lý thông tin hẹn khám -->
<app-appointment-edit
  [visible]="isVisibleEdit"
  [Id]="appointmentId"
  (closeModal)="closeModalEdit()"
  (refresh)="refreshData()"></app-appointment-edit>
<app-custom-column
  [(isVisible)]="isCustomColumnVisible"
  [showColumn]="showCols"
  [hideColumn]="hideCols"
  (saveColumns)="changeColumns($event)"></app-custom-column>
