<ng-container *ngIf="isVisible">
  <!-- <nz-modal [nzVisible]="isVisible" appModalMovable [modalId]="'note'+noteId" [nzClassName]="'note'+noteId"
    [nzWidth]="770" [nzMaskClosable]="false" (nzOnCancel)='handleCancel()' nzTitle="Master note" [nzMask]="false">
    <angular-editor *ngIf="isVisible" [config]="editorConfig" [(ngModel)]="content" [placeholder]="'Enter text here...'"
      (paste)="onPaste($event)"></angular-editor>
  </nz-modal> -->
  <!-- <div style="width: 800px;" appCustomModalMovable [modalId]="'note'+noteId" [needFocus]="true" *ngIf="isVisible"
    class="msc-custom-modal" nzClosable="false" nzMaskClosable="false" #materNoteEnlarge>
    <div class="div-header">
      <div>{{patientName}} <span class="italic">{{visitDate | date: "dd/MM/yyyy hh:mm:ss"}}</span></div>
      <a (click)="handleCancel()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </a>
    </div>
    <div class="div-content">
      <angular-editor *ngIf="isVisible" [config]="editorConfig" [(ngModel)]="content" #htmlEditor
        [placeholder]="'Enter text here...'" (paste)="onPaste($event)"></angular-editor>
    </div>
    <div class="div-action">
      <button nz-button (click)="handleCancel()">Hủy</button>
      <button nz-button nzType="primary" (click)="updateNotes()" class="button-submit">Lưu</button>
    </div>
  </div> -->
  <app-m-modal
    [modalId]="'note' + noteId"
    [isVisible]="isVisible"
    (closeModal)="handleCancel()"
    [mMask]="true"
    [height]="600"
    [minHeight]="400"
    [minWidth]="500">
    <ng-template #mModalTitle>
      <div>
        {{ patientName }}
        <span class="italic">{{
          visitDate | date: 'dd/MM/yyyy hh:mm:ss'
        }}</span>
      </div>
    </ng-template>
    <ng-template #mModalContent>
      <angular-editor
        *ngIf="isVisible"
        [config]="editorConfig"
        [(ngModel)]="content"
        #htmlEditor
        [placeholder]="'Enter text here...'"
        (paste)="onPaste($event)"></angular-editor>
    </ng-template>
    <ng-template #mModalFooter>
      <button nz-button (click)="handleCancel()">Hủy</button>
      <button
        nz-button
        nzType="primary"
        (click)="updateNotes()"
        class="button-submit">
        Lưu
      </button>
    </ng-template>
  </app-m-modal>

  <div
    style="width: 600px"
    appCustomModalMovable
    *ngIf="isVisibleImageEditor"
    class="msc-custom-modal image-modal"
    nzClosable="false"
    nzMaskClosable="false">
    <div class="div-header">
      <div>Ảnh</div>
      <a (click)="handleCancelShowImage()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </a>
    </div>
    <div class="div-content">
      <table style="width: 100%">
        <tr>
          <td>
            <input
              nz-input
              [disabled]="true"
              class="custom-text"
              type="text"
              id="txtImageUrl"
              [(ngModel)]="curImageUrl"
              style="width: 393px" />
          </td>
          <td>
            <nz-upload
              nz-col
              [nzSpan]="16"
              [nzBeforeUpload]="beforeUpload"
              [nzCustomRequest]="handleUploadContactAvatar"
              nzName="contactIdBack"
              [nzShowUploadList]="false"
              (nzChange)="handleChangeContactAvatar($event)">
              <button nz-button>
                <i nz-icon nzType="upload"></i>Click to Upload
              </button>
            </nz-upload>
          </td>
        </tr>
      </table>
      <br />
      <table style="width: 100%">
        <tr>
          <td>
            <div class="div-inline">
              <label class="size-label">Width: </label>
              <input
                nz-input
                class="custom-text size-input"
                type="number"
                [(ngModel)]="imgWidth"
                (ngModelChange)="changeImageWidth()"
                [min]="0" />
            </div>
            <div class="div-inline">
              <label class="size-label">Height: </label>
              <input
                nz-input
                class="custom-text size-input"
                type="number"
                [(ngModel)]="imgHeight"
                (ngModelChange)="changeImageHeight()"
                [min]="0" />
            </div>
          </td>
          <!-- <td width="100px">
            <div>
              <a (click)="toggleLockImage()">
                <ng-container *ngIf="isImageLock">
                  <span class='fa fa-fw fa-lock'></span>
                </ng-container>
                <ng-container *ngIf="!isImageLock">
                  <span class='fa fa-fw fa-unlock'></span>
                </ng-container>
              </a>
            </div>
            <div>
              <a (click)="setDefaultImageSize()">
                <span class='fa fa-fw fa-redo'></span>
              </a>
            </div>
          </td> -->
          <td>
            <div
              style="
                width: 300px;
                height: 200px;
                overflow: auto;
                border: solid 1px #ccc;
              ">
              <nz-spin [nzSpinning]="isLoading" style="height: 100%">
                <img
                  id="uploadImg"
                  *ngIf="curImageUrl"
                  [style]="toImageStyle()"
                  [src]="curImageUrl"
                  #imageUpload />
              </nz-spin>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="div-action">
      <button nz-button (click)="handleCancelShowImage()">Hủy</button>
      <button
        nz-button
        nzType="primary"
        (click)="handleImageOk()"
        class="button-submit">
        Lưu
      </button>
    </div>
  </div>
</ng-container>
