import { NzModalService } from 'ng-zorro-antd/modal';
import { FilterItem } from 'src/app/models/filter-model/filter-item.class';
import { Constant } from './../../../share/constants/constant.class';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, interval } from 'rxjs';
import { AppConfigService } from 'src/app-config.service';
import { WorklistColumn } from 'src/app/models/worklist-column';
import { CustomColumnService } from 'src/app/services/custom-cloumn.service';
import { ListVisitService } from 'src/app/services/listVisit-service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { TabDataService } from 'src/app/share/base-service/data-worklist.service';
import { AppState } from '../../app-state/app-state';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzResizeEvent } from 'ng-zorro-antd/resizable';
import { SearchListPatient } from 'src/app/models/list-patient.class';
import { PatientService } from 'src/app/services/patient.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PatientRoomService } from 'src/app/services/patient-room.service';
import { VisitService } from 'src/app/services/visit.service';
import { GroupTreeService } from 'src/app/services/groupTree.service';
import { OpenAddVisitUpdate } from '../../modal/redux/modal/modal.action';

const defaultFilters: FilterItem[] = [
  {
    field: 'patientName',
    label: 'Tên bệnh nhân',
    type: Constant.FILTER_TYPE.INPUT,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: true,
    keyField: '',
    valueField: '',
    isOT: false,
    options: [],
  },
  {
    field: 'coordinatorId',
    label: 'Điều phối viên',
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: '',
    searchable: true,
    clearable: true,
    isUsed: true,
    isOT: false,
    keyField: 'fullName',
    valueField: 'id',
    options: [],
  },
  {
    field: 'doctorInCareDoctId',
    label: 'BS phụ trách',
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: true,
    keyField: 'fullName',
    valueField: 'id',
    options: [],
  },
  {
    field: 'pharmacistId',
    label: 'Dược sĩ lâm sàng',
    isOT: false,
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: 'fullName',
    valueField: 'id',
    options: [],
  },
  {
    field: 'psychiatristId',
    label: 'Chuyên viên tâm lý',
    isOT: false,
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: 'fullName',
    valueField: 'id',
    options: [],
  },
  {
    field: 'nursingInCareId',
    label: 'Điều dưỡng phụ trách',
    isOT: false,
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: 'fullName',
    valueField: 'id',
    options: [],
  },
  {
    field: 'volunteerId',
    label: 'Tình nguyện viên',
    isOT: false,
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: true,
    keyField: 'fullName',
    valueField: 'id',
    options: [],
  },
  {
    field: 'roomId',
    label: 'Room',
    isOT: false,
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: 'name',
    valueField: 'id',
    options: [],
  },
  {
    field: 'endTreatment',
    label: 'Trạng thái điều trị',
    isOT: false,
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: 1,
    searchable: false,
    clearable: false,
    isUsed: true,
    keyField: 'label',
    valueField: 'value',
    options: [
      {
        value: 10,
        label: 'Tất cả',
      },
      {
        value: 1,
        label: 'Đang điều trị',
      },
      {
        value: 2,
        label: 'Kết thúc điều trị',
      },
    ],
  },
  {
    field: 'newPrescription',
    label: 'Đơn thuốc mới',
    isOT: false,
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: 10,
    searchable: false,
    clearable: false,
    isUsed: false,
    keyField: 'label',
    valueField: 'value',
    options: [
      {
        value: 10,
        label: 'Tất cả',
      },
      {
        value: 1,
        label: 'Có đơn mới',
      },
      {
        value: 2,
        label: 'Không có đơn mới',
      },
    ],
  },
  {
    field: 'reported',
    label: 'Trạng thái report',
    isOT: false,
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: 10,
    searchable: false,
    clearable: false,
    isUsed: false,
    keyField: 'label',
    valueField: 'value',
    options: [
      {
        value: 10,
        label: 'Tất cả',
      },
      {
        value: 1,
        label: 'Đã report',
      },
      {
        value: 2,
        label: 'Chưa report',
      },
    ],
  },
  {
    field: 'hasDiseases',
    label: 'Bệnh nền',
    isOT: false,
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: 99,
    searchable: false,
    clearable: false,
    isUsed: false,
    keyField: 'label',
    valueField: 'value',
    options: [
      {
        value: 99,
        label: 'Tất cả',
      },
      {
        value: 1,
        label: 'Có',
      },
      {
        value: 2,
        label: 'Không',
      },
    ],
  },
  {
    field: 'isVisited',
    label: 'Đã được khám',
    isOT: false,
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: 10,
    searchable: false,
    clearable: false,
    isUsed: false,
    keyField: 'label',
    valueField: 'value',
    options: [
      {
        value: 10,
        label: 'Tất cả',
      },
      {
        value: 1,
        label: 'Đã khám',
      },
      {
        value: 2,
        label: 'Chưa khám',
      },
    ],
  },
  {
    field: 'vaccinated',
    label: 'Số mũi đã tiêm',
    isOT: false,
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: 99,
    searchable: false,
    clearable: false,
    isUsed: false,
    keyField: 'label',
    valueField: 'value',
    options: [
      {
        value: 99,
        label: 'Tất cả',
      },
      {
        value: 0,
        label: 'Chưa tiêm',
      },
      {
        value: 1,
        label: '1 mũi',
      },
      {
        value: 2,
        label: '2 mũi',
      },
    ],
  },
  {
    field: 'address',
    label: 'Địa chỉ',
    isOT: false,
    type: Constant.FILTER_TYPE.INPUT,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'phone',
    label: 'Số điện thoại',
    isOT: false,
    type: Constant.FILTER_TYPE.INPUT,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'patientCode',
    label: 'Mã bệnh nhân',
    isOT: false,
    type: Constant.FILTER_TYPE.INPUT,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'districtName',
    label: 'Quận/Huyện',
    isOT: false,
    type: Constant.FILTER_TYPE.INPUT,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'wardName',
    label: 'Phường/Xã',
    isOT: false,
    type: Constant.FILTER_TYPE.INPUT,
    defaultValue: '',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'gender',
    label: 'Giới tính',
    isOT: false,
    type: Constant.FILTER_TYPE.SELETECTION,
    defaultValue: -1,
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: 'label',
    valueField: 'value',
    options: [
      {
        value: 0,
        label: 'Nữ',
      },
      {
        value: 1,
        label: 'Nam',
      },
      {
        value: 2,
        label: 'Không xác định',
      },
    ],
  },
  {
    field: 'SOT',
    label: 'Sốt',
    isOT: true,
    type: Constant.FILTER_TYPE.SELETECTION,
    otDatatype: Constant.DATATYPENUMBER.BOOLEAN,
    defaultValue: '10',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: 'label',
    valueField: 'value',
    options: [
      {
        value: '1',
        label: 'Có',
      },
      {
        value: '0',
        label: 'Không',
      },
      {
        value: '10',
        label: 'Tất cả',
      },
    ],
  },
  {
    field: 'NHIETDO',
    label: 'Nhiệt độ bé hơn',
    isOT: true,
    type: Constant.FILTER_TYPE.INPUT_NUMBER,
    otDatatype: Constant.DATATYPENUMBER.NUMBER,
    operator: '<=',
    defaultValue: null,
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'NHIETDO',
    label: 'Nhiệt độ lớn hơn',
    isOT: true,
    type: Constant.FILTER_TYPE.INPUT_NUMBER,
    otDatatype: Constant.DATATYPENUMBER.NUMBER,
    operator: '>=',
    defaultValue: null,
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'LP35925-4',
    label: 'BMI bé hơn',
    isOT: true,
    type: Constant.FILTER_TYPE.INPUT_NUMBER,
    otDatatype: Constant.DATATYPENUMBER.NUMBER,
    operator: '<=',
    defaultValue: null,
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'LP35925-4',
    label: 'BMI lớn hơn',
    isOT: true,
    type: Constant.FILTER_TYPE.INPUT_NUMBER,
    otDatatype: Constant.DATATYPENUMBER.NUMBER,
    operator: '>=',
    defaultValue: null,
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'SPO2',
    label: 'SpO2',
    isOT: true,
    type: Constant.FILTER_TYPE.SELETECTION,
    otDatatype: Constant.DATATYPENUMBER.SINGLECHOICE,
    defaultValue: '000000000000000000000000',
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: 'Text',
    valueField: '_id',
    options: [
      {
        Text: '>96%',
        _id: '60fa2d66a060ec1a73cdda1d',
        ValueNumber: 4.0,
      },
      {
        Text: '96%',
        _id: '60fa2d66a060ec1a73cdda1e',
        ValueNumber: 3.0,
      },
      {
        Text: '94-95%',
        _id: '60fa2d66a060ec1a73cdda1f',
        ValueNumber: 2.0,
      },
      {
        Text: '<94%',
        _id: '60fa2d66a060ec1a73cdda20',
        ValueNumber: 1.0,
      },
      {
        Text: 'Tất cả',
        _id: '000000000000000000000000',
        ValueNumber: -1,
      },
    ],
  },
  {
    field: 'minAge',
    label: 'Tuổi nhỏ nhất',
    isOT: false,
    type: Constant.FILTER_TYPE.INPUT_NUMBER,
    defaultValue: null,
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'maxAge',
    label: 'Tuổi lớn nhất',
    isOT: false,
    type: Constant.FILTER_TYPE.INPUT_NUMBER,
    defaultValue: null,
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'dateNoMin',
    label: 'Ngày bệnh nhỏ nhất',
    isOT: false,
    type: Constant.FILTER_TYPE.INPUT_NUMBER,
    defaultValue: null,
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'dateNoMax',
    label: 'Ngày bệnh lớn nhất',
    isOT: false,
    type: Constant.FILTER_TYPE.INPUT_NUMBER,
    defaultValue: null,
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
  {
    field: 'receptionDate',
    label: 'Ngày tiếp nhận',
    isOT: false,
    type: Constant.FILTER_TYPE.DATE_PICKER,
    defaultValue: null,
    searchable: false,
    clearable: true,
    isUsed: false,
    keyField: '',
    valueField: '',
    options: [],
  },
];
@Component({
  selector: 'app-list-patient',
  templateUrl: './list-patient.component.html',
  styleUrls: ['./list-patient.component.scss'],
})
export class ListPatientComponent implements OnInit, OnDestroy, AfterViewInit {
  currentFilters: FilterItem[] = [];
  defaultConfig: WorklistColumn[] = [];
  otCols = [];
  lstPatient;
  resVisit;
  selectedGroupId = '';
  exporting = false;
  localStorageName = 'worklist-column';
  today: Date = new Date();
  search: SearchListPatient = {
    keyword: '',
    patientName: '',
    address: '',
    phone: '',
    patientCode: '',
    doctorName: '',
    from: '',
    to: '',
    inGroup: true,
    pageSize: 50,
    page: 1,
    newId: '',
    groupId: '',
    roomId: '',
    doctorInCareDoctId: null,
    nursingInCareId: null,
    sosRange: 0,
    psychiatristId: null,
    pharmacistId: null,
    coordinatorId: null,
    volunteerId: null,
    endTreatment: 1,
    newPrescription: 10,
    reported: 10,
    isVisited: 10,
    districtName: '',
    wardName: '',
    gender: null,
    minAge: null,
    maxAge: null,
    dateNoMin: null,
    dateNoMax: null,
    vaccinated: 99,
    hasDiseases: 99,
    receptionDate: null,
    oTSearchFields: [],
  };
  isVisibleDuplicatePatient;
  dataTooltip = {
    id: null,
    patientName: null,
    genderText: null,
    dob: null,
    age: null,
    email: null,
    phoneNo: null,
    address: null,
    doctorName: null,
    patientCode: null,
    visitdate: null,
    visitTypeText: null,
    color: null,
    shortName: null,
    avatar: null,
  };
  baseAvatarurl = '';
  //scrollX: string = '1100px';
  scrollY: string = window.innerHeight - 305 + 'px';
  pageIndex = 1;
  pageSize = 15;
  total = 100;
  loading = false;
  resetFilter = false;
  doctorInGroup: any[] = [];

  listDoctorInCase: any[] = [];
  listCoordinator: any[] = [];
  listPharmacist: any[] = [];
  listPsychiatrist: any[] = [];
  listNursingInCase: any[] = [];

  checkColumnWidth = '60px';
  resizing = false;
  isCustomColumnVisible = false;

  showCols: WorklistColumn[] = [];
  hideCols: WorklistColumn[] = [];
  groupSub: Subscription;
  lockScreenSub: Subscription;

  currentUserId = '';
  userPermissionInGroup: number[] = [];
  intervalReloadGrid: any;
  reloadTime = 10000;
  isCoordinator = false;
  isAdminGroup = false;
  isCovidGroup = false;
  isFilterEndTreatment = false;

  isVisibleEditStatus = false;
  titlePopup = '';
  isVisibleAddPatientRoom = false;
  isVisibleMovePatientRoom = false;
  isVisibleCovidNumberModal = false;
  titlePatientRoom = '';
  selectedAddRoomId = '';
  selectedMoveRoomId = '';

  formEditStatus: FormGroup;
  initialEditStatus: any;

  covidNumber = '';
  seletedTreatmeant: any = {}; //dùng khi active context menu
  listOfRoomOptions: any[] = [];
  listOfMoveRoomOptions: any[] = [];
  roomsInGroup: any[] = [];
  //viewEndTreatment: number = 1;
  columnSortFilter: any[] = [
    {
      value: 'sos',
      label: 'Mức độ cảnh báo',
    },
    {
      value: 'visit',
      label: 'Ca khám',
    },
    {
      value: 'modified',
      label: 'Lần cập nhật',
    },
    {
      value: 'name',
      label: 'Tên bệnh nhân',
    },
    {
      value: 'age',
      label: 'Tuổi',
    },
  ];

  sortColumn = 'sos';
  sortColumnOrder = '1'; // 0 == asc, 1 == desc

  constructor(
    private tabDataService: TabDataService,
    private configService: AppConfigService,
    private listVisitService: ListVisitService,
    private notificationService: NotificationService,
    private shareService: ShareService,
    private customColumnService: CustomColumnService,
    private store: Store<AppState>,
    private patientService: PatientService,
    private doctorService: DoctorService,
    private nzContextMenuService: NzContextMenuService,
    private modalService: NzModalService,
    private fb: FormBuilder,
    private roomService: PatientRoomService,
    private visitService: VisitService,
    private groupTreeService: GroupTreeService
  ) {
    this.currentUserId = JSON.parse(localStorage.getItem(Constant.USER_INFO)).userId;
    this.formEditStatus = this.fb.group({
      isEndTreatmented: [true],
      typeEndTreatmented: [0],
      dateEndTreatmented: [null, [Validators.required]],
    });
    this.initialEditStatus = this.formEditStatus.value;
    this.reloadTime = this.configService.getConfig().reloadTimePatient
      ? this.configService.getConfig().reloadTimePatient
      : 120000;
    this.currentFilters = JSON.parse(JSON.stringify(defaultFilters));
    this.scrollY = window.innerHeight > 900 ? window.innerHeight - 305 + 'px' : window.innerHeight - 280 + 'px';
  }
  get f() {
    return this.formEditStatus.controls;
  }
  ngOnInit(): void {
    this.baseAvatarurl = this.configService.getConfig().media.baseUrl + '/Avatar/';

    this.groupSub = this.store.select('groupAuth').subscribe((data) => {
      if (data.isAuthenticated) {
        this.sortColumn = data.defaultSortBy;
        if (!this.shareService.checkEmpty(data)) {
          this.selectedGroupId = data.groupId;
          this.getDoctorInGroup(this.selectedGroupId);
          this.getRoomInGroup(this.selectedGroupId);
          if (data.role == Constant.UserRoleType.Admin || data.role == Constant.UserRoleType.Owner) {
            this.isAdminGroup = true;
          }

          if (data.isCovidGroup) {
            this.isCovidGroup = true;
          }
        }
        this.userPermissionInGroup =
          data.doctorPermission == null ? [] : data.doctorPermission.slice().sort((a, b) => a - b);
        //this.userPermissionInGroup = this.userPermissionInGroup.slice().sort((a, b)=> (a-b));
        this.isCoordinator = this.userPermissionInGroup.some((en) => en == Constant.DOCTORPERMISSION.Coordinator);

        // console.log("this.userPermissionInGroup", this.userPermissionInGroup);
        if (data.isLoadByPermission) {
          //lấy role đầu tiên để search
          const permis = Math.min(...this.userPermissionInGroup);
          // console.log("permis", permis, this.userPermissionInGroup);
          if (permis == Constant.DOCTORPERMISSION.Doctor) {
            this.search.doctorInCareDoctId = this.currentUserId;
          } else if (permis == Constant.DOCTORPERMISSION.Coordinator) {
            this.search.coordinatorId = this.currentUserId;
          } else if (permis == Constant.DOCTORPERMISSION.Nursing) {
            this.search.nursingInCareId = this.currentUserId;
          } else if (permis == Constant.DOCTORPERMISSION.Pharmacist) {
            this.search.pharmacistId = this.currentUserId;
          } else if (permis == Constant.DOCTORPERMISSION.Psychiatrist) {
            this.search.psychiatristId = this.currentUserId;
          } else if (permis == Constant.DOCTORPERMISSION.Volunteer) {
            this.search.volunteerId = this.currentUserId;
          }
        }
      }
    });

    this.lockScreenSub = this.store
      .select((store) => store.auth.isLock)
      .subscribe((data) => {
        if (this.intervalReloadGrid) {
          clearTimeout(this.intervalReloadGrid);
        }

        if (!data) {
          this.getListPatientTreatment();
        }
      });
    this.getColumns();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.intervalReloadGrid) {
      clearTimeout(this.intervalReloadGrid);
    }
    this.groupSub.unsubscribe();
    this.lockScreenSub.unsubscribe();
  }

  getPatientGridDefaultColumn() {
    return new Promise((resolve, reject) => {
      this.customColumnService.getPatientGridDefaultColumn().subscribe(
        (res) => {
          if (res.isValid) {
            let defaultConfig = res.jsonData;
            let otCols = this.defaultConfig.filter((t) => t.type == 'OT').map((t) => t.field);
            resolve({ defaultConfig, otCols });
          }
        },
        (error) => {
          reject();
          console.log(error);
          this.notificationService.showNotification('', 'Có lỗi xảy ra');
        }
      );
    });
  }

  getGroupInfo() {
    return new Promise((resolve, reject) => {
      this.groupTreeService.getGroupInfo(this.selectedGroupId).subscribe(
        (response) => {
          let groupColumns = response.defaultColumnDetail || [];
          resolve({ groupColumns });
        },
        (error) => {
          reject();
          console.log(error);
          this.notificationService.showNotification('', 'Có lỗi xảy ra');
        }
      );
    });
  }

  getPatientGridColumn() {
    return new Promise((resolve, reject) => {
      this.customColumnService.getPatientGridColumn(this.selectedGroupId).subscribe((res) => {
        if (res.isValid) {
          const patientColumns = res.jsonData;
          resolve({ patientColumns });
        } else {
          reject();
        }
      });
    });
  }

  getColumns() {
    Promise.all([this.getPatientGridDefaultColumn(), this.getGroupInfo(), this.getPatientGridColumn()]).then(
      (val: any[]) => {
        this.defaultConfig = val[0].defaultConfig;
        this.otCols = val[0].otCols;
        let groupColumns = val[1].groupColumns;
        const patientColumns = val[2].patientColumns;
        // console.log(this.defaultConfig, this.otCols, groupColumns, patientColumns);
        let curColumn = [];

        if (!groupColumns || groupColumns.length == 0) {
          groupColumns = this.defaultConfig;
        }

        if (patientColumns && patientColumns.length > 0) {
          patientColumns.forEach((element) => {
            let elm = groupColumns.find((t) => t.field == element.field);
            if (!this.shareService.checkEmptyObj(elm)) {
              curColumn.push(elm);
            }
          });
        } else {
          curColumn = JSON.parse(JSON.stringify(groupColumns));
        }

        this.showCols = curColumn;
        this.hideCols = groupColumns.filter((x) => !this.showCols.map((t) => t.field).includes(x.field));
        // console.log(this.showCols);
      }
    );
  }

  getOTTypeValues(patient, colFieldsOnly: any[]) {
    // let colFieldsOnly =fieldNames.map(t => t.field);
    const inValid = false;
    patient.lstVitalSigns = patient.lstVitalSigns.filter((t) => colFieldsOnly.includes(t.otCode));
    for (let i = 0; i < patient.lstVitalSigns.length; i++) {
      const elm = patient.lstVitalSigns[i];
      if (elm.dataType == Constant.DATATYPENUMBER.BOOLEAN) {
        patient[elm.otCode] = this.convertBooleanValue(elm.value2Show);
      } else {
        patient[elm.otCode] = elm.value2Show;
      }
    }
  }

  convertBooleanValue(val) {
    if (val == undefined || val == null) {
      return null;
    }

    if (val == true) {
      return 'Có';
    } else {
      ('Không');
    }
  }

  saveColumns() {
    const columns = this.showCols.map((t) => ({
      field: t.field,
      width: t.width,
    }));
    const payload = { columns };
    this.customColumnService.savetPatientGridColumn(payload, this.selectedGroupId).subscribe(
      (res) => {
        if (res.isValid) {
          this.hideCols = this.defaultConfig.filter((x) => !this.showCols.map((t) => t.field).includes(x.field));
        }
      },
      (error) => {
        console.log(error);
        this.notificationService.showNotification('', 'Có lỗi xảy ra');
      }
    );
  }

  changeColumns(cols: WorklistColumn[]) {
    const columns = cols.map((t) => ({ field: t.field, width: t.width }));
    const payload = { columns };
    this.customColumnService.savetPatientGridColumn(payload, this.selectedGroupId).subscribe(
      (res) => {
        if (res.isValid) {
          this.getColumns();
        }
      },
      (error) => {
        console.log(error);
        this.notificationService.showNotification('', 'Có lỗi xảy ra');
      }
    );
  }

  reloadDataTable() {
    this.pageIndex = 1;
    //run when choose different groupId
    if (!this.shareService.checkEmpty(this.selectedGroupId)) {
      // this.tabDataService.changeGroup();
      this.search = {
        keyword: '',
        patientName: '',
        address: '',
        phone: '',
        patientCode: '',
        doctorName: '',
        from: '',
        to: '',
        inGroup: true,
        pageSize: this.pageSize,
        page: this.pageIndex,
        newId: '',
        groupId: this.selectedGroupId,
        roomId: '',
        doctorInCareDoctId: '',
        nursingInCareId: '',
        sosRange: 0,
        psychiatristId: '',
        pharmacistId: '',
        coordinatorId: '',
        volunteerId: '',
        endTreatment: 1,
        newPrescription: 10,
        isVisited: 10,
        reported: 10,
        districtName: '',
        wardName: '',
        gender: null,
        minAge: null,
        maxAge: null,
        vaccinated: 99,
        dateNoMin: null,
        dateNoMax: null,
        hasDiseases: 99,
        receptionDate: null,
      };
      this.apiListPatient(this.search);
    }
  }

  apiListPatient(data) {
    const date = new Date();
    this.today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const $this = this;
    this.loading = true;
    if (this.intervalReloadGrid) {
      clearTimeout(this.intervalReloadGrid);
    }

    this.intervalReloadGrid = setTimeout(function () {
      $this.getListPatientTreatment();
    }, this.reloadTime);

    if (data.gender === null) {
      data.gender = -1;
    }

    // short column field
    data.field = this.sortColumn;
    data.sort = this.sortColumnOrder;

    this.patientService.getListTreatmentPatientGroup(data).subscribe(
      (resdata) => {
        if (resdata !== null) {
          this.lstPatient = resdata;
          this.total = this.lstPatient.itemCount;
          this.resVisit = this.lstPatient.source;
          if (this.resVisit != null) {
            this.resVisit.forEach((element) => {
              if (element.hasOwnProperty('otValues') && element.otValues) {
                const otValuesRes = element.otValues;
                otValuesRes.forEach((el) => {
                  let field = el.code;
                  let value = el.value;
                  element[field] = value;
                });
              }
            });
          }
          // console.log(this.resVisit);
        }

        this.loading = false;
      },
      (error) => {
        console.error(error);
        this.notificationService.showNotification('', error.statusText);
        this.loading = false;
      }
    );
  }

  exportListPatient(data) {
    console.log(data);
    this.exporting = true;
    const date = new Date();
    this.today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const $this = this;

    if (data.gender === null) {
      data.gender = -1;
    }

    // short column field
    data.field = this.sortColumn;
    data.sort = this.sortColumnOrder;

    this.patientService.exportListTreatmentPatientGroup(data).subscribe((res) => {
      if (res && res.size > 0) {
        const filename = 'Danh sách bệnh nhân';
        const dataType = res.type;
        const binaryData = [];
        binaryData.push(res);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        if (filename) {
          downloadLink.setAttribute('download', filename);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
      } else {
        this.notificationService.showNotification(
          Constant.NOTIFY_TYPE.ERROR,
          'Không thể xuất file, vui lòng liên hệ KTV'
        );
      }

      this.exporting = false;
    });
  }

  resetSearch() {
    for (let i = 0; i < defaultFilters.length; i++) {
      this.currentFilters[i].isUsed = defaultFilters[i].isUsed;
      this.search[this.currentFilters[i].field] = defaultFilters[i].defaultValue;
    }
    this.resetFilter = true;
    setTimeout(() => {
      this.resetFilter = false;
    }, 100);
    // this.search.patientName = '';
    // this.search.doctorInCareDoctId = '';
    // this.search.nursingInCareId = '';
    // this.search.coordinatorId = '';
    // this.search.pharmacistId = '';
    // this.search.psychiatristId = '';
    this.pageIndex = 1;
    this.getListPatientTreatment();
  }

  searchListPatient() {
    this.pageIndex = 1;
    this.getListPatientTreatment();
  }

  exportListPatientTreatment() {
    if (this.exporting) {
      return;
    }
    if (!this.shareService.checkEmpty(this.selectedGroupId)) {
      const data = {
        patientName: this.search.patientName ? this.search.patientName.trim() : '',
        patientCode: this.search.patientCode,
        phone: this.search.phone,
        keyword: '',
        address: this.search.address,
        doctorName: '',
        from: '',
        to: '',
        inGroup: true,
        pageSize: this.pageSize,
        page: this.pageIndex,
        newId: '',
        groupId: this.selectedGroupId,
        doctorInCareDoctId: this.search.doctorInCareDoctId,
        nursingInCareId: this.search.nursingInCareId,
        psychiatristId: this.search.psychiatristId,
        pharmacistId: this.search.pharmacistId,
        coordinatorId: this.search.coordinatorId,
        volunteerId: this.search.volunteerId,
        endTreatment: this.search.endTreatment,
        newPrescription: this.search.newPrescription,
        isVisited: this.search.isVisited,
        reported: this.search.reported,
        roomId: this.search.roomId,
        districtName: this.search.districtName,
        wardName: this.search.wardName,
        gender: this.search.gender,
        minAge: this.search.minAge,
        maxAge: this.search.maxAge,
        dateNoMin: this.search.dateNoMin,
        dateNoMax: this.search.dateNoMax,
        vaccinated: this.search.vaccinated,
        oTSearchFields: this.search.oTSearchFields,
        hasDiseases: this.search.hasDiseases,
        receptionDate: this.search.receptionDate,
        cols: this.showCols,
      };
      this.exportListPatient(data);
      //this.hideToolTip();
    }
  }

  getListPatientTreatment() {
    if (!this.shareService.checkEmpty(this.selectedGroupId)) {
      const data = {
        patientName: this.search.patientName ? this.search.patientName.trim() : '',
        patientCode: this.search.patientCode,
        phone: this.search.phone,
        keyword: '',
        address: this.search.address,
        doctorName: '',
        from: '',
        to: '',
        inGroup: true,
        pageSize: this.pageSize,
        page: this.pageIndex,
        newId: '',
        groupId: this.selectedGroupId,
        doctorInCareDoctId: this.search.doctorInCareDoctId,
        nursingInCareId: this.search.nursingInCareId,
        psychiatristId: this.search.psychiatristId,
        pharmacistId: this.search.pharmacistId,
        coordinatorId: this.search.coordinatorId,
        volunteerId: this.search.volunteerId,
        endTreatment: this.search.endTreatment,
        newPrescription: this.search.newPrescription,
        isVisited: this.search.isVisited,
        reported: this.search.reported,
        roomId: this.search.roomId,
        districtName: this.search.districtName,
        wardName: this.search.wardName,
        gender: this.search.gender,
        minAge: this.search.minAge,
        maxAge: this.search.maxAge,
        dateNoMin: this.search.dateNoMin,
        dateNoMax: this.search.dateNoMax,
        vaccinated: this.search.vaccinated,
        oTSearchFields: this.search.oTSearchFields,
        hasDiseases: this.search.hasDiseases,
        receptionDate: this.search.receptionDate,
      };
      this.apiListPatient(data);
      //this.hideToolTip();
    }
  }

  showModal(): void {
    //this.store.dispatch(new OpenAddVisit('ListVisit', {}, ''));
    //this.store.dispatch(new OpenAddVisit('ListVisitContainer', {}, ''));
  }

  showCustomColumn(): void {
    this.isCustomColumnVisible = true;
  }

  addTab(id, PatientName, patientId) {
    //clearTimeout(settime);
    //$('#tooltip').hide();
    this.tabDataService.updateTab(id, PatientName, 'VisitDetail', patientId);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.getListPatientTreatment();
  }

  ngAfterViewInit() {
    const $this = this;
    setTimeout(() => {
      $(document).ready(function () {
        $('.main-table-grid .ant-table-body').addClass('customTableCrollbar');
        $('.ant-table-header::-webkit-scrollbar').css({
          'background-color': 'white !important',
        });

        //Disable load more
        // $('.main-table-grid .ant-table-body').scroll(function (e) {
        //   if (e.currentTarget.offsetHeight + e.currentTarget.scrollTop == e.currentTarget.scrollHeight) {
        //     if ($this.pageSize > $this.total) {
        //       return;
        //     }
        //     let maxIndex = Math.round($this.total / $this.pageSize / 2);
        //     if (maxIndex < $this.pageIndex) {
        //       $this.pageIndex = maxIndex;
        //     }
        //     $this.pageSize += $this.pageSize;
        //   }
        // });
      });
    }, 500);
  }

  onResizeCloumn({ width }: NzResizeEvent, col: string): void {
    this.showCols = this.showCols.map((e) => (e.title === col ? { ...e, width: `${width}px` } : e));
    this.saveColumns();
  }

  getRoomInGroup(groupId) {
    this.roomService.getAllRoomInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.roomsInGroup = [];

        this.currentFilters.forEach((element) => {
          if (element.field == 'roomId') {
            element.options = res;
          }
        });
        res.forEach((element) => {
          element.unsignName = this.shareService.toUnsign(element.name);
          this.roomsInGroup.push(element);
        });
      }
    });
  }

  getRoomInGroupForSearch(groupId) {
    this.roomService.getAllRoomInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.roomsInGroup = [];

        res.forEach((element) => {
          element.unsignName = this.shareService.toUnsign(element.name);
          this.roomsInGroup.push(element);
        });
      }
    });
  }

  getDoctorInGroup(groupId) {
    this.doctorService.getDoctorInGroup(groupId).subscribe((res) => {
      if (res && res.length) {
        this.doctorInGroup = res;
        this.currentFilters = JSON.parse(JSON.stringify(defaultFilters));
        // this.listDoctorInCase = this.doctorInGroup.filter(en => (en.doctorPermissions != null
        //   && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Doctor)));

        // this.listCoordinator = this.doctorInGroup.filter(en => (en.doctorPermissions != null
        //   && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Coordinator)));

        // this.listPharmacist = this.doctorInGroup.filter(en => (en.doctorPermissions != null
        //   && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Pharmacist)));

        // this.listPsychiatrist = this.doctorInGroup.filter(en => (en.doctorPermissions != null
        //   && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Psychiatrist)));

        // this.listNursingInCase = this.doctorInGroup.filter(en => (en.doctorPermissions != null
        //   && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Nursing)));

        const doctorInCaseField = this.currentFilters.find((t) => t.field == 'doctorInCareDoctId');
        doctorInCaseField.options = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Doctor)
        );

        const coordinatorField = this.currentFilters.find((t) => t.field == 'coordinatorId');
        coordinatorField.options = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Coordinator)
        );

        const pharmacistField = this.currentFilters.find((t) => t.field == 'pharmacistId');
        pharmacistField.options = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Pharmacist)
        );

        const psychiatristField = this.currentFilters.find((t) => t.field == 'psychiatristId');
        psychiatristField.options = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Psychiatrist)
        );

        const nursingInCareField = this.currentFilters.find((t) => t.field == 'nursingInCareId');
        nursingInCareField.options = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Nursing)
        );

        const volunteerField = this.currentFilters.find((t) => t.field == 'volunteerId');
        volunteerField.options = this.doctorInGroup.filter(
          (en) => en.doctorPermissions != null && en.doctorPermissions.includes(Constant.DOCTORPERMISSION.Volunteer)
        );
      }
    });
  }

  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent, data): void {
    // if (!this.isCoordinator) {
    //   //$event.preventDefault();
    //   return;
    // }
    this.nzContextMenuService.create($event, menu);
    this.seletedTreatmeant = data;
  }

  closeMenu(): void {
    this.nzContextMenuService.close();
  }

  openModalEditStatus() {
    const data = this.seletedTreatmeant;
    if (this.search.endTreatment == 1) {
      this.titlePopup = 'Dừng điều trị cho BN ' + data.patientName;
    } else if (this.search.endTreatment == 2) {
      this.titlePopup = 'Cập nhật trạng thái cho BN ' + data.patientName;
      this.formEditStatus.patchValue({
        isEndTreatmented: data.isEndTreatmented,
        typeEndTreatmented: data.typeEndTreatmented,
        dateEndTreatmented: data.dateEndTreatmented,
      });
    }
    this.isVisibleEditStatus = true;
  }

  openAddRoomModal() {
    this.isVisibleAddPatientRoom = true;
    this.getRoomInGroup(this.selectedGroupId);
  }

  closeAddRoomModal() {
    this.isVisibleAddPatientRoom = false;
    this.seletedTreatmeant = {};
    this.selectedAddRoomId = '';
  }

  addPaientToRoom() {
    if (!this.selectedAddRoomId || !this.seletedTreatmeant) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Bạn chưa chọn room');
    } else {
      const payload = {
        patientId: this.seletedTreatmeant.patientId,
        roomId: this.selectedAddRoomId,
      };
      this.roomService.addPatient(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Thêm thành công');
          this.closeAddRoomModal();
          this.getListPatientTreatment();
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    }
  }

  openMoveRoomModal() {
    this.isVisibleMovePatientRoom = true;
    this.getRoomInGroup(this.selectedGroupId);
  }

  closeMoveRoomModal() {
    this.isVisibleMovePatientRoom = false;
    this.seletedTreatmeant = {};
    this.selectedMoveRoomId = '';
  }

  closeCovidNumberModal() {
    this.isVisibleCovidNumberModal = false;
    this.seletedTreatmeant = {};
  }

  movePaientToRoom() {
    if (!this.selectedMoveRoomId || !this.seletedTreatmeant) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Bạn chưa chọn room');
    } else {
      const payload = {
        patientId: this.seletedTreatmeant.patientId,
        currentRoomId: this.seletedTreatmeant.roomId,
        destinationRoomId: this.selectedMoveRoomId,
      };
      this.roomService.movePatient(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Chuyển room thành công');
          this.closeMoveRoomModal();
          this.getListPatientTreatment();
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    }
  }

  openCovidNumberModal() {
    this.isVisibleCovidNumberModal = true;
    this.covidNumber = this.seletedTreatmeant.covidNumber;
  }

  updateCovidNumber() {
    if (this.shareService.checkEmptyStr(this.covidNumber)) {
      this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, 'Bạn chưa nhập mã số Covid');
      return;
    }

    const payload = {
      patientId: this.seletedTreatmeant.patientId,
      covidNumber: this.covidNumber,
    };
    this.patientService.updateCovidNumber(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
        this.closeCovidNumberModal();
        this.reloadDataTable();
      } else {
        this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
      }
    });
  }

  confirmRemovePaientToRoom() {
    if (!this.seletedTreatmeant) {
      return;
    } else {
      this.modalService.confirm({
        nzTitle: '<b>Xác nhận</b>',
        nzContent:
          'Bạn muốn xóa ' +
          this.seletedTreatmeant.patientName +
          ' ra khỏi room ' +
          this.seletedTreatmeant.roomName +
          '?',
        nzOnOk: () => this.removePaientToRoom(),
      });
    }
  }

  removePaientToRoom() {
    if (!this.seletedTreatmeant) {
      return;
    } else {
      const payload = {
        patientId: this.seletedTreatmeant.patientId,
        roomId: this.seletedTreatmeant.roomId,
      };
      this.roomService.removePatient(payload).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xóa thành công');
          this.seletedTreatmeant = {};
          this.reloadDataTable();
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    }
  }

  updateStatus() {
    if (!this.formEditStatus.valid) {
      this.notificationService.showNotification(Constant.ERROR, 'Bạn cần nhập các trường thông tin bắt buộc');
      return;
    }
    const payload = {
      patientId: this.seletedTreatmeant.patientId,
      isEnd: this.formEditStatus.value.isEndTreatmented,
      typeEnd: this.formEditStatus.value.typeEndTreatmented,
      dateEnd: this.formEditStatus.value.dateEndTreatmented,
    };
    this.patientService.editStatusTreatmentPatient(payload).subscribe((res) => {
      if (res.isValid) {
        this.notificationService.showNotification(Constant.SUCCESS, 'Cập nhật thành công!');
        // nếu đang ở danh sách dừng điều trị và trạng thái vẫn là dừng -> k remove khỏi ds
        if (this.search.endTreatment == 2 && this.formEditStatus.value.isEndTreatmented) {
          this.getListPatientTreatment();
        } else {
          this.resVisit = this.resVisit.filter((en) => en.patientId != this.seletedTreatmeant.patientId);
        }
        this.formEditStatus.reset(this.initialEditStatus);
        this.isVisibleEditStatus = false;
      } else {
        this.notificationService.showNotification(
          Constant.ERROR,
          'Cập nhật không thành công! Xin liên hệ quản trị hệ thống.'
        );
      }
    });
  }

  cancelEditStatus() {
    this.isVisibleEditStatus = false;
    this.formEditStatus.reset(this.initialEditStatus);
  }

  userReportedToText(val) {
    if (this.shareService.checkEmpty(val)) {
      return 'Chưa report';
    }

    if (val) {
      return 'Đã report';
    }

    return '';
  }

  typeEndTreatmentedToText(val) {
    switch (val) {
      case 0:
        return 'Khỏi bệnh';
      case 1:
        return 'Tử vong';
      case 2:
        return 'Chuyển viện';
      case 3:
        return 'Khác';
      default:
        return '';
    }
  }

  calcDay(item) {
    //tính theo ngày phát hiện bệnh
    // console.log(item)
    if (item.dateOfDiagnosis) {
      return 'N' + (this.shareService.calcDateFromString(this.today, item.dateOfSymptom) + 1); //TH ở Sóc Trăng thì đếm từ 1
    }

    //tính theo ngày khởi phát triệu chứng
    if (!item.dateOfDiagnosis && item.dateOfSymptom) {
      return 'N' + (this.shareService.calcDateFromString(this.today, item.dateOfSymptom) + 1); //TH ở Sóc Trăng thì đếm từ 1
    }
  }

  laboratoryValue(item) {
    //tính theo ngày phát hiện bệnh
    // console.log(item)
    if (item == true) {
      return 'Dương tính';
    }

    if (item == false) {
      return 'Âm tính';
    }

    return '';
  }

  userReportedToStatus(item) {
    if (this.shareService.checkEmpty(item) || !item) {
      return 'error';
    } else {
      return 'success';
    }
  }

  endTreatmentEvent(val) {
    // console.log('endTreatmentEvent', val);
    this.isFilterEndTreatment = val;
  }

  searchByRoom(val) {
    // console.log('endTreatmentEvent', val);
    this.isFilterEndTreatment = val;
  }

  get filterByEndtreatment() {
    if (this.isFilterEndTreatment) {
      return this.showCols;
    } else {
      return this.showCols.filter((x) => x.field != 'typeEndTreatmented');
    }
  }

  searchAddRoomUnsign(val) {
    if (!val) {
      this.listOfRoomOptions = this.roomsInGroup;
    } else {
      val = this.shareService.toUnsign(val);
      this.listOfRoomOptions = this.roomsInGroup.filter((t) => t.unsignName.includes(val));
    }
  }
  searchMoveRoomUnsign(val) {
    if (!val) {
      this.listOfMoveRoomOptions = this.roomsInGroup.filter((t) => this.seletedTreatmeant.roomId != t.id);
    } else {
      val = this.shareService.toUnsign(val);
      this.listOfMoveRoomOptions = this.roomsInGroup.filter(
        (t) => this.seletedTreatmeant.roomId != t.id && t.unsignName.includes(val)
      );
    }
  }

  mFilterChange(val) {
    this.search = val;
  }

  openModalPatientTest() {
    this.modalService.confirm({
      nzTitle: '<b>Xác nhận</b>',
      nzContent: 'Bạn muốn gán BN ' + this.seletedTreatmeant.patientName + ' là BN test không?',
      nzOkText: 'Xác nhận',
      nzCancelText: 'Không',
      nzOnOk: () => {
        const payload = { treatmentId: this.seletedTreatmeant.id };
        this.visitService.setPatientIsTest(payload).subscribe((res) => {
          if (res.isValid) {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Cập nhật thành công');
            this.getListPatientTreatment();
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
          }
        });
      },
    });
  }

  onResize(event) {
    this.scrollY = window.innerHeight > 900 ? window.innerHeight - 305 + 'px' : window.innerHeight - 280 + 'px';
  }
  showAddPatient() {
    this.store.dispatch(new OpenAddVisitUpdate('ListPatient', {}, ''));
  }
}
