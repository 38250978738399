<div class="order-content">
  <div class="order-visit">
    <div class="d-flex justify-content-between" style="margin-bottom: 15px">
      <div class="m-tab-title">Danh sách y lệnh trong ca khám</div>
      <button
        nz-button
        class="btn-primary-small"
        nzType="success"
        (click)="showModalForm('add', '')">
        <i nz-icon nzType="plus" nzTheme="outline"></i>
        Thêm mới
      </button>
    </div>
    <nz-table
      #medicalVisitTable
      class="m-table-primary"
      [(nzData)]="lstDataOfVisit"
      [nzTotal]="total"
      nzShowPagination="false"
      [nzPageSize]="100"
      nzSize="small">
      <thead>
        <tr>
          <th nzWidth="50px">STT</th>
          <th>Y lệnh</th>
          <th>Mô tả</th>
          <th>Người tạo</th>
          <th>Thời gian tạo</th>
          <th>Thời gian dự kiến</th>
          <th>Người thực hiện</th>
          <th>Trạng thái</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of medicalVisitTable.data; index as i">
          <td>{{ i + 1 }}</td>
          <td>{{ order.name }}</td>
          <td>{{ order.desc }}</td>
          <td>{{ order.creatorName }}</td>
          <td>{{ order.createdDate | date: 'dd/MM/yyyy HH:mm' }}</td>
          <td>{{ order.executeDate | date: 'dd/MM/yyyy HH:mm' }}</td>
          <td>{{ order.executorName }}</td>
          <td>{{ order.statusText }}</td>
          <td>
            <i
              *ngIf="curUserId == order.creatorId"
              nz-icon
              nzType="edit"
              class="mg-r-10"
              title="Chỉnh sửa"
              (click)="showModalForm('edit', order)"
              nzTheme="outline"></i>
            <i
              nz-icon
              nzType="info-circle"
              class="mg-r-10"
              title="Chi tiết"
              (click)="showModalForm('view', order)"
              nzTheme="outline"></i>
            <i
              nz-icon
              nzType="node-expand"
              title="Cập nhật trạng thái"
              (click)="showModalForm('updateStatus', order)"
              nzTheme="outline"></i>
            <!-- <i nz-icon nzType="delete" class="mg-r-10" title="Xóa" (click)="remove(data.id)" nzTheme="outline"></i> -->
          </td>
        </tr>
      </tbody>
      <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }}
      </ng-template>
    </nz-table>
  </div>
  <div *ngIf="lstDataOfPatient.length > 0" class="order-patient">
    <div class="m-tab-title mg-bt-15">Y lệnh của các ca khám trước</div>
    <nz-table
      #medicalPatientTable
      class="m-table-primary"
      [(nzData)]="lstDataOfPatient"
      [nzTotal]="total"
      nzShowPagination="false"
      [nzPageSize]="100"
      nzSize="small">
      <thead>
        <tr>
          <th nzWidth="50px">STT</th>
          <th>Y lệnh</th>
          <th>Mô tả</th>
          <th>Người tạo</th>
          <th>Thời gian tạo</th>
          <th>Thời gian dự kiến</th>
          <th>Người thực hiện</th>
          <th>Trạng thái</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of medicalPatientTable.data; index as i">
          <td>{{ i + 1 }}</td>
          <td>{{ order.name }}</td>
          <td>{{ order.desc }}</td>
          <td>{{ order.creatorName }}</td>
          <td>{{ order.createdDate | date: 'dd/MM/yyyy HH:mm' }}</td>
          <td>{{ order.executeDate | date: 'dd/MM/yyyy HH:mm' }}</td>
          <td>{{ order.executorName }}</td>
          <td>{{ order.statusText }}</td>
          <td>
            <i
              *ngIf="curUserId == order.creatorId"
              nz-icon
              nzType="edit"
              class="mg-r-10"
              title="Chỉnh sửa"
              (click)="showModalForm('edit', order)"
              nzTheme="outline"></i>
            <i
              nz-icon
              nzType="info-circle"
              class="mg-r-10"
              title="Chi tiết"
              (click)="showModalForm('view', order)"
              nzTheme="outline"></i>
            <i
              nz-icon
              nzType="node-expand"
              title="Cập nhật trạng thái"
              (click)="showModalForm('updateStatus', order)"
              nzTheme="outline"></i>
          </td>
        </tr>
      </tbody>
      <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} {{ 'trên' }} {{ total }}
      </ng-template>
    </nz-table>
  </div>
</div>

<app-command-modal
  [(isVisible)]="isVisibleForm"
  [isAddOder]="isAddOder"
  [visit]="visit"
  [currentOrder]="currentOrder"
  (reloadData)="reloadData()"></app-command-modal>
<app-command-detail-modal
  [(isVisible)]="isVisibleView"
  [visit]="visit"
  [currentOrder]="currentOrder"
  [curUserId]="curUserId"
  (reloadData)="reloadData()"
  (opeUpdateStatus)="opeUpdateStatus()"></app-command-detail-modal>
<app-command-state-modal
  [(isVisible)]="showUpdateStatus"
  [visit]="visit"
  [currentOrder]="currentOrder"
  (reloadData)="reloadData()"></app-command-state-modal>
