<nz-modal class="modal-admin" [(nzVisible)]="isShowConfig" nzTitle="Cấu hình màn hình chờ" nzDraggable="true"
  nzMaskClosable="false" (nzClosable)="(true)" (nzOnCancel)="handleCancel()">
  <div class="msc-admin-row">
    <div class="msc-group-control width-obser">
      <label>Chọn từ config</label>
      <nz-select nzPlaceHolder="-- Chọn từ config --" [(ngModel)]="selectConfigId"
        (ngModelChange)="selectConfigChange()">
        <nz-option *ngFor="let config of lstConfigDisplay" [nzLabel]="config.name" [nzValue]="config.id">
        </nz-option>
      </nz-select>
    </div>
  </div>

  <ng-container *ngIf="!useRoomOrDoctorForVisit; else room">
    <div class="msc-admin-row">
      <label style="
          padding-left: 0px;
          margin-bottom: 0px;
          font-size: 16px;
          color: #666;
        " class="col-6">Số bác sĩ hiển thị <span class="red-dot"> *</span></label>
      <nz-radio-group [(ngModel)]="dataConfig.numberDoctor">
        <label nz-radio [nzValue]="1" (click)="syncDefaultFontSize()">1 bác sỹ</label>
        <label nz-radio [nzValue]="2" (click)="syncDefaultFontSize()">2 bác sỹ</label>
      </nz-radio-group>
    </div>

    <div class="msc-admin-row">
      <label style="padding-left: 0px; margin-bottom: 0px; font-size: 16px" class="col-6">Bác sĩ <span class="red-dot">
          *</span></label>
      <nz-select nzPlaceHolder="-- Chọn BS --" [(ngModel)]="selectedDoctor1">
        <nz-option *ngFor="let item of doctorInGroup" [nzLabel]="item.fullName" [nzValue]="item.id">
        </nz-option>
      </nz-select>
    </div>
    <div *ngIf="dataConfig.numberDoctor == 2" class="msc-admin-row">
      <label style="padding-left: 0px; margin-bottom: 0px; font-size: 16px" class="col-6">Bác sĩ <span class="red-dot">
          *</span></label>
      <nz-select nzPlaceHolder="-- Chọn BS 2 --" [(ngModel)]="selectedDoctor2">
        <nz-option *ngFor="let item of doctorInGroup" [nzLabel]="item.fullName" [nzValue]="item.id">
        </nz-option>
      </nz-select>
    </div>
  </ng-container>

  <ng-template #room>
    <div class="msc-admin-row"><label nz-checkbox [(ngModel)]="isShowdoctorInRoom">Hiện BS trong phòng</label></div>
    <div class="msc-admin-row">
      <label style="
          padding-left: 0px;
          margin-bottom: 0px;
          font-size: 16px;
          color: #666;
        " class="col-6">Số phòng hiển thị <span class="red-dot"> *</span></label>
      <nz-radio-group [(ngModel)]="dataConfig.numberDoctor">
        <label nz-radio [nzValue]="1" (click)="syncDefaultFontSize()">1 phòng</label>
        <label nz-radio [nzValue]="2" (click)="syncDefaultFontSize()">2 phòng</label>
      </nz-radio-group>
    </div>

    <div class="msc-admin-row">
      <label style="padding-left: 0px; margin-bottom: 0px; font-size: 16px" class="col-6">Phòng <span class="red-dot">
          *</span></label>
      <nz-select nzPlaceHolder="-- Chọn phòng --" [(ngModel)]="selectedDoctor1">
        <nz-option *ngFor="let item of doctorInGroup" [nzLabel]="item.name" [nzValue]="item.id">
        </nz-option>
      </nz-select>
    </div>
    <div *ngIf="dataConfig.numberDoctor == 2" class="msc-admin-row">
      <label style="padding-left: 0px; margin-bottom: 0px; font-size: 16px" class="col-6">Phòng <span class="red-dot">
          *</span></label>
      <nz-select nzPlaceHolder="-- Chọn phòng --" [(ngModel)]="selectedDoctor2">
        <nz-option *ngFor="let item of doctorInGroup" [nzLabel]="item.name" [nzValue]="item.id">
        </nz-option>
      </nz-select>
    </div>
  </ng-template>


  <div class="msc-admin-row">
    <div class="msc-group-control width-obser">
      <label>Các cột hiển thị <span class="red-dot"> *</span></label>
      <nz-select [nzMaxTagCount]="4" nzMode="multiple" nzPlaceHolder="" [(ngModel)]="dataConfig.listColumns">
        <nz-option *ngFor="let column of lstColumn" [nzLabel]="column.text" [nzValue]="column.text"></nz-option>
      </nz-select>
    </div>
  </div>

  <div class="msc-admin-row">
    <div class="msc-group-control width-obser">
      <label>Fontsize trên grid</label>
      <textarea [(ngModel)]="dataConfig.fontSizeGrid" style="height: 34px">{{
        dataConfig.fontSizeGrid
      }}</textarea>
    </div>
  </div>

  <div class="msc-admin-row">
    <div class="msc-group-control width-obser">
      <label>Tiêu đề</label>
      <textarea [(ngModel)]="dataConfig.headerHtml" style="height: 34px">{{
        dataConfig.headerHtml
      }}</textarea>
    </div>
  </div>

  <div class="msc-admin-row">
    <div class="msc-group-control width-obser">
      <label>Mô tả</label>
      <textarea [(ngModel)]="dataConfig.description" style="height: 60px">{{
        dataConfig.description
      }}</textarea>
    </div>
  </div>

  <div *nzModalFooter>
    <div class="d-flex justify-content-between">
      <div>
        <button nz-button nzType="primary" (click)="addConfig()">
          Tạo cấu hình
        </button>
        <button nz-button nzType="primary" (click)="saveConfig()">
          Lưu cấu hình
        </button>
      </div>
      <div>
        <button class="btn-green" nz-button nzType="primary" (click)="showScreen()">
          Hiển thị
        </button>
      </div>
      </div>
  </div>
</nz-modal>

<nz-modal class="modal-admin" [(nzVisible)]="isAddConfig" nzTitle="Tên config mới" nzDraggable="true"
  nzMaskClosable="false" (nzClosable)="(true)" (nzOnCancel)="handleCancelAdd()">
  <div class="msc-admin-row">
    <div class="msc-group-control width-obser">
      <textarea [(ngModel)]="nameConfig" style="height: 34px"></textarea>
    </div>
  </div>
  <div *nzModalFooter>
    <button class="btn-green" nz-button nzType="primary" (click)="handleAddOk(dataConfig)">
      Ok
    </button>
    <button class="btn-cancel-medium" nz-button nzType="primary" (click)="handleCancelAdd()">
      Huỷ
    </button>
  </div>
</nz-modal>

<nz-layout *ngIf="isShowScreen">
  <div class="header" style="position: relative">
    <img src="https://mediatest.pmr.vn/Media/GroupImg/742480329logo-trungduc.png" alt=""
      style="width: auto; height: 80%; position: absolute; left: 4%; top: 9%" />
    <div class="" style="padding-top: 22px">
      <h1>
        {{ dataConfig.headerHtml }}
      </h1>
    </div>
    <div class="" style="padding: 20px 0 20px 0; height: auto">
      <div style="font-size: 20px; line-height: 35px; white-space: pre-line">
        {{ dataConfig.description }}
      </div>
    </div>
  </div>
  <nz-content>
    <div *ngIf="!isShow2Doctors; else show2Doctors" class="wrapper">
      <ng-container [ngTemplateOutlet]="dataDoctor1"></ng-container>
      <div class="setting">
        <a (click)="openModalConfig()"><i class="icon-setting success" style="color: #25b396" nz-icon size="last"
            nzType="setting" nzIconfont="20" nzTheme="outline"></i></a>
      </div>
    </div>
    <ng-template #show2Doctors>
      <div class="wrapper">
        <ng-container [ngTemplateOutlet]="dataDoctor1"></ng-container>
        <ng-container [ngTemplateOutlet]="dataDoctor2"></ng-container>
        <div class="setting">
          <a (click)="openModalConfig()"><i class="icon-setting success" style="color: var(--dp-primary-1)" nz-icon
              size="last" nzType="setting" nzIconfont="20" nzTheme="outline"></i></a>
        </div>
      </div>
    </ng-template>
  </nz-content>
</nz-layout>

<ng-template #dataDoctor1>
  <div class="data-show" style="margin-right: 10px">
    <div [style.font-size]="
        isNumber(dataConfig.fontSizeGrid)
          ? dataConfig.fontSizeGrid + 'px'
          : dataConfig.fontSizeGrid
      " class="doctor-title">
      {{ doctorSelected[0] }}
    </div>

    <marquee behavior="" direction="" *ngIf="isShowdoctorInRoom">
      <span nz-tooltip nzTooltipTitle="{{ listNameDoctorInRoom1 }}" [style.font-size]="
      isNumber(dataConfig.fontSizeGrid)
        ? dataConfig.fontSizeGrid/1.5 + 'px'
        : dataConfig.fontSizeGrid/1.5
    ">BS trực phòng:{{listNameDoctorInRoom1 }}</span>
    </marquee>


    <nz-table class="main-table-grid" #fixedTable1 [nzFrontPagination]="false" [nzData]="lstVisitWaiting1"
      nzShowSizeChanger [nzPageSizeOptions]="[20, 50, 100]" [nzScroll]="{ y: scrollY }" style="min-height: 300px;">
      <thead>
        <tr>
          <th [style.font-size]="
              isNumber(dataConfig.fontSizeGrid)
                ? dataConfig.fontSizeGrid + 'px'
                : dataConfig.fontSizeGrid
            " *ngFor="let column of listColumnDisplay; index as i" [nzWidth]="listColumnDisplay[i].width" [ngClass]="{
              'text-center':
                listColumnDisplay[i].text == lstColumn[0].text ||
                listColumnDisplay[i].text == lstColumn[2].text
            }">
            {{ column.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fixedTable1.data; let i = index">
          <td [style.font-size]="
              isNumber(dataConfig.fontSizeGrid)
                ? dataConfig.fontSizeGrid + 'px'
                : dataConfig.fontSizeGrid
            " *ngIf="isIncludeField[0]" class="text-center">
            {{ data.stt }}
          </td>
          <td [style.font-size]="
              isNumber(dataConfig.fontSizeGrid)
                ? dataConfig.fontSizeGrid + 'px'
                : dataConfig.fontSizeGrid
            " *ngIf="isIncludeField[1]" class="txt-data">
            {{ data.patientName }}
          </td>
          <td [style.font-size]="
              isNumber(dataConfig.fontSizeGrid)
                ? dataConfig.fontSizeGrid + 'px'
                : dataConfig.fontSizeGrid
            " *ngIf="isIncludeField[2]" class="text-center">
            {{ calcAge(data.patientDOB) }}
          </td>
          <td [style.font-size]="
              isNumber(dataConfig.fontSizeGrid)
                ? dataConfig.fontSizeGrid + 'px'
                : dataConfig.fontSizeGrid
            " *ngIf="isIncludeField[3]" class="txt-data">
            {{ data.visitReason }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</ng-template>
<ng-template #dataDoctor2>
  <div class="data-show">
    <div [style.font-size]="
        isNumber(dataConfig.fontSizeGrid)
          ? dataConfig.fontSizeGrid + 'px'
          : dataConfig.fontSizeGrid
      " class="doctor-title">
      {{ doctorSelected[1] }}
    </div>
    <marquee behavior="" direction="" *ngIf="isShowdoctorInRoom">

      <span nz-tooltip nzTooltipTitle="{{ listNameDoctorInRoom2 }}" [style.font-size]="
      isNumber(dataConfig.fontSizeGrid)
        ? dataConfig.fontSizeGrid/1.5 + 'px'
        : dataConfig.fontSizeGrid/1.5
    ">BS trực phòng:{{ listNameDoctorInRoom2 }}</span>
    </marquee>

    <nz-table class="main-table-grid" #fixedTable2 [nzFrontPagination]="false" [nzData]="lstVisitWaiting2"
      nzShowSizeChanger [nzPageSizeOptions]="[20, 50, 100]" [nzScroll]="{ y: scrollY }" style="min-height: 300px;">
      <thead>
        <tr>
          <th [style.font-size]="
              isNumber(dataConfig.fontSizeGrid)
                ? dataConfig.fontSizeGrid + 'px'
                : dataConfig.fontSizeGrid
            " *ngFor="let column of listColumnDisplay; index as i" [nzWidth]="listColumnDisplay[i].width" [ngClass]="{
              'text-center':
                listColumnDisplay[i].text == lstColumn[0].text ||
                listColumnDisplay[i].text == lstColumn[2].text
            }">
            {{ column.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fixedTable2.data; let i = index">
          <td [style.font-size]="
              isNumber(dataConfig.fontSizeGrid)
                ? dataConfig.fontSizeGrid + 'px'
                : dataConfig.fontSizeGrid
            " *ngIf="isIncludeField[0]" class="text-center">
            {{ data.stt }}
          </td>
          <td [style.font-size]="
              isNumber(dataConfig.fontSizeGrid)
                ? dataConfig.fontSizeGrid + 'px'
                : dataConfig.fontSizeGrid
            " *ngIf="isIncludeField[1]" class="txt-data">
            {{ data.patientName }}
          </td>
          <td [style.font-size]="
              isNumber(dataConfig.fontSizeGrid)
                ? dataConfig.fontSizeGrid + 'px'
                : dataConfig.fontSizeGrid
            " *ngIf="isIncludeField[2]" class="text-center">
            {{ calcAge(data.patientDOB) }}
          </td>
          <td [style.font-size]="
              isNumber(dataConfig.fontSizeGrid)
                ? dataConfig.fontSizeGrid + 'px'
                : dataConfig.fontSizeGrid
            " *ngIf="isIncludeField[3]" class="txt-data">
            {{ data.visitReason }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</ng-template>
