import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  
} from '@angular/cdk/drag-drop';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorklistColumn } from 'src/app/models/worklist-column';

@Component({
  selector: 'app-custom-column',
  templateUrl: './custom-column.component.html',
  styleUrls: ['./custom-column.component.scss'],
})
export class CustomColumnComponent implements OnInit {
  _isVisible = false;
  @Input() set isVisible(val: boolean) {
    this._isVisible = val;
    if (val) {
      // let show = this.showColumn.filter(t => t.title != 'STT');
      this._showColumn = Object.assign([], this.showColumn);
      this._hideColumn = Object.assign([], this.hideColumn);
    }
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  _showColumn: WorklistColumn[] = [];
  _hideColumn: WorklistColumn[] = [];

  @Input() showColumn: WorklistColumn[] = [];
  @Input() hideColumn: WorklistColumn[] = [];
  @Input() modalTitle: string;

  @Output() isVisibleChange = new EventEmitter();
  @Output() saveColumns = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  evenPredicate(item: CdkDrag<string>) {
    console.log(item.dropContainer.data)  
    console.log('hh', 
                item.dropContainer.data.filter((en) => en.field === 'genderText'),
                item.dropContainer.data.filter((en) => en.field === 'genderText').length);
  //   if(item.dropContainer.data.filter((en) => en.field == 'genderText').length === 0) return true;
  //   else return false; 
  // }
  // if(item.dropContainer.data.filter((en) => en.field == 'genderText').length === 0) return false;
  // else return true; 
  // let data = item.element.nativeElement.innerText;
  // if (data === 'Giới tính') return false;
  // else return true;

  // let data = item.element.nativeElement.innerText;
  // if (data === 'Giới tính') {
  //   return false;
  // } else if (data === 'Tên bệnh nhân'){
  //   return false;
  // } else {
  //   return true;
  // }
  let data = item.element.nativeElement.innerText;
  if (data === 'Giới tính' ||  data === 'Tên bệnh nhân') return false;
  else return true;


  }
  
  noReturnPredicate() {
    return true;
  }

  handleCancel() {
    this.isVisibleChange.emit(false);
  }
  handleOk() {
    this.saveColumns.emit(this._showColumn);
    this.isVisibleChange.emit(false);
  }
}
