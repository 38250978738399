import { number } from '@amcharts/amcharts4/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { listStyleImage } from 'html2canvas/dist/types/css/property-descriptors/list-style-image';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { CodeMapSystemOT, DataTypeBoolean } from 'src/app/models/designated-list.class';
import { CommonInfoManagementService } from 'src/app/services/common-info-management.service';
import { CriteriaService } from 'src/app/services/criteria.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShareService } from 'src/app/services/share.service';
import { Constant } from 'src/app/share/constants/constant.class';

@Component({
  selector: 'app-common-info-management',
  templateUrl: './common-info-management.component.html',
  styleUrls: ['./common-info-management.component.scss'],
})
export class CommonInfoManagementComponent implements OnInit {
  textSearch: any;
  filterData: any[] = [];
  datas: any[] = [];
  isLoading = false;
  total = 0;
  pageSize = 20;
  pageIndex = 1;
  itemEdit = {
    id: '',
    name: '',
    title: '',
    source: 'Patient',
    description: '',
    defaultField: '',
    dataType: 0,
    dataSource: [],
  };
  isVisibleAdd = false;
  lstGroupName = [];
  lstGroupId = [];
  isVisibleDelete = false;
  isTitle = '';
  searchParameter = [];
  searchLogicalOperator = [];
  lstDataTypeBoolean: DataTypeBoolean[] = [];
  systemCodeDataSource: any;
  dataSourceTempId = 0;
  selectedParameter: {
    dataType: 1;
  };
  constructor(
    private fb: FormBuilder,
    private commonInfoManagementService: CommonInfoManagementService,
    private shareService: ShareService,
    private notificationService: NotificationService,
    private modalService: NzModalService,
    private criteriaService: CriteriaService
  ) {}

  ngOnInit(): void {
    console.log('hh',this.itemEdit.source)
   //q this.onSearchParameter(this.itemEdit.source)
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.getByFilter();
  }
  getByFilter() {
    this.isLoading = true;
    this.commonInfoManagementService.getByFilter().subscribe(
      (res) => {
        if (res !== null) {
          this.total = res.length;
          this.datas = res;
          this.filterData = res;
          this.isLoading = false;
        }
      },
      (error) => {}
    );
  }
  // showModalForm(type, data) {
  //   if (this.lstDataTypeBoolean.length == 0) {
  //     this.addDataTypeBoolean();
  //   }
  //   this.isVisibleAdd = true;
  //   if (this.lstDataTypeBoolean === null || this.lstDataTypeBoolean.length === 0) {
  //     this.lstDataTypeBoolean = [];
  //     this.addDataTypeBoolean();
  //   }
  //   if (type == 'add') {
  //     this.itemEdit = {
  //       id: '',
  //       name: '',
  //       title: '',
  //       source: 'Patient',
  //       description: '',
  //       defaultField: '',
  //       dataType: 0,
  //       dataSource: [],
  //     };
  //     this.isVisibleAdd = true;
  //     this.isTitle = 'Thêm mới Common-Info';
  //     console.log('idthem', this.itemEdit.id);
  //   } else if (type == 'update') {
  //     this.itemEdit = {
  //       id: data.id,
  //       name: data.name,
  //       title: data.title,
  //       source: data.source,
  //       description: data.description,
  //       defaultField: data.defaultField,
  //       dataType: data.dataType,
  //       dataSource: data.dataSource,
  //     };
  //     console.log('hh', this.itemEdit);
  //     this.isVisibleAdd = true;
  //     this.isTitle = 'Chỉnh sửa Common-Info';
  //     this.lstDataTypeBoolean = data.dataSource;
  //     if (this.lstDataTypeBoolean === null || this.lstDataTypeBoolean.length === 0) {
  //       this.lstDataTypeBoolean = [];
  //       this.addDataTypeBoolean();
  //     }
  //     console.log('h', this.itemEdit);
  //   }
  // }
  showModalForm(type, data) {
    if (this.lstDataTypeBoolean.length == 0) {
      this.addDataTypeBoolean();
    }
    this.isVisibleAdd = true;
    if (this.lstDataTypeBoolean === null || this.lstDataTypeBoolean.length === 0) {
      this.lstDataTypeBoolean = [];
      this.addDataTypeBoolean();
    }
    this.criteriaService.getFieldParameter(data.source).subscribe(
      (response) => {
        console.log('hoang', response);
        if (response !== null) {
          this.searchParameter = response;
        }
        if (type == 'add') {
          this.itemEdit = {
            id: '',
            name: '',
            title: '',
            source: 'Patient',
            description: '',
            defaultField: '',
            dataType: 0,
            dataSource: [],
          };
          this.isVisibleAdd = true;
          this.isTitle = 'Thêm mới Common-Info';
          console.log('idthem', this.itemEdit.id);
        } else if (type == 'update') {
          this.itemEdit = {
            id: data.id,
            name: data.name,
            title: data.title,
            source: data.source,
            description: data.description,
            defaultField: data.defaultField,
            dataType: data.dataType,
            dataSource: data.dataSource,
          };
          console.log('hh', this.itemEdit);
          this.isVisibleAdd = true;
          this.isTitle = 'Chỉnh sửa Common-Info';
          this.lstDataTypeBoolean = data.dataSource;
          if (this.lstDataTypeBoolean === null || this.lstDataTypeBoolean.length === 0) {
            this.lstDataTypeBoolean = [];
            this.addDataTypeBoolean();
          }
          console.log('h', this.itemEdit);
        }
      },
      (error) => {
        //console.log(error);
        this.notificationService.showNotification('', 'Lỗi data');
      }
    );
  }
  handleCancel() {
    this.isVisibleAdd = false;
    this.lstDataTypeBoolean = [];
  }
  submit() {
    this.lstDataTypeBoolean = this.lstDataTypeBoolean.filter((it) => it.text.length > 0 && it.valueNumber.length > 0);
    this.itemEdit.dataSource = this.lstDataTypeBoolean;
    console.log('hhh', this.lstDataTypeBoolean);
    const data = this.itemEdit;
    data.name = this.itemEdit.name.trim();
    // data.source = this.itemEdit.source.trim();
    data.defaultField = this.itemEdit.defaultField.trim();
    // data.description = this.itemEdit.description.trim();
    // data.title = this.itemEdit.title.trim();
    // data.dataType = this.itemEdit.dataType;
    if (
      this.shareService.checkEmptyStr(data.name) ||
      this.shareService.checkEmptyStr(data.title) ||
      this.shareService.checkEmptyStr(data.defaultField) ||
      this.shareService.checkEmpty(data.dataType)
    ) {
      this.notificationService.showNotification(
        Constant.NOTIFY_TYPE.ERROR,
        'Bạn cần nhập các trường thông tin bắt buộc'
      );
      return
    }
    if (data.id == '') {
      this.commonInfoManagementService.add(data).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Thêm mới thành công');
          this.getByFilter();
          this.isVisibleAdd = false;
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
          this.getByFilter();
          this.isVisibleAdd = false;
        }
      });
    } else {
      this.commonInfoManagementService.update(data).subscribe((res) => {
        if (res.isValid) {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Edit thành công');
          this.getByFilter();
          this.isVisibleAdd = false;
        } else {
          this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
        }
      });
    }
  }
  remove(id) {
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Bạn có muốn xóa hay không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Bỏ qua',
      nzOnOk: () => {
        this.commonInfoManagementService.remove(id).subscribe((res) => {
          if (res.isValid) {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.SUCCESS, 'Xóa thành công');
            this.getByFilter();
          } else {
            this.notificationService.showNotification(Constant.NOTIFY_TYPE.ERROR, res.errors[0].errorMessage);
          }
        });
      },
    });
  }
  doSearch() {
    const searchs = this.shareService.toUnsign(this.textSearch).trim();
    this.filterData = this.datas.filter((en) => this.shareService.toUnsign(en.name).includes(searchs));
  }
  toNum(text): string {
    let returnType = '_';
    switch (text) {
      case 1:
        returnType = 'Number';
        break;
      case 4:
        returnType = 'Boolean';
        break;
      case 5:
        returnType = 'String';
        break;
      case 7:
        returnType = 'Date';
        break;
      case 9:
        returnType = 'Single choice';
        break;
      case 99:
        returnType = 'Tuổi BN';
        break;
    }
    return returnType;
  }
  onSearchParameter(keyword) {
    this.itemEdit.defaultField = "";
    console.log('a', keyword);
    console.log('itemEdit.source', this.itemEdit.source);
    this.criteriaService.getFieldParameter(keyword).subscribe(
      (response) => {
        console.log('hoang', response);
        if (response !== null) {
          this.searchParameter = response;
        }
      },
      (error) => {
        //console.log(error);
        this.notificationService.showNotification('', 'Lỗi data');
      }
    );
  }
  addDataTypeBoolean() {
    this.lstDataTypeBoolean.push({
      text: '',
      valueNumber: '',
    });
  }
  removeDataTypeBoolean(index) {
    this.lstDataTypeBoolean.splice(index, 1);
  }
}
