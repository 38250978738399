import { FormObject } from './formObject.model';

export class CanvasElement extends FormObject {
  id: string;
  url: string;
  name: string;
  value: any;
  width = 200;
  height = 200;
}
