<div class="custom-modal">
  <form [formGroup]="formAddGroup" (ngSubmit)="handleOk()">
    <nz-modal
      class="custom-modal"
      [(nzVisible)]="isVisible"
      nzTitle="Thêm nhóm làm việc"
      (nzOnCancel)="handleCancel()"
      (nzOnOk)="handleCancel()">
      <div class="row">
        <div class="col-4">Tên nhóm</div>
        <div class="col-8">
          <input style="width: 100%" formControlName="Name" />
        </div>
      </div>
      <div class="row mg-t-30">
        <div class="col-4">Mã nhóm</div>
        <div class="col-8">
          <input style="width: 100%" formControlName="shortCode" />
        </div>
      </div>
      <div class="row mg-t-30">
        <label class="col-4">Hình ảnh</label>
        <div class="col-8 imgUpload">
          <nz-upload
            nzAction="{{ urlService }}"
            nzName="FormFile"
            [nzShowUploadList]="false"
            [nzBeforeUpload]="beforeUpload"
            [nzHeaders]="header"
            (nzChange)="handleChange($event)">
            <button nz-button>
              <i nz-icon nzType="upload"></i>Click to Upload
            </button>
          </nz-upload>
        </div>
      </div>
      <ng-container>
        <div class="row mg-t-30" *ngIf="fileName">
          <label class="col-4">Xem trước</label>
          <div class="col-8 imgUpload">
            <img [src]="baseImageurl + fileName" style="max-width: 120px" />
          </div>
        </div>
      </ng-container>
      <div *nzModalFooter>
        <button nz-button (click)="handleCancel()">Quay lại</button>
        <button
          nz-button
          nzType="primary"
          (click)="handleOk()"
          class="button-submit">
          Thêm nhóm
        </button>
      </div>
    </nz-modal>
  </form>
</div>
