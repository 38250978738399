import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../share/base-service/base-service.service';
import { UrlConstant } from '../share/constants/url.class';

@Injectable({
  providedIn: 'root',
})
export class ExaminationServiceService extends BaseService {
  getExaminationService(payload): Observable<any> {
    return this.post(UrlConstant.EXAMINATION_SERVICE_SEARCH, payload);
  }
  getAllExaminationService(payload): Observable<any> {
    return this.post(UrlConstant.EXAMINATION_SERVICE_SEARCH_ALL, payload);
  }

  addExaminationService(payload): Observable<any> {
    return this.post(UrlConstant.EXAMINATION_SERVICE_SAVE, payload);
  }

  editExaminationService(payload): Observable<any> {
    return this.patch(UrlConstant.EXAMINATION_SERVICE_SAVE, payload);
  }

  deleteExaminationService(id): Observable<any> {
    return this.delete(UrlConstant.EXAMINATION_SERVICE_DELETE, id);
  }

  getExaminationServiceById(id): Observable<any> {
    return this.get(UrlConstant.EXAMINATION_SERVICE_GET_BY_ID, id);
  }
}
