<div class="xetnghiem-container m-tab-container">
  <div class="mg-bt-20">
    <div class="box-custom-header">
      <div class="box-title m-tab-title">Mã yêu cầu XN: {{visit.testSampleId}}<p>Danh sách chỉ định XN ngày {{visit.visitDate | date:'dd/MM/yyyy'}}</p>
      </div>
      <div class="box-tools search-bar-right">
        <button nz-button class="btn-primary-medium" (click)="printResult()">In phiếu KQ</button>
      </div>
    </div>
    <app-xn-list [chisoxns]="chisoxnsView" [mockData]="mockData" [visit]="visit" [subClinicalXNs]="subClinicalXNs"
      [disabledAction]="disabledAction" (refresh)="resultSaved()"></app-xn-list>
  </div>

  <div class="box-custom-header">
    <div class="box-title m-tab-title">Kết quả xét nghiệm</div>
    <div class="box-tools search-bar-right">
      <input nz-input [(ngModel)]="searchText" (ngModelChange)="searchResult()" placeholder="Tên chỉ số xét nghiệm"
        style="width: 200px" />
      <button (click)="viewChart()" class="btn-primary-small">Lập biểu đồ</button>
      <button *ngIf="!isEditable" class="btn-primary-small" nz-button [disabled]="disabledAction"
        (click)="toggleQuickEdit()">
        Nhập nhanh
      </button>
      <button *ngIf="isEditable" (click)="toggleQuickEdit()" class="btn-cancel-small">Kết thúc cập nhật</button>
    </div>
  </div>
  <div *ngIf="!isEditable">
    <app-xn-result [chisoXNFilter]="chisoXNFilter" [cakhams]="cakhams" [visit]="visit"
      (editXN)="editXetNghiem($event)"></app-xn-result>
  </div>

  <div class="mg-bt-20">
    <div class="box-custom-header" style="margin-top: 21px;">
      <div class="box-title m-tab-title">
      </div>
    </div>
    <!-- <div class="clinical-container">
    <div *ngIf="subclinicalResultOfVisit.length > 0" class="clinical-visit">
      <div class="m-tab-title">Danh sách chỉ định CĐHA</div>
    </div>
    <div class="other">
      <div class="m-tab-title">Các kết quả cũ của BN</div>
    </div>
    <ul class="ul-image-general">
      <li>
        <a href="https://listest.stc.vn/Viewer/Media/19860/11082023-89122.pdf" class="thumbnail-report">
          <img src="assets/image/pdf.svg" style="height: 20px; width: 20px;" />
        </a>
        <div class="fs-12">Phiếu kết quả</div>
      </li>
    </ul>
  </div> -->

    <!-- <div class="box-custom-header">
    <div class="box-title m-tab-title">Danh sách chỉ định CĐHA</div>
    <div class="box-tools search-bar-right">
      <input nz-input [(ngModel)]="searchText" (ngModelChange)="searchResult()" placeholder="Tên chỉ số xét nghiệm"
        style="width: 200px" />
      <button (click)="viewChart()" class="btn-primary-small">Lập biểu đồ</button>
      <button *ngIf="!isEditable" class="btn-primary-small" nz-button [disabled]="disabledAction"
        (click)="toggleQuickEdit()">
        Nhập nhanh
      </button>
      <button *ngIf="isEditable" (click)="toggleQuickEdit()" class="btn-cancel-small">Kết thúc cập nhật</button>
    </div>
  </div> -->


    <div style="position: relative" *ngIf="isEditable">
      <span class="column-btn" title="Thêm ngày khám" (click)="openAddVisitDate()">
        <i nz-icon nzType="plus-circle" nzTheme="twotone"></i>
      </span>
      <nz-table #editTable [nzData]="chisoXNFilter" class="table-csxn-edit main-table-grid" nzSize="small"
        [nzFrontPagination]="false" [nzShowPagination]="false" [nzScroll]="{ x: '800px', y: 'calc(100vh - 450px)' }">
        <thead>
          <tr>
            <th style="padding-left: 12px" [nzWidth]="'250px'">Yêu cầu xét nghiệm</th>
            <ng-container *ngFor="let td of cakhams">
              <th>{{ td.visitDate }}</th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of editTable.data; let i = index">
            <td style="padding: 12px" class="xn-col" [innerHtml]="item.name" nzWidth="50px"></td>
            <ng-container *ngFor="let td of cakhams">
              <td [ngClass]="{
                XNSelected: td.visitId === visit.id,
                isNew: td.isNew
              }" class="xn-col" nzWidth="30px">
                <ng-container [ngSwitch]="item.dataType">
                  <ng-container *ngSwitchCase="contants.DATATYPENUMBER.NUMBER">
                    <nz-input-number style="width: 100%" [(ngModel)]="item[RAW_VAL + td.visitId]"
                      (nzBlur)="onOTChange(item, td.visitId)">
                    </nz-input-number>
                  </ng-container>
                  <ng-container *ngSwitchCase="contants.DATATYPENUMBER.STRING">
                    <input nz-input [(ngModel)]="item[RAW_VAL + td.visitId]"
                      (focusout)="onOTChange(item, td.visitId)" />
                  </ng-container>
                  <ng-container *ngSwitchCase="contants.DATATYPENUMBER.POSITIVENEGATIVE">
                    <nz-select [(ngModel)]="item[RAW_VAL + td.visitId]" (ngModelChange)="onOTChange(item, td.visitId)">
                      <nz-option [nzLabel]="'Positive'" [nzValue]="true"></nz-option>
                      <nz-option [nzLabel]="'Negative'" [nzValue]="false"></nz-option>
                    </nz-select>
                  </ng-container>
                  <ng-container *ngSwitchCase="contants.DATATYPENUMBER.SINGLECHOICE">
                    <nz-select [(ngModel)]="item[RAW_VAL + td.visitId]" (ngModelChange)="onOTChange(item, td.visitId)"
                      nzAllowClear nzMode="multiple" [nzMaxMultipleCount]="1">
                      <nz-option *ngFor="let datasource of item.dataSource" [nzLabel]="datasource.text"
                        [nzValue]="datasource.id"></nz-option>
                    </nz-select>
                  </ng-container>
                </ng-container>
              </td>
            </ng-container>
          </tr>
          <tr>
            <td style="text-align: center">
              <span title="Thêm chỉ số xét nghiệm" (click)="openAddVisitOT()">
                <i nz-icon nzType="plus-circle" nzTheme="twotone"></i>
              </span>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
  <div style="position: relative; margin-left: -29px; width: calc(100% - -60px);">
    <nz-table class="table-csxn-edit main-table-grid" nzSize="small">
      <thead>
        <tr>
          <th style="padding-left: 12px" [nzWidth]="'250px'">Danh sách chỉ số XN dạng file</th>
        </tr>
      </thead>
      <tbody>
        <ul>
          <li *ngFor="let a of responsive" style="margin-left: 29px; margin-top: 15px">
            <a target="_blank" [href]="a" class="thumbnail-report">
              <img src="assets/image/pdf.svg" style="height: 20px; width: 20px;" />
              <!-- <p>Phiếu kết quả</p> -->
              <div class="fs-12">Phiếu kết quả</div>
            </a>
          </li>
        </ul>
        <!-- <div class="fs-12">Phiếu kết quả</div> -->
      </tbody>
    </nz-table>
  </div>
  <div class="custom-container">
    <app-xetnghiem-chart [visit]="visit" [datas]="chisoXNCharts" [rawData]="mockData"
      [(isVisible)]="isVisibleChart"></app-xetnghiem-chart>
    <app-xetnghiem-add (addObserEmit)="addObserEmitEvent()" (closeModal)="closeAddXetNghiem($event)"
      [visitId]="visit.id" [isVisible]="isVisibleAddXN"></app-xetnghiem-add>
  </div>

  <!---------------------- template  ------------------------>
  <ng-template #customTpl let-name>
    <div style="text-align: center"></div>
  </ng-template>

  <!---------------------- modal  ------------------------>
  <nz-modal [nzVisible]="isVisibleAddVisit" [nzTitle]="'Chọn ngày giờ khám'" [nzWidth]="300"
    (nzOnCancel)="closeAddVisitDate()">
    <div *nzModalContent>
      <div style="text-align: center">
        <nz-date-picker [nzShowTime]="true" [(ngModel)]="newVisitDate" [nzPlaceHolder]="'DD/MM/YYYY hh:MM'"
          [nzFormat]="'dd/MM/yyyy HH:mm'" [nzDisabledDate]="disabledDateAfter"></nz-date-picker>
        <div *ngIf="isVisibleVisitError" class="message-required" style="margin-top: 8px">
          {{ addVistitError }}
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <button class="button-submit btn-cancel-large" (click)="closeAddVisitDate()">Hủy</button>
      <button nz-button class="btn-primary-large" (click)="addNewVisit()">Thêm</button>
    </div>
  </nz-modal>

  <nz-modal [nzVisible]="isVisibleAddOT" [nzTitle]="'Chọn chỉ số xét nghiệm'" [nzWidth]="400"
    (nzOnCancel)="closeAddVisitOt()">
    <div *nzModalContent style="text-align: center">
      <nz-select nzShowSearch [nzShowArrow]="true" [nzServerSearch]="true" nzPlaceHolder="Chọn chỉ số XN"
        (nzOnSearch)="onInputOT($event)" [(ngModel)]="newOT" style="width: 100%">
        <nz-option *ngFor="let opt of lstObservationType" [nzLabel]="opt.name" [nzValue]="opt"> </nz-option>
      </nz-select>
      <div *ngIf="isVisibleOTError" class="message-required" style="margin-top: 8px">
        {{ OTError }}
      </div>
    </div>
    <div *nzModalFooter>
      <button class="button-submit btn-cancel-large" (click)="closeAddVisitOt()">Hủy</button>
      <button nz-button class="btn-primary-large" (click)="addNewOT()">Thêm</button>
    </div>
  </nz-modal>
