import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-view-pdf',
  templateUrl: './m-view-pdf.component.html',
  styleUrls: ['./m-view-pdf.component.scss'],
})
export class MViewPdfComponent implements OnInit {
  @Input() pdfDocumentSrc: string;
  zoom=1;
  page = 1;
  totalPage: number;
  isLoaded=false;

  constructor() {}

  ngOnInit() {
    // console.log(this.pdfDocumentSrc);
  }

  afterLoadComplete(pdfData: any) {
    // console.log(pdfData);

    this.totalPage = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }
  zoomIn(){
    this.zoom=this.zoom+0.4;
  }
  zoomOut(){
    this.zoom=this.zoom-0.4;
  }
  resetZoom(){
    this.zoom=1;
  }
}
