import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-in-room',
  templateUrl: './patient-in-room.component.html',
  styleUrls: ['./patient-in-room.component.scss'],
})
export class PatientInRoomComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
