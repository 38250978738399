export class UserReturn {
  userId: string;
  userName: string;
  email: string;
  username: string;
  fullName: string;
  imageUrl: string;
  isAdmin: boolean;
  isOwner: boolean;
  isAddUser: boolean;
  isMember: boolean;
  isInvited: boolean;
  doctorPermissions: number[];
  permissionName: string;
  permission: number;
}
