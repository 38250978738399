import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'countFilter',
})
export class CountArrayPipe implements PipeTransform {
  //pipe

  transform(items: any, filterValue: boolean) {
    if (!items) {
      return [];
    }
    return items.filter((it) => it.isInvited === filterValue);
  }
}
