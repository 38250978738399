import { UrlConstant } from './../share/constants/url.class';
import { Constant } from './../share/constants/constant.class';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService } from '../share/base-service/base-service.service';

@Injectable({
  providedIn: 'root',
})
export class PatientRoomService extends BaseService {
  getAllRoomInGroup(groupId): Observable<any> {
    return this.post(UrlConstant.PATIENT_ROOM_GETALL + groupId, {});
  }

  createRoom(payload): Observable<any> {
    return this.post(UrlConstant.PATIENT_ROOM_ADD, payload);
  }

  getRoom(roomId): Observable<any> {
    return this.get(UrlConstant.PATIENT_ROOM_GET + roomId);
  }

  addPatient(payload): Observable<any> {
    return this.post(UrlConstant.PATIENT_ROOM_ADD_PATIENT, payload);
  }

  removePatient(payload): Observable<any> {
    return this.post(UrlConstant.PATIENT_ROOM_ROMOVE_PATIENT, payload);
  }

  movePatient(payload): Observable<any> {
    return this.post(UrlConstant.PATIENT_ROOM_MOVE_PATIENT, payload);
  }

  addDoctor(payload): Observable<any> {
    return this.post(UrlConstant.PATIENT_ROOM_ADD_DOCTOR, payload);
  }

  removeDoctor(payload): Observable<any> {
    return this.post(UrlConstant.PATIENT_ROOM_REMOVE_DOCTOR, payload);
  }
}
