<div class="containers">
  <div class="col-md-10 col-lg-8 content">
    <p>Kích hoạt tài khoản</p>
    <form [formGroup]="registrationForm">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="input-wrap">
            <nz-select
              class="mg-r-15 user-rank"
              nzPlaceHolder="Chức danh"
              formControlName="doctorRank"
              [nzSize]="'large'"
              nzAllowClear
              nzShowSearch>
              <nz-option
                *ngFor="let rank of doctorRanks"
                [nzValue]="rank"
                [nzLabel]="rank.name"></nz-option>
            </nz-select>
            <input
              nz-input
              formControlName="fullname"
              placeholder="Họ tên"
              [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }" />
          </div>
          <div class="message-required" *ngIf="submitted && f.fullname.errors">
            <div *ngIf="f.fullname.errors.required">Hãy nhập họ tên</div>
            <div *ngIf="f.fullname.errors.minlength">
              Độ dài tối thiểu là 8 ký tự
            </div>
            <div *ngIf="f.fullname.errors.maxlength">
              <i
                nz-icon
                nzType="close-circle"
                [nzTheme]="'twotone'"
                [nzTwotoneColor]="'#FF0000'"></i>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="input-wrap">
            <input
              nz-input
              formControlName="address"
              placeholder="Địa chỉ"
              [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="input-wrap">
            <input
              nz-input
              formControlName="email"
              placeholder="Email"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          </div>
          <div class="message-required" *ngIf="submitted && f.email.errors">
            <div *ngIf="f.email.errors.required">Hãy nhập email</div>
            <div *ngIf="f.email.errors.pattern">Email không hợp lệ</div>
          </div>
        </div>
        <div class="col-6">
          <div class="input-wrap">
            <input
              nz-input
              formControlName="username"
              placeholder="Tên đăng nhập (viết liền, không chứa ký tự đặc biệt, tối thiểu 4 ký tự)"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
          </div>
          <div class="message-required" *ngIf="submitted && f.username.errors">
            <div *ngIf="f.username.errors.required">Hãy nhập tên đăng nhập</div>
            <div *ngIf="f.username.errors.pattern">
              Viết liền, không dấu, không chứa ký tự đặc biệt hoặc in hoa và tối thiểu 4 ký tự
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="input-wrap">
            <input
              nz-input
              formControlName="phoneNo"
              placeholder="Số điện thoại"
              [ngClass]="{ 'is-invalid': submitted && f.phoneNo.errors }" />
          </div>
          <div class="message-required" *ngIf="submitted && f.phoneNo.errors">
            <div *ngIf="f.phoneNo.errors.required">Hãy nhập SĐT</div>
          </div>
        </div>
        <div class="col-6">
          <div class="input-wrap">
            <nz-input-group [nzSuffix]="suffixTemplate">
              <input
                nz-input
                [type]="passwordVisible ? 'text' : 'password'"
                formControlName="password"
                placeholder="Mật khẩu"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            </nz-input-group>
            <ng-template #suffixTemplate>
              <i
                nz-icon
                [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                (click)="passwordVisible = !passwordVisible"></i>
            </ng-template>
          </div>
          <div class="message-required" *ngIf="submitted && f.password.errors">
            <div *ngIf="f.password.errors.required">Hãy nhập mật khẩu</div>
            <div *ngIf="f.password.errors.pattern">
              Tối thiểu 8 ký tự, không nhập khoảng trắng đầu và cuối
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="input-wrap">
            <nz-date-picker
              formControlName="dob"
              nzPlaceHolder="Ngày sinh"
              nzFormat="dd/MM/yyyy"
              [nzDisabledDate]="disabledDateAfterToday"
              [ngClass]="{ 'is-invalid': submitted && f.dob.errors }">
            </nz-date-picker>
          </div>
        </div>
        <div class="col-6">
          <div class="input-wrap">
            <nz-input-group [nzSuffix]="suffixTemplate1">
              <input
                nz-input
                [type]="repeatpasswordVisible ? 'text' : 'password'"
                formControlName="repeatPassword"
                placeholder="Nhập lại mật khẩu"
                [ngClass]="{
                  'is-invalid': submitted && f.repeatPassword.errors
                }" />
            </nz-input-group>
            <ng-template #suffixTemplate1>
              <i
                nz-icon
                [nzType]="repeatpasswordVisible ? 'eye' : 'eye-invisible'"
                (click)="repeatpasswordVisible = !repeatpasswordVisible"></i>
            </ng-template>
          </div>
          <div
            class="message-required"
            *ngIf="submitted && f.repeatPassword.errors">
            <div *ngIf="f.repeatPassword.errors.required">
              Hãy nhập lại mật khẩu
            </div>
            <div *ngIf="f.repeatPassword.errors.pattern">
              Tối thiểu 8 ký tự, không nhập khoảng trắng đầu và cuối
            </div>
          </div>
          <div
            class="message-required"
            *ngIf="submitted && !f.repeatPassword.errors">
            <div *ngIf="registrationForm.hasError('confirm')">
              Mật khẩu không khớp
            </div>
          </div>
        </div>
      </div>
      <div class="term">
        <div class="mg-bt-10">
          <label
            nz-checkbox
            [(ngModel)]="checked"
            [ngModelOptions]="{ standalone: true }">
            Tôi đồng ý với những Điều khoản dịch vụ và Chính sách bảo mật
          </label>
        </div>
        <div class="message-required" *ngIf="submitted && !checked">
          <div>Bạn chưa chọn đồng ý</div>
        </div>
      </div>
      <button class="btn-regist" nz-button (click)="onSubmit()">Đăng ký</button>
    </form>
  </div>
</div>
