import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-observation-type-item',
  templateUrl: './observation-type-item.component.html',
  styleUrls: ['./observation-type-item.component.scss'],
})
export class ObservationTypeItemComponent implements OnInit {
  @Input() obt: any;
  @Input() type: boolean;
  @Output() selectItem: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {
    // console.log(this.obt);
  }

  selectOT(): void {
    this.selectItem.emit(this.obt);
  }
}
