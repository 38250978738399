import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html',
  styleUrls: ['./prescription-list.component.scss'],
})
export class PrescriptionListComponent implements OnInit {
  @Input() listPrescription = [];
  @Input() visit;
  @Input() isUseApprovePrescription = false;
  @Input() isUseDeliveryInfo = false;
  @Input() isCanActionCurPrescription = false;

  @Output() copyData = new EventEmitter();
  @Output() prescriptionChange = new EventEmitter();

  isMobile = false;
  dateTimeFormat = 'dd/MM/yyyy HH:mm';
  showDetail = false;
  prescriptionViewDetail: any;

  constructor() {
    this.isMobile = window.innerWidth < 540;
  }

  ngOnInit(): void {}
  showModalPreview(data) {
    this.showDetail = true;
    if (data.RecipientName == null) {
      data.RecipientName = this.visit.patientName;
      data.RecipientPhoneNo = this.visit.phoneNo;
      data.RecipientAddress = this.visit.address;
    }
    this.prescriptionViewDetail = data;
    console.log('showModalPreview', data);
  }
  closeModalPreview() {
    this.showDetail = false;
  }
  copyPrescription(data) {
    this.copyData.emit(data);
  }
  gotoPrescriptionTab(data) {
    this.prescriptionChange.emit(data);
  }
}
