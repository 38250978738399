import { CodeMapSystemOT } from './designated-list.class';

export class ObservationType {
  id: string;
  stt: string;
  Name: string;
  OTcode: string;
  Category: string;
  codeSystem: string;
  Datatype: string;
  Unit: string;
  Creator: string;
  Createdtime: string;
  dataSource: any;
  MinValue: number;
  MaxValue: number;
}

export interface SaveObservationType {
  id: any;
  groupIds: Array<any>;
  otCode: string;
  //codeSystem: string;
  unit: string;
  name: string;
  category: number;
  description: string;
  dataType: number;
  dataSource: Array<any>;
  validMinValue: number;
  validMaxValue: number;
  privacyUse: number;
  listRange: Array<any>;
  listCodeMapSystem: CodeMapSystemOT[];
  isDisabled: boolean;
}
export interface PostOT {
  search: string;
  category: number;
  dataType: number;
  groupId: string;
}
// export interface SaveListObservation {
//   visitId: string;
//   patientId: string;
//   listObservation: Array<Observation>;
// }
export class Observation {
  id: string;
  observationTypeId: string;
  otCode: string;
  name: string;
  value: any;
  value2Show: any;
  visitId: string;
  //dataType: number;
  unit: string;
  minValue: number;
  maxValue: number;

  valueString: string;
  valueBoolean: boolean;
  valueInteger: string;
  valueTime: any;
  valueDateTime: any;
  valueSelections: any;
  valueRangeType: any;
}

export interface PostObservation {
  visitId: string;
  observationCategory: number;
}

export interface ControlSource {
  Text: string;
  Id: string;
  ParentId: string;
  ImageSrc: string;
}
