<div *ngIf="isUsePrescriptionDeliveryInfo" class="thuoc-general-info {{ isEdittingGeneralInfo }}">
  <div style="display: flex">
    <div class="m-tab-title mg-bt-10">
      Thông tin giao nhận
      <ng-container *ngIf="
          !isEdittingMedical && prescription != null && canEditPrescription
        ">
        <i class="icon-general-info" title="Sửa thông tin giao nhận" nz-icon nzType="edit" nzTheme="outline"
          [disabled]="disabledActiton" (click)="editGeneralInfo()"></i>
        <i class="icon-general-info" title="Nhập số thuốc BN đã có" nz-icon nzType="medicine-box" nzTheme="outline"
          [disabled]="disabledActiton" (click)="openModalEditAmountExist()"></i>
      </ng-container>
      <i class="icon-expand" nz-icon [nzType]="isExpandSearch ? 'down' : 'right'" nzTheme="outline"
        (click)="toggleExpandSearch()"></i>
    </div>
  </div>
  <div *ngIf="!isEdittingGeneralInfo && !isEdittingMedical && isExpandSearch" class="row">
    <div class="col-6">
      <div class="item-info">
        <div class="txt-info">Người nhận thuốc:</div>
        <div>{{ generalInfoFormData['recipientName'].value }}</div>
      </div>
      <div class="item-info">
        <div class="txt-info">SĐT người nhận:</div>
        <div>{{ generalInfoFormData['recipientPhoneNo'].value }}</div>
      </div>
      <div class="item-info">
        <div class="txt-info">Địa chỉ nhận thuốc:</div>
        <div>{{ generalInfoFormData['recipientAddress'].value }}</div>
      </div>
    </div>
    <div class="col-6">
      <div class="item-info">
        <div class="txt-info">Thời gian có thể nhận:</div>
        <div>{{ generalInfoFormData['recipientTimePlan'].value }}</div>
      </div>
      <div class="item-info">
        <div class="txt-info">Phương thức thanh toán:</div>
        <div>
          {{ generalInfoFormData['paymentMethodString'].value }}
        </div>
      </div>
      <div class="item-info">
        <div class="txt-info">Ghi chú:</div>
        <div>{{ generalInfoFormData['recipientNote'].value }}</div>
      </div>
    </div>
    <!-- <div class="item-info">
      <div class="txt-info">Người nhận thuốc:</div>
      <div>{{ generalInfoFormData['recipientName'].value }}</div>
    </div>
    <div class="item-info">
      <div class="txt-info">SĐT người nhận:</div>
      <div>{{ generalInfoFormData['recipientPhoneNo'].value }}</div>
    </div>
    <div class="item-info">
      <div class="txt-info">Địa chỉ nhận thuốc:</div>
      <div>{{ generalInfoFormData['recipientAddress'].value }}</div>
    </div>
    <div class="item-info">
      <div class="txt-info">Thời gian có thể nhận:</div>
      <div>{{ generalInfoFormData['recipientTimePlan'].value }}</div>
    </div>
    <div class="item-info">
      <div class="txt-info">Phương thức thanh toán:</div>
      <div>
        {{ generalInfoFormData['paymentMethodString'].value }}
      </div>
    </div>
    <div class="item-info">
      <div class="txt-info">Ghi chú:</div>
      <div>{{ generalInfoFormData['recipientNote'].value }}</div>
    </div> -->
  </div>

  <div *ngIf="isEdittingGeneralInfo || isEdittingMedical" [formGroup]="generalInfoForm">
    <div class="item-info">
      <div class="txt-info">Người nhận thuốc:</div>
      <div class="input-info">
        <input nz-input type="text" formControlName="recipientName" placeholder="Nhập người nhận thuốc"
          autocomplete="off" />
        <div class="message-required" *ngIf="generalInfoFormData.recipientName.errors">
          <div class="div-error" *ngIf="generalInfoFormData.recipientName.errors.required">
            Người nhận không được để trống
          </div>
        </div>
      </div>
    </div>
    <div class="item-info">
      <div class="txt-info">SĐT người nhận:</div>
      <div class="input-info">
        <input nz-input type="text" formControlName="recipientPhoneNo" placeholder="Nhập SĐT người nhận"
          autocomplete="off" />
        <div class="message-required" *ngIf="generalInfoFormData.recipientPhoneNo.errors">
          <div class="div-error" *ngIf="generalInfoFormData.recipientPhoneNo.errors.required">
            SĐT người nhận không được để trống
          </div>
        </div>
      </div>
    </div>
    <div class="item-info">
      <div class="txt-info">Địa chỉ nhận thuốc:</div>
      <div class="input-info">
        <input nz-input type="text" formControlName="recipientAddress" placeholder="Nhập địa chỉ nhận thuốc"
          autocomplete="off" />
        <div class="message-required" *ngIf="generalInfoFormData.recipientAddress.errors">
          <div class="div-error" *ngIf="generalInfoFormData.recipientAddress.errors.required">
            Địa chỉ nhận thuốc không được để trống
          </div>
        </div>
      </div>
    </div>
    <div class="item-info">
      <div class="txt-info">Thời gian có thể nhận:</div>
      <div class="input-info">
        <input nz-input type="text" formControlName="recipientTimePlan" placeholder="Nhập thời gian dự kiến"
          autocomplete="off" />
      </div>
    </div>
    <div class="item-info">
      <div class="txt-info">Phương thức thanh toán:</div>
      <div class="input-info">
        <nz-select class="w-100" formControlName="paymentMethod" nzPlaceHolder="Chọn phương thức">
          <nz-option [nzValue]="0" nzLabel="Tiền mặt"></nz-option>
          <nz-option [nzValue]="1" nzLabel="Chuyển khoản"></nz-option>
          <nz-option [nzValue]="2" nzLabel="Miễn phí"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="item-info">
      <div class="txt-info">Ghi chú:</div>
      <div class="input-info">
        <input nz-input type="text" formControlName="recipientNote" placeholder="Nhập ghi chú giao thuốc"
          autocomplete="off" />
      </div>
    </div>
  </div>
</div>
<div class="item-info" *ngIf="isEdittingGeneralInfo" style="margin-top: 10px">
  <div class="txt-info"></div>
  <div class="d-flex">
    <button nz-button class="mg-r-15 btn-primary-small" (click)="saveGeneralInfo()" style="padding: 0 32px">
      Lưu thông tin
    </button>
    <button nz-button class="mg-r-15 btn-cancel-small" (click)="cancelEditGeneralInfo()" style="padding: 0 32px">
      Hủy
    </button>
  </div>
</div>
<app-modal-edit-amount [(isVisible)]="isVisibleAmountExistOfPatient" [listMedicationTable]="listMedicationTable"
  (saveGeneralInfoEvent)="saveGeneralInfo()"></app-modal-edit-amount>